import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Container } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import VendorsService from '~/services/VendorsService';

import { ErrorPaymentsAddForm } from './ErrorPaymentsAddForm';
import { ErrorPaymentsList } from './ErrorPaymentsList';

interface Props {
  offerId: string;
  vendorId: string;
  errorPayments: Record<string, unknown>[];
  totalErrorPayments: {
    amount: number;
    currency: string;
  };
  onErrorPaymentAdded: (errorPayment: Record<string, unknown>) => void;
  onErrorPaymentUpdated: (errorPayment: Record<string, unknown>) => void;
  onErrorPaymentDeleted: (errorPaymentId: string) => void;
}

export default function ErrorPaymentsPane(props: Props) {
  const { onErrorPaymentAdded, onErrorPaymentUpdated, onErrorPaymentDeleted } = props;

  const [schema, setSchema] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const toggleForm = useCallback(() => {
    setShowForm((prevState) => !prevState);
  }, []);

  const handleErrorPaymentAdded = useCallback(
    (errorPayment) => {
      setShowForm(false);
      onErrorPaymentAdded(errorPayment);
    },
    [onErrorPaymentAdded],
  );

  useEffect(() => {
    setLoading(true);
    VendorsService.getErrorPaymentsSchema()
      .then((schemas) => setSchema(schemas.post.body))
      .finally(() => setLoading(false));
  }, []);

  if (loading || !props.vendorId) {
    return <Spinner />;
  }

  return (
    <Box>
      <PageSubheader title="Error Payments">
        {showForm ? (
          <Button startIcon={<RemoveIcon />} variant="text" onClick={toggleForm} aria-label="Hide error payment form">
            Hide error payment form
          </Button>
        ) : (
          <Button startIcon={<AddIcon />} variant="text" onClick={toggleForm} aria-label="Add new error payment">
            Add error payment
          </Button>
        )}
      </PageSubheader>

      {showForm && (
        <Container maxWidth="sm">
          <ErrorPaymentsAddForm
            schema={schema}
            offerId={props.offerId}
            vendorId={props.vendorId}
            onErrorPaymentAdded={handleErrorPaymentAdded}
          />
        </Container>
      )}

      <Box id="error-payments-pane">
        <ErrorPaymentsList
          schema={schema}
          errorPayments={props.errorPayments}
          totalErrorPayments={props.totalErrorPayments}
          onErrorPaymentUpdated={onErrorPaymentUpdated}
          onErrorPaymentDeleted={onErrorPaymentDeleted}
        />
      </Box>
    </Box>
  );
}
