import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import addUserData from '~/utils/addUserData';

import VendorOfferPaymentsList from './VendorOfferPaymentsList';

interface Props {
  data: {
    result: Array<any>;
  };
  searchTerm?: string;
}

export default function VendorOfferPaymentsListContainer({ data, searchTerm }: Props) {
  const [isLoading, setLoading] = useState(false);
  const [payments, setPayments] = useState(data.result);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const result = await addUserData(data.result, 'fk_added_by');
        setPayments(result);
      } catch (err) {
        enqueueSnackbar(`Failed to get vendor offer payments: ${err.message}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [data.result, enqueueSnackbar]);

  let filteredPayments;
  // search by payment amount if the search string fits the payment amount format
  if (searchTerm?.match(/\d+\.00/i)) {
    filteredPayments = payments.filter((payment) => payment.cost_amount === searchTerm); // both cost_amount and searchTerm are string
  } else {
    filteredPayments = !searchTerm ? payments : payments.filter((payment) => payment.description?.includes(searchTerm));
  }

  return <VendorOfferPaymentsList isLoading={isLoading} payments={filteredPayments} />;
}
