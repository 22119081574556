import React from 'react';

import currencyFormatter from 'currency-formatter';

import { addYears, formatDateLong } from '~/services/TimeService';

export default class GiftCardOrderSummaryItem extends React.Component {
  render() {
    const {
      item: { price },
      currencyCode,
      itemIdx,
    } = this.props;

    const expiry = formatDateLong(addYears(3));

    return (
      <li className="summary-item-item">
        <div className="row">
          <div className="col-xs-12 col-sm-7">
            <div className="item-detail-container">
              <div className="item-number">{itemIdx + 1}</div>
              <div className="item-detail">
                <div className="row">
                  <div className="col-sm-4 col-xl-3">
                    <strong>Value</strong>
                  </div>
                  <div className="col-sm-8 col-xl-9">
                    {currencyFormatter.format(price, {
                      code: currencyCode,
                      precision: price % 1 ? 2 : 0,
                    })}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-xl-3">
                    <strong>Expiry Date</strong>
                  </div>
                  <div className="col-sm-8 col-xl-9">{expiry}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-5">
            <div className="item-amounts">
              <div className="item-price data-hj-whitelist">
                {currencyFormatter.format(price, {
                  code: currencyCode,
                })}
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}
