import React from 'react';

import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

interface MetricsTableProps {
  sizePerPage: number;
  alerts: any[];
  columns: any[];
  page: number;
  setPage: (page: number) => void;
  total: number;
  loading: boolean;
}

const MetricsResultsTable = ({ sizePerPage, alerts, columns, page, setPage, total, loading }: MetricsTableProps) => {
  return (
    <Box sx={{ height: '300px', width: '100%' }}>
      <DataGrid
        rows={alerts}
        columns={columns}
        paginationModel={{ page, pageSize: sizePerPage }}
        pagination
        paginationMode="server"
        rowCount={total}
        onPaginationModelChange={({ page }) => setPage(page)}
        getRowId={(row) => row.metricId}
        slots={{ pagination: GridPagination }}
        loading={loading}
      />
    </Box>
  );
};

export default MetricsResultsTable;
