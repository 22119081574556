import React, { useEffect, useState } from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { useParams } from 'react-router';

import { Container, Typography } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import TourOptionsEdit from './TourOptionsEdit';

export default function TourOptionsEditContainer() {
  const { id_vendor: vendorId, id_tour: tourId } = useParams<{ id_vendor: string; id_tour: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState<App.Vendor | null>(null);
  const [tour, setTour] = useState<API.Reservation.Tour & { tour_legs?: any[] }>(null);
  const [schema, setSchema] = useState<RJSFSchema>(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getTour(tourId),
      ReservationService.getTourOptionsSchema(tourId),
      VendorsService.getVendorById(vendorId),
    ])
      .then(([tour, schema, vendor]) => {
        setTour(tour.result);
        setSchema(schema.post.body.schema);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, tourId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load tour or vendor.</Typography>
      </Container>
    );
  }

  return (
    <TourOptionsEdit
      tour={tour}
      tourOptions={tour.tour_options_count ? tour.tour_options : []}
      tourLegs={tour.tour_legs_count ? tour.tour_legs : []}
      vendorId={vendorId}
      vendor={vendor}
      tourId={tour.id}
      tourOptionSchema={schema}
    />
  );
}
