import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '../Common/Elements/PageHeader';

import { contentTabs, contentTabsList } from './ContentTabs';

export default function CurationPageContainer({
  match: {
    params: { tab },
  },
}: {
  match: {
    params: { tab: keyof typeof contentTabs };
  };
}) {
  const currentTab = contentTabs[tab];
  const index = contentTabsList.indexOf(tab);
  const title = `Curation / ${currentTab.title}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Container style={{ maxWidth: '95vw' }}>
        <PageHeader title={title} />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={index} variant="scrollable">
            {contentTabsList.map((tab, index) => (
              <Tab key={tab} value={index} label={contentTabs[tab].title} to={`/curation/${tab}`} component={Link} />
            ))}
          </Tabs>
        </Box>

        <Box mt={4}>
          <Box component={currentTab.component} />
        </Box>
      </Container>
    </>
  );
}
