import React, { useMemo, useState } from 'react';

import pluralize from 'pluralize';

import { Alert, Box, Divider, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import PermissionedComponent from '~/components/Common/PermissionedComponent';
import DebugModal from '~/components/DebugModal/DebugModal';

import ResyncDataModal from '../ResyncDataModal';

import { ConditionChecks } from './Rules/offer-doctor-checks';
import { ICondition, Status } from './Rules/types';
import SingleCheck from './SingleCheck';

interface OfferDoctorProps {
  offer: App.Offer;
  property: App.Property;
}

export default function OfferHealthStatus({ offer, property }: OfferDoctorProps): JSX.Element {
  const [checks, setChecks] = useState<Array<ICondition>>([]);
  const [expanded, setExpanded] = useState<boolean>(false);

  const checksCount = Object.values(ConditionChecks)
    .flatMap((recordType) => Object.values(recordType))
    .filter((check) => !check.hidden?.(offer, property)).length;

  let buffer: Array<ICondition> = [];

  const setCheckState = (
    key,
    { level: value, nonCritical, hidden }: { level: number; nonCritical?: boolean; hidden?: boolean },
  ) => {
    const [recordType, condition] = key.split('-');
    const temp = {
      recordType,
      condition,
      level: value,
      nonCritical: nonCritical,
      hidden: hidden,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    buffer = [...buffer, temp];
    setChecks(buffer);
  };

  const issues = useMemo(() => {
    const issues = checks.map((check) => (check.nonCritical && check.level > 1 ? 1 : check.level));
    const passing = issues.filter((issue) => issue === Status.Pass).length;
    const warnings = issues.filter((issue) => issue === Status.Warning).length;
    const errors = issues.filter((issue) => issue === Status.Fail).length;
    const info = issues.filter((issue) => issue === Status.Info).length;
    return { passing, warnings, errors, info, all: issues };
  }, [checks]);

  const summary = useMemo(() => {
    const { warnings, errors } = issues;
    let text: string;
    if (warnings === 0 && errors === 0) {
      text = 'There are no issues detected';
    } else if (errors > 0) {
      text = `There ${pluralize('is', errors)} ${errors} critical ${pluralize('issue', errors)}`;
      if (warnings > 0) {
        text += ` and ${warnings} potential ${pluralize('issue', warnings)}`;
      }
    } else {
      text = `There ${pluralize('is', warnings)} ${warnings} potential ${pluralize('issue', warnings)}`;
    }
    return text;
  }, [issues]);

  const checkSeverity = useMemo(() => {
    switch (true) {
      case issues.errors > 0:
        return 'error';
      case issues.warnings > 0:
        return 'warning';
      default:
        return 'success';
    }
  }, [issues]);

  return (
    <PermissionedComponent>
      <Box>
        <Typography
          component="h1"
          variant="h4"
          color={[grey[600]]}
          sx={{ fontWeight: 300, cursor: 'pointer' }}
          onClick={() => setExpanded(!expanded)}
        >
          <Stack direction="row" alignItems="center">
            Offer Doctor&nbsp;
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                gap: '1rem',
                alignItems: 'center',
                media: { maxWidth: '100%' },
              }}
            >
              <Alert severity={checkSeverity}>{summary}</Alert>
            </Box>
          </Stack>
          <Box>
            {offer && <DebugModal type="offer" data={offer} />}
            {offer && property && <ResyncDataModal offerId={offer.id_salesforce_external} propertyId={property.id} />}
          </Box>
        </Typography>
        {checksCount !== checks.length && <LinearProgress />}
        <Box className={!expanded && 'hidden'}>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            {Object.entries(ConditionChecks).map(([recordType, conditions]) =>
              Object.entries(conditions).map(
                ([condition, check]) =>
                  !check.hidden?.(offer, property) && (
                    <SingleCheck
                      key={`${recordType}-${condition}`}
                      check={check}
                      offer={offer}
                      property={property}
                      setCheckResult={(value) => setCheckState(`${recordType}-${condition}`, value)}
                    />
                  ),
              ),
            )}
          </Grid>
        </Box>
      </Box>
    </PermissionedComponent>
  );
}
