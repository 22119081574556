import React from 'react';

import { Dayjs } from 'dayjs';
import dayjs from '~/timeInit';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';

type Props = Omit<DatePickerProps<Dayjs>, 'value' | 'onChange'> & {
  value?: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
};

export default function DateWidget({ value, onChange, fullWidth, ...props }: Props) {
  return (
    <DatePicker
      slotProps={{ textField: { fullWidth } }}
      format="DD/MM/YYYY"
      {...props}
      value={value ? dayjs(value) : undefined}
      onChange={(value) => onChange(value ? value.format('YYYY-MM-DD') : '')}
    />
  );
}
