import React from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';

import { Button, Stack } from '@mui/material';

import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import { DeleteButton } from '../DeleteButton';
import ErrorDisplay from '../ErrorDisplay';

import { buttonMessages, buttonStates } from './states/submitButton';

const uiSchema = {
  tour_id: { 'ui:widget': 'hidden' },
};

const errorMessage = `This tour option is currently attached to an offer. You must
  detach it from any offers before you can delete.`;

export default class TourOptionForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      tourOption: props.tourOption,
      saveState: buttonStates.default,
      deleteState: 'delete',
    };
    this.tourOptionSchema = this.props.tourOptionSchema;

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onSubmit(form) {
    var self = this;

    this.setState({
      saveState: buttonStates.saving,
      tourOption: form.formData,
    });

    let submitType = 'updateTourOption';
    if (!this.state.tourOption.id) {
      submitType = 'createTourOption';
    }

    const tourId = this.state.tourOption.tour_id;
    const tourOptionId = this.state.tourOption.id;

    ReservationService[submitType](form.formData, tourId, tourOptionId)
      .then((response) => {
        self.setState({
          tourOption: response.result,
          saveState: buttonStates.saved,
        });
      })
      .catch(function (e) {
        self.setState({ saveState: buttonStates.failed });
        reportError(e);
      });
  }

  onChange(edit) {
    const newState = {
      tourOption: edit.formData,
    };

    if (this.state.saveState !== buttonStates.default) {
      newState.saveState = buttonStates.default;
    }

    this.setState(newState);
  }

  async onDelete() {
    this.setState({ deleteState: 'deleting' });

    const tourOptionIsUsed = await this.findUsage();

    if (tourOptionIsUsed) {
      this.setState({ deleteState: 'failed' });
      return false;
    }
    var self = this;

    ReservationService.deleteTourOption(self.state.tourOption.tour_id, self.state.tourOption.id)
      .then(function () {
        self.props.handleRemove(self.props.formKey);
      })
      .catch(function (e) {
        if (e.status === 400 && e.errors) {
          alert(e.errors[0].message);
        }
        reportError(e);
      });
    this.setState({ deleteState: 'delete' });
  }

  findUsage = async () => {
    const offers = await this.fetchData();

    const typeOffers = offers.filter((offer) => {
      return offer.packages.some((p) => p.fk_tour_option_id == this.state.tourOption.id);
    });

    return typeOffers.length;
  };

  async fetchData() {
    const { result: offers } = await OffersService.getOffers({
      queryString: this.vendorId,
    }).catch(function (e) {
      reportError(e);
    });

    return offers;
  }

  render() {
    return (
      <Form
        schema={this.tourOptionSchema}
        onChange={this.onChange}
        formData={this.state.tourOption}
        onSubmit={this.onSubmit}
        uiSchema={uiSchema}
        validator={validator}
      >
        {this.state.deleteState == 'failed' ? <ErrorDisplay message={errorMessage} /> : null}

        <Stack direction="row" spacing={1} mt={1}>
          <Button type="submit" variant="contained" size="small" className={this.state.saveState}>
            {buttonMessages[this.state.saveState]}
          </Button>

          {this.state.tourOption.id && (
            <DeleteButton size="small" onClick={this.onDelete} buttonText={this.state.deleteState} />
          )}
        </Stack>
      </Form>
    );
  }
}
