import React from 'react';

import ExportMusementBookingsForm from '../Common/Forms/MusementExportBookingsForm';
import ExportMusementPaidForm from '../Common/Forms/MusementExportPaidForm';
import ExportMusementPayableForm from '../Common/Forms/MusementExportPayableForm';
import ExportMusementRefundForm from '../Common/Forms/MusementRefundForm';
import UploadMusementReportForm from '../Common/Forms/MusementUploadPaidForm';

import ContentWithSidebar from './ContentWithSidebar';

const DEFAULT_ITEM = 'musement-bookings';

const items = [
  {
    title: 'Musement Bookings',
    slug: 'musement-bookings',
    component: ExportMusementBookingsForm,
  },
  {
    title: 'Musement Payable Report',
    slug: 'musement-payable',
    component: ExportMusementPayableForm,
  },
  {
    title: 'Upload Musement Paid Report',
    slug: 'upload-musement-paid',
    component: UploadMusementReportForm,
  },
  {
    title: 'Musement Paid Report',
    slug: 'musement-paid',
    component: ExportMusementPaidForm,
  },
  {
    title: 'Musement Refund Report',
    slug: 'musement-refund',
    component: ExportMusementRefundForm,
  },
];

export default function MusementPage() {
  return <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEM} />;
}
