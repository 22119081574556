class Context {
  constructor() {
    this.router = null;
    this.dispatcher = null;
    this.tokenStore = null;
    this.sessionStore = null;
    this.userStore = null;
    this.history = null;
  }

  setRouter(router) {
    this.router = router;
  }

  setHistory(history) {
    this.history = history;
  }

  getRouter() {
    return this.router;
  }

  setDispatcher(dispatcher) {
    this.dispatcher = dispatcher;
  }

  getDispatcher() {
    return this.dispatcher;
  }

  setTokenStore(tokenStore) {
    this.tokenStore = tokenStore;
  }

  setSessionStore(sessionStore) {
    this.sessionStore = sessionStore;
  }

  getSessionStore() {
    return this.sessionStore;
  }

  setUserStore(userStore) {
    this.userStore = userStore;
  }

  getUserStore() {
    return this.userStore;
  }

  checkTokenExpired() {
    const token = this.userStore.service.getToken();

    // note: string 'undefined' is intentional... this is the value of the
    // token in localStorage when it expires
    const isTokenExpired = !token || token === 'undefined';

    if (isTokenExpired) {
      localStorage.setItem('showExpiredTokenMessage', true);
      this.userStore.service.logout(window?.location?.reload());
    }
  }
}

export default new Context();
