import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { Box, Container, Stack } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import { CabinCategoryById, getCabinCategoryById } from '~/services/cruises/CabinCategoriesService';

import CruisesLinkButton from '../CruisesLinkButton';

import CabinCategoriesProviderDetails from './CabinCategoriesProviderDetails';
import { DEFAULT_CABIN_CATEGORY_BY_ID_RESPONSE } from './constants';

export default function CabinCategoriesDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { id_cabin: cabinId } = useParams<{ id_cabin: string }>();

  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');
  const [cabinCategory, setCabinCategory] = useState<CabinCategoryById>(DEFAULT_CABIN_CATEGORY_BY_ID_RESPONSE);

  const fetchCabinCategory = useCallback(
    async (cabinId: string): Promise<void> => {
      setFetching('loading');

      try {
        const fetchedCabin = await getCabinCategoryById({ cabinId });

        setFetching('success');
        setCabinCategory(fetchedCabin.result);
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setCabinCategory(null);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (cabinId) {
      fetchCabinCategory(cabinId);
    }
  }, [cabinId, fetchCabinCategory]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Cruises | Cabin Category Details Page</title>
      </Helmet>

      <Box>
        {fetching === 'loading' && <Spinner size={24} />}

        {fetching === 'success' && (
          <Fragment>
            <Box mb={3}>
              <Stack direction="row" spacing={2} alignItems="center">
                {cabinCategory.ship.id && (
                  <Stack direction="row" spacing={2}>
                    <CruisesLinkButton
                      text={'Admin Portal Ship Details Page'}
                      href={`/cruises/ships/${cabinCategory.ship.id}`}
                    />
                  </Stack>
                )}
                {cabinCategory.cabinCategoryGroup?.id && (
                  <Stack direction="row" spacing={2}>
                    <CruisesLinkButton
                      text={'Admin Portal Cabin Categories Group Details Page'}
                      href={`/cruises/cabin-categories-groups/${cabinCategory.cabinCategoryGroup.id}`}
                    />
                  </Stack>
                )}
              </Stack>
            </Box>

            <Stack spacing={3}>
              <PageSubheader title="Cabin Category Overview" />
              <CabinCategoriesProviderDetails cabinCategory={cabinCategory} />
            </Stack>
          </Fragment>
        )}
      </Box>
    </Container>
  );
}
