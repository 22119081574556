import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Alert, Box, Button, CircularProgress, Grid } from '@mui/material';

import { getCompedPackageById } from '~/services/PromoService';

import CompedPackagesForm, { CompedPackage } from './CompedPackagesForm';

const CompedPackagesDetailContainer = () => {
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [compedPackage, setCompedPackage] = useState<CompedPackage | null>(null);

  const { id_comped: compedId } = useParams<{ id_comped: string }>();

  useEffect(() => {
    async function fetchCompedPackageDetails() {
      setLoading(true);
      try {
        const { result } = await getCompedPackageById(compedId);
        setCompedPackage(result);
      } catch (e) {
        setErrors(`Failed fetching list of famils and competition winners: ${e?.message}`);
      } finally {
        setLoading(false);
      }
    }
    fetchCompedPackageDetails();
  }, [compedId]);

  return (
    <Box sx={{ pt: 2, pb: 2, height: '100%' }}>
      <Helmet>
        <title>Comped | {compedPackage?.description || ''}</title>
      </Helmet>
      <Box sx={{ mb: 2 }}>
        <Button to="/marketing/comped/" component={Link} variant="text" startIcon={<ArrowBackIosNewIcon />}>
          Return to list
        </Button>
      </Box>
      {loading && (
        <Grid container justifyContent="center" alignContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!loading && (
        <Grid container justifyContent="center">
          <Grid item sx={{ width: '75%' }}>
            <CompedPackagesForm compedPackage={compedPackage} />
          </Grid>
        </Grid>
      )}
      {errors && (
        <Alert sx={{ mt: 1 }} severity="error">
          {errors}
        </Alert>
      )}
    </Box>
  );
};

export default CompedPackagesDetailContainer;
