import React from 'react';

import { Box } from '@mui/material';

export default function TourAutomaticRefundDetails(props) {
  return (
    <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr 1fr">
      <Box gridColumn="span 3">
        <h4>Refund Summary</h4>
      </Box>

      <Box>
        <small>Customer Name</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.customer_full_name}</small>
      </Box>

      <Box>
        <small>Refund Category</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.reason}</small>
      </Box>

      <Box>
        <small>Customer's reason</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.comment}</small>
      </Box>

      <Box>
        <small>Source of Fund</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.source}</small>
      </Box>

      <Box>
        <small>Room Cancelled</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.markCancelled}</small>
      </Box>

      <Box>
        <small>
          <b>Customer Refund Amount {props.currencyCode}</b>
        </small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.totalRefund}</small>
      </Box>
    </Box>
  );
}
