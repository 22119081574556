import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
  { field: 'created_at', headerName: 'Created at', width: 300, sortable: false, display: 'flex' },
];

export default function RatePlansGroupsList(props) {
  const { ratePlansGroups } = props;

  return (
    <DataGrid
      columns={columns}
      rows={ratePlansGroups || []}
      getRowId={(row) => row.name}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
}
