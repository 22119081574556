/* Purchases/Home/GiftCardsPage.js */
import React from 'react';

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { formatDateNumeralMonths } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

import { dateFormatter, orderLinkFormatter, tourBookingNumbersFormatter, userLinkFormatter } from './formatters';

interface Props {
  orders: API.Order.Order[];
  total: number;
  page: number;
  sizePerPage: number;
  onPageChange: (page: number) => void;
  rerender: () => void;
}

const columns: GridColDef[] = [
  {
    field: 'customer_email',
    headerName: 'Customer Email',
    sortable: false,
    flex: 1,
    width: 250,
    renderCell: userLinkFormatter,
    display: 'flex',
  },
  { field: 'customer_full_name', headerName: 'Customer Name', flex: 1, width: 250, sortable: false, display: 'flex' },
  {
    field: 'created_at',
    headerName: 'Order Placed',
    sortable: false,
    width: 190,
    renderCell: (params) => dateFormatter(params.value),
    display: 'flex',
  },
  { field: 'status', headerName: 'Order Status', width: 150, sortable: false, display: 'flex' },
  {
    field: 'tour_items',
    headerName: 'Booking Numbers',
    sortable: false,
    width: 150,
    renderCell: tourBookingNumbersFormatter,
    display: 'flex',
  },
  {
    field: 'total_price',
    headerName: 'Total Price',
    sortable: false,
    width: 110,
    renderCell: ({ row }: GridRenderCellParams<App.Order>) => currencyFormatter(row.currency_code, row.total),
    display: 'flex',
  },
  { field: 'utm_source', headerName: 'Source', width: 130, sortable: false, display: 'flex' },
  {
    field: 'travel_dates',
    headerName: 'Travel Dates',
    sortable: false,
    width: 200,
    renderCell: ({ row }: GridRenderCellParams<App.Order>) =>
      `${formatDateNumeralMonths(row.tour_items[0]?.start_date)} to ${formatDateNumeralMonths(
        row.tour_items[0]?.end_date,
      )}`,
    display: 'flex',
  },
  {
    field: 'id',
    headerName: 'View Order',
    sortable: false,
    width: 120,
    renderCell: orderLinkFormatter,
    display: 'flex',
  },
];

export default function ToursPage(props: Props) {
  const { orders, total, sizePerPage, onPageChange, page } = props;

  return (
    <DataGrid
      rows={orders}
      rowCount={total}
      columns={columns}
      pagination
      paginationMode="server"
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      pageSizeOptions={[sizePerPage]}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      slots={{ pagination: GridPagination }}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}
