import React, { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { removeTravellerFromPNR, updateTravellerDetails } from '~/actions/cart';

import { TRAVELLER_TYPES } from '../../../../consts/flight';

interface Props {
  pnr: App.OfflineFlightPnr;
  traveller: App.OfflineFlightTraveller;
  travellerIndex: number;
  pnrIndex: number;
  onRemoveTravellerFromPNR: Utils.ActionDispatcher<typeof removeTravellerFromPNR>;
  onUpdateTravellerDetails: Utils.ActionDispatcher<typeof updateTravellerDetails>;
}

function Component(props: Props) {
  const { pnr, traveller, travellerIndex, pnrIndex, onRemoveTravellerFromPNR, onUpdateTravellerDetails } = props;

  const handlePrice = (price) => {
    if (!price) {
      return 0;
    }
    const parsedPrice = Number(price);
    if (!parsedPrice || parsedPrice < 0) {
      return 0;
    }
    return Number(parsedPrice.toFixed(2));
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value;
      switch (event.target.name) {
        case 'price':
          value = handlePrice(event.target.value);
          break;
        default:
          value = event.target.value.toUpperCase();
          break;
      }

      onUpdateTravellerDetails(pnrIndex, travellerIndex, {
        [event.target.name]: value,
      });
    },
    [onUpdateTravellerDetails, pnrIndex, travellerIndex],
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      onUpdateTravellerDetails(pnrIndex, travellerIndex, {
        [event.target.name]: event.target.value,
      });
    },
    [onUpdateTravellerDetails, pnrIndex, travellerIndex],
  );

  const handleRemoveTravellerClick = useCallback(() => {
    if (pnr.travellers.length === 1) {
      return;
    }
    onRemoveTravellerFromPNR(pnrIndex, travellerIndex);
  }, [onRemoveTravellerFromPNR, pnr.travellers.length, pnrIndex, travellerIndex]);

  return (
    <Stack
      direction="column"
      gap={2}
      padding={2}
      className="booking-form" // Required for e2e tests
      sx={{ border: 1, borderColor: 'grey.300', mb: 2 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="firstName"
              name="firstName"
              label="Firstname"
              value={traveller.firstName}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="middleName"
              name="middleName"
              label="Middlename"
              value={traveller.middleName}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="lastName"
              name="lastName"
              label="Lastname"
              value={traveller.lastName}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="title"
              name="title"
              label="Title"
              value={traveller.title}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="typeLabel">Type</InputLabel>
            <Select
              labelId="typeLabel"
              id="type"
              name="type"
              label="Type"
              value={traveller.type}
              onChange={handleSelectChange}
            >
              {TRAVELLER_TYPES.map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField id="price" name="price" label="Price" value={traveller.price} onChange={handleInputChange} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              size="small"
              disabled={pnr.travellers.length === 1} // Prevent delete of the last Traveller on the PNR
              onClick={handleRemoveTravellerClick}
              startIcon={<DeleteIcon />}
            >
              Remove Traveller
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default Component;
