import * as Sentry from '@sentry/browser';
import { EventEmitter } from 'events';
import jwt_decode from 'jwt-decode';

import { addHours, isAfter } from '~/services/TimeService';

import { UserConstants } from './constants';
import context from './context';
import { FluxDispatcher } from './dispatchers';
import { UserService } from './services';
import { SessionStore, UserStore } from './stores';

const basePath = () => {
  return window.configs.AUTH_HOST + '/api';
};

class App extends EventEmitter {
  constructor() {
    super();
    this.initialized = false;
  }

  isInitialized() {
    return this.initialized;
  }

  init(options) {
    options = options || {};

    if (this.isInitialized()) {
      throw new Error('React Stormpath already initialized.');
    }

    this.initialized = true;

    let tokenStore = null;
    let sessionStore = new SessionStore();

    if (!options.endpoints) {
      options.endpoints = {};
    }

    let userService = new UserService(options.endpoints);

    let userStore = new UserStore(userService, sessionStore);

    context.setTokenStore(tokenStore);
    context.setSessionStore(sessionStore);
    context.setUserStore(userStore);

    let dispatcher = options.dispatcher || { type: 'flux' };

    let appReducer = (payload) => {
      switch (payload.type) {
        case UserConstants.USER_LOGIN:
          userStore.login(payload.options, payload.callback);
          break;
        case UserConstants.USER_LOGIN_WITH_GOOGLE:
          userStore.loginWithGoogle(payload.options, payload.callback);
          break;
        case UserConstants.USER_LOGOUT:
          userStore.logout(payload.callback);
          break;
        case UserConstants.USER_REGISTER:
          userStore.register(payload.options, payload.callback);
          break;
        case UserConstants.USER_FORGOT_PASSWORD:
          userStore.forgotPassword(payload.options, payload.callback);
          break;
        case UserConstants.USER_CHANGE_PASSWORD:
          userStore.changePassword(payload.options, payload.callback);
          break;
        case UserConstants.USER_UPDATE_PROFILE:
          userStore.updateProfile(payload.options.data, payload.callback);
          break;
        case UserConstants.USER_VERIFY_EMAIL:
          userStore.verifyEmail(payload.options.spToken, payload.callback);
          break;
        default:
          break;
      }
      return true;
    };

    switch (dispatcher.type) {
      case 'flux':
        dispatcher = new FluxDispatcher(appReducer);
        break;
      default:
        throw new Error('Stormpath SDK: Invalid dispatcher type ' + dispatcher.type);
    }
    context.setDispatcher(dispatcher);
  }

  getAccessToken() {
    return context.userStore.service.getToken();
  }

  setAccessToken(token) {
    return context.userStore.service.setToken(token);
  }

  getOrRefreshAccessToken() {
    // redirect to login if token is expired
    context.checkTokenExpired();

    const token = context.userStore.service.getToken();
    const decodedToken = token && jwt_decode(token);

    if (decodedToken && !isAfter(addHours(1), decodedToken.exp)) {
      return token;
    }

    const options = {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };

    return fetch(basePath() + '/refresh-token', options)
      .then((response) => response.json())
      .then(({ access_token }) => {
        context.userStore.service.setToken(access_token);
        return access_token;
      })
      .catch((error) => {
        Sentry.captureException(new Error(`${error.message} Auth token refresh failed`));
        context.userStore.service.logout(window?.location?.reload());
      });
  }
}

export default new App();
