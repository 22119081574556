import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import ReservationService from '~/services/ReservationService';

import ChainSettingsForm from './components/ChainSettingsForm';
import { GENERIC_INITIAL_REQUEST_STATE } from './constants';
import { hasRequiredFields } from './helpers';
import { ChainSettingsFormValues, Request } from './types';

const EditChainSettingsPage: React.FC = (): JSX.Element => {
  const { id_parent: parentId } = useParams<{ id_parent: string }>();

  const { enqueueSnackbar } = useSnackbar();
  const [chainSettings, setChainSettings] = useState<Request<any>>(GENERIC_INITIAL_REQUEST_STATE);

  const chainSettingsFormValues: ChainSettingsFormValues | null = useMemo(() => {
    if (!chainSettings.result) return null;

    return {
      cm_receives_gross: chainSettings.result.cm_receives_gross,
    };
  }, [chainSettings]);

  const fetchChainSettingsById = useCallback(
    async (parentId: string) => {
      setChainSettings({ loading: true, result: null });
      const res = await ReservationService.getChainSettings(parentId);

      if (!res || !res?.result || res?.status !== 200) {
        setChainSettings({ loading: false, result: null });
        enqueueSnackbar('Something went wrong, please contact cruises team', { variant: 'error' });
      } else {
        setChainSettings({ loading: false, result: res.result });
      }
    },
    [enqueueSnackbar],
  );

  const handleSubmit = useCallback(
    async (formValues: ChainSettingsFormValues) => {
      if (!hasRequiredFields(formValues)) {
        enqueueSnackbar(`Please fill all required fields`, { variant: 'error' });
      } else {
        enqueueSnackbar(`The information is being processed, please wait a moment.`, { variant: 'info' });

        const res = await ReservationService.editChainSettingsById({
          sfid: parentId,
          cm_receives_gross: formValues.cm_receives_gross,
        });

        if (res?.status === 201 || res?.status === 200) {
          enqueueSnackbar(`Chain settings edited successfully`, { variant: 'success' });
        } else {
          enqueueSnackbar('Something went wrong, please contact hotels team', { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, parentId],
  );

  useEffect(() => {
    fetchChainSettingsById(parentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  return (
    <>
      <Helmet>
        <title>Edit Chain Settings</title>
      </Helmet>

      <Box mx={3} px={5}>
        <Box mb={4}>
          <PageHeader title="Edit Chain Settings" backButton={`/vendors/chain/${parentId}`} />
        </Box>

        <ChainSettingsForm chainSettingsFormValues={chainSettingsFormValues} onSubmit={handleSubmit} />
      </Box>
    </>
  );
};

export default EditChainSettingsPage;
