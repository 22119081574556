import React from 'react';

import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

const userLinkFormatter = (params: GridRenderCellParams<Order.Order>) => (
  <Link href={`/users/${params.row.fk_customer_id}`} target="_blank" title={params.value}>
    {params.value}
  </Link>
);

export default userLinkFormatter;
