import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';

import { Box, Button, Link, Stack, TextField } from '@mui/material';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import type { TagType } from '~/consts/tags';
import { TAG_TYPE_CAMPAIGN, TAG_TYPE_HOLIDAY } from '~/consts/tags';

import { customerPortalHost } from '~/services/common';

function getCustomerPortalUrl(type: TagType, tagName: string) {
  const basePath = `${customerPortalHost({ value: 'lux' })}?`;

  switch (type) {
    case TAG_TYPE_HOLIDAY:
      return `${basePath}${qs.stringify({ holidayTypes: tagName })}`;
    case TAG_TYPE_CAMPAIGN:
    default:
      return `${basePath}${qs.stringify({ [type]: tagName })}`;
  }
}

type Props = {
  id: number;
  name: string;
  type: TagType;
  onDeleteTag: (type: TagType, id: number) => Promise<void>;
  onUpdateTag: (type: TagType, id: number, name: string) => Promise<void>;
};

export default function TagElement(props: Props) {
  const { id, name, type, onDeleteTag, onUpdateTag } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isEditMode, setEditMode] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);
  const [isProcessing, setProcessing] = useState(false);
  const showConfirmDialog = useConfirmDialog();

  const handleDelete = useCallback(
    async (e) => {
      e.preventDefault();

      const confirmed = await showConfirmDialog({
        title: 'Delete tag',
        description: 'This tag may be attached to live offers, are you sure you want to delete?',
      });

      if (!confirmed) {
        return;
      }

      try {
        setProcessing(true);
        await onDeleteTag(type, id);
      } catch (e) {
        setProcessing(false);
        enqueueSnackbar("Server error. Can't delete tag.", { variant: 'error' });
      }
    },
    [type, id, onDeleteTag, showConfirmDialog, enqueueSnackbar],
  );

  const handleEdit = useCallback(
    (e) => {
      e.preventDefault();
      setProcessing(true);

      onUpdateTag(type, id, updatedName)
        .then(() => {
          setEditMode(false);
          enqueueSnackbar('Tag changes has been saved', { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar("Server error. Can't save tag.", { variant: 'error' });
        })
        .finally(() => setProcessing(false));
    },
    [type, id, updatedName, onUpdateTag, enqueueSnackbar],
  );

  return (
    <form onSubmit={handleEdit}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box flexGrow={1}>
          {isEditMode && (
            <TextField
              size="small"
              type="text"
              label="Tag name"
              placeholder="Input a new tag name..."
              value={updatedName}
              onChange={(e) => setUpdatedName(e.target.value)}
              inputProps={{
                minLength: 3,
                maxLength: 64,
              }}
              required
            />
          )}
          {!isEditMode && (
            <Link href={getCustomerPortalUrl(type, name)} target="_blank" rel="noreferrer" underline="hover">
              {name}
            </Link>
          )}
        </Box>

        {isEditMode && (
          <>
            <Button variant="outlined" type="submit" disabled={isProcessing}>
              {isProcessing ? 'Saving' : 'Save'}
            </Button>
            <Button
              variant="text"
              disabled={isProcessing}
              onClick={() => {
                setEditMode(false);
                setUpdatedName(name);
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {!isEditMode && (
          <>
            <Button variant="outlined" disabled={isProcessing} onClick={() => setEditMode(true)}>
              Edit
            </Button>
            <Button variant="outlined" color="error" disabled={isProcessing} onClick={handleDelete}>
              Delete
            </Button>
          </>
        )}
      </Stack>
    </form>
  );
}
