function isEmployeeEmail(email: string) {
  if (!email) {
    return false;
  }

  const luxStaffEmailDomains = ['luxgroup.com', 'luxuryescapes.com', 'postindustria.com'];
  const domain = email.split('@')[1];

  return luxStaffEmailDomains.includes(domain);
}

export default {
  isEmployeeEmail,
};
