import React from 'react';

import classnames from 'clsx';

export default class SpecialRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      isOpen: false,
    };
  }

  handleUpdateGuestSpecialRequests = (val) => {
    const { onUpdateReservation, item } = this.props;
    this.setState({ value: val }, () => {
      const data = { guestSpecialRequests: this.state.value };
      onUpdateReservation(item, data);
    });
  };

  toggleIsOpen = () => {
    const { isOpen } = this.state;
    const nextIsOpen = !isOpen;

    if (nextIsOpen) {
      // Autofocus input element
      this.inputEl.focus();
    } else {
      // If closing the accordion, reset the guest requests
      this.handleUpdateGuestSpecialRequests('');
    }

    this.setState({ isOpen: nextIsOpen });
  };

  render() {
    const { isOpen, value } = this.state;

    return (
      <div className={classnames('special-requests', { 'is-open': isOpen })}>
        <button className="special-requests-toggle" aria-pressed={isOpen.toString()} onClick={this.toggleIsOpen}>
          <span className="open-indicator" /> <span className="toggle-text data-hj-whitelist">Add special request</span>
        </button>

        <div className="special-requests-body">
          <textarea
            className="special-requests-input"
            value={value}
            ref={(el) => {
              this.inputEl = el;
            }}
            onChange={(e) => {
              const val = e.target.value;

              this.handleUpdateGuestSpecialRequests(val);
            }}
          />
        </div>
      </div>
    );
  }
}
