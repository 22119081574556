import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material';

import { ChainSettingsFormValues } from '../types';

const INITIAL_FORM_STATE: ChainSettingsFormValues = {
  cm_receives_gross: false,
};

type Props = {
  chainSettingsFormValues?: ChainSettingsFormValues;
  onSubmit: (formValues: ChainSettingsFormValues) => void;
};

const ChainSettingsForm = (props: Props): JSX.Element => {
  const { chainSettingsFormValues, onSubmit } = props;

  const [formValues, setFormValues] = useState<ChainSettingsFormValues>(INITIAL_FORM_STATE);

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (event.target.name) {
        setFormValues({ ...formValues, [event.target.name]: checked });
      }
    },
    [formValues],
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      cm_receives_gross: formValues.cm_receives_gross,
    });
  }, [formValues, onSubmit]);

  useEffect(() => {
    if (chainSettingsFormValues) setFormValues(chainSettingsFormValues);
  }, [chainSettingsFormValues]);

  return (
    <Box>
      <Stack mt={3} alignItems="center" flexDirection="row" gap={3}>
        <FormControlLabel
          sx={{ marginTop: '8px' }}
          label="CM receives gross"
          control={
            <Checkbox
              name="cm_receives_gross"
              onChange={handleCheck}
              checked={formValues.cm_receives_gross}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
      </Stack>

      <Box mt={4}>
        <Button variant="contained" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default ChainSettingsForm;
