import React, { useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import ErrorListDisplay from '~/components/Common/ErrorListDisplay';
import Spinner from '~/components/Common/Spinner';

import { getPurchases } from '~/services/OrdersService';
import ReservationService from '~/services/ReservationService';

interface Props {
  isModalVisible: boolean;
  hideModal: () => void;
  bookingNumber: string;
}

export default function VCCConfirmationResendModal(props: Props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(undefined);

  const handleConfirm = async () => {
    setLoading(true);
    setErrors(undefined);

    try {
      const bookingNumber = props.bookingNumber;
      const response = await getPurchases({
        page: 1,
        per_page: 1,
        brand: null,
        customer_id: null,
        filterBy: 'booking_numbers',
        filterValue: bookingNumber,
      });

      const result = response.result;
      const item = result[0]?.items.find((item) => item.booking_number == bookingNumber);
      if (!item || !item.reservation) {
        throw new Error(`Can't fetch reservation for booking number ${bookingNumber}`);
      }

      await ReservationService.resendVCCConfirmation(item.reservation.id);

      props.hideModal();
    } catch (error) {
      setErrors(error.errors || [error.message || 'Some Error Occurred']);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={props.isModalVisible} onClose={props.hideModal}>
      <DialogTitle>Repush VCC</DialogTitle>

      <DialogContent>
        <DialogContentText>
          This action will repush VCC information to the property's reservations email address and property management
          system.
        </DialogContentText>

        {!!errors && (
          <Box mt={2}>
            <ErrorListDisplay errors={errors} />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.hideModal}>
          Cancel
        </Button>
        <Button variant="contained" disabled={loading} onClick={handleConfirm}>
          {loading ? <Spinner size={15} inline={true} /> : 'Yes, continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
