//stub component so that pattern can be established
import React, { KeyboardEvent, useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { Alert, Button, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridPaginationModel } from '@mui/x-data-grid';

import EmployeesGrid from '~/components/LeBusinessTraveller/components/EmployeesGrid';

import { getEmployeeList, getEmployeeSearch } from '~/services/BusinessTraveller/BusinessTravellerService';

interface Params {
  businessId: string;
  tab: string;
}

export default function EmployeesTab() {
  const [employees, setEmployees] = useState<App.BusinessEmployee[]>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [fetchEmployeesError, setFetchEmployeesError] = useState(false);
  const [nameString, setNameString] = useState('');
  const { businessId } = useParams<Params>();

  async function fetchEmployees() {
    try {
      const result = await getEmployeeList(businessId, paginationModel);
      setEmployees(result.result);
      setTotalEmployeeCount(result.meta.total);
      setFetchEmployeesError(false);
    } catch (e) {
      setFetchEmployeesError(true);
      console.error(e);
    }
  }

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  async function clearFilters() {
    await fetchEmployees();
    setNameString('');
  }

  async function filter() {
    try {
      const result = await getEmployeeSearch(businessId, nameString);
      setEmployees(result.result);
      setTotalEmployeeCount(1);
      setFetchEmployeesError(false);
    } catch (e) {
      setFetchEmployeesError(true);
      console.error(e);
    }
    return;
  }

  async function handleEnterPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      await filter();
    }
  }

  return (
    <Stack gap={2}>
      {fetchEmployeesError && <Alert severity="error">Error fetching employees</Alert>}
      <Typography variant="h5">Search for an Employee</Typography>
      <Stack>
        <Grid2 container spacing={2}>
          <Stack width="100%">
            <Grid2 xs={12}>
              <TextField
                id="BusinessSearch"
                fullWidth
                label="Employee search"
                onChange={(e) => setNameString(e.target.value)}
                onKeyDown={handleEnterPress}
                placeholder="Enter Employee Name"
                type="text"
                value={nameString}
                variant="outlined"
              />
            </Grid2>

            <Stack direction="row" gap={2} justifyContent="end">
              <Button onClick={clearFilters} variant="text">
                Clear all filters
              </Button>
              <Button onClick={filter} variant="contained">
                FILTER
              </Button>
            </Stack>
          </Stack>
          <EmployeesGrid
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            rows={employees}
            total={totalEmployeeCount}
          />
        </Grid2>
      </Stack>
    </Stack>
  );
}
