import qs from 'qs';

import { request } from '~/services/common';

function getBaseURL() {
  return window.configs.API_HOST + '/api';
}

export async function searchSentEmails({
  email,
  key,
  limit,
  offset,
}: {
  email: string;
  key: string;
  limit: number;
  offset: number;
}) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/notify/info?`;

  const queryParams = {
    email,
    key,
    limit,
    offset,
  };

  const emails = await request(`${url}${qs.stringify(queryParams)}`);
  return emails;
}

export async function resendEmail({ id, email }: { id: string; email: string }) {
  return request(`${getBaseURL()}/notify/resend`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, email }),
  });
}

export async function getSentEmail({ id }: { id: string }) {
  return request(`${getBaseURL()}/notify/render-email-v2?id=${id}`, { renderHtml: true });
}

export async function getQuoteEmail(key: string) {
  const queryParams = qs.stringify({
    key,
    type: 'quote_automation',
  });

  return request(`${getBaseURL()}/notify/log?${queryParams}`);
}
