import React from 'react';

import SegmentCellGroup from '~/components/Content/SearchRanking/Segment/SegmentCellGroup';

interface Props {
  segments: App.Segment[];
  segmentedScores: App.SegmentedOfferScore[];
}

function SegmentCellGroups({ segments, segmentedScores }: Props) {
  return (
    <>
      {segments.map((segment, idx) => {
        const backgroundColor = idx % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'rgba(0, 0, 0, 0.04)';
        const segmentValue = segment.value.split(':')[1];
        const segmentScore = segmentedScores.find((s) => s.segment === segmentValue);
        return <SegmentCellGroup key={segmentValue} segmentScore={segmentScore} backgroundColor={backgroundColor} />;
      })}
    </>
  );
}

export default SegmentCellGroups;
