import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { downloadVistaraReport } from '~/services/ReportingService';
import { formatDateISO } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter your date to export the Vistara report',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportVistaraReportForm() {
  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);

  const handleSubmit = async (event: IChangeEvent) => {
    setProcessing(true);

    const { startAt = formatDateISO(), endAt = formatDateISO() } = event.formData;

    try {
      const data = await downloadVistaraReport({ startAt, endAt });
      const fileName = `vistara_report_${startAt}_${endAt}.xlsx`;

      fileDownload(data, fileName);
    } catch (err) {
      setError(err.message);
    }

    setProcessing(false);
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained" disabled={isProcessing}>
        Download Vistara Report
      </Button>

      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
