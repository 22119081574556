import React from 'react';

import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { TOUR_OFFER_SELLING_REGION_OPTIONS } from '~/consts/tour';

interface Props {
  value: string;
  onChange: (newValue?: string) => void;
}

export default function TourOfferSellingRegionInput(props: Props) {
  const { value, onChange } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>Selling Region</InputLabel>
      <Select value={value} label="Selling Region" onChange={(e) => onChange(e.target.value)}>
        <MenuItem value="any">Any</MenuItem>
        <Divider variant="middle" />
        {TOUR_OFFER_SELLING_REGION_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
