/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from '@luxuryescapes/lib-types';

import { isBetween } from '~/services/TimeService';

import { RESERVATION_TYPE_INSTANT_BOOKING } from '../consts/reservation';
import PublicOffersService from '../services/PublicOfferService';
import ReservationService from '../services/ReservationService';

export const ADD_OFFER = 'cart/ADD_OFFER';
export const REMOVE_OFFER = 'cart/REMOVE_OFFER';
export const REMOVE_OFFERS = 'cart/REMOVE_OFFERS';
export const SET_CUSTOMER = 'cart/SET_CUSTOMER';
export const EMPTY_CART = 'cart/EMPTY_CART';
export const PURCHASE_FAILURE = 'cart/PURCHASE_FAILURE';
export const ADD_OFFER_TO_CART = 'cart/ADD_OFFER_TO_CART';
export const ADD_GIFT_CARD_TO_CART = 'cart/ADD_GIFT_CARD_TO_CART';
export const ADD_ADDON_TO_CART = 'cart/ADD_ADDON_TO_CART';
export const ADD_FLIGHT_TO_CART = 'cart/ADD_FLIGHT_TO_CART';
export const ADD_BUSINESS_FLIGHT_TO_CART = 'cart/ADD_BUSINESS_FLIGHT_TO_CART';
export const ADD_PNR = 'cart/ADD_PNR';
export const ADD_BUSINESS_PNR = 'cart/ADD_BUSINESS_PNR';
export const ADD_TRAVELLER_TO_PNR = 'cart/ADD_TRAVELLER_TO_PNR';
export const ADD_BUSINESS_TRAVELLER_TO_PNR = 'cart/ADD_BUSINESS_TRAVELLER_TO_PNR';
export const UPDATE_GIFT_CARD_PRICE = 'cart/UPDATE_GIFT_CARD_PRICE';
export const UPDATE_PNR_ID = 'cart/UPDATE_PNR_ID';
export const UPDATE_TRAVELLER_DETAILS = 'cart/UPDATE_TRAVELLER_DETAILS';
export const SET_ORDER_ID = 'cart/SET_ORDER_ID';
export const SET_ITEM_ID = 'cart/SET_ITEM_ID';
export const SET_BUSINESS_ID = 'cart/SET_BUSINESS_ID';
export const REMOVE_OTHER_ITEM_FROM_CART = 'cart/REMOVE_OTHER_ITEM_FROM_CART';
export const REMOVE_ALL_ADDONS_FROM_CART = 'cart/REMOVE_ALL_ADDONS_FROM_CART';
export const REMOVE_PNR = 'cart/REMOVE_PNR';
export const REMOVE_TRAVELLER = 'cart/REMOVE_TRAVELLER';
export const REDUCE_OFFER_ITEM_QUANTITY = 'cart/REDUCE_OFFER_ITEM_QUANTITY';
export const REDUCE_GIFT_CARD_QUANTITY = 'cart/REDUCE_GIFT_CARD_QUANTITY';
export const REMOVE_ADDON_FROM_CART = 'cart/REMOVE_ADDON_FROM_CART';
export const CHANGE_CURRENCY_CODE = 'cart/CHANGE_CURRENCY_CODE';
export const APPLY_LE_CREDIT = 'cart/APPLY_LE_CREDIT';
export const UPDATE_CREDIT = 'cart/UPDATE_CREDIT';
export const APPLY_PROMO_CODE = 'cart/APPLY_PROMO_CODE';
export const SET_PROMO_CODE_ERROR = 'cart/SET_PROMO_CODE_ERROR';
export const SET_BOOK_DATES_FOR_ORDER = 'cart/SET_BOOK_DATES_FOR_ORDER';
export const UPDATE_RESERVATION = 'cart/UPDATE_RESERVATION';
export const UPDATE_STATE_OF_RESIDENCE = 'cart/UPDATE_STATE_OF_RESIDENCE';
export const TOGGLE_BOOKING_DATES = 'cart/TOGGLE_BOOKING_DATES';
export const TOGGLE_RESERVATION_TYPE = 'cart/TOGGLE_RESERVATION_TYPE';
export const RECEIVE_ENQUIRY = 'cart/RECEIVE_ENQUIRY';
export const START_ENQUIRY = 'cart/START_ENQUIRY';
export const FINISH_ENQUIRY = 'cart/FINISH_ENQUIRY';
export const START_FETCH_ADDONS = 'cart/START_FETCH_ADDONS';
export const SUCCESS_FETCH_ADDONS = 'cart/SUCCESS_FETCH_ADDONS';
export const UPDATE_QUERY = 'cart/UPDATE_QUERY';
export const SET_RESERVATION_TYPE = 'cart/SET_RESERVATION_TYPE';
export const ALLOW_TOGGLE_RESERVATION_TYPE = 'cart/ALLOW_TOGGLE_RESERVATION_TYPE';
export const UPDATE_CONTACT_DETAILS = 'cart/UPDATE_CONTACT_DETAILS';
export const SELECT_INSURANCE_OPTION = 'cart/SELECT_INSURANCE_OPTION';
export const REMOVE_INSURANCE_OPTION = 'cart/REMOVE_INSURANCE_OPTION';
export const UPDATE_ORDER_NOTES = 'cart/UPDATE_ORDER_NOTES';
export const ADD_RESERVATION_FX_RATES_TO_CART = 'cart/ADD_RESERVATION_FX_RATES_TO_CART';

interface JustAddOfferToCartProps {
  pkg: App.Package;
  currencyCode: string;
  offer: App.Offer;
  orderItem: App.CartItem;
}

export function emptyCart() {
  return {
    type: EMPTY_CART,
  };
}

export function purchaseFailure() {
  return {
    type: PURCHASE_FAILURE,
  };
}

export function justAddOfferToCart({ pkg, currencyCode, offer, orderItem }: JustAddOfferToCartProps) {
  return {
    type: ADD_OFFER_TO_CART,
    pkg,
    currencyCode,
    offer,
    orderItem,
  };
}

interface SuccessFetchAddonsAction {
  type: 'cart/SUCCESS_FETCH_ADDONS';
  addons: App.Addon[];
  offerId: string;
  packageId: string;
}

function startFetchingAddons() {
  return { type: START_FETCH_ADDONS };
}

function successFetchAddons({
  addons,
  offerId,
  packageId,
}: {
  addons: App.Addon[];
  offerId: string;
  packageId: string;
}): SuccessFetchAddonsAction {
  return { type: SUCCESS_FETCH_ADDONS, addons, offerId, packageId };
}

function ensureAddons(pkg: App.Package): ThunkAction<Promise<void>, never, never, AnyAction> {
  return async (dispatch) => {
    if (!pkg.addons) {
      await dispatch(fetchAddons(pkg));
    }
  };
}

function fetchAddons(pkg: App.Package): ThunkAction<Promise<SuccessFetchAddonsAction>, never, never, AnyAction> {
  return async (dispatch) => {
    dispatch(startFetchingAddons());

    const addons = await PublicOffersService.getAddons(pkg);
    const activeAddons = addons.filter((a) => isBetween(a.travel_from_date, a.travel_to_date) && !a.inactive);

    return dispatch(
      successFetchAddons({
        addons: activeAddons,
        offerId: pkg.offer_id_salesforce_external,
        packageId: pkg.id_salesforce_external ?? pkg.le_package_id,
      }),
    );
  };
}

function addComplimentaryAddonsToCart({
  pkg,
  offer,
}: {
  pkg: App.CartPackage;
  offer: App.CartOffer;
}): ThunkAction<void, never, never, AnyAction> {
  return (dispatch) => {
    const item = (offer.items as App.CartItem[]).find((i) => i.pkg.unique_key === pkg.unique_key);

    pkg.addons
      .filter((addon) => addon.complimentary)
      .forEach((addon) => {
        dispatch(addAddonToCart(addon, item, offer, pkg));
      });
  };
}

export function addReservationFXRatesToCart({ reservationFXRates }) {
  return {
    type: ADD_RESERVATION_FX_RATES_TO_CART,
    reservationFXRates,
  };
}

interface AddOfferToCartProps {
  pkg: App.Package;
  currencyCode: string;
  offer: App.Offer | App.CartOffer;
  orderItem?: App.CartItem;
}

// TODO: Rename to addPackageToCart
export function addOfferToCart({
  pkg,
  currencyCode,
  offer,
  orderItem,
}: AddOfferToCartProps): ThunkAction<Promise<void>, App.State, never, AnyAction> {
  return async (dispatch, getState) => {
    dispatch(justAddOfferToCart({ pkg, currencyCode, offer, orderItem }));

    await dispatch(ensureAddons(pkg));

    const cartOffer = getState().cart.offers.find((o) => o.id_salesforce_external === offer.id_salesforce_external);

    dispatch(addComplimentaryAddonsToCart({ pkg, offer: cartOffer }));
  };
}

export function addAddonToCart(
  addon: App.Addon,
  item: App.CartItem,
  offer?: App.CartOffer,
  offerPackage?: App.CartPackage,
) {
  return {
    type: ADD_ADDON_TO_CART,
    addon,
    item,
    offer,
    offerPackage,
  };
}

export function addGiftCardToCart(price: number, currencyCode: string) {
  return {
    type: ADD_GIFT_CARD_TO_CART,
    price,
    currencyCode,
  };
}

export function addFlightToCart() {
  return {
    type: ADD_FLIGHT_TO_CART,
  };
}

export function addBusinessFlightToCart() {
  return {
    type: ADD_BUSINESS_FLIGHT_TO_CART,
  };
}

export function addPnr() {
  return {
    type: ADD_PNR,
  };
}

export function addBusinessPnr() {
  return {
    type: ADD_BUSINESS_PNR,
  };
}

export function addTravellerToPNR(pnrIndex) {
  return {
    type: ADD_TRAVELLER_TO_PNR,
    pnrIndex,
  };
}

export function addBusinessTravellerToPNR(pnrIndex) {
  return {
    type: ADD_BUSINESS_TRAVELLER_TO_PNR,
    pnrIndex,
  };
}

export function updateGiftCardPrice(oldPrice, newPrice) {
  return {
    type: UPDATE_GIFT_CARD_PRICE,
    oldPrice,
    newPrice,
  };
}

export function updatePNRId(pnrIndex, PNR) {
  return {
    type: UPDATE_PNR_ID,
    pnrIndex,
    PNR,
  };
}

export function updateTravellerDetails(pnrIndex, travellerIndex, travellerDetails) {
  return {
    type: UPDATE_TRAVELLER_DETAILS,
    pnrIndex,
    travellerIndex,
    travellerDetails,
  };
}

export function setOrderId(orderId: string) {
  return {
    type: SET_ORDER_ID,
    orderId,
  };
}

// setItemId is an action creator for setting itemId which is used for change
// dates workflow
export function setItemId(itemId: string) {
  return {
    type: SET_ITEM_ID,
    itemId,
  };
}

export function setBusinessId(businessId: string) {
  return {
    type: SET_BUSINESS_ID,
    businessId,
  };
}

export function addOffer(offer: App.Offer) {
  return {
    type: ADD_OFFER,
    offer: offer,
  };
}

interface RemoveOfferProps {
  offerId: string;
  offerCartId: number | string;
}

export function removeOffer({ offerId, offerCartId }: RemoveOfferProps) {
  return {
    type: REMOVE_OFFER,
    offerId,
    offerCartId,
  };
}

export function removeOffers(offerId: string) {
  return {
    type: REMOVE_OFFERS,
    offerId,
  };
}

export function setCustomer(customer: App.User) {
  return {
    type: SET_CUSTOMER,
    customer,
  };
}

// Remove 'other' item e.g. giftcard
export function removeFromCart(otherItem) {
  return {
    type: REMOVE_OTHER_ITEM_FROM_CART,
    item: otherItem,
  };
}

export function removePnr(pnrIndex) {
  return {
    type: REMOVE_PNR,
    pnrIndex,
  };
}

// REDUCE_OFFER_ITEM_QUANTITY removes an item from the cart by package id, thus effectively
// reducing the quantity of the items for that package in the cart
export function reduceOfferItemQuantity(packageId: string, offer: App.CartOffer) {
  return (dispatch, getState) => {
    const item = getState()
      .cart.offers.find(
        (o) => o.id_salesforce_external === offer.id_salesforce_external && o.offerCartId === offer.offerCartId,
      )
      .items.find((item) => item.pkg.unique_key === packageId);

    item.pkg.addons &&
      item.pkg.addons
        .filter((addon) => addon.complimentary)
        .forEach(() => {
          dispatch(
            removeAddonFromCart({
              offerId: offer.id_salesforce_external,
            }),
          );
        });

    dispatch({
      type: REDUCE_OFFER_ITEM_QUANTITY,
      packageId,
      offer,
    });
  };
}

interface RemoveAddonFromCartProps {
  addonID?: string;
  offerId: string;
  offerCartId?: string;
}

export function removeAddonFromCart({ addonID, offerId, offerCartId }: RemoveAddonFromCartProps) {
  return {
    type: REMOVE_ADDON_FROM_CART,
    addonID,
    offerId,
    offerCartId,
  };
}

export function removeTravellerFromPNR(pnrIndex, travellerIndex) {
  return {
    type: REMOVE_TRAVELLER,
    pnrIndex,
    travellerIndex,
  };
}

export function selectInsuranceOption(quoteResult) {
  return {
    type: SELECT_INSURANCE_OPTION,
    ...quoteResult,
  };
}

export function removeInsuranceOption() {
  return {
    type: REMOVE_INSURANCE_OPTION,
  };
}

export function reduceGiftCardQuantity(price: number) {
  return {
    type: REDUCE_GIFT_CARD_QUANTITY,
    price,
  };
}

export function updateContactDetails({ phonePrefix, phone }) {
  return {
    type: UPDATE_CONTACT_DETAILS,
    phonePrefix,
    phone,
  };
}

export function changeCurrencyCode(currencyCode: string, regionCode: string) {
  return {
    type: CHANGE_CURRENCY_CODE,
    currencyCode,
    regionCode,
  };
}

export function applyLeCredit(creditPaymentAmount) {
  return {
    type: APPLY_LE_CREDIT,
    creditPaymentAmount,
  };
}

export function updateCredit(currencyCode: string, balance, error) {
  return {
    type: UPDATE_CREDIT,
    currencyCode,
    balance,
    error,
  };
}

export function applyPromoCode(promoDetails) {
  return {
    type: APPLY_PROMO_CODE,
    promoDetails,
  };
}

export function setPromoCodeError(message) {
  return {
    type: SET_PROMO_CODE_ERROR,
    message,
  };
}

export function setBookDatesForOrder(orderId) {
  return {
    type: SET_BOOK_DATES_FOR_ORDER,
    orderId,
  };
}

export function updateReservation(itemId, reservationData, offer): ThunkAction<any, any, any, any> {
  return async (dispatch, getState) => {
    const state = getState();

    // BAD! TERRIBLY BAD! Never do side effects in the action creators
    // TODO: REMOVE THIS!
    if (!state.reservationFXRates) {
      const rates = await ReservationService.getFXRates();
      dispatch(
        addReservationFXRatesToCart({
          reservationFXRates: rates.result,
        }),
      );
    }

    dispatch({
      type: UPDATE_RESERVATION,
      itemId,
      reservationData,
      offer,
    });
  };
}

export function updateStateOfResidence(stateOfResidence) {
  return {
    type: UPDATE_STATE_OF_RESIDENCE,
    stateOfResidence,
  };
}

export function toggleBookingDates(item, offer, isBookingDates = true) {
  return {
    type: TOGGLE_BOOKING_DATES,
    item,
    offer,
    isBookingDates,
  };
}

export function setReservationType(reservationType: string) {
  return {
    type: SET_RESERVATION_TYPE,
    reservationType,
  };
}

export function justToggleReservationType(offer: App.Offer) {
  return {
    type: TOGGLE_RESERVATION_TYPE,
    offer,
  };
}

export function toggleReservationType() {
  return (dispatch, getState) => {
    const { cart } = getState();
    const { offers } = cart;
    offers.forEach((offer) => {
      offer.items.forEach((item) => {
        if (item.reservation.reservationType === RESERVATION_TYPE_INSTANT_BOOKING) {
          dispatch(removeAllAddonsFromCart());
        } else {
          item.pkg.addons
            .filter((addon) => addon.complimentary)
            .forEach((addon) => {
              dispatch(addAddonToCart(addon, item));
            });
        }
      });
      dispatch(justToggleReservationType(offer));
    });
  };
}

function removeAllAddonsFromCart() {
  return {
    type: REMOVE_ALL_ADDONS_FROM_CART,
  };
}

export function allowToggleReservationType(allow) {
  return {
    type: ALLOW_TOGGLE_RESERVATION_TYPE,
    allowToggleReservationType: allow,
  };
}

function startEnquiry(itemId: string, offer: App.Offer) {
  return {
    type: START_ENQUIRY,
    itemId,
    offer,
  };
}

function finishEnquiry({ itemId, errMessage = null, offer, dates = null }) {
  return {
    type: FINISH_ENQUIRY,
    itemId,
    errMessage,
    offer,
    dates,
  };
}

function receiveEnquiry(itemId: string, dates: API.Reservation.EnquiryRate[], offer: App.Offer) {
  return {
    type: RECEIVE_ENQUIRY,
    itemId,
    dates,
    offer,
  };
}

// enquireReservation is a generic action creator for enquiring and subsequently
// receiving available reservation dates
function enquireReservation(enquiryPromise: Promise<API.Reservation.EnquiryRate[]>, itemId: string, offer: App.Offer) {
  return (dispatch) => {
    dispatch(startEnquiry(itemId, offer));

    return enquiryPromise
      .then((dates) => {
        dispatch(receiveEnquiry(itemId, dates, offer));
        dispatch(finishEnquiry({ itemId, offer, dates }));
      })
      .catch((err) => {
        dispatch(finishEnquiry({ itemId, errMessage: err.message, offer }));
      });
  };
}

// enquireHotelReservation queries available dates for hotel reservation and
// receives the result
export function enquireHotelReservation(
  itemId: string,
  propertyId: string,
  roomTypeId: string,
  roomRateId: string,
  queryObj: unknown,
  offer: App.Offer,
) {
  return (dispatch) => {
    const enquiryPromise = ReservationService.enquireHotelReservation(propertyId, roomTypeId, roomRateId, queryObj);

    return dispatch(enquireReservation(enquiryPromise, itemId, offer));
  };
}

// enquireHotelReservation queries available date ranges for tour reservation
// and receives the result
export function enquireTourReservation(
  itemId: string,
  tourId: string,
  tourOptionId: string,
  queryObj: unknown,
  offer: App.Offer,
) {
  return (dispatch) => {
    const enquiryPromise = ReservationService.enquireTourReservation(tourId, tourOptionId, queryObj);

    return dispatch(enquireReservation(enquiryPromise, itemId, offer));
  };
}

// initCart initializes cart with the given data. It is used to initialize the
// cart for changing dates or choosing dates
export function initCart(
  offer: App.Offer,
  offerPackage: App.Package,
  customer: App.User,
  currencyCode: string,
  item: App.CartItem,
) {
  return (dispatch) => {
    dispatch(setCustomer(customer));
    dispatch(addOffer(offer));
    dispatch(
      addOfferToCart({
        pkg: offerPackage,
        currencyCode,
        offer: { ...offer, offerCartId: 0 } as App.CartOffer,
        orderItem: item,
      }),
    );
  };
}

export function updateOrderNotes(text: string) {
  return {
    type: UPDATE_ORDER_NOTES,
    notes: text,
  };
}
