import React, { useCallback, useMemo } from 'react';

import BookingsService from '../../services/BookingsService';
import Asinc from '../Common/Asinc';

import { BookingBox } from './BookingBox';

export function BookingPage(props) {
  const idBooking = useMemo(() => {
    const { match } = props;

    return match.params.id_orders;
  }, [props]);

  const fetchData = useCallback(() => {
    return BookingsService.getBooking(idBooking);
  }, [idBooking]);
  return (
    <div className="container">
      <Asinc promise={fetchData()} then={(promise_results) => <BookingBox booking={promise_results} />} />
    </div>
  );
}
