import { selectTenant } from '~/actions/tenant';

import { useAppDispatch, useAppSelector } from './store';

export default function useCurrentTenant() {
  const dispatch = useAppDispatch();

  const onTenantSelect = (tenant: string) => {
    localStorage.setItem('tenant', tenant);
    dispatch(selectTenant(tenant));
  };

  return {
    tenant: useAppSelector((state) => state.tenant),
    onTenantSelect,
  };
}
