import React, { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { removeTravellerFromPNR, updateTravellerDetails } from '~/actions/cart';

import DateWidget from '~/components/Common/Elements/DateWidget';

import { formatDateNumeralMonths } from '~/services/TimeService';

import {
  AIRLINE_FEE_TYPE,
  AIRLINE_FEE_TYPE_OTHER_FEE,
  FLIGHT_ORDER_TYPE,
  FLIGHT_TYPE,
  SERVICE_FEE_TYPE,
  TRAVELLER_TYPES,
} from '../../../../../consts/flight';

interface Props {
  pnr: App.OfflineFlightPnr;
  traveller: App.OfflineFlightTraveller;
  travellerIndex: number;
  pnrIndex: number;
  onRemoveTravellerFromPNR: Utils.ActionDispatcher<typeof removeTravellerFromPNR>;
  onUpdateTravellerDetails: Utils.ActionDispatcher<typeof updateTravellerDetails>;
}

function BusinessTravellerInformation(props: Props) {
  const { pnr, traveller, travellerIndex, pnrIndex, onRemoveTravellerFromPNR, onUpdateTravellerDetails } = props;

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onUpdateTravellerDetails(pnrIndex, travellerIndex, {
        [event.target.name]: event.target.value,
      });
    },
    [onUpdateTravellerDetails, pnrIndex, travellerIndex],
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      onUpdateTravellerDetails(pnrIndex, travellerIndex, {
        [event.target.name]: event.target.value,
      });
    },
    [onUpdateTravellerDetails, pnrIndex, travellerIndex],
  );

  const handleDateChange = useCallback(
    (name: string, value: string) => {
      onUpdateTravellerDetails(pnrIndex, travellerIndex, {
        [name]: formatDateNumeralMonths(value),
      });
    },
    [onUpdateTravellerDetails, pnrIndex, travellerIndex],
  );

  const handlePriceChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onUpdateTravellerDetails(pnrIndex, travellerIndex, {
        [event.target.name]: event.target.value,
      });

      const price = (+traveller.fareAndTaxes ?? 0) + (+traveller.airlineFee ?? 0) + (+traveller.serviceFee ?? 0);

      onUpdateTravellerDetails(pnrIndex, travellerIndex, {
        price,
      });
    },
    [
      onUpdateTravellerDetails,
      pnrIndex,
      traveller.airlineFee,
      traveller.fareAndTaxes,
      traveller.serviceFee,
      travellerIndex,
    ],
  );

  const handleRemoveTravellerClick = useCallback(() => {
    if (pnr.travellers.length === 1) {
      return;
    }
    onRemoveTravellerFromPNR(pnrIndex, travellerIndex);
  }, [onRemoveTravellerFromPNR, pnr.travellers.length, pnrIndex, travellerIndex]);

  return (
    <Stack
      direction="column"
      gap={2}
      padding={2}
      className="booking-form" // Required for e2e tests
      sx={{ border: 1, borderColor: 'grey.300', mb: 2 }}
    >
      <Typography variant="h6">Passenger details</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="title"
              name="title"
              label="Title"
              value={traveller.title}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="typeLabel">Type</InputLabel>
            <Select
              labelId="typeLabel"
              id="type"
              name="type"
              label="Type"
              value={traveller.type}
              onChange={handleSelectChange}
            >
              {TRAVELLER_TYPES.map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="firstName"
              name="firstName"
              label="Firstname"
              value={traveller.firstName}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="middleName"
              name="middleName"
              label="Middlename (optional)"
              value={traveller.middleName}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="lastName"
              name="lastName"
              label="Lastname"
              value={traveller.lastName}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Typography variant="h6">Flight details</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="orderTypeLabel">Order type</InputLabel>
            <Select
              labelId="orderTypeLabel"
              id="orderType"
              name="orderType"
              label="order Type"
              value={traveller.orderType}
              onChange={handleSelectChange}
            >
              {FLIGHT_ORDER_TYPE.map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="flightTypeLabel">Flight type</InputLabel>
            <Select
              labelId="flightTypeLabel"
              id="flightType"
              name="flightType"
              label="Flight Type"
              value={traveller.flightType}
              onChange={handleSelectChange}
            >
              {FLIGHT_TYPE.map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <DateWidget
              label="Departure date"
              onChange={(value: string) => handleDateChange('departureDate', value)}
              value={traveller.departureDate}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <DateWidget
              label="Return date"
              onChange={(value: string) => handleDateChange('returnDate', value)}
              value={traveller.returnDate}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="departureRoute"
              name="departureRoute"
              label="Departure route"
              value={traveller.departureRoute}
              onChange={handleInputChange}
              helperText="Sydney -> Melbourne"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="returnRoute"
              name="returnRoute"
              label="Return route"
              value={traveller.returnRoute}
              onChange={handleInputChange}
              helperText="Melbourne -> Sydney"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Typography variant="h6">Price details</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <TextField
              id="fareAndTaxes"
              name="fareAndTaxes"
              label="Fare and taxes"
              type="number"
              value={traveller.fareAndTaxes}
              onChange={handlePriceChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction="row">
            <FormControl fullWidth>
              <InputLabel id="airlineFeeType">Airline fee type</InputLabel>
              <Select
                labelId="airlineFeeType"
                id="airlineFeeType"
                name="airlineFeeType"
                label="Airline fee type"
                value={traveller.airlineFeeType}
                onChange={handleSelectChange}
              >
                {AIRLINE_FEE_TYPE.map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="airlineFee"
                name="airlineFee"
                label="Airline fee"
                type="number"
                value={traveller.airlineFee}
                onChange={handlePriceChange}
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction="row">
            <FormControl fullWidth>
              <InputLabel id="serviceFeeType">Service fee type</InputLabel>
              <Select
                labelId="serviceFeeType"
                id="serviceFeeType"
                name="serviceFeeType"
                label="Service fee type"
                value={traveller.serviceFeeType}
                onChange={handleSelectChange}
              >
                {SERVICE_FEE_TYPE.map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="serviceFee"
                name="serviceFee"
                label="Service fee"
                type="number"
                value={traveller.serviceFee}
                onChange={handlePriceChange}
              />
            </FormControl>
          </Stack>
        </Grid>

        {traveller.airlineFeeType === AIRLINE_FEE_TYPE_OTHER_FEE && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                id="airlineFeeDescription"
                name="airlineFeeDescription"
                label="Airline fee description"
                value={traveller.airlineFeeDescription}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <Stack direction="row">
            <Typography variant="h6">Total price:</Typography>
            <Typography variant="h6">{traveller.price}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              size="small"
              disabled={pnr.travellers.length === 1}
              onClick={handleRemoveTravellerClick}
              startIcon={<DeleteIcon />}
            >
              Remove Traveller
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default BusinessTravellerInformation;
