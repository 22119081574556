import React from 'react';

import Item from './Item';

interface Props {
  order: App.Order;
}

const ItemList = ({ order }: Props) => {
  return (
    <div className="T-Order-Item-List">
      {order.gift_card_items.map((item) => (
        <Item key={item.id} giftCardItem={item} order={order} />
      ))}
    </div>
  );
};

export default ItemList;
