export function addValuesToSchema(field, parent, schema) {
  const values = parent.reduce(
    (acc, value) => {
      acc.enum.push(value.id);
      acc.enumNames.push(value.name);

      return acc;
    },
    {
      enum: [],
      enumNames: [],
    },
  );

  return {
    ...schema,
    properties: {
      ...schema.properties,
      [field]: {
        ...schema.properties[field],
        enum: values.enum,
        enumNames: values.enumNames,
      },
    },
  };
}
