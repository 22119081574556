import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

import { Order } from '@luxuryescapes/contract-svc-order';
import { formatDate } from '@luxuryescapes/lib-refunds';

import { getDailyFxRates } from '~/services/ReportingService';
import { getFxRates } from '~/services/fxService';

export const finePrintToString = (finePrint: FinePrint): string => {
  return `#### Check-in
  Check-in starts at ${finePrint.checkIn?.beginTime}.
  Check-in ends at ${finePrint.checkIn?.endTime}.
  
  #### Check-out
  Check-out before at ${finePrint.checkOut?.time}
  
  #### Special Instructions
  
  ${finePrint.checkIn?.instructions}
  
  #### Special check-in instructions
  
  ${finePrint.checkIn?.specialInstructions}
  
  ${finePrint.pets ? '#### Pets' : ''}
  ${finePrint.pets.join('\n')}
  
  #### Fees
  ${finePrint.fees?.mandatory ? '##### Mandatory' : ''}
  ${finePrint.fees?.mandatory ? finePrint.fees?.mandatory : ''}
  ${finePrint.fees?.optional ? '##### Optional' : ''}
  ${finePrint.fees?.optional ? finePrint.fees?.optional : ''}
  
  #### Know Before You Go
  ${finePrint.policies?.knowBeforeYouGo}
  `;
};

export const combinePolicies = (cancellation: string, finePrint: string): string => {
  return `### Cancellation Policies
${cancellation}
### Fine Print
${finePrint}
`;
};

export const combineDescription = (
  roomType: string,
  description: string,
  specialRequests = '',
  facilities = [],
  promotionInclusions = '',
): string => {
  const hasInclusions = facilities.length > 0 || promotionInclusions;
  return `### Room Type
${roomType}
### Description
${description}
${hasInclusions && `### Inclusions`}
${promotionInclusions ? `${promotionInclusions}\n` : ''}
${facilities.length > 0 ? `- ${facilities.join('\n- ')}` : ''}
${specialRequests !== '' ? `### Special Requests\n${specialRequests}` : ''}
`;
};

export const bedbankCancellationToString = (room: Order.BedbankItemRoom, propertyTimezone: string): string => {
  const cancellationPolicy = room.cancellation_policies[0];
  if (room.refundable) {
    return `Free cancellation until ${formatDate.formattedDateByTimezone(
      cancellationPolicy.start,
      propertyTimezone,
    )} (local time of property)`;
  } else if (room.partially_refundable) {
    return `Partially Refundable`;
  } else {
    return `Non-refundable`;
  }
};

export const lpcCancellationToString = (cancellationPolicy: App.CancellationPolicy): string => {
  if (cancellationPolicy.cancellation_policy_details) {
    return `${cancellationPolicy.cancellation_policy_details.join('\n')}`;
  }
  return `${cancellationPolicy.cancellation_policy}`;
};

export const setUpTravellers = (handleAddNewTraveller, reservation) => {
  ['adult', 'child', 'infant'].forEach((type) => {
    const count = reservation[`number_of_${type === 'child' ? 'children' : `${type}s`}`];
    // minus 1 due to the default adult being there
    for (let i = 0; i < (type === 'adult' ? count - 1 : count); i++) {
      handleAddNewTraveller(type);
    }
  });
};

export const getBedbankCancellationDate = (room: Order.BedbankItemRoom, propertyTimezone: string) => {
  const cancellationPolicy = room.cancellation_policies[0];
  if (cancellationPolicy && cancellationPolicy.start) {
    try {
      const dateForConverting = new Date(cancellationPolicy.start);
      const convertedDate = formatDate.convertDateByTimezone(dateForConverting, propertyTimezone);
      return `${convertedDate.getFullYear()}-${convertedDate.getMonth() + 1}-${convertedDate.getDate()}`;
    } catch (err) {
      throw new Error(`Could not parse date: ${cancellationPolicy.start} - ${err.message}`);
    }
  }
  return null;
};

export interface CurrencyDict {
  [key: string]: number;
}

export const fetchSpotAndContractedRates = async (
  currencies: string[],
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
) => {
  const dailyRatesResponse = await getDailyFxRates({
    currency_from: 'AUD',
  });
  const contractedRatesResponse = await getFxRates();
  const customerFxRate = {};
  const contractFxRate = {};

  currencies.forEach((currency) => {
    const dailyRate = dailyRatesResponse.find((fxRate) => {
      return fxRate.currencyTo === currency;
    });
    const contractRate = contractedRatesResponse.find((fxRate) => {
      return fxRate.currency === currency;
    });

    if (dailyRate || contractRate) {
      // if a rate for a currency is missing, fall back to an existing value
      // if they are all missing for a currency, show an error
      contractFxRate[currency] = contractRate?.rate || dailyRate.rate;
      // For customer, we always want the highest rate as vendor is fixed
      customerFxRate[currency] = Math.max(dailyRate.rate, contractRate.rate);
    } else {
      enqueueSnackbar(`Exchange rate not found for ${currency}`, { variant: 'error' });
    }
  });

  return {
    customerFxRate,
    contractFxRate,
  };
};
