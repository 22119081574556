import React, { useCallback, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

interface Props {
  maxRefundAmount: number;
  currencyCode: string;
  formRef: any;
  handleLateChangeOfMindFieldChange: any;
}

export default function CustomAmount({
  maxRefundAmount,
  currencyCode,
  formRef,
  handleLateChangeOfMindFieldChange,
}: Props) {
  const {
    isToggled: isPercentageToolVisible,
    toggleOn: showPercentageTool,
    toggleOff: hidePercentageTool,
  } = useToggleState();

  const [refundToCustomer, setRefundToCustomer] = useState(maxRefundAmount);
  const [refundPercentage, setRefundPercentage] = useState(100);

  const handleRefundToCustomerChange = useCallback(
    (event) => {
      const formData = new FormData(formRef.current);
      let recalculatedPercentage = parseFloat(Number((event.target.value / maxRefundAmount) * 100).toFixed(2));
      if (recalculatedPercentage > 100 || event.target.value > maxRefundAmount) {
        recalculatedPercentage = 100;
        setRefundToCustomer(maxRefundAmount);
        formData.set('percentageRefundToCustomer', maxRefundAmount.toString());
      } else {
        setRefundToCustomer(event.target.value);
        formData.set('percentageRefundToCustomer', event.target.value);
      }
      setRefundPercentage(recalculatedPercentage);

      formData.set('percentageRefund', recalculatedPercentage.toString());
      handleLateChangeOfMindFieldChange(formData);
    },
    [formRef, handleLateChangeOfMindFieldChange, maxRefundAmount],
  );

  const handleRefundPercentageChange = useCallback(
    (event) => {
      let newPercentage = event.target.value;
      if (newPercentage > 100) {
        newPercentage = 100;
      } else if (newPercentage < 0) {
        newPercentage = 0;
      }
      setRefundPercentage(newPercentage);
      const recalculatedAmountToCustomer = ((maxRefundAmount / 100) * newPercentage).toFixed(2);
      setRefundToCustomer(Number(recalculatedAmountToCustomer));

      const formData = new FormData(formRef.current);
      // update this here as the state won't update
      formData.set('percentageRefundToCustomer', recalculatedAmountToCustomer);
      formData.set('percentageRefund', newPercentage);
      handleLateChangeOfMindFieldChange(formData);
    },
    [formRef, handleLateChangeOfMindFieldChange, maxRefundAmount],
  );

  return (
    <Box marginTop={5}>
      <Stack direction="row" spacing={4} marginBottom={3}>
        <TextField
          data-cy="RefundToCustomerAmount"
          defaultValue={0}
          InputLabelProps={{ shrink: !!refundToCustomer || undefined }}
          label="Refund to Customer"
          name="percentageRefundToCustomer"
          onChange={handleRefundToCustomerChange}
          placeholder="Enter amount"
          required
          type="number"
          value={refundToCustomer}
        />
        {!isPercentageToolVisible && (
          <Button onClick={showPercentageTool} data-cy="percentage_tool">
            Use percentage tool
          </Button>
        )}
        {isPercentageToolVisible && (
          <Stack direction="row">
            <IconButton onClick={hidePercentageTool}>
              <CancelIcon />
            </IconButton>
            <Box maxWidth="120px">
              <TextField
                label="% of sell price"
                type="number"
                name="percentageRefund"
                data-cy="% of total"
                value={refundPercentage}
                onChange={handleRefundPercentageChange}
                required
                defaultValue={0}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
