import React, { Component } from 'react';

import classNames from 'clsx';

import Calendar from '~/components/Common/Calendar';

import ReservationService from '~/services/ReservationService';
import {
  dateNowUtc,
  datesHaveSameDayMonthYear,
  formatDateDay,
  formatDateISO,
  isAfter,
  unix,
} from '~/services/TimeService';

import AriLogs from './AriLogs';
import { dateHasSurcharge, getAvailabilityForDate, getBlackoutRestrictionTypeForDate } from './helpers/availability';
import getCalendarGrid from './helpers/getCalendarGrid';

function isSameOrBefore(date1, date2) {
  return datesHaveSameDayMonthYear(date1, date2) || isAfter(date2, date1);
}

class AvailabilityCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      ariLogsDate: null,
      ariLogs: null,
    };
  }

  availabilityCompare = (a, b) => {
    return unix(a.range[0]) - unix(b.range[0]);
  };

  firstDay = () => {
    const earliestBlock = this.props.availability.sort(this.availabilityCompare)[0];
    return dateNowUtc(earliestBlock.range[0]);
  };

  lastDay = () => {
    const latestBlock = this.props.availability.sort(this.availabilityCompare).slice(-1)[0];
    return dateNowUtc(latestBlock.range[1]);
  };

  showingLastDay = (grid) => {
    const lastDayOfAvailability = this.lastDay();
    const lastMonthInGrid = grid.slice(-1)[0];
    const lastWeekInGrid = lastMonthInGrid.weeks.slice(-1)[0];
    const lastDayInGrid = lastWeekInGrid.filter((dayDate) => dayDate.day).slice(-1)[0];
    return isSameOrBefore(lastDayOfAvailability, lastDayInGrid.day);
  };

  getGrid = () => getCalendarGrid(this.firstDay(), 2, this.state.offset);

  showNextMonth = () => {
    this.setState((prevState) => ({
      offset: prevState.offset + 1,
    }));
  };

  showPrevMonth = () => {
    this.setState((prevState) => ({
      offset: prevState.offset - 1,
    }));
  };

  getGridWithDates = () => {
    const self = this;
    return this.getGrid().map(function (month) {
      return {
        date: month.date,
        weeks: month.weeks.map(function (week) {
          return week.map(function (day) {
            return {
              day,
              availability: getAvailabilityForDate(day, self.props.availability),
              blackout: getBlackoutRestrictionTypeForDate(day, self.props.blackoutDates),
              surcharge: dateHasSurcharge(day, self.props.surchargeDates),
              rate: dateHasSurcharge(day, self.props.roomRateDates),
            };
          });
        }),
      };
    });
  };

  deactivateAriLogsPopup = () => {
    this.setState({
      ariLogsDate: null,
      ariLogs: null,
    });
  };

  calendarDayComponent = ({ dayDate, idx }) => {
    const activateAriLogsPopup = async () => {
      const day = formatDateISO(dayDate.day);

      this.setState({
        ariLogsDate: day,
      });

      try {
        const res = await ReservationService.getAriLogsByDate(
          this.props.propertyId,
          this.props.roomTypeId,
          this.props.roomRateId,
          day,
        );
        const ariLogs = res?.result ?? [];
        this.setState({
          ariLogs,
        });
      } catch (error) {
        if (error?.message?.includes('422')) {
          this.setState({
            ariLogs: 'NOT_SUPPORTED',
          });
        }
      }
    };

    const formatDay = (m) => formatDateDay(m);

    const BlackoutMarker = ({ blackout }) => {
      if (blackout === 'arrival') {
        return <sup className="blackout-marker">cta</sup>;
      }

      if (blackout === 'departure') {
        return <sup className="blackout-marker">ctd</sup>;
      }

      return null;
    };

    const soldOut = dayDate.availability && dayDate.availability.total === dayDate.availability.count;

    const noInvertory = !dayDate.blackout && (!dayDate.availability || !dayDate.availability.total);

    const availableByLOS =
      dayDate.availability && dayDate.availability.availableByLOS !== undefined
        ? dayDate.availability.availableByLOS
        : true;

    const classes = classNames('availability-day', {
      blackout: !!dayDate.blackout || (noInvertory && !soldOut),
      [`blackout-${dayDate.blackout}`]: !!dayDate.blackout || (noInvertory && !soldOut),
      surcharge: dayDate.surcharge,
      rate: dayDate.rate,
    });

    return (
      <div className={classes} key={idx} onClick={activateAriLogsPopup}>
        {dayDate.day && (
          <div className="date">
            {formatDay(dayDate.day)}
            {dayDate.surcharge && <sup className="surcharge-marker"> +</sup>}
            <BlackoutMarker blackout={dayDate.blackout} />
            {!availableByLOS && <sup className="blackout-marker">LOS</sup>}
          </div>
        )}
        {dayDate.day && noInvertory && (
          <span className="no-invertory" title="No Invertory">
            NI
          </span>
        )}
        {dayDate.availability && (
          <div className="counts">
            {dayDate.availability.count} / {dayDate.availability.total}
          </div>
        )}
        {dayDate.rate && !this.props.showWithoutTax && (
          <div className="rate">
            {dayDate.rate.cost_amount} {dayDate.rate.cost_currency}
          </div>
        )}
        {dayDate.rate && this.props.showWithoutTax && (
          <div className="rate">
            {dayDate.rate.cost_amount_exclusive_tax ? dayDate.rate.cost_amount_exclusive_tax : 'NA'}
            &nbsp;
            {dayDate.rate.cost_amount_exclusive_tax ? dayDate.rate.cost_currency : ''}
          </div>
        )}
      </div>
    );
  };

  render() {
    const grid = this.getGridWithDates();

    const shouldHidePrevMonthButton = this.state.offset <= 0;
    const shouldHideNextMonthButton = this.showingLastDay(grid);

    return (
      <div className="availability-calendar">
        <div className="buttons">
          <button disabled={shouldHidePrevMonthButton} onClick={this.showPrevMonth}>
            &lt;
          </button>
          <button disabled={shouldHideNextMonthButton} onClick={this.showNextMonth}>
            &gt;
          </button>
        </div>
        <Calendar grid={grid} calendarDayComponent={this.calendarDayComponent} />
        <AriLogs
          date={this.state.ariLogsDate}
          ariLogs={this.state.ariLogs}
          onHide={this.deactivateAriLogsPopup.bind(this)}
        />
      </div>
    );
  }
}

export default AvailabilityCalendar;
