import React from 'react';

import styled from 'styled-components';

import { Alert } from '@mui/material';

const Li = styled.li`
  word-wrap: break-word;
`;

export default class ErrorListDisplay extends React.Component {
  render() {
    const messages = this.props.messages ? this.props.messages : ['There was an error'];
    return (
      <Alert severity="error">
        <strong>{messages.length > 1 ? 'Errors' : 'Error'}</strong>
        <ul>
          {messages.map((message, i) => (
            <Li key={i}>{message}</Li>
          ))}
        </ul>
      </Alert>
    );
  }
}
