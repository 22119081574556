import React, { ComponentProps, useCallback, useEffect, useState } from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';

import { Button, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';

import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import ErrorListDisplay from '../ErrorListDisplay';

const uiSchema = {
  room_types_groups: {
    'ui:options': {
      orderable: false,
    },
    items: {
      id: { 'ui:widget': 'hidden' },
      room_types: {
        'ui:options': {
          orderable: false,
        },
      },
    },
  },
};

const getSchema = (schema) => {
  return {
    ...schema,
    properties: {
      ...schema.properties,
      room_types_groups: {
        ...schema.properties.room_types_groups,
        items: {
          ...schema.properties.room_types_groups.items,
          title: 'Room Group',
        },
      },
    },
  };
};

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Room Name', flex: 1, sortable: false, display: 'flex' },
  { field: 'code', headerName: 'Room ID', width: 300, sortable: false, display: 'flex' },
];

interface Props {
  propertyId: string;
  schema: any;
  roomTypesGroups: any;
  roomTypes: any;
}

interface RoomTypesGroups {
  room_types_groups: any;
}

export default function RoomTypesGroupsForm(props: Props) {
  const { propertyId, roomTypes } = props;
  const [roomTypesGroups, setRoomTypesGroups] = useState<RoomTypesGroups>({
    room_types_groups: props.roomTypesGroups,
  });
  const [schema, setSchema] = useState(getSchema(props.schema));
  const [saveState, setSaveState] = useState(buttonStates.default);
  const [apiErrors, setApiErrors] = useState([]);
  const [unAssignedRoomTypes, setUnAssignedRoomTypes] = useState([]);

  useEffect(() => {
    const assignedRoomTypes = roomTypesGroups.room_types_groups.reduce((acc, group) => {
      group?.room_types?.forEach((roomTypeId) => {
        acc.add(roomTypeId);
      });
      return acc;
    }, new Set());
    const unAssignedRoomTypes = roomTypes
      .filter((room) => !assignedRoomTypes.has(room.id))
      .map((room) => {
        return {
          id: room.id,
          name: room.name,
          code: room.room_type_code,
        };
      });
    setUnAssignedRoomTypes(unAssignedRoomTypes);
  }, [roomTypes, roomTypesGroups]);

  const handleSubmit = useCallback(
    async (form) => {
      setSaveState(buttonStates.saving);
      setRoomTypesGroups(form.formData);

      try {
        await ReservationService.updateRoomTypesGroups(propertyId, form.formData);

        setSaveState(buttonStates.saved);
        setApiErrors([]);
      } catch (error) {
        const apiErrors = [];
        if (error.name === 'ValidationError') {
          error.errors
            .filter((x) => x.path !== 'propertyId')
            .forEach((err) => {
              apiErrors.push(err.message);
            });
        }
        setSaveState(buttonStates.failed);
        setApiErrors(apiErrors);
        reportError(error);
      }
    },
    [propertyId],
  );

  const handleFormChange = (form): ComponentProps<typeof form>['onSubmit'] => {
    if (saveState !== buttonStates.default) {
      setSaveState(buttonStates.default);
      setApiErrors([]);
    }

    setRoomTypesGroups(form.formData);
  };

  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      formData={roomTypesGroups}
      onSubmit={handleSubmit}
      onChange={handleFormChange}
      validator={validator}
      showErrorList={false}
    >
      <Button variant="contained" type="submit" disabled={saveState === buttonStates.saving}>
        {buttonMessages[saveState]}
      </Button>

      {apiErrors.length > 0 && <ErrorListDisplay messages={apiErrors} />}

      <Typography variant="h5">Unassigned Rooms: {unAssignedRoomTypes.length}</Typography>
      <Grid container>
        <Grid lg={12}>
          <DataGrid
            columns={columns}
            rows={unAssignedRoomTypes || []}
            getRowId={(row) => row.id}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </Grid>
      </Grid>
    </Form>
  );
}
