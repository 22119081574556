import React from 'react';

import { Alert } from '@mui/material';

export default function SuccessMessage({ total, message }) {
  return (
    <Alert severity="success">
      <strong>Success!</strong>
      <p>{message ? message : 'File upload successful'}</p>
      {total && (
        <>
          <p>Total:</p>
          <ul>
            {Object.entries(total).map(([key, value], index) => (
              <li key={index}>
                {value} {key}
              </li>
            ))}
          </ul>
        </>
      )}
    </Alert>
  );
}
