import React from 'react';

import { Control, Controller } from 'react-hook-form';

import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';

type Props = {
  name: string;
  label?: string;
  control: Control<any>;
  disabled?: boolean;
  onChange?: (value: any) => void;
};

// important: intended to be used inside react hook form
function Checkbox({ name, label, control, onChange, disabled }: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          <FormControlLabel
            control={
              <MuiCheckbox
                {...field}
                onChange={onChange || field.onChange}
                checked={!!field.value}
                disabled={disabled}
              />
            }
            label={label}
          />
        </FormControl>
      )}
    />
  );
}

export default Checkbox;
