import React from 'react';

import VendorsService from '~/services/VendorsService';

import Asinc from '../../Common/Asinc';

import VendorsPage from './vendors/VendorsPage';

const ChainVendorsPageContainer = (props) => {
  const fetchProperties = () => {
    const req = VendorsService.getVendorsByChainId(props.parentId);
    return req;
  };

  return (
    <Asinc
      promise={fetchProperties()}
      then={(data) => {
        return <VendorsPage parentId={props.parentId} properties={data.result} />;
      }}
    />
  );
};

export default ChainVendorsPageContainer;
