import React from 'react';

import pluralize from 'pluralize';
import { findDOMNode } from 'react-dom';

import { canIncreaseAdults, canIncreaseChildren, canIncreaseInfants } from '../../../../utils/guestCount';

import Options from './Options';

export default class GuestPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false);
    document.addEventListener('touchend', this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  }

  close = () => {
    this.setState({ isOpen: false });
  };

  toggleDisplay = () => {
    const { disabled } = this.props;

    if (disabled) {
      return;
    }

    this.setState((prevState) => {
      return { isOpen: !prevState.isOpen };
    });
  };

  handleDocumentClick = (e) => {
    const pickerEl = findDOMNode(this);
    const targetEl = e.target;

    if (pickerEl === targetEl || pickerEl.contains(targetEl)) {
      return;
    }

    this.close();
  };

  handleChangeNumAdults = (delta) => {
    const { onUpdateReservation, numAdults, numChildren, numInfants } = this.props;

    const newVal = numAdults + delta;

    if (newVal < 0) {
      return;
    }

    onUpdateReservation({
      numAdults: newVal,
      numChildren,
      numInfants,
    });
  };

  handleChangeNumChildren = (delta) => {
    const { onUpdateReservation, numAdults, numChildren, numInfants } = this.props;

    const newVal = numChildren + delta;

    if (newVal < 0) {
      return;
    }

    onUpdateReservation({
      numAdults,
      numChildren: newVal,
      numInfants,
    });
  };

  checkCanIncreaseChildren = () => {
    const { numChildren, numAdults, numInfants, capacities } = this.props;

    return canIncreaseChildren(numAdults, numChildren, numInfants, capacities);
  };

  handleChangeNumInfants = (delta) => {
    const { onUpdateReservation, numAdults, numChildren, numInfants } = this.props;

    const newVal = numInfants + delta;

    if (newVal < 0) {
      return;
    }

    onUpdateReservation({
      numAdults,
      numChildren,
      numInfants: newVal,
    });
  };

  checkCanIncreaseInfants = () => {
    const { numChildren, numAdults, numInfants, capacities } = this.props;

    return canIncreaseInfants(numAdults, numChildren, numInfants, capacities);
  };

  checkCanIncreaseAdults = () => {
    const { numChildren, numAdults, numInfants, capacities } = this.props;

    return canIncreaseAdults(numAdults, numChildren, numInfants, capacities);
  };

  getLabel = () => {
    const { numAdults, numChildren, numInfants, disabled } = this.props;

    if (disabled) {
      return 'Book later';
    }

    return `
    ${pluralize('Adult', numAdults, true)},
    ${pluralize('Child', numChildren, true)},
    ${pluralize('Infant', numInfants, true)}
    `;
  };

  render() {
    const { numAdults, numChildren, numInfants, disabled } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="guestpicker-container">
        <button
          onClick={this.toggleDisplay}
          className="booking-input guestpicker-trigger data-hj-whitelist"
          disabled={disabled}
        >
          {this.getLabel()}
        </button>

        {isOpen && (
          <Options
            onChangeNumAdults={this.handleChangeNumAdults}
            onChangeNumChildren={this.handleChangeNumChildren}
            onChangeNumInfants={this.handleChangeNumInfants}
            numAdults={numAdults}
            numChildren={numChildren}
            numInfants={numInfants}
            canIncreaseAdults={this.checkCanIncreaseAdults()}
            canIncreaseChildren={this.checkCanIncreaseChildren()}
            canIncreaseInfants={this.checkCanIncreaseInfants()}
          />
        )}
      </div>
    );
  }
}
