import React from 'react';

import PropTypes from 'prop-types';

import { makeCancelablePromise } from '../../utils/promises';
import { reportError } from '../../utils/reportError';

import ErrorDisplay from './ErrorDisplay';
import Spinner from './Spinner';

class Async extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      started: false,
      finished: false,
      resolved: null,
      rejected: null,
    };

    this.currentPromise = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.promise !== this.props.promise) {
      this.setState(
        {
          started: false,
          finished: false,
          resolved: null,
          rejected: null,
        },
        () => {
          this.currentPromise.cancel();

          this.currentPromise = makeCancelablePromise(nextProps.promise);
          this.handlePromise();
        },
      );
    }
  }

  componentDidMount() {
    this.currentPromise = makeCancelablePromise(this.props.promise);

    this.handlePromise();
  }

  handlePromise = () => {
    this.setState(
      {
        started: true,
      },
      () => {
        const promiseWrapper = this.currentPromise;

        promiseWrapper.promise.then(
          (res) => {
            if (promiseWrapper.canceled()) {
              return;
            }

            this.setState({
              resolved: res,
              finished: true,
            });
          },
          (err) => {
            if (promiseWrapper.canceled()) {
              return;
            }

            this.setState({
              rejected: err,
              finished: true,
            });
          },
        );
      },
    );
  };

  render() {
    const { props, state } = this;

    if (state.started) {
      if (!state.finished) {
        if (props.pendingRender) {
          return props.pendingRender; // custom component to indicate load in progress
        }
        return <div />;
      }
      if (props.then && state.resolved) {
        return props.then(state.resolved);
      }
      if (props.catch && state.rejected) {
        return props.catch(state.rejected);
      }
    }

    // FIXME: it seems like 'before' takes no argument
    return this.props.before(this.handlePromise.bind(this));
  }
}

Async.propTypes = {
  before: PropTypes.func, // renders its return value before promise is handled
  then: PropTypes.func, // renders its return value when promise is resolved
  catch: PropTypes.func, // renders its return value when promise is rejected
  pendingRender: PropTypes.node, // renders its value when promise is pending
  promise: PropTypes.object.isRequired, // promise itself
};

export default class Asinc extends React.Component {
  render() {
    return (
      <Async
        promise={this.props.promise}
        before={() => <Spinner />}
        pendingRender={<Spinner />}
        then={this.props.then}
        catch={(err) => <ErrorDisplay message={err.message} report={reportError(err)} />}
      />
    );
  }
}
