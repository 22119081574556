import React from 'react';

import omit from 'lodash/omit';
import Dropzone from 'react-dropzone';

import { Button, Card, CardActions, CardContent, Grid, LinearProgress } from '@mui/material';

import { ALLOWED_IMAGE_MIMETYPES, uploadImage } from '~/services/ImageService';

import ResponsiveImage from '../Elements/ResponsiveImage';

export default class ImageUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      progress: {},
    };

    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.xhrProgress = this.xhrProgress.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  clearError(e) {
    e.stopPropagation();
    this.setState({
      errorMessage: null,
    });
  }

  xhrProgress(filename) {
    return (ev) => {
      let progress = Math.round(ev.percent);
      this.setState((prevState) => ({
        progress: {
          ...prevState.progress,
          [filename]: progress,
        },
      }));
    };
  }

  handleImageUpload(files) {
    let isAllowUpload = true;
    if (this.props.isAllowUpload) {
      isAllowUpload = this.props.isAllowUpload(files);
    }

    if (isAllowUpload) {
      files.forEach((file) => {
        const xhrProgress = this.xhrProgress(file.name);

        const upload = uploadImage(file);

        upload.end((err, response) => {
          upload.removeEventListener('progress', xhrProgress);

          if (err) {
            this.setState((prevState) => ({
              errorMessage: err.message,
              progress: omit(prevState.progress, file.name),
            }));
            return false;
          }

          this.setState((prevState) => ({
            progress: omit(prevState.progress, file.name),
          }));
          this.props.onUpload(this.props.field_key, response.body.result.id, file.name);
        });
      });
    }
  }

  handleDelete() {
    this.props.onUpload(this.props.field_key, '');
  }

  render() {
    return (
      <div className="FileUpload">
        {this.props.value && (
          <Grid mb={2} container className="images-group">
            <Grid item sm={this.props.col || 6}>
              <Card variant="outlined">
                <CardContent>
                  <ResponsiveImage
                    imageId={this.props.value}
                    width={360}
                    height={240}
                    fullWidth={this.props.imageFullWidth}
                    fitType={this.props.imageFitType}
                  />
                </CardContent>

                <CardActions>
                  <Button variant="text" onClick={this.handleDelete}>
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        )}
        {this.props.multiple ? <label>Add new {this.props.label}s</label> : <label>Add a new {this.props.label}</label>}
        {this.props.requiredField && <span className="required">*</span>}
        {this.props.note && <p className="image-note">{this.props.note}</p>}
        <Dropzone
          className="drop-zone"
          activeClassName="active"
          rejectClassName="reject"
          multiple={this.props.multiple || false}
          accept={ALLOWED_IMAGE_MIMETYPES}
          disablePreview={true}
          onDrop={this.handleImageUpload}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="drop-zone-panel" {...getRootProps()}>
              <input {...getInputProps()} />
              <header className="text-center">
                {this.props.multiple ? (
                  <h4>+ Drop new {this.props.label}s</h4>
                ) : (
                  <h4>+ Drop a new {this.props.label}</h4>
                )}
                <p>or</p>
                <Button variant="contained" size="small">
                  Select {this.props.label}
                  {this.props.multiple && 's'}
                </Button>
              </header>
              {this.state.errorMessage && (
                <div className="alert alert-danger" role="alert">
                  <button type="button" className="close" aria-label="Close" onClick={this.clearError}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <strong>Oops!</strong>
                  <p>{this.state.errorMessage}</p>
                </div>
              )}
              {Object.keys(this.state.progress).map((filename) => (
                <LinearProgress key={filename} determinate value={this.state.progress[filename]} />
              ))}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}
