import React from 'react';

const Options = ({
  numAdults,
  numChildren,
  numInfants,
  onChangeNumAdults,
  onChangeNumChildren,
  onChangeNumInfants,
  canIncreaseAdults,
  canIncreaseChildren,
  canIncreaseInfants,
}) => {
  return (
    <div className="guestpicker-options">
      <div className="guestpicker-options-row T-adult">
        <span>Adult</span>
        <div>
          <button
            className="quantity-btn"
            onClick={() => {
              onChangeNumAdults(-1);
            }}
            disabled={numAdults < 1}
          >
            -
          </button>

          <span className="guest-count data-hj-whitelist">{numAdults}</span>

          <button
            className="quantity-btn T-quantity-plus"
            onClick={() => {
              onChangeNumAdults(1);
            }}
            disabled={!canIncreaseAdults}
          >
            +
          </button>
        </div>
      </div>
      <div className="guestpicker-options-row T-children">
        <span>Children</span>
        <div>
          <button
            className="quantity-btn"
            onClick={() => {
              onChangeNumChildren(-1);
            }}
            disabled={numChildren < 1}
          >
            -
          </button>

          <span className="guest-count data-hj-whitelist">{numChildren}</span>

          <button
            className="quantity-btn T-quantity-plus"
            onClick={() => {
              onChangeNumChildren(1);
            }}
            disabled={!canIncreaseChildren}
          >
            +
          </button>
        </div>
      </div>
      <div className="guestpicker-options-row T-infants">
        <span>Infants</span>
        <div>
          <button
            className="quantity-btn"
            onClick={() => {
              onChangeNumInfants(-1);
            }}
            disabled={numInfants < 1}
          >
            -
          </button>

          <span className="guest-count data-hj-whitelist">{numInfants}</span>

          <button
            className="quantity-btn T-quantity-plus"
            onClick={() => {
              onChangeNumInfants(1);
            }}
            disabled={!canIncreaseInfants}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default Options;
