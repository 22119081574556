import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { enqueueTaxInvoice } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

function validateForm(formData, errors) {
  if (typeof formData.idOffer === 'undefined') {
    errors.idOffer.addError('Please enter an Offer ID');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please enter an offer and date to export all bookings before the selected date',
  type: 'object',
  properties: {
    idOffer: {
      title: 'Offer ID',
      type: 'string',
    },
  },
  required: ['idOffer'],
};

const uiSchema = {
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportTaxInvoiceForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    enqueueTaxInvoice({
      idOffer: event.formData.idOffer,
    })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Tax Invoice Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
