import React from 'react';

import { WarningAmber } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import caseIdRenderer from '~/components/Promo/PromoPage/caseIdRenderer';

import { PromoMeta } from '~/services/PromoService';

import { PromoToggleFormatter } from '../formatters/PromoToggleFormatter';

import actionsRenderer from './actionsRenderer';
import descriptionRenderer from './descriptionRenderer';
import devicesRenderer from './devicesRenderer';
import limitsRenderer from './limitsRenderer';
import popupMessageRenderer from './promoPopupMessage';

const getColumns = (meta: PromoMeta): GridColDef<App.PromoData>[] => [
  {
    field: 'code_name',
    headerName: 'Information',
    sortable: false,
    flex: 5,
    renderCell: descriptionRenderer,
    display: 'flex',
  },
  {
    field: 'device_types',
    headerName: 'Devices',
    sortable: false,
    renderCell: devicesRenderer,
    display: 'flex',
  },
  {
    field: 'case_id',
    headerName: 'Case ID',
    sortable: false,
    flex: 2,
    renderCell: caseIdRenderer,
    display: 'flex',
  },
  {
    field: 'code_limit',
    headerName: 'Limits',
    sortable: false,
    flex: 2,
    renderCell: limitsRenderer,
    display: 'flex',
  },
  {
    field: 'popup_message_presented_example',
    headerName: '(Example) Popup Message',
    sortable: false,
    flex: 2,
    renderCell: (params) => popupMessageRenderer(params.row.popup_message_presented_example),
    display: 'flex',
  },
  {
    field: 'promo_toggles',
    headerName: 'Toggles',
    sortable: false,
    renderCell: (params) => {
      if (!params.row.promo_toggles) {
        return (
          <Box title="No promo_toggles on promo">
            <WarningAmber />
          </Box>
        );
      }

      if (!meta) {
        return (
          <Box title="Could not load promo meta">
            <WarningAmber />
          </Box>
        );
      }

      return PromoToggleFormatter({ promoToggles: params.row.promo_toggles, promoToggleInfo: meta.promo_toggles });
    },
  },
  {
    field: 'active',
    headerName: '',
    sortable: false,
    minWidth: 200,
    renderCell: actionsRenderer,
    display: 'flex',
  },
];

export default getColumns;
