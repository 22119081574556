import React from 'react';

import cn from 'clsx';
import styled, { keyframes } from 'styled-components';

const spin = (props: { size: number }) => keyframes`
  0% {
    stroke-dashoffset: ${0.66 * props.size};
    transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: ${3.14 * props.size};
    transform: rotate(720deg);
  }
  100% {
    stroke-dashoffset: ${0.66 * props.size};
    transform: rotate(1080deg);
  }
`;

const SpinnerSVG = styled.svg<{ color: string; size: number }>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  x: 0;
  y: 0;

  circle {
    fill: transparent;
    stroke: ${(props) => props.color};
    stroke-width: ${(props) => props.size / 10};
    stroke-dasharray: ${(props) => `${3.14 * props.size}`};
    transform-origin: ${(props) => `${0.5 * props.size}px ${0.5 * props.size}px 0`};
    animation: ${(props) => spin(props)} 2s linear infinite;
  }
`;

interface SpinnerProps {
  color?: string;
  size?: number;
  inline?: boolean;
  className?: string;
}

const Spinner = (props: SpinnerProps) => {
  const size = props.size || 100;
  const inline = props.inline || false;

  const spinner = (
    <SpinnerSVG color={props.color || '#212121'} size={size} viewBox={`0 0 ${size} ${size}`}>
      <circle cx={size / 2} cy={size / 2} r={size / 2 - size / 10}></circle>
    </SpinnerSVG>
  );

  if (inline) {
    return spinner;
  }

  return <div className={cn('spinner-container', props.className)}>{spinner}</div>;
};

export default Spinner;
