import React from 'react';

import { Button, Grid } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import DebugModal from '~/components/DebugModal/DebugModal';

import useReferralProcessor from '../hooks/useReferralProcessor';

type ReferralLogPageProcessorProps = {
  referralLogIds: Array<string>;
};

function ReferralLogListProcessor({ referralLogIds }: ReferralLogPageProcessorProps) {
  const { logs, errors, isLoading: referralProcessingLoading, processLogs } = useReferralProcessor();

  const onProcessPageLogs = async () => {
    await processLogs({ referralLogIds: referralLogIds });
  };

  if (referralProcessingLoading) {
    return <Spinner />;
  }
  return (
    <Grid item sm={2}>
      <Button
        type="submit"
        variant="contained"
        onClick={onProcessPageLogs}
        title="When pressed, we will attempt to process (give referral credit + send earn email) any valid referral logs currently listed"
      >
        Try process {referralLogIds.length} pending logs
      </Button>
      {errors && <DebugModal type="generic" data={errors} title={`Processing Referral Log Errors ${errors.length}`} />}
      {logs && (
        <DebugModal
          type="generic"
          data={{
            attempted: referralLogIds,
            logs: logs,
          }}
          title={`Processed ${logs.length} Logs`}
        />
      )}
    </Grid>
  );
}

export default ReferralLogListProcessor;
