import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import PropertyRelationsSearchResultsContainer from '~/components/Vendors/Home/PropertyRelationsSearchResultsContainer';

import VendorsService from '~/services/VendorsService';

import Spinner from '../Spinner';

const PropertyRelationsForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [searchFailed, setSearchFailed] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const isValidSearchQuery = (query) => /^\S{1,36}$/.test(query);

  const fetchRelations = async (event) => {
    event.preventDefault();
    const trimmedQuery = searchQuery.trim();

    if (!isValidSearchQuery(trimmedQuery)) {
      setSearchFailed(true);
      setSearchResult(null);
      return;
    }

    try {
      setLoading(true);
      setSearchFailed(false);
      const response = await VendorsService.getRelationsBySearchId(trimmedQuery);
      setSearchResult({ result: response?.result, total: response?.result?.length || 0 });
    } catch (error) {
      setSearchFailed(true);
      setSearchResult(null);
      enqueueSnackbar(`Error fetching property relations: ${error}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={fetchRelations}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box flex={1}>
            <TextField
              id="RelationsSearch"
              data-testid="RelationsSearch"
              type="text"
              name="searchRelations"
              label="Enter Search ID"
              placeholder="Search by Rate Plan ID, Rate Plan Code, Room Type ID, Rome Type Code or Room Rate ID"
              value={searchQuery}
              onChange={handleSearchChange}
              autoFocus
              inputProps={{ maxLength: 36, minLength: 1 }}
              required
              fullWidth
            />
          </Box>

          <Button type="submit" variant="contained" className="T-search-user">
            Search
          </Button>
        </Stack>
      </form>

      <Box mt={4}>
        {isLoading && <Spinner />}
        {searchFailed && <Typography variant="subtitle1">No relations found by your request...</Typography>}
        {!isLoading && searchResult && (
          <PropertyRelationsSearchResultsContainer data={searchResult.result} total={searchResult.total} />
        )}
      </Box>
    </>
  );
};

export default PropertyRelationsForm;
