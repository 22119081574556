import React from 'react';

import currencyFormatter from 'currency-formatter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Container, IconButton, Stack, Typography } from '@mui/material';

import { removeOffer } from '~/actions/cart';

import { getAllAddons, getAllItems } from './selectors';

const OrderFooter = ({ backStep, proceedStep, nextStepLabel, cart, canProceed, items, addons, doRemoveOffer }) => {
  const offers = cart.offers;

  return (
    <Box className="new-order-footer" py={2}>
      <Container maxWidth="xl">
        <Stack direction="row" spacing={2}>
          <Box display="flex" alignItems="center">
            <Button variant="text" startIcon={<ArrowBackIcon />} onClick={backStep}>
              Back
            </Button>
          </Box>

          <Box display="flex" alignItems="center">
            <Stack direction="column">
              {offers.length > 0 &&
                offers.map((offer, i) => (
                  <Stack direction="row" alignItems="center" key={i}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        doRemoveOffer({
                          offerId: offer.id_salesforce_external,
                          offerCartId: offer.offerCartId,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Typography
                      className="data-hj-whitelist"
                      variant="body1"
                    >
                      {i + 1}. {offer.id_salesforce_external} - {offer.name}{' '}
                    </Typography>
                    
                  </Stack>
                ))}
            </Stack>
          </Box>

          <div className="main-section">
            {offers.length > 0 && (
              <div className="amounts">
                <div className="amount">
                  <div className="noun">Packages</div>
                  <div className="value data-hj-whitelist" data-testid="number-of-packages">
                    {items.length}
                    {cart.insurance.applied && <span> + </span>}
                    {cart.insurance.applied && (
                      <img src="/assets/icons/shield.svg" width="16" alt="Insurance selected" />
                    )}
                  </div>
                </div>
                <div className="amount">
                  <div className="noun">Add-ons</div>
                  <div className="value">{addons.length}</div>
                </div>
                <div className="amount">
                  <div className="noun">Total</div>
                  <div className="value data-hj-whitelist">
                    {cart.currencyCode}{' '}
                    {currencyFormatter.format(cart.amounts.subTotal, {
                      code: cart.currencyCode,
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              className="T-proceed-step-btn"
              onClick={proceedStep}
              disabled={!canProceed}
            >
              {nextStepLabel}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    items: getAllItems(state),
    addons: getAllAddons(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doRemoveOffer: removeOffer,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFooter);
