import React from 'react';

import { Alert, Button, TextField } from '@mui/material';

import VendorsService from '~/services/VendorsService';

const SuccessMessage = ({ total }) => {
  return (
    <Alert severity="success">
      <strong>Success!</strong>
      <p>File upload successful</p>
      <p>Total: {total}</p>
    </Alert>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <Alert severity="error">
      <strong>Oops!</strong>
      <p>File upload failed</p>
      <pre>{message}</pre>
    </Alert>
  );
};

export default class UploadTtcPayments extends React.Component {
  state = {
    successMessage: false,
    showErrorMessage: false,
    errorMessage: '',
    file: null,
    total: null,
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await this.fileUpload(this.state.file);

      this.setState({
        successMessage: true,
        showErrorMessage: false,
        errorMessage: '',
        file: null,
        total: result.total,
      });
    } catch (err) {
      this.setState({
        successMessage: false,
        showErrorMessage: true,
        errorMessage: err.message,
        file: null,
        total: null,
      });
    }
  };

  onChange = async (e) => {
    this.setState({ file: e.target.files[0] });
  };

  fileUpload = async (file) => {
    const formData = new FormData();
    formData.append('ttc-payments', file);
    return VendorsService.uploadTTCPayments(formData);
  };

  render() {
    const { successMessage, showErrorMessage, errorMessage } = this.state;

    return (
      <div>
        <p>Ttc Payments will not be reflected in any reports until the following day.</p>

        {successMessage && <SuccessMessage total={this.state.total} />}
        {showErrorMessage && <ErrorMessage message={errorMessage} />}

        <form onSubmit={this.onFormSubmit}>
          <h5>File Upload</h5>

          <TextField
            label="File"
            type="file"
            name="file"
            onChange={this.onChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              accept:
                '.csv, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            }}
            fullWidth
          />

          <Button sx={{ marginTop: '10px' }} type="submit" variant="contained">
            Upload
          </Button>
        </form>
      </div>
    );
  }
}
