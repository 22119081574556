import React from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import { RefundMethods, mapRefundMethodToLabel } from '../Utils/RefundUtils';

interface Props {
  refundMethods: Array<RefundMethods>;
}

export default function SelectRefundMethod({ refundMethods }: Props) {
  return (
    <FormControl required>
      <FormLabel
        sx={{
          fontWeight: 'bold',
        }}
      >
        Method of refund to customer
      </FormLabel>
      <RadioGroup name="refundMethod" row>
        {refundMethods.map((method) => {
          return (
            <FormControlLabel
              value={method}
              data-testid={`refundMethod-${method}`}
              data-cy={`refundMethod-${method}`}
              label={mapRefundMethodToLabel(method)}
              control={
                <Radio
                  // don't set required on the `Radio` as it will show asterisk on all of the inputs
                  inputProps={{
                    required: true,
                  }}
                />
              }
              key={method}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
