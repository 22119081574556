import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { CONTENT, CONTENT_FIELDS, IMAGES, PACKAGES } from '../../../consts/offerFields';
import OffersService from '../../../services/OffersService';
import ErrorDisplay from '../../Common/ErrorDisplay';

const ALLOWED_COPY_OPTIONS = [CONTENT, PACKAGES, IMAGES];

const INITIAL_STATE = {
  copyOfferId: '',
  hasError: false,
  errorMessage: '',
  selectedCopyOptions: [CONTENT],
};

class CopyOfferModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  hideCopyModal = () => {
    this.setState(INITIAL_STATE);
    this.props.hideCopyModal();
  };

  /* additional fields to be added per request
  - we do not copy "slug", because it will autogenerate on "save"
  */
  getEditableOfferDetails = (copiedOffer) => {
    const editableOfferDetails = {};
    let editableFields = [];
    this.state.selectedCopyOptions.forEach((option) => {
      if (option === CONTENT) {
        editableFields = editableFields.concat(CONTENT_FIELDS);
      } else if (copiedOffer[option]) {
        editableFields.push(option);
      }
    });
    for (let field of editableFields) {
      if (field === PACKAGES) {
        editableOfferDetails[field] = this.copyPackagesBasedOnName(copiedOffer[field]);
      } else {
        editableOfferDetails[field] = copiedOffer[field];
      }
    }
    return editableOfferDetails;
  };

  copyPackagesBasedOnName = (candidatePackagesToCopy) => {
    const originalPackages = this.props.originalOfferDetails.packages;
    const packagesToUpdate = [];
    for (const originalPackage of originalPackages) {
      const packageToCopy = candidatePackagesToCopy.find(
        (candidatePackage) => candidatePackage.deal_option_name === originalPackage.deal_option_name,
      );
      if (packageToCopy) {
        packagesToUpdate.push({
          ...originalPackage,
          name: packageToCopy.name,
          description: packageToCopy.description,
          inclusion_highlights: packageToCopy.inclusion_highlights,
          room_occupancy: packageToCopy.room_occupancy,
          regions: packageToCopy.regions,
        });
      }
    }
    if (!packagesToUpdate.length) {
      alert('No package was copied since no shared deal option name was detected.');
    }
    return packagesToUpdate;
  };

  toggleCopyOption = (event) => {
    const selectedCopyOptions = this.state.selectedCopyOptions;
    const optionTriggered = event.target.name;
    this.setState({
      selectedCopyOptions: selectedCopyOptions.includes(optionTriggered)
        ? selectedCopyOptions.filter((option) => option !== optionTriggered)
        : selectedCopyOptions.concat(optionTriggered),
    });
  };

  checkOffersCompatibility = (copiedOffer, currentOfferStatus) => {
    let areOffersCompatible = true;
    let errorMessage = '';

    if (copiedOffer.status !== 'content-approved') {
      areOffersCompatible = false;
      errorMessage = 'The offer you want to copy is not content-approved';
    }

    if (areOffersCompatible && currentOfferStatus === 'content-approved') {
      areOffersCompatible = false;
      errorMessage = "You can't overwrite the details of a content-approved offer";
    }

    return {
      areOffersCompatible,
      errorMessage,
    };
  };

  searchAndCopyOfferDetails = async (event) => {
    event.preventDefault();

    // remove old errors if any
    this.setState({
      hasError: false,
      errorMessage: '',
    });

    let offerData;

    offerData = await OffersService.getOffer(this.state.copyOfferId.trim());
    if (!offerData) {
      this.setState({
        hasError: true,
        errorMessage: 'There is no such offer',
      });
      return;
    }

    const check = this.checkOffersCompatibility(offerData.result, this.props.currentOfferStatus);

    if (!check.areOffersCompatible) {
      this.setState({
        hasError: true,
        errorMessage: check.errorMessage,
      });
      return;
    }

    this.props.setAsNotReady();

    // get and pass just the details that are editable
    let editableOfferDetails = this.getEditableOfferDetails(offerData.result);
    this.props.copyOfferData(editableOfferDetails, this.state.selectedCopyOptions);
  };

  handleChange = (event) => {
    this.setState({ copyOfferId: event.target.value });
  };

  render() {
    return (
      <Dialog open={this.props.isCopyModalVisible} onClose={this.hideCopyModal}>
        <DialogTitle>Copy editable details from another content-approved offer</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <Typography variant="subtitle2">
            Please note that this action will pre-fill the details and will let you review and save the result. If you
            have data in any of the editable fields, it will be overwritten.
          </Typography>

          <form onSubmit={this.searchAndCopyOfferDetails.bind(this)}>
            <FormGroup>
              {this.state.hasError && <ErrorDisplay message={this.state.errorMessage} />}
              <Grid container spacing={2} alignItems="center">
                <Grid sm>
                  <TextField
                    type="text"
                    placeholder="Type offer ID here"
                    value={this.state.copyOfferId}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={this.state.selectedCopyOptions.length === 0}
                  >
                    Copy
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {ALLOWED_COPY_OPTIONS.map((option) => (
                  <Grid sm={4} key={option}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={option}
                          id={`cp-option-${option}`}
                          checked={this.state.selectedCopyOptions.includes(option)}
                          onChange={this.toggleCopyOption}
                        />
                      }
                      label={`Copy ${option}`}
                    />
                  </Grid>
                ))}
              </Grid>
              {this.state.selectedCopyOptions.includes(IMAGES) &&
                this.props.originalOfferDetails.images &&
                this.props.originalOfferDetails.images.length > 0 && (
                  <Alert severity="warning">
                    This offer currently has images, continuing will <strong>replace </strong> these with the copied
                    images
                  </Alert>
                )}
            </FormGroup>
          </form>
        </DialogContent>
        <DialogActions>
          <Stack justifyContent="end">
            <Button variant="contained" color="primary" onClick={this.hideCopyModal}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withRouter(CopyOfferModalContainer);
