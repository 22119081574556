import React, { useCallback, useMemo, useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { ROOM_RATE_LAST_MINUTE_TYPE, ROOM_RATE_LPC_TYPE, ROOM_RATE_RENTAL_TYPE } from '~/consts/ratePlans';

import featureToggleUtils from '~/utils/featureToggle';

import BlackoutDates from './BlackoutDates';
import LeadDays from './LeadDays';
import LengthsOfStay from './LengthsOfStay';
import RoomRateExtraGuestSurcharges from './RoomRateExtraGuestSurcharges';
import RoomRateInclusions from './RoomRateInclusions';
import RoomTypeCapacities from './RoomTypeCapacities';
import RoomTypeIncludedGuests from './RoomTypeIncludedGuests';
import SurchargeDates from './SurchargeDates';

type Tab = {
  label: string;
  pageTitle: string;
  component: React.ComponentType;
  showTab?: boolean;
};

interface Props {
  propertyId: string;
  roomType: any;
  roomRate: any;
  roomRates: any;
  ratePlans: any;
  vendor: any;
  property: any;
  availability: any;
  availabilitySchema: any;
  blackoutDates: any;
  surchargeDates: any;
  internalSurchargeDates: any;
  roomRateDates: any;
  getRoomRateDatesByOptions: any;
  changeAmountInCalendar: any;
  numberOfAdults: any;
  childrenAges: any;
  showWithoutTax: any;
  propertyRoomTypes: any;
  inclusions: any;
  inclusionsSchema: any;
  onUpdateRoomRateInclusions: any;
  onInclusionsOrderUpdate: any;
  deleteInclusion: any;
  onDeleteAllInclusions: any;
  capacities: any;
  onUpdateRoomTypeCapacities: any;
  deleteRoomTypeCapacity: any;
  onDeleteAllCapacity: any;
  includedGuests: any;
  onUpdateRoomTypeIncludedGuests: any;
  deleteRoomTypeIncludedGuest: any;
  onDeleteAllIncludedGuests: any;
  extraGuestSurcharges: any;
  onRoomRateExtraGuestSurchargeUpdated: any;
  deleteExtraGuestSurcharge: any;
  onUpdateAvailability: any;
  onUpdateBlackoutDates: any;
  deleteBlackoutDateBlock: any;
  onDeleteAllBlackoutDates: any;
  onUpdateSurchargeDates: any;
  deleteSurchargeDateBlock: any;
  deleteSurchargeDateBlockByRoomRateId: any;
  deleteSurchargeDateBlockByRatePlanId: any;
  onUpdateInternalSurchargeDates: any;
  deleteInternalSurchargeDateBlock: any;
  onUpdateRoomRates: any;
  deleteRoomRate: any;
  isDeletingAll: any;
  leadDays: any;
  leadDaysSchema: any;
  onUpdateRoomRateLeadDays: any;
  surchargeSchema: any;
  foreignExchangeRates: any;
  channelManaged: any;
  lengthOfStay: any;
}

export default function RoomTypeEditTabs(props: Props) {
  const isRoomRateDynamic = useCallback(() => {
    const productType = props.roomRate.rate_plan ? props.roomRate.rate_plan.product_type : null;
    return (
      productType === ROOM_RATE_LPC_TYPE ||
      productType === ROOM_RATE_LAST_MINUTE_TYPE ||
      productType === ROOM_RATE_RENTAL_TYPE
    );
  }, [props.roomRate.rate_plan]);

  const TABS_MAP = useMemo(() => {
    return {
      blackoutDates: {
        label: 'Blackout Dates',
        pageTitle: 'Blackout Dates',
        component: () => (
          <BlackoutDates
            propertyId={props.propertyId}
            channelManaged={props.channelManaged}
            roomType={props.roomType}
            roomRate={props.roomRate}
            propertyRoomTypes={props.propertyRoomTypes}
            blackoutDates={props.blackoutDates}
            isDeletingAll={props.isDeletingAll}
            deleteBlackoutDateBlock={props.deleteBlackoutDateBlock}
            onUpdateBlackoutDates={props.onUpdateBlackoutDates}
            onDeleteAll={props.onDeleteAllBlackoutDates}
          />
        ),
      } as Tab,
      surchargeDates: {
        label: 'Surcharge Dates',
        pageTitle: 'Surcharge Dates',
        showTab: !isRoomRateDynamic(),
        component: () => (
          <SurchargeDates
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            propertyRoomTypes={props.propertyRoomTypes}
            vendorCurrencyCode={props.vendor.currencyisocode}
            surchargeDates={props.surchargeDates}
            surchargeType={'vendor'}
            deleteSurchargeDateBlock={props.deleteSurchargeDateBlock}
            deleteSurchargeDateBlockByRoomRateId={props.deleteSurchargeDateBlockByRoomRateId}
            deleteSurchargeDateBlockByRatePlanId={props.deleteSurchargeDateBlockByRatePlanId}
            onUpdateSurchargeDates={props.onUpdateSurchargeDates}
            surchargeSchema={props.surchargeSchema}
            foreignExchangeRates={props.foreignExchangeRates}
          />
        ),
      } as Tab,
      leadDays: {
        label: 'Lead Days',
        pageTitle: 'Lead Days',
        showTab: props.property.lead_days.query,
        component: () => (
          <LeadDays
            property={props.property}
            roomType={props.roomType}
            roomRate={props.roomRate}
            leadDays={props.leadDays}
            leadDaysSchema={props.leadDaysSchema}
            onUpdate={props.onUpdateRoomRateLeadDays}
          />
        ),
      } as Tab,
      lengthsOfStay: {
        label: 'Lengths of Stay',
        pageTitle: 'Lengths of Stay',
        component: () => <LengthsOfStay lengthsOfStay={props.lengthOfStay} />,
      } as Tab,
      inclusions: {
        label: 'Inclusions',
        pageTitle: 'Inclusions',
        component: () => (
          <RoomRateInclusions
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            roomRateInslusions={props.inclusions}
            vendorCurrencyCode={props.vendor.currencyisocode}
            onUpdate={props.onUpdateRoomRateInclusions}
            onInclusionsOrderUpdate={props.onInclusionsOrderUpdate}
            onDelete={props.deleteInclusion}
            onDeleteAll={props.onDeleteAllInclusions}
            roomRateInslusionsSchema={props.inclusionsSchema}
            editing={undefined}
            isDeletingAll={false}
            addingNew={false}
          />
        ),
      } as Tab,
      capacity: {
        label: 'Capacity',
        pageTitle: 'Capacity',
        component: () => (
          <RoomTypeCapacities
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            capacities={props.capacities}
            isDeletingAll={props.isDeletingAll}
            deleteRoomTypeCapacity={props.deleteRoomTypeCapacity}
            onUpdateRoomTypeCapacities={props.onUpdateRoomTypeCapacities}
            onDeleteAll={props.onDeleteAllCapacity}
          />
        ),
      } as Tab,
      includedGuests: {
        label: 'Included Guests',
        pageTitle: 'Included Guests',
        component: () => (
          <RoomTypeIncludedGuests
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            includedGuests={props.includedGuests}
            isDeletingAll={props.isDeletingAll}
            deleteRoomTypeIncludedGuest={props.deleteRoomTypeIncludedGuest}
            onUpdateRoomTypeIncludedGuests={props.onUpdateRoomTypeIncludedGuests}
            onDeleteAll={props.onDeleteAllIncludedGuests}
          />
        ),
      } as Tab,
      extraGuestSurcharges: {
        label: 'Extra Guest Surcharges',
        pageTitle: 'Extra Guest Surcharges',
        showTab: featureToggleUtils.availableToShow('EXTRA_GUEST_SURCHARGE_ENABLED'),
        component: () => (
          <RoomRateExtraGuestSurcharges
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            extraGuestSurcharges={props.extraGuestSurcharges}
            onUpdateRoomRateExtraGuestSurcharge={props.onRoomRateExtraGuestSurchargeUpdated}
            deleteExtraGuestSurcharge={props.deleteExtraGuestSurcharge}
            vendorCurrencyCode={props.vendor.currencyisocode}
          />
        ),
      } as Tab,
      revenueManagement: {
        label: 'Revenue Management',
        pageTitle: 'Revenue Management',
        showTab: !isRoomRateDynamic(),
        component: () => (
          <SurchargeDates
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            propertyRoomTypes={props.propertyRoomTypes}
            vendorCurrencyCode={props.vendor.currencyisocode}
            surchargeDates={props.internalSurchargeDates}
            surchargeType={'internal'}
            deleteSurchargeDateBlock={props.deleteInternalSurchargeDateBlock}
            deleteSurchargeDateBlockByRoomRateId={props.deleteSurchargeDateBlockByRoomRateId}
            deleteSurchargeDateBlockByRatePlanId={props.deleteSurchargeDateBlockByRatePlanId}
            onUpdateSurchargeDates={props.onUpdateInternalSurchargeDates}
            surchargeSchema={props.surchargeSchema}
            foreignExchangeRates={props.foreignExchangeRates}
          />
        ),
      } as Tab,
    };
  }, [
    isRoomRateDynamic,
    props.property,
    props.propertyId,
    props.channelManaged,
    props.roomType,
    props.roomRate,
    props.propertyRoomTypes,
    props.blackoutDates,
    props.isDeletingAll,
    props.deleteBlackoutDateBlock,
    props.onUpdateBlackoutDates,
    props.onDeleteAllBlackoutDates,
    props.vendor.currencyisocode,
    props.surchargeDates,
    props.internalSurchargeDates,
    props.deleteSurchargeDateBlock,
    props.deleteInternalSurchargeDateBlock,
    props.onUpdateSurchargeDates,
    props.onUpdateInternalSurchargeDates,
    props.surchargeSchema,
    props.foreignExchangeRates,
    props.leadDays,
    props.leadDaysSchema,
    props.onUpdateRoomRateLeadDays,
    props.lengthOfStay,
    props.inclusions,
    props.onUpdateRoomRateInclusions,
    props.onInclusionsOrderUpdate,
    props.deleteInclusion,
    props.onDeleteAllInclusions,
    props.inclusionsSchema,
    props.capacities,
    props.deleteRoomTypeCapacity,
    props.onUpdateRoomTypeCapacities,
    props.onDeleteAllCapacity,
    props.includedGuests,
    props.deleteRoomTypeIncludedGuest,
    props.onUpdateRoomTypeIncludedGuests,
    props.onDeleteAllIncludedGuests,
    props.extraGuestSurcharges,
    props.onRoomRateExtraGuestSurchargeUpdated,
    props.deleteExtraGuestSurcharge,
  ]);

  type Tabs = keyof typeof TABS_MAP;

  const tabList = Object.keys(TABS_MAP);

  const [key, setKey] = useState<Tabs>('blackoutDates');

  const currentTab: Tab = TABS_MAP[key];

  const handleTabChange = (event: React.SyntheticEvent, newKey: Tabs) => {
    setKey(newKey);
  };

  return (
    <>
      <Tabs value={key} onChange={handleTabChange}>
        {tabList
          .filter((tabKey) => {
            const tab: Tab = TABS_MAP[tabKey];

            if (tab.showTab !== undefined) {
              return tab.showTab;
            }

            return true;
          })
          .map((tabKey) => {
            const tab: Tab = TABS_MAP[tabKey];

            return <Tab key={tabKey} label={tab.label} value={tabKey} />;
          })}
      </Tabs>

      <Box pt={1}>
        <Box component={currentTab.component} />
      </Box>
    </>
  );
}
