import React, { useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { getArticles, getChatById } from '~/services/SupportService';

import { Article } from '../../types/Article';

const LOW_RELEVANCE = 0.3;
const MEDIUM_RELEVANCE = 0.2;
const HIGH_RELEVANCE = 0.1;

const ArticlesPage: React.FC = (): JSX.Element => {
  const [searchString, setSearchString] = useState('');
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(false);
  const [minMaxDistance, setMinMaxDistance] = useState(null);

  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const searchQuery = params.get('search');
  const articleIdsQuery = params.get('articleIds');
  const chatId = params.get('chatId');
  const messageId = params.get('messageId');

  useEffect(() => {
    setSearchString(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getArticles({
        articleIds: articleIdsQuery,
        searchString: searchQuery?.trim(),
      }),
      chatId ? getChatById(chatId) : null,
    ])
      .then(([articlesResponse, chatResponse]) => {
        const message = chatResponse?.result?.messages.find((message) => message.id === messageId);
        const distanceArray = message?.articles?.map((articleId) => articleId.distance) || [0];
        const [min, max] = [Math.min(...distanceArray), Math.max(...distanceArray)];

        setMinMaxDistance({ min, max });

        const articlesWithDistance = articlesResponse.result.map((article) => ({
          ...article,
          distance: message?.articles?.find((articleId) => articleId.articleId === article.id)?.distance,
        }));
        const articles = articlesWithDistance.sort((a, b) => {
          return a.distance - b.distance;
        });

        setArticles(articles);
      })
      .catch(console.warn)
      .finally(() => setLoading(false));
  }, [articleIdsQuery, searchQuery, chatId, messageId]);

  const updateQuery = (e) => {
    if (e.keyCode !== 13 && e.target.name !== 'article-search-submit') {
      return;
    }
    params.set('search', searchString);
    history.push({
      search: params.toString(),
    });
  };

  const resetArtcleId = (id) => {
    const articleIds = params
      .get('articleIds')
      .split(',')
      .filter((articleId) => articleId !== id);
    if (!articleIds.length) {
      params.delete('articleIds');
    } else {
      params.set('articleIds', articleIds.join(','));
    }
    history.push({
      search: params.toString(),
    });
  };

  const getRelevanceValue = (distance) => {
    const { min, max } = minMaxDistance;

    // All articles are very relevant
    // we show relevance as percentage 0.05 distance would be 95%
    if (min <= HIGH_RELEVANCE && max <= HIGH_RELEVANCE) {
      return ((1 - distance) * 100) / (1 - min) - distance * 100;
    }

    // All articles equally irrelevant
    // we multiply by a relevance factor (1 - min) instead of dividing to get a better visual
    if (min > MEDIUM_RELEVANCE && max > MEDIUM_RELEVANCE) {
      return (1 - distance) * 100 * (1 - min);
    }

    // Show article relevance relative to each other
    return ((max - distance) * 100) / (max - min) - distance * 100;
  };

  const getRelevanceColor = (distance) => {
    if (distance < MEDIUM_RELEVANCE) {
      return distance <= HIGH_RELEVANCE ? 'success.light' : 'success.main';
    }
    return distance <= LOW_RELEVANCE ? 'error.main' : 'error.light';
  };

  const articleIds = useMemo(() => articleIdsQuery?.split(',') || [], [articleIdsQuery]);

  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <Typography variant="h4">Search articles</Typography>
        <Stack direction="row" spacing={2} onKeyDown={updateQuery}>
          <TextField
            variant="outlined"
            id="ChatSearch"
            data-testid="ChatSearch"
            type="text"
            label="Search query"
            placeholder="Article ID / Article title"
            className="data-hj-whitelist"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={updateQuery} name="article-search-submit">
            Search
          </Button>
        </Stack>
        {!!articleIds?.length && (
          <Box>
            <Typography component="span" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Search Article IDs:{' '}
            </Typography>
            {articleIds.map((articleId) => (
              <Chip
                key={articleId}
                size="small"
                variant="outlined"
                color="info"
                onDelete={() => resetArtcleId(articleId)}
                label={articleId}
                sx={{ m: 1, ml: 0 }}
              />
            ))}
          </Box>
        )}
        {loading && (
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        <Box>
          <Grid container spacing={2}>
            {!loading &&
              articles.map((article) => (
                <Grid item xs={12} md={6} lg={4} key={article.id}>
                  <Card variant="outlined">
                    <CardContent>
                      {article.distance && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress
                              sx={{
                                height: 10,
                                borderRadius: 5,
                                [`& .${linearProgressClasses.bar}`]: {
                                  borderRadius: 5,
                                },
                              }}
                              variant="determinate"
                              value={getRelevanceValue(article.distance)}
                              valueBuffer={article.distance}
                            />
                          </Box>
                          <Box sx={{ minWidth: 35 }}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                color: getRelevanceColor(article.distance),
                              }}
                            >
                              {article.distance?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        ID: {article.id}
                      </Typography>
                      <Typography variant="h5" component="div">
                        {article.title}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {article.createdAt}
                      </Typography>
                      <Typography variant="body2">{article.content}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button target="_blank" href={article.link} size="small">
                        Go to contentful
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
        {!loading && articles.length === 0 && (
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <Typography variant="h5">No articles found</Typography>
            </Grid>
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default ArticlesPage;
