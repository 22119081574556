import React, { MouseEventHandler, useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';

import { refreshSalesforceTour } from '~/services/ConnectionTourSalesforce';
import { ReqInstance } from '~/services/types';

interface Props {
  salesforceId: string;
}

export default function SFTourRefreshButton(props: Props) {
  const { salesforceId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [refreshReq, setRefreshReq] = useState<ReqInstance<never, string>>({
    status: 'uninitiated',
  });

  const refreshSFTour = useCallback(async () => {
    setRefreshReq({ status: 'pending' });

    try {
      await refreshSalesforceTour(salesforceId);
      setRefreshReq({ status: 'succeeded' });
      enqueueSnackbar(`SF ID "${salesforceId}" was refreshed. Please wait 30 seconds and refresh the page.`, {
        variant: 'success',
      });
    } catch (error) {
      setRefreshReq({ status: 'failed', error: JSON.stringify(error) });
      enqueueSnackbar(`SF ID "${salesforceId}" could not be refreshed!`, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, salesforceId]);

  const handleRefreshButtonClick = useCallback<MouseEventHandler>(
    (e) => {
      e.preventDefault();
      refreshSFTour();
    },
    [refreshSFTour],
  );

  return (
    <Tooltip title="Refresh Salesforce">
      <IconButton
        aria-label={`refresh salseforce ID ${salesforceId}`}
        onClick={handleRefreshButtonClick}
        disabled={refreshReq.status === 'pending'}
      >
        {refreshReq.status !== 'pending' && <RefreshIcon />}
        {refreshReq.status === 'pending' && <CircularProgress size={20} />}
      </IconButton>
    </Tooltip>
  );
}
