import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import { mapRoomRateToRatePlan } from './mapRoomRateToRatePlan';

export function getRoomRatesFromPackageOptions({
  pkg,
  propertyData,
  fk_property_id,
}: {
  pkg: App.AccommodationPackage;
  propertyData: Reservation.Property[];
  fk_property_id: string;
}): App.RoomRate[] {
  if (pkg.package_options === undefined) {
    return [];
  }

  return pkg.package_options.map((packageOption) =>
    mapRoomRateToRatePlan({
      propertyData,
      fk_property_id,
      fk_room_rate_id: packageOption.fk_room_rate_id,
    }),
  );
}
