import React from 'react';

import { Link } from '@mui/material';

interface Props {
  href: string;
  text: string;
}

export default function CruisesLinkButton(props: Props) {
  const { href, text } = props;

  return (
    <Link
      py={1}
      px={2}
      variant="body2"
      target="_blank"
      rel="noreferrer"
      fontWeight={500}
      underline="none"
      textTransform="uppercase"
      sx={{
        borderRadius: '4px',
        border: '1px solid #2996f375',
        '&:hover': {
          backgroundColor: '#2996f310',
          border: '1px solid #2996f3',
        },
      }}
      href={href}
    >
      {text}
    </Link>
  );
}
