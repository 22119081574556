import React from 'react';

import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import ReservationService from '~/services/ReservationService';
import { formatDateISO } from '~/services/TimeService';

import { reportError } from '~/utils/reportError';

import DropdownButton from '../Elements/DropdownButton';

import convertDateListToRanges from './helpers/convertDateListToRanges';
import { buttonStates } from './states/submitButton';

const ALLOCATION_FULL = 'allocation_full';
const INCREASE_CAPACITY = 'increase_capacity';
const DECREASE_CAPACITY = 'decrease_capacity';
const RE_LAUNCH_CAMPAIGN = 're_launch_campaign';
const RE_LAUNCH_CAMPAIGN_AND_INCREASE = 're_launch_campaign_and_increase_capacity';

export default class RoomTypeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roomCount: '',
      saveState: buttonStates.default,
      ranges: convertDateListToRanges(props.dates),
      rangesExpanded: false,
      successMessages: [],
      errorMessages: [],
      radioValue: ALLOCATION_FULL,
    };

    this.applyInventory = this.applyInventory.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ranges: convertDateListToRanges(nextProps.dates),
      rangesExpanded: false,
      successMessages: [],
      errorMessages: [],
    });
  }

  async applyInventory(event, applyToAllRoomTypes) {
    event.preventDefault();
    const { propertyId, roomType, propertyRoomTypes, onInventoryUpdated, onSuccess } = this.props;

    this.setState({
      saveState: buttonStates.saving,
    });

    let roomTypesToUpdate;
    if (applyToAllRoomTypes) {
      roomTypesToUpdate = propertyRoomTypes;
    } else {
      roomTypesToUpdate = [roomType];
    }

    const successes = [];
    const failures = [];
    const updatedRoomTypeInventories = {};

    // Update availability
    for (let i = 0; i < roomTypesToUpdate.length; i++) {
      const updatingRoomType = roomTypesToUpdate[i];

      try {
        const payload = this.mapAvailability(this.props.dates, this.state.roomCount);
        const response = await ReservationService.patchAvailability(payload, propertyId, updatingRoomType.id);

        successes.push(`Update successful for ${updatingRoomType.name}`);

        // We show the inventory at the room rate level even though we update it at the room type level (for now)
        for (const roomRate of updatingRoomType.room_rates) {
          updatedRoomTypeInventories[roomRate.id] = response.result;
        }
      } catch (e) {
        reportError(e);
        console.warn(e);
        failures.push(`Failed to update inventory for ${updatingRoomType.name}`);
      }
    }

    if (successes.length > 0) {
      onInventoryUpdated && onInventoryUpdated(updatedRoomTypeInventories);
      this.setState({ successMessages: successes });
      if (failures.length === 0) {
        onSuccess && onSuccess();
        this.setState({ saveState: buttonStates.saved });
      }
    }

    if (failures.length > 0) {
      this.setState({
        saveState: buttonStates.failed,
        errorMessages: failures,
      });
    }
  }

  onChange(event) {
    this.setState({
      roomCount: parseInt(event.target.value),
      saveState: buttonStates.default,
    });
  }

  handleRadioChange(event) {
    this.setState({
      radioValue: event.target.value,
    });
    if (event.target.value === ALLOCATION_FULL || event.target.value === RE_LAUNCH_CAMPAIGN) {
      this.setState({
        roomCount: '',
      });
    }
  }

  mapAvailability(dates, delta) {
    const ranges = [];
    for (const date of dates) {
      let newAvailability = 0;
      const bookings = date.count || 0;
      const availability = date.total || 0;

      if (this.props.onlyEmptyCellSelected) {
        newAvailability = delta;
      } else {
        switch (this.state.radioValue) {
          case ALLOCATION_FULL:
            newAvailability = bookings;
            break;
          case INCREASE_CAPACITY:
            newAvailability = availability + delta;
            break;
          case DECREASE_CAPACITY:
            newAvailability = availability - delta;
            if (newAvailability < bookings) newAvailability = bookings;
            break;
          case RE_LAUNCH_CAMPAIGN:
            newAvailability = bookings + availability;
            break;
          case RE_LAUNCH_CAMPAIGN_AND_INCREASE:
            newAvailability = bookings + availability + delta;
            break;
          default:
            throw new Error('not valid value');
        }
      }

      ranges.push({
        start_date: formatDateISO(date.date),
        end_date: formatDateISO(date.date),
        delta: newAvailability,
      });
    }

    return {
      op: 'flatten',
      ranges,
    };
  }

  formatRange(range) {
    if (range.end === range.start) {
      return formatDateISO(range.start);
    }
    return (
      <span>
        {formatDateISO(range.start)} &rarr; {formatDateISO(range.end)}
      </span>
    );
  }

  getSelectionMessage() {
    const { dates } = this.props;
    const { ranges, rangesExpanded } = this.state;
    if (ranges.length === 1) {
      if (ranges[0].end === ranges[0].start) {
        return <p>Setting inventory for date {this.formatRange(ranges[0])}</p>;
      } else {
        return (
          <p>
            Setting inventory for {dates.length} dates
            <br />
            {this.formatRange(ranges[0])}
          </p>
        );
      }
    } else {
      return (
        <p>
          Setting inventory for {dates.length} dates
          <br />
          {rangesExpanded ? (
            <div>
              <a onClick={this.toggleRangesExpanded}>Hide date ranges</a>
              <ul>
                {ranges.map((range, index) => (
                  <li key={`${formatDateISO(range.start)}-${formatDateISO(range.end)}-${index}`}>
                    {this.formatRange(range)}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <a onClick={this.toggleRangesExpanded}>Show date ranges</a>
          )}
        </p>
      );
    }
  }

  toggleRangesExpanded = () => {
    this.setState((state) => ({
      rangesExpanded: !state.rangesExpanded,
    }));
  };

  render() {
    const saving = this.state.saveState === buttonStates.saving;
    const { successMessages, errorMessages } = this.state;

    let mandatoryFieldsFullFilled = false;
    if (this.state.radioValue === ALLOCATION_FULL || this.state.radioValue === RE_LAUNCH_CAMPAIGN) {
      mandatoryFieldsFullFilled = true;
    } else if (typeof this.state.roomCount === 'number' && this.state.roomCount > 0) {
      mandatoryFieldsFullFilled = true;
    }

    let numberFieldDisabled = false;
    if (!this.props.onlyEmptyCellSelected) {
      if (this.state.radioValue === ALLOCATION_FULL || this.state.radioValue === RE_LAUNCH_CAMPAIGN) {
        numberFieldDisabled = true;
      }
    }

    const someEmptySelected = this.props.dates.some((date) => !date.total);

    return (
      <form onSubmit={(e) => this.applyInventory(e, false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              marginRight: 10,
              flexGrow: 2,
              textAlign: 'left',
            }}
          >
            {this.getSelectionMessage()}

            <Box my={2}>
              <Typography fontSize={16} fontWeight="bold" mb={1}>
                {this.props.onlyEmptyCellSelected ? 'Number of available rooms' : 'Adjust Capacity'}
              </Typography>
              <TextField
                disabled={numberFieldDisabled}
                type="number"
                value={this.state.roomCount}
                id="available_rooms"
                placeholder="Available rooms"
                onChange={this.onChange}
                inputProps={{ min: '0' }}
                fullWidth
                autoFocus
              />
            </Box>
          </Box>

          <Box>
            {!this.props.onlyEmptyCellSelected && (
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={ALLOCATION_FULL}
                  name="radio-buttons-group"
                  value={this.state.radioValue}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel value={ALLOCATION_FULL} control={<Radio />} label="Allocation Full" />
                  <FormControlLabel value={INCREASE_CAPACITY} control={<Radio />} label="Increase Capacity" />
                  <FormControlLabel value={DECREASE_CAPACITY} control={<Radio />} label="Decrease Capacity" />
                  <FormControlLabel
                    value={RE_LAUNCH_CAMPAIGN}
                    control={<Radio />}
                    label="Re-Launch Campaign"
                    disabled={someEmptySelected}
                  />
                  <FormControlLabel
                    value={RE_LAUNCH_CAMPAIGN_AND_INCREASE}
                    control={<Radio />}
                    label="Re-Launch Campaign and Increase Capacity"
                    disabled={someEmptySelected}
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Box>
        </Box>

        {successMessages.length > 0 && (
          <Alert severity="success" onClose={() => this.setState({ successMessages: [] })}>
            <ul>
              {successMessages.map((message, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          </Alert>
        )}

        {errorMessages.length > 0 && (
          <Alert severity="error" onClose={() => this.setState({ errorMessages: [] })}>
            <ul>
              {errorMessages.map((message, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          </Alert>
        )}

        <Stack mt={2} direction="row-reverse" spacing={1} justifyContent="space-between" alignItems="center">
          <DropdownButton
            buttonTitle="Apply to this room rate"
            buttonClick={(e) => this.applyInventory(e, false)}
            disabled={!mandatoryFieldsFullFilled || saving}
            className="T-apply-to-this-room-type"
          >
            <MenuList>
              <MenuItem onClick={(e) => this.applyInventory(e, false)}>Apply to this room type</MenuItem>
              <MenuItem onClick={(e) => this.applyInventory(e, true)}>Apply to all room types</MenuItem>
            </MenuList>
          </DropdownButton>

          <Button variant="text" type="button" onClick={this.props.onCancel} disabled={saving}>
            Cancel
          </Button>
        </Stack>
      </form>
    );
  }
}
