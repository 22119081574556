import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { emptyCart, setOrderId } from '~/actions/cart';

import Payment from '~/components/Common/Payment';
import TimeElapsed from '~/components/Common/TimeElapsed';

import { finalizeOrder, getOrder } from '~/services/OrdersService';

import { handleCreateOrder } from '~/utils/payment';
import sleep from '~/utils/sleep';

import NoDataWarning from '../NoDataWarning';

import PaymentTimedOutModal from './PaymentTimedOutModal';

const TIME_LIMIT = 1200000;

type Props = {
  cart: App.Cart;
  insurance: App.InsuranceState;
  orderType: App.OrderType;
  doEmptyCart: Utils.ActionDispatcher<typeof emptyCart>;
  doSetOrderId: Utils.ActionDispatcher<typeof setOrderId>;
};

function NewOrderPayment(props: Props) {
  const { doEmptyCart, doSetOrderId, insurance, orderType } = props;
  const id_member = props.cart.customer.id_member;

  const [hasTimeout, setHasTimeout] = useState(false);

  const handleOrder = (cart: App.Cart, offerSource, brand, insuranceState) => {
    const {
      customer,
      insurance: cartInsurance,
      currencyCode,
      regionCode,
      transactionKey,
      contactPhonePrefix,
      contactPhone,
      amounts,
      notes,
      offers,
      items,
      stateOfResidence,
      businessId,
    } = cart;

    if (cart.orderId !== '') {
      return getOrder(cart.orderId).then((response) => response.result);
    }

    const { travellers } = insurance;
    return handleCreateOrder({
      customer,
      offers,
      items,
      insurance: cartInsurance,
      currencyCode,
      regionCode,
      offerSource,
      brand,
      transactionKey,
      contactPhonePrefix,
      contactPhone,
      insuranceState,
      travellers,
      amounts,
      notes,
      stateOfResidence,
      businessId,
    }).then((order) => {
      doSetOrderId(order.id);
      return order;
    });
  };

  const onAfterPayment = (cart: App.Cart, orderId: string) => {
    return new Promise((resolve, reject) => {
      tryFinalize(orderId, 0, 5, resolve, reject);
    });
  };

  const tryFinalize = (orderId: string, count: number, maxRetries: number, resolve, reject) => {
    return finalizeOrder(orderId)
      .then(resolve)
      .catch(async (err) => {
        if (count === maxRetries) {
          return reject(err);
        }

        await sleep(1000);

        return tryFinalize(orderId, count + 1, maxRetries, resolve, reject);
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      doEmptyCart();
      setHasTimeout(true);
    }, TIME_LIMIT);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [doEmptyCart]);

  return (
    <div>
      <TimeElapsed hasTimedOut={hasTimeout} showComponent={<PaymentTimedOutModal userId={id_member} />} />

      <Payment
        heading="Order Summary"
        handleOrder={handleOrder}
        onAfterPayment={onAfterPayment}
        dataPlaceholder={<NoDataWarning userId={id_member} orderType={orderType} />}
      />
    </div>
  );
}

function mapStateToProps(state: App.State) {
  return {
    cart: state.cart,
    insurance: state.insurance,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doSetOrderId: setOrderId,
      doEmptyCart: emptyCart,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderPayment);
