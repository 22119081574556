import {
  ROLE_ADMIN_USER,
  ROLE_COORDINATOR,
  ROLE_EMPLOYEE_USER,
  ROLE_EXPERIENCES_COORDINATOR,
  ROLE_TOUR_COORDINATOR,
} from '~/consts/roles';

import UserActions from '../actions/UserActions';

import BaseStore from './BaseStore';

export default class UserStore extends BaseStore {
  constructor(userService, sessionStore) {
    super();
    this.service = userService;
    this.sessionError = null;
    this.sessionStore = sessionStore;
    this.resolveSession();
  }

  isValidRole(account) {
    if (
      (account &&
        !account.roles.includes(ROLE_ADMIN_USER) &&
        !account.roles.includes(ROLE_EMPLOYEE_USER) &&
        !account.roles.includes(ROLE_COORDINATOR) &&
        !account.roles.includes(ROLE_EXPERIENCES_COORDINATOR) &&
        !account.roles.includes(ROLE_TOUR_COORDINATOR)) ||
      !account
    ) {
      return new Error(`You don't have the permissions for this operation`);
    }

    return null;
  }

  getLoginViewData(callback) {
    this.service.getLoginViewData(callback);
  }

  login(options, callback) {
    this.reset();

    this.service.login(options, (err) => {
      if (err) {
        return callback(err);
      }

      this.resolveSession(callback, true);
    });
  }

  loginWithGoogle(options, callback) {
    this.reset();

    this.service.loginWithGoogle(options, (err) => {
      if (err) {
        return callback(err);
      }

      this.resolveSession(callback, true);
    });
  }

  register(options, callback) {
    this.service.register(options, callback);
  }

  getRegisterViewData(callback) {
    this.service.getRegisterViewData(callback);
  }

  forgotPassword(options, callback) {
    this.service.forgotPassword(options, callback);
  }

  changePassword(options, callback) {
    this.service.changePassword(options, callback);
  }

  updateProfile(data, callback) {
    this.service.updateProfile(data, callback);
  }

  verifyEmail(spToken, callback) {
    this.service.verifyEmail(spToken, callback);
  }

  logout(callback) {
    this.service.logout((err) => {
      if (err) {
        return callback(err);
      }

      this.reset();
      this.emitChange();

      callback();
    });
  }

  resolveSession(callback, force) {
    if (!force && (this.sessionError || !this.sessionStore.empty())) {
      return callback && callback(this.sessionError, this.sessionStore.get());
    }

    this.sessionStore.set({
      loading: true,
      account: null,
    });

    this.service.me((err, account) => {
      if (!err) {
        err = this.isValidRole(account);
      }
      if (err) {
        this.sessionError = err;
        this.sessionStore.set({
          loading: false,
          account: null,
        });
        UserActions.set(null);
        this.service.delToken();
      } else {
        this.sessionError = null;
        this.sessionStore.set({
          loading: false,
          account,
        });
        UserActions.set(account);
      }

      if (callback) {
        return callback(this.sessionError, this.sessionStore.get());
      }

      this.emitChange();
    });
  }

  reset() {
    this.sessionError = null;
    this.sessionStore.set({
      loading: false,
      account: null,
    });
    UserActions.set(null);
  }
}
