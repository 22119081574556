import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import TENANTS from './consts/tenant';
import rootReducer from './reducers';
import { INITIAL_CUSTOMER_COMMUNICATION_STATE } from './reducers/customerCommunicationReducer';

export const configureStore = (initialState) =>
  createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk, promise)));

// todo: createStore should be replaced with `configureStore` from `@reduxjs/toolkit`
const store = configureStore({
  tenant: TENANTS[localStorage.getItem('tenant') || 'lux'],
  customerCommunication: INITIAL_CUSTOMER_COMMUNICATION_STATE,
});

export default store;
