/* List of addons */
import React from 'react';

import PropTypes from 'prop-types';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import isInstantBooking from '~/utils/isInstantBooking';

import AddonItem from './item';

const getAllowedAddons = (offer: App.CartOffer) => {
  if (!isInstantBooking(offer.items)) {
    return [];
  }

  const pkgIds = offer.items.map((item) => item.pkg.unique_key);

  const offerPkgs = (offer.packages as App.CartPackage[]).filter((pkg) => pkgIds.includes(pkg.unique_key));

  return offerPkgs.reduce((allAddons, pkg) => {
    const addons = pkg.addons.map((addon) => {
      return {
        ...addon,
        offerPackageId: pkg.id_salesforce_external ?? pkg.le_package_id,
        itemId: (offer.items as App.CartItem[]).find((item) => item.pkg.unique_key === pkg.unique_key).id,
      };
    });
    return [...allAddons, ...addons];
  }, []);
};

const AddonsList = (props) => {
  const { offer, onAddAddon, onRemoveAddon } = props;
  const addons = getAllowedAddons(offer);

  if (!addons.length) {
    return (
      <TableContainer component={Paper} sx={{ border: 'solid 1px #d6d6d6' }}>
        <Table className="T-package-table package-addons-list">
          <TableHead>
            <TableRow>
              <TableCell>Addon</TableCell>
              <TableCell>Fare Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell colSpan={4}>
                <p>There are no available addons for this package</p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <Table className="T-package-table package-addons-list">
        <TableHead>
          <TableRow>
            <TableCell>Add-on Experiences</TableCell>
            <TableCell>Fare Type</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addons
            .filter((addon) => !addon.complimentary)
            .map((addon) => (
              <AddonItem
                key={addon.id}
                addon={addon}
                offer={offer}
                getAddonQuantity={() => {
                  return offer.addons.filter((a) => addon.id_salesforce_external === a.addonId).length;
                }}
                onRemoveAddon={onRemoveAddon}
                onAddAddon={onAddAddon}
              />
            ))}
        </TableBody>
      </Table>

      <Table className="T-package-table package-addons-list">
        <TableHead>
          <TableRow>
            <TableCell>Bundled Experiences</TableCell>
            <TableCell>Fare Type</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addons
            .filter((addon) => addon.complimentary)
            .map((addon) => (
              <AddonItem
                key={addon.id}
                addon={addon}
                offer={offer}
                getAddonQuantity={() => {
                  return offer.addons.filter((a) => addon.id_salesforce_external === a.addonId).length;
                }}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

AddonsList.propTypes = {
  offer: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  currencyCode: PropTypes.string.isRequired,
  onAddAddon: PropTypes.func.isRequired,
  onRemoveAddon: PropTypes.func.isRequired,
  getAddonQuantity: PropTypes.func.isRequired,
};

export default AddonsList;
