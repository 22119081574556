import React from 'react';

import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid';

import { formatDateWithClock } from '~/services/TimeService';

interface BlockedOffer {
  offerId: string;
  added: string;
  view: string;
}

const columns: GridColDef<BlockedOffer>[] = [
  {
    field: 'offerId',
    headerName: 'Offer ID',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    display: 'flex',
  },
  {
    field: 'view',
    headerName: 'View',
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    renderCell: (params) => (
      <a href={params.value} target="_blank">
        {params.value}
      </a>
    ),
    display: 'flex',
  },
  {
    field: 'added',
    headerName: 'Added',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (params.value ? formatDateWithClock(params.value) : '-'),
    flex: 2,
    display: 'flex',
  },
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 100,
  },
];

export default columns;
