/*
Adapted from https://github.com/superwf/react-drag-select/tree/4f23278b172dd352bccda624d66292ec2a2ef075
*/
import React from 'react';

import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import Arrow from './Arrow';

class Selection extends React.Component {
  static propTypes = {
    enabled: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    selectMode: PropTypes.string,
  };

  /**
   * Component default props
   */
  static defaultProps = {
    enabled: true,
    onSelectionChange: () => {}, // eslint-disable-line no-empty-function,
    selectMode: 'range',
  };

  constructor(props) {
    super(props);

    this.state = {
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
      selectionArrow: null,
      appendMode: false,
    };
  }

  setSelectableRefs = (selectableRefs) => {
    this.selectableRefs = selectableRefs;
  };

  componentWillMount() {
    this.selectedChildren = {};
  }

  componentDidMount() {
    document.addEventListener('keydown', this.cancelSelect);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelSelect);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.enabled || nextProps.selectMode !== this.props.selectMode) {
      this.selectedChildren = {};
      this.props.onSelectionChange.call(null, Object.keys(this.selectedChildren));
    }
  }

  _canStartSelection(e) {
    if (!this.props.enabled) {
      return false;
    }
    if (e.button === 2 || e.nativeEvent.which === 2) {
      this.cancelSelect();
      return false;
    }
    if (
      this.props.selectMode === 'range' &&
      !this._pointIntersectsSelectableNode({
        x: e.pageX,
        y: e.pageY,
      })
    ) {
      return false;
    }
    return true;
  }

  /**
   * On root element mouse down
   */
  _onMouseDown = (e) => {
    if (!this._canStartSelection(e)) {
      return;
    }
    const nextState = {};
    if (e.ctrlKey || e.altKey || e.shiftKey) {
      nextState.appendMode = true;
    }
    nextState.mouseDown = true;
    nextState.startPoint = {
      x: e.pageX,
      y: e.pageY,
    };
    this.setState(nextState);
    window.document.addEventListener('mousemove', this._onMouseMove);
    window.document.addEventListener('mouseup', this._onMouseUp);
  };

  cancelSelect = () => {
    if (!this.state.mouseDown) {
      return;
    }
    window.document.removeEventListener('mousemove', this._onMouseMove);
    window.document.removeEventListener('mouseup', this._onMouseUp);
    this.setState({
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
      selectionArrow: null,
      appendMode: false,
    });
  };

  /**
   * On document element mouse up
   */
  _onMouseUp = (e) => {
    if (!this.state.mouseDown) {
      return;
    }
    window.document.removeEventListener('mousemove', this._onMouseMove);
    window.document.removeEventListener('mouseup', this._onMouseUp);
    const endPoint = {
      x: e.pageX,
      y: e.pageY,
    };

    if (this.props.selectMode === 'free') {
      const selectionBox = this._calculateSelectionBox(this.state.startPoint, endPoint);
      this._updateCollidingChildren(selectionBox, this.state.appendMode);
    }

    if (this.props.selectMode === 'range') {
      this._updateInRangeChildren(this.state.startPoint, endPoint);
    }

    this.setState({
      mouseDown: false,
      startPoint: null,
      endPoint: null,
      selectionBox: null,
      selectionArrow: null,
      appendMode: false,
    });
    this.props.onSelectionChange.call(null, Object.keys(this.selectedChildren));
  };

  /**
   * On document element mouse move
   */
  _onMouseMove = (e) => {
    e.preventDefault();
    if (this.state.mouseDown) {
      const endPoint = {
        x: e.pageX,
        y: e.pageY,
      };
      this.setState({
        endPoint: endPoint,
        selectionBox: this._calculateSelectionBox(this.state.startPoint, endPoint),
        selectionArrow: this._calculateSelectionArrow(this.state.startPoint, endPoint),
      });
    }
  };

  render() {
    const className = 'selection ' + (this.state.mouseDown ? 'dragging' : '');
    return (
      <div className={className} ref="selectionBox" onMouseDown={this._onMouseDown}>
        {this.props.children}
        {this.props.selectMode === 'free' && this.renderSelectionBox()}
        {this.props.selectMode === 'range' && this.renderSelectionArrow()}
      </div>
    );
  }

  renderSelectionBox() {
    if (!this.state.mouseDown || !this.state.endPoint || !this.state.startPoint) {
      return null;
    }
    return <div className="selection-border" style={this.state.selectionBox} />;
  }

  renderSelectionArrow() {
    if (!this.state.mouseDown || !this.state.endPoint || !this.state.startPoint) {
      return null;
    }
    return <Arrow start={this.state.selectionArrow.startPoint} end={this.state.selectionArrow.endPoint} />;
  }

  /**
   * Manually update the selection status of an item
   * @param {string} key the item's target key value
   * @param {boolean} isSelected the item's target selection status
   */
  selectItem(key, isSelected) {
    if (isSelected) {
      this.selectedChildren[key] = isSelected;
    } else {
      delete this.selectedChildren[key];
    }
    this.props.onSelectionChange.call(null, Object.keys(this.selectedChildren));
    this.forceUpdate();
  }

  /**
   * Select all items
   */
  selectAll() {
    this.selectableRefs.forEach(
      function (ref, key) {
        if (key !== 'selectionBox') {
          this.selectedChildren[key] = true;
        }
      }.bind(this),
    );
  }

  /**
   * Manually clear selected items
   */
  clearSelection() {
    this.selectedChildren = {};
    this.props.onSelectionChange.call(null, []);
    this.forceUpdate();
  }

  /**
   * Detect 2D box intersection
   */
  _boxIntersects(boxA, boxB) {
    if (
      boxA.left <= boxB.left + boxB.width &&
      boxA.left + boxA.width >= boxB.left &&
      boxA.top <= boxB.top + boxB.height &&
      boxA.top + boxA.height >= boxB.top
    ) {
      return true;
    }
    return false;
  }

  _pointIntersectsSelectableNode(point) {
    const box = this.refs.selectionBox;
    const boxRect = box.getBoundingClientRect();
    const window = global;
    const selectionBox = {
      top: point.y - boxRect.top - window.scrollY,
      left: point.x - boxRect.left,
      width: 0,
      height: 0,
    };
    let tmpNode = null;
    let tmpBox = null;
    const _this = this;

    return this.selectableRefs.find(function (ref) {
      tmpNode = findDOMNode(ref);
      tmpBox = {
        top: tmpNode.offsetTop,
        left: tmpNode.offsetLeft,
        width: tmpNode.clientWidth,
        height: tmpNode.clientHeight,
      };
      return _this._boxIntersects(selectionBox, tmpBox);
    });
  }

  /**
   * Updates the selected items based on the
   * collisions with selectionBox
   */
  _updateCollidingChildren(selectionBox) {
    let tmpNode = null;
    let tmpBox = null;
    const _this = this;
    this.selectableRefs.forEach(function (ref, key) {
      tmpNode = findDOMNode(ref);
      tmpBox = {
        top: tmpNode.offsetTop,
        left: tmpNode.offsetLeft,
        width: tmpNode.clientWidth,
        height: tmpNode.clientHeight,
      };
      if (_this._boxIntersects(selectionBox, tmpBox)) {
        if (_this._onlyIntersectsThisElement(selectionBox, tmpBox)) {
          if (_this.selectedChildren[key]) {
            delete _this.selectedChildren[key];
          } else {
            _this.selectedChildren[key] = true;
          }
        } else {
          _this.selectedChildren[key] = true;
        }
      } else if (!_this.state.appendMode) {
        delete _this.selectedChildren[key];
      }
    });
  }

  /**
   * Updates the selected items based on the
   * collisions with rang arrow
   */
  _updateInRangeChildren(startPoint, endPoint) {
    const _this = this;
    let startNode = this._pointIntersectsSelectableNode(startPoint);
    let endNode = this._pointIntersectsSelectableNode(endPoint);

    if (startNode && endNode && this.props.rangeCompareFunc) {
      _this.selectedChildren = {};

      if (this.props.rangeCompareFunc(startNode, endNode) > 0) {
        let tmp = startNode;
        startNode = endNode;
        endNode = tmp;
      }

      this.selectableRefs.forEach(function (ref, key) {
        if (_this.props.rangeCompareFunc(ref, startNode) >= 0 && _this.props.rangeCompareFunc(ref, endNode) <= 0) {
          _this.selectedChildren[key] = true;
        }
      });
    }
  }

  _onlyIntersectsThisElement(selectionBox, elementBox) {
    return (
      selectionBox.left >= elementBox.left &&
      selectionBox.left + selectionBox.width <= elementBox.left + elementBox.width &&
      selectionBox.top >= elementBox.top &&
      selectionBox.top + selectionBox.height <= elementBox.top + elementBox.height
    );
  }

  /**
   * Calculate selection box dimensions
   */
  _calculateSelectionBox(startPoint, endPoint) {
    if (!this.state.mouseDown || !endPoint || !startPoint) {
      return null;
    }
    const left = Math.min(startPoint.x, endPoint.x);
    const top = Math.min(startPoint.y, endPoint.y);
    const width = Math.abs(startPoint.x - endPoint.x);
    const height = Math.abs(startPoint.y - endPoint.y);
    const box = this.refs.selectionBox;
    const boxRect = box.getBoundingClientRect();
    const window = global;
    return {
      left: left - boxRect.left,
      top: top - boxRect.top - window.scrollY,
      width,
      height,
    };
  }

  /**
   * Calculate selection arrow dimensions
   */
  _calculateSelectionArrow(startPoint, endPoint) {
    if (!this.state.mouseDown || !endPoint || !startPoint) {
      return null;
    }
    const box = this.refs.selectionBox;
    const boxRect = box.getBoundingClientRect();
    const window = global;
    return {
      startPoint: {
        x: startPoint.x - boxRect.left,
        y: startPoint.y - boxRect.top - window.scrollY,
      },
      endPoint: {
        x: endPoint.x - boxRect.left,
        y: endPoint.y - boxRect.top - window.scrollY,
      },
    };
  }
}

export default Selection;
