import React, { Component } from 'react';

import { Button, Stack } from '@mui/material';

import ReservationService from '~/services/ReservationService';

import { formatValidationMessages } from '~/utils/formatValidationMessages';
import { reportError } from '~/utils/reportError';

import ErrorListDisplay from '../ErrorListDisplay';
import Checkbox from '../Legacy/Checkbox';

import { buttonMessages, buttonStates } from './states/submitButton';

export default class RoomTypeOccupancyForm extends Component {
  constructor(props) {
    super(props);

    this.toggleIncludeInfants = this.toggleIncludeInfants.bind(this);

    this.state = {
      maxOccupancy: this.props.roomType.max_occupancy,
      maxAdultOccupancy: this.props.roomType.max_adult_occupancy,
      maxChildOccupancy: this.props.roomType.max_child_occupancy,
      maxInfantOccupancy: this.props.roomType.max_infant_occupancy,
      includeInfants: false,
      saveState: buttonStates.default,
      errors: null,
    };
  }

  submit = () => {
    if (!window.confirm('this will regenerate all capacities, are you sure?')) {
      return;
    }

    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    const data = {
      max_occupancy: this.state.maxOccupancy,
      max_adult_occupancy: this.state.maxAdultOccupancy,
      max_child_occupancy: this.state.maxChildOccupancy,
      max_infant_occupancy: this.state.maxInfantOccupancy,
      include_infants: this.state.includeInfants,
    };

    const promise = ReservationService.updateRoomTypeOccupancy(
      data,
      this.props.propertyId,
      this.props.roomType.id,
      this.props.roomRate.id,
    );

    return promise
      .then((response) => {
        this.setState({
          saveState: buttonStates.saved,
          errors: null,
        });
        this.props.onRoomTypeCapacityUpdated(response.result);
      })
      .catch((e) => {
        const errors = e.name === 'ValidationError' ? e.errors : null;
        this.setState({
          saveState: buttonStates.failed,
          errors,
        });
        reportError(e);
      });
  };

  toggleIncludeInfants() {
    this.setState({ includeInfants: !this.state.includeInfants });
  }

  formRow(label, fieldName) {
    return (
      <div className="row capacity-form-row">
        <label className="col-sm-9">{label}</label>
        <div className="col-sm-3">
          <input
            type="number"
            step="1"
            min="0"
            value={this.state[fieldName]}
            onChange={(event) =>
              this.setState({
                [fieldName]: parseInt(event.target.value),
              })
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="capacity-form form-horizontal">
        {this.formRow('Total number of guests', 'maxOccupancy')}
        <hr />
        {this.formRow('Adults', 'maxAdultOccupancy')}
        {this.formRow('Children', 'maxChildOccupancy')}
        {this.formRow('Infants', 'maxInfantOccupancy')}
        <Checkbox
          labelText="Include infants"
          checked={this.state.includeInfants}
          onToggle={this.toggleIncludeInfants}
        />

        <div className="modal-form-group">
          {this.state.errors && <ErrorListDisplay messages={formatValidationMessages(this.state.errors)} />}
        </div>

        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button variant="text" onClick={this.props.onCancel}>
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={this.state.saveState === buttonStates.saving}
            onClick={this.submit}
          >
            {buttonMessages[this.state.saveState]}
          </Button>
        </Stack>
      </div>
    );
  }
}
