import {
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
  VARIANT_OF_REFUND_NONE,
  VARIANT_OF_REFUND_PARTIAL,
  VENDOR_REFUND_AGREES_NUMBER,
  VENDOR_REFUND_AGREES_PERCENTAGE,
} from '../consts/refund';

// calcOrderPayments calculates the total payments and total promo code
export function calcOrderPayments(payments) {
  let paymentSum = 0;
  let promoSum = 0;

  for (let payment of payments) {
    if (payment.success && parseFloat(payment.amount) > 0) {
      paymentSum = paymentSum + parseFloat(payment.amount);
      if (['promo'].includes(payment.type)) {
        promoSum = promoSum + parseFloat(payment.amount);
      }
    }
  }

  return {
    sum: paymentSum,
    promo: promoSum,
  };
}

// calcPromoPercentage calculates the ratio of payments made in promo to the total
// payment amount
export function calcPromoPercentage(payments, items) {
  let { promo } = calcOrderPayments(payments);

  let totalItem = 0;
  for (let item of items.filter((i) => !i.is_downgraded)) {
    totalItem = totalItem + item.price;
  }

  return promo / totalItem;
}

// calcAccountingAmount calculates an amount for accounting purposes
export function calcAccountingAmount({ payments, initialAmount, promoAmount, inputs, items, includePromo = true }) {
  let cash_amount;
  let initial_amount;

  if (inputs && inputs.amount !== undefined && inputs.initial_amount !== undefined) {
    cash_amount = parseFloat(inputs.amount);
    initial_amount = parseFloat(inputs.initial_amount);
  } else {
    cash_amount = parseFloat(initialAmount);
    initial_amount = parseFloat(initialAmount);
    if (includePromo === true && promoAmount) {
      cash_amount = parseFloat(initialAmount - promoAmount);
    }
  }

  const promo_piece = calcPromoPercentage(payments, items) * (initial_amount / cash_amount);
  let finalPromoAmount = 0;
  if (includePromo === true) {
    finalPromoAmount = parseFloat(promo_piece * cash_amount);
  }
  const out = initial_amount * (cash_amount / (initial_amount - finalPromoAmount));

  // NaNs happen when the forms aren't inited (or a user clear the form)
  if (Number.isNaN(out)) {
    return 0.0;
  }

  return out.toFixed(2);
}

// calcCashAmount calculates the cash amount to be refunded
export function calcCashAmount({ payments, initialAmount, promoAmount, inputs, items, includePromo = true }) {
  let fee;
  let amount;

  if (inputs && (inputs.amount !== undefined || inputs.fee !== undefined)) {
    amount = inputs.amount;
    fee = inputs.fee;
  } else {
    // refund in full but without the promo amount
    let finalPromoAmount = 0;
    if (includePromo === true) {
      if (!promoAmount) {
        finalPromoAmount = calcPromoPercentage(payments, items) * initialAmount;
      } else {
        finalPromoAmount = promoAmount;
      }
    }
    amount = initialAmount - finalPromoAmount;
    fee = 0;
  }

  const out = amount - fee;

  // NaNs happen when the forms aren't inited (or a user clear the form)
  if (Number.isNaN(out)) {
    return 0.0;
  }

  return out.toFixed(2);
}

// calcInitialAmount calculates the initial amount to be used when calculating
// the accounting amount and cash amount
export function calcInitialAmount({ chargeComponentKey, salesPrice, refundMetadata }) {
  const refundSum = refundMetadata.reduce((acc, cur) => {
    if (chargeComponentKey == cur.charge_component_key) {
      return acc + parseFloat(cur.cash_amount);
    }

    return acc;
  }, 0);

  const raw = parseFloat(salesPrice) - refundSum;

  return raw.toFixed(2);
}

// getAccountingMetaPayload transforms accounting metadata into a format
// that can be consumed by API
export function getAccountingMetaPayload(accountingMetadata, fee) {
  return accountingMetadata.map((datum) => {
    return {
      source: datum.source,
      accounting_amount: String(datum.accounting_amount),
      cash_amount: String(datum.cash_amount),
      charge_component_key: datum.charge_component_key,
      refund_fee: String(fee),
    };
  });
}

// calcTotalRefund sums the cash amount of all accounting metadata
export function calcTotalRefund(accountingMetadata) {
  let total_cash_refund = 0;

  for (let metadatum of accountingMetadata) {
    total_cash_refund = total_cash_refund + parseFloat(metadatum.cash_amount);
  }

  return total_cash_refund;
}

// calcTotalAccounting sums the accounting amount of all accounting metadata
export function calcTotalAccounting(accountingMetadata) {
  let sum = 0.0;

  for (let row of accountingMetadata) {
    sum = sum + parseFloat(row.accounting_amount);
  }

  return sum.toFixed(2);
}

// calculation total refund Fee
export function calcTotalRefundFee(refundMetadata, componentKey) {
  const refundSumFee = refundMetadata.reduce((acc, cur) => {
    if (componentKey === cur.charge_component_key) {
      return acc + parseFloat(cur.refund_fee);
    }

    return acc;
  }, 0);

  return refundSumFee.toFixed(2);
}

// filter payments by transaction key
export function filterPaymentsByTransactionKey(payments, key) {
  return payments.filter((payment) => {
    return payment.transaction_key === key;
  });
}

// get surcharge payments
export function getSurchargePayments(payments, orderTransactionKey, surchargeTransactionKey) {
  let surchargePayments = filterPaymentsByTransactionKey(payments, surchargeTransactionKey);
  if (!surchargePayments.length) {
    surchargePayments = filterPaymentsByTransactionKey(payments, orderTransactionKey);
  }
  return surchargePayments;
}

// get addon payments
export function getAddonPayments(payments, orderTransactionKey, addonTransactionKey) {
  let addonPayments = filterPaymentsByTransactionKey(payments, addonTransactionKey);
  if (!addonPayments.length) {
    addonPayments = filterPaymentsByTransactionKey(payments, orderTransactionKey);
  }
  return addonPayments;
}

// calculate refund data by type

export function calculateRefundDataByType({ type, refund, amount, fee }) {
  let data = {};
  let keys = [];

  if (type == 'item') {
    keys.push('item_metadata');
  } else if (type == 'surcharge') {
    keys.push('surcharge_metadata');
  } else {
    keys.push('item_metadata');
    keys.push('surcharge_metadata');
    data['tmp'] = {
      amount: '',
      fee: '',
    };
  }

  let feeAmount = parseFloat(fee ? fee : 0).toFixed(2);
  let inputs = amount ? { amount: parseFloat(amount).toFixed(2), fee: feeAmount } : null;

  keys.forEach((key) => {
    if (refund[key].initial_amount > refund[key].refund_fee) {
      data[key] = Object.assign({}, refund[key], {
        cash_amount: calcCashAmount({
          payments: refund[key].payments,
          initialAmount: refund[key].initial_amount,
          promoAmount: refund[key].promo_amount,
          items: refund.items,
          inputs: inputs,
          includePromo: refund[key].includePromo,
        }),
        accounting_amount: calcAccountingAmount({
          payments: refund[key].payments,
          initialAmount: refund[key].initial_amount,
          promoAmount: refund[key].promo_amount,
          items: refund.items,
          includePromo: refund[key].includePromo,
          inputs:
            inputs &&
            Object.assign(inputs, {
              initial_amount: refund[key].initial_amount,
            }),
        }),
        fee: feeAmount,
      });
    }
  });

  return data;
}

// Calculate refund data
// Tests located here: src/utils/tests/refund.calcRefundDataByType.test.js

export function calcRefundDataByType(params) {
  const { itemId, salePrice, payments, refundMeta, includePromo, items } = params;

  let promo_amount = 0;

  if (includePromo) {
    promo_amount = parseFloat(calcPromoPercentage(payments, items) * salePrice).toFixed(2);
  }

  let refund_fee = calcTotalRefundFee(refundMeta, itemId);

  let initial_amount = calcInitialAmount({
    chargeComponentKey: itemId,
    salesPrice: salePrice,
    refundMetadata: refundMeta,
  });

  let cash_amount = 0;
  let accounting_amount = 0;

  if (initial_amount - refund_fee > 0) {
    cash_amount = calcCashAmount({
      payments: payments,
      initialAmount: initial_amount,
      promoAmount: promo_amount,
      includePromo: includePromo,
      items: items,
    });

    accounting_amount = calcAccountingAmount({
      payments: payments,
      initialAmount: initial_amount,
      promoAmount: promo_amount,
      includePromo: includePromo,
      items: items,
    });
  }

  const result = {
    cash_amount: cash_amount,
    cash_amount_original: cash_amount,
    accounting_amount: accounting_amount,
    initial_amount: initial_amount,
    promo_amount: promo_amount,
    refund_fee: refund_fee,
  };

  return result;
}

export function refundData(properties) {
  if (properties.sale_price) {
    const selectedAddon = properties.order.addon_items.find((addon) => addon.id === properties.item_id);
    if (selectedAddon && selectedAddon.channel_booking_id) {
      const allItemsForBooking = properties.order.addon_items.filter(
        (addon) => addon.channel_booking_id === selectedAddon.channel_booking_id,
      );
      if (allItemsForBooking.length > 1) {
        const totalCost = allItemsForBooking.reduce((total, current) => total + current.total, 0);
        properties.sale_price = totalCost;
      }
    }
  }

  if (properties.has_surcharge) {
    properties.surcharge_metadata = Object.assign(
      {},
      properties.surcharge_metadata,
      calcRefundDataByType({
        itemId: properties.surcharge_metadata.charge_component_key,
        salePrice: properties.surcharge_price,
        payments: properties.surcharge_metadata.payments,
        refundMeta: properties.refund_meta,
        items: [],
        includePromo: properties.surcharge_metadata.includePromo,
      }),
    );
  }

  properties.item_metadata = Object.assign(
    {},
    properties.item_metadata,
    calcRefundDataByType({
      itemId: properties.item_metadata.charge_component_key,
      salePrice: properties.total_sale_price,
      payments: properties.item_metadata.payments,
      items: properties.items,
      refundMeta: properties.refund_meta,
      includePromo: properties.item_metadata.includePromo,
    }),
  );

  return properties;
}

// this method return the package costPrice in currency which was made order
export function packageCostPriceInSaleCurrencyCode(refund) {
  const {
    currency_code: salePriceCurrencyCode,
    package_cost_price_data: {
      cost_price_currency: costPriceCurrencyCode,
      cost_price: costPrice,
      sale_price: salePriceInCostCurrency,
    },
    package_sale_price: salePrice,
  } = refund;

  let costPriceInSaleCurrencyCode = costPrice;

  if (salePriceCurrencyCode !== costPriceCurrencyCode) {
    costPriceInSaleCurrencyCode = (costPrice * salePrice) / salePriceInCostCurrency;
  }

  return parseFloat(costPriceInSaleCurrencyCode).toFixed(2);
}

// this method return the extra nights costPrice in currency which was made order
export function extraNightsCostPriceInSaleCurrencyCode(refund) {
  const {
    currency_code: salePriceCurrencyCode,
    extra_nights_cost_price_data: {
      cost_price_currency: costPriceCurrencyCode,
      cost_price: costPrice,
      sale_price: salePriceInCostCurrency,
    },
    extra_nights_sale_price: salePrice,
  } = refund;

  let costPriceInSaleCurrencyCode = costPrice;

  if (salePriceCurrencyCode !== costPriceCurrencyCode) {
    costPriceInSaleCurrencyCode = (costPrice * salePrice) / salePriceInCostCurrency;
  }

  // Extra nights pricing is per night unlike packages which is the total
  costPriceInSaleCurrencyCode = costPriceInSaleCurrencyCode * refund.extra_nights;

  return parseFloat(costPriceInSaleCurrencyCode).toFixed(2);
}

// this method return costPrice in currency which was made order
export function costPriceInSaleCurrencyCode(refund) {
  const packageCostPriceOriginal = packageCostPriceInSaleCurrencyCode(refund);

  let extraNightsCostPriceOriginal = 0;

  if (refund.extra_nights) {
    extraNightsCostPriceOriginal = extraNightsCostPriceInSaleCurrencyCode(refund);
  }

  const costPrice =
    (parseFloat(packageCostPriceOriginal) + parseFloat(extraNightsCostPriceOriginal)) / refund.total_nights;

  return costPrice;
}

function getTmpRefundAmount(refund, data) {
  let refundAmount = refund.tmp.amount;
  if (typeof data.tmp !== 'undefined' && typeof data.tmp.amount !== 'undefined') {
    refundAmount = data.tmp.amount;
  }

  return refundAmount;
}

function isUpdateAmount(data) {
  let updated = false;

  if (
    typeof data.number_of_nights !== 'undefined' ||
    typeof data.percentage !== 'undefined' ||
    (typeof data.variant_of_refund !== 'undefined' && data.variant_of_refund === VARIANT_OF_REFUND_PARTIAL)
  ) {
    updated = true;
  }

  return updated;
}

function calculateMaxAmountByPackageNights(refund, data) {
  const refundAmountOriginal = refund.item_metadata.cash_amount_original;
  const refundCostPrice = costPriceInSaleCurrencyCode(refund) * refund.number_of_nights;

  let refundAmount = getTmpRefundAmount(refund, data);

  const isUpdatedAmount = isUpdateAmount(data);

  const newRefundAmount = parseFloat(refundAmountOriginal - refundCostPrice).toFixed(2);
  if (newRefundAmount < Number(refundAmount) || isUpdatedAmount) {
    refundAmount = newRefundAmount;
  }

  if (refundAmount < 0) {
    return 0;
  }

  return refundAmount;
}

function calculateMaxAmountByPercentage(refund, data) {
  if (refund.variant_of_refund === VARIANT_OF_REFUND_NONE) return 0;

  const refundAmountOriginal = refund.item_metadata.cash_amount_original;
  const isUpdatedAmount = isUpdateAmount(data);
  const costPriceOriginal = costPriceInSaleCurrencyCode(refund) * refund.total_nights;

  const refundCostPrice = parseFloat(refund.percentage * (costPriceOriginal / 100)).toFixed(2);

  let refundAmount = getTmpRefundAmount(refund, data);

  const newRefundAmount = parseFloat(refundAmountOriginal - refundCostPrice).toFixed(2);
  if (newRefundAmount < Number(refundAmount) || isUpdatedAmount) {
    refundAmount = newRefundAmount;
  }

  if (refundAmount < 0) {
    return 0;
  }

  return refundAmount;
}

// this method calculate maximum of amount refund witch we can allow for refunding
export function calculateMaxAmountOfRefund(refund, data) {
  if (refund.variant_of_refund === VARIANT_OF_REFUND_NONE) return 0;

  if (
    refund.variant_of_refund === VARIANT_OF_REFUND_PARTIAL &&
    refund.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS
  ) {
    if (refund.missing_cost_price_data && refund.refund_vendor_agrees_to) {
      throw new Error("Can't perform custom refund with cost price data missing");
    }
    if (
      refund.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_NUMBER &&
      refund.number_of_nights > 0 &&
      refund.number_of_nights <= refund.total_nights
    ) {
      return calculateMaxAmountByPackageNights(refund, data);
    } else if (
      refund.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_PERCENTAGE &&
      refund.percentage > 0 &&
      refund.percentage <= 100
    ) {
      return calculateMaxAmountByPercentage(refund, data);
    }

    return 0;
  }

  return null;
}
