import React, { useEffect, useState } from 'react';

import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

import { Grid, Stack, Typography } from '@mui/material';

import { getRecordsDataByUrl } from '~/services/FlightsService';

import DateWidget from '../Common/Forms/DateWidget';

interface graphType {
  key: string;
  colour: string;
}

interface groupByProviderData {
  name: string;
  jetstar: number;
  amadeus: number;
  travelFusion: number;
}

interface groupByRegionData {
  name: string;
  AU: number;
  IN: number;
  HK: number;
  US: number;
  SG: number;
  GB: number;
  NZ: number;
  DE: number;
}

interface groupByStateData {
  name: string;
  confirmed: number;
  failed: number;
  abandoned: number;
  pending: number;
  created: number;
}

interface chartData {
  confirmedGroupByProvider: groupByProviderData[];
  confirmedGroupByRegion: groupByRegionData[];
  allGroupByState: groupByStateData[];
}

interface LineGraphProps {
  data: groupByProviderData[] | groupByRegionData[] | groupByStateData[];
  title: string;
  types: graphType[];
}

const providerTypes: graphType[] = [
  { key: 'jetstar', colour: '#8884d8' },
  { key: 'amadeus', colour: '#f784d8' },
  { key: 'travelFusion', colour: '#000000' },
];

const regionTypes: graphType[] = [
  { key: 'AU', colour: '#474B4E' },
  { key: 'IN', colour: '#A65E2E' },
  { key: 'HK', colour: '#5E2129' },
  { key: 'US', colour: '#287233' },
  { key: 'SG', colour: '#2F353B' },
  { key: 'GB', colour: '#B32821' },
  { key: 'NZ', colour: '#587246' },
  { key: 'DE', colour: '#C35831' },
];

const stateTypes: graphType[] = [
  { key: 'confirmed', colour: '#1CBF06' },
  { key: 'pending', colour: '#FF5733' },
  { key: 'abandoned', colour: '#000000' },
  { key: 'failed', colour: '#C60615' },
  { key: 'created', colour: '#0AF6F9' },
];

export const FlightDashboard = () => {
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState('Loading...');
  const year = date.getFullYear().toString();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const yearLabel = `By each month for ${year}`;
  const monthLabel = `By each date for ${year}-${month}`;

  const [monthData, setMonthData] = useState({
    confirmedGroupByProvider: [],
    confirmedGroupByRegion: [],
    allGroupByState: [],
  });

  const [yearData, setYearData] = useState({
    confirmedGroupByProvider: [],
    confirmedGroupByRegion: [],
    allGroupByState: [],
  });

  useEffect(() => {
    getRecordsDataByUrl('getDashboardData', {
      year,
      month,
    })
      .then((response) => {
        const data: chartData = response.result;
        setMonthData(data);
        setError('');
      })
      .catch(() => {
        setError(`Error loading data for ${year}-${month}`);
      });
  }, [date.getFullYear(), date.getMonth()]);

  useEffect(() => {
    getRecordsDataByUrl('getDashboardData', {
      year,
    })
      .then((response) => {
        const data: chartData = response.result;
        setYearData(data);
        setError('');
      })
      .catch(() => {
        setError(`Error loading data for ${year}`);
      });
  }, [date.getFullYear()]);

  if (error) {
    return <Typography variant="h4">{error}</Typography>;
  }

  return (
    <Grid container spacing={4}>
      <Grid item md={3}>
        <Typography variant="h6">Select Date</Typography>
        <DateWidget
          value={date}
          onChange={(newDate) => {
            setDate(new Date(newDate));
          }}
        />
      </Grid>
      <Grid item md={9} />
      <Grid item md={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6">Confirmed reservations by region</Typography>
        <Stack direction="row" spacing={4}>
          <LineGraph title={monthLabel} data={monthData.confirmedGroupByRegion} types={regionTypes} />
          <LineGraph title={yearLabel} data={yearData.confirmedGroupByRegion} types={regionTypes} />
        </Stack>
      </Grid>
      <Grid item md={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6">Confirmed reservations by provider</Typography>{' '}
        <Stack direction="row">
          <LineGraph title={monthLabel} data={monthData.confirmedGroupByProvider} types={providerTypes} />
          <LineGraph title={yearLabel} data={yearData.confirmedGroupByProvider} types={providerTypes} />
        </Stack>
      </Grid>
      <Grid item md={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6">All reservations by state</Typography>{' '}
        <Stack direction="row">
          <LineGraph title={monthLabel} data={monthData.allGroupByState} types={stateTypes} />
          <LineGraph title={yearLabel} data={yearData.allGroupByState} types={stateTypes} />
        </Stack>
      </Grid>
    </Grid>
  );
};

const LineGraph = ({ data, title, types }: LineGraphProps) => {
  return (
    <div>
      <Typography variant="subtitle1">{title}</Typography>
      <LineChart
        width={700}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        {types.map((type, idx) => (
          <Line key={`line_${idx}`} yAxisId="left" type="monotone" dataKey={type.key} stroke={type.colour} />
        ))}
      </LineChart>
    </div>
  );
};

export default FlightDashboard;
