import React, { useCallback, useState } from 'react';

import { Helmet } from 'react-helmet';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CardContent, Container, Stack } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import TourReviewForm from '~/components/Common/Forms/TourReviewForm';

import generateRandomString from '~/utils/generateRandomString';

export default function TourReviewsEdit({ tour, vendorId, vendor, ...props }) {
  const [reviewsData, setReviewsData] = useState(props.reviewsData);

  const addNewTourReview = useCallback(() => {
    setReviewsData((prevValue) => [...prevValue, { _id: generateRandomString() }]);
  }, []);

  const removeTourReview = (formKey) => {
    setReviewsData((prevValue) => prevValue.filter((review) => review.id !== formKey));
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor.name || vendorId} | {tour.name} | Edit Tour Reviews
        </title>
      </Helmet>

      <PageHeader title="Edit Tour Reviews" backButton={`/vendors/${vendorId}/tours/${props.tourId}`} />

      <Stack direction="column" spacing={2}>
        {reviewsData.map((review, i) => (
          <Card variant="outlined" key={i}>
            <CardContent>
              <TourReviewForm
                tour={tour}
                tourId={props.tourId}
                formKey={review.id}
                reviewData={review}
                schema={props.schema}
                onRemoveTourReview={removeTourReview}
              />
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Box mt={2}>
        <Button startIcon={<AddIcon />} variant="contained" onClick={addNewTourReview}>
          Add
        </Button>
      </Box>
    </Container>
  );
}
