import React, { Component } from 'react';

import classNames from 'clsx';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import ReservationService from '../../../services/ReservationService';
import { formatValidationMessages } from '../../../utils/formatValidationMessages';
import { reportError } from '../../../utils/reportError';
import ErrorListDisplay from '../ErrorListDisplay';

import { buttonMessages, buttonStates } from './states/submitButton';

class IncludedGuestForm extends Component {
  constructor(props) {
    super(props);

    const includedGuest = props.includedGuest
      ? // deep clone
        JSON.parse(JSON.stringify(props.includedGuest))
      : { adults: 0, children: 0, infants: 0 };

    this.state = {
      includedGuest,
      saveState: buttonStates.default,
      errors: null,
    };
  }

  setValue(key, value) {
    let newBlock = this.state.includedGuest;
    newBlock[key] = value;

    this.setState({
      includedGuest: newBlock,
    });
  }

  submit() {
    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    if (this.state.includedGuest.id) {
      ReservationService.updateRoomTypeIncludedGuest(
        this.state.includedGuest,
        this.state.includedGuest.id,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      )
        .then((response) => {
          this.setState({
            saveState: buttonStates.saved,
            errors: null,
          });
          this.props.onRoomTypeIncludedGuestUpdated(response.result);
        })
        .catch((e) => {
          const errors = e.name === 'ValidationError' ? e.errors : null;
          this.setState({
            saveState: buttonStates.failed,
            errors,
          });
          reportError(e);
        });
    } else {
      ReservationService.createRoomTypeIncludedGuest(
        this.state.includedGuest,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      )
        .then((response) => {
          this.setState({
            saveState: buttonStates.saved,
          });
          this.props.onRoomTypeIncludedGuestAdded(response.result);
        })
        .catch((e) => {
          const errors = e.name === 'ValidationError' ? e.errors : null;
          this.setState({
            saveState: buttonStates.failed,
            errors,
          });
          reportError(e);
        });
    }
  }

  formRow(label, fieldName) {
    return (
      <div className="row capacity-form-row">
        <label className="col-sm-9">{label}</label>
        <div className="col-sm-3">
          <input
            type="number"
            step="1"
            min="0"
            value={this.state.includedGuest[fieldName]}
            onChange={(event) => this.setValue(fieldName, parseInt(event.target.value))}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="capacity-form form-horizontal">
        {this.formRow('Adults', 'adults')}
        {this.formRow('Children', 'children')}
        {this.formRow('Infants', 'infants')}

        <div className="modal-form-group">
          {this.state.errors && <ErrorListDisplay messages={formatValidationMessages(this.state.errors)} />}
          <Button
            type="submit"
            variant="contained"
            className={classNames(this.state.saveState, 'pull-right')}
            onClick={this.submit.bind(this)}
          >
            {buttonMessages[this.state.saveState]}
          </Button>
          <Button onClick={this.props.onCancel}>Cancel</Button>
        </div>
      </div>
    );
  }
}

IncludedGuestForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onRoomTypeIncludedGuestAdded: PropTypes.func.isRequired,
  onRoomTypeIncludedGuestUpdated: PropTypes.func.isRequired,
  includedGuest: PropTypes.object,
};

export default IncludedGuestForm;
