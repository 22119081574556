import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface Props {
  description: string;
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

export const ConfirmDeleteDialog = ({ description, isOpen, handleClose, handleDelete }: Props) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Are you sure you want to delete this custom offer?</DialogTitle>
      {description && <DialogContent>{description}</DialogContent>}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
