import React, { MouseEventHandler, useCallback, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import ArchivedPromotionUpdateDialog from './ArchivedPromotionUpdateDialog';

interface Props {
  promotions: App.Bedbank.PromotionsEdit[];
  canUpdateArchivedPromotions: boolean;
  handleArchivedPromotionUpdate: (promotion: App.Bedbank.PromotionsEdit) => void;
}

export default function ArchivedPromotionPane(props: Props) {
  const { promotions, canUpdateArchivedPromotions, handleArchivedPromotionUpdate } = props;
  const {
    isToggled: isUpdatePromotionDialogOpen,
    toggleOn: openUpdatePromotionDialog,
    toggleOff: closeUpdatePromotionDialog,
  } = useToggleState();
  const [promoToUpdate, setPromoToUpdate] = useState<App.Bedbank.PromotionsEdit | undefined>(undefined);

  const handleUpdatePromotionClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const promoId = String(event.currentTarget.dataset['promoid']);
      const promotion = Object.values(promotions).find((p) => p.promoId === promoId);
      setPromoToUpdate(promotion);
      openUpdatePromotionDialog();
    },
    [openUpdatePromotionDialog, promotions],
  );

  return (
    <Box>
      {promotions.map((promo) => (
        <Accordion variant="outlined" key={promo.promoId}>
          <AccordionSummary expandIcon={<ExpandMore />}>{promo.promoName}</AccordionSummary>

          <AccordionDetails>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Promotion Name</Typography>
              <Typography>{promo.promoName}</Typography>
              <Typography variant="h6">Promotion Sell Period</Typography>
              <Typography>Start Date: {promo.promoPeriodSchedule?.promoPeriodFrom}</Typography>
              <Typography>End Date: {promo.promoPeriodSchedule?.promoPeriodTo}</Typography>
              {canUpdateArchivedPromotions && (
                <div>
                  <Button variant="text" data-promoid={promo.promoId} onClick={handleUpdatePromotionClick}>
                    Update promotion dates
                  </Button>
                </div>
              )}
              <Typography variant="h6">Promotion Travel Period</Typography>
              <Typography>Start Date: {promo.travelPeriod?.travelPeriodFrom}</Typography>
              <Typography>End Date: {promo.travelPeriod?.travelPeriodTo}</Typography>
              <Typography variant="h6">Minimum Length of Stay</Typography>
              <Typography>{promo.los}</Typography>
              <Typography variant="h6">Rate Inclusions</Typography>
              {/* https://github.com/mui/material-ui/issues/9189 */}
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>{promo.rateInclusionsLong}</Typography>
              <Typography variant="h6">Connected rates</Typography>
              {promo.restrictToRates.map((rateId) => (
                <li key={rateId}>{rateId}</li>
              ))}
              <Typography variant="h6">Inclusions</Typography>
              {promo.inclusions.map((inclusion) => (
                <li key={inclusion.text}>
                  {inclusion.icon} | {inclusion.text}
                </li>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
      {promoToUpdate && (
        <ArchivedPromotionUpdateDialog
          promotion={promoToUpdate}
          isOpen={isUpdatePromotionDialogOpen}
          onClose={closeUpdatePromotionDialog}
          handleArchivedPromotionUpdate={handleArchivedPromotionUpdate}
        />
      )}
    </Box>
  );
}
