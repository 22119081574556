import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { Box } from '@mui/material';

import { fetchBadges, submitPatchForm, submitPostForm } from '~/actions/badges';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import BadgesForm from './BadgesForm';
import BadgesPage from './BadgesPage';

export const TAG_ICONS = [
  { key: 'Tick in a Circle', value: 'tick_in_circle' },
  { key: 'Ticket', value: 'ticket' },
  { key: 'Award', value: 'award' },
];

interface BadgesContainerProps {
  error?: { message?: string };
  success: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  fetchBadges: () => void;
  submitPostForm: (badge: App.Badge) => void;
  items: Array<App.Badge>;
}

const BadgesContainer = ({
  error,
  success,
  isLoading,
  isUpdating,
  fetchBadges,
  submitPostForm,
  items: propBadges,
}: BadgesContainerProps) => {
  const sizePerPage = 5;

  useEffect(() => {
    fetchBadges();
  }, [fetchBadges]);

  const checkUniqueName = (name: string) => {
    const uniqueName = !propBadges.find((b) => b.name === name);
    return uniqueName;
  };

  return (
    <div className="badges T-badges-page">
      <PageSubheader title="Add badge" />

      <BadgesForm
        tag_icons={TAG_ICONS}
        error={error}
        success={success}
        isLoading={isLoading}
        onBadgeSubmit={submitPostForm}
        checkUniqueName={checkUniqueName}
      />

      <Box mt={4}>
        <PageSubheader title="Badges" />
      </Box>

      {(isLoading || isUpdating) && <Spinner />}
      <BadgesPage badges={propBadges} sizePerPage={sizePerPage} />
    </div>
  );
};

const mapStateToProps = (state: App.State) => {
  const { isLoading, isUpdating, error, success, items } = state.badges;

  return {
    error,
    success,
    isLoading,
    isUpdating,
    items,
  };
};

const mapDispatchToProps = {
  fetchBadges,
  submitPatchForm,
  submitPostForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgesContainer);
