import React, { useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  styled,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { regions as allRegions } from '@luxuryescapes/lib-regions/lib/regions';

import PlaceLookUp from '~/components/Common/Forms/PlaceLookUp';
import Image from '~/components/Common/Image';
import { TRENDING_PLACES_BRANDS } from '~/components/Content/TrendingPlaces/TrendingPlacesPage';
import { TYPEAHEAD_PLACE_TYPES } from '~/components/Content/TrendingPlaces/constants';
import { handleChangeUtil } from '~/components/Content/TrendingPlaces/utils';

import { uploadImage } from '~/services/ImageService';
import SearchService, { TypeaheadSearchResult } from '~/services/SearchService';

type Props = {
  onClose: () => void;
};

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledButton = styled(IconButton)`
  margin-left: 10px;
`;

const AddTrendingPlaceForm = ({ onClose }: Props) => {
  const [place, setPlace] = useState<TypeaheadSearchResult | null>(null);
  const [manualPosition, setManualPosition] = useState<number | null>(null);
  const [imageId, setImageId] = useState<string | null>(null);
  const [brands, setBrands] = useState<Array<string>>([]);
  const [regions, setRegions] = useState<Array<string>>([]);
  const allRegionCodes = allRegions['luxuryescapes'].map((region) => region.code);
  const allBrandValues = TRENDING_PLACES_BRANDS.map((region) => region.value);
  const [failedItem, setFailedItem] = useState<Array<{ brand: string; region: string }>>([]);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const handleSelectedPlace = useCallback((selection: TypeaheadSearchResult | undefined) => {
    setPlace(selection);
  }, []);

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    const res = await uploadImage(file);
    setImageId(res.body.public_id);
  };

  const handleManualPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // Allow only integers starting at 1
    if (inputValue === '' || (Number.isInteger(Number(inputValue)) && Number(inputValue) >= 1)) {
      setManualPosition(Number(inputValue));
    } else {
      setManualPosition(null);
    }
  };

  const handleBrandChange = (event: SelectChangeEvent<string[]>) => {
    event.preventDefault();
    const newBrands = handleChangeUtil({
      eventValue: event.target.value as string[],
      currentValues: brands,
      allValues: allBrandValues,
    });

    setBrands(newBrands);
  };

  const handleRegionChange = (event: SelectChangeEvent<string[]>) => {
    event.preventDefault();
    const newRegions = handleChangeUtil({
      eventValue: event.target.value as string[],
      currentValues: regions,
      allValues: allRegionCodes,
    });

    setRegions(newRegions);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const failedToAdd: Array<{ brand: string; region: string }> = [];
    for (const region of regions.filter((region) => region !== 'selectAll')) {
      for (const brand of brands.filter((region) => region !== 'selectAll')) {
        try {
          const { status } = await SearchService.upsertTrendingPlace(place.id, brand, region, imageId, manualPosition);

          if (status !== 200) {
            failedToAdd.push({
              brand: TRENDING_PLACES_BRANDS.find((item) => item.value === brand).name,
              region: allRegions['luxuryescapes'].find((item) => item.code === region).name,
            });
          }
        } catch (error) {
          failedToAdd.push({ brand, region });
        }
      }
    }

    if (failedToAdd.length > 0) {
      setFailedItem(failedToAdd);
      return;
    }

    setSuccessDialogOpen(true);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    onClose();
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 3 }}>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" gap={4}>
          <PlaceLookUp onCurrentValueChange={handleSelectedPlace} types={TYPEAHEAD_PLACE_TYPES} label="Place" />

          <TextField
            label="Manual Position"
            type="number"
            value={manualPosition || ''}
            onChange={handleManualPositionChange}
            inputProps={{ min: 1, step: 1 }}
            variant="outlined"
            helperText={'A manual position of 1 is at the top of the results'}
            required
            fullWidth
          />

          <FormControl fullWidth variant="outlined">
            <InputLabel id="regions-select-label">Select Regions (Required)</InputLabel>
            <Select
              labelId="regions-select-label"
              multiple
              value={regions}
              onChange={handleRegionChange}
              renderValue={(selected) => selected.filter((region) => region !== 'selectAll').join(', ')}
              label="Select Regions (Required)"
              required
            >
              {[{ name: 'Select All', code: 'selectAll' }, ...allRegions['luxuryescapes']].map((region) => (
                <MenuItem key={region.code} value={region.code}>
                  <Checkbox checked={regions.indexOf(region.code) > -1} />
                  <ListItemText primary={region.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel id="brands-select-label">Select Brands (Required)</InputLabel>
            <Select
              labelId="brands-select-label"
              multiple
              value={brands}
              onChange={handleBrandChange}
              renderValue={(selected) => selected.filter((brand) => brand !== 'selectAll').join(', ')}
              label="Select Brands (Required)"
              required
            >
              {[{ name: 'Select All', value: 'selectAll' }, ...TRENDING_PLACES_BRANDS].map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  <Checkbox checked={brands.indexOf(brand.value) > -1} />
                  <ListItemText primary={brand.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!imageId && (
            <Box display="flex" justifyContent="flex-start">
              <Button variant="outlined" component="label">
                Upload Image
                <VisuallyHiddenInput onChange={(event) => onImageChange(event)} type="file" />
              </Button>
            </Box>
          )}

          <Box display="flex" alignItems="center" gap={2}>
            {imageId && <Image options={{ width: 150, dpr: 3 }} publicId={imageId} loading="lazy" />}
            {imageId && (
              <StyledButton sx={{ height: 10 }} onClick={() => setImageId(undefined)}>
                <CloseIcon />
              </StyledButton>
            )}
          </Box>
          {!!failedItem.length && (
            <Alert severity="error">
              <strong>Failed to add the following combinations:</strong>
              <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
                {failedItem.map((item, index) => (
                  <li key={index}>
                    <strong> - Brand:</strong> {item.brand}
                    <strong> | Region:</strong> {item.region}
                  </li>
                ))}
              </ul>
            </Alert>
          )}
          <Stack direction="column" gap={2}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>

      <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <Alert severity="success">
            <strong>Successfully added place:</strong> {place?.format?.mainText}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AddTrendingPlaceForm;
