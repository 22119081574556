import qs from 'qs';

import { actions, definitions } from '@luxuryescapes/contract-svc-accommodation';

import { json_headers, request } from './common';
import { HttpResponse } from './types';

export type PropertyResponse = definitions['AdminProperty'];
export type PropertyDetailResponse = definitions['AdminPropertyDetail'];
export type ImportPropertyResponse = definitions['StatusLookup'];
export type ProgressStatusResponse = definitions['StatusResponse'];
export type SupplierProperty = definitions['AdminSupplierProperty'];
export type InternalSupplier = definitions['InternalSupplier'];
export type RoomResponse = definitions['AdminRoom'];

function basePath() {
  return window.configs.LUX_API_DOMAIN + '/api/accommodation';
}

function baseAdminPath() {
  return window.configs.LUX_API_DOMAIN + '/api/accommodation/admin';
}

export const listProperties = ({
  limit,
  page,
}: actions['listAdminProperties']['parameters']['query']): Promise<
  HttpResponse<{ total: number; properties: Array<PropertyResponse> }>
> => {
  const url = `${baseAdminPath()}/properties?${qs.stringify({ limit, page })}`;
  return request(url, {
    method: `GET`,
  });
};

export const getProperty = ({
  propertyId,
}: actions['getAdminProperty']['parameters']['path']): Promise<HttpResponse<PropertyDetailResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}`;
  return request(url, {
    method: `GET`,
  });
};

export const listPropertySuppliers = ({
  propertyId,
}: actions['getAdminSupplierProperties']['parameters']['path']): Promise<HttpResponse<Array<SupplierProperty>>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/suppliers`;
  return request(url, {
    method: 'GET',
  });
};

export const patchPropertySupplier = (
  { propertyId, supplierId }: actions['patchAdminSupplierProperty']['parameters']['path'],
  data: actions['patchAdminSupplierProperty']['parameters']['body']['AdminPatchSupplierProperty'],
): Promise<HttpResponse<SupplierProperty>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/suppliers/${supplierId}`;
  return request(url, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(data),
  });
};

export const listRooms = ({
  propertyId,
}: actions['getAdminRooms']['parameters']['path']): Promise<HttpResponse<Array<RoomResponse>>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms`;
  return request(url, {
    method: 'GET',
  });
};

export const importProperty = (
  propertyId: string,
  internalSupplier: InternalSupplier,
): Promise<HttpResponse<ProgressStatusResponse>> => {
  const url = `${baseAdminPath()}/import-property`;
  return request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({ propertyId, internalSupplier }),
  });
};

export const importSupplier = (
  basePropertyId: string,
  propertyId: string,
  internalSupplier: InternalSupplier,
): Promise<HttpResponse<ProgressStatusResponse>> => {
  const url = `${baseAdminPath()}/properties/${basePropertyId}/suppliers`;
  return request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({ propertyId, internalSupplier }),
  });
};

export const getProgressStatuses = (id: string): Promise<HttpResponse<Array<ProgressStatusResponse>>> => {
  const url = `${basePath()}/progress/statuses?${qs.stringify({ id })}`;
  return request(url, {
    method: `GET`,
  });
};
