import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addOffer as addOfferAction, addReservationFXRatesToCart as addRatesAction } from '~/actions/cart';

import OfferSearchForm from '~/components/Common/Forms/OfferSearchForm';
import OfferList from '~/components/Common/OfferList';
import Spinner from '~/components/Common/Spinner';

import { FILTER_BROWSABLE } from '~/consts/filters';
import { ADMIN_PURCHASE_SUPPORTED_OFFER_TYPES, OFFER_TYPE_HOTEL, TOUR_OFFER_TYPES } from '~/consts/offerTypes';

import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

import { preloadRoomType } from '~/utils/preloadOfferAssociations';

import OrderFooter from '../OrderFooter';

interface OfferSelectionProps {
  addOffer: (offer: App.Offer) => void;
  addReservationFXRatesToCart: (data: { reservationFXRates: unknown }) => void;
  backStep: () => void;
  cart: App.Cart;
  customer: App.User;
  nextStepLabel: string;
  proceedStep: () => void;
  tenant: App.Tenant;
}

export function OfferSelection({
  addOffer,
  addReservationFXRatesToCart,
  backStep,
  cart,
  nextStepLabel,
  proceedStep,
  tenant,
}: OfferSelectionProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);
  const [offers, setOffers] = useState<App.Offer[]>([]);
  const [page, setPage] = useState<number>(1);
  const [queryString, setQueryString] = useState<string>(null);
  const [total, setTotal] = useState<number>(0);
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    OffersService.getAllLocations().then((response: App.LocationsResponse) => {
      setDestinations(response.result || []);
    });
  }, []);

  useEffect(() => {
    ReservationService.getFXRates().then((rates) => {
      addReservationFXRatesToCart({
        reservationFXRates: rates.result,
      });
    });
  }, [addReservationFXRatesToCart]);

  const onPageChange = (page: number) => {
    searchOffers(queryString, page);
  };

  const searchOffers = (queryString: string, page = 1) => {
    const { brand } = tenant;

    setLoading(true);
    setOffers(null);
    setQueryString(queryString);
    setPage(page);

    OffersService.searchOffers({
      queryString: window.configs.BOOKING_FLOW_V2 === 'true' ? `${queryString}&page=${page}` : queryString,
      brand,
    })
      .then(({ result, total }) => {
        setLoading(false);
        setOffers(result);
        setTotal(total || result.length);
      })
      .catch(() => {
        setLoading(false);
        setOffers(null);
        setTotal(0);
      });
  };

  const canAddOffer = (offer: App.Offer) => {
    if (loadingOffer) return false;

    if (cart.offers && cart.offers.length >= (window.configs.MAXIMUM_OFFERS_PER_ORDER || 1)) return false;

    if (window.configs.BOOKING_FLOW_V2 === 'true') {
      const offerExistsInCart = !!cart.offers.find(
        (cartOffer) => cartOffer.id_salesforce_external === offer.id_salesforce_external,
      );

      if (offerExistsInCart) return false;
    }

    if (
      cart.offers.length &&
      (cart.offers.some((o) => TOUR_OFFER_TYPES.some((ot) => ot === o.type)) ||
        TOUR_OFFER_TYPES.some((ot) => ot === offer.type))
    )
      return false;

    return ADMIN_PURCHASE_SUPPORTED_OFFER_TYPES.some((ot) => ot === offer.type);
  };

  const handleSelectOffer = ({ id_salesforce_external }) => {
    setLoadingOffer(true);

    OffersService.getOffer(id_salesforce_external, {
      filter: FILTER_BROWSABLE,
    })
      .then(async ({ result }) => {
        const promises = [];

        // preload associations of the offer
        result.packages.forEach((offerPackage: App.Package) => {
          if (result.type === OFFER_TYPE_HOTEL) {
            promises.push(preloadRoomType(offerPackage));
          }
        });

        await Promise.all(promises);
        return result;
      })
      .then((offer) => {
        addOffer(offer);
        setLoadingOffer(false);
      });
  };

  return (
    <div>
      {window.configs.BOOKING_FLOW_V2 === 'true' && (
        <OfferSearchForm destinations={destinations} searchQuery={searchOffers} />
      )}
      {window.configs.BOOKING_FLOW_V2 !== 'true' && (
        <OfferSearchForm destinations={destinations} searchQuery={searchOffers} minimal />
      )}
      {loading === true ? (
        <Spinner />
      ) : (
        <OfferList
          sizePerPage={offers === null ? 0 : offers.length}
          offers={offers?.map((o) => ({
            ...o,
            disableSelection: !canAddOffer(o),
          }))}
          total={offers === null ? 0 : total}
          page={page}
          onPageChange={window.configs.BOOKING_FLOW_V2 === 'true' ? onPageChange : null}
          onRowClick={handleSelectOffer}
          selectedOffers={cart.offers}
        />
      )}

      <OrderFooter backStep={backStep} cart={cart} proceedStep={proceedStep} canProceed nextStepLabel={nextStepLabel} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    tenant: state.tenant,
    customer: state.cart.customer,
    cart: state.cart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addOffer: addOfferAction,
      addReservationFXRatesToCart: addRatesAction,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferSelection);
