import React, { useEffect, useState } from 'react';

import fileDownload from 'react-file-download';

import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getRecordsDataByUrl } from '~/services/FlightsService';
import { getOrdersByFlightItems } from '~/services/OrdersService';
import {
  formatDateISOWithTime,
  formatDateMonth,
  formatDateReportsByDate,
  formatDateReportsByMonth,
} from '~/services/TimeService';

import { ConvertToCSV } from '~/utils/arrayUtils';

import DateWidget from '../Common/Forms/DateWidget';

export const PendingReservations = () => {
  const [date, setDate] = useState(new Date());
  const [pending, setPending] = useState([]);
  const [error, setError] = useState('Loading...');

  const getPendingRecords = (url, by) => {
    const year = String(date.getFullYear());
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    let fileName = `generated-on-${formatDateISOWithTime()}.csv`;
    let requestBody = {};
    switch (by) {
      case 'byDate':
        fileName = `pending-reservations-for-${formatDateReportsByDate(date)}-${fileName}`;
        requestBody = {
          year,
          month,
          day,
        };
        break;
      case 'byMonth':
        fileName = `pending-reservations-for-${formatDateReportsByMonth(date)}-${fileName}`;
        requestBody = {
          year,
          month,
        };
        break;
    }

    getRecordsDataByUrl(url, requestBody)
      .then((response) => {
        const reservationIds = response.result.map((r) => r.id);
        const transactionKeys = response.result.map((r) => r.transaction_key);

        Promise.all([
          getOrdersByFlightItems('reservation-id', reservationIds),
          getOrdersByFlightItems('transaction-key', transactionKeys),
        ])
          .then(([reservationIdResponse, transactionKeyResponse]) => {
            const reservationIdOrderMap = {};
            reservationIdResponse.forEach((record) => {
              reservationIdOrderMap[record.reservationId] = record.orderId;
            });

            const transactionKeyOrderMap = {};
            transactionKeyResponse.forEach((record) => {
              transactionKeyOrderMap[record.transactionKey] = record.orderId;
            });

            const data = response.result.map((reservation) => {
              const isReservationIdAvailable = reservationIdOrderMap[reservation.id];
              return {
                order_id: isReservationIdAvailable
                  ? reservationIdOrderMap[reservation.id]
                  : transactionKeyOrderMap[reservation.transaction_key],
                via: isReservationIdAvailable ? 'reservation-id' : 'transaction-key',
                ...reservation,
              };
            });

            setError('');
            const text = ConvertToCSV(data);
            fileDownload(text, fileName);
          })
          .catch((err) => {
            setError(`Error loading data due to ${err.message}`);
          });
      })
      .catch((err) => {
        setError(`Error loading data due to ${err.message}`);
      });
  };

  useEffect(() => {
    const year = String(date.getFullYear());
    const month = ('0' + (date.getMonth() + 1)).slice(-2);

    getRecordsDataByUrl('getPendingReservationCounts', {
      year,
      month,
    })
      .then((response) => {
        setPending(response.result);
        setError('');
      })
      .catch((err) => {
        setError(`Error loading data due to ${err.message}`);
      });
  }, [date.getFullYear(), date.getMonth()]);

  if (error) {
    return <Typography variant="h4">{error}</Typography>;
  }

  return (
    <Grid container spacing={3}>
      <Grid md={12}>
        <Typography variant="h4">Pending Reservations by Date</Typography>
      </Grid>
      <Grid md={4}>
        <Typography>Select Date</Typography>
        <DateWidget
          value={date}
          onChange={(newDate) => {
            setDate(new Date(newDate));
          }}
        />
      </Grid>
      <Grid md={8} />
      <Grid md={6}>
        <Typography mb={1}>
          Get Pending Reservations by created date (Export for {formatDateMonth(date)}-{date.getDate()})
        </Typography>
        <Button variant="contained" onClick={() => getPendingRecords('getPendingReservations', 'byDate')}>
          Export CSV
        </Button>
      </Grid>
      <Grid md={6}>
        <Typography mb={1}>Get Pending Reservations by created month (Export for {formatDateMonth(date)})</Typography>
        <Button variant="contained" onClick={() => getPendingRecords('getPendingReservations', 'byMonth')}>
          Export CSV
        </Button>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Pending Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pending.map((row, index) => (
            <TableRow key={`${row.created_at}-${index}`}>
              <TableCell component="th" scope="row">
                {row.created_at}
              </TableCell>
              <TableCell>{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default PendingReservations;
