import React from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Box, Button } from '@mui/material';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import { buttonMessages, buttonStates } from './states/submitButton';

const uiSchema = {
  content: {
    'ui:widget': 'textarea',
    'ui:title': 'Review Content',
  },
  source: {
    'ui:title': 'Source',
    'ui:placeholder': 'ex. TripAdvisor',
  },
};

type Props = {
  property: any;
  propertyId: string;
  formKey: string;
  reviewData: any;
  schema: RJSFSchema;
  onRemovePropertyReview: (formKey: string) => void;
};

type State = {
  property: any;
  reviewData: any;
  saveState: string;
};

export default class PropertyReviewForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDeleteReview = this.onDeleteReview.bind(this);

    this.state = {
      property: props.property,
      reviewData: props.reviewData,
      saveState: buttonStates.default,
    };
  }

  onSubmit(form: IChangeEvent) {
    this.setState({
      saveState: buttonStates.saving,
      reviewData: form.formData,
    });

    const propertyId = this.state.property.id;

    let submitType = 'createPropertyReview';
    let reviewId = null;
    if (this.state.reviewData.id) {
      submitType = 'updatePropertyReview';
      reviewId = this.state.reviewData.id;
    }

    ReservationService[submitType](form.formData, propertyId, reviewId)
      .then((response) => {
        this.setState({
          reviewData: response.result,
          saveState: buttonStates.saved,
        });
      })
      .catch((e) => {
        this.setState({ saveState: buttonStates.failed });
        reportError(e);
      });
  }

  onChange(edit: IChangeEvent) {
    this.setState({
      reviewData: edit.formData,
      saveState: buttonStates.default,
    });
  }

  onDeleteReview() {
    ReservationService.deletePropertyReview(this.state.property.id, this.state.reviewData.id)
      .then(() => {
        this.props.onRemovePropertyReview(this.props.formKey);
      })
      .catch((e) => {
        reportError(e);
      });
  }

  render() {
    return (
      <div>
        <Form
          schema={this.props.schema}
          formData={this.state.reviewData}
          uiSchema={uiSchema}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          validator={validator}
        >
          <Box mt={1}>
            <Button type="submit" variant="contained">
              {buttonMessages[this.state.saveState]}
            </Button>
          </Box>

          {this.state.reviewData.id ? <ConfirmButton onConfirm={this.onDeleteReview}>Delete</ConfirmButton> : null}
        </Form>
      </div>
    );
  }
}
