import React, { useEffect, useState } from 'react';

import _isEqual from 'lodash/isEqual';
import Form from 'react-jsonschema-form';
import { RouteComponentProps } from 'react-router-dom';

import { Button } from '@mui/material';

import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';

import {
  createOrUpdateTravellerDetailRequirements,
  getTravellerDetailRequirements,
  getTravellerDetailRequirementsSchema,
} from '~/services/TravellerService';

import ErrorDisplay from '../../Common/ErrorDisplay';
import Spinner from '../../Common/Spinner';

interface RouteParams {
  id_offer: string;
}

const fetchOfferData = async (offerId: string) => {
  const [{ result: travellerRequirements }, { schema: travellerRequirementsSchema }] = await Promise.all([
    getTravellerDetailRequirements(offerId),
    getTravellerDetailRequirementsSchema(offerId),
  ]);

  return {
    travellerRequirements,
    travellerRequirementsSchema,
  };
};

const TravellerDetailRequirementsEditPage = ({ match }: RouteComponentProps<RouteParams>) => {
  const {
    params: { id_offer: offerId },
  } = match;

  const [isFetching, setIsFetching] = useState(true);
  const [travellerDetailRequirements, setTravellerDetailRequirements] = useState(null);
  const [requirementsSnapshot, setRequirementsSnapshot] = useState(null);
  const [travellerDetailRequirementsSchema, setTravellerDetailRequirementsSchema] = useState(null);
  const [saveButtonState, setSaveButtonState] = useState(buttonStates.default);
  const [hasRequirementsChanged, setHasRequirementsChanged] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getOffer = async () => {
      try {
        const { travellerRequirements, travellerRequirementsSchema } = await fetchOfferData(offerId);

        setTravellerDetailRequirements(travellerRequirements);
        setRequirementsSnapshot(travellerRequirements);
        setTravellerDetailRequirementsSchema(travellerRequirementsSchema);
        setIsFetching(false);
      } catch (err) {
        setError(err.message);
        setIsFetching(false);
      }
    };

    getOffer();
  }, []);

  const saveUpdatedRequirements = async (submitData) => {
    try {
      const { formData } = submitData;
      setSaveButtonState(buttonStates.saving);
      await createOrUpdateTravellerDetailRequirements(offerId, formData);
      setTravellerDetailRequirements(formData);
      setRequirementsSnapshot(formData);
      setHasRequirementsChanged(false);
      setSaveButtonState(buttonStates.default);
    } catch (err) {
      setError(err.message);
    }
  };

  const onChangeRequirements = (changedData) => {
    const { formData } = changedData;
    const isEqual = _isEqual(formData, requirementsSnapshot);

    setTravellerDetailRequirements(formData);
    setHasRequirementsChanged(!isEqual);
  };

  const shouldButtonBeDisabled = saveButtonState === buttonStates.saving || !hasRequirementsChanged;

  if (isFetching) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <div className="container">
      <Form
        schema={travellerDetailRequirementsSchema}
        formData={travellerDetailRequirements}
        showErrorList={false}
        onSubmit={saveUpdatedRequirements}
        onChange={onChangeRequirements}
      >
        <div className="button-container">
          <Button variant="contained" type="submit" className={saveButtonState} disabled={shouldButtonBeDisabled}>
            {buttonMessages[saveButtonState]}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TravellerDetailRequirementsEditPage;
