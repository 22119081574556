import React from 'react';

interface Props {
  firstName: string;
  lastName: string;
}

const GuestName = (props: Props) => {
  const { firstName, lastName } = props;
  return (
    <div className="guest-name">
      {firstName} {lastName}
    </div>
  );
};

export default GuestName;
