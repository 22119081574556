import React from 'react';

import { OrderListItemWithSortId } from '../SortableOfferListOrder';

import BedbankOfferBox from './BedbankOfferBox';
import CruiseOfferBox from './CruiseOfferBox';
import ExperienceOfferBox from './ExperienceOfferBox';
import LEOfferBox from './LEOfferBox';
import TourV2OfferBox from './TourV2OfferBox';

interface Props {
  onRemove?: () => void;
  offer: Exclude<OrderListItemWithSortId, { vertical: 'ev' }>;
  region: string;
  index: number;
}

function OfferBox({ offer, region, index, onRemove }: Props) {
  switch (offer.offerType) {
    case 'hotel':
    case 'last_minute_hotel':
    case 'tactical_ao_hotel':
    case 'bundle_and_save':
    case 'rental':
    case 'tour':
      return <LEOfferBox bk={offer.bk} region={region} index={index} onRemove={onRemove} />;
    case 'bedbank_hotel':
      return <BedbankOfferBox bk={offer.bk} region={region} index={index} onRemove={onRemove} />;
    case 'tour_v2':
      return <TourV2OfferBox bk={offer.bk} region={region} index={index} onRemove={onRemove} />;
    case 'cruise':
      return <CruiseOfferBox bk={offer.bk} index={index} onRemove={onRemove} />;
    case 'flash':
    case 'always_on':
    case 'channel_manager':
      return <ExperienceOfferBox bk={offer.bk} index={index} onRemove={onRemove} />;
  }
}

export default OfferBox;
