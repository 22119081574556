import React from 'react';

import isMatch from 'lodash/isMatch';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container } from '@mui/material';

import { fetchOrderAndPurchaserData, fetchOrderData, submitUpdatesForm } from '../../../actions/order';
import { fetchUserData } from '../../../actions/user';
import PermissionedComponent from '../../../components/Common/PermissionedComponent';
import { EDIT_ORDER_TITLE } from '../../../consts/order';
import { orderSelector } from '../../../selectors/orderSelector';
import { userSelector } from '../../../selectors/userSelector';
import { errorDisplay, errorMonitor } from '../../../utils/adminPermission';
import { createPatchPayload } from '../../../utils/createPatchPayload';
import OrderForm from '../../Common/Forms/OrderForm';
import Spinner from '../../Common/Spinner';
import { withTenant } from '../../hoc';

class OrderEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleChange = (e, op) => {
    this.setState({
      [e.target.name]: { op, value: e.target.value },
    });
  };

  handleChangePurchaser = async (op, purchaserId) => {
    await this.props.fetchUserData(purchaserId);
    this.setState({
      fk_purchaser_id: { op, value: purchaserId },
    });
  };

  submitUpdatesForm = () => {
    const { match, submitUpdatesForm, history } = this.props;
    const orderId = match.params.id_orders;
    const payload = createPatchPayload(this.state);
    submitUpdatesForm(orderId, payload, history);
  };

  isDirty = (order) => {
    const stateEntries = Object.entries(this.state);
    const orderUpdates = {};
    for (const entry of stateEntries) {
      orderUpdates[entry[0]] = entry[1].value;
    }
    return !isMatch(order, orderUpdates);
  };

  fetchWithAdminPermission = () => {
    try {
      errorMonitor(this.context.user);
    } catch (e) {
      return;
    }
    this.props.fetchOrderAndPurchaserData(this.props.match.params.id_orders);
  };

  componentDidMount() {
    if (this.context.user) {
      this.prevUserContext = this.context.user;
      this.fetchWithAdminPermission();
    }
  }

  componentDidUpdate() {
    if (!isMatch(this.prevUserContext, this.context.user)) {
      this.prevUserContext = this.context.user;
      this.fetchWithAdminPermission();
    }
  }

  render() {
    const { error, getPurchaser, isLoading, isUpdating, match, order } = this.props;

    const { id_orders: orderId } = match.params;
    return (
      <Container maxWidth="xl">
        <Button
          component={Link}
          startIcon={<ChevronLeftIcon />}
          to={`/purchases/${orderId}`}
          style={{ display: 'inline-flex' }}
        >
          Return to order
        </Button>
        <h1 className="page-header">{EDIT_ORDER_TITLE}</h1>

        {errorDisplay(this.context.user)}

        <PermissionedComponent>
          {isLoading ? (
            <Spinner />
          ) : (
            <OrderForm
              order={
                order // temp changes are saved to react state
              }
              purchaser={this.state.fk_purchaser_id ? getPurchaser(this.state.fk_purchaser_id.value) : order.purchaser}
              handleChange={this.handleChange}
              handleChangePurchaser={this.handleChangePurchaser}
              tenant={this.props.tenant}
              onSave={this.submitUpdatesForm}
              isLoading={isLoading}
              isUpdating={isUpdating}
              isDirty={this.isDirty(order)}
              error={error}
            />
          )}
        </PermissionedComponent>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const orderId = match.params.id_orders;
  const order = orderSelector(state, orderId);
  const getPurchaser = (purchaserId) => userSelector(state, purchaserId);
  const { isLoading: isLoadingOrder, isUpdating, error: orderError } = state.order;
  const { isLoading: isLoadingUser, error: userError } = state.user;
  return {
    error: userError || orderError,
    getPurchaser,
    isLoading: isLoadingOrder || isLoadingUser,
    isUpdating,
    order,
  };
};

const mapDispatchToProps = {
  fetchOrderData,
  fetchOrderAndPurchaserData,
  submitUpdatesForm,
  fetchUserData,
};

OrderEditContainer.contextTypes = {
  user: PropTypes.object,
};

export default withRouter(withTenant(connect(mapStateToProps, mapDispatchToProps)(OrderEditContainer)));
