// chars is a set of allowed characters for the random string
const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

// generateRandomString generates random alphanumeric string of a specified
// length
export default function (len = 5) {
  let ret = '';

  for (var i = 0; i < len; i++) {
    ret += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return ret;
}
