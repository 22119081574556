import React, { useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import OffersTab from './OffersTab';
import PurchasesTab from './PurchasesTab';
import SearchFiltersForm, { type SearchFilters } from './SearchFilters';

type Tab = 'purchases' | 'offers';

export default function Container() {
  const [currentTab, setCurrentTab] = useState<Tab>('purchases');
  const [filters, setFilters] = useState<SearchFilters | null>(null);

  return (
    <>
      <SearchFiltersForm onSearch={setFilters} onClear={() => setFilters(null)} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
        <Tabs value={currentTab} onChange={(_, newValue) => setCurrentTab(newValue)} id="custom-offers">
          <Tab label="Purchases" value="purchases" />
          <Tab label="Offers" value="offers" />
        </Tabs>
      </Box>

      <Box pt={4}>
        {currentTab === 'purchases' && <PurchasesTab searchFilters={filters} />}
        {currentTab === 'offers' && <OffersTab searchFilters={filters} />}
      </Box>
    </>
  );
}
