import React from 'react';

import { theme } from '~/theme';

import HelpIcon from '@mui/icons-material/Help';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${theme.palette.grey[100]}`,
    maxWidth: 220,
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}));

interface Props {
  handleFlagChange: () => void;
  send_customer_refund_notification: boolean;
}

export default function CustomerRefundNotificationToggle(props: Props) {
  const { handleFlagChange, send_customer_refund_notification } = props;

  return (
    <Stack direction="row">
      <FormControl>
        <FormLabel id="send_customer_refund_notification_label">
          Send refund notification
          <HtmlTooltip
            placement="right"
            title={
              <Stack direction="column" gap={1}>
                <Typography variant="subtitle2">Send Customer Refund Notification</Typography>
                <Typography variant="body2">
                  The default to send customer refund notification will be set according to the refund type selected.
                </Typography>
                <Typography variant="body2">You can override this setting here.</Typography>
              </Stack>
            }
          >
            <IconButton>
              <HelpIcon fontSize="small" />
            </IconButton>
          </HtmlTooltip>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="send_customer_refund_notification_label"
          name="send_customer_refund_notification"
          value={send_customer_refund_notification === false ? '0' : '1'}
          onChange={handleFlagChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
