import crypto from 'crypto';
import dayjs from '~/timeInit';

import constants from './latitudeConstants';

let timeInMilliseconds = dayjs().valueOf();

export function generateRequest(map, publicKey) {
  const generatedMap = {
    ...map,
    [constants.PAGE_TIMESTAMP]: timeInMilliseconds,
  };
  return insertSignature(generatedMap, publicKey);
}

function insertSignature(inputObject, publicKey) {
  let customFields = Object.keys(inputObject).join(',');
  let dataToSign = Object.keys(inputObject)
    .map((key) => `${key}=${inputObject[key]}`)
    .join(',');

  dataToSign += `,${constants.SIGNED_FIELDS_PUBLIC_SIGNATURE}=${getPublicDigest(customFields, publicKey)}`;

  return {
    ...inputObject,
    [constants.PAGE_PUBLIC_SIGNATURE]: getPublicDigest(dataToSign, publicKey),
    [constants.PAGE_SIGNED_FIELDS]: customFields,
  };
}

function getPublicDigest(customValues, publicKey) {
  return crypto.createHmac('sha1', publicKey).update(customValues).digest('base64');
}
