import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import { Alert, Box } from '@mui/material';

import { getHandpickedExperiencesForOffer } from '~/services/ExperiencesService';

interface Props {
  offer: App.Offer;
}

const ExperiencesPane = (props: Props) => {
  const { offer } = props;

  const [experiences, setExperiencesResult] = useState<App.OfferExperience[] | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchExperiences = async () => {
      const experiencesResult = await getHandpickedExperiencesForOffer(offer.id_salesforce_external);
      setExperiencesResult(experiencesResult);
    };

    fetchExperiences().catch((err) => {
      setError(err);
    });
  }, [offer]);

  if (error) {
    return (
      <Box sx={{ mb: 1 }}>
        <Alert severity="warning">Error loading experiences</Alert>
      </Box>
    );
  }

  return (
    <span>
      <h1 className="page-header">
        Experiences
        <Link
          to={{
            pathname: '/edit-offers/' + offer.id_salesforce_external + '/experiences',
          }}
        >
          <ModeEditTwoToneIcon fontSize="large" />
          <span className="sr-only">Edit</span>
        </Link>
      </h1>
      <p className="form-group">Sell experiences in-flow - {offer.experiences_in_flow ? 'Yes' : 'No'}</p>
      <p className="form-group">Curate Selection - {offer.experiences_curated ? 'Yes' : 'No'}</p>
      {experiences?.length > 0 && (
        <ul>
          {experiences.map((exp) => (
            <li key={exp.experienceId}>
              {exp.offer?.title} ({exp.experienceId})
            </li>
          ))}
        </ul>
      )}
      {!experiences?.length && <p>-</p>}
    </span>
  );
};

export default ExperiencesPane;
