module.exports = (remittance, potentialRemittance, emailSent) => {
  const remittanceIdentifiers = remittance.map((i) => {
    return { vendorId: i.vendor_id, dateOfPayment: i.date_of_payment };
  });

  const filteredPotentialRemittance = potentialRemittance.filter((i) => {
    return !remittanceIdentifiers.some(
      ({ vendorId, dateOfPayment }) => i.vendor_id === vendorId && i.date_of_payment === dateOfPayment,
    );
  });

  return remittance.concat(filteredPotentialRemittance).filter((i) => {
    // Dont filter potential remittance
    if (!i.remittance_number) {
      return true;
    }

    return i.email_sent === emailSent;
  });
};
