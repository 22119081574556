import React from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

type Props = {
  searchString: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
};

export default function UserSearchForm(props: Props) {
  const { searchString, handleChange, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box flex={1}>
          <TextField
            id="UserSearch"
            data-testid="UserSearch"
            type="text"
            name="searchString"
            label="Name / Email / QFF / Customer Support Code / User ID"
            placeholder="Enter Name / Email / QFF / Customer Support Code / User ID"
            value={searchString}
            onChange={handleChange}
            autoFocus
            fullWidth
          />
        </Box>

        <Button type="submit" variant="contained" className="T-search-user">
          Search
        </Button>
      </Stack>
    </form>
  );
}
