import React, { FormEvent, useRef } from 'react';

import { Box, Button, TextField } from '@mui/material';

import { usePorts } from '~/components/Cruises/context/usePorts';

export default function PortSearch() {
  const formRef = useRef(null);
  const { setFilter } = usePorts();

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    const form = formRef.current;
    event.preventDefault();

    setFilter({
      name: form['name']?.value,
    });
  };

  return (
    <Box>
      <form onSubmit={onSubmit} ref={formRef}>
        <Box display="flex" gap={2}>
          <TextField fullWidth type="text" id="name" name="name" placeholder="Search by name" />

          <Button variant="contained" type="submit">
            Filter
          </Button>
        </Box>
      </form>
    </Box>
  );
}
