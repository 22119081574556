import React from 'react';

import { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';

import { FormControl } from '@mui/material';

import AIGeneratorButton, { AIContext } from '~/components/AIGenerator/AIGenerateButton';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

export default function AIGeneratorMarkdownWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({
  id,
  label,
  required,
  disabled,
  readonly,
  placeholder,
  value,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  options,
  rawErrors = [],
  ...markdownEditorProps
}: WidgetProps<T, S, F>) {
  // Pass markdown info to updateContext function so we can ID the related fields
  const updateContext = () => {
    if (options?.updateContext && typeof options.updateContext === 'function') {
      return options.updateContext({ markdownId: id });
    }
    return {};
  };
  return (
    <FormControl fullWidth variant="outlined">
      <MarkdownEditor
        id={id}
        name={id}
        label={label}
        value={value || ''}
        onChange={(value) => onChange(value)}
        required={required}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        placeholder={placeholder}
        helperText={(options && options.helperText) || ''}
        onBlur={onBlur(id, value)}
        onFocus={onFocus(id, value)}
        {...markdownEditorProps}
      />
      <AIGeneratorButton
        context={options?.generatorContext as AIContext}
        content={value}
        buttonLabel={options?.buttonLabel?.toString() ?? `${value ? 'Rewrite ' : 'Generate '}${label}`}
        updateContext={updateContext}
        onChoose={(result: string) => onChange(result)}
        disableUseButton={!!options?.disableUseButton}
      />
    </FormControl>
  );
}
