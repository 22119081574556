import React from 'react';

import { types } from '../../../consts/types';
import { scheduleForType } from '../../../utils/scheduleForType';
import NewScheduleForm from '../../Common/Forms/NewScheduleForm';

export default class NewScheduleByType extends React.Component {
  state = {
    editing: false,
    errors: [],
  };

  onSuccess = () => {
    this.setState({
      editing: false,
      errors: [],
    });
  };

  onSave = () => {
    this.setState({
      editing: true,
    });
  };

  render() {
    const { regions, brandsValue, offerId, schedules, shouldMigrateSchedule } = this.props;
    const scheduleIdListForTypes = (type) => {
      return schedules.filter((s) => s.type === type).map((s) => s.id);
    };

    return (
      <div className="schedule-type-container">
        <h2>Edit Schedule</h2>
        {types.map((type, index) => {
          return (
            <div key={`${type}-${index}`}>
              <h3>{type.name}</h3>
              <NewScheduleForm
                key={`NewScheduleForm-${type.code}`}
                idList={scheduleIdListForTypes(type.code)}
                offerId={offerId}
                brands={brandsValue}
                disabled={shouldMigrateSchedule || this.state.editing}
                onSave={this.onSave}
                onSuccess={this.onSuccess}
                type={type.code}
                regions={regions}
                showSave={true}
                end={scheduleForType(schedules, type.code, 'end')}
                start={scheduleForType(schedules, type.code, 'start')}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
