/* src/components/Users/Orders/AddonsSelection/content.js */
import React from 'react';

import PropTypes from 'prop-types';

import AddonsList from './list';

const AddonsContent = (props) => {
  const { items, offer, currencyCode, onAddAddon, onRemoveAddon, getAddonQuantity } = props;
  // Sort reservations by check-in date
  items.sort((itemA, itemB) => {
    const checkInA = new Date(itemA.reservation.checkIn);
    const checkInB = new Date(itemB.reservation.checkIn);

    if (checkInA > checkInB) {
      return 1;
    }

    if (checkInA < checkInB) {
      return -1;
    }

    return 0;
  });

  // NOTE: Addons are coming from first item
  return (
    <AddonsList
      offer={offer}
      getAddonQuantity={getAddonQuantity}
      onAddAddon={onAddAddon}
      onRemoveAddon={onRemoveAddon}
      items={items}
      currencyCode={currencyCode}
    />
  );
};

AddonsContent.propTypes = {
  items: PropTypes.array.isRequired,
  offer: PropTypes.object.isRequired,
  currencyCode: PropTypes.string.isRequired,
  onAddAddon: PropTypes.func.isRequired,
  onRemoveAddon: PropTypes.func.isRequired,
  getAddonQuantity: PropTypes.func.isRequired,
};

export default AddonsContent;
