import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import useHeroPlannerCities from '~/hooks/customerCommunication/useHeroPlannerCities';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props {
  countryGroupId: string;
  countryId: string;
  stateId: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
}

function ScheduleCityInput(props: Props) {
  const { countryGroupId, countryId, stateId, name, value, onChange } = props;
  const { citiesReq } = useHeroPlannerCities(countryGroupId, countryId, stateId);
  const options = citiesReq?.result ?? EmptyArray;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (
      // Options are available.
      options.length &&
      // And there's no value selected or the selected value doesn't exist in the options.
      (!value || !options.find((i) => i.id === value))
    ) {
      onChange(options[0].id);
    }
  }, [onChange, options, value]);

  const initialCity = useMemo<CustomerCommunication.HeroPlannerCity | undefined>(() => {
    if (
      // There is a value present
      value &&
      // And the value doesn't exist in options
      !options.find((v) => v.id === value)
    )
      return { id: value, name: value };
  }, [options, value]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink>City</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        required
        variant="standard"
        disabled={isRequestUnresolved(citiesReq) || isRequestRejected(citiesReq)}
        error={isRequestRejected(citiesReq)}
      >
        {!!initialCity && (
          <MenuItem value={initialCity.id}>
            <i>{initialCity.name}</i>
          </MenuItem>
        )}
        {options.map((city) => (
          <MenuItem key={city.id} value={city.id}>
            {city.name}
          </MenuItem>
        ))}
      </Select>
      {isRequestRejected(citiesReq) && <FormHelperText error>{citiesReq.error}</FormHelperText>}
      {isRequestPending(citiesReq) && <LinearProgress sx={{ position: 'absolute' }} />}
    </FormControl>
  );
}

export default ScheduleCityInput;
