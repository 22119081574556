import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';

import { Box, Container, Stack } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import {
  CabinCategoriesGroupById,
  deleteCabinCategoriesGroup,
  getCabinCategoriesGroupById,
  updateCabinCategoriesGroup,
} from '~/services/cruises/CabinCategoriesGroupService';

import CruisesLinkButton from '../../CruisesLinkButton';
import { DEFAULT_CABIN_CATEGORIES_GROUP_RESPONSE, SubmitParams } from '../constants';

import CabinCategoriesGroupDetails from './CabinCategoriesGroupDetails';
import CabinCategoriesGroupEditableForm from './CabinCategoriesGroupEditableForm';

export default function CabinCategoriesGroupOverviewPage() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id_group: groupId } = useParams<{ id_group: string }>();

  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');
  const [categoriesGroup, setCategoriesGroup] = useState<CabinCategoriesGroupById>(
    DEFAULT_CABIN_CATEGORIES_GROUP_RESPONSE,
  );

  const fetchCabinCategoriesGroup = useCallback(
    async (groupId: string): Promise<void> => {
      setFetching('loading');

      try {
        const fetchedGroup = await getCabinCategoriesGroupById({ groupId });

        setFetching('success');
        setCategoriesGroup(fetchedGroup.result);
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setCategoriesGroup(null);
      }
    },
    [enqueueSnackbar],
  );

  const handlerSubmit = useCallback(
    async (params: SubmitParams) => {
      try {
        const res = await updateCabinCategoriesGroup(params.id, params);

        enqueueSnackbar(`Cabin Categories Group ${res.result.name} has been updated successfully`, {
          autoHideDuration: 5000,
          variant: 'success',
        });

        history.push('/cruises/cabin-categories-groups');
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });
      }
    },
    [enqueueSnackbar, history],
  );

  const handlerDelete = useCallback(
    async (id: string) => {
      try {
        const res = await deleteCabinCategoriesGroup(id, {});

        enqueueSnackbar(`Cabin Categories Group ${res.result.name} has been deleted successfully`, {
          autoHideDuration: 5000,
          variant: 'success',
        });

        history.push('/cruises/cabin-categories-groups');
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });
      }
    },
    [enqueueSnackbar, history],
  );

  useEffect(() => {
    if (groupId) {
      fetchCabinCategoriesGroup(groupId);
    }
  }, [groupId, fetchCabinCategoriesGroup]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Cruises | Cabin Categories Group Details Page</title>
      </Helmet>

      <Box>
        {fetching === 'loading' && <Spinner size={24} />}

        {fetching === 'success' && (
          <Fragment>
            <Box mb={3}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                {categoriesGroup.ship.id && (
                  <Stack direction="row" spacing={2}>
                    <CruisesLinkButton
                      text="Admin Portal Ship Details Page"
                      href={`/cruises/ships/${categoriesGroup.ship.id}`}
                    />
                  </Stack>
                )}
              </Stack>
            </Box>

            <Stack spacing={3}>
              <PageSubheader title="Cabin Categories Group Overview" />
              <Stack spacing={3} mt={3}>
                <CabinCategoriesGroupDetails categoriesGroup={categoriesGroup} />
                <CabinCategoriesGroupEditableForm
                  handlerDelete={handlerDelete}
                  handlerSubmit={handlerSubmit}
                  categoriesGroup={categoriesGroup}
                />
              </Stack>
            </Stack>
          </Fragment>
        )}
      </Box>
    </Container>
  );
}
