import React from 'react';

import { withRouter } from 'react-router-dom';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import TravellerForm from './TravellerForm';

class TravellerModalForm extends React.Component {
  hideTravellerModal = () => {
    this.props.hideTravellerModal();
  };

  render() {
    const bookingData = {
      bookingNumber: this.props.bookingNumber,
      orderId: this.props.orderId,
    };
    return (
      <div>
        <Dialog open={this.props.isTravellerModalVisible} onClose={this.hideTravellerModal} maxWidth="sm" fullWidth>
          <DialogTitle>Traveller Details</DialogTitle>

          <DialogContent>
            <TravellerForm
              tourFlightDetailsRequired={this.props.tourFlightDetailsRequired}
              tourWeightRequired={this.props.tourWeightRequired}
              bookingData={bookingData}
              getTravellers={this.props.getTravellers}
              traveller={this.props.traveller}
              setTravellerSubmitted={this.props.setTravellerSubmitted}
              setFlightsSubmitted={this.props.setFlightsSubmitted}
              setTravellers={this.props.setTravellers}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={() => this.hideTravellerModal()}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(TravellerModalForm);
