import React from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import type { TagType } from '~/consts/tags';
import { TAG_TYPE_CAMPAIGN, TAG_TYPE_HOLIDAY } from '~/consts/tags';

import OffersService from '~/services/OffersService';

import TagsComponent from './TagsComponent';

function sortTags(tags: App.MarketingTag[]) {
  const sortedTags = [...tags];

  sortedTags.sort(function (tagA, tagB) {
    if (tagA.name > tagB.name) {
      return 1;
    }
    if (tagA.name < tagB.name) {
      return -1;
    }
    return 0;
  });

  return sortedTags;
}

export default class TagsContainer extends React.Component {
  state = {
    isLoading: true,
    campaigns: [],
    holidayTypes: [],
    error: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ isLoading: true, error: null });

    Promise.all([OffersService.getTags(TAG_TYPE_CAMPAIGN), OffersService.getTags(TAG_TYPE_HOLIDAY)])
      .then(([campaignsRes, holidayTypesRes]) =>
        this.setState({
          campaigns: sortTags(campaignsRes.result),
          holidayTypes: sortTags(holidayTypesRes.result),
        }),
      )
      .catch((err) => this.setState({ error: err.toString() }))
      .finally(() => this.setState({ isLoading: false }));
  }

  handleDeleteTag = async (type: TagType, id: number): Promise<void> => {
    try {
      await OffersService.deleteTag(type, id);

      let tags = [];

      switch (type) {
        case TAG_TYPE_CAMPAIGN:
          tags = this.state.campaigns.filter((tag) => tag.id !== id);
          this.setState({ campaigns: sortTags(tags) });
          break;
        case TAG_TYPE_HOLIDAY:
          tags = this.state.holidayTypes.filter((tag) => tag.id !== id);
          this.setState({ holidayTypes: sortTags(tags) });
          break;
      }
    } catch (err) {
      alert("Something went wrong. Can't delete tag");
    }
  };

  handleUpdateTag = async (type: TagType, id: number, updatedName: string) => {
    const { result: updatedTag } = await OffersService.updateTag(type, id, updatedName);

    let tags = [];

    switch (type) {
      case TAG_TYPE_CAMPAIGN:
        tags = this.state.campaigns.map((tag) => (tag.id === id ? updatedTag : tag));
        this.setState({ campaigns: sortTags(tags) });
        break;
      case TAG_TYPE_HOLIDAY:
        tags = this.state.holidayTypes.map((tag) => (tag.id === id ? updatedTag : tag));
        this.setState({ holidayTypes: sortTags(tags) });
        break;
    }
  };

  handleCreateTag = async (type: TagType, name: string): Promise<App.MarketingTag> => {
    return OffersService.createTag(type, name).then((response) => {
      let tags = [];

      switch (type) {
        case TAG_TYPE_CAMPAIGN:
          tags = [...this.state.campaigns, response.result];
          this.setState({ campaigns: sortTags(tags) });
          break;
        case TAG_TYPE_HOLIDAY:
          tags = [...this.state.holidayTypes, response.result];
          this.setState({ holidayTypes: sortTags(tags) });
          break;
      }

      return response.result;
    });
  };

  render() {
    const { isLoading, error, campaigns, holidayTypes } = this.state;

    if (error) {
      return <ErrorDisplay message={error} />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <Grid container xs={12} spacing={6}>
        <Grid xs={12} md={6}>
          <TagsComponent
            tags={holidayTypes}
            title="Holiday Types"
            type={TAG_TYPE_HOLIDAY}
            onCreateTag={this.handleCreateTag}
            onDeleteTag={this.handleDeleteTag}
            onUpdateTag={this.handleUpdateTag}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <TagsComponent
            tags={campaigns}
            title="Campaigns"
            type={TAG_TYPE_CAMPAIGN}
            onCreateTag={this.handleCreateTag}
            onDeleteTag={this.handleDeleteTag}
            onUpdateTag={this.handleUpdateTag}
          />
        </Grid>
      </Grid>
    );
  }
}
