import React, { useEffect, useState } from 'react';

import _omit from 'lodash/omit';

import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { buttonMessages } from '~/components/Common/Forms/states/submitButton';

import {
  RISKY_FIELDS_TO_BE_COPIED,
  STEP_CONFIRM_COPIED_PACKAGES,
  STEP_SELECT_COPIED_PACKAGE_FIELDS,
  STEP_SELECT_COPY_TO_PACKAGES,
} from '~/consts/copyPackages';

import ErrorListDisplay from '../../../components/Common/ErrorListDisplay';
import Spinner from '../../Common/Spinner';

const getEditableFields = (properties, uiSchema) => {
  // generate copyable fields exclude the readonly
  const readOnlyUiFields = Object.keys(uiSchema).filter((key) => uiSchema[key]['ui:disabled'] === true);

  // filter out the risky fields to be copied
  const readOnlyFields = [...readOnlyUiFields, ...RISKY_FIELDS_TO_BE_COPIED];

  // add regions as new copy field as it doesn't exist in schema properties
  const editableFields: any = _omit(properties, readOnlyFields);

  editableFields.regions = {
    type: 'array',
    title: 'Regions',
  };

  return editableFields;
};

const CopyToPackageModal = (props) => {
  const {
    isOpen,
    onHide,
    packageData,
    uiSchema,
    schema,
    allPackagesData,
    onCopyToPackageSubmit,
    apiErrors,
    copyToState,
    setButtonToDefault,
  } = props;

  const editableFields = getEditableFields(schema.properties, uiSchema);

  const noCheckedFields = Object.keys(editableFields).map((key) => {
    return {
      value: key,
      label: editableFields[key].title,
      checked: false,
    };
  });

  const allCheckedFields = Object.keys(editableFields).map((key) => {
    return {
      value: key,
      label: editableFields[key].title,
      checked: true,
    };
  });

  const isSaving = buttonMessages[copyToState] === 'Saving...';
  const isSaved = buttonMessages[copyToState] === 'Saved';
  const [currentStep, setCurrentStep] = useState(STEP_SELECT_COPY_TO_PACKAGES);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(noCheckedFields);

  // generate the copied form data object and exclude current package
  const copyToPackages = allPackagesData
    .filter((item) => item.id_salesforce_external !== packageData.id_salesforce_external)
    .map((pkgData) => ({
      value: pkgData.id_salesforce_external,
      label: pkgData.deal_option_name,
    }));

  useEffect(() => setButtonToDefault(), [currentStep]);

  const clearState = () => {
    setSelectedPackages([]);
    setSelectedSchema(noCheckedFields);
  };

  const handleCloseModal = () => {
    clearState();
    onHide();
    setCurrentStep(STEP_SELECT_COPY_TO_PACKAGES);
  };

  const handleSubmit = (selectedPackages, selectedSchema) => {
    const checkedSchema = selectedSchema.filter((s) => s.checked);
    onCopyToPackageSubmit(selectedPackages, checkedSchema);
  };

  const handleToggle = (e) => {
    const newSelectedSchema = JSON.parse(JSON.stringify(selectedSchema));
    newSelectedSchema.map((ele) => {
      if (ele.value === e.target.value) {
        ele.checked = !ele.checked;
      }
    });

    setSelectedSchema(newSelectedSchema);
  };

  let copyApiErrors = null;
  if (apiErrors && apiErrors.length) {
    copyApiErrors = <ErrorListDisplay messages={apiErrors} />;
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '50%',
          minHeight: '300px',
        },
      }}
      open={isOpen}
      onClose={handleCloseModal}
    >
      {copyApiErrors}
      {currentStep === STEP_SELECT_COPY_TO_PACKAGES && (
        <>
          <DialogTitle>Step1: Select copy to packages from the list</DialogTitle>
          <DialogContent>
            <Autocomplete
              multiple
              options={copyToPackages}
              onChange={(_, selectedPackages) => setSelectedPackages(selectedPackages)}
              value={selectedPackages}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedPackages(copyToPackages)}>Select all</Button>
            <Button onClick={() => setSelectedPackages([])}>Deselect all</Button>
            <Button
              variant="contained"
              disabled={!selectedPackages?.length}
              onClick={() => setCurrentStep(STEP_SELECT_COPIED_PACKAGE_FIELDS)}
            >
              Continue
            </Button>
          </DialogActions>
        </>
      )}
      {currentStep === STEP_SELECT_COPIED_PACKAGE_FIELDS && (
        <>
          <DialogTitle>Step 2: Select copy to fields</DialogTitle>
          <DialogContent>
            <FormGroup>
              {selectedSchema.map((item) => (
                <FormControlLabel
                  control={<Checkbox />}
                  onChange={(e) => handleToggle(e)}
                  value={item.value}
                  checked={item.checked}
                  label={item.label}
                />
              ))}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentStep(STEP_SELECT_COPY_TO_PACKAGES)} disabled={isSaving}>
              Back
            </Button>
            <Button onClick={() => setSelectedSchema(allCheckedFields)} disabled={isSaving}>
              Select all
            </Button>
            <Button onClick={() => setSelectedSchema(noCheckedFields)} disabled={isSaving}>
              Deselect all
            </Button>
            <Button
              onClick={() => setCurrentStep(STEP_CONFIRM_COPIED_PACKAGES)}
              variant="contained"
              type="submit"
              disabled={!selectedSchema.filter((item) => item.checked)?.length}
              className={copyToState}
            >
              Continue
            </Button>
          </DialogActions>
        </>
      )}
      {currentStep === STEP_CONFIRM_COPIED_PACKAGES && !isSaved && (
        <>
          <DialogTitle>Please confirm the copy to details below</DialogTitle>
          <DialogContent>
            {isSaving && <Spinner />}
            {!isSaving && (
              <>
                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6">The packages you have selected |</Typography>
                  <Button variant="text" size="small" onClick={() => setCurrentStep(STEP_SELECT_COPY_TO_PACKAGES)}>
                    reselect
                  </Button>
                </Stack>
                <Divider />
                <List>
                  {selectedPackages.map((pkg) => (
                    <>
                      <ListItem key={pkg.value}>{pkg.label}</ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6">The fields you have selected |</Typography>
                  <Button variant="text" size="small" onClick={() => setCurrentStep(STEP_SELECT_COPIED_PACKAGE_FIELDS)}>
                    reselect
                  </Button>
                </Stack>
                <Divider />
                <List>
                  {selectedSchema
                    .filter((item) => item.checked)
                    .map((schema) => (
                      <>
                        <ListItem key={schema.value}>{schema.label}</ListItem>
                        <Divider />
                      </>
                    ))}
                </List>
                {!isSaving && (
                  <Alert color="warning">
                    Continuing will overwrite any currently existing content in these fields on the target packages. Are
                    you sure you want to continue?
                  </Alert>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentStep(STEP_SELECT_COPIED_PACKAGE_FIELDS)} disabled={isSaving}>
              Back
            </Button>
            <Button
              onClick={() => handleSubmit(selectedPackages, selectedSchema)}
              variant="contained"
              type="submit"
              disabled={!selectedSchema.filter((s) => s.checked).length || isSaving}
              className={copyToState}
            >
              {buttonMessages[copyToState]}
            </Button>
          </DialogActions>
        </>
      )}
      {currentStep === STEP_CONFIRM_COPIED_PACKAGES && isSaved && <Alert color="success">Successfully saved!</Alert>}
    </Dialog>
  );
};

export default CopyToPackageModal;
