import React, { useMemo } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';

import { Remittance } from '~/consts/remittance';

import { isRemittanceCreated, isRemittanceDraft } from '~/utils/remittance';

import { BULK_ACTIONS } from './RemittanceContainer';

type Props = {
  handleClose: () => void;
  handleConfirm: () => void;
  isOpen: boolean;
  selectedBulkAction: BULK_ACTIONS | null;
  selectedRemittances: Remittance[];
};

const ConfirmActionDialog = ({
  handleClose,
  handleConfirm,
  isOpen,
  selectedBulkAction,
  selectedRemittances,
}: Props) => {
  const actionRemittances = useMemo(() => {
    if (selectedBulkAction === BULK_ACTIONS.email) {
      return selectedRemittances.filter((remittance) => isRemittanceCreated(remittance.id));
    }

    if (selectedBulkAction === BULK_ACTIONS.create) {
      return selectedRemittances.filter((remittance) => isRemittanceDraft(remittance.id));
    }

    return [];
  }, [selectedRemittances, selectedBulkAction]);

  const totalPaymentAmount = useMemo(() => {
    return actionRemittances.reduce((total, remittance) => {
      return total + Number(remittance.payment_amount);
    }, 0);
  }, [actionRemittances]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {selectedBulkAction === BULK_ACTIONS.email && 'Do you want to email these remittances?'}
        {selectedBulkAction === BULK_ACTIONS.create && 'Do you want to create these remittances?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            {actionRemittances.map((remittance) => (
              <>
                <Grid item xs={6}>
                  {remittance.vendor_name}
                </Grid>
                <Grid item xs={3}>
                  {remittance.cost_currency}
                </Grid>
                <Grid item xs={3}>
                  {remittance.payment_amount}
                </Grid>
              </>
            ))}
            <Grid item xs={6} />
            <Grid item xs={3}>
              <strong>Total amount:</strong>
            </Grid>
            <Grid item xs={3}>
              <strong>{totalPaymentAmount}</strong>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmActionDialog;
