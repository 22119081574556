import React, { useCallback, useState } from 'react';

import { Alert, Button, Stack, TextField } from '@mui/material';

import { bulkCreditsReactivation } from '~/services/PaymentsService';

type ProcessingStatus = 'initial' | 'pending' | 'success' | 'error';
type ProcessingResult = {
  total_extended: number;
  total_reactivated: number;
  total_records: number;
};

export default function CreditExtensions() {
  const [status, setStatus] = useState<ProcessingStatus>('initial');
  const [result, setResult] = useState<ProcessingResult>({
    total_extended: 0,
    total_reactivated: 0,
    total_records: 0,
  });
  const [file, setFile] = useState();

  const fileSelected = useCallback((e) => {
    setFile(e.target.files[0]);
  }, []);

  const uploadCsv = useCallback(
    (e) => {
      e.preventDefault();

      setStatus('pending');

      bulkCreditsReactivation(file)
        .then((response) => {
          setResult(response.result);
          setStatus('success');
        })
        .catch((err) => {
          console.warn(err);
          setStatus('error');
        });
    },
    [file],
  );

  return (
    <div>
      {status == 'success' && (
        <Alert severity="success">
          <p>Credits have been successfully updated</p>
          <ul>
            <li>Total rows processed: {result.total_records}</li>
            <li>Total reactivated: {result.total_reactivated}</li>
            <li>Total extended: {result.total_extended}</li>
          </ul>
        </Alert>
      )}

      {status == 'error' && <Alert severity="warning">Something went wrong. Please check your CSV file.</Alert>}

      <p>Bulk reactivation of user credits</p>

      <form onSubmit={uploadCsv}>
        <Stack direction="column" spacing={2}>
          <TextField
            label="CSV File"
            id="credit-extensions-csv"
            type="file"
            name="csv"
            onChange={fileSelected}
            disabled={status == 'pending'}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <div>
            <Button type="submit" variant="contained" disabled={status == 'pending'}>
              Upload credit extensions
            </Button>
          </div>
        </Stack>
      </form>
    </div>
  );
}
