import React, { useCallback } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';

import { toggleBookingDates, updateReservation, updateStateOfResidence } from '~/actions/cart';

import BookingList from './BookingList';

type Props = ConnectedProps<typeof connector> & {
  items: App.CartItem[];
  offer: App.CartOffer;
  offerNumber: number;
  currencyCode: string;
};

function BookingContent(props: Props) {
  const handleUpdateReservation = useCallback(
    (item, reservationData) => {
      const { doUpdateReservation, offer } = props;
      doUpdateReservation(item.id, reservationData, offer);
    },
    [props],
  );

  const handleUpdateStateOfResidence = useCallback(
    (stateOfResidence) => {
      const { doUpdateStateOfResidence } = props;
      doUpdateStateOfResidence(stateOfResidence);
    },
    [props],
  );

  const { offer, currencyCode, doToggleBookingDates, doUpdateReservation } = props;
  const { items } = offer;

  return (
    <BookingList
      items={items}
      bookingType={offer.booking_type}
      offer={offer}
      currencyCode={currencyCode}
      onUpdateReservation={handleUpdateReservation}
      onUpdateStateOfResidence={handleUpdateStateOfResidence}
      doToggleBookingDates={doToggleBookingDates}
      doUpdateReservation={doUpdateReservation}
    />
  );
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      doToggleBookingDates: toggleBookingDates,
      doUpdateReservation: updateReservation,
      doUpdateStateOfResidence: updateStateOfResidence,
    },
    dispatch,
  );
}

const connector = connect(null, mapDispatchToProps);

export default connector(BookingContent);
