import isObject from './isObject';

function deleteByKey(data, keysToDelete) {
  let newData = data;
  if (isObject(data)) {
    newData = Object.assign({}, data);
    Object.keys(newData).forEach((key) => {
      if (keysToDelete.includes(key)) {
        delete newData[key];
      } else {
        newData[key] = deleteByKey(newData[key], keysToDelete);
      }
    });
  } else if (Array.isArray(data)) {
    newData = data.map((d) => deleteByKey(d, keysToDelete));
  }
  return newData;
}

export default deleteByKey;
