export const getAdminOfferUrl = (offerId: string) => {
  const bedbankPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

  if (bedbankPattern.test(offerId)) {
    return `/bedbank/properties/${offerId}`;
  } else if (offerId.startsWith('006')) {
    return `/offers/${offerId}`;
  } else {
    return '';
  }
};
