import React, { useCallback, useEffect, useState } from 'react';

import { Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

import { getDailyStats, getFeedbackIssuesStats } from '~/services/SupportService';
import { formatDateISO, subDays } from '~/services/TimeService';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const FeedbackStats = () => {
  const [stats, setStats] = useState(null);
  const [feedbackIssuesStats, setFeedbackIssuesStats] = useState(null);
  const [fromDate, setFromDate] = useState(formatDateISO(subDays(7)));
  const [toDate, setToDate] = useState(formatDateISO());
  const [customersOnly, setCustomersOnly] = useState(false);
  const [agentChatsOnly, setAgentChatsOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalFeedbacks, setTotalFeedbacks] = useState(0);
  const [totalChats, setTotalChats] = useState(0);
  const [totalAgentChats, setTotalAgentChats] = useState(0);
  const [hasMessagesOnly, setHasMessagesOnly] = useState(true);

  const getStats = useCallback(() => {
    setIsLoading(true);

    Promise.all([
      getDailyStats({ from: fromDate, to: toDate, customersOnly, agentChatsOnly, hasMessagesOnly }),
      getFeedbackIssuesStats({ from: fromDate, to: toDate, customersOnly, agentChatsOnly, hasMessagesOnly }),
    ]).then(([dailyStats, feedbackIssuesStats]) => {
      setStats(dailyStats.result);
      setFeedbackIssuesStats(feedbackIssuesStats.result);
      setIsLoading(false);
      setTotalFeedbacks(dailyStats.result.feedbacks.reduce((acc, curr) => acc + curr.count, 0));
      setTotalChats(dailyStats.result.chats.reduce((acc, curr) => acc + curr.count, 0));
      setTotalAgentChats(dailyStats.result.agentChats.reduce((acc, curr) => acc + curr.count, 0));
    });
  }, [fromDate, toDate, customersOnly, agentChatsOnly, hasMessagesOnly]);

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={2} columns={24}>
        <Grid item md={6}>
          <DateTimeWidget
            format="DD/MM/YYYY"
            disableFuture
            showDateOnly
            label="From"
            value={fromDate}
            onChange={setFromDate}
          />
        </Grid>
        <Grid item md={6}>
          <DateTimeWidget
            format="DD/MM/YYYY"
            disableFuture
            showDateOnly
            label="To"
            value={toDate}
            onChange={setToDate}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={customersOnly} onChange={(event) => setCustomersOnly(event.target.checked)} />}
            label="Customers only"
          />
          <FormControlLabel
            control={
              <Checkbox checked={agentChatsOnly} onChange={(event) => setAgentChatsOnly(event.target.checked)} />
            }
            label="Agent chats only"
          />
          <FormControlLabel
            control={
              <Checkbox checked={hasMessagesOnly} onChange={(event) => setHasMessagesOnly(event.target.checked)} />
            }
            label="Hide Empty Chats"
          />
        </Grid>
        <Grid item>
          <Button disabled={isLoading} variant="contained" onClick={getStats}>
            {isLoading ? 'Loading' : 'Apply'}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Typography variant="h5">Number of each feedback reason</Typography>
          {feedbackIssuesStats?.length > 0 && (
            <PieChart width={400} height={400}>
              <Pie data={feedbackIssuesStats} label dataKey="count" outerRadius={100} fill="#8884d8">
                {feedbackIssuesStats?.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend align="left" />
            </PieChart>
          )}
          {!feedbackIssuesStats?.length && (
            <Typography variant="body1">No feedback was reported in this period</Typography>
          )}
        </Grid>
        <Grid item md={8}>
          <Typography variant="h5" mb={4}>
            Usage volumes
          </Typography>
          <Stack direction={'row'} spacing={1}>
            <Typography variant="body1">
              Total number of chats: <strong>{totalChats}</strong>
            </Typography>
            <Typography variant="body1">
              Agent chats: <strong>{totalAgentChats}</strong>
            </Typography>
            <Typography variant="body1">
              Total number of feedbacks: <strong>{totalFeedbacks}</strong>
            </Typography>
          </Stack>
          <ResponsiveContainer height={350} width="100%">
            <LineChart width={630} height={500} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
              <XAxis dataKey="createdAt" xAxisId="benchmark" />
              <XAxis hide dataKey="createdAt" xAxisId="value" />
              <XAxis hide dataKey="createdAt" xAxisId="agentChats" />
              <YAxis />
              <Tooltip />
              <Line
                xAxisId="value"
                name="Chats #"
                data={stats?.chats}
                type="monotone"
                dataKey="count"
                stroke="#0056B9"
              />
              <Line
                xAxisId="agentChats"
                name="Agent chats #"
                data={stats?.agentChats}
                type="monotone"
                dataKey="count"
                stroke="#FFD800"
              />
              <Line
                xAxisId="benchmark"
                name="Feedback #"
                data={stats?.feedbacks}
                type="monotone"
                dataKey="count"
                stroke="#82ca9d"
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item md={11}>
          <Typography variant="h5" mb={4}>
            Number of positive and negative feedbacks per day
          </Typography>
          <ResponsiveContainer height={350} width="100%">
            <LineChart width={630} height={500} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
              <XAxis dataKey="createdAt" xAxisId="benchmark" />
              <XAxis hide dataKey="createdAt" xAxisId="value" />
              <YAxis />
              <Tooltip />
              <Line
                xAxisId="benchmark"
                name="Positive feedback #"
                data={stats?.feedbacks}
                type="monotone"
                dataKey="count_positive"
                stroke="#82ca9d"
              />
              <Line
                xAxisId="value"
                name="Negative feedback #"
                data={stats?.feedbacks}
                type="monotone"
                dataKey="count_negative"
                stroke="#FF0000"
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </>
  );
};
