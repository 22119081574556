import { useCallback, useState } from 'react';

import { HttpResponse, ReqInstance } from '~/services/types';

interface useAccommodationServiceRequestProps<APIResp> {
  apiReq: () => Promise<HttpResponse<APIResp>>;
  onSuccess?: (result: APIResp) => void;
}

export function useAccommodationServiceRequest<APIResp>({
  apiReq,
  onSuccess,
}: useAccommodationServiceRequestProps<APIResp>) {
  const [reqState, setReqState] = useState<ReqInstance<APIResp>>({
    status: 'uninitiated',
  });

  const doRequest = useCallback(async () => {
    setReqState({ status: 'pending' });
    try {
      const response = await apiReq();
      setReqState({ status: 'succeeded', result: response.result });
      if (onSuccess) {
        onSuccess(response.result);
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.errors) {
        errorMessage = `${error?.errors.join('\n')}`;
      }
      setReqState({ status: 'failed', error: errorMessage });
    }
  }, [apiReq, onSuccess]);

  return { reqState, doRequest: doRequest };
}
