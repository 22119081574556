import React from 'react';

import { Helmet } from 'react-helmet';

import { Box } from '@mui/material';

import Asinc from '~/components/Common/Asinc';
import PageHeader from '~/components/Common/Elements/PageHeader';
import TourForm from '~/components/Common/Forms/TourForm';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

export default class TourNewContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = props;
    this.vendorId = match.params.id_vendor;

    this.fetchData = this.fetchData.bind(this);
  }

  async fetchData() {
    const [schema, vendor] = await Promise.all([
      ReservationService.getTourSchema(),
      VendorsService.getVendorById(this.vendorId),
    ]);

    return {
      schema: schema.post.body.schema,
      vendor: vendor.result,
    };
  }

  render() {
    return (
      <div className="container tour-edit-form">
        <PageHeader title="Create Tour" />
        <Asinc
          promise={this.fetchData()}
          then={({ schema, vendor }) => {
            const tour = { id_salesforce_external: this.vendorId };

            return (
              <Box id="overview" className="tour-deal-section">
                <Helmet>
                  <title>Vendors | {vendor?.name || this.vendorId} | Create Tour</title>
                </Helmet>

                <TourForm schema={schema} tour={tour} vendorId={this.vendorId} />
              </Box>
            );
          }}
        />
      </div>
    );
  }
}
