import React from 'react';

import { Alert, Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

const SuccessMessage = ({ total }) => {
  return (
    <Alert severity="success">
      <strong>Success!</strong>
      <p>File upload successful</p>
      <p>Total:</p>
      <ul>
        {Object.entries(total).map(([key, value], index) => (
          <li key={index}>
            {value} {key}
          </li>
        ))}
      </ul>
    </Alert>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <Alert severity="error">
      <strong>Oops!</strong>
      <p>File upload failed</p>
      <pre>{message}</pre>
    </Alert>
  );
};

export default class UploadExpediaPayments extends React.Component {
  state = {
    successMessage: false,
    showErrorMessage: false,
    errorMessage: '',
    file: null,
    date_of_payment: '',
    total: null,
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await this.fileUpload(this.state.file, this.state.date_of_payment);
      this.setState({
        successMessage: true,
        showErrorMessage: false,
        errorMessage: '',
        file: null,
        total: result.total,
      });
    } catch (err) {
      this.setState({
        successMessage: false,
        showErrorMessage: true,
        errorMessage: err.message,
        file: null,
        total: null,
      });
    }
  };

  onChange = async (e) => {
    this.setState({ file: e.target.files[0] });
  };

  onDateOfPaymentChange = async (e) => {
    this.setState({ date_of_payment: e.target.value });
  };

  fileUpload = async (file, date_of_payment) => {
    const formData = new FormData();
    formData.append('expedia-payments', file);
    formData.append('date_of_payment', date_of_payment);
    return VendorsService.uploadExpediaPayments(formData);
  };

  render() {
    const { successMessage, showErrorMessage, errorMessage } = this.state;

    return (
      <div>
        <p>Expedia Payments will not be reflected in any reports until the following day.</p>

        {successMessage && <SuccessMessage total={this.state.total} />}
        {showErrorMessage && <ErrorMessage message={errorMessage} />}

        <form onSubmit={this.onFormSubmit}>
          <h5>Date of Payment</h5>
          <input type="date" onChange={this.onDateOfPaymentChange} />
          <h5>File Upload</h5>
          <input type="file" onChange={this.onChange} />
          <Button sx={{ marginTop: '10px' }} type="submit" variant="contained">
            Upload payments
          </Button>
        </form>
      </div>
    );
  }
}
