import React, { FormEvent, useRef } from 'react';

import { Box, Button, MenuItem, Stack, TextField } from '@mui/material';

import { VENDORS } from '~/components/Cruises/constants';
import { useCruiseShips } from '~/components/Cruises/context/useCruiseShips';

export default function ShipSearch() {
  const formRef = useRef(null);
  const { setFilter, filter } = useCruiseShips();

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    const form = formRef.current;
    event.preventDefault();

    setFilter({
      name: form['name']?.value,
      cruiseLine: form['cruiseLine']?.value,
    });
  };

  const isSelected = (value: string, field: string): boolean => filter[field] === value;

  return (
    <div>
      <form ref={formRef} onSubmit={onSubmit}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box flexGrow={1}>
            <TextField label="Name" type="text" name="name" placeholder="Search by name" fullWidth />
          </Box>

          <Box flexGrow={1}>
            <TextField
              name="cruiseLine"
              placeholder="Select Cruise Line"
              label="Cruise Line"
              SelectProps={{ displayEmpty: true }}
              select
              fullWidth
            >
              <MenuItem value={''}>Select a Cruise Line</MenuItem>
              {VENDORS.map((item, i) => (
                <MenuItem key={i} value={item} selected={isSelected(item, 'cruiseLine')}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </Stack>
      </form>
    </div>
  );
}
