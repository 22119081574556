import { RESERVATION_TYPE_INSTANT_BOOKING } from '../consts/reservation';

export default function isInstantBooking(items) {
  return items.every(
    (item) =>
      item.reservation.reservationType == RESERVATION_TYPE_INSTANT_BOOKING &&
      (item.reservation.checkIn || item.reservation.startDate) &&
      (item.reservation.checkOut || item.reservation.endDate),
  );
}
