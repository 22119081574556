import React, { useState } from 'react';

import { Autocomplete, TextField, Typography } from '@mui/material';

import { TypeaheadResult } from '~/services/SearchService';

interface Props {
  onSelect: (selection: TypeaheadResult) => void;
  setValue: (query: string) => void;
  value: string;
  setOpen: (open: boolean) => void;
  open: boolean;
  results: TypeaheadResult[];
}

export default function PlaceSearchForm(props: Props) {
  const { onSelect, value, setValue, results } = props;
  const [currentValue, setCurrentValue] = useState<TypeaheadResult | null>(null);

  const select = (result: TypeaheadResult | null) => {
    setCurrentValue(result);

    if (result !== null) onSelect(result);
  };

  return (
    <div>
      <Autocomplete
        options={results ?? []}
        filterOptions={(x) => x}
        value={currentValue}
        inputValue={value}
        noOptionsText="No locations"
        placeholder="Start typing the name of the place"
        getOptionKey={(option) => option.fk}
        getOptionLabel={(option) => option.primary_text ?? 'Anywhere'}
        renderInput={(params) => (
          <TextField label="Search place" name="searchString" data-testid="PlaceSearch" {...params} />
        )}
        renderOption={(props, option: TypeaheadResult) => (
          <li {...props}>
            <Typography>
              {option.primary_text} <small>{`${option.type} | ${option.secondary_text}`}</small>
            </Typography>
          </li>
        )}
        onInputChange={(_, value: string) => {
          setValue(value);
        }}
        onChange={(_, value: TypeaheadResult | null) => select(value)}
        fullWidth
      />
    </div>
  );
}
