import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Alert, Button } from '@mui/material';

import { queueMusementBookingsReport } from '~/services/ReportingService';
import { formatDateISO, startOfMonth, subMonths } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter your purchase dates to export the Musement bookings report',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    format: {
      title: 'File Format',
      type: 'string',
      enum: ['csv', 'email'],
    },
  },
};

const formData = {
  startAt: formatDateISO(startOfMonth(subMonths(1))),
  endAt: formatDateISO(startOfMonth()),
  format: 'email',
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  format: {
    'ui:widget': 'radio',
  },
};

export default function ExportMusementBookingsForm() {
  const [isLoadingReportGeneration, setIsLoadingReportGeneration] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt, format } = event.formData;
    const fileName = `musement_bookings_report_${startAt}_${endAt}.${format}`;

    setIsLoadingReportGeneration(true);

    queueMusementBookingsReport({ startAt, endAt, format })
      .then((text) => {
        if (format !== 'email') {
          fileDownload(text, fileName);
        } else {
          setShowSuccessMessage(true);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoadingReportGeneration(false);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained" disabled={isLoadingReportGeneration}>
        {!isLoadingReportGeneration ? 'Download / Email Report' : 'Loading report...'}
      </Button>

      {error && <ErrorDisplay message={error} />}

      {showSuccessMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
