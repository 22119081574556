import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import { Alert, Autocomplete, Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';
import { getRegionNamesAndCode } from '@luxuryescapes/lib-regions';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';
import { useDismissible } from '~/components/Experiences/hooks';

import { getExperienceOfferById, getHeroesByRegion, updateHeroes } from '~/services/ExperiencesService';

import HeroesListItem from './HeroesListItem';

const SELECT_ALL_OPTION = { label: 'All', value: 'all' };

type ContentProps = {
  selectedExperiences: Experiences.Offer[];
  onRemoveExperience: (experience: Experiences.Offer) => void;
  onLoadSelectedExperiences: (experiences: Experiences.Offer[]) => void;
};

type DismissibleAlert = {
  type: 'success' | 'error';
  message: string;
};

export default function HeroesList(props: ContentProps) {
  const { selectedExperiences, onRemoveExperience, onLoadSelectedExperiences } = props;

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [alert, setAlert, dismissAlert] = useDismissible<DismissibleAlert>({
    autoDismissAfterMs: 3000,
  });

  const options = useMemo(
    () =>
      getRegionNamesAndCode(brand).map(({ name, code }) => ({
        label: name,
        value: code,
      })),
    [brand],
  );

  const editHero = async () => {
    setLoading(true);
    dismissAlert();
    try {
      const payload = {
        experiencesOffersIds: selectedExperiences.map((item) => item.id),
        brand,
      };
      await Promise.all(selectedRegions.map((region) => updateHeroes(region.value, payload)));

      setAlert({ type: 'success', message: 'Changed heroes with success!' });
    } catch (error) {
      console.error('ERROR ON UPDATE HERO: ', error);
      setAlert({
        type: 'error',
        message: `${error.status}: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchHeroes = async (): Promise<void> => {
    dismissAlert();

    if (selectedRegions.length !== 1) return setLoading(false);

    try {
      setLoading(true);
      const res = await getHeroesByRegion(selectedRegions[0]?.value, brand);
      if (res.errors?.length > 0)
        return setAlert({
          type: 'error',
          message: "[ERROR] Don't exists heroes response.",
        });

      const { experiencesOffersIds } = res.result;
      const experienceOffersRequests = await Promise.all(
        experiencesOffersIds.map((id) => getExperienceOfferById({ id, brand, curationData: true })),
      );

      if (experienceOffersRequests.some((res) => res.errors?.length))
        return setAlert({
          type: 'error',
          message: "[ERROR] Don't exists heroes response.",
        });

      const experiences = experienceOffersRequests.flatMap((res) => res.result);
      onLoadSelectedExperiences(experiences);
      setLoading(false);
    } catch (error) {
      console.error('ERROR ON FETCH HEROES: ', error);
      setAlert({
        type: 'error',
        message: `${error.status}: ${error.message}`,
      });
    }
  };

  useEffect(() => {
    fetchHeroes();
  }, [selectedRegions, brand]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onLoadSelectedExperiences(arrayMove(selectedExperiences, oldIndex, newIndex));
  };

  return (
    <Paper>
      <PageSubheader title="Selected experiences for:">
        <Box width={300}>
          <Autocomplete
            multiple
            options={[SELECT_ALL_OPTION, ...options]}
            limitTags={1}
            onChange={(_, values) => {
              if (values.filter((item) => item.value === SELECT_ALL_OPTION.value).length === 1) {
                setSelectedRegions(options);
              } else {
                setSelectedRegions(values);
              }
            }}
            value={selectedRegions}
            renderInput={(params) => <TextField {...params} label="Select Regions" />}
          />
        </Box>
      </PageSubheader>

      <Box>
        {isLoading ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Spinner size={32} />
          </Stack>
        ) : (
          <Stack direction="column" spacing={2}>
            {selectedRegions.length > 1 && (
              <Alert severity="warning" style={{ textAlign: 'center' }}>
                More than one region selected. Clicking on <strong>save</strong> button will <strong>override</strong>{' '}
                all experiences for the selected regions.
              </Alert>
            )}
            {selectedExperiences.length ? (
              <HeroesListItem
                distance={1}
                items={selectedExperiences}
                onSortEnd={onSortEnd}
                removeOnclick={onRemoveExperience}
              />
            ) : (
              <Typography>No Experiences</Typography>
            )}

            <Box>
              <Button variant="contained" onClick={editHero} disabled={!selectedRegions.length}>
                Save
              </Button>
            </Box>
          </Stack>
        )}
      </Box>

      <Box>
        {alert && (
          <Alert severity={alert.type} style={{ textAlign: 'center' }}>
            <strong>{alert.message}</strong>
          </Alert>
        )}
      </Box>
    </Paper>
  );
}
