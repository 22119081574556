import React from 'react';

import { Box } from '@mui/material';

import { REFUND_TEST_PURCHASE } from '~/consts/refund';

export default function SummaryDetails(props) {
  return (
    <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr 1fr">
      <Box gridColumn="span 3">
        <h4>Refund Summary</h4>
      </Box>

      <Box>
        <small>Help Desk Ticket ID</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.ticket_id}</small>
      </Box>

      <Box>
        <small>Customer Name</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.customer_full_name}</small>
      </Box>

      <Box>
        <small>Refund Category</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.reason}</small>
      </Box>

      <Box>
        <small>{props.reason === REFUND_TEST_PURCHASE ? 'Agent Name' : "Customer's reason"}</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.comment}</small>
      </Box>

      <Box>
        <small>Source of Fund</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.source}</small>
      </Box>

      <Box>
        <small>Customer Fee</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.totalFee}</small>
      </Box>

      <Box>
        <small>Refund Surcharge</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.hasRefundSurcharge}</small>
      </Box>
      <Box>
        <small>Package Cancelled</small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.markCancelled}</small>
      </Box>
      <Box>
        <small>
          <b>Customer Refund Amount {props.currencyCode}</b>
        </small>
      </Box>
      <Box gridColumn="span 2">
        <small>{props.totalRefund}</small>
      </Box>
    </Box>
  );
}
