import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Grid, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import useQuery from '~/hooks/useQuery';

import SearchService, { TypeaheadPriority, TypeaheadResult, TypeaheadType } from '~/services/SearchService';

import PlaceSearchForm from '../../Common/Forms/PlaceSearchForm';
import PlaceSearchFormControls from '../../Common/Forms/PlaceSearchFormControls';

import SearchMap from './SearchMap';

function isLePropertyType(type) {
  return ['le_property', 'le_property_unique_stays'].includes(type);
}

function isBedbankPropertyType(type) {
  return ['bedbank_property', 'bedbank_property_unique_stays'].includes(type);
}

async function getTypeaheadDeprioritisation(placeId) {
  try {
    await SearchService.getTypeaheadDeprioritisation(placeId);
    return true;
  } catch (e) {
    return false;
  }
}

async function putTypeaheadDeprioritisation(placeId) {
  await SearchService.putTypeaheadDeprioritisation(placeId);
  return true;
}

async function deleteTypeaheadDeprioritisation(placeId) {
  try {
    await SearchService.deleteTypeaheadDeprioritisation(placeId);
    return true;
  } catch (e) {
    return false;
  }
}

async function getLeProperty(propertyId) {
  try {
    const response = await SearchService.getLeProperty(propertyId);
    return response.result;
  } catch (e) {
    return false;
  }
}

async function getBedbankProperty(propertyId) {
  try {
    const response = await SearchService.getBedbankProperty(propertyId);
    return response.result;
  } catch (e) {
    return false;
  }
}

async function getPlace(placeId) {
  try {
    const response = await SearchService.getPlace(placeId);
    return response.result;
  } catch (e) {
    return false;
  }
}

export default function DebugSearch() {
  const query = useQuery();
  const q = query.get('q') || '';
  const [geometries, updateGeometries] = useState([]);
  const [properties, updateProperties] = useState([]);
  const [results, setResults] = useState<TypeaheadResult[]>([]);
  const [priority, setPriority] = useState<TypeaheadPriority>('current');
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(q);
  const [types, setTypes] = useState<Record<TypeaheadType, boolean>>({
    airport: false,
    province_state: true,
    neighborhood: true,
    city: true,
    high_level_region: true,
    country: true,
    multi_city_vicinity: true,
    metro_station: false,
    continent: true,
    train_station: false,
    point_of_interest: true,
    colloquial_area: true,
    le_property: true,
    le_property_unique_stays: true,
    bedbank_property: true,
    bedbank_property_unique_stays: true,
    le_experience: false,
    channel_experience: false,
    car_hire_location: false,
  });

  const search = useCallback(async () => {
    if (value.length < 2) {
      return;
    }

    const selectedTypes = Object.keys(types).filter((type) => types[type]) as (keyof typeof types)[];

    if (selectedTypes.length === 0) {
      return;
    }

    const res = await SearchService.typeahead(value, {
      type: selectedTypes,
      brand: 'luxuryescapes',
      region: 'AU',
      limit: 20,
      priority,
    });

    setResults(res.result.results);
  }, [value, types, priority]);

  useEffect(() => {
    const t = setTimeout(search, 500);
    return () => clearTimeout(t);
  }, [search]);

  const onSelect = async (selection) => {
    if (isLePropertyType(selection.type)) {
      if (properties.find((property) => property.propertyId === selection.fk)) {
        return;
      }

      const property = await getLeProperty(selection.fk);

      if (!property) {
        return;
      }

      updateProperties([...properties, property]);

      return;
    }

    if (isBedbankPropertyType(selection.type)) {
      if (properties.find((property) => property.propertyId === selection.fk)) {
        return;
      }

      const property = await getBedbankProperty(selection.fk);

      if (!property) {
        return;
      }

      updateProperties([...properties, property]);

      return;
    }

    if (geometries.find((geometry) => geometry.id === selection.fk)) {
      return;
    }

    const place = await getPlace(selection.fk);

    if (!place) {
      return;
    }

    const deprioritsed = await getTypeaheadDeprioritisation(place.placeId);

    const geometry = { ...place, deprioritsed };

    updateGeometries([...geometries, geometry]);
  };

  const onClear = useCallback(() => {
    updateGeometries([]);
    updateProperties([]);
  }, []);

  const formatOfferColumn = useCallback((cell, row) => {
    const values = row.offers;
    if (!values) {
      return <div>None</div>;
    }
    return (
      <div>
        {values.map((offerId) => (
          <div key={offerId}>
            <Link to={`/offers/${offerId}`}>{offerId}</Link>
          </div>
        ))}
      </div>
    );
  }, []);

  const formatDeprioritisationColumn = useCallback(
    (cell, geometry) => {
      const onChange = () => {
        const index = geometries.indexOf(geometry);
        const newGeometries = [...geometries];
        const deprioritsed = !geometry.deprioritsed;

        if (deprioritsed) {
          putTypeaheadDeprioritisation(geometry.placeId);
        } else {
          deleteTypeaheadDeprioritisation(geometry.placeId);
        }

        newGeometries[index] = { ...geometry, deprioritsed };
        updateGeometries(newGeometries);
      };

      return (
        <div>
          <input checked={geometry.deprioritsed} type="checkbox" onChange={onChange} />
        </div>
      );
    },
    [geometries],
  );

  const pointMapItems = useMemo(
    () => [...geometries.filter(({ centroid }) => !centroid), ...properties],
    [geometries, properties],
  );

  const areaMapItems = useMemo(() => geometries.filter(({ centroid }) => centroid), [geometries]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid xs={12} md={4} lg={3}>
          <div>
            <PlaceSearchFormControls setTypes={setTypes} types={types} setPriority={setPriority} priority={priority} />
          </div>
        </Grid>
        <Grid xs={12} md={8} lg={9}>
          <Stack direction="column" spacing={2}>
            <PlaceSearchForm
              setValue={setValue}
              value={value}
              setOpen={setOpen}
              open={open}
              onSelect={onSelect}
              results={results}
            />

            <SearchMap pointMapItems={pointMapItems} areaMapItems={areaMapItems} />

            <DataGrid
              columns={[
                { field: 'placeId', headerName: 'Place ID', flex: 1, sortable: false, display: 'flex' },
                { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
                { field: 'type', headerName: 'Type', flex: 1, sortable: false, display: 'flex' },
                {
                  field: 'deprioritisation',
                  headerName: 'Deprioritised',
                  flex: 1,
                  sortable: false,
                  renderCell: (params) => formatDeprioritisationColumn(null, params.row),
                  display: 'flex',
                },
              ]}
              rows={geometries}
              getRowId={(row) => row.placeId}
              getRowHeight={() => 'auto'}
              autoHeight
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
            />

            <DataGrid
              columns={[
                { field: 'propertyId', headerName: 'Property ID', flex: 1, sortable: false, display: 'flex' },
                { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
                { field: 'type', headerName: 'Type', width: 150, sortable: false, display: 'flex' },
                {
                  field: 'offers',
                  headerName: 'Offers',
                  width: 200,
                  sortable: false,
                  renderCell: (params) => formatOfferColumn(null, params.row),
                  display: 'flex',
                },
              ]}
              rows={properties}
              getRowId={(row) => row.propertyId}
              getRowHeight={() => 'auto'}
              autoHeight
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
            />

            <div>
              <Button onClick={onClear} variant="contained">
                Reset
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
