import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Chip, Link } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Tour as TourResponse } from '@luxuryescapes/contract-svc-tour';

import { getTours } from '~/services/ToursService';
import { ReqInstance } from '~/services/types';

import { TTCTourSearchInput } from '../TTCTourSearchForm';

import TourChips from './TourChips';
import TourStatusIndicatorIcon from './TourStatusIndicatorIcon';
import { getTourResultSellingRegions, getTourResultVisitedCountries } from './tourSearchResultValueGetters';

const priceFormatter = new Intl.NumberFormat('en-au', {
  style: 'decimal',
  minimumFractionDigits: 2,
});

const columns: GridColDef<TourResponse.Tour>[] = [
  {
    field: 'statusIndicator',
    headerName: '',
    align: 'center',
    display: 'flex',
    width: 40,
    valueGetter: (_value, row) => row.status,
    renderCell: (params) => <TourStatusIndicatorIcon status={params.value} />,
  },
  {
    field: 'name',
    headerName: 'Tour Name',
    minWidth: 200,
    display: 'flex',
    flex: 1,
    renderCell: (params) => (
      <Link href={`/tours/details/${params.row.id}`} target="_blank" className="ttc-tour-search-result-link">
        {params.value}
      </Link>
    ),
  },
  {
    field: 'brand',
    headerName: 'Operator',
    maxWidth: 160,
    minWidth: 100,
    display: 'flex',
    valueGetter: (_value, row) => row.brandObject.name,
  },
  {
    field: 'sellingRegions',
    headerName: 'Regions',
    maxWidth: 250,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    display: 'flex',
    valueGetter: (_value, row) => getTourResultSellingRegions(row),
    renderCell: (params) => <TourChips value={params.value} />,
  },
  {
    field: 'countriesVisited',
    headerName: 'Countries Visited',
    maxWidth: 250,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    display: 'flex',
    valueGetter: (_value, row) => getTourResultVisitedCountries(row),
    renderCell: (params) => <TourChips value={params.value} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 140,
    headerAlign: 'center',
    align: 'center',
    display: 'flex',
    renderCell: (params) => (
      <Chip label={params.value} size="small" color={params.value === 'content-approved' ? 'info' : undefined} />
    ),
  },
  {
    field: 'cheapestPrice',
    headerName: 'From price',
    maxWidth: 200,
    minWidth: 100,
    align: 'right',
    headerAlign: 'right',
    display: 'flex',
    valueFormatter: (value) => (value ? priceFormatter.format(value) : '-'),
  },
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 200,
    minWidth: 160,
    display: 'flex',
  },
];

interface Props {
  searchInput: TTCTourSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export default function TTCTourSearchResultsTable(props: Props) {
  const { searchInput, pageIndex, onPageChange } = props;

  const [toursFetchReq, setToursFetchReq] = useState<ReqInstance<{ tours: TourResponse.Tour[]; total: number }>>({
    status: 'uninitiated',
  });

  const fetchTours = useCallback(async (input: TTCTourSearchInput, reqPageIndex = 0) => {
    setToursFetchReq({ status: 'pending' });

    try {
      const res = await getTours({
        source: 'ttc',
        page: String(reqPageIndex + 1),
        per_page: String(PAGE_SIZE),
        status: input.status,
        countriesVisited: input.countries,
        searchString: input.tourName,
        brands: input.brands,
        region: input.sellingRegion,
        retainDynamicTags: 'true',
      });

      setToursFetchReq({
        status: 'succeeded',
        result: { total: res.result.total, tours: res.result.tours },
      });
    } catch (err) {
      setToursFetchReq({ status: 'failed', error: JSON.stringify(err) });
    }
  }, []);

  useEffect(() => {
    fetchTours(searchInput, pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, pageIndex]);

  return (
    <>
      {toursFetchReq.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(toursFetchReq.error)}
        </Alert>
      )}
      <DataGrid
        columns={columns}
        rows={toursFetchReq.status === 'succeeded' ? toursFetchReq.result.tours : []}
        rowCount={toursFetchReq.status === 'succeeded' ? toursFetchReq.result.total : 0}
        getRowHeight={() => 'auto'}
        paginationMode="server"
        paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        loading={toursFetchReq.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableColumnMenu
      />
    </>
  );
}
