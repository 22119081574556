/* List of addons */
import React from 'react';

import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import getPackagePriceByCurrency from '~/utils/getPackagePriceByCurrency';

const AddonItem = (props) => {
  const { item, addon, getAddonQuantity, onRemoveAddon, onAddAddon, currencyCode, offer } = props;

  const addonQuantity = getAddonQuantity(addon);
  const isAddDisabled = addon.purchase_limit && addonQuantity >= addon.purchase_limit;
  const isRemoveDisabled = addonQuantity <= 0;
  return (
    <TableRow className="booking-form" data-testid={addon.id_salesforce_external}>
      <TableCell width="70%">
        <p>
          <strong>{addon.name}</strong>
        </p>
        <span>{addon.description}</span>
      </TableCell>
      <TableCell>{addon.channel_fare_type || `-`}</TableCell>
      <TableCell>
        {currencyCode}
        <span>
          {currencyFormatter.format(getPackagePriceByCurrency(addon, currencyCode).price, { code: currencyCode })}
        </span>
      </TableCell>
      <TableCell className="quanity-btn-cell">
        <input type="number" className="quantity-value" value={addonQuantity} disabled />
        {!addon.complimentary && (
          <>
            <button className="quantity-btn" disabled={isRemoveDisabled} onClick={() => onRemoveAddon(addon)}>
              &mdash;
            </button>
            <button className="quantity-btn" disabled={isAddDisabled} onClick={() => onAddAddon(addon, item, offer)}>
              +
            </button>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

AddonItem.propTypes = {
  offer: PropTypes.object.isRequired,
  addon: PropTypes.object.isRequired,
  currencyCode: PropTypes.string.isRequired,
  onAddAddon: PropTypes.func.isRequired,
  onRemoveAddon: PropTypes.func.isRequired,
  getAddonQuantity: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currencyCode: state.cart.currencyCode || 'AUD',
  };
};

export default connect(mapStateToProps)(AddonItem);
