import React from 'react';

import currencyFormatter from 'currency-formatter';

import { DataGrid } from '@mui/x-data-grid';

const paymentFormatter = (cell, row) => {
  const payments = currencyFormatter.format(cell, { code: row.currency });
  return <div>{payments}</div>;
};

const columns = [
  { field: 'id_payment', headerName: 'Traveler', width: 150, display: 'flex' },
  { field: 'title', headerName: 'Title', width: 150, sortable: true, display: 'flex' },
  { field: 'givenName', headerName: 'Given Names', width: 150, sortable: true, display: 'flex' },
  { field: 'surname', headerName: 'Last Name', width: 150, sortable: true, display: 'flex' },
  { field: 'type', headerName: 'Type', width: 150, sortable: true, display: 'flex' },
  { field: 'ticketNo', headerName: 'Ticket No', width: 150, sortable: true, display: 'flex' },
  { field: 'gross', headerName: 'Gross', width: 150, sortable: true, display: 'flex' },
  { field: 'taxes', headerName: 'Taxes', width: 150, sortable: true, display: 'flex' },
  {
    field: 'net',
    headerName: 'Net',
    width: 150,
    sortable: true,
    renderCell: (params) => paymentFormatter(params.value, params.row),
    display: 'flex',
  },
  {
    field: 'fees',
    headerName: 'Fees',
    width: 150,
    sortable: true,
    renderCell: (params) => paymentFormatter(params.value, params.row),
    display: 'flex',
  },
  { field: 'baggage', headerName: 'Baggage', width: 150, sortable: true, display: 'flex' },
];

export default function OrderTravellerDetails(props) {
  return (
    <DataGrid
      columns={columns}
      rows={props.travellers}
      getRowId={(row) => row.id_payment}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
}
