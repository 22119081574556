import React, { useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import { Box, Button, Checkbox, FormControl, ListSubheader, MenuItem, Select, TextField } from '@mui/material';

interface widgetProps {
  name: string;
  property: App.Bedbank.PropertyEdit;
  internalValue: Array<string>;
  activePromotions: App.Bedbank.PromotionsEdit[];
  setRates: (index: string | number, rates: Array<string>, append?: boolean) => void;
}

const containsText = (text, searchText) => {
  if (Array.isArray(searchText)) {
    return searchText.some((term) => text.toLowerCase().indexOf(term.toLowerCase()) > -1);
  }
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

const FIELD_NAME_REGEX = /\w+_(\d+)_restrictToRates/;

export default function PromotionMultiSelect({
  name,
  property,
  activePromotions,
  internalValue,
  setRates,
}: widgetProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState('');

  // The field name contains the index in the array between the root prefix (in this case it's root) and the field name (restrictToRates)
  // This seems to be the only way to get the index as we don't have access to the parent
  // I have left the regex without explicity putting root in it, because if someone decides to change the prefix this regex would fail to work
  const promotionIndex = useMemo(() => {
    return name.match(FIELD_NAME_REGEX)[1];
  }, [name]);

  const rateIds = useMemo(() => {
    return property.rates.map((rate) => rate.extId);
  }, [property]);

  const displayedRateIds = useMemo(
    () => rateIds.filter((rateId) => containsText(rateId, searchText.split(','))),
    [rateIds, searchText],
  );

  const onCopyClicked = () => {
    navigator.clipboard.writeText(JSON.stringify(activePromotions[promotionIndex]?.restrictToRates || []));
    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  const parseClipboardData = (data: string): Array<string> => {
    if (data.startsWith('[') && data.endsWith(']')) {
      return JSON.parse(data);
    }
    return data.split(',').map((item) => item.trim());
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const data = event.clipboardData.getData('text');
    try {
      const parsedData = parseClipboardData(data);
      const filteredData = parsedData.filter((rate) => rateIds.includes(rate.toString()));
      if (filteredData.length !== parsedData.length) {
        enqueueSnackbar('Some rates are not available', { variant: 'warning' });
      }
      // Pasted values are appended
      setRates(promotionIndex, filteredData, true);
    } catch (error) {
      enqueueSnackbar('Invalid data', { variant: 'error' });
    }
  };

  const selectedOptions = useMemo(() => {
    return internalValue || [];
  }, [internalValue]);

  const handleChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === 'all') {
      setRates(promotionIndex, selectedOptions.length === rateIds.length ? [] : rateIds);
      return;
    }
    setRates(promotionIndex, value);
  };

  const renderSelectedValue = () => {
    if (selectedOptions.length === rateIds.length) {
      return 'All items are selected';
    }
    if (selectedOptions.length) {
      const maxDisplayed = 3;
      return selectedOptions
        .slice(0, maxDisplayed)
        .join(', ')
        .concat(
          selectedOptions.length > maxDisplayed
            ? ` and ${pluralize('more rate', selectedOptions.length - maxDisplayed, true)} are selected`
            : '',
        );
    }
    return 'Select some items...';
  };

  return (
    <>
      <Box display="inline-block" mt={1} mb={1} ml={1}>
        <Button variant="contained" color="primary" size="small" onClick={onCopyClicked}>
          Copy values set
        </Button>
      </Box>

      <FormControl fullWidth>
        <Select
          MenuProps={{
            autoFocus: false,
            PaperProps: { sx: { maxHeight: 300 } },
          }}
          value={selectedOptions}
          onPaste={handlePaste}
          onChange={handleChange}
          onClose={() => setSearchText('')}
          renderValue={renderSelectedValue}
          multiple
          displayEmpty
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Search..."
              fullWidth
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem value="all">
            <Checkbox checked={selectedOptions.length === rateIds.length} />
            Select all
          </MenuItem>
          {displayedRateIds.map((rateId, i) => (
            <MenuItem key={i} value={rateId}>
              <Checkbox checked={selectedOptions.includes(rateId)} />
              {rateId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
