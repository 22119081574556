import * as libRegions from '@luxuryescapes/lib-regions';

import { ledBrands } from '../../../../consts/brands';

import { ScheduleUpdateResult } from './types';

export function extractErrorMessages(response: any) {
  return response.errors?.map((e) => e.message) ?? [response.message];
}

export function updateResultToErrorMessage(updateResult: ScheduleUpdateResult) {
  if (updateResult.result.status === 'rejected') {
    const messages = extractErrorMessages(updateResult.result.reason);
    return `${updateResult.label}: ${messages.join('; ')}`;
  }
  return null;
}

const regionNameLookup: { [regionCode: string]: string } = libRegions
  .getRegionNamesAndCode('luxuryescapes')
  .reduce((lookup, region) => {
    lookup[region.code] = region.name;
    return lookup;
  }, {});

export const getRegionName = (regionCode: string) => regionNameLookup[regionCode] ?? '';

export function getLEDBrandName(brandValue: string) {
  return ledBrands.find((b) => b.value === brandValue).title;
}
