import React, { useState } from 'react';

import { Button, Menu, MenuItem, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

interface Props extends GridRenderCellParams {
  downloadRemittance: (id: string, type: 'csv' | 'pdf') => void;
  handleCreateRemittance: (vendorId: string, dateOfPayment: string, costCurrency: string) => void;
  handleEmailRemittance: (id: string) => void;
  handleDeleteRemittance: (id: string) => void;
}

const RemittanceActionCell = ({
  downloadRemittance,
  handleCreateRemittance,
  handleEmailRemittance,
  handleDeleteRemittance,
  row,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleDownloadTypeClick = (type: 'csv' | 'pdf') => {
    downloadRemittance(row.id, type);
    setAnchorEl(null);
  };

  if (!row.remittance_number) {
    return (
      <Button
        onClick={() => handleCreateRemittance(row.vendor_id, row.date_of_payment, row.cost_currency)}
        size="small"
        variant="text"
      >
        Create
      </Button>
    );
  }

  return (
    <Stack direction="row">
      <Button onClick={(event) => setAnchorEl(event.currentTarget)} size="small" variant="text">
        Download
      </Button>
      <Button onClick={() => handleEmailRemittance(row.id)} size="small" variant="text">
        Email
      </Button>
      <Button onClick={() => handleDeleteRemittance(row.id)} size="small" variant="text">
        Cancel
      </Button>
      <Menu id="download-menu" anchorEl={anchorEl} open={isMenuOpen} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleDownloadTypeClick('csv')}>CSV</MenuItem>
        <MenuItem onClick={() => handleDownloadTypeClick('pdf')}>PDF</MenuItem>
      </Menu>
    </Stack>
  );
};

export default RemittanceActionCell;
