import React from 'react';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { updateCreditReservation } from '../../services/FlightsService';

const creditReservationStates = ['applied', 'inprogress', 'completed'];

class CreditReservationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'inprogress',
      comments: '',
      new_pnr_ids: '',
      available_credits: null,
      used_credits: null,
      alertMessage: '',
    };
  }

  derieveNumericValue = (str) => {
    if (str === '') return null;
    if (Number(str)) return Number(str);
    return NaN;
  };

  handleSubmit = async () => {
    const { creditReservation, history } = this.props;
    const { state, comments, new_pnr_ids, available_credits, used_credits } = this.state;

    const updatedCreditReservation = await updateCreditReservation(creditReservation.id, {
      state,
      comments,
      new_pnr_ids,
      available_credits: this.derieveNumericValue(available_credits),
      used_credits: this.derieveNumericValue(used_credits),
    });
    if (updatedCreditReservation.status === 200) {
      history.push(`/flights/credit-reservation/${creditReservation.id}/details`);
    } else {
      this.setState({
        alertMessage: 'Error updating credit reservation',
      });
    }
  };

  isDirty = () => {
    const properties = ['comments', 'new_pnr_ids', 'state', 'available_credits', 'used_credits'];

    return !isEqual(pick(this.state, properties), pick(this.props.creditReservation, properties));
  };

  componentDidMount() {
    const { state, comments, new_pnr_ids, available_credits, used_credits } = this.props.creditReservation;

    this.setState({
      state,
      comments,
      new_pnr_ids,
      available_credits,
      used_credits,
    });
  }

  onStateChange = (event) => {
    this.setState({
      state: event.target.value,
    });
  };

  onCommentsChange = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  onNewPnrIdsChange = (event) => {
    this.setState({
      new_pnr_ids: event.target.value,
    });
  };

  onAvailableCreditsChange = (event) => {
    const val = this.derieveNumericValue(event.target.value);
    if (!isNaN(val)) {
      this.setState({
        available_credits: String(val),
      });
    }
  };

  onUsedCreditsChange = (event) => {
    const val = this.derieveNumericValue(event.target.value);
    if (!isNaN(val)) {
      this.setState({
        used_credits: String(val),
      });
    }
  };

  render() {
    const { id, previous_pnr_id } = this.props.creditReservation;
    const { alertMessage } = this.state;

    return (
      <div className="container">
        <Button component={Link} to={`/flights/credit-reservation/${id}/details`}>
          <ChevronLeftIcon /> Return to request
        </Button>
        <h1 className="page-header">{`Edit Flight Credit Reservation for ${previous_pnr_id}`}</h1>

        {alertMessage && <ErrorDisplay message={alertMessage} />}

        <Stack flexDirection="column" gap={2}>
          <FormControl fullWidth>
            <InputLabel id="credit-reservation-state-label">State</InputLabel>
            <Select
              id="credit-reservation-state-label"
              label="State"
              labelId="credit-reservation-state-label"
              onChange={this.onStateChange}
              componentClass="select"
              placeholder="select"
              value={this.state.state}
            >
              {creditReservationStates?.map((type) => {
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Comments"
              value={this.state.comments}
              placeholder="Enter comments"
              onChange={this.onCommentsChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              value={this.state.new_pnr_ids}
              label="New PNR IDs"
              placeholder="Enter new Pnr ids"
              onChange={this.onNewPnrIdsChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              type="number"
              label="Available Credits"
              value={this.state.available_credits}
              placeholder="Available Credits"
              onChange={this.onAvailableCreditsChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              type="number"
              label="Used Credits"
              value={this.state.used_credits}
              placeholder="Used Credits"
              onChange={this.onUsedCreditsChange}
            />
          </FormControl>
          <Button variant="contained" color="primary" disabled={!this.isDirty()} onClick={this.handleSubmit}>
            Save
          </Button>
        </Stack>
      </div>
    );
  }
}

export default CreditReservationEdit;
