import React, { Fragment } from 'react';

import { Alert, Stack } from '@mui/material';

import { Brand } from '@luxuryescapes/lib-regions/lib/regions';

import { brands } from '~/consts/brands';
import { LUX_PLUS } from '~/consts/membership';

import { LIST_VISIBILITY, LUX_PLUS_SCHEDULE } from '../../../../consts/schedule';
import OffersService from '../../../../services/OffersService';
import { reportError } from '../../../../utils/reportError';

import ScheduleBulkUpdateContainer from './ScheduleBulkUpdateContainer';
import ScheduleTable from './ScheduleTable';

interface Props {
  brand?: Brand;
  offerId: string;
  shouldMigrateSchedule: boolean;
}

interface State {
  isUpdated: boolean;
  isFetched: boolean;
  schedules: Array<App.Schedule>;
  error?: Error;
  updateErrorMessages: Array<string>;
}

export default class Schedules extends React.Component<Props, State> {
  state: State = {
    isUpdated: false,
    isFetched: false,
    schedules: [],
    error: null,
    updateErrorMessages: null,
  };

  componentDidMount() {
    this.fetchOfferSchedules();
  }

  onSaveFinished = () => {
    this.setState(
      {
        isUpdated: !this.state.isUpdated,
        error: null,
        updateErrorMessages: null,
      },
      () => {
        this.fetchOfferSchedules();
      },
    );
  };

  clearErrors = () => {
    this.setState({
      error: null,
      updateErrorMessages: null,
    });
  };

  onError = (error: Error) => {
    this.setState({
      error,
    });
    reportError(error);

    // Re-fetch the offer in case the schedule data is now inconsistent between client/server
    this.fetchOfferSchedules();
  };

  onUpdateErrors = (updateErrorMessages: Array<string>) => {
    this.setState({
      updateErrorMessages,
    });
  };

  onDelete = (id: number) => {
    if (this.props.shouldMigrateSchedule) {
      return;
    }
    OffersService.deleteSchedule(this.props.offerId, id)
      .then(() => {
        this.onSaveFinished();
      })
      .catch((e) => {
        this.onError(e);
        reportError(e);
      });
  };

  fetchOfferSchedules = () => {
    OffersService.getOffer(this.props.offerId)
      .then((response) => {
        this.setState({
          schedules: response.result.brand_schedules,
          isFetched: true,
        });
      })
      .catch((error) => {
        this.setState({
          schedules: [],
          isFetched: false,
          error,
        });
        reportError(error);
      });
  };

  render() {
    const { brand = 'luxuryescapes', offerId, shouldMigrateSchedule } = this.props;
    const { schedules, isFetched, error, updateErrorMessages } = this.state;
    const brandTitle = brands.find((b) => b.value === brand)?.title;
    const showSchedules = isFetched;
    const scheduleTableTypes = [LIST_VISIBILITY, LUX_PLUS_SCHEDULE];

    return (
      <div className="fp-schedule-container">
        <h3>{brandTitle}</h3>
        <div className="fp-schedule-inner">
          {error && <Alert severity="error">{error.message}</Alert>}
          {updateErrorMessages && (
            <Alert severity="error">
              Error updating schedules:
              <ul>
                {updateErrorMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </Alert>
          )}
          {showSchedules && (
            <>
              <Stack direction="row" gap={2} justifyContent="flex-end">
                <ScheduleBulkUpdateContainer
                  brand={brand}
                  offerId={offerId}
                  schedules={schedules}
                  type={LUX_PLUS_SCHEDULE}
                  onOpen={this.clearErrors}
                  onSaveFinished={this.onSaveFinished}
                  shouldMigrateSchedule={shouldMigrateSchedule}
                  onError={this.fetchOfferSchedules}
                  title={`Bulk Update ${LUX_PLUS.PROGRAM_NAME}`}
                />
                <ScheduleBulkUpdateContainer
                  brand={brand}
                  offerId={offerId}
                  schedules={schedules}
                  type={LIST_VISIBILITY}
                  onOpen={this.clearErrors}
                  onSaveFinished={this.onSaveFinished}
                  shouldMigrateSchedule={shouldMigrateSchedule}
                  onError={this.fetchOfferSchedules}
                />
              </Stack>
              <ScheduleTable
                brand={brand}
                offerId={offerId}
                schedules={schedules}
                types={scheduleTableTypes}
                onDelete={this.onDelete}
                onSaveFinished={this.onSaveFinished}
                onError={this.onError}
                onUpdateErrors={this.onUpdateErrors}
                shouldMigrateSchedule={shouldMigrateSchedule}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
