import React, { useState } from 'react';

import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

const idColumns: GridColDef[] = [
  {
    field: 'sfid',
    headerName: 'Chain ID',
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'name',
    headerName: 'Chain Name',
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

const nameColumns: GridColDef[] = [
  {
    field: 'parentid',
    headerName: 'Chain ID',
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'parent_name',
    headerName: 'Chain Name',
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

const ChainSearchResults = (props) => {
  const [page, setPage] = useState(0);

  const { onRowClick, total, tableType } = props;
  const sizePerPage = 25;
  // handlePageChange, page;

  let { data } = props;

  // The search data allows for chain ID which will return a singular result. We need to wrap this for table slicing to work
  // See: VendorsService.getVendorById
  if (!Array.isArray(data)) {
    data = [data];
  }

  return (
    <DataGrid
      rows={data}
      columns={tableType === 'id' ? idColumns : nameColumns}
      getRowId={(row) => (tableType === 'id' ? row.sfid : row.parentid)}
      onRowClick={(params: GridRowParams) => onRowClick(params.row)}
      rowCount={total}
      pagination
      paginationMode="server"
      onPaginationModelChange={({ page }) => setPage(page)}
      pageSizeOptions={[sizePerPage]}
      paginationModel={{ page, pageSize: sizePerPage }}
      slots={{ pagination: GridPagination }}
      disableColumnMenu
      disableColumnFilter
      autoHeight
    />
  );
};

export default ChainSearchResults;
