import React from 'react';

import currencyFormatter from 'currency-formatter';
import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import CopyableField from '~/components/Common/CopyableField';

import { HOTEL_OFFER_TYPES, OFFER_TYPE_BUNDLE_AND_SAVE, TOUR_OFFER_TYPES } from '../../../consts/offerTypes';

export default class PackagesPane extends React.Component {
  getPackageName(packageData) {
    if (packageData.number_of_nights) {
      return `${packageData.number_of_nights} nights ${packageData.name}`;
    } else {
      return `${packageData.name}`;
    }
  }

  getRegionsFromPackageData(regions) {
    if (regions.includes('world')) {
      return ['World'];
    }
    const countries = regions.map((region) => {
      let country = libRegions.getRegionByCode(region, 'luxuryescapes');
      if (country && country.name) {
        return country.name;
      } else {
        return region;
      }
    });
    return countries;
  }

  generateLinkToAvailabilityRates(packageData, vendorId) {
    if (packageData?.fk_property_id && packageData?.fk_room_type_id && packageData?.fk_room_rate_id && vendorId) {
      return (
        <Link
          to={
            '/vendors/' +
            vendorId +
            '/properties/' +
            packageData.fk_property_id +
            '/room-types/' +
            packageData.fk_room_type_id +
            '/room-rates/' +
            packageData.fk_room_rate_id
          }
          target="_blank"
        >
          View Availability &amp; Rates&nbsp;
          <LaunchIcon
            sx={{
              position: 'relative',
              top: '0.25rem',
            }}
          />
        </Link>
      );
    }
    return 'Missing Data To Get Availability & Rates';
  }

  render() {
    const { result: offerResult } = this.props.offer;
    const { type: packageType } = offerResult;
    let title = 'Packages';
    let link = `/edit-offers/${offerResult.id_salesforce_external}`;
    if (packageType === OFFER_TYPE_BUNDLE_AND_SAVE) {
      title = `Bundle ${title}`;
      link = `${link}/bundle-packages`;
    } else {
      link = `${link}/packages`;
    }

    return (
      <span>
        <h1 className="page-header">
          {title}
          <Link to={link}>
            <EditIcon size="small" />
            <span className="sr-only">Edit</span>
          </Link>
        </h1>
        {offerResult.packages &&
          offerResult.packages.map((packageData, index) => (
            <Accordion
              key={packageData.le_package_id}
              sx={{
                border: '1px solid #ddd',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
                sx={{
                  borderBottom: '0',
                }}
              >
                <Typography variant="body1" data-meta={packageData.le_package_id}>
                  {this.getPackageName(packageData)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <dl
                  className={
                    HOTEL_OFFER_TYPES.includes(packageType) || TOUR_OFFER_TYPES.includes(packageType) ? 'col-sm-6' : ''
                  }
                >
                  <dt className="h6 text-uppercase text-muted">IDs</dt>
                  <dd>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '1rem' }}>
                      <CopyableField value={packageData.le_package_id} />
                      <CopyableField value={packageData.id_salesforce_external} />
                    </Box>
                  </dd>
                  <dt className="h6 text-uppercase text-muted">Inclusion</dt>
                  <dd>{packageData.description}</dd>
                </dl>
                {HOTEL_OFFER_TYPES.includes(packageType) && (
                  <>
                    <dl className="col-sm-6">
                      <dt className="h6 text-uppercase text-muted">
                        {packageData.fk_property_id && offerResult.vendor_account_id ? (
                          <Link
                            to={`/vendors/${offerResult.vendor_account_id}/properties/${packageData.fk_property_id}`}
                          >
                            Property
                          </Link>
                        ) : (
                          'Link Not Found'
                        )}
                      </dt>
                      <dt className="h6 text-uppercase text-muted">
                        {packageData && offerResult.vendor_account_id
                          ? this.generateLinkToAvailabilityRates(packageData, offerResult.vendor_account_id)
                          : 'Missing Data To Get Availability & Rates'}
                      </dt>
                    </dl>
                  </>
                )}
                {TOUR_OFFER_TYPES.includes(packageType) && (
                  <dl className="col-sm-6">
                    <dt className="h6 text-uppercase text-muted">
                      {packageData.fk_tour_id && offerResult.vendor_account_id ? (
                        <Link to={`/vendors/${offerResult.vendor_account_id}/tours/${packageData.fk_tour_id}`}>
                          Tour
                        </Link>
                      ) : (
                        'Link Not Found'
                      )}
                    </dt>
                  </dl>
                )}
                <div className="clearfix"></div>
                {packageType !== OFFER_TYPE_BUNDLE_AND_SAVE && (
                  <>
                    <dl>
                      <dt className="h6 text-uppercase text-muted">
                        <abbr title="Number">No</abbr> of nights
                      </dt>
                      <dd>{packageData.number_of_nights} Nights</dd>
                    </dl>
                    <dl>
                      <dt className="h6 text-uppercase text-muted">Cost Price</dt>
                      <dd>
                        {packageData.cost_currency}{' '}
                        {currencyFormatter.format(packageData.cost_price, {
                          code: packageData.cost_currency,
                        })}
                      </dd>
                    </dl>
                  </>
                )}
                <dl>
                  <dt className="h6 text-uppercase text-muted">Package Regions</dt>
                  <dd>
                    {this.getRegionsFromPackageData(packageData.regions || ['world']).map((country) => (
                      <span className="countries-span" key={country}>
                        {country}
                      </span>
                    ))}
                  </dd>
                </dl>
                {packageData.addons && packageData.addons.length > 0 && (
                  <dl>
                    <dt className="h6 text-uppercase text-muted">Add-Ons Available</dt>
                    <dd>{packageData.addons.map((addon) => addon.name).join(', ')}</dd>
                  </dl>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
      </span>
    );
  }
}
