import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Alert, Box, Button, Dialog, Stack, TextField, Typography } from '@mui/material';

import VendorService from '~/services/VendorsService';

interface Props {
  vendorId: string;
  isOpen: boolean;
  onClose: () => void;
}

const PartnerCentralInviteModal: React.FC<Props> = ({ vendorId, isOpen, onClose }) => {
  const [errors, setErrors] = useState('');
  const [givenNameField, setGivenNameField] = useState('');
  const [surnameField, setSurnameField] = useState('');
  const [emailField, setEmailField] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warningMessageMessage, setWarningMessageMessage] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async () => {
    setErrors(null);
    if (!givenNameField || !surnameField || !emailField) {
      setErrors('Please provide name and email address');
    } else {
      try {
        setIsSubmitting(true);
        const response = await VendorService.sendPartnerCentralInvite({
          givenName: givenNameField,
          surname: surnameField,
          email: emailField,
          roles: ['vendor-user'],
          vendors: [vendorId],
          brand: 'luxuryescapes',
        });
        setIsSubmitting(false);
        if (response.outcome === 'user_exists' || response.outcome === 'invitation_exists') {
          setWarningMessageMessage(response.message);
        } else {
          onClose();
          setGivenNameField('');
          setSurnameField('');
          setEmailField('');
          enqueueSnackbar(response.message, {
            variant: 'success',
          });
        }
      } catch (e) {
        setIsSubmitting(false);
        setErrors(e.message);
      }
    }
  }, [emailField, enqueueSnackbar, givenNameField, onClose, surnameField, vendorId]);

  const handleGivenNameChange = (query) => {
    setGivenNameField(query.target.value);
    setWarningMessageMessage('');
  };

  const handleSurnameChange = (query) => {
    setSurnameField(query.target.value);
    setWarningMessageMessage('');
  };

  const handleEmailChange = (query) => {
    setEmailField(query.target.value);
    setWarningMessageMessage('');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="invite-modal-title"
      aria-describedby="invite-modal-description"
      sx={{ m: 5 }}
    >
      <Stack direction="column" spacing={2} alignItems="center" sx={{ p: 4 }}>
        <Typography id="invite-modal-title" variant="h6" component="h2">
          Invite new user
        </Typography>
        <Typography id="invite-modal-description">
          Please insert the name and email address of the individual being invited to Partner Central
        </Typography>

        <TextField
          autoFocus
          type="text"
          fullWidth
          value={givenNameField}
          onChange={handleGivenNameChange}
          placeholder={'Given Name'}
          disabled={isSubmitting}
        />

        <TextField
          type="text"
          fullWidth
          value={surnameField}
          onChange={handleSurnameChange}
          placeholder={'Surname'}
          disabled={isSubmitting}
        />

        <TextField
          type="text"
          fullWidth
          value={emailField}
          onChange={handleEmailChange}
          placeholder={'Email'}
          disabled={isSubmitting}
        />

        {errors && (
          <Box sx={{ width: '100%' }}>
            <Alert severity="error">{errors}</Alert>
          </Box>
        )}
        <Stack direction="row" sx={{ width: '100%' }} spacing={1} alignItems="center">
          <Button type="submit" variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
            Send Invite
          </Button>
          <Button type="submit" variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
        {warningMessageMessage !== '' && (
          <Box sx={{ width: '100%' }}>
            <Alert severity="warning">{warningMessageMessage}</Alert>
          </Box>
        )}
      </Stack>
    </Dialog>
  );
};

export default PartnerCentralInviteModal;
