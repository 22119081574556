import React from 'react';

import { TableCell } from '@mui/material';

interface Props {
  segment: App.Segment;
  backgroundColor: string;
}

function SegmentHeaderGroup({ segment, backgroundColor }: Props) {
  return (
    <>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segment.shortName} Views
      </TableCell>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segment.shortName} Sales Count
      </TableCell>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segment.shortName} Sales Amount
      </TableCell>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segment.shortName} EV
      </TableCell>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segment.shortName} EV Score
      </TableCell>
    </>
  );
}

export default SegmentHeaderGroup;
