import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import UpdateTrendingPlaceImageForm from '~/components/Content/TrendingPlaces/UpdateTrendingPlaceImageForm';

type props = {
  open: boolean;
  onClose: () => void;
};

const UpdateTrendingPlaceImageModal = ({ open, onClose }: props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Update Image</DialogTitle>
      <DialogContent>
        <UpdateTrendingPlaceImageForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default UpdateTrendingPlaceImageModal;
