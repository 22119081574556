import React from 'react';

import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { accountFieldsMap, earnData, getBonusUnitPluralize, postEarnData } from '~/services/LoyaltyService';
import OffersService from '~/services/OffersService';

import { reportError } from '~/utils/reportError';

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormControl>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input id={id} {...props} />
      {help && <FormHelperText>{help}</FormHelperText>}
    </FormControl>
  );
}

function StatusModal({ status, message, onHide }) {
  return (
    <Dialog open>
      <DialogTitle>{status}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onHide(status)}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
}

export default class LoyaltyEarnForm extends React.Component {
  constructor(props) {
    super(props);

    const { partnership, customer } = this.props;

    let stateAccountFields = {};
    const accountPartnership = customer.partnerships && customer.partnerships[partnership.prefix];

    if (accountPartnership) {
      partnership.accountFields.forEach((field) => {
        stateAccountFields[field] = accountPartnership[field] || '';
      });
    }

    this.state = {
      member_id: props.customer.id_member,
      member_given_name: props.customer.givenName,
      member_surname: props.customer.surname,
      member_fullname: props.customer.fullName,
      order_id: props.order.id,
      order_item_name: '',
      item_id: '',
      order_date: props.order.created_at,
      order_payment_amount: '',
      currency_code: props.order.currency_code,
      order_item_surcharge: 0,
      points_requested: '',
      has_bonus_multiplier: '',
      points_base_multiplier: '',
      points_bonus_multiplier: '',
      promo: this.itemPromo(),
      order_item_status: '',
      request_by: typeof props.requestor !== 'undefined' ? props.requestor.id_member : '',
      request_reason: '',
      request_comment: '',
      bonus_points: 0,
      base_points: 0,
      ...stateAccountFields,
    };
  }

  otherReasonComment = () => {
    if (this.state.request_reason === 'Other' && !this.state.request_comment) {
      return false;
    }
    return true;
  };

  getValidationState = () => {
    const amount = this.state.points_requested || 0;
    let maxAmount = this.amountAfterPromo();
    if (parseInt(amount) > parseInt(maxAmount)) {
      return 'error';
    }
    return null;
  };

  closeStatusModal = (status) => {
    this.props.onHide();
    this.props.newPointsRequest(status);
  };

  onSubmitSuccess = (response) => {
    if (response.message) {
      this.setState({
        submitStatus: {
          title: 'Success',
          message: 'Your request was successful',
        },
      });
    }
  };

  onSubmitFailure = (error) => {
    if (error.message) {
      this.setState({
        submitStatus: {
          title: 'Error',
          message: 'There was an issue processing your request',
        },
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = earnData(this.state, this.props.partnership);
    postEarnData(data, this.props.partnership.code)
      .then((result) => this.onSubmitSuccess(result))
      .catch((error) => this.onSubmitFailure(error));
  };

  findItem = (items, type, value) => items.find((item) => item[type] === value);

  itemSurcharge = (item) => {
    let surcharge = '';
    if (item.reservation_made && item.reservation) {
      if (!item.reservation.surcharge_paid_direct_to_vendor && item.reservation.surcharge) {
        surcharge = item.reservation.surcharge;
      }
    }
    return surcharge;
  };

  itemPromo = () => {
    const promo = Object.assign({}, this.findItem(this.props.order.payments, 'type', 'promo'));
    if (promo) {
      return (promo.amount /= this.props.order.items.length);
    }
  };

  amountAfterPromo = () => {
    if (this.state.order_item_status === 'cancelled') {
      return 0;
    }
    const promoPaid = this.state.promo || 0;
    const surcharge = this.state.order_item_surcharge || 0;
    const packageBonus = this.state.bonus_points;
    const amountAfter = this.state.order_payment_amount + packageBonus + surcharge - promoPaid;
    return Math.round(amountAfter);
  };

  handleItemSelect = async (event) => {
    event.preventDefault();
    if (event.target.value === 'default') {
      return null;
    }
    const selection = this.findItem(this.props.order.items, 'id', event.target.value);

    let basePoints = 0;
    try {
      const packageData = await OffersService.getPackage(selection.fk_offer, selection.fk_package);

      const packagePrices = packageData.result && packageData.result.prices;
      const priceInUsd = packagePrices.find((price) => price.currency_code === 'USD');
      basePoints = priceInUsd && priceInUsd.price;
    } catch (error) {
      reportError(error);
    }

    const surcharge = this.itemSurcharge(selection);
    this.setState({
      order_item_status: selection.status,
      order_item_name: selection.offer.name,
      item_id: selection.id,
      order_payment_amount: selection.price,
      order_item_surcharge: surcharge,
      bonus_points: selection.offer_package[`${this.props.partnership.prefix}_bonus_points`] || 0,
      base_points: basePoints,
      has_bonus_multiplier: selection.has_points_multiplier || false,
      points_base_multiplier: selection.points_base_multiplier || 1,
      points_bonus_multiplier: selection.points_base_multilier || 0,
    });
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { partnership } = this.props;
    const hasLoyaltyDetails = this.state.account_id;
    const hasOtherComment = this.otherReasonComment();
    const validationState = this.getValidationState();
    const accountFields = accountFieldsMap(partnership);
    const bonusUnit = getBonusUnitPluralize(partnership);
    return (
      <Dialog open={this.props.show} onClose={this.props.onHide}>
        <DialogTitle>Request {partnership.rewardName}</DialogTitle>
        <DialogContent>
          <form>
            <FieldGroup
              id="earnMemberName"
              type="text"
              label="Customer Name"
              value={this.state.member_fullname}
              disabled
              name="member_fullname"
            />

            {accountFields.map((fieldData) => (
              <Box key={`form_col_${fieldData.field}`}>
                <FieldGroup
                  id={`form_${fieldData.field}`}
                  key={`form_${fieldData.field}`}
                  type="text"
                  label={`${fieldData.name}`}
                  value={this.state[fieldData.accountField]}
                  disabled
                  name={`${fieldData.accountField}`}
                />
              </Box>
            ))}

            {!hasLoyaltyDetails && <p>Please link a {partnership.prefix.toUpperCase()} account before proceeding</p>}
            <p>
              To update the {partnership.prefix.toUpperCase()} account details{' '}
              <Link to={`/users/${this.state.member_id}`}>click here</Link>
            </p>

            {hasLoyaltyDetails && (
              <FormControl>
                <InputLabel>Please select an order item</InputLabel>
                <Select
                  onChange={this.handleItemSelect}
                  placeholder="select"
                  value={this.state.item_id}
                  id="earnOrderItem"
                >
                  <MenuItem value="default">Select...</MenuItem>
                  {this.props.order.items.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name} - {item.booking_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {this.state.item_id && (
              <div>
                <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
                  <div>
                    <FieldGroup
                      id="earnOrderPayment"
                      type="number"
                      label="Item Amount"
                      value={this.state.order_payment_amount}
                      disabled
                      name="order_payment_amount"
                    />
                    <FieldGroup
                      id="earnOrderSurcharge"
                      type="number"
                      label="Item Surcharge Amount"
                      value={this.state.order_item_surcharge}
                      disabled
                      name="order_payment_amount"
                    />
                  </div>
                  <div>
                    <FieldGroup
                      id="itemBonusPoints"
                      type="number"
                      label="Package Bonus Points"
                      value={this.state.bonus_points}
                      disabled
                      name="bonus_points"
                    />
                    <FieldGroup
                      id="earnItemPromo"
                      type="text"
                      label="Promo Value Redeemed"
                      value={this.state.promo ? `(${this.state.promo})` : ''}
                      disabled
                      name="promo"
                    />
                  </div>
                  <div>
                    <FieldGroup
                      id="eligiblePoints"
                      type="number"
                      label="Eligible Amount"
                      value={this.amountAfterPromo()}
                      name="eligible_points"
                      disabled
                    />
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel htmlFor="earnPointsRequested">Amount Requested</InputLabel>
                      <Input
                        type="number"
                        value={this.state.points_requested}
                        placeholder={0}
                        onChange={this.handleInputChange}
                        name="points_requested"
                        min={0}
                        id="earnPointsRequested"
                      />
                      {!!validationState && <FormHelperText>Can not be more than eligible amount.</FormHelperText>}
                    </FormControl>
                  </div>
                </Box>

                <FormControl>
                  <InputLabel htmlFor="source">Request Reason</InputLabel>
                  <Select
                    value={this.state.request_reason}
                    onChange={this.handleInputChange}
                    id="source"
                    placeholder="select"
                    name="request_reason"
                  >
                    {[
                      'Select',
                      'Points Never Submitted',
                      'Incorrect Amount/Missing Points',
                      'Incorrect Account Details',
                      'Resubmit Points Request',
                      'Other',
                    ].map((reason, index) => (
                      <MenuItem key={index} value={reason}>
                        {reason}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FieldGroup
                  onChange={this.handleInputChange}
                  id="earnRequestComment"
                  componentClass="textarea"
                  label="Request Comment"
                  value={this.state.reason_comment}
                  name="request_comment"
                  help={!hasOtherComment && '*Required'}
                />
              </div>
            )}
          </form>
        </DialogContent>

        <DialogActions>
          {this.state.item_id && (
            <Button
              className="T-submit"
              type="submit"
              disabled={
                !this.state.points_requested ||
                this.state.points_requested === '0' ||
                !this.state.request_reason ||
                this.state.request_reason === 'Select' ||
                !hasOtherComment ||
                !!validationState ||
                !hasLoyaltyDetails
              }
              onClick={this.handleSubmit}
            >
              Submit {bonusUnit} Request
            </Button>
          )}
          <Button type="button" variant="text" onClick={() => this.props.onHide()}>
            Cancel
          </Button>
        </DialogActions>

        {this.state.submitStatus && (
          <StatusModal
            onHide={this.closeStatusModal}
            status={this.state.submitStatus.title}
            message={this.state.submitStatus.message}
          />
        )}
      </Dialog>
    );
  }
}
