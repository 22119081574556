import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerCountries from '~/queries/customerCommunication/requestGetHeroPlannerCountries';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type CountryGroupCountriesParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountriesRecords']>['params']
>;

const fetchHeroPlannerCountries =
  (brand: string, countryGroupId: string): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();

    const params = [brand, countryGroupId] as CountryGroupCountriesParams;
    const key = getRecordKeyFor(...params);
    const existing = appState.customerCommunication.countryGroupCountriesRecords[key];
    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_FETCHING,
        params,
      });
      try {
        const countries = await requestGetHeroPlannerCountries(...params);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_FULFILLED,
          params,
          countries,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerCountries;
