import styled from 'styled-components';

import { themes } from '~/consts/experiences';

import { Spacing, margin, padding } from '../../helpers';

type InputProps = {
  disabled?: boolean;
};

export const FieldWrapper = styled.div<Spacing>`
  position: relative;

  > input:focus-visible {
    outline: none !important;
    border: 1px solid ${themes?.primaryBlue};
  }

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 8px;
  line-height: 18px;
  text-transform: capitalize;
  color: ${themes?.primaryDark};
`;

export const Field = styled.input<InputProps>`
  width: 100%;
  height: 35px;
  font-size: 14px;
  min-width: 105px;
  padding: 0px 10px;
  border-radius: 10px;

  color: ${({ disabled }) => disabled && themes.secondaryGrey};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  border: 1px solid ${({ disabled }) => (disabled ? themes.disabled : themes?.primaryGrey)};

  ::placeholder {
    color: ${({ disabled }) => disabled && themes.disabled};
  }
`;

export const FieldArea = styled.textarea<InputProps>`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;

  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  color: ${({ disabled }) => disabled && themes.disabled};

  border: 1px solid ${({ disabled }) => (disabled ? themes.disabled : themes?.primaryGrey)};

  ::placeholder {
    color: ${({ disabled }) => disabled && themes.disabled};
  }
`;

export const FieldSearch = styled.input<InputProps>`
  width: 100%;
  height: 34px;
  font-size: 14px;
  padding: 0px 10px;
  border-radius: 10px;

  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  color: ${({ disabled }) => disabled && themes.secondaryGrey};

  border: 1px solid ${({ disabled }) => (disabled ? themes.disabled : themes?.primaryGrey)};

  ::placeholder {
    color: ${({ disabled }) => disabled && themes.disabled};
  }
`;

export const OptionsWrapper = styled.div`
  z-index: 9;
  width: 100%;
  padding: 12px 0;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  border-radius: 8px;
  background-color: ${themes?.white};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

  > .center {
    width: 100%;
    text-align: center;
  }

  > div {
    cursor: pointer;
    font-size: 14px;
    padding: 8px 16px;
    line-height: 18px;
    color: ${themes?.primaryDark};

    :hover {
      background-color: ${themes?.primaryLight};
    }
  }
`;
