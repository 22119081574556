import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

const withOffers = (Component) => (props) => {
  const { offers = [] } = props;
  return offers.map((offer, i) => (
    <Component key={i} offerNumber={i + 1} isMultiBooking={offers.length > 1} offer={offer} {...props} />
  ));
};

const mapStateToProps = (state) => {
  return {
    offers: state.cart.offers,
  };
};

export default compose(connect(mapStateToProps), withOffers);
