import { addDays, dateNow, dateNowUtc, datesHaveSameDayMonthYear, isAfter } from '~/services/TimeService';

import { ADDON_STATUS_CONTENT_APPROVED } from '../consts/addons';

import isInstantBooking from './isInstantBooking';

export default function canAddAddons(items) {
  // At lease one package has addons
  if (items.length == 0 || items.some((item) => item.pkg.addons.length == 0)) {
    return false;
  }

  // At least one addon must be buyable (adapted to multi-booking)
  if (!items.some((item) => item.pkg.addons.some((addon) => canAddAddon(addon, items)))) {
    return false;
  }

  return true;
}

export function canAddAddon(addon, items, options = {}) {
  const { currency } = options;

  if (addon.complimentary) {
    return false;
  }

  // Must be instant booking
  if (!isInstantBooking(items)) {
    return false;
  }

  //Must be content-approved
  if (addon.status !== ADDON_STATUS_CONTENT_APPROVED) {
    return false;
  }

  //Can't be inactive
  if (addon.inactive === true) {
    return false;
  }

  // Must be booking before/on the book by date
  const now = dateNow();
  const bookByDate = addDays(1, dateNowUtc(addon.book_by_date));
  if (datesHaveSameDayMonthYear(now, bookByDate) || isAfter(now, bookByDate)) {
    return false;
  }

  // First day of reservation must be on or after the travel from date
  const travelFromDate = dateNowUtc(addon.travel_from_date);
  if (isAfter(travelFromDate, earliestCheckin(items))) {
    return false;
  }

  // Last day of reservation must be on or before the travel to date
  const travelToDate = dateNowUtc(addon.travel_to_date);
  if (isAfter(latestCheckout(items), travelToDate)) {
    return false;
  }

  // Check if addon prices have needed currency
  if (
    currency &&
    addon.prices.findIndex((price) => {
      return price.currency_code === currency;
    }) < 0
  ) {
    return false;
  }

  return true;
}

// Vaildate new dates of addons
export function validateNewDatesAddon(addons, item) {
  let isValid = true;

  if (addons && addons.length > 0) {
    addons.forEach((addon) => {
      const pkgAddon = item.pkg.addons.find((pkgAddonData) => {
        return (pkgAddonData.id_salesforce_external = addon.addonId);
      });

      if (!canAddAddon(pkgAddon, [item])) {
        isValid = false;
      }
    });
  }

  return isValid;
}

// Returns the earliest checkin or start date from the items in the cart
function earliestCheckin(items) {
  return items.reduce((earliest, item) => {
    if (item.reservation.checkOut) {
      const checkIn = dateNowUtc(item.reservation.checkIn);
      if (isAfter(earliest, checkIn)) {
        return checkIn;
      }
    }
    if (item.reservation.endDate) {
      const startDate = dateNowUtc(item.reservation.startDate);
      if (isAfter(earliest, startDate)) {
        return startDate;
      }
    }
    return earliest;
  }, dateNow('2200-01-01')); // Arbitrary future date
}

// Returns the latest checkout or end date from the items in the cart
function latestCheckout(items) {
  return items.reduce((latest, item) => {
    if (item.reservation.checkOut) {
      const checkOut = dateNowUtc(item.reservation.checkOut);
      if (isAfter(checkOut, latest)) {
        return checkOut;
      }
    }
    if (item.reservation.endDate) {
      const endDate = dateNowUtc(item.reservation.endDate);
      if (isAfter(endDate, latest)) {
        return endDate;
      }
    }
    return latest;
  }, dateNow());
}
