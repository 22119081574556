import React from 'react';

import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { enquireHotelReservation } from '~/actions/cart';

import { NUM_MONTHS } from '~/consts/calendar';

import { addDays, dateNowUtc } from '~/services/TimeService';

import DatePicker from './DatePicker';

interface QuoteResult {
  quoteId: string;
  total: number;
  excessAmount: number;
  productId: string;
  productName: string;
}

interface Insurance {
  currencyCode: string;
  customerCountryCode: string;
  destinationCountries: Array<any>;
  endDate: any;
  fetched: boolean;
  notInterested: boolean;
  notInterestedReason: boolean;
  products: Array<any>;
  productsFetched: boolean;
  quoteErrors: Array<any>;
  quoteFetched: boolean;
  quoteResults: Array<QuoteResult>;
}

interface State {
  badges: App.BadgesState;
  cart: App.Cart;
  insurance: Insurance;
  order: any;
  tenant: App.Tenant;
  user: {
    isLoading: boolean;
    isUpdating: boolean;
    items: any;
  };
  selectedDate: {
    checkIn: Date;
    checkOut: Date;
    lastNight: Date;
    durationSurchargeTotal?: number;
  };
}

interface Props {
  brand: string;
  closeModal: any;
  currencyCode: string;
  doEnquireHotelReservation: any;
  isOpen: boolean;
  item: any;
  offer: any;
  onUpdateReservation: any;
  showDepositNote?: boolean;
  surchargePaidDirectToVendor?: boolean;
}

class HotelBookingModal extends React.Component<Props, State> {
  static contextTypes: { user: PropTypes.Requireable<unknown> };
  constructor(props: Props) {
    super(props);

    const { item } = props;

    // initialize the selected dates for the calendar based on the reservation
    const selectedDate = {
      checkIn: item.reservation.checkIn,
      checkOut: item.reservation.checkOut,
      lastNight: this.getInitialLastNight(),
    };

    this.state = {
      ...this.state,
      selectedDate,
    };
  }

  // getInitialLastNight calculates the initial value for lastNight. If we are
  // changing dates, the original reservation obj does not have lastNight value,
  // and it needs to be initialized
  getInitialLastNight = () => {
    const { item } = this.props;

    let ret;

    if (item.reservation.lastNight) {
      ret = item.reservation.lastNight;
    } else {
      ret = addDays(-1, dateNowUtc(item.reservation.checkOut));
    }

    return ret;
  };

  handleMakeEnquiry = (offset) => {
    const { item, currencyCode, doEnquireHotelReservation, offer } = this.props;
    const { pkg } = item;

    const query = {
      months: NUM_MONTHS,
      nights: item.numberOfNights || pkg.number_of_nights,
      currency: currencyCode,
      offset,
      date_floor_offset: this.context.user.roles.includes('admin-user') ? 0 : 2,
      unique_key: pkg.offer_id_salesforce_external,
    };

    return doEnquireHotelReservation(
      item.id,
      pkg.fk_property_id,
      pkg.fk_room_type_id,
      pkg.fk_room_rate_id,
      query,
      offer,
    );
  };

  handleSelectDate = (date: App.SelectedDate) => {
    this.setState({
      selectedDate: {
        checkIn: date.check_in,
        checkOut: date.check_out,
        lastNight: date.last_night,
        durationSurchargeTotal: date.duration_surcharge_total,
      },
    });
  };

  // handleFinalizeDateSelection actually updates the reservation of the item
  // with the current date selection on the calendar
  handleFinalizeDateSelection = () => {
    const { item, onUpdateReservation, closeModal } = this.props;
    const { selectedDate } = this.state;

    onUpdateReservation(item, {
      checkIn: selectedDate.checkIn,
      checkOut: selectedDate.checkOut,
      lastNight: selectedDate.lastNight,
      durationSurchargeTotal: selectedDate.durationSurchargeTotal,
      waivedSurchargeAmount: undefined,
    });

    closeModal();
  };

  render() {
    const { isOpen, closeModal, item, currencyCode, surchargePaidDirectToVendor, offer, showDepositNote } = this.props;
    const { selectedDate } = this.state;
    const { dates } = item.enquiry;

    return (
      <Dialog open={isOpen} className="date-booking-modal" maxWidth="md" onClose={closeModal}>
        <DialogTitle>Selecting Dates</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <ul className="list-unstyled data-hj-whitelist">
              <li>{item.pkg.name}</li>
              <li>{pluralize('night', item.numberOfNights, true)}</li>
            </ul>
          </DialogContentText>

          <DatePicker
            dates={dates}
            onMakeEnquiry={this.handleMakeEnquiry}
            selectedDate={selectedDate}
            onSelectDate={this.handleSelectDate}
            currencyCode={currencyCode}
            roomRateId={item.pkg.fk_room_rate_id}
            offerType={offer.type}
          />
        </DialogContent>

        <DialogActions>
          <Box mr="auto">
            <Typography>+ surcharge payable {surchargePaidDirectToVendor ? 'at hotel' : 'upon booking'}</Typography>

            {showDepositNote && (
              <Typography>
                + Note for deposit bookings: to change to a date before the remaining balance is due, customer must pay
                off the remaining balance first
              </Typography>
            )}
          </Box>

          <Button variant="text" onClick={closeModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className="T-finalize-date"
            disabled={!selectedDate}
            onClick={this.handleFinalizeDateSelection}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    brand: state.tenant.brand,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doEnquireHotelReservation: enquireHotelReservation,
    },
    dispatch,
  );
}

HotelBookingModal.contextTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelBookingModal);
