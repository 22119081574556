import React, { Fragment } from 'react';

import classnames from 'clsx';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import HotelBookingModal from '~/components/Common/Booking/HotelBookingModal';
import TourBookingModal from '~/components/Common/Booking/TourBookingModal';
import TravelDates from '~/components/Common/Booking/TravelDates';
import Spinner from '~/components/Common/Spinner';

import { OFFER_FACING_NAMES } from '~/consts/offerTypes';

import { updateOrderItem } from '~/services/OrdersService';
import { getRefundMeta } from '~/services/PaymentsService';
import ReservationService from '~/services/ReservationService';
import { addDays, datesHaveSameDayMonthYear, isAfter } from '~/services/TimeService';

import { isAdmin } from '~/utils/adminPermission';
import { validateNewDatesAddon } from '~/utils/canAddAddons';
import { getItemChangeDatesPayload, getItemChangeGuestNamePayload } from '~/utils/cart';
import { reportError } from '~/utils/reportError';

import ChangeGuestName from './ChangeGuestName';
import DateRange from './DateRange';
import GuestName from './GuestName';

export default class OrderItemChangeReservation extends React.Component {
  constructor(props, context) {
    super(props);
    this.isAdmin = isAdmin(context.user);

    this.originalSurcharge = parseFloat(props.cartItem.surcharge);
    this.originalReservation = props.cartItem.reservation;

    this.state = {
      isReady: false,
      error: '',
      isProcessing: false,
      isConfirming: false,
      channelManaged: true,
      isPastBookByDate: props.offer.type === 'hotel' && !isAfter(props.offer.book_by_date),
      caseNumber: '',
    };
  }

  async componentDidMount() {
    try {
      const { orderId, cartItem, offer } = this.props;
      const { result: refundMetadata } = await getRefundMeta(orderId);
      let channelManaged = true;

      if (offer.type === 'hotel') {
        const { result: reservationProperty } = await ReservationService.getProperty(cartItem.pkg.fk_property_id);

        channelManaged = reservationProperty.channel_managed;
      }

      this.setState({
        channelManaged: channelManaged,
        refundMetadata: refundMetadata,
        isReady: true,
      });
    } catch (e) {
      reportError(e);
      this.setState({ error: e });
    }
  }

  // Update originalReservation with the fetched dates
  componentDidUpdate(prevProps) {
    if (!prevProps.cartItem.reservation.checkIn && this.props.cartItem.reservation.checkIn) {
      this.originalReservation = this.props.cartItem.reservation;
    }
  }

  // getBookingDatesItem returns the first item in the cart that is
  // undergoing the process of booking dates
  // TODO: make this a util not method for DRY
  getBookingDatesItem = () => {
    const { cartItems } = this.props;

    for (let i = 0; i < cartItems.length; i++) {
      const item = cartItems[i];

      if (item.isBookingDates) {
        return item;
      }
    }

    return null;
  };

  // getSelectedDateRange returns an object denoting the selected date range
  // of a tour or hotel offer
  getSelectedDateRange = (reservation) => {
    const { offer } = this.props;

    let startDate;
    let endDate;
    if (offer.type === 'tour') {
      startDate = reservation.startDate;
      endDate = reservation.endDate;
    } else {
      startDate = reservation.checkIn;
      endDate = reservation.checkOut;
    }

    return {
      startDate,
      endDate,
    };
  };

  // hasDateChanged checks if the user has selected a new set of dates and
  // that the new date selection includes the same amount of surcharge as the
  // original's

  hasDateChanged = () => {
    const { cartItem } = this.props;
    const { startDate, endDate } = this.getSelectedDateRange(cartItem.reservation);
    const { startDate: originalStartDate, endDate: originalEndDate } = this.getSelectedDateRange(
      this.originalReservation,
    );

    return (
      !datesHaveSameDayMonthYear(originalStartDate, startDate) || !datesHaveSameDayMonthYear(originalEndDate, endDate)
    );
  };

  // hasGuestChanged checks if the user has a new set of names and
  hasGuestChanged = () => {
    const { cartItem } = this.props;
    const { firstname: firstName, lastname: lastName } = cartItem.reservation;
    const { firstname: originalFirstName, lastname: originalLastName } = this.originalReservation;

    return originalFirstName !== firstName || originalLastName !== lastName;
  };

  isChangeAllowed = () => {
    const { cartItem, cart } = this.props;
    const { firstname: firstName, lastname: lastName } = cartItem.reservation;

    const hasDateChanged = this.hasDateChanged();
    const hasGuestChanged = this.hasGuestChanged();
    const addonValidation = validateNewDatesAddon(cart.addons, cartItem);

    return (
      (hasDateChanged || hasGuestChanged) &&
      firstName &&
      lastName &&
      addonValidation &&
      parseFloat(cartItem.surcharge) === this.originalSurcharge
    );
  };

  handleChange = () => {
    const { onAfterChangeDates, cartItem, offer, orderId } = this.props;
    let payloadDate = {};
    let payloadGuest = {};
    let payload = {};
    let op = null;

    const hasDateChanged = this.hasDateChanged();
    const hasGuestChanged = this.hasGuestChanged();

    if (hasDateChanged) {
      payloadDate = getItemChangeDatesPayload(cartItem, offer);
      op = payloadDate.op;
    }

    if (hasGuestChanged) {
      payloadGuest = getItemChangeGuestNamePayload(cartItem);
      op = hasDateChanged ? 'change_reservation' : payloadGuest.op;
    }

    payload = Object.assign({}, payloadDate, payloadGuest, {
      op: op,
      cn: this.state.caseNumber,
    });

    this.setState({ isProcessing: true }, () => {
      updateOrderItem(orderId, cartItem.id, payload)
        .then(onAfterChangeDates)
        .then(() => {
          this.setState({ isProcessing: false });
        })
        .catch((e) => {
          reportError(e);
          this.setState({ error: e });
        });
    });
  };

  promptConfirmation = () => {
    this.setState({ isConfirming: true });
  };

  cancelConfirmation = () => {
    this.setState({ isConfirming: false });
  };

  handleCaseNumberChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  closeBookingDateModal = () => {
    const { onToggleBookingDates } = this.props;

    onToggleBookingDates(false);
  };

  filterAvailableDates = (availableDates, offer) => {
    if (offer.type === 'hotel') {
      return availableDates.filter((d) => isAfter(d.check_in, addDays(60)));
    }
    if (offer.type === 'tour') {
      return availableDates.filter((d) => isAfter(d.start_date, addDays(105)));
    }
    return availableDates;
  };

  isDepositBalancePaid = (depositDetails) => {
    return ['due_balance_manual_debit_taken', 'due_balance_auto_debit_taken'].includes(depositDetails?.deposit_status);
  };

  renderBookingDateModal = () => {
    const { offer, currencyCode, cartItem, onUpdateReservation } = this.props;
    const isDepositOrder = this.props?.payments?.[0]?.depositDetails;

    if (isDepositOrder && !this.isDepositBalancePaid(this.props?.payments?.[0]?.depositDetails))
      cartItem.enquiry.dates = this.filterAvailableDates(cartItem?.enquiry?.dates, offer);

    if (offer.type === 'hotel') {
      return (
        <HotelBookingModal
          isOpen
          closeModal={this.closeBookingDateModal}
          item={cartItem}
          currencyCode={currencyCode}
          surchargePaidDirectToVendor={offer.surcharge_paid_direct_to_vendor}
          onUpdateReservation={onUpdateReservation}
          offer={{ ...offer, offerCartId: null }}
          showDepositNote={isDepositOrder}
        />
      );
    }

    if (offer.type === 'tour') {
      return (
        <TourBookingModal
          isOpen
          closeModal={this.closeBookingDateModal}
          item={cartItem}
          onUpdateReservation={onUpdateReservation}
          offer={{ ...offer, offerCartId: null }}
        />
      );
    }

    return <div>Offer of type {OFFER_FACING_NAMES[offer.type]} is not supported</div>;
  };

  render() {
    const { onCancel, cartItem, onToggleBookingDates, cart } = this.props;
    const { isReady, isProcessing, isConfirming, error, isPastBookByDate } = this.state;

    const { reservation } = cartItem;

    const hasDateChanged = this.hasDateChanged();
    const hasGuestChanged = this.hasGuestChanged();
    const isChangeAllowed = this.isChangeAllowed();

    if (error) {
      return (
        <div className="alert alert-danger">
          <div>Error occurred: {error.message}</div>
          {error.errors && <div>Please report the following: {JSON.stringify(error.errors)}</div>}
        </div>
      );
    }

    if (!isReady) {
      return <Spinner />;
    }

    const bookingDatesItem = this.getBookingDatesItem();
    const { startDate, endDate } = this.getSelectedDateRange(reservation);
    const { startDate: originalStartDate, endDate: originalEndDate } = this.getSelectedDateRange(
      this.originalReservation,
    );

    const { firstname: firstName, lastname: lastName } = cartItem.reservation;
    const { firstname: originalFirstName, lastname: originalLastName } = this.originalReservation;
    const pastBookByDateDisable = isPastBookByDate && (!this.isAdmin || !this.state.caseNumber);

    return (
      <div className="booking-form">
        {isReady && !this.state.channelManaged && (
          <ChangeGuestName
            item={cartItem}
            originalReservation={this.originalReservation}
            onUpdateReservation={this.props.onUpdateReservation}
          />
        )}

        <div className="booking-form-row">
          <div className="booking-form-item">
            <label>Travel Dates</label>
            <TravelDates
              startDate={startDate}
              endDate={endDate}
              onStartBookingDates={() => {
                onToggleBookingDates(true);
              }}
            />
          </div>
        </div>

        <div className={classnames('booking-form-row', !isChangeAllowed && hasDateChanged && 'alert alert-danger')}>
          You can only change between dates that have the same surcharge
          {cart.addons ? ' and within the expire date of the paid inclusions.' : '.'}
        </div>

        <Stack mt={2} direction="row-reverse" spacing={2}>
          <Button
            variant="outlined"
            className="T-Submit-Change-Date"
            disabled={isProcessing || !isChangeAllowed}
            onClick={this.promptConfirmation}
          >
            Confirm Change
          </Button>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>

        {Boolean(bookingDatesItem) && this.renderBookingDateModal()}

        <Dialog open={isConfirming} onClose={this.cancelConfirmation} className="T-change-date-confirm-modal">
          <DialogTitle>Confirm change</DialogTitle>
          <DialogContent>
            <ul>
              {hasGuestChanged && (
                <Fragment>
                  <li>
                    Original guest name:
                    <GuestName firstName={originalFirstName} lastName={originalLastName} />
                  </li>
                  <li>
                    Desired guest name:
                    <GuestName firstName={firstName} lastName={lastName} />
                  </li>
                </Fragment>
              )}
              {hasDateChanged && (
                <Fragment>
                  <li>
                    Original date:
                    <DateRange startDate={originalStartDate} endDate={originalEndDate} />
                  </li>
                  <li>
                    Desired date:
                    <DateRange startDate={startDate} endDate={endDate} />
                  </li>
                </Fragment>
              )}
              {isPastBookByDate && this.isAdmin && (
                <div>
                  Please note you are amending a booking where the <b>book by date</b> has passed. Please ensure you
                  have been provided authority to amend this booking.
                  <div>
                    <label>Case Number:</label>
                    <input
                      type="text"
                      name="caseNumber"
                      maxLength={8}
                      id="CaseNumber"
                      value={this.state.caseNumber}
                      onChange={this.handleCaseNumberChange}
                    />
                  </div>
                </div>
              )}
              {isPastBookByDate && !this.isAdmin && (
                <div>
                  Please note you are attempting to change a booking where the <b>book by date</b> has passed. Please
                  contact your Team Leader to perform this action.
                </div>
              )}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.cancelConfirmation}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="T-Confirm-Change-Date"
              disabled={isProcessing || !isChangeAllowed || pastBookByDateDisable}
              onClick={this.handleChange}
            >
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

OrderItemChangeReservation.contextTypes = {
  user: PropTypes.object,
};
