export const checkCanViewLuxPlusBenefits = (subscriptions: App.MembershipSubscriptions) => {
  return ['ACTIVE', 'AWAITING_RENEWAL'].includes(subscriptions?.status);
};

export const isPendingRenewalLuxPlusMember = (subscriptions: App.MembershipSubscriptions) => {
  return subscriptions?.status === 'AWAITING_RENEWAL';
};

export const isFreePreviewLuxPlusMember = (subscriptions: App.MembershipSubscriptions) => {
  return checkCanViewLuxPlusBenefits(subscriptions) && subscriptions?.tier === 'free_preview';
};

export const checkCanRedeemLuxPlusBenefits = (subscriptions: App.MembershipSubscriptions) => {
  return (
    checkCanViewLuxPlusBenefits(subscriptions) &&
    !isFreePreviewLuxPlusMember(subscriptions) &&
    !isPendingRenewalLuxPlusMember(subscriptions)
  );
};

export const hasLuxPlusSubscriptionStatus = (subscriptions: App.MembershipSubscriptions) => {
  return !!subscriptions?.status;
};

export const getCurrentSubscriptionPeriod = (subscriptions: App.MembershipSubscriptions) => {
  return subscriptions.subscriptionPeriods?.slice(-1)[0];
};
