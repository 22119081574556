import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { JSONSchema7Definition } from 'json-schema';
import get from 'lodash/get';

import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import { camelCaseToSpaceSeparated } from '~/utils/stringUtils';

import { cloneFormData } from './formUtils';

const DIALOG_TRANSITION_PROPS: ComponentProps<typeof Dialog>['TransitionProps'] = { unmountOnExit: true };

export interface ParentSiblingArrayCopyDefinition {
  fieldDepth: string[];
  parentTitleProperty?: string;
  canCopyArrayItemsFromSimilar?: string;
  uncopyableProperties?: string[];
  properties: Record<string, JSONSchema7Definition>;
}

interface Props<T> {
  formData: T;
  copyDefinition?: ParentSiblingArrayCopyDefinition;
  onCopyAndAdd: (fieldDepth: string[], value: unknown[]) => void;
  onDismiss: () => void;
}

export default function ParentSiblingArrayCopyModal<T>(props: Props<T>) {
  const { formData, copyDefinition, onCopyAndAdd, onDismiss } = props;
  const [siblingCopyTarget, setSiblingCopyTarget] = useState<string>('');

  const { arrayName, copyOptions, parentTitle, arrayTitle, parentInstance } = useMemo(() => {
    if (!copyDefinition) return {};

    const { fieldDepth, parentTitleProperty, canCopyArrayItemsFromSimilar, properties } = copyDefinition;
    const [parentName, parentIdx, arrayName] = fieldDepth.slice(
      fieldDepth.length - 3, // the last 3 entries, if existing, represent the relevant identifiers
    );

    const parentIndex = Number(parentIdx);

    const parentTitle: string = camelCaseToSpaceSeparated(parentName).toLowerCase();
    const arrayTitle: string = camelCaseToSpaceSeparated(arrayName).toLowerCase();
    const similarTitle: string = canCopyArrayItemsFromSimilar
      ? camelCaseToSpaceSeparated(canCopyArrayItemsFromSimilar).toLowerCase()
      : '';

    let itemsToCopyFrom;
    let copyArrayItemSimilarValidate = false;
    if (canCopyArrayItemsFromSimilar) {
      const parentFieldDepth = fieldDepth.slice(0, fieldDepth.length - 3);
      itemsToCopyFrom = get(formData, [...parentFieldDepth, canCopyArrayItemsFromSimilar]);
      if (Array.isArray(itemsToCopyFrom) && itemsToCopyFrom.length > 0 && properties) {
        const originProps = Object.keys(properties);
        const itemToCopyProps = Object.keys(itemsToCopyFrom[0]);
        copyArrayItemSimilarValidate = originProps.every((prop) => itemToCopyProps.includes(prop));
      }
    }

    const parentInstance: unknown[] = get(formData, fieldDepth.slice(0, fieldDepth.length - 2));

    const copyOptions =
      parentInstance?.map((sibling, index) => {
        let label = `${parentTitle} ${index + 1}`;
        const title: string | undefined = sibling[parentTitleProperty];
        if (title) {
          label += ` [${title}]`;
        }
        label += ` ${arrayTitle}`;

        const isCurrent = index === parentIndex;
        if (isCurrent) {
          label += ' [CURRENT]';
        }

        return {
          disabled: isCurrent,
          label,
          value: String(index),
        };
      }) ?? [];

    if (copyArrayItemSimilarValidate) {
      copyOptions.push({
        disabled: false,
        label: `from ${similarTitle}`,
        value: canCopyArrayItemsFromSimilar,
      });
    }

    return {
      arrayName,
      copyOptions,
      arrayTitle,
      parentTitle,
      parentInstance,
    };
  }, [copyDefinition, formData]);

  const handleRadioChange = useCallback<ComponentProps<typeof RadioGroup>['onChange']>((e) => {
    setSiblingCopyTarget(e.target.value);
  }, []);

  const handleCopyAndAdd = useCallback(() => {
    const { uncopyableProperties, canCopyArrayItemsFromSimilar, fieldDepth } = copyDefinition;
    let itemsToCopy;
    if (canCopyArrayItemsFromSimilar === siblingCopyTarget) {
      const parentFieldDepth = fieldDepth.slice(0, fieldDepth.length - 3);
      itemsToCopy = get(formData, [...parentFieldDepth, siblingCopyTarget]);
    } else {
      itemsToCopy = parentInstance[siblingCopyTarget][arrayName];
    }

    const items: unknown[] = cloneFormData(itemsToCopy, uncopyableProperties);
    onCopyAndAdd(fieldDepth, items);
  }, [arrayName, copyDefinition, onCopyAndAdd, parentInstance, siblingCopyTarget, formData]);

  useEffect(() => {
    setSiblingCopyTarget('');
  }, [copyDefinition]);

  if (!copyDefinition) {
    return null;
  }

  return (
    <Dialog open={!!copyDefinition} TransitionProps={DIALOG_TRANSITION_PROPS} onClose={onDismiss}>
      <DialogTitle>
        Copying{' '}
        <Typography color="primary" variant="inherit" component="span">
          {arrayTitle}
        </Typography>{' '}
        from sibling{' '}
        <Typography color="primary" variant="inherit" component="span">
          {parentTitle}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup value={siblingCopyTarget} onChange={handleRadioChange}>
          {copyOptions.map((entity) => (
            <FormControlLabel
              key={`entity-${entity.value}`}
              value={entity.value}
              disabled={entity.disabled}
              control={<Radio />}
              label={entity.label}
            />
          ))}
          {!copyOptions.length && <Alert severity="warning">No parent sibling available</Alert>}
        </RadioGroup>
        {!!copyDefinition?.uncopyableProperties?.length && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="caption">These properties will be omitted:</Typography>
              {copyDefinition.uncopyableProperties.map((property, index) => (
                <Chip key={`${property}-${index}`} size="small" variant="outlined" label={property} />
              ))}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Dismiss</Button>
        <Button variant="contained" disabled={!siblingCopyTarget} onClick={handleCopyAndAdd}>
          Copy and add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
