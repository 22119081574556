import React from 'react';

import { Box, Stack, TextField, Typography } from '@mui/material';

import { CollectionReason } from '../../types';

interface Props {
  reason: CollectionReason;
  defaultComment?: string;
  defaultCaseId?: string;
  commentPlaceholder?: string;
  commentLabel?: string;
}

export default function CaseIDAndReasons({
  reason,
  defaultComment = '',
  defaultCaseId = '',
  commentPlaceholder = 'Brief explanation of why the customer is requesting a refund',
  commentLabel = "Customer's Reason",
}: Props) {
  return (
    <>
      <Stack spacing={2}>
        <Box>
          <Typography fontWeight="bold">{reason?.label ?? reason.key}</Typography>
          <Typography>{reason.description}</Typography>
        </Box>
        <TextField
          data-cy="CustomerReason"
          defaultValue={defaultComment}
          fullWidth
          label={commentLabel}
          minRows={3}
          multiline
          name="comment"
          placeholder={commentPlaceholder}
          required
        />
      </Stack>

      <Box marginTop={1}>
        <TextField data-cy="CaseID" defaultValue={defaultCaseId} name="caseId" placeholder="Case ID" required />
      </Box>
    </>
  );
}
