export const TYPEAHEAD_PLACE_TYPES = [
  'airport',
  'province_state',
  'neighborhood',
  'city',
  'high_level_region',
  'country',
  'multi_city_vicinity',
  'metro_station',
  'continent',
  'train_station',
  'point_of_interest',
  'colloquial_area',
];
