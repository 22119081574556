import React from 'react';

import PropTypes from 'prop-types';

import utils from '../utils';

export default class NotAuthenticated extends React.Component {
  static contextTypes = {
    loading: PropTypes.bool,
    user: PropTypes.object,
  };

  render() {
    let user = this.context.user;
    // cast to bool
    let authenticated = user && true;
    let propsToForward = utils.excludeProps(['inGroup'], this.props);

    return !authenticated && !this.context.loading
      ? utils.enforceRootElement(this.props.children, propsToForward)
      : null;
  }
}
