import React from 'react';

import { Button } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import ScheduleDeletionDialog from './ScheduleDeletionDialog';

interface Props {
  scheduleId: string;
  disabled?: boolean;
  onDelete: (scheduleId: string) => void;
}

function ScheduleDeletionButton(props: Props) {
  const { disabled, scheduleId, onDelete } = props;
  const {
    isToggled: isDeletionModalOpen,
    toggleOn: openDeletionModal,
    toggleOff: closeDeletionModal,
  } = useToggleState(false);

  return (
    <>
      <Button type="button" variant="text" size="large" color="error" disabled={disabled} onClick={openDeletionModal}>
        Delete schedule
      </Button>
      <ScheduleDeletionDialog
        scheduleId={scheduleId}
        open={isDeletionModalOpen}
        onClose={closeDeletionModal}
        onDelete={onDelete}
      />
    </>
  );
}

export default ScheduleDeletionButton;
