import React, { useCallback, useEffect } from 'react';

import { allCountries } from 'country-telephone-data';
import { ConnectedProps, connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';

import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';

import { updateContactDetails } from '~/actions/cart';

type Props = ConnectedProps<typeof connector> & {
  user: App.User;
};

const countryCodeOptions: { value: string; label: string }[] = allCountries.map(({ dialCode, name }) => ({
  value: dialCode,
  label: `+${dialCode} ${name}`,
}));

const findPrefixOptionByValue = (value: string) =>
  countryCodeOptions.find((option) => option.value === value) ?? countryCodeOptions[0];

function ContactPhone(props: Props) {
  const { user, phone, phonePrefix, updateContactDetails } = props;

  const updatePhoneDetails = useCallback(
    (details: { [key: string]: string }) => {
      updateContactDetails({
        phone,
        phonePrefix,
        ...details,
      });
    },
    [updateContactDetails, phone, phonePrefix],
  );

  const handlePhonePrefixChange = useCallback(
    (_: React.SyntheticEvent, { value }: { value: string }) => {
      updatePhoneDetails({ phonePrefix: value });
    },
    [updatePhoneDetails],
  );

  const handlePhoneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (!value.match(/^(\s*|\d+)$/)) {
        return;
      }

      updatePhoneDetails({ [name]: value });
    },
    [updatePhoneDetails],
  );

  useEffect(() => {
    updatePhoneDetails({
      phone: user.phone ?? '',
      phonePrefix: findPrefixOptionByValue(user.phone_prefix) ? user.phone_prefix : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack my={1} direction="row" spacing={2}>
      <Box flexGrow={2}>
        <Typography variant="h6">Customer Contact Phone Number</Typography>
        <Typography>The property might need to contact the customer</Typography>
      </Box>

      <Box flexGrow={1}>
        <Autocomplete
          id="customer-phone-prefix"
          options={countryCodeOptions}
          value={findPrefixOptionByValue(phonePrefix)}
          onChange={handlePhonePrefixChange}
          renderInput={(params) => <TextField {...params} label="Phone prefix" />}
          slotProps={{ paper: { elevation: 1 } }}
          disableClearable
          fullWidth
        />
      </Box>

      <Box flexGrow={1}>
        <TextField
          id="customer-phone"
          label="Customer phone"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
          inputProps={{ minLength: 7 }}
          helperText="This field is required. Only numbers, 7 digits min."
          required
          fullWidth
        />
      </Box>
    </Stack>
  );
}

function mapStateToProps(state: App.State) {
  return {
    phonePrefix: state.cart.contactPhonePrefix,
    phone: state.cart.contactPhone,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ updateContactDetails }, dispatch);
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ContactPhone);
