import { Reservation } from '@luxuryescapes/contract-svc-reservation';

export function mapRoomRateToRatePlan({
  propertyData,
  fk_property_id,
  fk_room_rate_id,
}: {
  propertyData: Reservation.Property[];
  fk_property_id: string;
  fk_room_rate_id: string;
}): App.RoomRate | undefined {
  const findRatePlan = (room_types, index) => {
    if (index < 0) {
      return undefined;
    }

    const roomRate = room_types[index].room_rates.find((room_rate) => room_rate.id === fk_room_rate_id);
    return roomRate ? roomRate : findRatePlan(room_types, index - 1);
  };

  const property = propertyData.find((property) => {
    if (property.id === fk_property_id) {
      return property;
    }
  });

  if (!property) {
    return undefined;
  }

  return findRatePlan(property?.room_types, property?.room_types.length - 1);
}
