import React, { useCallback, useMemo, useState } from 'react';

import { WidgetProps } from '@rjsf/utils';
import { useSelector } from 'react-redux';

import { ListItemText, MenuItem } from '@mui/material';
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';

import useToggleState from '~/hooks/useToggleState';

import SearchService, {
  KNOWN_TYPEAHEAD_TYPES,
  TypeaheadBrand,
  TypeaheadResult,
  TypeaheadType,
} from '~/services/SearchService';

interface LocationOption {
  title: string;
  subtitle?: string;
  value: `[${string}] ${string}`;
  type?: string;
  typeahead?: TypeaheadResult;
}

function LocationMenuOption(
  props: React.HTMLAttributes<HTMLLIElement>,
  option: LocationOption,
  state: AutocompleteRenderOptionState,
) {
  return (
    <MenuItem {...props} selected={state.selected} dense disableGutters>
      <ListItemText primary={option.title} secondary={option.subtitle} />
    </MenuItem>
  );
}

interface Props extends WidgetProps {
  options: {
    typeaheadTypes?: TypeaheadType[]; // defaults to SearchService.KNOWN_TYPEAHEAD_TYPES
    typeaheadResultsLimit?: number; // defaults to 20
  };
}

function LELocationSearchWidget(props: Props) {
  const { id, required, readonly, disabled, label, value, options, onChange } = props;
  const tenant = useSelector<App.State, App.Tenant>((state) => state.tenant);
  const { isToggled: isLoading, toggleOn: startLoading, toggleOff: endLoading } = useToggleState();
  const [typeaheadSuggestions, setTypeaheadSuggestions] = useState<TypeaheadResult[]>([]);

  const defaultLocation = useMemo<LocationOption | null>(() => {
    if (value) {
      const matches = value.match(/^\[(\S+)\]\s(.+)$/is);
      if (matches?.length) {
        const [, matchedValue, matchedLabel] = matches;
        return {
          value: matchedValue,
          title: matchedLabel,
        };
      } else {
        return {
          value,
          title: value,
        };
      }
    }
    return null;
  }, [value]);

  const locationOptions = useMemo<LocationOption[]>(
    () =>
      typeaheadSuggestions.map((typeahead) => {
        return {
          title: typeahead.primary_text,
          subtitle: typeahead.secondary_text,
          value: `[${typeahead.fk}] ${typeahead.primary_text}, ${typeahead.secondary_text}`,
          type: typeahead.type,
          typeahead,
        };
      }),
    [typeaheadSuggestions],
  );

  const fetchTypeahed = useCallback(
    (query) => {
      if (!query || query.length < 2 || isLoading) return;
      startLoading();
      SearchService.typeahead(query, {
        type: options?.typeaheadTypes ?? KNOWN_TYPEAHEAD_TYPES,
        // FIXME: the tenant brand support "scooponexperience" but the typeahead does not.
        brand: (tenant?.brand as TypeaheadBrand) ?? 'luxuryescapes',
        region: 'AU',
        limit: options?.typeaheadResultsLimit ?? 20,
      })
        .then((res) => setTypeaheadSuggestions(res.result.results))
        .catch((e) => {
          alert(`There was an error while fetching location typeahead [${JSON.stringify(e, null, 2)}]`);
        })
        .finally(endLoading);
    },
    [isLoading, options, tenant],
  );

  return (
    <Autocomplete
      id={id}
      multiple={false}
      value={defaultLocation}
      disabled={disabled}
      readOnly={readonly}
      options={locationOptions}
      getOptionLabel={(option: LocationOption) => option.title}
      groupBy={(option) => option.type}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={(o) => o}
      loading={isLoading}
      loadingText="Looking around the 🌎"
      onChange={(e, newOption: LocationOption) => {
        onChange(newOption?.value ?? '');
      }}
      onInputChange={debounce((e, inputValue) => {
        setTypeaheadSuggestions([]);
        fetchTypeahed(inputValue);
      }, 300)}
      renderOption={LocationMenuOption}
      renderInput={(params) => (
        <TextField {...params} required={required} label={label} placeholder="Enter an LE location name" fullWidth />
      )}
    />
  );
}

export default LELocationSearchWidget;
