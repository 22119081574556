import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueTTCPayableReport } from '~/services/ReportingService';
import { formatDateISO, startOfMonth } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter a date to export a report for all TTC payments due up to and including that date',
  type: 'object',
  properties: {
    endAt: {
      title: 'Select Date',
      type: 'string',
      format: 'date',
    },
  },
};

const formData = {
  endAt: formatDateISO(startOfMonth()),
  format: 'email',
};

const uiSchema = {
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportTTCPayableForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { endAt, format } = event.formData;

    queueTTCPayableReport({ endAt, format })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Tour Payable Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
