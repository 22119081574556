import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import dateFormatter from './dateFormatter';

const orderStatusFormatter = (params: GridRenderCellParams<App.Order>) => {
  const row = params.row;

  return (
    <Stack direction="column">
      <Typography>{dateFormatter(row.created_at)}</Typography>
      <Typography>{row.status}</Typography>
    </Stack>
  );
};

export default orderStatusFormatter;
