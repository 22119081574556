import React from 'react';

import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import { STEP_LATE_INSURANCE_REFUND, STEP_SUMMARY } from '../../consts/refund';

export default class PreRefundInsurance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nibRefundId: '',
      error: null,
    };

    this.handler = this.handler.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleNibRefundIdChange = this.handleNibRefundIdChange.bind(this);
  }

  componentDidMount() {
    this.props.setBackStep(null);
  }

  handleNibRefundIdChange(e) {
    this.setState({ nibRefundId: e.target.value, error: null });
  }

  handler() {
    const nibReferenceId = this.state.nibRefundId;

    if (!nibReferenceId.length) {
      this.setState({ error: 'Please provide NIB Refund Reference Number' });
      return;
    }

    const outOfCoolingOff = this.props.outOfCoolingOff;
    const setDefaultValuesForReason = this.props.setDefaultValuesForReason;

    this.props.stepHandler(function (error, next) {
      setDefaultValuesForReason({
        nib_reference_id: nibReferenceId,
      });

      if (outOfCoolingOff) {
        return next(STEP_LATE_INSURANCE_REFUND);
      }

      next(STEP_SUMMARY);
    });
  }

  cancel() {
    this.props.closeModal();
  }

  render() {
    const { error } = this.state;

    const message = this.props.outOfCoolingOff
      ? 'The policy is outside the 21 day cooling off period. Are you sure want to refund it?'
      : 'Have you cancelled the policy in the nib portal?';

    return (
      <Box>
        <div className="insurance-refund-message">
          <h5>{message}</h5>
        </div>

        <Box id="nib_refund_id" validationState={error ? 'warning' : null}>
          <TextField
            label="Refund Reference Number"
            name="nib_refund_id"
            value={this.state.nibRefundId}
            onChange={this.handleNibRefundIdChange}
            className="data-hj-whitelist"
          />
          {error && <Typography color="orange">{error}</Typography>}
        </Box>

        <Stack direction="row" spacing={2}>
          <Button variant="text" onClick={this.cancel}>
            No, I couldn't cancel it
          </Button>
          <Button variant="contained" className="T-submit" onClick={this.handler}>
            Yes, it's been cancelled
          </Button>
        </Stack>
      </Box>
    );
  }
}
