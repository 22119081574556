import React, { ComponentProps, useCallback, useMemo, useState } from 'react';

import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { ArrayFieldTemplateItemType, ArrayFieldTemplateProps, RJSFSchema } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DataArrayIcon from '@mui/icons-material/DataArray';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { camelCaseToSpaceSeparated } from '~/utils/stringUtils';

import { ActiveAccordionContext } from './ActiveAccordionContext';
import BreadcrumbsLegendTemplate from './BreadcrumbsLegendTemplate';
import ExtendedArrayFieldItemTemplate from './ExtendedArrayFieldItemTemplate';
import { formIdToFieldDepthConverter } from './formUtils';
import { ExtendedRJSFormContext } from './useExtendedRJSForm';

interface Props
  extends ArrayFieldTemplateProps<ArrayFieldTemplateItemType<unknown>[], RJSFSchema, ExtendedRJSFormContext> {
  uiSchema: {
    'ui:options': {
      isInitiallyCollapsed: boolean; // collapse the array initially, because they're all expanded by default
      canCopyFromSibling: boolean; // enable the ability to copy values from siblings
      canCopyArrayItems: boolean; // enable the ability to copy items within the array
      canCopyArrayItemsFromSimilar: string; // enable the ability to copy items within the array from similar items
      uncopyableProperties: string[]; // define fields that cannot be copied
      itemTitleProperty: string; // define the property key used to display an item as an option
      layout: 'grid' | 'stack'; // switch between vertical stack or grid layout
      toolbarDirection?: 'row' | 'column'; // direction of buttons in toolbar
      simpleLayout: boolean; // some sort of distinction between tour specific design and more general that we can use elsewhere
      displaySingleOption?: boolean; // only allow one accordion to be open at a time
    };
  };
}

export default function ExtendedArrayFieldTemplate(props: Props) {
  const {
    canAdd,
    className,
    disabled,
    formContext,
    formData,
    idSchema,
    items,
    readonly,
    registry,
    required,
    schema,
    title,
    uiSchema,
    onAddClick,
  } = props;
  const options = uiSchema?.['ui:options'];
  const sortableItems = useMemo<UniqueIdentifier[]>(() => items.map((i) => i.key), [items]);

  const handleDragSort = useCallback<ComponentProps<typeof DndContext>['onDragEnd']>(
    (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        let activeItem: Props['items'][number], overItem: Props['items'][number];
        for (const item of items) {
          if (activeItem && overItem) break;
          if (item.key === active.id) activeItem = item;
          else if (item.key === over.id) overItem = item;
        }

        activeItem.onReorderClick(activeItem.index, overItem.index)();
      }
    },
    [items],
  );

  const gridTemplateColumns = useMemo<ComponentProps<typeof Box>['gridTemplateColumns']>(
    () => (options?.layout === 'grid' ? 'repeat(auto-fit, minmax(320px, 1fr))' : '1fr'),
    [options?.layout],
  );

  const fieldDepth = useMemo(() => formIdToFieldDepthConverter(idSchema.$id), [idSchema]);

  const { parentTitle, arrayName } = useMemo(() => {
    const [parentName, parentIndex, arrayName] = fieldDepth.slice(
      fieldDepth.length - 3, // the last 3 entries, if existing, represent the relevant identifiers
    );

    return {
      parentTitle: camelCaseToSpaceSeparated(parentName).toLowerCase(),
      parentName,
      parentIndex: Number(parentIndex),
      arrayName,
    };
  }, [fieldDepth]);

  const canCopyFromSibling = !!options?.canCopyFromSibling && canAdd;

  const canCopyArrayItems = !!options?.canCopyArrayItems && formData.length > 1;

  const canCopyArrayItemsFromSimilar = options?.canCopyArrayItemsFromSimilar;

  const uncopyableProperties: string[] = useMemo(
    () => (Array.isArray(options?.uncopyableProperties) ? options?.uncopyableProperties : []),
    [options],
  );

  const fieldTitleProperty: string = options?.itemTitleProperty ? String(options.itemTitleProperty) : 'title';

  const handleSiblingParentArrayTrigger = useCallback(() => {
    formContext.triggerSiblingParentArrayCopy({
      fieldDepth,
      uncopyableProperties,
      canCopyArrayItemsFromSimilar,
      fieldTitleProperty,
      properties: (schema.items as JSONSchema7)?.properties,
    });
  }, [formContext, fieldDepth, uncopyableProperties, fieldTitleProperty, canCopyArrayItemsFromSimilar, schema]);

  const handleItemCopyTrigger = useCallback(
    (currentItemIndex: number) => {
      formContext.triggerArrayItemCopy({
        currentItemIndex,
        fieldDepth,
        uncopyableProperties,
        fieldTitleProperty,
      });
    },
    [fieldDepth, fieldTitleProperty, formContext, uncopyableProperties],
  );

  const [inclusionItemsGenerateButtonDisabled, setInclusionItemsGenerateButtonDisabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleInclusionItemsGenerate = useCallback(async () => {
    const tourOptionIndex = parseInt(idSchema.$id.split('_')[2], 10);
    setInclusionItemsGenerateButtonDisabled(true);
    try {
      await formContext.handleInclusionItemsGenerate(tourOptionIndex);
      setInclusionItemsGenerateButtonDisabled(false);
    } catch (error) {
      setInclusionItemsGenerateButtonDisabled(false);
      enqueueSnackbar('Unable to generate inclusion items', {
        variant: 'error',
      });
    }
  }, [formContext]);

  const [lastOpenedIndex, setLastOpenedIndex] = useState<number>(0);

  const handleAccordionClicked = useCallback(
    (index: number) => {
      if (options?.displaySingleOption) {
        setLastOpenedIndex(index);
      }
    },
    [options?.displaySingleOption],
  );

  return (
    <Badge badgeContent={items.length} showZero color="primary">
      <Paper variant="outlined" sx={{ width: '100%' }} className={className}>
        <Accordion defaultExpanded={!options?.isInitiallyCollapsed && !!title} disableGutters elevation={0}>
          {!!title && (
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                {!options?.simpleLayout && (
                  <BreadcrumbsLegendTemplate
                    id={idSchema.$id}
                    title={title}
                    required={required}
                    schema={schema}
                    registry={registry}
                  />
                )}
                {options?.simpleLayout && <Typography variant="h5">{title}</Typography>}
                <DataArrayIcon color="disabled" />
              </Stack>
            </AccordionSummary>
          )}

          <AccordionDetails sx={{ px: 1 }}>
            <Box display="grid" gap={2} gridTemplateColumns={gridTemplateColumns} justifyContent="center">
              {arrayName === 'inclusionItems' && (
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={inclusionItemsGenerateButtonDisabled}
                  onClick={handleInclusionItemsGenerate}
                >
                  Generate
                </Button>
              )}
              {items.length === 0 && (
                <Typography variant="overline" color="secondary" sx={{ textAlign: 'center' }}>
                  Empty
                </Typography>
              )}
              <DndContext onDragEnd={handleDragSort}>
                <SortableContext items={sortableItems}>
                  {options?.displaySingleOption && (
                    <ActiveAccordionContext.Provider
                      value={{
                        activeAccordionIndex: lastOpenedIndex,
                        setActiveAccordion: handleAccordionClicked,
                        displaySingleOption: options?.displaySingleOption,
                        targetedSection: idSchema.$id.replace(/([0-9]|_)/g, ''),
                      }}
                    >
                      {items.map((item) => (
                        <ExtendedArrayFieldItemTemplate
                          key={item.key}
                          {...item}
                          identifier={item.key}
                          readonly={readonly || item.readonly}
                          disabled={disabled || item.disabled}
                          arrayTitle={title}
                          onItemCopyTrigger={canCopyArrayItems ? handleItemCopyTrigger : undefined}
                          toolbarDirection={options?.toolbarDirection}
                        />
                      ))}
                    </ActiveAccordionContext.Provider>
                  )}
                  {!options?.displaySingleOption &&
                    items.map((item) => (
                      <ExtendedArrayFieldItemTemplate
                        key={item.key}
                        {...item}
                        identifier={item.key}
                        readonly={readonly || item.readonly}
                        disabled={disabled || item.disabled}
                        arrayTitle={title}
                        onItemCopyTrigger={canCopyArrayItems ? handleItemCopyTrigger : undefined}
                        toolbarDirection={options?.toolbarDirection}
                      />
                    ))}
                </SortableContext>
              </DndContext>
            </Box>
          </AccordionDetails>

          <AccordionActions>
            <Stack direction="row" justifyContent="end" spacing={1} sx={{ width: '100%' }}>
              {canAdd && !options?.simpleLayout && (
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={disabled || readonly}
                  onClick={onAddClick}
                >
                  Add {title}
                </Button>
              )}
              {canAdd && options?.simpleLayout && (
                <IconButton disabled={disabled || readonly} color="primary" onClick={onAddClick} size="large">
                  <AddIcon />
                </IconButton>
              )}
              {canCopyFromSibling && (
                <Tooltip placement="top" title={`Copy and add from sibling ${parentTitle}`}>
                  <IconButton
                    type="button"
                    color="secondary"
                    size="large"
                    disabled={disabled || readonly}
                    onClick={handleSiblingParentArrayTrigger}
                  >
                    <CopyAllIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </AccordionActions>
        </Accordion>
      </Paper>
    </Badge>
  );
}
