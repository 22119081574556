import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { Button, Skeleton } from '@mui/material';

import { getQuoteEmail } from '~/services/SailthruService';

type Props = {
  customerEmailDedupeKey: string;
};

export default function QuoteEmailSent({ customerEmailDedupeKey }: Props) {
  const [emailId, setEmailId] = useState('');
  const [loading, setLoading] = useState<Utils.FetchingState>('idle');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchEmailLog = async () => {
      try {
        setLoading('loading');
        const res = await getQuoteEmail(customerEmailDedupeKey);
        setEmailId(res.result.notification_id);
        setLoading('success');
      } catch (error) {
        setLoading('failed');
        enqueueSnackbar(`Error fetching email info: ${error}`, { variant: 'error' });
      }
    };

    fetchEmailLog();
  }, [customerEmailDedupeKey]);

  if (loading === 'loading') {
    return <Skeleton width={100} />;
  }

  if (loading === 'failed') {
    return null;
  }

  return (
    <Link to={`/email/render?id=${emailId}`} target="_blank">
      <Button variant="text" size="small">
        View email
      </Button>
    </Link>
  );
}
