import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import ReservationService from '../../../services/ReservationService';
import VendorsService from '../../../services/VendorsService';

import PropertyReviewsEdit from './PropertyReviewsEdit';

export default function PropertyReviewsEditContainer() {
  const { id_vendor: vendorId, id_property: propertyId } = useParams<{ id_vendor: string; id_property: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [property, setProperty] = useState(null);
  const [schema, setSchema] = useState(null);
  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getProperty(propertyId),
      ReservationService.getPropertyReviewSchema(propertyId),
      VendorsService.getVendorById(vendorId),
    ])
      .then(([property, schema, vendor]) => {
        setProperty(property.result);
        setSchema(schema.post.body.schema);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, propertyId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load vendor, property or schema.</Typography>
      </Container>
    );
  }

  return (
    <PropertyReviewsEdit
      property={property}
      reviewsData={property.reviews_count ? property.reviews : []}
      propertyId={property.id}
      schema={schema}
      vendorId={vendorId}
      vendor={vendor}
    />
  );
}
