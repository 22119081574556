import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

import * as publicOfferFeedService from '~/services/PublicOfferFeedService';

import ErrorDisplay from '../Common/ErrorDisplay';
import Spinner from '../Common/Spinner';
import DebugModal from '../DebugModal/DebugModal';

interface Props {
  customerId: string;
  campaignId: string;
  adCampaigns: Array<publicOfferFeedService.AdCampaign> | undefined;
  adGroups: Array<publicOfferFeedService.AdGroup> | undefined;
  allowUpdate: boolean;
}

function GoogleAdsCampaignBudget({ customerId, campaignId, adCampaigns, adGroups, allowUpdate }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [amountMicros, setAmountMicros] = useState<number>(
    adCampaigns ? adCampaigns[0]?.campaignBudget.amountMicros : 0,
  );
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  type CampaignBudgetUpdateProps = {
    amountMicros: number;
  };

  const updateCampaignBudget = useCallback(
    async ({ amountMicros }: CampaignBudgetUpdateProps) => {
      if (adCampaigns.length === 0) {
        return;
      }

      try {
        setFetchingState('loading');
        await publicOfferFeedService.updateCampaignBudget({
          customerId,
          campaignId,
          payload: { amountMicros, resourceName: adCampaigns[0]?.campaignBudget.resourceName },
        });
        enqueueSnackbar('Successfully updated the campaign budget', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(`Error while updating the campaign budget: ${e.message}.`, { variant: 'error' });
      } finally {
        setFetchingState('idle');
      }
    },
    [customerId, campaignId, adCampaigns, enqueueSnackbar],
  );

  const title = adCampaigns ? `${adCampaigns[0]?.name} Budget (${amountMicros})` : `Budget ${amountMicros}`;

  if (fetchingState == 'loading') {
    return <Spinner />;
  }

  if (!adCampaigns || adCampaigns?.length == 0) {
    return <ErrorDisplay message="No Campaigns Found" />;
  }

  if (!adGroups || adGroups?.length == 0) {
    return <ErrorDisplay message="No Ad Groups Found" />;
  }

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>{title}</Button>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Ad Group Budget</DialogTitle>
        <DialogContent>
          {adCampaigns.map((campaign) => (
            <Stack key={campaign.id}>
              <Typography variant="h6">{campaign.name}</Typography>
              <Typography variant="body1">Campaign Budget: {campaign.campaignBudget.amountMicros}</Typography>
              <Typography variant="body1">Campaign Budget ID: {campaign.campaignBudget.id}</Typography>
              <Typography variant="body1">
                Campaign Budget Ref Count: {campaign.campaignBudget.referenceCount}
              </Typography>
              <Typography variant="body1">Campaign Budget Ref Count: {campaign.campaignBudget.status}</Typography>

              <Typography variant="body1">
                Campaign Budget Resource Name: {campaign.campaignBudget.resourceName}
              </Typography>
              <Accordion>
                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                  <Typography variant="body1">Applies to {adGroups.length} Ad Groups</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {adGroups.map((adGroup) => (
                    <Stack key={adGroup.adGroupId}>
                      <DebugModal data={adGroup} title={adGroup.adGroupName} type="generic" />
                    </Stack>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Stack>
          ))}
        </DialogContent>
        <DialogActions>
          {allowUpdate && (
            <Stack direction="row" spacing={2}>
              <TextField
                type="number"
                name="amountMicros"
                title="AmountMicros"
                label="Budget Limit"
                helperText="This campaign will be limited to this dollar amount. (AmountMicros)"
                value={amountMicros}
                onChange={(event) => setAmountMicros(+event.target.value)}
                required
              />

              <Button variant="text" size="small" onClick={(e) => updateCampaignBudget({ amountMicros })}>
                Update
              </Button>
            </Stack>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GoogleAdsCampaignBudget;
