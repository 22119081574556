import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import OfferSearchForm from '~/components/Common/Forms/OfferSearchForm';
import OfferList from '~/components/Common/OfferListContainer';

import OffersService from '~/services/OffersService';

import { removeQuery } from '~/utils/url';

const urlBuilder = (row: App.Offer) => {
  return `/offers/${row.id_salesforce_external}`;
};

export default function OffersPage(props: RouteComponentProps) {
  const [searchString, setSearchString] = useState('');
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    OffersService.getAllLocations().then((response: App.LocationsResponse) => {
      setDestinations(response.result || []);
    });
  }, []);

  const searchQuery = (query: string) => {
    setSearchString(query);

    const { location } = props;
    removeQuery(location, 'page');
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Offers</title>
      </Helmet>

      <PageHeader title="Offers" />

      <OfferSearchForm destinations={destinations} searchQuery={searchQuery} />

      <OfferList urlBuilder={urlBuilder} searchString={searchString} showCountMessage />
    </Container>
  );
}
