import React from 'react';

import { Paper, TableBody, TableCell, TableContainer, TableHead, styled } from '@mui/material';

const DenseTableCell = styled(TableCell)({
  padding: 1,
  marginRight: 2,
});

export default function RatesTable({ vendorCurrency, customerCurrency, customerFxRates, contractFxRates }) {
  return (
    vendorCurrency &&
    customerCurrency &&
    customerFxRates &&
    contractFxRates && (
      <TableContainer component={Paper}>
        <TableHead>
          <DenseTableCell>Currency</DenseTableCell>
          <DenseTableCell>Spot</DenseTableCell>
          <DenseTableCell>Contracted</DenseTableCell>
        </TableHead>
        <TableBody>
          <DenseTableCell>{vendorCurrency}</DenseTableCell>
          <DenseTableCell>{customerFxRates[vendorCurrency]}</DenseTableCell>
          <DenseTableCell>{contractFxRates[vendorCurrency]}</DenseTableCell>
        </TableBody>
        <TableBody>
          <DenseTableCell>{customerCurrency}</DenseTableCell>
          <DenseTableCell>{customerFxRates[customerCurrency]}</DenseTableCell>
          <DenseTableCell>{contractFxRates[customerCurrency]}</DenseTableCell>
        </TableBody>
      </TableContainer>
    )
  );
}
