import { BookingRequest } from '../types/types';

import { typeFormatter } from './formatters';
import { getDateChangeOutsidePolicyStatus } from './getDateChangeOutsidePolicyStatus';

export const mapOrderDatesToBookingRequest = (orderDates: Array<DateChangeRequest>): Array<BookingRequest> => {
  const statusMap = {
    pending: 'warning',
    approved: 'success',
    rejected: 'error',
    customer_booked: 'success',
    customer_cancelled: 'error',
  };
  const textMap = {
    pending: 'Request sent',
    approved: 'Request approved',
    rejected: 'Request rejected',
    customer_booked: 'Customer booked',
    customer_cancelled: 'Customer cancelled',
  };

  return orderDates.map((orderDate) => {
    let statusText: string, statusSeverity: string;

    if (orderDate.is_outside_policy) {
      const { text, severity } = getDateChangeOutsidePolicyStatus(orderDate.status, orderDate.type);
      statusText = text;
      statusSeverity = severity;
    } else {
      // Use the regular mapping for inside policy
      statusText = textMap[orderDate.status];
      statusSeverity = statusMap[orderDate.status];
    }

    return {
      type: typeFormatter(orderDate.type, !!orderDate.is_outside_policy),
      id: orderDate.id_dates_request,
      created_at: orderDate.created_at,
      updated_at: orderDate.updated_at,
      statusText,
      statusSeverity,
    };
  });
};
