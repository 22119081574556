import React, { ChangeEventHandler, useCallback, useMemo, useState } from 'react';

import { v4 as uuidV4 } from 'uuid';

import { Clear } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

interface Props {
  file: File;
  onDelete: (file: File) => void;
}

function ImageUploadInputCard({ file, onDelete }: Props) {
  const previewUrl = useMemo(() => URL.createObjectURL(file), [file]);

  const [id, setId] = useState<string>('');
  const handleIdChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setId(event.target.value);
  }, []);
  const generateRandomId = useCallback(() => {
    setId(uuidV4());
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(file);
  }, [file, onDelete]);

  return (
    <Card raised>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Typography typography="caption">{file.name}</Typography>
        <Tooltip placement="top" title="Remove from upload queue">
          <IconButton size="small" color="error" type="button" onClick={handleDelete}>
            <Clear />
          </IconButton>
        </Tooltip>
      </CardActions>
      <CardMedia
        component="img"
        src={previewUrl}
        sx={{
          backgroundColor: '#eee',
          objectFit: 'contain',
          aspectRatio: '1.618',
        }}
      />
      <CardContent>
        <TextField
          name="id"
          label="ID"
          variant="outlined"
          placeholder="Auto-generated if left empty"
          value={id}
          onChange={handleIdChange}
          fullWidth
          margin="none"
          helperText="The image will be accessible via this ID."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip placement="top" title="Generate random ID">
                  <IconButton edge="end" type="button" onClick={generateRandomId}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </CardContent>
    </Card>
  );
}

export default ImageUploadInputCard;
