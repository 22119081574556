import React from 'react';

import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

import * as publicOfferFeedService from '~/services/PublicOfferFeedService';

import Spinner from '../Common/Spinner';

type CampaignOptionSelectProps = {
  id: string;
  value: Array<string>;
  campaigns: Array<publicOfferFeedService.AdCampaign>;
  isLoading: boolean;
  onCampaignIdsSet?: (value: Array<string>) => void;
};

function CampaignMultiSelect({ id, value, campaigns, isLoading, onCampaignIdsSet }: CampaignOptionSelectProps) {
  const [campaignIds, setCampaignIds] = React.useState<Array<string>>(value ?? []);

  const handleValueChange = (event: SelectChangeEvent<Array<string>>) => {
    const newValue = event.target.value;
    if (!Array.isArray(newValue)) {
      console.warn('CampaignMultiSelect: newValue is not an array. This is unexpected.');
      return;
    }
    setCampaignIds(newValue);
    onCampaignIdsSet(newValue);
  };

  const renderValue = (selected: Array<string>) => {
    return `${campaigns
      .filter((c) => selected.includes(c.id))
      .map((c) => c.name)
      .join(', ')}`;
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!campaigns) {
    return null;
  }

  const options = campaigns.map((opt) => ({
    isChecked: campaignIds?.includes(opt.id),
    label: opt.name,
    value: opt.id,
  }));

  return (
    <Select
      labelId="campaignIds"
      id={id}
      multiple
      value={campaignIds}
      placeholder="Select Campaigns"
      onChange={handleValueChange}
      renderValue={(selected) => renderValue(selected)}
      fullWidth
      autoComplete="false"
    >
      {options.map((opt, i) => (
        <MenuItem key={opt.value} value={opt.value}>
          <Checkbox checked={opt.isChecked} />
          <ListItemText primary={opt.label} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default CampaignMultiSelect;
