import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getLoyaltyBurn, getLoyaltyEarn, getPartnershipByCode } from '~/services/LoyaltyService';
import UsersService from '~/services/UsersService';

import LoyaltyPage from './Page';

export default function LoyaltyContainer() {
  const { id_user: userId, code } = useParams<{ id_user: string; code: string }>();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [partnership, setPartnership] = useState(null);
  const [burns, setBurns] = useState(null);
  const [earns, setEarns] = useState(null);
  const [user, setUser] = useState<App.User | null>(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      getPartnershipByCode(code),
      code === 'qantas' ? getLoyaltyBurn(userId, code, brand) : new Promise((resolve) => resolve({ result: null })),
      getLoyaltyEarn(userId, code, brand),
      UsersService.getUser(userId),
    ])
      .then(([partnership, burns, earns, user]) => {
        setPartnership(partnership.result);
        setBurns(burns);
        setEarns(earns);
        setUser(user);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [userId, brand, code]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load user or partnership information</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <LoyaltyPage partnership={partnership} burns={burns} earns={earns} user={user} />
    </Container>
  );
}
