import React from 'react';

import isJSON from '../../utils/isJSON';

const JSONViewer = ({ json, style }) => {
  return (
    <div>
      <pre style={style}>{isJSON(json) ? JSON.stringify(JSON.parse(json), null, 2) : 'Not JSON'}</pre>
    </div>
  );
};

export default JSONViewer;
