import React from 'react';

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

interface Props {
  fields: {
    field: string;
    headerName: string;
  }[];
  onSearch: () => void;
  searchField: string;
  searchValue: string;
  onSearchFieldChange: (value: string) => void;
  onSearchValueChange: (value: string) => void;
}

export function FDATableServerSearch(props: Props) {
  const { fields, onSearch, searchField, searchValue, onSearchFieldChange, onSearchValueChange } = props;

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <FormControl>
        <InputLabel>Search field</InputLabel>

        <Select
          label="Search field"
          onChange={(e) => onSearchFieldChange(e.target.value)}
          value={searchField}
          style={{ width: '200px' }}
        >
          {fields.map((column) => (
            <MenuItem key={column.field} value={column.field}>
              {column.headerName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Search value"
        value={searchValue}
        onChange={(event) => onSearchValueChange(event.target.value)}
      />

      <Button variant="contained" onClick={onSearch}>
        Search
      </Button>
    </Box>
  );
}
