import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Container, Stack, Typography } from '@mui/material';

import UserReviewsTable from '~/components/Reviews/UserReviewsTable';

import { clearReviewsCache } from '~/services/ReviewService';

import TourUserReviewLinkedToursForm from './Forms/TourUserReviewLinkedToursForm';

interface Props {
  tourId: string;
}

export default function TourDetailUserReviews(props: Props) {
  const { tourId } = props;

  const { enqueueSnackbar } = useSnackbar();

  const clearCache = useCallback(async () => {
    await clearReviewsCache({ tourId });

    enqueueSnackbar('Review rating was successfully cleared!', { variant: 'success' });
  }, [tourId, enqueueSnackbar]);

  return (
    <Container maxWidth="xl">
      <Stack spacing={2} direction="column">
        <div>
          <Typography variant="h5">Link other tours' reviews</Typography>
        </div>
        <div>
          <TourUserReviewLinkedToursForm tourId={tourId} />
        </div>
        <hr />
        <div>
          <Typography variant="h5">Manage reviews</Typography>
          <Typography sx={{ fontStyle: 'italic' }} variant="body1">
            The rating will be reset next time a user sees the tour on the customer portal
          </Typography>
        </div>

        <div>
          <Button color="error" variant="contained" onClick={clearCache}>
            Clear rating
          </Button>
        </div>
      </Stack>

      <hr />

      <UserReviewsTable tourId={tourId} />
    </Container>
  );
}
