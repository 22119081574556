import React, { Component } from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Property Name',
    flex: 3,
    display: 'flex',
  },
  {
    field: 'hotel_code',
    headerName: 'Hotel Code',
    flex: 1,
    display: 'flex',
  },
];

interface PropertiesSearchProps {
  total: number;
  sizePerPage: number;
  handlePageChange: (page: number) => void;
  onRowClick: (row: any) => void;
  page: number;
  data: Array<any>;
}

class PropertiesSearchResults extends Component<PropertiesSearchProps> {
  render() {
    const { total, sizePerPage, handlePageChange, page, onRowClick } = this.props;

    let { data } = this.props;

    // The search data allows for property ID which will return a singular result. We need to wrap this for table slicing to work
    // See: VendorsService.getProperty
    if (!Array.isArray(data)) {
      data = [data];
    }

    return (
      <DataGrid
        rows={data}
        columns={columns}
        paginationModel={{ page, pageSize: sizePerPage }}
        pagination={true}
        paginationMode="server"
        rowCount={total}
        onRowClick={onRowClick}
        onPaginationModelChange={({ page }) => handlePageChange(page)}
        slots={{ pagination: GridPagination }}
        autoHeight
        sx={{
          minHeight: 300,
        }}
      />
    );
  }
}

export default PropertiesSearchResults;
