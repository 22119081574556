import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import OffersService from '~/services/OffersService';
import UsersService from '~/services/UsersService';

import UserSearchWidget from '../../UserSearchWidget';
import { mapCustomOfferToCreatePayload } from '../maps/customOfferMaps';

interface Props {
  offer: App.CustomOffer;
  brand: string;
  isOpen: boolean;
  handleClose: () => void;
}

export default function CustomOfferDuplicateDialog(props: Props) {
  const { offer, brand, isOpen, handleClose } = props;

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState<App.User | undefined>(undefined);
  const [formErrors, setFormErrors] = useState<string[] | undefined>(undefined);

  // Clear the fields that are not needed to create the duplicate offer
  // Also set the dates correctly as if a new custom offer
  const offerToDuplicate = mapCustomOfferToCreatePayload(offer);

  // Using react-hook-form to manage the offerToDuplicate object
  // This will future proof the dialog if changes need to be made to the offer before duplication.
  const { handleSubmit, setValue } = useForm<App.CustomOfferCreatePayload>({
    defaultValues: offerToDuplicate,
    mode: 'onBlur',
  });

  const fetchUser = useCallback(
    async (userId: string) => {
      try {
        const user = await UsersService.getUser(userId, { brand });
        setUser(user);
        setFormErrors([]);
      } catch (err) {
        enqueueSnackbar(`Error loading user: ${err.message}`, { variant: 'error' });
      }
    },
    [brand, enqueueSnackbar],
  );

  const handleUserSearchSelect = useCallback(
    (id, searchQuery: string) => {
      fetchUser(searchQuery);
    },
    [fetchUser],
  );

  const handleDialogClose = useCallback(() => {
    setUser(undefined);
    setFormErrors([]);
    handleClose();
  }, [handleClose]);

  const handleDuplicateSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (!data.customer_full_name || !data.fk_customer_id) {
          setFormErrors(['fk_customer_id']);
          return;
        }
        try {
          const response = await OffersService.createCustomOffer(data);
          const duplicatedOffer = response.result as App.CustomOffer;
          history.replace(`/users/${duplicatedOffer.fk_customer_id}/custom-offers/${duplicatedOffer.id}`);

          handleDialogClose();
        } catch (error) {
          enqueueSnackbar(`Can't process custom quote: ${error.message}`, { variant: 'error' });
        }
      }),
    [enqueueSnackbar, handleDialogClose, handleSubmit, history],
  );

  useEffect(() => {
    if (user) {
      setValue('fk_customer_id', user.id_member);
      setValue('customer_full_name', user.fullName);
    }
  }, [setValue, user]);

  return (
    <Dialog open={isOpen} onClose={handleDialogClose}>
      <form onSubmit={handleDuplicateSubmit}>
        <DialogTitle>
          Duplicate Custom Offer
          <Typography variant="body2">
            Search for the user that you want to duplicate the custom offer to. You will be taken to the draft page for
            the new offer when you click duplicate.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle2">Search For User</Typography>
              <UserSearchWidget
                id="fk_customer_id"
                label="Name / Email / Customer Support Code / User ID"
                formErrors={formErrors}
                loading={false}
                onChange={handleUserSearchSelect}
              />
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle2">Selected User</Typography>
              <Typography variant="body2">Customer: {user?.fullName}</Typography>
              <Typography variant="body2">Email: {user?.email}</Typography>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button variant="contained" type="submit">
            Duplicate
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
