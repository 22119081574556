import React from 'react';

import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';

import { removeFromCart, toggleBookingDates, toggleReservationType } from '~/actions/cart';

import BookingFormHotel from '~/components/Common/Booking/BookingFormHotel';
import BookingFormTour from '~/components/Common/Booking/BookingFormTour';
import BookingItemWrapper from '~/components/Common/Booking/BookingItemWrapper';
import VoucherBookingForm from '~/components/Common/Booking/VoucherBookingForm';

import { HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

import { getAllItems } from '../selectors';

type Props = ConnectedProps<typeof connector> & {
  offer: App.CartOffer;
  item: App.CartItem;
  itemIdx: number;
  bookingType: string;
  currencyCode: string;
  onUpdateReservation: (item, reservationData) => void;
  onUpdateStateOfResidence: (stateOfResidence) => void;
};

class BookingItem extends React.Component<Props> {
  handleRemoveItem = ({ item }) => {
    this.props.doRemoveFromCart(item.pkg.id_salesforce_external ?? item.pkg.le_package_id);
  };

  canRemoveItem = () => {
    const { offer, items } = this.props;
    return items.some((item) => item.offerId === offer.id_salesforce_external);
  };

  // getOfferPkg gets the package from the offer by the id of the package of the
  // current item.
  getOfferPkg = (offer: App.CartOffer, item: App.CartItem) => {
    const itemPkg = item.pkg;
    const offerPackages = offer.packages;

    for (let i = 0; i < offerPackages.length; i++) {
      const offerPkg = offerPackages[i];
      if (
        offerPkg.id_salesforce_external === itemPkg.id_salesforce_external ||
        offerPkg.le_package_id === itemPkg.le_package_id
      ) {
        return offerPkg;
      }
    }

    // If the for-loop terminates without returning, there has been an error
    // TODO: error boundary
    throw new Error(`Could not find item with id
      ${itemPkg.id_salesforce_external ?? itemPkg.le_package_id} from the offer`);
  };

  renderForm = () => {
    const {
      onUpdateReservation,
      onUpdateStateOfResidence,
      doToggleBookingDates,
      doToggleReservationType,
      bookingType,
      item,
      offer,
      cart: { reservationType, allowToggleReservationType, stateOfResidence },
    } = this.props;

    const offerType = offer.type;

    if (bookingType === 'voucher') {
      return <VoucherBookingForm />;
    }

    const onToggleBookingDates = (item: App.CartItem) => doToggleBookingDates(item, offer, true);
    const pkg = this.getOfferPkg(offer, item);

    if (HOTEL_OFFER_TYPES.includes(offerType)) {
      return (
        <BookingFormHotel
          package={pkg as App.CartAccommodationPackage}
          item={item}
          onUpdateReservation={onUpdateReservation}
          onUpdateStateOfResidence={onUpdateStateOfResidence}
          onToggleBookingDates={onToggleBookingDates}
          allowToggleReservationType={offerType === 'hotel' ? allowToggleReservationType : false}
          reservationType={reservationType}
          onToggleReservationType={doToggleReservationType}
          currencyCode={this.props.currencyCode}
          stateOfResidence={stateOfResidence}
        />
      );
    }

    if (offerType === 'tour') {
      return (
        <BookingFormTour
          item={item}
          onUpdateReservation={onUpdateReservation}
          onUpdateStateOfResidence={onUpdateStateOfResidence}
          onToggleBookingDates={onToggleBookingDates}
          currencyCode={this.props.currencyCode}
          stateOfResidence={stateOfResidence}
        />
      );
    }

    return <div>Booking type not supported - {bookingType}</div>;
  };

  getOfferPackage = () => this.props.item.pkg;

  render() {
    const { item, itemIdx, currencyCode, offer } = this.props;

    const count = itemIdx + 1;
    const surchargePaidDirectToVendor = offer.type != 'tour' && offer.surcharge_paid_direct_to_vendor;

    return (
      <BookingItemWrapper
        item={item}
        offerPackage={this.getOfferPackage()}
        count={count}
        currencyCode={currencyCode}
        offer={offer}
        surchargePaidDirectToVendor={surchargePaidDirectToVendor}
      >
        <div>
          <div className="booking-item-form">{this.renderForm()}</div>
          <div className="booking-item-footer">
            <Button
              variant="text"
              onClick={(e) => {
                e.preventDefault();

                if (!this.canRemoveItem()) {
                  return;
                }

                this.handleRemoveItem({ item });
              }}
              disabled={!this.canRemoveItem()}
              startIcon={<DeleteIcon />}
            >
              Remove
            </Button>
          </div>
        </div>
      </BookingItemWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    items: getAllItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doRemoveFromCart: removeFromCart,
      doToggleBookingDates: toggleBookingDates,
      doToggleReservationType: toggleReservationType,
    },
    dispatch,
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(BookingItem);
