import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Container, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';

import PlaceLookUp from '~/components/Common/Forms/PlaceLookUp';
import { PropertyLocationOverride } from '~/components/Content/PropertyLocationOverride/Columns';
import {
  TYPEAHEAD_PLACE_TYPES,
  TYPEAHEAD_PROPERTY_TYPES,
} from '~/components/Content/PropertyLocationOverride/constants';

import useCurrentUser from '~/hooks/useCurrentUser';

import SearchService, { TypeaheadSearchResult } from '~/services/SearchService';

type Props = {
  onClose: () => void;
  data?: PropertyLocationOverride[];
};

const AddPropertyLocationOverrideForm = ({ onClose, data = [] }: Props) => {
  const [property, setProperty] = useState<TypeaheadSearchResult | undefined>(undefined);
  const [place, setPlace] = useState<TypeaheadSearchResult | undefined>(undefined);
  const [remarks, setRemarks] = useState<string>('');

  const { user } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectedProperty = useCallback((selection: TypeaheadSearchResult | undefined) => {
    setProperty(selection);
  }, []);

  const handleSelectedPlace = useCallback((selection: TypeaheadSearchResult | undefined) => {
    setPlace(selection);
  }, []);

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarks(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (data.some((item) => item.propertyId === property.id.split(':')[1] && item.placeId === place.id)) {
      enqueueSnackbar('Offer Association already exists', { variant: 'error' });
      return;
    }

    try {
      const { status } = await SearchService.putOfferAssociationList(
        property.id.split(':')[1],
        place.id,
        user.fullName,
        remarks,
      );
      if (status === 200) {
        enqueueSnackbar('Added association', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to add association', { variant: 'error' });
      }

      onClose();
    } catch (e) {
      enqueueSnackbar(`Failed to add association: ${e}`, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 3 }}>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" gap={4}>
          <PlaceLookUp
            onCurrentValueChange={handleSelectedProperty}
            types={TYPEAHEAD_PROPERTY_TYPES}
            label="Property"
          />
          <PlaceLookUp onCurrentValueChange={handleSelectedPlace} types={TYPEAHEAD_PLACE_TYPES} label="Place" />

          <TextField
            label="Remarks (Optional)"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
            placeholder="Enter any remarks here"
            value={remarks}
            onChange={handleNotesChange}
          />

          <Stack direction="column" gap={2}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Container>
  );
};

export default AddPropertyLocationOverrideForm;
