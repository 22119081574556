import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import {
  REVIEWS_PER_PAGE,
  Review,
  deleteReview,
  getReviews,
  updateReviewResponse,
  updateReviewVisibility,
  updateReviewWording,
} from '~/services/ReviewService';

import UserReviewDetailsModal from './UserReviewDetailsModal';

interface Props {
  tourId?: string;
  propertyId?: string;
  experienceId?: string;
  isRental?: boolean;
}

const UserReviewsTable = ({ tourId, propertyId, experienceId, isRental }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [fetchingRequest, setFetchingRequest] = useState<boolean>(false);
  const [viewReviewDetails, setViewReviewDetails] = useState<Review>(null);

  const getReviewsList = useCallback(async () => {
    const offset = (page - 1) * REVIEWS_PER_PAGE;

    const { result, total } = await getReviews({
      tourId,
      propertyId,
      experienceId,
      offset,
      showHidden: true,
      limit: REVIEWS_PER_PAGE,
    });

    setTotal(total ?? 0);
    setReviews(result ?? []);
  }, [experienceId, propertyId, tourId, page]);

  const closeReviewDetailsModal = useCallback(() => {
    setViewReviewDetails(null);
  }, []);

  const onReviewDetailsModalConfirm = useCallback(
    async (reviewId, changes) => {
      if (!Object.keys(changes).length) return;

      try {
        setFetchingRequest(true);

        if ('isPubliclyVisible' in changes) {
          await updateReviewVisibility(reviewId, {
            isPubliclyVisible: changes.isPubliclyVisible,
          });
          enqueueSnackbar('Review visibility successfully updated!', {
            variant: 'success',
          });
        }

        if ('response' in changes) {
          await updateReviewResponse(reviewId, changes.response);
          enqueueSnackbar('Review response successfully updated!', {
            variant: 'success',
          });
        }

        if ('reviewWording' in changes) {
          await updateReviewWording(reviewId, changes.reviewWording);
          enqueueSnackbar('Review wording successfully updated!', {
            variant: 'success',
          });
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        await getReviewsList();
        setFetchingRequest(false);
        closeReviewDetailsModal();
      }
    },
    [enqueueSnackbar, getReviewsList, closeReviewDetailsModal],
  );

  const onDeleteReview = useCallback(
    async (reviewId) => {
      try {
        setFetchingRequest(true);

        await deleteReview(reviewId);
        enqueueSnackbar('Review successfully deleted!', {
          variant: 'success',
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        await getReviewsList();
        setFetchingRequest(false);
        closeReviewDetailsModal();
      }
    },
    [enqueueSnackbar, getReviewsList, closeReviewDetailsModal],
  );

  useEffect(() => {
    getReviewsList();
  }, [getReviewsList]);

  const columns = useMemo<GridColDef<Review>[]>(
    () => [
      {
        field: 'userName',
        headerName: isRental ? 'Guest Name' : 'User',
        minWidth: 400,
        display: 'flex',
      },
      {
        field: 'rating',
        headerName: 'Rating',
        width: 150,
        display: 'flex',
      },
      {
        field: 'review',
        headerName: 'Review',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'isPubliclyVisible',
        headerName: 'Visibility',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params) => <span>{params.row.isPubliclyVisible ? 'Visible' : 'Hidden'}</span>,
        display: 'flex',
      },
    ],
    [isRental],
  );

  const orderType = useMemo(() => {
    if (tourId) return 'tour';
    if (propertyId) return 'property';
    return 'experience';
  }, [tourId, propertyId]);

  return (
    <>
      <UserReviewDetailsModal
        isOpen={viewReviewDetails !== null}
        review={viewReviewDetails}
        onRequestClose={closeReviewDetailsModal}
        onConfirm={onReviewDetailsModalConfirm}
        onDelete={onDeleteReview}
        isRental={isRental}
      />

      {reviews.length === 0 && <Typography>There are no reviews for this {orderType}.</Typography>}

      {reviews.length > 0 && (
        <DataGrid
          loading={fetchingRequest}
          className={`${orderType}-reviews-table`}
          rows={reviews || []}
          rowCount={total}
          columns={columns}
          pageSizeOptions={[REVIEWS_PER_PAGE]}
          paginationModel={{ page: page - 1, pageSize: REVIEWS_PER_PAGE }}
          pagination
          paginationMode="server"
          getRowId={(row: Review) => row.id}
          getRowHeight={() => 'auto'}
          onPaginationModelChange={({ page }) => setPage(page + 1)}
          onRowClick={(row: GridRowParams<Review>) => setViewReviewDetails(row.row)}
          slots={{ pagination: GridPagination }}
          disableRowSelectionOnClick
          disableColumnMenu
          autoHeight
        />
      )}
    </>
  );
};

export default UserReviewsTable;
