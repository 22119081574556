import {
  EXP_PROVIDER_LED,
  EXP_PROVIDER_LED_PREFIX,
  EXP_PROVIDER_REZDY,
  EXP_PROVIDER_REZDY_PREFIX,
} from '~/consts/experiences';

const experienceVendorFormatter = (provider: string) => {
  switch (provider) {
    case 'mus':
      return 'Musement';
    case EXP_PROVIDER_LED_PREFIX:
      return EXP_PROVIDER_LED;
    case EXP_PROVIDER_REZDY:
      return EXP_PROVIDER_REZDY_PREFIX;
    default:
      return 'Addon';
  }
};

export default experienceVendorFormatter;
