import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  refreshAmenities: () => void;
}

export default function NewAmenityModal({ isModalOpen, closeModal, refreshAmenities }: Props) {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [builtIn, setBuiltIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(async () => {
    if (!name) {
      enqueueSnackbar('Please fill in all fields', { variant: 'error' });
      return;
    }
    try {
      setLoading(true);
      await ReservationService.createAmenity(name, builtIn);
      setLoading(false);
      refreshAmenities();
      closeModal();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setLoading(false);
    }
  }, [name, builtIn]);

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogTitle>Create New Amenity</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={'Name'}
        />
        <FormControlLabel
          control={<Checkbox checked={builtIn} onChange={(e) => setBuiltIn(e.target.checked)} />}
          label="Built In"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={isLoading}>
          {isLoading ? <Spinner size={15} inline /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
