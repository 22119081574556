import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Box } from '@mui/material';

import { formatDateLong } from '~/services/TimeService';

import Log from './Log';

interface Props {
  giftCardItem: App.GiftCardItem;
  order: App.Order;
}

export default function Item({ giftCardItem, order }: Props) {
  return (
    <Box display="grid" gap={1} gridTemplateColumns="repeat(6, 1fr)">
      <div>
        <strong>Value</strong>
      </div>

      <Box gridColumn="span 3">
        {currencyFormatter.format(giftCardItem.total, {
          code: order.currency_code,
        })}
      </Box>
      <div>
        {currencyFormatter.format(giftCardItem.total, {
          code: order.currency_code,
        })}
      </div>

      <div> {order.status === 'completed' ? 'Paid' : ''}</div>

      <div>
        <strong>Expiry Date</strong>
      </div>

      <Box gridColumn="span 3">{formatDateLong(giftCardItem.expiry)}</Box>

      <Box gridColumn="span 6">
        <Log item={giftCardItem} order={order} />
      </Box>
    </Box>
  );
}
