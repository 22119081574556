import { addBadge, addBadgesToOffer, deleteBadge, getBadge, getBadges, updateBadge } from '../services/BadgesService';
import { reportError } from '../utils/reportError';

export const START_API_CALL = 'badges/START_API_CALL';
export const FETCH_BADGES_SUCCEED = 'badges/FETCH_BADGES_SUCCEED';
export const FAIL_API_CALL = 'badges/FAIL_API_CALL';

export const UPDATE_BADGE_START = 'badges/UPDATE_BADGE_START';
export const UPDATE_BADGE_SUCCEED = 'badges/UPDATE_BADGE_SUCCEED';
export const UPDATE_BADGE_FAIL = 'badges/UPDATE_BADGE_FAIL';
export const FETCH_BADGE_DETAILS_SUCCEED = 'badges/FETCH_BADGE_DETAILS_SUCCEED';
export const POST_BADGE_SUCCEED = 'badges/POST_BADGE_SUCCEED';
export const DELETE_BADGE_SUCCEED = 'badges/DELETE_BADGE_SUCCEED';
export const PATCH_BADGE_DETAILS_SUCCEED = 'badges/PATCH_BADGE_DETAILS_SUCCEED';

export const ADD_BADGE_TO_SELECTED = 'badges/ADD_BADGE_TO_SELECTED';
export const REMOVE_BADGE_FROM_SELECTED = 'badges/REMOVE_BADGE_FROM_SELECTED';
export const ADD_BADGES_TO_OFFER = 'badges/ADD_BADGES_TO_OFFER';

function startApiCall() {
  return { type: START_API_CALL };
}

interface FetchingBadges {
  badges: App.Badge[];
  total: number;
}
function succeedFetchingBadges(badgesPayload: FetchingBadges) {
  return { type: FETCH_BADGES_SUCCEED, badgesPayload };
}

function failApiCall(error) {
  return { type: FAIL_API_CALL, error };
}

function succeedFetchingBadgeDetails(badge: App.Badge) {
  return { type: FETCH_BADGE_DETAILS_SUCCEED, badge };
}

function successPatchBadgeDetails(badge: App.Badge) {
  return { type: PATCH_BADGE_DETAILS_SUCCEED, badge };
}

function succeedAddNewBadge(badge: App.Badge) {
  return { type: POST_BADGE_SUCCEED, badge };
}

function succeedRemoveBadge(badge: App.Badge) {
  return { type: DELETE_BADGE_SUCCEED, badge };
}

function addBadgeToSelected(id: string) {
  return { type: ADD_BADGE_TO_SELECTED, id };
}

function removeBadgeFromSelected(id: string) {
  return { type: REMOVE_BADGE_FROM_SELECTED, id };
}

function succeedAddBadgesToOffer(badges: App.Badge[]) {
  return { type: ADD_BADGES_TO_OFFER, badges };
}

export function fetchBadges() {
  return (dispatch) => {
    dispatch(startApiCall());
    return getBadges()
      .then(({ result, total }) => {
        dispatch(succeedFetchingBadges({ badges: result, total }));
      })
      .catch((error) => {
        reportError(error);
        dispatch(failApiCall(error));
      });
  };
}

export function fetchBadgeDetails(badge_id: number, badges: App.Badge[]) {
  return async (dispatch) => {
    dispatch(startApiCall());
    const badgeDetails = badges.find((badge: App.Badge) => badge.id === badge_id);
    if (badgeDetails) {
      dispatch(succeedFetchingBadgeDetails(badgeDetails));
      return;
    }
    return getBadge(badge_id)
      .then(({ result }) => {
        dispatch(succeedFetchingBadgeDetails(result));
      })
      .catch((error) => {
        reportError(error);
        dispatch(failApiCall(error));
      });
  };
}

export function submitPatchForm(badge: App.Badge) {
  return async (dispatch) => {
    dispatch(startApiCall());
    return updateBadge(badge.id, badge)
      .then(({ result }) => {
        dispatch(successPatchBadgeDetails(result));
      })
      .catch((error) => {
        reportError(error);
        dispatch(failApiCall(error));
      });
  };
}

export function submitPostForm(badge: App.Badge) {
  return async (dispatch) => {
    dispatch(startApiCall());
    return addBadge(badge)
      .then(({ result }) => {
        dispatch(succeedAddNewBadge(result));
      })
      .catch((error) => {
        reportError(error);
        dispatch(failApiCall(error));
      });
  };
}

export function removeBadge(badge: App.Badge) {
  return async (dispatch) => {
    dispatch(startApiCall());
    return deleteBadge(badge.id)
      .then(() => {
        dispatch(succeedRemoveBadge(badge));
      })
      .catch((error) => {
        reportError(error);
        dispatch(failApiCall(error));
      });
  };
}

export function selectBadge(badge_id: string) {
  return async (dispatch) => {
    dispatch(addBadgeToSelected(badge_id));
  };
}

export function deselectBadge(badge_id: string) {
  return async (dispatch) => {
    dispatch(removeBadgeFromSelected(badge_id));
  };
}

export function submitSelectedBadges(offer_id: string, badges: App.Badge[]) {
  return async (dispatch) => {
    dispatch(startApiCall());
    return addBadgesToOffer(offer_id, badges)
      .then((result) => {
        dispatch(succeedAddBadgesToOffer(result));
      })
      .catch((error) => {
        reportError(error);
        dispatch(failApiCall(error));
      });
  };
}
