import React from 'react';

import { Helmet } from 'react-helmet';

import { Box, Container } from '@mui/material';

import Asinc from '~/components/Common/Asinc';
import PageHeader from '~/components/Common/Elements/PageHeader';
import RatePlansGroupsForm from '~/components/Common/Forms/RatePlansGroupsForm';

import ReservationService from '~/services/ReservationService';

export default class RatePlansGroupEditContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = props;

    this.vendorId = match.params.id_vendor;
    this.fetchData = this.fetchData.bind(this);
  }

  async fetchData(vendorId) {
    const [schema, ratePlansGroups] = await Promise.all([
      ReservationService.getRatePlansGroupsSchema(vendorId),
      ReservationService.getRatePlansGroups(vendorId),
    ]);

    return {
      schema: schema.put.body.schema,
      ratePlansGroups: ratePlansGroups.result,
    };
  }

  render() {
    const returnTo = `/vendors/${this.vendorId}`;

    return (
      <Container maxWidth="xl" className="rate-plans-groups-edit-form">
        <Helmet>
          <title>Vendors | Rate Plans Groups</title>
        </Helmet>

        <PageHeader title="Rate plan groups" backButton={returnTo} />

        <Asinc
          promise={this.fetchData(this.vendorId)}
          then={({ schema, ratePlansGroups }) => (
            <Box id="overview" className="property-deal-section">
              <RatePlansGroupsForm schema={schema} ratePlansGroups={ratePlansGroups} vendorId={this.vendorId} />
            </Box>
          )}
        />
      </Container>
    );
  }
}
