import React, { useCallback, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

import { formatDateISO } from '~/services/TimeService';
import VendorsService from '~/services/VendorsService';

const uiSchema: UiSchema = {
  fk_offer: { 'ui:widget': 'hidden' },
  fk_vendor: { 'ui:widget': 'hidden' },
  fk_added_by: { 'ui:widget': 'hidden' },
  date_of_adjustment: {
    'ui:placeholder': '31/12/2017',
    'ui:widget': 'date',
    'ui:title': 'Date of Payment',
  },
  currency: {
    'ui:widget': 'select',
    'ui:title': 'Payment currency',
  },
  amount: {
    'ui:placeholder': '0',
    'ui:widget': 'text',
    'ui:title': 'Payment amount',
  },
  description: {
    'ui:widget': 'textarea',
    'ui:title': 'Description',
  },
};

interface Props {
  schema: any;
  id_adjustment: string;
  date_of_adjustment: string;
  fk_offer: string;
  fk_vendor: string;
  currency: string;
  amount: string;
  description: string;
  onErrorPaymentUpdated: (errorPayment: Record<string, unknown>) => void;
}

export function ErrorPaymentsUpdateForm(props: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [formData] = useState({
    id_adjustment: props.id_adjustment,
    date_of_adjustment: formatDateISO(props.date_of_adjustment),
    fk_offer: props.fk_offer,
    fk_vendor: props.fk_vendor,
    currency: props.currency,
    amount: props.amount,
    description: props.description,
  });

  const onErrorPaymentUpdated = props.onErrorPaymentUpdated;

  const validationHandler = useCallback((data, errors) => {
    const amount = Number.parseFloat(data.amount);

    if (!Number.isFinite(amount)) {
      errors.amount.addError('should be a number');
    }

    return errors;
  }, []);

  const submitHandler = useCallback(
    async (e: IChangeEvent) => {
      try {
        const res = await VendorsService.updateErrorPayment(e.formData);
        enqueueSnackbar(`Error payment updated`, { variant: 'success' });
        onErrorPaymentUpdated(res.result);
      } catch (err) {
        enqueueSnackbar(`Can't update error payment: ${err.message}`, { variant: 'error' });
      }
    },
    [onErrorPaymentUpdated, enqueueSnackbar],
  );

  return (
    <Box id="errorpayments-form" mb={2}>
      <Form
        schema={props.schema}
        uiSchema={uiSchema}
        onSubmit={submitHandler}
        customValidate={validationHandler}
        validator={validator}
        formData={formData}
      />
    </Box>
  );
}
