import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import { buttonMessages as baseButtonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';

const buttonMessages = { ...baseButtonMessages };
buttonMessages[buttonStates.default] = 'Save changes';

const imageOptions = {
  width: 400,
  height: 300,
};

const ImagePreview = ({ id_image, id_cloudinary_external, title, order, onTitleChange, onImageDelete }) => {
  const imageSrc = useImageUrlGenerator(id_cloudinary_external, imageOptions);

  return (
    <>
      <Card elevation={1} sx={{ maxWidth: imageOptions.width, width: imageOptions.width }}>
        <CardMedia component="img" image={imageSrc} alt={title} height={imageOptions.height} />
        <CardContent>
          <span>{order}</span>{' '}
          <input
            id={`title-${id_image}`}
            name="title"
            type="text"
            value={title || ''}
            onChange={onTitleChange(id_image)}
          />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={onImageDelete(id_image)} startIcon={<DeleteIcon />}>
            Delete image
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

const ImageCard = ({ imageId, title }) => {
  const imageSrc = useImageUrlGenerator(imageId, imageOptions);

  return (
    <Card elevation={1} sx={{ maxWidth: imageOptions.width, width: imageOptions.width }}>
      <CardMedia component="img" image={imageSrc} alt={title} height={imageOptions.height} />
      <CardContent>
        <Typography fontSize={16}>{title}</Typography>
      </CardContent>
    </Card>
  );
};

export function Component({
  addon,
  addonOption,
  images,
  saveState,
  onImageUpload,
  onImageDelete,
  onTitleChange,
  onChangesSave,
}) {
  // Current restriction: only one image
  // Will be changed after preparing design for multiple images on customer portal
  const image = images.length > 0 ? images[0] : null;

  return (
    <>
      <PageHeader
        title="Edit Addon Image"
        backButton={`/vendors/${addon.vendor_id}/addons/${addon.fk_salesforce_id}`}
      />

      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <dl>
          <dt>Addon Name</dt>
          <dd>{addon.name}</dd>

          <dt className="mt-10">Option Name</dt>
          <dd>{addonOption.name}</dd>
        </dl>

        {images.length > 0 && (
          <div>
            <Button variant="contained" onClick={onChangesSave} disabled={saveState === buttonStates.saving}>
              {buttonMessages[saveState]}
            </Button>
          </div>
        )}
      </Stack>

      <Stack direction="row" spacing={2} flexWrap="wrap" mt={2}>
        {addonOption.salesforce_cloudinary_id && (
          <Box>
            <ImageCard imageId={addonOption.salesforce_cloudinary_id} title="Current image from Salesforce" />
          </Box>
        )}

        <Box>
          {image ? (
            <ImagePreview key={image.id_image} onTitleChange={onTitleChange} onImageDelete={onImageDelete} {...image} />
          ) : (
            <ImageUploadField label="image" field_key="addonImage" multiple={false} onUpload={onImageUpload} />
          )}
        </Box>
      </Stack>
    </>
  );
}
