import React from 'react';

import { Box, TextField } from '@mui/material';

import { STEP_PREREFUND_NIB_INSURANCE } from '~/consts/refund';

import RefundMethod from './RefundMethod';
import SubmitButton from './SubmitButton';

export default class LateInsuranceRefund extends React.Component {
  constructor(props) {
    super(props);

    this.hasLatitudePayment = props.order.payments.some(
      (p) => p.currency === 'AUD' && p.type === 'latitude' && p.intent === 'sale' && p.success === true,
    );
  }

  componentDidMount() {
    this.props.setBackStep(STEP_PREREFUND_NIB_INSURANCE);
  }

  render() {
    return (
      <Box>
        <Box id="comment">
          <label>Customer's reason:</label>
          <TextField
            name="comment"
            value={this.props.refund.comment}
            onChange={this.props.handleValueChange}
            placeholder="Brief explanation of why the customer is requesting a refund"
            className="data-hj-whitelist"
            minRows={2}
            multiline
          />
        </Box>

        <Box id="ticket_id">
          <label>Case ID:</label>
          <input
            name="ticket_id"
            value={this.props.refund.ticket_id}
            onChange={this.props.handleValueChange}
            className="data-hj-whitelist"
          />
        </Box>

        <Box id="refund_method">
          <label>Select Method of Refund to Customer</label>
          <RefundMethod
            handleValueChange={this.props.handleValueChange}
            refund_method={this.props.refund.refund_method}
            methods={this.props.refund.methods}
            hasLatitudePayment={this.hasLatitudePayment}
          />
        </Box>

        <SubmitButton
          setError={this.props.setError}
          refund={this.props.refund}
          order={this.props.order}
          totalRefund={this.props.totalRefund}
          totalFee={this.props.totalFee}
          hasRefundItem={this.props.hasRefundItem}
          hasRefundSurcharge={this.props.hasRefundSurcharge}
          totalPromoAmount={this.props.totalPromoAmount}
          accountingMetaPayload={this.props.accountingMetaPayload}
          closeModal={this.props.closeModal}
        />
      </Box>
    );
  }
}
