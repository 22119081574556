export function isContentEmpty(val) {
  return (
    val === null ||
    val === undefined ||
    (Array.isArray(val) && !val.length) ||
    (typeof val === 'object' && Object.keys(val).length === 0) ||
    (typeof val === 'object' && !Object.keys(val).length && !Object.values(val).every(isContentEmpty)) ||
    (typeof val === 'string' && !val.length) ||
    (typeof val === 'string' && !val.trim().length)
  );
}

export default isContentEmpty;
