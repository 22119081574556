import React from 'react';

import currencyFormatter from 'currency-formatter';

const PriceInclusionDetail = ({
  surcharge,
  extraGuestSurcharge,
  currencyCode,
  surchargePaidDirectToVendor,
  waivedSurchargeAmount,
}) => {
  let surchargeMessage = surchargePaidDirectToVendor ? 'Surcharge to be paid at hotel' : 'Peak period surcharge';

  if (waivedSurchargeAmount) {
    surchargeMessage = 'Waived surcharge';
  }

  return (
    <div className="price-inclusion-detail">
      {!surchargePaidDirectToVendor && !waivedSurchargeAmount && (
        <div className="price-inclusion-detail-header">Includes</div>
      )}
      <div className="price-inclusion-detail-row">
        <span className="price-inclusion-detail-type">Extra Guest Surcharge</span>
        <span className="price-inclusion-detail-amount data-hj-whitelist">
          +
          {currencyFormatter.format(extraGuestSurcharge, {
            code: currencyCode,
          })}
        </span>
      </div>
      <div className="price-inclusion-detail-row">
        <span className={`price-inclusion-detail-type ${waivedSurchargeAmount && 'surcharge-waived-msg'}`}>
          {surchargeMessage}
        </span>
        <span
          className={`price-inclusion-detail-amount data-hj-whitelist ${
            waivedSurchargeAmount && 'surcharge-waived-msg'
          }`}
        >
          +
          {currencyFormatter.format(waivedSurchargeAmount || surcharge, {
            code: currencyCode,
          })}
        </span>
      </div>
    </div>
  );
};

export default PriceInclusionDetail;
