import React from 'react';

import { connect } from 'react-redux';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import { Stack } from '@mui/material';

import { formatDateISO } from '~/services/TimeService';

import { RESERVATION_TYPE_BOOK_LATER } from '../../../consts/reservation';

function ReservationDetailHotel({ reservation, stateOfResidence }) {
  if (reservation.reservationType === RESERVATION_TYPE_BOOK_LATER) {
    return 'Buy now book later';
  }

  const fullname = `${reservation.firstname} ${reservation.lastname}`;

  return (
    <div className="item-reservation">
      <Stack direction="row" className="section">
        <PersonIcon />
        <span className="data-hj-whitelist">{reservation.numAdults} adults,</span>
        <span className="data-hj-whitelist">{reservation.numChildren} children,</span>
        <span className="data-hj-whitelist">{reservation.numInfants} infants</span>
      </Stack>
      <Stack direction="row" className="section">
        <ListAltIcon />
        <span>{fullname}</span>
      </Stack>
      <Stack direction="row" className="section">
        <CalendarMonthIcon />
        <span className="data-hj-whitelist">{formatDateISO(reservation.checkIn)}</span>
        <span className="separator">-</span>
        <span className="data-hj-whitelist">{formatDateISO(reservation.checkOut)}</span>
      </Stack>
      {reservation.guestSpecialRequests && (
        <Stack direction="row" className="section">
          <div className="section-header">Special requests</div>
          <p>{reservation.guestSpecialRequests}</p>
        </Stack>
      )}
      {stateOfResidence && (
        <Stack direction="row" className="section">
          <HomeIcon />
          <span>{stateOfResidence}</span>
        </Stack>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    stateOfResidence: state.cart.stateOfResidence,
  };
}

export default connect(mapStateToProps)(ReservationDetailHotel);
