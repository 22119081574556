import { CustomValidator } from '@rjsf/utils';

// TODO: consume the contract
const validateTourDetailsFormData: CustomValidator = function (formData, errors) {
  if (!formData?.campaignSchedule?.length) return errors;

  const campaignScheduleErrors = formData.campaignSchedule
    .map((campaignScheduleItem, i) => {
      let { startDate, endDate, offlineStartDate, offlineEndDate } = campaignScheduleItem;

      if (!startDate || !endDate || !offlineStartDate || !offlineEndDate) return null;

      // Use the dates for comparison
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      offlineStartDate = new Date(offlineStartDate);
      offlineEndDate = new Date(offlineEndDate);

      if (startDate > endDate) {
        return {
          name: 'endDate',
          message: `End date must be after start date in Campaign Schedule #${i + 1}`,
        };
      }

      if (offlineStartDate > offlineEndDate) {
        return {
          name: 'offlineEndDate',
          message: `Offline end date must be after offline start date in Campaign Schedule #${i + 1}`,
        };
      }

      if (offlineStartDate > startDate) {
        return {
          name: 'offlineStartDate',
          message: `Offline start date must be before start date in Campaign Schedule #${i + 1}`,
        };
      }

      if (offlineEndDate < endDate) {
        return {
          name: 'offlineEndDate',
          message: `Offline end date must be after end date in Campaign Schedule #${i + 1}`,
        };
      }

      return null;
    })
    .filter(Boolean);

  if (campaignScheduleErrors.length)
    errors.campaignSchedule.addError(campaignScheduleErrors.map((e) => e.message).join(', '));

  return errors;
};

export default validateTourDetailsFormData;
