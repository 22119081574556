import React, { useCallback, useState } from 'react';

import { Alert, Button, Checkbox, FormControlLabel } from '@mui/material';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { LUX_PLUS_SCHEDULE } from '~/consts/schedule';

import DateTimeWidget from '../../../Common/Forms/DateTimeWidget';

interface Props {
  label: string;
  type: string;
  schedule: App.Schedule;
  isModified: boolean;
  onChange: (newSchedule: App.Schedule) => void;
  onDelete: () => void;
  disabled: boolean;
}

const ScheduleTableRow = React.memo((props: Props) => {
  const { label, type, schedule, isModified, onChange, onDelete, disabled } = props;

  const [isDeleting, setDeleting] = useState(false);
  const deleteSchedule = () => {
    if (confirm('Are you sure you want to delete this schedule?')) {
      setDeleting(true);
      onDelete();
    }
  };

  const onStartChange = useCallback(
    (start: string) => {
      onChange({ ...schedule, start });
    },
    [schedule, onChange],
  );

  const onEndChange = useCallback(
    (end: string) => {
      onChange({ ...schedule, end });
    },
    [schedule, onChange],
  );

  const toggleRestrictPurchasesFlag = useCallback(
    (event) => {
      onChange({ ...schedule, restrict_purchase_to_lux_plus_members: event.target.checked });
    },
    [schedule, onChange],
  );

  return (
    <tr>
      <td>{label}</td>
      <td>
        <div className="schedule-form-base">
          <DateTimeWidget disabled={disabled} value={schedule.start} onChange={onStartChange} defaultTimezone="UTC" />
          <div className="schedules-arrow-short">&#8594;</div>
          <DateTimeWidget disabled={disabled} value={schedule.end} onChange={onEndChange} defaultTimezone="UTC" />
        </div>
      </td>
      <td>
        <PermissionedComponent>
          <Button variant="contained" color="error" onClick={deleteSchedule} disabled={isDeleting || disabled}>
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </PermissionedComponent>
      </td>
      {type === LUX_PLUS_SCHEDULE && (
        <td>
          <FormControlLabel
            control={
              <Checkbox
                checked={schedule.restrict_purchase_to_lux_plus_members}
                onChange={toggleRestrictPurchasesFlag}
              />
            }
            label="Restrict purchase to LuxPlus+ members"
          />
        </td>
      )}

      <td>{isModified && <Alert severity="info">Unsaved changes</Alert>}</td>
    </tr>
  );
});

ScheduleTableRow.displayName = 'ScheduleTableRow';

export default ScheduleTableRow;
