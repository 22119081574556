import React from 'react';

import { getCreditReservationDetails } from '../../services/FlightsService';
import Spinner from '../Common/Spinner';

import CreditReservationEdit from './CreditReservationEdit';

export class CreditReservationEditPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      dataError: '',
      creditReservation: {},
    };
  }

  componentDidMount() {
    this.refreshData().catch((err) => {
      this.setState({ dataError: err });
    });
  }

  refreshData = async () => {
    this.setState({ isReady: false }, async () => {
      const {
        match: { params },
      } = this.props;

      const response = await getCreditReservationDetails(params.id);
      if (response.result) {
        this.setState({
          isReady: true,
          creditReservation: response.result,
        });
      }
    });
  };

  render() {
    const { creditReservation, isReady, dataError } = this.state;
    const { history } = this.props;

    if (dataError) {
      return <div className="alert alert-danger">Failed to load data: {dataError}</div>;
    }

    if (!isReady) {
      return <Spinner />;
    }

    return (
      <div className="container">
        <CreditReservationEdit creditReservation={creditReservation} history={history} />
      </div>
    );
  }
}

export default CreditReservationEditPageContainer;
