import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  iconSize?: 'small' | 'medium' | 'large';
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand: _expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ExpandModeButton({ iconSize = 'large', ...props }: ExpandMoreProps) {
  return (
    <ExpandMore {...props} aria-label="show more" expand={props.expand} aria-expanded={props.expand}>
      <ExpandMoreIcon fontSize={iconSize} />
    </ExpandMore>
  );
}
