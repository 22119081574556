import React from 'react';

import { Stack, Typography } from '@mui/material';

export default function AccommodationPropertyRoomMappingSection() {
  return (
    <Stack direction="column" gap={4}>
      <Typography variant="h5">Room mapping will go here</Typography>
    </Stack>
  );
}
