import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

type Props = {
  cabinCategories: { id: string; code: string; color?: string }[];
};

export default function CabinCategoryDetailsColumnDetails(props: Props) {
  const { cabinCategories } = props;

  return (
    <Stack flexDirection="row" gap={1} justifyContent="center" alignItems="center">
      {cabinCategories.map((cabinCategory) => (
        <Box
          width={40}
          borderRadius={10}
          textAlign="center"
          key={cabinCategory.id}
          style={{
            backgroundColor: `${cabinCategory.color ?? '#adadad'}40`,
            border: `1px solid ${cabinCategory.color ?? '#adadad'}`,
          }}
        >
          <Typography fontWeight="500" variant="body2">
            {cabinCategory.code}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}
