import { SELECT_TENANT } from '../actions/tenant';
import TENANTS from '../consts/tenant';

export default function (state = TENANTS.lux, action) {
  switch (action.type) {
    case SELECT_TENANT: {
      return TENANTS[action.value];
    }
    default:
      return state;
  }
}
