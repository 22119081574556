import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import Spinner from '~/components/Common/Spinner';

import * as carHireService from '~/services/CarHireService';

import CarHireOrderItemDetails from './CarHireOrderItemDetails';

type Props = {
  currencyCode: string;
  carHireItems: App.CarHireItem[];
  hasAllowedRefund?: boolean;
  showRefundModal?: ({ itemId, itemType }) => void;
  refreshData: () => void;
};

export default function CarHireOrderListDetails(props: Props) {
  const {
    carHireItems: [item],
    currencyCode,
    hasAllowedRefund,
    showRefundModal,
    refreshData,
  } = props;

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getBookingDetails = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoading(true);
        const { result } = await carHireService.get(id);

        if (!result) {
          enqueueSnackbar('Missing reservation ID', {
            variant: 'warning',
          });
        }

        setResult(result);
      } catch (e) {
        enqueueSnackbar('Failed to load Car Hire data', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (item.id_reservation) {
      getBookingDetails(item.id_reservation);
    }
  }, [item.id_reservation, getBookingDetails]);

  return (
    <Box mt={2}>
      <FoldableSection title="Car hire" initiallyExpanded>
        {loading && <Spinner size={36} />}

        {!loading && (
          <CarHireOrderItemDetails
            count={1}
            carHireItem={item}
            bookingInfo={result}
            currencyCode={currencyCode}
            hasAllowedRefund={hasAllowedRefund}
            showRefundModal={showRefundModal}
            refreshData={refreshData}
          />
        )}
      </FoldableSection>
    </Box>
  );
}
