import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import { Alert, Box, Button, Container, Tab, Tabs } from '@mui/material';

import AsincCreateNewUser from '../Common/AsincCreateNewUser';
import PageHeader from '../Common/Elements/PageHeader';

import RolesTab from './UsersRoles/RolesTab';
import UsersTab from './UsersRoles/UsersTab';

const alertUserSuccessfullyRegistered = (
  <Alert severity="success">
    <strong>Success!</strong> User has been registered.
  </Alert>
);

const tabsMap = {
  Users: UsersTab,
  'Roles & Permissions': RolesTab,
};

type Tab = keyof typeof tabsMap;

export default function AdminUsersPageContainer() {
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<Tab>('Users');
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccessCreation = () => {
    setShowNewUserModal(false);
    enqueueSnackbar(alertUserSuccessfullyRegistered, {
      variant: 'success',
    });
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>System | Admin Users & Roles</title>
      </Helmet>

      <PageHeader title="Admin Users & Roles">
        <Button variant="contained" onClick={() => setShowNewUserModal(true)}>
          Create New User
        </Button>
      </PageHeader>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
          {Object.keys(tabsMap).map((tab) => (
            <Tab key={tab} value={tab} label={tab} />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box component={tabsMap[currentTab]} />
      </Box>

      <AsincCreateNewUser
        show={showNewUserModal}
        onHide={() => setShowNewUserModal(false)}
        userRegistrationSuccess={handleSuccessCreation}
      />
    </Container>
  );
}
