import React, { useEffect } from 'react';

import { Box, Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import useQuery from '~/hooks/useQuery';

import { formatDateISO, formatDateISOUTC } from '~/services/TimeService';

import MetricsResults from './MetricsResults';

const channelOptions = [
  {
    value: 'derbysoft',
    label: 'Derbysoft',
  },
];

const metricNames = {
  derbysoft: ['inventoryPerProperty', 'inventoryPerRoomRate', 'activeRoomRatesPerProperty', 'newlyInactiveRoomRate'],
};

const SIZE_PER_PAGE = 10;

export default function MetricsResultsContainer() {
  const [channel, setChannel] = React.useState<{
    value: string;
    label: string;
  }>(null);

  const query = useQuery();
  const dateParam = query.get('date') ? formatDateISO(query.get('date')) : formatDateISOUTC(new Date());

  const channelParam = query.get('channel');

  useEffect(() => {
    if (channelParam) {
      setChannel(channelOptions.find((c) => c.value === channelParam));
    }
  }, [channelParam]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h3">Property Metrics</Typography>

      <Box sx={{ width: '25%' }}>
        <FormControl fullWidth>
          <InputLabel id="property-metrics-label">Property metrics</InputLabel>
          <Select
            labelId="property-metrics-label"
            id="property-metrics"
            value={channel?.value ?? 'derbysoft'}
            placeholder="Select a channel"
            onChange={(e) => {
              const value = e.target.value;
              setChannel({ label: channelOptions.find((c) => c.value === value).label, value });
            }}
          >
            {channelOptions.map((ch) => (
              <MenuItem key={ch.value} value={ch.value}>
                {ch.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {channel &&
        metricNames[channel.value].map((metricName: string) => (
          <MetricsResults
            key={metricName}
            sizePerPage={SIZE_PER_PAGE}
            metricName={metricName}
            channel={channel.value}
            date={dateParam}
          />
        ))}
    </Container>
  );
}
