import React, { useCallback, useState } from 'react';

import { Alert, Box, Typography } from '@mui/material';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';

import ReservationService from '~/services/ReservationService';

interface Props {
  propertyId: string;
  outgoingConnections: string[];
}

export default function PropertyARIPane(props: Props) {
  const { propertyId, outgoingConnections } = props;
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);

  const triggerSnapshot = useCallback(async () => {
    setInProgress(true);
    setError(null);
    setShowConfirmationMessage(false);

    try {
      await ReservationService.triggerSnapshot(propertyId);
      setShowConfirmationMessage(true);
    } catch (e) {
      setError(e.message);
    } finally {
      setInProgress(false);
    }
  }, [propertyId]);

  return (
    <>
      <PageSubheader title="Outgoing Connection Controls" />

      {error && <ErrorDisplay message={error} />}

      {showConfirmationMessage && (
        <Alert severity="success">
          <strong>Success!</strong> Updated
        </Alert>
      )}

      {!showConfirmationMessage && (
        <>
          <Typography>
            Pressing this button updates the availability, prices and inventory levels on{' '}
            {outgoingConnections.join(',')}
          </Typography>

          <Box mt={1}>
            <ConfirmButton variant="contained" position="top" onConfirm={triggerSnapshot} disabled={inProgress}>
              {inProgress ? 'Update In Progress' : 'Update'}
            </ConfirmButton>
          </Box>
        </>
      )}
    </>
  );
}
