import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
  {
    field: 'room_groups',
    headerName: 'Number of rooms assigned',
    width: 300,
    sortable: false,
    display: 'flex',
    renderCell: (params) => params.row.room_types.length,
  },
  { field: 'created_at', headerName: 'Created at', width: 300, sortable: false, display: 'flex' },
  { field: 'updated_at', headerName: 'Updated at', width: 300, sortable: false, display: 'flex' },
];

export default function RoomTypesGroupsList(props) {
  const { roomTypesGroups } = props;

  return (
    <DataGrid
      columns={columns}
      rows={roomTypesGroups || []}
      getRowId={(row) => row.name}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
}
