import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import {
  FDATableSchema,
  FDATable as IFDATable,
  createFDATableRecord,
  deleteFDATableRecord,
  getFDATableData,
  getFDATableSchema,
  updateFDATableRecord,
} from '~/services/FlightsService';

import { arrayToObject } from '~/utils/arrayUtils';

import FDAFormDialog from './FDAFormDialog';
import FDATable from './FDATable';
import FDATableServer from './FDATableServer';
import { FDATableServerSearch } from './FDATableServerSearch';

interface Props {
  selectedTable: string;
  onTableChange: (table: string) => void;
  tables: IFDATable[];
}

const HARD_LIMIT = 1000000;
const DEFAULT_PAGE_SIZE = 100;
const PAGE_SIZES = [25, 50, 100];

export default function FDATableContainer(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [schema, setSchema] = useState<
    | { attributes: FDATableSchema[]; searchSupported: boolean; sortableFields: string[]; searchableFields: string[] }
    | undefined
  >();
  const [data, setData] = useState<{ rows: any[]; count: number }>();
  const [loadingSchema, setLoadingSchema] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [showNewForm, setShowNewForm] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [toEdit, setToEdit] = useState<Record<string, string | number | boolean> | undefined>();
  const [toView, setToView] = useState<Record<string, string | number | boolean> | undefined>();
  const [sortModel, setSortModel] = useState<{ field: string; sort: string }>();
  const [searchModel, setSearchModel] = useState<{ field: string; value: string }>({ field: '', value: '' });

  const { tables, selectedTable, onTableChange } = props;

  const fetchSchema = async () => {
    setLoadingSchema(true);

    try {
      const { result } = await getFDATableSchema(selectedTable);
      setSchema({
        attributes: result.attributes,
        searchSupported: result.searchSupported,
        sortableFields: result.sortableFields,
        searchableFields: result.searchableFields,
      });

      if (result.searchSupported) {
        setSearchModel({ field: result.sortableFields[0], value: '' });
      }
    } catch (error) {
      setError(error.message);
    }

    setLoadingSchema(false);
  };

  const fetchTableData = useCallback(async () => {
    setLoading(true);

    try {
      const options = {
        sortField: sortModel?.field,
        sortDirection: sortModel?.sort,
        searchField: searchModel?.field,
        searchValue: searchModel?.value,
      };

      const limitOptions = schema.searchSupported
        ? { limit: pageSize, offset: page - 1 }
        : { limit: HARD_LIMIT, offset: 0 };

      const data = await getFDATableData(selectedTable, {
        ...options,
        ...limitOptions,
      });

      setData({ rows: data.result.rows, count: data.result.count });
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  }, [
    sortModel?.field,
    sortModel?.sort,
    searchModel?.field,
    searchModel?.value,
    schema?.searchSupported,
    pageSize,
    page,
    selectedTable,
  ]);

  useEffect(() => {
    setPage(1);
    setPageSize(100);
    setData(undefined);
    setSchema(undefined);
    setSortModel(undefined);
    setSearchModel({ field: '', value: '' });
    fetchSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTable]);

  useEffect(() => {
    if (schema) {
      fetchTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, page, pageSize, sortModel]);

  const handlePageChange = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) => {
      setPage(page + 1);
      setPageSize(pageSize);
    },
    [setPage, setPageSize],
  );

  const handleSave = async (data: Record<string, string | number | boolean>) => {
    setSaving(true);

    try {
      await createFDATableRecord(selectedTable, data);
      fetchTableData();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }

    setSaving(false);
    setShowNewForm(false);
  };

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteFDATableRecord(selectedTable, id);
        fetchTableData();
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [selectedTable, fetchTableData, enqueueSnackbar],
  );

  const handleEdit = useCallback(
    async (data: Record<string, string | number | boolean>) => {
      setSaving(true);

      try {
        await updateFDATableRecord(selectedTable, toEdit?.Id as string, data);
        fetchTableData();
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }

      setSaving(false);
      setToEdit(undefined);
    },
    [selectedTable, toEdit, fetchTableData, enqueueSnackbar],
  );

  const handleOpenEditForm = useCallback(
    (id: string) => {
      const row = data?.rows.find((row) => row.Id === id);
      setToEdit(row);
    },
    [data, setToEdit],
  );

  const handleSortChange = useCallback((sortField: string, sortDirection: string) => {
    setSortModel(sortField ? { field: sortField, sort: sortDirection } : undefined);
  }, []);

  const handleSearch = useCallback(() => {
    if (page !== 1) {
      setPage(1);
      return;
    }

    fetchTableData();
  }, [fetchTableData, page, setPage]);

  const handleOpenViewForm = useCallback(
    (id: string) => {
      const row = data?.rows.find((row) => row.Id === id);
      setToView(row);
    },
    [data, setToView],
  );

  const selectedTableName = useMemo(() => {
    return tables.find((t) => t.name === selectedTable)?.displayName || '';
  }, [selectedTable, tables]);

  const columns = useMemo(() => {
    return schema?.attributes.map((column) => ({ field: column.name, headerName: column.displayName })) || [];
  }, [schema]);

  const searchable = useMemo(() => {
    return arrayToObject(schema?.searchableFields || [], (val: string) => val);
  }, [schema?.searchableFields]);

  const searchableColumns = useMemo(() => {
    return columns.filter((c) => searchable[c.field]);
  }, [searchable, columns]);

  const showTable = !loadingSchema && schema && data;

  if (error || (!loadingSchema && !schema)) {
    return <div className="alert alert-danger">Failed to fetch table data: {error}</div>;
  }

  const formSchema = schema?.attributes.filter((field) => field.name !== 'Id');

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <FormControl>
          <InputLabel>Table</InputLabel>

          <Select
            label="Screen"
            onChange={(e) => onTableChange(e.target.value)}
            value={selectedTable}
            style={{ minWidth: '200px' }}
          >
            {tables.map((table) => (
              <MenuItem key={table.name} value={table.name}>
                {table.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {showTable && <Button onClick={() => setShowNewForm(true)}>New Record</Button>}
      </Box>

      {loadingSchema || (loading && !data) ? <Typography variant="h4">Loading...</Typography> : null}

      {showTable && !schema?.searchSupported ? (
        <FDATable
          columns={columns}
          onDelete={handleDelete}
          onEdit={handleOpenEditForm}
          data={data.rows}
          loading={loading}
          onView={handleOpenViewForm}
        />
      ) : null}

      {showTable && schema?.searchSupported ? (
        <Box display="flex" flexDirection="column" gap={4}>
          {schema.searchableFields.length > 0 && (
            <FDATableServerSearch
              fields={searchableColumns}
              onSearch={handleSearch}
              onSearchFieldChange={(field) => setSearchModel((prev) => ({ ...prev, field }))}
              onSearchValueChange={(value) => setSearchModel((prev) => ({ ...prev, value }))}
              searchField={searchModel?.field}
              searchValue={searchModel?.value}
            />
          )}

          <FDATableServer
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleOpenEditForm}
            data={data.rows}
            count={data.count}
            onPageChange={handlePageChange}
            onView={handleOpenViewForm}
            page={page - 1}
            pageSize={pageSize}
            pageSizes={PAGE_SIZES}
            loading={loading}
            sortableFields={schema.sortableFields}
            onSortChange={handleSortChange}
            sortModel={sortModel}
          />
        </Box>
      ) : null}

      {showNewForm && (
        <FDAFormDialog
          open={showNewForm}
          schema={formSchema}
          onClose={() => setShowNewForm(false)}
          title={`New Record [${selectedTableName}]`}
          onSave={handleSave}
          loading={saving}
        />
      )}

      {Boolean(toEdit) && (
        <FDAFormDialog
          open={Boolean(toEdit)}
          schema={formSchema}
          onClose={() => setToEdit(undefined)}
          title={`Edit Record [${toEdit?.Id}]`}
          onSave={handleEdit}
          initialValues={toEdit}
          loading={saving}
        />
      )}

      {Boolean(toView) && (
        <FDAFormDialog
          open={Boolean(toView)}
          schema={formSchema}
          onClose={() => setToView(undefined)}
          title={`View Record [${toView?.Id}]`}
          initialValues={toView}
          readOnly
        />
      )}
    </>
  );
}
