import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Autocomplete, Chip, FormControl, Stack, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';
import { Option, Request } from '~/components/Cruises/pages/Promotions/types';

import departureService from '~/services/cruises/DepartureService';

interface Props {
  selectedDepartureIds: Array<string>;
  onChange: (selectedDepartureIds: Array<string>) => void;
  vendorId: string;
}

function InputDepartureSelect({ selectedDepartureIds, onChange, vendorId }: Props) {
  const [loadedVendorId, setLoadedVendorId] = useState<string | null>(null);
  const [departures, setDepartures] = useState<Request<Array<API.DepartureResponse>>>({
    result: [],
    loading: false,
  });

  useEffect(() => {
    const fetchRateCodes = async () => {
      setDepartures((prev) => ({ ...prev, loading: true }));
      const res = await departureService.listWithPagination({
        take: 500,
        status: 'ACTIVE',
        vendorId,
      });

      if (res.status === 200 && res.result) {
        setLoadedVendorId(vendorId);
        setDepartures({ loading: false, result: res.result });
      }
    };

    if (!departures.loading && !!vendorId && loadedVendorId !== vendorId) {
      fetchRateCodes();
    }
  }, [departures.loading, vendorId, loadedVendorId]);

  const departuresSelectOptions = useMemo(() => {
    return departures.result
      ?.filter((item) => !selectedDepartureIds.includes(item.id))
      .map((item) => ({
        value: item.id,
        label: `${item.id} - ${item.name}`,
      }));
  }, [departures.result, selectedDepartureIds]);

  const departuresSelected = useMemo(() => {
    return selectedDepartureIds
      .map((rateId) => {
        const rate = departures.result?.find((rate) => rate.id === rateId);
        return { value: rateId, label: rate?.name };
      })
      .filter((item) => !!item.label);
  }, [selectedDepartureIds, departures.result]);

  const handleRatesChange = useCallback(
    (_, selectedOptions: Array<Option> | null) => {
      onChange(selectedOptions?.map((selectedRate) => selectedRate.value) || []);
    },
    [onChange],
  );

  return (
    <Stack spacing={2} direction="row">
      <FormControl fullWidth>
        <Autocomplete
          multiple
          id="select-rates"
          disableCloseOnSelect
          value={departuresSelected}
          options={departuresSelectOptions}
          onChange={handleRatesChange}
          disabled={!vendorId || departures.loading}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} required fullWidth label="Departures" placeholder="Please Enter Departures" />
          )}
        />
      </FormControl>
      {departures.loading && <Spinner size={20} />}
    </Stack>
  );
}

export default InputDepartureSelect;
