import React, { Fragment, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Link, Stack } from '@mui/material';

import useQuery from '~/hooks/useQuery';

import { addQuery, removeQuery } from '~/utils/url';

import CabinCategoriesGroupList from './CabinCategoriesGroupList';
import CabinCategoriesGroupSearchFilters from './CabinCategoriesGroupSearchFilters';
import { SearchFilters } from './constants';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function CruisesCabinCategoriesGroupPage() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const currentPage = useMemo(() => parseInt(query.get('page')) || 1, [query]);

  const searchFilters = useMemo(() => {
    const filters: SearchFilters = {};

    if (query.get('id')) filters.id = query.get('id');
    if (query.get('name')) filters.name = query.get('name');
    if (query.get('cabinCategoryCode')) filters.cabinCategoryCode = query.get('cabinCategoryCode');
    if (query.get('shipId')) filters.shipId = query.get('shipId');
    if (query.get('shipName')) filters.shipName = query.get('shipName');
    if (query.get('shipExternalId')) filters.shipExternalId = query.get('shipExternalId');
    if (query.get('vendorId')) filters.vendorId = query.get('vendorId');

    return filters;
  }, [query]);

  const onSearch = useCallback(
    (params: SearchFilters) => {
      const filtersToRemove: string[] = [];

      if (!params.id) filtersToRemove.push('id');
      if (!params.name) filtersToRemove.push('name');
      if (!params.cabinCategoryCode) filtersToRemove.push('cabinCategoryCode');
      if (!params.shipId) filtersToRemove.push('shipId');
      if (!params.shipName) filtersToRemove.push('shipName');
      if (!params.shipExternalId) filtersToRemove.push('shipExternalId');
      if (!params.vendorId) filtersToRemove.push('vendorId');

      const newLocation = addQuery(location, {
        id: params.id,
        name: params.name,
        cabinCategoryCode: params.cabinCategoryCode,
        shipId: params.shipId,
        shipName: params.shipName,
        shipExternalId: params.shipExternalId,
        vendorId: params.vendorId,
      });

      history.push(removeQuery(newLocation, ...filtersToRemove));
    },
    [history, location],
  );

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  return (
    <Fragment>
      <CabinCategoriesGroupSearchFilters onSearch={onSearch} querySearchFilters={searchFilters} />

      <Stack direction="row" justifyContent="end" my={3}>
        <Link target="_blank" href={'/cruises/cabin-categories-groups/create'} rel="noreferrer" underline="hover">
          CREATE CABIN CATEGORY GROUP
        </Link>
      </Stack>

      <CabinCategoriesGroupList
        brand={brand}
        page={currentPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
        searchFilters={searchFilters}
        sizePerPage={DEFAULT_SIZE_PER_PAGE}
      />
    </Fragment>
  );
}
