import { getRegionByCode } from '@luxuryescapes/lib-regions';

import { LUX_PLUS, MEMBERSHIP_BRANDS, MEMBERSHIP_REGIONS } from '~/consts/membership';

/**
 * Determine if the user's tenancy is compatible with a given site tenant
 *
 * Roughly translated from svc-auth: https://github.com/lux-group/svc-auth/blob/master/src/lib/tenant.js#L2-L30
 *
 * e.g. If the site tenant is currently set to 'cudo', lux tenant accounts are allowed to be used
 * @param {App.TenantValues} siteTenant The value of the current site tenant
 * @param {string} userTenant The tenant value of the user
 * @returns {boolean}
 */
const validUserTenantForSiteTenant = (siteTenant: App.TenantValues, userTenant: string) => {
  const luxCompatibleTenants = ['lux', 'cudo', 'deals', 'treatme', 'lebusinesstraveller'];

  if (luxCompatibleTenants.includes(siteTenant)) {
    return luxCompatibleTenants.includes(userTenant);
  }

  return siteTenant === userTenant;
};

/**
 * Generates an error message for a given invalid brand.
 * @param brand The invalid brand
 * @returns a string error message
 */
const invalidBrandErr = (brand?: string) =>
  `The customer is attempting to access ${LUX_PLUS.PROGRAM_NAME} from brand ${brand}. ${
    LUX_PLUS.PROGRAM_NAME
  } is only available from the following brands: ${MEMBERSHIP_BRANDS.join(', ')}`;

/**
 * Generates an error message for a given invalid region.
 * @param region The invalid region
 * @returns a string error message
 */
const invalidRegionErr = (regionName?: string) =>
  `${LUX_PLUS.PROGRAM_NAME} is not available in the customer's home region of ${regionName}`;

/**
 * Checks whether the user's membership region is currently a valid region for LuxPlus+
 * @param region The user's membership region
 */
function validateMembershipRegion(region?: string): asserts region is App.MembershipRegion {
  if (!(MEMBERSHIP_REGIONS as ReadonlyArray<string>).includes(region)) {
    throw new Error(invalidRegionErr(getRegionByCode(region)?.name));
  }
}

/**
 * Checks whether the user's membership brand is currently a valid brand for LuxPlus+
 * @param brand The user's membership brand
 */
function validateMembershipBrand(brand?: string): asserts brand is App.MembershipBrand {
  if (!(MEMBERSHIP_BRANDS as ReadonlyArray<string>).includes(brand)) {
    throw new Error(invalidBrandErr(brand));
  }
}

export { validUserTenantForSiteTenant, validateMembershipRegion, validateMembershipBrand };
