import React from 'react';

import { Box, Button } from '@mui/material';

import { chunkArray } from '~/utils/chunkArray';

// AddableCheckboxGroup is a group of checkboxes to which new options can be
// added. It is merely presentational and appropriate handlers and values should
// be passed down from the field used by jsonschema-form
export default class AddableCheckboxGroup extends React.Component {
  constructor(props) {
    super(props);

    this.isChecked = this.isChecked.bind(this);
  }

  // IDEA: looping is not the most efficient solution. Maybe use Set or object
  // if the number of options grow
  isChecked(value) {
    const { selection } = this.props;

    for (let i = 0; i < selection.length; i++) {
      const option = selection[i];

      if (option === value) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { options, onChange, onStartAddingAmenities } = this.props;

    const countColumns = 4;
    const chunkSize = Math.ceil(options.length / countColumns);
    const chunks = chunkArray(options, chunkSize);

    return (
      <div className="addable-checkbox-group">
        <Box display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
          {chunks.map((items, idx) => (
            <Box key={idx}>
              <ul className="option-list list-unstyled">
                {items.map((option) => {
                  const optionId = option.toLowerCase().replace(/\s/, '-');

                  return (
                    <li className="option-item" key={optionId}>
                      <label className="option">
                        <input
                          type="checkbox"
                          value={option}
                          checked={this.isChecked(option)}
                          onChange={(e) => {
                            const val = e.target.value;
                            const checked = e.target.checked;

                            onChange(val, checked);
                          }}
                        />
                        {option}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </Box>
          ))}
        </Box>

        <div className="actions">
          <Button variant="text" onClick={onStartAddingAmenities}>
            Add
          </Button>
        </div>
      </div>
    );
  }
}
