import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Container } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';
import { CruisePromoBannerProvider } from '~/components/Cruises/context/useCruisePromoBanners';

import promoBannerService from '~/services/cruises/PromoBannerService';

import PromoBannerForm from './components/PromoBannerForm/PromoBannerForm';

const PromoBannerEdit: React.FC = (): JSX.Element => {
  const { promoBannerId } = useParams<{ promoBannerId: string }>();
  const [promoBanner, setPromoBanner] = useState<API.PromoBanner | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    const fetchPromoBanner = async () => {
      setFetching(true);
      const promoBanner = await promoBannerService.getPromoBannerById(promoBannerId);
      setPromoBanner(promoBanner);
      setFetching(false);
    };
    fetchPromoBanner();
  }, [promoBannerId, setPromoBanner, setFetching]);

  return (
    <CruisePromoBannerProvider>
      <Container maxWidth="xl">
        <Helmet>
          <title>Edit promo tile</title>
        </Helmet>

        <PageHeader title="Edit Promo Tile" backButton="/cruises/promo-banners" />

        {fetching && !promoBanner && <Spinner size={30} />}
        {promoBanner && <PromoBannerForm promoBanner={promoBanner} />}
      </Container>
    </CruisePromoBannerProvider>
  );
};

export default PromoBannerEdit;
