import React from 'react';

import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import ArticlesPage from '../Articles/ArticlesPage';
import ChatsHistoryPage from '../ChatsHistory/ChatsHistoryPage';
import FeedbackPage from '../Feedback/FeedbackPage';
import SettingsPage from '../Settings/SettingsPage';
import StatisticsPage from '../Statistics/StatisticsPage';
import TopicsPage from '../Topics/TopicsPage';

const TabsMap = {
  'chats-history': {
    label: 'CHATS HISTORY',
    slug: 'chats-history',
    component: () => <ChatsHistoryPage />,
  },
  articles: {
    label: 'ARTICLES',
    slug: 'articles',
    component: () => <ArticlesPage />,
  },
  feedback: {
    label: 'FEEDBACK',
    slug: 'feedback',
    component: () => <FeedbackPage />,
  },
  statistics: {
    label: 'STATISTICS',
    slug: 'statistics',
    component: () => <StatisticsPage />,
  },
  topics: {
    label: 'TOPICS',
    slug: 'topics',
    component: () => <TopicsPage />,
  },
  settings: {
    label: 'SETTINGS',
    slug: 'settings',
    component: () => <SettingsPage />,
  },
} as const;

type TabKey = keyof typeof TabsMap;
const allTabs = Object.keys(TabsMap);

const SupportAssistantPage: React.FC = (): JSX.Element => {
  const { tab = 'chats-history' } = useParams<{ tab?: TabKey }>();
  const currentTab = TabsMap[tab];

  const title = `Support - ${currentTab.label}`;
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab}>
            {allTabs.map((tab) => (
              <Tab
                key={tab}
                value={tab}
                label={TabsMap[tab].label}
                to={`/support-assistant/${tab}`}
                component={Link}
                sx={{
                  alignItems: 'start',
                  '&.Mui-selected': {
                    backgroundColor: 'aliceblue',
                  },
                }}
              />
            ))}
          </Tabs>
        </Box>

        <Box mt={3}>
          <Box component={currentTab.component} />
        </Box>
      </Container>
    </>
  );
};

export default SupportAssistantPage;
