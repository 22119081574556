import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { useCruisePromoBanners } from '~/components/Cruises/context/useCruisePromoBanners';

import { formatDateShort } from '~/services/TimeService';
import promoBannerService from '~/services/cruises/PromoBannerService';

export const PromoBannerList: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteButton, setDeleteButton] = useState(null);
  const { promoBanners, page, total, promoBannersPerPage, paginate, getPromoBannerList, loading } =
    useCruisePromoBanners();
  const history = useHistory();

  const handleDeleteBanner = useCallback(
    async (id: string) => {
      try {
        await promoBannerService.deletePromoBanner(id);
        enqueueSnackbar('Promo tile deleted', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting promo tile', { variant: 'error' });
      }
    },
    [enqueueSnackbar],
  );

  const actionColumn = useCallback(
    (cell, row) => (
      <div>
        {deleteButton !== row.id && (
          <Button
            variant="text"
            size="small"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setDeleteButton(row.id);
            }}
          >
            Delete
          </Button>
        )}

        {deleteButton === row.id && (
          <div>
            <Button
              variant="text"
              size="small"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await handleDeleteBanner(row.id);
                getPromoBannerList();
              }}
            >
              Confirm
            </Button>

            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(null);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    ),
    [deleteButton, handleDeleteBanner, getPromoBannerList],
  );

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => formatDateShort(params.value),
      display: 'flex',
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => formatDateShort(params.value),
      display: 'flex',
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => {
        const today = new Date();
        const startDate = new Date(params.row.startDate);
        const endDate = new Date(params.row.endDate);

        if (today < startDate) {
          return 'Scheduled';
        } else if (today > endDate) {
          return 'Expired';
        } else {
          return 'Live';
        }
      },
      display: 'flex',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      sortable: false,
      renderCell: (params) => actionColumn(params.value, params.row),
      display: 'flex',
    },
  ];

  return (
    <Box mt={1}>
      <DataGrid
        loading={loading}
        rows={promoBanners || []}
        columns={columns}
        pagination
        paginationModel={{ page: page - 1, pageSize: promoBannersPerPage }}
        pageSizeOptions={[10]}
        getRowId={(row: API.PromoBanner) => row.id}
        paginationMode="server"
        rowCount={total}
        onPaginationModelChange={({ page }) => paginate(page + 1)}
        slots={{ pagination: GridPagination }}
        onRowClick={(row: GridRowParams<API.PromoBanner>) => history.push(`/cruises/promo-banner/${row.id}`)}
        autoHeight // dangerous if number of rows per page > 25
      />
    </Box>
  );
};
