import React from 'react';

import classNames from 'clsx';

import { datesHaveSameDayMonthYear, formatDateShortDD } from '~/services/TimeService';

const DateItem = ({ date, onSelectDate, startDate }) => {
  const isBookable = date.totalAvailable > 0;
  const isSelected = startDate && datesHaveSameDayMonthYear(date.startDate, startDate);

  const classes = classNames('date', 'T-check-in', {
    disabled: !isBookable,
    'T-selected': isSelected,
  });
  return (
    <label className={classes}>
      <span className="radio-button">
        <input
          type="radio"
          onChange={() => {
            onSelectDate(date);
          }}
          name="tour-date"
          disabled={!isBookable}
          defaultChecked={isSelected}
        />
        <i />
      </span>
      <span className="inline-group">
        <div className="start-date-text">{formatDateShortDD(date.startDate)}</div>
        <div className="icon-arrow">
          <hr />
        </div>
        <div className="end-date-text">Ending on {formatDateShortDD(date.endDate)}</div>
      </span>
    </label>
  );
};

export default DateItem;
