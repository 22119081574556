import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';

export default class VideoPane extends React.Component {
  render() {
    let offer = this.props.offer.result;

    return (
      <span>
        <h1>
          <Link to={`/edit-offers/${offer.id_salesforce_external}/videos`}>
            <ModeEditTwoToneIcon fontSize="large" />
            <span className="sr-only">Edit</span>
          </Link>
        </h1>
        <h2>Luxury Escapes</h2>
        {offer.videos.luxuryescapes.vimeo_id && offer.videos.luxuryescapes.vimeo_id != 'undefined' && (
          <div className="embed-responsive embed-responsive-16by9" style={{ marginTop: '10px' }}>
            <iframe
              width="560"
              height="315"
              src={`https://player.vimeo.com/video/${offer.videos.luxuryescapes.vimeo_id}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        )}
        <h2>Scoopon</h2>
        {offer.videos.scoopontravel.vimeo_id && offer.videos.scoopontravel.vimeo_id != 'undefined' && (
          <div className="embed-responsive embed-responsive-16by9" style={{ marginTop: '10px' }}>
            <iframe
              width="560"
              height="315"
              src={`https://player.vimeo.com/video/${offer.videos.scoopontravel.vimeo_id}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        )}
      </span>
    );
  }
}
