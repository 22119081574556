import React from 'react';

import { Link } from 'react-scroll';

import { HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

export default class HoverNav extends React.Component {
  render() {
    return (
      <span className="hover-scroll-nav">
        <ul className="nav nav-pills nav-stacked">
          <li>
            <Link to="overview" duration={500} smooth>
              Overview
            </Link>
          </li>
          <li>
            <Link to="timeline" duration={500} smooth>
              Timeline
            </Link>
          </li>
          <li>
            <Link to="packages" duration={500} smooth>
              Packages
            </Link>
          </li>
          <li>
            {HOTEL_OFFER_TYPES.includes(this.props.offerType) && (
              <Link to="roomPolicies" duration={500} smooth>
                Room Policy
              </Link>
            )}
          </li>
          <li>
            <Link to="images" duration={500} smooth>
              Images &amp; Videos
            </Link>
          </li>
          <li>
            <Link to="addons" duration={500} smooth>
              Add-ons
            </Link>
          </li>
          <li>
            {this.props.offerType === 'tour' && (
              <Link to="travellerDetailRequirements" duration={500} smooth>
                Requirements
              </Link>
            )}
          </li>
          <li>
            {this.props.offerType !== 'tour' && (
              <Link to="experiences" duration={500} smooth>
                Experiences
              </Link>
            )}
          </li>
        </ul>
      </span>
    );
  }
}
