export default function getDateDayDiff(
  endDateString: string | undefined,
  comparisonDateString: string = new Date().toISOString(),
): number | undefined {
  if (endDateString) {
    const endDate = new Date(endDateString);
    const diffTime = endDate.getTime() - new Date(comparisonDateString).getTime();
    if (diffTime >= 0) {
      return Math.floor(diffTime / (1000 * 3600 * 24))
    }
  }
};