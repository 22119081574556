import { ROLE_LERE_ADMIN, ROLE_LERE_USER } from '~/consts/roles';

import type { UserType } from '~/types/responses';

export function hasReadAccess(user: UserType | null) {
  return user?.roles.includes(ROLE_LERE_USER);
}

export function hasFullAccess(user: UserType | null) {
  return user?.roles.includes(ROLE_LERE_ADMIN);
}
