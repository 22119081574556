import React, { useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Card, Container } from '@mui/material';

import TTCTourSearchForm, { TTCTourSearchInput } from '../../ToursListing/TTCTourSearchForm';
import TTCTourSearchResultsTable from '../../ToursListing/Tables/TTCTourSearchResultsTable';

function getTTCTourSearchInputFromSearchParams(searchParams: URLSearchParams): TTCTourSearchInput {
  const input: TTCTourSearchInput = {};

  const brands = searchParams.get('brands')?.split(',');
  if (brands?.length) input.brands = brands;
  const countries = searchParams.get('countries')?.split(',');
  if (countries?.length) input.countries = countries;
  const sellingRegion = searchParams.get('sellingRegion');
  if (sellingRegion) input.sellingRegion = sellingRegion;
  const status = searchParams.get('status');
  if (status) input.status = status;
  const tourName = searchParams.get('tourName');
  if (tourName) input.tourName = tourName;

  return input;
}

function ToursTTCListPage() {
  const history = useHistory();
  const location = useLocation();

  const handleSearchFormSubmission = useCallback(
    (formObject: Record<string, string>) => {
      const urlSearchParamsToPush = new URLSearchParams(formObject);
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [history],
  );

  const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

  const { searchInput, pageNumber } = useMemo(() => {
    const pageFromSearchParams = urlSearchParams.get('page');
    return {
      searchInput: getTTCTourSearchInputFromSearchParams(urlSearchParams),
      pageNumber: pageFromSearchParams ? Number(pageFromSearchParams) : 1,
    };
  }, [urlSearchParams]);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
      if (pageIndex) urlSearchParamsToPush.set('page', String(pageIndex + 1));
      else urlSearchParamsToPush.delete('page');
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [urlSearchParams, history],
  );

  return (
    <Container maxWidth="xl">
      <TTCTourSearchForm initialInput={searchInput} onSubmit={handleSearchFormSubmission} />
      <Card sx={{ mt: 4 }}>
        <TTCTourSearchResultsTable
          searchInput={searchInput}
          pageIndex={pageNumber - 1}
          onPageChange={handlePageChange}
        />
      </Card>
    </Container>
  );
}

export default ToursTTCListPage;
