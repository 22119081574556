import { FETCH_USER_FAIL, FETCH_USER_START, FETCH_USER_SUCCEED } from '../actions/user';

const initialState = { items: {}, isLoading: true, isUpdating: false };

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_START: {
      const newState = { ...state };
      delete newState.error;
      return {
        ...newState,
        isLoading: true,
      };
    }
    case FETCH_USER_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        items: Object.assign({}, state.items, {
          [action.user.memberId]: action.user,
        }),
      };
    }
    case FETCH_USER_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
