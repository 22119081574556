import dayjs from '~/timeInit';

import { formatDateDateTimeWidget } from '~/services/TimeService';

export const defaultStartDate = () => {
  return formatDateDateTimeWidget(dayjs().tz(window.configs.DEFAULT_TIMEZONE).startOf('day').hour(6).utc());
};

export const defaultEndDate = () => {
  return formatDateDateTimeWidget(
    dayjs().tz(window.configs.DEFAULT_TIMEZONE).add(1, 'days').startOf('day').hour(23).minute(59).utc(),
  );
};
