import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Button } from '@mui/material';

import GiftCardOrderFooter from '../GiftCardOrderFooter';

const Row = ({ price, priceDisplay, title, getPackageQuantity, onRemoveFromCart, onAddToCart }) => (
  <tr className="booking-form">
    <td>{title}</td>
    <td>{priceDisplay}</td>
    <td>
      <input
        type="number"
        className="form-control quantity-value data-hj-whitelist"
        value={getPackageQuantity(price)}
        disabled
      />
      <button onClick={() => onRemoveFromCart(price)} className="quantity-btn">
        &mdash;
      </button>
      <button onClick={() => onAddToCart(price)} className="quantity-btn T-quantity-plus">
        +
      </button>
    </td>
  </tr>
);

export default class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNonAUDAlert: false,
    };
  }

  showNonAUDAlert = () => {
    this.setState({
      showNonAUDAlert: true,
    });
  };

  onChangeCurrencyCode = (e) => {
    this.props.onChangeCurrencyCode(e.target.value);
  };

  render() {
    const {
      fixedPriceOptions,
      customPriceOptions,
      onAddToCart,
      onRemoveFromCart,
      backStep,
      cart,
      nextStepLabel,
      currencyCode,
      getPackageQuantity,
      onProceedStep,
      regions,
      disableChangeCurrency,
      creatingOrder,
      brandTitle,
      addNewCustomPrice,
      onUpdatePrice,
    } = this.props;
    return (
      <div className="container">
        {creatingOrder && <div className="alert alert-info">Processing...</div>}

        <label htmlFor="currency-selection">Country</label>
        <select
          disabled={disableChangeCurrency}
          id="currency-selection"
          className="form-control"
          value={cart.regionCode}
          onChange={this.onChangeCurrencyCode}
          onFocus={this.showNonAUDAlert}
        >
          {regions.map((region) => {
            return (
              <option key={region.code} value={region.code}>
                {`${region.name} (${region.currencyCode})`}
              </option>
            );
          })}
        </select>
        {this.state.showNonAUDAlert && (
          <div className="alert alert-info" style={{ marginTop: '22px' }}>
            * Use spoofing for non-AUD gift card purchase.
          </div>
        )}
        <table className="table table-striped table-hover T-package-table">
          <thead>
            <tr>
              <th>Type</th>
              <th className="gift-card-price-column">Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {fixedPriceOptions.map((price) => (
              <Row
                price={price}
                priceDisplay={currencyCode + ' ' + currencyFormatter.format(price, { code: currencyCode })}
                title={
                  currencyFormatter.format(price, {
                    code: currencyCode,
                    precision: price % 1 ? 2 : 0,
                  }) + ` ${brandTitle} Gift Card`
                }
                getPackageQuantity={getPackageQuantity}
                onRemoveFromCart={onRemoveFromCart}
                onAddToCart={onAddToCart}
                key={price}
              />
            ))}
            {customPriceOptions.map((price, i) => (
              <Row
                price={price}
                priceDisplay={
                  <span>
                    {currencyCode} {currencyFormatter.findCurrency(currencyCode).symbol}
                    <input
                      className="form-control gift-card-custom-price-input"
                      type="number"
                      min={1}
                      value={price}
                      onChange={(e) => onUpdatePrice(price, parseInt(e.target.value) || 1)}
                    />
                  </span>
                }
                title="Set Custom Value"
                getPackageQuantity={getPackageQuantity}
                onRemoveFromCart={onRemoveFromCart}
                onAddToCart={onAddToCart}
                key={i}
              />
            ))}
            <tr>
              <td colSpan="3">
                <Button variant="text" onClick={addNewCustomPrice}>
                  + Add Custom Gift Card
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <GiftCardOrderFooter
          cart={cart}
          proceedStep={onProceedStep}
          canProceed={cart.items.length > 0 && !creatingOrder}
          backStep={backStep}
          nextStepLabel={nextStepLabel}
        />
      </div>
    );
  }
}
