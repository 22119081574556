import React from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridPagination, GridRenderCellParams } from '@mui/x-data-grid';

export default function TourList(props) {
  const { tours, total, page, onPageChange, editBtnFormatter, limit } = props;

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'id',
      headerName: 'Edit',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 100,
      flex: 0,
      renderCell: (params: GridRenderCellParams) => editBtnFormatter(params.value),
      display: 'flex',
    },
  ];

  return (
    <Box>
      <DataGrid
        className="T-tour-list-table"
        rows={tours || []}
        columns={columns}
        pageSizeOptions={[10]}
        paginationModel={{ pageSize: limit, page }}
        paginationMode="server"
        onPaginationModelChange={({ page }) => onPageChange(page)}
        getRowId={(row) => row.id}
        rowCount={total}
        getRowHeight={() => 'auto'}
        slots={{
          pagination: GridPagination,
        }}
        autoHeight
        disableRowSelectionOnClick
        sx={{ mt: 2 }}
      />
    </Box>
  );
}
