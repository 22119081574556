import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerCountryStatesGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/country-groups/{countryGroup}/countries/{country}/states']['get'];

function mapHeroPlannerCountryState(
  external: Utils.ArrayElement<
    HeroPlannerCountryStatesGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerCountryState {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetHeroPlannerCountryStates(brand: string, countryGroup: string, country: string) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';

  const url = makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/country-groups/${countryGroup}/countries/${country}/states`,
  );
  url.searchParams.set('brand', brand);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerCountryStatesGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerCountryState);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerCountryStates;
