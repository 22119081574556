import React, { KeyboardEvent, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Alert, Button, Container, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridPaginationModel } from '@mui/x-data-grid';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { getBusinessList } from '~/services/BusinessTraveller/BusinessTravellerService';

import BusinessGrid from '../components/BusinessGrid';

export default function MainPage() {
  const title = 'Businesses';
  const [businesses, setBusinesses] = useState<App.Business[]>();
  const [nameString, setNameString] = useState(null);
  const [idString, setIdString] = useState(null);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 50,
    page: 0,
  });
  const [totalBusinessCount, setTotalBusinessCount] = useState(0);
  const [fetchBusinessesError, setFetchBusinessesError] = useState(false);

  async function fetchBusinesses() {
    try {
      const result = await getBusinessList(paginationModel.pageSize, paginationModel.page);
      setBusinesses(result.result);
      setTotalBusinessCount(result.meta.total);
    } catch (e) {
      setFetchBusinessesError(true);
      console.error(e);
    }
  }

  useEffect(() => {
    fetchBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  async function clearFilters() {
    await fetchBusinesses();
    setNameString('');
  }

  async function filter() {
    const result = await getBusinessList(paginationModel.pageSize, 0, nameString, idString);
    setBusinesses(result.result);
    setTotalBusinessCount(result.meta.total);
    return;
  }

  async function handleEnterPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      await filter();
    }
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader title={title} />
      <Stack gap={2}>
        {fetchBusinessesError && <Alert severity="error">Error fetching businesses</Alert>}
        <Typography variant="h4">Search for a business</Typography>
        <Stack>
          <Grid2 container spacing={2}>
            <Stack width="100%">
              <Grid2 xs={12}>
                <TextField
                  variant="outlined"
                  id="BusinessSearchWithName"
                  type="text"
                  label="Business search with name"
                  placeholder="Enter Business Name"
                  onChange={(e) => setNameString(e.target.value)}
                  value={nameString}
                  fullWidth
                  onKeyDown={handleEnterPress}
                />
              </Grid2>
              <Grid2 xs={12}>- OR -</Grid2>
              <Grid2 xs={12}>
                <TextField
                  variant="outlined"
                  id="BusinessSearchWithId"
                  type="text"
                  label="Business search with Id"
                  placeholder="Enter Business Id"
                  onChange={(e) => setIdString(e.target.value)}
                  value={idString}
                  fullWidth
                  onKeyDown={handleEnterPress}
                />
              </Grid2>
              <Stack gap={2} direction="row" justifyContent="end">
                <Button variant="text" onClick={clearFilters}>
                  Clear all filters
                </Button>
                <Button variant="contained" onClick={filter}>
                  FILTER
                </Button>
              </Stack>
            </Stack>
            <BusinessGrid
              onPaginationModelChange={setPaginationModel}
              paginationModel={paginationModel}
              rows={businesses}
              total={totalBusinessCount}
            />
          </Grid2>
        </Stack>
      </Stack>
    </Container>
  );
}
