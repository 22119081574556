import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, Grid, TextField, Tooltip } from '@mui/material';

import searchService from '~/services/SearchService';

export const AddToBlocklist = () => {
  const tenant = useSelector((state: App.State) => state.tenant);
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, reset } = useForm();

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        const offerIds = data.offerIds.split(',').map((id) => id.trim());
        const { status } = await searchService.addToBlockedOfferList(tenant.brand, offerIds);
        if (status === 201) {
          reset({ offerIds: '' });
          enqueueSnackbar("Offers blocked successfully. They won't be participated in Ads", { variant: 'success' });
        } else {
          enqueueSnackbar('Failed to block offers', { variant: 'error' });
        }
      } catch (e) {
        enqueueSnackbar('Failed to block offers', { variant: 'error' });
      }
    },
    [enqueueSnackbar, reset, tenant.brand],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Controller
            key="offerIds"
            name="offerIds"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <Tooltip title="Multiple offer Id(s) must be comma separated.">
                <TextField {...field} fullWidth label="Add OfferId(s) to Block" />
              </Tooltip>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
