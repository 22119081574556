import React from 'react';

import { Link } from 'react-router-dom';

import { Button, Card, CardContent, Stack, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

export default function PropertyReviewsPane(props) {
  const { vendorId, propertyId, reviews } = props;

  return (
    <div>
      <PageSubheader title="Reviews">
        <Button
          variant="text"
          component={Link}
          to={`/vendors/${vendorId}/properties/${propertyId}/edit-property-reviews`}
        >
          Edit
        </Button>
      </PageSubheader>

      {reviews.length === 0 && <Typography>There are no reviews for this property.</Typography>}

      <Stack direction="row" flexWrap="wrap" spacing={1}>
        {reviews.length > 0 &&
          reviews.map((review) => (
            <Card key={review.id} variant="outlined" sx={{ maxWidth: 500, minWidth: 300 }}>
              <CardContent>
                <Typography variant="h6">Review</Typography>
                <Typography>{review.content}</Typography>

                <Typography variant="h6">Source</Typography>
                <Typography>{review.source}</Typography>
              </CardContent>
            </Card>
          ))}
      </Stack>
    </div>
  );
}
