import React from 'react';

import classnames from 'clsx';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import AddonsSelection from '../AddonsSelection';
import BookingInformation from '../BookingInformation';
import StepIndicator from '../StepIndicator';

import Payment from './AddItemPayment';

/******* Names of steps in the order process **/
const BOOKING_INFORMATION = 'booking-information';
const ADDONS_SELECTION = 'add-ons-selection';
const PAYMENT = 'payment';

// componentMap is a mapping from names of steps to components to render
const componentMap = {
  [BOOKING_INFORMATION]: BookingInformation,
  [ADDONS_SELECTION]: AddonsSelection,
  [PAYMENT]: Payment,
};

// labelMap maps step names to human friendly labels
const labelMap = {
  [BOOKING_INFORMATION]: 'Booking Information',
  [ADDONS_SELECTION]: 'Add-ons Selection',
  [PAYMENT]: 'Payment',
};

// steps is an array of steps in an ordered sequence
const steps = [BOOKING_INFORMATION, ADDONS_SELECTION, PAYMENT];

const stepNames = [labelMap[BOOKING_INFORMATION], labelMap[ADDONS_SELECTION], labelMap[PAYMENT]];

class AddItemPage extends React.Component {
  constructor(props) {
    super(props);
    this.getNextStepName = this.getNextStepName.bind(this);
    this.proceedStep = this.proceedStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this._transitionStep = this._transitionStep.bind(this);
    this.handleCancelOrderProcess = this.handleCancelOrderProcess.bind(this);
  }

  getCurrentStepIdx() {
    const {
      match: {
        params: { step },
      },
    } = this.props;

    return steps.indexOf(step);
  }

  getCurrentStepComponent() {
    const {
      match: {
        params: { step },
      },
    } = this.props;

    const Component = componentMap[step];
    if (Component) {
      return Component;
    }

    const firstStepName = steps[0];
    return componentMap[firstStepName];
  }

  _transitionStep(stepName) {
    const { history, user, orderId } = this.props;

    history.push(`/users/${user.id_member}/edit-orders/${orderId}` + `/add-item/${stepName}`);
  }

  proceedStep() {
    const currentStepIdx = this.getCurrentStepIdx();
    const nextStep = steps[currentStepIdx + 1];
    if (!nextStep) {
      return;
    }

    this._transitionStep(nextStep);
  }

  getNextStepName() {
    const currentStepIdx = this.getCurrentStepIdx();
    const nextStep = steps[currentStepIdx + 1];

    return labelMap[nextStep];
  }

  handleCancelOrderProcess() {
    const { history, onEmptyCart, orderId } = this.props;

    onEmptyCart();
    history.push(`/purchases/${orderId}`);
  }

  backStep() {
    const currentStepIdx = this.getCurrentStepIdx();
    const prevStep = steps[currentStepIdx - 1];

    if (!prevStep) {
      return this.handleCancelOrderProcess();
    }

    this._transitionStep(prevStep);
  }

  render() {
    const {
      user,
      match: {
        params: { step },
      },
      orderId,
    } = this.props;

    const Component = this.getCurrentStepComponent();

    return (
      <div className="new-order-page">
        <Helmet>
          <title>Users | {user.fullName} | Add item</title>
        </Helmet>
        <div className="new-order-header">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h1 className="new-order-heading">Add item: {user.fullName}</h1>
                <span className="user-email">{user.email}</span>

                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleCancelOrderProcess();
                  }}
                  href="#cancel-order-process"
                  className="cancel-btn"
                >
                  Cancel
                </a>

                <StepIndicator currentStepIdx={this.getCurrentStepIdx()} steps={steps} stepNames={stepNames} />
              </div>
            </div>
          </div>
        </div>

        <main className={classnames('new-order-body', step)}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <Component
                  allowToggleReservationType={true}
                  disableChangeCurrency={true}
                  proceedStep={this.proceedStep}
                  backStep={this.backStep}
                  nextStepLabel={this.getNextStepName()}
                  user={user}
                  orderId={orderId}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(AddItemPage);
