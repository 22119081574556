import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import { Grid } from '@mui/material';

import Image from '../../Common/Image';

const imageOptions = {
  width: 170,
};

export default class ImagesPane extends React.Component {
  render() {
    return (
      <span>
        <h1>
          <Link to={'/edit-offers/' + this.props.offer.result.id_salesforce_external + '/images'}>
            <ModeEditTwoToneIcon fontSize="large" />
            <span className="sr-only">Edit</span>
          </Link>
        </h1>
        <Grid container>
          {this.props.offer.result.images.map((image) => (
            <Grid item key={image.id_image} sm={3}>
              <div className="img-thumbnail">
                <Image className="img-responsive" publicId={image.id_cloudinary_external} options={imageOptions} />
                {image.title && <span>{image.title}</span>}
              </div>
            </Grid>
          ))}
        </Grid>
      </span>
    );
  }
}
