import React from 'react';

import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';

import { Box, Button } from '@mui/material';

import { downloadCruiseRefundReport } from '~/services/ReportingService';

const formSchema: RJSFSchema = {
  description: 'Please enter your dates to export the Cruise refund report',
  type: 'object',
  properties: {
    startAt: {
      type: 'string',
      format: 'date',
      title: 'Select Start Date',
    },
    endAt: {
      type: 'string',
      format: 'date',
      title: 'Select End Date',
    },
    fileFormat: {
      type: 'string',
      title: 'File Format',
      enum: ['csv', 'email'],
    },
  },
};

const formData = {
  startAt: null,
  endAt: null,
  fileFormat: 'email',
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  fileFormat: {
    'ui:widget': 'radio',
  },
};

const ExportCruiseRefundReportForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    const { startAt, endAt, fileFormat } = event.formData;
    const data = downloadCruiseRefundReport({ startAt, endAt, fileFormat });

    data
      .then((text) => {
        if (fileFormat !== 'email') {
          const fileName = `Cruise_refund_report_${startAt}_${endAt}.${fileFormat}`;
          fileDownload(text, fileName);
        } else {
          enqueueSnackbar(`Your report is queued for delivery and will be emailed shortly.`, { variant: 'success' });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  return (
    <Box>
      <Form
        schema={formSchema}
        formData={formData}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={handleSubmit}
        className="package-form"
      >
        <Button type="submit" variant="contained">
          Download/Email Refund Report
        </Button>
      </Form>
    </Box>
  );
};

export default ExportCruiseRefundReportForm;
