import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

import ErrorDisplay from '../ErrorDisplay';

function validateForm(formData, errors) {
  if (typeof formData.dateOfPayment === 'undefined') {
    errors.dateOfPayment.addError('Please enter date of payment');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please enter a date of payment to generate an ABA file',
  type: 'object',
  properties: {
    dateOfPayment: {
      title: 'Date of payment',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  dateOfPayment: {
    'ui:widget': 'date',
  },
};

export default function ABAFileForm() {
  const [error, setError] = useState(null);
  const [comment, setComment] = useState(null);

  const resetMessageState = () => {
    setError(null);
    setComment(null);
  };

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setComment('Downloading ABA File...');

      await VendorsService.downloadABAFile(event.formData.dateOfPayment);

      resetMessageState();
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Download ABA File
      </Button>

      {error && <ErrorDisplay message={error} />}

      {comment && (
        <Alert severity="info">
          <p>{comment}</p>
        </Alert>
      )}
    </Form>
  );
}
