import UsersService from '../services/UsersService';

// Loops the given results, looks up the user based on the given user field
// and then merges the results back into the original results
export default (results, field) => {
  const users = {};

  results.forEach((result) => {
    if (result[field]) {
      users[result[field]] = null;
    }
  });

  const lookups = Object.keys(users).map((user) => UsersService.getUser(user));

  return Promise.all(lookups).then((userData) => {
    userData.forEach((user) => {
      users[user.id_member] = user;
    });

    return results.map((result) => {
      result[field] = users[result[field]];

      return result;
    });
  });
};
