import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

const formatLabel = (value) => {
  switch (value) {
    case 'non-commissionable':
      return 'Non-Commissionable';
    case 'commissionable':
      return 'Commissionable';
    default:
      return '-';
  }
};

export default function CommissionableTaxesAndFeesPane({ vendorId, propertyId, items }) {
  return (
    <>
      <PageSubheader title="Non-commissionable taxes and fees">
        <Button
          variant="text"
          component={Link}
          to={`/vendors/${vendorId}/properties/${propertyId}/edit-property-commissionable-taxes-content`}
        >
          Edit
        </Button>
      </PageSubheader>

      {items.length === 0 && <span>There are no non-commissionable taxes and fees set for this property.</span>}

      {items.length > 0 && (
        <dl className="dl-horizontal">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <dt>{formatLabel(item.commissionable_type)}</dt>
              <dd>{item.content}</dd>
            </React.Fragment>
          ))}
        </dl>
      )}
    </>
  );
}
