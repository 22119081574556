import { API } from '@luxuryescapes/lib-types';

import { json_headers, request } from './common';

interface BadgeResult {
  result: API.Offer.Badge;
}

function basePath() {
  return window.configs.API_HOST + '/api/offers/badges';
}

function offerBadgesURL(offerId) {
  return window.configs.API_HOST + `/api/offers/${offerId}/badges`;
}

export async function addBadge(badgeData) {
  return request(basePath(), {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(badgeData),
  });
}

export async function getBadges() {
  return request(basePath() + '/' + 'all', {
    method: 'GET',
  });
}

export async function getBadge(id): Promise<BadgeResult> {
  return request(basePath() + '/' + id, {
    method: 'GET',
  });
}

export async function updateBadge(id, badgeData) {
  return request(basePath() + '/' + id, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(badgeData),
  });
}

export async function deleteBadge(id) {
  return request(basePath() + '/' + id, {
    method: 'DELETE',
    headers: json_headers,
    ignoreResponse: true,
  });
}

export function addBadgesToOffer(offerId, badges) {
  return request(offerBadgesURL(offerId), {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({ badge_ids: badges }),
  });
}

export function removeBadgeFromOffer(offerBadgeId, offerId) {
  return request(offerBadgesURL(offerId) + '/' + offerBadgeId, {
    method: 'DELETE',
    headers: json_headers,
    ignoreResponse: true,
  });
}

export function updateOfferBadge(offerId, offerBadgeId, regions, schedules, brands) {
  return request(offerBadgesURL(offerId) + '/' + offerBadgeId, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify({ regions, schedules, ...(brands && { brands }) }),
  });
}
