import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    dateField: {
      title: 'Date Field Filter',
      type: 'string',
      anyOf: [
        {
          type: 'string',
          enum: ['booking_date'],
          title: 'Booking Date',
        },
        {
          type: 'string',
          enum: ['payment_date'],
          title: 'Payment Date',
        },
      ],
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  dateField: {
    'ui:widget': 'radio',
  },
};

export default function ExportExpediaMarketingFeesReportForm() {
  const [error, setError] = useState(null);
  const [comment, setComment] = useState(null);

  const resetMessageState = () => {
    setError(null);
    setComment(null);
  };

  const handleSubmit = async (event: IChangeEvent) => {
    const { startAt, endAt, dateField } = event.formData;
    try {
      setComment('Downloading Expedia Marketing Fees Report...');

      await VendorsService.downloadExpediaMarketingFeesReport({
        startAt,
        endAt,
        dateField,
      });

      resetMessageState();
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };
  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Download Expedia Marketing Fees Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {comment && (
        <Alert severity="info">
          <p>{comment}</p>
        </Alert>
      )}
    </Form>
  );
}
