import React from 'react';

class Utils {
  nopElement = (<span />);

  isInputLikeComponent(element, inputNames = ['input', 'field', 'text']) {
    if (typeof element.type === 'function') {
      const hasInputLikeName = this.containsWord(this.functionName(element.type), inputNames);
      const spInputLike = this.takeProp(element.props, 'spInputLike', 'data-spInputLike');
      return spInputLike || hasInputLikeName;
    }
  }

  takeProp(source, ...fields) {
    for (let i = 0; i < fields.length; i++) {
      let fieldName = fields[i];
      if (fieldName in source) {
        return source[fieldName];
      }
    }
  }

  excludeProps(exclude, source) {
    var result = {};
    if (source) {
      for (var key in source) {
        if (exclude.indexOf(key) === -1) {
          result[key] = source[key];
        }
      }
    }
    return result;
  }

  deepForEach(node, handler) {
    handler(node);
    if (node.props.children) {
      this.forEachChild(node.props.children, handler, node);
    }
  }

  forEachChild(children, handler, parent) {
    React.Children.forEach(children, (child) => {
      handler(child, parent);
      if (child.props && child.props.children) {
        this.forEachChild(child.props.children, handler, child);
      }
    });
  }

  buildElementTree(element, optionsFactory, elementFactory, parent) {
    var newChildren;
    var newElement = (elementFactory && elementFactory(element, parent)) || element;
    var newOptions = (optionsFactory && optionsFactory(element, parent)) || {};
    if (newElement !== this.nopElement && newElement.props && newElement.props.children) {
      newChildren = React.Children.map(newElement.props.children, (childElement) => {
        if (!React.isValidElement(childElement)) {
          return elementFactory(childElement, parent);
        }
        return this.buildElementTree(childElement, optionsFactory, elementFactory, newElement);
      });
    }
    return React.cloneElement(newElement, newOptions, newChildren);
  }

  mapFormField(element, mappingFn, defaultValue) {
    if (this.isInputLikeComponent(element)) {
      if (element.props && element.props.name) {
        mappingFn(element.props.name, defaultValue);
      }
    } else if (['input', 'textarea'].indexOf(element.type) > -1) {
      if (element.props.type !== 'submit') {
        mappingFn(element.props.name, defaultValue);
      }
    }
  }

  getFormFieldMap(root, handler) {
    var fields = {};
    var tryMapField = (field, name, defaultValue) => {
      if (field.props.ignore) {
        return;
      }
      if (field.props.fieldName) {
        name = field.props.fieldName;
      }
      if (!(name in fields)) {
        fields[name] = {
          element: field,
          defaultValue: defaultValue,
        };
      }
    };

    this.forEachChild(root, (child) => {
      if (!child.props) {
        return;
      }
      handler(child, tryMapField.bind(null, child));
    });
    var inverseMap = {};
    var defaultValues = {};
    for (var key in fields) {
      var field = fields[key];
      var element = field.element;
      var elementType = typeof element.type === 'function' ? this.functionName(element.type) : element.type;
      if (!(elementType in inverseMap)) {
        inverseMap[elementType] = {};
      }
      defaultValues[key] = field.defaultValue ? field.defaultValue : element.props.value || '';
      inverseMap[elementType][element.props.name] = {
        fieldName: key,
        field: element,
      };
    }
    return {
      defaultValues: defaultValues,
      inverse: inverseMap,
    };
  }

  getFieldValue(source, name) {
    let cursor = source;
    let segments = name.split('.');
    for (let i = 0; i < segments.length; i++) {
      let key = segments[i];
      if (cursor) {
        if (!(key in cursor)) {
          return;
        }
        cursor = cursor[key];
      }
    }
    return cursor;
  }

  setFieldValue(source, name, value, force) {
    let ref = source;
    let segments = name.split('.');
    for (let i = 0; i < segments.length; i++) {
      let key = segments[i];
      if (i === segments.length - 1) {
        if (!force && key in ref) {
          return;
        }
        ref[key] = value;
        return;
      }
      if (!(key in ref)) {
        ref[key] = {};
      }
      ref = ref[key];
    }
  }

  makeForm(source, fieldMapFn, spIfFn, spBindFn) {
    var root = React.cloneElement(<div />, {}, source.props.children);
    var fieldMap = this.getFormFieldMap(root, fieldMapFn);
    source.state.fields = source.state.fields || {};
    for (var key in fieldMap.defaultValues) {
      this.setFieldValue(source.state.fields, key, fieldMap.defaultValues[key]);
    }
    var elementFactory = (element) => {
      if (element.props) {
        var spIf = this.takeProp(element.props, 'spIf', 'data-spif');
        if (spIf) {
          var test = null;
          var inverted = false;
          if (spIf[0] === '!') {
            inverted = true;
            spIf = spIf.substr(1);
          }
          test = spIfFn(spIf, element);
          if (test !== null) {
            if (inverted) {
              test = !test;
            }
            if (!test) {
              return this.nopElement;
            }
          }
        }

        var spBind = this.takeProp(element.props, 'spBind', 'data-spBind');
        if (spBind) {
          var newElement = spBindFn(spBind, element);
          if (newElement !== false || newElement) {
            element = newElement;
          }
        }
      }
      return element;
    };

    var optionsFactory = (element) => {
      var options = {};
      if (element.props) {
        var elementType = typeof element.type === 'function' ? element.type.name : element.type;
        var elementAttributeName = element.props.name;
        if (elementType in fieldMap.inverse && elementAttributeName in fieldMap.inverse[elementType]) {
          var mappedField = fieldMap.inverse[elementType][elementAttributeName];
          if (elementAttributeName in fieldMap.defaultValues) {
            options.defaultValue = fieldMap.defaultValues[elementAttributeName];
          }
          var originalOnChange = element.props.onChange;
          options.onChange = (e, ...args) => {
            options.disabled = source.state.isFormProcessing;
            this.setFieldValue(source.state.fields, mappedField.fieldName, e.target.value, true);
            if (originalOnChange) {
              originalOnChange(e, ...args);
            }
          };
        }
        var elementAttributeType = element.props.type;
        if ((elementType === 'input' || elementType === 'button') && elementAttributeType === 'submit') {
          options.disabled = source.state.isFormProcessing;
        }
      }
      return options;
    };
    return this.buildElementTree(root, optionsFactory, elementFactory);
  }

  clone(value) {
    return JSON.parse(JSON.stringify(value));
  }

  mergeObjects(obj1, obj2) {
    var obj3 = {};
    if (obj1) {
      for (let attrname in obj1) {
        obj3[attrname] = obj1[attrname];
      }
    }
    if (obj2) {
      for (let attrname in obj2) {
        obj3[attrname] = obj2[attrname];
      }
    }
    return obj3;
  }

  isArray(object) {
    var nativeIsArray = Array.isArray;
    var toString = Object.prototype.toString;
    return nativeIsArray(object) || toString.call(object) === '[object Array]';
  }

  enforceRootElement(object, props) {
    let newObject;
    if (typeof object === 'string' || this.isArray(object)) {
      if (!props) {
        props = {};
      }
      if (!props.style) {
        props.style = {};
      }
      newObject = <div {...props}>{object}</div>;
    } else {
      let newProps = props;
      let newChildren = [];
      if (object.props) {
        for (let key in object.props) {
          let value = object.props[key];
          if (key === 'children') {
            newChildren = value;
          } else {
            newProps[key] = value;
          }
        }
      }

      newObject = React.cloneElement(object, newProps, newChildren);
    }
    return newObject;
  }

  serializeFormObject(value) {
    var items = [];
    for (var key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        items.push(encodeURIComponent(key) + '=' + encodeURIComponent(value[key]));
      }
    }
    return items.join('&');
  }
}

export default new Utils();
