import React, { useEffect, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import cleanProperty from './helpers/cleanProperty';

const fetchProperty = (propertyId) => {
  return ReservationService.getProperty(propertyId);
};

const fetchPropertySchema = (propertyId) => {
  return ReservationService.getPropertySchema(propertyId);
};

const fetchVendor = (vendorId) => {
  return VendorsService.getVendorById(vendorId);
};

const formSchema = (commissionableTaxesAndFeesSchema) => ({
  type: 'object',
  properties: {
    commissionable_taxes_and_fees: commissionableTaxesAndFeesSchema,
  },
});

export default function PropertyCommissionableTaxesContentEditPage() {
  const { id_property: propertyId, id_vendor: vendorId } = useParams<{ id_vendor: string; id_property: string }>();

  const history = useHistory();

  const [property, setProperty] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [schema, setSchema] = useState(null);
  const [items, setItems] = useState([]);
  const [saveState, setSaveState] = useState(buttonStates.default);
  const [err, setErr] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const onChange = ({ formData }: IChangeEvent) => {
    setErr(null);
    setSaveState(buttonStates.default);
    setItems(formData.commissionable_taxes_and_fees);
  };

  const onSubmit = ({ formData }: IChangeEvent) => {
    setErr(null);
    const payload = {
      ...property,
      commissionable_taxes_and_fees: formData.commissionable_taxes_and_fees,
    };

    setSaveState(buttonStates.saving);
    ReservationService.updateProperty(payload, propertyId)
      .then(() => {
        history.push(`/vendors/${vendorId}/properties/${propertyId}`);
      })
      .catch((e) => {
        setSaveState(buttonStates.failed);
        setErr(e);
      });
  };

  useEffect(() => {
    Promise.all([fetchPropertySchema(propertyId), fetchProperty(propertyId), fetchVendor(vendorId)]).then(
      ([schemaData, propertyData, vendorData]) => {
        const schema = schemaData.put.body.schema;
        const property = propertyData.result;
        const vendor = vendorData.result;

        setProperty(cleanProperty(property));
        setItems(property.commissionable_taxes_and_fees);
        setSchema(formSchema(schema.properties.commissionable_taxes_and_fees));
        setVendor(vendor);

        setHasLoaded(true);
      },
    );
  }, [propertyId, vendorId]);

  const formData = {
    commissionable_taxes_and_fees: items,
  };

  return (
    <div className="container property-edit-form">
      <Helmet>
        <title>
          Vendors | {vendor?.name || vendorId} | {property?.name || propertyId} | Edit Non-Commissionable Taxes and Fees
        </title>
      </Helmet>

      <PageHeader
        title="Edit Property Non-Commissionable Taxes and Fees"
        backButton={`/vendors/${vendorId}/properties/${propertyId}`}
      />

      {!hasLoaded && <div>Loading...</div>}

      {hasLoaded && (
        <Form schema={schema} formData={formData} onChange={onChange} onSubmit={onSubmit} validator={validator}>
          <Button variant="contained" type="submit" disabled={saveState === buttonStates.saving}>
            {buttonMessages[saveState]}
          </Button>
        </Form>
      )}

      {!!err && <ErrorDisplay message={err.message} />}
    </div>
  );
}
