import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Grid, TextField } from '@mui/material';

import { OrderOffer } from '~/services/SearchService';

import { buildOrderListItems } from './helpers';

interface Props {
  onSubmit: (items: Array<Exclude<OrderOffer, null>>) => void;
  brand: App.Brands;
  region: string;
}

function OfferInput(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [input, setInput] = useState<string>('');

  const onSubmit = useCallback(async () => {
    const bkList = input
      .split(',')
      .filter(Boolean)
      .map((bk) => bk.trim());
    const listItems = await buildOrderListItems(bkList, props.brand, props.region);

    if (listItems.length < bkList.length) {
      enqueueSnackbar(
        'Some offers could be found so they were not added to the list. Please check your input and try again.',
        { variant: 'error' },
      );
    } else {
      setInput('');
    }

    props.onSubmit(listItems);
  }, [enqueueSnackbar, input, props]);

  return (
    <Grid container gap={2} alignItems="center">
      <Grid item xs>
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInput(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit();
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button type="submit" onClick={onSubmit} fullWidth variant="contained">
          Add Offers
        </Button>
      </Grid>
    </Grid>
  );
}

export default OfferInput;
