import React, { FormEventHandler } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';

interface Props {
  reason: CollectionReason;
  onSubmit: FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  defaultComment: string;
}

export default function RefundTestPurchase({ reason, onSubmit, decrementStep, defaultComment }: Props) {
  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <CaseIDAndReasons
          reason={reason}
          defaultComment={defaultComment}
          defaultCaseId="N/A"
          commentPlaceholder=""
          commentLabel="Name of agent processing refund"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained">
          Continue
        </Button>
      </DialogActions>
    </form>
  );
}
