import React from 'react';

import { useHistory } from 'react-router';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useCruiseShips } from '~/components/Cruises/context/useCruiseShips';

import * as ShipListItem from '../ShipListItem';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    width: 350,
    renderCell: (params) => ShipListItem.ShipName(params.value, params.row),
    display: 'flex',
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params) => ShipListItem.ShipName(params.value, params.row),
    display: 'flex',
  },
];

export const ShipList: React.FC = (): JSX.Element => {
  const { ships, page, total, shipsPerPage, paginate } = useCruiseShips();
  const history = useHistory();

  return (
    <DataGrid
      columns={columns}
      rows={ships ?? []}
      rowCount={total}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: shipsPerPage,
          },
        },
      }}
      pageSizeOptions={[shipsPerPage]}
      paginationModel={{ page: page - 1, pageSize: shipsPerPage }}
      onPaginationModelChange={({ page }) => paginate(page + 1)}
      onRowClick={(params) => history.push(`/cruises/ships/${params.row.id}`)}
      autoHeight
      disableColumnFilter
      disableColumnMenu
    />
  );
};
