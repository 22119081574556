import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Alert, Button } from '@mui/material';

import { queueXeroTrialBalance } from '~/services/ReportingService';
import { formatDateISO, startOfMonth, subMonths } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter your dates to export the trial balances report',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    group: {
      title: 'Group',
      type: 'string',
      enum: ['LE', 'LED', 'LP'],
    },
    fileFormat: {
      title: 'File Format',
      type: 'string',
      enum: ['email', 'csv'],
    },
  },
};

const formData = {
  startAt: formatDateISO(startOfMonth(subMonths(1))),
  endAt: formatDateISO(startOfMonth()),
  fileFormat: 'email',
  group: 'LE',
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  group: {
    'ui:widget': 'radio',
    'ui:options': {
      inline: true,
    },
  },
  fileFormat: {
    'ui:widget': 'radio',
    'ui:options': {
      inline: true,
    },
  },
};

export default function ExportXeroTrialBalanceForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt, fileFormat, group } = event.formData;
    const fileName = `xero_trial_balance_report_${startAt}_${endAt}_${group}.${fileFormat}`;

    queueXeroTrialBalance({ startAt, endAt, fileFormat, group })
      .then(function (text) {
        if (fileFormat != 'email') {
          fileDownload(text, fileName);
        } else {
          setSuccessMessage(true);
        }
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Xero Trial balance report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
