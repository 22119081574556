import { CabinCategoriesGroupById } from '~/services/cruises/CabinCategoriesGroupService';

export type SearchFilters = {
  id?: string;
  name?: string;
  cabinCategoryCode?: string;
  shipId?: string;
  shipName?: string;
  shipExternalId?: string;
  vendorId?: string;
};

export const SEARCH_FILTERS_DEFAULT: SearchFilters = {
  id: '',
  name: '',
  cabinCategoryCode: '',
  shipId: '',
  shipName: '',
  shipExternalId: '',
  vendorId: '',
};

export const DEFAULT_CABIN_CATEGORIES_GROUP_RESPONSE: CabinCategoriesGroupById = {
  id: '',
  name: '',
  description: '',
  ship: {
    id: '',
    name: '',
    externalId: undefined,
  },
  vendor: {
    id: '',
    name: '',
    code: '',
    externalId: undefined,
  },
  cabinCategories: [],
  images: [],
};

type CabinCategoriesGroupImage = {
  imageId: string;
  order?: number;
  fileName?: string;
};

export type SubmitParams = {
  id: string;
  name: string;
  description: string;
  cabinCategoriesIds: string[];
  images: CabinCategoriesGroupImage[];
};
