import React from 'react';

import groupBy from 'lodash/groupBy';
import { Link } from 'react-router-dom';

import TENANTS from '../../../consts/tenant';

import ScheduleHistory from './ScheduleHistory';

function formateDate(date_in) {
  let date = new Date(date_in);
  return date.toDateString();
}

export default class TimelinePane extends React.Component {
  constructor(props) {
    super(props);
    this.schedules = groupBy(this.props.schedules, (s) => s.brand);
  }

  schedulesFor(brand) {
    let schedules_index = { list_visibility: [] };
    let schedules = {
      list: [],
      country_list: [],
    };

    if (!this.schedules[brand]) {
      return schedules;
    }

    for (let schedule of this.schedules[brand]) {
      if (schedule.type == 'availability' || schedule.type == 'online_purchase') {
        schedules_index[schedule.type] = schedule;
      } else if (schedule.type == 'list_visibility') {
        schedules_index[schedule.type].push(schedule);
      }
    }

    // This elaborate charade is needed because we need to account for the fact that any given schedule may be missing
    if (schedules_index.availability) {
      schedules.list.push({
        title: 'Start Private Sales:',
        date: formateDate(schedules_index.availability.start),
      });
    }
    if (schedules_index.online_purchase) {
      schedules.list.push({
        title: 'Start Online Sales:',
        date: formateDate(schedules_index.online_purchase.start),
      });
      schedules.list.push({
        title: 'End Online Sales:',
        date: formateDate(schedules_index.online_purchase.end),
      });
    }
    if (schedules_index.availability) {
      schedules.list.push({
        title: 'End Private Sales:',
        date: formateDate(schedules_index.availability.end),
      });
    }
    for (let schedule of schedules_index.list_visibility) {
      schedules.country_list.push({
        title: schedule.region,
        dateFrom: formateDate(schedule.start),
        dateTo: formateDate(schedule.end),
      });
    }
    return schedules;
  }

  render() {
    const offerId = this.props.offer.result.id_salesforce_external;
    return (
      <span>
        {Object.keys(TENANTS).map((k) => {
          const schedules = this.schedulesFor(TENANTS[k].brand);
          return (
            <div className="panel-section" key={k}>
              <h2>
                <span>
                  {TENANTS[k].title}
                  <ScheduleHistory offerId={offerId} brand={TENANTS[k].brand} />
                </span>
                <Link to={'/edit-offers/' + offerId + `/schedules/${TENANTS[k].brand}`}>
                  <button type="button" className="btn btn-primary">
                    {schedules.list.length === 0 ? 'Add Schedule' : 'Edit Schedule'}
                  </button>
                </Link>
              </h2>
              <div className="timeline-wrapper">
                {schedules.list.map((schedule_string) => (
                  <dl className="timeline-item" key={schedule_string.title}>
                    <dt className="h6 text-uppercase text-muted">{schedule_string.title}</dt>
                    <dd>{schedule_string.date}</dd>
                  </dl>
                ))}
              </div>
              {schedules.country_list.length !== 0 && (
                <p className="h6 text-muted text-uppercase">Front-page visibility by country:</p>
              )}
              <div className="timeline-wrapper">
                {schedules.country_list.length !== 0 &&
                  schedules.country_list.map((schedule_string) => (
                    <dl key={schedule_string.title}>
                      <dt className="h5 text-capitalize">{schedule_string.title}</dt>
                      <dd>
                        {schedule_string.dateFrom} - {schedule_string.dateTo}
                      </dd>
                    </dl>
                  ))}
              </div>
            </div>
          );
        })}
      </span>
    );
  }
}
