import React, { Context, createContext, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { CruisesContract as Api } from '@luxuryescapes/contract-svc-cruise';

import shipService from '~/services/cruises/ShipService';

import { parseSearchString } from '~/utils/url';

interface Filter {
  name?: string;
  cruiseLine?: string;
}

interface CruisesData {
  ships: Api.Ship[];
  page: number;
  total: number;
  shipsPerPage: number;
  paginate: (page: number) => void;
  setFilter: (value: Filter) => void;
  filter: Filter;
}

interface CruiseShipProviderProps {
  children?: React.ReactNode;
}

export const CruiseShipContext: Context<CruisesData> = createContext({} as CruisesData);

const shipsPerPage = 15;

export const CruiseShipProvider = ({ children }: CruiseShipProviderProps): JSX.Element => {
  const [filter, setFilter] = useState<Filter>({});
  const { search } = useLocation();
  const { push: setQueryString } = useHistory();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [ships, setShips] = useState<Api.Ship[]>([]);

  const getShipsList = async () => {
    const skip = (page - 1) * shipsPerPage;
    const res = await shipService.listWithPagination({
      ...(filter.name && { name: filter.name }),
      ...(filter.cruiseLine && { cruiseLine: filter.cruiseLine }),
      skip,
      take: shipsPerPage,
    });
    setShips(res.result);
    setTotal(res.total);
  };

  const setCurrentPage = (): void => {
    const queryString = parseSearchString(search);
    if (queryString) {
      const pageToGo = parseInt(queryString.page as string);
      if (pageToGo) setPage(pageToGo);
    }
  };

  const paginate = (page: number): void => {
    const queryString = new URLSearchParams({
      page: page.toString(),
    }).toString();
    setQueryString({ search: queryString });
    setPage(page);
  };

  useEffect(() => {
    setCurrentPage();
  }, []);

  useEffect(() => {
    getShipsList();
  }, [page, filter]);

  return (
    <CruiseShipContext.Provider
      value={{
        filter,
        setFilter,
        ships,
        page,
        total,
        shipsPerPage,
        paginate,
      }}
    >
      {children}
    </CruiseShipContext.Provider>
  );
};

export const useCruiseShips = (): CruisesData => useContext(CruiseShipContext);
