import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Container, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import {
  OFFER_FACING_NAMES,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_TOUR,
} from '~/consts/offerTypes';

import { formatDateSlashes } from '~/services/TimeService';

import QuickSearchToolbar from '../../Common/Elements/QuickSearchToolbar';

interface Props {
  templates: App.OfferTemplate[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onApprove: (id: number) => void;
}

export default function TemplateEditDeleteContainer(props: Props) {
  const [templates, setTemplates] = useState<App.OfferTemplate[]>(props.templates);
  const [page, setPage] = useState(0);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'template_type',
      headerName: 'Type',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      display: 'flex',
    },
    {
      field: 'offer_type',
      headerName: 'Offer Type',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      valueFormatter(value) {
        if (value === 'all') {
          return value;
        }
        return OFFER_FACING_NAMES[value];
      },
      display: 'flex',
    },
    {
      field: 'updated_at',
      headerName: 'Date Modified',
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      minWidth: 130,
      valueFormatter(value) {
        return formatDateSlashes(value);
      },
      display: 'flex',
    },
    {
      field: 'templateActions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      // width: 125,
      flex: 1,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack direction={{ sm: 'column', md: 'row' }} spacing={2}>
            <Button onClick={() => props.onEdit(params.row.id)}>Edit</Button>
            <Button onClick={() => props.onDelete(params.row.id)} color="error">
              Delete
            </Button>
          </Stack>
        );
      },
      display: 'flex',
    },
    {
      field: 'templateApprove',
      headerName: 'Approved',
      sortable: false,
      disableColumnMenu: true,
      // width: 100,
      flex: 1,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Checkbox
            checked={params.row.status === 'approved'}
            onChange={() => props.onApprove(params.row.id)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
        );
      },
      display: 'flex',
    },
  ];

  //setting to an empty object could simplify
  //filterTemplates(), but would complicate filter(e)
  const [filters, setFilters] = useState({
    offer_type: '',
    title: '',
    template_type: '',
  });

  //set templates after update,
  //avoids having to GET
  useEffect(() => {
    setTemplates(props.templates);
  }, [props.templates]);

  useEffect(() => {
    setTemplates(filterTemplates());
  }, [filters]);

  /**Sets filters, which triggers filtration */
  function filter(e) {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  }

  function filterTemplates(): App.OfferTemplate[] {
    let passFilters = false;
    const filteredTemplates = props.templates.filter((template) => {
      for (const key in filters) {
        passFilters = checkPassFilter(
          filters[key].toLowerCase(),
          template[key].toLowerCase(),
          key !== 'title' ? true : false,
        );
        if (!passFilters) break;
      }
      if (passFilters) return template;
    });

    return filteredTemplates;
  }

  function checkPassFilter(rule: string, test: string, strictMatch: boolean): boolean {
    //if there is no rule, then it passes
    if (rule === '') {
      return true;
    }

    if (strictMatch) {
      return test === rule;
    }
    //partial match
    return test.includes(rule);
  }

  return (
    <Container maxWidth="xl">
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id="template-type-label">Template Type</InputLabel>
        <Select
          id="template_type"
          labelId="template-type-label"
          label="Template Type"
          name="template_type"
          onChange={filter}
          value={filters.template_type}
        >
          <MenuItem value={''}></MenuItem>
          <MenuItem value={'location'}>Location</MenuItem>
          <MenuItem value={'fine_print'}>Fine print</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id="offer-type-label">Offer Type</InputLabel>
        <Select
          id="offer_type"
          labelId="offe-type-label"
          label="Offer Type"
          name="offer_type"
          onChange={filter}
          value={filters.offer_type}
        >
          <MenuItem value={''}></MenuItem>
          <MenuItem value={OFFER_TYPE_HOTEL}>{OFFER_FACING_NAMES[OFFER_TYPE_HOTEL]}</MenuItem>
          <MenuItem value={OFFER_TYPE_TOUR}>{OFFER_FACING_NAMES[OFFER_TYPE_TOUR]}</MenuItem>
          <MenuItem value={OFFER_TYPE_LAST_MINUTE_HOTEL}>{OFFER_FACING_NAMES[OFFER_TYPE_LAST_MINUTE_HOTEL]}</MenuItem>
          <MenuItem value={OFFER_TYPE_TACTICAL_AO_HOTEL}>{OFFER_FACING_NAMES[OFFER_TYPE_TACTICAL_AO_HOTEL]}</MenuItem>
          <MenuItem value={'all'}>All offer types</MenuItem>
        </Select>
      </FormControl>
      <DataGrid
        className="T-offer-templates-table"
        rows={templates || []}
        columns={columns}
        pageSizeOptions={[10]}
        paginationModel={{ pageSize: 10, page }}
        onPaginationModelChange={({ page }) => setPage(page)}
        getRowId={(row: App.OfferTemplate) => row.id}
        rowCount={templates.length}
        getRowHeight={() => 'auto'}
        slots={{
          pagination: GridPagination,
          toolbar: QuickSearchToolbar,
        }}
        autoHeight // dangerous if number of rows per page > 25
        sx={{ mt: 2 }}
      />
    </Container>
  );
}
