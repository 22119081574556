import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

import { formatDateISO } from '~/services/TimeService';

import { PromotionFormValues } from '../types';

const NUMBER_FIELDS = ['depositAmount', 'onBoardCreditAmount'];

const INITIAL_FORM_STATE: PromotionFormValues = {
  promotionName: '',
  promotionStartDate: null,
  promotionEndDate: null,
  promotionSellingPoint1: '',
  promotionSellingPoint2: '',
  promotionSellingPoint3: '',
  leExclusive: false,
  depositAmount: 0,
  depositType: 'VALUE',
  depositCurrency: 'AUD',
  onBoardCreditAmount: 0,
  onBoardCreditCurrency: 'AUD',
};

type Props = {
  promoFormValues?: PromotionFormValues;
  onSubmit: (formValues: PromotionFormValues) => void;
};

const PromotionForm = (props: Props): JSX.Element => {
  const { promoFormValues, onSubmit } = props;

  const [formValues, setFormValues] = useState<PromotionFormValues>(INITIAL_FORM_STATE);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target?.name) {
        if (NUMBER_FIELDS.includes(event.target.name)) {
          setFormValues({
            ...formValues,
            [event.target.name]: !event.target.value ? undefined : Number(parseFloat(event.target.value).toFixed(2)),
          });
        } else {
          setFormValues({
            ...formValues,
            [event.target.name]: event.target.value ?? undefined,
          });
        }
      }
    },
    [formValues],
  );

  const handleChangeDateField = useCallback(
    (value: string, name: 'promotionStartDate' | 'promotionEndDate') => {
      setFormValues({ ...formValues, [name]: formatDateISO(value) });
    },
    [formValues],
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      if (event.target) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
        });
      }
    },
    [formValues],
  );

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (event.target.name) {
        setFormValues({ ...formValues, [event.target.name]: checked });
      }
    },
    [formValues],
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      promotionName: formValues.promotionName,
      promotionStartDate: formValues.promotionStartDate,
      promotionEndDate: formValues.promotionEndDate,
      promotionSellingPoint1: formValues.promotionSellingPoint1,
      promotionSellingPoint2: formValues.promotionSellingPoint2,
      promotionSellingPoint3: formValues.promotionSellingPoint3,
      leExclusive: formValues.leExclusive,
      depositType: formValues.depositType,
      depositAmount: formValues.depositAmount,
      depositCurrency: formValues.depositCurrency,
      onBoardCreditAmount: formValues.onBoardCreditAmount,
      onBoardCreditCurrency: formValues.onBoardCreditCurrency,
    });
  }, [formValues, onSubmit]);

  useEffect(() => {
    if (promoFormValues) setFormValues(promoFormValues);
  }, [promoFormValues]);

  return (
    <Box>
      <Box mt={2}>
        <TextField
          required
          fullWidth
          name="promotionName"
          label="Promotion Name"
          onChange={handleChangeField}
          inputProps={{ maxLength: 50 }}
          value={formValues.promotionName}
          helperText="The maximum length is 50 characters."
        />
      </Box>

      <Stack mt={2} spacing={2} direction="row">
        <DateTimeWidget
          disablePast
          showDateOnly
          format="YYYY/MM/DD"
          label="Promotion Start Date *"
          value={formValues.promotionStartDate}
          onChange={(value) => handleChangeDateField(value, 'promotionStartDate')}
        />

        <DateTimeWidget
          disablePast
          showDateOnly
          format="YYYY/MM/DD"
          label="Promotion End Date *"
          value={formValues.promotionEndDate}
          onChange={(value) => handleChangeDateField(value, 'promotionEndDate')}
        />
      </Stack>

      <Stack direction="row" spacing={2} mt={2}>
        <TextField
          required
          fullWidth
          onChange={handleChangeField}
          inputProps={{ maxLength: 50 }}
          value={formValues.promotionSellingPoint1}
          name="promotionSellingPoint1"
          label="Promotion Selling Point 1"
          helperText="The maximum length is 50 characters."
        />

        <TextField
          fullWidth
          onChange={handleChangeField}
          inputProps={{ maxLength: 50 }}
          value={formValues.promotionSellingPoint2}
          name="promotionSellingPoint2"
          label="Promotion Selling Point 2"
          helperText="The maximum length is 50 characters."
        />

        <TextField
          fullWidth
          onChange={handleChangeField}
          inputProps={{ maxLength: 50 }}
          value={formValues.promotionSellingPoint3}
          name="promotionSellingPoint3"
          label="Promotion Selling Point 3"
          helperText="The maximum length is 50 characters."
        />
      </Stack>

      <Stack direction="row" spacing={2} mt={4}>
        <FormControl fullWidth>
          <InputLabel id="depositType">Deposit Type</InputLabel>
          <Select
            id="depositType"
            name="depositType"
            label="Deposit Type"
            labelId="depositType"
            onChange={handleSelectChange}
            value={formValues.depositType}
          >
            <MenuItem value="VALUE">VALUE</MenuItem>
            <MenuItem value="PERCENTAGE">PERCENTAGE</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="depositCurrency">Deposit Currency</InputLabel>
          <Select
            id="depositCurrency"
            name="depositCurrency"
            label="Deposit Currency"
            labelId="depositCurrency"
            onChange={handleSelectChange}
            value={formValues.depositCurrency}
          >
            <MenuItem value="AUD">AUD</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          type="number"
          name="depositAmount"
          label="Deposit Amount"
          inputProps={{ step: '1' }}
          onChange={handleChangeField}
          value={formValues.depositAmount}
        />
      </Stack>

      <Stack direction="row" spacing={2} mt={4}>
        <FormControl fullWidth>
          <InputLabel id="onBoardCreditCurrency">OBC Currency</InputLabel>
          <Select
            label="OBC Currency"
            id="onBoardCreditCurrency"
            name="onBoardCreditCurrency"
            labelId="onBoardCreditCurrency"
            onChange={handleSelectChange}
            value={formValues.onBoardCreditCurrency}
          >
            <MenuItem value="AUD">AUD</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          type="number"
          label="OBC Amount"
          name="onBoardCreditAmount"
          inputProps={{ step: '1' }}
          onChange={handleChangeField}
          value={formValues.onBoardCreditAmount}
        />
      </Stack>

      <Box mt={2}>
        <FormControlLabel
          label="LE Exclusive?"
          control={
            <Checkbox
              name="leExclusive"
              onChange={handleCheck}
              checked={formValues.leExclusive}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
      </Box>

      <Box mt={2}>
        <Button variant="contained" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default PromotionForm;
