import React, { useMemo } from 'react';

import { connect } from 'react-redux';

import { List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';

import { customerPortalHost } from '~/services/common';

interface PreviewLink {
  href: string;
  label: string;
}

interface MappedStateProps {
  tenant?: App.Tenant;
}

interface Props {
  tourId: string;
  tourTitle: string;
  vendorId?: string;
}

function TourDetailsLinksList(props: Props & MappedStateProps) {
  const { tenant, tourId, tourTitle, vendorId } = props;

  const customerPreviewLinks = useMemo<PreviewLink[]>(() => {
    const links: PreviewLink[] = [];

    if (tourId) {
      links.push({
        href: `${customerPortalHost(tenant)}/tour/tour-${tourId}`,
        label: tenant.title,
      });
    }

    return links;
  }, [tenant, tourId]);

  const vendorPreviewLinks = useMemo<PreviewLink[]>(() => {
    const links: PreviewLink[] = [];

    if (vendorId) {
      links.push({
        href: `/vendors/${vendorId}`,
        label: vendorId,
      });
    }

    return links;
  }, [vendorId]);

  return (
    <List>
      <ListSubheader disableSticky sx={{ lineHeight: 1.4 }}>
        {tourTitle}
      </ListSubheader>
      {customerPreviewLinks?.length > 0 && (
        <>
          <ListSubheader disableSticky color="primary">
            Preview on:
          </ListSubheader>
          {customerPreviewLinks.map((link) => (
            <ListItemButton key={link.href} href={link.href} target="_blank" rel="noreferrer">
              <ListItemText primary={link.label} />
            </ListItemButton>
          ))}
        </>
      )}

      {vendorPreviewLinks?.length > 0 && (
        <>
          <ListSubheader disableSticky color="primary">
            Vendor:
          </ListSubheader>
          {vendorPreviewLinks.map((link) => (
            <ListItemButton key={link.href} href={link.href} target="_blank" rel="noreferrer">
              <ListItemText primary={link.label} />
            </ListItemButton>
          ))}
        </>
      )}
    </List>
  );
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => ({
  tenant: state.tenant,
}))(TourDetailsLinksList);
