import React from 'react';

import { useSnackbar } from 'notistack';

import { Box, ImageList, ImageListItem } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid/models/gridRows';

import Image from '~/components/Common/Image';

import BedbankService from '~/services/BedbankService';

type Props = {
  row: GridRowModel;
  onOpenModalImage: (id: string) => void;
  updateImageData: (data: any, id: string) => void;
};

export default function BedbankRowExpansionContent({ row, onOpenModalImage, updateImageData }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  let images = [];
  let cycle = null;

  const toggleImageShowHide = (id, currentValue) => {
    // allow for batching if image within a short window (1.2s)
    images.push({
      imageServiceId: id,
      hidden: !currentValue,
    });

    if (cycle === null) {
      cycle = setTimeout(processUpdates, 1200);
    }
  };

  const processUpdates = () => {
    BedbankService.patchShowHideImages(row.id, { images })
      .then((data) => {
        images = [];
        cycle = null;
        updateImageData(data.result, row.id);
      })
      .catch(() => {
        enqueueSnackbar('Could not process image updates:', { variant: 'error' });
        images = [];
        cycle = null;
      });
  };

  return (
    <ImageList sx={{ width: '100%', height: '100%' }} cols={10}>
      {row.images.map((image, index) => (
        <ImageListItem key={index} onClick={() => onOpenModalImage(image.id)}>
          <Box
            className={`show-hide-image ${image.hidden ? 'image-hidden' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              toggleImageShowHide(image.id, image.hidden);
            }}
            key={index}
            sx={{
              position: 'absolute',
              top: '0.5rem',
              right: '0.5rem',
              cursor: 'pointer',
              zIndex: 1,
            }}
            title={image.hidden ? 'Show Image' : 'Hide Image'}
          >
            <i className={`fa ${image.hidden ? 'fa-eye-slash' : 'fa-eye'}`}></i>
          </Box>
          <Image
            className={`img-responsive ${image.hidden ? 'image-hidden' : ''}`}
            publicId={image.id}
            options={{ width: 170 }}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
