import React, { useState } from 'react';

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers-pro';

interface Props {
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
}

function DualDatePicker(props: Props) {
  const { setStartDate, setEndDate } = props;

  const [isStartDateCalendarOpen, setIsStartDateCalendarOpen] = useState(false);
  const [isEndDateCalendarOpen, setIsEndDateCalendarOpen] = useState(false);

  const handleStartDateChange = (date) => {
    const dateString = date.toISOString().split('T')[0];
    setStartDate(dateString);
  };

  const handleEndDateChange = (date) => {
    const dateString = date.toISOString().split('T')[0];
    setEndDate(dateString);
  };

  return (
    <div>
      <MUIDatePicker
        open={isStartDateCalendarOpen}
        onChange={handleStartDateChange}
        format="YYYY-MM-DD"
        label="Start Date"
        onClose={() => {
          setIsStartDateCalendarOpen(false);
        }}
        onOpen={() => {
          setIsStartDateCalendarOpen(true);
        }}
      />

      <MUIDatePicker
        open={isEndDateCalendarOpen}
        onChange={handleEndDateChange}
        format="YYYY-MM-DD"
        label="End Date"
        onClose={() => {
          setIsEndDateCalendarOpen(false);
        }}
        onOpen={() => {
          setIsEndDateCalendarOpen(true);
        }}
      />
    </div>
  );
}

export default DualDatePicker;
