import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Container, IconButton } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  getGridStringOperators,
} from '@mui/x-data-grid';

import PageHeader from '~/components/Common/Elements/PageHeader';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';
import OfferSearchWidget from '~/components/Marketing/CompedPackages/OfferSearchWidget';

import InclusionsService from '~/services/InclusionsService';
import { formatDateSlashes } from '~/services/TimeService';

import isUUID from '~/utils/isUUID';

import { InclusionsAssetForm } from '../../Common/Forms/InclusionsAssetForm/InclusionsAssetForm';

const containsOnlyFilter = getGridStringOperators().filter((operator) => operator.value === 'contains');
const equalsOnlyFilter = getGridStringOperators().filter((operator) => operator.value === 'equals');

const mapTypeToLabel = (type) => {
  if (type === 'Flash') {
    return 'LTLE';
  }

  return type;
};

const columns: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'id',
    headerName: 'ID',
    filterOperators: equalsOnlyFilter,
    flex: 1,
  },
  {
    disableColumnMenu: true,
    field: 'name',
    headerName: 'Name',
    filterOperators: containsOnlyFilter,
    flex: 6,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'type',
    filterable: false,
    headerName: 'Type',
    valueGetter: (_value, row) => mapTypeToLabel(row.type),
    flex: 1,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'category',
    filterOperators: containsOnlyFilter,
    headerName: 'Category',
    valueGetter: (_value, row) => row.category?.name,
    flex: 4,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'unit',
    filterable: false,
    headerName: 'Unit',
    flex: 2,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'rrp',
    filterable: false,
    headerName: 'RRP',
    flex: 1,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'currency',
    filterable: false,
    headerName: 'Currency',
    flex: 2,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'offers',
    filterable: false,
    headerName: 'Offers',
    valueGetter: (_value, row) => new Set(row.packages.map((pkg) => pkg.offer_id)).size,
    flex: 2,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'packages',
    filterable: false,
    headerName: 'Packages',
    valueGetter: (_value, row) => row.packages?.length,
    flex: 2,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'is_hidden',
    filterable: false,
    headerName: 'Hidden',
    type: 'boolean',
    flex: 2,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'last_validated_on',
    filterable: false,
    headerName: 'Validated',
    valueGetter: (_value, row) => (row.last_validated_on ? formatDateSlashes(row.last_validated_on) : ''),
    flex: 2,
    display: 'flex',
  },
  {
    field: 'created_at',
    filterable: false,
    headerName: 'Created',
    valueGetter: (_value, row) => (row.created_at ? formatDateSlashes(row.created_at) : ''),
    width: 120,
    display: 'flex',
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default function VendorInclusionsPage() {
  const { id_vendor: vendorId } = useParams<{ id_vendor: string }>();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const queryInclusionId = params.get('inclusionId');

  const showConfirmDialog = useConfirmDialog();
  const { enqueueSnackbar } = useSnackbar();

  const [inclusions, setInclusions] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState();
  const [filter, setFilter] = useState<{ field: string; query: string }>(null);
  const [sortModel, setSortModel] = useState<{ field: string; sort: string }>({ field: 'created_at', sort: 'desc' });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const [total, setTotal] = useState(0);
  const [selectedInclusionId, setEditInclusionId] = useState(null);
  const [inclusionAssetDialog, setInclusionAssetDialog] = useState(false);
  const [selectedInclusionsIds, setSelectedInclusionIds] = useState<GridRowSelectionModel>([]);

  const handleOfferSearchChange = (_, value) => {
    setSelectedOfferId(value);
  };

  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    if (newFilterModel?.items?.[0].field === 'id' && !isUUID(newFilterModel?.items?.[0].value)) {
      enqueueSnackbar('Inclusion ID must be a valid UUID', { variant: 'error' });
      setFilter(null);
      return;
    }
    // newFilterModel.items will always have 1 item because non-PRO datagrid has only 1 filter at the same time
    setFilter({
      field: newFilterModel?.items?.[0].field,
      query: newFilterModel?.items?.[0].value,
    });
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    // sortModel will always have 1 item because non-PRO datagrid has only 1 sort at the same time
    setSortModel({
      field: sortModel?.[0]?.field,
      sort: sortModel?.[0]?.sort,
    });
  };

  const fetchData = useCallback(
    () =>
      InclusionsService.getInclusions(vendorId, selectedOfferId, paginationModel, filter, sortModel).then(
        (response) => {
          setInclusions(response.result);
          setTotal(response.count);

          const inclusion = response.result.find((inclusion) => inclusion.id === queryInclusionId);

          if (inclusion) {
            inclusionAssetDialogOpen(inclusion);
          }
        },
      ),
    [selectedOfferId, vendorId, paginationModel, filter, queryInclusionId, sortModel],
  );

  const editInclusionAsset: GridEventListener<'rowClick'> = (params) => {
    inclusionAssetDialogOpen(params.row);
  };

  const inclusionAssetDialogClose = (isSaved = false) => {
    if (queryInclusionId) {
      params.delete('inclusionId');
      history.replace({
        search: params.toString(),
      });
    }
    setEditInclusionId(null);
    setInclusionAssetDialog(false);
    if (isSaved) fetchData();
  };

  const inclusionAssetDialogOpen = (inclusion = null) => {
    setEditInclusionId(inclusion?.id);
    setInclusionAssetDialog(true);
  };

  const handleBulkUnlinkPackagesClick = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to continue?',
      description: 'This will remove links to all packages for all selected inclusions.',
    });

    if (confirmed) {
      try {
        await InclusionsService.unlinkPackagesByInclusionIds({ inclusionIds: selectedInclusionsIds });

        enqueueSnackbar('Packages unlinked successfully', { variant: 'success' });
        setSelectedInclusionIds([]);
        fetchData();
      } catch (error) {
        enqueueSnackbar(`Error unlinking inclusion packages: ${error.message}`, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth="xl">
      <Link to={`/vendors/${vendorId}/`}>
        <IconButton size="small" color="primary" aria-label="Return to offer">
          <ChevronLeft /> Return to vendor
        </IconButton>
      </Link>
      <PageHeader title="Inclusions">
        <Button
          disabled={selectedInclusionsIds.length === 0}
          sx={{ mr: 2 }}
          variant="contained"
          onClick={handleBulkUnlinkPackagesClick}
        >
          Unlink all packages
        </Button>
        <Button variant="contained" onClick={inclusionAssetDialogOpen}>
          Create custom Inclusion
        </Button>
      </PageHeader>
      <OfferSearchWidget
        value={selectedOfferId}
        formErrors={null}
        onChange={handleOfferSearchChange}
        placeholder="Enter a flash offer name or ID"
      />

      <Box mt={2}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          autoHeight
          checkboxSelection
          columns={columns}
          density="compact"
          disableRowSelectionOnClick
          filterMode="server"
          hideFooterSelectedRowCount
          isRowSelectable={(params: GridRowParams) => params.row.packages?.length > 0}
          onFilterModelChange={handleFilterModelChange}
          onPaginationModelChange={setPaginationModel}
          onRowClick={editInclusionAsset}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={[10, 25, 50]}
          paginationMode="server"
          paginationModel={paginationModel}
          onRowSelectionModelChange={(newSelection) => setSelectedInclusionIds(newSelection)}
          rowSelectionModel={selectedInclusionsIds}
          rowCount={total}
          rows={inclusions}
          slots={{ toolbar: CustomToolbar }}
          sortingMode="server"
        />
      </Box>

      {inclusionAssetDialog && (
        <InclusionsAssetForm
          fetchData={fetchData}
          handleClose={inclusionAssetDialogClose}
          inclusion={inclusions.find((inclusion) => inclusion.id === selectedInclusionId)}
          isOpen={inclusionAssetDialog}
          paginationModel={paginationModel}
          selectedOfferId={selectedOfferId}
          vendorId={vendorId}
        />
      )}
    </Container>
  );
}
