import React from 'react';

import { Alert } from '@mui/material';

const ErrorMessage = ({ message }) => {
  return (
    <Alert severity="error">
      <strong>Oops!</strong>
      <p>File upload failed</p>
      <pre>{message}</pre>
    </Alert>
  );
};

export default ErrorMessage;
