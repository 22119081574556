import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { Action, State, createExperiencesReducer, initialValues } from '~/reducers/experiences';

import { Alert, Box, Container, Stack, Tab, Tabs } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import { ContentTab, useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { EXPERIENCE_CONTENT_TAB, EXP_PROVIDER_REZDY, ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

import {
  CategoryItem,
  CurateExperienceParams,
  CurationFeatureManager,
  ExperienceOffer,
  curationExperience,
  getCurationFeatureManager,
  getOriginalOffer,
  mapOfferTickets,
} from '~/services/ExperiencesService';

import { handleImagesPayload } from '../helpers';

import { ExperienceItemContent } from './Content';

interface Props {
  tenant: App.Tenant;
  experience: ExperienceOffer;
  categories: CategoryItem[];
}

export const ExperiencesOverview: React.FC<Props> = (props) => {
  const { experience, tenant, categories } = props;

  const { payload, contentTab, handleContentTab, handleOriginalExperienceValues } = useUpdateExperienceValues();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [originalExperience, setOriginalExperience] = useState<ExperienceOffer | null>(null);

  const [curationFeatures, setCurationFeatures] = useState<CurationFeatureManager | undefined>(null);

  useEffect(() => {
    return () => {
      setError('');
      setLoading(false);
      setOriginalExperience(null);
    };
  }, []);

  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(createExperiencesReducer, initialValues);

  const getOriginalExperience = useCallback(
    async (experienceId: string) => {
      setLoading(true);
      try {
        const { result } = await getOriginalOffer(experienceId, tenant.brand);
        handleOriginalExperienceValues(result);
        setOriginalExperience(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [handleOriginalExperienceValues, tenant.brand],
  );

  const getCurationFeatures = useCallback(async (experienceId: string) => {
    setLoading(true);
    try {
      const { result } = await getCurationFeatureManager(experienceId);
      setCurationFeatures(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (experience) {
      getCurationFeatures(experience.id);
    }
  }, [experience, getCurationFeatures]);

  const createExperience = async ({ curationStatus }: CurateExperienceParams) => {
    dispatch({ type: 'LOAD' });

    const images = await handleImagesPayload(payload, experience);
    const tickets = await mapOfferTickets(payload?.tickets);

    try {
      const res = await curationExperience(experience.id, tenant.brand, {
        curationStatus,
        ...payload,
        images,
        tickets,
      });

      if (!res) {
        dispatch({
          type: 'ERROR',
          message: "[ERROR]: Don't exists response (create experiences).",
        });
      } else {
        dispatch({ type: 'SUCCESS', message: 'Request with success!' });
      }
      window.location.reload();
    } catch (error) {
      console.error('ERROR ON CREATE EXPERIENCE: ', error);
      dispatch({
        type: 'ERROR',
        message: `${error.status}: ${error.message}`,
      });
    }
  };

  const isRezdy = experience?.provider === EXP_PROVIDER_REZDY;

  const getTab = (selectedTab: ContentTab) => {
    if (selectedTab === ORIGINAL_EXPERIENCE_CONTENT_TAB && !originalExperience) {
      getOriginalExperience(experience.id);
    }
    handleContentTab(selectedTab);
  };

  return (
    <Container maxWidth="xl">
      {isRezdy ? (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row' }}>
            <Tabs value={contentTab} onChange={(_, value) => getTab(value)} id="experience-offer-tabs">
              <Tab label="Experience" value={EXPERIENCE_CONTENT_TAB} />
              <Tab label="Original Content" value={ORIGINAL_EXPERIENCE_CONTENT_TAB} />
            </Tabs>
          </Box>

          <Box mt={2}>
            {contentTab === EXPERIENCE_CONTENT_TAB && (
              <ExperienceItemContent
                experience={experience}
                creatingExperience={state.loading}
                createExperience={createExperience}
                curationFeatures={curationFeatures}
                categories={categories}
                tenant={tenant}
              />
            )}
            {contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB && (
              <>
                {loading && (
                  <Stack direction="row" alignItems="center" justifyContent="center">
                    <Spinner size={32} />
                  </Stack>
                )}
                {error && (
                  <Box>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
                {originalExperience && (
                  <ExperienceItemContent
                    experience={originalExperience}
                    creatingExperience={state.loading}
                    createExperience={createExperience}
                    categories={categories}
                    curationFeatures={curationFeatures}
                    tenant={tenant}
                  />
                )}
              </>
            )}
          </Box>
        </>
      ) : (
        <ExperienceItemContent
          experience={experience}
          creatingExperience={state.loading}
          createExperience={createExperience}
          categories={categories}
          curationFeatures={curationFeatures}
          tenant={tenant}
        />
      )}

      <Box>
        {(state.error || state.success) && <Alert severity={state.error ? 'error' : 'success'}>{state.message}</Alert>}
      </Box>

      {state.loading && (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Spinner size={32} />
        </Stack>
      )}
    </Container>
  );
};
