import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { Container, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import TourOptionDatesEdit from './TourOptionDatesEdit';

export default function TourOptionDatesEditContainer() {
  const {
    id_vendor: vendorId,
    id_tour: tourId,
    id_tour_option: tourOptionId,
  } = useParams<{ id_vendor: string; id_tour: string; id_tour_option: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState<App.Vendor | null>(null);
  const [tourOptionName, setTourOptionName] = useState<string>('');
  const [tourOptionDates, setTourOptionDates] = useState<App.TourOptionDate[]>([]);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getTourOptionDates(tourId, tourOptionId),
      ReservationService.getTourOption(tourId, tourOptionId),
      VendorsService.getVendorById(vendorId),
    ])
      .then(([tourOptionDates, tourOption, vendor]) => {
        setTourOptionDates(tourOptionDates.result.dates);
        setTourOptionName(tourOption.result.name);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [tourId, vendorId, tourOptionId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load tour or vendor.</Typography>
      </Container>
    );
  }

  return (
    <TourOptionDatesEdit
      tourOptionDates={tourOptionDates}
      vendorId={vendorId}
      vendor={vendor}
      tourId={tourId}
      tourOptionId={tourOptionId}
      tourOptionName={tourOptionName}
    />
  );
}
