// A hotel reservation can be either 'instant booking' or 'buy now book later'
export const RESERVATION_TYPE_INSTANT_BOOKING = 'instant_booking';
export const RESERVATION_TYPE_BOOK_LATER = 'buy_now_book_later';

export const REASON_FOR_THE_WAIVED_SURCHARGE = 'Reason for the waived surcharge';

export const PROPERTY_CHANNEL_MANAGERS = {
  selfManaged: 'self-managed',
  siteminder: 'siteminder',
  travelclick: 'travelclick',
  synxis: 'synxis',
  derbysoft: 'derbysoft',
  ratedock: 'ratedock',
  rentalsunited: 'rentalsunited',
  primalres: 'primalres',
};
