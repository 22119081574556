import React from 'react';

import { Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';

import { TypeaheadPriority, TypeaheadType } from '~/services/SearchService';

type TypeaheadTypeSelection = Record<TypeaheadType, boolean>;

interface Props {
  setTypes: (types: TypeaheadTypeSelection) => void;
  setPriority: (priority: TypeaheadPriority) => void;
  types: TypeaheadTypeSelection;
  priority: TypeaheadPriority;
}

const PlaceSearchFormControls = (props: Props) => {
  const { priority, setPriority, types, setTypes } = props;

  return (
    <>
      <FormGroup>
        <FormLabel id="search-form-typeahead-priority">Priority Formula</FormLabel>
        <RadioGroup
          row
          aria-labelledby="search-form-typeahead-priority"
          name="row-radio-buttons-group"
          sx={{ alignItems: 'center' }}
        >
          <FormControlLabel
            value="current"
            control={<Radio checked={priority === 'current'} onChange={() => setPriority('current')} />}
            label="current"
          />

          <FormControlLabel
            value="next"
            control={<Radio checked={priority === 'next'} onChange={() => setPriority('next')} />}
            label="next"
          />
        </RadioGroup>
        <FormLabel id="search-form-typeahead-types">Types</FormLabel>
        {Object.keys(types).map((type) => (
          <FormGroup key={type}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setTypes({ ...types, [type]: !types[type] })}
                  defaultChecked={types[type]}
                  name={type}
                />
              }
              label={type}
            />
          </FormGroup>
        ))}
      </FormGroup>
    </>
  );
};

export default PlaceSearchFormControls;
