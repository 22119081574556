import React from 'react';

import { useLocation } from 'react-router';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import offerService from '../../../services/cruises/OfferService';

interface CruiseOfferByIdData {
  offer: API.Offer;
}

interface CruiseOfferByIdProviderProps {
  children?: React.ReactNode;
}

export const CruiseOfferByIdContext: React.Context<CruiseOfferByIdData> = React.createContext(
  {} as CruiseOfferByIdData,
);

export const CruiseOfferByIdProvider = ({ children }: CruiseOfferByIdProviderProps): JSX.Element => {
  const { pathname } = useLocation();
  const [offer, setOffer] = React.useState<API.Offer>(null);
  const offerId = React.useMemo(() => pathname.split('/').pop(), [pathname]);

  const getOfferById = async (id: string) => {
    const { result } = await offerService.getById(id);
    setOffer(result);
  };

  React.useEffect(() => {
    getOfferById(offerId);
  }, [offerId]);

  return <CruiseOfferByIdContext.Provider value={{ offer }}>{children}</CruiseOfferByIdContext.Provider>;
};

export const useCruiseOfferById = (): CruiseOfferByIdData => React.useContext(CruiseOfferByIdContext);
