import React, { Component } from 'react';

import { Button, Stack, TextField } from '@mui/material';

import { withTenant } from '~/components/hoc';

import { getPromo, getReferralPromo } from '~/services/PromoService';

import getRegionByCurrencyCode from '~/utils/getRegionByCurrencyCode';

import ErrorDisplay from '../ErrorDisplay';

class PromoPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promoCode: '',
    };

    this.handlePromoCodeChange = this.handlePromoCodeChange.bind(this);
    this.handleApplyPromoCode = this.handleApplyPromoCode.bind(this);
  }

  couldBeReferralCode = (input) => input.slice(0, 6) === 'INVITE';

  handlePromoCodeChange(e) {
    const value = e.target.value;

    this.setState({ promoCode: value });
  }

  async handleApplyPromoCode(e) {
    e.preventDefault();
    const { onPromoCodeApply, onPromoCodeError, tenant, brand, customerId, currencyCode, cartValue } = this.props;
    const { promoCode } = this.state;
    const regionCode = getRegionByCurrencyCode(currencyCode).code;
    let response;
    let promoPackage;

    if (!promoCode) {
      return;
    }

    try {
      if (this.couldBeReferralCode(promoCode)) {
        response = await getReferralPromo({
          codeName: promoCode,
          regionCode,
          customerId,
          brand,
          cartValue,
        });

        if (response.result) {
          promoPackage = response.result;
        }
      }

      if (!promoPackage) {
        response = await getPromo({
          code_name: promoCode,
          brand: tenant.brand,
          user_id: customerId,
        });

        if (response.result !== null) {
          promoPackage = response.result;
        }
      }

      if (!promoPackage) {
        onPromoCodeError('This promo code does not exist');
      }

      onPromoCodeApply(promoPackage, Date.now());
    } catch (err) {
      if (err.status === 410) {
        onPromoCodeError('Promo code limit reached');
      } else if (err.status === 400) {
        onPromoCodeError('This promo code does not exist');
      } else {
        onPromoCodeError(err.message);
      }
    }
  }

  render() {
    const { error, disabled } = this.props;

    return (
      <div>
        {error && <ErrorDisplay message={`Error applying the promo code: ${error}`} />}

        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            onChange={this.handlePromoCodeChange}
            value={this.state.promoCode}
            label="Promo Code"
            className="data-hj-whitelist"
            sx={{ width: 300 }}
          />

          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.handleApplyPromoCode}
              disabled={disabled}
            >
              Apply Promo Code
            </Button>
          </div>
        </Stack>
      </div>
    );
  }
}

export default withTenant(PromoPayment);
