import {
  ADD_BADGE_TO_SELECTED,
  DELETE_BADGE_SUCCEED,
  FAIL_API_CALL,
  FETCH_BADGES_SUCCEED,
  FETCH_BADGE_DETAILS_SUCCEED,
  PATCH_BADGE_DETAILS_SUCCEED,
  POST_BADGE_SUCCEED,
  REMOVE_BADGE_FROM_SELECTED,
  START_API_CALL,
} from '../actions/badges';

const initialState: App.BadgesState = {
  items: [],
  total: 0,
  badge: {} as App.Badge,
  selected: [],
  isLoading: true,
  isUpdating: false,
  error: {},
  success: false,
};

export default function (state = initialState, action): App.BadgesState {
  switch (action.type) {
    case START_API_CALL: {
      const newState = { ...state };
      delete newState.error;
      delete newState.success;
      return {
        ...newState,
        isLoading: true,
      };
    }
    case FAIL_API_CALL: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case FETCH_BADGES_SUCCEED: {
      const newBadges = (action.badgesPayload && action.badgesPayload.badges) || [];
      const total = (action.badgesPayload && action.badgesPayload.total) || 0;
      return {
        ...state,
        isLoading: false,
        items: Array.of(...newBadges),
        total: total,
      };
    }
    case FETCH_BADGE_DETAILS_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        badge: Object.assign({}, action.badge),
      };
    }
    case PATCH_BADGE_DETAILS_SUCCEED: {
      const filteredItems = state.items.filter((badge) => badge.id !== action.badge.id);
      return {
        ...state,
        isLoading: false,
        success: true,
        items: Array.of(action.badge, ...filteredItems),
        badge: Object.assign({}, action.badge),
      };
    }
    case POST_BADGE_SUCCEED: {
      const newItems = Array.of(action.badge, ...state.items);
      return {
        ...state,
        isLoading: false,
        success: true,
        badge: Object.assign({}, action.badge),
        items: newItems,
        total: newItems.length,
      };
    }
    case DELETE_BADGE_SUCCEED: {
      const filteredItems = state.items.filter((badge) => badge.id !== action.badge.id);
      return {
        ...state,
        isLoading: false,
        success: true,
        badge: Object.assign({}),
        items: Array.of(...filteredItems),
        total: filteredItems.length,
      };
    }
    case ADD_BADGE_TO_SELECTED: {
      const selected = [...state.selected, action.id];
      return {
        ...state,
        selected,
      };
    }
    case REMOVE_BADGE_FROM_SELECTED: {
      const selected = state.selected.filter((id) => id !== action.id);
      return {
        ...state,
        selected,
      };
    }
    default:
      return state;
  }
}
