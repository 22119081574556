import React, { useCallback, useEffect, useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';
import TourList from '~/components/Common/TourList';

import ReservationService from '~/services/ReservationService';

type Props = { vendorName?: string };

export default function VendorToursContainer(_: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [tours, setTours] = useState([]);
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onPageChange = useCallback((page) => setPage(page), []);

  const editButtonFormatter = useCallback(
    (cell) => {
      return (
        <Button component={Link} variant="text" to={`/vendors/${vendorId}/tours/${cell}`}>
          Edit
        </Button>
      );
    },
    [vendorId],
  );

  useEffect(() => {
    setLoading(true);
    ReservationService.getTours({
      page: page + 1,
      limit,
      vendorId,
    })
      .then((data) => {
        setTours(data.result);
        setTotal(data.total);
      })
      .catch((error) => {
        console.warn(error);
        setError("Service error. Can't load vendor tours");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vendorId, page, limit]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <>
      <TourList
        total={total}
        tours={tours}
        limit={limit}
        page={page}
        editBtnFormatter={editButtonFormatter}
        onPageChange={onPageChange}
      />

      <Box mt={1}>
        <Button component={Link} variant="contained" to={`/vendors/${vendorId}/tours`} startIcon={<AddIcon />}>
          Add
        </Button>
      </Box>
    </>
  );
}
