// Human readable underscore things e.g last_minute_hotel => Last Minute Hotel, propertyCuratedDescription => Property Curated Description
export const humanize = (str) =>
  !str
    ? ''
    : str
        .split('_') // snake_case
        .map((word) => (word.length > 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toUpperCase()))
        .join(' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2') // camelCase
        .split(' ')
        .map((word) => (word.length > 2 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toUpperCase()))
        .join(' ');
