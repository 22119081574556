import React from 'react';

import currencyFormatter from 'currency-formatter';

import BedIcon from '@mui/icons-material/Bed';
import { Stack } from '@mui/material';

import { getDurationText } from '~/utils/cart';

function getDisplayPrice(item: App.CartItem, offer: App.CartOffer) {
  if (offer.type === 'tour') {
    return item.price;
  }

  const { surcharge_paid_direct_to_vendor } = offer;
  const itemPrice = item.subscriberTier && item.luxPlusPrice > 0 ? item.luxPlusPrice : item.price;

  if (surcharge_paid_direct_to_vendor) {
    return itemPrice + item.extraGuestSurcharge;
  }

  return itemPrice + item.surcharge + item.extraGuestSurcharge;
}

export default function BookingItemWrapper({
  count,
  item,
  offerPackage,
  currencyCode,
  children,
  offer,
  surchargePaidDirectToVendor = false,
}) {
  const itemDisplayPrice = getDisplayPrice(item, offer);
  return (
    <div className="booking-item">
      <div className="booking-item-content">
        <div className="booking-item-meta">
          <div className="item-number data-hj-whitelist">{count}</div>

          <div className="package-name data-hj-whitelist">
            <h4>{item.pkg.name}</h4>
          </div>

          {offerPackage.room_type && (
            <Stack sx={{ mt: 2 }} direction="row">
              <BedIcon /> {offerPackage.room_type.name}
            </Stack>
          )}

          <div className="num-nights data-hj-whitelist">{getDurationText(item)}</div>

          <div className="item-price data-hj-whitelist">
            {window.configs.BOOKING_FLOW_V2 === 'true' && !item.reservation.checkIn && !item.reservation.checkOut && (
              <span>From </span>
            )}
            {currencyFormatter.format(itemDisplayPrice, {
              code: currencyCode,
            })}
          </div>

          {item.surcharge > 0 && (
            <div>
              {surchargePaidDirectToVendor ? 'Surcharge payable at hotel: ' : 'Includes peak period surcharge: '}
              {currencyFormatter.format(item.surcharge, {
                code: currencyCode,
              })}
            </div>
          )}

          {item.extraGuestSurcharge > 0 && (
            <div>
              {'Includes extra guest surcharge: '}
              {currencyFormatter.format(item.extraGuestSurcharge, {
                code: currencyCode,
              })}
            </div>
          )}
        </div>

        <div className="booking-item-form-container">{children}</div>
      </div>
    </div>
  );
}
