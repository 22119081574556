import React from 'react';

import { Alert, Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

const SuccessMessage = ({ total, message }) => {
  return (
    <Alert severity="success">
      <strong>Success!</strong>
      <p>{message ? message : 'File upload successful'}</p>
      {total && (
        <>
          <p>Total:</p>
          <ul>
            {Object.entries(total).map(([key, value], index) => (
              <li key={index}>
                Payment Reference {key} {value}
              </li>
            ))}
          </ul>
        </>
      )}
    </Alert>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <Alert severity="error">
      <strong>Oops!</strong>
      <p>File upload failed</p>
      <pre>{message}</pre>
    </Alert>
  );
};

export default class UploadExpediaMarketingFees extends React.Component {
  state = {
    successMessage: false,
    showErrorMessage: false,
    errorMessage: '',
    file: null,
    date_of_payment: '',
    total: null,
    message: null,
  };

  checkStatus = (jobId) => {
    setTimeout(() => {
      VendorsService.checkUploadJobStatus(jobId).then((result) => {
        const rowsProcessed = result.rows_processed || 0;

        if (result.job_state !== 'completed' && result.job_state !== 'failed') {
          this.setState({
            successMessage: true,
            message: `File processing... ${rowsProcessed} rows processed.`,
          });
          return this.checkStatus(jobId);
        }

        if (result.status === 'success') {
          const message =
            rowsProcessed > 0
              ? 'File uploaded successfully.'
              : 'File uploaded successfully but 0 rows processed. Please check file format and/or column names.';
          return this.setState({
            showMessage: true,
            total: result.result,
            message,
          });
        }

        this.setState({
          successMessage: false,
          showErrorMessage: true,
          errorMessage: result.result,
          file: null,
          total: null,
          message: null,
        });
      });
    }, 2000);
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await this.fileUpload(this.state.file, this.state.date_of_payment);

      this.setState({
        successMessage: true,
        showErrorMessage: false,
        errorMessage: '',
        file: null,
        total: null,
        message: result.message,
      });
      this.checkStatus(result.job_id);
    } catch (err) {
      this.setState({
        successMessage: false,
        showErrorMessage: true,
        errorMessage: err.message,
        file: null,
        total: null,
        message: null,
      });
    }
  };

  onChange = async (e) => {
    this.setState({ file: e.target.files[0] });
  };

  onDateOfPaymentChange = async (e) => {
    this.setState({ date_of_payment: e.target.value });
  };

  fileUpload = async (file, date_of_payment) => {
    const formData = new FormData();
    formData.append('expedia-marketing-fees', file);
    formData.append('date_of_payment', date_of_payment);
    return VendorsService.uploadExpediaMarketingFees(formData);
  };

  render() {
    const { successMessage, showErrorMessage, errorMessage } = this.state;

    return (
      <div>
        <p>Expedia Marketing Fees will not be reflected in any reports until the following day.</p>

        {successMessage && <SuccessMessage message={this.state.message} total={this.state.total} />}

        {showErrorMessage && <ErrorMessage message={errorMessage} />}

        <form onSubmit={this.onFormSubmit}>
          <h5>Date of Payment</h5>
          <input type="date" onChange={this.onDateOfPaymentChange} />
          <h5>File Upload</h5>
          <input type="file" onChange={this.onChange} />
          <Button sx={{ marginTop: '10px' }} type="submit" variant="contained">
            Upload fees
          </Button>
        </form>
      </div>
    );
  }
}
