import React from 'react';

import { Chip } from '@mui/material';
import { ChipOwnProps } from '@mui/material/Chip/Chip';

interface props {
  status: 'Pending' | 'Approved' | 'Onboarded' | 'Rejected';
}

interface StatusMap {
  [key: string]: { color: ChipOwnProps['color']; label: props['status'] };
}

function EoiStatusChip({ status }: props): JSX.Element {
  const STATUS_MAP: StatusMap = {
    pending: { color: 'warning', label: 'Pending' },
    approved: { color: 'primary', label: 'Approved' },
    onboarded: { color: 'success', label: 'Onboarded' },
    rejected: { color: 'error', label: 'Rejected' },
  };
  const currentStatus = STATUS_MAP[status];

  return <Chip size="small" color={currentStatus?.color || 'default'} label={currentStatus?.label || status} />;
}

export default EoiStatusChip;
