import React from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';

import { Box, Button } from '@mui/material';

import { enqueueReport } from '~/services/ReportingService';
import { formatDateISO, startOfMonth, subMonths } from '~/services/TimeService';

const formSchema: RJSFSchema = {
  description: 'Please enter your dates to export custom offer orders summary report',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
  },
};

const formData = {
  startAt: formatDateISO(startOfMonth(subMonths(1))),
  endAt: formatDateISO(startOfMonth()),
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function CustomOffersSummaryForm() {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event: IChangeEvent<typeof formData>) => {
    try {
      const { startAt, endAt } = event.formData;

      await enqueueReport('custom-offers/summary', { start_at: startAt, end_at: endAt });
      enqueueSnackbar(`Your report is queued for delivery and will be emailed shortly.`, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(`Report error: ${err.message}`, { variant: 'error' });
    }
  };

  return (
    <Box>
      <Form
        schema={formSchema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={handleSubmit}
        validator={validator}
        showErrorList={false}
      >
        <Button type="submit" variant="contained">
          Email Custom Offer Orders Summary Report
        </Button>
      </Form>
    </Box>
  );
}
