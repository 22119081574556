import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface Props {
  openModal: boolean;
  closeModal: () => void;
  messageId: string;
  deleteInAppPromoMessage: () => void;
}

export default function DeleteInAppPromoMessageModal({
  openModal,
  closeModal,
  messageId,
  deleteInAppPromoMessage,
}: Props) {
  return (
    <Dialog open={openModal} className="expire-promo-modal" onClose={closeModal}>
      <DialogTitle>Delete In App Promo Message</DialogTitle>

      <DialogContent>
        <div className="text-center promo-code">
          <strong>{`Delete in app promo Message id: ${messageId}`}</strong>
        </div>

        <div className="text-center modal-text">Are you sure you want to delete this in app promo message?</div>
        <div className="text-center modal-text">You can't undo this action</div>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={deleteInAppPromoMessage}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
