import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

interface PartnerCentralPropertiesProps {
  properties: Array<any>;
  page: number;
  setPage: (page: number) => void;
  onRowClick: (selection: any) => void;
  total: number;
  loading: boolean;
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Hotel',
    flex: 3,
    display: 'flex',
  },
  {
    field: 'hotel_code',
    headerName: 'Hotel Code',
    flex: 1,
    display: 'flex',
  },
];

function PartnerCentralProperties({
  properties,
  page,
  setPage,
  onRowClick,
  total,
  loading,
}: PartnerCentralPropertiesProps) {
  return (
    <DataGrid
      rows={properties}
      columns={columns}
      paginationModel={{ page, pageSize: 10 }}
      pagination
      paginationMode="server"
      rowCount={total}
      onRowClick={onRowClick}
      onPaginationModelChange={({ page }) => setPage(page)}
      slots={{ pagination: GridPagination }}
      loading={loading}
      autoHeight
    />
  );
}

export default PartnerCentralProperties;
