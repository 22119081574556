import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import ErrorDisplay from '~/components/Common/ErrorDisplay';

import OffersService from '~/services/OffersService';
import { formatDateWithClock } from '~/services/TimeService';

type Props = {
  user: App.User;
};

const columns: GridColDef<App.CustomOffer>[] = [
  { field: 'name', headerName: 'Offer name', flex: 1, sortable: false },
  {
    field: 'start_date',
    headerName: 'Start date',
    sortable: false,
    width: 150,
    valueGetter: (_value, row) => row.items[0].start_date,
    display: 'flex',
  },
  { field: 'due_date', headerName: 'Due date', sortable: false, width: 150, display: 'flex' },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: false,
    width: 180,
    valueGetter: (_value, row: App.CustomOffer) => formatDateWithClock(row.created_at),
    display: 'flex',
  },
  { field: 'status', headerName: 'Status', sortable: false, display: 'flex' },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    display: 'flex',
    renderCell: (params) => (
      <>
        <Button
          variant="text"
          component={Link}
          to={`/users/${params.row.fk_customer_id}/custom-offers/${params.row.id}`}
        >
          Edit
        </Button>
      </>
    ),
  },
];

export default function CustomOffersTab({ user }: Props) {
  const userId = user.id_member;

  const [offers, setOffers] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [error, setError] = useState(null);

  const perPage = 25;

  useEffect(() => {
    setLoading(true);
    OffersService.getCustomOffers({ customerId: userId, page: currentPage + 1, perPage })
      .then((res) => {
        setOffers(res.result);
        setTotal(res.total);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [userId, currentPage, perPage]);

  return (
    <>
      {error && <ErrorDisplay message={error} />}

      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={offers}
        rowCount={total}
        pagination
        paginationMode="server"
        paginationModel={{ page: currentPage, pageSize: perPage }}
        onPaginationModelChange={({ page }) => setCurrentPage(page)}
        slots={{ pagination: GridPagination }}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableRowSelectionOnClick
        autoHeight
      />
    </>
  );
}
