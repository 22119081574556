import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import cruisesAPI from './CruisesApiService';

const resource = 'departures';

const listWithPagination = async (
  paginationParams: CruisesContract.DepartureParams,
): Promise<CruisesContract.Response<CruisesContract.DepartureResponse[]>> => {
  return await cruisesAPI.http.get({ resource, paginationParams });
};

type DepartureByIdResponse = CruisesContract.Response<CruisesContract.DepartureByIdResponse>;

const getById = async (id: string): Promise<DepartureByIdResponse> => {
  let status: number;

  try {
    const res: DepartureByIdResponse = await cruisesAPI.http.get({
      resource: `${resource}/${id}`,
    });

    status = res.status;
    if (res.result && !res.errors) return res;
  } catch (err) {
    console.error('[Cruise Departure - Get By Id]: ', err);
    return { status, message: err, result: null };
  }
};

type GenericMessageResponse = CruisesContract.Response<CruisesContract.GenericMessageResponse>;

const updateById = async (id: string, data: CruisesContract.DepartureUpdateParams): Promise<GenericMessageResponse> => {
  let status: number;

  try {
    const res: GenericMessageResponse = await cruisesAPI.http.post({
      body: data,
      resource: `${resource}/${id}`,
    });

    status = res.status;
    if (res.result && !res.errors) return res;
  } catch (err) {
    console.error('[Cruise Departure - Update By Id]: ', err);
    return { status, message: err, result: null };
  }
};

type DataFeedResponse = CruisesContract.Response<CruisesContract.DataFeedResponse>;

const getDataFeedByDepartureId = async (departureId: string): Promise<DataFeedResponse> => {
  let status: number;

  try {
    const res: DataFeedResponse = await cruisesAPI.http.get({
      resource: `${resource}/${departureId}/data-feed`,
    });

    status = res.status;
    if (res.result && !res.errors) return res;
  } catch (err) {
    console.error('[Data Feed By Departure ID]: ', err);
    return { status, message: err, result: null };
  }
};

type DataFeedDiffsResponse = CruisesContract.Response<CruisesContract.DataFeedDiffsByDepartureIdResponse>;

const getDataFeedDiffsByDepartureId = async (departureId: string): Promise<DataFeedDiffsResponse> => {
  let status: number;

  try {
    const res: DataFeedDiffsResponse = await cruisesAPI.http.get({
      resource: `${resource}/${departureId}/data-feed/diffs`,
    });

    status = res.status;
    if (res.result && !res.errors) return res;
  } catch (err) {
    console.error('[Data Feed Diffs By Departure ID]: ', err);
    return { status, message: err, result: null };
  }
};

type RebuildDeparture = {
  toOfferId: string;
  fromOfferId: string;
};

type RebuildDepartureResponse = CruisesContract.Response<RebuildDeparture>;

const rebuildDeparture = async (departureId: string): Promise<RebuildDepartureResponse> => {
  return await cruisesAPI.http.post({
    resource: `${resource}/${departureId}/rebuild`,
  });
};

export default {
  getById,
  updateById,
  rebuildDeparture,
  listWithPagination,
  getDataFeedByDepartureId,
  getDataFeedDiffsByDepartureId,
};
