export enum INSTALMENT_STATUS {
  INITIAL_PAYMENT_TAKEN = 'initial_payment_taken', // Initial payment paid by user
  PAYMENT_FAILED = 'payment_failed', // FAILURE: Initial payment failed
  INSTALMENT_DUE_BALANCE_MANUAL_DEBIT_FAILED = 'payment_due_balance_manual_debit_failed', // FAILURE: Manual payment of due balance amount failed
  PAYMENT_REFUNDED = 'payment_refunded',
  PAYMENT_FULL_BALANCE_REFUNDED = 'payment_full_balance_refunded',
  PAYMENT_MANUAL_DEBIT_TAKEN = 'payment_manual_debit_taken',
  PAYMENT_MANUAL_DEBIT_FAILED = 'payment_manual_debit_failed',
  PAYMENT_TAKEN_COMPLETE = 'payment_taken_complete',
  PAYMENT_MANUAL_DEBIT_TAKEN_COMPLETE = 'payment_manual_debit_taken_complete',
  PAYMENT_AUTO_DEBIT_TAKEN = 'payment_auto_debit_taken',
  PAYMENT_AUTO_DEBIT_FAILED = 'payment_auto_debit_failed',
  PAYMENT_UPCOMING = 'payment_upcoming', // independent status for showing unpaid instalments
  CREDIT_APPLIED = 'credit_applied', // independent status for showing initial credit applied
  PAYMENT_DETAILS_CREATED = 'payment_details_created',
}

export const instalmentRefundStatuses = new Set([
  INSTALMENT_STATUS.PAYMENT_REFUNDED,
  INSTALMENT_STATUS.PAYMENT_FULL_BALANCE_REFUNDED,
]);
