import React, { useCallback, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

const NewRentalModal = ({ isModalOpen, closeModal }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [sfVendorId, setSfVendorId] = useState('');
  const [sfOfferId, setSfOfferId] = useState('');
  const [ruVendorId, setRuVendorId] = useState('');
  const [status, setStatus] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const TWO_MINUTES_IN_MS = 60000 * 2;
  const POLL_RATE_IN_MS = 1000;

  const handleError = (message) => {
    setErrorMessage(message);
    setStatusMessage('');
    setLoading(false);
  };

  const handleSfVendorId = useCallback((query) => {
    setSfVendorId(query.target.value);
  }, []);

  const handleSfOfferId = useCallback((query) => {
    setSfOfferId(query.target.value);
  }, []);

  const handleRuVendorId = useCallback((query) => {
    setRuVendorId(query.target.value);
  }, []);

  const clearMessages = useCallback(() => {
    setErrorMessage('');
    setStatusMessage('');
  }, []);

  const onSubmit = async () => {
    if (!sfVendorId || !ruVendorId || !sfOfferId) {
      handleError('Please fill in all fields');
      return;
    }
    try {
      setLoading(true);
      clearMessages();
      const txId = uuidv4();
      await ReservationService.createImportNewRental(ruVendorId, sfVendorId, sfOfferId, txId);
      setStatus('dispatched');
      pollImportStatus(txId);
    } catch (e) {
      setStatus('error');
      handleError(e.message);
      setLoading(false);
    }
  };

  const pollImportStatus = async (transId: string) => {
    const startTimestamp = Date.now();

    for (
      let timestampDifference = Date.now() - startTimestamp, tries = 1;
      timestampDifference <= TWO_MINUTES_IN_MS;
      timestampDifference = Date.now() - startTimestamp
    ) {
      if (timestampDifference >= tries * POLL_RATE_IN_MS) {
        tries++;

        try {
          const { result } = await ReservationService.getRentalImportStatus(transId);

          setStatus(result?.state);
          setStatusMessage(result?.message);
          if (result?.state === 'success') {
            setLoading(false);
            setComplete(true);
            return;
          } else if (result?.state === 'failed') {
            setLoading(false);
            setComplete(true);
            handleError(result?.message);
            return;
          }
        } catch (e) {
          setStatus('error');
          handleError(e.message);
          setLoading(false);
          return;
        }
      }
    }

    setStatus('error');
    handleError('Operation timed out');
    setLoading(false);
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogTitle>Create New Rental</DialogTitle>
      <DialogContent>
        <Stack spacing={1} direction="column">
          <DialogContentText>
            Import rental from Rentals United - this process may take a short while, please do not close until completed
          </DialogContentText>
          <TextField
            autoFocus
            type="text"
            fullWidth
            value={sfVendorId}
            onChange={handleSfVendorId}
            onFocus={clearMessages}
            placeholder={'Saleforce Vendor Id'}
          />
          <TextField
            type="text"
            fullWidth
            value={sfOfferId}
            onChange={handleSfOfferId}
            onFocus={clearMessages}
            placeholder={'Saleforce Offer Id'}
          />
          <TextField
            type="text"
            fullWidth
            value={ruVendorId}
            onChange={handleRuVendorId}
            onFocus={clearMessages}
            placeholder={'Rentals United Vendor Id'}
          />
        </Stack>

        {!isComplete && status && <Typography>{statusMessage}</Typography>}
        {isComplete && <Typography sx={{ color: 'success.main' }}>{statusMessage}</Typography>}
        {errorMessage && <Typography sx={{ color: 'warning.main' }}>{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={isLoading}>
          {isLoading ? <Spinner size={15} inline={true} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewRentalModal;
