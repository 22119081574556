import React from 'react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Paper, Stack } from '@mui/material';

import { ExperienceImage } from '~/components/Experiences/Home/Content/styles';

const SortableListItem = SortableElement(({ value, toRemove, imageHero, handleSetImageHero, handleDeleteImage }) => (
  <Paper variant="outlined" sx={{ width: '100%', maxWidth: 350, p: 1, display: 'flex', gap: 2 }}>
    <Stack direction="column" spacing={2}>
      <ExperienceImage src={value.urls[0]?.url} alt={`${value.description}`} style={{ opacity: toRemove ? 0.2 : 1 }} />

      <Stack direction="row" justifyContent="space-between" spacing={2}>
        {value.isHero ? (
          <p>This is the hero image</p>
        ) : (
          <Button
            variant="text"
            size="small"
            onClick={() => handleSetImageHero(value.id)}
            disabled={imageHero === value.id || toRemove}
          >
            Set as hero
          </Button>
        )}
        <Button variant="text" size="small" onClick={() => handleDeleteImage(value.id)}>
          {toRemove ? 'Marked to delete' : 'Delete image'}
        </Button>
      </Stack>
    </Stack>

    <div style={{ cursor: 'pointer' }}>
      <MenuIcon />
    </div>
  </Paper>
));

const ExperienceCurationImages = SortableContainer(
  ({ items, handleSetImageHero, handleDeleteImage, imageHero, imagesToRemove }) => (
    <Box display="flex" gap={2} flexDirection="column" maxHeight={600} overflow="scroll">
      {items.map((value, index) => (
        <SortableListItem
          handleSetImageHero={handleSetImageHero}
          handleDeleteImage={handleDeleteImage}
          key={`item-${value.id}`}
          imageHero={imageHero}
          toRemove={imagesToRemove.includes(value.id)}
          index={index}
          value={value}
        />
      ))}
    </Box>
  ),
);

export default ExperienceCurationImages;
