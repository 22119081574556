import React from 'react';

import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface Props {
  order: App.Order;
  partnerships: App.Partnership[];
  customer: App.User;
}

export default class OrderDetailPartnerships extends React.Component<Props> {
  columns: GridColDef[] = [
    {
      field: 'brand_name',
      headerName: 'BrandName',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'account_id',
      headerName: 'Account',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'first_name',
      headerName: 'FirstName',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'last_name',
      headerName: 'LastName',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
  ];
  render() {
    const data = [];

    for (const partnershipData of this.props.partnerships) {
      const orderPartnership = this.props.order.partnerships[partnershipData.prefix];

      if (orderPartnership) {
        data.push({
          brand_name: partnershipData.brandName,
          account_id: orderPartnership.account_id,
          first_name: orderPartnership.first_name || '-',
          last_name: orderPartnership.last_name || '-',
        });
      }
    }

    if (data.length === 0) {
      return null;
    }

    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          sx={{
            backgroundColor: 'grey.200',
            height: '60px',
          }}
        >
          <Typography> Linked Loyalty Accounts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            columns={this.columns}
            rows={data}
            getRowId={(row) => row.brand_name}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
}
