import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import Spinner from '~/components/Common/Spinner';

interface Props {
  openModal: boolean;
  closeModal: () => void;
  deleteBundle: () => void;
  isLoading?: boolean;
}

// TODO: Make reusable
const DeleteBundleModal = ({ openModal, closeModal, deleteBundle, isLoading = false }: Props) => {
  return (
    <Dialog open={openModal} className="expire-promo-modal" onClose={closeModal}>
      <DialogTitle>Delete Bundle</DialogTitle>
      <DialogContent>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Typography variant="body1">Are you sure you want to delete this in bundle?</Typography>
        </DialogContentText>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Typography variant="body1" fontWeight="bold">
            You cannot undo this action?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ width: 100 }} onClick={closeModal} variant="outlined">
          Cancel
        </Button>
        <Button sx={{ width: 100 }} onClick={deleteBundle} variant="contained" disabled={isLoading}>
          {isLoading ? <Spinner size={20} /> : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBundleModal;
