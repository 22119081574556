import React from 'react';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { withRouter } from 'react-router-dom';

import UserActions from '../actions/UserActions';
import utils from '../utils';

class LoginForm extends React.Component {
  state = {
    fields: {
      username: '',
      password: '',
    },
    errorMessage: null,
    isFormProcessing: false,
  };

  handleVerifyRecaptcha = async () => {
    const { executeRecaptcha } = this.props.googleReCaptchaProps;
    return await executeRecaptcha('login');
  };

  async onFormSubmit(e) {
    let setErrorState = (recentError, newError) => {
      this.setState({
        isFormProcessing: false,
        errorMessage: (newError || recentError)?.message,
      });
    };

    e.preventDefault();
    e.persist();
    setErrorState();

    let onSubmitSuccess = this.props.onSubmitSuccess;
    let onSubmitError = this.props.onSubmitError;

    let recaptchaResponse;
    try {
      recaptchaResponse = await this.handleVerifyRecaptcha();
    } catch (recaptchaError) {
      setErrorState(new Error('Invalid Login Verification'));
    }

    var next = (err, data) => {
      if (err) {
        if (onSubmitError) {
          return onSubmitError(
            {
              data: data,
              error: err,
            },
            setErrorState.bind(this, err),
          );
        }

        return setErrorState(err);
      }

      // If the user didn't specify any data,
      // then simply default to what we have in state.
      data = data || this.state.fields;

      UserActions.login(
        {
          login: data.username,
          password: data.password,
          recaptchaResponse,
        },
        (err, result) => {
          if (err) {
            if (onSubmitError) {
              return onSubmitError(
                {
                  data: data,
                  error: err,
                },
                setErrorState.bind(this, err),
              );
            }

            return setErrorState(err);
          }

          const performRedirect = this._performRedirect.bind(this);

          if (onSubmitSuccess) {
            return onSubmitSuccess(
              {
                data: data,
                result: result,
              },
              performRedirect,
            );
          }

          performRedirect();
        },
      );
    };

    this.setState({
      isFormProcessing: true,
    });

    const formData = new FormData(e.target);
    const fields = {
      username: formData.get('login'),
      password: formData.get('password'),
    };

    if (this.props.onSubmit) {
      e.data = fields;
      this.props.onSubmit(e, next);
    } else {
      return next(this.state.errorMessage ? new Error(this.state.errorMessage) : null, fields);
    }
  }

  _performRedirect() {
    const { history, redirectTo } = this.props;
    history.push(redirectTo || '/');
  }

  _mapFormFieldHandler(element, tryMapField) {
    let tryMapFormField = () => {
      switch (element.props.name) {
        case 'login':
        case 'username':
          tryMapField('username');
          break;
        case 'password':
          tryMapField('password');
          break;
        default:
          break;
      }
    };

    utils.mapFormField(element, tryMapFormField);
  }

  _spIfHandler(action) {
    var test = null;

    switch (action) {
      case 'form.processing':
        test = this.state.isFormProcessing;
        break;
      case 'form.error':
        test = !!this.state.errorMessage;
        break;
    }

    return test;
  }

  _spBindHandler(bind, element) {
    var result = false;
    let className;
    switch (bind) {
      case 'form.errorMessage':
        className = element.props ? element.props.className : null;
        result = <span className={className}>{this.state.errorMessage}</span>;
        break;
    }

    return result;
  }

  render() {
    let selectedProps = utils.excludeProps(
      [
        'redirectTo',
        'hideSocial',
        'onSubmit',
        'onSubmitError',
        'onSubmitSuccess',
        'children',
        'staticContext',
        'googleReCaptchaProps',
      ],
      this.props,
    );

    return (
      <form onSubmit={this.onFormSubmit.bind(this)} {...selectedProps}>
        {utils.makeForm(
          this,
          this._mapFormFieldHandler.bind(this),
          this._spIfHandler.bind(this),
          this._spBindHandler.bind(this),
        )}
      </form>
    );
  }
}

export default withGoogleReCaptcha(withRouter(LoginForm));
