import React, { useCallback, useState } from 'react';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { checkSyncPropertyMetaJob, syncPropertyMeta } from '~/services/HotelMetaService';

const STATUS_NOT_TRIGGERED = 'not-triggered';
const STATUS_IN_PROGRESS = 'in-progress';
const STATUS_PROCESSED = 'processed';
const STATUS_FAILED = 'failed';

export default function ReSyncPropertyButton(props) {
  const [buttonStatus, setButtonStatus] = useState(STATUS_NOT_TRIGGERED);

  const triggerPropertyMetaSync = useCallback(async () => {
    if (buttonStatus !== STATUS_NOT_TRIGGERED) return;

    try {
      setButtonStatus(STATUS_IN_PROGRESS);
      const propertyMetaResponse = await syncPropertyMeta(props.propertyId);
      const checkStatusInterval = setInterval(async () => {
        const checkStatus = await checkSyncPropertyMetaJob(propertyMetaResponse.jobId);
        if (checkStatus.is_completed === true) {
          setButtonStatus(STATUS_PROCESSED);
          clearInterval(checkStatusInterval);
        }
      }, 4000);
    } catch (e) {
      setButtonStatus(STATUS_FAILED);
    }
  }, [buttonStatus, props.propertyId]);

  return (
    <Button
      variant="outlined"
      onClick={triggerPropertyMetaSync}
      type="button"
      style={buttonStatus !== STATUS_NOT_TRIGGERED ? { cursor: 'initial' } : null}
      startIcon={buttonStatus === STATUS_IN_PROGRESS ? <Spinner size={20} inline /> : null}
      endIcon={buttonStatus === STATUS_PROCESSED ? <TaskAltIcon /> : null}
    >
      {buttonStatus === STATUS_NOT_TRIGGERED ? 'Manual Re-sync' : null}
      {buttonStatus === STATUS_IN_PROGRESS ? 'Syncing' : null}
      {buttonStatus === STATUS_PROCESSED ? 'Synced' : null}
      {buttonStatus === STATUS_FAILED ? 'Failed' : null}
    </Button>
  );
}
