// makeCancelablePromise returns an object containing a promise that is
// cancelable and a method to cancel the promise. A cancelled promise resolves
// and rejects with no value
export function makeCancelablePromise(promise) {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) => {
        if (!isCanceled) {
          resolve(val);
        } else {
          resolve();
        }
      })
      .catch((err) => {
        if (!isCanceled) {
          reject(err);
        } else {
          reject();
        }
      });
  });

  return {
    promise: wrappedPromise,
    canceled() {
      return isCanceled;
    },
    cancel() {
      isCanceled = true;
    },
  };
}
