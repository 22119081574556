import { PACKAGE_STATUS_APPROVED } from '~/consts/package';

import ReservationService from '~/services/ReservationService';

import { ICondition, LEVELS } from './types';
import { pluralisedCount } from './utils';

export const property: { [key: string]: ICondition } = {
  inventory: {
    thresholds: ({ withoutInventory, total }) => {
      const percent = Math.ceil((withoutInventory.length / total) * 100);
      return percent >= 95 ? LEVELS.ERROR : percent >= 50 ? LEVELS.WARNING : LEVELS.PASS;
    },
    prefetch: async (offer, property) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === 'content-approved')
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === 'content-approved')
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const roomRates: any =
        property?.room_types
          ?.flatMap((rt) => rt.room_rates.map((rr) => ({ ...rr, room_type_id: rt.id })))
          .filter((rr) => roomRateIds.includes(rr.id)) ?? [];
      for (const roomRate of roomRates) {
        try {
          const availability = await ReservationService.getAvailability(
            property.id,
            roomRate.room_type_id,
            roomRate.id,
            1,
          );
          roomRate.inventory = availability.result.map((a) => a.count).reduce((a, b) => a + b, 0);
        } catch (e) {
          roomRate.inventory = 0;
        }
      }
      return roomRates;
    },
    check: (offer, property, prefetched) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const roomRates =
        property?.room_types?.flatMap((rt) => rt.room_rates).filter((rr) => roomRateIds.includes(rr.id)) ?? [];
      const withoutInventory = [];
      for (const roomRate of roomRates) {
        const inventory = prefetched?.find((i) => i.id === roomRate.id);
        if (!inventory || inventory.value < 1) {
          withoutInventory.push(roomRate.id);
        }
      }
      return withoutInventory.length;
    },
    result: (offer, property, prefetched, linkBuilder) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const roomRates =
        property?.room_types?.flatMap((rt) =>
          rt.room_rates
            .filter((rr) => roomRateIds.includes(rr.id))
            .map((rr) => ({ ...rr, room_type_id: rt.id, name: rt.name })),
        ) ?? [];
      const withoutInventory = [];
      for (const roomRate of roomRates) {
        const inventory = prefetched?.find((i) => i.id === roomRate.id);
        if (!inventory || inventory.value < 1) {
          withoutInventory.push({
            link: linkBuilder(property.id_salesforce_external, property.id, roomRate.room_type_id, roomRate.id),
            name: roomRate.name + ' - ' + roomRate.rate_plan.name,
            inventory: inventory?.value ?? 0,
          });
        }
      }
      return { withoutInventory, total: roomRates.length };
    },
    summary: ({ withoutInventory }) => {
      return withoutInventory.length === 0
        ? 'All room rates have inventory'
        : pluralisedCount('room rate', withoutInventory.length) + ' with no inventory';
    },
    label: 'Inventory',
    linkBuilder: (vendorId, propertyId, roomTypeId, roomRateId) => {
      return `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}`;
    },
    nonCritical: true,
    helpText: 'Orange indicates no bookable dates for the entire period on a specific room rate, please verify.',
  },
  capacities: {
    thresholds: {
      type: 'lt',
      levels: [1, 2, 3],
    },
    check: (offer, property) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      return (property?.room_types?.flatMap((rt) => rt.room_rates) ?? [])
        .filter((rr) => roomRateIds.includes(rr.id))
        .map((rt) => rt.capacities.length)
        .filter((c) => c < 1).length;
    },
    result: (offer, property, _, linkBuilder) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const capacities = (
        property?.room_types?.flatMap((rt) =>
          rt.room_rates
            .filter((rr) => roomRateIds.includes(rr.id))
            .map((rr) => ({ ...rr, room_type_id: rt.id, name: rt.name })),
        ) ?? []
      ).map((rr) => ({
        roomName: rr.name,
        link: linkBuilder(property.id_salesforce_external, property.id, rr.room_type_id, rr.id),
        capacities: rr.capacities,
      }));
      return {
        total: capacities.length,
        noCapacities: capacities.filter((c) => c.capacities.length < 1),
      };
    },
    summary: ({ noCapacities }) => {
      return noCapacities.length === 0
        ? 'All room rates have capacities'
        : noCapacities.length + ' room rates have no capacities';
    },
    label: 'Room Capacities',
    linkBuilder: (vendorId, propertyId, roomTypeId, roomRateId) => {
      return `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}`;
    },
    helpText: 'Red indicates no room capacities set against a room rate, please verify.',
  },
  ratePlans: {
    check: (offer, property) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];

      return (property?.room_types?.flatMap((rt) => rt.room_rates) ?? [])
        .filter((rr) => roomRateIds.includes(rr.id))
        .map((rr) => rr.rate_plan)
        .every((rp) => rp.status !== 'draft');
    },
    result: (offer, property, _, linkBuilder) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const ratePlans = (
        property?.room_types?.flatMap((rt) => rt.room_rates).filter((rr) => roomRateIds.includes(rr.id)) ?? []
      ).map((rr) => rr.rate_plan);
      const drafts = ratePlans
        .filter((rp) => rp.status === 'draft')
        .map((rp) => ({
          link: linkBuilder(property.id_salesforce_external, rp.id),
          name: rp.name,
        }));
      return { drafts, total: ratePlans.length };
    },
    summary: (resultOutput) => {
      const { drafts, total } = resultOutput;
      const percentage = Math.round((drafts.length / total) * 100);
      return percentage === 0
        ? 'All rate plans are approved'
        : `${drafts.length} rate plan${drafts.length > 1 ? 's are' : ' is'}  draft`;
    },
    label: 'Rate Plans',
    linkBuilder: (vendorId, rateplanId) => {
      return `/vendors/${vendorId}/rate-plans/${rateplanId}`;
    },
    helpText: 'Orange indicates there are some rate plans set to draft, please check if this is intentional.',
  },
  schedules: {
    check: (offer, property) => {
      const schedules = (property?.room_types?.flatMap((rt) => rt.room_rates) ?? [])
        .map((rr) => rr.rate_plan)
        .flatMap((rp) => rp.schedules_list.filter((s) => s.type === 'sell'));
      return schedules.filter((s) => new Date(s.activePeriod?.to) < new Date()).length === 0;
    },
    result: (offer, property, _, linkBuilder) => {
      const ratePlans = (property?.room_types?.flatMap((rt) => rt.room_rates) ?? []).map((rr) => ({
        ...rr.rate_plan,
        schedules: rr.rate_plan.schedules_list.filter((s) => s.type === 'sell'),
      }));
      return {
        totalSchedules: ratePlans.flatMap((rp) => rp.schedules).length,
        ratePlansWithExpiredSchedules: ratePlans
          .map((rp) => ({
            link: linkBuilder(property.id_salesforce_external, rp.id),
            schedules: rp.schedules.filter((s) => new Date(s.activePeriod?.to) < new Date()),
          }))
          .filter((s) => s.schedules.length > 0),
      };
    },
    summary: (resultOutput) => {
      const { totalSchedules, ratePlansWithExpiredSchedules } = resultOutput;
      return totalSchedules > 0 && ratePlansWithExpiredSchedules.length === 0
        ? 'All sell schedules are active'
        : ratePlansWithExpiredSchedules.length > 0
        ? ratePlansWithExpiredSchedules.length + ' rate plans have expired sell schedules'
        : 'No rate plan sell schedules';
    },
    label: 'Rate Plan Schedules',
    hidden: (offer, property) =>
      (property?.room_types?.flatMap((rt) => rt.room_rates) ?? [])
        .map((rr) => rr.rate_plan)
        .flatMap((rp) => rp.schedules_list.filter((s) => s.type === 'sell')).length === 0,
    helpText: `Sell schedules are used to control the availability of rate plans. If a rate plan has an expired <strong>sell</strong> schedule, it will not be available for booking.`,
    linkBuilder: (vendorId, rateplanId) => {
      return `/vendors/${vendorId}/rate-plans/${rateplanId}`;
    },
  },
  inclusionValues: {
    check: (offer, property) => {
      const inclusions = (property?.room_types?.flatMap((rt) => rt.room_rates) ?? []).flatMap((rr) => rr.inclusions);
      if (inclusions.length === 0) {
        return true;
      }
      return inclusions.filter((i) => !i.value || i.value < 0.01).length === 0;
    },
    result: (offer, property, _, linkBuilder) => {
      const inclusions = (
        property?.room_types?.flatMap((rt) => rt.room_rates.map((rr) => ({ ...rr, room_type_id: rt.id }))) ?? []
      )
        .flatMap((rr) => rr.inclusions.map((i) => ({ ...i, room_rate_id: rr.id })))
        .map((i) => ({
          name: i.name,
          link: linkBuilder(property.id_salesforce_external, property.id, i.room_type_id, i.room_rate_id),
          value: i.value,
        }));
      return {
        total: inclusions.length,
        inclusions: inclusions.filter((i) => !i.value || i.value < 0.01),
      };
    },
    summary: ({ total, inclusions }) => {
      return total === 0
        ? 'All inclusions have values'
        : pluralisedCount('inclusion', inclusions.length) + ' have no value';
    },
    label: 'Inclusion Values',
    linkBuilder: (vendorId, propertyId, roomTypeId, roomRateId) => {
      return `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}`;
    },
    hidden: (offer, property) =>
      (property?.room_types?.flatMap((rt) => rt.room_rates) ?? []).flatMap((rr) => rr.inclusions).length === 0,
    nonCritical: true,
    helpText: 'Orange indicates that some room rates have inclusions with no value set, please verify.',
  },
  includedGuests: {
    check: (offer, property) => {
      return (
        (property?.room_types?.flatMap((rt) => rt.room_rates) ?? []).filter(
          (rr) => rr.extra_guest_surcharges.length > 0 && rr.included_guests.length === 0,
        ).length === 0
      );
    },
    result: (offer, property, _, linkBuilder) => {
      const roomRates =
        (property?.room_types ?? []).flatMap((rt) =>
          (rt.room_rates ?? []).map((rr) => ({
            ...rr,
            room_type_id: rt.id,
            room_type_name: rt.name,
          })),
        ) ?? [];
      return {
        total: roomRates.length,
        roomRates: roomRates
          .filter((rr) => rr.extra_guest_surcharges.length > 0 && rr.included_guests.length === 0)
          .map((rr) => ({
            link: linkBuilder(property.id_salesforce_external, property.id, rr.room_type_id, rr.id),
            roomType: rr.room_type_name,
            extraGuestSurcharges: rr.extra_guest_surcharges.length,
            includedGuests: rr.included_guests.length,
          })),
      };
    },
    summary: ({ roomRates }) => {
      return roomRates.length === 0
        ? 'All room rates have surcharges & included guests'
        : pluralisedCount('room rate', roomRates.length) + ' have mismatched surcharges & included guests';
    },
    label: 'Included Guests',
    hidden: (offer) => offer.type?.toLowerCase() !== 'hotel',
    nonCritical: true,
    helpText: 'Orange indicates Extra Guest Surcharges have been added but Included Guests have not, please verify.',
  },
};
