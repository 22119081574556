import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Checkbox } from '@mui/material';

import { BEDBANK_PROPERTY_STATUSES_ENUM } from '~/consts/bedbank';

import { isAdmin } from '~/utils/adminPermission';

interface Props {
  id: string;
  value: string;
  onStatusUpdate: (value) => boolean;
}

interface State {
  isAdmin: boolean;
  statuses: Array<{
    name: string;
    value: boolean;
  }>;
}

const CheckboxWrapper = styled.div`
  text-align: left;
  font-size: 14px;
`;

class CheckboxGroup extends React.Component<Props, State> {
  timer: NodeJS.Timeout;
  static contextTypes = {
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props);
    this.state = {
      isAdmin: isAdmin(context.user),
      statuses: BEDBANK_PROPERTY_STATUSES_ENUM.map((name) => {
        return {
          name,
          value: name === props.value,
        };
      }),
    };
  }

  changeStatusValue = (e) => {
    const elementName = e.target.name;

    this.setState(
      {
        statuses: BEDBANK_PROPERTY_STATUSES_ENUM.map((name) => {
          return {
            name,
            value: name === elementName,
          };
        }),
      },
      () => {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.props.onStatusUpdate(elementName);
        }, 1000);
      },
    );
  };

  render() {
    return (
      <>
        {this.state.statuses.map(({ name, value }) => {
          return (
            <CheckboxWrapper key={`${this.props.id}-status-${name}`}>
              <Checkbox
                id={`${this.props.id}-status-${name}`}
                disabled={!this.state.isAdmin}
                checked={value}
                name={name}
                onClick={(event) => event.stopPropagation()}
                onChange={this.changeStatusValue}
                size="small"
                sx={{ padding: 0 }}
              />
              <label htmlFor={`${this.props.id}-status-${name}`}>&nbsp; {name}</label>
            </CheckboxWrapper>
          );
        })}
      </>
    );
  }
}

export default CheckboxGroup;
