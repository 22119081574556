import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueItemsPurchased } from '~/services/ReportingService';
import { addMonths, formatDateISO, startOfMonth } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

function validateForm(formData, errors) {
  if (typeof formData.endAt === 'undefined') {
    errors.endAt.addError('Please enter end at');
  }
  if (typeof formData.startAt === 'undefined') {
    errors.startAt.addError('Please enter start at');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please enter a date to export all items purchased between the selected date range',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(-1, startOfMonth())),
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(0, startOfMonth())),
    },
    dataSource: {
      title: 'Data Source',
      type: 'string',
      anyOf: [
        {
          type: 'string',
          enum: ['bq'],
          title: 'BigQuery Data Model',
        },
        {
          type: 'string',
          enum: ['fdw'],
          title: 'Reporting Service Postgresql',
        },
      ],
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportItemsPurchased() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    queueItemsPurchased({
      startAt: event.formData.startAt,
      endAt: event.formData.endAt,
      source: event.formData.dataSource,
    })
      .then(function () {
        setSuccessMessage(true);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Items Purchased Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
