import filter from 'lodash/filter';

import { UTM_MEDIUM } from '~/consts/utm';

import { createItem, createOrder } from '~/services/OrdersService';
import { logInsuranceNotInterested } from '~/services/PovolyticsService';

import { getAddonItemPayload, getItemPayload } from '~/utils/cart';

import UsersService from '../services/UsersService';

// handleCreateOrder creates order and its items return returns a promise that
// resolves with the order's id
export async function handleCreateOrder({
  customer,
  insurance,
  offers,
  items,
  currencyCode,
  regionCode,
  offerSource,
  brand,
  transactionKey,
  contactPhonePrefix,
  contactPhone,
  insuranceState,
  travellers,
  amounts,
  notes,
  stateOfResidence,
  businessId,
}) {
  // Update contact details
  if (contactPhonePrefix && contactPhone) {
    await UsersService.updateUser(
      {
        id_member: customer.id_member,
        email: customer.email,
        phone_prefix: contactPhonePrefix,
        phone: contactPhone,
      },
      brand,
    );
  }

  let createPayload = {
    customer_id: customer.id_member,
    currency_code: currencyCode,
    region_code: regionCode,
    brand,
    utm_source: brand,
    utm_medium: UTM_MEDIUM,
    utm_campaign: '',
    le_label: offerSource,
    le_attribution: offerSource,
    transaction_key: transactionKey,
    state_of_residence: stateOfResidence,
    travellers,
    notes,
  };

  // we need to add the business id if it exists
  if (businessId) {
    createPayload = {
      ...createPayload,
      business_id: businessId,
    };
  }

  // Hard code region for admin purchases
  return createOrder(createPayload).then(async (res) => {
    const order = res.result;
    const orderId = order.id;
    const combinedItemIds = [];

    // Accommodation Items (for each Offer)
    for (const offer of offers) {
      for (const item of offer.items) {
        const data = getItemPayload(item, customer, offer, brand);
        const createdItem = await createItem(orderId, data);
        combinedItemIds.push(createdItem.result.id);

        const itemAddons = filter(offer.addons, (addon) => addon.itemId === item.id);

        for (const addon of itemAddons) {
          const data = getAddonItemPayload({
            cartItem: addon,
            item: createdItem.result,
            offer,
            brand,
            packageId: item.pkg.id_salesforce_external ?? item.pkg.le_package_id,
          });

          const createdAddonItem = await createItem(orderId, data);
          combinedItemIds.push(createdAddonItem.result.id);
        }
      }
    }

    // Other Items not associated with Offer e.g. flights, giftcard
    for (const item of items) {
      const otherCreatedItem = await createItem(orderId, getItemPayload(item, customer, null, brand));
      combinedItemIds.push(otherCreatedItem.result.id);
    }

    if (insurance.applied) {
      const insuranceItem = await createItem(orderId, {
        quote_id: insurance.quoteId,
        brand,
        product_id: insurance.productId,
        transaction_key: transactionKey,
        type: 'insurance',
        policy_holder_firstname: customer.givenName,
        policy_holder_surname: customer.surname,
        travellers_details: travellers.map((p) => ({
          firstname: p.givenName,
          surname: p.surname,
        })),
      });
      combinedItemIds.push(insuranceItem.result.id);
    } else {
      // Analytics shouldn't stop checkout
      logInsuranceNotInterested(insuranceState.notInterestedReason, brand)
        .then()
        .catch((e) => console.warn(e));
    }

    return { ...order, combinedItemIds: combinedItemIds };
  });
}
