import React from 'react';

import Form from 'react-jsonschema-form';

import { Button } from '@mui/material';

import ReservationService from '../../../services/ReservationService';
import { reportError } from '../../../utils/reportError';

import { buttonMessages, buttonStates } from './states/submitButton';

const uiSchema = {
  tour_id: { 'ui:widget': 'hidden' },
  tour_option_id: { 'ui:widget': 'hidden' },
};

export default class TourLegForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.schema = props.schema;

    this.state = {
      tourLeg: props.tourLeg,
      saveState: buttonStates.default,
    };

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onSubmit(form) {
    var self = this;

    this.setState({
      saveState: buttonStates.saving,
      tourLeg: form.formData,
    });

    let submitType = 'updateTourLeg';
    if (!this.state.tourLeg.id) {
      submitType = 'createTourLeg';
    }

    const tourId = this.state.tourLeg.tour_id;
    const tourLegId = this.state.tourLeg.id;

    ReservationService[submitType](form.formData, tourId, tourLegId)
      .then((response) => {
        self.setState({
          tourLeg: response.result,
          saveState: buttonStates.saved,
        });
      })
      .catch(function (e) {
        self.setState({ saveState: buttonStates.failed });
        reportError(e);
      });
  }

  onChange(edit) {
    const newState = {
      tourLeg: edit.formData,
    };

    if (this.state.saveState !== buttonStates.default) {
      newState.saveState = buttonStates.default;
    }

    this.setState(newState);
  }

  onDelete() {
    var self = this;
    ReservationService.deleteTourLeg(self.state.tourLeg.tour_id, self.state.tourLeg.id)
      .then(function () {
        self.props.handleRemove(self.props.formKey);
      })
      .catch(function (e) {
        reportError(e);
      });
  }

  render() {
    let deleteButton = '';

    if (this.state.tourLeg.id) {
      deleteButton = (
        <Button variant="contained" color="error" onClick={this.onDelete}>
          Delete
        </Button>
      );
    }

    return (
      <div>
        <Form
          schema={this.schema}
          onChange={this.onChange}
          formData={this.state.tourLeg}
          onSubmit={this.onSubmit}
          uiSchema={uiSchema}
        >
          <Button type="submit" variant="contained" className={this.state.saveState}>
            {buttonMessages[this.state.saveState]}
          </Button>
          {deleteButton}
        </Form>
        <hr />
      </div>
    );
  }
}
