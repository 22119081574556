import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { convertCodeToSlug } from '~/services/LoyaltyService';
import { queueLoyaltyRewardPointEarnReport } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema = {
  description: 'Please enter a date to export all earnings between the selected date range',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    code: {
      type: 'string',
      title: 'Partnership',
      default: 'qantas',
      enum: [],
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportLoyaltyRewardsEarnReportForm(props) {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  formSchema.properties.code.enum = [];
  props.partnerships.forEach((partnership) => {
    formSchema.properties.code.enum.push(partnership.code);
  });

  const handleSubmit = (event: IChangeEvent) => {
    queueLoyaltyRewardPointEarnReport({
      startAt: event.formData.startAt,
      endAt: event.formData.endAt,
      code: convertCodeToSlug(event.formData.code),
    })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema as RJSFSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Loyalty Rewards Earn Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
