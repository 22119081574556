import React, { useState } from 'react';

import { Button, Stack, TextField } from '@mui/material';

import VendorsService from '~/services/VendorsService';

import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';

export default function UploadBookingPayments() {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [populateOfferPayments, setPopulateOfferPayments] = useState(false); // change to `false` after initial release
  const [result, setResult] = useState(null);

  const checkStatus = (jobId: string) => {
    const intervalId = setInterval(() => {
      VendorsService.checkUploadJobStatus(jobId).then((result) => {
        const rowsProcessed = result.rows_processed || 0;

        if (result.job_state !== 'completed' && result.job_state !== 'failed') {
          setSuccess(true);
          setMessage(`File processing... ${rowsProcessed} rows processed.`);
          return;
        }

        if (intervalId) {
          clearInterval(intervalId);
        }

        if (result.status === 'success') {
          const message =
            rowsProcessed > 0
              ? `File uploaded successfully. ${rowsProcessed} row(s) processed.`
              : 'File uploaded successfully but 0 rows processed. Please check file format and/or column names.';
          setSuccess(true);
          setResult(result.result);
          setMessage(message);
          return;
        }

        setSuccess(false);
        setErrorMessage(result.result);
        setMessage('');
      });
    }, 2000);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('csv', file);
    formData.append('populate_offer_payments', populateOfferPayments ? '1' : '');
    return VendorsService.uploadBookingPayments(formData);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccess(false);
    setMessage('');

    try {
      const result = await uploadFile(file);

      setSuccess(true);
      setMessage(result.message);
      checkStatus(result.job_id);
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setFile(null);
    }
  };

  return (
    <>
      <p>Payments will not be reflected in any reports until the following day.</p>

      {success && <SuccessMessage total={result} message={message} />}
      {errorMessage && <ErrorMessage message={errorMessage} />}

      <form onSubmit={handleFormSubmit}>
        <Stack direction="column" spacing={2}>
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                name="populate_offer_payments"
                checked={populateOfferPayments}
                onChange={(e) => setPopulateOfferPayments(e.target.checked)}
              />
              Populate vendor offer payments
            </label>
          </div>

          <TextField
            label="File Upload"
            type="file"
            id="csv-file"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFile(e.target.files[0])}
            fullWidth
          />

          <div>
            <Button type="submit" variant="contained">
              Upload payments
            </Button>
          </div>
        </Stack>
      </form>
    </>
  );
}
