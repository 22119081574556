import React from 'react';

import classnames from 'clsx';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';

import StepIndicator from '../StepIndicator';

import StepInformation from './StepInformation';
import { StepPayment } from './StepPayment';

const INSURANCE_INFORMATION = 'insurance-information';
const PAYMENT = 'payment';

const componentMap = {
  [INSURANCE_INFORMATION]: StepInformation,
  [PAYMENT]: StepPayment,
};

const labelMap = {
  [INSURANCE_INFORMATION]: 'Insurance Information',
  [PAYMENT]: 'Payment',
};

const steps = [INSURANCE_INFORMATION, PAYMENT];
const stepNames = [labelMap[INSURANCE_INFORMATION], labelMap[PAYMENT]];

type Props = {
  orderId: string;
  order: App.Order;
  customer: App.User;
  onEmptyCart: () => void;
};

export default function Page(props: Props) {
  const { orderId, order, customer, onEmptyCart } = props;
  const { step, id } = useParams<{ id: string; step: string }>();
  const history = useHistory();

  const currentInsurance = order.insurance_items.find((insurance) => insurance.id_insurance_items === id);

  const getCurrentStepIdx = () => steps.indexOf(step);
  const getCurrentStepComponent = () => componentMap[step] || componentMap[steps[0]];

  const transitionStep = (nextStep: string, stepData?) => {
    history.push(`/users/${customer.memberId}/edit-orders/${orderId}/insurance/${id}/${nextStep}`, {
      ...stepData,
    });
  };
  const proceedStep = (stepData) => {
    const currentStepIdx = getCurrentStepIdx();
    const nextStep = steps[currentStepIdx + 1];

    if (nextStep) {
      transitionStep(nextStep, stepData);
    }
  };

  const getNextStepName = () => {
    const currentStepIdx = getCurrentStepIdx();
    const nextStep = steps[currentStepIdx + 1];

    return labelMap[nextStep];
  };

  const handleCancelOrderProcess = () => {
    onEmptyCart();
    history.push(`/purchases/${orderId}`);
  };

  const backStep = () => {
    const currentStepIdx = this.getCurrentStepIdx();
    const prevStep = steps[currentStepIdx - 1];

    if (!prevStep) {
      return handleCancelOrderProcess();
    }

    transitionStep(prevStep);
  };

  const Component = getCurrentStepComponent();

  return (
    <div className="new-order-page">
      <Helmet>
        <title>Users | {customer.fullName} | Update insurance</title>
      </Helmet>
      <div className="new-order-header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1 className="new-order-heading">Update insurance: {customer.fullName}</h1>
              <span className="user-email">{customer.email}</span>

              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleCancelOrderProcess();
                }}
                href="#cancel-order-process"
                className="cancel-btn"
              >
                Cancel
              </a>

              <StepIndicator currentStepIdx={getCurrentStepIdx()} steps={steps} stepNames={stepNames} />
            </div>
          </div>
        </div>
      </div>

      <main className={classnames('new-order-body', step)}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <Component
                allowToggleReservationType={false}
                proceedStep={proceedStep}
                backStep={backStep}
                nextStepLabel={getNextStepName()}
                customer={customer}
                orderId={orderId}
                order={order}
                insurance={currentInsurance}
                disableChangeCurrency
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
