import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Link, Stack, Typography } from '@mui/material';

import { ReserveForZeroDetails, getPaymentDueDate, getStripeSubscriptionLink } from './ReserveForZeroUtils';

interface Props {
  reserveForZeroDetails: ReserveForZeroDetails;
}

const OrderReserveForZeroDetails = ({ reserveForZeroDetails }: Props) => {
  return (
    <Accordion>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Reserve For $0 Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <div>
            <Typography variant="h6" fontWeight="bold">
              Status
            </Typography>
            <Typography>{reserveForZeroDetails.booking_status}</Typography>
          </div>
          <div>
            <Typography variant="h6" fontWeight="bold">
              Payment due date
            </Typography>
            <Typography>{getPaymentDueDate(reserveForZeroDetails)}</Typography>
          </div>
          <div>
            <Typography variant="h6" fontWeight="bold">
              Stripe subscription link
            </Typography>
            <Typography>
              <Link href={getStripeSubscriptionLink(reserveForZeroDetails)} target="_blank" rel="noreferrer">
                {getStripeSubscriptionLink(reserveForZeroDetails)}
              </Link>
            </Typography>
          </div>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderReserveForZeroDetails;
