import React from 'react';

import classnames from 'clsx';

export default class PrimaryGuestName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getGuestName();
  }

  getGuestName() {
    if (this.props.lastname) {
      this.setState({
        firstname: this.props.firstname,
        lastname: this.props.lastname,
      });
    }
  }

  onChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.setPrimaryGuest({ ...this.state });
      },
    );
  }

  render() {
    const { isBookLater } = this.props;
    return [
      <div className={classnames('guest-name booking-form-item')} key="0">
        <label>First Name</label>
        <input
          type="text"
          name="firstname"
          placeholder={isBookLater ? 'Book Later' : 'John'}
          value={this.state.firstname}
          className={classnames('guest-name booking-input data-hj-whitelist')}
          onChange={this.onChange}
          disabled={isBookLater}
        />
      </div>,
      <div className={classnames('guest-name booking-form-item')} key="1">
        <label>Last Name</label>
        <input
          type="text"
          name="lastname"
          placeholder={isBookLater ? 'Book Later' : 'Doe'}
          value={this.state.lastname}
          className={classnames('guest-name booking-input data-hj-whitelist')}
          onChange={this.onChange}
          disabled={isBookLater}
        />
      </div>,
    ];
  }
}
