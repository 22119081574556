import React from 'react';

import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import JsonDetails from '~/components/Common/Blocks/JsonDetails';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

interface Props {
  data: any;
}

const columns: GridColDef[] = [
  {
    field: 'payment_plan_group_id',
    headerName: 'Transaction ID',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    sortable: false,
    disableColumnMenu: true,
    display: 'flex',
  },
  {
    field: 'type',
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    disableColumnMenu: true,
    display: 'flex',
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(value),
    display: 'flex',
  },
  {
    field: 'additional_info',
    headerName: 'Additional Info',
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    renderCell: (params: GridRenderCellParams<unknown, Record<string, unknown> | null>) => (
      <>{params.value && <JsonDetails title="Additional info" data={params.value} />}</>
    ),
    display: 'flex',
  },
  {
    field: 'external_ref',
    headerName: 'External Ref',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      switch (params.row.type) {
        case 'promo':
          return <Link to={`/promos/${params.value}`}>{params.value}</Link>;
        default:
          return params.value;
      }
    },
    display: 'flex',
  },
];

export default function OrderDetailPaymentPlanLogs({ data }: Props) {
  return (
    <Box mt={2}>
      <FoldableSection title="Payment plan log" initiallyExpanded>
        <DataGrid
          columns={columns}
          rows={data}
          getRowId={(row) => row.payment_plan_component_id}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </FoldableSection>
    </Box>
  );
}
