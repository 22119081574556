import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { insuranceProviders } from '~/consts/insuranceProviders';

import { queueInsuranceBookingsReport } from '~/services/ReportingService';
import { formatDateISO, startOfMonth, subMonths } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    recordInsuranceProviders: {
      title: 'Filter by insurance provider',
      type: 'array',
      items: {
        title: 'Insurance Provider',
        type: 'string',
        enum: insuranceProviders,
        uniqueItems: true,
      },
    },
  },
};

const formData = {
  startAt: formatDateISO(startOfMonth(subMonths(1))),
  endAt: formatDateISO(startOfMonth()),
  format: 'email',
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportInsuranceBookingsReportForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt, recordInsuranceProviders, format } = event.formData;

    queueInsuranceBookingsReport({
      startAt,
      endAt,
      recordInsuranceProviders,
      format,
    })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <div>
      <Form
        schema={formSchema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={handleSubmit}
        validator={validator}
        showErrorList={false}
      >
        <Button type="submit" variant="contained">
          Email Insurance Bookings Report
        </Button>

        {error && <ErrorDisplay message={error} />}

        {successMessage && (
          <Alert severity="success">
            <p>Your report is queued for delivery and will be emailed shortly.</p>
          </Alert>
        )}
      </Form>
    </div>
  );
}
