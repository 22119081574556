import { ROOM_RATE_LPC_TYPE } from '~/consts/ratePlans';
import ReservationService from '../../../../../services/ReservationService';
import {
  COPY_OPERATION_INCLUDED_GUESTS,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM,
  COPY_OPTIONS_MODE_ALL_RATES,
} from '../../constants';

const NO_RATES_MESSAGE = 'no room rates found for this option!';
const NO_INCLUDED_GUESTS_MESSAGE = 'no included_guests found to copy!';

async function copyIncludedGuests(mode, included_guests, propertyId, roomRate, roomType, advacePayload) {
  switch (mode) {
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM: {
      const roomRatesLPC = getRatesForRoomAndProductType(roomType, roomRate, ROOM_RATE_LPC_TYPE);
      try {
        await callCreateOperationInBulk(roomRatesLPC, included_guests, propertyId, roomType.id, advacePayload);
      } catch (e) {
        throw new Error(
          `Error while copying room rate included_guests across all Lux Premium Collection rates under this room: ${e.message}`,
        );
      }
      break;
    }
    case COPY_OPTIONS_MODE_ALL_RATES:
    default: {
      const roomRates = getRatesForAll(roomType, roomRate);
      try {
        await callCreateOperationInBulk(roomRates, included_guests, propertyId, roomType.id, advacePayload);
      } catch (e) {
        throw new Error(`Error while copying room rate included_guests under this room: ${e.message}`);
      }
      break;
    }
  }
}

function getRatesForRoomAndProductType(roomType, roomRate, productType) {
  return roomType.room_rates.filter((rate) => {
    return rate.id !== roomRate.id && rate.rate_plan.product_type === productType;
  });
}

function getRatesForAll(roomType: App.RoomType, roomRate: App.RoomRate) {
  return roomType.room_rates.filter((rate) => rate.id !== roomRate.id);
}

const validate = (rates, included_guests) => {
  if (!rates.length) {
    throw new Error(NO_RATES_MESSAGE);
  }
  if (!included_guests.length) {
    throw new Error(NO_INCLUDED_GUESTS_MESSAGE);
  }
};

async function callCreateOperationInBulk(roomRates, included_guests, propertyId, roomTypeId, advancePayload) {
  validate(roomRates, included_guests);
  const payload = advancePayload ? { included_guests, advanced: advancePayload } : { included_guests };

  await Promise.all(
    roomRates.map(async (roomRate) => {
      await ReservationService.createOperationInBulk(
        payload,
        propertyId,
        roomTypeId,
        roomRate.id,
        COPY_OPERATION_INCLUDED_GUESTS,
      );
    }),
  );
}

export default copyIncludedGuests;
