import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { formatDateOrdinalWithClock } from '~/services/TimeService';
import userService from '~/services/UsersService';
import bookingService, { Booking } from '~/services/cruises/BookingInfoService';

import { SearchFilters } from './constants';

const BRAND = 'luxuryescapes';

interface Props {
  page: number;
  brand: App.Brands;
  sizePerPage: number;
  currentPage: number;
  searchFilters: SearchFilters;
  onPageChange: (page: number) => void;
}

export type FetchBookingsParams = {
  customerId?: string;
  sessionId?: string;
  orderId?: string;
  departureId?: string;
  bookingNumber?: string;
  page: number;
  itemsPerPage: number;
};

interface BookingWithUserSummary extends Omit<Booking, 'customerId' | 'spoofedBy'> {
  spoofedBy: App.UserSummary | string | null;
  customer: App.UserSummary | string | null;
}

type BookingListResponseWithUserSummary = CruisesContract.Response<BookingWithUserSummary[]>;

const DEFAULT_ROWS_RESPONSE: BookingListResponseWithUserSummary = { result: [], total: 0, status: 200 };

const columns: GridColDef[] = [
  {
    flex: 1.5,
    field: 'bookingNumber',
    headerName: 'Booking Number',
    display: 'flex',
    resizable: true,
  },
  {
    flex: 2,
    field: 'sessionId',
    headerName: 'Session ID',
    display: 'flex',
    resizable: true,
  },
  {
    flex: 2,
    field: 'orderId',
    headerName: 'Order ID',
    resizable: true,
    renderCell: (params: GridRenderCellParams<BookingWithUserSummary>) =>
      params.row.orderId && (
        <Link component={RouterLink} to={`/purchases/${params.row.orderId}`} title={params.row.orderId}>
          {params.row.orderId}
        </Link>
      ),
    display: 'flex',
  },
  {
    flex: 2,
    field: 'customerId',
    headerName: 'Customer ID',
    resizable: true,
    display: 'flex',
    renderCell: (params: GridRenderCellParams<BookingWithUserSummary>) => getFormattedUser(params.row.customer),
  },
  {
    flex: 2,
    field: 'spoofedBy',
    headerName: 'Spoofed By',
    resizable: true,
    display: 'flex',
    renderCell: (params: GridRenderCellParams<BookingWithUserSummary>) => getFormattedUser(params.row.spoofedBy),
  },
  {
    flex: 2,
    field: 'departureId',
    headerName: 'Departure ID',
    resizable: true,
    renderCell: (params: GridRenderCellParams<BookingWithUserSummary>) => (
      <Link component={RouterLink} to={`/cruises/sailings/${params.row.departureId}`} title={params.row.departureId}>
        {params.row.departureId}
      </Link>
    ),
    display: 'flex',
  },
  {
    flex: 1.5,
    field: 'bookingDate',
    headerName: 'Booking Date',
    resizable: true,
    display: 'flex',
    renderCell: (params: GridRenderCellParams<BookingWithUserSummary>) =>
      formatDateOrdinalWithClock(params.row.bookingDate),
  },
  {
    flex: 1,
    field: 'status',
    headerName: 'Status',
    resizable: true,
    display: 'flex',
  },
  {
    flex: 1,
    field: 'id',
    headerName: '',
    resizable: true,
    renderCell: (params: GridRenderCellParams<BookingWithUserSummary>) => (
      <Button
        variant="text"
        size="small"
        href={`/cruises/bookings/${params.row.id}/logs`}
        target="_blank"
        rel="noreferrer"
      >
        View Logs
      </Button>
    ),
    display: 'flex',
  },
];

const getFormattedUser = (user: App.UserSummary | string | null) => {
  if (!user) {
    return null;
  }

  if (typeof user === 'string') {
    return (
      <Link component={RouterLink} to={`/users/${user}`} title={user}>
        {user}
      </Link>
    );
  }

  return (
    <Stack direction="column" overflow="hidden">
      <Link component={RouterLink} to={`/users/${user.id_member}`} title={user.email} noWrap>
        {user.email}
      </Link>
      <Typography variant="body1" title={user.full_name} noWrap>
        {user.full_name}
      </Typography>
    </Stack>
  );
};

const getUniqueUserIds = (bookings: Booking[]): string[] => {
  const userIdsSet = bookings.reduce((acc: Set<string>, booking: Booking) => {
    if (booking.customerId && !acc.has(booking.customerId)) {
      acc.add(booking.customerId);
    }

    if (booking.spoofedBy && !acc.has(booking.spoofedBy)) {
      acc.add(booking.spoofedBy);
    }

    return acc;
  }, new Set<string>());

  return Array.from(userIdsSet);
};

const injectUsersIntoBookings = async (bookings: Booking[]): Promise<BookingWithUserSummary[]> => {
  const uniqueUserIds = getUniqueUserIds(bookings);
  const mappedBookings = bookings.map(({ customerId, ...restOfTheBooking }: Booking) => ({
    ...restOfTheBooking,
    customer: customerId,
  }));

  try {
    const usersMap: Map<string, App.UserSummary> = await userService.getUsersSummaryByIds(uniqueUserIds, BRAND);

    if (!usersMap || usersMap.size === 0) {
      return mappedBookings;
    }

    return mappedBookings.map((booking) => ({
      ...booking,
      customer: usersMap.get(booking.customer) ?? booking.customer,
      spoofedBy: usersMap.get(booking.spoofedBy) ?? booking.spoofedBy,
    }));
  } catch (error) {
    return mappedBookings;
  }
};

export default function BookingList(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { brand, page, currentPage, searchFilters, sizePerPage, onPageChange } = props;

  const [rows, setRows] = useState<BookingListResponseWithUserSummary>(DEFAULT_ROWS_RESPONSE);
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');

  const fetchData = useCallback(
    async (params: FetchBookingsParams & { searchFilters: SearchFilters }): Promise<void> => {
      const { searchFilters } = params;

      setFetching('loading');

      const queryParams = {
        take: params.itemsPerPage,
        skip: (params.page - 1) * params.itemsPerPage,
        bookingNumber: searchFilters.bookingNumber ? searchFilters.bookingNumber : undefined,
        sessionId: searchFilters.sessionId ? searchFilters.sessionId : undefined,
        orderId: searchFilters.orderId ? searchFilters.orderId : undefined,
        customerId: searchFilters.customerId ? searchFilters.customerId : undefined,
        departureId: searchFilters.departureId ? searchFilters.departureId : undefined,
      };

      try {
        const bookingsResponse = await bookingService.getBookings(queryParams);

        if (!bookingsResponse || bookingsResponse.result.length === 0) {
          enqueueSnackbar('No results found', { autoHideDuration: 5000, variant: 'warning' });
          return;
        }

        const bookingsWithUsers = await injectUsersIntoBookings(bookingsResponse.result);

        setRows({
          total: bookingsResponse.total,
          result: bookingsWithUsers,
          status: bookingsResponse.status,
        });

        setFetching('success');
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });
        setRows({ total: 0, result: [], status: error.status });
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchData({
      itemsPerPage: sizePerPage,
      searchFilters,
      page: currentPage,
    });
  }, [currentPage, fetchData, searchFilters, sizePerPage, brand]);

  return (
    <DataGrid
      pagination
      autoHeight
      columns={columns}
      disableColumnMenu
      disableColumnFilter
      rows={rows.result}
      rowCount={rows.total}
      paginationMode="server"
      disableRowSelectionOnClick
      getRowHeight={() => 'auto'}
      pageSizeOptions={[sizePerPage]}
      loading={fetching === 'loading'}
      slots={{ pagination: GridPagination }}
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
    />
  );
}
