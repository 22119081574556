import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import useHeroPlannerCountryStates from '~/hooks/customerCommunication/useHeroPlannerCountryStates';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props {
  countryGroupId: string;
  countryId: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
}

function ScheduleStateInput(props: Props) {
  const { countryGroupId, countryId, name, value, onChange } = props;
  const { countryStatesReq } = useHeroPlannerCountryStates(countryGroupId, countryId);
  const options = countryStatesReq?.result ?? EmptyArray;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (options.length && (!value || !options.find((i) => i.id === value))) {
      onChange(options[0].id);
    }
  }, [onChange, options, value]);

  const initialCountryState = useMemo<CustomerCommunication.HeroPlannerCountryState | undefined>(() => {
    if (value && !options.find((v) => v.id === value)) return { id: value, name: value };
  }, [options, value]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink>State</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        required
        variant="standard"
        disabled={isRequestUnresolved(countryStatesReq) || isRequestRejected(countryStatesReq)}
        error={isRequestRejected(countryStatesReq)}
      >
        {!!initialCountryState && (
          <MenuItem value={initialCountryState.id}>
            <i>{initialCountryState.name}</i>
          </MenuItem>
        )}
        {options.map((state) => (
          <MenuItem key={state.id} value={state.id}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
      {isRequestRejected(countryStatesReq) && <FormHelperText error>{countryStatesReq.error}</FormHelperText>}
      {isRequestPending(countryStatesReq) && <LinearProgress sx={{ position: 'absolute' }} />}
    </FormControl>
  );
}

export default ScheduleStateInput;
