import React, { useCallback, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Box, Button, Container, Stack, TextField } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ImagesForm from '~/components/Common/Forms/ImagesForm';
import Spinner from '~/components/Common/Spinner';

import shipService from '~/services/cruises/ShipService';

interface image {
  id: string | number;
  publicImageId: string;
  order: number;
  title?: string;
  hidden?: boolean;
  filename?: string;
}

const ShipImageEditor = () => {
  const [isReady, setIsReady] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [images, setImages] = React.useState<image[]>([]);
  const [ship, setShip] = React.useState<CruisesContract.Ship>(null);
  const [cruiseCriticId, setCruiseCriticId] = React.useState<number>(null);

  const { shipId, shipName } = useParams<{
    shipId: string;
    shipName: string;
  }>();

  const fetchCruiseDetails = async () => {
    const [shipResponse, shipImagesResponse] = await Promise.all([
      shipService.getShipById(shipId),
      shipService.getShipImages(shipId),
    ]);

    const { result: ship } = shipResponse;
    const { result: images } = shipImagesResponse;

    setShip(ship);
    setCruiseCriticId(ship.cruiseCriticId);
    setImages(
      images
        .map((image) => ({
          ...image,
          id: image.idImage,
          publicImageId: image.idCloudinaryExternal,
        }))
        .sort((a, b) => a.order - b.order),
    );
    setIsReady(true);
  };

  const onEditShip = async () => {
    if (!cruiseCriticId) return;

    setIsFetching(true);
    const { result: updatedShip } = await shipService.updateShip(shipId, cruiseCriticId);

    setShip(updatedShip);
    setIsFetching(false);
  };

  const onAddImage = async (cloudinaryId: string, filename?: string) => {
    const newImage: CruisesContract.ShipImage = await shipService.createShipImage(shipId, {
      idCloudinaryExternal: cloudinaryId,
      filename: filename ? filename : undefined,
    });

    newImage.id = newImage.idImage;
    return newImage;
  };

  const onUpdateImages = (newImageList) => {
    const updatePayload = newImageList
      .filter((image) => image.idCloudinaryExternal)
      .map((image) => ({
        idImage: image.id,
        idCloudinaryExternal: image.idCloudinaryExternal,
        order: image.order,
      }));

    if (!updatePayload.length) return Promise.resolve();
    return shipService.updateShipImages(shipId, updatePayload);
  };

  const onDeleteImage = (imageId) => {
    return shipService.deleteShipImage(shipId, imageId);
  };

  useEffect(() => {
    fetchCruiseDetails();
  }, []);

  const handleEditableCruiseCriticId = useCallback((e) => {
    setCruiseCriticId(Number(e.target.value));
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Ships | {shipName || shipId}</title>
      </Helmet>

      <PageHeader title="Edit Ship" backButton="/cruises/ships" />

      {!isReady && <Spinner />}

      {isReady && (
        <Box>
          <form>
            <PageSubheader title="Ship overview" />

            <Stack spacing={2} direction="column">
              <Stack spacing={2} direction="row">
                <TextField fullWidth disabled label="Ship Name" value={ship.name} InputProps={{ readOnly: true }} />
                <TextField
                  type="number"
                  fullWidth
                  disabled={isFetching}
                  label="Cruise Critic ID"
                  value={cruiseCriticId}
                  defaultValue={ship.cruiseCriticId}
                  onChange={handleEditableCruiseCriticId}
                />
              </Stack>

              <Box>
                <Button variant="contained" disabled={isFetching} onClick={onEditShip}>
                  Save Changes
                </Button>
              </Box>
            </Stack>
          </form>

          <Box mt={4}>
            <PageSubheader title="Ship Images" />

            <ImagesForm
              images={images}
              onAddImage={onAddImage}
              onUpdateImages={onUpdateImages}
              onDeleteImage={onDeleteImage}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ShipImageEditor;
