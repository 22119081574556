import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { emptyCart, setCustomer } from '../../../../actions/cart';
import UsersService from '../../../../services/UsersService';
import Spinner from '../../../Common/Spinner';

import AddItemPage from './AddItemPage';

class AddItemPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
    };
  }

  componentDidMount() {
    const { doSetCustomer, match } = this.props;

    // Restore the initial state for the cart in the beginning of the purchase flow
    emptyCart();
    UsersService.getUser(match.params.id_user).then((user) => {
      doSetCustomer(user);

      this.setState({ isReady: true });
    });
  }

  render() {
    const { doEmptyCart, customer, match } = this.props;
    const { isReady } = this.state;

    if (!isReady) {
      return <Spinner />;
    }

    return <AddItemPage orderId={match.params.id_orders} user={customer} onEmptyCart={doEmptyCart} {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    customer: state.cart.customer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doEmptyCart: emptyCart,
      doSetCustomer: setCustomer,
    },
    dispatch,
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddItemPageContainer));
