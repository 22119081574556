import React from 'react';

import { CheckCircle } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import LoopIcon from '@mui/icons-material/Loop';
import { Box, TableCell, TableRow, Tooltip } from '@mui/material';

import { EndpointStatus } from './SearchHealthCheck';

interface Props {
  position: number | 'N/A';
  status: EndpointStatus;
  title: string;
  tooltipText: string;
}

function HealthCheckRow(props: Props) {
  const { position, status, title, tooltipText } = props;
  return (
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title={tooltipText}>
            <InfoIcon />
          </Tooltip>
          <b>{title}</b>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="center">
          {status === 'loading' && <LoopIcon color="info" />}
          {status === 'success' && <CheckCircle color="success" />}
          {status === 'failed' && <CancelIcon color="error" />}
        </Box>
      </TableCell>
      <TableCell>
        <Box textAlign="center">{position}</Box>
      </TableCell>
    </TableRow>
  );
}

export default HealthCheckRow;
