import React from 'react';

import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

const columns: GridColDef<App.Vendor>[] = [
  {
    field: 'sfid',
    headerName: 'Vendor ID',
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'name',
    headerName: 'Vendor Name',
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

type Props = {
  data: App.Vendor[];
  onRowClick: (vendor: App.Vendor) => void;
  onPageChange: (page: number) => void;
  page: number;
  total: number;
  pageSize: number;
};

export default function VendorSearchResults({ onRowClick, onPageChange, page, total, data, pageSize }: Props) {
  return (
    <DataGrid
      className="T-search-vendor-result"
      rows={data}
      columns={columns}
      getRowId={(row) => row.sfid}
      getRowClassName={() => 'T-search-vendor-row'}
      onRowClick={(params: GridRowParams<App.Vendor>) => onRowClick(params.row)}
      rowCount={total}
      paginationMode="server"
      onPaginationModelChange={({ page }) => onPageChange(page)}
      pageSizeOptions={[pageSize]}
      paginationModel={{ page, pageSize }}
      slots={{ pagination: GridPagination }}
      disableColumnMenu
      disableColumnFilter
      autoHeight
    />
  );
}
