import React from 'react';

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';

import {
  bedbankCheckInFormatter,
  bedbanksOrderPropertyFormatter,
  checkInFormatter,
  customerDataFormatter,
  locationFormatter,
  orderDetailFormatter,
  orderStatusFormatter,
  orderTotalFormatter,
  packageInformationFormatter,
} from './formatters';

interface Props {
  customerEmail: string;
  onPageChange: (page: number) => void;
  page: number;
  purchases: API.Order.Order[];
  rerender: () => void;
  sizePerPage: number;
  total: number;
}

const getCheckIn = (params: GridRenderCellParams<App.Order>) => {
  if (params.row.items.length > 0) {
    return checkInFormatter(params);
  }

  if (params.row.bedbank_items.length > 0) {
    return bedbankCheckInFormatter(params);
  }

  return '';
};

const getLocation = (params: GridRenderCellParams<App.Order>) => {
  if (params.row.items.length > 0) {
    return locationFormatter(params);
  }

  if (params.row.bedbank_items.length > 0) {
    return bedbanksOrderPropertyFormatter(params);
  }

  return '';
};

const columns: GridColDef[] = [
  {
    field: 'fk_customer_id',
    headerName: 'Customer',
    sortable: false,
    flex: 2,
    renderCell: customerDataFormatter,
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Order Status',
    sortable: false,
    flex: 2,
    renderCell: orderStatusFormatter,
    display: 'flex',
  },
  { field: 'location', headerName: 'Location', sortable: false, flex: 1, renderCell: getLocation, display: 'flex' },
  {
    field: 'checkin',
    headerName: 'Check In / Departure Dates',
    sortable: false,
    width: 200,
    renderCell: getCheckIn,
    display: 'flex',
  },
  {
    field: 'package_information',
    headerName: 'Package Information',
    sortable: false,
    width: 200,
    renderCell: packageInformationFormatter,
    display: 'flex',
  },
  { field: 'currency_code', headerName: 'Currency', sortable: false, display: 'flex' },
  {
    field: 'total',
    headerName: 'Order Total',
    sortable: false,
    width: 120,
    renderCell: orderTotalFormatter,
    display: 'flex',
  },
  {
    field: 'id',
    headerName: 'View Order',
    sortable: false,
    width: 120,
    renderCell: orderDetailFormatter,
    display: 'flex',
  },
];

export default function InsurancesPageContainer(props: Props) {
  const { purchases, total, sizePerPage, onPageChange, page } = props;

  return (
    <DataGrid
      rows={purchases}
      rowCount={total}
      columns={columns}
      pagination
      paginationMode="server"
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      pageSizeOptions={[sizePerPage]}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      getRowHeight={() => 'auto'}
      slots={{ pagination: GridPagination }}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}
