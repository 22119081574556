import React from 'react';

import { updateOrderItem } from '~/services/OrdersService';

import { reportError } from '~/utils/reportError';

interface Props {
  orderId: string;
  cartItem: App.CartItem;
  onAfterPutOnHold: () => void;
  onCancel: () => void;
}

interface State {
  isReady: boolean;
  error?: {
    message: string;
    errors: Record<string, unknown>;
  };
  isProcessing: boolean;
  isConfirming: boolean;
}

export default class PutOnHold extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      error: null,
      isProcessing: false,
      isConfirming: false,
    };
  }

  handleChange = () => {
    const { onAfterPutOnHold, cartItem, orderId } = this.props;

    this.setState({ isProcessing: true }, () => {
      updateOrderItem(orderId, cartItem.id, { op: 'put_tour_on_hold' })
        .then(onAfterPutOnHold)
        .then(() => {
          this.setState({ isProcessing: false });
        })
        .catch((e) => {
          reportError(e);
          this.setState({ error: e });
        });
    });
  };

  render() {
    const { onCancel } = this.props;
    const { isProcessing, error } = this.state;

    if (error) {
      return (
        <div className="alert alert-danger">
          <div>Error occurred: {error.message}</div>
          {error.errors && <div>Please report the following: {JSON.stringify(error.errors)}</div>}
        </div>
      );
    }

    return (
      <div className="booking-form">
        <div>
          <p>Note that this will put this booking on hold.</p>
        </div>
        <div className="booking-item-footer">
          <button className="btn btn-lg btn-link" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn btn-lg btn-link T-Submit-Change-Date"
            disabled={isProcessing}
            onClick={this.handleChange}
          >
            Confirm Change
          </button>
        </div>
      </div>
    );
  }
}
