import React from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
} from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { asiaRegions } from '~/consts/allRegions';

interface Props {
  open: boolean;
  onRequestClose: () => void;
  onSet: (pureRegions: string[]) => void;
  regions: string[];
  name: string;
  label: string;
  saveButton?: string;
}

interface State {
  regions: Region[];
}

interface Region {
  name: string;
  code: string;
  isChecked: boolean;
}

class SetRegionModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedRegions = props.regions;

    const regions = libRegions.getRegionNamesAndCode().map((region) => {
      return {
        ...region,
        isChecked: selectedRegions.includes('world') || selectedRegions.includes(region.code),
      };
    });

    this.state = {
      regions: regions,
    };

    this.selectAll = this.selectAll.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
    this.selectAsiaRegions = this.selectAsiaRegions.bind(this);
    this.getCheckedRegions = this.getCheckedRegions.bind(this);
  }

  getCheckedRegions(): string[] {
    return this.state.regions.flatMap((region) => (region.isChecked ? [region.code] : []));
  }

  changeSelection(value) {
    this.setState((prevState) => {
      return {
        regions: prevState.regions.map((region) => {
          return {
            ...region,
            isChecked: value,
          };
        }),
      };
    });
  }

  selectAll() {
    this.changeSelection(true);
  }

  deselectAll() {
    this.changeSelection(false);
  }

  selectAsiaRegions(isAsia) {
    this.setState((prevState) => {
      return {
        regions: prevState.regions.map((region) =>
          asiaRegions.includes(region.name)
            ? {
                ...region,
                isChecked: isAsia,
              }
            : {
                ...region,
                isChecked: !isAsia,
              },
        ),
      };
    });
  }

  regionsChanges(e, region) {
    const checked = e.target.checked;
    const name = region.name;

    this.setState((prevState) => {
      const updatedRegions = prevState.regions.map((region) => {
        if (region.name === name) {
          const updatedRegion = {
            ...region,
            isChecked: checked,
          };
          return updatedRegion;
        }

        return region;
      });

      return { regions: updatedRegions };
    });
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onRequestClose} scroll="paper" maxWidth="md" fullWidth>
        <DialogTitle id="scroll-dialog-title">{this.props.name}</DialogTitle>

        <DialogContent>
          <DialogContentText>{this.props.label}</DialogContentText>

          <Stack direction="row" spacing={2} mt={1}>
            <Button variant="text" onClick={this.selectAll}>
              Select all
            </Button>
            <Button variant="text" onClick={this.deselectAll}>
              Deselect all
            </Button>
            <Button variant="text" onClick={() => this.selectAsiaRegions(true)}>
              Asia only
            </Button>
            <Button variant="text" onClick={() => this.selectAsiaRegions(false)}>
              Non-Asian only
            </Button>
          </Stack>

          <Box mt={1} display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
            {this.state.regions.map((region) => (
              <FormControlLabel
                key={region.name}
                control={
                  <Checkbox
                    value={region.code}
                    checked={region.isChecked}
                    onChange={(e) => this.regionsChanges(e, region)}
                    sx={{ py: 0 }}
                  />
                }
                label={region.name}
              />
            ))}
          </Box>

          {this.props.children}
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onRequestClose} variant="text">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              this.props.onSet(this.getCheckedRegions());
            }}
          >
            {this.props.saveButton || 'Set Regions'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SetRegionModal;
