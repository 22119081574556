import React, { Component, Fragment } from 'react';

import { MarkdownPreview } from 'react-marked-markdown';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import { COPY_OPERATION_INCLUSIONS } from '~/components/Vendors/Properties/constants';

import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import { buttonMessages, buttonStates } from './states/submitButton';

const DEFAULT_VALUES = {
  description: '',
  amount: 1,
  currency: 'AUD',
  minimum_los: 0,
  maximum_los: 0,
  unit: 'per_stay',
  order: 0,
};

interface Props {
  vendorCurrencyCode: string;
  propertyId: string;
  roomTypeId: string;
  roomRate: API.Reservation.RoomRate;
  onCancel: () => void;
  onRoomRateInclusionBulkAdded: () => void;
  open: boolean;
  onHide: () => void;
}

interface State {
  saveState: string;
  inclusionsToCopy: API.Reservation.Inclusions[];
}

export default class RoomRateInclusionsForm extends Component<Props, State> {
  loading = false;

  constructor(props) {
    super(props);

    this.state = {
      saveState: buttonStates.default,
      inclusionsToCopy: [],
    };
  }

  componentDidMount() {
    const inclusions = [];
    if (this.props.roomRate.rate_plan.inclusions) {
      this.props.roomRate.rate_plan.inclusions.split('\n').forEach((inclusionDescription) => {
        inclusions.push({
          ...DEFAULT_VALUES,
          currency: this.props.vendorCurrencyCode,
          description: inclusionDescription,
          is_bonus: false,
        });
      });
    }

    if (this.props.roomRate.rate_plan.bonus_inclusions) {
      this.props.roomRate.rate_plan.bonus_inclusions.forEach((inclusion) => {
        inclusion.content.split('\n').forEach((inclusionDescription) => {
          inclusions.push({
            ...DEFAULT_VALUES,
            currency: this.props.vendorCurrencyCode,
            description: inclusionDescription,
            is_bonus: true,
            minimum_los: inclusion.from_nights,
            maximum_los: inclusion.to_nights,
          });
        });
      });
    }

    this.setState({
      inclusionsToCopy: inclusions,
    });
  }

  onConfirmToBulk = () => {
    if (this.loading) return;
    this.loading = true;

    ReservationService.createOperationInBulk(
      this.state.inclusionsToCopy,
      this.props.propertyId,
      this.props.roomTypeId,
      this.props.roomRate.id,
      COPY_OPERATION_INCLUSIONS,
    )
      .then(() => {
        this.setState({
          saveState: buttonStates.saved,
        });
        this.props.onRoomRateInclusionBulkAdded();
      })
      .catch((e) => {
        this.setState({
          saveState: buttonStates.failed,
        });
        reportError(e);
      })
      .finally(() => {
        this.loading = false;
      });
  };

  render() {
    const markdownPreview = this.state.inclusionsToCopy.map((incl) => incl.description).join('\n');

    return (
      <Fragment>
        <Dialog
          open={this.props.open}
          onClose={this.props.onHide}
          aria-labelledby="form-dialog-title"
          aria-describedby="scroll-dialog-description"
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="scroll-dialog-title">Confirm adding Rate Plan Inclusions</DialogTitle>

          <DialogContent>
            <DialogContentText>Next inclusions will be added to the list:</DialogContentText>

            <DialogContentText>
              <MarkdownPreview value={markdownPreview} />
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button type="button" variant="text" onClick={this.props.onCancel}>
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={this.onConfirmToBulk}
              disabled={this.state.saveState === buttonStates.saving}
            >
              {buttonMessages[this.state.saveState]}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
