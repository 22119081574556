import React from 'react';

import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';

import { Box, Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

const formSchema: RJSFSchema = {
  description: 'Please choose payment date period for export',
  type: 'object',
  properties: {
    startAt: {
      type: 'string',
      format: 'date',
      title: 'Select Start Date',
    },
    endAt: {
      type: 'string',
      format: 'date',
      title: 'Select End Date',
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  dateField: {
    'ui:widget': 'radio',
  },
};

const ExportRevelexPaymentsReportForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    const { startAt, endAt } = event.formData;

    try {
      enqueueSnackbar(`Downloading Revelex Payments Report...`, {
        variant: 'info',
      });

      await VendorsService.downloadRevelexPaymentsReport({
        startAt,
        endAt,
      });

      enqueueSnackbar(`Your report is queued for delivery and will be emailed shortly.`, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'error' });
    }
  };

  return (
    <Box>
      <Form
        schema={formSchema}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={handleSubmit}
        className="package-form"
      >
        <Button type="submit" variant="contained">
          Download Revelex Payments Report
        </Button>
      </Form>
    </Box>
  );
};

export default ExportRevelexPaymentsReportForm;
