import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import ReservationService from '~/services/ReservationService';

import { getRoomRatesFromPackageOptions } from '~/utils/getRoomRatesFromPackageOptions';
import { mapRoomRateToRatePlan } from '~/utils/mapRoomRateToRatePlan';

import { PackageRoomRate, SyncCancellationPolicyModalProps } from './types';

function decorateWithRoomRates(
  pkg: App.AccommodationPackage,
  propertyData: Reservation.Property[],
  newCancellationPolicy: string,
) {
  const { fk_room_rate_id, fk_property_id } = pkg;

  const defaultRoomRate = mapRoomRateToRatePlan({ propertyData, fk_property_id, fk_room_rate_id });

  const extraRates = getRoomRatesFromPackageOptions({ pkg, propertyData, fk_property_id });

  return {
    pkg,
    roomRateList: [...extraRates, defaultRoomRate]
      .filter((roomRate) => !!roomRate)
      .filter((roomRate) => roomRate.rate_plan.cancellation_policy !== newCancellationPolicy),
  };
}

export function useSyncCancellationPolicies({
  packages,
  propertyData,
  newCancellationPolicy,
  refreshData,
}: SyncCancellationPolicyModalProps) {
  const [packageRoomRates, setPackageRoomRates] = useState<PackageRoomRate[]>([]);
  const [syncState, setSyncState] = useState<Utils.FetchingState>('idle');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const roomRates = packages
      .map((pkg) => {
        return decorateWithRoomRates(pkg, propertyData, newCancellationPolicy);
      })
      .filter(({ roomRateList }) => roomRateList.length > 0);

    setPackageRoomRates(roomRates);
  }, [newCancellationPolicy, packages, propertyData]);

  const syncCancellationPolicies = useCallback(async () => {
    setSyncState('loading');
    let errorList = [];

    await Promise.all(
      packageRoomRates
        .flatMap((roomRate) => roomRate.roomRateList)
        .map((roomRate) => {
          const ratePlanId = roomRate.rate_plan.id;

          return ReservationService.updateRatePlan(
            { ...roomRate.rate_plan, cancellation_policy: newCancellationPolicy },
            ratePlanId,
          ).catch((error) => {
            errorList = [
              ...errorList,
              `Error syncing cancellation policy for rate plan: ${ratePlanId}. Error: ${JSON.stringify(error)}`,
            ];
          });
        }),
    );

    await refreshData();

    if (errorList.length > 0) {
      setSyncState('failed');
      enqueueSnackbar(errorList, { variant: 'error' });
    } else {
      setSyncState('success');
    }
  }, [enqueueSnackbar, newCancellationPolicy, packageRoomRates, refreshData]);

  const hasRatesToSync = useMemo(() => {
    return packageRoomRates.length > 0 && !!newCancellationPolicy;
  }, [newCancellationPolicy, packageRoomRates.length]);

  return { syncCancellationPolicies, syncState, setPackageRoomRates, packageRoomRates, hasRatesToSync };
}
