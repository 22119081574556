import isObject from './isObject';

function nullsToEmptyStrings(data) {
  let newData = data;
  if (isObject(data)) {
    newData = Object.assign({}, data);
    Object.keys(newData).forEach((key) => {
      if (newData[key] === null) {
        newData[key] = '';
      } else {
        newData[key] = nullsToEmptyStrings(newData[key]);
      }
    });
  } else if (Array.isArray(data)) {
    newData = newData.map(nullsToEmptyStrings);
  }
  return newData;
}

export default nullsToEmptyStrings;
