import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueDetailedBookings } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

function validateForm(formData, errors) {
  if (formData && !formData.endAt) {
    errors.endAt.addError('Please enter end at');
  }
  if (formData && !formData.idOffer && !formData.leIdOffer) {
    errors.idOffer.addError('Please enter an Offer ID');
    errors.leIdOffer.addError('Please enter an Le Offer ID');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please enter a date to export all Redemption only bookings before the selected date',
  type: 'object',
  properties: {
    endAt: {
      title: 'Select Date',
      type: 'string',
      format: 'date',
    },
    idOffer: {
      title: 'Offer ID',
      type: 'string',
    },
    leIdOffer: {
      title: 'Le Offer ID',
      type: 'string',
    },
  },
  required: ['endAt'],
};

const uiSchema = {
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportDetailedBookingsReportForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      await queueDetailedBookings({
        endAt: event.formData.endAt,
        idOffer: event.formData.idOffer,
        leIdOffer: event.formData.leIdOffer,
      });
      setSuccessMessage(true);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Detailed Bookings Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
