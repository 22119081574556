import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { emailPromoCodeSpend } from '~/services/ReportingService';
import { diffDays } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

function validate(formData, errors) {
  if (!formData.startAt) {
    errors.startAt.addError('Please enter start at date');
  }
  if (!formData.endAt) {
    errors.endAt.addError('Please enter end at');
  }

  const startDate = new Date(formData.startAt);
  const endDate = new Date(formData.endAt);

  if (startDate >= endDate) {
    errors.startAt.addError('Start date should be before end date');
  } else if (diffDays(startDate, endDate, 'months') > 2) {
    errors.startAt.addError('Dates range should be less than 3 months');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    startAt: {
      title: 'Start at',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'End at',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportPromoCodeSpendForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt } = event.formData;

    emailPromoCodeSpend({ startAt, endAt })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch(function (e) {
        if (e.name == 'Empty data set') {
          setError("There aren't promo codes spent for this period");
        } else {
          setError('Error: ' + e.message);
        }
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validate}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Promo Code Spend Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
