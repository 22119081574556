import React, { useEffect, useState } from 'react';

import { Form } from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { Box, Button, Dialog, DialogContent } from '@mui/material';

import { getRegionByCurrency } from '@luxuryescapes/lib-regions';

import { PROMO_DEVICE_TYPES } from '~/consts/promo';

import { InternalPromoWithDiscounts, getPromoMeta, quickAddPromo, quickAddPromoOptions } from '~/services/PromoService';

import { getPromoTagArrays } from '~/utils/promoTags';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  user: App.User;
}

interface QuickPromoPayload {
  promoData: Partial<InternalPromoWithDiscounts>;
  discountData: {
    discount_value: number;
    region: string;
  };
}

const CreatePromoForUserModal = ({ isModalOpen, closeModal, user }: Props) => {
  const [baseSchema, setBaseSchema] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [promoTags, setPromoTags] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>(null);
  const [formData, setFormData] = useState(null);
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { enqueueSnackbar } = useSnackbar();

  const formSchema = (baseSchema: RJSFSchema, selectedDepartment, selectedCategory): RJSFSchema => {
    if ('post' in baseSchema && 'body' in baseSchema.post && 'properties' in baseSchema.post.body) {
      const tagArrays = getPromoTagArrays({ promoTags, selectedDepartment, selectedCategory });

      delete baseSchema.post.body.properties.department_tag;

      const requiredFields = baseSchema.post.body.required
        .filter((field) => field !== 'department_tag')
        .concat(['dept_tag']);

      const res: RJSFSchema = {
        title: 'Promo Code',
        type: 'object',
        properties: {
          ...baseSchema.post.body.properties,
          dept_tag: {
            title: 'Department Tag',
            enum: tagArrays.departmentTags,
          },
        },
        required: requiredFields,
      };

      if (tagArrays.categoryTags.length > 0) {
        res.properties = {
          ...res.properties,
          category_tag: {
            title: 'Category Tag',
            enum: tagArrays.categoryTags,
          },
        };
        res.required.push('category_tag');
      }

      if (tagArrays.subCategoryTags.length > 0) {
        res.properties = {
          ...res.properties,
          sub_category_tag: {
            title: 'Sub Category Tag',
            enum: tagArrays.subCategoryTags,
          },
        };
        res.required.push('sub_category_tag');
      }
      return res;
    } else {
      console.error('invalid form schema?');
    }
  };

  const handleFormChange = (evt) => {
    if (evt && 'formData' in evt && evt.formData !== undefined && 'dept_tag' in evt.formData) {
      setSelectedDepartment(evt.formData.dept_tag);

      if ('formData' in evt && 'category_tag' in evt.formData) {
        setSelectedCategory(evt.formData.category_tag);
      }
    }
    setFormData(evt.formData);
  };

  useEffect(() => {
    Promise.all([quickAddPromoOptions(), getPromoMeta()]).then(([schemaData, promoMeta]) => {
      const promo_tags = promoMeta.result.promo_tags;

      setPromoTags(promo_tags);
      setBaseSchema(schemaData);

      setHasLoaded(true);
    });
  }, []);

  const onSubmit = async (form) => {
    const {
      code_name,
      currency,
      promo_type,
      discount_value,
      department_tag,
      description,
      expires_at,
      case_id,
      dept_tag,
      category_tag,
      sub_category_tag,
    } = form.formData;
    try {
      const quickUpdatePromo: QuickPromoPayload = {
        promoData: {
          code_name,
          promo_type,
          description,
          expires_at,
          code_limit: 1,
          brand: brand as QuickPromoPayload['promoData']['brand'],
          email_addresses: [user.email],
          device_types: PROMO_DEVICE_TYPES.filter((device) => device.key !== 'all').map((item) => item.key),
          limit_per_user: 1,
          department_tag,
          currency,
          case_id,
          dept_tag,
          category_tag,
          sub_category_tag,
        },
        discountData: {
          discount_value,
          region: getRegionByCurrency(currency).code,
        },
      };

      await quickAddPromo(quickUpdatePromo);
      await navigator.clipboard.writeText(code_name);
      enqueueSnackbar(`${brand}: Promo code created and copied to clipboard!`, {
        variant: 'success',
      });
      closeModal();
    } catch (e) {
      const errorMessage = e.errors.length ? e.errors[0] : 'Unexpected error';
      enqueueSnackbar(`${brand}: ${errorMessage}`, { variant: 'error' });
    }
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogContent>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={closeModal}>
            X
          </Button>
        </Box>
        {hasLoaded && baseSchema && (
          <Form
            schema={formSchema(baseSchema, selectedDepartment, selectedCategory)}
            onChange={handleFormChange}
            onSubmit={onSubmit}
            validator={validator}
            formData={formData}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreatePromoForUserModal;
