import React, { Fragment } from 'react';

import currencyFormatter from 'currency-formatter';

import { Box } from '@mui/material';

import RefundMethod from './RefundMethod';
import SubmitButton from './SubmitButton';
import TourAutomaticRefundDetails from './TourAutomaticRefundDetails';

export default class TourAutomaticRefund extends React.Component {
  constructor(props) {
    super(props);

    this.reason = props.getReasonInfo();
    this.hasLatitudePayment = props.order.payments.some(
      (p) => p.currency === 'AUD' && p.type === 'latitude' && p.intent === 'sale' && p.success === true,
    );
  }

  componentDidMount() {
    this.props.setDefaultValuesForReason();
  }

  render() {
    return (
      <Box>
        <TourAutomaticRefundDetails
          customer_full_name={this.props.order.customer_full_name}
          reason={this.props.refund.reason}
          comment={this.props.refund.comment}
          source={this.props.refund.source}
          markCancelled={this.props.refund.mark_cancelled ? 'Yes' : 'No'}
          currencyCode={this.props.refund.currency_code}
          totalRefund={currencyFormatter.format(this.props.totalRefund(), {
            code: this.props.refund.currency_code,
          })}
        />

        <h4>Refund Policies</h4>

        {this.props.refund.refund_policies.map((policy) => (
          <Fragment key={policy}>
            <small>{policy}</small>
          </Fragment>
        ))}

        <Box id="refund_method">
          <label>Select Method of Refund to Customer</label>
          <RefundMethod
            handleValueChange={this.props.handleValueChange}
            refund_method={this.props.refund.refund_method}
            hasLatitudePayment={this.hasLatitudePayment}
            methods={this.props.refund.methods}
          />
        </Box>

        <SubmitButton
          setError={this.props.setError}
          refund={this.props.refund}
          order={this.props.order}
          totalRefund={this.props.totalRefund}
          totalFee={this.props.totalFee}
          hasRefundItem={this.props.hasRefundItem}
          hasRefundSurcharge={this.props.hasRefundSurcharge}
          totalPromoAmount={this.props.totalPromoAmount}
          accountingMetaPayload={this.props.accountingMetaPayload}
          closeModal={this.props.closeModal}
          stepHandler={this.props.stepHandler}
        />
      </Box>
    );
  }
}
