import React from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { Box, Button, Stack, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { Input } from '~/components/Common/Forms/fields';
import Spinner from '~/components/Common/Spinner';
import DebugModal from '~/components/DebugModal/DebugModal';

import useReferralProcessor from '../hooks/useReferralProcessor';

function ReferralLogsProcessor() {
  const { enqueueSnackbar } = useSnackbar();
  const { logs, errors, isLoading, processLogs } = useReferralProcessor();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      limit: 500,
      offset: 0,
    },
  });

  const onSubmit = async (formData: { limit: number; offset: number }) => {
    try {
      processLogs({
        limit: formData.limit,
        offset: formData.offset,
      });
    } catch (err) {
      enqueueSnackbar(err.message || 'Error! Unable to process logs.', { variant: 'error' });
    }
  };

  return (
    <>
      <Box mb={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body1" fontSize={14}>
          This form allows for manual processing of referral logs in the referral log queue. It will send emails to the
          users who referred a friend and provide referral rewards.
        </Typography>
        <Stack direction="row" spacing={3} alignItems="center">
          <Input fullWidth control={control} muiLabel="Limit" name="limit" type="number" />
          <Input fullWidth control={control} muiLabel="Offset" name="offset" type="number" />
          <Button fullWidth variant="contained" type="submit">
            Process Logs
          </Button>
        </Stack>
      </Box>
      <Box>
        {isLoading && <Spinner />}
        {logs && (
          <Stack direction="row" spacing={3} alignItems="center">
            {logs.map((l) => {
              <Typography>{JSON.stringify(l)}</Typography>;
            })}
            <DebugModal title={`Processed ${logs.length} Logs`} type="generic" data={logs} />
          </Stack>
        )}
        {errors?.length > 0 &&
          errors.map((error, index) => <ErrorDisplay key={index} severity="warning" message={JSON.stringify(error)} />)}
      </Box>
    </>
  );
}

export default ReferralLogsProcessor;
