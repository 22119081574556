import React from 'react';

import dayjs from '~/timeInit';

const OrderFlightLeg = ({ flights }) => {
  const formatDate = (date, time) => {
    return dayjs(`${date} ${time}`, 'DDMMYY HHmm').format('DD-MMM-YYYY hh:mmA');
  };

  return (
    <div className="list-unstyled T-Order-Flight-Item-List flight-item-list">
      {flights.map((flight, idx) => (
        <div className="order-item" key={idx}>
          <div>
            <div className="flight-traveller">
              <div className="type-heading">
                {flight.departureLocation}
                <br />
                {formatDate(flight.departureDate, flight.departureTime)}
              </div>
              <div className="type-heading">
                {'====================>'}
                <br />
                {flight.aircraftNumber}
              </div>
              <div className="type-heading">
                {flight.arrivalLocation}
                <br />
                {formatDate(flight.arrivalDate, flight.arrivalTime)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderFlightLeg;
