import { getCountries } from '@luxuryescapes/lib-countries';

import { BEDBANK_PROPERTY_STATUSES_ENUM } from './bedbank';

export const statusOptions = BEDBANK_PROPERTY_STATUSES_ENUM.map((status) => ({
  label: status,
  value: status,
}));

export const promotionsOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const countriesOptions = getCountries().map((country) => ({
  label: country,
  value: country,
}));

export const ratingOperatoins = [
  {
    value: '',
    label: 'Op',
  },
  {
    value: 'gt',
    label: '>',
  },
  {
    value: 'lt',
    label: '<',
  },
  {
    value: 'eq',
    label: '=',
  },
  {
    value: 'lte',
    label: '<=',
  },
  {
    value: 'gte',
    label: '>=',
  },
];

export const ratingValues = [
  {
    value: '',
    label: 'Value',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '3.5',
    label: '3.5',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '4.5',
    label: '4.5',
  },
  {
    value: '5',
    label: '5',
  },
];
