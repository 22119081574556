import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Stack, TextField } from '@mui/material';

import ConfirmButton from '../Elements/ConfirmButton';
import DateWidget from '../Elements/DateWidget';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DateRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateWrapper = styled.div`
  width: 50%;
  margin-right: 20px;
`;

const InventoryInput = styled.input`
  width: 25%;
`;

interface DateRowItem {
  start_date: string;
}

interface Props {
  handleAddMultiple: (nonEmptyDatesList: Array<DateRowItem>, inventory: number) => void;
  closeAddMultipleDates: () => void;
}

function MultipleTourDatesForm(props: Props) {
  const { handleAddMultiple, closeAddMultipleDates } = props;
  const [newTourOptionDates, setNewTourOptionDates] = useState([{ start_date: '' }]);
  const [inventory, setInventory] = useState(0);

  const isDateListEmpty = useCallback(() => {
    return newTourOptionDates.length < 2 && newTourOptionDates[0].start_date === '';
  }, [newTourOptionDates]);

  useEffect(() => {
    addEmptyDate();
  });

  // If there is no blank date entry at the end of the list, add one.
  const addEmptyDate = () => {
    if (newTourOptionDates[newTourOptionDates.length - 1].start_date === '') return;
    const newDates = [...newTourOptionDates];
    newDates.push({
      start_date: '',
    });

    setNewTourOptionDates(newDates);
  };

  const handleChangeDate = (item: DateRowItem, date: string) => {
    if (newTourOptionDates.find((i) => i.start_date === date)) {
      alert('Duplicate date');
      removeDate(item);
    } else {
      const newDates = [...newTourOptionDates];
      newDates.find((i) => i.start_date === item.start_date).start_date = date;
      setNewTourOptionDates(newDates);
    }
  };

  const removeDate = (dateItem: DateRowItem) => {
    const newDates = [...newTourOptionDates];
    const arrItem = newDates.find((i) => i.start_date === dateItem.start_date);
    const index = newDates.indexOf(arrItem);
    newDates.splice(index, 1);
    setNewTourOptionDates(newDates);
  };

  const handleOnConfirm = (dateItem: DateRowItem) => () => {
    removeDate(dateItem);
  };

  const handleOnChangeDate = (dateItem: DateRowItem) => (date: string) => {
    handleChangeDate(dateItem, date);
  };

  const getDateRow = (dateItem: DateRowItem, index: number) => {
    return (
      <Stack direction="row" spacing={1} key={dateItem.start_date}>
        <Box flexGrow={1}>
          <DateWidget value={newTourOptionDates[index].start_date} onChange={handleOnChangeDate(dateItem)} />
        </Box>

        {dateItem.start_date !== '' && (
          <Box>
            <ConfirmButton
              title="Delete"
              confirmTitle="Confirm delete"
              confirmAnswer="Delete"
              onConfirm={handleOnConfirm(dateItem)}
              asIcon
            >
              <DeleteIcon />
            </ConfirmButton>
          </Box>
        )}
      </Stack>
    );
  };

  const applyDates = () => {
    const nonEmptyDatesList = [...newTourOptionDates].filter((item) => item.start_date !== '');
    handleAddMultiple(nonEmptyDatesList, inventory);
    closeAddMultipleDates();
  };

  const onChangeInventory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInventory(parseInt(e.target.value));
  };

  return (
    <Container>
      <Stack direction="column" spacing={1}>
        {newTourOptionDates.map((item, index) => getDateRow(item, index))}
      </Stack>

      <Box mt={2}>
        <TextField
          label="Select inventory"
          type="number"
          inputProps={{ step: '1', min: '0' }}
          value={inventory}
          onChange={onChangeInventory}
          fullWidth
        />
      </Box>

      <div>
        <Button variant="contained" onClick={applyDates} disabled={isDateListEmpty()}>
          Apply dates
        </Button>
      </div>
    </Container>
  );
}

export default MultipleTourDatesForm;
