import React, { useCallback, useState } from 'react';

import { Autocomplete, Button, FormGroup, MenuItem, Stack, TextField } from '@mui/material';

import { getRegions } from '@luxuryescapes/lib-regions';

import { LE_AGENT_HUB } from '~/consts/brands';

import { UpdateEoiDetailsParams } from '~/types/services/agentHub';

export default function AgencySubmissionEditor({ eoiData, onCancel, availableAffiliations, onSave }) {
  const [editedData, setEditedData] = useState<Partial<UpdateEoiDetailsParams>>({
    agencyName: eoiData.agencyName,
    affiliation: eoiData.affiliation,
    regionCode: eoiData.regionCode,
    name: eoiData.name,
    email: eoiData.email,
    phone: eoiData.phone,
  });

  const availableRegions = getRegions(LE_AGENT_HUB);

  const handleAgencyUpdate = useCallback(() => {
    onSave(editedData);
  }, [editedData, onSave]);

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} style={{ minWidth: '500px' }}>
        <Stack mt={2} direction="column" spacing={2}>
          <FormGroup>
            <TextField
              label="Agency Name"
              value={editedData.agencyName}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, agencyName: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              select
              label="Region"
              fullWidth
              required
              value={editedData.regionCode}
              onChange={(e) =>
                setEditedData({ ...editedData, regionCode: e.target.value as UpdateEoiDetailsParams['regionCode'] })
              }
            >
              {availableRegions.map((region) => (
                <MenuItem key={region.code} value={region.code}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>
          <FormGroup>
            <Autocomplete
              value={availableAffiliations?.find((affiliation) => affiliation.name === editedData?.affiliation)}
              options={availableAffiliations}
              getOptionLabel={(option) => `${option.region} - ${option.name}`}
              onChange={(_, value) => setEditedData({ ...editedData, affiliation: value ? value.name : '' })}
              renderInput={(params) => <TextField {...params} required label="Affiliation" />}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Name"
              value={editedData.name}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Email"
              value={editedData.email}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Phone"
              value={editedData.phone}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
            />
          </FormGroup>
        </Stack>
      </form>
      <Stack mt={2} display="flex" justifyContent="space-around" direction="row">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleAgencyUpdate}>
          Save
        </Button>
      </Stack>
    </>
  );
}
