import React from 'react';

import { Link } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';

import { ITEM_TYPE_ADDON, ITEM_TYPE_GIFT_CARD, ITEM_TYPE_INSURANCE, ITEM_TYPE_OFFER } from '~/consts/order';

const startPage = {
  [ITEM_TYPE_OFFER]: 'new-order/offer-selection',
  [ITEM_TYPE_GIFT_CARD]: 'new-gift-card-order/gift-card-selection',
  [ITEM_TYPE_INSURANCE]: 'new-insurance-order/travel-insurance',
  [ITEM_TYPE_ADDON]: 'new-order/add-ons-selection',
};

const NoDataWarning = ({ userId, orderType }) => {
  return (
    <Box mt={2} className="no-data-warning">
      <p>There is no data to display. Please make sure to go through the booking flow from the beginning.</p>
      <Button
        component={Link}
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        to={`/users/${userId}/${startPage[orderType]}`}
      >
        Back to beginning
      </Button>
    </Box>
  );
};

export default NoDataWarning;
