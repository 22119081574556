import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { DYNAMIC_PRICED_HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

import { queueVccBookings } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

function validateForm(formData, errors) {
  if (typeof formData.date === 'undefined') {
    errors.date.addError('Please enter date');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please enter a date to export a list of all VCC bookings payable on the selected date',
  type: 'object',
  properties: {
    date: {
      title: 'Select Date',
      type: 'string',
      format: 'date',
    },
    offerTypeFilters: {
      title: 'Filter offer type',
      type: 'array',
      items: {
        title: 'Offer type',
        type: 'string',
        enum: DYNAMIC_PRICED_HOTEL_OFFER_TYPES,
        uniqueItems: true,
      },
    },
  },
};

const uiSchema = {
  date: {
    'ui:widget': 'date',
  },
};

export default function ExportVccBookingsForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    queueVccBookings({
      date: event.formData.date,
      offerTypeFilters: event.formData.offerTypeFilters,
    })
      .then(function () {
        setSuccessMessage(true);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email VCC Bookings Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
