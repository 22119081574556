import React from 'react';

import { Box, Stack } from '@mui/material';

export default class BookingCancelledToggle extends React.Component {
  render() {
    return (
      <Box id="BookingCancelledToggle">
        <h6>Should the booking be cancelled?</h6>
        <Stack direction="row" spacing={2}>
          <Box>
            <input
              type="radio"
              key="yes"
              name="mark_cancelled"
              className="T-mark-cancelled-yes"
              value="1"
              onChange={this.props.handleFlagChange}
              checked={this.props.mark_cancelled === true}
            />
            <label>Yes</label>
          </Box>

          <Box>
            <input
              type="radio"
              key="no"
              name="mark_cancelled"
              className="T-mark-cancelled-no"
              value="0"
              onChange={this.props.handleFlagChange}
              checked={this.props.mark_cancelled === false}
            />
            <label>No</label>
          </Box>
        </Stack>
      </Box>
    );
  }
}
