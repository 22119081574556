import React from 'react';

function VendorPaymentsReport() {
  return (
    <iframe
      width="100%"
      height="1000"
      src="https://lookerstudio.google.com/embed/reporting/7c2de5d8-424b-4afe-9178-fa5ced5235e7/page/QZZtD?params=%7B%22df2%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%25800062y00000GvYZ9AAN%22%7D"
      frameBorder="0"
      allowFullScreen
      sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
    />
  );
}

export default VendorPaymentsReport;
