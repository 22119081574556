import React from 'react';

import parse, { domToReact } from 'html-react-parser';

const fullPageStyle = {
  backgroundColor: 'rgb(255, 253, 211)',
  width: '210mm',
  minHeight: '297mm',
  marginLeft: '10px',
  marginRight: 'auto',
};

export default class AtolCertificate extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { html } = this.props;
    const startBodyIndex = html.indexOf('<body');
    const endBodyIndex = html.indexOf('</body>') + 7;
    const body = html.slice(startBodyIndex, endBodyIndex);

    const options = {
      replace: ({ attribs, children }) => {
        if (!attribs) return;

        if (attribs.class === 'em_body') {
          return <div>{domToReact(children, options)}</div>;
        }
      },
    };

    return (
      <div id="atol-certificate" style={fullPageStyle}>
        {parse(body, options)}
      </div>
    );
  }
}
