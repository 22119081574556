import React from 'react';

import { Box, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import type { TagType } from '~/consts/tags';

import TagElement from './TagElement';
import TagForm from './TagForm';

type Props = {
  title: string;
  tags: App.MarketingTag[];
  type: TagType;
  onCreateTag: (type: TagType, name: string) => Promise<App.MarketingTag>;
  onDeleteTag: (type: TagType, id: number) => Promise<void>;
  onUpdateTag: (type: TagType, id: number, name: string) => Promise<void>;
};

export default function TagsComponent(props: Props) {
  const { title, tags, type, onCreateTag, onDeleteTag, onUpdateTag } = props;

  return (
    <Box>
      <PageSubheader title={title} />

      <Typography variant="body1" fontSize={16} color="GrayText">
        Please allow up to one hour for changes to come into effect.
      </Typography>

      <Box mt={4}>
        <TagForm type={type} onCreateTag={onCreateTag} />
      </Box>

      <Box mt={4} display="grid" gridTemplateColumns="repeat(2, minmax(200px, 1fr))" columnGap={4} rowGap={2}>
        {tags.map((tag) => (
          <Box key={tag.id}>
            <TagElement {...tag} type={type} onDeleteTag={onDeleteTag} onUpdateTag={onUpdateTag} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
