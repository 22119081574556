import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { formatDateShort } from '~/services/TimeService';

import { daysOfWeekMessage } from '~/utils/daysOfWeek';

import { useConfirmDialog } from '../../Common/Providers/ConfirmDialogProvider';

export default function BlackoutDateBlock({ blackoutDateBlock, onDelete, channelManaged }) {
  const startDate = formatDateShort(blackoutDateBlock.start_date);
  const endDate = formatDateShort(blackoutDateBlock.end_date);

  const showConfirmDialog = useConfirmDialog();

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete this blackout date?',
    });

    if (!confirmed) return;

    onDelete(blackoutDateBlock.id);
  };

  return (
    <Grid container spacing={0} className="box-list-item">
      <Grid item xs={11}>
        <div>
          {startDate} &nbsp; &mdash; &nbsp; {endDate}
        </div>
        <div>
          <i>{daysOfWeekMessage(blackoutDateBlock)}</i>
        </div>
      </Grid>
      <Grid item xs={1} textAlign="right">
        {!channelManaged && (
          <Button variant="text" startIcon={<DeleteIcon />} onClick={handleDelete} fullWidth>
            Delete
          </Button>
        )}
        {!!channelManaged && blackoutDateBlock.restriction_type === 'arrival' && <div>CTA</div>}
        {!!channelManaged && blackoutDateBlock.restriction_type === 'departure' && <div>CTD</div>}
      </Grid>
    </Grid>
  );
}
