import styled from 'styled-components';

import { themes } from '../../../../consts/experiences';
import { Spacing, margin, padding } from '../../helpers';

type TextProps = {
  size?: string;
  color?: string;
  align?: string;
  weight?: number;
  lineHeight?: string;
} & Spacing;

export const Text = styled.p<TextProps>`
  width: 100%;
  font-size: ${({ size }) => size || '16px'};
  text-align: ${({ align }) => align || 'left'};
  font-weight: ${({ weight }) => weight || 400};
  color: ${({ color }) => color || themes?.primaryDark};
  line-height: ${({ lineHeight }) => lineHeight || '19px'};

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};
`;
