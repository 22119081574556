import currencyFormatter from 'currency-formatter';
import findIndex from 'lodash/findIndex';
import { v4 as uuid } from 'uuid';
import dayjs from '~/timeInit';

import {
  ADD_ADDON_TO_CART,
  ADD_BUSINESS_FLIGHT_TO_CART,
  ADD_BUSINESS_PNR,
  ADD_BUSINESS_TRAVELLER_TO_PNR,
  ADD_FLIGHT_TO_CART,
  ADD_GIFT_CARD_TO_CART,
  ADD_OFFER,
  ADD_OFFER_TO_CART,
  ADD_PNR,
  ADD_RESERVATION_FX_RATES_TO_CART,
  ADD_TRAVELLER_TO_PNR,
  ALLOW_TOGGLE_RESERVATION_TYPE,
  APPLY_LE_CREDIT,
  APPLY_PROMO_CODE,
  CHANGE_CURRENCY_CODE,
  EMPTY_CART,
  FINISH_ENQUIRY,
  PURCHASE_FAILURE,
  RECEIVE_ENQUIRY,
  REDUCE_GIFT_CARD_QUANTITY,
  REDUCE_OFFER_ITEM_QUANTITY,
  REMOVE_ADDON_FROM_CART,
  REMOVE_ALL_ADDONS_FROM_CART,
  REMOVE_INSURANCE_OPTION,
  REMOVE_OFFER,
  REMOVE_OFFERS,
  REMOVE_OTHER_ITEM_FROM_CART,
  REMOVE_PNR,
  REMOVE_TRAVELLER,
  SELECT_INSURANCE_OPTION,
  SET_BOOK_DATES_FOR_ORDER,
  SET_BUSINESS_ID,
  SET_CUSTOMER,
  SET_ORDER_ID,
  SET_PROMO_CODE_ERROR,
  START_ENQUIRY,
  SUCCESS_FETCH_ADDONS,
  TOGGLE_BOOKING_DATES,
  TOGGLE_RESERVATION_TYPE,
  UPDATE_CONTACT_DETAILS,
  UPDATE_CREDIT,
  UPDATE_GIFT_CARD_PRICE,
  UPDATE_ORDER_NOTES,
  UPDATE_PNR_ID,
  UPDATE_RESERVATION,
  UPDATE_STATE_OF_RESIDENCE,
  UPDATE_TRAVELLER_DETAILS,
} from '../actions/cart';
import {
  AIRLINE_FEE_TYPE_NO_FEE,
  FLIGHT_ORDER_TYPE_NEW,
  FLIGHT_TYPE_DOMESTIC,
  SERVICE_FEE_TYPE_NO_FEE,
  TRAVELLER_TYPE_ADULT,
} from '../consts/flight';
import { ITEM_TYPE_ADDON, ITEM_TYPE_GIFT_CARD, ITEM_TYPE_OFFER, ITEM_TYPE_OFFLINE_FLIGHT } from '../consts/order';
import { PROMO_TYPE_FIXED } from '../consts/promo';
import { RESERVATION_TYPE_BOOK_LATER, RESERVATION_TYPE_INSTANT_BOOKING } from '../consts/reservation';
import generateRandomString from '../utils/generateRandomString';
import generateTransactionKey from '../utils/generateTransactionKey';
import getPackagePriceByCurrency from '../utils/getPackagePriceByCurrency';
import { getRoomExtraGuestSurcharge } from '../utils/guestCount';

/****** configure dayjs **/
dayjs.tz.setDefault(window.configs.DEFAULT_TIMEZONE);

const initialState = {
  offers: [],
  items: [],
  addons: [],
  currencyCode: 'AUD',
  regionCode: 'AU',
  orderId: '',
  orderType: null,
  businessId: '',
  transactionKey: generateTransactionKey(),
  bookDatesOnly: false,
  allowToggleReservationType: true,
  creditPaymentAmount: 0,
  contactPhonePrefix: '',
  contactPhone: '',
  stateOfResidence: '',
  customer: {},
  insurance: {
    total: 0,
    quoteId: null,
    productId: null,
    productName: null,
    applied: false,
  },
  promo: {
    id: null,
    code: null,
    error: null,
    discount: 0,
    maxDiscount: null,
    type: null,
  },
  credit: {
    currencyCode: null,
    balance: 0,
    error: null,
  },
  reservationFXRates: {},
  amounts: {
    price: 0,
    value: 0,
    packageAmount: 0,
    surchargeAmount: 0,
    surchargeAmountOnBooking: 0,
    surchargeAmountOnCheckin: 0,
    extraGuestSurchargeAmount: 0,
    subTotal: 0,
    savedAmount: 0,
    totalAfterPromo: 0,
    creditPaymentAmount: 0,
    grandTotal: 0,
  },
};

function updatePackage(state, offerId, packageId, updateFn) {
  const updatedOffers = state.offers.map((o) => {
    if (o.id_salesforce_external === offerId) {
      const updatedPackages = o.packages.map((p) => {
        if (p.id_salesforce_external === packageId) return updateFn(p);
        return p;
      });
      o.packages = updatedPackages;
    }
    return o;
  });

  return { ...state, offers: updatedOffers };
}

function getSurcharge(orderItem) {
  if (!orderItem || !orderItem.reservation) {
    return 0;
  }
  return orderItem.reservation.peak_period_surcharge_amount || 0;
}

function getExtraGuestSurcharge(orderItem) {
  if (!orderItem || !orderItem.reservation) {
    return 0;
  }

  return orderItem.reservation.extra_guest_surcharge_amount || 0;
}

function getNumberOfNights(orderItem, pkg) {
  if (!orderItem || !orderItem.number_of_nights) {
    return pkg.number_of_nights;
  }

  return orderItem.number_of_nights;
}

function getNumberOfDays(orderItem, pkg) {
  if (!orderItem || !orderItem.number_of_days) {
    return pkg.number_of_days;
  }

  return orderItem.number_of_nights;
}

// returns a new offer item object that can be added to the cart
function createOfferItem(pkg, currencyCode, orderItem, reservationType, override, offer) {
  return {
    id: orderItem ? orderItem.id : generateRandomString(10),
    offerId: offer.id_salesforce_external,
    packageId: pkg.id_salesforce_external ?? pkg.le_package_id,
    offerCartId: offer.offerCartId,
    type: ITEM_TYPE_OFFER,
    pkg: pkg,
    price: getPackagePriceByCurrency(pkg, currencyCode).price,
    luxPlusPrice: getPackagePriceByCurrency(pkg, currencyCode).lux_plus_price,
    value: getPackagePriceByCurrency(pkg, currencyCode).value,
    surcharge: getSurcharge(orderItem),
    extraGuestSurcharge: getExtraGuestSurcharge(orderItem),
    transactionKey: generateTransactionKey(),
    numberOfNights: getNumberOfNights(orderItem, pkg),
    numberOfDays: getNumberOfDays(orderItem, pkg),
    reservation: {
      numAdults: 0,
      numChildren: 0,
      numInfants: 0,
      reservationType,
    },
    isBookingDates: false,
    subscriberTier: orderItem?.subscriber_tier || null,
    enquiry: {
      errorMessage: '',
      isFetching: false,
      dates: [],
      query: {},
    },
    ...override,
  };
}

// returns a new gift card item object that can be added to the cart
function createGiftCardItem(price) {
  return {
    id: generateRandomString(10),
    type: ITEM_TYPE_GIFT_CARD,
    price,
    value: price,
    surcharge: 0,
    transactionKey: generateTransactionKey(),
    reservation: {},
    isBookingDates: false,
    enquiry: {
      errorMessage: '',
      isFetching: false,
      dates: [],
      query: {},
    },
  };
}

function createAddonItem({ addon, currencyCode, offer }) {
  return {
    id: generateRandomString(12),
    type: ITEM_TYPE_ADDON,
    offerId: offer.id_salesforce_external,
    offerCartId: offer.offerCartId,
    packageId: addon.offerPackageId,
    transactionKey: generateTransactionKey(),
    // complimentary addons can have an empty prices array
    price: addon.complimentary ? 0 : getPackagePriceByCurrency(addon, currencyCode).price,
    name: addon.name,
    currency: addon.currency,
    addonId: addon.id_salesforce_external,
    detailedDescription: addon.detailed_description,
    description: addon.description,
    itemId: addon.itemId,
    vendor_salesforce_id: addon.vendor_id,
    vendor_booking_email: addon.vendor_booking_email,
    image_cloudinary_external: addon.image_cloudinary_external,
    parent_package_salesforce_id: addon.addon_parent_package_salesforce_id,
    fk_opportunity_salesforce_id: addon.fk_opportunity_salesforce_id,
  };
}

function createTraveller() {
  return {
    firstName: '',
    middleName: '',
    lastName: '',
    title: '',
    price: 0,
    type: TRAVELLER_TYPE_ADULT,
  };
}

function createBusinessTraveller() {
  return {
    airlineFee: 0,
    airlineFeeType: AIRLINE_FEE_TYPE_NO_FEE,
    departureDate: '',
    departureRoute: '',
    fareAndTaxes: 0,
    firstName: '',
    flightType: FLIGHT_TYPE_DOMESTIC,
    lastName: '',
    middleName: '',
    orderType: FLIGHT_ORDER_TYPE_NEW,
    price: 0,
    returnDate: '',
    returnRoute: '',
    serviceFee: 0,
    serviceFeeType: SERVICE_FEE_TYPE_NO_FEE,
    title: '',
    type: TRAVELLER_TYPE_ADULT,
  };
}

function createFlightItem() {
  return {
    id: generateRandomString(12),
    type: ITEM_TYPE_OFFLINE_FLIGHT,
    transactionKey: generateTransactionKey(),
    price: 0,
    pnrs: [createPnr()],
    surcharge: 0,
    value: 0,
  };
}

function createBusinessFlightItem() {
  return {
    id: generateRandomString(12),
    type: ITEM_TYPE_OFFLINE_FLIGHT,
    transactionKey: generateTransactionKey(),
    price: 0,
    pnrs: [createBusinessPnr()],
    surcharge: 0,
    value: 0,
  };
}

function createPnr() {
  return {
    PNR: '',
    travellers: [createTraveller()],
  };
}

function createBusinessPnr() {
  return {
    PNR: '',
    travellers: [createBusinessTraveller()],
  };
}

function calculateDiscount(amount, discountValue, discountType, maxDiscount) {
  if (discountType == PROMO_TYPE_FIXED) {
    return Math.min(discountValue, amount);
  } else {
    const percentageDiscount = parseAmount((amount * discountValue) / 100);
    return maxDiscount ? Math.min(maxDiscount, percentageDiscount) : percentageDiscount;
  }
}

function parseAmount(amount) {
  return parseFloat(parseFloat(amount).toFixed(2));
}

const getAddonTotal = (state) => {
  const allAddons = state.offers.reduce((allAddons, offer) => [...allAddons, ...offer.addons], []);
  return allAddons.reduce((sum, addon) => sum + addon.price, 0);
};
function withCalculatedAmounts(state) {
  return {
    ...state,
    amounts: calculateAmounts(
      state.items,
      state.offers,
      state.bookDatesOnly,
      state.creditPaymentAmount,
      state.promo.discount,
      state.promo.type,
      state.promo.maxDiscount,
      state.insurance.total,
      getAddonTotal(state),
    ),
  };
}

function calculateAmounts(
  items,
  offers,
  bookDatesOnly,
  creditPaymentAmount,
  discountValue,
  discountType,
  maxDiscount,
  insuranceTotal,
  addonsTotal,
) {
  // calculate total package, surcharge and value for all offers
  const packageAmount =
    window.configs.MULTI_OFFER === 'true'
      ? offers.reduce((sum, offer) => sum + offer.items.reduce((acc, val) => acc + val.pkg.price, 0), 0)
      : offers.reduce((sum, offer) => sum + offer.items.reduce((acc, val) => acc + val.price, 0), 0);

  const surchargeAmountPayableToVendor = offers.reduce(
    (sum, offer) =>
      offer.surcharge_paid_direct_to_vendor
        ? sum + offer.items.reduce((acc, val) => acc + (val.surcharge || 0), 0)
        : sum,
    0,
  );
  const surchargeAmountNotPayableToVendor = offers.reduce(
    (sum, offer) =>
      offer.surcharge_paid_direct_to_vendor
        ? sum
        : sum + offer.items.reduce((acc, val) => acc + (val.surcharge || 0), 0),
    0,
  );

  const extraGuestSurchargeAmount = offers.reduce(
    (sum, offer) => sum + offer.items.reduce((acc, val) => acc + (val.extraGuestSurcharge || 0), 0),
    0,
  );

  const packageValue =
    window.configs.MULTI_OFFER === 'true'
      ? offers.reduce((sum, offer) => sum + offer.items.reduce((acc, val) => acc + val.pkg.value, 0), 0)
      : offers.reduce((sum, offer) => sum + offer.items.reduce((acc, val) => acc + val.value, 0), 0);
  const otherItemsValue = items.reduce((sum, item) => sum + item.value, 0) || 0;
  const otherItemsAmount = items.reduce((sum, item) => sum + item.price, 0) || 0;

  const surchargeAmountOnBooking = surchargeAmountNotPayableToVendor;
  const surchargeAmountOnCheckin = surchargeAmountPayableToVendor;

  const price = bookDatesOnly
    ? surchargeAmountOnBooking + extraGuestSurchargeAmount
    : packageAmount + otherItemsAmount + surchargeAmountOnBooking + extraGuestSurchargeAmount;
  const subTotal = price + insuranceTotal + addonsTotal;
  const savedAmount = calculateDiscount(subTotal, parseInt(discountValue), discountType, maxDiscount);
  const totalAfterPromoData = parseAmount(subTotal - savedAmount);
  const totalAfterPromo = totalAfterPromoData > 0 ? totalAfterPromoData : 0;
  const grandTotal = totalAfterPromo - creditPaymentAmount;

  return {
    price,
    value: packageValue + otherItemsValue,
    packageAmount,
    surchargeAmount: surchargeAmountPayableToVendor + surchargeAmountNotPayableToVendor,
    surchargeAmountOnBooking,
    surchargeAmountOnCheckin,
    extraGuestSurchargeAmount,
    subTotal,
    savedAmount,
    creditPaymentAmount,
    totalAfterPromo,
    grandTotal,
  };
}

function setPromoCodeError(state, message) {
  const newState = {
    ...state,
    promo: {
      id: null,
      code: null,
      error: message,
      discount: 0,
      maxDiscount: null,
      type: null,
    },
  };
  return withCalculatedAmounts(newState);
}

function enquiryReducer(enquiry, action) {
  switch (action.type) {
    case START_ENQUIRY: {
      return {
        ...enquiry,
        isFetching: true,
      };
    }
    case FINISH_ENQUIRY: {
      return {
        ...enquiry,
        dates: action.dates,
        isFetching: false,
      };
    }
    case RECEIVE_ENQUIRY: {
      return {
        ...enquiry,
        dates: action.dates,
      };
    }
    default:
      return enquiry;
  }
}

function itemsReducer(items, action, currencyCode, reservationFXRates) {
  switch (action.type) {
    case REMOVE_OTHER_ITEM_FROM_CART: {
      const { item } = action;

      return items.filter((thisItem) => thisItem.id !== item.id);
    }
    case UPDATE_RESERVATION: {
      const { itemId, reservationData } = action;
      const { durationSurchargeTotal } = reservationData;

      return items.map((item) => {
        if (item.id === itemId) {
          const reservation = {
            ...item.reservation,
            ...reservationData,
          };

          const extraGuestSurcharge = item.pkg.room_rate
            ? getRoomExtraGuestSurcharge({
                currentAdults: reservation.numAdults,
                currentChildren: reservation.numChildren,
                currentInfants: reservation.numInfants,
                includedGuests: item.pkg.room_rate.included_guests,
                extraGuestSurcharges: item.pkg.room_rate.extra_guest_surcharges,
                numberOfNights: item.numberOfNights,
                currencyCode,
                fxRates: reservationFXRates,
              })
            : 0;

          return {
            ...item,
            reservation: {
              ...reservation,
              extraGuestSurcharge,
            },
            surcharge: typeof durationSurchargeTotal === 'number' ? durationSurchargeTotal : item.surcharge,
            extraGuestSurcharge,
          };
        }
        return item;
      });
    }
    case TOGGLE_BOOKING_DATES: {
      const { item, isBookingDates } = action;

      return items.map((thisItem) => {
        if (thisItem.id === item.id) {
          return {
            ...thisItem,
            isBookingDates,
          };
        }

        return thisItem;
      });
    }
    case START_ENQUIRY:
    case FINISH_ENQUIRY:
    case RECEIVE_ENQUIRY: {
      const { itemId } = action;

      return items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            enquiry: enquiryReducer(item.enquiry, action),
          };
        }

        return item;
      });
    }
    default:
      return items;
  }
}

export default function (state = initialState, action) {
  const { offers, currencyCode, reservationFXRates } = state;
  const { offer: actionOffer, offerId } = action;
  const { customer } = action;

  // This is to identify an offer in case there are multiples of same offer in a multi-booking.
  // When this is not passed e.g. for post-purchase updates, a single offer is assumed to be in the cart.
  const offerCartId = actionOffer?.offerCartId;
  const singleOffer = !offerCartId;

  switch (action.type) {
    case ADD_OFFER: {
      const offerWithItems = {
        ...actionOffer,
        offerCartId: uuid(),
        items: actionOffer.items || [],
        addons: actionOffer.addons || [],
      };
      return {
        ...state,
        offers: [...offers, offerWithItems],
        orderId: '',
        transactionKey: generateTransactionKey(),
      };
    }
    case REMOVE_OFFERS: {
      return {
        ...state,
        offers: offers.filter((offer) => offer.id_salesforce_external !== offerId),
      };
    }
    case REMOVE_OFFER: {
      const foundIndex = offers.findIndex(
        (o) => o.id_salesforce_external === offerId && (o.offerCartId === offerCartId || singleOffer),
      );
      if (foundIndex > -1) {
        offers.splice(foundIndex, 1);
      }

      return {
        ...state,
        offers: [...offers],
        stateOfResidence: '',
      };
    }
    case SET_CUSTOMER:
      return {
        ...state,
        customer,
      };
    case EMPTY_CART:
      return {
        ...initialState,
        transactionKey: generateTransactionKey(),
      };
    case PURCHASE_FAILURE:
      return {
        ...state,
        transactionKey: generateTransactionKey(),
      };
    case ADD_OFFER_TO_CART: {
      const item = createOfferItem(
        action.pkg,
        action.currencyCode || initialState.currencyCode,
        action.orderItem,
        RESERVATION_TYPE_INSTANT_BOOKING,
        action.override,
        actionOffer,
      );

      const updatedOffers = state.offers.map((o) => {
        if (
          (o.offerCartId === actionOffer.offerCartId || singleOffer) &&
          o.id_salesforce_external === actionOffer.id_salesforce_external
        ) {
          o.items = [...o.items, item];
        }
        return o;
      });

      const newState = {
        ...state,
        orderType: ITEM_TYPE_OFFER,
        currencyCode: action.currencyCode,
        offers: updatedOffers,
      };

      return withCalculatedAmounts(newState);
    }
    case ADD_GIFT_CARD_TO_CART: {
      const currentItems = state.items;

      const item = createGiftCardItem(action.price);

      const items = currentItems.filter((item) => item.type == ITEM_TYPE_GIFT_CARD).concat([item]);

      const newState = {
        ...state,
        orderType: ITEM_TYPE_GIFT_CARD,
        currencyCode: action.currencyCode,
        promo: initialState.promo,
        creditPaymentAmount: 0,
        items,
      };

      return withCalculatedAmounts(newState);
    }
    case ADD_ADDON_TO_CART: {
      const { addon: addonItem, item } = action;
      const currencyCode = state.currencyCode || initialState.currencyCode;
      const addon = createAddonItem({
        addon: addonItem,
        currencyCode,
        offer: actionOffer,
      });

      const updatedOffers = state.offers.map((o) => {
        if (
          (o.offerCartId === actionOffer.offerCartId || singleOffer) &&
          o.id_salesforce_external === actionOffer.id_salesforce_external
        ) {
          o.addons = [
            ...o.addons,
            {
              ...addon,
              addonIndex: o.addons.length,
              itemId: addon.itemId || item.id,
            },
          ];
        }
        return o;
      });

      const newState = {
        ...state,
        offers: updatedOffers,
      };

      return withCalculatedAmounts(newState);
    }
    case ADD_FLIGHT_TO_CART: {
      const currentItems = state.items;
      const flight = createFlightItem();

      const newItems = currentItems.concat([flight]);

      const newState = {
        ...state,
        orderType: ITEM_TYPE_OFFLINE_FLIGHT,
        items: newItems,
        promo: initialState.promo,
        creditPaymentAmount: 0,
      };

      return withCalculatedAmounts(newState);
    }
    case ADD_BUSINESS_FLIGHT_TO_CART: {
      const currentItems = state.items;
      const flight = createBusinessFlightItem();

      const newItems = currentItems.concat([flight]);

      const newState = {
        ...state,
        orderType: ITEM_TYPE_OFFLINE_FLIGHT,
        items: newItems,
        promo: initialState.promo,
        creditPaymentAmount: 0,
      };

      return withCalculatedAmounts(newState);
    }
    case ADD_PNR: {
      const currentFlight = state.items[0];
      const newPnrs = currentFlight.pnrs.concat([createPnr()]);
      const newFlight = { ...currentFlight, pnrs: newPnrs };
      const newState = {
        ...state,
        items: [newFlight],
      };
      return newState;
    }
    case ADD_BUSINESS_PNR: {
      const currentFlight = state.items[0];
      const newPnrs = currentFlight.pnrs.concat([createBusinessPnr()]);
      const newFlight = { ...currentFlight, pnrs: newPnrs };
      const newState = {
        ...state,
        items: [newFlight],
      };
      return newState;
    }

    case ADD_TRAVELLER_TO_PNR: {
      const traveller = createTraveller();
      const currentFlight = state.items[0];

      const newPnrs = currentFlight.pnrs.map((pnr, pnrIndex) => {
        if (pnrIndex === action.pnrIndex) {
          return { ...pnr, travellers: pnr.travellers.concat([traveller]) };
        } else {
          return pnr;
        }
      });
      const newFlight = { ...currentFlight, pnrs: newPnrs };
      const newState = {
        ...state,
        items: [newFlight],
      };

      return newState;
    }
    case ADD_BUSINESS_TRAVELLER_TO_PNR: {
      const traveller = createBusinessTraveller();
      const currentFlight = state.items[0];

      const newPnrs = currentFlight.pnrs.map((pnr, pnrIndex) => {
        if (pnrIndex === action.pnrIndex) {
          return { ...pnr, travellers: pnr.travellers.concat([traveller]) };
        } else {
          return pnr;
        }
      });
      const newFlight = { ...currentFlight, pnrs: newPnrs };
      const newState = {
        ...state,
        items: [newFlight],
      };

      return newState;
    }
    case UPDATE_GIFT_CARD_PRICE: {
      const items = state.items.map((item) => {
        if (item.type == ITEM_TYPE_GIFT_CARD && item.price == action.oldPrice) {
          return {
            ...item,
            price: action.newPrice,
            value: action.newPrice,
          };
        } else {
          return item;
        }
      });

      const newState = {
        ...state,
        items,
      };

      return withCalculatedAmounts(newState);
    }
    case UPDATE_TRAVELLER_DETAILS: {
      const currentFlight = state.items[0];
      let price = currentFlight.price;
      const newPnrs = currentFlight.pnrs.map((pnr, pnrIndex) => {
        if (pnrIndex === action.pnrIndex) {
          const currentTraveller = pnr.travellers[action.travellerIndex];
          if (Object.keys(action.travellerDetails)[0] === 'price') {
            price = price - currentTraveller.price + action.travellerDetails.price;
          }
          const newTravellerDetails = Object.assign(currentTraveller, action.travellerDetails);
          return {
            ...pnr,
            travellers: [
              ...pnr.travellers.slice(0, action.travellerIndex),
              newTravellerDetails,
              ...pnr.travellers.slice(action.travellerIndex + 1),
            ],
          };
        } else {
          return pnr;
        }
      });
      const newFlight = { ...currentFlight, pnrs: newPnrs, price };

      const newState = {
        ...state,
        items: [newFlight],
      };

      return withCalculatedAmounts(newState);
    }
    case UPDATE_PNR_ID: {
      const currentFlight = state.items[0];
      const newPnrs = currentFlight.pnrs.map((pnr, pnrIndex) => {
        if (pnrIndex === action.pnrIndex) {
          return { ...pnr, PNR: action.PNR };
        } else {
          return pnr;
        }
      });
      const newFlight = { ...currentFlight, pnrs: newPnrs };
      return {
        ...state,
        items: [newFlight],
      };
    }
    case REMOVE_ALL_ADDONS_FROM_CART: {
      return {
        ...state,
        offers: offers.map((o) => ({ ...o, addons: [] })),
      };
    }
    case REMOVE_OTHER_ITEM_FROM_CART: {
      const items = itemsReducer(state.items, action, currencyCode, reservationFXRates);
      const newState = {
        ...state,
        items,
      };

      return withCalculatedAmounts(newState);
    }
    case REMOVE_PNR: {
      const currentFlight = state.items[0];
      const currentPnrs = currentFlight.pnrs;
      const priceDeduction = currentPnrs[action.pnrIndex].travellers.reduce(
        (pnrPrice, traveller) => pnrPrice + traveller.price,
        0,
      );
      const newPnrs = [...currentPnrs.slice(0, action.pnrIndex), ...currentPnrs.slice(action.pnrIndex + 1)];
      const newFlight = {
        ...currentFlight,
        pnrs: newPnrs,
        price: currentFlight.price - priceDeduction,
      };
      const newState = {
        ...state,
        items: [newFlight],
      };
      return withCalculatedAmounts(newState);
    }
    case REMOVE_TRAVELLER: {
      const currentFlight = state.items[0];
      let priceDeduction = 0;
      const newPnrs = currentFlight.pnrs.map((pnr, pnrIndex) => {
        if (pnrIndex === action.pnrIndex) {
          priceDeduction = pnr.travellers[action.travellerIndex].price;
          return {
            ...pnr,
            travellers: [
              ...pnr.travellers.slice(0, action.travellerIndex),
              ...pnr.travellers.slice(action.travellerIndex + 1),
            ],
          };
        } else {
          return pnr;
        }
      });
      const newFlight = {
        ...currentFlight,
        pnrs: newPnrs,
        price: currentFlight.price - priceDeduction,
      };

      const newState = {
        ...state,
        items: [newFlight],
      };

      return withCalculatedAmounts(newState);
    }
    case APPLY_LE_CREDIT: {
      const newState = {
        ...state,
        creditPaymentAmount: action.creditPaymentAmount,
      };

      return withCalculatedAmounts(newState);
    }
    case REDUCE_OFFER_ITEM_QUANTITY: {
      const { packageId } = action;
      const { id_salesforce_external: offerId, offerCartId } = actionOffer;
      const offer = state.offers.find(
        (o) => o.id_salesforce_external === offerId && (o.offerCartId === offerCartId || singleOffer),
      );
      // remove the first package with matching predicates
      const newItems = [...offer.items];
      newItems.splice(
        offer.items.findIndex((item) => item.pkg.unique_key === packageId),
        1,
      );

      const newState = {
        ...state,
        offers: state.offers.map((o) =>
          o.id_salesforce_external === offerId && (o.offerCartId === offerCartId || singleOffer)
            ? { ...o, items: newItems }
            : o,
        ),
      };

      return withCalculatedAmounts(newState);
    }
    case REMOVE_ADDON_FROM_CART: {
      const { addonID, offerId, offerCartId } = action;
      const offer = state.offers.find(
        (o) => o.id_salesforce_external === offerId && (o.offerCartId === offerCartId || singleOffer),
      );

      // remove the first addon with matching predicates
      const newAddons = [...offer.addons];
      newAddons.splice(
        offer.addons.findIndex(
          (addon) => addon.addonId === addonID && addon.offerCartId === offerCartId && addon.offerId === offerId,
        ),
        1,
      );

      const newState = {
        ...state,
        items: [...state.items],
        offers: state.offers.map((o) =>
          o.id_salesforce_external === offer.id_salesforce_external && o.offerCartId === offer.offerCartId
            ? { ...o, addons: newAddons }
            : o,
        ),
      };
      return withCalculatedAmounts(newState);
    }
    case REDUCE_GIFT_CARD_QUANTITY: {
      // remove an item
      const idx = findIndex(state.items, (item) => {
        return item.price === action.price;
      });
      if (idx === -1) {
        return state;
      }

      const items = [...state.items.slice(0, idx), ...state.items.slice(idx + 1)];

      const newState = {
        ...state,
        items,
      };

      return withCalculatedAmounts(newState);
    }
    case CHANGE_CURRENCY_CODE: {
      const items = state.items.map((item) => ({
        ...item,
        currencyCode: action.currencyCode,
      }));
      const offers = state.offers.map((o) => ({
        ...o,
        items: o.items.map((item) => {
          const newItem = createOfferItem(
            item.pkg,
            action.currencyCode,
            {
              ...item,
              reservation: null,
            },
            state.reservationType,
            undefined,
            o,
          );

          newItem.transactionKey = generateTransactionKey();

          return newItem;
        }),
      }));

      const newState = {
        ...state,
        offers,
        items,
        orderId: '',
        transactionKey: generateTransactionKey(),
        currencyCode: action.currencyCode,
        regionCode: action.regionCode,
        stateOfResidence: '',
      };

      return withCalculatedAmounts(newState);
    }
    case UPDATE_CREDIT: {
      const { currencyCode, balance, error } = action;
      return {
        ...state,
        credit: {
          currencyCode,
          balance,
          error,
        },
      };
    }
    case SET_PROMO_CODE_ERROR: {
      return setPromoCodeError(state, action.message);
    }
    case APPLY_PROMO_CODE: {
      const { promoDetails } = action;
      const date = new Date();

      if (date > new Date(promoDetails.expires_at)) {
        return setPromoCodeError(
          state,
          `This promo code expired on ${dayjs(promoDetails.expires_at).format('MMM Do YYYY')}`,
        );
      }

      if (promoDetails.currency !== null && promoDetails.currency !== state.currencyCode) {
        return setPromoCodeError(state, `This promo code has a specified currency - ${promoDetails.currency}`);
      }

      if (promoDetails.currency !== null && promoDetails.min_spend > state.amounts.subTotal) {
        const minSpend = currencyFormatter.format(promoDetails.min_spend, {
          code: promoDetails.currency,
        });
        return setPromoCodeError(state, `Minimum spend for this promo code is ${minSpend}`);
      }

      if (promoDetails.promo_discounts.length > 1) {
        return setPromoCodeError(state, `This promo code has more than 1 condition and not supported by admin portal`);
      }

      if (promoDetails.id_promo_code) {
        const newState = {
          ...state,
          promo: {
            id: promoDetails.id_promo_code,
            code: promoDetails.code_name,
            error: null,
            discount: promoDetails.promo_discounts[0].discount_value,
            type: promoDetails.promo_type,
            maxDiscount: promoDetails.promo_discounts[0].max_discount,
          },
        };

        return withCalculatedAmounts(newState);
      }

      return state;
    }
    case SET_ORDER_ID: {
      return {
        ...state,
        orderId: action.orderId,
      };
    }
    case SET_BUSINESS_ID: {
      return {
        ...state,
        businessId: action.businessId,
      };
    }
    case SET_BOOK_DATES_FOR_ORDER: {
      return {
        ...state,
        bookDatesOnly: true,
        orderId: action.orderId,
      };
    }
    case ALLOW_TOGGLE_RESERVATION_TYPE: {
      return {
        ...state,
        allowToggleReservationType: action.allowToggleReservationType,
      };
    }
    case UPDATE_STATE_OF_RESIDENCE: {
      return {
        ...state,
        stateOfResidence: action.stateOfResidence,
      };
    }
    case UPDATE_RESERVATION: {
      const updatedOffers = offers.map((o) => {
        if (
          (o.offerCartId === actionOffer.offerCartId || singleOffer) &&
          o.id_salesforce_external === actionOffer.id_salesforce_external
        ) {
          o.items = itemsReducer(o.items, action, currencyCode, reservationFXRates);
        }
        return o;
      });
      const newState = {
        ...state,
        offers: updatedOffers,
      };

      return withCalculatedAmounts(newState);
    }
    case TOGGLE_BOOKING_DATES: {
      const updatedOffers = state.offers.map((o) => {
        if (
          (o.offerCartId === actionOffer.offerCartId || singleOffer) &&
          o.id_salesforce_external === actionOffer.id_salesforce_external
        ) {
          o.items = itemsReducer(o.items, action, currencyCode, reservationFXRates);
        }
        return o;
      });
      return {
        ...state,
        offers: updatedOffers,
      };
    }
    case TOGGLE_RESERVATION_TYPE: {
      const getInverseReservationType = (item) =>
        item.reservation.reservationType === RESERVATION_TYPE_INSTANT_BOOKING
          ? { type: RESERVATION_TYPE_BOOK_LATER, applySurcharge: false }
          : { type: RESERVATION_TYPE_INSTANT_BOOKING, applySurcharge: true };

      const updatedOffers = offers.map((o) => {
        if (
          (o.offerCartId === actionOffer.offerCartId || singleOffer) &&
          o.id_salesforce_external === actionOffer.id_salesforce_external
        ) {
          o.items = o.items.map((item) => {
            const newType = getInverseReservationType(item);
            return {
              ...item,
              surcharge: newType.applySurcharge ? item.reservation.durationSurchargeTotal : 0,
              extraGuestSurcharge: newType.applySurcharge ? item.reservation.extraGuestSurcharge : 0,
              reservation: {
                ...item.reservation,
                reservationType: newType.type,
              },
            };
          });
        }
        return o;
      });

      const newState = {
        ...state,
        offers: updatedOffers,
      };

      return withCalculatedAmounts(newState);
    }
    case START_ENQUIRY:
    case FINISH_ENQUIRY:
    case RECEIVE_ENQUIRY:
      const updatedOffers = offers.map((o) => {
        if (
          (o.offerCartId === actionOffer.offerCartId || singleOffer) &&
          o.id_salesforce_external === actionOffer.id_salesforce_external
        ) {
          o.items = itemsReducer(o.items, action, currencyCode, reservationFXRates);
        }
        return o;
      });
      return {
        ...state,
        offers: updatedOffers,
      };
    case UPDATE_CONTACT_DETAILS:
      const { phonePrefix, phone } = action;

      return {
        ...state,
        contactPhonePrefix: phonePrefix,
        contactPhone: phone,
      };
    case REMOVE_INSURANCE_OPTION: {
      return withCalculatedAmounts({
        ...state,
        insurance: { ...initialState.insurance },
      });
    }
    case SELECT_INSURANCE_OPTION: {
      return withCalculatedAmounts({
        ...state,
        insurance: {
          total: action.total,
          quoteId: action.quoteId,
          productId: action.productId,
          productName: action.productName,
          applied: true,
        },
      });
    }
    case UPDATE_ORDER_NOTES: {
      return {
        ...state,
        notes: action.notes,
      };
    }
    case ADD_RESERVATION_FX_RATES_TO_CART: {
      return {
        ...state,
        reservationFXRates: (action.reservationFXRates || []).reduce((acc, rate) => {
          acc[rate.currency] = rate.rate;
          return acc;
        }, {}),
      };
    }
    case SUCCESS_FETCH_ADDONS: {
      return updatePackage(state, action.offerId, action.packageId, (offerPackage) => {
        offerPackage.addons = action.addons;
        return offerPackage;
      });
    }
    default:
      return state;
  }
}
