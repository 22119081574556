import React, { Component } from 'react';

import indexOf from 'lodash/indexOf';

import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

import ReservationService from '~/services/ReservationService';
import { formatDateISO, isAfter } from '~/services/TimeService';

import { reportError } from '~/utils/reportError';

import DateWidget from '../Elements/DateWidget';

import CopyToTourOptionsModal from './CopyToTourOptionsModal';
import MultipleTourDatesForm from './MultipleTourDatesForm';
import { buttonMessages, buttonStates } from './states/submitButton';

const deleteButtonMsgs = {
  default: 'Delete',
  failed: 'Failed',
  deleting: 'Deleting..',
};

interface CopyDatesResponse {
  errors?: string[];
  message: string;
  name: string;
  status: number;
}

interface Props {
  addingMultipleDates: boolean;
  closeAddMultipleDates: () => void;
  tourId: string;
  tourOptionDates: App.TourOptionDate[];
  tourOptionId: App.TourOptionId;
}

interface State {
  copyToError: string[];
  copyToState: string;
  selectedDatesFormKeys: number[];
  showCopyToTourOptions: boolean;
  tourOptionDataList: App.TourOptionLabel[];
  tourOptionDates: App.TourOptionDate[];
}

class TourOptionDateForm extends Component<Props, State> {
  columns: GridColDef[];

  constructor(props: Props) {
    super(props);

    this.state = {
      tourOptionDates: props.tourOptionDates
        .map((date, i) => ({
          ...date,
          formKey: i,
          saveState: buttonStates.loaded,
          deleteButtonMsg: deleteButtonMsgs.default,
        }))
        // We don't want dates to be sorted when changing dates, so don't rely on bootstrap table sorting.
        .sort((d1, d2) => (isAfter(d1.start_date, d2.start_date) ? 1 : -1)),
      selectedDatesFormKeys: [],
      showCopyToTourOptions: false,
      tourOptionDataList: [],
      copyToState: buttonStates.default,
      copyToError: [],
    };

    this.columns = [
      {
        field: 'start_date',
        headerName: 'Start date',
        flex: 1,
        sortable: false,
        renderCell: this.dateFormatter,
        display: 'flex',
      },
      {
        field: 'total',
        headerName: 'Total availability',
        flex: 1,
        sortable: false,
        renderCell: this.availabilityFormatter,
        display: 'flex',
      },
      { field: 'count', headerName: 'Number of bookings', width: 250, sortable: false, display: 'flex' },
      {
        field: 'save',
        headerName: '',
        sortable: false,
        renderCell: this.saveFormatter,
        display: 'flex',
      },
      {
        field: 'delete',
        headerName: '',
        sortable: false,
        renderCell: this.deleteFormatter,
        display: 'flex',
      },
    ];
  }

  updateDatesStateOnChange = (formKey: number, newDate: App.TourOptionDate) => {
    this.setState({
      tourOptionDates: this.state.tourOptionDates.map((date) => (date.formKey === formKey ? newDate : date)),
    });
  };

  onSubmit = async (row: App.TourOptionDate) => {
    const submitData = {
      id: row.id,
      start_date: row.start_date,
      total: row.total,
      tour_option_id: row.tour_option_id,
    };

    this.updateDatesStateOnChange(row.formKey, {
      ...row,
      saveState: buttonStates.saving,
    });

    let submitType = 'updateTourOptionDate';
    if (!row.id) {
      submitType = 'createTourOptionDate';
    }

    const tourId = this.props.tourId;
    const tourOptionId = row.tour_option_id;
    const tourOptionDateId = row.id;

    try {
      const response = await ReservationService[submitType](submitData, tourId, tourOptionId, tourOptionDateId);
      this.updateDatesStateOnChange(row.formKey, {
        ...response.result,
        saveState: buttonStates.saved,
        deleteButtonMsg: deleteButtonMsgs.default,
        formKey: row.formKey,
      });
    } catch (error) {
      this.updateDatesStateOnChange(row.formKey, {
        ...row,
        saveState: buttonStates.failed,
      });
      reportError(error);
    }
  };

  onChange = (row: App.TourOptionDate, e) => {
    this.updateDatesStateOnChange(row.formKey, {
      ...row,
      [e.target.name]: e.target.value,
      saveState: buttonStates.unsaved,
      deleteButtonMsg: deleteButtonMsgs.default,
    });
  };

  onDelete = async (row: App.TourOptionDate) => {
    if (row.count) {
      alert(`Unable to delete, this date (${row.start_date}) has bookings present.`);
      return;
    }

    this.updateDatesStateOnChange(row.formKey, {
      ...row,
      deleteButtonMsg: deleteButtonMsgs.deleting,
    });
    if (row.id) {
      const tourId = this.props.tourId;
      const tourOptionId = row.tour_option_id;
      const tourOptionDateId = row.id;
      try {
        await ReservationService.deleteTourOptionDate(tourId, tourOptionId, tourOptionDateId);

        this.handleRemove(row.formKey);
      } catch (error) {
        reportError(error);
        if (this.state.tourOptionDates.find((date) => date.formKey === row.formKey)) {
          this.updateDatesStateOnChange(row.formKey, {
            ...row,
            deleteButtonMsg: deleteButtonMsgs.failed,
          });
        }
      }
    } else {
      this.handleRemove(row.formKey);
    }
  };

  updateStartDate = (newDate: string, row: App.TourOptionDate) => {
    this.updateDatesStateOnChange(row.formKey, {
      ...row,
      start_date: newDate,
      saveState: buttonStates.unsaved,
      deleteButtonMsg: deleteButtonMsgs.default,
    });
  };

  deleteFormatter = ({ row }: GridRenderCellParams<App.TourOptionDate>) => (
    <ConfirmButton onConfirm={() => this.onDelete(row)}>{row.deleteButtonMsg}</ConfirmButton>
  );

  saveFormatter = ({ row }: GridRenderCellParams<App.TourOptionDate>) => (
    <Button variant="text" disabled={row.saveState == buttonStates.saving} onClick={() => this.onSubmit(row)}>
      {buttonMessages[row.saveState]}
    </Button>
  );

  onToggleCheckbox = (row: App.TourOptionDate) => () => {
    if (this.state.selectedDatesFormKeys.includes(row.formKey)) {
      const newSelected = this.state.selectedDatesFormKeys.filter((i) => i !== row.formKey);
      this.setState({
        selectedDatesFormKeys: newSelected,
      });
    } else {
      const newSelected = this.state.selectedDatesFormKeys;
      newSelected.push(row.formKey);
      this.setState({
        selectedDatesFormKeys: newSelected,
      });
    }
  };

  dateFormatter = ({ row }: GridRenderCellParams<App.TourOptionDate>) => (
    <Stack direction="row" spacing={1} alignItems="center">
      <Checkbox
        checked={this.state.selectedDatesFormKeys.includes(row.formKey)}
        onChange={this.onToggleCheckbox(row)}
      />
      <DateWidget value={row.start_date} onChange={(newDate) => this.updateStartDate(newDate, row)} />
    </Stack>
  );

  availabilityFormatter = ({ row }: GridRenderCellParams<App.TourOptionDate>) => (
    <TextField value={row.total} type="number" name="total" onChange={(e) => this.onChange(row, e)} />
  );

  nextFormKey = () => {
    if (this.state.tourOptionDates.length === 0) {
      return 0;
    }
    return Math.max(...this.state.tourOptionDates.map((date) => date.formKey)) + 1;
  };

  handleRemove = (formKey: number) => {
    const newTourOptionDates = this.state.tourOptionDates.filter((date) => date.formKey !== formKey);
    this.setState({
      tourOptionDates: newTourOptionDates,
    });
  };

  handleAddNew = () => {
    const newTourOptionDate = {
      tour_option_id: this.props.tourOptionId,
      start_date: formatDateISO(),
      total: 1,
      count: 0,
      formKey: this.nextFormKey(),
      deleteButtonMsg: deleteButtonMsgs.default,
      saveState: buttonStates.unsaved,
    };

    const newTourOptionDates = [...this.state.tourOptionDates];
    newTourOptionDates.push(newTourOptionDate);

    this.setState({
      tourOptionDates: newTourOptionDates,
    });
  };

  getAllOtherTourOptions = (allOptions) => {
    const filteredOutCurrentOption = allOptions
      .filter((i) => i.id !== this.props.tourOptionId)
      .map((tourOptionData) => ({
        value: tourOptionData.id,
        label: tourOptionData.name,
      }));
    return filteredOutCurrentOption;
  };

  fetchTourOptionsList = () => {
    ReservationService.getTour(this.props.tourId)
      .then((response) => {
        this.setState({
          tourOptionDataList: this.getAllOtherTourOptions(response.result.tour_options),
        });
      })
      .catch((error) => reportError(error));
  };

  handleCopyDates = () => {
    this.fetchTourOptionsList();
    this.setState({
      showCopyToTourOptions: true,
    });
  };

  handleAddMultiple = (multipleTourOptionDates: App.TourOptionDate[], inventory: number) => {
    const newTourOptionDates = [...this.state.tourOptionDates];

    for (const item of multipleTourOptionDates) {
      const newTourOptionDate = {
        tour_option_id: this.props.tourOptionId,
        start_date: item.start_date,
        total: inventory,
        count: 0,
        formKey: this.nextFormKey() + indexOf(multipleTourOptionDates, item),
        deleteButtonMsg: deleteButtonMsgs.default,
        saveState: buttonStates.unsaved,
      };
      newTourOptionDates.push(newTourOptionDate);
    }
    this.setState({
      tourOptionDates: newTourOptionDates,
    });
  };

  onSubmitAll = async () => {
    // Save new rows and changes to dates and inventories for all unsaved rows.
    const tourOptionDates = [...this.state.tourOptionDates];
    for (const tourOptionDate of tourOptionDates) {
      if (tourOptionDate.saveState === buttonStates.unsaved) {
        const submitData = {
          id: tourOptionDate.id,
          start_date: tourOptionDate.start_date,
          total: tourOptionDate.total,
          tour_option_id: tourOptionDate.tour_option_id,
        };

        this.updateDatesStateOnChange(tourOptionDate.formKey, {
          ...tourOptionDate,
          saveState: buttonStates.saving,
        });

        let submitType = 'updateTourOptionDate';
        if (!tourOptionDate.id) {
          submitType = 'createTourOptionDate';
        }

        const tourId = this.props.tourId;
        const tourOptionId = tourOptionDate.tour_option_id;
        const tourOptionDateId = tourOptionDate.id;

        try {
          const response = await ReservationService[submitType](submitData, tourId, tourOptionId, tourOptionDateId);
          this.updateDatesStateOnChange(tourOptionDate.formKey, {
            ...response.result,
            saveState: buttonStates.saved,
            deleteButtonMsg: deleteButtonMsgs.default,
            formKey: tourOptionDate.formKey,
          });
        } catch (error) {
          this.updateDatesStateOnChange(tourOptionDate.formKey, {
            ...tourOptionDate,
            saveState: buttonStates.failed,
          });
          reportError(error);
        }
      }
    }
  };

  closeCopyToTourOptions = () => {
    this.setState({
      showCopyToTourOptions: false,
      copyToError: [],
      copyToState: buttonStates.default,
    });
  };

  onCopyToTourOptionsSubmit = async (
    selectedTourOptions: App.TourOptionLabel[],
    selectedDates: App.TourOptionDate[],
    copyAvailability: boolean,
    inputInventory: number,
  ) => {
    this.setState({ copyToState: buttonStates.saving, copyToError: [] });
    const datesToCopy = selectedDates.map((date) => {
      const total = copyAvailability ? date.total : inputInventory;
      return {
        start_date: date.start_date,
        total,
      };
    });
    try {
      const submitData = {
        copyToTourOptionIds: selectedTourOptions,
        datesToCopy,
      };
      await ReservationService.createTourOptionDates(submitData);
      this.handleCopyDatesResponseSuccess();
    } catch (error) {
      reportError(error);
      this.handleCopyDatesResponseError(error);
    }
  };

  setButtonToDefault = () => {
    this.setState({ copyToState: buttonStates.default });
  };

  reload() {
    location.reload();
  }

  handleCopyDatesResponseSuccess() {
    this.setState({ copyToState: buttonStates.saved, copyToError: [] });
    // refresh page
    setTimeout(this.reload, 2000);
  }

  handleCopyDatesResponseError(copyDatesResponse: CopyDatesResponse) {
    if (copyDatesResponse.status === 400 && copyDatesResponse.errors?.length > 1) {
      const errorsResponseMessage = copyDatesResponse.errors;
      this.setState({
        copyToState: buttonStates.failed,
        copyToError: [...errorsResponseMessage],
      });
    } else {
      this.setState({
        copyToState: buttonStates.failed,
        copyToError: [copyDatesResponse.message],
      });
    }
  }

  render() {
    return (
      <>
        <DataGrid
          columns={this.columns}
          rows={this.state.tourOptionDates}
          getRowId={(row) => row.id ?? (Math.random() * 10000).toString()}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
        />

        <Stack mt={1} direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center">
            <Button variant="contained" onClick={this.handleAddNew}>
              New
            </Button>
            <Button
              variant="contained"
              onClick={this.handleCopyDates}
              disabled={this.state.tourOptionDates.some((row) => row.saveState === buttonStates.unsaved || !row.id)}
            >
              Copy to tour options
            </Button>
          </Stack>

          <div>
            <Button variant="contained" onClick={this.onSubmitAll}>
              Save All Changes
            </Button>
          </div>
        </Stack>

        <Dialog open={this.props.addingMultipleDates} onClose={this.props.closeAddMultipleDates}>
          <DialogTitle>Select departure dates</DialogTitle>
          <DialogContent>
            <MultipleTourDatesForm
              handleAddMultiple={this.handleAddMultiple}
              closeAddMultipleDates={this.props.closeAddMultipleDates}
            />
          </DialogContent>
        </Dialog>

        <CopyToTourOptionsModal
          isOpen={this.state.showCopyToTourOptions}
          onHide={this.closeCopyToTourOptions}
          selectedDatesFormKeys={this.state.selectedDatesFormKeys}
          currentTourOptionDates={this.state.tourOptionDates}
          tourOptionDataList={this.state.tourOptionDataList}
          copyToState={this.state.copyToState}
          onCopyToTourOptionsSubmit={this.onCopyToTourOptionsSubmit}
          setButtonToDefault={this.setButtonToDefault}
          apiErrors={this.state.copyToError}
        />
      </>
    );
  }
}

export default TourOptionDateForm;
