import React from 'react';

import { Link } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { getBonusUnitPluralize } from '~/services/LoyaltyService';

import dateFormatter from '~/utils/dateFormatter';

const EarnDetails = ({ data, partnership, bonusUnit }) => (
  <List disablePadding>
    <ListItem>
      <ListItemText primary="Transaction ID" secondary={data.id} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Created at" secondary={dateFormatter(data.created_at)} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Last updated" secondary={dateFormatter(data.updated_at)} />
    </ListItem>
    <ListItem>
      <ListItemText
        primary={`${partnership.prefix.toUpperCase()} ID`}
        secondary={data[`${partnership.prefix}_number`]}
      />
    </ListItem>
    <ListItemButton component={Link} to={`/purchases/${data.order_id}`}>
      <ListItemText primary="Order ID" secondary={data.order_id} />
    </ListItemButton>
    <ListItem>
      <ListItemText primary="Order date" secondary={dateFormatter(data.order_date)} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Order item" secondary={data.order_item_name} />
    </ListItem>
    {data[`${partnership.prefix}_bonus_points`] && (
      <ListItem>
        <ListItemText primary="Package Bonus Points" secondary={data[`${partnership.prefix}_bonus_points`]} />
      </ListItem>
    )}
    <ListItem>
      <ListItemText primary="Order payment amount" secondary={`$${data.order_payment_amount}`} />
    </ListItem>
    <ListItem>
      <ListItemText primary={`Total ${bonusUnit} Earned`} secondary={data[`${bonusUnit}_total_earned`]} />
    </ListItem>
  </List>
);

const BurnDetails = ({ data }) => (
  <List disablePadding>
    <ListItem>
      <ListItemText primary="Status" secondary={data.status} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Transaction ID" secondary={data.id} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Created At" secondary={dateFormatter(data.created_at)} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Last Updated" secondary={dateFormatter(data.updated_at)} />
    </ListItem>
    <ListItem>
      <ListItemText primary="QFF ID" secondary={data.qff} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Account ID" secondary={data.fk_account_id} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Amount" secondary={`$${data.burn_amount}`} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Points Used" secondary={data.burn_points} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Burn ID" secondary={data.burn_transaction_number} />
    </ListItem>
    <ListItem>
      <ListItemText primary="Burn Started" secondary={dateFormatter(data.burn_initiated_at)} />
    </ListItem>
    {data.burn_succeeded_at && (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ color: 'green' }}
          primary="Burn Completed"
          secondary={dateFormatter(data.burn_succeeded_at)}
        />
      </ListItem>
    )}
    {data.burn_failed_at && (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ color: 'red' }}
          primary="Burn Failed"
          secondary={dateFormatter(data.burn_failed_at)}
        />
      </ListItem>
    )}
    {data.burn_reversed_at && (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ color: 'yellow' }}
          primary="Burn Reversed"
          secondary={dateFormatter(data.burn_reversed_at)}
        />
      </ListItem>
    )}
    {data.credit_succeeded_at && (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ color: 'green' }}
          primary="Credit Completed"
          secondary={dateFormatter(data.credit_succeeded_at)}
        />
      </ListItem>
    )}
    {data.credit_failed_at && (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ color: 'red' }}
          primary="Credit Failed"
          secondary={dateFormatter(data.credit_failed_at)}
        />
      </ListItem>
    )}
  </List>
);

export default function LoyaltyDetailsModal({ partnership, show, data, type, onHide }) {
  const bonusUnit = getBonusUnitPluralize(partnership);

  if (data === null) return null;

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Details</DialogTitle>
      <DialogContent>
        {type === 'earn' ? (
          <EarnDetails data={data} partnership={partnership} bonusUnit={bonusUnit} />
        ) : (
          <BurnDetails data={data} />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onHide}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
