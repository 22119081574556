import React from 'react';

import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Button, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { isAllCancelled } from '~/utils/order';

const packagesOrderLinkFormatter = (params: GridRenderCellParams<App.Order, string>) => {
  const { row } = params;

  if (row.items.length) {
    const allAccommodationsCancelled = isAllCancelled(row.items);
    const allAddonsCancelled = isAllCancelled(row.addon_items);

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Button variant="text" size="small" href={'/purchases/' + row.id_orders} target="_blank">
          Details
        </Button>

        {row.has_addons && allAccommodationsCancelled && !allAddonsCancelled && (
          <span title="This order has all accommodation items cancelled but some addons still active.">
            <WarningOutlinedIcon color="warning" />
          </span>
        )}
      </Stack>
    );
  }
  return '';
};

export default packagesOrderLinkFormatter;
