import React from 'react';

import sum from 'lodash/sum';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import { ORDER_STATUS_COMPLETED } from '~/consts/order';

import { getSuccessfulItemsTotal } from '~/utils/order';

import dateFormatter from './dateFormatter';

const orderInformationFormatter = (params: GridRenderCellParams<Order.Order>) => {
  const order = params.row;

  const credit = order.business_credit_items?.length > 0 ? order.business_credit_items[0].total : 0;
  const depositTotal =
    order.custom_offer_items?.length > 0 && order.custom_offer_items[0].custom_offer.payment_metadata
      ? Math.round(order.custom_offer_items[0].custom_offer.payment_metadata.customer_deposit_1_amount / 100)
      : 0;
  const total = order.status !== ORDER_STATUS_COMPLETED ? order.total : getSuccessfulItemsTotal(order);

  const bedbankMarginTotal = sum(order.bedbank_items.map((item) => item.marketing_fee));
  const bedbankOrderTotal = sum(order.bedbank_items.map((item) => item.total));
  // in the unlikely case a bedbank order has total === 0
  const bedbankMarginPercentage = bedbankOrderTotal > 0 ? (bedbankMarginTotal / bedbankOrderTotal) * 100 : 0;

  return (
    <Stack direction="column" sx={{ '& strong': { fontWeight: 500 } }}>
      <Typography>
        <strong>Order date</strong>: {dateFormatter(order.created_at)}
      </Typography>

      <Typography>
        <strong>Status</strong>: {order.status}
      </Typography>

      <Typography>
        <strong>Order total</strong>: {(total + credit + depositTotal).toFixed(2)}
      </Typography>

      {order.bedbank_items.length > 0 && (
        <Typography>
          <strong>Bedbank margin</strong>: {bedbankMarginTotal.toFixed(2)} ({bedbankMarginPercentage.toFixed(2)}% of{' '}
          {bedbankOrderTotal.toFixed(2)})
        </Typography>
      )}

      <Typography>
        <strong>Currency</strong>: {order.currency_code}
      </Typography>
    </Stack>
  );
};

export default orderInformationFormatter;
