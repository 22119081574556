import React, { useCallback, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getAffiliationList, getEOI, updateEOIDetails } from '~/services/AgentHub/AgentService';

import { Affiliation, Eoi } from '~/types/services/agentHub';

import AgencySubmissionDetails from './AgencySubmissionDetails';
import AgencySubmissionEditor from './AgencySubmissionEditor';

interface Props {
  id: string;
  onClose?: () => void;
  opened?: boolean;
}

export default function AgencySubmissionDetailModal({ id, onClose, opened = false }: Props) {
  const [open, setOpen] = useState(opened);
  const [eoiDetails, setEoiDetails] = useState<Eoi | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [availableAffiliations, setAvailableAffiliations] = useState<Array<Affiliation>>([]);

  const closeModal = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [setOpen, onClose]);

  const openModal = useCallback(async () => {
    if (!id) {
      closeModal();
    }

    setOpen(true);

    try {
      setLoading(true);

      const [eoiDetailsResult, availableAffiliationsResult] = await Promise.all([getEOI(id), getAffiliationList()]);

      setEoiDetails(eoiDetailsResult);
      setAvailableAffiliations(availableAffiliationsResult);
    } finally {
      setLoading(false);
    }
  }, [setOpen, closeModal, id]);

  const onSave = useCallback(
    async (eoiData: Eoi) => {
      const result = await updateEOIDetails(id, eoiData);

      setEoiDetails(result);
      setEditing(false);
    },
    [id],
  );

  const onCancel = useCallback(() => {
    setEditing(false);
  }, []);

  useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [openModal, opened]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>Agency Submission Details</DialogTitle>
      <DialogContent>
        {loading && <Spinner />}
        {!loading && editing && (
          <AgencySubmissionEditor
            eoiData={eoiDetails}
            availableAffiliations={availableAffiliations}
            onCancel={onCancel}
            onSave={onSave}
          />
        )}
        {!loading && !editing && eoiDetails && (
          <AgencySubmissionDetails onSave={onSave} eoiDetails={eoiDetails} setEditing={setEditing} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
