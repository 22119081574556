import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Box, Typography } from '@mui/material';

import BookingCancelledToggle from './BookingCancelledToggle';
import RefundAmount from './RefundAmount';
import VccBalanceUpdateToggle from './VccBalanceUpdateToggle';

export default class PartialRefundVariant extends React.Component {
  render() {
    return (
      <Box id="CustomRefundVariant" display="flex" flexDirection="column" gap={1}>
        <RefundAmount
          tmpValue={this.props.tmpValue}
          handleTmpChange={this.props.handleTmpChange}
          refund={this.props.refund}
          itemRefund={this.props.itemRefund}
          itemRefundOriginal={this.props.itemRefundOriginal}
        />
        {this.props.refund.is_subscription && (
          <Typography>
            Pro rata refund for the remainder of the annual subscription:{' '}
            <Typography fontWeight="bold" as="span">
              {currencyFormatter.format(this.props.refund.pro_rata_subscription_refund_amount, {
                code: this.props.refund.currency_code,
                precision: 2,
              })}
            </Typography>
          </Typography>
        )}
        {!this.props.refund.is_subscription && (
          <>
            <BookingCancelledToggle
              handleFlagChange={this.props.handleFlagChange}
              mark_cancelled={this.props.refund.mark_cancelled}
            />
            <VccBalanceUpdateToggle
              handleFlagChange={this.props.handleFlagChange}
              update_vcc_balance={this.props.refund.update_vcc_balance}
            />
          </>
        )}
      </Box>
    );
  }
}
