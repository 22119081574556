import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { Container, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import RatePlanForm from '~/components/Common/Forms/RatePlanForm';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

export default function RatePlanNewContainer() {
  const { id_vendor: vendorId } = useParams<{ id_vendor: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState(null);
  const [packagedRatePlans, setPackagedRatePlans] = useState(null);
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getRatePlansSchema(),
      VendorsService.getVendorById(vendorId),
      ReservationService.getRatePlans(vendorId, true),
    ])
      .then(([schema, vendor, packagedRatePlans]) => {
        setSchema(schema.post.body.schema);
        setVendor(vendor.result);
        setPackagedRatePlans(packagedRatePlans.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load vendor.</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Vendors | {vendor?.name || vendorId} | Create rate plan</title>
      </Helmet>

      <PageHeader title="Create rate plan" backButton={`/vendors/${vendorId}`} />

      <RatePlanForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        schema={schema}
        ratePlan={{
          id_salesforce_external: vendorId,
        }}
        packagedRatePlans={packagedRatePlans}
        vendorId={vendorId}
      />
    </Container>
  );
}
