import { useEffect, useState } from 'react';

import { Brand, getBrands } from '~/services/ExperiencesService';

export default function useBrands() {
  const [brands, setBrands] = useState<Brand[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchExperienceBrands = async () => {
      setLoading(true);
      try {
        const allBrands = await getBrands();
        setBrands(allBrands.result);
      } catch (error) {
        setError(error);
        setBrands(null);
      } finally {
        setLoading(false);
      }
    };
    fetchExperienceBrands();
  }, []);

  return { data: brands, loading, error };
}
