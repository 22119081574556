import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { Helmet } from 'react-helmet';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import * as PromoContract from '@luxuryescapes/contract-svc-promo';
import * as libRegions from '@luxuryescapes/lib-regions';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

import useToggleState from '~/hooks/useToggleState';

import {
  deleteInAppMessagePromoById,
  getSchemaForInAppMessagePromo,
  listInAppPromoMessage,
} from '~/services/PromoService';
import { formatDateISO, formatDateNumeralMonths } from '~/services/TimeService';

import convertSchemaTypesWithIsoFormatToDate from '../Common/Forms/helpers/convertSchemaTypesWithIsoFormatToDate';
import DeleteInAppPromoMessageModal from '../Common/Modals/DeleteInAppPromoMessageModal';
import { withTenant } from '../hoc';

import InAppPromoMessageForm from './InAppPromoMessageForm';

const MESSAGING_STATUSES: PromoContract.Promotion.ActiveOption[] = ['all', 'active', 'in-active'];

interface Props {
  tenant: App.Tenant;
}

function InAppMessageContainer({ tenant }: Props) {
  const [schemaState, setSchemaState] = useState<RJSFSchema>({} as RJSFSchema);
  const [regionState, setRegion] = useState<string>('AU');
  const [messageStatusState, setMessageStatus] = useState('all');
  const currDate = new Date();
  const [startDateState, setStartDate] = useState<string>(formatDateISO(currDate));
  currDate.setMonth(currDate.getMonth() + 1);
  const [endDateState, setEndDate] = useState<string>(formatDateISO(currDate));
  const [dataState, setData] = useState([]);
  const { isToggled: isModalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggleState();
  const [inAppPromoMessageEdit, setInAppPromoMessageEdit] =
    useState<PromoContract.Promotion.InternalFeaturePromoSchedule>(null);

  // Setting for inAppMessage deletion
  const [deleteInAppMessageId, setDeleteInAppMessageId] = useState(null);
  const {
    isToggled: isDeleteMessageModalOpen,
    toggleOn: openDeleteMessageModal,
    toggleOff: closeDeleteMessageModal,
  } = useToggleState();

  const fetchData = useCallback(async () => {
    const inAppMessage = await listInAppPromoMessage(
      tenant.brand,
      regionState,
      messageStatusState,
      startDateState,
      endDateState,
    );
    if (inAppMessage.featuredPromoSchedules && inAppMessage.featuredPromoSchedules.length > 0) {
      setData(inAppMessage.featuredPromoSchedules);
    } else {
      setData([]);
    }
  }, [tenant, regionState, messageStatusState, startDateState, endDateState]);

  const getSchema = async () => {
    const schema = await getSchemaForInAppMessagePromo();
    const refinedSchema = convertSchemaTypesWithIsoFormatToDate(schema.put.body.properties.featuredPromoSchedule);
    setSchemaState(refinedSchema);
  };

  useEffect(() => {
    fetchData();
  }, [tenant, regionState, messageStatusState, startDateState, endDateState, fetchData]);

  useEffect(() => {
    getSchema();
  }, []);

  const deleteInAppPromoMessage = useCallback(async () => {
    await deleteInAppMessagePromoById(deleteInAppMessageId);
    fetchData();
    setDeleteInAppMessageId(null);
  }, [deleteInAppMessageId, fetchData]);

  const editInAppPromoMessage = useCallback(
    (id: string) => {
      setInAppPromoMessageEdit(null);
      const editItem = dataState.find((data) => data.id === id);
      setInAppPromoMessageEdit({
        ...editItem,
        startAt: formatDateISO(editItem.startAt),
        endAt: formatDateISO(editItem.endAt),
      });
      openModal();
    },
    [dataState, openModal],
  );

  const createInAppPromoMessage = () => {
    setInAppPromoMessageEdit(null);
    openModal();
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'codeName',
        headerName: 'Code Name',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'region',
        headerName: 'Region',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'brand',
        headerName: 'Brand',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'startAt',
        headerName: 'Start At',
        flex: 1,
        valueFormatter: (value) => formatDateNumeralMonths(value) || '',
        display: 'flex',
      },
      {
        field: 'endAt',
        headerName: 'End At',
        flex: 1,
        valueFormatter: (value) => formatDateNumeralMonths(value) || '',
        display: 'flex',
      },
      {
        field: 'active',
        headerName: 'Status',
        valueFormatter: (value) => (value ? 'active' : 'in-active'),
        flex: 1,
        display: 'flex',
      },
      {
        field: 'heading',
        headerName: 'Heading',
        flex: 1,
        display: 'flex',
      },
      {
        field: 'details',
        headerName: 'Details',
        flex: 1,
        display: 'flex',
      },
      {
        field: '',
        headerName: 'Details',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <Button
                className="expire-button"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setDeleteInAppMessageId(params.row.id);
                  openDeleteMessageModal();
                }}
              >
                Delete
              </Button>
              <Button
                className="edit-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  editInAppPromoMessage(params.row.id);
                }}
              >
                Edit
              </Button>
            </div>
          );
        },
        display: 'flex',
      },
    ],
    [editInAppPromoMessage, openDeleteMessageModal],
  );

  return (
    <div>
      <Helmet>
        <title>In App Promo Message</title>
      </Helmet>
      <Grid container spacing={2} mb={2}>
        <Grid md={6}>
          <Typography variant="h4">Search In App Promo Message</Typography>
        </Grid>
        <Grid md={5} />
        <Grid md={1}>
          <Typography variant="h3">
            <Button variant="contained" onClick={createInAppPromoMessage}>
              Create
            </Button>
          </Typography>
        </Grid>
        <Grid md={3}>
          <FormControl fullWidth>
            <InputLabel id="select-region-label">Select Region</InputLabel>
            <Select
              labelId="select-region-label"
              value={regionState}
              label="Select Region"
              onChange={(e) => setRegion(e.target.value)}
            >
              {libRegions.getRegionCodes().map((regionCode) => (
                <MenuItem key={regionCode} value={regionCode}>
                  {regionCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid md={3}>
          <FormControl fullWidth>
            <InputLabel id="select-status-label">Select Status</InputLabel>
            <Select
              labelId="select-status-label"
              value={messageStatusState}
              label="Select Status"
              onChange={(e) => setMessageStatus(e.target.value)}
            >
              {MESSAGING_STATUSES.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid md={3}>
          <DateTimeWidget
            showDateOnly
            format="DD.MM.YYYY."
            value={startDateState}
            onChange={(newDate) => setStartDate(newDate)}
            label="Select Start Date"
          />
        </Grid>
        <Grid md={3}>
          <DateTimeWidget
            showDateOnly
            format="DD.MM.YYYY."
            value={endDateState}
            onChange={(newDate) => setEndDate(newDate)}
            label="Select End Date"
          />
        </Grid>
      </Grid>
      <DataGrid autoHeight columns={columns} rows={dataState || []} />
      {deleteInAppMessageId && (
        <DeleteInAppPromoMessageModal
          openModal={isDeleteMessageModalOpen}
          closeModal={closeDeleteMessageModal}
          messageId={deleteInAppMessageId}
          deleteInAppPromoMessage={deleteInAppPromoMessage}
        />
      )}
      {Object.entries(schemaState).length > 0 && (
        <Dialog maxWidth="md" onClose={closeModal} open={isModalOpen} scroll="paper">
          <DialogTitle>
            {inAppPromoMessageEdit
              ? `Update In App Promo Message ${inAppPromoMessageEdit.id}`
              : 'Create In App Promo Message'}
          </DialogTitle>
          <DialogContent>
            <InAppPromoMessageForm
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              inAppPromoMessage={inAppPromoMessageEdit}
              fetchData={fetchData}
              schema={schemaState}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default withTenant(InAppMessageContainer);
