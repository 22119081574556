import React, { useEffect, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Button, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import nullsToEmptyStrings from '~/utils/nullsToEmptyStrings';

import cleanProperty from './helpers/cleanProperty';

const fetchProperty = (propertyId) => {
  return ReservationService.getProperty(propertyId);
};

const fetchVendor = (vendorId) => {
  return VendorsService.getVendorById(vendorId);
};

const formSchema: RJSFSchema = {
  type: 'object',
  title: 'Taxes and fees policy',
  properties: {
    content: {
      title: 'Taxes and fees policy',
      type: 'string',
    },
  },
};

const uiSchema = {
  content: {
    'ui:widget': MarkdownEditor,
  },
};

export default function PropertyTaxesContentEditPage(props) {
  const { id_property: propertyId, id_vendor: vendorId } = useParams<{ id_vendor: string; id_property: string }>();

  const [property, setProperty] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [contentText, setContentText] = useState('');
  const [saveState, setSaveState] = useState(buttonStates.default);
  const [err, setErr] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const onChange = ({ formData }: IChangeEvent) => {
    setErr(null);
    setSaveState(buttonStates.default);
    setContentText(formData.content);
  };

  const onSubmit = () => {
    setErr(null);
    const payload = {
      ...property,
      pms: property.pms ? property.pms : undefined,
      taxes_and_fees_content: typeof contentText === 'undefined' ? '' : contentText,
    };

    setSaveState(buttonStates.saving);
    ReservationService.updateProperty(payload, propertyId)
      .then(() => {
        const { history } = props;
        history.push(`/vendors/${vendorId}/properties/${propertyId}`);
      })
      .catch((e) => {
        setSaveState(buttonStates.failed);
        setErr(e);
      });
  };

  useEffect(() => {
    Promise.all([fetchProperty(propertyId), fetchVendor(vendorId)]).then(([property, vendor]) => {
      setProperty(cleanProperty(property.result));
      setContentText(nullsToEmptyStrings(property.result.taxes_and_fees_content));
      setVendor(vendor.result);
      setHasLoaded(true);
    });
  }, [propertyId, vendorId]);

  const formData = {
    content: contentText || '',
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor?.name || vendorId} | {property?.name || propertyId} | Edit Taxes and Fees Content
        </title>
      </Helmet>

      <PageHeader
        title="Edit property taxes and fees content"
        backButton={`/vendors/${vendorId}/properties/${propertyId}`}
      />

      {!hasLoaded && <div>Loading...</div>}

      {hasLoaded && (
        <Form
          schema={formSchema}
          formData={formData}
          uiSchema={uiSchema}
          onChange={onChange}
          onSubmit={onSubmit}
          validator={validator}
        >
          <Button variant="contained" type="submit" disabled={saveState === buttonStates.saving}>
            {buttonMessages[saveState]}
          </Button>
        </Form>
      )}

      {!!err && <ErrorDisplay message={err.message} />}
    </Container>
  );
}
