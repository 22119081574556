import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { Box, Button, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import * as libRegions from '@luxuryescapes/lib-regions';

import { LUX_PLUS } from '~/consts/membership';

import useCurrentUser from '~/hooks/useCurrentUser';

import { createCustomerSubscriptionsForLuxPlusAdmins, getSubscriptionOffers } from '~/services/MembershipService';

import { hasLuxPlusAdminAccess } from '~/utils/luxPlus';

import { validateMembershipBrand, validateMembershipRegion } from './utils';

interface Props {
  customer: App.User;
  refreshData: () => Promise<void>;
  canViewLuxPlusBenefits: boolean;
}

const luxPlusRoyalBlue = '#4169E1';

const RoyalBlueButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(luxPlusRoyalBlue),
  backgroundColor: luxPlusRoyalBlue,
  '&:hover': {
    backgroundColor: luxPlusRoyalBlue,
  },
}));

export default function UpgradeToLuxPlusButton({ customer, refreshData, canViewLuxPlusBenefits }: Props) {
  const { user } = useCurrentUser();
  const tenant = useSelector((state: App.State) => state.tenant);
  const { brand } = tenant;
  const { enqueueSnackbar } = useSnackbar();

  const [isProcessing, setProcessing] = useState(false);

  const isCurrentUserLuxPlusAdmin = hasLuxPlusAdminAccess(user);
  if (!isCurrentUserLuxPlusAdmin) return null;
  if (canViewLuxPlusBenefits) return null;

  if (isProcessing) {
    return (
      <RoyalBlueButton variant="contained">
        Processing...
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size="24px" sx={{ marginLeft: '24px', marginRight: '16px' }} />
        </Box>
      </RoyalBlueButton>
    );
  }

  const createLuxPlusSubscription = async ({
    region,
    subscriptionOfferId,
    price,
  }: {
    region: App.MembershipRegion;
    subscriptionOfferId: string;
    price: number;
  }) => {
    try {
      const currency = libRegions.getRegionByCode(customer.country_code)?.currencyCode;

      validateMembershipBrand(brand);

      return await createCustomerSubscriptionsForLuxPlusAdmins({
        customerId: customer.id_member,
        region,
        currency,
        price,
        subscriptionOfferId,
        brand,
      });
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
      });

      return;
    }
  };

  const handleUpgradeToLuxPlus = async () => {
    setProcessing(true);

    const region = customer.country_code;
    if (!region) {
      enqueueSnackbar(`Customer home region not set, please set home region before upgrading to LuxPlus+`, {
        variant: 'error',
      });
      setProcessing(false);
      return;
    }

    let subscriptionOffers;
    try {
      validateMembershipRegion(region);
      validateMembershipBrand(brand);
      subscriptionOffers = await getSubscriptionOffers({
        brand,
        region,
      });
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
      setProcessing(false);
      return;
    }

    const offer = subscriptionOffers.find((offer) => offer.joiningFee === 0);

    let createdSubscription;
    try {
      createdSubscription = await createLuxPlusSubscription({
        region,
        subscriptionOfferId: offer.id,
        price: offer.price,
      });
    } catch (err) {
      enqueueSnackbar(`Failed to create LuxPlus+ subscription`, {
        variant: 'error',
      });
      setProcessing(false);
    }

    if (createdSubscription) {
      enqueueSnackbar(`Success. This customer is now a LuxPlus+ Member!`, {
        variant: 'success',
      });
      refreshData();
    }
  };

  return (
    <RoyalBlueButton variant="contained" onClick={handleUpgradeToLuxPlus}>
      Free Upgrade to {LUX_PLUS.PROGRAM_NAME}
    </RoyalBlueButton>
  );
}
