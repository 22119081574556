import React from 'react';

import AppSpinner from '~/components/Common/Spinner';

type SpinnerProps = {
  size?: number;
  duration?: number;
  borderSize?: number;
  thirdColor?: string;
  singleColor?: string;
  fourthColor?: string;
  primaryColor?: string;
  secondaryColor?: string;
  inline?: boolean;
};

export const Spinner: React.FC<SpinnerProps> = (props) => {
  return <AppSpinner {...props} size={props.size || 35} />;
};
