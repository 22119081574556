import React, { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import { Input } from '~/components/Common/Forms/fields';

import { Guest } from './StoplistPage';

const DEFAULT_FORM_VALUES = {
  email: '',
  first_name: '',
  last_name: '',
  dob: null,
  passport_number: '',
  contact_number: '',
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Guest) => void;
}

const StoplistFormModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    mode: 'onBlur',
  });

  useEffect(() => {
    reset();
  }, [formState.isSubmitSuccessful, reset]);

  return (
    <Dialog maxWidth="md" onClose={onClose} open={isOpen} scroll="paper">
      <DialogTitle>Add guest</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={1} columnSpacing={5}>
            <Grid item md={5}>
              <Input fullWidth control={control} label="First Name" name="first_name" />
            </Grid>
            <Grid item md={5}>
              <Input fullWidth control={control} label="Last Name" name="last_name" />
            </Grid>
            <Grid item md={5}>
              <Input fullWidth control={control} label="Passport number" name="passport_number" />
            </Grid>
            <Grid item md={5}>
              <Input fullWidth control={control} label="Contact number" name="contact_number" />
            </Grid>
            <Grid item md={5}>
              <Input fullWidth control={control} label="Email" name="email" />
            </Grid>
            <Grid item md={5}>
              <FormControl variant="standard">
                <label htmlFor="input-dob">Date of birth</label>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field: { onChange, ...restField } }) => (
                    <DatePicker disableFuture onChange={onChange} {...restField} />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button variant="contained" type="submit" sx={{ marginTop: '15px' }}>
            Add
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export { StoplistFormModal };
