import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';

import Grid from '@mui/material/Unstable_Grid2';

import { reportError } from '../../../utils/reportError';

import ImagePanel from './ImagePanel';
import ImageUploadField from './ImageUploadField';

export interface RoomTypeImage {
  id: string;
  id_cloudinary_external: string;
  title?: string;
  order: number;
  hidden?: boolean;
}

interface Props {
  images: RoomTypeImage[];
  onAddImage: (cloudinaryId: string, filename: string) => Promise<RoomTypeImage>;
  onDeleteImage?: (imageId: string | number) => void;
  onHideImage?: (imageId: string | number) => () => void;
  onUpdateImages: (images: RoomTypeImage[]) => Promise<void>;
  hasHiddenToggle?: boolean;
  hideDeleteButton?: boolean;
}

const ImagesForm = forwardRef((props: Props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const [images, setImages] = useState<RoomTypeImage[]>([]);
  useEffect(() => {
    setImages(
      props.images.map((image) => {
        image.title = image.title || undefined;
        return image;
      }),
    );
  }, [props.images]);

  useImperativeHandle(ref, () => ({
    addImageToList(newImages) {
      const combinedImages = images.concat(newImages);

      setImages(combinedImages);
      props.onUpdateImages(combinedImages);
    },
  }));

  const debouncedOnUpdateImages = useCallback(
    debounce((images) => {
      setImages(images);
      props.onUpdateImages(images);
    }, 1000),
    [props.onUpdateImages],
  );

  const handleChangeTitle = (imageId, newTitle) => {
    const newImages = images.map((image) => {
      if (image.id === imageId) {
        image.title = newTitle;
      }
      return image;
    });
    debouncedOnUpdateImages(newImages);
  };

  const addImage = (_fieldKey, cloudinaryId, filename) => {
    props
      .onAddImage(cloudinaryId, filename)
      .then((response: RoomTypeImage) => {
        const appendedImages = [...images, response];
        setImages(appendedImages);
        enqueueSnackbar(`Successfully uploaded image`, { variant: 'success' });
        return appendedImages;
      })
      .then((newImages) => {
        props.onUpdateImages(newImages);
      })
      .catch((e) => {
        enqueueSnackbar('Error uploading image: ' + e.message, {
          variant: 'error',
        });
        reportError(e);
      });
  };

  const handleDeleteImage = async (imageId) => {
    if (!props.onDeleteImage) {
      return;
    }
    await props.onDeleteImage(imageId);
    let newImages;
    setImages((images) => {
      newImages = images.filter((image) => image.id !== imageId);
      for (let i = 0; i < newImages.length; i++) {
        newImages[i].order = i + 1;
      }
      return newImages;
    });
    await props.onUpdateImages(newImages);
  };

  const handleHiddenImage = (imageId) => {
    return async () => {
      // toggle selected image
      const newImages = images.map((image) => {
        if (image.id === imageId) {
          image.hidden = !image.hidden;
        }
        return image;
      });

      // reorder images if changing to hidden
      const hiddenImages = newImages.filter((image) => image.hidden);
      const visibleImages = newImages.filter((image) => !image.hidden);
      for (let i = 0; i < newImages.length; i++) {
        if (visibleImages.includes(newImages[i])) {
          newImages[i].order = visibleImages.indexOf(newImages[i]) + 1;
        } else {
          newImages[i].order = visibleImages.length + hiddenImages.indexOf(newImages[i]) + 1;
        }
      }

      newImages.sort((a, b) => a.order - b.order);

      setImages(newImages);
      await props.onUpdateImages(newImages);
    };
  };

  const handleDragSort = ({ active, over }) => {
    if (active.id !== over.id) {
      setImages((images) => {
        const oldIndex = images.map((e) => e.id).indexOf(active.id);
        const newIndex = images.map((e) => e.id).indexOf(over.id);
        const newImageList = arrayMove(images, oldIndex, newIndex);
        let order = 1;
        for (const i in newImageList) {
          newImageList[i].order = order++;
        }
        return newImageList;
      });
      props.onUpdateImages(images);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <Grid container className="images-form">
      <Grid xs={12}>
        <Grid container spacing={2}>
          <DndContext onDragEnd={handleDragSort} sensors={sensors}>
            <SortableContext items={images}>
              {images.map((value) => (
                <ImagePanel
                  {...value}
                  publicImageId={value.id_cloudinary_external}
                  key={value.id}
                  onChangeTitle={handleChangeTitle}
                  onDeleteImage={handleDeleteImage}
                  onHideImage={handleHiddenImage}
                  hasHiddenToggle={props.hasHiddenToggle}
                  hideDeleteButton={props.hideDeleteButton}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Grid>
      </Grid>

      <Grid xs={12}>
        <ImageUploadField label="image" field_key="newimage" onUpload={addImage} multiple={true} />
      </Grid>
    </Grid>
  );
});

ImagesForm.displayName = 'ImagesForm';

export default ImagesForm;
