import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { Agency, MappedAgent } from '~/types/services/agentHub';

import EoiStatusChip from '../../EoiStatusChip';

const DEFAULT_SIZE_PER_PAGE = 10;

const columns: Array<GridColDef> = [
  { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
  { field: 'email', headerName: 'Email', flex: 1, sortable: false, display: 'flex' },
  {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    sortable: false,
    display: 'flex',
    renderCell: (params) => <EoiStatusChip status={params.row.status} />,
  },
  {
    field: '',
    headerName: 'Links',
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          {params.row.customerId && (
            <Link target="_blank" rel="noreferrer" to={`/users/${params.row.customerId}`}>
              User
            </Link>
          )}
          {params.row.eoiSubmissionId && (
            <Box ml={2}>
              <Link target="_blank" rel="noreferrer" to={`/users-list/agency-submission/${params.row.eoiSubmissionId}`}>
                EOI
              </Link>
            </Box>
          )}
        </>
      );
    },
    display: 'flex',
  },
];

interface AgencyDetailsProps {
  data: Agency;
  users: Array<MappedAgent>;
  page: number;
  setPage: (page: number) => void;
  total: number;
  loadingUserState: Utils.FetchingState;
}

export default function AgencyDetails({ data, users, total, loadingUserState }: AgencyDetailsProps) {
  const [page, setPage] = useState(0);

  return (
    <>
      <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr" sx={{ minWidth: '500px' }}>
        <Stack>
          <Typography>Name:</Typography>
          <Typography fontWeight="bold">{data.name}</Typography>
        </Stack>
        <Stack>
          <Typography>Identity:</Typography>
          <Typography fontWeight="bold">
            {data.businessCodeType} - {data.businessCodeValue}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ gridColumn: 'span 2' }}>Affiliation:</Typography>
          <Typography sx={{ gridColumn: 'span 2' }} fontWeight="bold">
            {data.affiliation}
          </Typography>
        </Stack>

        <Typography mt={2} variant="h6" sx={{ gridColumn: 'span 2' }}>
          Address
        </Typography>
        <Stack>
          <Typography sx={{ gridColumn: 'span 2' }}>Street:</Typography>
          <Typography fontWeight="bold">{data.address}</Typography>
        </Stack>
        <Stack>
          <Typography>City:</Typography>
          <Typography fontWeight="bold">{data.city}</Typography>
        </Stack>
        <Stack>
          <Typography>Postal Code:</Typography>
          <Typography fontWeight="bold">{data.postcode}</Typography>
        </Stack>
        <Stack>
          <Typography>State:</Typography>
          <Typography fontWeight="bold">{data.state}</Typography>
        </Stack>
        <Stack>
          <Typography>Country:</Typography>
          <Typography fontWeight="bold">{data.country}</Typography>
        </Stack>
      </Box>

      <Typography mt={2} variant="h6">
        Agents
      </Typography>

      <DataGrid
        rows={users}
        rowCount={total}
        columns={columns}
        loading={loadingUserState == 'loading'}
        pagination
        paginationMode="server"
        paginationModel={{ page: page, pageSize: DEFAULT_SIZE_PER_PAGE }}
        pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
        onPaginationModelChange={({ page }) => setPage(page)}
        slots={{ pagination: GridPagination }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </>
  );
}
