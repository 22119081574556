import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import PublicOfferService from '~/services/PublicOfferService';

import getDateDayDiff from '~/utils/getDateDayDiff';

import BaseOfferBox from './BaseOfferBox';

interface Props {
  bk: string;
  region: string;
  index: number;
  onRemove: () => void;
}

function BedbankOfferBox({ bk, region, index, onRemove }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: App.State) => state.tenant);
  const [offer, setOffer] = useState<Awaited<ReturnType<typeof PublicOfferService.getBedbankOfferById>>['result']>();

  useEffect(() => {
    PublicOfferService.getBedbankOfferById(bk, region, tenant.brand)
      .then((res) => {
        setOffer(res.result);
      })
      .catch((err) => {
        if (err.status === 404) {
          onRemove && onRemove();
          return;
        }
        enqueueSnackbar(`Bedbank ${bk} could not be found`, { variant: 'error' });
      });
  }, [bk, region, tenant.brand]);

  if (!offer) {
    return <BaseOfferBox tenant={tenant} index={index} />;
  }

  return (
    <BaseOfferBox
      tenant={tenant}
      title={offer.name}
      imageId={offer.images[0]?.id}
      subtitle={[offer.property.address.city, offer.property.address.countryName].filter(Boolean).join(', ')}
      path={`/partner/${offer.slug}/${bk}`}
      vertical="hotel"
      index={index}
      remainingDays={getDateDayDiff(offer?.promotions[0]?.periodSchedule?.to)}
    />
  );
}

export default BedbankOfferBox;
