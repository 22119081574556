import React, { useCallback, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import Spinner from '~/components/Common/Spinner';
import { withTenant } from '~/components/hoc';

const REQUIRED_FIELDS = ['name', 'image', 'tag_icon', 'tag_text', 'tag_tooltip'];

export interface Badge {
  id: number;
  name: string;
  url?: string;
  image: string | null;
  tag_icon: string;
  tag_text: string;
  tag_tooltip: string;
  is_scheduled?: boolean;
  start?: string;
  end?: string;
  brands?: Array<App.Brands>;
}

interface BadgesFormProps extends Badge {
  onBadgeUpdate: (badge: Badge) => void;
  onBadgeSubmit: (badge: Badge) => void;
  onBadgeRemove: (badge: Badge) => Promise<Badge>;
  checkUniqueName: (string) => boolean;
  tag_icons: Array<{ key: string; value: string }>;
  error: unknown;
  success: boolean;
  isLoading?: boolean;
}

const BadgesForm = ({
  id,
  name,
  url,
  image,
  tag_icon,
  tag_text,
  tag_tooltip,
  tag_icons,
  error,
  success,
  isLoading,
  onBadgeUpdate,
  onBadgeSubmit,
  onBadgeRemove,
  checkUniqueName,
}: BadgesFormProps) => {
  const [badge, setBadge] = useState<Badge | null>();
  const [validationError, setValidationError] = useState<{ message: string }>(error as { message: string });
  const [modalState, setModalState] = useState(false);
  const [badgeRemoved, setBadgeRemoved] = useState(false);

  useEffect(() => {
    setBadge({ id, name, url, image, tag_icon, tag_text, tag_tooltip });
  }, [id, name, url, image, tag_icon, tag_text, tag_tooltip]);

  useEffect(() => {
    setValidationError(error as { message: string });
  }, [error]);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleBadgeUpdate(event.target.name, event.target.value);
  };

  const handleBadgeUpdate = (field: string, value: string) => {
    const updatedBadge = Object.assign(
      {},
      {
        ...badge,
        [field]: value,
      },
    );
    setBadge(updatedBadge);
  };

  const handleImageUpload = (image_url: string) => {
    handleBadgeUpdate('image', image_url);
  };

  const isFormValid = (badge: Badge) => {
    const missingFields = [];

    REQUIRED_FIELDS.forEach((key) => {
      if (!badge[key]) {
        missingFields.push(key);
      }
    });

    if (missingFields.length) {
      const message = `${missingFields.join(', ')} ${missingFields.length > 1 ? 'are' : 'is'} required field${
        missingFields.length > 1 ? 's' : ''
      }`;
      setValidationError({ message });
      return false;
    }

    return true;
  };

  const handleBadgeRemove = () => {
    onBadgeRemove(badge).then(() => {
      setBadge(null);
      setBadgeRemoved(true);
    });
  };

  const handleBadgeSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (isFormValid(badge)) {
      badgeSaveCallback();
    }
  };

  const badgeSaveCallback = useCallback(() => {
    return id ? onBadgeUpdate(badge) : onBadgeSubmit(badge);
  }, [id, onBadgeUpdate, badge, onBadgeSubmit]);

  const toggleModal = useCallback(() => setModalState(!modalState), [modalState]);

  const onCheckUnique = useCallback(() => {
    const uniqueName = checkUniqueName(badge?.name);
    if (!uniqueName) {
      setValidationError({ message: 'Badge name should be unique' });
      return;
    }
    setValidationError(null);
  }, [badge?.name, checkUniqueName]);

  return (
    <>
      {!badgeRemoved && (
        <Box>
          <Grid container spacing={3}>
            <Grid xs={12} md={6}>
              <TextField
                value={badge?.name ?? ''}
                onChange={handleTextFieldChange}
                onBlur={onCheckUnique}
                name="name"
                label="Badge name"
                placeholder="Enter name of badge"
                variant="outlined"
                required
                fullWidth
              />
            </Grid>

            <Grid xs={12} md={6}>
              <TextField
                value={badge?.url ?? ''}
                onChange={handleTextFieldChange}
                placeholder="Enter url for tag link"
                label="URL"
                name="url"
                fullWidth
              />
            </Grid>

            <Grid xs={12} md={2}>
              <TextField
                value={badge?.tag_icon ?? ''}
                onChange={handleTextFieldChange}
                label="Tag icon"
                name="tag_icon"
                select
                fullWidth
              >
                {tag_icons.map((tag_icon) => (
                  <MenuItem key={tag_icon.key} value={tag_icon.value}>
                    {tag_icon.key}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid xs={12} md={4}>
              <TextField
                value={badge?.tag_text ?? ''}
                inputProps={{ maxLength: 30 }}
                placeholder="Enter tag text e.g. Vax Voucher Accepted"
                label="Badge text"
                name="tag_text"
                onChange={handleTextFieldChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{badge?.tag_text?.length || 0}/30</InputAdornment>,
                }}
                required
                fullWidth
              />
            </Grid>

            <Grid xs={12} md={6}>
              <TextField
                value={badge?.tag_tooltip ?? ''}
                onChange={handleTextFieldChange}
                label="Badge tagline tooltip"
                name="tag_tooltip"
                placeholder="Add small snippet of info here to provide additional context"
                inputProps={{ maxLength: 70 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{badge?.tag_tooltip?.length || 0}/70</InputAdornment>,
                }}
                required
                fullWidth
              />
            </Grid>

            <Grid xs={12} md={6}>
              <ImageUploadField
                label="badge"
                field_key="image"
                value={badge?.image}
                requiredField
                note={'Note: Badge should be 130x130px, and as a .PNG format.'}
                onUpload={(_fieldKey: string, newValue: string) => {
                  handleImageUpload(newValue);
                }}
              />
            </Grid>
          </Grid>

          <Box mt={2}>
            <Divider />

            {validationError && <ErrorDisplay message={validationError.message} />}
            {success && <Alert severity="success">Success</Alert>}

            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mt={2}>
              <Button type="submit" onClick={handleBadgeSubmit} variant="contained">
                Save
              </Button>

              {!!id && (
                <Button type="button" onClick={toggleModal} variant="contained" color="error">
                  Remove Badge
                </Button>
              )}
            </Stack>
          </Box>

          <Dialog open={modalState} onClose={toggleModal}>
            <DialogTitle>Remove Badge</DialogTitle>

            <DialogContent>
              <DialogContentText>Are you sure you want to remove this badge?</DialogContentText>
              <DialogContentText>This action cannot be undone.</DialogContentText>
              <DialogContentText>
                Deleting this badge will remove it from all offers it is currently shown in.
              </DialogContentText>

              {isLoading && <Spinner />}

              {validationError && <ErrorDisplay message={validationError.message} />}
              {success && <Alert severity="success">Success</Alert>}
            </DialogContent>

            <DialogActions>
              <Button type="button" onClick={toggleModal} variant="text">
                Close
              </Button>
              {badge?.id && (
                <Button type="button" onClick={handleBadgeRemove} variant="contained">
                  Remove Badge
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      )}

      {badgeRemoved && (
        <Box>
          {validationError && <ErrorDisplay message={validationError.message} />}
          {success && <Alert severity="success">Success</Alert>}
        </Box>
      )}
    </>
  );
};

export default withTenant(BadgesForm);
