import React from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ConfirmDeleteDialog = ({ isOpen, handleClose, handleConfirm }: Props) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Are you sure you wish to delete this remittance?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
