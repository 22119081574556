import React from 'react';

import { CustomOfferFormValues } from '@/typings/formValues';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DateWidget from '~/components/Common/Elements/DateWidget';

import { FLEXIBLE_CANCELLATION_POLICY, NONREFUNDABLE_CANCELLATION_POLICY } from '../constants';

interface Props {
  control: Control<any>;
  isSubmitting: boolean;
  setValue: UseFormSetValue<CustomOfferFormValues>;
  watch: UseFormWatch<CustomOfferFormValues>;
}

export function CancellationPolicies({ control, isSubmitting, setValue, watch }: Props) {
  const cancellationType = watch('payment_metadata.cancellation_type');
  return (
    <>
      <Grid xs={4} pl={0}>
        <Grid container columns={6} spacing={2}>
          <Grid xs={6} md={2}>
            <Controller
              name="payment_metadata.cancellation_type"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cancellation Policy"
                  placeholder="Cancellation Policy"
                  disabled={isSubmitting}
                  select
                  fullWidth
                >
                  <MenuItem value={NONREFUNDABLE_CANCELLATION_POLICY}>Non-Refundable</MenuItem>
                  <MenuItem value={FLEXIBLE_CANCELLATION_POLICY}>Flexible Cancellation</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          {cancellationType === FLEXIBLE_CANCELLATION_POLICY && (
            <Grid xs={6} md={1}>
              <Controller
                control={control}
                name="payment_metadata.cancellation_end_date"
                render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                  <DateWidget
                    {...field}
                    slotProps={{ textField: { helperText: error?.message, error: !!error } }}
                    disabled={isSubmitting}
                    label="Cancellation End Date"
                    fullWidth
                    disablePast
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
