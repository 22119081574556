import React from 'react';

import { Button, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { deleteFrequentFlyer } from '~/services/BusinessTraveller/BusinessTravellerService';

import { FrequentFlyerItemType } from '../pages/BusinessDetailsPage/Tabs/FrequentFlyerTab';

interface Props {
  businessId: string;
  item: FrequentFlyerItemType;
  index: number;
  frequentFlyerItems: FrequentFlyerItemType[];
  setFrequentFlyerItems: (value: React.SetStateAction<FrequentFlyerItemType[]>) => void;
  setHasSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CorporateAirAgreementItem(props: Props) {
  const { businessId, item, index, frequentFlyerItems, setFrequentFlyerItems, setHasSaved } = props;

  function deleteRow(indexToRemove: number, businessId: string) {
    const frequentFlyerCopy = [...frequentFlyerItems];

    if (frequentFlyerCopy[indexToRemove].id) {
      deleteFrequentFlyer(frequentFlyerCopy[indexToRemove].id, businessId);
    }

    frequentFlyerCopy.splice(indexToRemove, 1); // Remove the selected row
    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  function onSelectVerified(e: SelectChangeEvent<boolean>, index: number) {
    const { name, value } = e.target;

    const frequentFlyerCopy = [...frequentFlyerItems];
    frequentFlyerCopy[index][name] = value === 'true';

    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) {
    const { name, value } = e.target;

    const frequentFlyerCopy = [...frequentFlyerItems];
    frequentFlyerCopy[index][name] = value;

    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  return (
    <Grid container gap={2} key={item.id} spacing={2}>
      <Grid alignItems="center" container flexDirection="row" xs={12}>
        <Grid>
          <Typography fontSize={32} fontWeight="bold">
            Corporate Air Agreement: {item.airlineName ? item.airlineName : 'New'}
          </Typography>
        </Grid>
        <Grid>
          <Button color="error" variant="outlined" onClick={() => deleteRow(index, businessId)}>
            Delete
          </Button>
        </Grid>
      </Grid>
      <Grid alignItems="center" container flexDirection="row" xs={12}>
        <Grid xs={1}>
          <Typography>Airline:</Typography>
        </Grid>
        <Grid xs={5}>
          <FormControl fullWidth>
            <TextField
              name="airlineName"
              value={item.airlineName}
              onChange={(event) => handleInputChange(event, index)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid alignItems="center" container flexDirection="row" xs={12}>
        <Grid xs={1}>
          <Typography>Account code:</Typography>
        </Grid>
        <Grid xs={5}>
          <FormControl fullWidth>
            <TextField name="code" value={item.code} onChange={(event) => handleInputChange(event, index)} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid alignItems="center" container flexDirection="row" xs={12}>
        <Grid xs={1}>
          <Typography>Membership Status:</Typography>
        </Grid>
        <Grid xs={5}>
          <FormControl fullWidth>
            <Select
              label="verified"
              name="verified"
              notched={false}
              onChange={(event) => onSelectVerified(event, index)}
              value={item.verified}
            >
              <MenuItem disabled></MenuItem>
              <MenuItem value="true">Verified membership</MenuItem>
              <MenuItem value="false">No membership</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
