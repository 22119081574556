import React from 'react';

import { Link } from 'react-router-dom';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  userId: string;
};

export default function PaymentTimedOutModal({ userId }: Props) {
  return (
    <Dialog open={true}>
      <DialogTitle>Session Timed Out!</DialogTitle>

      <DialogContent>
        <DialogContentText>Order is open for more than 10 minutes. Please start over again</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Link to={`/users/${userId}/new-order/offer-selection`}>Back to beginning</Link>
      </DialogActions>
    </Dialog>
  );
}
