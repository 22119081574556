import React from 'react';

import { Button } from '@mui/material';

export const DeleteButton = ({ buttonText, onClick, size = 'medium' }) => {
  const deleteStates = {
    delete: 'Delete',
    deleting: 'Deleting...',
    failed: 'Failed to delete',
  };
  return (
    <>
      <Button onClick={onClick} variant="text" size={size}>
        {deleteStates[buttonText]}
      </Button>
    </>
  );
};
