import cloneDeep from 'lodash/cloneDeep';

import { HOTEL_OFFER_TYPES, TOUR_OFFER_TYPES } from '../consts/offerTypes';

const getCheckInOrZero = (item) => (item?.reservation?.check_in ? new Date(item.reservation.check_in).getTime() : 0);
const getFirstCheckIn = (items) =>
  items.reduce((acc, item) => {
    const checkIn = getCheckInOrZero(item);
    if (acc === null) {
      return checkIn;
    }
    if (checkIn < acc) {
      return checkIn;
    }
  }, null);

const compareByItemFirstCheckIn = (i1, i2) => getCheckInOrZero(i1) - getCheckInOrZero(i2);

const compareByOfferFirstCheckin = (o1, o2) => getFirstCheckIn(o1.items) - getFirstCheckIn(o2.items);

export const getOffersFromAccommodationItems = (items) =>
  cloneDeep(items)
    .sort(compareByItemFirstCheckIn)
    .reduce((offers, item) => {
      const withoutOffer = ({ offer, ...rest }) => rest;
      if ([...HOTEL_OFFER_TYPES, ...TOUR_OFFER_TYPES].some((t) => item.offer.type === t)) {
        const offer = offers.find(
          (o) => item.offer && item.offer.id_salesforce_external === o.offer.id_salesforce_external,
        );
        if (offer) {
          offer.items.push(withoutOffer(item));
        } else {
          offers.push({
            offer: item.offer,
            items: [withoutOffer(item)],
          });
        }
      }
      return offers;
    }, [])
    .sort(compareByOfferFirstCheckin);
