import React, { useCallback } from 'react';

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Divider, Grid, Typography } from '@mui/material';

import { OrderOffer } from '~/services/SearchService';

import SortableOfferBox from './SortableOfferBox';

export type OrderListItemWithSortId = OrderOffer & { id: string };

interface Props {
  offers: Array<OrderListItemWithSortId>;
  onChange: (offers: Array<OrderListItemWithSortId>) => void;
  region: string;
  onRemove?: (offer: OrderListItemWithSortId) => void;
  softLimit?: number;
}

function OfferListOrder({ offers, onChange, onRemove, region, softLimit }: Props) {
  const onDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        const oldIndex = offers.findIndex((offer) => offer.id === active.id);
        const newIndex = offers.findIndex((offer) => offer.id === over.id);

        onChange(arrayMove(offers, oldIndex, newIndex));
      }
    },
    [offers, onChange],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext onDragEnd={onDragEnd} sensors={sensors} collisionDetection={closestCenter}>
      <SortableContext items={offers.map((offer) => offer.id)} strategy={verticalListSortingStrategy}>
        <Grid container rowGap={1}>
          {offers.map((offer, i) => (
            <Grid item xs={12} key={offer.id}>
              {softLimit && i === softLimit && (
                <>
                  <Typography variant="h6" sx={{ margin: '24px' }}>
                    Offers beyond here will not appear on the carousels, move them above the line to have them included.
                  </Typography>
                  <Divider variant="middle" sx={{ margin: '24px' }} />
                </>
              )}
              <SortableOfferBox offer={offer} onRemove={() => onRemove(offer)} region={region} index={i} />
            </Grid>
          ))}
        </Grid>
      </SortableContext>
    </DndContext>
  );
}

export default OfferListOrder;
