import React from 'react';

import PropTypes from 'prop-types';
import { BrowserRouter as ReactRouter } from 'react-router-dom';

import context from './../context';
import utils from './../utils';
import HomeRoute from './HomeRoute';

export default class Router extends ReactRouter {
  static childContextTypes = {
    token: PropTypes.string,
    loading: PropTypes.bool,
    authenticated: PropTypes.bool,
    user: PropTypes.object,
  };

  static defaultProps = ReactRouter.defaultProps;

  state = {
    token: undefined,
    authenticated: false,
    loading: true,
    user: undefined,
  };

  markedRoutes = {
    home: {
      type: HomeRoute,
      authenticated: {
        props: null,
      },
      notAuthenticated: {
        props: null,
      },
    },
  };

  constructor() {
    super(...arguments);

    if (this.props.routes) {
      // The reason we wrap in a div is because we just need to have a root element.
      this._mapMarkedRoutes(<div>{this.props.routes}</div>);
    } else {
      this._mapMarkedRoutes(this);
    }

    this.sessionChangeListener = this._setSessionState.bind(this);

    context.setRouter(this);
  }

  _mapMarkedRoutes(routes) {
    let markedRoutes = this.markedRoutes;

    utils.deepForEach(routes, (node) => {
      // Try and map the route node to a marked route.
      for (var routeName in markedRoutes) {
        var route = markedRoutes[routeName];
        if (node.type === route.type) {
          var markedRoute = markedRoutes[routeName];

          if (node.type === HomeRoute) {
            markedRoute = markedRoute.notAuthenticated;
          }

          markedRoute.props = node.props;
          break;
        }
      }
    });
  }

  getHomeRoute() {
    return this.markedRoutes.home.notAuthenticated.props;
  }

  getAuthenticatedHomeRoute() {
    return this.markedRoutes.home.authenticated.props;
  }

  getLoginRoute() {
    return this.markedRoutes.login.props;
  }

  _setSessionState({ account, loading }) {
    this.setState({
      loading: loading,
      authenticated: account && true,
      user: account,
    });
  }

  componentDidMount() {
    this._setSessionState(context.sessionStore.get());
    context.sessionStore.addListener('changed', this.sessionChangeListener);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    context.sessionStore.removeListener('changed', this.sessionChangeListener);
  }

  getChildContext() {
    return {
      token: localStorage.getItem('token'),
      authenticated: !!localStorage.getItem('token'),
      loading: this.state.loading,
      user: this.state.user,
    };
  }
}
