import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Button, Container, Grid } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PartnerCentralProperties from '~/components/Vendors/PartnerCentral/PartnerCentralProperties';

import ReservationService from '~/services/ReservationService';

import PartnerCentralInviteModal from './PartnerCentralInviteModal/PartnerCentralInviteModal';

const TITLE = 'Partner Central';
const PAGE_SIZE = 10;

function VendorsPartnerCentralPage() {
  const [showInviteNewUserModal, setShowInviteNewUserModal] = useState(false);
  const [properties, setProperties] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { id_vendor: vendorId } = useParams<{ id_vendor: string }>();

  const openInviteNewUser = () => {
    setShowInviteNewUserModal(true);
  };

  const closeInviteNewUser = () => {
    setShowInviteNewUserModal(false);
  };

  const onRowClick = (selection: any) => {
    history.push(`/vendors/${selection.row.id_salesforce_external}/edit-property/${selection.row.id}`);
  };

  const fetchProperties = async (page: number) => {
    setIsLoading(true);
    const results = await ReservationService.getPropertiesForPartnerCentral(vendorId, PAGE_SIZE, page + 1);

    setProperties(results.result);
    setTotal(results.total);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProperties(page);
  }, [page]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <PageHeader title={TITLE} />
      <Grid container justifyContent="flex-end">
        <Button type="submit" variant="contained" onClick={openInviteNewUser}>
          Invite new user
        </Button>
      </Grid>
      <PartnerCentralInviteModal isOpen={showInviteNewUserModal} onClose={closeInviteNewUser} vendorId={vendorId} />
      <Box sx={{ m: 4 }}>
        <PartnerCentralProperties
          properties={properties}
          page={page}
          setPage={setPage}
          onRowClick={onRowClick}
          total={total}
          loading={isLoading}
        />
      </Box>
    </Container>
  );
}

export default VendorsPartnerCentralPage;
