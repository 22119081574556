import { request } from '../services/common';

const baseURL = window.configs.API_HOST;

export function preloadRoomType(offerPackage) {
  if (offerPackage && offerPackage._links && offerPackage._links.room_type) {
    const endpoint = `${baseURL}${offerPackage._links.room_type.href}`;

    return request(endpoint).then((response) => {
      offerPackage.room_type = response.error ? {} : response.result;
      offerPackage.room_rates = response.error ? [] : response.result.room_rates;
      offerPackage.room_rate = response.error
        ? {}
        : response.result.room_rates.find((rate) => rate.id === offerPackage.fk_room_rate_id);
      return offerPackage;
    });
  }

  return Promise.resolve(offerPackage);
}
