import React, { useCallback, useMemo, useRef, useState } from 'react';

import { subDays } from 'date-fns';
import dayjs from 'dayjs';
import fileDownload from 'react-file-download';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { downloadBusinessBookingReport } from '~/services/ReportingService';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface Inputs {
  endDate: dayjs.Dayjs;
  exportAs: 'csv';
  reportType: 'bookings' | 'pendingCredits';
  startDate: dayjs.Dayjs;
}

type DateRangeValues = '30' | '91' | '365' | 'custom';

export default function ReportingExportDialog(props: Props) {
  const { isOpen, onClose } = props;
  const hiddenAnchorLinkRef = useRef<HTMLAnchorElement>(null);
  const [dateRange, setDateRange] = useState<DateRangeValues>('30');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  // URL path is of form: /lebusiness/:businessId/orders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const businessId = useMemo(() => location.pathname.split('/')[2], []);

  const now = useMemo(() => new Date(), []);
  const defaultValues = useMemo(
    () => ({
      endDate: dayjs(now),
      exportAs: 'csv' as const,
      reportType: 'bookings' as const,
      startDate: dayjs(subDays(now, 30)),
    }),
    [now],
  );

  const { control, register, handleSubmit, setValue, watch } = useForm<Inputs>({
    defaultValues,
  });

  const startAt = watch('startDate');
  const endAt = watch('endDate');

  const handleDateRangeChange = useCallback(
    (e: SelectChangeEvent<DateRangeValues>) => {
      const dateRangeValue = e.target.value as DateRangeValues;
      setDateRange(dateRangeValue);
      if (dateRangeValue !== 'custom') {
        setValue('startDate', dayjs(subDays(now, parseInt(dateRangeValue))));
        setValue('endDate', dayjs(now));
      }
    },
    [now, setValue],
  );

  const handleSubmission = useCallback(
    handleSubmit(() => {
      setLoading(true);
      setError('');
      const startDate = dayjs(startAt).format('YYYY-MM-DD');
      const endDate = dayjs(endAt).format('YYYY-MM-DD');
      const fileName = `booking_report_${startDate}_${endDate}.csv`;
      downloadBusinessBookingReport({ business_id: businessId, start_at: startDate, end_at: endDate })
        .then(function (text) {
          setLoading(false);
          fileDownload(text, fileName);
        })
        .catch(function (err) {
          setLoading(false);
          setError(err.message);
        });
    }),
    [startAt, endAt, businessId, handleSubmit],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmission}>
        <DialogTitle>Export your business travel reports</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>Select desired format and date range</DialogContentText>
          <Stack direction="column" gap={3}>
            <Stack direction="row" gap={4} alignItems="center">
              <Typography variant="body1">Export as</Typography>
              <RadioGroup row value="csv" {...register('exportAs', { required: true })}>
                <FormControlLabel control={<Radio />} label="CSV" value="csv" />
              </RadioGroup>
            </Stack>

            <Grid2 container spacing={3}>
              <Grid2 xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Select date range</InputLabel>
                  <Select value={dateRange} label="Select date range" onChange={handleDateRangeChange}>
                    <MenuItem value="30">Last 30 days</MenuItem>
                    <MenuItem value="91">Last 3 months</MenuItem>
                    <MenuItem value="365">Last 12 months</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              {dateRange === 'custom' && (
                <>
                  <Grid2 xs={6}>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{
                        required: 'Missing start date',
                        validate: {
                          beforeEndDate: (date, formValues: Inputs) =>
                            date < formValues.endDate || 'Must be before end date',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          label="Start date"
                          value={field.value}
                          onChange={field.onChange}
                          slotProps={{
                            textField: {
                              name: field.name,
                              ref: field.ref,
                              value: field.value,
                              error: fieldState.invalid,
                              helperText: fieldState.error?.message,
                              onBlur: field.onBlur,
                              required: true,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 xs={6}>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{
                        required: 'Missing end date',
                        validate: {
                          beforeEndDate: (date, formValues: Inputs) =>
                            date > formValues.startDate || 'Must be after start date',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <DatePicker
                          label="End date"
                          value={field.value}
                          onChange={field.onChange}
                          slotProps={{
                            textField: {
                              name: field.name,
                              ref: field.ref,
                              value: field.value,
                              error: fieldState.invalid,
                              helperText: fieldState.error?.message,
                              onBlur: field.onBlur,
                              required: true,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid2>
                </>
              )}
            </Grid2>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center" flexGrow="1">
            {loading && <Typography variant="caption">Generating report...</Typography>}

            {error && <ErrorDisplay message={error} />}

            <Box flexGrow="1" flexShrink="0" role="separator" />

            <Stack direction="row">
              <Button onClick={onClose} variant="text">
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
                Export
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogActions>
        <Box ref={hiddenAnchorLinkRef} display="none" component="a" tabIndex={-1} />
      </form>
    </Dialog>
  );
}
