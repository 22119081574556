import React from 'react';

import PropTypes from 'prop-types';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { OFFER_TYPE_HOTEL } from '~/consts/offerTypes';

import { updateOrderItem } from '~/services/OrdersService';
import { isAfter } from '~/services/TimeService';

import { isAdmin } from '~/utils/adminPermission';
import { reportError } from '~/utils/reportError';

interface Props {
  cartItem: App.CartItem;
  onAfterHandleConvertToBNBL: () => void;
  onCancel: () => void;
  orderId: string;
  offer: App.Offer;
  offerPackage: any;
}

interface State {
  isAdmin: boolean;
  isReady: boolean;
  error?: {
    errors: Record<string, unknown>;
    message: string;
  };
  caseNumber: string;
  isBNBLEnabled: boolean;
  isConfirming: boolean;
  isPastBookByDate: boolean;
  isProcessing: boolean;
}

class ConvertToBNBL extends React.Component<Props, State> {
  static contextTypes = {
    user: PropTypes.object,
  };

  constructor(props: Props, context) {
    super(props);

    this.state = {
      error: null,
      isAdmin: isAdmin(context.user),
      isConfirming: false,
      isProcessing: false,
      isReady: false,
      isBNBLEnabled: props.offer.type === OFFER_TYPE_HOTEL && props.offerPackage.allow_buy_now_book_later,
      isPastBookByDate: props.offer.type === OFFER_TYPE_HOTEL && !isAfter(props.offer.book_by_date),
      caseNumber: '',
    };
  }

  handleChange = () => {
    const { onAfterHandleConvertToBNBL, cartItem, orderId } = this.props;

    this.setState({ isProcessing: true }, () => {
      updateOrderItem(orderId, cartItem.id, {
        op: 'remove_dates',
        cn: this.state.caseNumber,
      })
        .then(onAfterHandleConvertToBNBL)
        .then(() => {
          this.setState({ isProcessing: false });
        })
        .catch((e) => {
          reportError(e);
          this.setState({ error: e });
        });
    });
  };

  promptConfirmation = () => {
    if (this.state.isPastBookByDate || !this.state.isBNBLEnabled) {
      return this.setState({ isConfirming: true });
    }
    this.handleChange();
  };

  cancelConfirmation = () => {
    this.setState({ isConfirming: false });
  };

  handleCaseNumberChange = (event) => {
    event.preventDefault();
    const value = event.target.value;

    this.setState({
      caseNumber: value,
    });
  };

  render() {
    const { onCancel } = this.props;
    const { isProcessing, isConfirming, error, isPastBookByDate, isBNBLEnabled } = this.state;

    const isChangeDisabled = isProcessing || !this.state.isAdmin || (isPastBookByDate && !this.state.caseNumber);

    if (error) {
      return (
        <div className="alert alert-danger">
          <div>Error occurred: {error.message}</div>
          {error.errors && <div>Please report the following: {JSON.stringify(error.errors)}</div>}
        </div>
      );
    }

    return (
      <div className="booking-form">
        <div>
          <Typography fontSize={16}>
            Note that this will cancel the Booking dates and create a new BNBL package for the customer. Any surcharges
            paid will be added to the customer credits.
          </Typography>
        </div>
        <Stack mt={2} direction="row-reverse" spacing={2}>
          <Button
            className="T-Submit-Change-Date"
            variant="contained"
            disabled={isProcessing}
            onClick={this.promptConfirmation}
          >
            Confirm Change
          </Button>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        </Stack>

        <Dialog open={isConfirming} onClose={this.cancelConfirmation} className="T-change-date-confirm-modal">
          <DialogTitle>Confirm change</DialogTitle>
          <DialogContent>
            {!isBNBLEnabled && (
              <div>
                {!this.state.isAdmin && (
                  <>
                    <b>Buy now, choose dates later</b> is <b>turned off</b> on this package. Only team leaders can
                    convert after purchase.
                  </>
                )}
                {this.state.isAdmin && (
                  <>
                    <b>Buy now, choose dates later</b> is <b>turned off</b> on this package. Are you sure you want to
                    convert to BNBL?
                  </>
                )}
              </div>
            )}

            {isPastBookByDate && this.state.isAdmin && (
              <div>
                Please note you are amending a booking where the <b>book by date</b> has passed. Please ensure you have
                been provided authority to amend this booking.
                <Box mt={2}>
                  <div>
                    <label>Case Number:</label>
                    <input
                      type="text"
                      id="CaseNumber"
                      name="caseNumber"
                      maxLength={8}
                      value={this.state.caseNumber}
                      onChange={this.handleCaseNumberChange}
                    />
                  </div>
                </Box>
              </div>
            )}

            {isPastBookByDate && !this.state.isAdmin && (
              <div>
                Please note you are attempting to change a booking where the <b>book by date</b> has passed. Please
                contact your Team Leader to perform this action.
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.cancelConfirmation}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              className="T-Confirm-Change-Date"
              disabled={isChangeDisabled}
              onClick={this.handleChange}
            >
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConvertToBNBL;
