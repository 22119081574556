import React from 'react';

import { PortProvider } from '~/components/Cruises/context/usePorts';

import PortSearch from './components/PortSearch';
import PortsList from './components/PortsList';

export default function PortsPage() {
  return (
    <PortProvider>
      <PortSearch />
      <PortsList />
    </PortProvider>
  );
}
