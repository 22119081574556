import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import DateWidget from '~/components/Common/Elements/DateWidget';
import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import Spinner from '~/components/Common/Spinner';
import InputCruiseLineSelect from '~/components/Cruises/components/Selects/InputCruiseLineSelect';

import { brands } from '~/consts/brands';

import promoBannerService, { PromoBannerFormData } from '~/services/cruises/PromoBannerService';

const BannerImageUpload = styled.div`
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
`;

const PLACEHOLDER_BY_TYPE = {
  RATE_CODE: 'Rate code',
  LANDING_PAGE: 'Enter landing page name',
  SEARCH_FILTERS: 'Enter search filters',
  CRUISE_OFFER: 'Enter cruise offer ID',
  FLASH_OFFER: 'Enter flash offer ID',
};

interface Props {
  promoBanner?: API.PromoBanner;
}

const regions: API.Region[] = ['AU', 'NZ'];

const PromoBannerForm = ({ promoBanner }: Props) => {
  const [status, setStatus] = useState<string>();

  const [formData, setFormData] = useState<PromoBannerFormData>({
    description: promoBanner?.description || null,
    startDate: promoBanner?.startDate || null,
    endDate: promoBanner?.endDate || null,
    isFixed: promoBanner?.isFixed || false,
    redirectType: promoBanner?.redirectType || 'RATE_CODE',
    redirectData: promoBanner?.redirectData || null,
    imageId: promoBanner?.imageId || null,
    mobileImageId: promoBanner?.mobileImageId || null,
    availableInBrands: promoBanner?.availableInBrands || [],
    availableInRegions: promoBanner?.availableInRegions || [],
    vendorId: promoBanner?.vendorId || null,
    isVisibleOnVendorPage: promoBanner?.isVisibleOnVendorPage || false,
  });

  const onBrandChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name as API.Brand;
      const isSelected = formData.availableInBrands.includes(name);

      if (isSelected) {
        setFormData({
          ...formData,
          availableInBrands: formData.availableInBrands.filter((brandName) => brandName !== name),
        });
      } else {
        setFormData({
          ...formData,
          availableInBrands: [...formData.availableInBrands, name],
        });
      }
    },
    [formData, setFormData],
  );

  const onRegionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name as API.Region;
      const { availableInRegions } = formData;
      const isSelected = availableInRegions.includes(name);

      if (isSelected) {
        setFormData({
          ...formData,
          availableInRegions: availableInRegions.filter((brandName) => brandName !== name),
        });
      } else {
        setFormData({
          ...formData,
          availableInRegions: [...availableInRegions, name],
        });
      }
    },
    [formData, setFormData],
  );

  const setType = useCallback(
    (redirectType: string) => {
      setFormData({
        ...formData,
        redirectType,
        redirectData: '',
      });
    },
    [formData, setFormData],
  );

  const handleUploadBannerImage = useCallback(
    (_, cloudinaryId) => {
      setFormData({ ...formData, imageId: cloudinaryId });
    },
    [formData],
  );

  const handleUploadMobileBannerImage = useCallback(
    (_, cloudinaryId) => {
      setFormData({ ...formData, mobileImageId: cloudinaryId });
    },
    [formData],
  );

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleSave = useCallback(async () => {
    setStatus('loading');

    try {
      const data = {
        ...formData,
        startDate: formData.startDate,
        endDate: formData.endDate,
      };
      if (promoBanner) {
        await promoBannerService.updatePromoBanner(promoBanner.id, data);
      } else {
        await promoBannerService.createPromoBanner(data);
      }

      setStatus('success');
      enqueueSnackbar('Banner saved with success', { variant: 'success' });
      history.push(`/cruises/promo-banners/`);
    } catch (error) {
      setStatus('error');
      enqueueSnackbar('Error saving banner', { variant: 'error' });
    }
  }, [formData, promoBanner, enqueueSnackbar, history]);

  const saveButtonIsEnabled = useMemo(() => {
    return (
      formData.description &&
      formData.startDate &&
      formData.endDate &&
      formData.imageId &&
      formData.mobileImageId &&
      formData.redirectData
    );
  }, [formData]);

  const redirectType = useMemo(() => formData.redirectType || 'RATE_CODE', [formData]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Description *"
            value={formData.description}
            onChange={({ target }) => {
              setFormData({ ...formData, description: target.value });
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <DateWidget
            value={formData.startDate}
            onChange={(time) => {
              setFormData({ ...formData, startDate: time });
            }}
            label="Start date *"
          />
        </Grid>

        <Grid item xs={3}>
          <DateWidget
            value={formData.endDate}
            onChange={(time) => {
              setFormData({ ...formData, endDate: time });
            }}
            label="End date *"
          />
        </Grid>
      </Grid>

      <Stack mt={2} direction="column" spacing={2}>
        <FormControl>
          <FormLabel id="promo-banner-type-filter">Type filter</FormLabel>

          <RadioGroup row aria-labelledby="promo-banner-type-filter" name="row-radio-buttons-group">
            <FormControlLabel
              value="rateCode"
              control={<Radio checked={redirectType === 'RATE_CODE'} onChange={() => setType('RATE_CODE')} />}
              label="Rate code"
            />

            <FormControlLabel
              value="filters"
              control={<Radio checked={redirectType === 'SEARCH_FILTERS'} onChange={() => setType('SEARCH_FILTERS')} />}
              label="Custom filters"
            />

            <FormControlLabel
              value="filters"
              control={<Radio checked={redirectType === 'LANDING_PAGE'} onChange={() => setType('LANDING_PAGE')} />}
              label="Landing page"
            />

            <FormControlLabel
              value="filters"
              control={<Radio checked={redirectType === 'CRUISE_OFFER'} onChange={() => setType('CRUISE_OFFER')} />}
              label="Cruise offer page"
            />

            <FormControlLabel
              value="filters"
              control={<Radio checked={redirectType === 'FLASH_OFFER'} onChange={() => setType('FLASH_OFFER')} />}
              label="Flash offer page"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          fullWidth
          label={PLACEHOLDER_BY_TYPE[redirectType]}
          value={formData.redirectData}
          onChange={({ target }) => {
            setFormData({
              ...formData,
              redirectData: target.value,
            });
          }}
        />

        {redirectType === 'SEARCH_FILTERS' && (
          <Alert severity="info">
            <AlertTitle>On the search page, copy all URL content after the "?" and paste here, ex:</AlertTitle>
            <Typography fontSize={12}>
              /region/search/cruises?
              <Typography fontSize={12} variant="caption" fontWeight="bold">
                cruiseLines=AZA&rateCodes=DSC-K8459069
              </Typography>
            </Typography>
          </Alert>
        )}

        {redirectType === 'LANDING_PAGE' && (
          <Alert severity="info">
            <AlertTitle>Copy the landing page name at the end of the URL and paste here, ex:</AlertTitle>
            <Typography fontSize={12}>
              https://luxuryescapes.com/region/
              <Typography fontSize={12} variant="caption" fontWeight="bold">
                homes-and-villas.h1
              </Typography>
            </Typography>
          </Alert>
        )}

        {['CRUISE_OFFER', 'FLASH_OFFER'].includes(redirectType) && (
          <Alert severity="info">
            <AlertTitle>Copy the offer ID at the end of the URL and paste here, ex:</AlertTitle>
            <Typography fontSize={12}>
              https://luxuryescapes.com/region/cruises/
              <Typography fontSize={12} variant="caption" fontWeight="bold">
                ec7f9ff5-bfe8-4749-8967-d68fa0c42481
              </Typography>
            </Typography>
          </Alert>
        )}

        <InputCruiseLineSelect
          label="Cruise line"
          value={formData.vendorId}
          onChange={(cruiseLine) => {
            setFormData({ ...formData, vendorId: cruiseLine.id });
          }}
        />

        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label="It is only visible on cruise line landing pages"
            checked={formData.isVisibleOnVendorPage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, isVisibleOnVendorPage: event.target.checked });
            }}
          />
        </FormGroup>

        <Box>
          <Typography fontWeight="bold">Available in regions:</Typography>

          <FormGroup row>
            {regions.map((region) => (
              <FormControlLabel
                key={region}
                control={<Checkbox defaultChecked />}
                label={region}
                name={region}
                checked={formData?.availableInRegions?.includes(region as API.Region)}
                onChange={onRegionChange}
              />
            ))}
          </FormGroup>
        </Box>

        <Box>
          <Typography fontWeight="bold">Available in brands:</Typography>

          <FormGroup row>
            {brands.map((brand) => (
              <FormControlLabel
                key={brand.value}
                control={<Checkbox defaultChecked />}
                label={brand.title}
                name={brand.value}
                checked={formData?.availableInBrands?.includes(brand.value as API.Brand)}
                onChange={onBrandChange}
              />
            ))}
          </FormGroup>
        </Box>
      </Stack>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={6}>
          <BannerImageUpload>
            <ImageUploadField
              col={12}
              label="Banner image"
              field_key="cruisePromoBannerImage"
              value={formData.imageId}
              multiple={false}
              onUpload={handleUploadBannerImage}
            />
          </BannerImageUpload>
        </Grid>

        <Grid item xs={6}>
          <BannerImageUpload>
            <ImageUploadField
              col={12}
              label="Mobile banner image"
              field_key="cruisePromoBannerImage"
              value={formData.mobileImageId}
              multiple={false}
              onUpload={handleUploadMobileBannerImage}
            />
          </BannerImageUpload>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Button variant="contained" onClick={handleSave} disabled={!saveButtonIsEnabled || status === 'loading'}>
          {status === 'loading' && <Spinner size={15} />}
          Save banner
        </Button>
      </Box>

      {status === 'success' && <Alert severity="success">BANNER SAVED WITH SUCCESS</Alert>}

      {status === 'error' && <Alert severity="error">ERROR SAVING BANNER</Alert>}
    </Box>
  );
};

export default PromoBannerForm;
