import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ChevronLeft, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';

import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/muiSubmitButton';
import Spinner from '~/components/Common/Spinner';

import generateRandomString from '~/utils/generateRandomString';
import { reportError } from '~/utils/reportError';

import BundlePackagesService from '../../../services/BundlePackagesService';
import OffersService from '../../../services/OffersService';
import ErrorListDisplay from '../../Common/ErrorListDisplay';
import BundlePackageForm from '../../Common/Forms/BundlePackageForm';

const FlexRight = styled.div`
  display: flex;
  justify-content: right;
`;

const ExpandOptionHolder = styled.div`
  margin-bottom: 32px;
`;

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
`;

interface Props {
  match: {
    params: {
      id_offer: string;
    };
  };
}

let initialPackageSchema = [];

const BundlePackagesEditContainer = (props: Props) => {
  const { id_offer: offerId } = props.match.params;
  const [offerData, setOfferData] = useState(null);
  const [packageData, setPackageData] = useState([]);

  const [packagesExpansionStatuses, setPackagesExpansionStatuses] = useState([]);
  const [packagesSchemas, setPackagesSchemas] = useState([]);
  const [saveFailed, setSaveFailed] = useState(false);
  const [shouldSaveAll, setShouldSaveAll] = useState(false);
  const [allSaveCounter, setAllSaveCounter] = useState(-1);

  const fetchData = useCallback(
    async (offerId, firstRequest = true) => {
      return Promise.all([BundlePackagesService.getPackageSchema(offerId), OffersService.getOffer(offerId)]).then(
        (data) => {
          initialPackageSchema = data[0]?.patch?.body?.schema;
          const offer = data[1]?.result;
          setOfferData(offer);
          if (firstRequest) {
            const numberOfAccordionElements = offer.packages.length;
            const arrayWithNumberOfAccordionElements = Array(numberOfAccordionElements);

            const expansionElements = arrayWithNumberOfAccordionElements.fill(false);
            setPackagesExpansionStatuses(expansionElements);

            const initialSchemaElements = arrayWithNumberOfAccordionElements.fill({});
            setPackagesSchemas(initialSchemaElements);
          }
          setPackageData(offer.packages);
        },
      );
    },
    [offerId],
  );

  const history = useHistory();
  useEffect(() => {
    fetchData(offerId).catch(function (error) {
      reportError(error);
      history.goBack();
    });
  }, []);

  useEffect(() => {
    recalculateAndUpdatePackageSchemas();
  }, [packageData]);

  const recalculateAndUpdatePackageSchemas = () => {
    const arrayWithNumberOfPackageElements = Array(packageData.length);

    const packageSchemas = arrayWithNumberOfPackageElements.fill(undefined).map(() => {
      return JSON.parse(JSON.stringify(initialPackageSchema));
    });

    for (let i = 0; i < packageData.length; i++) {
      const items = packageSchemas[i]?.properties?.attached_package_options?.items;
      const newEnums = [];
      const newEnumNames = [];
      for (let y = 0; y < items?.enum.length; y++) {
        const opSiblingsNotUsedInPack = !packageData[i].attached_package_options.find(
          (attachedPackage) => attachedPackage.split('|')[0] === items.enum[y].split('|')[0],
        );
        const opUsedInPack = packageData[i].attached_package_options.includes(items.enum[y]);

        if (opSiblingsNotUsedInPack || opUsedInPack) {
          newEnums.push(items.enum[y]);
          newEnumNames.push(items.enumNames[y]);
        }
      }
      items.enum = newEnums;
      items.enumNames = newEnumNames;
      packageSchemas[i].properties.attached_package_options.items = items;
    }

    setPackagesSchemas(packageSchemas);
  };
  const onPackageSaveFailed = () => {
    setSaveFailed(true);
    if (allSaveCounter != -1) setAllSaveCounter((prevCounter) => prevCounter + 1);
  };
  const onPackageSaveComplete = (isPackageCreation) => {
    if (allSaveCounter != -1) setAllSaveCounter((prevCounter) => prevCounter + 1);
    if (isPackageCreation) {
      fetchData(offerId, false).catch((error) => {
        reportError(error);
      });
    }
  };

  const onPackageChange = (index, shouldRecalculateAttachedOptions, attachedPackageOptions) => {
    if (allSaveCounter === packageData.length) {
      setAllSaveCounter(-1);
    }
    if (shouldRecalculateAttachedOptions) {
      const updatedPackages = [...packageData];
      updatedPackages[index].attached_package_options = attachedPackageOptions;
      setPackageData(updatedPackages);
    }
  };

  useEffect(() => {
    if (allSaveCounter === 0) {
      setShouldSaveAll((prevState) => !prevState);
    }
  }, [allSaveCounter]);

  const onSaveFailedModalClose = () => setSaveFailed(false);

  const toggleAllPackages = () => {
    setPackagesExpansionStatuses((prevState) => {
      return prevState.map(() => !isAllExpanded);
    });
  };

  const togglePackage = (index) => {
    setPackagesExpansionStatuses((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const onAddPackage = () => {
    setPackagesExpansionStatuses([...packagesExpansionStatuses, true]);
    setPackagesSchemas([...packagesSchemas, initialPackageSchema]);
    setPackageData([
      ...packageData,
      {
        le_package_id: 'fake_id_' + generateRandomString(),
        regions: [],
        attached_package_options: [],
        offer_id_salesforce_external: offerData.id_salesforce_external,
      },
    ]);
  };

  const onDeletePackage = (index) => {
    const newPackageData = [...packageData];
    newPackageData.splice(index, 1);
    setPackageData(newPackageData);

    const newPackagesExpansionStatuses = [...packagesExpansionStatuses];
    newPackagesExpansionStatuses.splice(index, 1);
    setPackagesExpansionStatuses(newPackagesExpansionStatuses);

    const newPackagesSchemas = [...packagesSchemas];
    newPackagesSchemas.splice(index, 1);
    setPackagesSchemas(newPackagesSchemas);
  };

  if (!offerData) {
    return <Spinner />;
  }

  const isAllExpanded = packagesExpansionStatuses.some((isExpanded) => isExpanded);

  let allSaveButtonState = buttonStates.default;
  allSaveButtonState =
    allSaveCounter < packageData.length && allSaveCounter !== -1 ? buttonStates.saving : allSaveButtonState;
  allSaveButtonState = allSaveCounter === packageData.length ? buttonStates.saved : allSaveButtonState;

  let saveButtonMessage;
  switch (allSaveButtonState) {
    case buttonStates.default:
      saveButtonMessage = `${buttonMessages[buttonStates.default]} All`;
      break;
    case buttonStates.saving:
      saveButtonMessage = `${buttonMessages[buttonStates.saving]} ${allSaveCounter}/${packageData.length}`;
      break;
    case buttonStates.saved:
      saveButtonMessage = `All ${buttonMessages[buttonStates.saved]}`;
  }

  return (
    <div className="container">
      <Helmet>
        <title>Offers | {offerData.name} | Edit Offer Bundle Packages</title>
      </Helmet>
      <Link to={`/offers/${offerId}`}>
        <IconButton size="small" color="primary" aria-label="Return to offer">
          <ChevronLeft /> Return to offer
        </IconButton>
      </Link>
      <h1 className="page-header"> Edit Offer Bundle Packages </h1>

      <Modal open={saveFailed} onClose={onSaveFailedModalClose}>
        <ModalBox>
          <ErrorListDisplay messages={['Some packages not saved.']} />
        </ModalBox>
      </Modal>

      <ExpandOptionHolder>
        {packageData.length > 1 && (
          <Button onClick={toggleAllPackages}>{isAllExpanded ? 'Collapse All Options' : 'Expand All Options'}</Button>
        )}

        {(offerData.internal_packages || offerData.packages.length === 0) && (
          <FlexRight>
            <div>
              <Button variant="contained" onClick={onAddPackage}>
                Add Package
              </Button>
            </div>
          </FlexRight>
        )}
      </ExpandOptionHolder>

      {packageData.map((pkg, index) => {
        return (
          <Accordion
            sx={{ boxShadow: 2 }}
            expanded={packagesExpansionStatuses[index]}
            key={pkg.le_package_id ?? pkg.id_salesforce_external}
            onChange={() => {
              togglePackage(index);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={pkg.le_package_id ?? pkg.id_salesforce_external}
              id={pkg.le_package_id ?? pkg.id_salesforce_external}
            >
              <Typography variant="h6">{pkg.deal_option_name || pkg.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BundlePackageForm
                key={pkg.le_package_id ?? pkg.id_salesforce_external}
                schema={packagesSchemas[index]}
                offerType={offerData.type}
                offerBundles={offerData.offer_bundles}
                offerAttachedPackages={offerData.attached_packages}
                packageData={pkg}
                shouldSaveAll={shouldSaveAll}
                onChange={onPackageChange.bind(null, index)}
                onSaveFailed={onPackageSaveFailed}
                onSaveComplete={onPackageSaveComplete}
                onDeleteComplete={onDeletePackage.bind(null, index)}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div className="button-container" style={{ justifyContent: 'right', zIndex: 1 }}>
        {packageData.length > 1 && (
          <Button
            variant="contained"
            color={allSaveButtonState === buttonStates.saved ? buttonStates.saved : buttonStates.default}
            onClick={() => {
              setAllSaveCounter(0);
            }}
            disabled={allSaveButtonState === buttonStates.saving}
          >
            {saveButtonMessage}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BundlePackagesEditContainer;
