import React, { useCallback } from 'react';

import { WidgetProps } from '@rjsf/utils';
import { useSnackbar } from 'notistack';

import { Autocomplete, Button, Checkbox, Grid, TextField } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { getTourCategoriesSuggestion } from '~/services/ToursService';

function TourCategoriesComboBoxWidget(props: WidgetProps) {
  const {
    defaultValue,
    disabled,
    id,
    label,
    multiple,
    options,
    placeholder,
    readonly,
    required,
    value,
    onChange,
    formContext,
  } = props;

  const { isToggled: isLoading, toggleOn: startLoading, toggleOff: endLoading } = useToggleState();
  const { enqueueSnackbar } = useSnackbar();

  const getCategories = useCallback(() => {
    const tourId = formContext.tourId;

    if (!tourId) {
      enqueueSnackbar('tourId is undefined/null', {
        variant: 'error',
      });

      return;
    }

    startLoading();

    getTourCategoriesSuggestion(tourId)
      .then((response: any) => {
        const suggestedTourCategories = (response.result?.suggestedCategories ?? []) as string[];

        if (suggestedTourCategories.length === 0) {
          enqueueSnackbar("Couldn't generate any suggestions", {
            variant: 'error',
          });

          return;
        }

        onChange(suggestedTourCategories);

        enqueueSnackbar('Successfully generated categories', {
          variant: 'success',
        });
      })
      .catch((e) =>
        enqueueSnackbar(
          `There was an error while fetching tour categories suggestion [${JSON.stringify(e, null, 2)}]`,
          {
            variant: 'error',
          },
        ),
      )
      .finally(endLoading);
  }, [formContext, isLoading]);

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      readOnly={readonly}
      loading={isLoading}
      loadingText="Computing suggestion..."
      options={options.enumOptions}
      getOptionLabel={(option) => option.label ?? option}
      isOptionEqualToValue={(option, value) => option.value === value || option === value}
      onChange={(e, newValue) => {
        onChange(newValue.map((o) => o.value ?? o));
      }}
      openOnFocus
      disableCloseOnSelect
      renderInput={(params) => (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <TextField {...params} label={label} placeholder={placeholder} fullWidth />
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="contained"
              size="large"
              fullWidth
              onClick={getCategories}
              disabled={isLoading}
            >
              {isLoading ? 'Computing suggestion...' : options.btnLabel}
            </Button>
          </Grid>
        </Grid>
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox required={required} checked={selected} />
          {option.label ?? option}
        </li>
      )}
    />
  );
}

export default TourCategoriesComboBoxWidget;
