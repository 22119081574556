import React from 'react';

import { CruisesContract as Api } from '@luxuryescapes/contract-svc-cruise';

import { LinkText } from './styles';

interface ILinkCell {
  row: Api.OfferSummary;
  children: React.ReactNode;
  urlBuilder?: (row: Api.OfferSummary) => string;
}

const LinkCell = ({ row, children, urlBuilder }: ILinkCell): JSX.Element => (
  <a href={urlBuilder?.(row)}>
    <LinkText>{children}</LinkText>
  </a>
);

const ShipName = (cell: string, row: Api.OfferSummary): JSX.Element => {
  return <LinkCell row={row}>{cell}</LinkCell>;
};

export { ShipName };
