import React from 'react';

import { Box } from '@mui/material';

import Image from '~/components/Common/Image';

type Props = {
  imageId: string;
  width?: number;
  height?: number;
  fullWidth?: boolean;
  fitType?: 'cover' | 'contain';
};

export default function ResponsiveImage({ imageId, fullWidth, height = 90, width = 170, fitType = 'cover' }: Props) {
  return (
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        width: fullWidth ? '100%' : width,
        height,
        '& img': {
          objectFit: fitType,
          objectPosition: 'center',
          width: '100%',
          height: '100%',
        },
      }}
    >
      <Image publicId={imageId} options={{ width }} />
    </Box>
  );
}
