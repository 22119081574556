import React, { useCallback, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import ReservationService from '~/services/ReservationService';

import ImagesForm from './ImagesForm';
import cleanProperty from './helpers/cleanProperty';

const fetchProperty = (propertyId) => {
  return ReservationService.getProperty(propertyId);
};

export default function ImagesEditPage(props) {
  const { id_property: propertyId, id_vendor: vendorId } = useParams<{ id_property: string; id_vendor: string }>();

  const [property, setProperty] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    fetchProperty(propertyId)
      .then((propFetch) => {
        setProperty(cleanProperty(propFetch.result));
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  }, [enqueueSnackbar, propertyId]);

  const handleImagesChange = useCallback(
    (images) => {
      setProperty({
        ...property,
        images,
      });
    },
    [property],
  );

  const createImage = useCallback(
    async (image) => {
      return ReservationService.createPropertyImages(image, property.id);
    },
    [property],
  );

  const updateImages = useCallback(
    async (images) => {
      const imagesWithPropertyId = images.map((img) => ({ ...img, property_id: property.id }));
      return ReservationService.updatePropertyImages(imagesWithPropertyId, property.id);
    },
    [property],
  );

  const deleteImage = useCallback(
    async (propertyImageId) => {
      return ReservationService.deletePropertyImage(property.id, propertyImageId);
    },
    [property],
  );

  return (
    <Container maxWidth="xl">
      <PageHeader title="Edit property images" backButton={`/vendors/${vendorId}/properties/${propertyId}`} />
      {loading && <div>Loading...</div>}
      {!loading && (
        <ImagesForm
          images={property.images}
          onImagesChange={handleImagesChange}
          createImage={createImage}
          updateImages={updateImages}
          deleteImage={deleteImage}
          propertyId={propertyId}
        />
      )}
    </Container>
  );
}
