import React from 'react';

import { Control, useController } from 'react-hook-form';

import { FormControl, FormHelperText, InputBaseComponentProps, TextField } from '@mui/material';

type Props = {
  control: Control<any>;
  fullWidth?: boolean;
  helperText?: string;
  hidden?: boolean;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps;
  label?: string;
  muiLabel?: string;
  name: string;
  required?: boolean;
  type?: string;
  rows?: number;
  value?: any;
  rules?: {
    required?: boolean | string;
    maxLength?: number | { value: number; message: string };
  };
};

// important: intended to be used inside react hook form
const Input = ({
  control,
  fullWidth,
  helperText,
  hidden,
  disabled,
  inputProps,
  label,
  muiLabel,
  name,
  required,
  type,
  rows,
  value = null,
  rules,
}: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  if (hidden) {
    return <input {...field} type="hidden" />;
  }

  return (
    <FormControl fullWidth={fullWidth} error={!!error} variant="standard">
      {label && <label htmlFor={`input-${name}`}>{label}</label>}
      <TextField
        id={`input-${name}`}
        inputProps={inputProps}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        disabled={disabled}
        ref={field.ref}
        required={required}
        type={type}
        value={value || field.value}
        label={muiLabel}
        multiline={rows > 1}
        rows={rows}
        error={!!error}
      />
      <FormHelperText>{error ? error.message : helperText}</FormHelperText>
    </FormControl>
  );
};

export default Input;
