import React, { useCallback, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import ErrorListDisplay from '~/components/Common/ErrorListDisplay';
import { buttonMessages } from '~/components/Common/Forms/states/submitButton';
import Spinner from '~/components/Common/Spinner';

import {
  STEP_CONFIRM_COPIED_DATES,
  STEP_SELECT_COPIED_DATES,
  STEP_SELECT_COPY_TO_TOUR_OPTIONS,
} from '~/consts/copyTourOptions';

import { formatDateNumeralMonths } from '~/services/TimeService';

interface Props {
  apiErrors: string[];
  onHide: () => void;
  copyToState: string;
  currentTourOptionDates: App.TourOptionDate[];
  isOpen: boolean;
  onCopyToTourOptionsSubmit: (
    selectedTourOptions: App.TourOptionLabel[],
    selectedDates: App.TourOptionDate[],
    copyAvailability: boolean,
    inputInventory: number,
  ) => void;
  selectedDatesFormKeys: number[];
  setButtonToDefault: () => void;
  tourOptionDataList: App.TourOptionLabel[];
}

const CopyToTourOptionsModal = (props: Props) => {
  const {
    isOpen,
    onHide,
    selectedDatesFormKeys,
    currentTourOptionDates,
    tourOptionDataList,
    copyToState,
    onCopyToTourOptionsSubmit,
    setButtonToDefault,
    apiErrors,
  } = props;

  const isSaving = buttonMessages[copyToState] === 'Saving...';
  const isSaved = buttonMessages[copyToState] === 'Saved';
  const [currentStep, setCurrentStep] = useState(STEP_SELECT_COPY_TO_TOUR_OPTIONS);
  const [selectedTourOptions, setSelectedTourOptions] = useState<App.TourOptionLabel[]>([]);
  const [selectedDates, setSelectedDates] = useState(selectedDatesFormKeys);
  const [copyAvailability, setCopyAvailability] = useState(false);
  const [inputInventory, setInputInventory] = useState(0);

  useEffect(() => setButtonToDefault(), [currentStep, setButtonToDefault]);

  const getDatesDataFromFormKeys = useCallback(
    (formKeys: number[]) => {
      const fullSelectedDatesData = currentTourOptionDates.filter((date) => formKeys.includes(date.formKey));
      return fullSelectedDatesData;
    },
    [currentTourOptionDates],
  );

  const onChangeInventory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputInventory(parseInt(e.target.value));
  };

  const clearState = () => {
    setSelectedTourOptions([]);
    setSelectedDates(selectedDatesFormKeys);
    setCopyAvailability(false);
    setInputInventory(0);
  };

  const handleCloseModal = () => {
    clearState();
    onHide();
    setCurrentStep(STEP_SELECT_COPY_TO_TOUR_OPTIONS);
  };

  const onToggleCheckbox = (row: App.TourOptionDate) => () => {
    let newSelected = [...selectedDates];
    if (selectedDates.includes(row.formKey)) {
      newSelected = newSelected.filter((i) => i !== row.formKey);
    } else {
      newSelected.push(row.formKey);
    }
    setSelectedDates(newSelected);
  };

  const dateFormatter = (row: App.TourOptionDate) => (
    <FormGroup key={row.formKey}>
      <FormControlLabel
        control={
          <Checkbox key={row.formKey} checked={selectedDates.includes(row.formKey)} onChange={onToggleCheckbox(row)} />
        }
        label={formatDateNumeralMonths(row.start_date)}
      />
    </FormGroup>
  );

  const handleTourOptionsChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    const values = typeof value === 'string' ? value.split(',') : value;

    setSelectedTourOptions(tourOptionDataList.filter((o) => values.includes(o.value)));
  };

  const selectAllDates = () => {
    const allDates = [...currentTourOptionDates];
    const allFormKeys = allDates.map((i) => i.formKey);
    setSelectedDates(allFormKeys);
  };

  const deselectAllDates = () => {
    setSelectedDates([]);
  };

  const toggleAvailabilityCheckbox = () => {
    setCopyAvailability(!copyAvailability);
  };

  let copyApiErrors: React.ReactNode | null = null;
  if (apiErrors && apiErrors.length) {
    copyApiErrors = <ErrorListDisplay messages={apiErrors} />;
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
        {copyApiErrors}
        {currentStep === STEP_SELECT_COPY_TO_TOUR_OPTIONS && (
          <>
            <DialogTitle>Step1: Select copy to tour options from the list</DialogTitle>

            <DialogContent>
              <FormControl fullWidth>
                <Select
                  multiple
                  id="selectCopyToTourOptions"
                  onChange={handleTourOptionsChange}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={selectedTourOptions.map((o) => o.value)}
                  input={<OutlinedInput />}
                >
                  {tourOptionDataList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>

            <DialogActions>
              <Button variant="text" onClick={() => setSelectedTourOptions(tourOptionDataList)}>
                Select all
              </Button>
              <Button variant="text" onClick={() => setSelectedTourOptions(null)}>
                Deselect all
              </Button>
              <Button
                variant="contained"
                disabled={!selectedTourOptions?.length}
                onClick={() => setCurrentStep(STEP_SELECT_COPIED_DATES)}
              >
                Continue
              </Button>
            </DialogActions>
          </>
        )}
        {currentStep === STEP_SELECT_COPIED_DATES && (
          <>
            <DialogTitle>Step 2: Select copy to dates</DialogTitle>

            <DialogContent>
              <DataGrid
                columns={[
                  {
                    field: 'start_date',
                    headerName: 'Start date',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => dateFormatter(params.row),
                    display: 'flex',
                  },
                  { field: 'total', headerName: 'Total availability', flex: 1, sortable: false, display: 'flex' },
                ]}
                rows={currentTourOptionDates}
                getRowId={(row) => row.id}
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
              />
            </DialogContent>

            <DialogActions>
              <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <Stack direction="column">
                  {!copyAvailability && (
                    <Box>
                      <TextField
                        label="Availability"
                        type="number"
                        inputProps={{ step: '1', min: '0' }}
                        value={inputInventory}
                        onChange={onChangeInventory}
                      />
                    </Box>
                  )}

                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={copyAvailability} onChange={toggleAvailabilityCheckbox} />}
                      label="Copy availabilities"
                    />
                  </FormGroup>
                </Stack>

                <div>
                  <Button variant="text" onClick={() => setCurrentStep(STEP_SELECT_COPY_TO_TOUR_OPTIONS)}>
                    Back
                  </Button>
                  <Button variant="text" onClick={selectAllDates}>
                    Select all
                  </Button>
                  <Button variant="text" onClick={deselectAllDates}>
                    Deselect all
                  </Button>
                  <Button
                    onClick={() => setCurrentStep(STEP_CONFIRM_COPIED_DATES)}
                    variant="contained"
                    type="submit"
                    disabled={!selectedDates.length}
                  >
                    Continue
                  </Button>
                </div>
              </Stack>
            </DialogActions>
          </>
        )}
        {currentStep === STEP_CONFIRM_COPIED_DATES && !isSaved && (
          <>
            <DialogTitle>Please confirm the copy to details below</DialogTitle>

            <DialogContent>
              {isSaving && <Spinner />}
              {!isSaving && (
                <>
                  <List>
                    <ListItem key="selected-tour-options-list">
                      <ListItemText>The tour options you have selected</ListItemText>
                      <Button variant="contained" onClick={() => setCurrentStep(STEP_SELECT_COPY_TO_TOUR_OPTIONS)}>
                        Reselect
                      </Button>
                    </ListItem>
                    {selectedTourOptions.map((option) => (
                      <ListItem key={option.value}>
                        <ListItemText>{option.label}</ListItemText>
                      </ListItem>
                    ))}
                  </List>

                  <List>
                    <ListItem key="selected-dates-list">
                      <ListItemText>The dates and availabilities you have selected</ListItemText>
                      <Button variant="contained" onClick={() => setCurrentStep(STEP_SELECT_COPIED_DATES)}>
                        Reselect
                      </Button>
                    </ListItem>
                    {getDatesDataFromFormKeys(selectedDates).map((date) => (
                      <ListItem key={date.id}>
                        <ListItemText>{formatDateNumeralMonths(date.start_date)}</ListItemText>
                        <ListItemText>{copyAvailability ? date.total : inputInventory}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </DialogContent>

            <DialogActions>
              {!isSaving && <Alert severity="warning">Do you want to continue?</Alert>}
              <Button onClick={() => setCurrentStep(STEP_SELECT_COPIED_DATES)} disabled={isSaving}>
                Back
              </Button>
              <Button
                onClick={() =>
                  onCopyToTourOptionsSubmit(
                    selectedTourOptions,
                    getDatesDataFromFormKeys(selectedDates),
                    copyAvailability,
                    inputInventory,
                  )
                }
                variant="contained"
                type="submit"
                disabled={!selectedDates.length || isSaving}
              >
                {buttonMessages[copyToState]}
              </Button>
            </DialogActions>
          </>
        )}
        {currentStep === STEP_CONFIRM_COPIED_DATES && isSaved && <Alert severity="success">Successfully saved!</Alert>}
      </Dialog>
    </div>
  );
};

export default CopyToTourOptionsModal;
