import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import UsersService from '~/services/UsersService';

import CreateNewUser from './CreateNewUser';

const augmentRegisterSchema = (registerSchema) => {
  const required = [...registerSchema.required, 'givenName', 'surname', 'roles'];
  return Object.assign({}, registerSchema, { required });
};

type Props = {
  show: boolean;
  onHide: () => void;
  userRegistrationSuccess: () => void;
  userData?: unknown;
};

export default function AsincCreateNewUser({ show, ...props }: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const [schema, setSchema] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) return;

    setLoading(true);
    UsersService.getRegisterSchema(brand)
      .then(setSchema)
      .finally(() => setLoading(false));
  }, [show, brand]);

  return (
    <CreateNewUser
      show={show}
      onHide={props.onHide}
      schema={augmentRegisterSchema((schema ?? { post: { body: { required: [] } } })?.post.body)}
      userRegistrationSuccess={props.userRegistrationSuccess}
      userData={props.userData}
      isLoading={isLoading}
    />
  );
}
