import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';

import { Button } from '@mui/material';

import { ROLE_ADMIN_USER, ROLE_HOTEL_COORDINATOR } from '~/consts/roles';

import OffersService from '../../../services/OffersService';
import { defaultEndDate, defaultStartDate } from '../../../utils/DefaultDate';
import { reportError } from '../../../utils/reportError';
import { scheduleForBrandAndType } from '../../../utils/scheduleForBrandAndType';
import PermissionedComponent from '../PermissionedComponent';

import NewScheduleFormBase from './NewScheduleFormBase';

class NewScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      startUpdated: false,
      endUpdated: false,
      schedule: {
        start: this.props.start || defaultStartDate(),
        end: this.props.end || defaultEndDate(),
      },
      idList: this.props.idList,
      errors: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.start !== prevProps.start) {
      this.updateStart(this.props.start);
    }

    if (this.props.end !== prevProps.end) {
      this.updateEnd(this.props.end);
    }

    if (!isEqual(this.props.idList.sort(), prevProps.idList.sort())) {
      this.setState({ idList: this.props.idList });
    }
  }

  onSubmit = () => {
    if (this.state.isSaving) {
      return;
    }

    this.setState({
      errors: [],
      isSaving: true,
    });
    const { type, brands, offerId } = this.props;
    const { idList } = this.state;
    const regionCodes = this.props.regions.map((r) => r.code);
    const { end, start } = this.state.schedule;

    const createPayload = {
      type,
      regions: regionCodes,
      start,
      end,
      brands,
    };

    if (this.state.idList.length > 0) {
      const updatePayload = {
        idList,
        ...createPayload,
      };

      OffersService.updateScheduleList(updatePayload, offerId)
        .then((result) => {
          this.setState({
            idList: result.map((schedule) => schedule.id),
            isSaving: false,
          });
          this.props.onSuccess(this.state.schedule);
        })
        .catch((e) => {
          this.setState({
            isSaving: false,
          });
          this.onError(e);
          reportError(e);
        });
    } else {
      OffersService.createScheduleList(createPayload, offerId)
        .then((newSchedule) => {
          this.setState({
            schedule: {
              start: newSchedule[0].start,
              end: newSchedule[0].end,
            },
            isSaving: false,
            idList: newSchedule.map((schedule) => schedule.id),
          });
          this.props.onSuccess(this.state.schedule);
        })
        .catch((e) => {
          this.setState({
            isSaving: false,
          });
          this.onError(e);
          reportError(e);
        });
    }
  };

  onDelete = () => {
    const payload = {
      idList: this.state.idList,
    };

    OffersService.deleteScheduleList(this.props.offerId, payload)
      .then(() => {
        this.setState({
          idList: [],
        });
      })
      .catch((e) => {
        this.onError(e);
        reportError(e);
      });
  };

  onError = (data) => {
    this.setState({
      editing: false,
      errors: data.errors,
    });
  };

  buttonLabel() {
    const { idList } = this.state;
    let label;

    if (this.state.isSaving === true) {
      return 'Saving...';
    }

    label = idList.length > 0 ? 'Save' : 'Create';
    return label;
  }

  updateStart = (time) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        start: time,
      },
      startUpdated: true,
    }));
  };

  updateEnd = (time) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        end: time,
      },
      endUpdated: true,
    }));
  };

  onReset = () => {
    const { schedules, brand, type } = this.props;

    this.setState({
      schedule: {
        start:
          scheduleForBrandAndType({
            schedules,
            brand,
            type,
            scheduleKey: 'start',
          }) || defaultStartDate(),
        end:
          scheduleForBrandAndType({
            schedules,
            brand,
            type,
            scheduleKey: 'end',
          }) || defaultEndDate(),
      },
    });
  };

  onApplyToAll = () => {
    this.props.onApplyToAll({
      schedule: {
        start: this.state.schedule.start,
        end: this.state.schedule.end,
      },
    });
  };

  render() {
    const buttonClasses = this.state.idList.length > 0 ? 'edit-btn' : 'create-btn';
    return (
      <div>
        <div>
          {this.state.errors &&
            this.state.errors.map((error, index) => {
              return (
                <p key={index} className="schedules-error">
                  {error.message}
                </p>
              );
            })}
        </div>
        <div className="schedule-form">
          <NewScheduleFormBase
            schedule={this.state.schedule}
            updateStart={this.updateStart}
            updateEnd={this.updateEnd}
            startUpdated={this.state.startUpdated}
            endUpdated={this.state.endUpdated}
            onReset={this.onReset}
            onApplyToAll={this.onApplyToAll}
            enableUtilBtn={this.props.enableUtilBtn}
          />
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_HOTEL_COORDINATOR]}>
            <div>
              <Button
                disabled={this.props.disabled || this.state.isSaving}
                type="button"
                className={buttonClasses}
                onClick={this.onSubmit}
              >
                {this.buttonLabel()}
              </Button>
              {this.props.isDeleteAllowed && !!this.state.idList.length && (
                <Button type="button" onClick={this.onDelete} variant="contained" color="warning">
                  Delete
                </Button>
              )}
            </div>
          </PermissionedComponent>
        </div>
      </div>
    );
  }
}

export default NewScheduleForm;
