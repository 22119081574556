import React, { ReactElement } from 'react';

import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

export function RoomRateTableHeader(): ReactElement {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">
          <Typography variant="h6">Package</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="h6">Room Rate</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="h6">Old Cancellation Policy</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
