import React from 'react';

import { useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';

const TABS: Array<{ title: string; route: string }> = [
  { title: 'Details', route: '' },
  { title: 'Suppliers', route: '/suppliers' },
  { title: 'Rooms', route: '/rooms' },
  { title: 'Room Mapping', route: '/room-mapping' },
];

export default function AccommodationPropertyDetailsNav() {
  const { pathname } = useLocation();
  const { propertyId } = useParams<{ propertyId: string }>();

  return (
    <Tabs
      orientation="horizontal"
      variant="scrollable"
      aria-label="Accommodation Tabs"
      sx={{ border: 0 }}
      value={pathname}
    >
      {TABS.map((tab, index) => (
        <Tab
          key={`${tab.title}-${index}`}
          label={tab.title}
          component={NavLink}
          value={`/accommodation/properties/${propertyId}${tab.route}`}
          to={`/accommodation/properties/${propertyId}${tab.route}`}
        />
      ))}
    </Tabs>
  );
}
