import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Chat } from '~/components/SupportAssistant/types/Chat';

import { searchChats } from '~/services/SupportService';
import UsersService from '~/services/UsersService';

import { SearchChatParams } from './types';

const LE_BRAND = 'luxuryescapes';

type SearchChatQueryValues = {
  searchChatsQuery: (searchChatsParams: SearchChatParams) => Promise<void>;
  loading: boolean;
  chats: Chat[];
  chatsCustomers: Map<string, App.User>;
  chatsWithCustomerDetails: (Chat & { customer: App.User })[];
  total: number;
};

export function useSearchChatsQuery(): SearchChatQueryValues {
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');

  const [chats, setChats] = useState([]);
  const [total, setTotal] = useState(0);

  const [chatsCustomers, setChatsCustomers] = useState<Map<string, App.User>>(new Map());

  const { enqueueSnackbar } = useSnackbar();

  const searchChatsQuery = useCallback(
    async ({
      searchString,
      page,
      limit,
      sortModel,
      filterByDeadEnd,
      chatTopicsFilter,
      customersOnly,
      agentChatsOnly,
      followUpOnly,
      hasMessagesOnly,
    }: SearchChatParams) => {
      try {
        setFetchingState('loading');

        const { result, count } = await searchChats({
          searchString: searchString?.trim(),
          offset: page * limit,
          limit,
          sortBy: sortModel.field,
          sortDirection: sortModel.sort,
          hasDeadEnd: filterByDeadEnd || undefined,
          chatTopics: chatTopicsFilter,
          customersOnly,
          agentChatsOnly,
          followUpOnly,
          hasMessagesOnly,
        });

        setChats(result);
        setTotal(count);
        setFetchingState('success');
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'warning' });
        setFetchingState('failed');
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (chats.length > 0) {
      const customers = chats.map((chat) => chat.customerId).filter((customerId) => !!customerId);

      if (customers.length === 0) {
        return;
      }

      UsersService.getUsersSummaryByIds([...new Set(customers)], LE_BRAND).then((users) => setChatsCustomers(users));
    }
  }, [chats]);

  const chatsWithCustomerDetails = useMemo(
    () =>
      chats.map((chat) => ({
        ...chat,
        customer: chatsCustomers.get(chat.customerId),
      })),
    [chats, chatsCustomers],
  );

  const loading = useMemo(() => {
    return fetchingState === 'loading';
  }, [fetchingState]);

  return {
    searchChatsQuery,
    loading,
    chats,
    chatsCustomers,
    chatsWithCustomerDetails,
    total,
  };
}
