import React, { useMemo } from 'react';

import { List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

import { formatDateLongMonthWithMeridiemNoSeconds as formatDate } from '~/services/TimeService';

import type { ScheduleLogRecordWithUser } from '~/types/responses';

const actionVerbMap = {
  INSERT: 'created',
  UPDATE: 'updated',
  DELETE: 'deleted',
};

const marketingChannelMap = {};

type Props = {
  logs: ScheduleLogRecordWithUser[];
};

export default function ScheduleHistoryList({ logs }: Props) {
  const sortedLogs = useMemo(
    () => logs.sort((a, b) => new Date(b.logged_at).getTime() - new Date(a.logged_at).getTime()),
    [logs],
  );

  return (
    <List disablePadding>
      {sortedLogs.length > 0 &&
        sortedLogs.map((log) => (
          <ListItem key={log.id} disableGutters divider>
            <ListItemText
              disableTypography
              primary={
                <Typography fontSize="1em">
                  Schedule {log.type} for {log.region} {actionVerbMap[log.action]} on {formatDate(log.logged_at)} by{' '}
                  {log.user.full_name}
                </Typography>
              }
              secondary={
                <Stack direction="row" spacing={2}>
                  <Typography color="secondary">{formatDate(log.start)}</Typography>
                  <Typography color="secondary">&#8594;</Typography>
                  <Typography color="secondary">{formatDate(log.end)}</Typography>
                </Stack>
              }
            />
          </ListItem>
        ))}
    </List>
  );
}
