import React, { ReactElement, useCallback, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import {
  checkSyncPropertyMetaJob,
  checkUploadingStatus,
  populateImagesToRoomTypes,
  syncPropertyMeta,
} from '~/services/HotelMetaService';

import OffersService from '../../../services/OffersService';
import Spinner from '../../Common/Spinner';

const STATUS_NOT_TRIGGERED = 'not-triggered';
const STATUS_IN_PROGRESS = 'in-progress';
const STATUS_PROCESSED = 'processed';
const STATUS_FAILED = 'failed';

export default function AddImagesButton(props: any): ReactElement {
  const propertyId = props.propertyId;
  const vendorId = props.vendorId;
  const [allOffersIds, setAllOffersIds] = useState([]);
  const [offerId, setOfferId] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(STATUS_NOT_TRIGGERED);
  const [reSyncGotTriggered, setReSyncStatus] = useState(false);

  const [modalState, setModalState] = useState(false);
  const toggleModal = () => setModalState((prevState) => !prevState);
  const openModal = useCallback(() => {
    if (buttonStatus !== STATUS_NOT_TRIGGERED) return;

    OffersService.getOffers({
      page: 1,
      limit: 100,
      queryString: vendorId,
      orderByScheduleDate: 1,
    }).then((data) => {
      const fetchedOffers = (data.result || []).map((offer) => {
        return {
          value: offer.id_salesforce_external,
          label: offer.id_salesforce_external,
        };
      });
      setAllOffersIds([
        {
          value: null,
          label: null,
        },
        ...fetchedOffers,
      ]);
    });

    toggleModal();
  }, [buttonStatus, vendorId]);

  const addImagesToRooms = useCallback(async () => {
    if (buttonStatus !== STATUS_NOT_TRIGGERED) return;

    try {
      setButtonStatus(STATUS_IN_PROGRESS);
      setModalState(false);
      const status = await populateImagesToRoomTypes(propertyId, offerId?.value);
      if (status.message === STATUS_PROCESSED) {
        setButtonStatus(STATUS_PROCESSED);
      } else {
        const checkStatusInterval = setInterval(async () => {
          const checkStatus = await checkUploadingStatus(propertyId);
          if (checkStatus.message === STATUS_PROCESSED) {
            setButtonStatus(STATUS_PROCESSED);
            clearInterval(checkStatusInterval);
          }
        }, 4000);
      }
    } catch (e) {
      if (e.status === 409 && !reSyncGotTriggered) {
        await triggerPropertyMetaSync();
      } else {
        setButtonStatus(STATUS_FAILED);
      }
    }
  }, [buttonStatus, propertyId, offerId?.value, reSyncGotTriggered]);

  const triggerPropertyMetaSync = async () => {
    setReSyncStatus(true);
    try {
      const propertyMetaResponse = await syncPropertyMeta(props.propertyId);
      const checkStatusInterval = setInterval(async () => {
        const checkStatus = await checkSyncPropertyMetaJob(propertyMetaResponse.jobId);
        if (checkStatus.is_completed === true) {
          clearInterval(checkStatusInterval);
          await addImagesToRooms();
        }
      }, 4000);
    } catch (e) {
      setButtonStatus(STATUS_FAILED);
    }
  };

  return (
    <>
      <Box>
        <Button
          onClick={openModal}
          type="button"
          startIcon={buttonStatus === STATUS_IN_PROGRESS ? <Spinner size={20} inline /> : undefined}
        >
          {buttonStatus === STATUS_NOT_TRIGGERED ? 'Add Images to rooms' : null}
          {buttonStatus === STATUS_IN_PROGRESS ? 'Processing' : null}
          {buttonStatus === STATUS_PROCESSED ? 'Images added' : null}
          {buttonStatus === STATUS_FAILED ? 'Failed' : null}
        </Button>
      </Box>

      <Dialog open={modalState} onClose={toggleModal}>
        <DialogTitle>Add Images to rooms</DialogTitle>

        <DialogContent>
          <DialogContentText>Room types will be populated with images from property meta provider.</DialogContentText>
          <DialogContentText>
            Additionally, to populate an offer with images, please select an offer ID.
          </DialogContentText>

          <div style={{ width: '300px' }}>
            <FormControl fullWidth>
              <InputLabel id="offer-id-label">Offer ID</InputLabel>
              <Select
                labelId="offer-id-label"
                id="offer-id"
                value={offerId}
                onChange={(e) => setOfferId(e.target.value)}
              >
                {allOffersIds.map((oId) => (
                  <MenuItem key={oId} value={oId}>
                    {oId}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Offer ID to copy Images into</FormHelperText>
            </FormControl>
          </div>

          <Button type="button" onClick={addImagesToRooms} variant="contained">
            Add Images
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
