import React, { useMemo, useState } from 'react';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getRebookStatus, updateOrderItem } from '~/services/OrdersService';

import RebookInfo from './RebookInfo';

interface Props {
  order: App.Order;
  itemId: string;
  onClose: () => void;
  refreshData: () => void;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
    };
  };
}

function RebookModal(props: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const item = useMemo(() => {
    return props.order.bedbank_items.find((i) => i.id === props.itemId);
  }, [props.itemId]);

  const checkStatus = () => {
    getRebookStatus(props.order.id_orders, props.itemId).then(({ jobState, failedReason }) => {
      if (typeof jobState !== 'undefined') {
        if (jobState === 'failed') {
          setError(failedReason);
          setLoading(false);
        } else {
          setTimeout(checkStatus, 1000);
        }
      } else {
        setLoading(false);
        props.refreshData();
      }
    });
  };

  const rebookItem = () => {
    setLoading(true);
    setError(null);

    updateOrderItem(props.order.id_orders, props.itemId, {
      op: 'rebook',
    })
      .then(() => {
        checkStatus();
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  if (loading) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <Spinner />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>
        <Stack spacing={2}>Rebooking item {props.itemId}</Stack>
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2}>
          {item.rooms
            .filter(
              (r) =>
                props.bedbankRoomsInfo[r.id_reservation_room] && props.bedbankRoomsInfo[r.id_reservation_room]?.rebook,
            )
            .map((room) => (
              <RebookInfo
                key={room.room_index}
                roomIndex={room.room_index}
                rebookableData={props.bedbankRoomsInfo[room.id_reservation_room].rebook}
                currencyCode={props.order.currency_code}
              />
            ))}
        </Stack>
      </DialogContent>

      {error && <Alert severity="error">{error}</Alert>}

      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={rebookItem}>
          Consent
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RebookModal;
