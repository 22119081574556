import React, { useState } from 'react';

import { Button, Typography } from '@mui/material';

interface Props {
  value?: string | null;
}

function ExpandableText({ value }: Props) {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  if (!value) {
    return <Typography>N/A</Typography>;
  }

  const isLongText = value.length > 40;

  return (
    <>
      <Typography noWrap={!showMore}>{value}</Typography>
      {isLongText && (
        <Button size="small" onClick={handleToggle}>
          {showMore ? 'Less' : 'More'}
        </Button>
      )}
    </>
  );
}

export default ExpandableText;
