import React, { useEffect } from 'react';

import currencyFormatter from 'currency-formatter';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import { changeCurrencyCode, selectInsuranceOption, setOrderId } from '~/actions/cart';
import { setInitialState } from '~/actions/insurance';

import OrderInsuranceSummary from '../../../Common/Payment/OrderInsuranceSummary';
import OrderFooter from '../OrderFooter';

function StepInformation(props) {
  const { orderId, order, insurance }: { orderId: string; order: App.Order; insurance: App.InsuranceItem } = props;

  const location = useLocation<{
    updateId: string;
    amount: number;
    coveredAmount: number;
    startDate: string;
    endDate: string;
    travellerDetails: App.InsuranceTraveller[];
  }>();
  const updateData = location.state;

  useEffect(() => {
    props.setOrderId(orderId);
  }, [orderId]);

  useEffect(() => {
    if (props.cart.currencyCode !== order.currency_code) {
      props.changeCurrencyCode(order.currency_code, order.region_code);
    }
  }, [order.currency_code, order.region_code]);

  useEffect(() => {
    if (!insurance) return;

    props.setInitialState({
      startDate: updateData.startDate,
      endDate: updateData.endDate,
      travellers: updateData.travellerDetails,
      destinationCountries: insurance.destinations,
      currencyCode: order.currency_code,
    });

    props.selectInsuranceOption({
      total: updateData.amount,
      quoteId: updateData.updateId,
      productId: insurance.product_id,
      productName: insurance.product_name,
    });
  }, [insurance]);

  const nextStep = () => {
    props.proceedStep(updateData);
  };

  return (
    <div className="payment">
      <div className="row">
        <div className="col-xs-12 col-md-9">
          <div className="order-summary">
            <h2>Updated insurance</h2>

            <OrderInsuranceSummary
              productName={insurance.product_name}
              total={updateData.amount}
              destinationCountries={insurance.destinations}
              currencyCode={order.currency_code}
              travellers={updateData.travellerDetails}
              startDate={updateData.startDate}
              endDate={updateData.endDate}
            />

            <div style={{ marginTop: 30 }}>
              <h4>
                Total amount for update:{' '}
                {currencyFormatter.format(updateData.amount, {
                  code: order.currency_code,
                })}
              </h4>
            </div>
          </div>
        </div>
      </div>

      <OrderFooter
        cart={props.cart}
        proceedStep={nextStep}
        backStep={props.backStep}
        nextStepLabel={props.nextStepLabel}
        canProceed
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOrderId, setInitialState, selectInsuranceOption, changeCurrencyCode }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StepInformation);
