import React from 'react';

import { Pagination as MuiPagination, TablePaginationProps } from '@mui/material';
import { GridPagination as MuiGridPagination } from '@mui/x-data-grid';

function Pagination({
  page,
  count,
  rowsPerPage,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className' | 'count' | 'rowsPerPage'>) {
  return (
    <MuiPagination
      color="primary"
      className={className}
      count={Math.ceil(count / rowsPerPage)}
      page={page + 1}
      onChange={(event: React.MouseEvent<HTMLButtonElement>, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

export default function GridPagination(props) {
  return <MuiGridPagination ActionsComponent={Pagination} {...props} />;
}
