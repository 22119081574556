import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { offerRecordTypes } from '~/consts/offerRecordTypes';

import { downloadVendorPaymentsReport } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    recordTypeFilters: {
      title: 'Filter record type',
      type: 'array',
      items: {
        title: 'Record Type',
        type: 'string',
        enum: offerRecordTypes,
        uniqueItems: true,
      },
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportVendorPaymentsReportForm() {
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt, recordTypeFilters } = event.formData;
    const fileName = `VendorPayments_${startAt}_${endAt}.csv`;

    downloadVendorPaymentsReport({
      startAt,
      endAt,
      recordTypeFilters,
    })
      .then(function (text) {
        fileDownload(text, fileName);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Download Vendor Offer Payments Report
      </Button>

      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
