import React, { useCallback, useEffect, useState } from 'react';

import _debounce from 'lodash/debounce';

import { Stack } from '@mui/material';

interface Props {
  item: App.CartItem;
  onUpdateReservation: (item: App.OrderItem, newValue: string) => void;
}

export default function ChangeGuestName({ item, onUpdateReservation }: Props) {
  const [firstNameValue, setFirstNameValue] = useState(item.reservation.firstname);
  const [lastNameValue, setLastNameValue] = useState(item.reservation.lastname);

  const debouncedOnUpdateReservation = useCallback(
    _debounce((item, newValue) => {
      onUpdateReservation(item, newValue);
    }, 300),
    [onUpdateReservation],
  );

  useEffect(() => {
    debouncedOnUpdateReservation(item, {
      firstname: firstNameValue,
    });
  }, [debouncedOnUpdateReservation, firstNameValue]);

  useEffect(() => {
    debouncedOnUpdateReservation(item, {
      lastname: lastNameValue,
    });
  }, [debouncedOnUpdateReservation, lastNameValue]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <label htmlFor="first_name">Guest First Name:</label>
        <input
          id="first_name"
          name="firstname"
          value={firstNameValue}
          onChange={(event) => setFirstNameValue(event.target.value)}
        />
      </div>

      <div>
        <label htmlFor="last_name">Guest Last Name:</label>
        <input
          id="last_name"
          name="lastname"
          value={lastNameValue}
          onChange={(event) => setLastNameValue(event.target.value)}
        />
      </div>
    </Stack>
  );
}
