import React from 'react';

import { Box, Link } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarQuickFilter } from '@mui/x-data-grid';

import { API } from '@luxuryescapes/lib-types';

import { formatDateSlashes, sortByDate } from '~/services/TimeService';

interface Props {
  roomRates: Array<API.Reservation.RoomType>;
  propertyId: string;
  selectedRoomRates: Array<{
    propertyId: string;
    uniqueId: string;
    id: string;
    roomType: App.RoomType;
    roomRate: App.RoomRate;
  }>;
  conflictingRoomRates: Array<string>;
  onSelectRoomRates: (roomRate) => void;
  vendorId: string;
}

const roomRateLink = (vendorId, propertyId, roomTypeId, roomRateId) => {
  return `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}`;
};

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <GridToolbarQuickFilter style={{ flex: 1 }} />
    </Box>
  );
}

export function RoomRateSelection({
  roomRates,
  propertyId,
  selectedRoomRates,
  conflictingRoomRates,
  onSelectRoomRates,
  vendorId,
}: Props) {
  const columns: GridColDef[] = [
    {
      field: 'RoomType',
      headerName: 'Room Type',
      minWidth: 200,
      flex: 1,
      valueGetter: (_value, row) => row.roomType.name,
      display: 'flex',
    },
    {
      field: 'RoomRate',
      headerName: 'Room Rate',
      minWidth: 200,
      flex: 1,
      valueGetter: (_value, row) => row.roomRate.rate_plan.name,
      display: 'flex',
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      minWidth: 150,
      sortComparator: sortByDate,
      valueGetter: (_value, row) => formatDateSlashes(row.roomRate.created_at),
      display: 'flex',
    },
    {
      field: 'Link',
      headerName: 'Link',
      minWidth: 100,
      flex: 0,
      sortable: false,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <Link href={roomRateLink(vendorId, propertyId, params.row.roomType.id, params.row.roomRate.id)} target="_blank">
          Open
        </Link>
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={roomRates}
        columns={columns}
        density="compact"
        pageSizeOptions={[10]}
        sx={{
          minWidth: 650,
          height: roomRates.length >= 10 ? 460 : 310,
          '.MuiDataGrid-row.conflicting-row': {
            bgcolor: 'error.light',
            '&:hover': {
              bgcolor: 'error.main',
            },
          },
          '.MuiDataGrid-menuIcon': {
            width: 'auto',
            visibility: 'visible',
          },
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
            width: 'auto',
            '&:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
              opacity: 1,
            },
          },
        }}
        getRowClassName={(params) =>
          `${conflictingRoomRates.includes(params.row.roomRate.id) ? 'conflicting-row' : ''}`
        }
        checkboxSelection
        onRowSelectionModelChange={onSelectRoomRates}
        rowSelectionModel={selectedRoomRates.map((r) => r.id)}
        slots={{ toolbar: QuickSearchToolbar }}
      />
    </Box>
  );
}
