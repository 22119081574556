import { pluralizeToString } from './stringUtils';

export function formatOccupants(adults?: number, children?: number): string {
  return [pluralizeToString('adult', adults), (children ?? 0) > 0 ? pluralizeToString('child', children ?? 0) : '']
    .filter(Boolean)
    .join(', ');
}

export function formatSeats(childSeats?: number, boosterSeats?: number): string {
  return [
    childSeats ? pluralizeToString('child seat', childSeats) : undefined,
    boosterSeats ? pluralizeToString('booster seat', boosterSeats) : undefined,
  ]
    .filter(Boolean)
    .join(', ');
}

export function formatTransferTravellers(travellers: App.ExperienceDealOptions) {
  const { adults, children, child_seats, booster_seats } = travellers;

  let travellerText = formatOccupants(adults, children);
  if (children && (child_seats || booster_seats)) {
    travellerText += ` (${formatSeats(child_seats, booster_seats)})`;
  }
  return travellerText;
}

export function isAirportTransfer(experienceItem: App.ExperienceItem) {
  const airportTransferTypes = ['AIRPORT-TO-HOTEL', 'HOTEL-TO-AIRPORT'];
  return airportTransferTypes.includes(experienceItem.ticket?.type || '');
}
