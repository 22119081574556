import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';

import { ITEM_TYPE_SUBSCRIPTION } from '~/consts/order';

import { getPurchases } from '~/services/OrdersService';
import { formatDateWithClock } from '~/services/TimeService';

import { customerDataFormatter } from '../formatters';

const columns: GridColDef[] = [
  { field: 'customer_email', headerName: 'Customer', flex: 1, renderCell: customerDataFormatter },
  {
    field: 'purchase_date',
    headerName: 'Purchase date',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => formatDateWithClock(params.row.created_at),
  },
  {
    field: 'details_link',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <Button
            variant="text"
            component={Link}
            to={`/users/${params.row.fk_customer_id}?view_luxplus_membership=true`}
          >
            Details
          </Button>
        </>
      );
    },
  },
];

const getRowId = (order) => {
  return order.id;
};

type Props = {
  filterField: string;
  filterValue: string;
  additionalSearchParams: MultiFilterState;
};

const LuxPlusPage = ({ filterField, filterValue, additionalSearchParams }: Props) => {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalRows, setTotalRows] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const fetchedPurchases = await getPurchases({
        page,
        per_page: perPage,
        item_type: ITEM_TYPE_SUBSCRIPTION,
        brand,
        filterBy: filterField,
        filterValue,
        additionalFilter: additionalSearchParams,
      });
      setRows(fetchedPurchases.result);
      setTotalRows(fetchedPurchases.total);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, filterField, filterValue, additionalSearchParams, perPage]);

  return (
    <Box>
      {error && <ErrorDisplay message={error} />}
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        rowCount={totalRows}
        pagination
        paginationMode="server"
        paginationModel={{ page: page - 1, pageSize: perPage }}
        pageSizeOptions={[perPage]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: perPage,
            },
          },
        }}
        onPaginationModelChange={({ page }) => setPage(page + 1)}
        getRowId={getRowId}
        getRowHeight={() => 'auto'}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </Box>
  );
};

export default LuxPlusPage;
