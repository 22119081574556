import React from 'react';

import { useParams } from 'react-router-dom';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import InclusionItemForm from './components/InclusionItemForm';

type Props = {
  onSave: () => void;
};

function InclusionsItemCreate({ onSave }: Props) {
  const { inclusionId } = useParams<{ inclusionId: string }>();

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>Create Inclusion Item</DialogTitle>
      <DialogContent>
        <InclusionItemForm inclusionId={inclusionId} onSave={onSave} />
      </DialogContent>
    </Dialog>
  );
}

export default InclusionsItemCreate;
