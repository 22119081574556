import { useEffect } from 'react';

import { HttpResponse } from '~/services/types';

import { useAccommodationServiceRequest } from './useAccommodationServiceRequest';

interface UseFetchDataOptions<APIResp> {
  fetchFn: () => Promise<HttpResponse<APIResp>>;
}

export function useAccommodationServiceFetch<APIResp>({ fetchFn }: UseFetchDataOptions<APIResp>) {
  const { reqState, doRequest } = useAccommodationServiceRequest<APIResp>({ apiReq: fetchFn });

  useEffect(() => {
    doRequest();
  }, [doRequest]);

  return { fetchReqState: reqState, refetch: doRequest };
}
