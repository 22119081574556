import React, { useMemo } from 'react';

import { useParams } from 'react-router';

import { Container, Stack, Typography } from '@mui/material';

import useQuery from '~/hooks/useQuery';

import ExistingScheduleForm from '../Components/ExistingScheduleForm';
import NewScheduleForm from '../Components/NewScheduleForm';

function HeroPlannerScheduleEditorPage() {
  const searchParams = useQuery();
  const isDuplicate = searchParams.get('duplicate');
  const { scheduleEditorId } = useParams<{
    scheduleEditorId: string | 'new';
  }>();

  const { scheduleId, editorType } = useMemo(() => {
    if (scheduleEditorId === 'new') {
      return { editorType: 'new' as const, scheduleId: undefined };
    }

    if (isDuplicate) {
      return { editorType: 'duplicate' as const, scheduleId: scheduleEditorId };
    }

    return {
      editorType: 'existing' as const,
      scheduleId: scheduleEditorId,
    };
  }, [scheduleEditorId, isDuplicate]);

  return (
    <Container>
      <Stack direction="column" gap={4}>
        <Typography variant="h3">
          {editorType === 'new' && 'Create new schedule'}
          {editorType === 'duplicate' && 'Create schedule from duplicate'}
          {editorType === 'existing' && 'Update existing schedule'}
        </Typography>
        {(editorType === 'new' || editorType === 'duplicate') && <NewScheduleForm scheduleId={scheduleId} />}
        {editorType === 'existing' && <ExistingScheduleForm scheduleId={scheduleId} />}
      </Stack>
    </Container>
  );
}

export default HeroPlannerScheduleEditorPage;
