import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { Container } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { changeCurrencyCode, emptyCart, setCustomer } from '~/actions/cart';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import UsersService from '~/services/UsersService';

import NewOrderPage from './NewOrderPage';

type MatchParams = { id_user: string; step: string };

type Props = {
  doChangeCurrencyCode: Utils.ActionDispatcher<typeof changeCurrencyCode>;
  doEmptyCart: Utils.ActionDispatcher<typeof emptyCart>;
  doSetCustomer: Utils.ActionDispatcher<typeof setCustomer>;
  orderType: App.OrderType;
};

function NewOrderPageContainer(props: Props) {
  const { orderType = 'offer', doChangeCurrencyCode, doEmptyCart, doSetCustomer } = props;
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [error, setError] = useState<string | null>(null); // Page level error, prevents entire page to work properly

  const { id_user: userId, step } = useParams<MatchParams>();

  const brand = useSelector((state: App.State) => state.tenant.brand);
  const customer = useSelector((state: App.State) => state.cart.customer);

  const isLoading = loadingState == 'loading';

  useEffect(() => {
    doEmptyCart();

    setLoadingState('loading');

    UsersService.getUser(userId)
      .then((user: App.User) => {
        const region = libRegions.getRegionByCode(user.country_code, brand);

        doChangeCurrencyCode(region.currencyCode, region.code);
        doSetCustomer(user);
        setLoadingState('success');
      })
      .catch((err) => {
        setError(err.message);
        setLoadingState('failed');
      });
  }, [brand, userId, doChangeCurrencyCode, doEmptyCart, doSetCustomer]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Container maxWidth="xl">
        <ErrorDisplay message={error} />
      </Container>
    );
  }

  return <NewOrderPage user={customer} onEmptyCart={doEmptyCart} orderType={orderType} step={step} />;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doEmptyCart: emptyCart,
      doSetCustomer: setCustomer,
      doChangeCurrencyCode: changeCurrencyCode,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(NewOrderPageContainer);
