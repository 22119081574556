import React from 'react';

const whitelistedOrigins = ['https://cloud.em.luxuryescapes.com', 'https://cloud.em.scoopon.com.au'];

export default class HeroSchedules extends React.Component {
  componentDidMount() {
    let frame = document.getElementById('hero-planner-iframe');
    frame.onload = function () {
      frame.contentWindow.postMessage('dimensions', '*');
    };
    window.addEventListener('message', function (event) {
      if (whitelistedOrigins.includes(event.origin)) {
        const dimensions = JSON.parse(event.data);
        frame.height = parseInt(dimensions.height) + 20;
        frame.width = parseInt(dimensions.width) + 20;
      }
    });
  }

  componentWillUnmount() {
    let frame = document.getElementById('hero-planner-iframe');
    window.removeEventListener('message', function (event) {
      if (whitelistedOrigins.includes(event.origin)) {
        const dimensions = JSON.parse(event.data);
        frame.height = parseInt(dimensions.height) + 20;
        frame.width = parseInt(dimensions.width) + 20;
      }
    });
  }

  render() {
    return (
      <iframe
        className="salesforce-hero-planner-iframe"
        id="hero-planner-iframe"
        src="https://cloud.em.luxuryescapes.com/le-hero?ro=1"
      />
    );
  }
}
