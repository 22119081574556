import { getDayJs } from '~/services/TimeService';
import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type ExternalOfferVisibility = { country: string; visibility: { start: string | undefined; end: string | undefined } };

function mapHeroPlannerOfferVisibility(
  external: ExternalOfferVisibility,
): CustomerCommunication.HeroPlannerOfferVisibility {
  return {
    countryId: external.country,
    start: external.visibility.start ? getDayJs(external.visibility.start) : undefined,
    end: external.visibility.end ? getDayJs(external.visibility.end) : undefined,
  };
}

async function requestGetHeroPlannerOfferVisibilities(
  brand: string,
  countryGroup: string,
  country: string,
  offerId: string,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!offerId) throw 'missing offerId';

  const url = makeCustomerCommunicationV1URL(`/offer/${offerId}/schedules/visibility`);
  url.searchParams.set('brand', brand);
  url.searchParams.set('countryGroup', countryGroup);
  url.searchParams.set('country', country);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: { result: Array<ExternalOfferVisibility> }) => {
      return response.result.map(mapHeroPlannerOfferVisibility);
    })
    .catch((error) => {
      throw JSON.stringify(error.errors ?? error, null, 2);
    });
}
export default requestGetHeroPlannerOfferVisibilities;
