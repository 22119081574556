type ErrorArgs = {
  message: string;
  name: string;
  status?: number;
  errors?: unknown;
};

export default class ApiError extends Error {
  status: number;
  errors: unknown;

  constructor(args: ErrorArgs) {
    super(args.message);

    this.name = args.name;
    this.status = args.status;
    this.errors = args.errors;
  }
}
