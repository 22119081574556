import qs from 'qs';

import { json_headers, request } from './common';

const BASE_PATH = `${window.configs.API_HOST}/api/review`;
const REVIEWS_PER_PAGE = 15;

type GetReviewsParams = {
  limit?: number;
  offset?: number;
  experienceId?: string;
  propertyId?: string;
  tourId?: string;
  showHidden?: boolean;
};

type ClearReviewsCacheParams = {
  propertyId?: string;
  tourId?: string;
  propertyProvider?: string;
};

type UpdateReviewsParams = {
  isPubliclyVisible: boolean;
};

interface ReviewResponse {
  message: string;
  createdAt: Date;
}

interface Review {
  id: string;
  rating: number;
  review: string;
  propertyId: string;
  userId: string;
  orderId: string;
  offerId?: string;
  tourId?: string;
  experienceId?: string;
  isPubliclyVisible: boolean;
  userName: string;
  createdAt: Date;
  response?: ReviewResponse;
}

const getReviews = (params: GetReviewsParams) => {
  const queryParams = qs.stringify(params);
  const uri = `${BASE_PATH}?${queryParams}`;

  return request(uri, { method: 'GET' });
};

const updateReviewVisibility = (reviewId: string, reviewUpdate: UpdateReviewsParams) => {
  const jsonData = JSON.stringify(reviewUpdate);
  const uri = `${BASE_PATH}/${reviewId}/changeVisibility`;

  return request(uri, {
    method: 'PATCH',
    headers: json_headers,
    body: jsonData,
  });
};

const updateReviewResponse = (reviewId: string, response: string) => {
  const jsonData = JSON.stringify({ message: response });
  const uri = `${BASE_PATH}/${reviewId}/response`;

  return request(uri, {
    method: 'POST',
    headers: json_headers,
    body: jsonData,
  });
};

const updateReviewWording = (reviewId: string, reviewWording: string) => {
  const uri = `${BASE_PATH}/${reviewId}`;
  const jsonData = JSON.stringify({ review: reviewWording });

  return request(uri, {
    method: 'PATCH',
    headers: json_headers,
    body: jsonData,
  });
};

const deleteReview = (reviewId: string) => {
  const uri = `${BASE_PATH}/${reviewId}`;

  return request(uri, {
    method: 'DELETE',
    headers: json_headers,
    ignoreResponse: true,
  });
};

const clearReviewsCache = (params: ClearReviewsCacheParams) => {
  const queryParams = qs.stringify(params);
  const uri = `${BASE_PATH}/clearCache?${queryParams}`;

  return request(uri, {
    method: 'POST',
    headers: json_headers,
  });
};

const isReviewsHiddenForProperty = (propertyId: string) => {
  const uri = `${BASE_PATH}/visibility/${propertyId}`;

  return request(uri, {
    method: 'GET',
    headers: json_headers,
  });
};

const changeReviewsVisibilityForProperty = (propertyId: string, isHidden: boolean) => {
  const uri = `${BASE_PATH}/visibility/${propertyId}`;
  const jsonData = JSON.stringify({ hideReviews: !isHidden });

  return request(uri, {
    method: 'POST',
    headers: json_headers,
    body: jsonData,
  });
};

export {
  BASE_PATH,
  REVIEWS_PER_PAGE,
  Review,
  getReviews,
  updateReviewVisibility,
  updateReviewResponse,
  updateReviewWording,
  deleteReview,
  clearReviewsCache,
  isReviewsHiddenForProperty,
  changeReviewsVisibilityForProperty,
};
