import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Container, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import QuoteAgentUser from '~/components/Users/Quotes/QuoteAgentUser';
import QuoteCartItems from '~/components/Users/Quotes/QuoteCartItems';
import QuoteEmailSent from '~/components/Users/Quotes/QuoteEmailSent';

import useCurrentBrand from '~/hooks/useCurrentBrand';

import { getQuotes } from '~/services/CartService';
import { formatDateWithClock } from '~/services/TimeService';

import QuoteEmailAddress from './QuoteEmailAddress';

export default function AgentQuotes() {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const brand = useCurrentBrand();

  const columns: Array<GridColDef> = [
    {
      field: 'items',
      headerName: 'Cart items',
      sortable: false,
      flex: 3,
      display: 'flex',
      renderCell: (params) => <QuoteCartItems hideStatus items={params.row.items} />,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      sortable: false,
      flex: 1,
      valueGetter: (_value, row) => formatDateWithClock(row.createdAt),
      display: 'flex',
    },
    {
      field: 'agentId',
      headerName: 'Created by',
      sortable: false,
      flex: 1,
      renderCell: (params) => <QuoteAgentUser userId={params.row.customerId} />,
      display: 'flex',
    },
    {
      field: 'emails',
      headerName: 'Emails',
      sortable: false,
      flex: 1.75,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.multipleCustomerEmailDedupeKey && (
              <QuoteEmailAddress dedupeKey={params.row.multipleCustomerEmailDedupeKey} />
            )}
          </Stack>
        );
      },
      display: 'flex',
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.multipleCustomerEmailDedupeKey && (
              <QuoteEmailSent customerEmailDedupeKey={params.row.multipleCustomerEmailDedupeKey} />
            )}
          </Stack>
        );
      },
      display: 'flex',
    },
  ];

  useEffect(() => {
    setLoading(true);
    getQuotes({ page: currentPage + 1, limit: 10, brand }).then((quotes) => {
      setQuotes(quotes.result);
      setTotalRows(quotes.total);
      setLoading(false);
    });
  }, [currentPage, brand]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Agent Quotes</title>
      </Helmet>
      <PageSubheader title="Agent Quotes" />
      <DataGrid
        columns={columns}
        rows={quotes}
        pagination
        rowCount={totalRows}
        paginationModel={{ page: currentPage, pageSize: 10 }}
        paginationMode="server"
        onPaginationModelChange={({ page }) => setCurrentPage(page)}
        getRowHeight={() => 'auto'}
        sx={{ '.MuiDataGrid-cell': { paddingBlock: 2 } }}
        slots={{ pagination: GridPagination }}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        loading={loading}
        disableRowSelectionOnClick
        autoHeight
      />
    </Container>
  );
}
