import React from 'react';

import { Box, Stack } from '@mui/material';

import PartialCalculatedRefundVariant from '~/components/Refund/PartialCalculatedRefundVariant';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import {
  VARIANT_OF_REFUND_ALL,
  VARIANT_OF_REFUND_PARTIAL,
  VARIANT_OF_REFUND_PARTIAL_CALCULATED,
  VARIANT_OF_REFUND_SURCHARGE,
} from '../../consts/refund';

import FullRefundVariant from './FullRefundVariant';
import PartialRefundVariant from './PartialRefundVariant';
import SurchargeRefundVariant from './SurchargeRefundVariant';

export default class VariantsContainer extends React.Component {
  hasCalculatedRefund() {
    const { channel_manager } = this.props.refund;
    if (channel_manager === PROPERTY_CHANNEL_MANAGERS.rentalsunited) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Box id="VariantsContainer">
        <Stack direction="row" spacing={2}>
          <Box>
            <input
              type="radio"
              key={VARIANT_OF_REFUND_ALL}
              name="variant_of_refund"
              value={VARIANT_OF_REFUND_ALL}
              onChange={this.props.handleVariantChange}
              checked={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_ALL}
            />
            <label>Full package refund</label>
          </Box>
          <Box>
            <input
              type="radio"
              key={VARIANT_OF_REFUND_PARTIAL}
              name="variant_of_refund"
              value={VARIANT_OF_REFUND_PARTIAL}
              onChange={this.props.handleVariantChange}
              checked={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL}
            />
            <label>Partial refund</label>
          </Box>
          {this.hasCalculatedRefund() && (
            <Box>
              <input
                type="radio"
                key={VARIANT_OF_REFUND_PARTIAL_CALCULATED}
                name="variant_of_refund"
                value={VARIANT_OF_REFUND_PARTIAL_CALCULATED}
                onChange={this.props.handleVariantChange}
                checked={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL_CALCULATED}
              />
              <label>Calculated Partial Refund</label>
            </Box>
          )}
          {this.props.refund.has_surcharge && (
            <Box>
              <input
                type="radio"
                key={VARIANT_OF_REFUND_SURCHARGE}
                name="variant_of_refund"
                value={VARIANT_OF_REFUND_SURCHARGE}
                onChange={this.props.handleVariantChange}
                checked={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_SURCHARGE}
              />
              <label>Surcharge only</label>
            </Box>
          )}
        </Stack>
        {this.props.refund.variant_of_refund == VARIANT_OF_REFUND_ALL && (
          <FullRefundVariant
            handleFlagChange={this.props.handleFlagChange}
            surchargeInfo={this.props.surchargeInfo}
            handleTmpChange={this.props.handleTmpChange}
            tmpValue={this.props.tmpValue}
            isActive={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_ALL}
            refund={this.props.refund}
            with_fee={false}
          />
        )}
        {this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL && (
          <PartialRefundVariant
            itemRefund={this.props.itemRefund}
            itemRefundOriginal={this.props.itemRefundOriginal}
            handleTmpChange={this.props.handleTmpChange}
            handleFlagChange={this.props.handleFlagChange}
            tmpValue={this.props.tmpValue}
            isActive={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL}
            refund={this.props.refund}
          />
        )}
        {this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL_CALCULATED && (
          <PartialCalculatedRefundVariant
            itemRefund={this.props.itemRefund}
            itemRefundOriginal={this.props.itemRefundOriginal}
            handleTmpChange={this.props.handleTmpChange}
            handleFlagChange={this.props.handleFlagChange}
            tmpValue={this.props.tmpValue}
            isActive={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_PARTIAL_CALCULATED}
            refund={this.props.refund}
          />
        )}
        {this.props.refund.variant_of_refund == VARIANT_OF_REFUND_SURCHARGE && (
          <SurchargeRefundVariant
            surchargeInfo={this.props.surchargeInfo}
            isActive={this.props.refund.variant_of_refund == VARIANT_OF_REFUND_SURCHARGE}
            refund={this.props.refund}
          />
        )}
      </Box>
    );
  }
}
