import { Refund, RefundAttributes } from '../NewModal';

export default function calculateAccountingAmount(
  refundAttributes: RefundAttributes,
  refundMetadata: Refund,
  userInput?: { amount: number; fee: number },
) {
  let cashAmount = userInput ? userInput.amount : refundAttributes.cash_amount_original;
  if (cashAmount === 0) {
    return refundAttributes.accounting_amount;
  }

  // When we provide full refund without cancelling the reservation, we need to deduct the fee from accounting amount
  if (
    userInput?.fee > 0 &&
    refundMetadata.variant_of_refund === 'all' &&
    refundMetadata.mark_cancelled === false &&
    cashAmount > userInput.fee
  ) {
    cashAmount = cashAmount - userInput.fee;
  }

  const promoPiece = refundAttributes.promo_percentage * (refundAttributes.initial_amount / cashAmount);
  let finalPromoAmount = 0;
  if (refundAttributes.include_promo === true) {
    finalPromoAmount = parseFloat(`${promoPiece * cashAmount}`);
  }

  return Number((refundAttributes.initial_amount / (refundAttributes.initial_amount - finalPromoAmount)) * cashAmount);
}
