import React from 'react';

import { CategoriesState } from '~/reducers/experiences';

import { Alert, Box } from '@mui/material';

import { Field, FlexWrapper, GridWrapper, Spinner, Tag, Text } from '~/components/Experiences/components';

import { FilerKeys, PROVIDER_OPTIONS, StatusOptions, statusOptions, themes } from '~/consts/experiences';

import { CategoryItem, CurationStatus, Filters } from '~/services/ExperiencesService';

import { FieldValueParams } from '../Experiences';

import { Body } from './styles';

type FilterContentProps = {
  filters: Filters;
  categories: CategoriesState;
  handleFieldValue: (params: FieldValueParams) => void;
  setFiltersByKey: (key: FilerKeys, value: string) => void;
};

export function ExperienceFilterContent(props: FilterContentProps) {
  const { filters, categories, setFiltersByKey, handleFieldValue } = props;

  const handleStatus = (value: string) => setFiltersByKey('status', value);
  const handleCategories = (value: string) => setFiltersByKey('categories', value);
  const handleProviders = (value: string) => setFiltersByKey('providerIn', value);

  const hasFieldSelected = (value: CurationStatus | string, type: string) => {
    if (type === 'status') {
      return filters.status?.includes(value);
    }
    return {
      categories: filters.categories?.includes(value),
      provider: filters.providerIn?.includes(value),
    }[type];
  };

  const setFieldPriceValue = ({ value, range }) => {
    handleFieldValue({ key: 'price', fieldValue: value, range });
  };

  const setFieldRatingValue = ({ value, range }) => {
    handleFieldValue({ key: 'rating', fieldValue: value, range });
  };

  const hasNoCategories = !categories.error && !categories.loading && categories.items?.length === 0;

  return (
    <FlexWrapper direction="column">
      <FlexWrapper justify="flex-start" my={'15px'}>
        <FlexWrapper justify="flex-start" mb="10px">
          <Text size={'18px'} color={themes.secondaryGrey}>
            STATUS
          </Text>
        </FlexWrapper>
        <FlexWrapper justify="flex-start">
          {statusOptions?.map((status: StatusOptions) => (
            <Tag
              mr={'18px'}
              key={status.value}
              minWidth={'125px'}
              name={status?.name}
              value={status?.value}
              onClick={handleStatus}
              outline={!hasFieldSelected(status?.value, 'status')}
              selectable={hasFieldSelected(status?.value, 'status')}
            />
          ))}
        </FlexWrapper>
      </FlexWrapper>

      <Box>
        <FlexWrapper justify="flex-start" mb="10px">
          <Text size={'18px'} color={themes.secondaryGrey}>
            PROVIDERS
          </Text>
        </FlexWrapper>
        <FlexWrapper justify="flex-start">
          {PROVIDER_OPTIONS.map(({ name, value }) => (
            <Tag
              mr={'18px'}
              key={value}
              minWidth={'125px'}
              name={name}
              value={value}
              onClick={handleProviders}
              outline={!hasFieldSelected(value, 'provider')}
              selectable={hasFieldSelected(value, 'provider')}
            />
          ))}
        </FlexWrapper>
      </Box>

      <Box>
        <FlexWrapper justify="flex-start" mt="10px">
          <Text size={'18px'} color={themes.secondaryGrey}>
            CATEGORIES
          </Text>
        </FlexWrapper>

        <FlexWrapper justify="flex-start" mb="10px">
          {categories.loading && (
            <FlexWrapper justify="center">
              <Spinner size={25} />
            </FlexWrapper>
          )}

          {categories?.error && <Alert severity="error">ERROR ON FETCH CATEGORIES</Alert>}

          {hasNoCategories && <Alert severity="warning">NOT FOUND CATEGORIES</Alert>}
        </FlexWrapper>

        {categories.items?.length > 0 && (
          <Body>
            <GridWrapper mb={'15px'} rowGap={'15px'} columnGap={'10px'} columns="1fr 1fr 1fr" justify={'flex-start'}>
              {categories.items
                ?.filter((item: CategoryItem) => item.level === 1)
                .map((category, i) => (
                  <Tag
                    minWidth={'125px'}
                    maxWidth={'125px'}
                    name={category?.label}
                    value={category?.value}
                    key={`${i}-${category.value}`}
                    onClick={handleCategories}
                    outline={!hasFieldSelected(category?.value, 'categories')}
                    selectable={hasFieldSelected(category?.value, 'categories')}
                  />
                ))}
            </GridWrapper>
          </Body>
        )}
      </Box>

      <FlexWrapper justify="flex-start" my="10px">
        <Box>
          <Text size={'18px'} color={themes.secondaryGrey}>
            RATING
          </Text>
        </Box>
      </FlexWrapper>
      <FlexWrapper mb="15px">
        <Box flexGrow={1}>
          <Field
            type="number"
            rangeName="min"
            autoComplete="off"
            placeholder="Rating min"
            name="experiences-rating-min"
            onChange={setFieldRatingValue}
            value={filters.rating?.min || ''}
            inputProps={{ min: filters.rating?.min ?? 0, max: filters.rating?.max ?? 5 }}
            fullWidth
          />
        </Box>
        <Box flexGrow={1}>
          <Field
            type="number"
            rangeName="max"
            autoComplete="off"
            placeholder="Rating max"
            name="experiences-rating-max"
            onChange={setFieldRatingValue}
            value={filters.rating?.max || ''}
            inputProps={{ min: filters.rating?.min ?? 0, max: filters.rating?.max ?? 5 }}
            fullWidth
          />
        </Box>
      </FlexWrapper>

      <FlexWrapper justify="flex-start" my="10px">
        <Box>
          <Text size={'18px'} color={themes.secondaryGrey}>
            SALES PRICE
          </Text>
        </Box>
      </FlexWrapper>
      <FlexWrapper mb="15px">
        <Box flexGrow={1}>
          <Field
            inputProps={{ min: 0 }}
            mr={'5px'}
            type="number"
            rangeName="min"
            autoComplete="off"
            placeholder="Sales Price min"
            name="experiences-sales-price-min"
            onChange={setFieldPriceValue}
            value={filters.price?.min || ''}
          />
        </Box>
        <Box flexGrow={1}>
          <Field
            type="number"
            rangeName="max"
            autoComplete="off"
            placeholder="Sales Price max"
            name="experiences-sales-price-max"
            onChange={setFieldPriceValue}
            value={filters.price?.max || ''}
            inputProps={{ min: filters.price?.min || 0 }}
          />
        </Box>
      </FlexWrapper>

      <FlexWrapper justify="flex-start" my="10px">
        <Box>
          <Text size={'18px'} color={themes.disabled}>
            MERCHANT PRICE
          </Text>
        </Box>
      </FlexWrapper>
      <FlexWrapper mb="15px">
        <Box flexGrow={1}>
          <Field
            disabled
            mr={'5px'}
            value={''}
            type="number"
            rangeName="min"
            autoComplete="off"
            onChange={setFieldPriceValue}
            placeholder="Merchant Price min"
            name="experiences-merchant-price-min"
          />
        </Box>
        <Box flexGrow={1}>
          <Field
            disabled
            value={''}
            type="number"
            rangeName="max"
            autoComplete="off"
            onChange={setFieldPriceValue}
            placeholder="Merchant Price max"
            name="experiences-merchant-price-max"
          />
        </Box>
      </FlexWrapper>
    </FlexWrapper>
  );
}
