import React, { useState } from 'react';

import { Box, Button, ButtonProps, IconButton, Popover, Stack, Typography } from '@mui/material';

type Props = ButtonProps & {
  confirmTitle?: string;
  confirmQuestion?: string;
  confirmAnswer?: string;
  asIcon?: boolean;
  onConfirm: () => void | Promise<void>;
  position?: 'bottom' | 'top';
};

export default function ConfirmButton(props: Props) {
  const {
    children,
    onConfirm,
    confirmTitle = 'Confirm',
    confirmQuestion = 'Are you sure?',
    confirmAnswer = 'Yes',
    asIcon,
    position = 'bottom',
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isOpen = Boolean(anchorEl);

  const TriggerButton: React.JSXElementConstructor<ButtonProps> = asIcon ? IconButton : Button;

  const handleConfirm = () => {
    setAnchorEl(null);
    onConfirm();
  };

  return (
    <>
      <TriggerButton {...rest} onClick={(e) => setAnchorEl(e.currentTarget)}>
        {children}
      </TriggerButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: position,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: position === 'top' ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Box p={2}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h6">{confirmTitle}</Typography>

            <Typography>{confirmQuestion}</Typography>

            <div>
              <Button onClick={handleConfirm} size="small" variant="contained">
                {confirmAnswer}
              </Button>
            </div>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
