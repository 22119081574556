import qs from 'qs';

import { request } from './common';

export default class LedOffersService {
  static basePath() {
    return window.configs.API_HOST + '/api/ee/offers';
  }

  static getFilterOffers({ page = 1, limit = 15, queryString, filter }) {
    let queryParams;

    if (filter) {
      queryParams = {
        page,
        limit,
        filter,
      };
    } else {
      queryParams = {
        page,
        limit,
      };
    }

    return request(`${this.basePath()}?${qs.stringify(queryParams)}&${queryString}`, {
      method: 'GET',
    });
  }

  static getOffer(id, options?) {
    let params = '';
    if (options) {
      params = Object.entries(options)
        .map(([key, val]: [string, string]) => `${key}=${encodeURIComponent(val)}`)
        .join('&');
    }
    const path = params ? `/${id}?${params}` : `/${id}`;
    return request(this.basePath() + path, { method: 'GET' });
  }
}
