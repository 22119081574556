import React from 'react';

import Form from 'react-jsonschema-form';
import { withRouter } from 'react-router-dom';

import { Button, Stack } from '@mui/material';

import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

import nullsToEmptyStrings from '~/utils/nullsToEmptyStrings';
import { reportError } from '~/utils/reportError';

import { DeleteButton } from '../DeleteButton';
import ErrorDisplay from '../ErrorDisplay';
import PropertyMap from '../PropertyMap';

import ImageUploadField from './ImageUploadField';
import { buttonMessages, buttonStates } from './states/submitButton';
import MarkdownEditor from './widgets/MarkdownEditor';

let uiSchema = {
  id_salesforce_external: { 'ui:widget': 'hidden' },
  itinerary: { 'ui:widget': MarkdownEditor },
  logo_id: {
    'ui:title': 'Tour logo',
    'ui:widget': (props) => (
      <ImageUploadField
        label="logo"
        field_key={props.id}
        value={props.value}
        onUpload={(field_key, newValue) => {
          props.onChange(newValue);
        }}
      />
    ),
  },
  geo_data: {
    classNames: 'row',
    country: {
      classNames: 'col-sm-6 col-md-3',
    },
    country_code: {
      classNames: 'col-sm-6 col-md-3',
    },
    administrative_area_level_1: {
      classNames: 'col-sm-6 col-md-3',
    },
    administrative_area_level_2: {
      classNames: 'col-sm-6 col-md-3',
    },
    administrative_area_level_3: {
      classNames: 'col-sm-6 col-md-3',
    },
    administrative_area_level_4: {
      classNames: 'col-sm-6 col-md-3',
    },
    administrative_area_level_5: {
      classNames: 'col-sm-6 col-md-3',
    },
    locality: {
      classNames: 'col-sm-6 col-md-3',
    },
    route: {
      classNames: 'col-sm-6 col-md-3',
    },
    street_number: {
      classNames: 'col-sm-6 col-md-3',
    },
    place_id: {
      classNames: 'col-sm-6 col-md-3',
    },
  },
};

const errorMessage = `This tour is currently attached to an offer. You must
  detach it from any offers before you can delete.`;

class TourForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tour: nullsToEmptyStrings(props.tour),
      saveState: buttonStates.default,
      deleteState: 'delete',
    };

    this.vendorId = props.vendorId;
    this.schema = props.schema;
  }

  onSubmit = (form) => {
    const { history } = this.props;

    form.errors = [];
    form.errorSchema = {};
    var self = this;

    this.setState({
      saveState: buttonStates.saving,
      tour: form.formData,
    });

    let submitType = 'createTour';
    let id = null;
    if (this.state.tour.id) {
      submitType = 'updateTour';
      id = this.state.tour.id;
    }

    ReservationService[submitType](form.formData, id)
      .then((response) => {
        history.push(`/vendors/${this.vendorId}/tours/${response.id}`);
      })
      .catch(function (e) {
        self.setState({ saveState: buttonStates.failed });
        reportError(e);
      });
  };

  onChange = (edit) => {
    const newState = {
      tour: edit.formData,
    };

    if (this.state.saveState !== buttonStates.default) {
      newState.saveState = buttonStates.default;
    }

    this.setState(newState);
  };

  onDelete = async () => {
    this.setState({ deleteState: 'deleting' });

    const tourIsUsed = await this.findUsage();

    if (tourIsUsed) {
      this.setState({ deleteState: 'failed' });
      return false;
    }
    var self = this;

    const { history } = this.props;

    ReservationService.deleteTour(self.state.tour.id)
      .then(function () {
        history.push(`/vendors/${self.vendorId}`);
      })
      .catch(function (e) {
        if (e.status === 400 && e.errors) {
          alert(e.errors[0].message);
        }
        reportError(e);
      });
  };

  onMapClick = (latitude, longitude) => {
    // TODO: save the place ID (future enhancement)

    const edit = {
      formData: Object.assign({}, this.state.tour, {
        latitude,
        longitude,
      }),
    };
    this.onChange(edit);
  };

  onReverseGeocode = (result) => {
    const edit = {
      formData: Object.assign({}, this.state.tour, {
        geo_data: result,
      }),
    };
    this.onChange(edit);
  };

  findUsage = async () => {
    const offers = await this.fetchData();

    const typeOffers = offers.filter((offer) => {
      return offer.packages.some((p) => p.fk_tour_id == this.state.tour.id);
    });

    return typeOffers.length;
  };

  async fetchData() {
    const { result: offers } = await OffersService.getOffers({
      queryString: this.vendorId,
    }).catch(function (e) {
      reportError(e);
    });

    return offers;
  }

  render() {
    return (
      <div>
        <Form
          schema={this.schema}
          onChange={this.onChange}
          formData={this.state.tour}
          onSubmit={this.onSubmit}
          uiSchema={uiSchema}
        >
          <label>Select location</label>
          <PropertyMap
            lat={this.state.tour.latitude}
            lng={this.state.tour.longitude}
            onMapClick={this.onMapClick}
            reverseGeocode={true}
            onReverseGeocode={this.onReverseGeocode}
          />
          <div className="button-container">
            <Stack direction="row" spacing={1}>
              <Button type="submit" variant="contained" className={this.state.saveState}>
                {buttonMessages[this.state.saveState]}
              </Button>
              {this.state.tour.id ? <DeleteButton onClick={this.onDelete} buttonText={this.state.deleteState} /> : null}
            </Stack>
          </div>

          {this.state.deleteState == 'failed' ? <ErrorDisplay message={errorMessage} /> : null}
        </Form>
      </div>
    );
  }
}

export default withRouter(TourForm);
