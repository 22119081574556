import React, { useEffect, useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import PropertyUploadModal from '~/components/Vendors/Properties/PropertyUploadModal';

interface Props {
  vendorId: string;
  properties: App.Property[];
  offers: Array<any>;
}
export default function PropertiesPage(props: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const { offers } = props;
  const [properties, setProperties] = useState(props.properties);
  const [formattedProperties, setFormattedProperties] = useState([]);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    const properties = props.properties.map(({ id, hotel_code, name, deleted_at }) => ({
      name: deleted_at ? `${name} (DELETED)` : name,
      id,
      hotel_code,
      isDeleted: !!deleted_at,
    }));
    setProperties(properties);
    setFormattedProperties(properties.filter((property) => !property.isDeleted));
  }, [props.properties, vendorId]);

  const editBtnFormatter = (id) => {
    return <Button href={`/vendors/${vendorId}/properties/${id}`}>Edit</Button>;
  };

  const updateFormattedProperties = (prop) => {
    setChecked(!checked);
    if (checked) {
      return setFormattedProperties(prop);
    }
    setFormattedProperties(prop.filter((property) => !property.isDeleted));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'hotel_code',
      headerName: 'Hotel Code',
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 70,
      flex: 0,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => {
        return editBtnFormatter(params.row?.id);
      },
    },
  ];

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={checked} onClick={() => updateFormattedProperties(properties)} />}
          label="Hide deleted properties"
        />
      </FormGroup>

      <Box mt={2}>
        <DataGrid
          className="T-properties-page-table"
          rows={formattedProperties || []}
          columns={columns}
          getRowId={(row) => row.id}
          getRowHeight={() => 'auto'}
          slots={{ pagination: null }}
          autoHeight
          disableRowSelectionOnClick
        />
      </Box>

      <Stack direction="row" spacing={2}>
        <Button component={Link} variant="contained" to={`/vendors/${vendorId}/properties`}>
          + New
        </Button>
        <PropertyUploadModal vendorId={vendorId} offers={offers} />
      </Stack>
    </div>
  );
}
