import React from 'react';

import { InputLabel, Stack, TextField } from '@mui/material';

interface Props {
  vendorContribution: number;
  LEContribution: number;
}

export default function ShareOfRefund({ vendorContribution, LEContribution }: Props) {
  return (
    <Stack direction="row" spacing={2}>
      <Stack>
        <InputLabel
          htmlFor="vendor-contributions-input"
          sx={{
            fontWeight: 'bold',
          }}
        >
          Vendor Contribution
        </InputLabel>
        <TextField
          id="vendor-contributions-input"
          name="vendorContribution"
          value={vendorContribution}
          disabled
          hiddenLabel
          type="number"
          size="small"
          variant="filled"
        />
      </Stack>
      <Stack>
        <InputLabel
          htmlFor="le-contributions-input"
          sx={{
            fontWeight: 'bold',
          }}
        >
          LE Contribution
        </InputLabel>
        <TextField
          id="le-contributions-input"
          value={LEContribution}
          name="LEContributions"
          type="number"
          size="small"
          variant="filled"
          hiddenLabel
          disabled
        />
      </Stack>
    </Stack>
  );
}
