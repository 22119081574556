import React from 'react';

import { CruiseLineProvider } from '~/components/Cruises/context/useCruiseLines';

import { CruiseLineList } from './components/CruiseLineList';

const ShipsPage: React.FC = (): JSX.Element => (
  <CruiseLineProvider>
    <CruiseLineList />
  </CruiseLineProvider>
);

export default ShipsPage;
