import React from 'react';

import OfferLocationHeading from '../Booking/OfferLocationHeading';

import OrderSummaryItem from './OrderSummaryItem';

const OrderSummaryList = ({
  items,
  offer,
  offerNumber,
  currencyCode,
  bookDatesOnly,
  isAdmin,
  doUpdateReservation,
  stateOfResidence,
}) => {
  if (!offer || items.length === 0) {
    return null;
  }

  return (
    <div className="order-summary-panel">
      <div className="offer-meta">
        <div className="offer-id data-hj-whitelist">
          <OfferLocationHeading offer={offer} offerNumber={offerNumber} />
          {offer.id_salesforce_external}
        </div>
      </div>

      <div className="summary-item-list">
        {items.map((item, idx) => {
          return (
            <OrderSummaryItem
              stateOfResidence={stateOfResidence}
              itemIdx={idx}
              item={item}
              currencyCode={currencyCode}
              key={item.id}
              bookingType={offer.booking_type}
              offerType={offer.type}
              surchargePaidDirectToVendor={offer.surcharge_paid_direct_to_vendor}
              bookDatesOnly={bookDatesOnly}
              isAdmin={isAdmin}
              doUpdateReservation={doUpdateReservation}
              offer={offer}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OrderSummaryList;
