import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material';

interface TravellerDetailRequirements {
  covid_vaccination_status_required: boolean;
  weight_required: boolean;
  flight_details_required: boolean;
  id_level_required: string;
}

interface Props {
  offerId: string;
  travellerDetailRequirements: TravellerDetailRequirements | null;
}

const convertSnakeCaseTextIntoPresentableForm = (text: string) => text.replace(/_/g, ' ');

const extractRequirements = (requirementsObject: TravellerDetailRequirements) => {
  if (requirementsObject === null) {
    return null;
  }

  const requirements = Object.keys(requirementsObject).map(
    (requirement) =>
      `${convertSnakeCaseTextIntoPresentableForm(requirement)}: ${convertSnakeCaseTextIntoPresentableForm(
        String(requirementsObject[requirement]),
      )}`,
  );

  return requirements;
};

const TravellerDetailRequirementsPane = (props: Props) => {
  const { offerId, travellerDetailRequirements } = props;
  const requirements = extractRequirements(travellerDetailRequirements);

  return (
    <span>
      <h1 className="page-header">
        Traveller Detail Requirements
        <Link
          to={{
            pathname: '/edit-offers/' + offerId + '/traveller-detail-requirements',
          }}
        >
          <ModeEditTwoToneIcon fontSize="large" />
          <span className="sr-only">Edit</span>
        </Link>
      </h1>
      {requirements === null && <span>Missing traveller detail requirements</span>}
      {requirements !== null && (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {requirements.map((requirement, index) => (
            <div key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography
                      sx={{ textTransform: 'uppercase', display: 'inline', fontSize: 18, fontWeight: 300 }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {requirement}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </div>
          ))}
        </List>
      )}
    </span>
  );
};

export default TravellerDetailRequirementsPane;
