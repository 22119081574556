import { Tour as TourResponse } from '@luxuryescapes/contract-svc-tour';

export function getTourResultSellingRegions(tour: TourResponse.Tour) {
  if (tour.scheduleState?.relevantRegions?.length) {
    return Array.from(new Set(tour.scheduleState.relevantRegions)).sort();
  }

  if (!tour.tourOptions.length) return 'no tour options';

  const allSeasons = tour.tourOptions.flatMap((tourOption) => tourOption.seasons);
  if (!allSeasons.length) return 'no seasons';

  const allDepartures = allSeasons.flatMap((season) => season.departures);
  if (!allDepartures.length) return 'no departures';
  if (!allDepartures.some((d) => d.availability.status === 'available')) return 'no available departures';

  const allSellingRegions = allDepartures.flatMap((departure) => departure.sellingRegion);
  return Array.from(new Set(allSellingRegions)).sort();
}

export function getTourResultVisitedCountries(tour: TourResponse.Tour) {
  if (!tour.tourOptions.length) return 'no tour options';

  const allSeasons = tour.tourOptions.flatMap((tourOption) => tourOption.seasons);
  if (!allSeasons.length) return 'no seasons';

  return Array.from(new Set(allSeasons.flatMap((season) => season.countriesVisited)));
}
