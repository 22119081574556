import React from 'react';

import classNames from 'clsx';
import PropTypes from 'prop-types';

import { formatDateCalendar, formatDateCalendarTitle } from '~/services/TimeService';

// Calendar is a presentational component that renders calendar layout with
// custom behaviors
class Calendar extends React.Component {
  constructor() {
    super();

    this.days = [];
  }

  refFunc = (a) => {
    this.days.push(a);
  };

  render() {
    const self = this;

    const { grid, calendarDayComponent } = this.props;

    const CalendarDay = calendarDayComponent;

    return (
      <div className="calendar">
        <div className="inline-group">
          {grid.map(function (month, monthIdx) {
            const monthClasses = classNames('month', `month-${formatDateCalendar(month.date)}`);
            return (
              <div className={monthClasses} key={`month-${monthIdx}`}>
                <div className="month-name data-hj-whitelist">{formatDateCalendarTitle(month.date)}</div>
                <div>
                  <div className="week">
                    <div className="day-name">Su</div>
                    <div className="day-name">Mo</div>
                    <div className="day-name">Tu</div>
                    <div className="day-name">We</div>
                    <div className="day-name">Th</div>
                    <div className="day-name">Fr</div>
                    <div className="day-name">Sa</div>
                  </div>
                  {month.weeks.map(function (week, weekIdx) {
                    return (
                      <div className="week names" key={`week-${weekIdx}`}>
                        {week.map(function (dayDate, idx) {
                          return (
                            <div className="day-container" key={idx}>
                              <CalendarDay dayDate={dayDate} idx={idx} ref={dayDate.day ? self.refFunc : undefined} />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  // In this case the "func" must be a react component, but there's no proptype for that
  calendarDayComponent: PropTypes.func.isRequired,
  grid: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Calendar;
