import React, { Component } from 'react';

import dayjs from '~/timeInit';

import { Button } from '@mui/material';

import OffersService from '../../../services/OffersService';
import { reportError } from '../../../utils/reportError';

import DateTimeWidget from './DateTimeWidget';
import { buttonMessages, buttonStates } from './states/submitButton';

class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    dayjs.tz.setDefault(window.configs.DEFAULT_TIMEZONE);
    this.state = {
      schedule: this.props.schedule,
      saveState: buttonStates.default,
    };
  }

  onSubmit() {
    if (this.state.saveState === buttonStates.saving) {
      return;
    }

    this.setState({
      saveState: buttonStates.saving,
    });

    const payload = {
      type: this.state.schedule.type,
      region: this.state.schedule.region,
      start: this.state.schedule.start,
      end: this.state.schedule.end,
      brand: this.props.brand,
    };

    this.props.onSave(this.state.schedule);

    if (this.state.schedule.id) {
      OffersService.updateSchedule(payload, this.props.offerId, this.state.schedule.id)
        .then(() => {
          this.setState({
            saveState: buttonStates.saved,
          });
          this.props.onSuccess(this.state.schedule);
        })
        .catch((e) => {
          this.setState({
            saveState: buttonStates.failed,
          });
          this.props.onError(e);
          reportError(e);
        });
    } else {
      payload.brand = this.props.brand;
      OffersService.createSchedule(payload, this.props.offerId)
        .then((newSchedule) => {
          this.setState({
            schedule: newSchedule,
            saveState: buttonStates.saved,
          });
          this.props.onSuccess(this.state.schedule);
        })
        .catch((e) => {
          this.setState({
            saveState: buttonStates.failed,
          });
          this.props.onError(e);
          reportError(e);
        });
    }
  }

  onRemove() {
    const confirmed = confirm('Are you sure you want to delete this schedule?');

    if (!confirmed) {
      return;
    }

    if (!this.state.schedule.id) {
      this.setState({
        schedule: null,
        saveState: buttonStates.default,
      });
      return;
    }

    OffersService.deleteSchedule(this.props.offerId, this.state.schedule.id)
      .then(() => {
        this.setState({
          schedule: null,
          saveState: buttonStates.default,
        });
      })
      .catch((e) => {
        this.setState({
          saveState: buttonStates.failed,
        });
        this.props.onError(e);
        reportError(e);
      });
  }

  buttonLabel() {
    if (this.state.saveState === 'default') {
      return this.state.schedule.id ? 'Save' : 'Create';
    }
    return buttonMessages[this.state.saveState];
  }

  render() {
    return (
      <div className="ScheduleForm">
        {this.state.schedule ? (
          <div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="control-label">Start date</label>
                  <DateTimeWidget
                    disabled={this.props.disabled}
                    value={this.state.schedule.start}
                    onChange={this.updateStart.bind(this)}
                  />
                </div>
              </div>
              <div className="col-sm-1" style={{ textAlign: 'center', fontSize: '2em' }}>
                &#8680;
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="control-label">End date</label>
                  <DateTimeWidget
                    disabled={this.props.disabled}
                    value={this.state.schedule.end}
                    onChange={this.updateEnd.bind(this)}
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div style={{ marginTop: 25 }}>
                  <Button
                    variant="contained"
                    disabled={this.props.disabled}
                    type="button"
                    className={this.state.saveState}
                    onClick={this.onSubmit.bind(this)}
                  >
                    {this.buttonLabel()}
                  </Button>
                  <Button
                    disabled={this.props.disabled}
                    variant="contained"
                    color="error"
                    type="button"
                    style={{ marginLeft: 10 }}
                    onClick={this.onRemove.bind(this)}
                  >
                    {this.state.schedule.id ? 'Delete' : 'Cancel'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Button variant="contained" onClick={this.createSchedule.bind(this)}>
            Create schedule
          </Button>
        )}
      </div>
    );
  }

  updateStart(time) {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        start: time,
      },
    }));
  }

  updateEnd(time) {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        end: time,
      },
    }));
  }

  createSchedule() {
    this.setState({
      schedule: ScheduleForm.newSchedule(this.props.offerId, this.props.type, this.props.region),
    });
  }

  static newSchedule(offerId, type, region) {
    return {
      id: null,
      offer_id_salesforce_external: offerId,
      type: type,
      region: region,
      start: dayjs().format(),
      end: dayjs().add(1, 'days').format(),
    };
  }
}

export default ScheduleForm;
