import { pluralizeToString, titleCase } from './stringUtils';

export function countTravellers(travellers: Array<App.OrderTourTraveller>) {
  const travellersCount = {};

  for (let i = 0; i < travellers.length; ++i) {
    travellersCount[travellers[i].type] = (travellersCount[travellers[i].type] || 0) + 1;
  }

  return travellersCount;
}

export function formatTravellers(travellers: Array<App.OrderTourTraveller>, isTitleCase = false) {
  const travellersCount = countTravellers(travellers);

  const travellerTypes = Object.keys(travellersCount);

  const travellerTypesStr = travellerTypes.map((type) =>
    isTitleCase
      ? titleCase(pluralizeToString(type, travellersCount[type]) || '')
      : pluralizeToString(type, travellersCount[type]) || '',
  );

  return travellerTypesStr.join(', ');
}

export function formatTourV2SnapshotRoom(room: App.OrderTourItemRooms) {
  const { adultCount, childCount } = room;
  return `${adultCount ? pluralizeToString('adult', adultCount) : ''}${
    childCount ? `, ${pluralizeToString('child', childCount)} }` : ''
  }, Special Request: ${room.specialRequests}`;
}

export function getToursSellingRegions() {
  return window.configs.TOUR_V2_SELLING_REGIONS.split(',');
}
