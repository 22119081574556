import React, { useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import OffersService from '~/services/OffersService';

import { Component } from './Component';

type Props = { vendorName?: string };

export function Container(_: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [addons, setAddons] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [currentPage] = useState(1);

  useEffect(() => {
    OffersService.getVendorAddons(vendorId)
      .then((response) => response.result)
      .then((result) => {
        setAddons(result);
        setTotal(result.length);
      })
      .catch((error) => {
        console.warn(error);
        setError("Service error. Can't load vendor addons");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vendorId]);

  return (
    <div>
      {error && <ErrorDisplay message={error} />}
      {isLoading ? <Spinner /> : <Component addons={addons} currentPage={currentPage} total={total} />}
    </div>
  );
}
