export const UTM_MEDIUM = 'admin';
export const LE_LABEL = 'luxuryescapes';
export const LE_ATTRIBUTION = 'luxuryescapes';
export const brands = [
  {
    title: 'Luxury Escapes',
    value: 'luxuryescapes',
    sailthru: '9d220dfb0bb97ebaaa372ce80a85c465',
  },
  {
    title: 'Cudo',
    value: 'cudo',
    sailthru: '58e9cf5da179a48132ebc71a877910dc',
  },
  {
    title: 'Ouffer',
    value: 'ouffer',
    sailthru: null,
  },
  {
    title: 'Deals',
    value: 'deals',
    sailthru: 'f33be26942ffa3c84af3f7276f739801',
  },
  {
    title: 'Treat Me',
    value: 'treatme',
    sailthru: 'c81ee857b89b857431e7768a0ed6f151',
  },
  {
    title: 'Living Social',
    value: 'livingsocial',
    sailthru: '3f8da786448e372298e1f5becb459912',
  },
  // To be refactored for header brand toggle
  {
    title: 'Scoopon Travel',
    value: 'scoopontravel',
    sailthru: '',
  },
  // may be need a value here
  {
    title: 'Kogan Travel',
    value: 'kogantravel',
    sailthru: '',
  },
];
