import React from 'react';

import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';

export default function OnlineLabel() {
  return (
    <Box display="inline-flex" alignItems="center" p={0.5} borderRadius={2}>
      <Check color="success" sx={{ fontSize: '15px' }} />
      <Typography color="green">online</Typography>
    </Box>
  );
}
