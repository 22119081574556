import React, { ComponentProps, useState } from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import CheckboxesWidget from 'react-jsonschema-form/lib/components/widgets/CheckboxesWidget';

import { Alert, Box, Button } from '@mui/material';
import TextField from '@mui/material/TextField';

import * as libRegions from '@luxuryescapes/lib-regions';

import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/muiSubmitButton';
import AIGeneratorTextAreaWidget from '~/components/Common/Forms/widgets/AIGeneratorTextAreaWidget';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

import { asiaRegionCodes, getNonAsiaRegionCodes } from '~/consts/allRegions';

import OffersService from '~/services/OffersService';

import CustomCheckboxWidget from '../../../Common/Forms/widgets/CustomCheckboxWidget';
import CustomSelectFieldWidget from '../../../Common/Forms/widgets/CustomSelectFieldWidget';
import CustomTextFieldWidget from '../../../Common/Forms/widgets/CustomTextFieldWidget';

const WIDGETS = {
  CheckboxWidget: CustomCheckboxWidget,
  TextWidget: CustomTextFieldWidget,
  SelectWidget: CustomSelectFieldWidget,
  AITextAreaWidget: AIGeneratorTextAreaWidget,
};

const ExtraTextWidget = (props) => {
  const handleImport = () => {
    if (confirm('Any existing content will be overwritten. Are you sure?')) {
      props.onChange(props.formContext[props.schema.field]);
    }
  };

  return (
    <Box>
      <label htmlFor={props.id}>{props.label}</label>
      <Box style={{ marginLeft: -8 }}>
        <Button variant="text" onClick={handleImport}>
          Import from default
        </Button>
      </Box>
      <Box>
        <TextField
          id={props.id}
          value={props.value}
          variant="standard"
          fullWidth
          onChange={(event) => props.onChange(event.target.value)}
        />
      </Box>
    </Box>
  );
};

const MarkdownEditorWidget = (props) => {
  const [editorKey, setEditorKey] = useState((Math.random() * 1000 + 1000).toFixed());

  const handleImport = () => {
    if (confirm('Any existing content will be overwritten. Are you sure?')) {
      props.onChange(props.formContext[props.schema.field]);
      setEditorKey((Math.random() * 1000 + 1000).toFixed());
    }
  };

  return (
    <Box>
      <label htmlFor={props.id}>{props.label}</label>
      <Box style={{ marginLeft: -8 }}>
        <Button variant="text" onClick={handleImport}>
          Import from default
        </Button>
      </Box>
      <MarkdownEditor key={editorKey} {...props} />
    </Box>
  );
};

const AITextAreaWidget = (props) => {
  const [editorKey, setEditorKey] = useState((Math.random() * 1000 + 1000).toFixed());

  const handleImport = () => {
    if (confirm('Any existing content will be overwritten. Are you sure?')) {
      props.onChange(props.formContext[props.schema.field]);
      setEditorKey((Math.random() * 1000 + 1000).toFixed());
    }
  };

  return (
    <Box>
      <label htmlFor={props.id}>{props.label}</label>
      <Box style={{ marginLeft: -8 }}>
        <Button variant="text" onClick={handleImport}>
          Import from default
        </Button>
      </Box>
      <AIGeneratorTextAreaWidget key={editorKey} {...props} />
    </Box>
  );
};

const SelectableCheckboxesWidget = (props) => {
  return (
    <Box>
      <Box style={{ marginLeft: -8 }}>
        <Button variant="text" onClick={() => props.onChange([])}>
          Deselect all
        </Button>
        <Button variant="text" onClick={() => props.onChange(asiaRegionCodes)}>
          Asia only
        </Button>
        <Button variant="text" onClick={() => props.onChange(getNonAsiaRegionCodes)}>
          Non-Asia only
        </Button>
      </Box>
      <Box>
        <CheckboxesWidget {...props} />
      </Box>
    </Box>
  );
};

const schema: any = {
  title: 'Localised content',
  description: 'Content in these fields will overwrite default content for the selected markets',
  type: 'array',
  items: {
    type: 'object',
    title: 'Countries',
    properties: {
      countries: {
        type: 'array',
        minItems: 1,
        title: 'Countries',
        items: {
          type: 'string',
          oneOf: libRegions.getRegions().map((region) => ({ const: region.code, title: region.name })),
        },
        uniqueItems: true,
      },
      name: {
        type: 'string',
        title: 'Name',
        field: 'name',
      },
      description: {
        type: 'string',
        title: 'Description',
        field: 'description',
      },
      additional_description: {
        type: 'string',
        title: 'Additional Description',
        field: 'additional_description',
      },
      highlights: {
        type: 'string',
        title: 'Highlights',
        field: 'highlights',
      },
    },
    required: ['countries'],
  },
};

export type LocalizationData = {
  countries: string[];
  name?: string;
  description?: string;
  additional_description?: string;
  highlights?: string;
};

type FormProps = {
  formData?: LocalizationData[];
};

interface Props {
  offer: App.AccommodationOffer;
  fields: LocalizationData[];
  onChange: (fields: LocalizationData[]) => void;
}

export default function ContentLocalizationForm(props: Props) {
  const offerId = props.offer.id_salesforce_external;

  const [contentFields, setContentFields] = useState<LocalizationData[]>(props.fields);
  const [saveState, setSaveState] = useState(buttonStates.default);
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const uiSchema = {
    'ui:options': {
      orderable: false,
    },
    items: {
      name: {
        'ui:widget': ExtraTextWidget,
      },
      description: {
        'ui:widget': MarkdownEditorWidget,
      },
      additional_description: {
        'ui:widget': MarkdownEditorWidget,
      },
      highlights: {
        'ui:widget': MarkdownEditorWidget,
      },
      countries: {
        'ui:widget': SelectableCheckboxesWidget,
      },
    },
  };

  const transformErrors = (errors) => {
    return errors.map((error) => {
      if (error.name === 'minItems') {
        error.message = 'At least one market should be selected';
      }
      return error;
    });
  };

  const handleFormChange = (form): ComponentProps<typeof form>['onSubmit'] => {
    if (saveState !== buttonStates.default) {
      setSaveState(buttonStates.default);
    }

    if (error !== null) {
      setError(null);
    }

    setContentFields(form.formData);
  };

  const handleFormValidation = (formData: LocalizationData[], errors) => {
    for (let i = 0; i < formData.length; i++) {
      const item = formData[i];

      const otherItems = formData.filter((other) => other != item);

      const hasSameMarkets = otherItems.some((other) => {
        const otherAsSet = new Set(other.countries);
        const commonMarkets = new Set([...item.countries].filter((c) => otherAsSet.has(c)));

        return commonMarkets.size > 0;
      });

      if (hasSameMarkets) {
        errors[i].countries.addError('Market should be unique');
      }

      if (!item.name && !item.description && !item.additional_description && !item.highlights) {
        errors[i].addError('Either Name, Description, Additional Description or Highlights fields are required');
      }
    }

    return errors;
  };

  const handleFormSubmit = async (form: FormProps) => {
    setProcessing(true);
    setSaveState(buttonStates.saving);

    try {
      await OffersService.updateOfferContentLocalization(offerId, form.formData);
      // todo: display response.message

      props.onChange(contentFields);

      setSaveState(buttonStates.saved);
    } catch (e) {
      setError("Can't save changes. Please check form and try again later.");
      setSaveState(buttonStates.failed);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert severity="warning">
            <p>{error}</p>
          </Alert>
        </Box>
      )}

      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={contentFields}
        showErrorList={false}
        disabled={isProcessing}
        formContext={props.offer}
        transformErrors={transformErrors}
        customValidate={handleFormValidation}
        validator={validator}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        widgets={WIDGETS}
      >
        <Box className="button-container">
          <Button type="submit" variant="contained" color={saveState} disabled={isProcessing}>
            {buttonMessages[saveState]}
          </Button>
        </Box>
      </Form>
    </Box>
  );
}
