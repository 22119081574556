import React from 'react';

import Form from 'react-jsonschema-form';

import { Button } from '@mui/material';

import OffersService from '../../../services/OffersService';
import { reportError } from '../../../utils/reportError';
import ErrorListDisplay from '../ErrorListDisplay';

import RoomPolicyFormTextWidget from './RoomPolicyFormWidget';
import { buttonMessages, buttonStates } from './states/submitButton';
import MarkdownEditor from './widgets/MarkdownEditor';

export default class RoomPolicyForm extends React.Component {
  constructor(props) {
    super(props);

    this.schema = {
      ...this.props.schema,
      properties: {
        room_type_name: {
          title: ' ',
          type: 'string',
        },
        id: {
          title: 'Room Policy ID',
          type: ['integer', 'null'],
        },
        ...this.props.schema.properties,
      },
    };

    this.uiSchema = {
      room_type_name: { 'ui:disabled': true },
      room_type_id: { 'ui:widget': 'hidden' },
      id: { 'ui:widget': 'hidden' },
      description: { 'ui:widget': MarkdownEditor },
    };

    this.state = {
      errors: [],
      apiErrors: [],
      roomPolicy: props.roomPolicy,
      schema: this.schema,
      saveState: buttonStates.default,
    };
  }

  onChange = (edit) => {
    const newState = {
      roomPolicy: edit.formData,
    };
    if (this.state.saveState !== buttonStates.default) {
      newState.saveState = buttonStates.default;
    }

    this.setState(newState);
  };

  onSubmit = async (form) => {
    form.errors = [];
    form.errorSchema = {};

    this.setState({
      saveState: buttonStates.saving,
    });

    const submitData = JSON.parse(JSON.stringify(form.formData));
    const idOffer = this.props.idOffer;
    const idRoomPolicy = submitData.id;
    delete submitData['room_type_name'];
    delete submitData['id'];

    try {
      if (idRoomPolicy) {
        delete submitData['room_type_id'];
        await OffersService.updateRoomPolicy(submitData, idOffer, idRoomPolicy);
      } else {
        await OffersService.createRoomPolicy(submitData, idOffer);
      }
      this.setState({ saveState: buttonStates.saved, apiErrors: [] });
    } catch (e) {
      const apiErrors = [];
      if (e.name === 'ValidationError') {
        e.errors.forEach((err) => {
          apiErrors.push(err.message);
        });
      }
      this.setState({
        saveState: buttonStates.failed,
        apiErrors,
      });
      reportError(e);
    }
  };

  validate = (formData, errors) => {
    this.state.errors.forEach((error) => errors.addError(error.message));
    return errors;
  };

  render() {
    let apiErrors = null;

    if (this.state.apiErrors && this.state.apiErrors.length) {
      apiErrors = <ErrorListDisplay messages={this.state.apiErrors} />;
    }

    return (
      <div>
        <Form
          schema={this.state.schema}
          formData={this.state.roomPolicy}
          showErrorList={false}
          liveValidate={true}
          validate={this.validate}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          uiSchema={this.uiSchema}
          widgets={{
            TextWidget: RoomPolicyFormTextWidget,
          }}
          formContext={{
            hideLabel: true,
          }}
        >
          {apiErrors}
          <div className="button-container">
            <Button type="submit" variant="contained" className={this.state.saveState}>
              {buttonMessages[this.state.saveState]}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
