import React, { useCallback, useContext, useEffect } from 'react';

import { useTourDetailsContextQuery } from '~/queries/tours/useTourDetailsFormQueries';

import { Alert, Box, Button, CircularProgress, Divider, Stack } from '@mui/material';

import TourDetailsContext from './TourDetailsContext';
import TourDetailsLinksList from './TourDetailsLinksList';
import TourDetailsTabs from './TourDetailsTabs';

interface Props {
  tourId: string;
}

export default function TourDetailsNav(props: Props) {
  const { tourId } = props;
  const { setTourDetails, setTabs, tabs, tourDetails } = useContext(TourDetailsContext);
  const { fetchContext, contextFetchReq } = useTourDetailsContextQuery();

  useEffect(() => {
    fetchContext(tourId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId]);

  useEffect(() => {
    switch (contextFetchReq.status) {
      case 'succeeded': {
        const { tabs, tourId, tourTitle, vendorId } = contextFetchReq.result;
        setTourDetails({ id: tourId, title: tourTitle, vendorId });
        setTabs(tabs);
      }
    }
  }, [contextFetchReq, setTabs, setTourDetails]);

  const handleRetry = useCallback(() => {
    fetchContext(tourId);
  }, [fetchContext, tourId]);

  return (
    <Stack direction="column" spacing={1}>
      {contextFetchReq.status === 'pending' && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress variant="indeterminate" />
        </Box>
      )}
      {contextFetchReq.status === 'succeeded' && (
        <>
          {!!tabs.length && <TourDetailsTabs tabs={tabs} />}
          <Divider variant="middle" />
          {!!tourDetails && (
            <TourDetailsLinksList
              tourId={tourDetails.id}
              tourTitle={tourDetails.title}
              vendorId={tourDetails.vendorId}
            />
          )}
        </>
      )}
      {contextFetchReq.status === 'failed' && (
        <Alert
          severity="error"
          action={
            <Button color="inherit" variant="outlined" onClick={handleRetry}>
              Retry
            </Button>
          }
        >
          There was a problem fetching links!
        </Alert>
      )}
    </Stack>
  );
}
