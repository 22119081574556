import { PromotionFormValues } from './types';

export const hasRequiredFields = (values: PromotionFormValues): boolean => {
  return (
    !!values.promotionName &&
    !!values.promotionSellingPoint1 &&
    !!values.promotionStartDate &&
    values.promotionStartDate !== 'Invalid Date' &&
    !!values.promotionEndDate &&
    values.promotionEndDate !== 'Invalid Date'
  );
};
