import React from 'react';

import { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';

import { CancelOutlined } from '@mui/icons-material';
import { FormControl, List, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';

import { extractNumberFromString } from '~/utils/stringUtils';

export default function CustomRoomMappingsWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = {
    rooms: App.Bedbank.RoomEdit[];
  },
>({ id, label, options }: WidgetProps<T, S, F>) {
  const getCurrentRoom = (): App.Bedbank.RoomEdit | null => {
    const pos = extractNumberFromString(id);
    if (pos === null) {
      return null;
    }
    return options?.rooms[pos];
  };

  const currentRoom = getCurrentRoom();

  return (
    <FormControl fullWidth variant="outlined">
      <label htmlFor={id}>{label}</label>
      <List id={id}>
        {(currentRoom?.mappedRooms ?? []).map((item, index) => (
          <Typography key={index} display="flex" alignItems="center">
            {item.externalId}
            {item.isVerified === false && (
              <CancelOutlined fontSize="small" sx={{ color: orange[300] }} titleAccess="Not Verified" />
            )}
          </Typography>
        ))}
      </List>
    </FormControl>
  );
}
