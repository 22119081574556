import React, { useEffect, useState } from 'react';

import { getPartnerships } from '~/services/LoyaltyService';

import { reportError } from '~/utils/reportError';

import MonthReportsPage from './MonthReportsPage';

export default function MonthReportsContainer() {
  const [partnerships, setPartnerships] = useState(null);

  useEffect(() => {
    const fetchPartnerships = async () => {
      try {
        const partnershipsData = await getPartnerships();
        setPartnerships(partnershipsData?.result || null);
      } catch (error) {
        reportError(error);
      }
    };

    fetchPartnerships();
  }, []);

  return <MonthReportsPage partnerships={partnerships} />;
}
