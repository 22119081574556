import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

const actionsCell = (params: GridRenderCellParams) => {
  return (
    <Button
      size="small"
      component={Link}
      variant="text"
      to={`/vendors/${params.row.vendor_id}/addons/${params.row.fk_salesforce_id}`}
    >
      Edit
    </Button>
  );
};

const sizePerPage = 20;

type Props = {
  addons: any;
  currentPage: number;
  total: number;
};

const columns: GridColDef[] = [
  { field: 'fk_salesforce_id', headerName: 'ID', width: 200, display: 'flex' },
  { field: 'name', headerName: 'Opportunity Name', flex: 1, display: 'flex' },
  { field: 'close_date', headerName: 'Close Date', width: 150, display: 'flex' },
  { field: 'run_date', headerName: 'Run Date', width: 150, display: 'flex' },
  { field: 'end_date', headerName: 'End Date', width: 150, display: 'flex' },
  { field: 'actions', headerName: '', width: 100, sortable: false, renderCell: actionsCell, display: 'flex' },
];

export function Component({ currentPage, total, addons }: Props) {
  return (
    <DataGrid
      rows={addons || []}
      columns={columns}
      rowCount={total}
      getRowId={(row) => row.fk_salesforce_id}
      pageSizeOptions={[sizePerPage]}
      paginationModel={{ page: currentPage - 1, pageSize: sizePerPage }}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
}
