import React from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import { checkCanRedeemLuxPlusBenefits, isFreePreviewLuxPlusMember } from '~/components/Membership/utils';

import { LUX_PLUS } from '~/consts/membership';

import { formatDateOrdinalWithClock, formatDateShortDD } from '~/services/TimeService';

import { getCustomerPhoneNumberFromOrder } from '~/utils/order';

import { buildOfferTypes } from './orderMetaUtils';

interface Props {
  customer: App.User;
  order: App.Order;
  purchaser: string;
  subscriptions: App.MembershipSubscriptions | null;
  businessInfo?: {
    businessName: string;
    businessCreditCustomerId?: string;
    employeePersonalAccountInfo: string;
    personalName: string;
    personalEmail: string;
  };
}

const formatDate = (cell) => {
  const date = new Date(cell);

  return formatDateOrdinalWithClock(date);
};

const getOrderStatusColor = (status: string) => {
  let orderStatusColor = 'black';
  switch (status) {
    case 'completed':
    case 'refunded':
      orderStatusColor = 'green';
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      orderStatusColor = 'red';
      break;
    case 'pending':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      orderStatusColor = 'orange';
      break;
  }
  return orderStatusColor;
};

const getGiftStatusTexAndColor = (status: string) => {
  let giftStatusText = '';
  let giftStatusColor = 'black';
  switch (status) {
    case 'redeemed':
      giftStatusText = 'Redeemed';
      giftStatusColor = 'green';
      break;
    case 'awaiting_delivery':
      giftStatusText = 'Awaiting delivery';
      giftStatusColor = 'orange';
      break;
    case 'pending_redemption':
      giftStatusText = 'Pending redemption';
      giftStatusColor = 'orange';
      break;
    default:
      giftStatusText = status;
      break;
  }
  return { giftStatusText, giftStatusColor };
};

function OrderMeta({ customer, order, purchaser, subscriptions, businessInfo }: Props) {
  const { giftStatusText, giftStatusColor } = getGiftStatusTexAndColor(order.gift_status);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipboard = (info: string) => {
    navigator.clipboard.writeText(info);
    enqueueSnackbar('Copied to clipboard', { variant: 'success', autoHideDuration: 2000 });
  };

  const offerTypes = buildOfferTypes(order).join(', ');

  return (
    <Box mb={2} display="grid" gap={2} gridTemplateColumns={['1fr', '1fr 1fr', 'repeat(3, 1fr)', 'repeat(6, 1fr)']}>
      <Box>
        <DefinitionTitle>Customer {order.business_id ? '(Business)' : ''}</DefinitionTitle>
        <DefinitionText>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Link to={`/users/${customer.memberId}`}>{customer.fullName} </Link>
            </Grid>
            <Grid item maxHeight={'20px'}>
              <IconButton
                title="Copy customer full name"
                onClick={() => {
                  handleCopyToClipboard(customer.fullName);
                }}
                size="small"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          {customer.shadow_ban_user && <Typography color="red">Shadowbanned</Typography>}
          {customer.vip_client && <Typography color="gold">VIP Client</Typography>}
          {checkCanRedeemLuxPlusBenefits(subscriptions) && (
            <Typography color="blue">{LUX_PLUS.PROGRAM_NAME} Member</Typography>
          )}
          {isFreePreviewLuxPlusMember(subscriptions) && (
            <Typography color="blue">Free Preview {LUX_PLUS.PROGRAM_NAME} Member</Typography>
          )}
          <Typography noWrap textOverflow="ellipsis" title={customer.email}>
            {customer.email}{' '}
            <IconButton
              title="Copy user details"
              onClick={() => {
                handleCopyToClipboard(customer.email);
              }}
              size="small"
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Typography>
        </DefinitionText>
      </Box>

      {!!order.items[0]?.reservation?.dob && (
        <Box>
          <DefinitionTitle>Date of Birth</DefinitionTitle>
          <DefinitionText>{formatDateShortDD(new Date(order.items[0]?.reservation?.dob))}</DefinitionText>
        </Box>
      )}

      {order.business_id && (
        <>
          <Box>
            <DefinitionTitle>Customer (Personal)</DefinitionTitle>
            <DefinitionText>
              {businessInfo.businessCreditCustomerId ? (
                <Link to={`/users/${businessInfo.businessCreditCustomerId}`}>{businessInfo.personalName}</Link>
              ) : (
                <Link to={`/users/${customer.memberId}`}>{customer.fullName}</Link>
              )}
              <Typography noWrap textOverflow="ellipsis" title={customer.email}>
                {businessInfo.personalEmail || customer.email}
              </Typography>
            </DefinitionText>
          </Box>
          <Box>
            <DefinitionTitle>Business</DefinitionTitle>
            <DefinitionText>
              <Link to={`/lebusiness/${order.business_id}/business`} target="_blank">
                {businessInfo.businessName}
              </Link>
            </DefinitionText>
          </Box>
        </>
      )}

      <Box>
        <DefinitionTitle>Order phone</DefinitionTitle>
        <DefinitionText>{getCustomerPhoneNumberFromOrder(order)}</DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Customer postcode</DefinitionTitle>
        <DefinitionText>{order.customer_postcode}</DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Order date</DefinitionTitle>
        <DefinitionText>{formatDate(order.created_at)}</DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Sold By</DefinitionTitle>
        <DefinitionText>{purchaser}</DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Order status</DefinitionTitle>
        <DefinitionText>
          <Typography color={getOrderStatusColor(order.status)} textTransform="uppercase">
            {order.status}
          </Typography>
          {order.create_order_transaction?.message && <Typography>{order.create_order_transaction.message}</Typography>}
        </DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Source</DefinitionTitle>
        <DefinitionText>{order.utm_source}</DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Campaign</DefinitionTitle>
        <DefinitionText>{order.utm_campaign}</DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Medium</DefinitionTitle>
        <DefinitionText>{order.utm_medium}</DefinitionText>
      </Box>

      <Box>
        <DefinitionTitle>Attribution</DefinitionTitle>
        <DefinitionText>{order.le_attribution}</DefinitionText>
      </Box>

      {order.gift_status && (
        <Box>
          <DefinitionTitle>Gift</DefinitionTitle>
          <Typography color={giftStatusColor} textTransform="uppercase">
            {giftStatusText}
          </Typography>
        </Box>
      )}

      {order.number && (
        <Box>
          <DefinitionTitle>Number</DefinitionTitle>
          <DefinitionText>{order.number}</DefinitionText>
        </Box>
      )}

      {order.platform && (
        <Box>
          <DefinitionTitle>Platform</DefinitionTitle>
          <DefinitionText>{order.platform}</DefinitionText>
        </Box>
      )}

      {offerTypes && (
        <Box>
          <DefinitionTitle>Offer Types</DefinitionTitle>
          <DefinitionText>{offerTypes}</DefinitionText>
        </Box>
      )}
    </Box>
  );
}

export default OrderMeta;
