import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerCities from '~/actions/customerCommunication/fetchHeroPlannerCities';

import { useAppSelector } from '../store';

function useHeroPlannerCities(countryGroupId: string, countryId: string, stateId: string) {
  const brand = useAppSelector((state) => state.tenant.brand);
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerCities> = [brand, countryGroupId, countryId, stateId];
    return [getRecordKeyFor(...params), params];
  }, [brand, countryGroupId, countryId, stateId]);
  const citiesReq = useAppSelector((state) => state.customerCommunication.countryGroupCountryStateCitiesRecords[key]);
  const appDispatch = useDispatch();
  const fetchCities = useCallback(() => {
    appDispatch(fetchHeroPlannerCities(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  return { citiesReq, fetchCities };
}

export default useHeroPlannerCities;
