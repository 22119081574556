import { createContext, useContext } from 'react';

// Create a context to store the current active accordion and the function to change it
export const ActiveAccordionContext = createContext<{
  activeAccordionIndex: number;
  setActiveAccordion: React.Dispatch<React.SetStateAction<number>>;
  displaySingleOption?: boolean;
  targetedSection: string;
}>({
  activeAccordionIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveAccordion: () => {},
  displaySingleOption: false,
  targetedSection: null,
});

export function useActiveAccordion() {
  return useContext(ActiveAccordionContext);
}
