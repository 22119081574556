import React, { useEffect, useState } from 'react';

import { Control } from 'react-hook-form';
import { theme } from '~/theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select, Stack, Typography } from '@mui/material';

import { ArrayField, Autocomplete, Checkbox, Input, RichTextEditor } from '~/components/Common/Forms/fields';

import { getAllForceBundles } from '~/services/FlightsService';

interface Props {
  control: Control<any>;
  isFlightsWarningEnabled: boolean;
  schema: any;
}

const FlightsAccordion = ({ control, isFlightsWarningEnabled, schema }: Props) => {
  const [forceBundles, setForceBundles] = useState([]);

  useEffect(() => {
    getAllForceBundles()
      .then((data) => {
        const forceBundleIds = data.result.map((forceBundle) => forceBundle.forceBundleId);
        setForceBundles([...new Set(forceBundleIds)]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Accordion disableGutters>
      <AccordionSummary
        aria-controls="flights"
        expandIcon={<ExpandMoreIcon />}
        id="flights"
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Typography variant="h5">Flights</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack mt={2} spacing={2}>
          <Stack spacing={0.1}>
            <Checkbox control={control} name="flights_enabled" label="Flights enabled" />
            <Checkbox control={control} name="bundled_with_flights_only" label="Bundle with flights only" />
          </Stack>
          <ArrayField
            control={control}
            label="Included carriers for bundle"
            name="whitelisted_carrier_codes"
            render={(props) => (
              <Select {...props} fullWidth>
                {schema.properties.whitelisted_carrier_codes.items.enum.map((option, index) => (
                  <MenuItem key={option} value={option}>
                    {schema.properties.whitelisted_carrier_codes.items.enumNames[index]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Autocomplete control={control} label="Force Bundles" name="force_bundle_id" options={forceBundles} />
          <Autocomplete
            control={control}
            label="Flight destination port"
            name="flight_destination_port"
            options={schema.properties.flight_destination_port.enum}
          />
          <ArrayField
            control={control}
            label="Regions that can sell flights"
            name="flight_regions"
            render={(props) => (
              <Select {...props} fullWidth>
                {schema.properties.flight_regions.items.enum.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Stack spacing={0.1}>
            <Checkbox control={control} label="Disable flight cache for this offer" name="flights_cache_disabled" />
            <Checkbox control={control} label="Enable schedule change robot" name="schedule_change_robot_enabled" />
          </Stack>
          <Input
            control={control}
            fullWidth
            label="Latest flight arrival time of departing flight eg 23:45"
            name="flights_max_arrival_time"
          />
          <Input
            control={control}
            fullWidth
            label="Earliest flight departure time of returning flight eg 07:00"
            name="flights_min_returning_departure_time"
          />
          <Checkbox
            control={control}
            label="Display Flights Warning Banner for Hotel + Flight Bookings"
            name="flights_warning_enabled"
          />
          {isFlightsWarningEnabled && (
            <>
              <Input control={control} fullWidth label="Flights Warning Headline" name="flights_warning_headline" />
              <RichTextEditor control={control} label="Flights Warning Body" name="flights_warning_popup_body" />
            </>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default FlightsAccordion;
