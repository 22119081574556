export const SET_INITIAL_STATE = 'insurance/SET_INITIAL_STATE';
export const SET_DESTINATION_COUNTRIES = 'insurance/SET_DESTINATION_COUNTRIES';
export const SET_HIGH_RISK_DESTINATION = 'insurance/SET_HIGH_RISK_DESTINATION';
export const SET_START_DATE = 'insurance/SET_START_DATE';
export const SET_END_DATE = 'insurance/SET_END_DATE';
export const SET_TRAVELLERS = 'insurance/SET_TRAVELLERS';
export const SET_QUOTE_ERRORS = 'insurance/SET_QUOTE_ERRORS';
export const SET_QUOTE_RESULT = 'insurance/SET_QUOTE_RESULT';
export const BEGIN_QUOTE = 'insurance/BEGIN_QUOTE';
export const EDIT_QUOTE = 'insurance/EDIT_QUOTE';
export const CUSTOMER_NOT_INTERESTED = 'insurance/CUSTOMER_NOT_INTERESTED';
export const CUSTOMER_INTERESTED = 'insurance/CUSTOMER_INTERESTED';

export const setInitialState = (state) => ({
  type: SET_INITIAL_STATE,
  state,
});

export const beginQuote = () => ({
  type: BEGIN_QUOTE,
});

export const editQuote = () => ({
  type: EDIT_QUOTE,
});

export const setQuoteErrors = (errors) => ({
  type: SET_QUOTE_ERRORS,
  errors,
});

export const setQuoteResults = (results) => ({
  type: SET_QUOTE_RESULT,
  results,
});

export const setDestinationCountries = (destinationCountries) => ({
  type: SET_DESTINATION_COUNTRIES,
  destinationCountries,
});

export const setStartDate = (startDate) => ({
  type: SET_START_DATE,
  startDate,
});

export const setEndDate = (endDate) => ({
  type: SET_END_DATE,
  endDate,
});

export const setTravellers = (travellers) => ({
  type: SET_TRAVELLERS,
  travellers,
});

export const setCustomerNotInterested = (reason) => ({
  type: CUSTOMER_NOT_INTERESTED,
  reason,
});

export const setCustomerInterested = () => ({
  type: CUSTOMER_INTERESTED,
});
