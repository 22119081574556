import qs from 'qs';

function blacklist(object, allowedProperties) {
  return Object.keys(object)
    .filter((key) => allowedProperties.indexOf(key) === -1)
    .reduce((acc, val) => {
      acc[val] = object[val];
      return acc;
    }, {});
}

// parseSearchString parses location.search provided by react-router into
// an object. `searchString` is in the form of '?orderId=foo&itemId=bar'
export function parseSearchString(searchString) {
  if (!searchString) {
    return {};
  }
  // ignore the preceding question mark
  const queryString = searchString.substring(1);

  return qs.parse(queryString);
}

export function addQuery(location, query) {
  const queryObj = parseSearchString(location.search);

  const q = {
    ...queryObj,
    ...query,
  };

  return {
    ...location,
    search: `?${qs.stringify(q)}`,
  };
}

export function removeQuery(location, ...queryKeys) {
  const queryObj = parseSearchString(location.search);

  const q = blacklist(queryObj, queryKeys);

  return {
    ...location,
    search: `?${qs.stringify(q)}`,
  };
}
