import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';

import OfferExperiencesForm from '~/components/Common/Forms/OfferExperiencesForm';
import Spinner from '~/components/Common/Spinner';

import {
  getExperienceOffers,
  getHandpickedExperiencesForOffer,
  postHandpickedExperiencesForOffer,
} from '~/services/ExperiencesService';
import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

interface Props {
  offer: App.Offer;
  match: any;
}

const DEFAULT_RADIUS_TO_SEARCH_EXPERIENCES = 40775;
const DEFAULT_MAX_EXPERIENCES_TO_SHOW = 2000;

const ExperiencesEditContainer = (props: Props) => {
  const { match } = props;
  const offerId = match.params.id_offer;

  const [isFetching, setIsFetching] = useState(true);

  const [experiences, setExperiences] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [expInFlow, setExperiencesInFlow] = useState(null);
  const [expCurated, setExperiencesCurated] = useState(null);
  const [showOnlyExperiencesCurated, setShowOnlyExperiencesCurated] = useState(null);

  const fetchData = async () => {
    const [offerResponse, experiencesResponse] = await Promise.all([
      OffersService.getOffer(offerId),
      getHandpickedExperiencesForOffer(offerId),
    ]);

    const property = await ReservationService.getProperty(offerResponse.result.packages[0].fk_property_id);

    const experiences = await getExperienceOffers({
      curationData: true,
      limit: DEFAULT_MAX_EXPERIENCES_TO_SHOW,
      minified: true,
      filters: {
        status: ['APPROVED'],
        distance: `${DEFAULT_RADIUS_TO_SEARCH_EXPERIENCES}km`,
        coordinates: {
          lat: property.result.latitude,
          lng: property.result.longitude,
        },
      },
      brand: 'luxuryescapes',
    });

    setExperiencesInFlow(offerResponse.result.experiences_in_flow);
    setExperiencesCurated(offerResponse.result.experiences_curated);
    setShowOnlyExperiencesCurated(offerResponse.result.show_only_experiences_curated);

    setExperiences(experiences.result);
    setSelectedExperiences(experiencesResponse.result.map((i) => i.experienceId));

    setIsFetching(false);
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const onSubmitForm = async () => {
    setIsFetching(true);
    Promise.all([
      // Patch offer
      OffersService.updateOffer(
        {
          experiences_in_flow: expInFlow,
          experiences_curated: expCurated,
          show_only_experiences_curated: showOnlyExperiencesCurated,
        },
        offerId,
      ),
      // Patch experiences
      postHandpickedExperiencesForOffer({
        offerId,
        override: true,
        payload: selectedExperiences.map((experienceId) => {
          return {
            offerSalesforceId: offerId,
            experienceId: experienceId,
          };
        }),
      }),
    ]).then(() => {
      setIsFetching(false);
    });
  };

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <Button component={Link} to={`/offers/${offerId}`} style={{ display: 'inline-flex' }}>
        <ChevronLeftIcon /> Return to offer
      </Button>
      <h1 className="page-header"> Edit Offer Experiences </h1>
      <OfferExperiencesForm
        expInFlowChecked={expInFlow}
        setExperiencesInFlow={setExperiencesInFlow}
        expCuratedChecked={expCurated}
        setExperiencesCurated={setExperiencesCurated}
        experiences={experiences}
        selectedExperiences={selectedExperiences}
        setSelectedExperiences={setSelectedExperiences}
        onSubmitForm={onSubmitForm}
        showOnlyExperiencesCurated={showOnlyExperiencesCurated}
        setShowOnlyExperiencesCurated={setShowOnlyExperiencesCurated}
      />
    </div>
  );
};

export default ExperiencesEditContainer;
