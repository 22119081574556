import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Control, Controller } from 'react-hook-form';

import { Autocomplete, Box, Grid, TextField } from '@mui/material';

import ReservationService from '~/services/ReservationService';

interface Props {
  control: Control<any>;
  label: string;
  name: string;
  setValue: (name: string, value: unknown, config?: object) => void;
}

const CategoryIconWidget = ({ control, label, name, setValue }: Props) => {
  const [inclusionsCategories, setInclusionsCategories] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ReservationService.getInclusionsCategories()
      .then(({ result }) => setInclusionsCategories(result))
      .catch((e) =>
        enqueueSnackbar(`Failed to fetch offer inclusion categories: ${e?.message}`, { variant: 'warning' }),
      )
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar]);

  const onAutocompleteChange = (value) => {
    const newValue = value?.icon ?? '';
    setValue(name, newValue);
  };

  const findInternalValue = (value) => {
    return inclusionsCategories?.find((category) => category.icon === value) ?? null;
  };

  const sortCategories = (a, b) => {
    return -b.category.localeCompare(a.category);
  };

  return (
    <Box>
      <Grid container>
        <Grid item sm>
          <Controller
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(_, newValue: string | null) => {
                  onAutocompleteChange(newValue);
                }}
                loading={loading}
                isOptionEqualToValue={(option, value) => option.icon === value}
                value={findInternalValue(field.value)}
                options={inclusionsCategories?.sort(sortCategories)}
                groupBy={(option) => option.category}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => <TextField {...params} label="Category (Icon)" />}
                slotProps={{ paper: { elevation: 1 } }}
              />
            )}
            name={`${name}`}
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CategoryIconWidget;
