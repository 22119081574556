import React from 'react';

import { AdminPanelSettings, Computer, MergeType, SupervisorAccount, Warning } from '@mui/icons-material';
import { Box } from '@mui/material';

import { operations } from '@luxuryescapes/contract-svc-promo';

type PromoOrderTableCellProps = {
  requestSource: operations['calculateDiscount']['parameters']['body']['Discount Request']['requestSource'];
};

function PromoRequestSourceFormatter({ requestSource }: PromoOrderTableCellProps) {
  switch (requestSource) {
    case 'cart':
      return (
        <Box title={'Request via cart (legacy)'}>
          <Computer /> (legacy)
        </Box>
      );
    case 'global-checkout':
      return (
        <Box title={'Request via global checkout'}>
          <Computer />
        </Box>
      );
    case 'admin':
      return (
        <Box title={'Request made via admin checkout'}>
          <AdminPanelSettings />
        </Box>
      );
    case 'bff':
      return (
        <Box title={'Request made via Backend-for-Frontend'}>
          <MergeType />
        </Box>
      );
    case 'global-checkout-spoof':
      return (
        <Box title={'Request made via global checkout spoof'}>
          <SupervisorAccount />
        </Box>
      );
    default:
      return (
        <Box title={`Request made via unknown request source: "${requestSource}"`}>
          <Warning />
        </Box>
      );
  }
}

export { PromoRequestSourceFormatter };
