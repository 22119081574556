import React from 'react';

import currencyFormatter from 'currency-formatter';

import { INSTALMENT_STATUS } from '~/consts/instalment';

import InstalmentSchedule from './InstalmentSchedule';
import { dateFormatterLong } from './instalmentUtils';

const OrderInstalmentDetails = ({ instalmentDetails, payments }) => {
  const getStripeSubscriptionLink = () => {
    const subscription = instalmentDetails?.metadata?.subscription;
    if (subscription?.id) {
      const isTest = subscription.livemode ? '' : '/test';
      return `https://dashboard.stripe.com${isTest}/subscription_schedules/${subscription.id}`;
    }
    return 'NA';
  };

  const getAmount = (amount: number) => {
    return instalmentDetails?.metadata?.subscription?.id
      ? currencyFormatter.format(amount, {
          code: instalmentDetails.currency,
          precision: 2,
        })
      : 'NA';
  };

  const getNextInstalmentDate = () => {
    return instalmentDetails.is_active && instalmentDetails?.scheduled_instalment_dates
      ? dateFormatterLong(instalmentDetails.scheduled_instalment_dates[instalmentDetails.paid_instalments_count])
      : 'NA';
  };

  const getInstalmentInterval = () => {
    const freq = instalmentDetails.instalment_frequency;
    const freqDays = instalmentDetails.instalment_frequency_days;

    return `${freq.charAt(0).toUpperCase() + freq.slice(1)} (${freqDays} days)`;
  };

  const instalmentRemaining = instalmentDetails.is_active
    ? instalmentDetails.total_instalments_count - instalmentDetails.paid_instalments_count
    : 0;

  return (
    <>
      <div className="row">
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted">Instalment Status</dt>
          {instalmentDetails.instalment_status}
        </dl>
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted data-hj-whitelist">Instalments Paid</dt>
          {instalmentDetails.paid_instalments_count}
        </dl>
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted">Instalments Remaining</dt>
          {instalmentRemaining}
        </dl>
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted">Next Instalment Date</dt>
          {getNextInstalmentDate()}
        </dl>
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted">Instalment Amount</dt>
          {getAmount(instalmentDetails.per_instalment_amount)}
        </dl>
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted">Balance Paid</dt>
          {getAmount(instalmentDetails.total_paid_amount)}
        </dl>
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted">Balance Remaining</dt>
          {getAmount(instalmentDetails.balance_amount)}
        </dl>
        <dl className="col-sm-3">
          <dt className="h6 text-uppercase text-muted">Instalment Interval</dt>
          {getInstalmentInterval()}
        </dl>
      </div>
      <div className="row">
        <dl className="col-sm-9">
          <dt className="h6 text-uppercase text-muted">Stripe subscription link</dt>
          <a href={getStripeSubscriptionLink()} target="_blank" rel="noreferrer">
            {getStripeSubscriptionLink()}
          </a>
        </dl>
      </div>
      <div className="row">
        {instalmentDetails.instalment_status !== INSTALMENT_STATUS.PAYMENT_DETAILS_CREATED && (
          <InstalmentSchedule instalmentDetails={instalmentDetails} payments={payments} />
        )}
      </div>
    </>
  );
};

export default OrderInstalmentDetails;
