import React, { Component } from 'react';

import classNames from 'clsx';

import { Button } from '@mui/material';

import ReservationService from '../../../services/ReservationService';
import { formatValidationMessages } from '../../../utils/formatValidationMessages';
import { reportError } from '../../../utils/reportError';
import ErrorListDisplay from '../ErrorListDisplay';
import Checkbox from '../Legacy/Checkbox';

import { buttonMessages, buttonStates } from './states/submitButton';

class RoomTypeIncludedGuestsForm extends Component {
  constructor(props) {
    super(props);

    this.toggleIncludeInfants = this.toggleIncludeInfants.bind(this);

    this.state = {
      maxIncludedGuests: this.props.roomType.max_included_guests,
      maxAdultIncludedGuests: this.props.roomType.max_adult_included_guests,
      maxChildIncludedGuests: this.props.roomType.max_child_included_guests,
      maxInfantIncludedGuests: this.props.roomType.max_infant_included_guests,
      includeInfants: false,
      saveState: buttonStates.default,
      errors: null,
    };
  }

  submit() {
    if (!window.confirm('this will regenerate all included guests, are you sure?')) {
      return;
    }

    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    const data = {
      max_included_guests: this.state.maxIncludedGuests,
      max_adult_included_guests: this.state.maxAdultIncludedGuests,
      max_child_included_guests: this.state.maxChildIncludedGuests,
      max_infant_included_guests: this.state.maxInfantIncludedGuests,
      include_infants: this.state.includeInfants,
    };

    const promise = ReservationService.updateRoomTypeIncludedGuests(
      data,
      this.props.propertyId,
      this.props.roomType.id,
      this.props.roomRate.id,
    );

    return promise
      .then((response) => {
        this.setState({
          saveState: buttonStates.saved,
          errors: null,
        });
        this.props.onRoomTypeIncludedGuestUpdated(response.result);
      })
      .catch((e) => {
        console.warn(e);
        const errors = e.name === 'ValidationError' ? e.errors : null;
        this.setState({
          saveState: buttonStates.failed,
          errors,
        });
        reportError(e);
      });
  }

  toggleIncludeInfants() {
    this.setState({ includeInfants: !this.state.includeInfants });
  }

  formRow(label, fieldName) {
    return (
      <div className="row capacity-form-row">
        <label className="col-sm-9">{label}</label>
        <div className="col-sm-3">
          <input
            type="number"
            step="1"
            min="0"
            value={this.state[fieldName]}
            onChange={(event) =>
              this.setState({
                [fieldName]: parseInt(event.target.value),
              })
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="capacity-form form-horizontal">
        {this.formRow('Total number of guests', 'maxIncludedGuests')}
        <hr />
        {this.formRow('Adults', 'maxAdultIncludedGuests')}
        {this.formRow('Children', 'maxChildIncludedGuests')}
        {this.formRow('Infants', 'maxInfantIncludedGuests')}
        <Checkbox
          labelText="Include infants"
          checked={this.state.includeInfants}
          onToggle={this.toggleIncludeInfants}
        />

        <div className="modal-form-group">
          {this.state.errors && <ErrorListDisplay messages={formatValidationMessages(this.state.errors)} />}
          <Button
            type="submit"
            variant="contained"
            className={classNames(this.state.saveState, 'pull-right')}
            onClick={this.submit.bind(this)}
          >
            {buttonMessages[this.state.saveState]}
          </Button>
          <Button onClick={this.props.onCancel}>Cancel</Button>
        </div>
      </div>
    );
  }
}

export default RoomTypeIncludedGuestsForm;
