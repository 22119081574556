import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerSchedulePushContentGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules/schedule-push-content']['get'];

function mapHeroPlannerPushContent(
  external: HeroPlannerSchedulePushContentGetContract['responses']['200']['content']['application/json']['result'],
): CustomerCommunication.HeroPlannerSchedulePushContent {
  return {
    title: external.pushTitle,
    subtitle: external.pushSubtitle,
    message: external.pushMessage,
    imageId: external.pushImageId,
    link: external.pushLink,
  };
}

async function requestGetHeroPlannerSchedulePushContent(
  brand: string,
  countryGroup: string,
  country: string,
  offerId: string,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!offerId) throw 'missing offerId';

  const url = makeCustomerCommunicationV1URL('/todays-escapes-schedule/hero-planner/schedules/schedule-push-content');
  url.searchParams.set('brand', brand);
  url.searchParams.set('countryGroup', countryGroup);
  url.searchParams.set('country', country);
  url.searchParams.set('offerId', offerId);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((res: HeroPlannerSchedulePushContentGetContract['responses']['200']['content']['application/json']) => {
      return mapHeroPlannerPushContent(res.result);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerSchedulePushContent;
