export const SUBMIT_TYPES = {
  SINGLE_ROOM_AND_RATE: {
    id: 'SINGLE_ROOM_AND_RATE',
    value: 'Apply to this room rate on this room',
  },
  ALL_ROOMS_FOR_THIS_RATE: {
    id: 'ALL_ROOMS_FOR_THIS_RATE',
    value: 'Apply to this room rate on all rooms',
  },
  ALL_RATES_FOR_THIS_ROOM: {
    id: 'ALL_RATES_FOR_THIS_ROOM',
    value: 'Apply to all room rates on this room',
  },
  ALL_RATES_FOR_ALL_ROOMS: {
    id: 'ALL_RATES_FOR_ALL_ROOMS',
    value: 'Apply to all room rates on all rooms',
  },
};

export const getRoomRatesToUpdate = ({ option, roomType, roomRate, allRoomTypes }) => {
  const roomRatesToUpdate = [];

  if (option.id === 'SINGLE_ROOM_AND_RATE') {
    roomRatesToUpdate.push({
      ...roomType,
      room_rates: [roomRate],
    });
  } else if (option.id === 'ALL_ROOMS_FOR_THIS_RATE') {
    roomRatesToUpdate.push(
      ...allRoomTypes.reduce((result, rt) => {
        const roomRateWithSameRatePlan = rt.room_rates.find((rr) => rr.rate_plan.id === roomRate.rate_plan.id);
        if (!roomRateWithSameRatePlan) {
          return result;
        }

        return result.concat({ ...rt, room_rates: [roomRateWithSameRatePlan] });
      }, []),
    );
  } else if (option.id === 'ALL_RATES_FOR_THIS_ROOM') {
    roomRatesToUpdate.push(roomType);
  } else if (option.id === 'ALL_RATES_FOR_ALL_ROOMS') {
    roomRatesToUpdate.push(...allRoomTypes);
  } else {
    throw new Error(`Invalid submit type ${option}`);
  }

  return roomRatesToUpdate;
};
