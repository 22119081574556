import React, { useCallback, useEffect, useState } from 'react';

import { theme } from '~/theme';

import Plus from '@mui/icons-material/Add';
import { Box, Button, Table, TableBody, TableContainer } from '@mui/material';

import TemplateDetails from '~/components/Content/TypeaheadTemplating/components/TemplateDetails';

import SearchService from '~/services/SearchService';

import Row from './components/Row';
import TableHeader from './components/TableHeader';

const ColumnHeadingNames = ['Template Name', 'Date created', 'Score'];

function TypeaheadTemplatingPage() {
  const [templates, setTemplates] = useState<App.TypeaheadSearchTemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [bestTemplateId, setBestTemplateId] = useState('');

  const loadTemplates = useCallback(async () => {
    const templates: App.TypeaheadSearchTemplate[] = (await SearchService.getAllTypeaheadTemplates()).result;

    const selectedTemplate = templates.find((template) => template.id === selectedTemplateId);
    if (!selectedTemplate) {
      setSelectedTemplateId('');
    }

    const maxScore = Math.max(...templates.map((template) => template.score || 0));
    const bestTemplate = templates.find((template) => template.score === maxScore);
    if (bestTemplate) {
      setBestTemplateId(bestTemplate.id);
    }

    const withoutActive = templates.filter((template) => template.is_active !== true);
    const active = templates.find((template) => template.is_active === true);
    if (active) {
      setTemplates([active, ...withoutActive]);
    } else {
      setTemplates(withoutActive);
    }
  }, [selectedTemplateId]);

  const onTemplateSavedAs = useCallback(
    (templateId?: string) => {
      if (templateId) {
        setSelectedTemplateId(templateId);
      }
      loadTemplates();
    },
    [loadTemplates],
  );

  const onTemplateHidden = useCallback(() => {
    setSelectedTemplateId('');
    loadTemplates();
  }, [loadTemplates]);

  useEffect(() => {
    const timer = setInterval(() => {
      loadTemplates();
    }, 5000);
    loadTemplates();

    return () => clearInterval(timer);
  }, [loadTemplates]);

  return (
    <>
      <Box
        style={{ position: 'relative' }}
        display="grid"
        gridTemplateColumns="0.3fr 0.7fr"
        border={`1px solid ${theme.palette.grey[300]}`}
        borderRadius={2}
        columnGap={10}
        minHeight="60vh"
      >
        <Box
          display="flex"
          flexDirection="column"
          paddingBottom={2}
          gap={2}
          borderRight={1}
          sx={{ borderRightColor: theme.palette.grey[300] }}
        >
          <TableContainer sx={{ height: '73vh', borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
            <Table stickyHeader>
              <TableHeader columnNames={ColumnHeadingNames} />
              <TableBody>
                {templates.map((template) => (
                  <Row
                    key={template.id}
                    bestTemplateId={bestTemplateId}
                    selectedTemplateId={selectedTemplateId}
                    template={template}
                    onTemplateSelected={(id) => setSelectedTemplateId(id)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button startIcon={<Plus />} onClick={() => setSelectedTemplateId('')}>
            Add new Template
          </Button>
        </Box>
        <TemplateDetails
          templateId={selectedTemplateId}
          onSavedAs={onTemplateSavedAs}
          onHide={onTemplateHidden}
          onBackToParent={(id) => setSelectedTemplateId(id)}
        />
      </Box>
    </>
  );
}

export default TypeaheadTemplatingPage;
