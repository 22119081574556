import React from 'react';

import SegmentHeaderGroup from '~/components/Content/SearchRanking/Segment/SegmentHeaderGroup';

interface Props {
  segments: App.Segment[];
}
function SegmentHeaderGroups({ segments }: Props) {
  return (
    <>
      {segments.map((segment, idx) => {
        const backgroundColor = idx % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'rgba(0, 0, 0, 0.04)';
        return <SegmentHeaderGroup key={segment.value} segment={segment} backgroundColor={backgroundColor} />;
      })}
    </>
  );
}

export default SegmentHeaderGroups;
