import React, { ComponentProps, useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Container, Stack } from '@mui/material';

import LETourSearchForm, { LETourSearchInput } from '../../ToursListing/LETourSearchForm';
import SFTourAddTourForm from '../../ToursListing/SFTourAddTourForm';
import LETourSearchResultsTable from '../../ToursListing/Tables/LETourSearchResultsTable';

function getLETourSearchInputFromSearchParams(searchParams: URLSearchParams): LETourSearchInput {
  const input: LETourSearchInput = {};

  const countries = searchParams.get('countries')?.split(',');
  if (countries?.length) input.countries = countries;
  const salesforceId = searchParams.get('salesforceId');
  if (salesforceId) input.salesforceId = salesforceId;
  const status = searchParams.get('status');
  if (status) input.status = status;
  const tourName = searchParams.get('tourName');
  if (tourName) input.tourName = tourName;
  const ids = searchParams.get('ids');
  if (ids) input.ids = ids;

  return input;
}

export default function ToursLEListPage() {
  const history = useHistory();
  const location = useLocation();

  const handleSearchFormSubmission = useCallback<ComponentProps<typeof LETourSearchForm>['onSubmit']>(
    (formObject) => {
      const urlSearchParamsToPush = new URLSearchParams(formObject);
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [history],
  );

  const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

  const { searchInput, pageNumber } = useMemo(() => {
    const pageFromSearchParams = urlSearchParams.get('page');
    return {
      searchInput: getLETourSearchInputFromSearchParams(urlSearchParams),
      pageNumber: pageFromSearchParams ? Number(pageFromSearchParams) : 1,
    };
  }, [urlSearchParams]);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
      if (pageIndex) urlSearchParamsToPush.set('page', String(pageIndex + 1));
      else urlSearchParamsToPush.delete('page');
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [urlSearchParams, history],
  );

  return (
    <Container maxWidth="xl">
      <Stack direction="column" spacing={2}>
        <SFTourAddTourForm />
        <LETourSearchForm initialInput={searchInput} onSubmit={handleSearchFormSubmission} />

        <LETourSearchResultsTable
          searchInput={searchInput}
          pageIndex={pageNumber - 1}
          onPageChange={handlePageChange}
        />
      </Stack>
    </Container>
  );
}
