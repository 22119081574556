import React, { Component } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';
import RoomRateBulkInclusionsForm from '~/components/Common/Forms/RoomRateBulkInclusionsForm';
import RoomRateInclusionsForm from '~/components/Common/Forms/RoomRateInclusionsForm';
import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import ReservationService from '~/services/ReservationService';

import RoomRateInclusionsTable from './RoomRateInclusionsTable';

interface Props {
  roomRateInslusions: Array<App.RoomRateInclusion>;
  editing: App.RoomRateInclusion;
  roomType: API.Reservation.RoomType;
  roomRate: API.Reservation.RoomRate;
  isDeletingAll: boolean;
  addingNew: boolean;
  vendorCurrencyCode: string;
  propertyId: string;
  roomRateInslusionsSchema: any;
  onUpdate: () => void;
  onDelete: () => void;
  onDeleteAll: () => void;
  onInclusionsOrderUpdate: () => void;
}

interface State {
  addingNew: boolean;
  editing: App.RoomRateInclusion;
  addingBulked: boolean;
  hideValue: boolean;
}
class RoomRateInclusions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.openAddBox = this.openAddBox.bind(this);
    this.openEditBox = this.openEditBox.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.onRoomRateInclusionUpdated = this.onRoomRateInclusionUpdated.bind(this);

    this.openBulkBox = this.openBulkBox.bind(this);
    this.closeBulkBox = this.closeBulkBox.bind(this);
    this.onRoomRateInclusionBulkAdded = this.onRoomRateInclusionBulkAdded.bind(this);

    this.state = {
      addingNew: false,
      editing: null,
      addingBulked: false,
      hideValue: props.roomRate.inclusions_hide_value,
    };
  }

  openAddBox() {
    this.setState({ addingNew: true });
  }

  openEditBox(inclusionItem) {
    this.setState({
      editing: inclusionItem,
    });
  }

  closeForm() {
    this.setState({
      addingNew: false,
      editing: null,
    });
  }

  onRoomRateInclusionUpdated() {
    this.props.onUpdate();
    this.setState({
      addingNew: false,
      editing: null,
    });
  }

  openBulkBox() {
    this.setState({
      addingBulked: true,
    });
  }

  closeBulkBox() {
    this.setState({
      addingBulked: false,
    });
  }

  onRoomRateInclusionBulkAdded() {
    this.onRoomRateInclusionUpdated();
    this.closeBulkBox();
  }

  changeHideValue = (e) => {
    const flag = e.target.checked;
    this.setState({ hideValue: flag }, async () => {
      await ReservationService.patchRoomRateInclusionHideValue(
        this.props.propertyId,
        this.props.roomType.id,
        this.props.roomRate.id,
        flag,
      );
    });
  };
  render() {
    return (
      <div className="RoomRateInclusions">
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography textTransform="uppercase" color="secondary">
            Room Rate Inclusions
          </Typography>

          <div>
            <FormControlLabel
              label="Hide Value"
              control={
                <Checkbox
                  id="inclusions-hide-value-toggle"
                  name="inclusions-hide-value-toggle"
                  checked={this.state.hideValue}
                  onChange={this.changeHideValue}
                />
              }
            />
          </div>

          <div>
            {this.props.roomRate.rate_plan.inclusions || this.props.roomRate.rate_plan.bonus_inclusions ? (
              <Button onClick={this.openBulkBox}>Copy Rate Plan Inclusions</Button>
            ) : null}
          </div>
        </Stack>

        {this.props.roomRateInslusions.length === 0 ? (
          <p>
            <i>Inclusions have not been defined</i>
          </p>
        ) : (
          <>
            <RoomRateInclusionsTable
              inclusions={this.props.roomRateInslusions}
              inclusionsSchema={this.props.roomRateInslusionsSchema}
              onEdit={this.openEditBox}
              onInclusionsOrderUpdate={this.props.onInclusionsOrderUpdate.bind(this)}
              onDelete={this.props.onDelete.bind(this)}
            />
          </>
        )}

        <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
          <Stack mt={1} direction="row" spacing={1}>
            <Button variant="contained" onClick={this.openAddBox} startIcon={<AddIcon />}>
              Add
            </Button>

            {this.props.roomRateInslusions && this.props.roomRateInslusions.length > 0 && (
              <ConfirmButton
                title="Delete All"
                confirmTitle="Delete All"
                confirmQuestion="Are you sure you want to delete all? This action cannot be undone."
                confirmAnswer="Yes, delete all"
                disabled={this.props.isDeletingAll}
                onConfirm={!this.props.isDeletingAll ? this.props.onDeleteAll : null}
                position="top"
              >
                Delete All
              </ConfirmButton>
            )}
          </Stack>
        </PermissionedComponent>

        <Dialog open={this.state.addingNew || !!this.state.editing} onClose={this.closeForm} maxWidth="md" fullWidth>
          <DialogContent>
            <RoomRateInclusionsForm
              vendorCurrencyCode={this.props.vendorCurrencyCode}
              inclusionItem={this.state.editing}
              inclusionsSchema={this.props.roomRateInslusionsSchema}
              propertyId={this.props.propertyId}
              roomTypeId={this.props.roomType.id}
              roomRateId={this.props.roomRate.id}
              onCancel={this.closeForm}
              onRoomRateInclusionUpdated={this.onRoomRateInclusionUpdated}
            />
          </DialogContent>
        </Dialog>

        <RoomRateBulkInclusionsForm
          open={this.state.addingBulked}
          onHide={this.closeBulkBox}
          vendorCurrencyCode={this.props.vendorCurrencyCode}
          propertyId={this.props.propertyId}
          roomTypeId={this.props.roomType.id}
          roomRate={this.props.roomRate}
          onCancel={this.closeBulkBox}
          onRoomRateInclusionBulkAdded={this.onRoomRateInclusionBulkAdded}
        />
      </div>
    );
  }
}

export default RoomRateInclusions;
