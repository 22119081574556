import React from 'react';

import { Container as MuiContainer } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { buttonStates } from '~/components/Common/Forms/states/submitButton';
import Spinner from '~/components/Common/Spinner';

import OffersService from '~/services/OffersService';

import { Component } from './Component';

const getImageDataForUpdate = (image) => ({
  id_cloudinary_external: image.id_cloudinary_external,
  title: image.title || '',
  order: image.order,
});

export class Container extends React.Component {
  state = {
    isLoading: true,
    addon: null,
    addonOption: null,
    images: [],
    error: null,
    saveState: buttonStates.default,
  };

  constructor(props) {
    super(props);

    this.vendorId = props.match.params.id_vendor;
    this.addonId = props.match.params.id_addon;
    this.addonDealOptionId = props.match.params.id_addon_deal_option;
  }

  componentDidMount() {
    const promises = [this.fetchAddon(), this.fetchAddonOption(), this.fetchImages()];

    Promise.all(promises)
      .then(([addon, addonOption, images]) => this.setState({ isLoading: false, addon, addonOption, images }))
      .catch((err) => {
        console.warn(err);
        this.setState({
          isLoading: false,
          error: "Something went wrong. Can't load images",
        });
      });
  }

  fetchAddonOption = async () => {
    const response = await OffersService.getVendorAddonOption(this.vendorId, this.addonId, this.addonDealOptionId);
    return response.result;
  };

  fetchAddon = async () => {
    const response = await OffersService.getVendorAddon(this.vendorId, this.addonId);

    return response.result;
  };

  fetchImages = async () => {
    const response = await OffersService.getAddonImages(this.addonDealOptionId);
    return response.result;
  };

  handleUploadImage = (_, cloudinaryId) => {
    const { images } = this.state;

    const data = {
      id_cloudinary_external: cloudinaryId,
      order: images.length + 1,
    };

    OffersService.createAddonImage(this.addonDealOptionId, data)
      .then((response) => response.result)
      .then((newImage) => {
        images.push(newImage);
        this.setState({ images });
      });
  };

  handleDeleteImage = (imageId) => () => {
    if (!confirm('Are you sure you want to delete this image?')) {
      return;
    }

    OffersService.deleteAddonImage(this.addonDealOptionId, imageId).then(() =>
      this.setState((prevState) => ({
        images: prevState.images.filter((image) => image.id_image !== imageId),
      })),
    );
  };

  handleChangeTitle = (imageId) => (e) => {
    const value = e.target.value;
    const images = this.state.images;

    const image = images.find((image) => image.id_image === imageId);

    const data = {
      ...getImageDataForUpdate(image),
      title: value,
    };

    this.setState({
      images: images.map((existingImage) =>
        existingImage.id_image === imageId ? { ...image, ...data } : existingImage,
      ),
    });
  };

  handleSaveChanges = () => {
    // @todo
    // Current restriction: only one image
    // Will be changed after preparing design for multiple images on customer portal

    this.setState({ saveState: buttonStates.saving });

    const images = this.state.images;

    if (images.length == 0) {
      return;
    }

    const image = images[0];

    OffersService.updateAddonImage(this.addonDealOptionId, image.id_image, {
      ...getImageDataForUpdate(image),
    })
      .then(() => this.setState({ saveState: buttonStates.saved }))
      .catch((err) => {
        console.warn(err);
        this.setState({
          saveState: buttonStates.failed,
          error: "Something went wrong. Can't save image.",
        });
      });
  };

  render() {
    const { isLoading, error, addon, addonOption, images, saveState } = this.state;

    return (
      <MuiContainer maxWidth="xl">
        {error && <ErrorDisplay message={error} />}

        {isLoading && <Spinner />}

        {!isLoading && !error && (
          <Component
            addon={addon}
            addonOption={addonOption}
            images={images}
            saveState={saveState}
            onImageUpload={this.handleUploadImage}
            onImageDelete={this.handleDeleteImage}
            onTitleChange={this.handleChangeTitle}
            onChangesSave={this.handleSaveChanges}
          />
        )}
      </MuiContainer>
    );
  }
}
