import React, { useCallback, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import UserSearchWidget from '~/components/Common/UserSearchWidget';
import OfferSearchWidget from '~/components/Marketing/CompedPackages/OfferSearchWidget';

import OffersService from '~/services/OffersService';
import { addCompedPackage, updateCompedPackage } from '~/services/PromoService';
import UsersService from '~/services/UsersService';

const BRAND = 'luxuryescapes';

export interface CompedPackage {
  id_comped_package: number;
  fk_offer_id: string;
  fk_customer_id: string;
  description: string | null;
  status: string;
  added_by: string;
}

interface CompedPackagesFormProps {
  compedPackage?: CompedPackage;
  onSuccess?: () => void;
}

const CompedPackagesForm = ({ compedPackage, onSuccess = () => null }: CompedPackagesFormProps) => {
  const [compedPackageState, setCompedPackageState] = useState<CompedPackage | Record<string, unknown>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[] | null>([]);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);
  const [userDetails, setUserDetails] = useState<App.UserSummary | null>(null);
  const [addedByDetails, setAddedByDetails] = useState<App.UserSummary | null>(null);

  const [offerDetails, setOfferDetails] = useState<App.Offer | null>(null);

  const isInEditMode = !!compedPackage?.id_comped_package;

  const canBeEdited = compedPackage?.status === 'pending';

  useEffect(() => {
    async function fetchCompedPackageDetails() {
      setLoading(true);
      const { result: offer } = await OffersService.getOffer(compedPackage?.fk_offer_id);
      const usersMap = (await UsersService.getUsersSummaryByIds(
        [compedPackage?.fk_customer_id, compedPackage?.added_by],
        BRAND,
      )) as Map<string, App.UserSummary>;

      setOfferDetails(offer);

      setUserDetails(usersMap.get(compedPackage?.fk_customer_id));
      setAddedByDetails(usersMap.get(compedPackage?.added_by));

      setLoading(false);
    }
    if (compedPackage) {
      setCompedPackageState(compedPackage);
      fetchCompedPackageDetails();
    }
  }, [compedPackage]);

  useEffect(() => {
    if (!compedPackageState?.fk_customer_id) {
      setUserDetails(null);
      return;
    }
    if (compedPackageState.fk_customer_id !== userDetails?.id_member) {
      UsersService.getUserSummary(compedPackageState?.fk_customer_id, BRAND).then((userDetail) => {
        setUserDetails(userDetail);
      });
    }
  }, [compedPackageState?.fk_customer_id, userDetails?.id_member]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCompedPackageState(e.target.name, e.target.value);
  };

  const updateCompedPackageState = (key, value) => {
    setCompedPackageState({
      ...compedPackageState,
      [key]: value,
    });
  };

  const saveCompedPackage = useCallback(
    async (e) => {
      setLoading(true);
      setFormErrors(null);
      setSuccess(null);
      setErrors(null);
      e.preventDefault();

      const validateForm = () => {
        const required = ['fk_offer_id', 'fk_customer_id', 'description'];
        const valid = required.filter((prop) => !Object.prototype.hasOwnProperty.call(compedPackageState, prop));
        return valid;
      };

      const compedId = compedPackage?.id_comped_package;
      const formValidation = validateForm();
      if (formValidation.length) {
        setFormErrors(formValidation);
        setLoading(false);
        return;
      }

      try {
        const newCompedPackage = {
          fk_offer_id: compedPackageState.fk_offer_id,
          fk_customer_id: compedPackageState.fk_customer_id,
          description: compedPackageState.description,
        };

        if (compedId) {
          await updateCompedPackage(compedId, newCompedPackage);
        } else {
          await addCompedPackage(newCompedPackage);
        }

        setSuccess(`Prize winner successfully ${compedId ? 'updated' : 'added'}`);
        onSuccess();
      } catch (e) {
        setErrors(
          `Failed to ${compedId ? 'update' : 'add'} prize winner: ${e?.message} ${e?.errors
            ?.map((e) => e.message)
            .join(', ')}`,
        );
      } finally {
        setLoading(false);
      }
    },
    [compedPackage, compedPackageState, onSuccess],
  );

  return (
    <form onSubmit={saveCompedPackage}>
      <Box p={3} mb={2}>
        {(errors || success) && (
          <Grid container justifyContent="center" direction="column">
            <Stack sx={{ width: '100%', mb: 2 }}>
              {errors && <Alert severity="error">{errors}</Alert>}
              {success && <Alert severity="success">{success}</Alert>}
            </Stack>
          </Grid>
        )}
        <Grid
          container
          justifyContent="center"
          direction="column"
          spacing={1}
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {isInEditMode && (
            <Grid item md={3} sm={6} xs={6}>
              <TextField
                disabled
                value={userDetails?.email}
                id="email-prize-winner"
                label="Prize winner email"
                InputLabelProps={{
                  shrink: !!compedPackageState?.id_comped_package,
                }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item md={3} sm={6} xs={6}>
            <OfferSearchWidget
              id="fk_offer_id"
              value={compedPackage?.fk_offer_id}
              label={offerDetails?.name}
              formErrors={formErrors}
              loading={loading}
              onChange={updateCompedPackageState}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={6}>
            <UserSearchWidget
              id="fk_customer_id"
              disabled={isInEditMode && !canBeEdited}
              value={compedPackage?.fk_customer_id}
              label={userDetails?.full_name ?? 'Gift receiver: Name / Email / QFF / Customer Support Code / User ID'}
              formErrors={formErrors}
              loading={loading}
              onChange={updateCompedPackageState}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={6}>
            <TextField
              error={formErrors?.includes('description')}
              value={compedPackageState?.description}
              onChange={handleChange}
              name="description"
              id="description-id"
              label="Why is this person receiving this trip?"
              InputLabelProps={{
                shrink: !!compedPackageState?.description,
              }}
              fullWidth
              disabled={isInEditMode && !canBeEdited}
            />
            {formErrors?.includes('description') && <FormHelperText id="component-error-text">Error</FormHelperText>}
          </Grid>
          {isInEditMode && (
            <Grid item md={3} sm={6} xs={6}>
              <FormControl sx={{ minWidth: 240 }} fullWidth error={formErrors?.includes('status')}>
                <InputLabel id="comped-status-label">Status</InputLabel>
                <Select
                  error={formErrors?.includes('status')}
                  labelId="comped-status-label"
                  id="comped-status"
                  value={compedPackageState?.status || ''}
                  name="status"
                  label="Status"
                  disabled
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value={'pending'}>Pending</MenuItem>
                  <MenuItem value={'used'}>Used</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {isInEditMode && (
            <Grid item md={3} sm={6} xs={6}>
              <TextField
                disabled
                value={addedByDetails?.full_name || compedPackage.added_by}
                id="added-by-id"
                label="Added By"
                InputLabelProps={{
                  shrink: !!compedPackageState?.added_by,
                }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item md={3} sm={6} xs={6} justifyContent="flex-end">
            <Button size="large" variant="contained" disabled={loading} type="submit">
              Save
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default CompedPackagesForm;
