import React, { useEffect, useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import OffersService from '~/services/OffersService';
import UsersService from '~/services/UsersService';

import type { ScheduleLogRecordWithUser, UserSummaryType } from '~/types/responses';

import ScheduleHistoryList from './ScheduleHistoryList';

const filterLogs = (logs: ScheduleLogRecordWithUser[], region: string) => {
  if (region === '') {
    return logs;
  }

  return logs.filter((log) => log.region === region);
};

type Props = {
  offerId: string;
  type: string;
  brand: App.Brands;
};

export default function ScheduleHistory({ offerId, type, brand }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [logs, setLogs] = useState<ScheduleLogRecordWithUser[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<ScheduleLogRecordWithUser[]>([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');

  useEffect(() => {
    setFilteredLogs(filterLogs(logs, selectedRegion));
  }, [selectedRegion, logs]);

  const handleOpen = async () => {
    try {
      setOpen(true);
      setLoadingState('loading');
      setError(null);
      setLogs([]);
      setRegions([]);
      setSelectedRegion('');
      setFilteredLogs([]);

      const response = await OffersService.getScheduleLogs(offerId, { type, brand });

      const userIds = [...new Set(response.logs.map((log) => log.fk_member_id))];
      const userDetails: Map<string, UserSummaryType> = await UsersService.getUsersSummaryByIds(userIds, brand);

      const logs = response.logs as ScheduleLogRecordWithUser[];
      for (const log of logs) {
        log.user = userDetails.get(log.fk_member_id);
      }

      setLogs(logs);
      setRegions([...new Set(logs.map((log) => log.region).sort())]);
      setLoadingState('success');
    } catch (err) {
      setError(err.message);
      setLoadingState('failed');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleOpen} title="Schedule history">
        <AccessTimeIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="schedule-history-title" scroll="paper" maxWidth="md">
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <DialogTitle id="schedule-history-title">Schedule History</DialogTitle>

          {regions.length > 1 && (
            <Box pr={3}>
              <FormControl sx={{ width: 80 }} size="small" variant="standard">
                <Select
                  label="Region"
                  id="region-select"
                  value={selectedRegion}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  size="medium"
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  {regions.map((region) => (
                    <MenuItem key={region} value={region}>
                      {region}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Stack>

        <DialogContent sx={{ pt: 0 }}>
          {loadingState === 'loading' && (
            <DialogContentText textAlign="center">
              <Spinner size={56} inline />
            </DialogContentText>
          )}

          {loadingState === 'failed' && <ErrorDisplay message={`Can't load schedule logs: ${error}`} />}

          {loadingState === 'success' && <ScheduleHistoryList logs={filteredLogs} />}
        </DialogContent>
      </Dialog>
    </div>
  );
}
