import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';
import Spinner from '~/components/Common/Spinner';
import { withTenant } from '~/components/hoc';

import { ITEM_TYPE_CAR_HIRE } from '~/consts/order.js';

import { getPurchases } from '~/services/OrdersService';

import { addQuery, parseSearchString, removeQuery } from '~/utils/url';

import CarHirePurchaseList from './CarHirePurchaseList';

const DEFAULT_SIZE_PER_PAGE = 10;

type Props = {
  tenant: App.Tenant;
};

const CarHirePurchasePage = (props: Props): JSX.Element => {
  const { tenant } = props;
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { push: setQueryString } = useHistory();

  const [filter, setFilter] = useState({
    page: null,
    field: null,
    value: null,
    endDate: null,
    startDate: null,
    customerId: null,
  });
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);

  const onPageChange = async (page: number): Promise<void> => {
    setQueryString(addQuery(location, { page }));
  };

  const handleSearchString = ({ customer_id, page }): void => {
    setFilter((prev) => ({
      ...prev,
      customerId: customer_id as string,
      page: parseInt(page as string) || 1,
    }));
  };

  const searchQuery = async (field, value): Promise<void> => {
    setFilter((prev) => ({ ...prev, field, value }));

    setQueryString(removeQuery(location, 'customer_id', 'page'));
  };

  const fetchPurchaseList = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const { result, total } = await getPurchases({
        page: filter.page,
        per_page: DEFAULT_SIZE_PER_PAGE,
        brand: tenant.brand,
        customer_id: filter.customerId,
        filterBy: filter.field,
        filterValue: filter.value,
        additionalFilter: additionalSearchParams,
        item_type: ITEM_TYPE_CAR_HIRE,
      });

      setTotal(total);
      setResult(result);
    } catch (e) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, filter, additionalSearchParams, tenant.brand]);

  useEffect(() => {
    const { customer_id, page } = parseSearchString(location.search);
    handleSearchString({ customer_id, page });
  }, [location.search]);

  useEffect(() => {
    if (filter.page) {
      fetchPurchaseList();
    }
  }, [filter, fetchPurchaseList]);

  return (
    <>
      <OrderSearchForm
        e2eSuffix="-car-hire"
        searchQuery={searchQuery}
        bookingIdType="booking_numbers"
        setAdditionalParams={setAdditionalSearchParams}
        bookingNumberPlaceholder="Enter Car Hire Booking Number"
        showCarHireFilter
      />

      <>
        {loading && <Spinner size={48} />}

        {!loading && result && (
          <CarHirePurchaseList
            page={filter.page}
            total={total}
            orders={result}
            sizePerPage={DEFAULT_SIZE_PER_PAGE}
            onPageChange={onPageChange}
            rerender={fetchPurchaseList}
          />
        )}
      </>
    </>
  );
};

export default withRouter(withTenant(CarHirePurchasePage));
