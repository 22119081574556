import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '@mui/material';

import { vendor } from '@luxuryescapes/lib-global';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import {
  addOffer,
  addOfferToCart,
  allowToggleReservationType,
  emptyCart,
  setOrderId,
  setReservationType,
} from '../../../actions/cart';
import { HOTEL_OFFER_TYPES, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR_V2 } from '../../../consts/offerTypes';
import { getCreditReservationByPnrId } from '../../../services/FlightsService';
import { getCustomerMembershipSubscriptions } from '../../../services/MembershipService';
import {
  resendBookingConfirmation,
  resendDepositBookingConfirmation,
  resendGiftBookingConfirmation,
  resendOrderGiftDeliveryEmail,
  resendVendorBookingConfirmation,
} from '../../../services/NotificationService';
import OffersService from '../../../services/OffersService';
import { getItemCheckInStatus, getOrderDetail } from '../../../services/OrdersService';
import { getRefundMeta } from '../../../services/PaymentsService';
import { getDetails } from '../../../services/TravellerService';
import UsersService from '../../../services/UsersService';
import VendorsService from '../../../services/VendorsService';
import { getOffersFromAccommodationItems } from '../../../utils/getOffersFromAccommodationItems';
import { preloadRoomType } from '../../../utils/preloadOfferAssociations';
import { reportError } from '../../../utils/reportError';
import Spinner from '../../Common/Spinner';

import OrderDetailPage from './OrderDetailPage';

const getDefaultEmailState = () => ({
  isSending: false,
  errorSending: null,
  successSending: null,
});

const getSendingState = () => ({
  isSending: true,
  errorSending: null,
  successSending: null,
});

const getErrorSendingState = (err) => ({
  isSending: false,
  errorSending: err.message,
  successSending: null,
});

const getSuccessSendingState = (msg) => ({
  isSending: false,
  errorSending: null,
  successSending: msg,
});

export class OrderDetailPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offers: null,
      isReady: false,
      dataError: '',
      isBookingDates: false,
      isChangingDates: false,
      billingCountry: null,
      emailToCustomerState: getDefaultEmailState(),
      emailToVendorState: getDefaultEmailState(),
      customerEmailToAgentState: getDefaultEmailState(),
      vendorEmailToAgentState: getDefaultEmailState(),
      checkInStatus: null,
      refunds: null,
      flightsCreditReservationId: null,
      subscriptions: null,
    };
  }

  componentDidMount() {
    this.refreshData().catch((err) => {
      this.setState({ dataError: err });
    });
  }

  getTravellers = async (orderId) => {
    let travellers = [];

    try {
      travellers = await getDetails(orderId, true, true);
    } catch (e) {
      reportError(e);
    }

    this.setState({
      travellers,
    });
  };

  getCreditReservationId = async (order) => {
    let creditReservationId = null;
    try {
      const pnrId = order?.flight_items?.[0]?.pnr_id;
      if (pnrId) {
        const creditReservation = await getCreditReservationByPnrId(pnrId);
        creditReservationId = creditReservation?.result?.id;
      }
    } catch (err) {
      creditReservationId = null;
    }
    return creditReservationId;
  };

  getCustomerSubscriptionsData = async (customerId) => {
    try {
      const { result: fetchedSubscriptions } = await getCustomerMembershipSubscriptions({ customerId });
      return fetchedSubscriptions;
    } catch (e) {
      /*
          Once auth returns whether a user is a member and
          we only fetch subscriptions for members, this error will be displayed
       */
      return null;
    }
  };

  refreshData = async () => {
    this.setState({ isReady: false }, async () => {
      const {
        match: { params },
      } = this.props;

      const order = await getOrderDetail(params.id_orders);
      const customer = await UsersService.getUser(order.fk_customer_id);
      const orderOffers = getOffersFromAccommodationItems(order.items);
      const refunds = await getRefundMeta(order.id_orders);
      const flightsCreditReservationId = await this.getCreditReservationId(order);
      const subscriptions = await this.getCustomerSubscriptionsData(customer.id_member);

      const offers = await Promise.all(
        orderOffers.map(async ({ offer, items }) => {
          const result = {};

          // Load details about the offers
          const offerResponse = await OffersService.getOffer(offer.id_salesforce_external);
          result.offer = {
            ...offerResponse.result,
            bundle_offer_id: offer.bundle_offer_id,
          };

          // Load details for ordered packages
          const orderedPackages = items.map((i) => i.offer_package);
          result.offer.packages = result.offer.packages.filter((p) =>
            orderedPackages.some((op) => p.id_salesforce_external === op.id_salesforce_external),
          );

          result.offer.billingCountry = (
            await VendorsService.getVendorById(result.offer.vendor_account_id)
          ).result.billingcountry;

          if (HOTEL_OFFER_TYPES.includes(offer.type)) {
            // Load room types
            result.offer.packages = await Promise.all(result.offer.packages.map(async (p) => preloadRoomType(p)));

            // Load check-in status
            result.items = await Promise.all(
              items.map(async (i) => ({
                ...i,
                checkInStatus: (await getItemCheckInStatus(order.id_orders, i.id)).result,
              })),
            );
          } else {
            result.items = items;
          }
          return result;
        }),
      );

      // Tours are not supported for multi-booking
      if (
        offers.length == 1 &&
        offers[0].offer.type === 'tour' &&
        vendor.requiresTravellerDetails(offers[0].vendor_account_id)
      ) {
        await this.getTravellers(params.id_orders);
      }

      this.setState({
        isReady: true,
        offers,
        order,
        customer,
        refunds,
        flightsCreditReservationId,
        subscriptions,
      });
    });
  };

  setIsBookingDates = (val) => {
    this.setState({ isBookingDates: val });
  };

  setIsChangingDates = (val) => {
    this.setState({ isChangingDates: val });
  };

  setIsConvertingToBNBL = (val) => {
    this.setState({ isConvertingToBNBL: val });
  };

  setIsPuttingOnHold = (val) => {
    this.setState({ isPuttingOnHold: val });
  };

  resendCustomerEmail = (offerId, offerType = OFFER_TYPE_HOTEL) => {
    this.setState({ emailToCustomerState: getSendingState() }, async () => {
      try {
        const depositDetails = this.state.order?.payments?.[0]?.depositDetails;
        const giftStatus = this.state.order?.gift_status;
        const giftAwaitingDelivery = giftStatus === 'awaiting_delivery';
        const giftPendingRedemption = giftStatus === 'pending_redemption';

        const newGiftGiverConfirmationEmailEnabled =
          (giftAwaitingDelivery || giftPendingRedemption) && window.configs.ENABLE_NEW_GIFT_GIVER_CONFIRMATION_EMAIL;

        if (depositDetails && offerType !== OFFER_TYPE_TOUR_V2) {
          await resendDepositBookingConfirmation({
            orderId: this.state.order.id_orders,
            offerId,
          });
        } else if (newGiftGiverConfirmationEmailEnabled) {
          await resendGiftBookingConfirmation({
            orderId: this.state.order.id_orders,
            offerId,
          });
        } else {
          await resendBookingConfirmation({
            orderId: this.state.order.id_orders,
            offerId,
            offerType,
            brand: this.state.order.brand,
          });
        }

        this.setState({
          emailToCustomerState: getSuccessSendingState('Successfully resent customer confirmation email'),
        });
      } catch (err) {
        this.setState({
          emailToCustomerState: getErrorSendingState(err),
        });
      }
    });
  };

  resendOrderGiftDeliveryEmail = () => {
    this.setState({ emailToCustomerState: getSendingState() }, async () => {
      try {
        await resendOrderGiftDeliveryEmail({
          orderId: this.state.order.id_orders,
        });

        this.setState({
          emailToCustomerState: getSuccessSendingState(
            'Successfully resend the gift redemption email to the recipient',
          ),
        });
      } catch (err) {
        this.setState({
          emailToCustomerState: getErrorSendingState(err),
        });
      }
    });
  };

  resendVendorEmail = async (offerId, offerType = OFFER_TYPE_HOTEL) => {
    this.setState({ emailToVendorState: getSendingState() }, async () => {
      try {
        await resendVendorBookingConfirmation({
          orderId: this.state.order.id_orders,
          offerId,
          offerType,
        });
        this.setState({
          emailToVendorState: getSuccessSendingState('Successfully resent vendor confirmation email'),
        });
      } catch (err) {
        this.setState({
          emailToVendorState: getErrorSendingState(err),
        });
      }
    });
  };

  resendCustomerEmailToAgent = (offerId, offerType = OFFER_TYPE_HOTEL) => {
    this.setState(
      {
        customerEmailToAgentState: getSendingState(),
      },
      async () => {
        try {
          const giftStatus = this.state.order?.gift_status;
          const giftAwaitingDelivery = giftStatus === 'awaiting_delivery';
          const giftPendingRedemption = giftStatus === 'pending_redemption';
          const newGiftGiverConfirmationEmailEnabled =
            (giftAwaitingDelivery || giftPendingRedemption) && window.configs.ENABLE_NEW_GIFT_GIVER_CONFIRMATION_EMAIL;

          if (newGiftGiverConfirmationEmailEnabled && offerType !== OFFER_TYPE_TOUR_V2) {
            await resendGiftBookingConfirmation({
              orderId: this.state.order.id_orders,
              offerId,
              sendToAgent: true,
            });
          } else {
            await resendBookingConfirmation({
              orderId: this.state.order.id_orders,
              offerId,
              offerType,
              sendToAgent: true,
              brand: this.state.order.brand,
            });
          }

          this.setState({
            customerEmailToAgentState: getSuccessSendingState(
              'Successfully resent customer confirmation email to agent',
            ),
          });
        } catch (err) {
          this.setState({
            customerEmailToAgentState: getErrorSendingState(err),
          });
        }
      },
    );
  };

  resendVendorEmailToAgent = async (offerId, offerType = OFFER_TYPE_HOTEL) => {
    this.setState(
      {
        vendorEmailToAgentState: getSendingState(),
      },
      async () => {
        try {
          await resendVendorBookingConfirmation({
            orderId: this.state.order.id_orders,
            offerId,
            offerType,
            sendToAgent: true,
          });

          this.setState({
            vendorEmailToAgentState: getSuccessSendingState('Successfully resent vendor confirmation email to agent'),
          });
        } catch (err) {
          this.setState({
            vendorEmailToAgentState: getErrorSendingState(err),
          });
        }
      },
    );
  };

  render() {
    const { offers, order, customer, travellers, refunds, isReady, flightsCreditReservationId, subscriptions } =
      this.state;
    const { isBookingDates, isChangingDates, isConvertingToBNBL, dataError } = this.state;

    if (dataError) {
      return (
        <Container maxWidth="xl">
          <ErrorDisplay message={`Failed to load offer data: ${dataError}`} />
        </Container>
      );
    }

    if (!isReady) {
      return <Spinner />;
    }

    return (
      <Container maxWidth="xl">
        <OrderDetailPage
          order={order}
          offers={offers}
          customer={customer}
          travellers={travellers}
          refunds={refunds}
          refreshData={this.refreshData}
          onEmptyCart={this.props.doEmptyCart}
          selectOffer={this.props.doSelectOffer}
          addOfferToCart={this.props.doAddOfferToCart}
          setOrderId={this.props.doSetOrderId}
          setReservationType={this.props.doSetReservationType}
          allowToggleReservationType={this.props.doAllowToggleReservationType}
          setIsBookingDates={this.setIsBookingDates}
          setIsChangingDates={this.setIsChangingDates}
          setIsConvertingToBNBL={this.setIsConvertingToBNBL}
          setIsPuttingOnHold={this.setIsPuttingOnHold}
          isBookingDates={isBookingDates}
          isChangingDates={isChangingDates}
          isConvertingToBNBL={isConvertingToBNBL}
          resendCustomerEmail={this.resendCustomerEmail}
          emailToCustomerState={this.state.emailToCustomerState}
          resendVendorEmail={this.resendVendorEmail}
          emailToVendorState={this.state.emailToVendorState}
          resendCustomerEmailToAgent={this.resendCustomerEmailToAgent}
          customerEmailToAgentState={this.state.customerEmailToAgentState}
          resendVendorEmailToAgent={this.resendVendorEmailToAgent}
          vendorEmailToAgentState={this.state.vendorEmailToAgentState}
          getTravellers={this.getTravellers}
          flightsCreditReservationId={flightsCreditReservationId}
          resendOrderGiftDeliveryEmail={this.resendOrderGiftDeliveryEmail}
          subscriptions={subscriptions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = {
  doEmptyCart: emptyCart,
  doSelectOffer: addOffer,
  doAddOfferToCart: addOfferToCart,
  doSetOrderId: setOrderId,
  doAllowToggleReservationType: allowToggleReservationType,
  doSetReservationType: setReservationType,
};

OrderDetailPageContainer.contextTypes = {
  user: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(OrderDetailPageContainer);
