import React from 'react';

import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { humanize } from '~/utils/humanize';

type EmailType = 'customer' | 'vendor';

interface Props {
  emailType: EmailType;
  resendEmail: (offerId: string, offerType?: string) => void;
  resendEmailToAgent: (offerId: string, offerType?: string) => void;
  offerId?: string;
  offerType?: string;
  isCustomerEmailDisabled: boolean;
  isAgentEmailDisabled: boolean;
}

export default function ResendEmail({
  emailType,
  resendEmail,
  resendEmailToAgent,
  isCustomerEmailDisabled,
  isAgentEmailDisabled,
  offerId,
  offerType,
}: Props) {
  const handleResendEmail = (event) => {
    event.stopPropagation();
    resendEmail(offerId, offerType);
  };

  const handleResendEmailToAgent = (event) => {
    event.stopPropagation();
    resendEmailToAgent(offerId, offerType);
  };

  return (
    <Grid container direction="row">
      <Grid xs={6}>
        <Typography color="black">Resend {emailType} booking email to:</Typography>
      </Grid>
      <Grid xs={2}>
        <Button variant="text" size="small" onClick={handleResendEmail} disabled={isCustomerEmailDisabled}>
          {humanize(emailType)}
        </Button>
      </Grid>
      <Grid xs={2}>
        <Button variant="text" size="small" onClick={handleResendEmailToAgent} disabled={isAgentEmailDisabled}>
          Agent
        </Button>
      </Grid>
    </Grid>
  );
}
