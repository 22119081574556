import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableCell, TableRow, Tooltip } from '@mui/material';

import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer';

import { FlexTableCell } from '~/components/Content/SearchRanking/FlexTableCell';
import SegmentCellGroups from '~/components/Content/SearchRanking/Segment/SegmentCellGroups';

import OffersService from '~/services/OffersService';
import PublicOfferService from '~/services/PublicOfferService';
import SearchService from '~/services/SearchService';

import currencyFormatter from '~/utils/currencyFormatter';
import isUUID from '~/utils/isUUID';

interface IExpectedValueData {
  views: string;
  salesCount: string;
  salesAmount: string;
  marginAmount: string;
  marginPercentage: string;
  expectedValue: string;
  expectedValueInfo?: string;
  adjustedExpectedValue: string;
  adjustedExpectedValueInfo?: string;
  expectedValueScore: string;
  tierScore: string;
  overrideScore: string;
  overrideActive: boolean;
  ctr: string;
  calculatedScore: string;
  info?: string;
}

function SearchHotelRow({
  offerId,
  region,
  segments,
  variant,
}: {
  offerId: string;
  region: string;
  segments: Array<App.Segment>;
  variant: string;
}) {
  const isMountedRef = useRef(true);
  const kind = useMemo(() => (isUUID(offerId) ? 'bedbank' : 'luxuryescapes'), [offerId]);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [offerDetails, setOfferDetails] = useState<App.Offer | PublicOfferV2.BedbankOffer | undefined>();
  const [offerScore, setOfferScore] = useState<App.OfferScore | undefined>();
  const [segmentedScores, setSegmentedScores] = useState<Array<App.SegmentedOfferScore>>([]);

  useEffect(() => {
    (async () => {
      try {
        setFetchingState('loading');
        const [score, offerDetails, segmentedScores] = await Promise.all([
          SearchService.getHotelScoreForVariant(offerId, region, variant),
          kind === 'bedbank'
            ? PublicOfferService.getBedbankOfferById(offerId, region, 'luxuryescapes')
            : OffersService.getOffer(offerId),
          SearchService.getOfferSegmentedScores(offerId, segments),
        ]);

        if (!isMountedRef.current) {
          return;
        }

        setOfferScore(score.result.score);
        setOfferDetails(offerDetails.result);
        setSegmentedScores(segmentedScores?.result || []);
        setFetchingState('success');
      } catch (error) {
        setFetchingState('failed');
      }
    })();

    return () => {
      isMountedRef.current = false;
    };
  }, [kind, offerId, region, segments, variant]);

  const schedule = useMemo(() => {
    if (!offerDetails || offerDetails.type === 'bedbank_hotel' || !offerDetails.brand_schedules) {
      return null;
    }
    const schedules = offerDetails.brand_schedules.find(
      (s: { type: string; start: string; end: string }) => s.type === 'list_visibility',
    ) as { start: string; end: string };
    const startDate = new Date(schedules?.start || '');
    const endDate = new Date(schedules?.end || '');
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  }, [offerDetails]);

  const expectedValueData: IExpectedValueData = useMemo(() => {
    if (offerScore) {
      const marginPercentage =
        offerScore.totalSales > 0 ? ((offerScore.totalMargin / offerScore.totalSales) * 100).toFixed(2) + '%' : 'N/A';
      return {
        views: offerScore.numberOfViews.toLocaleString(),
        salesCount: offerScore.salesCount.toLocaleString(),
        salesAmount: currencyFormatter('AUD', offerScore.totalSales),
        marginAmount: currencyFormatter('AUD', Math.round(offerScore.totalMargin)),
        marginPercentage,
        expectedValue: offerScore.expectedValue.toFixed(3),
        expectedValueInfo: offerScore.expectedValueInfo,
        adjustedExpectedValue: offerScore.adjustedExpectedValue.toFixed(3),
        adjustedExpectedValueInfo: offerScore.adjustedExpectedValueInfo,
        expectedValueScore: offerScore.expectedValueScore.toFixed(3),
        tierScore: (offerScore.tierScore * offerScore.tierWeight).toFixed(1),
        overrideScore: (offerScore.overrideScore * offerScore.overrideWeight).toFixed(1),
        overrideActive: new Date(offerScore.overrideExpireDate) >= new Date(),
        ctr: (offerScore.ctr * 100).toFixed(2) + '%',
        calculatedScore: offerScore.calculatedScore.toFixed(3),
        info: offerScore.info,
      };
    }
  }, [offerScore]);

  const offerType = useMemo(() => {
    if (!offerDetails) {
      return null;
    }
    if ('promotions' in offerDetails && offerDetails.promotions.length > 0) {
      return 'limited_time_special';
    }
    return offerDetails.type;
  }, [offerDetails]);

  const isBedbankType = useMemo(() => {
    return offerType === 'bedbank_hotel' || offerType === 'limited_time_special';
  }, [offerType]);

  const finalScore = useMemo(
    () => getFinalScore(expectedValueData, segments, segmentedScores),
    [expectedValueData, segmentedScores, segments],
  );

  const columnNum = useMemo(() => segments.length * 5 + 13, [segments]);
  const offerName = useMemo(() => {
    if (!offerDetails) {
      return '';
    }

    if (isBedbankType || offerType === 'rental') {
      return offerDetails.name;
    }

    if ('location' in offerDetails) {
      return offerDetails.location;
    }

    return '';
  }, [isBedbankType, offerDetails, offerType]);

  return (
    <>
      {fetchingState == 'loading' && (
        <TableRow>
          <TableCell component="th" colSpan={columnNum}>
            Loading...
          </TableCell>
        </TableRow>
      )}
      {fetchingState == 'success' && offerDetails && (
        <TableRow>
          <TableCell align="left" component="th" scope="row">
            <Link to={isBedbankType ? `/bedbank/properties/${offerId}` : `/offers/${offerId}`}>{offerName}</Link>
          </TableCell>
          <TableCell align="right">{offerType}</TableCell>
          <TableCell align="right">{schedule}</TableCell>
          <TableCell align="right">{expectedValueData.views}</TableCell>
          <TableCell align="right">{expectedValueData.salesCount}</TableCell>
          <TableCell align="right">{expectedValueData.salesAmount}</TableCell>
          <TableCell align="right">{expectedValueData.marginAmount}</TableCell>
          <TableCell align="right">{expectedValueData.marginPercentage}</TableCell>
          <FlexTableCell align="right">{expectedValueData.expectedValue}</FlexTableCell>
          <FlexTableCell align="right">
            {expectedValueData.adjustedExpectedValue}
            {expectedValueData.adjustedExpectedValueInfo && (
              <Tooltip title={expectedValueData.adjustedExpectedValueInfo} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <TableCell align="right">{expectedValueData.tierScore}</TableCell>
          <TableCell align="right">{expectedValueData.overrideScore}</TableCell>
          <SegmentCellGroups segments={segments} segmentedScores={segmentedScores} />
          <TableCell align="right">{expectedValueData.ctr}</TableCell>
          <TableCell align="right">{expectedValueData.expectedValueScore}</TableCell>
          <FlexTableCell align="right">
            {finalScore}
            {expectedValueData.info && (
              <Tooltip title={expectedValueData.info}>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
            {expectedValueData.overrideActive && (
              <Tooltip title="Using admin override score">
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
        </TableRow>
      )}
      {fetchingState == 'failed' && (
        <TableRow>
          <TableCell component="th" colSpan={columnNum}>
            Failed to load offer: {offerId}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function getFinalScore(
  regionEvData: IExpectedValueData,
  segments: Array<App.Segment>,
  segmentedScores: Array<App.SegmentedOfferScore>,
): number {
  const regionScore = regionEvData ? parseFloat(regionEvData.calculatedScore) : 0;

  if (segments.length === 0) {
    return regionScore;
  }

  const totalScore = segments.reduce((acc, segment) => {
    const segmentValue = segment.value.split(':')[1];
    const segmentScore = segmentedScores.find((s) => s.segment === segmentValue);
    return acc + (segmentScore?.expectedValueScore || 0) * segment.weight;
  }, regionScore);
  const totalWeight = segments.reduce((acc, segment) => acc + segment.weight, 1);
  return Math.round((1000 * totalScore) / totalWeight) / 1000;
}

export default SearchHotelRow;
