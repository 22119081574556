import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';

import { Promotion } from '~/components/Cruises/types';

import promotionService from '~/services/cruises/PromotionService';

import { INITIAL_REQUEST_STATE } from '../constants';
import { buildDepositDetails, buildOnBoardCreditDetails } from '../helpers';
import { DataRow, Request } from '../types';

type Props = {
  onClose: () => void;
  managementValues: DataRow;
  onDelete: (promotionId: string, rateId: string) => Promise<void>;
};

const RatePromosManagement = (props: Props): JSX.Element => {
  const { managementValues, onClose, onDelete } = props;
  const [promoNameFilter, setPromoNameFilter] = useState<string>('');
  const [filteredPromo, setFilteredPromo] = useState<Promotion[]>([]);
  const [promotions, setPromotions] = useState<Request<Promotion[]>>(INITIAL_REQUEST_STATE);

  const fetchPromotionsByRateId = useCallback(async (rateId: string) => {
    setPromotions({ loading: true, result: null });
    const res = await promotionService.getPromotionsByRateId(rateId);
    if (res.status === 200) setPromotions({ loading: false, result: res.result });
  }, []);

  const handleChangePromoNameField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fieldValue = event.target.value;
      setPromoNameFilter(fieldValue);
      setFilteredPromo(
        promotions.result.filter((promo) => promo.name.toLowerCase().includes(fieldValue.toLowerCase())),
      );
    },
    [promotions.result],
  );

  const handleDelete = useCallback(
    async (promotion: Promotion) => {
      await onDelete(promotion.id, managementValues.rateId);
    },
    [managementValues.rateId, onDelete],
  );

  const promos = useMemo(() => {
    if (promoNameFilter) return filteredPromo;
    return promotions.result;
  }, [filteredPromo, promoNameFilter, promotions.result]);

  useEffect(() => {
    if (managementValues.rateId) fetchPromotionsByRateId(managementValues.rateId);
  }, [fetchPromotionsByRateId, managementValues.rateId]);

  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: '100vh', width: '600px', padding: '32px 40px' }}
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ svg: { width: '24px', height: '24px', cursor: 'pointer' } }}
      >
        <Typography variant="h5">Management Promotions By Rate Code</Typography>
        <CloseIcon onClick={onClose} />
      </Stack>

      <Stack>
        {!promotions.loading && !promotions.result?.length && (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '80vh' }}>
            <Typography>No promotions found</Typography>
          </Stack>
        )}

        {promotions.loading && (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '80vh' }}>
            <CircularProgress size={32} />
          </Stack>
        )}

        {promotions.result?.length > 0 && (
          <Stack minHeight="85vh" direction="column" justifyContent="space-between">
            <Box>
              <TextField
                fullWidth
                name="promotionName"
                value={promoNameFilter}
                label="Filter By Promotion Name"
                onChange={handleChangePromoNameField}
              />
            </Box>

            <Stack maxHeight="75vh" sx={{ overflowY: 'scroll' }} mt={4}>
              {promos?.map((promotion) => (
                <Box
                  py={1}
                  my={1}
                  px={2}
                  key={promotion.id}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid #e2e2e2',
                  }}
                >
                  <Box pb={1} sx={{ borderBottom: '0.5px solid #e2e2e2' }}>
                    <Stack
                      gap={2}
                      alignItems="center"
                      flexDirection="row"
                      sx={{ maxWidth: '100%' }}
                      justifyContent="space-between"
                    >
                      <Box sx={{ maxWidth: '65%' }}>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {promotion.name}
                        </Typography>
                      </Box>

                      <Stack gap={2} flexDirection="row" sx={{ maxWidth: '35%' }}>
                        <Button
                          disabled // RIGHT NOW EDIT BY RATE CODE IS NOT AVAILABLE ON SVC-CRUISE
                          size="small"
                          color="info"
                          variant="text"
                          component={Link}
                          to={`/cruises/promotions/${promotion.id}/edit?vendorCode=${managementValues.vendorCode}&rateCode=${managementValues.rateCode}`}
                        >
                          Edit
                        </Button>
                        <Button size="small" color="error" variant="text" onClick={() => handleDelete(promotion)}>
                          Delete
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>

                  <Stack mt={1} gap={2} flexDirection="row" sx={{ maxWidth: '100%' }}>
                    <Box sx={{ maxWidth: '50%' }}>
                      <Typography variant="body2">LE Exclusive: {promotion.leExclusive ? 'YES' : 'NO'}</Typography>
                      <Typography variant="body2">Start Date: {promotion.startDate}</Typography>
                      <Typography variant="body2">End Date: {promotion.endDate}</Typography>
                    </Box>

                    <Box sx={{ maxWidth: '50%' }}>
                      {promotion.deposit && (
                        <Typography variant="body2">Deposit: {buildDepositDetails(promotion.deposit)}</Typography>
                      )}
                      {promotion.onBoardCredit && (
                        <Typography variant="body2">
                          OBC: {buildOnBoardCreditDetails(promotion.onBoardCredit)}
                        </Typography>
                      )}
                      {promotion.sellingPoints && (
                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          Selling Points: {promotion.sellingPoints?.join(', ')}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default RatePromosManagement;
