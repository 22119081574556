import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { Container, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import presentRoomType from '~/utils/presentRoomType';

import RoomTypesEdit from './RoomTypesEdit';

export default function RoomTypesEditContainer() {
  const { id_vendor: vendorId, id_property: propertyId } = useParams<{ id_vendor: string; id_property: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [property, setProperty] = useState(null);
  const [schema, setSchema] = useState(null);
  const [amenityOptionsData, setAmenityOptionsData] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [vendorOffers, setVendorOffers] = useState([]);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getProperty(propertyId),
      ReservationService.getRoomTypesSchema(propertyId),
      ReservationService.getAmenities(),
      VendorsService.getVendorById(vendorId),
      OffersService.getOffers({ queryString: vendorId }),
    ])
      .then(([property, schema, amenityOptions, vendor, offers]) => {
        setProperty(property.result);
        setSchema(schema.post.body.schema);
        setAmenityOptionsData(amenityOptions.result);
        setVendor(vendor.result);
        setVendorOffers(offers.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, propertyId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load vendor, property or schema.</Typography>
      </Container>
    );
  }

  return (
    <RoomTypesEdit
      roomTypes={(property.room_types_count ? property.room_types : []).map(presentRoomType)}
      vendorId={vendorId}
      property={property}
      schema={schema}
      amenityOptionsData={amenityOptionsData}
      vendor={vendor}
      offers={vendorOffers}
      channelManager={property.channel_manager}
    />
  );
}
