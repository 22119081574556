export const COPY_OPTION_AVAILABLE_TABS = {
  inclusions: 'Inclusions',
  capacity: 'Capacity',
  includedGuests: 'Included Guests',
  extraGuestSurcharges: 'Extra Guest Surcharges',
};

export const COPY_OPERATION_INCLUSIONS = 'inclusions';
export const COPY_OPERATION_CAPACITIES = 'capacities';
export const COPY_OPERATION_SURCHARGES = 'surcharges';
export const COPY_OPERATION_INCLUDED_GUESTS = 'included_guests';

export const COPY_OPTIONS_MODE_ALL_RATES = 'allRates';
export const COPY_OPTIONS_MODE_ALL_ROOM_TYPES = 'allRoomTypes';
// Lux Premium Collection
export const COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM = 'all_LPC_rates_for_room';
export const COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY = 'all_LPC_rates_for_property';
// Last Minute
export const COPY_OPTIONS_MODE_ALL_LM_FOR_ROOM = 'all_LM_rates_for_room';
export const COPY_OPTIONS_MODE_ALL_LM_FOR_PROPERTY = 'all_LM_rates_for_property';

export const COMMON_COPY_OPTION = [
  {
    value: COPY_OPTIONS_MODE_ALL_RATES,
    label: 'across all rates under this room',
  },
  {
    value: COPY_OPTIONS_MODE_ALL_ROOM_TYPES,
    label: 'across all room types for this rate',
  },
  {
    value: COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM,
    label: 'across all Lux Premium Collection rates under this room',
  },
  {
    value: COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY,
    label: 'across all Lux Premium Collection rates under this property',
  },
  {
    value: COPY_OPTIONS_MODE_ALL_LM_FOR_ROOM,
    label: 'across all Last Minute Escapes rates under this room',
  },
  {
    value: COPY_OPTIONS_MODE_ALL_LM_FOR_PROPERTY,
    label: 'across all Last Minute Escapes rates under this property',
  },
];
export const CONDITIONAL_COPY_OPTION = [
  {
    value: COPY_OPTIONS_MODE_ALL_RATES,
    label: 'across all rates under this room',
  },
  {
    value: COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM,
    label: 'across all Lux Premium Collection rates under this room',
  },
];

export const SEARCH_ID_LENGTH = 18;
