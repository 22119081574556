import React from 'react';

import classnames from 'clsx';

import { Button } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import OffersService from '../../../../services/OffersService';
import { defaultEndDate, defaultStartDate } from '../../../../utils/DefaultDate';
import { getAllScheduledRegions } from '../../../../utils/getAllScheduledRegions';

import FrontPageScheduleEdit from './FrontPageScheduleEdit';

export default class NewAddScheduleFrontPage extends React.Component {
  state = {
    isFrontPageOpen: false,
    isUpdated: false,
    clearRegions: false,
    isFetched: false,
    schedules: [],
  };

  onFrontPageClick = () => {
    this.setState(
      {
        isFrontPageOpen: !this.state.isFrontPageOpen,
        clearRegions: !this.state.clearRegions,
      },
      () => {
        this.fetchData();
      },
    );
  };

  onFrontPageClose = () => {
    this.setState({
      isFrontPageOpen: false,
    });
  };

  fetchData() {
    OffersService.getOffer(this.props.offerId).then((response) =>
      this.setState({
        schedules: response.result.brand_schedules,
        isFetched: true,
      }),
    );
  }

  render() {
    const { offerId, type, shouldMigrateSchedule } = this.props;
    const { isFetched, schedules } = this.state;
    const regions = new Map();
    libRegions.getRegions().map((region) => regions.set(region.name, false));

    const getEnabledRegions = (schedules, type) => {
      const uniqueAllRegions = getAllScheduledRegions(schedules, type);
      return libRegions.getRegions().filter((el) => !uniqueAllRegions.includes(el.code));
    };
    const classes = classnames('fp-container', {
      hide: !this.state.isFrontPageOpen,
    });

    return (
      <div>
        <div className={classes}>
          {isFetched && (
            <FrontPageScheduleEdit
              selectedRegion={regions}
              enabledRegions={getEnabledRegions(schedules, type)}
              offerId={offerId}
              type={type}
              onCancel={this.onFrontPageClick}
              onClose={this.onFrontPageClose}
              onSaveFinished={this.props.onSaveFinished}
              clearRegions={this.state.clearRegions}
              startDate={defaultStartDate()}
              endDate={defaultEndDate()}
              isNewSchedule={true}
            />
          )}
        </div>

        {!this.state.isFrontPageOpen && (
          <Button
            type="button"
            className="add-schedule-old"
            variant="contained"
            color="secondary"
            onClick={this.onFrontPageClick}
            disabled={shouldMigrateSchedule}
          >
            +Add Schedule
          </Button>
        )}
      </div>
    );
  }
}
