import PublicOffersService from '../../../services/PublicOfferService';
import { isAfter, isBefore } from '../../../services/TimeService';
import { getTourById } from '../../../services/ToursService';
import { Brands } from '../../../services/cruises/CabinCategoriesService';
import OfferService from '../../../services/cruises/OfferService';

import { OrderListItemWithSortId } from './SortableOfferListOrder';

export interface VerificationResult {
  isValid: boolean;
  reason?: string;
  bk: string;
}

interface Args {
  id: string;
  regionCode: string;
  brand: string;
}

async function verifyLeOffer(args: Args): Promise<VerificationResult> {
  const res = await PublicOffersService.getOfferById(args.id, args.regionCode, args.brand as Brands);
  const isValid = isAfter(res?.listSchedule?.end) && isBefore(res?.listSchedule?.start);

  return {
    isValid,
    reason: isValid ? '' : 'Schedule expired or not configured',
    bk: args.id,
  };
}

async function verifyCruiseOffer(args: Args): Promise<VerificationResult> {
  const res = await OfferService.getById(args.id);
  return {
    isValid: true,
    bk: '',
  };
}

async function verifyTourOffer(args: Args): Promise<VerificationResult> {
  const res = await getTourById(args.id);
  return {
    isValid: true,
    bk: args.id,
  };
}

async function verifyBedbankOffer(args: Args): Promise<VerificationResult> {
  await PublicOffersService.getBedbankOfferById(args.id, args.regionCode, args.brand as Brands);
  return {
    isValid: true,
    bk: args.id,
  };
}

export async function verifyOffer(
  offer: OrderListItemWithSortId,
  regionCode: string,
  brand: App.Brands,
  offerType: string,
): Promise<VerificationResult> {
  const verifyMap = {
    hotel: verifyLeOffer,
    bedbank: verifyBedbankOffer,
    tour_v2: verifyTourOffer,
    cruise: verifyCruiseOffer,
  };

  try {
    if (!(offerType in verifyMap)) {
      return {
        isValid: true,
        reason: '',
        bk: offer.bk,
      };
    }
    const result: VerificationResult = await (verifyMap[offerType]({ id: offer.bk, regionCode, brand } as Args) ??
      Promise.resolve({ isValid: true, reason: '', bk: offer.bk }));

    return {
      isValid: result.isValid,
      reason: result.reason,
      bk: offer.bk,
    };
  } catch (err) {
    if (err.status === 404) {
      return { isValid: false, reason: 'Offer not found', bk: offer.bk };
    }
  }
}
