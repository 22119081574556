/**
 * @dev Enforces a constraint whereby the set of elements in the array must equal members of string literal set T
 */
type ArrayLiteralUnionMatcher<T extends string, K = T> = [T] extends [never]
  ? []
  : K extends K
  ? [K, ...ArrayLiteralUnionMatcher<Exclude<T, K>>]
  : never;

export const MEMBERSHIP_BRANDS: ArrayLiteralUnionMatcher<App.MembershipBrand> = ['luxuryescapes'];

export const MEMBERSHIP_REGIONS: ArrayLiteralUnionMatcher<App.MembershipRegion> = ['AU', 'NZ'];

export const LUX_PLUS = {
  PROGRAM_NAME: 'LuxPlus+',
  REGIONS: MEMBERSHIP_REGIONS,
} as const;
