import React, { useMemo, useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import BlackoutDatesHistory from './BlackoutDatesHistory';
import SurchargeDatesHistory from './SurchargeDatesHistory';

type Tab = {
  label: string;
  pageTitle: string;
  component: React.ComponentType;
  showTab?: boolean;
};

interface Props {
  propertyId: string;
  roomType: any;
  roomRate: any;
  roomRates: any;
  channelManaged: any;
}

export default function RoomTypeEditHistoryTabs(props: Props) {
  const TABS_MAP = useMemo(() => {
    return {
      blackoutDates: {
        label: 'Blackout Dates',
        pageTitle: 'Blackout Dates',
        component: () => (
          <BlackoutDatesHistory
            propertyId={props.propertyId}
            channelManaged={props.channelManaged}
            roomType={props.roomType}
            roomRate={props.roomRate}
          />
        ),
      } as Tab,
      surchargeDates: {
        label: 'Surcharge Dates',
        pageTitle: 'Surcharge Dates',
        component: () => (
          <SurchargeDatesHistory
            propertyId={props.propertyId}
            channelManaged={props.channelManaged}
            roomType={props.roomType}
            roomRate={props.roomRate}
            surchargeType="vendor"
          />
        ),
      } as Tab,
      revenueManagement: {
        label: 'Revenue Management',
        pageTitle: 'Revenue Management',
        component: () => (
          <SurchargeDatesHistory
            propertyId={props.propertyId}
            channelManaged={props.channelManaged}
            roomType={props.roomType}
            roomRate={props.roomRate}
            surchargeType="internal"
          />
        ),
      } as Tab,
    };
  }, [props.propertyId, props.channelManaged, props.roomType, props.roomRate]);

  type Tabs = keyof typeof TABS_MAP;

  const tabList = Object.keys(TABS_MAP);

  const [key, setKey] = useState<Tabs>('blackoutDates');

  const currentTab: Tab = TABS_MAP[key];

  const handleTabChange = (event: React.SyntheticEvent, newKey: Tabs) => {
    setKey(newKey);
  };

  return (
    <>
      <Tabs value={key} onChange={handleTabChange}>
        {tabList
          .filter((tabKey) => {
            const tab: Tab = TABS_MAP[tabKey];

            if (tab.showTab !== undefined) {
              return tab.showTab;
            }

            return true;
          })
          .map((tabKey) => {
            const tab: Tab = TABS_MAP[tabKey];

            return <Tab key={tabKey} label={tab.label} value={tabKey} />;
          })}
      </Tabs>

      <Box pt={1}>
        <Box component={currentTab.component} />
      </Box>
    </>
  );
}
