import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import { brands } from '~/consts/brands';

import { getRebookedCustomOrderIdFromOrder } from '~/utils/order';

import { buildOfferTypes } from '../../OrderDetail/orderMetaUtils';

const getBrandTitle = (brand: string) => {
  return brands.find((b) => b.value === brand)?.title ?? 'Unknown';
};

const internalInformationFormatter = (params: GridRenderCellParams<Order.Order>) => {
  const order = params.row;

  let hasBeenRebookedAsCustomOffer = null;
  if (order.bedbank_items.length > 0) {
    hasBeenRebookedAsCustomOffer = getRebookedCustomOrderIdFromOrder(order);
  }

  const allOfferTypes = buildOfferTypes(order as unknown as App.Order);

  return (
    <Stack direction="column" sx={{ '& strong': { fontWeight: 500 } }}>
      <Typography>
        <strong>Brand</strong>: {getBrandTitle(order.brand)}
      </Typography>

      <Typography>
        <strong>Type</strong>: {allOfferTypes.join(', ')}
      </Typography>

      <Typography>
        <strong>Order ID</strong>: {order.id_orders}
      </Typography>

      {!!order.external_order_id && (
        <Typography>
          <strong>External order ID</strong>: {order.external_order_id}
        </Typography>
      )}
      {hasBeenRebookedAsCustomOffer && (
        <Typography>
          <strong>Rebooked as Custom Offer:</strong> {hasBeenRebookedAsCustomOffer}
        </Typography>
      )}
    </Stack>
  );
};

export default internalInformationFormatter;
