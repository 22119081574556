import React, { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Button, ImageList, ImageListItem, Typography } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Image from '~/components/Common/Image';

interface Props {
  images: Reservation.RoomType['images'];
  vendorId: string;
  propertyId: string;
}

const ImagesPane = (props: Props): ReactElement => {
  const { images, vendorId, propertyId } = props;

  return (
    <>
      <span>
        <PageSubheader title="Images">
          <div>
            <Button variant="text" component={Link} to={`/vendors/${vendorId}/properties/${propertyId}/edit-images`}>
              Edit
            </Button>
          </div>
        </PageSubheader>
        {images.length > 0 && <dt className="h6 text-uppercase text-muted">Top images</dt>}
        <ImageList cols={4} gap={10} className={'mt-10'}>
          {images.slice(0, 3).map((image) => (
            <ImageListItem key={image.id} style={{ cursor: 'pointer' }}>
              <Image
                className="img-responsive mx-auto"
                publicId={image.id_cloudinary_external}
                options={{
                  width: 266,
                  height: 200,
                }}
              />
              <Typography variant="body2" className="text-center">
                {image.title}
              </Typography>
            </ImageListItem>
          ))}
        </ImageList>
      </span>
    </>
  );
};

export default ImagesPane;
