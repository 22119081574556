import React, { useCallback, useState } from 'react';

import { Box, Button, FormControl, Stack, TextField } from '@mui/material';

import InputCruiseLineSelect from '~/components/Cruises/components/Selects/InputCruiseLineSelect';

import { SEARCH_FILTERS_DEFAULT, SearchFilters } from './constants';

type Props = {
  querySearchFilters: SearchFilters;
  onSearch: (params: SearchFilters) => void;
};

export default function CabinCategoriesSearchFilters(props: Props) {
  const { querySearchFilters, onSearch } = props;

  const [searchFilters, setSearchFilter] = useState<SearchFilters>({
    ...SEARCH_FILTERS_DEFAULT,
    ...querySearchFilters,
  });

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();

      if (event?.target && event?.target?.name) {
        setSearchFilter((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    },
    [setSearchFilter],
  );

  const handleSearch = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSearch({
        id: searchFilters.id,
        code: searchFilters.code,
        shipId: searchFilters.shipId,
        shipName: searchFilters.shipName,
        vendorId: searchFilters.vendorId,
        cabinType: searchFilters.cabinType,
        externalId: searchFilters.externalId,
        shipExternalId: searchFilters.shipExternalId,
        cabinInfoCategoryName: searchFilters.cabinInfoCategoryName,
      });
    },
    [searchFilters, onSearch],
  );

  return (
    <form autoComplete="off" onSubmit={handleSearch}>
      <Box my={4} display="flex" flexDirection="column" gap={2}>
        <Stack display="flex" direction="row" gap={2}>
          <TextField
            fullWidth
            name="id"
            label="Cabin Category ID"
            onChange={handleChangeField}
            value={searchFilters.id}
          />
          <TextField
            fullWidth
            name="externalId"
            label="Cabin Category External ID"
            onChange={handleChangeField}
            value={searchFilters.externalId}
          />
          <TextField
            fullWidth
            name="code"
            label="Cabin Category Code"
            onChange={handleChangeField}
            value={searchFilters.code}
          />
          <TextField
            fullWidth
            name="cabinType"
            label="Cabin Category Type"
            onChange={handleChangeField}
            value={searchFilters.cabinType}
          />
          <TextField
            fullWidth
            name="cabinInfoCategoryName"
            label="Category Name"
            onChange={handleChangeField}
            value={searchFilters.cabinInfoCategoryName}
          />
        </Stack>

        <Stack display="flex" direction="row" gap={2}>
          <FormControl fullWidth>
            <InputCruiseLineSelect
              label="Cruise line"
              value={searchFilters.vendorId}
              onChange={(cruiseLine) => setSearchFilter((prev) => ({ ...prev, vendorId: cruiseLine.id }))}
            />
          </FormControl>
          <TextField
            fullWidth
            name="shipId"
            label="Ship ID"
            onChange={handleChangeField}
            value={searchFilters.shipId}
          />
          <TextField
            fullWidth
            name="shipExternalId"
            label="Ship External ID"
            onChange={handleChangeField}
            value={searchFilters.shipExternalId}
          />
          <TextField
            fullWidth
            name="shipName"
            label="Ship Name"
            onChange={handleChangeField}
            value={searchFilters.shipName}
          />
          <Box>
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}
