import React from 'react';

import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const STATUS_OPTIONS = [
  { value: 'draft', label: 'Draft' },
  { value: 'content-approved', label: 'Content-Approved' },
];

interface Props {
  value: string;
  onChange: (newValue?: string) => void;
}

export default function AccommodationPropertiesStatusInput({ value, onChange }: Props) {
  return (
    <FormControl fullWidth>
      <InputLabel>Status</InputLabel>
      <Select value={value} label="Status" onChange={(e) => onChange(e.target.value)}>
        <MenuItem value="any">Any</MenuItem>
        <Divider variant="middle" />
        {STATUS_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
