import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addAddonToCart,
  addOffer,
  addOfferToCart,
  allowToggleReservationType,
  emptyCart,
  initCart,
  setBookDatesForOrder,
  setCustomer,
  setOrderId,
  setReservationType,
  toggleBookingDates,
  updateReservation,
} from '../../../../actions/cart';
import { reservationMap } from '../../../../utils/cart';
import BookingItemWrapper from '../../../Common/Booking/BookingItemWrapper';

import ChangeReservation from './ChangeReservation';
import ConvertToBNBL from './ConvertToBNBL';
import OrderItemDefault from './OrderItemDefault';
import PutOnHold from './PutOnHold';

interface Props {
  cart: App.Cart;
  checkInStatus: {
    addons: any[];
    check_in_status?: string;
  };
  count: number;
  currencyCode: string;
  customer: App.User;
  disableInteraction?: any;
  doAddAddonToCart: any;
  doAddOfferToCart: any;
  doAllowToggleReservationType: any;
  doEmptyCart: any;
  doInitCart: any;
  doSelectOffer: any;
  doSetBookDatesForOrder: any;
  doSetCustomer: any;
  doSetOrderId: any;
  doSetReservationType: any;
  doToggleBookingDates: any;
  doUpdateReservation: any;
  getTravellers: any;
  hasAllowedRefund: boolean;
  history: any;
  item: App.OrderItem;
  offer: App.AccommodationOffer;
  offerPackage: App.AccommodationPackage;
  order: App.Order;
  refreshData: any;
  setIsBookingDates: any;
  setIsChangingDates: any;
  setIsConvertingToBNBL: any;
  setIsPuttingOnHold: any;
  showRefundModal: any;
  showWarningModal: any;
  traveller: any;
}

interface State {
  isBookingDates: boolean;
  isChangingDates: boolean;
  isConvertingToBNBL: boolean;
  isPuttingOnHold: boolean;
}

class OrderItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isBookingDates: false,
      isChangingDates: false,
      isConvertingToBNBL: false,
      isPuttingOnHold: false,
    };
  }

  getCartItem = () => {
    const { cart, item } = this.props;

    const cartItems = cart.offers.reduce((acc, offer) => acc.concat(offer.items), []);

    return cartItems.find((cartItem) => cartItem.id === item.id);
  };

  getAddons = (packageOption) => {
    const addons = {};

    if (typeof packageOption.addons === 'undefined') {
      return addons;
    }

    for (let i = 0; i < packageOption.addons.length; i++) {
      const addon = packageOption.addons[i];
      addons[addon.id_salesforce_external] = addon;
    }

    return addons;
  };

  handleToggleBookingDates = () => {
    const {
      doSetCustomer,
      doSelectOffer,
      doAddOfferToCart,
      doSetBookDatesForOrder,
      doAllowToggleReservationType,
      offer,
      customer,
      item,
      offerPackage,
      currencyCode,
      order,
      history,
    } = this.props;

    doAllowToggleReservationType(false);
    doSetCustomer(customer);
    doSelectOffer(offer);

    doAddOfferToCart({
      pkg: offerPackage,
      currencyCode,
      offer: { ...offer, offerCartId: null },
      orderItem: item,
    });

    doSetBookDatesForOrder(order.id);

    history.push(`/users/${customer.memberId}/edit-orders/` + `${order.id}/add-item/booking-information`);
  };

  handleToggleChangingDates = () => {
    const {
      order,
      doInitCart,
      doEmptyCart,
      doSetOrderId,
      doUpdateReservation,
      offer,
      customer,
      currencyCode,
      item,
      offerPackage,
      doAddAddonToCart,
    } = this.props;
    const { isChangingDates } = this.state;
    const nextIsChangingDates = !isChangingDates;

    if (nextIsChangingDates) {
      doInitCart(offer, offerPackage, customer, currencyCode, item);
      doSetOrderId(order.id);

      const addonItems = order.addon_items || [];
      const addons = this.getAddons(offerPackage);

      const orderAddonItems = [];

      addonItems.forEach((addonItem) => {
        if (typeof addons[addonItem.salesforce_id] !== 'undefined' && addonItem.status !== 'cancelled') {
          orderAddonItems.push(addons[addonItem.salesforce_id]);
        }
      });

      // initialize item reservation state in the cart;
      const reservation = reservationMap(offer.type, item.reservation);
      doUpdateReservation(item.id, reservation, {
        ...offer,
        offerCartId: null,
      });

      orderAddonItems.forEach((addon) => {
        doAddAddonToCart(addon, item, offer, offerPackage);
      });
    } else {
      doEmptyCart();
    }

    this.setState({
      isChangingDates: nextIsChangingDates,
      isBookingDates: false,
    });
  };

  handleConvertToBNBL = () => {
    const { offer, offerPackage, customer, currencyCode, item, doInitCart } = this.props;

    this.setState((prevState) => {
      const nextIsConvertingToBNBL = !prevState.isConvertingToBNBL;

      doInitCart(offer, offerPackage, customer, currencyCode, item);

      return {
        isConvertingToBNBL: nextIsConvertingToBNBL,
      };
    });
  };

  handlePutOnHold = () => {
    const { offer, offerPackage, customer, currencyCode, item, doInitCart } = this.props;

    this.setState((prevState) => {
      const nextIsPuttingOnHold = !prevState.isPuttingOnHold;

      doInitCart(offer, offerPackage, customer, currencyCode, item);

      return {
        isPuttingOnHold: nextIsPuttingOnHold,
      };
    });
  };

  onAfterHandleConvertToBNBL = () => {
    const { doEmptyCart, setIsConvertingToBNBL, showWarningModal } = this.props;

    doEmptyCart();
    this.setState({ isConvertingToBNBL: false }, () => {
      setIsConvertingToBNBL();
      showWarningModal();
    });
  };

  onAfterPutOnHold = () => {
    const { doEmptyCart, setIsPuttingOnHold, showWarningModal } = this.props;

    doEmptyCart();
    this.setState({ isPuttingOnHold: false }, () => {
      setIsPuttingOnHold();
      showWarningModal();
    });
  };

  handleUpdateReservation = (item, reservationData) => {
    const { doUpdateReservation, offer } = this.props;

    doUpdateReservation(item.id, reservationData, {
      ...offer,
      offerCartId: null,
    });
  };

  onAfterChangeDates = () => {
    const { doEmptyCart, setIsChangingDates, showWarningModal } = this.props;

    doEmptyCart();
    this.setState({ isChangingDates: false }, () => {
      setIsChangingDates();
      showWarningModal();
    });
  };

  render() {
    const { order, currencyCode, count, offerPackage, offer, cart, doToggleBookingDates, disableInteraction } =
      this.props;
    const { isChangingDates, isConvertingToBNBL, isPuttingOnHold } = this.state;

    const cartItem = this.getCartItem();

    if (cartItem && isConvertingToBNBL) {
      return (
        <BookingItemWrapper
          item={cartItem}
          offerPackage={offerPackage}
          count={count}
          currencyCode={currencyCode}
          offer={offer}
        >
          <ConvertToBNBL
            offerPackage={offerPackage}
            orderId={order.id}
            cartItem={cartItem}
            offer={offer}
            onCancel={this.handleConvertToBNBL}
            onAfterHandleConvertToBNBL={this.onAfterHandleConvertToBNBL}
          />
        </BookingItemWrapper>
      );
    }

    if (cartItem && isPuttingOnHold) {
      return (
        <BookingItemWrapper
          item={cartItem}
          offerPackage={offerPackage}
          count={count}
          currencyCode={currencyCode}
          offer={offer}
        >
          <PutOnHold
            orderId={order.id}
            cartItem={cartItem}
            onCancel={this.handlePutOnHold}
            onAfterPutOnHold={this.onAfterPutOnHold}
          />
        </BookingItemWrapper>
      );
    }

    if (cartItem && isChangingDates) {
      return (
        <BookingItemWrapper
          item={cartItem}
          offerPackage={offerPackage}
          count={count}
          currencyCode={currencyCode}
          offer={offer}
        >
          <ChangeReservation
            orderId={order.id}
            payments={order.payments}
            cart={cart}
            cartItem={cartItem}
            offer={offer}
            cartCustomer={cart.customer}
            cartItems={[cartItem]}
            cartAmounts={cart.amounts}
            onCancel={this.handleToggleChangingDates}
            onToggleBookingDates={(isBookingDates) =>
              doToggleBookingDates(cartItem, { ...offer, offerCartId: null }, isBookingDates)
            }
            onUpdateReservation={this.handleUpdateReservation}
            onAfterChangeDates={this.onAfterChangeDates}
            currencyCode={currencyCode}
          />
        </BookingItemWrapper>
      );
    }

    return (
      <OrderItemDefault
        onToggleBookingDates={this.handleToggleBookingDates}
        onToggleChangingDates={this.handleToggleChangingDates}
        onToggleConvertToBNBL={this.handleConvertToBNBL}
        onTogglePutOnHold={this.handlePutOnHold}
        disableInteraction={disableInteraction}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doSetOrderId: setOrderId,
      doSelectOffer: addOffer,
      doSetCustomer: setCustomer,
      doSetBookDatesForOrder: setBookDatesForOrder,
      doAddOfferToCart: addOfferToCart,
      doInitCart: initCart,
      doAddAddonToCart: addAddonToCart,
      doUpdateReservation: updateReservation,
      doToggleBookingDates: toggleBookingDates,
      doEmptyCart: emptyCart,
      doAllowToggleReservationType: allowToggleReservationType,
      doSetReservationType: setReservationType,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
