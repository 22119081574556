import React, { useCallback, useRef, useState } from 'react';

import { Dayjs } from 'dayjs';
import { useHistory } from 'react-router';

import LoadingButton from '@mui/lab/LoadingButton';
import { Backdrop, Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { EmptyArray } from '~/utils/arrayUtils';

import ScheduleDeletionButton from './ScheduleDeletionButton';
import ScheduleBannerForm, { parseScheduleBannerFormData } from './ScheduleForms/ScheduleBannerForm';
import ScheduleBaseForm, { parseScheduleBaseFormData } from './ScheduleForms/ScheduleBaseForm';
import ScheduleEmailContentForm, { parseScheduleEmailContentFormData } from './ScheduleForms/ScheduleEmailContentForm';
import ScheduleHeroOffersForm, { parseScheduleHeroOffersFormData } from './ScheduleForms/ScheduleHeroOffersForm';
import SchedulePushContentForm, { parseSchedulePushContentFormData } from './ScheduleForms/SchedulePushContentForm';
import ScheduleTestEmailModal from './ScheduleForms/ScheduleTestEmailModal';
import ScheduleTpfmForm, { parseScheduleTpfmFormData } from './ScheduleForms/ScheduleTpfmForm';

function parseFormData(forms: {
  baseFormData: FormData;
  heroOffersFormData: FormData;
  emailContentFormData: FormData;
  pushContentFormData?: FormData;
  tpfmFormData: FormData;
  bannerFormData: FormData;
}): CustomerCommunication.UnsavedHeroPlannerSchedule {
  const { baseFormData, heroOffersFormData, emailContentFormData, pushContentFormData, tpfmFormData, bannerFormData } =
    forms;

  let unsaved: CustomerCommunication.UnsavedHeroPlannerSchedule = {
    ...parseScheduleBaseFormData(baseFormData),
    ...parseScheduleHeroOffersFormData(heroOffersFormData),
    ...parseScheduleEmailContentFormData(emailContentFormData),
    ...parseScheduleTpfmFormData(tpfmFormData),
    ...parseScheduleBannerFormData(bannerFormData),
  };

  if (pushContentFormData) {
    unsaved = { ...unsaved, ...parseSchedulePushContentFormData(pushContentFormData) };
  }

  return unsaved;
}

type Props = {
  busy?: boolean;
  onSubmit: (schedule: CustomerCommunication.UnsavedHeroPlannerSchedule) => void;
} & (
  | {
      editorType: 'new';
      schedule: CustomerCommunication.UnsavedHeroPlannerSchedule;
    }
  | {
      editorType: 'existing';
      schedule: CustomerCommunication.HeroPlannerSchedule;
    }
);

function ScheduleEntityForm(props: Props) {
  const { busy = false, editorType, schedule, onSubmit } = props;
  const history = useHistory();

  const [sendDate, setSendDate] = useState<Dayjs>(schedule.sendDate ?? '');
  const [brand, setBrand] = useState<string>(schedule.brandId ?? '');
  const [countryGroupId, setCountryGroupId] = useState<string>(schedule.countryGroupId ?? '');
  const [countryId, setCountryId] = useState<string>(schedule.countryId ?? '');
  const [offerIds, setOfferIds] = useState<Array<string>>(schedule.emailHeroOfferIds ?? EmptyArray);

  const baseFormRef = useRef<HTMLFormElement>(null);
  const heroOffersFormRef = useRef<HTMLFormElement>(null);
  const emailContentFormRef = useRef<HTMLFormElement>(null);
  const pushContentFormRef = useRef<HTMLFormElement>(null);
  const tpfmFormRef = useRef<HTMLFormElement>(null);
  const bannerFormRef = useRef<HTMLFormElement>(null);

  const {
    isToggled: isTestEmailModalOpen,
    toggleOn: openTestEmailModal,
    toggleOff: closeTestEmailModal,
  } = useToggleState(false);

  const handleSubmissionClick = useCallback(() => {
    if (
      baseFormRef.current?.reportValidity() === false ||
      heroOffersFormRef.current?.reportValidity() === false ||
      emailContentFormRef.current?.reportValidity() === false ||
      pushContentFormRef.current?.reportValidity() === false ||
      bannerFormRef.current?.reportValidity() === false
    ) {
      return;
    }

    const baseFormData = new FormData(baseFormRef.current);
    const heroOffersFormData = new FormData(heroOffersFormRef.current);
    const emailContentFormData = new FormData(emailContentFormRef.current);
    const pushContentFormData = pushContentFormRef.current ? new FormData(pushContentFormRef.current) : undefined;
    const tpfmFormData = new FormData(tpfmFormRef.current);
    const bannerFormData = new FormData(bannerFormRef.current);

    const unsaved = {
      ...parseFormData({
        baseFormData,
        heroOffersFormData,
        emailContentFormData,
        pushContentFormData,
        tpfmFormData,
        bannerFormData,
      }),
    };

    onSubmit(unsaved);
  }, [onSubmit]);

  const handleDeletion = useCallback(() => {
    history.push('/customer-communication/hero-planner/schedules');
  }, [history]);

  return (
    <>
      <Stack direction="column" gap={3}>
        <Paper sx={{ position: 'relative' }}>
          <ScheduleBaseForm
            ref={baseFormRef}
            sendDateValue={sendDate}
            brandValue={brand}
            countryGroupIdValue={countryGroupId}
            countryIdValue={countryId}
            stateIdDefaultValue={schedule.stateId}
            cityIdDefaultValue={schedule.cityId}
            segmentIdDefaultValue={schedule.segmentId}
            cadenceIdDefaultValue={schedule.cadenceId}
            membershipIdDefaultValue={schedule.membershipId}
            onSendDateChange={setSendDate}
            onBrandChange={setBrand}
            onCountryChange={setCountryId}
            onCountryGroupChange={setCountryGroupId}
          />
          <Backdrop open={busy} sx={{ position: 'absolute' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Hero Offers</Typography>

            <ScheduleHeroOffersForm
              ref={heroOffersFormRef}
              sendDate={sendDate}
              brand={brand}
              countryGroupId={countryGroupId}
              countryId={countryId}
              offerIds={offerIds}
              onOfferIdsChange={setOfferIds}
            />
          </Stack>

          <Backdrop open={busy} sx={{ position: 'absolute' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Top Picks For Me Email</Typography>
            <ScheduleTpfmForm ref={tpfmFormRef} isApprovedForTpfmInitial={schedule.isApprovedForTpfm} />
          </Stack>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Banners</Typography>
            <ScheduleBannerForm
              ref={bannerFormRef}
              defaultEmailBannerInitial={schedule.emailBannerId}
              emailBannerPosition2Initial={schedule.emailBannerPosition2Id}
            />
          </Stack>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Email Content</Typography>
            <ScheduleEmailContentForm
              ref={emailContentFormRef}
              preHeaderDefaultValue={schedule.emailPreHeader}
              subjectLineDefaultValue={schedule.emailSubjectLine}
              countryGroupId={countryGroupId}
              countryId={countryId}
              offerIds={offerIds}
            />
          </Stack>
          <Backdrop open={busy} sx={{ position: 'absolute' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Paper>

        {brand === 'luxuryescapes' && (
          <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
            <Stack direction="column" gap={2}>
              <Typography variant="h4">Push Content</Typography>
              <SchedulePushContentForm
                ref={pushContentFormRef}
                titleDefaultValue={schedule.pushTitle}
                subtitleDefaultValue={schedule.pushSubtitle}
                messageDefaultValue={schedule.pushMessage}
                linkDefaultValue={schedule.pushLink}
                imageIdDefaultValue={schedule.pushSvcImageId}
                countryGroupId={countryGroupId}
                countryId={countryId}
                offerId={offerIds[0]}
              />
            </Stack>
            <Backdrop open={busy} sx={{ position: 'absolute' }}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Paper>
        )}

        <Stack direction="row-reverse" gap={2}>
          <LoadingButton loading={busy} variant="contained" size="large" onClick={handleSubmissionClick}>
            {editorType === 'new' && <span>Create new schedule</span>}
            {editorType === 'existing' && <span>Save schedule</span>}
          </LoadingButton>
          {editorType === 'existing' && (
            <Button type="button" variant="outlined" size="large" onClick={openTestEmailModal} disabled={busy}>
              Send test email
            </Button>
          )}
          {editorType === 'existing' && (
            <ScheduleDeletionButton scheduleId={schedule.id} disabled={busy} onDelete={handleDeletion} />
          )}
        </Stack>
      </Stack>
      {editorType === 'existing' && (
        <ScheduleTestEmailModal
          scheduleId={schedule.id}
          baseFormRef={baseFormRef}
          emailContentFormRef={emailContentFormRef}
          open={isTestEmailModalOpen}
          onClose={closeTestEmailModal}
        />
      )}
    </>
  );
}

export default ScheduleEntityForm;
