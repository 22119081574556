import React, { FormEventHandler, useCallback, useRef } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';

import UsersService from '~/services/UsersService';

const NEW_PASSWORD_INPUT_NAME = 'new-password';

interface Props {
  isOpen: boolean;
  user: App.User;
  onClose: () => void;
}

export default function ForceLogoutModal(props: Props) {
  const { isOpen, onClose, user } = props;

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const newPassword = String(formData.get(NEW_PASSWORD_INPUT_NAME));
      await UsersService.forceLogout(user.id_member, user.email, newPassword);
      onClose();
    },
    [user],
  );

  const handleClose = useCallback(() => {
    onClose();
    formRef.current?.reset();
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form ref={formRef} onSubmit={handleSubmission}>
        <DialogTitle>Force logout</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2}>
            <DialogContentText>
              This will log the user out of all devices. To proceed you must enter a new password for the customer.
            </DialogContentText>
            <TextField
              name={NEW_PASSWORD_INPUT_NAME}
              type="password"
              required
              autoComplete="new-password"
              label="New password"
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>

          <Button type="submit" variant="contained" color="error">
            Force log out and reset password
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
