import React, { useState } from 'react';

import pluralize from 'pluralize';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';

import { buttonStates } from '~/components/Common/Forms/states/muiSubmitButton';

import { ledBrands } from '../../../../consts/brands';
import OffersService from '../../../../services/OffersService';
import { defaultEndDate, defaultStartDate } from '../../../../utils/DefaultDate';
import NewScheduleFormBase from '../../../Common/Forms/NewScheduleFormBase';
import useScheduleUpdate from '../common/useScheduleUpdate';
import { extractErrorMessages, getLEDBrandName } from '../common/utils';

type BrandSelections = { [brandValue: string]: boolean };

function createBrandSelections(value: boolean): BrandSelections {
  const brandSelections = {};
  ledBrands.forEach((brand) => {
    brandSelections[brand.value] = value;
  });
  return brandSelections;
}

interface Props {
  isOpen: boolean;
  offerId: string;
  type: string;
  currentSchedules: App.Schedule[];
  onClose: () => void;
  onSaveFinished: () => void;
  onError: () => void;
}

interface ErrorState {
  brands: string[];
  errors: string[];
}

export default function LEDScheduleBulkUpdate({
  isOpen,
  offerId,
  type,
  currentSchedules,
  onClose,
  onSaveFinished,
  onError,
}: Props) {
  const {
    schedule,
    setSchedule,
    startUpdated,
    setStartUpdated,
    endUpdated,
    setEndUpdated,
    saveState,
    setSaveState,
    updateStart,
    updateEnd,
    onReset,
    updateSchedule,
  } = useScheduleUpdate({ offerId });
  const [brandSelections, setBrandSelections] = useState<BrandSelections>(createBrandSelections(false));
  const [updateErrors, setUpdateErrors] = useState<ErrorState | null>(null);
  const [createErrors, setCreateErrors] = useState<ErrorState | null>(null);

  const onBrandToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const brand = e.target.value;
    const isChecked = e.target.checked;

    setBrandSelections((prevState) => ({
      ...prevState,
      [brand]: isChecked,
    }));
  };

  const changeSelection = (isChecked: boolean) => {
    setBrandSelections(createBrandSelections(isChecked));
  };

  const createSchedules = async (brandValues: string[]) => {
    // Group the brands by their associated regions
    const brandsToCreateSchedulesFor = ledBrands.filter((b) => brandValues.includes(b.value));
    const regionCodes = [...new Set(brandsToCreateSchedulesFor.map((b) => b.region.code))];

    return Promise.all(
      regionCodes.map((regionCode) => {
        const brandsForRegion = brandsToCreateSchedulesFor.filter((brand) => brand.region.code === regionCode);

        const payload = {
          brands: brandsForRegion.map((b) => b.value),
          start: schedule.start,
          end: schedule.end,
          regions: [regionCode],
          type,
        };

        return OffersService.createScheduleList(payload, offerId);
      }),
    );
  };

  const saveSchedule = async () => {
    const currentlyScheduledBrands = currentSchedules.map((s) => s.brand);
    const selectedBrands = filterCheckedBrands();

    const updatedSchedules = currentSchedules.filter((s) => selectedBrands.includes(s.brand));
    const updatedBrands = updatedSchedules.map((s) => s.brand);
    const addedBrands = selectedBrands.filter((r) => !currentlyScheduledBrands.includes(r));

    setSaveState(buttonStates.saving);

    const updates: Promise<any>[] = [Promise.resolve(), Promise.resolve()];
    if (updatedSchedules.length) {
      updates[0] = updateSchedule(updatedSchedules);
    }
    if (addedBrands.length) {
      updates[1] = createSchedules(addedBrands);
    }
    const updateResults = await Promise.allSettled(updates);

    if (updateResults.some((r) => r.status === 'rejected')) {
      setSaveState(buttonStates.failed);
      setUpdateErrors(
        updateResults[0].status === 'rejected'
          ? {
              brands: updatedBrands,
              errors: extractErrorMessages(updateResults[0].reason),
            }
          : null,
      );
      setCreateErrors(
        updateResults[1].status === 'rejected'
          ? {
              brands: addedBrands,
              errors: extractErrorMessages(updateResults[1].reason),
            }
          : null,
      );
      onError();
    } else {
      reset();
      onSaveFinished();
      onClose();
    }
  };

  const filterCheckedBrands = () => {
    return Object.entries(brandSelections)
      .filter(([brand, isSelected]) => isSelected)
      .map(([brand]) => brand);
  };

  const anyBrandsChecked = () => {
    return filterCheckedBrands().length > 0;
  };

  const reset = () => {
    setSchedule({
      start: defaultStartDate(),
      end: defaultEndDate(),
    });
    setBrandSelections(createBrandSelections(false));
    setStartUpdated(false);
    setEndUpdated(false);
    setSaveState(buttonStates.default);
    setUpdateErrors(null);
    setCreateErrors(null);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>Bulk Update</DialogTitle>
      <DialogContent className="fp-schedule-edit">
        {updateErrors && (
          <Alert severity="error">
            Error updating {pluralize('schedule', updateErrors.brands.length)} for{' '}
            {updateErrors.brands.map(getLEDBrandName).join(', ')}:
            <Box component="ul">
              {updateErrors.errors.map((error, index) => (
                <Box component="li" key={index}>
                  {error}
                </Box>
              ))}
            </Box>
          </Alert>
        )}
        {createErrors && (
          <Alert severity="error">
            Error creating {pluralize('schedule', createErrors.brands.length)} for{' '}
            {createErrors.brands.map(getLEDBrandName).join(', ')}:
            <Box component="ul">
              {createErrors.errors.map((error, index) => (
                <Box component="li" key={index}>
                  {error}
                </Box>
              ))}
            </Box>
          </Alert>
        )}

        <NewScheduleFormBase
          schedule={schedule}
          updateStart={updateStart}
          updateEnd={updateEnd}
          startUpdated={startUpdated}
          endUpdated={endUpdated}
          onReset={onReset}
          disabled={saveState === buttonStates.saving}
        />
        <Stack direction="row" spacing={1} alignItems="baseline">
          <Typography>Select Brands: </Typography>
          <Button variant="text" onClick={() => changeSelection(true)} className="select-link">
            Select All
          </Button>
          <span className="bar">|</span>
          <Button variant="text" onClick={() => changeSelection(false)} className="select-link">
            Deselect All
          </Button>
        </Stack>

        <Stack direction="row" useFlexGap flexWrap="wrap">
          {ledBrands.map((brand) => (
            <FormGroup key={brand.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={brand.value}
                    checked={brandSelections[brand.value]}
                    onChange={onBrandToggle}
                    disabled={saveState === buttonStates.saving}
                  />
                }
                label={brand.title}
              />
            </FormGroup>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel} disabled={saveState === buttonStates.saving}>
          Cancel
        </Button>
        <Button
          onClick={saveSchedule}
          disabled={saveState === buttonStates.saving || !anyBrandsChecked()}
          color={saveState}
        >
          {saveState === buttonStates.saving ? 'Saving...' : 'Save Schedule'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
