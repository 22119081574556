import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';

import { TraverseObject } from '~/components/DebugModal/utils';

import { ICondition, LEVELS } from './Rules/types';

interface Props {
  check: ICondition;
  offer: App.Offer;
  property: App.Property;
  setCheckResult: (value: { level: number; nonCritical?: boolean; hidden?: boolean }) => void;
}

export default function SingleCheck({ check, offer, property, setCheckResult }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [completedCheck, setCompletedCheck] = useState<ICondition>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedHelp, setExpandedHelp] = useState<boolean>(false);

  const checkStateLevel = (check) => {
    if (check.checkResult === true) {
      return LEVELS.PASS;
    }
    if (check.thresholds) {
      if (typeof check.thresholds === 'function') {
        const level = check.thresholds(check.resultResult);
        if (typeof level === 'number') {
          return level;
        }
      }
      const value = check.checkResult;
      if (check.thresholds.type === 'gt') {
        if (value > check.thresholds.levels[0]) {
          return LEVELS.PASS;
        }
        if (value > check.thresholds.levels[1]) {
          return LEVELS.WARNING;
        }
        if (value > check.thresholds.levels[2]) {
          return LEVELS.ERROR;
        }
      } else if (check.thresholds.type === 'lt') {
        if (value < check.thresholds.levels[0]) {
          return LEVELS.PASS;
        }
        if (value < check.thresholds.levels[1]) {
          return LEVELS.WARNING;
        }
        if (value < check.thresholds.levels[2]) {
          return LEVELS.ERROR;
        }
      } else {
        // eq - double equals is intentional as only numbers can be added to the thresholds array
        if (value == check.thresholds[0]) {
          return LEVELS.PASS;
        }
        if (value == check.thresholds[1]) {
          return LEVELS.WARNING;
        }
        if (value == check.thresholds[2]) {
          return LEVELS.ERROR;
        }
      }
    }
    return check.nonCritical ? LEVELS.WARNING : LEVELS.ERROR;
  };

  const processCheck = useCallback(async () => {
    setLoadingState('loading');
    let prefetchedData;
    if (check.prefetch) {
      prefetchedData = await check.prefetch(offer, property);
    }
    const completed: any = {
      ...check,
      checkResult: check.check(offer, property, prefetchedData),
      resultResult: await check.result?.(offer, property, prefetchedData, check.linkBuilder),
      hidden: check.hidden?.(offer, property),
    };
    completed.summary = check.summary(completed.resultResult);
    completed.level = check.level ?? checkStateLevel(completed);
    completed.color = check.level && check.level === -1 ? grey : [green, orange, red][completed.level];

    setCompletedCheck(completed);
    setCheckResult({ level: completed.level, nonCritical: completed.nonCritical, hidden: completed.hidden });
    setLoadingState('success');
  }, [check, offer, property, setCheckResult]);

  useEffect(() => {
    processCheck();
  }, [offer, property]);

  return (
    <>
      {loadingState === 'loading' && <LinearProgress />}
      {completedCheck && (
        <Grid item xs={4}>
          <Box sx={{ position: 'relative' }}>
            <Typography
              variant="h6"
              onMouseEnter={() => {
                if (completedCheck.helpText && !expandedHelp) {
                  setExpandedHelp(true);
                }
              }}
              onMouseLeave={() => {
                if (completedCheck.helpText && expandedHelp) {
                  setExpandedHelp(false);
                }
              }}
            >
              {completedCheck.label}
            </Typography>
            <Typography
              sx={{
                color: (completedCheck.color ?? red)[500],
                cursor: completedCheck.resultResult && completedCheck.level > 0 ? 'pointer' : 'default',
              }}
              onClick={() =>
                completedCheck.canExpand !== false && completedCheck.resultResult && setExpanded(!expanded)
              }
            >
              {completedCheck.summary}
            </Typography>
            {completedCheck.resultResult && expanded && (
              <Stack
                sx={{
                  position: 'absolute',
                  zIndex: 99,
                  background: 'white',
                  padding: '1rem',
                  border: '1px solid #ccc',
                  boxShadow: '0 0 10px 0 #00000033',
                }}
              >
                {Array.isArray(completedCheck.resultResult) &&
                  completedCheck.resultResult.map(({ link, ...obj }, i) => (
                    <>
                      {link && (
                        <a key={`link-${i}`} href={link} target="_blank" rel="noreferrer">
                          Link to {completedCheck.label}
                        </a>
                      )}
                      <TraverseObject key={`obj-${i}`} obj={obj} />
                    </>
                  ))}
                {!Array.isArray(completedCheck.resultResult) && <TraverseObject obj={completedCheck.resultResult} />}
              </Stack>
            )}
            {completedCheck.helpText && expandedHelp && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 99,
                  background: 'white',
                  padding: '1rem',
                  border: '1px solid #ccc',
                  boxShadow: '0 0 10px 0 #00000033',
                }}
              >
                <Box dangerouslySetInnerHTML={{ __html: completedCheck.helpText }}></Box>
              </Box>
            )}
          </Box>
        </Grid>
      )}
    </>
  );
}
