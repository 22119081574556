import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

interface Props {
  onClose: (isSaved?: boolean) => void;
  isOpen: boolean;
}

export const CopyRatePlansModal = ({ onClose, isOpen }: Props) => {
  const { id_vendor: vendorId } = useParams<{ id_vendor: string }>();

  const [copyVendorId, setCopyVendorId] = useState(null);
  const [isCoping, setCoping] = useState(false);
  const [error, setError] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    onClose();

    setCoping(false);
    setCopyVendorId(null);
    setError(null);
  };

  const handleConfirm = () => {
    setCoping(true);
    setError(null);
    ReservationService.copyRatePlans(vendorId, copyVendorId)
      .then(() => {
        onClose(true);

        enqueueSnackbar('Rate plan copied successfully', { variant: 'success' });
      })
      .catch((err) => {
        reportError(err);
        setCoping(false);
        setError(err.message);
      });
  };

  const handleChange = (event) => {
    setCopyVendorId(event.target.value);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Copy rate plans from another vendor ID</DialogTitle>
        <DialogContent>
          <Box>
            {error && <ErrorDisplay message={error} />}
            {isCoping && <Spinner />}
            {!isCoping && (
              <Box className="copy-offer-form">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box flexGrow={3}>
                    <input
                      required
                      type="text"
                      placeholder="Type vendor ID here"
                      value={copyVendorId}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box flexGrow={1}>
                    <Button variant="contained" fullWidth onClick={handleConfirm}>
                      Copy
                    </Button>
                  </Box>
                </Stack>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
