import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Divider } from '@mui/material';
import { DataGrid, GridColDef, GridPagination, GridRenderCellParams } from '@mui/x-data-grid';

import PageSubheader from '../../../Common/Elements/PageSubheader';
import QuickSearchToolbar from '../../../Common/Elements/QuickSearchToolbar';

const ChainPropertiesPage = (props) => {
  const [properties, setProperties] = useState(props.properties);
  const [page, setPage] = useState(0);

  const columns: GridColDef[] = [
    {
      field: 'property_name',
      headerName: 'Name',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'property_id',
      headerName: 'Property ID',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'sfid',
      headerName: 'Vendor ID',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'parent_name',
      headerName: 'Chain',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'parent_id',
      headerName: 'Chain ID',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'geo_country',
      headerName: 'Country',
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'id',
      headerName: 'View',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 100,
      flex: 0,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button component={Link} to={`/vendors/${params.row.sfid}/properties/${params.row.property_id}`}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <PageSubheader title="Properties" />
      <Divider sx={{ mb: 2, mt: 1 }} />
      <DataGrid
        className="T-properties-chain-table"
        rows={properties || []}
        columns={columns}
        pageSizeOptions={[10]}
        paginationModel={{ pageSize: 10, page }}
        onPaginationModelChange={({ page }) => setPage(page)}
        getRowId={(row) => row.property_id}
        rowCount={properties.length}
        getRowHeight={() => 'auto'}
        slots={{
          pagination: GridPagination,
          toolbar: QuickSearchToolbar,
        }}
        autoHeight
        disableRowSelectionOnClick
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default ChainPropertiesPage;
