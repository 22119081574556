import React, { FormEventHandler } from 'react';

import { Button, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { DEFAULT_REASON_SOURCE, TNFH_REASON_SOURCE } from '~/consts/refund';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';
import MarkCancelled from '../Common/MarkCancelled';

interface Props {
  reason: CollectionReason;
  onSubmit: FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  defaultComment: string;
}

export default function FraudComplianceAndDispute({ onSubmit, reason, decrementStep, defaultComment }: Props) {
  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <CaseIDAndReasons reason={reason} defaultComment={defaultComment} />
        <RadioGroup row name="liabilitySource">
          <FormControlLabel
            data-cy="Both LE & Vendor are liable"
            value={DEFAULT_REASON_SOURCE}
            control={<Radio />}
            label="Both LE & Vendor are liable"
          />
          <FormControlLabel
            data-cy="Only LE is liable"
            value={TNFH_REASON_SOURCE}
            control={<Radio />}
            label="Only LE is liable"
          />
        </RadioGroup>
        <MarkCancelled />
      </DialogContent>

      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained">
          Continue
        </Button>
      </DialogActions>
    </form>
  );
}
