import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Box, FormLabel, Stack } from '@mui/material';

import { STEP_ADDITIONAL_INFO, STEP_PREREFUND_NIB_INSURANCE } from '~/consts/refund';

import CustomerRefundNotificationToggle from './CustomerRefundNotificationToggle';
import RefundMethod from './RefundMethod';
import SubmitButton from './SubmitButton';
import SummaryDetails from './SummaryDetails';

export default class Summary extends React.Component {
  state = {
    refundProviderFeeChecked: false,
  };

  constructor(props) {
    super(props);

    this.reason = props.getReasonInfo();
    this.hasLatitudePayment = props.order.payments.some(
      (p) => p.currency === 'AUD' && p.type === 'latitude' && p.intent === 'sale' && p.success === true,
    );
  }

  componentDidMount() {
    this.props.refund.is_insurance
      ? this.props.setBackStep(STEP_PREREFUND_NIB_INSURANCE)
      : this.props.setBackStep(STEP_ADDITIONAL_INFO);

    this.setState({
      refundProviderFeeChecked: this.props.refund.refund_provider_fee,
    });
  }

  onDiscountFeeClick = (event) => {
    this.setState({
      refundProviderFeeChecked: JSON.parse(event.target.value),
    });

    this.props.handleValueChange(event);
  };

  render() {
    return (
      <Box>
        {!this.props.refund.is_insurance && (
          <SummaryDetails
            ticket_id={this.props.refund.ticket_id}
            customer_full_name={this.props.order?.customer_full_name}
            reason={this.props.refund.reason}
            comment={this.props.refund.comment}
            source={this.props.refund.source}
            totalFee={currencyFormatter.format(this.props.totalFee(), {
              code: this.props.refund.currency_code,
            })}
            hasRefundSurcharge={this.props.hasRefundSurcharge() ? 'Yes' : 'No'}
            markCancelled={this.props.refund.mark_cancelled ? 'Yes' : 'No'}
            currencyCode={this.props.refund.currency_code}
            totalRefund={currencyFormatter.format(this.props.totalRefund(), {
              code: this.props.refund.currency_code,
            })}
          />
        )}
        <Stack direction="column" gap={2}>
          {this.props.refund.is_experience &&
            this.props.refund.item_metadata.vendor_refund_fee > 0 &&
            this.props.refund.item_metadata.cash_amount > 0 && (
              <Box>
                <FormLabel>Modify experience refund options</FormLabel>
                <Box>
                  <input
                    type="checkbox"
                    name="refund_provider_fee"
                    id="experience_settings"
                    value={!this.state.refundProviderFeeChecked}
                    checked={this.state.refundProviderFeeChecked}
                    onClick={this.onDiscountFeeClick}
                  />
                  <label htmlFor="experience_settings">
                    Refund vendor cancellation fee (LE at loss):{' '}
                    {currencyFormatter.format(this.props.refund.item_metadata.vendor_refund_fee, {
                      code: this.props.refund.currency_code,
                    })}
                  </label>
                </Box>
              </Box>
            )}

          <Box>
            <FormLabel>Select Method of Refund to Customer</FormLabel>
            <RefundMethod
              handleValueChange={this.props.handleValueChange}
              refund_method={this.props.refund.refund_method}
              hasLatitudePayment={this.hasLatitudePayment}
              methods={this.props.refund.methods}
            />
          </Box>

          {(this.props.refund.is_accommodation || this.props.refund.is_bedbank) && (
            <CustomerRefundNotificationToggle
              handleFlagChange={this.props.handleFlagChange}
              send_customer_refund_notification={this.props.refund.send_customer_refund_notification}
            />
          )}

          <SubmitButton
            setError={this.props.setError}
            refund={this.props.refund}
            order={this.props.order}
            totalRefund={this.props.totalRefund}
            totalFee={this.props.totalFee}
            hasRefundItem={this.props.hasRefundItem}
            hasRefundSurcharge={this.props.hasRefundSurcharge}
            totalPromoAmount={this.props.totalPromoAmount}
            accountingMetaPayload={this.props.accountingMetaPayload}
            closeModal={this.props.closeModal}
            stepHandler={this.props.stepHandler}
          />
        </Stack>
      </Box>
    );
  }
}
