import React, { useCallback, useState } from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { Link } from 'react-router-dom';

import { Button, Stack } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/muiSubmitButton';
import SetRegionModal from '~/components/Common/Modals/SetRegionModal';

import BundlePackagesService from '~/services/BundlePackagesService';

import { reportError } from '~/utils/reportError';

interface Props {
  schema: any;
  offerType: string;
  packageData: any;
  shouldSaveAll: boolean;
  offerBundles: any;
  offerAttachedPackages: any;
  onChange: (shouldRecalculateAttachedOptions?: boolean, attachedPackageOptions?: string[]) => void;
  onSaveFailed: () => void;
  onSaveComplete: (isPackageCreation: boolean) => void;
  onDeleteComplete: () => void;
}

type PackageData = {
  name?: string;
  description?: string;
  status?: string;
  attached_package_options?: [string];
  regions?: [string];
};

const getLinksFromOptions = (options, bundles, packages) => {
  const availabilityAndRatesLinks = [];
  options.forEach((option) => {
    const [offer_id, package_id, room_type_id, room_rate_id] = option.split('|');
    const offer = bundles.find((offer) => offer.id_salesforce_external === offer_id);
    const pkg = packages.find((pkg) => pkg.le_package_id === package_id);
    if (offer && pkg && room_type_id && room_rate_id) {
      availabilityAndRatesLinks.push(
        `/vendors/${offer.vendor_account_id}/properties/${pkg.fk_property_id}/room-types/${room_type_id}/room-rates/${room_rate_id}`,
      );
    }
  });
  return availabilityAndRatesLinks;
};

export default function PackageForm(props: Props) {
  const [packageData, setPackageData] = useState<PackageData>({
    name: props.packageData.name,
    description: props.packageData.description,
    status: props.packageData.status,
    attached_package_options: props.packageData.attached_package_options,
    regions: props.packageData.regions,
  });
  const [apiErrors, setApiErrors] = useState([]);
  const [saveState, setSaveState] = useState(buttonStates.default);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldSaveAll, setShouldSaveAll] = useState(props.shouldSaveAll);
  const [availabilityAndRatesLinks, setAvailabilityAndRatesLinks] = useState(
    getLinksFromOptions(props.packageData.attached_package_options, props.offerBundles, props.offerAttachedPackages),
  );

  const onSubmit = useCallback(
    async (form) => {
      setSaveState(buttonStates.saving);
      setPackageData(form.formData);

      const idPackage = props.packageData.le_package_id ?? props.packageData.id_salesforce_external;
      const idOffer = props.packageData.offer_id_salesforce_external;
      // deep cloning for submitting data
      const submitData = JSON.parse(JSON.stringify(form.formData));
      const isPackageCreation = idPackage.slice(0, 7) === 'fake_id';
      try {
        if (isPackageCreation) {
          await BundlePackagesService.createPackage(submitData, idOffer);
        } else {
          await BundlePackagesService.updatePackage(submitData, idOffer, idPackage);
        }

        setSaveState(buttonStates.saved);
        setApiErrors([]);
        props.onSaveComplete(isPackageCreation);
      } catch (error) {
        const apiErrors = [];
        if (error.name === 'ValidationError') {
          const regex = /instance[.|\s](\w+)[[\d\]|\s]+(.+)/i;
          error.errors.forEach((err) => {
            const match = err.message.match(regex);
            if (match?.length >= 3) {
              apiErrors.push(`${match[1].replace(/_/g, ' ')} ${match[2]}`);
            } else {
              apiErrors.push(err.message);
            }
          });
        }
        setSaveState(buttonStates.failed);
        setApiErrors(apiErrors);
        props.onSaveFailed();
        reportError(error);
      }
    },
    [props],
  );

  const removePackage = async () => {
    setSaveState(buttonStates.saving);

    const idPackage = props.packageData.le_package_id ?? props.packageData.id_salesforce_external;
    const idOffer = props.packageData.offer_id_salesforce_external;
    const isBlankPackage = idPackage.slice(0, 7) === 'fake_id';
    try {
      if (!isBlankPackage) {
        await BundlePackagesService.deletePackage(idOffer, idPackage);
      }

      setSaveState(buttonStates.default);
      props.onDeleteComplete();
    } catch (error) {
      const apiErrors = [];
      if (error.name === 'ValidationError') {
        error.errors.forEach((err) => {
          apiErrors.push(err.message);
        });
      }
      setApiErrors(apiErrors);
      reportError(error);
    }
  };

  const onChange = (edit, changedField) => {
    setApiErrors([]);
    if (saveState !== buttonStates.default) {
      setSaveState(buttonStates.default);
    }

    setPackageData(edit.formData);

    let shouldRecalculateAttachedOptions = false;
    let attachedPackageOptions = [];
    if (changedField === 'root_attached_package_options') {
      shouldRecalculateAttachedOptions = true;
      attachedPackageOptions = edit.formData.attached_package_options;
    }
    setAvailabilityAndRatesLinks(
      getLinksFromOptions(attachedPackageOptions, props.offerBundles, props.offerAttachedPackages),
    );
    props.onChange(shouldRecalculateAttachedOptions, attachedPackageOptions);
  };

  const getRegionNameFromCode = (code) => {
    const region = libRegions.getRegionByCode(code, 'luxuryescapes');
    return region ? region.name : code;
  };

  const setRegions = (regions) => {
    setPackageData((prevState) => {
      return {
        ...prevState,
        regions: regions,
      };
    });
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (shouldSaveAll !== props.shouldSaveAll) {
    setShouldSaveAll(props.shouldSaveAll);
    onSubmit({
      formData: {
        ...packageData,
      },
    });
  }

  return (
    <div>
      <Form
        schema={props.schema}
        formData={packageData}
        onSubmit={onSubmit}
        className="package-form"
        onChange={onChange}
        validator={validator}
        showErrorList={false}
      >
        <div className="regions-tag-container">
          <div>
            <label className="control-label">Package Regions</label>
          </div>
          <div>
            <ul className="region-tags-list">
              {packageData.regions.length === libRegions.getRegions().length ? (
                <li key="world">world</li>
              ) : (
                packageData.regions.map((region) => <li key={region}>{getRegionNameFromCode(region)}</li>)
              )}
            </ul>
          </div>
          <div>
            <Button variant="contained" onClick={showModal}>
              Set Regions
            </Button>
          </div>
          <Stack mt={2} direction="column" alignItems="flex-start">
            {availabilityAndRatesLinks.map((link) => {
              return (
                <Button
                  component={Link}
                  to={link}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(link, '_blank');
                  }}
                >
                  View Availability &amp; Rates&nbsp;
                </Button>
              );
            })}
          </Stack>
        </div>
        <div className="packages-errors text-capitalize">{apiErrors}</div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            style={{ marginRight: '8px' }}
            variant="contained"
            color={buttonStates.failed}
            disabled={saveState === buttonStates.saving}
            onClick={removePackage}
          >
            Remove
          </Button>
          <Button
            type="submit"
            variant="contained"
            color={saveState === buttonStates.saved ? buttonStates.saved : buttonStates.default}
            disabled={saveState === buttonStates.saving}
          >
            {buttonMessages[saveState]}
          </Button>
        </div>
      </Form>

      <SetRegionModal
        open={isModalOpen}
        onRequestClose={closeModal}
        onSet={setRegions}
        regions={packageData.regions}
        name="Package Regions"
        label="Tag all regions that you want this package to be visible in"
      />
    </div>
  );
}
