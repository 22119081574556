import { RJSFSchema } from '@rjsf/utils';

export default function convertSchemaTypesWithIsoFormatToDate(schema: RJSFSchema) {
  const properties = { ...schema.properties };
  Object.entries(properties).forEach(([key, value]) => {
    if (typeof value !== 'boolean' && value.type === 'string' && value.format === 'ISO8601') {
      properties[key]['format'] = 'date';
    }
  });
  return { ...schema, properties };
}
