import ReservationService from '~/services/ReservationService';
import { formatDateISO } from '~/services/TimeService';

import { convertValueByFX } from './convertValueByFX';

/**
 * Retrieves the surcharge date block prices for a given property, room type, and room rate.
 *
 * @param propertyId - The ID of the property.
 * @param roomTypeId - The ID of the room type.
 * @param roomRateId - The ID of the room rate.
 * @param price - The base price to convert.
 * @param currency - The currency of the base price.
 * @returns A promise that resolves to an object containing the prices in different currencies.
 */
async function getSurchargeDateBlockPrices(
  propertyId: string,
  roomTypeId: string,
  roomRateId: string,
  price: number,
  currency: string,
): Promise<{ [currencyCode: string]: number }> {
  // Retrieve surcharge date block schema
  const surchargeSchema = await ReservationService.getSurchargeDateBlockSchema(propertyId, roomTypeId, roomRateId);
  const currencyCodes = surchargeSchema.post.body.schema.properties.prices.required;

  // Retrieve FX rates and map them to a currency: rate object
  const fxRatesResponse = await ReservationService.getFXRates();
  const fxRates = fxRatesResponse.result.reduce((acc, fxRate) => {
    acc[fxRate.currency] = fxRate.rate;
    return acc;
  }, {});

  // Calculate prices in each currency based on FX rates
  const prices = currencyCodes.reduce((acc, currencyCode) => {
    acc[currencyCode] = convertValueByFX({
      fxRates,
      fromCurrency: currency,
      toCurrency: currencyCode,
      value: price,
    });
    return acc;
  }, {});

  return prices;
}

/**
 * Generates a surcharge date block with formatted date ranges and surcharge prices.
 *
 * @param propertyId - The ID of the property.
 * @param roomTypeId - The ID of the room type.
 * @param roomRateId - The ID of the room rate.
 * @param surchargeAmount - The amount of the surcharge.
 * @param ranges - An array of date ranges for the surcharge.
 * @param costAmount - The cost amount (optional).
 * @param costCurrency - The currency of the cost amount (default: 'AUD').
 * @param monday - Whether to include Monday (default: true).
 * @param tuesday - Whether to include Tuesday (default: true).
 * @param wednesday - Whether to include Wednesday (default: true).
 * @param thursday - Whether to include Thursday (default: true).
 * @param friday - Whether to include Friday (default: true).
 * @param saturday - Whether to include Saturday (default: true).
 * @param sunday - Whether to include Sunday (default: true).
 * @param isAllowedZeroSellingPrices - Whether zero selling prices are allowed (default: false).
 * @param surchargeType - The type of surcharge ('internal' or 'vendor').
 * @returns An object representing the surcharge date block.
 */
export async function presentSurchargeDateBlock({
  propertyId,
  roomTypeId,
  roomRateId,
  surchargeAmount,
  surchargeCurrency,
  ranges,
  costAmount = 0,
  costCurrency = 'AUD',
  monday = true,
  tuesday = true,
  wednesday = true,
  thursday = true,
  friday = true,
  saturday = true,
  sunday = true,
  isAllowedZeroSellingPrices = false,
  surchargeType,
}: {
  propertyId: string;
  roomTypeId: string;
  roomRateId: string;
  surchargeAmount: number;
  surchargeCurrency: string;
  ranges: Array<{ startDate: string; endDate: string }>;
  costAmount?: number;
  costCurrency?: string;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  isAllowedZeroSellingPrices?: boolean;
  surchargeType: 'internal' | 'vendor';
}) {
  const formattedRanges = ranges.map(({ startDate, endDate }) => ({
    start_date: formatDateISO(startDate),
    end_date: formatDateISO(endDate),
  }));
  const prices = await getSurchargeDateBlockPrices(
    propertyId,
    roomTypeId,
    roomRateId,
    surchargeAmount,
    surchargeCurrency,
  );

  return {
    ranges: formattedRanges,
    cost_amount: costAmount,
    cost_currency: costCurrency,
    prices,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    is_allowed_zero_selling_price: isAllowedZeroSellingPrices,
    surcharge_type: surchargeType,
  };
}
