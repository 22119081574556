import React, { useCallback, useEffect, useState } from 'react';

import { Box, CircularProgress, FormControl, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { getAllForceBundles, getForceBundleAvailability } from '~/services/FlightsService';

import { ForceBundleView } from './ForceBundleView';

export const FlightForceBundles = () => {
  const [loadingState, setLoadingState] = useState({
    bundleLoading: true,
    availabilityLoading: false,
  });

  const [forceBundles, setForceBundles] = useState<App.ForceBundle[]>([]);

  const [selectedForceBundle, setSelectedForceBundle] = useState<App.ForceBundle>();

  const [selectedForceBundleAvailability, setSelectedForceBundleAvailability] = useState<
    App.ForceBundle & { amountAvailable: number }
  >();

  useEffect(() => {
    async function fetchForceBundles() {
      setLoadingState((rest) => ({
        ...rest,
        bundleLoading: true,
      }));
      const bundles = (await getAllForceBundles())?.result;
      setForceBundles(bundles);
      setLoadingState((rest) => ({
        ...rest,
        bundleLoading: false,
      }));
    }
    fetchForceBundles();
  }, []);

  useEffect(() => {
    if (!selectedForceBundle) {
      return;
    }
    async function fetchForceBundleAvailability() {
      setLoadingState((rest) => ({
        ...rest,
        availabilityLoading: true,
      }));
      const availability = (
        await getForceBundleAvailability({
          origin: selectedForceBundle.origins[0],
          destination: selectedForceBundle.destinations[0],
          ...selectedForceBundle,
        })
      ).result;
      setSelectedForceBundleAvailability(availability?.[0]);
      setLoadingState((rest) => ({
        ...rest,
        availabilityLoading: false,
      }));
    }
    fetchForceBundleAvailability();
  }, [selectedForceBundle]);

  const onForceBundleChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      setSelectedForceBundle(forceBundles?.[event.target.value]);
    },
    [forceBundles],
  );

  return (
    <Box sx={{ flexGrow: 1, rowGap: 4 }} mt={4}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Select label="Select force bundle" placeholder="Select force bundle" onChange={onForceBundleChange}>
              {forceBundles.map((forceBundle, idx) => {
                return (
                  <MenuItem key={forceBundle.id} value={idx}>
                    {forceBundle.dealName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {loadingState.availabilityLoading || loadingState.bundleLoading ? (
            <CircularProgress />
          ) : (
            <ForceBundleView
              selectedForceBundle={selectedForceBundle}
              availability={selectedForceBundleAvailability?.amountAvailable ?? NaN}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
