import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageHeader from '~/components/Common/Elements/PageHeader';
import ResponsiveImage from '~/components/Common/Elements/ResponsiveImage';

const actionsCell = (params: GridRenderCellParams) => {
  const vendorId = params.row.vendor_id;
  const addonId = params.row.addon_opportunity_salesforce_id;
  const dealOptionId = params.row.addon_deal_option_salesforce_id;

  return (
    <div>
      <Button
        component={Link}
        variant="outlined"
        size="small"
        to={`/vendors/${vendorId}/addons/${addonId}/deal-options/${dealOptionId}/edit`}
      >
        Edit
      </Button>
    </div>
  );
};

const imageCell = (params: GridRenderCellParams) => {
  const cloudinaryId = params.row.cloudinary_id ? params.row.cloudinary_id : params.row.salesforce_cloudinary_id;

  return <ResponsiveImage imageId={cloudinaryId} />;
};

const columns: GridColDef[] = [
  { field: 'addon_deal_option_salesforce_id', headerName: 'ID', width: 200, display: 'flex' },
  { field: 'name', headerName: 'Deal Option Name', flex: 1, display: 'flex' },
  { field: 'channel_fare_type', headerName: 'Channel Fare Type', width: 200, display: 'flex' },
  { field: 'channel_grouping_id', headerName: 'Grouping ID', width: 200, display: 'flex' },
  { field: 'cloudinary_id', headerName: 'Image', width: 200, renderCell: imageCell, display: 'flex' },
  { field: 'actions', headerName: '', align: 'right', renderCell: actionsCell, display: 'flex' },
];

type Props = {
  addon: any;
  addonOptions: any[];
  total: number;
  currentPage: number;
  sizePerPage: number;
  onPageChange: (page: number) => void;
};

export function Component({ addon, addonOptions, total, currentPage, sizePerPage, onPageChange }: Props) {
  return (
    <>
      <PageHeader title={addon.name} backButton={`/vendors/${addon.vendor_id}`} />

      <DataGrid
        columns={columns}
        rows={addonOptions}
        rowCount={total}
        getRowId={(row) => row.addon_deal_option_salesforce_id}
        getRowHeight={() => 'auto'}
        pagination
        paginationModel={{ page: currentPage - 1, pageSize: sizePerPage }}
        pageSizeOptions={[sizePerPage]}
        onPaginationModelChange={({ page }) => onPageChange(page + 1)}
        slots={{ pagination: GridPagination }}
        autoHeight
      />
    </>
  );
}
