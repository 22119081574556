import React, { useCallback } from 'react';

import { TableCell, TableRow } from '@mui/material';

import BestLabel from './BestLabel';
import OnlineLabel from './OnlineLabel';

interface Props {
  bestTemplateId: string;
  selectedTemplateId: string;
  template: App.TypeaheadSearchTemplate;
  onTemplateSelected: (templateId: string) => void;
}

enum TemplateStatus {
  EVALUATED = 'evaluated',
}

export default function Row({ bestTemplateId, selectedTemplateId, template, onTemplateSelected }: Props) {
  const onRowClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const element = event.currentTarget as HTMLElement;
      const id = element.getAttribute('data-id');
      if (id) {
        onTemplateSelected(id);
      }
    },
    [onTemplateSelected],
  );

  const diff = template.score_diff && template.score_diff * 100;
  const diffText = diff > 0 ? `+${diff.toFixed(2)}` : diff?.toFixed(2);
  const statusOrScore =
    template.status === TemplateStatus.EVALUATED ? (template.score * 100).toFixed(2) : template.status;
  const diffColor = diff > 0 ? 'green' : 'red';
  return (
    <TableRow
      key={template.id}
      data-id={template.id}
      selected={template.id === selectedTemplateId}
      onClick={onRowClick}
    >
      <TableCell sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
        {template.name} {template.is_active && <OnlineLabel />}{' '}
      </TableCell>
      <TableCell>{template.created_at}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {statusOrScore}&nbsp;
        <span style={{ color: diffColor }}>{diff ? `(${diffText})` : ''}</span>
        {template.id === bestTemplateId && <BestLabel />}
      </TableCell>
    </TableRow>
  );
}
