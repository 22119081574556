import {
  FETCH_ORDER_FAIL,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCEED,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_START,
  UPDATE_ORDER_SUCCEED,
} from '../actions/order';

const initialState = { items: {}, isLoading: true, isUpdating: false };

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDER_START: {
      const newState = { ...state };
      delete newState.error;
      return {
        ...newState,
        isLoading: true,
      };
    }
    case FETCH_ORDER_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        items: Object.assign({}, state.items, {
          [action.order.id]: action.order,
        }),
      };
    }
    case FETCH_ORDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case UPDATE_ORDER_START: {
      const newState = { ...state };
      delete newState.error;
      return {
        ...newState,
        isUpdating: true,
      };
    }
    case UPDATE_ORDER_SUCCEED: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case UPDATE_ORDER_FAIL: {
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
