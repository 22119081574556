import React from 'react';

import { Link } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Tooltip } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import ScheduleDeletionDialog from './ScheduleDeletionDialog';

interface Props {
  scheduleId: string;
}

function ScheduleRowActions(props: Props) {
  const { scheduleId } = props;
  const {
    isToggled: isDeletionModalOpen,
    toggleOn: openDeletionModal,
    toggleOff: closeDeletionModal,
  } = useToggleState(false);

  return (
    <Stack direction="column">
      <Tooltip title="Edit in the editor">
        <IconButton component={Link} to={`/customer-communication/hero-planner/schedules/${scheduleId}`}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Duplicate as a new schedule">
        <IconButton component={Link} to={`/customer-communication/hero-planner/schedules/${scheduleId}?duplicate=true`}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Open the  deletion modal">
        <IconButton onClick={openDeletionModal}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ScheduleDeletionDialog scheduleId={scheduleId} open={isDeletionModalOpen} onClose={closeDeletionModal} />
    </Stack>
  );
}

export default ScheduleRowActions;
