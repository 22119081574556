import React, { useCallback, useState } from 'react';

import * as RE from '~/reducers/experiences';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { FILTER_KEYS, FILTER_RANGE_KEYS, FilerKeys, themes } from '~/consts/experiences';

import * as ES from '~/services/ExperiencesService';

import * as LE from '../components';

import { ExperienceFilterContent } from './Content/ExperienceFilterContent';
import { FieldValueParams } from './Experiences';

type FiltersProps = {
  tab: string;
  ready: boolean;
  filters: ES.Filters;
  searchOptions: string[];
  toggleOptions: string[];
  fieldSearchValue: string;
  resetFilters: () => void;
  categories: RE.CategoriesState;
  onSelectToggle: (opt: string) => void;
  fetchExperiencesWithFilters: () => void;
  toggleSelected: { [key: string]: boolean };
  handleSearchFieldValue: (value: string) => void;
  onSelectLocation: (address: string) => Promise<void>;
  handleFieldValue: (params: FieldValueParams) => void;
  setFiltersByKey: (key: 'categories' | 'status', value: string) => void;
  searchModeSelected: SearchModeOptions;
  setSearchModeSelected: (option: SelectChangeEvent<SearchModeOptions>) => void;
  bulkUpdate: () => void;
  bulkProcessing: boolean;
  downloadOffersReport: (format: 'csv' | 'xlsx') => void;
  downloadingOffersReport: boolean;
};

export enum SearchModeOptions {
  ID = 'idIn',
  TITLE = 'title',
  VENDOR = 'vendor',
  LOCATION = 'location',
}

const searchModeOptionsLabels = {
  [SearchModeOptions.ID]: 'ID',
  [SearchModeOptions.TITLE]: 'Title',
  [SearchModeOptions.VENDOR]: 'Vendor',
  [SearchModeOptions.LOCATION]: 'Location',
};

export const ExperiencesFormFilters: React.FC<FiltersProps> = (props) => {
  const {
    tab,
    ready,
    filters,
    categories,
    resetFilters,
    searchOptions,
    toggleOptions,
    toggleSelected,
    onSelectToggle,
    setFiltersByKey,
    fieldSearchValue,
    onSelectLocation,
    handleFieldValue,
    handleSearchFieldValue,
    fetchExperiencesWithFilters,
    searchModeSelected,
    setSearchModeSelected,
    bulkUpdate,
    bulkProcessing,
    downloadOffersReport,
    downloadingOffersReport,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const countFiltersSelected = useCallback(() => {
    const arr = Object.entries(filters).filter((item) => {
      if (FILTER_RANGE_KEYS.includes(item[0])) {
        return item[1]?.min || item[1]?.max;
      }
      if (FILTER_KEYS.includes(item[0] as FilerKeys) && item[1]?.length > 0) {
        return item[1];
      }
    });

    return arr.length;
  }, [filters.categories, filters.status, filters.price, filters.rating, filters.providerIn]);

  const applyFilters = useCallback(() => {
    fetchExperiencesWithFilters();
    handleClose();
  }, [filters]);

  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box>
          <Select onChange={setSearchModeSelected} value={searchModeSelected} fullWidth>
            {Object.entries(searchModeOptionsLabels).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box flexGrow={1}>
          {searchModeSelected == SearchModeOptions.LOCATION && (
            <LE.FieldSearch
              autoComplete="off"
              name="experiences-location"
              value={fieldSearchValue || ''}
              onClickOption={onSelectLocation}
              options={ready && searchOptions}
              onChange={handleSearchFieldValue}
              placeholder="Search experiences by location"
              fullWidth
            />
          )}
          {searchModeSelected == SearchModeOptions.ID && (
            <LE.Field
              autoComplete="off"
              name="experiences-id"
              value={fieldSearchValue || ''}
              onChange={(params) => handleSearchFieldValue(params.value)}
              placeholder="Search experiences by ID separateds by comma"
              fullWidth
            />
          )}

          {searchModeSelected == SearchModeOptions.TITLE && (
            <LE.Field
              autoComplete="off"
              name="experiences-title"
              value={fieldSearchValue || ''}
              onChange={(params) => handleSearchFieldValue(params.value)}
              placeholder="Search experiences by title"
            />
          )}

          {searchModeSelected == SearchModeOptions.VENDOR && (
            <LE.Field
              autoComplete="off"
              name="experiences-vendor"
              value={fieldSearchValue || ''}
              onChange={(params) => handleSearchFieldValue(params.value)}
              placeholder="Search experiences by vendor"
              fullWidth
            />
          )}
        </Box>

        <Box>
          <LE.FlexWrapper justify="flex-end">
            <Badge badgeContent={countFiltersSelected().toString()} color="secondary">
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                Filters
              </Button>
            </Badge>

            {tab === 'experiences-curation' && (
              <>
                <LE.LineDivisor mx={'15px'} />

                <LE.Toggle options={toggleOptions} onClick={onSelectToggle} selected={toggleSelected} />

                <LE.LineDivisor mx={'15px'} />

                <ButtonGroup variant="contained">
                  <Button onClick={bulkUpdate} disabled={bulkProcessing} sx={{ whiteSpace: 'nowrap' }}>
                    {bulkProcessing ? 'Loading...' : 'Bulk Update'}
                  </Button>

                  <Button
                    onClick={() => downloadOffersReport('xlsx')}
                    disabled={downloadingOffersReport}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {downloadingOffersReport ? 'Processing...' : 'Export XLSX'}
                  </Button>

                  <Button
                    onClick={() => downloadOffersReport('csv')}
                    disabled={downloadingOffersReport}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {downloadingOffersReport ? 'Processing...' : 'Export CSV'}
                  </Button>
                </ButtonGroup>
              </>
            )}
          </LE.FlexWrapper>
        </Box>
      </Stack>

      <Drawer open={isOpen} anchor="left" onClose={handleClose} title="Experience Filters">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
          <Typography variant="h4" color={themes?.primaryBlue}>
            Experience Filters
          </Typography>
          <IconButton onClick={handleClose}>
            <CancelOutlinedIcon fontSize="large" />
          </IconButton>
        </Box>
        <ExperienceFilterContent
          filters={filters}
          categories={categories}
          setFiltersByKey={setFiltersByKey}
          handleFieldValue={handleFieldValue}
        />
        <LE.FlexWrapper direction="column">
          <LE.FlexWrapper mt={'15px'}>
            <LE.Button title="Apply Filters" onClick={applyFilters} />
          </LE.FlexWrapper>

          <LE.FlexWrapper mt={'15px'}>
            <LE.OutlineButton title="Reset Filters" onClick={resetFilters} borderColor={themes.fourthBlue} />
          </LE.FlexWrapper>

          <LE.FlexWrapper my={'15px'}>
            <LE.OutlineButton title="Cancel" onClick={handleClose} borderColor={themes?.primaryGrey} />
          </LE.FlexWrapper>
        </LE.FlexWrapper>
      </Drawer>
    </>
  );
};
