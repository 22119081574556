import React from 'react';

import ABAFileForm from '../Common/Forms/ABAFileForm';
import IMTFileForm from '../Common/Forms/IMTFileForm';
import VCCFileForm from '../Common/Forms/VCCFileForm';

import ContentWithSidebar from './ContentWithSidebar';

const DEFUALT_ITEM = 'aba-file';

const items = [
  { title: 'ABA File', slug: 'aba-file', component: ABAFileForm },
  { title: 'IMT File', slug: 'imt-file', component: IMTFileForm },
  { title: 'VCC File', slug: 'vcc-file', component: VCCFileForm },
];

export default function BankFilesPage() {
  return <ContentWithSidebar items={items} defaultItem={DEFUALT_ITEM} />;
}
