import React, { ChangeEvent } from 'react';

type CheckboxProps = {
  name?: string;
  checked: boolean;
  className?: string;
  onChange: (check: boolean) => void;
};

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { name, checked, className, onChange } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget?.checked);
  };

  return <input id={name} className={className} checked={checked} onChange={handleChange} type="checkbox" />;
};
