import { useCallback } from 'react';

import { type SetCurrentUserPayload, setCurrentUser } from '~/actions/currentUser';

import { useAppDispatch, useAppSelector } from './store';

export default function useCurrentUser() {
  const dispatch = useAppDispatch();

  const { isLoading, isAuthenticated, user } = useAppSelector((state) => state.currentUser);

  const setCurrentUserState = useCallback(
    (payload: SetCurrentUserPayload) => {
      dispatch(setCurrentUser(payload));
    },
    [dispatch],
  );

  return { isLoading, isAuthenticated, user, setCurrentUserState };
}
