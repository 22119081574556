import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { API } from '@luxuryescapes/lib-types';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import { unix } from '~/services/TimeService';

import BlackoutDateForm from '../../Common/Forms/BlackoutDateForm';
import PermissionComponent from '../../Common/PermissionedComponent';

import BlackoutDateBlock from './BlackoutDateBlock';

interface Props {
  propertyId: string;
  channelManaged: boolean;
  roomType: API.Reservation.RoomType;
  roomRate: API.Reservation.RoomRate;
  propertyRoomTypes: Array<App.RoomType>;
  blackoutDates: Array<App.BlackoutDate>;
  deleteBlackoutDateBlock: (id: string) => void;
  onUpdateBlackoutDates: (dates: Array<App.BlackoutDate>) => void;
  isDeletingAll: boolean;
  onDeleteAll: () => void;
}

export default function BlackoutDates(props: Props) {
  const [addingNew, setAddingNew] = useState(false);

  const showConfirmDialog = useConfirmDialog();

  const openAddBox = () => {
    setAddingNew(true);
  };

  const closeAddBox = () => {
    setAddingNew(false);
  };

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete all? This action cannot be undone.',
    });

    if (!confirmed || props.isDeletingAll) return;

    props.onDeleteAll();
  };

  const onBlackoutDateBlocksAdded = (newBlackoutDateBlocks) => {
    if (newBlackoutDateBlocks[props.roomRate.id]) {
      const blackoutDates = props.blackoutDates;
      newBlackoutDateBlocks[props.roomRate.id].dates.forEach((date) => {
        blackoutDates.push(date);
      });
      props.onUpdateBlackoutDates(blackoutDates);
      setAddingNew(false);
    }
  };

  const blackoutDatesCompare = (a, b) => {
    return unix(a.start_date) - unix(b.start_date);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid pb={0}>
        <Typography className="text-uppercase text-muted">Room Rate Blackout Dates</Typography>
      </Grid>

      {props.blackoutDates.length === 0 ? (
        <Grid>
          <i>No blackout dates set</i>
        </Grid>
      ) : (
        <Grid>
          {props.blackoutDates.sort(blackoutDatesCompare).map((blackoutDateBlock) => (
            <BlackoutDateBlock
              key={blackoutDateBlock.id}
              blackoutDateBlock={blackoutDateBlock}
              channelManaged={props.channelManaged}
              onDelete={props.deleteBlackoutDateBlock}
            />
          ))}
        </Grid>
      )}

      {props.channelManaged ? (
        <Grid>
          <i>* Blackout dates controlled by channel manager</i>
        </Grid>
      ) : (
        <Grid xs={12} display="flex" justifyContent="space-between">
          <Button onClick={openAddBox} variant="contained" className="add-button">
            Add
          </Button>
          {props.blackoutDates && props.blackoutDates.length > 0 && (
            <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
              <Button
                title="Delete"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              >
                Delete All
              </Button>
            </PermissionComponent>
          )}
        </Grid>
      )}

      <Dialog open={addingNew} onClose={closeAddBox}>
        <DialogTitle>Apply Blackout Dates</DialogTitle>

        <DialogContent>
          <BlackoutDateForm
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            propertyRoomTypes={props.propertyRoomTypes}
            onCancel={closeAddBox}
            onBlackoutDateBlocksAdded={onBlackoutDateBlocksAdded}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
