import React from 'react';

import styled from 'styled-components';

import { Paper } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { allRegions } from '../../../../consts/allRegions';
import { getAllScheduledRegions } from '../../../../utils/getAllScheduledRegions';
import { getRegionsNameByCode } from '../../../../utils/getRegionsNameByCode';
import { groupRegionsBySchedule } from '../../../../utils/groupRegionsBySchedule';

import FrontPageScheduleEdit from './FrontPageScheduleEdit';

const ScheduleActionButton = styled.span`
  &:hover {
    cursor: ${(props) => (props.shouldMigrateSchedule ? 'not-allowed' : 'pointer')};
  }
`;

function formatDate(date_in) {
  let date = new Date(date_in);
  return date.toDateString();
}

class ScheduleRow extends React.Component {
  state = {
    isExpanded: false,
  };

  onEdit = () => {
    if (!this.props.shouldMigrateSchedule) {
      this.setState({ isExpanded: !this.state.isExpanded });
    }
  };

  render() {
    const { start, end, index, offerId, idList, regionsObj, type, shouldMigrateSchedule } = this.props;

    const regions = getRegionsNameByCode(this.props.regions, allRegions);
    const allScheduledRegions = getRegionsNameByCode(this.props.allScheduledRegions, allRegions);
    const disabledRegions = allScheduledRegions.filter((r) => !regions.includes(r));
    const enabledRegions = libRegions.getRegions().filter((el) => !disabledRegions.includes(el.name));

    const uncheckedRegions = enabledRegions.map((c) => c.name).filter((r) => !regions.includes(r));

    const regionsList = new Map();
    regions.map((name) => regionsList.set(name, true));
    uncheckedRegions.map((name) => regionsList.set(name, false));

    const header = (
      <div className="lux-schedule-row" key={index}>
        <div className="lux-schedule-regions">{regions.join(', ')}</div>
        <div className="lux-schedule-date-row">
          <div className="date">
            <div className="date-start">{formatDate(start)}</div>
            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>&#8594;</div>
            <div className="date-end">{formatDate(end)}</div>
          </div>
          <div className="button-link">
            <ScheduleActionButton
              className="select-link first"
              shouldMigrateSchedule={shouldMigrateSchedule}
              onClick={this.onEdit}
            >
              Edit
            </ScheduleActionButton>
            <span className="bar">|</span>
            <ScheduleActionButton
              className="select-link"
              shouldMigrateSchedule={shouldMigrateSchedule}
              onClick={() => this.props.onDelete(idList)}
            >
              Delete
            </ScheduleActionButton>
          </div>
        </div>
      </div>
    );
    return (
      <Paper key={index}>
        <PageSubheader title={header} />

        <FrontPageScheduleEdit
          selectedRegion={regionsList}
          enabledRegions={enabledRegions}
          startDate={start}
          endDate={end}
          type={type}
          onClose={() => this.setState({ isExpanded: false })}
          offerId={offerId}
          idList={this.props.idList}
          selectedRegionObj={regionsObj}
          onSaveFinished={this.props.onSaveFinished}
          isNewSchedule={false}
        />
      </Paper>
    );
  }
}

export default class LuxuryEscapesScheduleContainer extends React.Component {
  render() {
    const { schedules, onSaveFinished, type, shouldMigrateSchedule } = this.props;
    const uniqueAllRegions = getAllScheduledRegions(schedules, type);
    return groupRegionsBySchedule(schedules, type).map((row, index) => {
      return (
        <ScheduleRow
          regions={row.regions}
          start={row.start}
          end={row.end}
          index={row.id}
          offerId={row.offerId}
          key={`${index}-${row.id}`}
          idList={row.idList}
          regionsObj={row.regionsObj}
          onDelete={this.props.onDelete}
          allScheduledRegions={uniqueAllRegions}
          onSaveFinished={onSaveFinished}
          type={type}
          shouldMigrateSchedule={shouldMigrateSchedule}
        />
      );
    });
  }
}
