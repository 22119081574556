import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { accountFieldsMap, getBonusUnitPluralize } from '../../../services/LoyaltyService';
import LoyaltyEarnForm from '../../Common/Forms/LoyaltyEarnForm';
import LoyaltyDetailsModal from '../../Users/Loyalty/DetailsModal';

interface Props {
  data: any;
  partnership: any;
  order: App.Order;
  newPointsRequest: any;
  customer: App.User;
  status: any;
  showForm: boolean;
}

interface State {
  showDetails: boolean;
  details: string;
  type: string;
  data: any;
  showForm: boolean;
}

export default class OrderDetailRewards extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      details: '',
      type: '',
      data: props.data.result,
      showForm: false,
    };
  }

  openDetailsModal = (data, type) => {
    this.setState({ showDetails: true, details: data, type: type });
  };

  closeDetailsModal = () => {
    this.setState({ showDetails: false, details: '', type: '' });
  };

  closeFormModal = () => {
    this.setState({ showForm: false });
  };

  openFormModal = (event) => {
    event.stopPropagation();
    this.setState({ showForm: true });
  };

  render() {
    const { partnership } = this.props;
    const accountFields = accountFieldsMap(partnership);
    const bonusUnit = getBonusUnitPluralize(partnership);
    const noDataMessage = this.props.status.message !== '' ? this.props.status.message : 'No data';

    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'Id',
        sortable: false,
        disableColumnMenu: true,
        flex: 1.2,
        display: 'flex',
      },
    ];
    accountFields.map((fieldData) =>
      columns.push({
        field: fieldData.field,
        headerName: fieldData.name,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: 'flex',
      }),
    );
    columns.push(
      {
        field: 'gifter_user_id',
        headerName: "Gifter's user ID",
        sortable: false,
        disableColumnMenu: true,
        flex: 1.5,
        display: 'flex',
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        sortable: false,
        disableColumnMenu: true,
        flex: 2,
        display: 'flex',
      },
      {
        field: `${bonusUnit}_total_earned`,
        headerName: `${bonusUnit} earned`,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: 'flex',
      },
      {
        field: null,
        headerName: null,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.5,
        renderCell: (params) => {
          return <Button onClick={() => this.openDetailsModal(params.row, 'earn')}>View Details</Button>;
        },
        display: 'flex',
      },
    );

    return (
      <Accordion key={partnership.programName}>
        <AccordionSummary
          sx={{
            backgroundColor: 'grey.200',
            height: '60px',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
            <Typography>{partnership.programName}</Typography>
            {this.props.showForm && (
              <Button variant="contained" onClick={this.openFormModal}>
                Request {bonusUnit}
              </Button>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            columns={columns}
            rows={this.state.data}
            getRowId={(row) => row.id}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
            localeText={{
              noRowsLabel: noDataMessage,
            }}
          />
          <Typography variant="subtitle1">
            * This table does not reflect the current status of the {bonusUnit} in customers{' '}
            {partnership.prefix.toUpperCase()} account.
          </Typography>
          <LoyaltyDetailsModal
            show={this.state.showDetails}
            onHide={this.closeDetailsModal}
            data={this.state.details}
            type={this.state.type}
            partnership={this.props.partnership}
          />
          <LoyaltyEarnForm
            requestor={this.context.user}
            newPointsRequest={this.props.newPointsRequest}
            partnership={this.props.partnership}
            order={this.props.order}
            customer={this.props.customer}
            show={this.state.showForm}
            onHide={this.closeFormModal}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
}
