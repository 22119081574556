import useCurrentUser from '~/hooks/useCurrentUser';

import { isAdmin } from '~/utils/adminPermission';

interface Props {
  // a user will be excluded if it has these roles
  // UNLESS they are also admin
  excludedRoles?: Array<string>;
  children?: any;
}

export default function RestrictedComponent({ excludedRoles, children }: Props) {
  const { user } = useCurrentUser();

  const userHasAnyExcludedRole =
    user && !isAdmin(user) && excludedRoles?.some((excludedRole) => user?.roles?.includes(excludedRole));

  if (!userHasAnyExcludedRole) {
    return children ?? null;
  }
  return null;
}
