import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Button, Stack, TextField } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import PropertiesSearchResultsContainer from '~/components/Vendors/Home/PropertiesSearchResultsContainer';
import { SEARCH_ID_LENGTH } from '~/components/Vendors/Properties/constants';

import ReservationService from '~/services/ReservationService';

const propertySearchTypes = {
  ID: 'id',
  NAME: 'name',
  HOTEL_CODE: 'hotel_code',
};

const PropertySearchForm = (props) => {
  const [searchId, setSearchId] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchCode, setSearchCode] = useState('');
  const [searchType, setSearchType] = useState('');
  const [sizePerPage, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState({ result: null, total: null });

  const history = useHistory();

  const handlePageChange = (page) => {
    setPage(page);
  };

  const clearSearches = () => {
    setSearchId('');
    setSearchName('');
    setSearchCode('');
  };

  const handleSearchId = (query) => {
    setSearchId(query.target.value);
    setSearchType(propertySearchTypes.ID);
  };

  const handleSearchName = (query) => {
    setSearchName(query.target.value);
    setSearchType(propertySearchTypes.NAME);
  };

  const handleSearchCode = (query) => {
    setSearchCode(query.target.value);
    setSearchType(propertySearchTypes.HOTEL_CODE);
  };

  const handleSubmit = () => {
    setPage(0);
    fetchData();
  };

  React.useEffect(() => {
    if (searchType !== '') {
      fetchData();
    }
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    let data;
    try {
      switch (searchType) {
        case propertySearchTypes.ID:
          if (
            searchId.length === SEARCH_ID_LENGTH &&
            searchId.indexOf(' ') === -1 &&
            searchId.match(/^[0-9a-zA-Z]{18}$/)
          ) {
            // search by vendor salesforce id
            data = await ReservationService.getPropertiesForPartnerCentral(searchId, sizePerPage, page + 1);
          } else {
            data = await ReservationService.getProperty(searchId);
          }
          break;
        case propertySearchTypes.NAME:
          data = await ReservationService.getPropertiesByName(searchName, sizePerPage, page + 1);
          break;
        case propertySearchTypes.HOTEL_CODE:
          data = await ReservationService.getPropertiesByHotelCode(searchCode, sizePerPage, page + 1);
          break;
      }
    } catch (e) {
      data = { result: [] };
    }
    setResults(data);
    setLoading(false);
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Stack direction="row" sx={{ width: '100%' }} spacing={1} alignItems="center">
        <Box flex={2}>
          <TextField
            autoFocus
            type="text"
            fullWidth
            value={searchId}
            onChange={handleSearchId}
            onFocus={clearSearches}
            placeholder={'Search by Property ID or Vendor ID'}
          />
        </Box>
        <Box flex={3}>
          <TextField
            type="text"
            fullWidth
            value={searchName}
            onChange={handleSearchName}
            onFocus={clearSearches}
            placeholder={'Search by Name'}
          />
        </Box>
        <Box flex={2}>
          <TextField
            type="text"
            fullWidth
            value={searchCode}
            onChange={handleSearchCode}
            onFocus={clearSearches}
            placeholder={'Search by Hotel Code'}
          />
        </Box>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Search
        </Button>
      </Stack>
      {isLoading && <Spinner />}
      {!isLoading && results?.result && (
        <>
          <h2>Properties</h2>
          <PropertiesSearchResultsContainer
            data={results.result}
            page={page}
            total={results.total}
            handlePageChange={handlePageChange}
            sizePerPage={sizePerPage}
            history={history}
          />
        </>
      )}
    </form>
  );
};

export default PropertySearchForm;
