import { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { getChatById } from '~/services/SupportService';

export function useSelectChatById() {
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);

  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');

  const loading = useMemo(() => {
    return fetchingState === 'loading';
  }, [fetchingState]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!selectedChatId) return;

    setFetchingState('loading');
    getChatById(selectedChatId)
      .then(({ result }) => {
        setSelectedChat(result);
      })
      .then(() => setFetchingState('success'))
      .catch((error) => {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'warning' });
        setFetchingState('failed');
      });
  }, [enqueueSnackbar, selectedChatId]);

  return {
    setSelectedChatId,
    selectedChat,
    loading,
  };
}
