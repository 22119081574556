import React from 'react';

import { withRouter } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container, Divider } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import UsersService from '../../services/UsersService';
import Asinc from '../Common/Asinc';

import SystemUserDetails from './SystemUserDetails';

class SystemUserPage extends React.Component {
  constructor(props) {
    super(props);
    let searchQuerySubmit = '';
    if (this.props.location.state && this.props.location.state.searchQuerySubmit) {
      searchQuerySubmit = this.props.location.state.searchQuerySubmit;
    }
    this.searchQuerySubmit = searchQuerySubmit;
    this.goBack = this.goBack.bind(this);
  }

  fetchData() {
    const { match } = this.props;

    return UsersService.getUser(match.params.id_user);
  }

  goBack() {
    const { history } = this.props;

    if (this.searchQuerySubmit) {
      history.push({
        pathname: '/system/admin-users/',
        state: {
          searchQuerySubmit: this.searchQuerySubmit,
          searchString: this.searchQuerySubmit,
        },
      });
    } else {
      history.goBack();
    }
  }

  render() {
    const { match } = this.props;

    const backButton = (
      <Button startIcon={<ChevronLeftIcon />} onClick={this.goBack}>
        Back
      </Button>
    );

    return (
      <Container maxWidth="xl">
        {backButton}

        <Divider />

        <Asinc
          promise={this.fetchData()}
          then={(promise_results) => (
            <SystemUserDetails
              user={promise_results}
              id_user={match.params.id_user}
              regions={libRegions.getRegions()}
            />
          )}
        />
      </Container>
    );
  }
}

export default withRouter(SystemUserPage);
