export const SIZE_PER_PAGE = 10;

type PurchaseFilter = {
  page: number;
  field: string;
  value: string;
  endDate: string;
  startDate: string;
  customerId: string;
};

export const FILTER_INITIAL_STATE: PurchaseFilter = {
  page: null,
  field: null,
  value: null,
  endDate: null,
  startDate: null,
  customerId: null,
};

type PurchaseState<T> = {
  result: T;
  total: number;
  error: boolean;
  loading: boolean;
};

export const PURCHASE_INITIAL_STATE: PurchaseState<any> = {
  error: null,
  total: null,
  result: null,
  loading: null,
};
