import React, { useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import { Inclusion } from '~/components/Common/Forms/InclusionsAssetForm/InclusionsAssetForm';

import InclusionsService from '~/services/InclusionsService';

export const COLUMNS: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'opportunity_name',
    headerName: 'Opportunity name',
    flex: 1,
    minWidth: 150,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'offer_id',
    headerName: 'Offer ID',
    minWidth: 200,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'package_name',
    headerName: 'Package',
    flex: 1,
    minWidth: 150,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'package_id',
    filterable: false,
    headerName: 'Package',
    sortable: false,
    maxWidth: 125,
    display: 'flex',
    renderCell: (params: GridRenderCellParams) => (
      <Button variant="text" target="_blank" href={`/edit-offers/${params.row.offer_id}/packages`}>
        View
      </Button>
    ),
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const STEPS = {
  SELECT_PACKAGE_INCLUSIONS: {
    label: 'Select package inclusions',
    index: 0,
  },
  REVIEW_AND_CONFIRM: {
    label: 'Review & confirm',
    index: 1,
  },
};

interface Props {
  onClose: (isSaved?: boolean) => void;
  inclusion: Inclusion;
  isOpen: boolean;
}

export const RemovePackagesFromInclusionModal = ({ onClose, inclusion, isOpen }: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPackageInclusionIds, setSelectedPackageInclusionIds] = useState<GridRowSelectionModel>([]);

  const { enqueueSnackbar } = useSnackbar();

  const isLastStep = activeStep === Object.values(STEPS).length - 1;

  const isNextButtonDisabled = useMemo(() => {
    if (activeStep === STEPS.SELECT_PACKAGE_INCLUSIONS.index) {
      return selectedPackageInclusionIds.length === 0;
    }

    return false;
  }, [activeStep, selectedPackageInclusionIds]);

  const handleConfirm = () => {
    InclusionsService.unlinkPackagesByInclusionIds({ ids: selectedPackageInclusionIds })
      .then(() => {
        onClose(true);

        setSelectedPackageInclusionIds([]);
        setActiveStep(0);

        enqueueSnackbar('Inclusions and packages unlinked successfully', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Failed to unlink inclusions and packages', { variant: 'error' });
      });
  };

  const handleClose = () => {
    onClose();

    setSelectedPackageInclusionIds([]);
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stepper activeStep={activeStep} sx={{ marginBottom: '20px', marginTop: '10px' }}>
          {Object.values(STEPS).map(({ label }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent sx={{ height: '500px' }}>
        {activeStep === STEPS.SELECT_PACKAGE_INCLUSIONS.index && (
          <DataGrid
            checkboxSelection
            columns={COLUMNS}
            density="compact"
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            pagination
            rows={inclusion?.packages || []}
            slots={{ toolbar: CustomToolbar }}
            onRowSelectionModelChange={(newSelection) => setSelectedPackageInclusionIds(newSelection)}
            rowSelectionModel={selectedPackageInclusionIds}
            disableColumnMenu
          />
        )}
        {activeStep === STEPS.REVIEW_AND_CONFIRM.index && (
          <>
            <Typography mb={1}>Unlinking the following packages and inclusions:</Typography>
            <DataGrid
              columns={COLUMNS}
              density="compact"
              hideFooterPagination
              hideFooterSelectedRowCount
              rows={(inclusion?.packages || []).filter((packageInclusion) =>
                selectedPackageInclusionIds.includes(packageInclusion.id),
              )}
              disableColumnMenu
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mx: 2 }}>
        <Button variant="text" onClick={() => handleClose()}>
          Cancel
        </Button>
        <div>
          <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Button variant="contained" onClick={isLastStep ? handleConfirm : handleNext} disabled={isNextButtonDisabled}>
            {isLastStep ? 'Confirm' : 'Next'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
