import React, { useMemo } from 'react';

import { TitleFieldProps } from '@rjsf/utils';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const splitTitleFieldIdIntoTitles = (id: string): string[] => {
  const matches = id
    .replace('root__', '')
    .replace('__title', '')
    .match(/([a-zA-Z]+_\d+|([a-zA-Z]+)$)/gis); // match anyText_numbers and then consecutive letters

  if (!matches) return [];

  return matches.map(
    (match) =>
      match
        .replace(/_\d+$/g, (match) => `_${Number(match.substring(1)) + 1}`) // bump index bit by one
        .replace('_', ' ')
        .replace(/([A-Z])/g, ' $1') // camelCase to space-seperated
        .toLowerCase(), // CSS will take care of casing
  );
};

function useRJSFormLegendTitleExtender(title: string, id: string) {
  return useMemo<{
    mainTitle: string;
    leadingTitles: string[];
  }>(() => {
    let leadingTitles: string[] = [];
    if (!title) return { mainTitle: '', leadingTitles };

    leadingTitles = splitTitleFieldIdIntoTitles(id);

    if (!leadingTitles.length) return { mainTitle: title, leadingTitles };

    const placeholder = leadingTitles.pop();

    // if last title matches a number in the end of the string, it's a placeholder,
    // otherwise it's the main title and we must add the number in the end of the placeholder to it
    const mainTitle = /-\d+$/.test(title) ? placeholder : title.concat(placeholder?.replace(/[a-zA-Z]+/g, '') || '');

    return {
      mainTitle,
      leadingTitles,
    };
  }, [id, title]);
}

export default function BreadcrumbsLegendTemplate(props: TitleFieldProps) {
  const { title, id, required } = props;

  const { mainTitle, leadingTitles } = useRJSFormLegendTitleExtender(title, id);

  if (!mainTitle) return null;

  return (
    <Breadcrumbs id={id} component="legend" sx={{ textTransform: 'capitalize' }}>
      {!!leadingTitles?.length &&
        leadingTitles.map((leadingTitle, titleIndex) => (
          <Typography key={titleIndex} variant="subtitle2">
            {leadingTitle}
          </Typography>
        ))}
      {!!mainTitle && (
        <Stack spacing={0.5} direction="row">
          <Typography variant="h6">{mainTitle}</Typography>
          {!!required && (
            <Typography variant="subtitle2" color="error" component="span">
              {' '}
              *
            </Typography>
          )}
        </Stack>
      )}
    </Breadcrumbs>
  );
}
