import React, { useEffect, useState } from 'react';

import { Dialog, DialogTitle } from '@mui/material';

import StepConfirm from './StepConfirm';
import StepForm from './StepForm';
import StepResult from './StepResult';

const Steps = {
  Form: 'form',
  Confirm: 'confirm',
  Result: 'result',
} as const;

type Steps = (typeof Steps)[keyof typeof Steps];

type Props = {
  show: boolean;
  currentInsurance: App.InsuranceItem;
  currentOrderAmount: number;
  order: App.Order;
  totalTicketPrice: number;
  onSuccess: () => void;
  onClose: () => void;
};

export default function UpdateInsuranceModal(props: Props) {
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.Form);
  const [stepData, setStepData] = useState(null);

  useEffect(() => {
    setCurrentStep(Steps.Form);
  }, [props.show]);

  const goToNextStep = (data?: any) => {
    switch (currentStep) {
      case Steps.Form:
        setStepData(data);
        setCurrentStep(Steps.Confirm);
        break;
      case Steps.Confirm:
        setStepData(data);
        setCurrentStep(Steps.Result);
        break;
      case Steps.Result:
        props.onClose();
        props.onSuccess();
        break;
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={props.show} onClose={props.onClose}>
      <DialogTitle>Update insurance</DialogTitle>
      {currentStep === Steps.Form && (
        <StepForm
          totalTicketPrice={props.totalTicketPrice}
          currentInsurance={props.currentInsurance}
          currentOrderAmount={props.currentOrderAmount}
          onContinue={goToNextStep}
          onClose={props.onClose}
        />
      )}
      {currentStep === Steps.Confirm && (
        <StepConfirm
          order={props.order}
          totalTicketPrice={props.totalTicketPrice}
          currentInsurance={props.currentInsurance}
          updateData={stepData}
          onContinue={goToNextStep}
          onClose={props.onClose}
        />
      )}
      {currentStep === Steps.Result && (
        <StepResult
          currentInsurance={props.currentInsurance}
          order={props.order}
          paymentData={stepData}
          onContinue={goToNextStep}
          onClose={props.onClose}
        />
      )}
    </Dialog>
  );
}
