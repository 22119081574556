import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import OffersService from '~/services/OffersService';
import { formatDateWithClock } from '~/services/TimeService';

import isUUID from '~/utils/isUUID';

import type { SearchFilters } from './SearchFilters';

type Props = {
  searchFilters: SearchFilters;
};

const columns: GridColDef<App.CustomOffer>[] = [
  {
    field: 'customer_email',
    headerName: 'Customer',
    flex: 1,
    renderCell: (params: GridRenderCellParams<App.CustomOffer>) => (
      <Stack direction="column">
        <Typography>{params.row.customer_full_name}</Typography>
        <Typography color="secondary">{params.row.fk_customer_id}</Typography>
      </Stack>
    ),
    display: 'flex',
  },
  { field: 'name', headerName: 'Offer name', flex: 1, sortable: false, display: 'flex' },
  {
    field: 'start_date',
    headerName: 'Start date',
    sortable: false,
    width: 150,
    valueGetter: (_value, row) => row.items[0].start_date,
    display: 'flex',
  },
  { field: 'due_date', headerName: 'Due date', sortable: false, width: 150, display: 'flex' },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: false,
    width: 180,
    valueGetter: (_value, row: App.CustomOffer) => formatDateWithClock(row.created_at),
    display: 'flex',
  },
  { field: 'status', headerName: 'Status', sortable: false, display: 'flex' },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: (params) => (
      <>
        <Button
          variant="text"
          component={Link}
          to={`/users/${params.row.fk_customer_id}/custom-offers/${params.row.id}`}
        >
          Edit
        </Button>
      </>
    ),
    display: 'flex',
  },
];

const perPage = 25;

export default function OffersTab({ searchFilters }: Props) {
  const [offers, setOffers] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');

  const { enqueueSnackbar } = useSnackbar();

  const isLoading = loadingState === 'loading';

  useEffect(() => {
    const customerId =
      searchFilters && searchFilters.customer && isUUID(searchFilters.customer) ? searchFilters.customer : undefined;

    setLoadingState('loading');

    OffersService.getCustomOffers({ customerId, page: currentPage + 1, perPage })
      .then((res) => {
        setOffers(res.result);
        setTotal(res.total);
        setLoadingState('success');
      })
      .catch((err) => {
        setLoadingState('failed');
        enqueueSnackbar(`Can't load offers: ${err.message}`, { variant: 'error' });
      });
  }, [enqueueSnackbar, searchFilters, currentPage]);

  return (
    <DataGrid
      columns={columns}
      rows={offers}
      rowCount={total}
      loading={isLoading}
      pagination
      paginationMode="server"
      paginationModel={{ page: currentPage, pageSize: perPage }}
      pageSizeOptions={[perPage]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: perPage,
          },
        },
      }}
      onPaginationModelChange={({ page }) => setCurrentPage(page)}
      getRowId={(row: App.CustomOffer) => row.id}
      getRowHeight={() => 'auto'}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}
