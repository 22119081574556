import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Container, IconButton, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridPagination, GridRenderCellParams } from '@mui/x-data-grid';

import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

export default function AmenityGroupEdit() {
  const {
    params: { id_group: groupId },
  } = useRouteMatch<{ id_group: string }>();

  const [name, setName] = useState('');
  const [order, setOrder] = useState(0);
  const [amenities, setAmenities] = useState<App.Amenity[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<Utils.FetchingState>('idle');
  const [saveButton, setSaveButton] = useState<Utils.SavingState>('save changes');

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    setSaveButton('save changes');
  }, [name]);

  const handleSave = () => {
    setSaveButton('saving');
    ReservationService.updateAmenityGroup(groupId, name, order)
      .then(() => {
        setSaveButton('saved');
      })
      .catch(() => {
        setSaveButton('failed');
      });
  };

  const isButtonStateSaving = useCallback(() => {
    return saveButton === 'saving';
  }, [saveButton]);

  const fetchGroup = async () => {
    try {
      setLoading('loading');
      const { result } = await ReservationService.getAmenityGroup(groupId);
      setName(result.name);
      setOrder(result.order);
      setAmenities(result.amenities);
      setTotal(result.amenities.length);
      setLoading('success');
    } catch (error) {
      setLoading('failed');
      enqueueSnackbar('Error fetching amenity:', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchGroup();
  }, [groupId]);

  const onRemove = useCallback(
    async (params: GridRenderCellParams) => {
      const amenityToRemove = amenities.find((i) => i.id === params.id);
      await ReservationService.removeAmenityFromGroup(amenityToRemove);
      await fetchGroup();
    },
    [amenities],
  );

  const title = 'Edit Amenity Group';

  if (loading === 'loading') {
    return <Spinner />;
  }

  const columns: GridColDef<App.Amenity>[] = [
    {
      field: 'name',
      headerName: 'Amenity Group Name',
      width: 600,
      display: 'flex',
    },
    {
      field: ' ',
      headerName: 'Delete',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <IconButton onClick={() => onRemove(params)} color="error">
            <DeleteIcon fontSize="medium" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title}></PageHeader>

      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2} alignItems="left">
          <TextField
            id="order"
            data-testid="order"
            type="text"
            name="order"
            label="Order"
            value={order}
            disabled
            fullWidth
          />
          <TextField
            id="name"
            data-testid="name"
            type="text"
            name="name"
            label="Amenity Group Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            fullWidth
          />
          <div>
            <Button variant="contained" onClick={handleSave} disabled={isButtonStateSaving()}>
              {saveButton}
            </Button>
          </div>
          <DataGrid
            className="T-amenity"
            rows={amenities}
            columns={columns}
            getRowClassName={() => 'T-amenity-row'}
            rowCount={total}
            paginationMode="server"
            paginationModel={{
              page: 1,
              pageSize: 100,
            }}
            pageSizeOptions={[100]}
            slots={{ pagination: GridPagination }}
            disableColumnMenu
            disableColumnFilter
            autoHeight
          />
        </Stack>
      </form>
    </Container>
  );
}
