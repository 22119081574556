import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface Props {
  visible?: boolean;
  onClose?: () => void;
}

export default function OptionalExtraWarningModal({
  visible = false,
  onClose = () => {
    /* pass */
  },
}: Props) {
  return (
    <div>
      <Dialog open={visible} onClose={onClose}>
        <DialogTitle>Warning</DialogTitle>

        <DialogContent>
          <Typography>You must cancel the active optional extras first</Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
