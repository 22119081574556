import React from 'react';

import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

import { TextField, Typography } from '@mui/material';

import { FieldSearch } from '~/components/Experiences/components';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

export default function ExperienceCurationOverview() {
  const [searchLocationValue, setSearchLocationValue] = React.useState('');

  const { values, updateValues, contentTab } = useUpdateExperienceValues();

  const {
    ready,
    setValue,
    clearSuggestions,
    suggestions: { status, data },
  } = usePlacesAutocomplete({ debounce: 300 });

  const searchLocationOptions = React.useMemo(
    () => (status === 'OK' && ready ? data?.map((option) => option?.description) : []),
    [status, data, ready],
  );

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  const onSelectLocation = React.useCallback(
    async (address: string) => {
      clearSuggestions();
      setSearchLocationValue(address);
      const position = await getGeocode({ address });
      const coordinates = await getLatLng(position[0]);
      const location = {
        description: position[0].formatted_address,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      };
      updateValues({ location });
    },
    [clearSuggestions, updateValues],
  );

  const handleSearchLocationFieldValue = (fieldValue: string) => {
    setValue(fieldValue);
    setSearchLocationValue(fieldValue);
  };

  React.useEffect(() => {
    if (values?.location && !searchLocationValue) {
      const value = values.location.description;
      setValue(value);
      setSearchLocationValue(value);
    }
  }, [searchLocationValue, setValue, values?.location]);

  React.useEffect(() => {
    return () => setSearchLocationValue('');
  }, []);

  return (
    <>
      <Typography variant="h6" color="primary">
        Experience Overview
      </Typography>

      <TextField
        label="Title"
        value={values?.title ?? ''}
        disabled={areOriginalValues}
        onChange={(e) => updateValues({ title: e.target.value })}
        fullWidth
      />

      <FieldSearch
        label="Location"
        autoComplete="off"
        value={searchLocationValue || ''}
        onClickOption={onSelectLocation}
        options={searchLocationOptions}
        onChange={handleSearchLocationFieldValue}
        showOptions={searchLocationValue !== values?.location?.description}
        disabled={areOriginalValues}
        fullWidth
      />
    </>
  );
}
