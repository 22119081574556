import { Request, RequestPaginationParams, SelectFormValues } from './types';

export const INITIAL_PAGINATION_STATE: RequestPaginationParams = {
  skip: 0,
  take: 30,
  done: false,
};

export const GENERIC_INITIAL_REQUEST_STATE = {
  result: null,
  loading: false,
};

export const INITIAL_REQUEST_STATE: Request<[]> = {
  result: [],
  loading: false,
};

export const INITIAL_SELECT_FORM_STATE: SelectFormValues = {
  rates: [],
  cruiseLine: null,
};
