import { blue, blueGrey, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const theme = createTheme({
  typography: {
    // fontSize: 12,
    allVariants: {
      color: grey[900],
    },
  },
  palette: {
    primary: {
      light: blue[400],
      main: blue[500],
      dark: blue[600],
    },
    secondary: {
      light: blueGrey[400],
      main: blueGrey[500],
      dark: blueGrey[600],
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          userSelect: 'text',
        },
      },
    },

    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },

    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
        slotProps: {
          paper: {
            elevation: 1,
          },
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          fontSize: '1rem',
        },
        columnHeaders: {
          background: grey[200],
        },
        row: {
          cursor: 'pointer',
        },
        cell: {
          paddingBottom: 3,
          paddingTop: 3,
        },
      },
    },
    /*
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          transform: ownerState.shrink ? 'translate(14px, -10px) scale(0.9)' : 'translate(14px, 10px) scale(1)',
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '10px 14px',
        },
        input: {
          padding: '0 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px 14px',
        },
        notchedOutline: {
          '& legend': {
            fontSize: '0.9em',
          },
        },
      },
    },
    */
  },
});
