import { json_headers, request } from './common';

export default class BookingsService {
  static basePath() {
    return window.configs.API_HOST + '/api/orders';
  }

  static getBookings() {
    return request(this.basePath(), { method: 'GET' });
  }

  static getBookingSchema(id) {
    return request(this.basePath() + '/' + id, { method: 'OPTIONS' });
  }

  static getBooking(id) {
    return request(this.basePath() + '/' + id, { method: 'GET' });
  }

  static createBooking(object) {
    var jsonData = JSON.stringify(object);
    return request(this.basePath(), {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateBooking(object, id) {
    var jsonData = JSON.stringify(object);
    return request(this.basePath() + '/' + id, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }
}
