import utils from '../utils';

import BaseService from './BaseService';
import RequestPool from './RequestPool';

export default class UserService extends BaseService {
  constructor(endpoints, forceAgentHeader) {
    let defaultEndpoints = {
      me: '/me',
      login: '/login',
      loginWithGoogle: '/login/google',
      register: '/register',
      verifyEmail: '/verify',
      forgotPassword: '/forgot',
      changePassword: '/change',
      logout: '/logout',
    };

    super(utils.mergeObjects(defaultEndpoints, endpoints), forceAgentHeader);

    this.meRequestPool = new RequestPool();
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  delToken() {
    localStorage.removeItem('token');
  }

  _makeAuthRequest(method, path, body, headers, callback) {
    let token = this.getToken();
    if (!token) {
      return callback(new Error('No access token. Login required.'));
    }
    headers = headers || {};
    headers['Authorization'] = 'Bearer ' + token;
    super._makeRequest(method, path, body, headers, callback);
  }

  _unwrapAccountResult(callback) {
    return (err, result) => {
      if (err) {
        return callback(err);
      }
      callback(null, result.account || result || {});
    };
  }

  me(callback) {
    this.meRequestPool.request((resultCallback) => {
      this._makeAuthRequest('get', this.endpoints.me, null, null, this._unwrapAccountResult(resultCallback));
    }, callback);
  }

  updateProfile(data, callback) {
    this._makeAuthRequest('post', this.endpoints.me, data, null, callback);
  }

  getLoginViewData(callback) {
    this._makeAuthRequest('get', this.endpoints.login, null, null, callback);
  }

  login(options, callback) {
    this._makeRequest('post', this.endpoints.login, options, null, (err, response) => {
      if (err) {
        return callback(err);
      }
      this.setToken(response.result.access_token);
      this._unwrapAccountResult(callback(null, response));
    });
  }

  loginWithGoogle(options, callback) {
    this._makeRequest('post', this.endpoints.loginWithGoogle, options, null, (err, response) => {
      if (err) {
        return callback(err);
      }
      this.setToken(response.result.access_token);
      this._unwrapAccountResult(callback(null, response));
    });
  }

  register(options, callback) {
    this._makeRequest('post', this.endpoints.register, options, null, this._unwrapAccountResult(callback));
  }

  getRegisterViewData(callback) {
    this._makeAuthRequest('get', this.endpoints.register, null, null, callback);
  }

  verifyEmail(spToken, callback) {
    this._makeRequest(
      'get',
      this.endpoints.verifyEmail + '?sptoken=' + encodeURIComponent(spToken),
      null,
      null,
      callback,
    );
  }

  forgotPassword(options, callback) {
    this._makeRequest('post', this.endpoints.forgotPassword, options, null, callback);
  }

  changePassword(options, callback) {
    this._makeAuthRequest('post', this.endpoints.changePassword, options, null, callback);
  }

  logout(callback) {
    this._makeAuthRequest('post', this.endpoints.logout, null, null, callback);
    this.delToken();
  }
}
