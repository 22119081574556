import React, { useMemo } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import UsersService from '~/services/UsersService';

import Spinner from './Spinner';

const uiSchema = {
  givenName: { 'ui:placeholder': 'John' },
  surname: { 'ui:placeholder': 'Doe' },
  email: { 'ui:widget': 'email', 'ui:placeholder': 'john@example.com' },
  password: { 'ui:widget': 'password', 'ui:placeholder': 'Secret password' },
};

type Props = {
  show: boolean;
  schema: RJSFSchema;
  isLoading?: boolean;
  userData?: unknown;
  onHide: () => void;
  userRegistrationSuccess: () => void;
};

export default function CreateNewUser(props: Props) {
  const [formData, setFormData] = React.useState(props.userData || {});
  const { enqueueSnackbar } = useSnackbar();

  const formSchema = useMemo(() => {
    const schema = { ...props.schema };

    delete schema.title;

    return schema;
  }, [props.schema]);

  const handleSubmit = async ({ formData }: IChangeEvent) => {
    try {
      const registerUser = await UsersService.registerUser(formData);
      if (registerUser) {
        props.userRegistrationSuccess();
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error! Unable to register user.', { variant: 'error' });
    }
  };

  const handleChange = ({ formData }: IChangeEvent) => setFormData(formData);

  return (
    <Dialog open={props.show} onClose={props.onHide} scroll="paper" maxWidth="sm" fullWidth>
      <DialogTitle>Create New User</DialogTitle>

      <DialogContent dividers>
        {props.isLoading && <Spinner />}

        {!props.isLoading && (
          <Form
            schema={formSchema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            formData={formData}
            onChange={handleChange}
            validator={validator}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
