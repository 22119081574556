import React from 'react';

import { connect } from 'react-redux';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Stack } from '@mui/material';

import { formatDateWeekDayShortMonth } from '~/services/TimeService';

function ReservationDetailTour({ reservation, stateOfResidence }) {
  const fullname = `${reservation.firstname} ${reservation.lastname}`;
  return (
    <div className="item-reservation">
      <Stack direction="row" className="section">
        <CalendarMonthIcon />
        <span>{formatDateWeekDayShortMonth(reservation.startDate)}</span>
        <span className="separator">-</span>
        <span>{formatDateWeekDayShortMonth(reservation.endDate)}</span>
      </Stack>
      <Stack direction="row" className="section">
        <ListAltIcon />
        <span>{fullname}</span>
      </Stack>
      {stateOfResidence && (
        <Stack direction="row" className="section">
          <HomeIcon />
          <span>{stateOfResidence}</span>
        </Stack>
      )}
      {reservation.guestSpecialRequests && (
        <Stack direction="row" className="section">
          <div className="section-header">Special requests</div>
          <p>{reservation.guestSpecialRequests}</p>
        </Stack>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    stateOfResidence: state.cart.stateOfResidence,
  };
}

export default connect(mapStateToProps)(ReservationDetailTour);
