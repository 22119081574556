import React from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SouthIcon from '@mui/icons-material/South';
import { Box, Button, Divider, Stack } from '@mui/material';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

interface Props {
  disabled: boolean;
  schedule: Partial<App.Schedule>;
  startUpdated: boolean;
  endUpdated: boolean;
  onReset: () => void;
  updateEnd: (end: string) => void;
  updateStart: (start: string) => void;
  onApplyToAll?: () => void;
  enableUtilBtn?: boolean;
}

export default function NewScheduleFormBase(props: Props) {
  const { disabled, schedule, startUpdated, endUpdated, onReset, updateEnd, updateStart, onApplyToAll } = props;

  const resetActive = endUpdated || startUpdated;

  return (
    <Box mt={1}>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" spacing={2}>
        <DateTimeWidget
          disabled={disabled}
          value={schedule.start}
          onChange={updateStart}
          format="DD/MM/YYYY hh:mm a"
          label="Start"
          timeSteps={{ minutes: 1 }}
        />

        <ArrowRightAltIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
        <SouthIcon sx={{ display: { xs: 'block', sm: 'none' } }} />
        <DateTimeWidget
          disabled={disabled}
          value={schedule.end}
          onChange={updateEnd}
          format="DD/MM/YYYY hh:mm a"
          label="End"
          timeSteps={{ minutes: 1 }}
        />
        {props.enableUtilBtn && (
          <Stack direction="row" spacing={1}>
            <Button variant="text" onClick={onApplyToAll}>
              Apply To All
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button variant="text" color={resetActive ? 'primary' : 'secondary'} onClick={onReset}>
              Reset
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
