import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Stack, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

import { getBusiness, getEmployee } from '~/services/BusinessTraveller/BusinessTravellerService';

import { setBusinessId } from '../../../../../actions/cart';

interface Props {
  customer: App.User;
  doSetBusinessId: Utils.ActionDispatcher<typeof setBusinessId>;
}

function BusinessTravellerBusiness(props: Props) {
  const { customer, doSetBusinessId } = props;
  const [business, setBusiness] = useState<App.Business>();

  async function fetchBusiness() {
    try {
      const employee = await getEmployee(customer.id_member);
      if (employee) {
        doSetBusinessId(employee.businessId);
        const business = await getBusiness(employee.businessId);
        if (business) {
          setBusiness(business);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography variant="h6">Business:</Typography>
      {business && <Typography variant="h6">{business.name}</Typography>}
      {!business && (
        <Typography variant="h6" sx={{ color: red.A700 }}>
          This user has no business
        </Typography>
      )}
    </Stack>
  );
}

function mapStateToProps(state: App.State) {
  return {
    customer: state.cart.customer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doSetBusinessId: setBusinessId,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTravellerBusiness);
