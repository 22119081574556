import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';

import Image from '../../Common/Image';

const imageOptions = {
  width: 300,
};

export default class PanelImagePane extends React.Component {
  render() {
    return (
      <span>
        <h1>
          <Link to={'/edit-offers/' + this.props.offer.result.id_salesforce_external}>
            <ModeEditTwoToneIcon fontSize="large" />
            <span className="sr-only">Edit</span>
          </Link>
        </h1>
        {this.props.offer.result.panel_cloudinary_id && (
          <Image
            className="img-responsive img-thumbnail"
            publicId={this.props.offer.result.panel_cloudinary_id}
            options={imageOptions}
          />
        )}
      </span>
    );
  }
}
