import qs from 'qs';

import { CruisesContract, definitions } from '@luxuryescapes/contract-svc-cruise';

import { request } from '../common';

const RESOURCE = 'cabin-categories';
const BASE_PATH = () => window.configs.API_HOST + '/api/cruise';

export type Brands = definitions['LE_BRAND'];
export type CabinCategory = definitions['CABIN_CATEGORY_LIST'];
export type CabinCategoryListQuery = definitions['CABIN_CATEGORY_LIST_QUERY'];

export async function getCabinCategoryList(
  params: CabinCategoryListQuery,
): Promise<CruisesContract.Response<CabinCategory[]>> {
  const url = `${BASE_PATH()}/v2/${RESOURCE}?${qs.stringify(params)}`;

  return await request(url, { method: 'GET' });
}

export type CabinCategoryById = definitions['CABIN_CATEGORY_BY_ID'];

export type CabinCategoryByIdQuery = {
  cabinId: string;
  brand?: Brands;
};

export async function getCabinCategoryById(
  params: CabinCategoryByIdQuery,
): Promise<CruisesContract.Response<CabinCategoryById>> {
  const { cabinId, ...queries } = params;
  const url = `${BASE_PATH()}/v2/${RESOURCE}/${cabinId}?${qs.stringify(queries)}`;

  return await request(url, { method: 'GET' });
}
