import React, { useMemo, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { dateFormatterShort, failedCountFormatter, generateInstalmentRows, paymentFormatter } from './instalmentUtils';

const ToggleButton = ({ handleClick, isOpen }) => {
  return (
    <Button variant="outlined" endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />} onClick={handleClick}>
      Show Instalment Schedule
    </Button>
  );
};

const InstalmentScheduleTable = ({ data }) => {
  return (
    <DataGrid
      columns={[
        { field: 'instalments_number', headerName: 'Instalment number', width: 120, sortable: false, display: 'flex' },
        {
          field: 'instalment_due_date',
          headerName: 'Due Date',
          width: 120,
          sortable: false,
          renderCell: (params) => dateFormatterShort(params.value),
          display: 'flex',
        },
        {
          field: 'instalment_paid_date',
          headerName: 'Paid Date',
          width: 120,
          sortable: false,
          renderCell: (params) => dateFormatterShort(params.value),
          display: 'flex',
        },
        {
          field: 'instalment_amount',
          headerName: 'Instalment Amount',
          width: 120,
          sortable: false,
          renderCell: (params) => paymentFormatter(params.value, params.row),
          display: 'flex',
        },
        { field: 'currency', headerName: 'Currency', width: 120, sortable: false, display: 'flex' },
        { field: 'instalment_status', headerName: 'Status', width: 200, sortable: false, display: 'flex' },
        {
          field: 'due_balance_auto_debit_failed_count',
          headerName: 'Auto Debit Failure Count',
          width: 200,
          sortable: false,
          renderCell: (params) => failedCountFormatter(params.value),
          display: 'flex',
        },
      ]}
      rows={data}
      getRowId={(row) => row.instalments_number}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
};

function InstalmentSchedule({ instalmentDetails, payments }) {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const instalmentScheduleData = useMemo(() => {
    return generateInstalmentRows(instalmentDetails, payments);
  }, [instalmentDetails, payments]);

  return (
    <div className="col-sm-12">
      <ToggleButton isOpen={isOpen} handleClick={toggleOpen} />
      {isOpen && (
        <>
          <InstalmentScheduleTable data={instalmentScheduleData} />
          <div className="p-3 text-right text-muted">
            *indicates how many times the automatic payment for the instalment amount has failed. The booking is
            automatically cancelled after 3 failures.
          </div>
        </>
      )}
    </div>
  );
}

export default InstalmentSchedule;
