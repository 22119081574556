import React, { PropsWithChildren } from 'react';

import { Typography } from '@mui/material';

export default function DefinitionTitle({ children }: PropsWithChildren<unknown>) {
  return (
    <Typography variant="h6" fontSize={14} textTransform="uppercase" color="GrayText">
      {children}
    </Typography>
  );
}
