import React from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
  title: string;
  color?: string;
};

export default function CodeColumnDetails(props: Props) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      <Box width={15} height={15} borderRadius="4px" style={{ background: props.color }} />
      <Typography>{props.title}</Typography>
    </Box>
  );
}
