import React from 'react';

import { Stack } from '@mui/material';

import ContentWithSidebar from '../ContentWithSidebar';

import CancelledBookingPayments from './CancelledBookingPayments';
import ProblematicPayments from './ProblematicPayments';
import RemittanceContainer from './RemittanceContainer';
import UploadBookingPayments from './UploadBookingPayments';
import UploadOfferPayments from './UploadOfferPayments';
import VendorPaymentsReport from './VendorPaymentsReport';

const DEFAULT_ITEM = 'upload-booking-payments';

const items = [
  {
    title: 'Upload Bookings Payments (EFT & VCC)',
    slug: 'upload-booking-payments',
    component: UploadBookingPayments,
  },
  {
    title: 'VCC Payments',
    slug: 'upload-offer-payments',
    component: UploadOfferPayments,
  },
  {
    title: 'Cancelled Booking Payments',
    slug: 'cancelled-booking-payments',
    component: CancelledBookingPayments,
  },
  {
    title: 'Vendor Payments Report',
    slug: 'report',
    component: VendorPaymentsReport,
  },
  {
    title: 'Potential Issues',
    slug: 'potential-issues',
    component: ProblematicPayments,
  },
];

export default class VendorPayments extends React.Component {
  render() {
    return (
      <Stack direction="column" spacing={4}>
        <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEM} />

        <RemittanceContainer />
      </Stack>
    );
  }
}
