import React from 'react';

import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

type ButtonProps = {
  title?: string;
  loading?: boolean;
  background?: string;
} & MuiButtonProps;

export const Button: React.FC<ButtonProps> = (props) => {
  const { title, onClick, disabled, children, loading, ...rest } = props;

  return (
    <MuiButton
      disabled={disabled}
      {...rest}
      startIcon={loading ? <Spinner size={20} color="#FFFFFF" inline /> : undefined}
      onClick={onClick}
    >
      {loading ? 'Processing' : title ?? children ?? ''}
    </MuiButton>
  );
};
