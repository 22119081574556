import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Box, Card, CardActionArea, CardContent, Container, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

type PageItem = {
  title: string;
  description: string;
  url: string;
};

const items: Array<PageItem> = [
  {
    title: 'Admin Users & Roles',
    description: 'Search, create and edit admin users and roles',
    url: '/system/admin-users',
  },
  {
    title: 'Reservation Remediation',
    description: 'See failed reservations & detailed logs',
    url: '/system/reservation-remediation',
  },
  {
    title: 'Account Deletions',
    description: 'Bulk delete accounts',
    url: '/system/account-deletions',
  },
  {
    title: 'Audit Logs',
    description: 'Search audit logs',
    url: '/system/audit-logs',
  },
  {
    title: 'Cancellation Modal Settings',
    description: 'Configure rules for the CS phone number cancellation modal in My Escapes',
    url: '/system/settings',
  },
  {
    title: 'Image Uploader',
    description: 'Upload image assets into the S3 bucket via the image service.',
    url: '/system/image-uploader',
  },
];

export default function SystemPageContainer() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>System | Admin | Users & Roles</title>
      </Helmet>

      <PageHeader title="System actions" />
      <Box gap={2} display="grid" gridTemplateColumns="repeat(auto-fill, minmax(288px, 1fr))">
        {items.map((item, i) => (
          <Card key={i} variant="outlined">
            <CardActionArea component={Link} to={item.url} sx={{ height: '100%' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="p">
                  {item.title}
                </Typography>

                <Typography variant="body1" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Container>
  );
}
