import React from 'react';

import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import Image from '~/components/Common/Image';
import { TrendingPlace, defaultColumns } from '~/components/Content/TrendingPlaces/Columns';

interface Props {
  trendingPlaces: Array<TrendingPlace>;
  isLoading: boolean;
  handleUpdate: (row: TrendingPlace) => void;
}

const TrendingPlacesTable = ({ trendingPlaces, isLoading, handleUpdate }: Props) => {
  const columns: GridColDef<TrendingPlace>[] = [
    ...defaultColumns,
    {
      field: 'imageId',
      headerName: 'Image',
      width: 300,
      renderCell: (params) => {
        return params.row.imageId ? (
          <Image options={{ width: 150 }} publicId={params.row.imageId} loading="lazy" />
        ) : (
          'No Image'
        );
      },
      display: 'flex',
    },
    {
      field: 'update',
      headerName: 'Update',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleUpdate(params.row)}>
          Update
        </Button>
      ),
    },
  ];

  return (
    <Box mt={2}>
      <DataGrid
        columns={columns}
        className="T-offers-table"
        rows={trendingPlaces || []}
        loading={isLoading}
        getRowId={(row: TrendingPlace) => row.id}
        getRowHeight={() => 'auto'}
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight
      />
    </Box>
  );
};

export default TrendingPlacesTable;
