import React, { forwardRef, useCallback, useState } from 'react';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Card, CardActionArea, CardMedia, TextField, Tooltip } from '@mui/material';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';

interface Props {
  /**
   * @default FALLBACK
   */
  defaultValue: string;
  name: string;
}

const SchedulePushImageInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { defaultValue = '', name } = props;
  const [imageId, setImageId] = useState(defaultValue);
  const imageSrc = useImageUrlGenerator(imageId);

  const handleNewImageId = useCallback((e) => {
    setImageId(e.target.value);
  }, []);

  return (
    <>
      <TextField
        inputRef={ref}
        name={name}
        defaultValue={defaultValue}
        onChange={handleNewImageId}
        label="image id"
        variant="standard"
        InputLabelProps={{ shrink: true }}
      />

      <Card elevation={2} sx={{ height: 240, flexGrow: '1' }}>
        {!imageSrc && <ImageNotSupportedIcon sx={{ m: 1 }} />}
        {!!imageSrc && (
          <Tooltip placement="top" title="Click to open the image in a new tab" followCursor>
            <CardActionArea sx={{ height: '100%' }} href={imageSrc} target="_blank">
              <CardMedia
                key={imageSrc}
                component="img"
                src={imageSrc}
                alt="push image preview"
                sx={{ height: '100%', objectFit: 'contain' }}
              />
            </CardActionArea>
          </Tooltip>
        )}
      </Card>
    </>
  );
});
SchedulePushImageInput.displayName = 'SchedulePushImageInput';
export default SchedulePushImageInput;
