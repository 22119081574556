import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';

import { Box, MenuItem, TextField } from '@mui/material';

import { getRegionByCode, getRegions } from '@luxuryescapes/lib-regions';
import { ExtendedRegion } from '@luxuryescapes/lib-regions/lib/extended';

import { changeCurrencyCode } from '~/actions/cart';

interface CurrencySelectorProps {
  cart: App.Cart;
  changeCurrencyCodeState: (
    currencyCode: string,
    regionCode: string,
  ) => {
    type: string;
    currencyCode: string;
    regionCode: string;
  };
  onChangeCurrencyCode?: (currencyCode: string) => void;
  tenant: App.Tenant;
}

export function CurrencySelector(props: CurrencySelectorProps) {
  const { cart, changeCurrencyCodeState, onChangeCurrencyCode, tenant } = props;

  const [regions, setRegions] = useState<ExtendedRegion[]>([]);

  useEffect(() => {
    setRegions(getRegions(tenant.brand));
  }, [tenant.brand]);

  const handleChangeRegionCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { currencyCode, code } = getRegionByCode(e.target.value, tenant.brand);

    onChangeCurrencyCode(code);
    changeCurrencyCodeState(currencyCode, code);
  };

  return (
    <Box mb={2} width={200}>
      <TextField
        id="currency-selection"
        label="Order Region / Currency"
        value={cart.regionCode}
        onChange={handleChangeRegionCode}
        fullWidth
        select
      >
        {regions.length > 0 &&
          regions.map((region) => {
            return (
              <MenuItem key={region.code} value={region.code}>
                {`${region.name} (${region.currencyCode})`}
              </MenuItem>
            );
          })}
      </TextField>
    </Box>
  );
}

function mapStateToProps({ cart, tenant }: App.State) {
  return {
    cart,
    tenant,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      changeCurrencyCodeState: changeCurrencyCode,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelector);
