import React from 'react';

import { MarkdownPreview } from 'react-marked-markdown';
import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import mergeRoomPolicyNames from '../../../utils/mergeRoomPolicyNames';

export default function RoomPolicyPane({ offer, propertyData }) {
  const roomPolicyDetails = mergeRoomPolicyNames(offer.result.packages, propertyData);

  return (
    <span>
      <h1 className="page-header">
        Room Policy
        <Link to={'/edit-offers/' + offer.result.id_salesforce_external + '/room-policies'}>
          <ModeEditTwoToneIcon fontSize="large" />
          <span className="sr-only"> Edit</span>
        </Link>
      </h1>
      <Box>
        {roomPolicyDetails.map((roomPolicy, index) => (
          <Accordion key={`${roomPolicy.id}-${index}`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="room-policy-content"
              id={`${roomPolicy.id}-${index}`}
            >
              <Typography sx={{ textTransform: 'uppercase', display: 'inline', fontSize: 18, fontWeight: 300 }}>
                {roomPolicy.room_type_name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderTop: '1px solid rgba(0, 0, 0, .125)', padding: 2 }}>
              <MarkdownPreview value={roomPolicy.description} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </span>
  );
}
