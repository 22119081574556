import React from 'react';

import currencyFormatter from 'currency-formatter';

import BadgeIcon from '@mui/icons-material/Badge';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LabelIcon from '@mui/icons-material/Label';
import SailingIcon from '@mui/icons-material/Sailing';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack, Typography } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import { Departure } from './mappers';

const formatDeposit = (deposit: API.PromotionDeposit): string => {
  if (!deposit.amount) return 'N/A';
  if (deposit.type === 'PERCENTAGE') return `${deposit.amount}%`;
  return currencyFormatter.format(deposit.amount, { code: deposit.currencyCode });
};

const formatOBC = (obc: API.PromotionOnBoardCredit): string => {
  if (!obc.amount) return 'N/A';
  return currencyFormatter.format(obc.amount, { code: obc.currencyCode });
};

const buildAccordionStyle = (departureId: string, leadDepartureId: string | null) => {
  if (departureId !== leadDepartureId) return { border: '1px solid #c8c8c8', borderRadius: '8px' };
  else return { border: '1.5px solid #2c8a3a', borderRadius: '8px', svg: { fill: '#2c8a3a' } };
};

type Props = {
  departures: Departure[];
  leadDepartureId: string | null;
  onHandleDeparture: (departureId: string | null) => void;
};

function DeparturesDetails(props: Props) {
  const { departures, leadDepartureId, onHandleDeparture } = props;

  const handleDeparture = (departureId: string) => {
    if (departureId === leadDepartureId) onHandleDeparture(null);
    else onHandleDeparture(departureId);
  };

  return (
    <Stack spacing={1}>
      {departures.map((departure) => (
        <Accordion key={departure.id} sx={buildAccordionStyle(departure.id, leadDepartureId)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`${departure.id}-header`}
            aria-controls={`${departure.id}-content`}
          >
            <Stack direction="row" width="100%" gap="24" pr={2}>
              <Stack direction="row" flex={1.7} alignItems="flex-end">
                <BadgeIcon sx={{ width: '24px', height: '24px', marginRight: '8px' }} />
                <Typography variant="body1">ID: {departure.id}</Typography>
              </Stack>
              <Divider orientation="vertical" sx={{ borderColor: '#000', margin: '0 16px' }} />
              <Stack direction="row" flex={1} alignItems="flex-end">
                <SailingIcon sx={{ width: '24px', height: '24px', marginRight: '8px' }} />
                <Typography variant="body1">Sailing ID: {departure.externalId}</Typography>
              </Stack>
              <Divider orientation="vertical" sx={{ borderColor: '#000', margin: '0 16px' }} />
              <Stack direction="row" flex={0.7} alignItems="flex-end">
                <DateRangeIcon sx={{ width: '24px', height: '24px', marginRight: '8px' }} />
                <Typography variant="body1">{departure.departureDate}</Typography>
              </Stack>
              <Divider orientation="vertical" sx={{ borderColor: '#000', margin: '0 16px' }} />
              <Stack direction="row" flex={1} alignItems="flex-end">
                <LabelIcon sx={{ width: '24px', height: '24px', marginRight: '8px' }} />
                <Typography variant="body1">Has Promotion: {departure.activePromotion ? 'YES' : 'NO'}</Typography>
              </Stack>
              <Stack flex={0.7}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleDeparture(departure.id)}
                  color={departure.id === leadDepartureId ? 'error' : 'success'}
                >
                  {departure.id === leadDepartureId ? 'Remove as Lead' : 'Mark as Lead'}
                </Button>
              </Stack>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            {departure.activePromotion && (
              <>
                <Box width="100%" px={5}>
                  <Stack direction="row" width="100%" gap="24px" mt="8px">
                    <Typography flex={1.5} variant="body1">
                      <b>Promotion ID:</b> {departure.activePromotion.id}
                    </Typography>
                    <Typography flex={1} variant="body1">
                      <b>Promotion Name:</b> {departure.activePromotion.name}
                    </Typography>
                    <Typography flex={1} variant="body1">
                      <b>Promotion Date:</b> {departure.activePromotion.startDate} - {departure.activePromotion.endDate}
                    </Typography>
                  </Stack>
                  <Stack direction="row" width="100%" gap="24" mt="8px">
                    <Typography flex={1} variant="body1">
                      <b>LE Exclusive:</b> {departure.activePromotion.leExclusive ? 'YES' : 'NO'}
                    </Typography>
                    <Typography flex={1} variant="body1">
                      <b>Deposit:</b>{' '}
                      {departure.activePromotion.deposit ? formatDeposit(departure.activePromotion.deposit) : 'N/A'}
                    </Typography>
                    <Typography flex={1} variant="body1">
                      <b>Onboard Credits:</b>{' '}
                      {departure.activePromotion.deposit ? formatOBC(departure.activePromotion.onBoardCredit) : 'N/A'}
                    </Typography>
                    <Typography flex={2} variant="body1">
                      <b>Selling Points:</b> {departure.activePromotion.sellingPoints.join(' | ')}
                    </Typography>
                  </Stack>
                </Box>
                <Divider sx={{ borderColor: '#c4c4c4', margin: '24px 0' }} />
              </>
            )}

            <Stack direction="row" width="100%" gap="24">
              {Object.entries(departure.lowestPriceByCabinCategories)?.map(([key, value]) => (
                <Stack key={`${key}-${value.price}`} flex={1} sx={{ textAlign: 'center' }}>
                  <Typography variant="body1">
                    <b>{key}</b> from
                  </Typography>
                  <Typography variant="body1">
                    {currencyFormatter.format(value.price, { code: value.currencyCode, precision: 0 })} / person
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}

export default DeparturesDetails;
