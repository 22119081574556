import React, { useCallback, useEffect, useState } from 'react';

import { Alert, Chip, Link } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Tour as TourResponse } from '@luxuryescapes/contract-svc-tour';

import { getTours } from '~/services/ToursService';
import { ReqInstance } from '~/services/types';

import { LETourSearchInput } from '../LETourSearchForm';

import SFTourRefreshButton from './SFTourRefreshButton';
import TourSellingRegionChips from './TourChips';
import TourScheduleIndicatorIcon from './TourScheduleIndicatorIcon';
import { getTourResultSellingRegions } from './tourSearchResultValueGetters';

const columns: GridColDef<TourResponse.Tour>[] = [
  {
    field: 'scheduleIndicator',
    headerName: '',
    align: 'center',
    display: 'flex',
    width: 40,
    valueGetter: (_value, row) => row.scheduleState?.state,
    renderCell: (params) => <TourScheduleIndicatorIcon status={params.value} />,
  },
  {
    field: 'name',
    headerName: 'Tour Name',
    display: 'flex',
    minWidth: 260,
    flex: 1,
    renderCell: (params) => (
      <Link href={`/tours/details/${params.row.id}`} target="_blank" className="le-tour-search-result-link">
        {params.value}
      </Link>
    ),
  },
  {
    field: 'brand',
    headerName: 'Operator',
    display: 'flex',
    maxWidth: 220,
    minWidth: 140,
    valueGetter: (_value, row) => row.brandObject.name,
  },
  {
    field: 'vendorName',
    headerName: 'Vendor',
    display: 'flex',
    maxWidth: 220,
    minWidth: 140,
  },
  {
    field: 'sellingRegion',
    headerName: 'Regions',
    maxWidth: 200,
    minWidth: 150,
    align: 'center',
    headerAlign: 'center',
    display: 'flex',
    valueGetter: (_value, row) => getTourResultSellingRegions(row),
    renderCell: (params) => <TourSellingRegionChips value={params.value} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 140,
    align: 'center',
    headerAlign: 'center',
    display: 'flex',
    renderCell: (params) => (
      <Chip label={params.value} size="small" color={params.value === 'content-approved' ? 'info' : undefined} />
    ),
  },
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 240,
    minWidth: 200,
    display: 'flex',
  },
  {
    field: 'sourceTourId',
    headerName: 'SF ID',
    maxWidth: 240,
    minWidth: 200,
    display: 'flex',
  },
  {
    field: 'refreshAction',
    headerName: 'Refresh',
    width: 60,
    align: 'center',
    headerAlign: 'center',
    display: 'flex',
    renderCell: (params) => <SFTourRefreshButton salesforceId={params.row.sourceTourId} />,
  },
];

interface Props {
  searchInput: LETourSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export default function LETourSearchResultsTable(props: Props) {
  const { searchInput, pageIndex, onPageChange } = props;
  const [toursFetchReq, setToursFetchReq] = useState<
    ReqInstance<{ tours: TourResponse.Tour[]; total: number; error?: string }>
  >({
    status: 'uninitiated',
  });

  const fetchTours = useCallback(async (input: LETourSearchInput, reqPageIndex = 0) => {
    setToursFetchReq({ status: 'pending' });

    try {
      const toursRes = await getTours({
        source: 'salesforce',
        page: String(reqPageIndex + 1),
        per_page: String(PAGE_SIZE),
        status: input.status,
        countriesVisited: input.countries,
        searchString: input.tourName,
        sourceTourIds: input.salesforceId,
        ids: input.ids,
      });

      const total = toursRes.result.total;
      const tours: TourResponse.Tour[] = toursRes.result.tours;
      let error: string;

      setToursFetchReq({ status: 'succeeded', result: { total, tours, error } });
    } catch (err) {
      setToursFetchReq({ status: 'failed', error: JSON.stringify(err) });
    }
  }, []);

  useEffect(() => {
    fetchTours(searchInput, pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, pageIndex]);

  return (
    <>
      {toursFetchReq.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(toursFetchReq.error)}
        </Alert>
      )}
      {toursFetchReq.status === 'succeeded' && toursFetchReq.result.error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {toursFetchReq.result.error}
        </Alert>
      )}
      <DataGrid
        className="le-tour-search-results-table"
        columns={columns}
        rows={toursFetchReq.status === 'succeeded' ? toursFetchReq.result.tours : []}
        rowCount={toursFetchReq.status === 'succeeded' ? toursFetchReq.result.total : 0}
        getRowHeight={() => 'auto'}
        paginationMode="server"
        paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        loading={toursFetchReq.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableColumnMenu
      />
    </>
  );
}
