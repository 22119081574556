import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Stack, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import UserReviewsTable from '~/components/Reviews/UserReviewsTable';

import {
  changeReviewsVisibilityForProperty,
  clearReviewsCache,
  isReviewsHiddenForProperty,
} from '~/services/ReviewService';

interface Props {
  propertyId: string;
}

export default function PropertyUserReviewsPane({ propertyId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isHiddenFetched, setIsHiddenFetched] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    isReviewsHiddenForProperty(propertyId).then(({ result }) => {
      setIsHidden(!!result.isHidden);
      setIsHiddenFetched(true);
    });
  }, [propertyId]);

  const clearCache = useCallback(async () => {
    await clearReviewsCache({ propertyId });

    enqueueSnackbar('Review cache was successfully cleared!', { variant: 'success' });
  }, [propertyId, enqueueSnackbar]);

  const hideAllReviews = useCallback(async () => {
    try {
      setIsActionLoading(true);
      await changeReviewsVisibilityForProperty(propertyId, isHidden);
      setIsHidden(!isHidden);
      setIsActionLoading(false);
      enqueueSnackbar('Reviews visibility successfully changed for this property!', { variant: 'success' });
    } catch (error) {
      setIsActionLoading(false);
      enqueueSnackbar('Error when trying to change reviews visibility for this property!', { variant: 'error' });
    }
  }, [propertyId, enqueueSnackbar, isHidden]);

  return (
    <div>
      <PageSubheader title="User reviews" />

      <Stack spacing={2} direction="column">
        <div>
          <Typography variant="h5">Manage reviews</Typography>
          <Typography fontStyle="italic" variant="body1">
            The rating and Google reviews will be reset next time a user sees the property on the customer portal
          </Typography>
          {isHidden && (
            <Typography fontStyle="italic" variant="body1" color="error">
              All reviews (Google and LE) are currently hidden for this property
            </Typography>
          )}
        </div>
        <Stack spacing={2} direction="row">
          <Button color="warning" variant="outlined" disabled={isActionLoading} onClick={clearCache}>
            Clear rating & reviews
          </Button>
          {isHiddenFetched && (
            <Button color="warning" variant="outlined" disabled={isActionLoading} onClick={hideAllReviews}>
              {isHidden ? 'Show' : 'Hide'} all reviews
            </Button>
          )}
        </Stack>
      </Stack>

      <hr />

      <UserReviewsTable propertyId={propertyId} />
    </div>
  );
}
