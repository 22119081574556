import React from 'react';

import useImageUrlGenerator, { ImageUrlGeneratorOptions } from '~/hooks/useImageUrlGenerator';

interface Props extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  className?: string;
  publicId: string;
  options?: ImageUrlGeneratorOptions;
}

function Image(props: Props) {
  const { className, publicId, options, ...restOfImageProps } = props;

  const src = useImageUrlGenerator(publicId, options);

  return <img className={className} src={src} {...restOfImageProps} />;
}

export default Image;
