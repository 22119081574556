import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerCountriesGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/country-groups/{countryGroup}/countries']['get'];

function mapHeroPlannerCountry(
  external: Utils.ArrayElement<
    HeroPlannerCountriesGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerCountry {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetHeroPlannerCountries(brand: string, countryGroup: string) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';

  const url = makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/country-groups/${countryGroup}/countries`,
  );
  url.searchParams.set('brand', brand);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerCountriesGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerCountry);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerCountries;
