import axios from 'axios';

export const generateUrl = async (data: string): Promise<string> => {
  // Make a request to the /mac-key route to get the MAC key
  try {
    const macKeyResponse = await axios.get(`/mac-key?data=${encodeURIComponent(data)}`);

    return `?data=${encodeURIComponent(data)}&mac=${macKeyResponse.data}`;
  } catch (generateMacErr) {
    console.error('something went wrong while creating the MAC', generateMacErr);
  }
};
