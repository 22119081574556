import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Box, Button, Container, Stack } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import OfferAssociationsSearchForm from '~/components/Common/Forms/helpers/OfferAssociationsSearchForm';
import Spinner from '~/components/Common/Spinner';
import AddPropertyLocationOverrideModal from '~/components/Content/PropertyLocationOverride/AddPropertyLocationOverrideModal';
import { PropertyLocationOverride } from '~/components/Content/PropertyLocationOverride/Columns';
import ConfirmDeleteModal from '~/components/Content/PropertyLocationOverride/ConfirmDeleteModal';
import PropertyLocationOverrideTable from '~/components/Content/PropertyLocationOverride/PropertyLocationOverrideTable';
import UpdatePropertyLocationOverrideModal from '~/components/Content/PropertyLocationOverride/UpdatePropertyLocationOverrideModal';

import SearchService from '~/services/SearchService';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

function PropertyLocationOverrideList() {
  const [searchString, setSearchString] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [data, setData] = useState<Array<PropertyLocationOverride>>([]);
  const [displayData, setDisplayData] = useState<Array<PropertyLocationOverride>>([]);
  const [rowToUpdate, setRowToUpdate] = useState<PropertyLocationOverride | undefined>(undefined);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemsToDelete, setItemsToDelete] = useState<Array<PropertyLocationOverride>>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSearchTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchString(e.target.value);
  };

  useEffect(() => {
    if (!searchString) {
      setData(data); // Reset to original list if searchString is empty
    } else {
      const lowerCaseSearchString = searchString.trim().toLowerCase();
      setDisplayData(
        data.filter(
          (item) =>
            item.propertyName.toLowerCase().includes(lowerCaseSearchString) ||
            item.propertyId.toLowerCase().includes(lowerCaseSearchString) ||
            item.placeName.toLowerCase().includes(lowerCaseSearchString) ||
            item.placeId.toLowerCase().includes(lowerCaseSearchString) ||
            item.notes.toLowerCase().includes(lowerCaseSearchString) ||
            item.lastModifiedBy.toLowerCase().includes(lowerCaseSearchString),
        ),
      );
    }
  }, [searchString, data]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { result } = await SearchService.getOfferAssociationList();
      const data = result.map(({ id, propertyId, propertyName, placeId, placeName, notes, lastModifiedBy }) => ({
        id,
        propertyName,
        propertyId,
        placeName,
        placeId,
        notes: notes ?? '',
        lastModifiedBy,
      }));
      setData(data);
      setDisplayData(data);
    } catch (e) {
      setData([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearchSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      setSearchString(searchString);
    },
    [searchString],
  );

  const handleSelect = useCallback((ids: GridRowSelectionModel) => {
    setSelected(ids);
  }, []);

  const handleDelete = useCallback(async () => {
    const deletePromises = selected.map(async (id: string) => {
      const itemToBeRemoved = data.find((item) => item.id === id);
      try {
        const { status } = await SearchService.deleteOfferAssociationList(id);

        if (status === 200) {
          enqueueSnackbar(`Successfully deleted association for ${itemToBeRemoved.propertyName}`, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(`Failed to delete association for ${itemToBeRemoved.propertyName}`, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(`Error deleting association for ${itemToBeRemoved.propertyName}`, { variant: 'error' });
      }
    });

    await Promise.all(deletePromises);
    await fetchData();
    setItemsToDelete([]);
  }, [selected, fetchData, data, enqueueSnackbar]);

  const handleUpdate = useCallback(async (row: PropertyLocationOverride) => {
    setUpdateModalOpen(true);
    setRowToUpdate(row);
  }, []);

  const handleAddOpenModal = () => {
    setAddModalOpen(true);
  };

  const handleAddCloseModal = async () => {
    setAddModalOpen(false);
    await fetchData();
  };

  const handleUpdateCloseModal = async () => {
    setUpdateModalOpen(false);
    await fetchData();
  };

  const handleConfirmDeleteCloseModal = async () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDeleteOpenModal = async () => {
    const selectedIds = selected.map((id: string) => id);
    setItemsToDelete(selectedIds.map((id) => data.find((item) => item.id === id)));
    setDeleteModalOpen(true);
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Property Location Override List</title>
      </Helmet>

      <Stack direction="column" gap={4}>
        <AddPropertyLocationOverrideModal data={data} open={isAddModalOpen} onClose={handleAddCloseModal} />
        <UpdatePropertyLocationOverrideModal
          data={data}
          rowToUpdate={rowToUpdate}
          open={isUpdateModalOpen}
          onClose={handleUpdateCloseModal}
        />
        <ConfirmDeleteModal
          itemsToRemove={itemsToDelete}
          open={isDeleteModalOpen}
          handleDelete={handleDelete}
          onClose={handleConfirmDeleteCloseModal}
        />

        <PageSubheader title={`Property Location Override`} />

        <HeaderContainer>
          <Box width="450px">
            <OfferAssociationsSearchForm
              searchString={searchString}
              handleChange={handleSearchTextChange}
              handleSubmit={handleSearchSubmit}
            />
          </Box>
          <Button sx={{ width: '400px' }} variant="contained" color="primary" onClick={handleAddOpenModal}>
            Add New Override
          </Button>
        </HeaderContainer>

        {isLoading && <Spinner />}

        <PropertyLocationOverrideTable
          selected={selected}
          isLoading={isLoading}
          data={displayData}
          handleSelect={handleSelect}
          handleDelete={handleConfirmDeleteOpenModal}
          handleUpdate={handleUpdate}
        />
      </Stack>
    </Container>
  );
}

export default PropertyLocationOverrideList;
