import React from 'react';

import { Box, TableCell } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';

export function FlexTableCell({ children, align, ...props }: TableCellProps) {
  return (
    <TableCell {...props}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.3,
          ...(align === 'right' && {
            justifyContent: 'flex-end',
          }),
        }}
      >
        {children}
      </Box>
    </TableCell>
  );
}
