import cloneDeep from 'lodash/cloneDeep';

import { addDays, datesHaveSameDayMonthYear, isAfter } from '~/services/TimeService';

function dateIsNextFromRange(range, date) {
  const nextDayFromRange = addDays(1, cloneDeep(range.end));
  return datesHaveSameDayMonthYear(nextDayFromRange, date);
}

export default function convertDateListToRanges(dates) {
  const sortedDates = dates.sort((a, b) => (isAfter(a.date, b.date) ? 1 : -1));
  const ranges = [];

  for (let i = 0; i < sortedDates.length; i++) {
    const last = ranges.length - 1;
    const date = sortedDates[i].date;

    if (ranges.length > 0 && dateIsNextFromRange(ranges[last], date)) {
      ranges[last].end = date;
    } else {
      ranges.push({
        start: date,
        end: date,
      });
    }
  }

  return ranges;
}
