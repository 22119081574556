export function mapSFCancellationPolicyToAdmin(sfCancellationPolicy?: string): string | undefined {
  switch (sfCancellationPolicy) {
    case 'Flexible cancellation 21 days before check-in':
      return 'partner-policy-prior-to-check-in-twenty-one-days';
    case 'Flexible cancellation 45 days before check-in':
      return 'flexible-cancellation-forty-five-days';
    case '7 day change of mind guarantee':
      return 'post-purchase-seven-days';
    case 'Hidden Cancellation Policy':
      return 'hidden-cancellation-policy';
    default:
      undefined;
  }
}
