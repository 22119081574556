import React, { Component } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';

import { reportError } from '~/utils/reportError';

import ReservationService from '../../../services/ReservationService';
import ErrorListDisplay from '../ErrorListDisplay';
import Spinner from '../Spinner';

import { buttonStates } from './states/submitButton';

class RoomRateForm extends Component {
  constructor(props) {
    super(props);

    this.createRoomRate = this.createRoomRate.bind(this);

    this.state = {
      saveState: buttonStates.default,
      errors: null,
      anchorEl: null,
    };
  }

  async createRoomRate(ratePlanId) {
    this.handleClose();

    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    try {
      await ReservationService.createRoomRate(this.props.propertyId, this.props.roomTypeId, {
        rate_plan_id: ratePlanId,
      });
      await this.props.onUpdateRoomRates();

      this.setState({
        saveState: buttonStates.saved,
        errors: null,
      });
    } catch (e) {
      const errors = e.name === 'ValidationError' ? e.errors : null;

      this.setState({
        saveState: buttonStates.failed,
        errors,
      });

      reportError(e);
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const saving = this.state.saveState === buttonStates.saving;
    const open = Boolean(this.state.anchorEl);

    return (
      <div className="capacity-form form-horizontal">
        <div className="modal-form-group">
          {this.state.errors && <ErrorListDisplay messages={this.state.errors.map(({ message }) => message)} />}
          <Button
            id="room-rate-select-button"
            aria-controls={open ? 'room-rate-select-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            onClick={this.handleClick}
            endIcon={<ArrowDropDownIcon />}
          >
            Create Room Rate {saving && <Spinner size={15} inline={true} />}
          </Button>
          <Menu
            MenuListProps={{
              'aria-labelledby': 'room-rate-select-button',
            }}
            id="room-rate-select-menu"
            anchorEl={this.state.anchorEl}
            open={open}
            onClose={this.handleClose}
          >
            {this.props.ratePlans.map((ratePlan) => {
              return (
                <MenuItem key={ratePlan.id} onClick={() => this.createRoomRate(ratePlan.id)}>
                  {ratePlan.name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    );
  }
}

export default RoomRateForm;
