import qs from 'qs';

import { actions, definitions } from '@luxuryescapes/contract-svc-flights';

import { AtolForm } from '~/components/Users/Orders/Atol/AtolPage';

import { request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

const validRates = ['daily', 'twice daily'];
const validTravelDayLongNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const validTravelDayNames = [
  ...validTravelDayLongNames,
  ...validTravelDayLongNames.map((name) => name.substring(0, 3)),
];

export const structureTravelDaysForApi = (travelDays: string) => {
  const days = travelDays
    .split(/\s*,\s*/)
    .map((value) => value.toLowerCase().trim())
    .filter(Boolean);
  if (days.length === 0) {
    return null;
  }
  if (days.length === 1 && validRates.includes(days[0])) {
    return days[0];
  }
  return days;
};

export const validateTravelDays = (travelDays: string | string[] | null) => {
  if (!travelDays) {
    return true;
  }
  if (typeof travelDays === 'string') {
    return validRates.includes(travelDays);
  }
  if (!Array.isArray(travelDays)) {
    return false;
  }
  return travelDays.every((day) => validTravelDayNames.includes(day));
};

export const transformTravelDaysFromApi = (travelDays: string | string[] | null) => {
  if (!travelDays) {
    return '';
  }
  if (typeof travelDays === 'string') {
    return travelDays;
  }
  return travelDays.join(', ');
};

function postRequest(path, data) {
  return request(path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function putRequest(path, data) {
  return request(path, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function getRequest(path) {
  return request(path, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

function patchRequest(path, data) {
  return request(path, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function deleteRequest(path) {
  return request(path, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

export function retrievePNRBookingDetails(data) {
  return postRequest(basePath() + 'flights/retrievePNRBookingDetails', data);
}

export function addFlightDeal(data) {
  return postRequest(basePath() + 'flights/flight-deals/create', data);
}

export function updateFlightDeal(data, dealId) {
  return patchRequest(basePath() + `flights/flight-deals/${dealId}/edit`, data);
}

export function deleteFlightDeal(dealId: string) {
  return deleteRequest(basePath() + `flights/flight-deals/${dealId}`);
}

export function retrieveAtolDetails(data) {
  return postRequest(basePath() + 'flights/atol/getAll', data);
}

export async function getCreditReservations(data) {
  return postRequest(basePath() + 'flights/credit-reservation/getAll', data);
}

export async function getCreditReservationDetails(id) {
  return getRequest(basePath() + `flights/credit-reservation/${id}/details`);
}

export async function getCreditReservationByPnrId(pnrId) {
  return getRequest(basePath() + `flights/credit-reservation/${pnrId}/by-previous-id`);
}

export async function updateCreditReservation(id, data) {
  return putRequest(basePath() + `flights/credit-reservation/${id}/update`, data);
}

export async function getRecordsDataByUrl(url, data) {
  return postRequest(basePath() + `flights/reports/${url}`, data);
}

export async function getFlightDeals(data) {
  return postRequest(basePath() + 'flights/flight-deals/getAll', data);
}

export async function getDealFromId(dealId) {
  return getRequest(basePath() + `flights/flight-deals/${dealId}`);
}

export async function getAllAirlineCarriers(brand = 'luxuryescapes') {
  return getRequest(basePath() + `flights/airlines?brand=${brand}`);
}

type GetAllForceBundlesResponse = Result<App.ForceBundle[]>;
export async function getAllForceBundles(): Promise<GetAllForceBundlesResponse> {
  return getRequest(basePath() + 'flights/force-bundles');
}

type GetForceBundleResponse = Result<App.ForceBundle>;
export async function getForceBundle(id: string): Promise<GetForceBundleResponse> {
  return getRequest(basePath() + 'flights/force-bundles/' + id);
}

type ForceBundleAvailabilityRequest = {
  brand: string;
  forceBundleDealName: string;
  forceBundleId: string;
  origin: string;
  destination: string;
  region: string;
  startDate: string;
  endDate: string;
};
type ForceBundleAvailabilityResponse = Result<App.ForceBundle & { amountAvailable: number }>;
export async function getForceBundleAvailability(
  data: Partial<ForceBundleAvailabilityRequest>,
): Promise<ForceBundleAvailabilityResponse> {
  return postRequest(basePath() + 'flights/force-bundles/availability', {
    brand: 'luxuryescapes',
    ...data,
  });
}

export async function sendAtolCertificate(data: AtolForm): Promise<Result<AtolForm>> {
  return postRequest(basePath() + `le-notify/atol-certificate/create`, {
    brand: 'luxuryescapes',
    ...data,
  });
}

export interface FDATable {
  name: string;
  displayName: string;
}

export async function getFDATables(): Promise<{ result: FDATable[] }> {
  return getRequest(basePath() + 'flights/fda/table-names');
}

export async function getFDATableData(
  tableName: string,
  options?: {
    limit: number;
    offset: number;
    sortField?: string;
    sortDirection?: string;
    searchField?: string;
    searchValue?: string;
  },
): Promise<{ result: { count: number; rows: Record<string, FDATableRecord>[] } }> {
  let query = {};

  if (options) {
    query = {
      limit: options.limit,
      offset: options.limit * options.offset,
    };
  }

  if (options?.sortField && options?.sortDirection) {
    query = {
      ...query,
      orderBy: `${options.sortField}:${options.sortDirection.toUpperCase()}`,
    };
  }

  if (options?.searchField && options?.searchValue) {
    query = {
      ...query,
      filterBy: `{"${options.searchField}":"${options.searchValue}"}`,
    };
  }

  return getRequest(basePath() + `flights/fda/${tableName}?${qs.stringify(query)}`);
}

export type FDATableDataType = 'number' | 'string' | 'boolean' | 'date';

export interface FDATableSchema {
  name: string;
  displayName: string;
  dataType: FDATableDataType;
  allowNull: boolean;
  maxLength?: number;
}

interface FDATableSchemaResponse {
  attributes: FDATableSchema[];
  searchSupported: boolean;
  sortableFields: string[];
  searchableFields: string[];
}

export async function getFDATableSchema(tableName: string): Promise<{ result: FDATableSchemaResponse }> {
  return getRequest(basePath() + `flights/fda/${tableName}/schema`);
}

type FDATableRecord = string | number | boolean;

export async function createFDATableRecord(
  tableName: string,
  data: Record<string, FDATableRecord>,
): Promise<{ result: Record<string, FDATableRecord> }> {
  return postRequest(basePath() + `flights/fda/${tableName}`, data);
}

export async function updateFDATableRecord(tableName: string, recordId: string, data: Record<string, FDATableRecord>) {
  return patchRequest(basePath() + `flights/fda/${tableName}/${recordId}`, data);
}

export async function deleteFDATableRecord(tableName: string, recordId: string) {
  return deleteRequest(basePath() + `flights/fda/${tableName}/${recordId}`);
}

type getCalendarSearchAdminPayload =
  actions['/api/flights/calendar-search/admin/post']['parameters']['body']['CheapestPriceParams'];
export type getCalendarSearchAdminResponse =
  actions['/api/flights/calendar-search/admin/post']['responses']['200']['content']['application/json'];
export type CheapestPrice = definitions['CheapestPrice'];

export async function getCalendarSearchAdmin(
  payload: getCalendarSearchAdminPayload,
): Promise<getCalendarSearchAdminResponse> {
  return postRequest(basePath() + 'flights/calendar-search/admin', payload);
}
