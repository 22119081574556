import React from 'react';

import { connect } from 'react-redux';

import { Box } from '@mui/material';

import OrderFooter from '../OrderFooter';
import { getAllItems } from '../selectors';

import GroupedPackages from './GroupedPackages';

interface PackageSelectionProps {
  backStep: () => void;
  cart: App.Cart;
  items: App.CartItem[];
  nextStepLabel: string;
  proceedStep: () => void;
}

function PackageSelection({ backStep, cart, items, nextStepLabel, proceedStep }: PackageSelectionProps) {
  return (
    <>
      <Box mb={6}>
        {cart.offers.map((cartOffer, index) => (
          <div key={cartOffer.id_salesforce_external}>
            <GroupedPackages offer={cartOffer} offerNumber={index + 1} />
          </div>
        ))}
      </Box>

      <OrderFooter
        cart={cart}
        proceedStep={proceedStep}
        canProceed={items.length > 0}
        backStep={backStep}
        nextStepLabel={nextStepLabel}
      />
    </>
  );
}

function mapStateToProps(state: App.State) {
  return {
    cart: state.cart,
    items: getAllItems(state),
  };
}

export default connect(mapStateToProps, null)(PackageSelection);
