import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';

interface Props {
  openModal: boolean;
  flushSpoofingState: () => void;
  spoofUrl: string;
  trip: string;
}

function TripSpoofDialog({ openModal, flushSpoofingState, spoofUrl, trip }: Props) {
  return (
    <Dialog open={openModal} onClose={flushSpoofingState}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div>Spoofing Link Generated</div>
          <IconButton edge="end" color="inherit" onClick={flushSpoofingState} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={1}>
          <Alert severity="warning">
            Spoofing link for {trip} has been copied to the clipboard! (EXTREME CAUTION, PASTE INTO INCOGNITO ONLY)
          </Alert>
          <TextField
            value={spoofUrl}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default TripSpoofDialog;
