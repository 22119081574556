import React from 'react';

import hoistNonReactStatic from 'hoist-non-react-statics';
import { connect } from 'react-redux';

export function withTenant(Component) {
  const HOC = (props) => <Component {...props} />;

  hoistNonReactStatic(HOC, Component);

  const mapStateToProps = (state) => ({
    tenant: state.tenant,
  });

  return connect(mapStateToProps)(HOC);
}
