import React, { Component } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';

import { Button, Stack } from '@mui/material';

import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import { buttonMessages, buttonStates } from './states/submitButton';

const DEFAULT_VALUES = {
  start_date: '',
  end_date: '',
  min_days: 0,
  max_days: 0,
};

interface Props {
  propertyId: string;
  roomTypeId: string;
  roomRateId: string;
  leadDaysSchema: any;
  onRoomRateLeadDaysUpdated: (response: unknown) => void;
  onCancel: () => void;
}

interface State {
  leadDayItem: any;
  saveState: string;
  errors: Array<string>;
}

export default class RoomRateLeadDaysForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      leadDayItem: { ...DEFAULT_VALUES },
      saveState: buttonStates.default,
      errors: null,
    };
  }

  handleChange = ({ formData }: IChangeEvent) => this.setState({ leadDayItem: formData });

  handleSubmit = async ({ formData }: IChangeEvent) => {
    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    try {
      const response = await ReservationService.manageRoomRateLeadDays(
        formData,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      );

      this.setState({
        saveState: buttonStates.saved,
      });

      this.props.onRoomRateLeadDaysUpdated(response.result);
    } catch (e) {
      const errors = e.name === 'ValidationError' ? e.errors : null;
      this.setState({
        saveState: buttonStates.failed,
        errors,
      });
      reportError(e);
    }
  };

  render() {
    const schema = {
      ...this.props.leadDaysSchema,
      properties: {
        ...this.props.leadDaysSchema.properties,
        start_date: {
          ...this.props.leadDaysSchema.properties.start_date,
          format: 'date',
        },
        end_date: {
          ...this.props.leadDaysSchema.properties.end_date,
          format: 'date',
        },
      },
    };
    const uiSchema = {};

    return (
      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={this.handleSubmit}
        formData={this.state.leadDayItem}
        onChange={this.handleChange}
        validator={validator}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mt={2}>
          <Button variant="text" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={this.state.saveState === buttonStates.saving}>
            {buttonMessages[this.state.saveState]}
          </Button>
        </Stack>
      </Form>
    );
  }
}
