import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Box, Container, Typography } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import PageHeader from '~/components/Common/Elements/PageHeader';
import TourForm from '~/components/Common/Forms/TourForm';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

export default function TourEditContainer() {
  const { id_vendor: vendorId, id_tour: tourId } = useParams<{ id_vendor: string; id_tour: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState<App.Vendor | null>(null);
  const [tour, setTour] = useState<API.Reservation.Tour>(null);
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getTourSchema(),
      ReservationService.getTour(tourId),
      VendorsService.getVendorById(vendorId),
    ])
      .then(([schema, tour, vendor]) => {
        setSchema(schema.put.body.schema);
        setTour(tour.result);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, tourId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load tour or vendor.</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor.name} | {tour.name} | Edit Tour
        </title>
      </Helmet>

      <PageHeader title="Edit tour" backButton={`/vendors/${vendorId}/tours/${tourId}`} />

      <Box>
        <TourForm tour={tour} schema={schema} vendorId={vendorId} />
      </Box>
    </Container>
  );
}
