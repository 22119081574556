import React from 'react';

import { Controller } from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';

import ConnectForm from './ConnectForm';

type Props = {
  index: number;
  handleDelete: (index: number) => void;
  isLoading: boolean;
};

const OFFER_TYPES = ['Flash', 'LPC', 'LPP'];

const SUPPORTED_REGIONS = ['AU', 'NZ', 'GB', 'US'];

export function CancellationModalSettingsRuleInputs({ index, isLoading, handleDelete }: Props) {
  return (
    <ConnectForm>
      {({ control, getValues }) => {
        return (
          <Box sx={{ my: 2 }}>
            <Typography variant="h3" fontSize="1.6rem">
              Rule {index + 1}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* Offer types multi select */}
              <Grid item xs={4}>
                <Controller
                  name={`rules.${index}.offerTypes`}
                  control={control}
                  rules={{ required: 'Please specify at least 1 offer type' }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        label="Offer types"
                        error={!!error}
                        helperText={error?.message ?? ''}
                        value={getValues(`rules.${index}.offerTypes`) || []}
                        fullWidth
                        select
                        disabled={isLoading}
                        required
                        SelectProps={{
                          multiple: true,
                        }}
                      >
                        {OFFER_TYPES.map((offerType) => {
                          return (
                            <MenuItem key={offerType} value={offerType}>
                              {offerType}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    );
                  }}
                />
              </Grid>

              {/* Regions multi-select */}
              <Grid item xs={4}>
                <Controller
                  name={`rules.${index}.regions`}
                  control={control}
                  rules={{ required: 'Please specify at least 1 region' }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        label="Regions*"
                        error={!!error}
                        helperText={error?.message ?? ''}
                        value={getValues(`rules.${index}.regions`) || []}
                        fullWidth
                        select
                        disabled={isLoading}
                        SelectProps={{
                          multiple: true,

                          // force max height (and make it scroll) rather than rendering a potentially
                          // huge list of regions
                          MenuProps: { sx: { maxHeight: 400 } },
                        }}
                      >
                        {SUPPORTED_REGIONS.map((regionCode) => {
                          return (
                            <MenuItem key={regionCode} value={regionCode}>
                              {regionCode}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    );
                  }}
                />
              </Grid>

              {/* Order total value field */}
              <Grid item xs={2}>
                <Controller
                  name={`rules.${index}.orderTotalValue`}
                  control={control}
                  rules={{ required: 'Please specify the minimum order value' }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message ?? ''}
                        type="number"
                        fullWidth
                        value={getValues(`rules.${index}.orderTotalValue`)}
                        disabled={isLoading}
                        label="Min order total value (AUD)"
                        required
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Days until cancellation expiry */}
              <Grid item xs={2}>
                <Controller
                  name={`rules.${index}.daysUntilCancellationExpiry`}
                  control={control}
                  rules={{ required: 'Please specify the number of days before the cancellation period expires' }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message ?? ''}
                        type="number"
                        fullWidth
                        value={getValues(`rules.${index}.daysUntilCancellationExpiry`)}
                        disabled={isLoading}
                        label="Days until cancellation expires"
                        required
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Start time */}
              <Grid item xs={2}>
                <Controller
                  name={`rules.${index}.startTime`}
                  control={control}
                  rules={{ required: 'Please specify a start time' }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TimePicker
                        {...field}
                        timezone="Australia/Sydney"
                        disabled={isLoading}
                        ampm={false} // use 24h format
                        label="Start time"
                        value={getValues(`rules.${index}.startTime`)}
                        slotProps={{
                          textField: {
                            helperText: error?.message ?? '',
                            error: !!error,
                            required: true,
                          },
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              {/* End time */}
              <Grid item xs={3}>
                <Controller
                  name={`rules.${index}.endTime`}
                  control={control}
                  rules={{ required: 'Please specify a end time' }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TimePicker
                        {...field}
                        timezone="Australia/Sydney"
                        disabled={isLoading}
                        ampm={false} // use 24h format
                        label="End time"
                        value={getValues(`rules.${index}.endTime`)}
                        slotProps={{
                          textField: {
                            helperText: error?.message ?? '',
                            error: !!error,
                            required: true,
                          },
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Delete button */}
              <Grid item sm={12} sx={{ my: 1 }}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    startIcon={<Delete />}
                    variant="contained"
                    disabled={isLoading}
                    onClick={() => handleDelete(index)}
                    color="error"
                  >
                    Delete Rule {index + 1}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </ConnectForm>
  );
}

export default CancellationModalSettingsRuleInputs;
