import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import { createLuxMomentsPage, getLuxMomentByOfferId } from '~/services/LuxMomentsService';

interface Props {
  offer: App.Offer;
}

const LuxMomentsPane = ({ offer }: Props) => {
  const [luxMomentsPage, setLuxMomentsPage] = useState(null);

  const onCreateLuMomentsPageClick = () => {
    createLuxMomentsPage({
      name: offer.name,
      id_salesforce_external: offer.id_salesforce_external,
      locations: offer.locations,
    }).then((res) => {
      setLuxMomentsPage(res.result?.[0].fields);
      window.open(res.result?.[0].contentfulUrl, '_blank', 'noreferrer');
    });
  };

  useEffect(() => {
    getLuxMomentByOfferId(offer.id_salesforce_external)
      .then((res) => {
        setLuxMomentsPage(res.result?.[0].fields);
      })
      .catch(() => {
        setLuxMomentsPage(null);
      });
  }, [offer]);

  return (
    <div>
      {luxMomentsPage ? (
        <Link to={`/edit-offers/${offer.id_salesforce_external}/lux-moments`}>Edit Lux Moments Page</Link>
      ) : (
        <Stack width="20%" spacing={2}>
          <Typography variant="subtitle1">No Lux Moments Page.</Typography>
          <Button onClick={onCreateLuMomentsPageClick} variant="contained">
            Create Lux Moments Page
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default LuxMomentsPane;
