import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { currencyFormatter } from '~/components/Experiences/helpers';

interface Props {
  businessCreditItems: App.BusinessCreditItem[];
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'total',
    headerName: 'Total',
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => currencyFormatter(params.row.currency_code, params.row.total, 2),
    display: 'flex',
  },
  {
    field: 'type',
    headerName: 'Type',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'currency_code',
    headerName: 'Currency Code',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

export default function OrderDetailBusinessCredits(props: Props) {
  const { businessCreditItems } = props;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Business Credits</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          columns={columns}
          rows={businessCreditItems}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </AccordionDetails>
    </Accordion>
  );
}
