import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { getExperienceOfferById } from '~/services/ExperiencesService';

import getDateDayDiff from '~/utils/getDateDayDiff';

import BaseOfferBox from './BaseOfferBox';

interface Props {
  bk: string;
  index: number;
  onRemove: () => void;
}

function ExperienceOfferBox({ bk, index, onRemove }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: App.State) => state.tenant);
  const [offer, setOffer] = useState<Awaited<ReturnType<typeof getExperienceOfferById>>['result']>();

  useEffect(() => {
    getExperienceOfferById({ id: bk, brand: tenant.brand })
      .then((res) => {
        setOffer(res.result);
      })
      .catch((err) => {
        if (err.status === 404) {
          onRemove && onRemove();
          return;
        }
        enqueueSnackbar(`Experience ${bk} could not be found`, { variant: 'error' });
      });
  }, [bk, tenant.brand]);

  if (!offer) {
    return <BaseOfferBox tenant={tenant} index={index} />;
  }

  return (
    <BaseOfferBox
      tenant={tenant}
      title={offer.title}
      imageId={offer.images[0]?.id}
      path={`/experience/${offer.slug}/${bk}`}
      vertical="experience"
      index={index}
      remainingDays={getDateDayDiff(offer.scheduledEndDate)}
    />
  );
}

export default ExperienceOfferBox;
