import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import CopyableField from '~/components/Common/CopyableField';
import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { formatDateWeekDayShortMonth } from '~/services/TimeService';

export default function descriptionRenderer(
  params: GridRenderCellParams<
    App.PromoData & { addedBy: App.UserSummary | null; updatedBy: App.UserSummary | null; showErrors: boolean },
    string
  >,
) {
  return (
    <Box
      component="dl"
      sx={{
        display: 'grid',
        gridTemplateColumns: '120px 1fr',
        rowGap: 0.5,
        columnGap: 1,
      }}
      title={`Promo ID: ${params.row.id_promo_code}`}
    >
      <DefinitionTitle>
        Code name: <CopyableField value={params.row.code_name} type="span" label=" " />
      </DefinitionTitle>
      <DefinitionText>
        <Link to={`/promos/code/${encodeURIComponent(params.row.code_name)}`} target="_blank" rel="noreferrer">
          {params.row.code_name}
        </Link>
      </DefinitionText>
      <DefinitionTitle>Brand:</DefinitionTitle>
      <DefinitionText>{params.row.brand}</DefinitionText>
      <DefinitionTitle>Type:</DefinitionTitle>
      <DefinitionText>{params.row.promo_type === 'percentage' ? 'Percentage' : 'Fixed'}</DefinitionText>
      <DefinitionTitle>Created at:</DefinitionTitle>
      <DefinitionText>{formatDateWeekDayShortMonth(params.row.created_at)}</DefinitionText>
      <DefinitionTitle>
        Created by: <CopyableField noWrap value={params.row.added_by} label=" " />
      </DefinitionTitle>
      <Typography title={params.row.addedBy?.email} noWrap>
        <Link to={`/users/${params.row.added_by}`} target="_blank" rel="noreferrer">
          {params.row.addedBy
            ? `${params.row.addedBy?.full_name}`
            : params.row.showErrors && (
                <ErrorDisplay
                  severity="warning"
                  message={`Could not get added by user from /users/${params.row.addedBy}`}
                />
              )}
        </Link>
      </Typography>
      <DefinitionTitle>Updated at:</DefinitionTitle>
      <DefinitionText>{formatDateWeekDayShortMonth(params.row.updated_at)}</DefinitionText>
      {params.row.modified_by && (
        <>
          <DefinitionTitle>
            Updated by: <CopyableField value={params.row.modified_by} label=" " />
          </DefinitionTitle>
          <Typography title={params.row.updatedBy?.email} noWrap>
            <Link to={`/users/${params.row.modified_by}`} target="_blank" rel="noreferrer">
              {params.row.updatedBy ? (
                <>{params.row.updatedBy.full_name}</>
              ) : (
                params.row.showErrors && (
                  <ErrorDisplay
                    severity="warning"
                    message={`Could not get updated by user from /users/${params.row.modified_by}`}
                  />
                )
              )}
            </Link>
          </Typography>
        </>
      )}
      <DefinitionTitle>Description:</DefinitionTitle>
      <DefinitionText>{params.row.description}</DefinitionText>
      {params.row.cloned_from_promo_id && <DefinitionTitle>Child of:</DefinitionTitle>}
      {params.row.cloned_from_promo_id && (
        <DefinitionText>
          <Link to={`/promos/${params.row.cloned_from_promo_id}`} target="_blank" rel="noreferrer">
            <Typography title={'The promo this promo was cloned from'} color="primary" fontWeight="bold" noWrap>
              {params.row.cloned_from_promo_id}
            </Typography>
          </Link>
        </DefinitionText>
      )}
      <DefinitionTitle>Tags: </DefinitionTitle>
      <DefinitionText>
        {`${params.row.dept_tag} / ${params.row.category_tag} / ${params.row.sub_category_tag}`}
      </DefinitionText>
      <DefinitionTitle>
        promoId <CopyableField value={params.row.id_promo_code} label=" " />
      </DefinitionTitle>
      <DefinitionText>{params.row.id_promo_code}</DefinitionText>
    </Box>
  );
}
