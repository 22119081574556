import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Typography } from '@mui/material';

import VendorsService from '~/services/VendorsService';

const UploadRevelexPayments = () => {
  const [file, setFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const onChange = async (event) => {
    setFile(event.target.files[0]);
  };

  const fileUpload = async (file) => {
    const formData = new FormData();
    formData.append('revelex-payments', file);
    return VendorsService.uploadRevelexPayments(formData);
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await fileUpload(file);

      enqueueSnackbar(`File upload successful. Total: ${result.total}`, {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(`File upload failed. Error: ${err.message}`, {
        variant: 'error',
      });
    }
  };

  return (
    <div>
      <Typography variant="body1">
        Revelex Payments will not be reflected in any reports until the following day.
      </Typography>

      <form onSubmit={onFormSubmit}>
        <Typography variant="h6" mt={2} mb={1}>
          File Upload
        </Typography>
        <input
          type="file"
          onChange={onChange}
          accept=".csv, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <Button sx={{ marginTop: '24px' }} type="submit" variant="contained">
          Upload
        </Button>
      </form>
    </div>
  );
};

export default UploadRevelexPayments;
