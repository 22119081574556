import React, { useCallback } from 'react';

import { Grid, TextField } from '@mui/material';

import DateWidget from '~/components/Common/Elements/DateWidget';

import { AtolForm } from './AtolPage';

type Props = {
  atolData: AtolForm;
  onUpdate: (field: string, value: string) => void;
};

function AirlineDetailsForm(props: Props) {
  const { atolData, onUpdate } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      onUpdate(name, value);
    },
    [onUpdate],
  );

  return (
    <Grid container item spacing={2} sm={12}>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="Origin Airport Code"
          name="originAirport"
          value={atolData.originAirport}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Origin Airport Name"
          name="originAirportName"
          value={atolData.originAirportName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          label="Destination Airport Code"
          name="destinationAirport"
          value={atolData.destinationAirport}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Destination Airport Name"
          name="destinationAirportName"
          value={atolData.destinationAirportName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateWidget
          value={atolData.departureDate}
          onChange={(date) => onUpdate('departureDate', date)}
          label="Departure Date"
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateWidget
          value={atolData.returnDate}
          onChange={(date) => onUpdate('returnDate', date)}
          label="Return Date"
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  );
}

export default AirlineDetailsForm;
