import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';

import {
  customerDataFormatter,
  orderStatusFormatter,
  orderTotalFormatter,
  packageInformationFormatter,
  packagesCheckInFormatter,
  packagesOrderLinkFormatter,
} from './formatters';

interface Props {
  purchases: Array<API.Order.Order>;
  total: number;
  page: number;
  sizePerPage: number;
  onPageChange: (page: number) => void;
}

const columns: Array<GridColDef> = [
  {
    field: 'fk_customer_id',
    headerName: 'Customer',
    sortable: false,
    flex: 1,
    renderCell: customerDataFormatter,
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Order Status',
    sortable: false,
    flex: 1,
    renderCell: orderStatusFormatter,
    display: 'flex',
  },
  {
    field: 'check_in',
    headerName: 'Check In / Departure',
    sortable: false,
    flex: 1,
    renderCell: packagesCheckInFormatter,
    display: 'flex',
  },
  {
    field: 'package_information',
    headerName: 'Package Information',
    sortable: false,
    flex: 1,
    renderCell: packageInformationFormatter,
    display: 'flex',
  },
  { field: 'currency_code', headerName: 'Currency', sortable: false, display: 'flex' },
  {
    field: 'total',
    headerName: 'Order Total',
    sortable: false,
    width: 120,
    renderCell: orderTotalFormatter,
    display: 'flex',
  },
  {
    field: 'id_orders',
    headerName: 'View Order',
    sortable: false,
    width: 120,
    renderCell: packagesOrderLinkFormatter,
    display: 'flex',
  },
];

function PackagesPage(props: Props) {
  return (
    <DataGrid
      rows={props.purchases}
      rowCount={props.total}
      columns={columns}
      pagination
      paginationMode="server"
      paginationModel={{
        page: props.page - 1,
        pageSize: props.sizePerPage,
      }}
      pageSizeOptions={[props.sizePerPage]}
      classes={{
        root: 'T-packages-container',
        virtualScrollerContent: 'T-packages-table',
      }}
      slots={{ pagination: GridPagination }}
      onPaginationModelChange={({ page }) => props.onPageChange(page + 1)}
      getRowHeight={() => 'auto'}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}

export default PackagesPage;

export { PackagesPage as PackagesPageComponent };
