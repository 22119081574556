import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { FDATable, getFDATables } from '~/services/FlightsService';

import FDATableContainer from './FDATableContainer';

export default function FDAPage() {
  const [tables, setTables] = useState<FDATable[]>([]);
  const [selectedTable, setSelectedTable] = useState<string | undefined>();
  const [loadingTables, setLoadingTables] = useState(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const fetchTables = async () => {
      setLoadingTables(true);

      try {
        const data = await getFDATables();
        setTables(data.result);
        setSelectedTable(data.result[0]?.name);
      } catch (error) {
        setError(error.message);
      }

      setLoadingTables(false);
    };

    fetchTables();
  }, []);

  if (loadingTables) {
    return <Typography variant="h4">Loading...</Typography>;
  }

  if (error) {
    return <div className="alert alert-danger">Failed to fetch tables: {error}</div>;
  }

  return (
    <Box flexDirection="column" display="flex" gap={4}>
      {selectedTable && (
        <FDATableContainer selectedTable={selectedTable} tables={tables} onTableChange={setSelectedTable} />
      )}
    </Box>
  );
}
