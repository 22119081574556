import React, { Fragment } from 'react';

import currencyFormatter from 'currency-formatter';

import { Stack, Typography } from '@mui/material';

import BookingCancelledToggle from '~/components/Refund/BookingCancelledToggle';
import VccBalanceUpdateToggle from '~/components/Refund/VccBalanceUpdateToggle';

import { Refund } from './NewModal';

type Props = {
  handleFlagChange: () => void;
  tmpValue: (key) => number;
  refund: Refund;
};

function PartialCalculatedRefundVariant({ refund, tmpValue, handleFlagChange }: Props) {
  const amount = currencyFormatter.format(tmpValue('amount'), {
    code: refund.currency_code,
    precision: 2,
  });
  return (
    <Stack id="PartialCalculatedRefundVariant" direction="column" spacing={2}>
      <Stack direction="row">
        <Typography pr={1}>Refund Amount to Customer:</Typography>
        <Typography component="span" fontWeight="bold" data-cy="refund-amount-to-customer">
          {amount}
        </Typography>
      </Stack>
      <Stack direction="column">
        <Typography pr={2}>Refund Policies:</Typography>
        <ul>
          {refund.refund_policies.map((policy) => (
            <li key={policy}>
              <Typography variant="subtitle1">{policy}</Typography>
            </li>
          ))}
        </ul>
      </Stack>
      {refund.is_subscription && (
        <Stack direction="row">
          <Typography pr={1}>Pro rata refund for the remainder of the annual subscription:</Typography>
          <Typography component="span" fontWeight="bold">
            {currencyFormatter.format(refund.pro_rata_subscription_refund_amount, {
              code: refund.currency_code,
              precision: 2,
            })}
          </Typography>
        </Stack>
      )}
      {!refund.is_subscription && (
        <>
          <BookingCancelledToggle handleFlagChange={handleFlagChange} mark_cancelled={refund.mark_cancelled} />
          <VccBalanceUpdateToggle handleFlagChange={handleFlagChange} update_vcc_balance={refund.update_vcc_balance} />
        </>
      )}
    </Stack>
  );
}

export default PartialCalculatedRefundVariant;
