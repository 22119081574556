import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import AIGeneratorButton from '~/components/AIGenerator/AIGenerateButton';
import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';
import { useCruiseOfferById } from '~/components/Cruises/context/useCruiseOfferById';

import { TAG_TYPE_CAMPAIGN } from '~/consts/tags';

import OffersService from '~/services/OffersService';
import { customerPortalHost } from '~/services/common';
import cruiseOfferService from '~/services/cruises/OfferService';

import CruiseOfferImages from './CruiseOfferImages';
import DeparturesDetails from './DeparturesDetails';
import { getDepartures } from './mappers';

const OFFER = Object.freeze({
  status: [
    { value: 'DRAFT', name: 'Draft' },
    { value: 'APPROVED', name: 'Content-Approved' },
  ],
});

interface Props {
  offerId: string;
  tenant: App.Tenant;
}

const CruiseOfferDetails = (props: Props) => {
  const { offerId, tenant } = props;
  const { offer } = useCruiseOfferById();

  const [leadDepartureId, setLeadDepartureId] = useState<string | null>(null);
  const [wasCurated, setCurated] = useState<boolean>(null);
  const [leExclusive, setLeExclusive] = useState<boolean>(false);
  const [disableDeposits, setDisableDeposits] = useState<boolean>(false);
  const [showPricePerNight, setShowPricePerNight] = useState<boolean>(false);
  const [luxPremiumCollection, setLuxPremiumCollection] = useState<boolean>(false);
  const [offerStatus, setOfferStatus] = useState<API.OfferStatus>('DRAFT');
  const [heroImageId, setHeroImageId] = useState<string>(null);
  const [editableSailingName, setEditableSailingName] = useState<string>(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [editableSailingDescription, setEditableSailingDescription] = useState<string>(null);
  const [editableSlug, setEditableSlug] = useState<string>(null);

  const onCheckExclusive = useCallback((e) => {
    setLeExclusive(e.target.checked);
  }, []);

  const onDisableDeposits = useCallback((e) => {
    setDisableDeposits(e.target.checked);
  }, []);

  const onShowPricePerNight = useCallback((e) => {
    setShowPricePerNight(e.target.checked);
  }, []);
  const onCheckLuxPremiumCollection = useCallback((e) => {
    setLuxPremiumCollection(e.target.checked);
  }, []);

  const handleEditableSailingName = useCallback((e) => {
    setEditableSailingName(e.target.value);
  }, []);

  const handleEditableSailingDescription = useCallback((e) => {
    setEditableSailingDescription(e.target.value);
  }, []);

  const handleEditableSlug = useCallback((e) => {
    setEditableSlug(e.target.value);
  }, []);

  const handleSelectCampaigns = useCallback((e) => {
    setSelectedCampaigns(e.target.value);
  }, []);

  const onSubmit = async (): Promise<void> => {
    setCurated(false);

    if (!editableSailingName && !offer.displayName) {
      return;
    }

    const res = await cruiseOfferService.setCurationById(offer.id, {
      leadDepartureId,
      slug: editableSlug ?? offer.slug,
      status: offerStatus ?? offer.status,
      leExclusive: leExclusive ?? offer.leExclusive,
      luxPremiumCollection: luxPremiumCollection ?? offer.luxPremiumCollection,
      disableDeposits: disableDeposits ?? offer.disableDeposits,
      showPricePerNight: showPricePerNight ?? offer.showPricePerNight,
      editableSailingName: editableSailingName ?? offer.displayName,
      heroImageId: heroImageId ?? offer.heroImageId,
      editableSailingDescription: editableSailingDescription ?? offer.leVisibleDescription,
      campaigns: selectedCampaigns ?? offer.campaigns,
    });

    if (res && res.status === 200) {
      setCurated(true);
    }
  };

  const onHandleLeadDeparture = useCallback((departureId: string | null) => setLeadDepartureId(departureId), []);

  const cruiseItinerary = useMemo(
    () =>
      offer?.itineraries?.map((itinerary) => `Day ${itinerary.dayNumber}: ` + itinerary?.description).join(' ') ?? '',
    [offer?.itineraries],
  );

  const headlineAIContext = useMemo(
    () => ({
      model: 'cruise',
      field: 'headline',
      itinerary: cruiseItinerary,
      cruiseName: offer?.name,
    }),
    [offer?.name, cruiseItinerary],
  );

  const descriptionAIContext = useMemo(
    () => ({
      model: 'cruise',
      field: 'description',
      itinerary: cruiseItinerary,
      duration: offer?.duration,
      cruiseName: offer?.name,
    }),
    [cruiseItinerary, offer?.duration, offer?.name],
  );

  const slugAIContext = useMemo(
    () => ({
      model: 'cruise',
      field: 'slug',
      slug: offer?.slug,
      ship: offer?.ship?.name,
      duration: offer?.duration,
      itinerary: cruiseItinerary,
      departurePort: offer?.departurePort?.name,
      cruiseName: offer?.displayName ?? offer?.name,
    }),
    [offer, cruiseItinerary],
  );

  const departures = useMemo(() => (offer ? getDepartures(offer) : []), [offer]);

  useEffect(() => {
    if (offer?.status) setOfferStatus(offer?.status);
    if (offer?.heroImageId) setHeroImageId(offer?.heroImageId);
    if (offer?.campaigns) setSelectedCampaigns(offer?.campaigns);
    setLeadDepartureId(offer?.leadDepartureId);

    OffersService.getTags(TAG_TYPE_CAMPAIGN).then((campaignsRes) => {
      setCampaigns(campaignsRes.result);
    });
  }, [offer]);

  return (
    <Container maxWidth="xl">
      <PageHeader title="Cruises / Sailing Overview">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Preview on:</Typography>
          <Button
            variant="text"
            target="_blank"
            rel="noreferrer"
            href={`${customerPortalHost(tenant)}/cruises/${offerId}`}
          >
            {tenant.title}
          </Button>
        </Stack>
      </PageHeader>

      {!offer && <Spinner size={48} />}

      {offer && (
        <form>
          <Stack mt={4} spacing={2} direction="row">
            <TextField disabled fullWidth label="Source" defaultValue="Revelex" InputProps={{ readOnly: true }} />
            <TextField
              disabled
              fullWidth
              label="Cruise Line Brand"
              InputProps={{ readOnly: true }}
              defaultValue={offer.cruiseLine.name}
            />
            <TextField
              disabled
              fullWidth
              label="Offer Name"
              defaultValue={offer.name}
              InputProps={{ readOnly: true }}
            />
          </Stack>

          <Stack mt={3} spacing={2} direction="row">
            <TextField
              disabled
              fullWidth
              label="Price"
              InputProps={{ readOnly: true }}
              defaultValue={offer.lowestDeparturePrice?.total ?? '-'}
            />
            <TextField
              disabled
              fullWidth
              label="Brochure Price"
              InputProps={{ readOnly: true }}
              defaultValue={offer.lowestDeparturePrice?.brochurePrice ?? '-'}
            />
            <TextField
              disabled
              fullWidth
              label="Discount"
              InputProps={{ readOnly: true }}
              value={`${offer.lowestDeparturePrice?.brochureDiscountPercentage ?? 0}%`}
            />
            <TextField
              disabled
              fullWidth
              label="Discount Price"
              InputProps={{ readOnly: true }}
              defaultValue={offer.lowestDeparturePrice?.brochureDiscountPrice ?? '-'}
            />
          </Stack>

          <Stack mt={3} spacing={2} direction="row">
            <TextField
              rows={4}
              disabled
              fullWidth
              multiline
              label="Sailing Description"
              sx={{ '> div textarea[readonly]': { pointerEvents: 'auto' } }}
              InputProps={{ readOnly: true }}
              defaultValue={
                offer.ship.shipInfo?.find((info) => info.dataTypeName === 'Summary')?.description ??
                offer.destinationDescription
              }
            />
          </Stack>

          <Stack mt={3} spacing={2} direction="row">
            <TextField
              select
              fullWidth
              label="Status"
              value={offerStatus}
              onChange={({ target }) => setOfferStatus(target.value as API.OfferStatus)}
            >
              {OFFER.status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <Stack mt={3} spacing={2} direction="column">
            <TextField
              fullWidth
              label="Slug"
              value={editableSlug}
              defaultValue={offer.slug}
              onChange={handleEditableSlug}
            />
            {offer && (
              <AIGeneratorButton
                context={slugAIContext}
                content={slugAIContext.slug}
                onChoose={(value) => setEditableSlug(value)}
                buttonLabel={headlineAIContext.cruiseName ? 'Rewrite slug' : 'Generate slug'}
              />
            )}
          </Stack>

          <Stack mt={3} spacing={2} direction="column">
            <TextField
              required
              fullWidth
              label="Display Name"
              value={editableSailingName}
              defaultValue={offer.displayName}
              onChange={handleEditableSailingName}
              error={!editableSailingName && !offer.displayName}
              helperText="This field is mandatory"
            />
            {offer && (
              <AIGeneratorButton
                context={headlineAIContext}
                content={headlineAIContext.cruiseName}
                buttonLabel={headlineAIContext.cruiseName ? 'Rewrite title' : 'Generate title'}
                onChoose={(value) => setEditableSailingName(value)}
              />
            )}
          </Stack>

          <Stack mt={3} spacing={2} direction="column">
            <TextField
              rows={4}
              multiline
              fullWidth
              label="LE Sailing Description"
              value={editableSailingDescription}
              defaultValue={offer.leVisibleDescription}
              onChange={handleEditableSailingDescription}
            />
            {offer && (
              <AIGeneratorButton
                context={descriptionAIContext}
                content={descriptionAIContext.itinerary}
                buttonLabel={descriptionAIContext.itinerary ? 'Rewrite description' : 'Generate description'}
                onChoose={(value) => setEditableSailingDescription(value)}
              />
            )}
          </Stack>

          <Stack mt={3} direction="column">
            <FormLabel id="promo-banner-type-filter">Campaign tags</FormLabel>
            <Select multiple fullWidth value={selectedCampaigns} onChange={handleSelectCampaigns}>
              {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.name}>
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <Stack mt={3} direction="column">
            <CruiseOfferImages offer={offer} heroImageId={heroImageId} setHeroImageId={setHeroImageId} />
          </Stack>

          <Box mt={4} sx={{ '.alert-lead-departure': { margin: '12px 0' } }}>
            <FormLabel id="select-departure-lead">Select Departure Lead</FormLabel>
            <Alert className="alert-lead-departure" severity="info">
              <strong>NOTE:</strong> The tile on CP will be displayed with the selected departure. Remember to mark only
              one departure as lead and save the changes.
            </Alert>
            <DeparturesDetails
              departures={departures}
              leadDepartureId={leadDepartureId}
              onHandleDeparture={onHandleLeadDeparture}
            />
          </Box>

          <Box mt={2}>
            <FormGroup row>
              <FormControlLabel
                label="LE Exclusive"
                control={
                  <Checkbox
                    value={leExclusive}
                    onChange={onCheckExclusive}
                    defaultChecked={offer.leExclusive}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />

              <FormControlLabel
                label="Disable deposits"
                control={
                  <Checkbox
                    value={disableDeposits}
                    onChange={onDisableDeposits}
                    defaultChecked={offer.disableDeposits}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />

              <FormControlLabel
                label="Show price per night"
                control={
                  <Checkbox
                    value={showPricePerNight}
                    onChange={onShowPricePerNight}
                    defaultChecked={offer.showPricePerNight}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />

              <FormControlLabel
                label="Lux Premium Collection"
                control={
                  <Checkbox
                    value={luxPremiumCollection}
                    onChange={onCheckLuxPremiumCollection}
                    defaultChecked={offer.luxPremiumCollection}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </FormGroup>
          </Box>

          <Box mt={2}>
            <Button variant="contained" onClick={onSubmit}>
              Save Changes
            </Button>
          </Box>

          {wasCurated && <Alert severity="success">CRUISE OFFER CURATED WITH SUCCESS</Alert>}
        </form>
      )}
    </Container>
  );
};

export default CruiseOfferDetails;
