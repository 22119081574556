import React from 'react';

import { theme } from '~/theme';

import { TableBody, TableCell, TableRow } from '@mui/material';

import { getCancellationPolicyLabel } from '~/utils/cancellationPolicy';

import { PackageRoomRate } from './types';

export function RoomRatesTable({ packageRoomRates }: { packageRoomRates: PackageRoomRate[] }) {
  return (
    <TableBody>
      {packageRoomRates.map(({ pkg, roomRateList }) => {
        return (
          <>
            <TableRow
              sx={{
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <TableCell sx={{ typography: 'h6' }}>{pkg.deal_option_name}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            {roomRateList.map((roomRate) => {
              return (
                <TableRow key={roomRate.id}>
                  <TableCell />
                  <TableCell sx={{ typography: 'body1' }} align="right">
                    {roomRate?.rate_plan.name ?? 'None'}
                  </TableCell>
                  <TableCell sx={{ typography: 'body1' }} align="right">
                    {getCancellationPolicyLabel(roomRate?.rate_plan.cancellation_policy) ?? 'None'}
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        );
      })}
    </TableBody>
  );
}
