import React, { useCallback, useEffect, useState } from 'react';

import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import Spinner from '../../Common/Spinner';

const containerStyle = {
  width: '100%',
  height: '600px',
};

// Sydney
const initialCenter = {
  lat: -33.868,
  lng: 151.209,
};

const initialZoom = 10;
const options = {};

function SearchMap({ areaMapItems, pointMapItems }) {
  const [map, setMap] = useState<google.maps.Map>(null);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState(initialCenter);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: window.configs.GOOGLE_PLACE_API_KEY,
  });

  // Map Area Items
  useEffect(() => {
    if (!map) {
      return;
    }

    if (areaMapItems.length === 0) {
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      setCenter(initialCenter);
      return;
    }

    const lastAdded = areaMapItems[areaMapItems.length - 1];
    map.data.addGeoJson({
      type: 'FeatureCollection',
      features: [{ type: 'Feature', geometry: lastAdded.geom }],
    });

    setCenter({
      lng: lastAdded.centroid.coordinates[0],
      lat: lastAdded.centroid.coordinates[1],
    });
  }, [areaMapItems, map]);

  // Map Point Items
  useEffect(() => {
    if (pointMapItems.length === 0) {
      setCenter(initialCenter);
      setMarkers([]);
      return;
    }
    const newMarkers = pointMapItems.map((item) => {
      return {
        lng: item.geom.coordinates[0],
        lat: item.geom.coordinates[1],
        id: item.id,
        title: item.name,
      };
    });
    setMarkers(newMarkers);

    const lastAdded = pointMapItems[pointMapItems.length - 1];
    setCenter({
      lng: lastAdded.geom.coordinates[0],
      lat: lastAdded.geom.coordinates[1],
    });
  }, [pointMapItems]);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  return (
    <div>
      {!loadError && !isLoaded && <Spinner />}
      {loadError && <div>Error loading Google Maps</div>}
      {!loadError && isLoaded && (
        <GoogleMap
          center={center}
          options={options}
          zoom={initialZoom}
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
        >
          {markers.map((marker) => (
            <Marker
              position={{
                lat: marker.lat,
                lng: marker.lng,
              }}
              title={marker.title}
              key={marker.id}
            />
          ))}
        </GoogleMap>
      )}
    </div>
  );
}

export default SearchMap;
