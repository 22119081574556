import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { LinearProgress, Link, Paper } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import { userLinkFormatter } from '~/components/Purchases/Home/formatters';

import { getDatesRequests, getOrdersByIds } from '~/services/OrdersService';
import { formatDateSlashes, formatDateSlashesWithClock } from '~/services/TimeService';

import ExpandableText from './ExpandableText';

interface DateChangeRequestData {
  dateChangeRequests: Array<DateChangeRequest>;
  total: number;
}

export default function DateChangeOutsidePolicy() {
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [dateChangeRequestData, setDateChangeRequestData] = useState<DateChangeRequestData>({
    dateChangeRequests: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const fetchDateChangeRequests = useCallback(
    async (currentPage, perPage) => {
      setLoading(true);
      try {
        const response = await getDatesRequests(currentPage + 1, perPage);
        const dateChangeRequests = response.result;

        const orderIds = dateChangeRequests.map((dateChangeRequest) => dateChangeRequest.fk_order_id);

        // Fetch orders data
        const orders = await getOrdersByIds(orderIds, brand);

        // Attach relevant order data to date change requests
        const updatedDateChangeRequests = dateChangeRequests.map((dateChangeRequest) => {
          const matchedOrder = orders.find((order) => order.id === dateChangeRequest.fk_order_id);
          const matchedOrderItem = matchedOrder?.items.find((item) => item.id === dateChangeRequest.fk_item_id);

          const check_in_date = matchedOrderItem?.reservation?.check_in || null;
          const customer_name = matchedOrder?.customer_full_name || 'N/A';

          return {
            ...dateChangeRequest,
            customer_name,
            check_in_date,
          };
        });

        setDateChangeRequestData({
          dateChangeRequests: updatedDateChangeRequests,
          total: response.total,
        });
      } catch (error) {
        enqueueSnackbar('Failed to fetch dates requests.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [brand, enqueueSnackbar],
  );

  useEffect(() => {
    fetchDateChangeRequests(paginationModel.page, paginationModel.pageSize);
  }, [fetchDateChangeRequests, paginationModel.page, paginationModel.pageSize]);

  const vendorPortalLink = useCallback((vendorId: string) => {
    return `${window.configs.VENDOR_PORTAL}/${vendorId}/dates-requests`;
  }, []);

  const ordersPageLink = useCallback((orderId: string) => {
    return `/purchases/${orderId}`;
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'id_dates_request',
        headerName: 'Refund Request ID',
        width: 100,
        renderCell: (params) => {
          const vendorId = params.row.vendor_salesforce_id;
          return (
            <Link href={vendorPortalLink(vendorId)} target="_blank" rel="noopener" title={params.value} noWrap>
              {params.value}
            </Link>
          );
        },
      },
      {
        field: 'customer_name',
        headerName: 'Customer',
        width: 150,
        renderCell: userLinkFormatter,
      },
      {
        field: 'fk_order_id',
        headerName: 'Order ID',
        width: 100,
        renderCell: (params) => {
          const orderId = params.value;
          return (
            <Link href={ordersPageLink(orderId)} target="_blank" rel="noopener" title={params.value} noWrap>
              {params.value}
            </Link>
          );
        },
      },
      { field: 'status', headerName: 'Status', width: 100 },
      {
        field: 'reason_type',
        headerName: 'Reason Classification',
        width: 300,
      },
      {
        field: 'reason',
        headerName: 'Reason',
        width: 300,
        renderCell: (params) => <ExpandableText value={params.value} />,
      },
      {
        field: 'created_at',
        headerName: 'Date of request',
        width: 150,
        valueFormatter: (value) => {
          return formatDateSlashesWithClock(value);
        },
      },
      {
        field: 'check_in_date',
        headerName: 'Check In',
        width: 150,
        valueFormatter: (value) => {
          return value ? formatDateSlashes(value) : 'N/A';
        },
      },
    ],
    [ordersPageLink, vendorPortalLink],
  );

  return (
    <Paper>
      <PageSubheader title="Date change outside policy" />
      {loading && <LinearProgress />}
      <DataGrid
        rows={dateChangeRequestData.dateChangeRequests}
        columns={columns}
        rowCount={dateChangeRequestData.total}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getRowId={(row) => row.id_dates_request}
        getRowHeight={() => 'auto'}
        autoHeight
        disableRowSelectionOnClick={loading}
      />
    </Paper>
  );
}
