import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Button, Card, CardContent, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import TourOptionDateForm from '~/components/Common/Forms/TourOptionDateForm';

class TourOptionDatesEdit extends Component {
  state = {
    addingMultipleDates: false,
  };

  openAddMultipleDates = () => {
    this.setState({
      addingMultipleDates: true,
    });
  };

  closeAddMultipleDates = () => {
    this.setState({
      addingMultipleDates: false,
    });
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>
            Vendors | {this.props.vendor?.name || this.props.vendorId} | {this.props.tourOptionName} | Tour Option Dates
          </title>
        </Helmet>

        <PageHeader
          title={this.props.tourOptionName}
          backButton={`/vendors/${this.props.vendorId}/tours/${this.props.tourId}`}
        />

        <div className="tour-option-dates-edit-section">
          <Box mb={2}>
            <Button variant="contained" onClick={this.openAddMultipleDates}>
              Apply multiple dates
            </Button>
          </Box>

          <Card variant="outlined">
            <CardContent>
              <TourOptionDateForm
                tourId={this.props.tourId}
                tourOptionId={this.props.tourOptionId}
                tourOptionDates={this.props.tourOptionDates}
                addingMultipleDates={this.state.addingMultipleDates}
                closeAddMultipleDates={this.closeAddMultipleDates}
              />
            </CardContent>
          </Card>
        </div>
      </Container>
    );
  }
}

export default TourOptionDatesEdit;
