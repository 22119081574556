import React from 'react';

import { Typography } from '@mui/material';

function SpeedNavigationKeyboardKey({ children }) {
  return (
    <Typography
      variant="overline"
      display="block"
      sx={{
        border: '1px solid rgba(0,0,0,0.3)',
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderRadius: '4px',
        maxHeight: '16px',
        lineHeight: 1,
        padding: '2px 3px',
        color: 'white',
        marginLeft: '4px',
        marginRight: '4px',
      }}
    >
      {children}
    </Typography>
  );
}

export default SpeedNavigationKeyboardKey;
