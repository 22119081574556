import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import { isBetween } from '~/services/TimeService';

export type LowestPriceByCabinCategories = {
  [cabinCategory: string]: { price: number; currencyCode: string };
};

const getLowestPriceByCabinCategories = (prices: API.Price[]): LowestPriceByCabinCategories => {
  const lowestPriceByCabinCategories = prices.reduce((acc, price) => {
    const priceValue = price.total;
    const cabinCategory = price.cabinCategory;
    if (!acc[cabinCategory] || acc[cabinCategory] > priceValue) {
      acc[cabinCategory] = { price: Math.round(priceValue), currencyCode: price.currencyCode };
    }
    return acc;
  }, {});

  return lowestPriceByCabinCategories;
};

const getActivePromotion = (promotions: API.Promotion[]): API.Promotion | null => {
  return (
    promotions.filter((promotion: API.Promotion) => {
      const { startDate, endDate } = promotion;
      return isBetween(startDate, endDate);
    })[0] ?? null
  );
};

export type Departure = {
  id: string;
  externalId: number;
  departureDate: string;
  activePromotion: API.Promotion | null;
  lowestPriceByCabinCategories: LowestPriceByCabinCategories;
};

export const getDepartures = (offer: API.Offer): Departure[] => {
  const departures = Object.entries(offer.departures ?? {}).flatMap((item) => item[1]) ?? [];
  return departures.map((departure) => ({
    id: departure.id,
    externalId: departure.externalId,
    departureDate: departure.departureDate,
    lowestPriceByCabinCategories: getLowestPriceByCabinCategories(departure.prices),
    activePromotion: departure.promotions?.length > 0 ? getActivePromotion(departure.promotions) : null,
  }));
};
