import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';

import { Box, Button, Container, TextField } from '@mui/material';

import DateWidget from '~/components/Common/Elements/DateWidget';
import PageHeader from '~/components/Common/Elements/PageHeader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getOrderDetail, updateOrder } from '~/services/OrdersService';

type OrderGift = {
  gift_message: string;
  giver_name: string;
  receiver_email: string;
  receiver_name: string;
  scheduled_date: string;
};

export default function OrderGiftEditPage() {
  const { id_orders: orderId } = useParams<{ id_orders: string }>();
  const history = useHistory();

  const [error, setError] = useState<Error>();
  const [gift, setGift] = useState<OrderGift>({
    gift_message: '',
    giver_name: '',
    receiver_email: '',
    receiver_name: '',
    scheduled_date: '',
  });
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [submitState, setSubmitState] = useState<Utils.ProcessingState>('idle');

  const isSubmitting = submitState === 'processing';

  useEffect(() => {
    setLoadingState('loading');
    getOrderDetail(orderId)
      .then((order) => {
        const { gift_message, giver_name, receiver_email, receiver_name, scheduled_date } = order.order_gift;
        setGift({ gift_message, giver_name, receiver_email, receiver_name, scheduled_date });
        setLoadingState('success');
      })
      .catch((err) => {
        setError(err);
        setLoadingState('failed');
      });
  }, [orderId]);

  const inputChange = useCallback((event) => {
    const target = event.target;
    setGift((gift) => ({
      ...gift,
      [target.name]: target.value,
    }));
  }, []);

  const updateGift = useCallback(
    async (event) => {
      event.preventDefault();

      setSubmitState('processing');

      const formData = Object.assign({ op: 'update_order_gift' }, gift);

      try {
        await updateOrder(orderId, formData);
        history.push(`/purchases/${orderId}`);
      } catch (error) {
        setError(error);
        setSubmitState('failed');
      }
    },
    [history, orderId, gift],
  );

  return (
    <Container maxWidth="xl">
      <PageHeader title="Update gift" backButton={`/purchases/${orderId}`} />

      {error && <ErrorDisplay message={error.message} />}

      {loadingState === 'loading' && <Spinner />}

      {loadingState === 'success' && (
        <form onSubmit={updateGift}>
          <Box display="grid" gap={2} gridTemplateColumns="repeat(5, 1fr)">
            <div>
              <DateWidget
                name="scheduled_date"
                label="Scheduled date"
                value={gift.scheduled_date ?? ''}
                onChange={(value) => inputChange({ target: { name: 'scheduled_date', value } })}
                slotProps={{ textField: { required: true, name: 'scheduled_date', fullWidth: true } }}
                disabled={isSubmitting}
                fullWidth
              />
            </div>

            <Box gridColumn="span 2">
              <TextField
                label="Recipient's name"
                name="receiver_name"
                onChange={inputChange}
                value={gift.receiver_name ?? ''}
                disabled={isSubmitting}
                fullWidth
                required
              />
            </Box>

            <Box gridColumn="span 2">
              <TextField
                label="Recipient's name"
                name="receiver_email"
                onChange={inputChange}
                value={gift.receiver_email ?? ''}
                disabled={isSubmitting}
                fullWidth
                required
              />
            </Box>

            <Box gridColumn="span 5">
              <TextField
                label="Message (optional)"
                name="gift_message"
                onChange={inputChange}
                value={gift.gift_message ?? ''}
                disabled={isSubmitting}
                rows={2}
                multiline
                fullWidth
              />
            </Box>
          </Box>

          <Box mt={2}>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Save
            </Button>
          </Box>
        </form>
      )}
    </Container>
  );
}
