import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Box, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { CUSTOM_OFFER_PDF, INVOICE, PUBLIC_ATTACHMENT } from '~/consts/notes';

import useCurrentUser from '~/hooks/useCurrentUser';

import { deleteOrderNote, getInvoiceUrl, getOrderNotes } from '~/services/OrdersService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { isAdmin } from '~/utils/adminPermission';

import addUserData from '../../../utils/addUserData';

function deleteRow(row, refreshData, user) {
  return async function () {
    if (!isAdmin(user)) {
      alert('Only admin users can delete');
      return;
    }
    if (window.confirm('Are you sure you wish to delete this comment?')) {
      try {
        await deleteOrderNote(row.fk_order_id, row.id_notes);
        refreshData();
      } catch (e) {
        alert(e.message);
      }
    }
  };
}

interface Props {
  orderId: string;
  refreshData: () => void;
  customer: App.User;
  offerId?: string;
}

export default function OrderDetailNotes({ orderId, refreshData, customer, offerId }: Props) {
  const { user } = useCurrentUser();

  const [result, setResult] = useState([]);
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [isExpanded, setExpanded] = useState(true);
  const [error, setError] = useState<string>('');

  let customOfferParams = '';
  if (offerId && customer?.id_member) {
    customOfferParams = `?offer_id=${offerId}&user_id=${customer.id_member}`;
  }

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'fk_added_by_id',
        headerName: 'User',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (_value, row) => row.fk_added_by_id?.fullName,
        display: 'flex',
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        disableColumnMenu: true,
        flex: 1,
        valueFormatter: (value) => formatDateOrdinalWithClock(value),
        display: 'flex',
      },
      {
        field: 'comment_type',
        headerName: 'Type',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: 'flex',
      },
      {
        field: 'comment',
        headerName: 'Comment',
        sortable: false,
        disableColumnMenu: true,
        flex: 3,
        display: 'flex',
      },
      {
        field: 'invoice',
        headerName: 'Attached PDF',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          if (
            params.row.comment_type === INVOICE ||
            params.row.comment_type === CUSTOM_OFFER_PDF ||
            params.row.comment_type === PUBLIC_ATTACHMENT
          ) {
            return (
              <DownloadForOfflineOutlinedIcon
                onClick={() => {
                  getInvoiceUrl(params.row.fk_order_id, params.row.id_notes, params.row.comment);
                }}
              />
            );
          }
          return null;
        },
        display: 'flex',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton onClick={deleteRow(params.row, refreshData, user)}>
              <DeleteIcon />
            </IconButton>
          );
        },
        display: 'flex',
      },
    ],
    [refreshData, user],
  );

  useEffect(() => {
    if (!isExpanded) return;

    async function fetchData() {
      try {
        setLoadingState('loading');
        setError('');

        const orderNotes = await getOrderNotes(orderId);
        const result = await addUserData(orderNotes, 'fk_added_by_id');

        setLoadingState('success');
        setResult(result);
      } catch (error) {
        setLoadingState('failed');
        setError(`Can't load order notes: ${error.message}`);
      }
    }

    // todo: add request cancellation support
    fetchData();
  }, [orderId, isExpanded]);

  const onExpandChange = useCallback((isExpanded) => setExpanded(isExpanded), []);

  return (
    <Box mt={2}>
      <FoldableSection
        title="Notes"
        actions={
          <IconButton href={`/purchases/${orderId}/notes/add${customOfferParams}`} title="Add order notes">
            <PostAddIcon />
          </IconButton>
        }
        onChange={onExpandChange}
        initiallyExpanded
      >
        {loadingState === 'loading' && <Spinner size={36} />}
        {loadingState === 'failed' && <ErrorDisplay message={error} />}
        {loadingState === 'success' && (
          <DataGrid
            columns={columns}
            rows={result}
            getRowId={(row) => row.id_notes}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        )}
      </FoldableSection>
    </Box>
  );
}
