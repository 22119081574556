import React, { FormEventHandler, useCallback, useRef, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
  TextField,
} from '@mui/material';

import { createSalesforceTour, refreshSalesforceTour } from '~/services/ConnectionTourSalesforce';
import { ReqInstance } from '~/services/types';

import featureToggleUtils from '~/utils/featureToggle';

class ErrorResponse extends Error {
  status?: number;
}

const SF_ID_INPUT_NAME = 'salesforce_id';

function SFTourAddTourForm(): React.ReactElement {
  const formRef = useRef<HTMLFormElement>();
  const [submissionReq, setSubmissionReq] = useState<
    ReqInstance<string, { status: number; title: string; message: string }>
  >({ status: 'uninitiated' });

  const handleFormSubmission = useCallback<FormEventHandler<HTMLFormElement>>(async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const salesforceId = String(formData.get(SF_ID_INPUT_NAME));

    setSubmissionReq({ status: 'pending' });

    try {
      let response = await createSalesforceTour(salesforceId);

      let result = 'created';
      if (response?.message?.errors?.includes('This opportunity already has a tour.')) {
        response = await refreshSalesforceTour(salesforceId);
        result = 'updated';
      }

      if (response?.status !== 200) {
        const errorMessage = response?.message?.errors ? response?.message?.errors?.join(' | ') : response?.message;
        const error = new ErrorResponse(errorMessage);
        error.status = response?.status;
        throw error;
      }

      setSubmissionReq({ status: 'succeeded', result });
      formRef.current.reset();
    } catch (err) {
      setSubmissionReq({
        status: 'failed',
        error: {
          status: err.status,
          title: err.status === 404 ? `"${salesforceId}" was not found!` : `Something went wrong with ${salesforceId}!`,
          message: err.status === 404 ? err.message : JSON.stringify(err),
        },
      });
    }
  }, []);

  return (
    <form ref={formRef} onSubmit={handleFormSubmission}>
      <Card sx={{ position: 'relative' }}>
        {submissionReq.status === 'pending' && (
          <LinearProgress sx={{ position: 'absolute', left: 0, top: 0, width: '100%' }} />
        )}

        <CardHeader title="Add Salesforce Tour" />
        <CardContent>
          <Stack direction="row" spacing={2}>
            <TextField
              name={SF_ID_INPUT_NAME}
              label="Salesforce ID"
              required
              error={submissionReq.status === 'failed'}
              disabled={
                submissionReq.status === 'pending' || featureToggleUtils.availableToShow('TOUR_ADD_TOUR_SYNC_DISABLED')
              }
              fullWidth
            />

            <Box>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  submissionReq.status === 'pending' ||
                  featureToggleUtils.availableToShow('TOUR_ADD_TOUR_SYNC_DISABLED')
                }
              >
                Add
              </Button>
            </Box>
          </Stack>

          {submissionReq.status === 'failed' && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <AlertTitle>{submissionReq.error.title}</AlertTitle>
              {submissionReq.error.message}
            </Alert>
          )}
          {submissionReq.status === 'succeeded' && (
            <Alert severity="success" sx={{ mt: 2 }}>
              <AlertTitle>{submissionReq.result}</AlertTitle>
              {submissionReq.result === 'created' && (
                <>Please wait 30 seconds and refresh the page to see the new tour.</>
              )}
              {submissionReq.result === 'updated' && (
                <>The tour you've added already exists. The tour has now been re-synced from SF.</>
              )}
            </Alert>
          )}
        </CardContent>
      </Card>
    </form>
  );
}

export default SFTourAddTourForm;
