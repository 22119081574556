import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';

import { CabinCategory, getCabinCategoryList } from '~/services/cruises/CabinCategoriesService';

export type CabinCategoriesResponse = {
  total: number;
  result: CabinCategory[];
};

type Option = {
  value: string;
  label: string;
  name?: string;
};

type SelectedCabinCategories = Pick<CabinCategory, 'id' | 'code'>[];

export const DEFAULT_RESPONSE: CabinCategoriesResponse = { result: [], total: 0 };

export type Props = {
  selectedCabinCategories: SelectedCabinCategories;
  onChange: (ship: SelectedCabinCategories) => void;
  shipId?: string;
  requiredShip?: boolean;
};

export default function CabinCategoriesAutocomplete(props: Props) {
  const { selectedCabinCategories, onChange, shipId, requiredShip } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');

  const [cabinCategories, setCabinCategories] = useState<CabinCategoriesResponse>(DEFAULT_RESPONSE);

  const fetchCabinCategories = useCallback(
    async (shipId?: string): Promise<void> => {
      setFetching('loading');

      try {
        const response = await getCabinCategoryList({
          skip: 0,
          take: 1000,
          shipId: shipId,
        });

        setFetching('success');

        if (!response || response.result.length === 0) {
          enqueueSnackbar('No cabin categories found', { autoHideDuration: 5000, variant: 'warning' });
        }

        setCabinCategories({
          total: response.total,
          result: response.result,
        });
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setCabinCategories({ total: 0, result: [] });
      }
    },
    [enqueueSnackbar],
  );

  const onCabinCategoriesChange = useCallback(
    (_, selectedOptions: Option[]) => {
      onChange(
        selectedOptions.map((option) => ({
          id: option.value,
          code: option.label,
        })),
      );
    },
    [onChange],
  );

  const cabinCategoriesOptions = useMemo(() => {
    return cabinCategories.result?.map((item) => ({
      value: item.id,
      label: item.code,
      name: `${item.code} | ${item.cabinType} | ${item.cabinInfo[0]?.name ?? '__NO_NAME__'} `,
    }));
  }, [cabinCategories]);

  const cabinCategoriesSelected = useMemo(() => {
    return selectedCabinCategories?.map((item) => ({
      name: undefined,
      value: item.id,
      label: item.code,
    }));
  }, [selectedCabinCategories]);

  useEffect(() => {
    if (requiredShip && shipId) fetchCabinCategories(shipId);
    if (!requiredShip) fetchCabinCategories();
  }, [fetchCabinCategories, requiredShip, shipId]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        size="small"
        aria-required
        disableCloseOnSelect
        id="select-cabin-categories"
        value={cabinCategoriesSelected}
        options={cabinCategoriesOptions}
        onChange={onCabinCategoriesChange}
        loading={fetching === 'loading'}
        disabled={requiredShip && !shipId}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip size="small" variant="outlined" key={option.value} label={option.label} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label="Select Cabin Categories"
            placeholder={'Please enter a Cabin Category'}
          />
        )}
      />
    </FormControl>
  );
}
