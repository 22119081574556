import React from 'react';

import PropTypes from 'prop-types';

import utils from '../utils';

export default class Authenticated extends React.Component {
  static contextTypes = {
    user: PropTypes.object,
  };

  render() {
    let user = this.context.user;
    let authenticated = user && true;

    let propsToForward = utils.excludeProps(['inGroup'], this.props);

    return authenticated ? utils.enforceRootElement(this.props.children, propsToForward) : null;
  }
}
