import React from 'react';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { Alert } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { retrieveAtolDetails } from '~/services/FlightsService';
import { editAtolCertificate, resendAtolCertificate } from '~/services/NotificationService';

import { reportError } from '~/utils/reportError';

import { withTenant } from '../../hoc';

import AtolEdit from './AtolComponents/AtolEdit';
import AtolHeader from './AtolComponents/AtolHeader';
import AtolCertificate from './BookingDetails/AtolCertificate';

class OrderAtolPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      region: null,
      pnrId: null,
      contract_html: '<h4>Loading ATOL Certificate...</h4>',
      version_count: 0,
      customer_email: '',
      alertMessage: '',
      editVisible: false,
      template_params: {},
    };
  }

  fetchCertificate = async (pnrId) => {
    try {
      const atolResponse = await retrieveAtolDetails({
        valueBy: 'pnrId',
        value: pnrId,
      });
      const { contract_html, version_count, customer_email, template_params } = atolResponse.result.reduce(
        (prev, current) => (prev.version_count > current.version_count ? prev : current),
      );

      this.setState({
        contract_html,
        version_count,
        customer_email,
        template_params,
      });
    } catch (error) {
      reportError(error);
    }
  };

  componentDidMount() {
    const { order } = this.props;
    const { region_code: region } = order;
    const { pnr_id: pnrId } = order?.flight_items?.[0];
    this.setState({
      region,
      pnrId,
    });

    this.fetchCertificate(pnrId);
  }

  render() {
    const { customer } = this.props;
    const { pnrId, region, contract_html, version_count, customer_email, alertMessage, editVisible } = this.state;

    const { id_orders: orderId } = this.props.match.params;

    const showAtolDetails = region === 'GB' && pnrId !== null;
    if (!showAtolDetails) {
      return (
        <div className="container order-detail">
          <h2>ATOL details not found</h2>
          <h4>Invalid region or PNR number not found</h4>
        </div>
      );
    }

    const downloadCertificate = () => {
      const input = document.getElementById('atol-certificate');
      html2canvas(input, { scale: 1.5 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const pageHeight = 297;
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        const pdf = new jsPDF('pt', 'mm');
        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);

        let position = 0;
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight + 10);
          heightLeft -= pageHeight;
        }

        pdf.save(`ATOL-${pnrId}-v${version_count}.pdf`);
      });
    };

    const resendCertificate = async () => {
      try {
        const result = await resendAtolCertificate({ pnrId, orderId });
        if (result.status === 200) {
          this.setState({
            alertMessage: `Send to ${customer_email} successful.`,
          });
        } else {
          this.setState({
            alertMessage: `Send to ${customer_email} not successful.`,
          });
        }
      } catch (err) {
        this.setState({
          alertMessage: `Send to ${customer_email} not successful.`,
        });
      }

      setTimeout(() => {
        this.setState({ alertMessage: '' });
      }, 5000);
    };

    const editCertificate = async (values) => {
      try {
        const result = await editAtolCertificate({ ...values, pnrId, orderId });
        if (result.status === 200) {
          this.setState({
            alertMessage: `Edit certificate and resend email successful.`,
            editVisible: false,
          });
          this.fetchCertificate(pnrId);
        } else {
          this.setState({
            alertMessage: `Edit certificate not successful.`,
          });
        }
      } catch (err) {
        this.setState({
          alertMessage: `Edit certificate not successful.`,
        });
      }

      setTimeout(() => {
        this.setState({ alertMessage: '' });
      }, 5000);
    };

    const toggleEditForm = () => {
      this.setState({
        editVisible: !this.state.editVisible,
      });
    };

    return (
      <div className="container order-detail">
        <Helmet>
          <title>Orders | {customer.fullName} | ATOL Details</title>
        </Helmet>
        <div id="timeline">
          <PageHeader title="ATOL Details" backButton={`/purchases/${orderId}`} />

          <AtolHeader
            version_count={version_count}
            downloadCertificate={downloadCertificate}
            resendCertificate={resendCertificate}
            toggleEditForm={toggleEditForm}
          />
          {alertMessage !== '' && (
            <Alert severity={alertMessage.includes('not successful') ? 'error' : 'success'}>{alertMessage}</Alert>
          )}
        </div>

        {editVisible && <AtolEdit templateParams={this.state.template_params} editCertificate={editCertificate} />}

        <div>
          <div>ATOL cerificate</div>
          <div>
            <AtolCertificate html={contract_html} />
          </div>
        </div>
      </div>
    );
  }
}

OrderAtolPage.defaultProps = {};

// Exported without router & redux HOC for testing
export const OrderDetailPageComponent = OrderAtolPage;

export default withRouter(withTenant(OrderAtolPage));
