import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import {
  BOOKING_TYPE_CALENDAR_TIMESLOTS,
  EXP_PROVIDER_LED,
  ORIGINAL_EXPERIENCE_CONTENT_TAB,
} from '~/consts/experiences';

const ExperienceCurationFeatures: React.FC = () => {
  const { contentTab, updateValues, values } = useUpdateExperienceValues();

  const onFeatureChange = (event) => {
    const { name, checked } = event.target;
    if (!checked) {
      delete values?.features[name];
      updateValues({ features: values?.features });
      return;
    }
    updateValues({ features: { [name]: {} } });
  };

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;
  const canEnableTransfer =
    values?.provider === EXP_PROVIDER_LED && values?.bookingType !== BOOKING_TYPE_CALENDAR_TIMESLOTS;
  const canToggleTransferFeature =
    !areOriginalValues && (values?.features?.transfer || canEnableTransfer) && !values?.allowBuyNowBookLater; // temporary while we don't have BNBL transfer feature

  return (
    <Box mt={2}>
      <PageSubheader title="Features" />

      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Transfer</AccordionSummary>
        <AccordionDetails>
          <FormControlLabel
            control={
              <Checkbox
                name="transfer"
                checked={!!values?.features?.transfer}
                onChange={onFeatureChange}
                disabled={!canToggleTransferFeature}
              />
            }
            label={
              values?.allowBuyNowBookLater ? 'Enabled (You cannot enable it if you have allowed BNBL/Gift)' : 'Enable'
            }
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ExperienceCurationFeatures;
