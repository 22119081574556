import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueAllCreditMovements } from '~/services/ReportingService';
import { addMonths, diffDays, formatDateISO, startOfMonth } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

function validate(formData, errors) {
  if (typeof formData.start_at === 'undefined') {
    errors.start_at.addError('Please enter start at date');
  }
  if (typeof formData.end_at === 'undefined') {
    errors.end_at.addError('Please enter end at');
  }
  if (typeof formData.credit_type === 'undefined') {
    errors.end_at.addError('Please select a credit type');
  }

  const startDate = new Date(formData.start_at);
  const endDate = new Date(formData.end_at);

  if (startDate >= endDate) {
    errors.start_at.addError('Start date should be before end date');
  } else if (diffDays(startDate, endDate, 'months') > 2) {
    errors.start_at.addError('Dates range should be less than 3 months');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    start_at: {
      title: 'Start at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(-1, startOfMonth())),
    },
    end_at: {
      title: 'End at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(0, startOfMonth())),
    },
    credit_type: {
      title: 'Credit Type',
      type: 'string',
      enum: ['All', 'Giftcard', 'Spent', 'Expired', 'Other', 'Qantas Points'],
    },
  },
};

const formData = {
  credit_type: 'All',
};

const uiSchema = {
  start: {
    'ui:widget': 'date',
  },
  end: {
    'ui:widget': 'date',
  },
};

export default function ExportCreditMovementsForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    queueAllCreditMovements(event.formData.start_at, event.formData.end_at, event.formData.credit_type, 'email')
      .then(() => {
        setSuccessMessage(true);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validate}
      validator={validator}
      formData={formData}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email credit movements report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
