/**
 * Get currency specific price object. Works for both packages and addons
 *
 * @param pkg Object
 * @param currencyCode String
 * @return {*}
 */
export default function getPackagePriceByCurrency(pkg, currencyCode) {
  return pkg.prices.find((price) => price.currency_code === currencyCode);
}
