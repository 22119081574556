import { bool, number, object, string } from 'yup';

const inclusionAssetFormSchema = object({
  id_salesforce_external: string().label('Salesforce ID').nullable(),
  vendor_salesforce_id: string().label('Vendor Salesforce ID'),
  name: string().label('Name').required('Name cannot be empty'),
  salesforce_name: string().label('Salesforce Name').nullable(),
  description: string().label('Description').nullable(),
  currency: string().label('Currency').nullable(),
  type: string().label('Type').required('Type cannot be empty'),
  category_id: string().label('Category ID').nullable(),
  unit: string().label('Unit').required('Unit field must be completed'),
  rrp: number().label('RRP').nullable(),
  is_hidden: bool().label('Hidden').nullable(),
  last_validated_on: string().label('Last Validated On').nullable(),
});

export default inclusionAssetFormSchema;
