import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Grid } from '@mui/material';

import { formatDateShort } from '~/services/TimeService';

import { daysOfWeekMessage } from '~/utils/daysOfWeek';

export function SurchargeDateBlockContent({ surchargeDateBlock }) {
  const startDate = formatDateShort(surchargeDateBlock.start_date);
  const endDate = formatDateShort(surchargeDateBlock.end_date);

  return (
    <Grid item xs={11}>
      <Grid container spacing={0} direction="column">
        <Grid item>
          <div>
            {startDate} &nbsp; &mdash; &nbsp; {endDate}
          </div>
        </Grid>
        <Grid item>
          <div>
            <i>{daysOfWeekMessage(surchargeDateBlock)}</i>
          </div>
        </Grid>
        <Grid item>
          {['AUD', 'USD', 'NZD']
            .map(
              (currencyCode) =>
                currencyFormatter.format(surchargeDateBlock.prices[currencyCode], {
                  code: currencyCode,
                  precision: 0,
                }) +
                ' ' +
                currencyCode,
            )
            .join(' | ')}
        </Grid>
      </Grid>
    </Grid>
  );
}
