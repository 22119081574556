import React from 'react';

import { Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';

export default class UploadOfferPayments extends React.Component {
  state = {
    successMessage: false,
    showErrorMessage: false,
    errorMessage: '',
    file: null,
    date_of_payment: '',
    total: null,
    message: null,
  };

  checkStatus = (jobId: string) => {
    const intervalId = setInterval(() => {
      VendorsService.checkUploadJobStatus(jobId).then((result) => {
        const rowsProcessed = result.rows_processed || 0;

        if (result.job_state !== 'completed' && result.job_state !== 'failed') {
          this.setState({
            successMessage: true,
            message: `File processing... ${rowsProcessed} rows processed.`,
          });
          return;
        }

        if (intervalId) {
          clearInterval(intervalId);
        }

        if (result.status === 'success') {
          const message =
            rowsProcessed > 0
              ? 'File uploaded successfully.'
              : 'File uploaded successfully but 0 rows processed. Please check file format and/or column names.';
          return this.setState({
            showMessage: true,
            total: result.result,
            message,
          });
        }

        this.setState({
          successMessage: false,
          showErrorMessage: true,
          errorMessage: result.result,
          file: null,
          total: null,
          message: null,
        });
      });
    }, 2000);
  };

  onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const result = await this.fileUpload(this.state.file, this.state.date_of_payment);

      this.setState({
        successMessage: true,
        showErrorMessage: false,
        errorMessage: '',
        file: null,
        total: result.total,
        message: result.message,
      });

      this.checkStatus(result.job_id);
    } catch (err) {
      this.setState({
        successMessage: false,
        showErrorMessage: true,
        errorMessage: err.message,
        file: null,
        total: null,
        message: null,
      });
    }
  };

  onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ file: e.target.files[0] });
  };

  onDateOfPaymentChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ date_of_payment: e.target.value });
  };

  fileUpload = async (file: File, date_of_payment: string) => {
    const formData = new FormData();
    formData.append('offer-payments', file);
    formData.append('date_of_payment', date_of_payment);
    return VendorsService.uploadOfferPayments(formData);
  };

  render() {
    const { successMessage, showErrorMessage, errorMessage } = this.state;

    return (
      <>
        <p>VCC Payments will not be reflected in any reports until the following day.</p>

        {successMessage && <SuccessMessage total={this.state.total} message={this.state.message} />}
        {showErrorMessage && <ErrorMessage message={errorMessage} />}

        <form onSubmit={this.onFormSubmit}>
          <h5>Date of Payment</h5>
          <input type="date" onChange={this.onDateOfPaymentChange} />

          <h5>File Upload</h5>
          <input type="file" onChange={this.onChange} />

          <br />

          <Button type="submit" variant="contained">
            Upload payments
          </Button>
        </form>
      </>
    );
  }
}
