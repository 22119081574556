import { RJSFSchema } from '@rjsf/utils';

export default function pruneObjectBasedOnSchema(object, schema: RJSFSchema) {
  const res = { ...object };
  const schemaPropertyKeys = Object.keys(schema.properties);
  for (const key of Object.keys(res)) {
    if (!schemaPropertyKeys.includes(key)) {
      delete res[key];
    }
  }
  return res;
}
