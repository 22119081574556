import React from 'react';

import { Link, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export default function PreviewCell({ row }: GridRenderCellParams) {
  const lePath = `${window.configs['LUX_CUSTOMER_PORTAL']}/partner/${row.slug}/${row.id}?preview=true&previewHash=${row.previewHash}`;
  const expediaPath = `${window.configs['EXPEDIA_PORTAL']}/${row.externalSlug}`;

  return (
    <Stack direction="row" spacing={1}>
      <Link href={lePath}>LE</Link>
      <Link href={expediaPath}>EPS</Link>
    </Stack>
  );
}
