import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addDays,
  addMonths,
  dateNowUtc,
  datesHaveSameDayMonthYear,
  getCalendarMonthWeeks,
  isAfter,
} from '~/services/TimeService';

import { NUM_MONTHS } from '../../../../consts/calendar';
import getPureMonthDiff from '../../../../utils/getPureMonthDiff';
import Calendar from '../../Calendar';

import Day from './Day';
import {
  isBlackoutDay,
  isCheckinDay,
  isCheckoutDay,
  isDayBlocked,
  isDayHighlighted,
  isSurchargeDay,
} from './utils/day';

interface Props {
  cart: any;
  currencyCode: string;
  dates: Array<App.SelectedDate>;
  offerType: string;
  onMakeEnquiry: any;
  onSelectDate: any;
  roomRateId: string;
  selectedDate: {
    checkIn: Date;
    checkOut: Date;
    lastNight: Date;
  };
}

interface State {
  offset: number;
}

class DatePicker extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      offset: this.getInitialOffset(),
    };
  }

  componentDidMount() {
    const { onMakeEnquiry } = this.props;
    const { offset } = this.state;

    onMakeEnquiry(offset);
  }

  // getInitialOffset calculates the initial offset value if there is a selected
  // date already. Such case happens if we are changing dates.
  getInitialOffset = () => {
    const { selectedDate } = this.props;

    let ret;

    if (selectedDate) {
      const now = new Date();
      ret = getPureMonthDiff(now, selectedDate.checkIn);
    } else {
      ret = 0;
    }

    return ret;
  };

  monthsArray = (n, o) => {
    const accum = Array(n);
    for (let i = 0; i < n; i++) {
      accum[i] = addMonths(o + i);
    }

    return accum;
  };

  getMonths = () => {
    const { offset } = this.state;

    return this.monthsArray(NUM_MONTHS, offset);
  };

  getMonthWeeks = (month) => {
    return getCalendarMonthWeeks(month, false);
  };

  getGrid = () => {
    return this.getMonths().map((month) => {
      return {
        date: month,
        weeks: this.getMonthWeeks(month),
      };
    });
  };

  getDates = () => {
    const { dates } = this.props;

    return dates.map((date) => {
      return {
        ...date,
        check_in: dateNowUtc(date.check_in),
        check_out: dateNowUtc(date.check_out),
        last_night: dateNowUtc(date.last_night),
      };
    });
  };

  handleSelectDate = (dayDate) => {
    const { onSelectDate } = this.props;
    const { day: startDate, date } = dayDate;

    if (!startDate) {
      return;
    }

    if (!date || this.checkDayBlocked(dayDate)) {
      return;
    }

    const lastMonth = this.getMonths().pop();
    if (isAfter(date.check_out, lastMonth)) {
      this.handleShowNextMonth();
    }

    onSelectDate(date);
  };

  getGridWithDates = () => {
    const dates = this.getDates();

    return this.getGrid().map((month) => ({
      date: month.date,
      weeks: month.weeks.map((week) =>
        week.map((day) => ({
          day,
          date: dates.find((date) => datesHaveSameDayMonthYear(date.check_in, day)),
        })),
      ),
    }));
  };

  navigateMonth = (delta) => {
    const { onMakeEnquiry } = this.props;

    this.setState(
      (prevState) => {
        return { offset: prevState.offset + delta };
      },
      () => {
        const { offset } = this.state;

        onMakeEnquiry(offset);
      },
    );
  };

  handleShowNextMonth = () => {
    this.navigateMonth(1);
  };

  handleShowPrevMonth = () => {
    this.navigateMonth(-1);
  };

  checkDayBlocked = (dayDate) => {
    const yesterday = addDays(-1);

    const checkFn = isDayBlocked(yesterday);
    return checkFn(dayDate);
  };

  checkCheckinDay = (day) => {
    const { selectedDate } = this.props;
    const { checkIn } = selectedDate;

    const checkFn = isCheckinDay(checkIn);
    return checkFn(day);
  };

  checkCheckoutDay = (day) => {
    const { selectedDate } = this.props;
    const { checkOut } = selectedDate;

    const checkFn = isCheckoutDay(checkOut);
    return checkFn(day);
  };

  checkHighlightedDay = (day) => {
    const { selectedDate } = this.props;
    const { checkIn, lastNight } = selectedDate;

    const checkFn = isDayHighlighted(checkIn, lastNight);
    return checkFn(day);
  };

  calendarDayComponent = ({ dayDate, idx }) => {
    const { currencyCode } = this.props;

    // TODO: replace idx with something else for key
    return (
      <Day
        key={idx}
        dayDate={dayDate}
        onSelectDate={this.handleSelectDate}
        isDayBlocked={this.checkDayBlocked}
        isDayHighlighted={this.checkHighlightedDay}
        isCheckinDay={this.checkCheckinDay}
        isCheckoutDay={this.checkCheckoutDay}
        isBlackoutDay={isBlackoutDay}
        isSurchargeDay={isSurchargeDay}
        currencyCode={currencyCode}
      />
    );
  };

  render() {
    const { offset } = this.state;
    const grid = this.getGridWithDates();

    const shouldHidePrevMonthButton = offset <= 0;

    return (
      <div className="availability-calendar">
        <div className="buttons">
          <button disabled={shouldHidePrevMonthButton} onClick={this.handleShowPrevMonth}>
            &lt;
          </button>
          <button className="T-next-month" disabled={false} onClick={this.handleShowNextMonth}>
            &gt;
          </button>
        </div>

        <Calendar grid={grid} calendarDayComponent={this.calendarDayComponent} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
