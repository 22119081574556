import React, { useMemo } from 'react';

import EastIcon from '@mui/icons-material/East';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';

import { formatDateSlashesWithClock } from '~/services/TimeService';

import { formatTransferTravellers } from '~/utils/experienceUtils';

interface Props {
  experienceItem: App.ExperienceItem;
}

function ExperienceAirportTransferInformation({ experienceItem }: Props) {
  const transferDateTime = useMemo(() => {
    if (experienceItem.ticket?.date) {
      return formatDateSlashesWithClock(experienceItem.ticket?.date);
    }
    return 'Not Supplied';
  }, [experienceItem.ticket?.date]);

  const travellerInformation = formatTransferTravellers(experienceItem.deal_options);

  return (
    <Accordion variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        {experienceItem.ticket?.type === 'AIRPORT-TO-HOTEL' && (
          <Typography>Airport to Hotel Transfer Details</Typography>
        )}
        {experienceItem.ticket?.type === 'HOTEL-TO-AIRPORT' && (
          <Typography>Hotel to Airport Transfer Details</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column">
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Route:</Typography>
            <Stack direction="row" gap={1}>
              <Typography>{experienceItem.pickup_point_name}</Typography>
              <EastIcon />
              <Typography>{experienceItem.dropoff_point_name}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Flight Number:</Typography>
            <Typography>
              {experienceItem.deal_options?.flight_number ? experienceItem.deal_options.flight_number : 'Not Supplied'}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Date/Time:</Typography>
            <Typography>{transferDateTime}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Travellers:</Typography>
            <Typography>{travellerInformation}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Special Requests:</Typography>
            <Typography>{experienceItem.special_requests ?? 'None supplied'}</Typography>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export default ExperienceAirportTransferInformation;
