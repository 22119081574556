import React, { useCallback } from 'react';

import { themes } from '../../../../consts/experiences';
import { Margin } from '../../helpers';
import * as Icon from '../Icons';

import * as S from './styles';

type TagProps = {
  name: string;
  color?: string;
  value?: unknown;
  outline?: boolean;
  minWidth?: string;
  maxWidth?: string;
  background?: string;
  selectable?: boolean;
  onClick?: (value: unknown) => void;
} & Margin;

export const Tag: React.FC<TagProps> = (props) => {
  const { name, value, onClick, selectable, ...rest } = props;

  const handleClick = useCallback(() => onClick(value), [onClick]);

  return (
    <S.TagWrapper {...rest} className="le-tag" onClick={onClick && handleClick}>
      {selectable && <Icon.Close fill={themes?.white} />}
      <span title={name}>{name}</span>
    </S.TagWrapper>
  );
};
