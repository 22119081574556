import React from 'react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import { currencyFormatter } from '../../../helpers';

interface Props {
  value: Experiences.Offer;
  removeOnclick: (value: Experiences.Offer) => void;
}

const SortableListItem = SortableElement(({ value, removeOnclick }: Props) => (
  <Paper variant="outlined">
    <Box display="grid" gap={2} gridTemplateColumns="1fr auto auto auto auto" p={1} alignItems="center">
      <Typography>{value.title}</Typography>
      <Typography>{value.provider}</Typography>
      <Typography>{currencyFormatter(value.baseSalesPrice.currencyCode, value.baseSalesPrice.amount)}</Typography>

      <IconButton title="Remove" onClick={() => removeOnclick(value)}>
        <DeleteIcon />
      </IconButton>

      <MenuIcon />
    </Box>
  </Paper>
));

const HeroesListItem = SortableContainer(({ items, removeOnclick }) => {
  return (
    <Stack direction="column" spacing={1}>
      {items.map((value, index) => {
        return <SortableListItem removeOnclick={removeOnclick} key={`item-${value.id}`} index={index} value={value} />;
      })}
    </Stack>
  );
});

export default HeroesListItem;
