import React, { useCallback, useEffect, useMemo, useState } from 'react';

import groupBy from 'lodash/groupBy';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';
import Spinner from '~/components/Common/Spinner';

import useQuery from '~/hooks/useQuery';

import BedbankService from '~/services/BedbankService';
import { getPurchases } from '~/services/OrdersService';

import { addQuery, removeQuery } from '~/utils/url';

import BedbankPage from './BedbankPage';

const ITEM_TYPE_BEDBANK = 'bedbank';
const DEFAULT_SIZE_PER_PAGE = 10;
const SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE = 50;

function BedbankPageContainer() {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [isLoading, setIsLoading] = useState(true);
  const [purchases, setPurchases] = useState(null);
  const [rebookableData, setRebookableData] = useState(null);
  const [filterField, setFilterField] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);

  const [sizePerPage, setSizePerPage] = useState(DEFAULT_SIZE_PER_PAGE);

  const currentPage = parseInt(query.get('page')) || 1;
  const customerId = query.get('customer_id');

  const isRebookableFilter = useMemo(() => {
    return filterField === 'rebookable' && filterValue === 'true';
  }, [filterValue, filterField]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setPurchases(null);
    setRebookableData(null);

    try {
      const fetchedPurchases = await getPurchases({
        page: currentPage,
        per_page: sizePerPage,
        brand,
        customer_id: customerId,
        filterBy: filterField,
        filterValue,
        additionalFilter: additionalSearchParams,
        item_type: ITEM_TYPE_BEDBANK,
      });

      if (!fetchedPurchases) {
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      }

      setPurchases(fetchedPurchases);
    } catch (error) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, customerId, filterField, filterValue, sizePerPage, brand, additionalSearchParams, enqueueSnackbar]);

  useEffect(() => {
    const reservationIds = (purchases?.result ?? [])
      .flatMap((p) => p.bedbank_items.map((i) => i.id_reservation))
      .filter(Boolean);

    if (isRebookableFilter && reservationIds.length > 0) {
      BedbankService.getRebookableInfo(reservationIds).then(({ result }) => {
        setRebookableData(groupBy(result, (d) => d.newReservationId ?? d.reservationId));
      });
    }
  }, [isRebookableFilter, purchases]);

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (customerId) {
      setSizePerPage(SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE);
    } else {
      setSizePerPage(DEFAULT_SIZE_PER_PAGE);
    }
  }, [customerId]);

  const searchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);

    const newLocation = removeQuery(location, 'customer_id', 'page');

    history.push(newLocation);
  };

  return (
    <>
      <OrderSearchForm
        bookingNumberPlaceholder="Enter Bedbank Number"
        searchQuery={searchQuery}
        setAdditionalParams={setAdditionalSearchParams}
        e2eSuffix="-bedbank"
        bookingIdType="bedbank_number"
        showRebookableFilter
        showOrderNumberFilter
        showBedbankProviderFilter
      />
      <>
        {isLoading && <Spinner size={48} />}

        {!isLoading && (
          <BedbankPage
            orders={purchases?.result}
            total={purchases?.total}
            rebookableData={rebookableData}
            isRebookableFilter={isRebookableFilter}
            page={currentPage}
            sizePerPage={sizePerPage}
            onPageChange={onPageChange}
            rerender={fetchData}
          />
        )}
      </>
    </>
  );
}

export default BedbankPageContainer;
