import React, { Component } from 'react';

import styled from 'styled-components';

import { dateNowUtc, datesHaveSameDayMonthYear, formatDateDay, formatDateISO, isAfter } from '~/services/TimeService';

import Calendar from '../../Common/Calendar';

import getCalendarGrid from './helpers/getCalendarGrid';

const LeadDayInfo = styled.div`
  font-size: 12px;
  font-weight: 100;
`;

interface Props {
  leadDays: Array<App.RoomRateLeadDay>;
}

interface State {
  offset: number;
}

class LeadDaysCalendar extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
    };
  }

  firstDay = () => {
    return dateNowUtc(this.props.leadDays[0].date);
  };

  lastDay = () => {
    const latestBlock = this.props.leadDays.slice(-1)[0];
    return dateNowUtc(latestBlock.date);
  };

  showingLastDay = (grid) => {
    const lastDayOfAvailability = this.lastDay();
    const lastMonthInGrid = grid.slice(-1)[0];
    const lastWeekInGrid = lastMonthInGrid.weeks.slice(-1)[0];
    const lastDayInGrid = lastWeekInGrid.filter((dayDate) => dayDate.day).slice(-1)[0];
    return (
      datesHaveSameDayMonthYear(lastDayOfAvailability, lastDayInGrid.day) ||
      isAfter(lastDayInGrid.day, lastDayOfAvailability)
    );
  };

  getGrid = () => getCalendarGrid(this.firstDay(), 2, this.state.offset);

  getGridWithDates = () => {
    const leadDays = this.props.leadDays.reduce((acc, item) => {
      acc[item.date] = item;
      return acc;
    }, {});
    return this.getGrid().map(function (month) {
      return {
        date: month.date,
        weeks: month.weeks.map(function (week) {
          return week.map(function (day) {
            return {
              day,
              leadDay: day ? leadDays[formatDateISO(day)] ?? null : null,
            };
          });
        }),
      };
    });
  };

  showNextMonth = () => {
    this.setState((prevState) => ({
      offset: prevState.offset + 1,
    }));
  };

  showPrevMonth = () => {
    this.setState((prevState) => ({
      offset: prevState.offset - 1,
    }));
  };

  calendarDayComponent = ({ dayDate, idx }) => {
    const formatDay = (m) => formatDateDay(m);

    return (
      <div key={idx}>
        {dayDate.day && <div className="date">{formatDay(dayDate.day)}</div>}
        {dayDate.leadDay && (
          <LeadDayInfo>
            <div>MIN: {dayDate.leadDay.min_days}</div>
            <div>MAX: {dayDate.leadDay.max_days}</div>
          </LeadDayInfo>
        )}
      </div>
    );
  };

  render() {
    const grid = this.getGridWithDates();

    const shouldHidePrevMonthButton = this.state.offset <= 0;
    const shouldHideNextMonthButton = this.showingLastDay(grid);

    return (
      <div className="availability-calendar">
        <div className="buttons">
          <button disabled={shouldHidePrevMonthButton} onClick={this.showPrevMonth}>
            &lt;
          </button>
          <button disabled={shouldHideNextMonthButton} onClick={this.showNextMonth}>
            &gt;
          </button>
        </div>
        <Calendar grid={grid} calendarDayComponent={this.calendarDayComponent} />
      </div>
    );
  }
}

export default LeadDaysCalendar;
