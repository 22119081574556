import React from 'react';

import { EditorState, Modifier } from 'draft-js';
import { connect } from 'react-redux';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Box,
  ClickAwayListener,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { whiteLabel } from '@luxuryescapes/lib-global';

type Props = {
  brand: App.Tenant['brand'];
  editorState: EditorState;
  onChange: (e: SelectChangeEvent) => void;
  disabled?: boolean;
};

const BrandContentSelector = ({ brand, editorState, onChange, disabled }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const addTokenToEditorState = (e: SelectChangeEvent) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `{{${e.target.value}}}`,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControl sx={{ minWidth: 130 }} size="small">
        <InputLabel id="brand-tokens-label">Brand Tokens</InputLabel>
        <Select labelId="brand-tokens-label" onChange={addTokenToEditorState} label="Brand Tokens" disabled={disabled}>
          {Object.entries(whiteLabel.dynamicTags.brandContent[brand]).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {key} -&nbsp;
              <Typography variant="caption" display="block">
                eg. {value}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ClickAwayListener onClickAway={handleClose}>
        <Box sx={{ position: 'relative' }}>
          <IconButton size="small" aria-describedby={id} type="button" onClick={handleClick}>
            <InfoRoundedIcon />
          </IconButton>
          <Popper id={id} open={open} anchorEl={anchorEl} transition popperOptions={{ placement: 'top' }}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                  <Typography variant="body2">
                    You can access a full description for each value by brand here:{' '}
                    <a
                      onClick={handleClose}
                      href="https://aussiecommerce.atlassian.net/l/cp/ZdG0M8iq"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      https://aussiecommerce.atlassian.net/l/cp/ZdG0M8iq
                    </a>
                  </Typography>
                </Box>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

const mapStateToProps = (state: App.State) => ({
  brand: state.tenant.brand,
});

export default connect(mapStateToProps)(BrandContentSelector);
