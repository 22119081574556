import qs from 'qs';

import { GridPaginationModel } from '@mui/x-data-grid';

import { json_headers, request } from './common';

function getBaseURL() {
  return window.configs.API_HOST + '/api';
}

function getInclusions(
  vendorId: string,
  offerId: string,
  paginationModel: GridPaginationModel,
  filter: { field: string; query: string },
  sortModel: { field: string; sort: string },
) {
  const queryParams = {
    offer_id: offerId,
    vendor_id: vendorId,
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort_by: sortModel?.field,
    sort_direction: sortModel?.sort,
    [filter?.field]: filter?.query,
  };

  const baseURL = getBaseURL();
  const url = `${baseURL}/reservations/inclusions-catalogue/inclusions?${qs.stringify(queryParams)}`;

  return request(url);
}

function getInclusionsCategories() {
  const baseURL = getBaseURL();
  const url = `${baseURL}/reservations/inclusions-catalogue/categories`;

  return request(url);
}

function saveInclusionAsset(vendorId, data, id) {
  const baseURL = getBaseURL();
  const create = `${baseURL}/reservations/inclusions-catalogue/inclusions`;
  const update = `${create}/${vendorId}/${id}`;
  const url = id ? update : create;

  return request(url, {
    method: id ? 'PUT' : 'POST',
    headers: json_headers,
    body: JSON.stringify(data),
  });
}

function unlinkPackagesByInclusionIds({ inclusionIds = [], ids = [] }) {
  const url = `${getBaseURL()}/reservations/inclusions-catalogue/package-inclusions`;

  return request(url, { method: 'DELETE', headers: json_headers, body: JSON.stringify({ inclusionIds, ids }) });
}

function deleteInclusion(inclusionId) {
  const url = `${getBaseURL()}/reservations/inclusions-catalogue/inclusions/${inclusionId}`;

  return request(url, { method: 'DELETE' });
}

function fetchPackageInclusions(offerId) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/reservations/inclusions-catalogue/package-inclusions/${offerId}`;

  return request(url);
}

function updatePackageInclusion(data) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/reservations/inclusions-catalogue/package-inclusions`;

  return request(url, {
    method: 'PUT',
    headers: json_headers,
    body: JSON.stringify(data),
  });
}

function copyInclusionsOrder(vendorId, offerId, data) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/reservations/inclusions-catalogue/package-inclusions/${vendorId}/${offerId}/copy-order`;

  return request(url, {
    method: 'PUT',
    headers: json_headers,
    body: JSON.stringify(data),
  });
}

export default {
  deleteInclusion,
  getInclusions,
  getInclusionsCategories,
  saveInclusionAsset,
  fetchPackageInclusions,
  updatePackageInclusion,
  copyInclusionsOrder,
  unlinkPackagesByInclusionIds,
};
