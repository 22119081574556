import React, { useCallback, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Button, Typography, capitalize } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageHeader from '~/components/Common/Elements/PageHeader';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { accountFieldsMap, getBonusUnitPluralize } from '~/services/LoyaltyService';

import DetailsModal from './DetailsModal';

const currencyFormat = (amount) => {
  return `$${amount}`;
};

export default function LoyaltyPage({ partnership, burns, earns, user }) {
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState(null);
  const [type, setType] = useState<string | null>(null);

  const accountFields = accountFieldsMap(partnership);
  const bonusUnit = getBonusUnitPluralize(partnership);

  const openDetailsModal = (data, type) => {
    setShowDetails(true);
    setDetails(data);
    setType(type);
  };

  const closeDetailsModal = () => {
    setShowDetails(false);
    setDetails(null);
    setType(null);
  };

  const buttonFormatter = useCallback(
    (row) => (
      <Button type="button" variant="text" size="small" onClick={() => openDetailsModal(row, 'burn')}>
        View Details
      </Button>
    ),
    [],
  );

  const earnButtonFormatter = useCallback(
    (row) => (
      <Button type="button" variant="text" size="small" onClick={() => openDetailsModal(row, 'earn')}>
        View Details
      </Button>
    ),
    [],
  );

  const earnsColumns = useMemo<GridColDef[]>(
    () => [
      ...accountFields.map(
        (field) =>
          ({
            field: field.field,
            headerName: field.name,
            width: 200,
            sortable: false,
            display: 'flex',
          } as GridColDef),
      ),
      { field: 'order_id', headerName: 'Order ID', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'order_payment_amount',
        headerName: 'Order Amount',
        width: 150,
        sortable: false,
        valueFormatter: (value) => currencyFormat(value),
        display: 'flex',
      },
      {
        field: `${bonusUnit}_total_earned`,
        headerName: capitalize(`${bonusUnit} Earned`),
        width: 150,
        sortable: false,
        display: 'flex',
      },
      {
        field: 'actions',
        headerName: '',
        width: 200,
        sortable: false,
        align: 'right',
        renderCell: (params) => earnButtonFormatter(params.row),
        display: 'flex',
      },
    ],
    [accountFields, bonusUnit, earnButtonFormatter],
  );

  const burnsColumns = useMemo<GridColDef[]>(
    () => [
      { field: 'qff', headerName: 'QFF Number', width: 200, sortable: false, display: 'flex' },
      {
        field: 'burn_transaction_number',
        headerName: 'Burn Transaction ID',
        width: 200,
        sortable: false,
        display: 'flex',
      },
      { field: 'burn_amount', headerName: 'Credit Amount', width: 200, sortable: false, display: 'flex' },
      { field: 'burn_points', headerName: 'Points Burned', width: 200, sortable: false, display: 'flex' },
      { field: 'status', headerName: 'Status', width: 200, sortable: false, display: 'flex' },
      {
        field: 'actions',
        headerName: '',
        flex: 1,
        sortable: false,
        align: 'right',
        renderCell: (params) => buttonFormatter(params.row),
        display: 'flex',
      },
    ],
    [buttonFormatter],
  );

  return (
    <>
      <Helmet>
        <title>
          Users | {user.fullName} | {partnership.brandName}
        </title>
      </Helmet>

      <PageHeader title={partnership.brandName} backButton={`/users/${user.id_member}`} />

      <PageSubheader title={`Earn ${capitalize(bonusUnit)} Transactions`} />

      {earns.result && (
        <DataGrid
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          columns={earnsColumns}
          rows={earns.result}
          rowCount={earns.total}
          pageSizeOptions={[10]}
          slots={{ pagination: GridPagination }}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
        />
      )}

      <Typography mb={3}>
        * This table does not reflect the current status of the {bonusUnit} in customers{' '}
        {partnership.prefix.toUpperCase()} account.
      </Typography>

      <PageSubheader title={`Burn ${capitalize(bonusUnit)} Transactions`} />

      {burns.result && (
        <DataGrid
          columns={burnsColumns}
          rows={burns.result}
          rowCount={burns.total}
          slots={{ pagination: GridPagination }}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
        />
      )}

      <DetailsModal
        show={showDetails}
        onHide={closeDetailsModal}
        data={details}
        partnership={partnership}
        type={type}
      />
    </>
  );
}
