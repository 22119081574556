import React from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { OfferInclusionsSelect } from '~/components/Common/Forms/fields';
import ReactHookFormAdapter from '~/components/Common/Forms/widgets/ReactHookFormAdapter';

import { prepareScheduledDiscountList } from '~/utils/scheduledDiscountsHelper';
import { ingestSchedules, prepareSchedulesList } from '~/utils/schedulesHelpers';

import { BONUS_INCLUSIONS_MAX_TO_NIGHTS } from '../../../consts/ratePlans';
import OffersService from '../../../services/OffersService';
import ReservationService from '../../../services/ReservationService';
import { getCancellationPolicyLabel } from '../../../utils/cancellationPolicy';
import nullsToEmptyStrings from '../../../utils/nullsToEmptyStrings';
import { reportError } from '../../../utils/reportError';
import { DeleteButton } from '../DeleteButton';
import SetRegionModal from '../Modals/SetRegionModal';

import ScheduledDiscount from './fields/ScheduledDiscount';
import Schedules from './fields/Schedules';
import { buttonMessages, buttonStates } from './states/submitButton';
import MarkdownEditor from './widgets/MarkdownEditor';

const mapRatePlanFormData = ({
  _links,
  id_salesforce_external,
  group,
  created_at,
  updated_at,
  id,
  bonus_inclusions,
  cancellation_policy_detail,
  ...form
}) => {
  const mappedRatePlan = {
    ...form,
    bonus_inclusions: (bonus_inclusions || []).map(({ id, ...bonusInclusion }) => {
      const mappedBonusInclusion = {
        ...bonusInclusion,
        content_override: bonusInclusion.content_override || [],
      };
      return mappedBonusInclusion;
    }),
    inclusions_override: form.inclusions_override || [],
  };
  if (!id) {
    mappedRatePlan.id_salesforce_external = id_salesforce_external;
  }
  mappedRatePlan.schedules_list = ingestSchedules(form?.schedules_list);
  mappedRatePlan.discounts = (form?.discounts ?? []).filter((discount) => discount.source === 'internal');
  mappedRatePlan.stay_pay?.sort((a, b) => a.stay_nights - b.stay_nights);
  return nullsToEmptyStrings(mappedRatePlan);
};

const getRateTypeWidget = (rateType) => {
  const RateTypeWidget = (props) => {
    return (
      <TextField
        value={props.value}
        required={props.required}
        type={props.schema.type}
        onChange={(event) => props.onChange(event.target.value)}
        aria-readonly={props.formContext.rateTypeValue !== rateType}
        inputProps={{
          readOnly: props.formContext.rateTypeValue !== rateType,
        }}
      />
    );
  };
  return RateTypeWidget;
};

const setToMaxWidget = (props) => {
  const largestToNightsBonusInclusions = props.formContext.bonusInclusions?.reduce(
    (max, x, index) => (x.to_nights > max.bonusInclusions.to_nights ? { index, bonusInclusions: x } : max),
    { index: -1, bonusInclusions: { to_nights: 0 } },
  );
  const currentIndex = Number(props.id.match(/root_bonus_inclusions_([0-9]+)_set_to_max/)[1]);

  const isMaxToNights =
    props.formContext.bonusInclusions &&
    props.formContext.bonusInclusions[currentIndex]?.to_nights === BONUS_INCLUSIONS_MAX_TO_NIGHTS;

  const isShorterThanMaxToNightInclusions =
    largestToNightsBonusInclusions.index !== -1 &&
    largestToNightsBonusInclusions.index !== currentIndex &&
    props.formContext.bonusInclusions[currentIndex]?.from_nights <
      largestToNightsBonusInclusions.bonusInclusions.to_nights;

  const hasFromNightLargerThanLargestToNight =
    largestToNightsBonusInclusions.index !== -1 &&
    largestToNightsBonusInclusions.index === currentIndex &&
    props.formContext.bonusInclusions.some(
      (inclusions) => inclusions.from_nights > props.formContext.bonusInclusions[currentIndex]?.to_nights,
    );
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isMaxToNights}
            onChange={(event) => props.onChange(event.target.checked)}
            disabled={
              isShorterThanMaxToNightInclusions ||
              !props.formContext.bonusInclusions[currentIndex]?.from_nights ||
              hasFromNightLargerThanLargestToNight
            }
          />
        }
        label="Set to max"
      />
    </>
  );
};

const fromNightsWidget = (props) => (
  <TextField
    value={props.value}
    required={props.required}
    type={props.schema.type}
    onChange={(event) => props.onChange(event.target.value)}
  />
);

const toNightsWidget = (props) => {
  const currentIndex = Number(props.id.match(/root_bonus_inclusions_([0-9]+)_to_nights/)[1]);

  const isMaxToNights =
    props.formContext.bonusInclusions &&
    props.formContext.bonusInclusions[currentIndex]?.to_nights === BONUS_INCLUSIONS_MAX_TO_NIGHTS;

  if (isMaxToNights) {
    return <TextField value={props.formContext.bonusInclusions[currentIndex].from_nights + '+'} type="text" disabled />;
  }
  return (
    <TextField
      value={props.value}
      required={props.required}
      type={props.schema.type}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
};

const GrossRateTypeWidget = getRateTypeWidget('gross');
const NetRateTypeWidget = getRateTypeWidget('net');

const widgets = {
  markup: NetRateTypeWidget,
  commission: GrossRateTypeWidget,
  discount: GrossRateTypeWidget,
  setToMax: setToMaxWidget,
  toNights: toNightsWidget,
  fromNights: fromNightsWidget,
};

const defaultUiSchema = {
  should_instant_purchase: { 'ui:widget': 'hidden' },
  deposit_percent: { 'ui:widget': 'hidden' },
  id_salesforce_external: { 'ui:widget': 'hidden' },
  default_plan: { 'ui:widget': 'hidden' },
  product_type: {
    'ui:help': 'The type of offer this rate plan will be used for',
  },
  rate_plan_code: { 'ui:emptyValue': null },
  rate_type: { 'ui:widget': 'radio' },
  markup: {
    'ui:widget': 'markup',
    'ui:help': 'Net + markup = gross',
  },
  commission: {
    'ui:widget': 'commission',
    'ui:help': 'Gross - discount - commission = net',
  },
  discount: {
    'ui:widget': 'discount',
    'ui:help': 'Gross - discount = sale price',
  },
  discounts: {
    'ui:widget': 'hidden',
    'ui:disabled': true,
  },
  inclusions: { 'ui:widget': MarkdownEditor },

  // overwrites inclusions if filled. This will eventually replace inclusions completely
  inclusions_override: {
    'ui:widget': ReactHookFormAdapter,
    'ui:options': { ReactHookFormComponent: OfferInclusionsSelect },
  },
  bonus_inclusions: {
    items: {
      set_to_max: {
        'ui:widget': 'setToMax',
      },
      from_nights: {
        'ui:widget': 'fromNights',
      },
      to_nights: {
        'ui:widget': 'toNights',
      },
      content: {
        'ui:widget': MarkdownEditor,
      },
      content_override: {
        'ui:widget': ReactHookFormAdapter,
        'ui:options': { ReactHookFormComponent: OfferInclusionsSelect },
      },
    },
  },
  custom_vcc_payment_currency: { 'ui:widget': 'select' },
  currency: { 'ui:widget': 'select' },
  schedules: {
    'ui:widget': 'hidden',
    'ui:disabled': true,
  },
  schedules_list: {
    'ui:widget': 'hidden',
    'ui:disabled': true,
  },
  regions: {
    'ui:classNames': 'hidden-form-field',
    'ui:disabled': true,
  },
  bundle_and_save_discount_only: {
    'ui:title': 'Show on Single & Bundle Offer',
  },
  bundle_discount_percent: {
    'ui:widget': 'hidden',
  },
  is_stay_pay_enabled: {
    'ui:widget': 'hidden',
    'ui:disabled': true,
  },
  stay_pay: {
    'ui:widget': 'hidden',
    'ui:disabled': true,
  },
  'ui:order': [
    'name',
    'product_type',
    'rate_type',
    'markup',
    'commission',
    'discount',
    'type_of_pay',
    'deposit_percent',
    'allow_free_nights',
    'ignore_dynamic_cancellation_policy',
    'cancellation_policy',
    'non_refundable_cancellation_policy_codes',
    'rate_plan_code',
    'inclusions',
    'inclusions_override',
    'bonus_inclusions',
    'custom_vcc_payment_currency',
    'currency',
    'is_packaged_rate',
    'packaged_rate_id',
    'status',
    'is_base_rate',
    'regions',
    '*',
  ],
};

class RatePlanForm extends React.Component {
  constructor(props) {
    super(props);

    this.vendorId = props.vendorId;
    this.schema = props.schema;

    // temp override for `type_of_pay` until it's fixed on backend
    this.schema.properties.type_of_pay = {
      type: ['string', 'null'],
      title: 'Instant Purchase or Deposit',
      oneOf: [
        { const: null, title: 'Default' },
        { const: 'instant_purchase', title: 'Instant Purchase' },
        { const: 'deposit', title: 'Deposit' },
      ],
    };

    this.schema.properties.cancellation_policy.enumNames = this.schema.properties.cancellation_policy.enum.map(
      (value) => getCancellationPolicyLabel(value),
    );

    this.schema.properties.bonus_inclusions.items.properties.setToMax = {
      title: 'Set to max duration',
      type: 'boolean',
    };

    this.schema.properties.bonus_inclusions.items.properties = {
      from_nights: this.schema.properties.bonus_inclusions.items.properties.from_nights,
      to_nights: this.schema.properties.bonus_inclusions.items.properties.to_nights,
      set_to_max: { title: 'Set to max duration', type: 'boolean' },
      content: this.schema.properties.bonus_inclusions.items.properties.content,
      content_override: this.schema.properties.bonus_inclusions.items.properties.content_override,
    };

    this.schema.properties.packaged_rate_id = {
      ...this.schema.properties.packaged_rate_id,
      default: '',
      enum: [''],
      enumNames: [''],
    };

    for (const packagedRatePlan of props.packagedRatePlans) {
      this.schema.properties.packaged_rate_id.enum.push(packagedRatePlan.id);
      this.schema.properties.packaged_rate_id.enumNames.push(packagedRatePlan.name);
    }

    const uiSchema = { ...defaultUiSchema };

    if (props.ratePlan.is_packaged_rate) {
      uiSchema.packaged_rate_id = {
        'ui:widget': 'hidden',
      };
    }

    if (props.ratePlan.default_plan) {
      uiSchema.status = {
        'ui:disabled': true,
        'ui:help': "You can't change default rate plan status.",
      };
    }

    if (props.ratePlan.type_of_pay === 'deposit') {
      uiSchema.deposit_percent = {};
    }

    if (props.ratePlan.bundle_and_save_discount_only) {
      uiSchema.bundle_discount_percent = {};
    }

    uiSchema.inclusions_override = {
      ...uiSchema.inclusions_override,
    };

    this.state = {
      uiSchema,
      deleteState: 'delete',
      ratePlan: mapRatePlanFormData(props.ratePlan),
      saveState: buttonStates.default,
      modalIsOpen: false,
    };
  }

  findUsage = async () => {
    const { properties, offers } = await this.fetchData();

    const relatedRoomRates = properties.reduce((acc, property) => {
      property?.room_types?.forEach((roomType) => {
        roomType.room_rates.forEach((roomRate) => {
          if (roomRate?.rate_plan.id === this.props.ratePlanId) {
            acc.add(roomRate.id);
          }
        });
      });
      return acc;
    }, new Set());

    const rateOffers = offers.filter((offer) => {
      return offer.packages.some(
        (p) =>
          relatedRoomRates.has(p.fk_room_rate_id) ||
          p.package_options?.some((o) => relatedRoomRates.has(o.fk_room_rate_id)),
      );
    });

    return rateOffers.length;
  };

  async fetchData() {
    const [properties, offers] = await Promise.all([
      ReservationService.getProperties(this.vendorId),
      OffersService.getOffers({
        queryString: this.vendorId,
      }),
    ]).catch(function (e) {
      reportError(e);
    });

    return {
      properties: properties.result,
      offers: offers.result,
    };
  }

  onSubmit = (form) => {
    const { history, ratePlanId } = this.props;

    form.errors = [];
    form.errorSchema = {};
    var self = this;

    const formData = {
      ...form.formData,
      packaged_rate_id: form.formData.packaged_rate_id || null,
    };

    this.setState({ saveState: buttonStates.saving, ratePlan: formData });
    delete formData.schedules; // not expected in update/create

    const toSubmit = {
      ...formData,
      bonus_inclusions: (formData.bonus_inclusions || []).map(
        ({ content, from_nights, to_nights, content_override }) => ({
          content,
          content_override,
          from_nights,
          to_nights,
        }),
      ),
      custom_vcc_payment_currency:
        formData?.custom_vcc_payment_currency && formData.custom_vcc_payment_currency !== ''
          ? formData.custom_vcc_payment_currency
          : null,
      currency: formData?.currency && formData.currency !== '' ? formData.currency : null,
      deposit_percent: formData.type_of_pay === 'deposit' ? formData.deposit_percent : 0,
      schedules_list: prepareSchedulesList(formData?.schedules_list, ratePlanId),
      discounts: prepareScheduledDiscountList(formData.discounts),
    };

    // Remove empty values so validation passes
    if (toSubmit.type_of_pay === '') {
      delete toSubmit.type_of_pay;
    }
    if (toSubmit.vcc_buffer === '') {
      delete toSubmit.vcc_buffer;
    }

    const submitType = ratePlanId ? 'updateRatePlan' : 'createRatePlan';

    ReservationService[submitType](toSubmit, ratePlanId)
      .then(({ result }) => {
        history.push(`/vendors/${this.vendorId}/rate-plans/${result.id}`);
      })
      .catch(function (e) {
        self.setState({ saveState: buttonStates.failed });
        reportError(e);
        self.props.enqueueSnackbar(e?.errors[0] || e.message || 'Unable to save rate plan!', {
          variant: 'error',
        });
      });
  };

  updateBonusInclusions = (bonusInclusions) => {
    const shouldMarkAsSetToMax = bonusInclusions?.find(
      (inclusions) => inclusions.to_nights === BONUS_INCLUSIONS_MAX_TO_NIGHTS && inclusions.set_to_max === undefined,
    );

    if (shouldMarkAsSetToMax) {
      shouldMarkAsSetToMax.set_to_max = true;
    }

    const toSetToMax = bonusInclusions?.find((inclusions) => inclusions.set_to_max);
    if (toSetToMax) {
      toSetToMax.to_nights = BONUS_INCLUSIONS_MAX_TO_NIGHTS;
    }

    const toRemoveToNights = bonusInclusions?.find(
      (inclusions) => !inclusions.set_to_max && inclusions.to_nights === BONUS_INCLUSIONS_MAX_TO_NIGHTS,
    );
    if (toRemoveToNights) {
      toRemoveToNights.to_nights = toRemoveToNights.from_nights;
    }
  };

  onChange = (edit) => {
    const newState = {
      ratePlan: edit.formData,
    };

    if (edit.formData.bonus_inclusions) {
      this.updateBonusInclusions(edit.formData.bonus_inclusions);
    }

    if (this.state.saveState !== buttonStates.default) {
      newState.saveState = buttonStates.default;
    }

    if (newState.ratePlan.type_of_pay !== this.state.ratePlan.type_of_pay) {
      newState.uiSchema = {
        ...this.state.uiSchema,
        deposit_percent:
          newState.ratePlan.type_of_pay === 'deposit'
            ? {}
            : {
                'ui:widget': 'hidden',
              },
      };
    }

    if (newState.ratePlan.is_packaged_rate !== this.state.ratePlan.is_packaged_rate) {
      newState.uiSchema = {
        ...this.state.uiSchema,
        packaged_rate_id: newState.ratePlan.is_packaged_rate
          ? {
              'ui:widget': 'hidden',
            }
          : {},
      };
    }

    if (newState.ratePlan.bundle_and_save_discount_only !== this.state.ratePlan.bundle_and_save_discount_only) {
      newState.uiSchema = {
        ...this.state.uiSchema,
        bundle_discount_percent: !newState.ratePlan.bundle_and_save_discount_only
          ? {
              'ui:widget': 'hidden',
            }
          : {
              'ui:title': 'Discount for Bundle Offer (%)',
            },
      };
    }

    if (newState.inclusions_override !== this.state.inclusions_override) {
      newState.uiSchema = {
        ...this.state.uiSchema,
        promotions: edit.formData.map((promo) => ({
          ...promo,
          inclusions: (promo.inclusions ?? []).map((inclusion) => ({
            text: inclusion.text ?? '',
            category: inclusion.category ?? '',
            icon: inclusion.icon ?? '',
            isHighlighted: inclusion.isHighlighted ?? false,
          })),
        })),
      };
    }

    this.setState(nullsToEmptyStrings(newState));
  };

  onDelete = async () => {
    this.setState({ deleteState: 'deleting' });
    const ratePlanIsUsed = await this.findUsage();

    if (ratePlanIsUsed) {
      this.setState({ deleteState: 'failed' });
      this.props.enqueueSnackbar(
        'This rate plan is currently attached to an offer. You must detach it from any offers before you can delete.',
        {
          variant: 'error',
        },
      );
      return false;
    }
    const { history } = this.props;

    var self = this;

    ReservationService.deleteRatePlan(this.props.ratePlanId)
      .then(function () {
        history.push(`/vendors/${self.vendorId}`);
      })
      .catch((e) => {
        reportError(e);
        this.setState({ deleteState: 'failed' });
        this.props.enqueueSnackbar(e?.errors[0] || e.message || 'Error! Unable to delete rate plan.', {
          variant: 'error',
        });
      });
  };

  getRegionNameFromCode(code) {
    const region = libRegions.getRegionByCode(code, 'luxuryescapes');
    return region ? region.name : code;
  }

  setRegions(regions) {
    let isAllRegionsSelected = false;
    if (regions.length === libRegions.getRegions().length) {
      isAllRegionsSelected = true;
    }

    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        regions: isAllRegionsSelected ? ['world'] : regions,
      },
      modalIsOpen: false,
    }));
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  showModal() {
    this.setState({ modalIsOpen: true });
  }

  handleScheduleChange = (value, id) => {
    const schedules = { ...this.state.ratePlan.schedules_list };
    schedules[id] = value;

    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        schedules_list: schedules,
      },
    }));
  };

  addNewSchedule = () => {
    const schedules = { ...this.state.ratePlan.schedules_list };
    schedules[uuid()] = {
      // we don't set the id in the object, it's only used for the key
      activePeriod: {
        from: null,
        to: null,
      },
      travelPeriod: {
        from: null,
        to: null,
      },
      type: 'sell',
      parent: this.state.ratePlan.id,
    };
    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        schedules_list: schedules,
      },
    }));
  };

  removeSchedule = (index) => {
    const schedules = { ...this.state.ratePlan.schedules_list };
    delete schedules[index];
    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        schedules_list: schedules,
      },
    }));
  };

  handleScheduledDiscountChange = (value, id) => {
    const discounts = { ...this.state.ratePlan.discounts };
    discounts[id] = value;

    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        discounts,
      },
    }));
  };

  addNewScheduledDiscount = () => {
    const discounts = { ...this.state.ratePlan.discounts };
    discounts[uuid()] = {
      rate_plan_id: this.props.ratePlanId,
      discount_name: null,
      is_displayed: true,
      source: 'internal',
      paid_by: 'vendor', // only vendor is supported in backend currently
      discount_type: 'long_stays',
      discount_percent: 0,
      min_los: null,
      max_los: null,
      min_days_to_arrival: null, // currently not changable in front-end, or used for internal discount
      max_days_to_arrival: null,
      schedules: [
        {
          activePeriod: {
            from: null,
            to: null,
          },
          travelPeriod: {
            from: null,
            to: null,
          },
          type: 'sell',
        },
      ],
    };
    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        discounts,
      },
    }));
  };

  removeScheduledDiscount = (id) => {
    const discounts = { ...this.state.ratePlan.discounts };
    delete discounts[id];
    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        discounts,
      },
    }));
  };

  addStayPayTier = () => {
    const stayPay = {
      id: uuid(),
      rate_plan_id: this.props.ratePlanId,
      stay_nights: null,
      free_nights: null,
    };

    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        stay_pay: [...(prevState.ratePlan.stay_pay || []), stayPay],
      },
    }));
  };

  onStayPayChange = (event) => {
    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        is_stay_pay_enabled: event.target.checked,
      },
    }));
  };

  removeStayPayTier = (id) => {
    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        stay_pay: prevState.ratePlan.stay_pay.filter((stayPay) => stayPay.id !== id),
      },
    }));
  };

  handleStayPayTierChange = (field, value, id) => {
    const stayPay = this.state.ratePlan.stay_pay.map((tier) => {
      if (tier.id === id) {
        return {
          ...tier,
          [field]: value,
        };
      }
      return tier;
    });

    this.setState((prevState) => ({
      ratePlan: {
        ...prevState.ratePlan,
        stay_pay: stayPay,
      },
    }));
  };

  render() {
    const ratePlan = { ...this.state.ratePlan };

    return (
      <Form
        validator={validator}
        schema={this.schema}
        uiSchema={this.state.uiSchema}
        onChange={this.onChange}
        formData={ratePlan}
        onSubmit={this.onSubmit}
        widgets={widgets}
        formContext={{
          rateTypeValue: ratePlan.rate_type,
          bonusInclusions: ratePlan.bonus_inclusions,
        }}
      >
        <div className="regions-tag-container">
          <div>
            <label className="control-label">Package Regions</label>
          </div>
          <div>
            <ul className="region-tags-list">
              {!ratePlan.regions || ratePlan.regions.length === libRegions.getRegions().length ? (
                <li key="world">world</li>
              ) : (
                ratePlan.regions.map((region) => <li key={region}>{this.getRegionNameFromCode(region)}</li>)
              )}
            </ul>
          </div>
          <div>
            <Button variant="contained" onClick={this.showModal.bind(this)}>
              Set Regions
            </Button>
          </div>
        </div>

        <Box>
          <Typography variant="h6">Schedules</Typography>
          {this.state.ratePlan.schedules_list &&
            Object.keys(this.state.ratePlan.schedules_list).map((key) => (
              <Grid key={key} spacing={2} container alignItems="center">
                <Grid item xs={11}>
                  <Schedules
                    key={key}
                    index={key}
                    value={this.state.ratePlan.schedules_list[key]}
                    onChange={(value) => this.handleScheduleChange(value, key)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton color="error" onClick={() => this.removeSchedule(key)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="info"
              size="large"
              variant="contained"
              onClick={this.addNewSchedule}
              my={2}
              sx={{ minWidth: 200, alignSelf: 'end', width: '25%' }}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6">Promotional Discounts (Only affect Villas Pricing!)</Typography>
          {this.state.ratePlan.discounts &&
            Object.keys(this.state.ratePlan.discounts).map((key) => (
              <Grid key={key} spacing={2} container alignItems="center">
                <Grid item xs={11}>
                  <ScheduledDiscount
                    key={key}
                    index={key}
                    value={this.state.ratePlan.discounts[key]}
                    onChange={(value) => this.handleScheduledDiscountChange(value, key)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton color="error" onClick={() => this.removeScheduledDiscount(key)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="info"
              size="large"
              variant="contained"
              onClick={this.addNewScheduledDiscount}
              my={2}
              sx={{ minWidth: 200, alignSelf: 'end', width: '25%' }}
            >
              <AddIcon /> Add Promotional Discount
            </Button>
          </Box>
        </Box>

        <FormControlLabel
          control={
            <Checkbox checked={ratePlan.is_stay_pay_enabled} onChange={(event) => this.onStayPayChange(event)} />
          }
          label="Enable Stay Pay"
        />
        <Paper>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Stay Pay Tiers
          </Typography>

          {this.state.ratePlan.stay_pay?.length > 0 &&
            this.state.ratePlan.stay_pay.map((tier, key) => (
              <Paper key={key} sx={{ py: 1 }}>
                <Grid spacing={2} container alignItems="center">
                  <Grid item xs={1}>
                    <Typography variant="p">Tier {key + 1}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Stay Nights"
                      type="number"
                      onChange={(event) => this.handleStayPayTierChange('stay_nights', event.target.value, tier.id)}
                      value={tier.stay_nights}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Free Nights"
                      type="number"
                      onChange={(event) => this.handleStayPayTierChange('free_nights', event.target.value, tier.id)}
                      value={tier.free_nights}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton color="error" onClick={() => this.removeStayPayTier(tier.id)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}

          {this.state.ratePlan.stay_pay?.length === 0 && <Typography variant="p">No Stay Pay Tiers</Typography>}
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Button
              color="info"
              size="large"
              variant="contained"
              onClick={this.addStayPayTier}
              my={2}
              sx={{ minWidth: 200, alignSelf: 'end', width: '50%' }}
            >
              <AddIcon /> Add Stay Pay Tier
            </Button>
          </Box>
        </Paper>

        <div className="button-container">
          <Button type="submit" variant="contained" className={this.state.saveState}>
            {buttonMessages[this.state.saveState]}
          </Button>
          <Box ml={2}>
            {this.props.ratePlanId ? (
              <DeleteButton onClick={this.onDelete} buttonText={this.state.deleteState} />
            ) : null}
          </Box>
        </div>

        <SetRegionModal
          open={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          onSet={this.setRegions.bind(this)}
          regions={ratePlan.regions ?? ['world']}
          name="Package Regions"
          label="Tag all regions that you want this package to be visible in"
        />
      </Form>
    );
  }
}

export default withRouter(withSnackbar(RatePlanForm));
