import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button, Grid } from '@mui/material';

import { withTenant } from '~/components/hoc';

import OffersService from '~/services/OffersService';

import { STATUS_APPROVED } from './Constants';
import TemplateEditContainer from './TemplateEditContainer';
import TemplateTableContainer from './TemplateTableContainer';

function TemplatesContainer() {
  const [currTemplate, setTemplateWeAreCurrentlyEditing] = useState<App.OfferTemplate>();
  const [templates, setTemplates] = useState<App.OfferTemplate[]>([]);
  const [isOnTablePage, setIsOnTablePage] = useState(false);
  const [isOnTemplateEditPage, setIsOnTemplateEditPage] = useState(false);

  useEffect(() => {
    async function fetchTemplates() {
      const result = await OffersService.getOfferTemplates();
      setTemplates(result);
    }
    fetchTemplates();
  }, []);

  function goToMenu() {
    if (isOnTemplateEditPage) {
      setIsOnTemplateEditPage(false);
      return;
    }
    if (isOnTablePage) {
      setIsOnTablePage(false);
    }
  }

  function resetContainer() {
    goToMenu();
    setTemplateWeAreCurrentlyEditing(undefined);
  }

  function doesThisTemplateExist(templateId) {
    if (templateId !== 0) {
      return true;
    }
    return false;
  }

  async function submitUpsert(templateToUpsert: App.OfferTemplate) {
    //upsertion returns rows affected on update, and row inserted (with details) on insertion, this will be used to update state
    const result = await OffersService.upsertOfferTemplate(templateToUpsert);

    const templateAlreadyExists = doesThisTemplateExist(templateToUpsert.id);
    const newTemplates = [...templates];
    if (templateAlreadyExists) {
      const index = newTemplates.findIndex((template) => template.id === templateToUpsert.id);
      newTemplates[index] = templateToUpsert;
    }
    if (!templateAlreadyExists) {
      newTemplates.push(result.result);
    }
    setTemplates(newTemplates);
    resetContainer();
  }

  function onEdit(id: number) {
    const template = templates.find((template) => template.id === id);
    setTemplateWeAreCurrentlyEditing(template);
    setIsOnTemplateEditPage(true);
  }

  function onApprove(id: number) {
    const newTempates = [...templates];
    const index = newTempates.findIndex((template) => template.id === id);
    newTempates[index].status = newTempates[index].status === STATUS_APPROVED ? 'draft' : 'approved';

    OffersService.upsertOfferTemplate(newTempates[index]);
    setTemplates(newTempates);
  }

  function onDelete(id: number) {
    const sure = confirm('Are you sure you want to delete this template? It will be deleted permanently.');
    if (!sure) {
      return;
    }

    OffersService.deleteOfferTemplate(id);

    const index = templates.findIndex((template) => template.id === id);
    const updatedTemplates = templates.slice(index, 1);
    setTemplates(updatedTemplates);
  }

  return (
    <div className="templates-container">
      <Helmet>
        <title>Templates</title>
      </Helmet>
      {(isOnTablePage || isOnTemplateEditPage) && (
        <Button
          onClick={resetContainer}
          variant="text"
          startIcon={<ArrowBackIosNewIcon />}
          color="secondary"
          size="large"
        >
          {isOnTemplateEditPage ? 'Back (resets data)' : 'Back'}
        </Button>
      )}
      {!isOnTemplateEditPage && !isOnTablePage && (
        <Grid container direction="row" justifyContent="space-evenly">
          <Grid item sm={3}>
            <Button fullWidth variant="contained" onClick={() => setIsOnTemplateEditPage(true)}>
              Create
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button fullWidth variant="contained" color="success" onClick={() => setIsOnTablePage(true)}>
              Edit/Delete
            </Button>
          </Grid>
        </Grid>
      )}

      {isOnTemplateEditPage && <TemplateEditContainer submit={submitUpsert} propsTemplate={currTemplate} />}

      {!isOnTemplateEditPage && isOnTablePage && (
        <TemplateTableContainer templates={templates} onEdit={onEdit} onDelete={onDelete} onApprove={onApprove} />
      )}
    </div>
  );
}

export default withRouter(withTenant(TemplatesContainer));
