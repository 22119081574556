import React from 'react';

import { Helmet } from 'react-helmet';

import { dateNowUtc, formatDateISO } from '~/services/TimeService';

import { getOrder } from '../../../services/OrdersService';
import ReservationService from '../../../services/ReservationService';
import Payment from '../../Common/Payment';

class OrderPaymentSurcharge extends React.Component {
  handleOrder = async (cart) => {
    const { offers, customer, orderId, transactionKey } = cart;

    const promises = [];

    offers.forEach((offer) =>
      offer.items.forEach((item) => {
        const data = {
          customer_email: customer.email,
          customer_id: customer.memberId,
          transaction_key: transactionKey,
          check_in: formatDateISO(dateNowUtc(item.reservation.checkIn)),
          check_out: formatDateISO(dateNowUtc(item.reservation.checkOut)),
          number_of_adults: item.reservation.numAdults,
          number_of_children: item.reservation.numChildren,
          number_of_infants: item.reservation.numInfants || 0,
          guest_first_name: item.reservation.firstname,
          guest_last_name: item.reservation.lastname,
          guest_special_requests: item.reservation.guestSpecialRequests,
          is_surcharge_waived: !!item.reservation.waivedSurchargeAmount,
          peak_period_surcharge: item.reservation.durationSurchargeTotal,
          extra_guest_surcharge: item.reservation.extraGuestSurcharge,
        };

        promises.push(ReservationService.createReservation(data, orderId, item.id));
      }),
    );

    await Promise.all(promises);

    const orderResponse = await getOrder(orderId);
    return orderResponse.result;
  };

  render() {
    return (
      <div className="container">
        <Helmet>
          <title>Orders | Order surcharge payment</title>
        </Helmet>
        <h1>Order surcharge payment</h1>
        <Payment handleOrder={this.handleOrder} />
      </div>
    );
  }
}

export default OrderPaymentSurcharge;
