import React, { useState } from 'react';

import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';

const renderConcatenatedCell = (codeField: string | null, nameField: string | null): string =>
  `${codeField ?? ''} ${nameField && codeField ? ' : ' : ''} ${nameField ?? ''}`;

const commonColumnProps = {
  sortable: true,
  disableColumnMenu: true,
};

const nameColumns: GridColDef[] = [
  {
    field: 'property_name',
    headerName: 'Property Name',
    flex: 1,
    display: 'flex',
    ...commonColumnProps,
  },
  {
    field: 'room_type_name',
    headerName: 'Room Type',
    renderCell: ({ row }: GridRenderCellParams) => renderConcatenatedCell(row.room_type_code, row.room_type_name),
    flex: 1.3,
    display: 'flex',
    ...commonColumnProps,
  },
  {
    field: 'room_rate_id',
    headerName: 'Room Rate',
    flex: 1,
    display: 'flex',
    ...commonColumnProps,
  },
  {
    field: 'rate_plan_name',
    headerName: 'Rate Plan',
    renderCell: ({ row }: GridRenderCellParams) => renderConcatenatedCell(row.rate_plan_code, row.rate_plan_name),
    flex: 1.3,
    display: 'flex',
    ...commonColumnProps,
  },
];

const PropertyRelationsSearchResults = ({
  onCellClick,
  total = 0,
  data = [],
}: {
  onCellClick?: (params: GridCellParams) => void;
  total?: number;
  data?: any[];
}) => {
  const [page, setPage] = useState(0);
  const searchResult = Array.isArray(data) ? data : [data];
  const sizePerPage = 25;
  const paginationModel = {
    page,
    pageSize: sizePerPage,
  };

  return (
    <DataGrid
      rows={searchResult}
      columns={nameColumns}
      getRowId={(row) => row.id ?? (Math.random() * 10000).toString()}
      onCellClick={onCellClick}
      rowCount={total}
      pagination
      paginationMode="client"
      onPaginationModelChange={({ page }) => setPage(page)}
      pageSizeOptions={[sizePerPage]}
      paginationModel={paginationModel}
      slots={{ pagination: GridPagination }}
      disableColumnFilter
      autoHeight
    />
  );
};

export default PropertyRelationsSearchResults;
