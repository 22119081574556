import React, { useMemo } from 'react';

import { ErrorListProps } from '@rjsf/utils';

import { Alert, AlertTitle, Card, CardContent, Container, Stack } from '@mui/material';

interface AlertEntity {
  key: string;
  title: string;
  message: string;
}

export default function ErrorsAlertTemplate(props: ErrorListProps) {
  const { errors } = props;
  const alerts = useMemo<AlertEntity[]>(
    () =>
      errors.map((e) => {
        let title = e.name ?? '';
        if (e.property) {
          title += e.property
            .replace(/^\./, '')
            .match(/([a-zA-Z]+\.\d+|([a-zA-Z]+)$)/gis)
            .map(
              (match) =>
                match
                  .replace(/\.\d+$/g, (match) => ` ${Number(match.substring(1)) + 1}`)
                  .replace('.', ' ')
                  .replace(/([A-Z])/g, ' $1') // camelCase to space-seperated
                  .toLowerCase(), // CSS will take care of casing
            )
            .join(' | ');
        }

        return {
          key: e.stack,
          title,
          message: e.message,
        };
      }),
    [errors],
  );

  return (
    <Container>
      <Card>
        <CardContent>
          <Stack direction="column" gap={1}>
            {alerts.map((alert) => (
              <Alert key={alert.key} severity="error">
                <AlertTitle sx={{ textTransform: 'capitalize' }}>{alert.title}</AlertTitle>
                {alert.message}
              </Alert>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}
