import React from 'react';

import { Link } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef, GridDeleteIcon, getGridDateOperators, getGridStringOperators } from '@mui/x-data-grid';

import { deleteFlightDeal } from '~/services/FlightsService';
import { formatDateShort } from '~/services/TimeService';

import { reportError } from '~/utils/reportError';

import ConfirmButton from '../Common/Elements/ConfirmButton';

const sizePerPage = 20;

const getDate = (val) => formatDateShort(val);
const getName = (val) => (val?.length > 20 ? val.substring(0, 20) + '...' : val);
const getActionButtons = (row, onDelete: (id: string) => void) => {
  return (
    <div>
      <Button component={Link} variant="contained" to={`/flights/flight-deal/${row.id}/edit`}>
        <SearchIcon /> Edit
      </Button>
      <ConfirmButton
        confirmTitle="Delete deal"
        confirmQuestion="Are you sure you want to delete this deal?"
        confirmAnswer="Yes, delete"
        onConfirm={() => onDelete(row.id)}
        color="error"
        variant="contained"
      >
        <GridDeleteIcon /> Delete
      </ConfirmButton>
    </div>
  );
};

const routeFormatter = (row) => `${row.origin_airport_code} - ${row.destination_airport_code}`;

const salesPeriodFormatter = (row) => `${getDate(row.sales_start_date)} - ${getDate(row.sales_end_date)}`;

const travelPeriodFormatter = (row) => {
  const travelPeriods = [];

  if (row.departure_date_1 && row.arrival_date_1) {
    travelPeriods.push({
      start: row.departure_date_1,
      end: row.arrival_date_1,
    });
  }

  if (row.departure_date_2 && row.arrival_date_2) {
    travelPeriods.push({
      start: row.departure_date_2,
      end: row.arrival_date_2,
    });
  }

  if (row.departure_date_3 && row.arrival_date_3) {
    travelPeriods.push({
      start: row.departure_date_3,
      end: row.arrival_date_3,
    });
  }

  return (
    <div>
      {travelPeriods.map((period, index) => (
        <>
          {index > 0 && ', '}
          {getDate(period.start)} - {getDate(period.end)}
        </>
      ))}
    </div>
  );
};

export const MerchandisingFlightDealsTable = ({
  isLoading,
  onPageChange,
  total,
  page,
  records,
  onDelete,
  onFilterChange,
  onSortChange,
  activeFilters,
  activeSorts,
}) => {
  const DeleteHandler = (id: string) => {
    deleteFlightDeal(id)
      .then(() => {
        onDelete();
      })
      .catch((e) => {
        reportError(e);
      });
  };

  const COLUMNS: GridColDef[] = [
    {
      field: 'deal_name',
      headerName: 'Deal Name',
      renderCell: ({ row }) => getName(row.deal_name),
      flex: 3,
      display: 'flex',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: 'category_group',
      headerName: 'Category',
      flex: 2,
      display: 'flex',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: 'region',
      headerName: 'Region',
      flex: 1,
      display: 'flex',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: 'fare_type',
      headerName: 'Type',
      flex: 2,
      display: 'flex',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: 'carrier_code',
      headerName: 'Airline Code',
      flex: 2,
      display: 'flex',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: 'route',
      headerName: 'Route',
      renderCell: ({ row }) => routeFormatter(row),
      flex: 2,
      display: 'flex',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: 'sales_period',
      headerName: 'Sales Period',
      renderCell: ({ row }) => salesPeriodFormatter(row),
      flex: 4,
      display: 'flex',
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === 'onOrAfter' || operator.value === 'onOrBefore',
      ),
    },
    {
      field: 'travel_period',
      headerName: 'Travel Periods',
      renderCell: ({ row }) => travelPeriodFormatter(row),
      flex: 6,
      display: 'flex',
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === 'onOrAfter' || operator.value === 'onOrBefore',
      ),
    },
    {
      field: 'id',
      headerName: '',
      renderCell: ({ row }) => getActionButtons(row, DeleteHandler),
      flex: 4,
      display: 'flex',
    },
  ];

  return (
    <Box sx={{ mb: 1 }}>
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        columns={COLUMNS}
        loading={isLoading}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        pageSizeOptions={[10]}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        sortingMode="server"
        onSortModelChange={onSortChange}
        sortModel={activeSorts}
        filterModel={activeFilters}
        pagination
        paginationMode="server"
        paginationModel={{
          page: page,
          pageSize: sizePerPage,
        }}
        rows={records}
        rowCount={total}
      />
    </Box>
  );
};

export default MerchandisingFlightDealsTable;
