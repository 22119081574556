import React from 'react';

import { useSnackbar } from 'notistack';

import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';

import { copyToClipboard } from './utils';

export default function TemplateForCSButton({ bookingDetails, toggleSummary }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = () => {
    copyToClipboard(bookingDetails);
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  };
  return (
    <Box>
      <IconButton title="Copy user details" onClick={handleCopyClick}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
      <Button
        variant="text"
        size="small"
        onClick={toggleSummary}
        title="CS often need to send this info to vendors. This makes it easy to copy/paste."
      >
        Template for CS
      </Button>
    </Box>
  );
}
