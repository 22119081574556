import React, { useMemo } from 'react';

import { Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';
import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';

import RebookInfo from '../OrderDetail/RebookInfo';

import {
  customerDataFormatter,
  internalInformationFormatter,
  orderInformationFormatter,
  orderLinkFormatter,
  packageInformationFormatter,
} from './formatters';

interface Props {
  orders: API.Order.Order[];
  rebookableData: Record<string, App.Bedbank.ReservationRebookInfo[]> | null;
  isRebookableFilter: boolean;
  total: number;
  page: number;
  sizePerPage: number;
  onPageChange: (page: number) => void;
  rerender: () => void;
}

const rebookableFormatter = (rebookableData: Record<string, App.Bedbank.ReservationRebookInfo[]>) => {
  return (params: GridRenderCellParams<Order.Order>) => {
    return (
      <Stack direction="column" spacing={2}>
        {params.row.bedbank_items
          .flatMap((r) => rebookableData?.[r.id_reservation])
          .filter(Boolean)
          .flatMap((d, index) => (
            <RebookInfo
              key={index}
              roomIndex={index + 1}
              rebookableData={d}
              currencyCode={params.row.currency_code}
              shortInfo
            />
          ))}
      </Stack>
    );
  };
};

export default function BedbankPage(props: Props) {
  const { orders, total, sizePerPage, onPageChange, page, isRebookableFilter, rebookableData } = props;

  const columnsData = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'fk_customer_id',
        headerName: 'Customer',
        sortable: false,
        width: 250,
        renderCell: customerDataFormatter,
        display: 'flex',
      },
      {
        field: 'order_information',
        headerName: 'Order information',
        sortable: false,
        flex: 3,
        renderCell: orderInformationFormatter,
        display: 'flex',
      },
      {
        field: 'package_information',
        headerName: 'Package Information',
        sortable: false,
        flex: 2,
        renderCell: packageInformationFormatter,
        display: 'flex',
      },
      {
        field: 'internal_information',
        headerName: 'Internal Information',
        sortable: false,
        flex: 3,
        renderCell: internalInformationFormatter,
        display: 'flex',
      },
    ];

    if (isRebookableFilter) {
      columns.push({
        field: 'rebookable',
        headerName: 'Rebookable',
        sortable: false,
        flex: 3,
        renderCell: rebookableFormatter(rebookableData),
        display: 'flex',
      });
    }

    columns.push({
      field: 'id',
      headerName: 'View Order',
      sortable: false,
      width: 120,
      renderCell: orderLinkFormatter,
      display: 'flex',
    });

    return columns;
  }, [isRebookableFilter, rebookableData]);

  return (
    <DataGrid
      rows={orders}
      rowCount={total}
      columns={columnsData}
      pagination
      paginationMode="server"
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      pageSizeOptions={[sizePerPage]}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      slots={{ pagination: GridPagination }}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      getRowHeight={() => 'auto'}
      autoHeight
    />
  );
}
