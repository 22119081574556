import { useFormContext } from 'react-hook-form';

// Note: this is a pattern documented by react-hook-form
// https://react-hook-form.com/advanced-usage#ConnectForm
// it provides a mechanism for distributing react-hook-form actions (e.g. register etc.) from a form context to deeply nested components
const ConnectForm = ({ children }) => {
  const methods = useFormContext();
  return children({ ...methods });
};

export default ConnectForm;
