interface getPromoTagArraysProps {
  promoTags: Array<App.PromoTag>;
  selectedDepartment: string | null;
  selectedCategory: string | null;
}

const promoTagSort = (a, b) => (a === 'N/A' ? 1 : b === 'N/A' ? -1 : a.localeCompare(b));
/**
 *
 * @param promoTags from svc-promo getPromoMeta endpoint
 * @returns Arrays for department, category, and subcategory tags
 */
export function getPromoTagArrays({
  promoTags,
  selectedDepartment,
  selectedCategory,
}: getPromoTagArraysProps): App.PromoTagArrays {
  const departmentTags: Array<string> = Array.from(new Set(promoTags.map((pt) => pt.department_tag) ?? [])).sort(
    promoTagSort,
  );
  const categoryTags: Array<string> = Array.from(
    new Set(
      promoTags
        .filter((pt) => pt.department_tag == selectedDepartment && !!pt.category_tag)
        .map((pt) => pt.category_tag) ?? [],
    ),
  ).sort(promoTagSort);
  const subCategoryTags: Array<string> = Array.from(
    new Set(
      promoTags
        .filter(
          (pt) =>
            pt.department_tag == selectedDepartment && pt.category_tag == selectedCategory && !!pt.sub_category_tag,
        )
        .map((pt) => pt.sub_category_tag) ?? [],
    ),
  ).sort(promoTagSort);

  return {
    departmentTags,
    categoryTags,
    subCategoryTags,
  };
}
