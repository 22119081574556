import React from 'react';

import getEarnOrderPartnerships from '~/utils/getEarnOrderPartnerships';

import BalancePaidReportForm from '../Common/Forms/BalancePaidReportForm';
import DepositInstalmentRefundReportForm from '../Common/Forms/DepositInstalmentRefundReportForm';
import ExportCreditsMovementsForm from '../Common/Forms/ExportCreditsMovementsForm';
import ExportCreditsSummaryForm from '../Common/Forms/ExportCreditsSummaryForm';
import ExportFinanceRecReportForm from '../Common/Forms/ExportFinanceRecReportForm';
import ExportItemsPurchasedForm from '../Common/Forms/ExportItemsPurchasedForm';
import ExportLoyaltyEarnForm from '../Common/Forms/ExportLoyaltyEarnForm';
import ExportLoyaltyRewardsEarnReportForm from '../Common/Forms/ExportLoyaltyRewardsEarnReportForm';
import ExportPromoCodeSpendForm from '../Common/Forms/ExportPromoCodeSpendForm';
import ExportRefundsForm from '../Common/Forms/ExportRefundsForm';
import ExportStripeBalanceTransactionsForm from '../Common/Forms/ExportStripeBalanceTransactionsForm';
import ExportTransactionsForm from '../Common/Forms/ExportTransactionsForm';
import ExportTravelVouchersSoldReportForm from '../Common/Forms/ExportTravelVouchersSoldReportForm';
import ExportXeroTrialBalanceForm from '../Common/Forms/ExportXeroTrialBalanceForm';
import GrossDepositReportForm from '../Common/Forms/GrossDepositReportForm';
import OutstandingDepositBalanceReportForm from '../Common/Forms/OutstandingDepositBalanceReportForm';
import ExportLebtCreditForm from '../Common/Forms/businessTraveller/ExportLebtCreditForm';

import ContentWithSidebar from './ContentWithSidebar';

const DEFAULT_REPORT = 'credit-summary';

const reports = [
  {
    title: 'Transaction Report',
    slug: 'transactions',
    component: ExportTransactionsForm,
  },
  {
    title: 'Items Purchased report',
    slug: 'items-purchased',
    component: ExportItemsPurchasedForm,
  },
  {
    title: 'Items Refund report',
    slug: 'items-refunded',
    component: ExportRefundsForm,
  },
  {
    title: 'Credit movements',
    slug: 'credit-movements',
    component: ExportCreditsMovementsForm,
  },
  {
    title: 'Credit summary report',
    slug: 'credit-summary',
    component: ExportCreditsSummaryForm,
  },
  {
    title: 'LEBT Credit report',
    slug: 'lebt-credit',
    component: ExportLebtCreditForm,
  },
  {
    title: 'Promo Code Spend report',
    slug: 'promo-codes-spend',
    component: ExportPromoCodeSpendForm,
  },
  {
    title: 'Travel Vouchers Sold',
    slug: 'travel-vouchers-sold',
    component: ExportTravelVouchersSoldReportForm,
  },
  {
    title: 'Finance Reconciliation Report',
    slug: 'finance-reconciliation',
    component: ExportFinanceRecReportForm,
  },
  {
    title: 'Stripe Balance Transactions Report',
    slug: 'stripe-balance-transactions',
    component: ExportStripeBalanceTransactionsForm,
  },
  {
    title: 'Xero Trial Balance',
    slug: 'xero-trial-balance',
    component: ExportXeroTrialBalanceForm,
  },
  {
    title: 'Outstanding Deposit Balance Report',
    slug: 'outstanding-deposit-balance',
    component: OutstandingDepositBalanceReportForm,
  },
  {
    title: 'Gross Deposits And Instalments Report',
    slug: 'gross-deposits',
    component: GrossDepositReportForm,
  },
  {
    title: 'Balance Paid Report',
    slug: 'balance-paid',
    component: BalancePaidReportForm,
  },
  {
    title: 'Deposits and Instalments Refund Report',
    slug: 'deposit-instalments-refunds',
    component: DepositInstalmentRefundReportForm,
  },
];

type Props = {
  partnerships: unknown;
};

export default function MonthReportsPage(props: Props) {
  const partnershipReports = [
    {
      title: 'Rewards Point Earn Report - Expenses',
      slug: 'rewards-points-earn-expenses',
      component: ExportLoyaltyEarnForm,
      componentProps: {
        partnerships: getEarnOrderPartnerships(props.partnerships),
      },
    },
    {
      title: 'Rewards Point Earn Report - Liabilities',
      slug: 'rewards-points-earn-liabilities',
      component: ExportLoyaltyRewardsEarnReportForm,
      componentProps: {
        partnerships: getEarnOrderPartnerships(props.partnerships),
      },
    },
  ];

  return (
    <ContentWithSidebar
      items={[...reports, ...(props.partnerships ? partnershipReports : [])]}
      defaultItem={DEFAULT_REPORT}
    />
  );
}
