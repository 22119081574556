import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import { Box, Button } from '@mui/material';

import { StoplistFormModal } from '~/components/Stoplist/StoplistFormModal';

import { addStoplistGuest, deleteStoplistGuest, getStoplistGuests } from '~/services/StoplistService';

import Spinner from '../Common/Spinner';

import { StoplistTable } from './StoplistTable';

export type Guest = {
  guest_id: number;
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  passport_number: string;
  contact_number: string;
  created_at: string;
};

const StoplistPage = () => {
  const [isGuestFormModalOpen, setIsGuestFormModalOpen] = useState(false);
  const [guests, setGuests] = useState<Guest[] | null>(null);
  const [isReady, setIsReady] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = useCallback(async () => {
    setIsReady(false);

    getStoplistGuests()
      .then((data) => {
        setGuests(data.result);
      })
      .finally(() => setIsReady(true));
  }, []);

  const handleDeleteClick = (guestId: string) => {
    deleteStoplistGuest(guestId)
      .then(() => {
        enqueueSnackbar(`Guest ${guestId} deleted successfully`, { variant: 'success' });
        fetchData();
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong. Can't delete guest", { variant: 'error' });
      });
  };

  const handleAddGuestSubmit = async (data: Guest) => {
    try {
      if (!data.dob) {
        delete data.dob;
      }

      await addStoplistGuest(data);
      enqueueSnackbar('Guest added successfully', { variant: 'success' });

      setIsGuestFormModalOpen(false);
      fetchData();
    } catch (e) {
      if (e.errors && e.errors[0].includes('error inserting or updating guest"')) {
        enqueueSnackbar('Error inserting or updating guest', { variant: 'error' });
      } else {
        enqueueSnackbar(e.errors || e.message, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Helmet>
        <title>Stoplist</title>
      </Helmet>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button variant="contained" onClick={() => setIsGuestFormModalOpen(true)}>
          Add guest
        </Button>
      </Box>
      {!isReady && <Spinner />}
      {isReady && <StoplistTable guests={guests} onDelete={handleDeleteClick} />}
      <StoplistFormModal
        isOpen={isGuestFormModalOpen}
        onClose={() => setIsGuestFormModalOpen(false)}
        onSubmit={handleAddGuestSubmit}
      />
    </>
  );
};

export default StoplistPage;
