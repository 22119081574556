import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';

import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from '@mui/material';

import { InternalSupplier, importProperty } from '~/services/AccommodationService';

import AccommodationAddPropertyProgressModal from './AccommodationAddPropertyProgressModal';
import AccommodationPropertiesStatusInput from './AccommodationPropertiesStatusInput';

export interface AccommodationPropertiesSearchInput {
  status?: string;
  propertyName?: string;
  id?: string;
}

const DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT: AccommodationPropertiesSearchInput = {
  status: 'any',
  propertyName: '',
  id: '',
};

interface Props {
  initialInput?: AccommodationPropertiesSearchInput;
  onSubmit: (formObject: Record<string, string>) => void;
  refreshProperties: () => void;
}

export default function AccommodationPropertiesSearchForm({ initialInput, onSubmit, refreshProperties }: Props) {
  const [propertyName, setPropertyName] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
  const [status, setStatus] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.status);
  const [id, setId] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
  const [addPropertyModalVisible, setAddPropertyModalVisible] = useState(false);

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      const formObject: Record<string, string> = {};

      if (status && status !== 'any') {
        formObject.status = status;
      }
      if (propertyName) {
        formObject.propertyName = propertyName;
      }
      if (id) {
        formObject.id = id;
      }
      onSubmit(formObject);
    },
    [onSubmit, status, propertyName, id],
  );

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();

    setStatus(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.status);
    setPropertyName(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
    setId(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
  }, []);

  useEffect(() => {
    setStatus(initialInput?.status ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.status);
    setPropertyName(initialInput?.propertyName ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
    setId(initialInput?.id ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
  }, [initialInput]);

  const showAddPropertyModal = useCallback(() => {
    setAddPropertyModalVisible(true);
  }, []);

  const closeAddPropertyModal = useCallback(() => {
    setAddPropertyModalVisible(false);
    refreshProperties();
  }, [refreshProperties]);

  const startImportProperty = useCallback((propertyId: string, internalSupplier: InternalSupplier) => {
    return importProperty(propertyId, internalSupplier);
  }, []);

  return (
    <>
      <form onSubmit={handleSubmission} onReset={handleReset}>
        <Card>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <CardHeader title="Search for Properties" />
            <Button variant="text" size="small" onClick={showAddPropertyModal}>
              Import Property
            </Button>
          </Box>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Property name"
                  value={propertyName}
                  onChange={(e) => setPropertyName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AccommodationPropertiesStatusInput value={status} onChange={setStatus} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Property ID" value={id} onChange={(e) => setId(e.target.value)} fullWidth />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end' }}>
            <Button variant="text" type="reset">
              Reset
            </Button>
            <Button variant="contained" type="submit">
              Search
            </Button>
          </CardActions>
        </Card>
      </form>
      <AccommodationAddPropertyProgressModal
        open={addPropertyModalVisible}
        onClose={closeAddPropertyModal}
        apiCall={startImportProperty}
      />
    </>
  );
}
