import React from 'react';

import { Container as MuiContainer } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import OffersService from '~/services/OffersService';

import { Component } from './Component';

export default class Container extends React.Component {
  state = {
    isLoading: true,
    addon: null,
    addonOptions: [],
    error: null,
    total: 0,
    currentPage: 1,
    sizePerPage: 30,
  };

  constructor(props) {
    super(props);

    this.vendorId = props.match.params.id_vendor;
    this.addonId = props.match.params.id_addon;
  }

  componentDidMount() {
    const { currentPage, sizePerPage } = this.state;

    const promises = [
      OffersService.getVendorAddon(this.vendorId, this.addonId),
      OffersService.getVendorAddonOptions({
        page: currentPage,
        limit: sizePerPage,
        vendorId: this.vendorId,
        addonOpportunityId: this.addonId,
      }),
    ];

    Promise.all(promises)
      .then((responses) => [responses[0].result, responses[1]])
      .then((results) =>
        this.setState({
          isLoading: false,
          addon: results[0],
          addonOptions: results[1].result,
          total: parseInt(results[1].total),
        }),
      )
      .catch((error) => {
        console.warn(error);
        this.setState({
          isLoading: false,
          error: "Something went wrong. Can't load addon details",
        });
      });
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { sizePerPage } = this.state;

      OffersService.getVendorAddonOptions({
        page,
        limit: sizePerPage,
        vendorId: this.vendorId,
        addonOpportunityId: this.addonId,
      }).then((response) =>
        this.setState({
          addonOptions: response.result,
          total: parseInt(response.total),
        }),
      );
    });
  };

  render() {
    const { isLoading, addon, addonOptions, error, total, currentPage, sizePerPage } = this.state;

    return (
      <MuiContainer maxWidth="xl">
        {error && <ErrorDisplay message={error} />}

        {isLoading && <Spinner />}

        {!isLoading && !error && (
          <Component
            addon={addon}
            addonOptions={addonOptions}
            total={total}
            currentPage={currentPage}
            sizePerPage={sizePerPage}
            onPageChange={this.onPageChange}
          />
        )}
      </MuiContainer>
    );
  }
}
