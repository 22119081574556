import React from 'react';

import currencyFormatter from 'currency-formatter';
import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import PageSubheader from '../../Common/Elements/PageSubheader';

interface Props {
  offer: {
    result: App.Offer;
  };
}

export default function AddonsPane(props: Props) {
  const getPackageName = (packageData) => {
    if (packageData.number_of_nights) {
      return `${packageData.number_of_nights} nights ${packageData.name}`;
    } else {
      return `${packageData.name}`;
    }
  };

  /*
    There can be multiple copies of addons that all inherit from the same
    addon "package" in salesforce. We only want to show one of each set of
    sibling packages, so we must group them by the
    `addon_parent_package_salesforce_id` field.
    */

  const addonsPerPackage = props.offer.result.packages.map((pkg) => {
    return pkg.addons
      ? pkg.addons.map((addon) => ({
          ...addon,
          package_name: getPackageName(pkg),
        }))
      : [];
  });
  const allAddons = [].concat(...addonsPerPackage);
  const groupedAddons = allAddons.reduce((acc, addon) => {
    const parentId = addon.addon_parent_package_salesforce_id;
    if (acc[parentId]) {
      acc[parentId].push(addon);
    } else {
      acc[parentId] = [addon];
    }
    return acc;
  }, {});

  return (
    <Box>
      <PageSubheader title="Add-ons" />

      {Object.values(groupedAddons).map((addonGroup: App.Addon[]) => {
        const addon = addonGroup[0];
        return (
          <Accordion key={addon.addon_parent_package_salesforce_id} variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{addon.name}</AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <Grid container>
                  <Grid item sm={3} mr={1}>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Display name
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Vendor Id
                    </Typography>
                    <Typography variant="body1">
                      <Link to={`/vendors/${addon.vendor_id}`}>{addon.vendor_id}</Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Description
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.description}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Sell Price
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.currency}{' '}
                      {currencyFormatter.format(addon.price, {
                        code: addon.currency,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Cost Price
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.currency}{' '}
                      {currencyFormatter.format(addon.cost_price, {
                        code: addon.currency,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Fare Type
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.channel_fare_type}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Available with packages
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      <ul>
                        {addonGroup.map((addon) => (
                          <li key={addon.id_salesforce_external}>{addon.package_name}</li>
                        ))}
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Escalation Contact
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.escalation_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Escalation Phone
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.escalation_phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Escalation Email
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.escalation_email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Reservation Phone
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.vendor_contact_phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
                      Reservation Email
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {addon.vendor_booking_email}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
