import React from 'react';

import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import TENANTS from '../../../consts/tenant';
import OffersService from '../../../services/OffersService';
import Asinc from '../../Common/Asinc';

import SchedulesPage from './SchedulesPage';

export default class ScheduleEditContainer extends React.Component {
  fetchData() {
    const { match } = this.props;
    const { id_offer: offerId } = match.params;

    return OffersService.getOffer(offerId);
  }

  render() {
    const { match } = this.props;
    const { id_offer: offerId, brand } = match.params;

    const tenant = TENANTS[Object.keys(TENANTS).find((k) => TENANTS[k].brand === brand)];

    return (
      <div className="container">
        <Button component={Link} startIcon={<ChevronLeftIcon />} to={'/offers/' + offerId}>
          Return to offer
        </Button>
        <h1 className="page-header">Edit Offer Schedules for {tenant.title}</h1>
        <Asinc
          promise={this.fetchData()}
          then={(response) => (
            <SchedulesPage brand={tenant.brand} offer={response.result} regions={libRegions.getRegions()} />
          )}
        />
      </div>
    );
  }
}
