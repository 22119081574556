import React from 'react';

import { Box } from '@mui/material';

import { getRebookedCustomOrderIdFromOrder } from '~/utils/order';

export default function CustomOfferRebookingOrderBanner({ order }) {
  const displayedId = getRebookedCustomOrderIdFromOrder(order);

  return (
    displayedId && (
      <Box bgcolor="gold" p={1.5}>
        <strong>Rebooked as Custom Offer: </strong> <a href={`/purchases/${displayedId}`}>{displayedId}</a>
      </Box>
    )
  );
}
