import { CabinCategoryById } from '~/services/cruises/CabinCategoriesService';

export type SearchFilters = {
  id?: string;
  code?: string;
  cabinType?: string;
  externalId?: string;
  shipId?: string;
  shipName?: string;
  shipExternalId?: string;
  cabinInfoCategoryName?: string;
  vendorId?: string;
};

export const SEARCH_FILTERS_DEFAULT: SearchFilters = {
  id: '',
  code: '',
  cabinType: '',
  externalId: '',
  shipId: '',
  shipName: '',
  shipExternalId: '',
  cabinInfoCategoryName: '',
  vendorId: '',
};

export const DEFAULT_CABIN_CATEGORY_BY_ID_RESPONSE: CabinCategoryById = {
  id: '',
  code: '',
  cabinType: '',
  externalId: undefined,
  color: '',
  ship: {
    id: '',
    name: '',
    externalId: undefined,
  },
  vendor: {
    id: '',
    name: '',
    code: '',
    externalId: undefined,
  },
  cabinInfo: [],
};
