import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import PublicOfferService from '~/services/PublicOfferService';

import BaseOfferBox from './BaseOfferBox';

interface Props {
  bk: string;
  region: string;
  index: number;
  onRemove: () => void;
}

function TourV2OfferBox({ bk, region, index, onRemove }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: App.State) => state.tenant);

  const [offer, setOffer] = useState<Awaited<ReturnType<typeof PublicOfferService.getTourV2OfferById>>['result']>();

  useEffect(() => {
    PublicOfferService.getTourV2OfferById(bk, region, tenant.brand)
      .then((res) => {
        setOffer(res.result);
      })
      .catch((err) => {
        if (err.status === 404) {
          onRemove && onRemove();
          return;
        }
        enqueueSnackbar(`Tour ${bk} could not be found`, { variant: 'error' });
      });
  }, [bk, region, tenant.brand]);

  const season = offer ? Object.values(offer.tourOptions)[0]?.defaultSeason : undefined;

  if (!offer || !season) {
    return <BaseOfferBox tenant={tenant} index={index} />;
  }

  return (
    <BaseOfferBox
      tenant={tenant}
      title={offer.name}
      imageId={season.images[0]?.id}
      subtitle={offer.emailSubjectLine || season.countriesVisited.join(' -> ')}
      path={`/tour/${bk}`}
      vertical="tour"
      index={index}
    />
  );
}

export default TourV2OfferBox;
