import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';

import { property as propertyGlobals } from '@luxuryescapes/lib-global';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Checkbox from '~/components/Common/Legacy/Checkbox';
import Spinner from '~/components/Common/Spinner';

import {
  ROOM_RATE_FLASH_TYPE,
  ROOM_RATE_LAST_MINUTE_TYPE,
  ROOM_RATE_LPC_TYPE,
  ROOM_RATE_RENTAL_TYPE,
} from '~/consts/ratePlans';

import { InventoryPane } from './InventoryPane';
import RoomTypeEditHistoryTabs from './RoomTypeEditHistory/RoomTypeEditHistoryTabs';
import RoomTypeEditTabs from './RoomTypeEditTabs';
import RoomTypeRoomRates from './RoomTypeRoomRates';
import { COMMON_COPY_OPTION, CONDITIONAL_COPY_OPTION, COPY_OPTION_AVAILABLE_TABS } from './constants';
import copyCapacities from './helpers/copy-feature/copyCapacities';
import copyExtraGuestSurcharges from './helpers/copy-feature/copyExtraGuestSurcharges';
import copyIncludedGuests from './helpers/copy-feature/copyIncludedGuests';
import copyInclusions from './helpers/copy-feature/copyInclusions';

const PRODUCT_TYPE_ALL = 'All';
const PRODUCT_TYPE_FLASH = 'Flash';
const PRODUCT_TYPE_DYNAMIC = 'Dynamic';
const PRODUCT_TYPES = [PRODUCT_TYPE_ALL, PRODUCT_TYPE_FLASH, PRODUCT_TYPE_DYNAMIC];

interface Props {
  vendorId: string;
  propertyId: string;
  roomType: any;
  roomRate: any;
  roomRates: any;
  ratePlans: any;
  vendor: any;
  property: any;
  availability: any;
  availabilitySchema: any;
  blackoutDates: any;
  surchargeDates: any;
  internalSurchargeDates: any;
  roomRateDates: any;
  getRoomRateDatesByOptions: any;
  changeAmountInCalendar: any;
  lengthOfStay: any;
  numberOfAdults: any;
  childrenAges: any;
  showWithoutTax: any;
  propertyRoomTypes: any;
  inclusions: any;
  inclusionsSchema: any;
  onUpdateRoomRateInclusions: any;
  onInclusionsOrderUpdate: any;
  deleteInclusion: any;
  onDeleteAllInclusions: any;
  capacities: any;
  onUpdateRoomTypeCapacities: any;
  deleteRoomTypeCapacity: any;
  onDeleteAllCapacity: any;
  includedGuests: any;
  onUpdateRoomTypeIncludedGuests: any;
  deleteRoomTypeIncludedGuest: any;
  onDeleteAllIncludedGuests: any;
  extraGuestSurcharges: any;
  onRoomRateExtraGuestSurchargeUpdated: any;
  deleteExtraGuestSurcharge: any;
  onUpdateAvailability: any;
  onUpdateBlackoutDates: any;
  deleteBlackoutDateBlock: any;
  onDeleteAllBlackoutDates: any;
  onUpdateSurchargeDates: any;
  deleteSurchargeDateBlock: any;
  deleteSurchargeDateBlockByRoomRateId: any;
  deleteSurchargeDateBlockByRatePlanId: any;
  onUpdateInternalSurchargeDates: any;
  deleteInternalSurchargeDateBlock: any;
  onUpdateRoomRates: any;
  deleteRoomRate: any;
  isDeletingAll: any;
  leadDays: any;
  leadDaysSchema: any;
  onUpdateRoomRateLeadDays: any;
  surchargeSchema: any;
  foreignExchangeRates: any;
  channelManaged: any;
  lengthsOfStay: any;
}

interface State {
  bulkCopyError: any;
  copyInProgress: boolean;
  overrideExisting: boolean;
  copyToStandardPlans: boolean;
  selectedCopyMethod: any;
  selectOptions: any;
  capacitiesSelected: boolean;
  showOverrideExisting: boolean;
  overrideCheckboxLabel: string;
  modalIsVisible: boolean;
  isRoomRateDynamic: boolean;
  selectedRatesType: string;
  selectedOperation: any;
  key: string;
}

export default class RoomTypeEdit extends Component<Props, State> {
  roomType: any;
  propertyIsRental: boolean;

  constructor(props) {
    super(props);

    // used to show/hide several editing fields
    this.propertyIsRental = props.property.type === propertyGlobals.RENTAL_TYPE;

    this.roomType = props.roomType;
    this.roomType.availability = props.availability;

    this.state = {
      bulkCopyError: null,
      copyInProgress: false,
      overrideExisting: true,
      copyToStandardPlans: false,
      selectedCopyMethod: '',
      selectOptions: COMMON_COPY_OPTION,
      capacitiesSelected: false,
      showOverrideExisting: false,
      overrideCheckboxLabel: 'Override the existing inclusions',
      isRoomRateDynamic: !this.isRoomRateDynamic(),
      selectedRatesType: PRODUCT_TYPE_ALL,
      modalIsVisible: false,
      selectedOperation: '',
      key: 'blackoutDates',
    };
  }

  toggleModalVisibility = () => {
    this.setState((prevState) => ({
      modalIsVisible: !prevState.modalIsVisible,
    }));
  };

  getSelectOptions(selectedOperation) {
    if (
      selectedOperation['label'] === COPY_OPTION_AVAILABLE_TABS.capacity ||
      selectedOperation['label'] === COPY_OPTION_AVAILABLE_TABS.includedGuests
    ) {
      return CONDITIONAL_COPY_OPTION;
    }
    return COMMON_COPY_OPTION;
  }

  isCapacitiesSelected(selectedOperation) {
    return selectedOperation['label'] === COPY_OPTION_AVAILABLE_TABS.capacity;
  }

  overrideRequired(selectedOperation) {
    return selectedOperation['label'] !== COPY_OPTION_AVAILABLE_TABS.extraGuestSurcharges;
  }

  getCopyOptions() {
    const optionsToReturn = [];
    for (const option in COPY_OPTION_AVAILABLE_TABS) {
      optionsToReturn.push({
        value: option,
        label: COPY_OPTION_AVAILABLE_TABS[option],
      });
    }
    return optionsToReturn;
  }

  getSelectedCopyOperation() {
    const selected = this.state.selectedOperation;
    return selected?.label;
  }

  handleCopy = async () => {
    const op = this.getSelectedCopyOperation();
    switch (op) {
      case COPY_OPTION_AVAILABLE_TABS.inclusions: {
        this.handleCopyInclusion();
        break;
      }
      case COPY_OPTION_AVAILABLE_TABS.capacity: {
        this.handleCopyCapacities();
        break;
      }
      case COPY_OPTION_AVAILABLE_TABS.includedGuests: {
        this.handleCopyIncludedGuests();
        break;
      }
      case COPY_OPTION_AVAILABLE_TABS.extraGuestSurcharges: {
        this.handleCopyExtraGuestSurcharges();
        break;
      }
      default: {
        this.setState({ bulkCopyError: `Unsupported op: ${op}` });
        break;
      }
    }
  };

  handleCopyInclusion = async () => {
    this.setState({
      copyInProgress: true,
    });

    const params = {
      // Configs
      mode: this.state.selectedCopyMethod,
      overrideExisting: this.state.overrideExisting,
      copyToStandardPlans: this.state.copyToStandardPlans,
      // Data
      inclusions: this.props.inclusions,
      propertyId: this.props.propertyId,
      propertyRoomTypes: this.props.propertyRoomTypes,
      roomRate: this.props.roomRate,
      roomType: this.props.roomType,
    };

    try {
      await copyInclusions(params);
      this.setState({
        modalIsVisible: false,
      });
    } catch (e) {
      this.setState({ copyInProgress: false, bulkCopyError: e.message });
    } finally {
      this.setState({
        copyInProgress: false,
      });
    }
  };

  handleCopyCapacities = async () => {
    try {
      const roomType = this.props.roomType;
      const advancePayload = this.state.overrideExisting
        ? {
            max_occupancy: roomType.max_occupancy,
            max_adult_occupancy: roomType.max_adult_occupancy,
            max_child_occupancy: roomType.max_child_occupancy,
            max_infant_occupancy: roomType.max_infant_occupancy,
          }
        : null;

      this.setState({
        copyInProgress: true,
      });

      await copyCapacities(
        this.state.selectedCopyMethod,
        this.props.capacities,
        this.props.propertyId,
        this.props.roomRate,
        this.props.roomType,
        advancePayload,
      );
      this.setState({
        modalIsVisible: false,
      });
    } catch (e) {
      this.setState({ bulkCopyError: e.message });
    } finally {
      this.setState({
        copyInProgress: false,
      });
    }
  };

  handleCopyIncludedGuests = async () => {
    try {
      const roomType = this.props.roomType;

      const advancePayload = this.state.overrideExisting
        ? {
            max_included_guests: roomType.max_included_guests,
            max_adult_included_guests: roomType.max_adult_included_guests,
            max_child_included_guests: roomType.max_child_included_guests,
            max_infant_included_guests: roomType.max_infant_included_guests,
          }
        : null;

      this.setState({
        copyInProgress: true,
      });

      await copyIncludedGuests(
        this.state.selectedCopyMethod,
        this.props.includedGuests,
        this.props.propertyId,
        this.props.roomRate,
        this.props.roomType,
        advancePayload,
      );
      this.setState({
        modalIsVisible: false,
      });
    } catch (e) {
      this.setState({ bulkCopyError: e.message });
    } finally {
      this.setState({
        copyInProgress: false,
      });
    }
  };

  handleCopyExtraGuestSurcharges = async () => {
    try {
      this.setState({
        copyInProgress: true,
      });
      const params = {
        // Copy mode
        mode: this.state.selectedCopyMethod,
        // Data
        surcharges: this.props.extraGuestSurcharges,
        propertyId: this.props.propertyId,
        propertyRoomTypes: this.props.propertyRoomTypes,
        roomRate: this.props.roomRate,
        roomType: this.props.roomType,
      };

      await copyExtraGuestSurcharges(params);
      this.setState({
        modalIsVisible: false,
      });
    } catch (e) {
      this.setState({ bulkCopyError: e.message });
    } finally {
      this.setState({
        copyInProgress: false,
      });
    }
  };

  handleCopyMethodSelected = (event: SelectChangeEvent) => {
    const copyMethod = event.target.value;
    this.setState({
      selectedCopyMethod: copyMethod,
      bulkCopyError: null,
    });
  };

  canCopy() {
    return this.state.selectedCopyMethod && this.state.selectedOperation;
  }

  handleOperationSelect = (event: SelectChangeEvent) => {
    const selectedOperation = event.target.value;
    this.setState({
      selectedOperation: selectedOperation,
      selectedCopyMethod: null,
      bulkCopyError: null,
      selectOptions: this.getSelectOptions(selectedOperation),
      capacitiesSelected: this.isCapacitiesSelected(selectedOperation),
      showOverrideExisting: this.overrideRequired(selectedOperation),
      overrideCheckboxLabel: this.getOverrideCheckboxLabel(selectedOperation),
    });
  };

  toggleOverrideExisting = () => {
    this.setState({ overrideExisting: !this.state.overrideExisting });
  };

  toggleCopyForStandardPlan = () => {
    this.setState({
      copyToStandardPlans: !this.state.copyToStandardPlans,
    });
  };

  getOverrideCheckboxLabel = (selectedOperation) => {
    if (selectedOperation['label'] === COPY_OPTION_AVAILABLE_TABS.capacity) {
      return 'Override the existing Advanced capacities also';
    } else if (selectedOperation['label'] === COPY_OPTION_AVAILABLE_TABS.includedGuests) {
      return 'Override the existing Advanced Included Guests';
    }
    return 'Override the existing inclusions';
  };

  getCopySubject = () => {
    return 'inclusions';
  };

  isRoomRateDynamic = () => {
    const productType = this.props.roomRate.rate_plan ? this.props.roomRate.rate_plan.product_type : null;
    return (
      productType === ROOM_RATE_LPC_TYPE ||
      productType === ROOM_RATE_LAST_MINUTE_TYPE ||
      productType === ROOM_RATE_RENTAL_TYPE
    );
  };

  handleRatesType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value;
    if (!PRODUCT_TYPES.includes(type)) return;
    this.setState({
      selectedRatesType: type,
    });
  };

  getTitle = () => {
    return `${this.roomType.name} Availability ${this.props.channelManaged ? ' (Channel managed)' : ''}`;
  };

  render() {
    const propertyPath = `/vendors/${this.props.vendorId}/properties/${this.props.propertyId}`;
    const datesPath = `${propertyPath}/room-types/${this.roomType.id}/room-rates/${this.props.roomRate.id}/dates`;
    const { vendor, vendorId } = this.props;

    const header = (
      <>
        <Helmet>
          <title>
            Vendor | {vendor?.name || vendorId} | {this.roomType.name} Availability
          </title>
        </Helmet>

        <PageHeader title={this.getTitle()} backButton={propertyPath}>
          {!this.propertyIsRental && (
            <Box width="150px">
              <TextField
                label="View by type"
                value={this.state.selectedRatesType}
                onChange={this.handleRatesType}
                variant="outlined"
                fullWidth
                select
              >
                {PRODUCT_TYPES.map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          )}
        </PageHeader>
      </>
    );

    const filteredRoomRates = this.props.roomRates?.filter((rate) => {
      switch (this.state.selectedRatesType) {
        case PRODUCT_TYPE_ALL:
          return true;
        case PRODUCT_TYPE_FLASH:
          return rate.rate_plan.product_type === ROOM_RATE_FLASH_TYPE;
        case PRODUCT_TYPE_DYNAMIC:
          return (
            rate.rate_plan.product_type === ROOM_RATE_LPC_TYPE ||
            rate.rate_plan.product_type === ROOM_RATE_LAST_MINUTE_TYPE ||
            rate.rate_plan.product_type === ROOM_RATE_RENTAL_TYPE
          );
        default:
          return false;
      }
    });

    return (
      <Box m={3}>
        {header}

        <Stack spacing={4}>
          <RoomTypeRoomRates
            vendorId={this.props.vendorId}
            propertyId={this.props.propertyId}
            propertyIsRental={this.propertyIsRental}
            roomTypeId={this.roomType.id}
            roomRate={this.props.roomRate}
            roomRates={filteredRoomRates}
            ratePlans={this.props.ratePlans}
            deleteRoomRate={this.props.deleteRoomRate}
            onUpdateRoomRates={this.props.onUpdateRoomRates}
          />

          <div>
            <InventoryPane
              availability={this.props.availability}
              blackoutDates={this.props.blackoutDates}
              surchargeDates={this.props.surchargeDates}
              roomRateDates={this.props.roomRateDates}
              getRoomRateDatesByOptions={this.props.getRoomRateDatesByOptions}
              changeAmountInCalendar={this.props.changeAmountInCalendar}
              lengthOfStay={this.props.lengthOfStay}
              numberOfAdults={this.props.numberOfAdults}
              childrenAges={this.props.childrenAges}
              propertyId={this.props.propertyId}
              propertyIsRental={this.propertyIsRental}
              roomTypeId={this.roomType.id}
              roomRateId={this.props.roomRate.id}
              showWithoutTax={this.props.showWithoutTax}
              ratePlanLink={
                this.props.roomRate.rate_plan?.id
                  ? `/vendors/${this.props.vendorId}/rate-plans/${this.props.roomRate.rate_plan.id}`
                  : null
              }
            />
            <Button className="room-type-availability-edit-button" variant="outlined" component={Link} to={datesPath}>
              {this.props.channelManaged ? 'View' : 'Edit'}
            </Button>
          </div>

          <div>
            <PageSubheader title="Dates, inclusions and guests">
              <div>
                <Button variant="text" onClick={this.toggleModalVisibility} startIcon={<ContentCopyIcon />}>
                  Copy
                </Button>
              </div>
            </PageSubheader>

            <RoomTypeEditTabs
              propertyId={this.props.propertyId}
              roomType={this.props.roomType}
              roomRate={this.props.roomRate}
              roomRates={filteredRoomRates}
              ratePlans={this.props.ratePlans}
              vendor={this.props.vendor}
              property={this.props.property}
              availability={this.props.availability}
              blackoutDates={this.props.blackoutDates}
              surchargeDates={this.props.surchargeDates}
              internalSurchargeDates={this.props.internalSurchargeDates}
              roomRateDates={this.props.roomRateDates}
              getRoomRateDatesByOptions={this.props.getRoomRateDatesByOptions}
              changeAmountInCalendar={this.props.changeAmountInCalendar}
              lengthOfStay={this.props.lengthsOfStay}
              numberOfAdults={this.props.numberOfAdults}
              childrenAges={this.props.childrenAges}
              showWithoutTax={this.props.showWithoutTax}
              deleteRoomRate={this.props.deleteRoomRate}
              onUpdateRoomRates={this.props.onUpdateRoomRates}
              availabilitySchema={this.props.availabilitySchema}
              deleteRoomTypeIncludedGuest={this.props.deleteRoomTypeIncludedGuest}
              onUpdateRoomTypeIncludedGuests={this.props.onUpdateRoomTypeIncludedGuests}
              onDeleteAllIncludedGuests={this.props.onDeleteAllIncludedGuests}
              deleteExtraGuestSurcharge={this.props.deleteExtraGuestSurcharge}
              onRoomRateExtraGuestSurchargeUpdated={this.props.onRoomRateExtraGuestSurchargeUpdated}
              channelManaged={this.props.channelManaged}
              isDeletingAll={this.props.isDeletingAll}
              includedGuests={this.props.includedGuests}
              extraGuestSurcharges={this.props.extraGuestSurcharges}
              inclusions={this.props.inclusions}
              inclusionsSchema={this.props.inclusionsSchema}
              propertyRoomTypes={this.props.propertyRoomTypes}
              onUpdateRoomRateInclusions={this.props.onUpdateRoomRateInclusions}
              onInclusionsOrderUpdate={this.props.onInclusionsOrderUpdate}
              deleteInclusion={this.props.deleteInclusion}
              onDeleteAllInclusions={this.props.onDeleteAllInclusions}
              capacities={this.props.capacities}
              onUpdateRoomTypeCapacities={this.props.onUpdateRoomTypeCapacities}
              deleteRoomTypeCapacity={this.props.deleteRoomTypeCapacity}
              onDeleteAllCapacity={this.props.onDeleteAllCapacity}
              onUpdateAvailability={this.props.onUpdateAvailability}
              onUpdateBlackoutDates={this.props.onUpdateBlackoutDates}
              deleteBlackoutDateBlock={this.props.deleteBlackoutDateBlock}
              onDeleteAllBlackoutDates={this.props.onDeleteAllBlackoutDates}
              onUpdateSurchargeDates={this.props.onUpdateSurchargeDates}
              deleteSurchargeDateBlock={this.props.deleteSurchargeDateBlock}
              deleteSurchargeDateBlockByRoomRateId={this.props.deleteSurchargeDateBlockByRoomRateId}
              deleteSurchargeDateBlockByRatePlanId={this.props.deleteSurchargeDateBlockByRatePlanId}
              onUpdateInternalSurchargeDates={this.props.onUpdateInternalSurchargeDates}
              deleteInternalSurchargeDateBlock={this.props.deleteInternalSurchargeDateBlock}
              leadDays={this.props.leadDays}
              leadDaysSchema={this.props.leadDaysSchema}
              onUpdateRoomRateLeadDays={this.props.onUpdateRoomRateLeadDays}
              surchargeSchema={this.props.surchargeSchema}
              foreignExchangeRates={this.props.foreignExchangeRates}
            />
          </div>
          <Box>
            <PageSubheader title="Availability and Surcharge History" />
            <RoomTypeEditHistoryTabs
              propertyId={this.props.propertyId}
              roomType={this.props.roomType}
              roomRate={this.props.roomRate}
              roomRates={this.props.roomRates}
              channelManaged={this.props.channelManaged}
            />
          </Box>

          <Dialog fullWidth={true} open={this.state.modalIsVisible} onClose={this.toggleModalVisibility}>
            <DialogTitle>Copy</DialogTitle>

            <DialogContent>
              {!this.state.copyInProgress && this.state.bulkCopyError && (
                <div className="alert alert-danger">{this.state.bulkCopyError}</div>
              )}

              {this.state.copyInProgress && <Spinner />}
              {!this.state.copyInProgress && (
                <Stack mt={1} spacing={2}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="select-operation-label">Select...</InputLabel>
                    <MuiSelect
                      labelId="select-operation-label"
                      value={this.state.selectedOperation}
                      onChange={this.handleOperationSelect}
                      input={<OutlinedInput label="Select..." />}
                      renderValue={(selected) => {
                        return selected.label;
                      }}
                    >
                      {this.getCopyOptions().map((option) => {
                        return (
                          <MenuItem key={option.value} value={option}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  </FormControl>
                  <FormControl fullWidth={true}>
                    <InputLabel id="select-copy-label">Select...</InputLabel>
                    <MuiSelect
                      value={this.state.selectedCopyMethod}
                      labelId="select-copy-label"
                      onChange={this.handleCopyMethodSelected}
                      input={<OutlinedInput label="Select..." />}
                    >
                      {this.state.selectOptions.map((option) => {
                        return (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  </FormControl>
                  {this.getSelectedCopyOperation() && this.state.showOverrideExisting && (
                    <Checkbox
                      wrapperClassName="mt-10"
                      labelText={this.state.overrideCheckboxLabel}
                      checked={this.state.overrideExisting}
                      onToggle={this.toggleOverrideExisting}
                      buttonClassName="btn btn-default"
                    />
                  )}
                  {this.getSelectedCopyOperation() === COPY_OPTION_AVAILABLE_TABS.inclusions && (
                    <Checkbox
                      wrapperClassName="mt-10"
                      checked={this.state.copyToStandardPlans}
                      onToggle={this.toggleCopyForStandardPlan}
                      labelText={'Copy the existing ' + this.getCopySubject() + ' across Standard Rate plans'}
                      buttonClassName="btn btn-default"
                    />
                  )}
                </Stack>
              )}
              <DialogActions>
                <Button variant="text" onClick={this.toggleModalVisibility}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={this.handleCopy} disabled={!this.canCopy()}>
                  Apply
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Stack>
      </Box>
    );
  }
}
