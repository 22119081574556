import React from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';

export default class LinkBack extends React.Component {
  constructor(props) {
    super(props);

    this.handler = this.handler.bind(this);
  }

  handler() {
    this.props.stepToggle(this.props.stepBack);
  }

  render() {
    return (
      <Button onClick={this.handler} variant="text" startIcon={<ArrowBack />}>
        Back
      </Button>
    );
  }
}
