import React, { Component } from 'react';

import styled from 'styled-components';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';

import ReservationService from '../../../services/ReservationService';
import { buttonMessages, buttonStates } from '../../Common/Forms/states/submitButton';

const StyledItem = styled.li`
  margin-top: 10px;
`;

const StyledLabel = styled.label`
  display: inline;
  margin-left: 5px;
`;

interface Props {
  propertyId: string;
  roomTypes: Array<App.RoomType>;
  ratePlans: Array<App.RatePlan>;
  editing: boolean;
  toggleEditMode: () => void;
}

interface State {
  saveState: string;
  saving: boolean;
  editing: boolean;
  alertError: string;
  roomTypes: any;
  ratePlans: any;
}

export default class RoomTypeAddRatePlansModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      saveState: buttonStates.default,
      saving: false,
      editing: this.props.editing,
      alertError: '',
      roomTypes: this.initModalData(this.props.roomTypes),
      ratePlans: this.initModalData(this.props.ratePlans),
    };
  }

  initModalData(collection) {
    return collection.map((i) => {
      return {
        ...i,
        isChecked: true,
      };
    });
  }

  toggleEditMode() {
    this.props.toggleEditMode();
    this.setState({
      saveState: buttonStates.default,
      editing: !this.state.editing,
      alertError: null,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      saveState: buttonStates.saving,
      saving: true,
      alertError: '',
    });

    const input = Array.from(event.target.form).filter((i: any) => i.type === 'checkbox' && i.checked);
    const roomTypes = input.filter((i: any) => i.name === 'room_type_id').map((i: any) => i.value);
    const ratePlans = input.filter((i: any) => i.name === 'rate_plan_id').map((i: any) => i.value);

    if (!roomTypes.length || !ratePlans.length) {
      this.setState({
        alertError: 'Please select at least 1 rate plan and 1 room type',
        saving: false,
        saveState: buttonStates.default,
      });

      return false;
    }

    try {
      await Promise.allSettled(
        roomTypes.map((roomType) =>
          ReservationService.createBatchRoomRates(this.props.propertyId, roomType, {
            rate_plan_id: ratePlans,
          }),
        ),
      );

      this.setState({
        saveState: buttonStates.saved,
      });
    } catch (err) {
      this.setState({
        editing: false,
        saveState: buttonStates.failed,
        alertError: err.message || 'Someting went wrong.',
      });
    }
  };

  toggleAll(obj) {
    const flag = this.state[obj][0].isChecked;
    const data = this.state[obj];
    data.forEach((i) => {
      i.isChecked = !flag;
    });
    this.setState({ [obj]: data } as Pick<State, keyof State>);
  }

  onChange(target, name) {
    const newState = this.state[name];
    const itemIndex = newState.findIndex((i) => i.id === target.value);
    newState[itemIndex]['isChecked'] = target.checked;
    this.setState({
      [name]: newState,
    } as Pick<State, keyof State>);
  }

  render() {
    return (
      <Dialog open={true} onClose={this.toggleEditMode} maxWidth="md" fullWidth>
        <DialogTitle>Add Rate Plans</DialogTitle>

        <DialogContent>
          <form id="add-rate-plan-modal-form" onSubmit={this.handleSubmit}>
            <div>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Typography fontWeight={500} color="grey.800">
                  Room Types
                </Typography>
                <Button variant="text" size="small" onClick={() => this.toggleAll('roomTypes')}>
                  Toggle all
                </Button>
              </Stack>

              <Box display="grid" columnGap={2} gridTemplateColumns="auto auto">
                {this.state.roomTypes.map((roomType) => (
                  <FormControlLabel
                    key={roomType.id}
                    control={
                      <Checkbox
                        name="room_type_id"
                        checked={roomType.isChecked}
                        value={roomType.id}
                        onChange={(e) => this.onChange(e.currentTarget, 'roomTypes')}
                      />
                    }
                    label={roomType.name + (roomType.room_type_code ? ` (${roomType.room_type_code})` : '')}
                  />
                ))}
              </Box>
            </div>

            <Box mt={2}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Typography fontWeight={500} color="grey.800">
                  Rate Plans
                </Typography>
                <Button variant="text" size="small" onClick={() => this.toggleAll('ratePlans')}>
                  Toggle all
                </Button>
              </Stack>

              <Box display="grid" columnGap={2} gridTemplateColumns="auto auto">
                {this.state.ratePlans.map((ratePlan) => (
                  <FormControlLabel
                    key={ratePlan.id}
                    control={
                      <Checkbox
                        name="rate_plan_id"
                        checked={ratePlan.isChecked}
                        value={ratePlan.id}
                        onChange={(e) => this.onChange(e.currentTarget, 'ratePlans')}
                      />
                    }
                    label={ratePlan.name + (ratePlan.rate_plan_code ? ` (${ratePlan.rate_plan_code})` : '')}
                  />
                ))}
              </Box>
            </Box>

            {this.state.alertError && <Alert severity="error">{this.state.alertError}</Alert>}
          </form>
        </DialogContent>

        <DialogActions>
          <Button size="small" onClick={this.toggleEditMode}>
            Close
          </Button>

          <Button
            form="add-rate-plan-modal-form"
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            className={this.state.saveState}
            onClick={this.handleSubmit}
          >
            {buttonMessages[this.state.saveState]}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
