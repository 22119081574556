import React, { useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { VENDOR_REFUND_AGREES_PERCENTAGE } from '~/consts/refund';

import { deleteAccountingMeta } from '~/services/PaymentsService';

import type { RefundRecord, RefundsResponse } from '~/types/responses';

function deleteRow(row, refreshData) {
  return async function () {
    if (window.confirm('Are you sure you wish to delete this accounting metadata row?')) {
      try {
        await deleteAccountingMeta(row.fk_orders, row.id_refund_metadata);
        refreshData();
      } catch (e) {
        alert(e);
      }
    }
  };
}

const isValidData = (data) => {
  return typeof data === 'string' || typeof data === 'number';
};

const isValidKeys = (info) => {
  if (info && isValidData(info.refund_vendor_agrees_to)) {
    if (info.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_PERCENTAGE) {
      return isValidData(info.percentage);
    } else {
      return isValidData(info.number_of_nights) && isValidData(info.total_nights);
    }
  } else {
    return false;
  }
};

const additionalInfoFormatter = (additionalInfoObj) => {
  if (isValidKeys(additionalInfoObj)) {
    return additionalInfoObj.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_PERCENTAGE
      ? additionalInfoObj.percentage + '%'
      : `${additionalInfoObj.number_of_nights} of ${additionalInfoObj.total_nights} nights`;
  } else {
    return JSON.stringify(additionalInfoObj) === '{}' || additionalInfoObj === null
      ? ''
      : JSON.stringify(additionalInfoObj);
  }
};

interface AccountingMetaProps {
  refunds: RefundsResponse;
  users: Users;
  sale_currency_code: string;
  refreshData: () => void;
}

type Users = {
  [id: string]: App.UserSummary;
};

type RefundData = RefundRecord & { refundedBy: string };

export default function AccountingMetaTable({ refunds, users, sale_currency_code, refreshData }: AccountingMetaProps) {
  const data = useMemo<RefundData[]>(
    () =>
      refunds.result.map((refund) => ({
        ...refund,
        refundedBy: users[refund.refunded_by]?.full_name || null,
      })),
    [refunds, users],
  );

  const columns: GridColDef[] = useMemo<GridColDef<RefundData>[]>(
    () => [
      {
        field: 'id_refund_metadata',
        headerName: 'Accounting Refund ID',
        sortable: false,
        flex: 2,
        renderCell: (params: GridRenderCellParams<RefundData, string>) => {
          const refund = params.row;
          return (
            <Box width="100%">
              <Typography>
                <strong>Accounting Refund ID</strong>: {refund.id_refund_metadata}
              </Typography>

              <Typography>
                <strong>Item ID</strong>: {refund.charge_component_key}
              </Typography>
            </Box>
          );
        },
        display: 'flex',
      },
      {
        field: 'reason',
        headerName: 'Refund information',
        sortable: false,
        flex: 3,
        renderCell: (params: GridRenderCellParams<RefundData, string>) => {
          const refund = params.row;
          return (
            <Box width="100%">
              <Typography>
                <strong>Refunded by</strong>: {refund.refundedBy}
              </Typography>

              <Typography>
                <strong>Refund reason</strong>: {refund.reason}
              </Typography>

              <Typography>
                <strong>Case ID</strong>: {refund.ticket_id}
              </Typography>

              <Typography>
                <strong>Comment</strong>: {refund.comment}
              </Typography>

              {refund.customer_refund_reason && refund.customer_refund_reason.length > 0 && (
                <Typography>
                  <strong>Customer refund reason</strong>: {refund.customer_refund_reason}
                </Typography>
              )}
            </Box>
          );
        },
        display: 'flex',
      },
      {
        field: 'accounting_amount',
        headerName: 'Accounting Amount',
        sortable: false,
        display: 'flex',
      },
      {
        field: 'cash_amount',
        headerName: 'Cash Amount',
        sortable: false,
        display: 'flex',
      },
      {
        field: 'currency',
        headerName: 'Currency',
        sortable: false,
        renderCell: () => sale_currency_code,
        display: 'flex',
      },
      {
        field: 'source',
        headerName: 'Source',
        sortable: false,
        display: 'flex',
      },
      {
        field: 'additional_info',
        headerName: 'Additional Information',
        sortable: false,
        valueFormatter: (value) => additionalInfoFormatter(value),
        display: 'flex',
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        width: 60,
        align: 'center',
        renderCell: (params: GridRenderCellParams<RefundData, string>) => {
          return (
            <IconButton onClick={deleteRow(params.row, refreshData)}>
              <DeleteIcon />
            </IconButton>
          );
        },
        display: 'flex',
      },
    ],
    [refreshData, sale_currency_code],
  );

  return (
    <DataGrid
      columns={columns}
      rows={data}
      getRowId={(row: RefundData) => row.id_refund_metadata}
      autoHeight
      pageSizeOptions={[]}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
      getRowHeight={() => 'auto'}
    />
  );
}
