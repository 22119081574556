import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import { IconButton, Stack } from '@mui/material';

interface Props {
  index?: number | string;
  itemKey: any;
  children: React.ReactElement;
  createUniqueId?: (itemKey, index) => string;
}

export default function ArrayFieldItem({
  index = '',
  itemKey,
  children,
  createUniqueId = (itemKey, index = '') => `${itemKey}-${index}`,
}: Props) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: createUniqueId(itemKey, index),
  });

  return (
    <Stack
      alignItems="center"
      direction="row"
      ref={setNodeRef}
      sx={{
        flexGrow: 1,
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      spacing={1}
    >
      {children}
      <IconButton ref={setActivatorNodeRef} type="button" color="secondary" {...listeners} {...attributes}>
        <OpenWithIcon />
      </IconButton>
    </Stack>
  );
}
