import { GridRenderCellParams } from '@mui/x-data-grid';

const bedbanksPropertyFormatter = ({ row }: GridRenderCellParams<App.Order>) => {
  if (!row.bedbank_items.length) {
    return;
  }

  return row.bedbank_items.map((bedbankItem) => bedbankItem.offer.name).join(', ');
};

export default bedbanksPropertyFormatter;
