import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { offerRecordTypes } from '~/consts/offerRecordTypes';

import { queueSummaryBookings } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter a date to export a summary of all Redemption only bookings before the selected date',
  type: 'object',
  properties: {
    endAt: {
      title: 'Select Date',
      type: 'string',
      format: 'date',
    },
    recordTypeFilters: {
      title: 'Filter record type',
      type: 'array',
      items: {
        title: 'Record Type',
        type: 'string',
        enum: offerRecordTypes,
        uniqueItems: true,
      },
    },
  },
  required: ['endAt'],
};

const uiSchema = {
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportSummaryBookingsForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      await queueSummaryBookings({
        endAt: event.formData.endAt,
        recordTypeFilters: event.formData.recordTypeFilters,
      });
      setSuccessMessage(true);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} showErrorList={false} validator={validator}>
      <Button type="submit" variant="contained">
        Email Summary Bookings Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
