import React from 'react';

import { Box, Typography } from '@mui/material';

interface Props {
  label: string;
  value: string;
}

const ReservationSummaryField = ({ label, value }: Props) => {
  return (
    <Typography>
      <Box fontWeight="bold" component="span">
        {label}{' '}
      </Box>
      {value}
    </Typography>
  );
};

export default ReservationSummaryField;
