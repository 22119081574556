import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerCountryGroups from '~/queries/customerCommunication/requestGetHeroPlannerCountryGroups';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type CountryGroupsParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['countryGroupRecords']>['params']
>;

const fetchHeroPlannerCountryGroups =
  (brand: string): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();

    const params = [brand] as CountryGroupsParams;
    const key = getRecordKeyFor(...params);
    const existing = appState.customerCommunication.countryGroupRecords[key];
    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_FETCHING,
        params,
      });
      try {
        const countryGroups = await requestGetHeroPlannerCountryGroups(...params);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_FULFILLED,
          params,
          countryGroups,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerCountryGroups;
