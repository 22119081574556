import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';

import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

export default function AmenityEdit() {
  const {
    params: { id_amenity: amenityId },
  } = useRouteMatch<{ id_amenity: string }>();

  const [builtIn, setBuiltIn] = useState(false);
  const [name, setName] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [groups, setGroups] = useState<App.AmenityGroup[]>([]);
  const [loading, setLoading] = useState<Utils.FetchingState>('idle');
  const [saveButton, setSaveButton] = useState<Utils.SavingState>('save changes');

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    setSaveButton('save changes');
  }, [name, builtIn, groupId]);

  const handleSave = () => {
    setSaveButton('saving');
    ReservationService.updateAmenity(parseInt(amenityId), name, builtIn, groupId)
      .then(() => {
        setSaveButton('saved');
      })
      .catch(() => {
        setSaveButton('failed');
      });
  };

  useEffect(() => {
    ReservationService.getAmenityGroups().then((response) => {
      setGroups(response.result);
    });
  }, []);

  useEffect(() => {
    const fetchAmenity = async () => {
      try {
        setLoading('loading');
        const { result } = await ReservationService.getAmenity(amenityId);
        setBuiltIn(result.built_in);
        setName(result.name);
        setGroupId(result.group?.id ?? null);
        setLoading('success');
      } catch (error) {
        setLoading('failed');
        enqueueSnackbar(`Error fetching amenity: ${error}`, { variant: 'error' });
      }
    };

    fetchAmenity();
  }, [amenityId]);

  const title = 'Edit Amenity';

  if (loading === 'loading') {
    return <Spinner />;
  }

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title}></PageHeader>

      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2} alignItems="left">
          <Typography>ID: {amenityId}</Typography>
          <TextField
            id="name"
            data-testid="name"
            type="text"
            name="name"
            label="Amenity Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={builtIn}
                onChange={(e) => setBuiltIn((e.target as HTMLInputElement).checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Built-in"
          />
          <InputLabel id="group-label">Group</InputLabel>
          <MuiSelect labelId="group-label" value={groupId} onChange={(e) => setGroupId(e.target.value)}>
            {groups &&
              groups.length > 0 &&
              groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
          </MuiSelect>
          <div>
            <Button variant="contained" onClick={handleSave} disabled={saveButton === 'saving'}>
              {saveButton}
            </Button>
          </div>
        </Stack>
      </form>
    </Container>
  );
}
