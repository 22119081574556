import React from 'react';

import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FDATableDataType } from '~/services/FlightsService';
import { newDate } from '~/services/TimeService';

interface Props {
  onChange: (data: string | number | boolean) => void;
  displayName: string;
  name: string;
  value: string | number | boolean;
  dataType: FDATableDataType;
  required: boolean;
  maxLength?: number;
  readOnly?: boolean;
}

export default function FDAFormInput(props: Props) {
  const { onChange, displayName, dataType, required, name, value, maxLength, readOnly } = props;

  return (
    <>
      {dataType === 'boolean' && (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={Boolean(value)} />}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
            label={displayName}
          />
        </FormGroup>
      )}

      {dataType === 'date' && (
        <DatePicker
          label={displayName}
          value={value ? newDate(value as string, 'YYYY-MM-DD') : null}
          format="YYYY-MM-DD"
          onChange={(value) => value.format('YYYY-MM-DD')}
          slotProps={{
            textField: {
              required: required,
              style: { flex: 1 },
            },
          }}
          readOnly={readOnly}
        />
      )}

      {['string', 'number'].includes(dataType) && (
        <TextField
          id={name}
          label={displayName}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          style={{ flex: 1 }}
          required={required}
          inputProps={{
            maxLength,
            readOnly,
          }}
        />
      )}
    </>
  );
}
