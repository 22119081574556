import React from 'react';

import { SettingsSuggest } from '@mui/icons-material';
import { Link } from '@mui/material';

type PromoTesterLinkProps = {
  codeName: string;
  orderId: string;
};

const PromoTesterLink = ({ codeName, orderId }: PromoTesterLinkProps) => {
  return (
    <Link
      target="_blank"
      href={`/marketing/promo-tester?orderId=${orderId}&codeName=${codeName}`}
      title="simulate this promo event via the promo tester tool"
    >
      <SettingsSuggest />
    </Link>
  );
};

export default PromoTesterLink;
