/* Common/UserName.js */
import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UsersService from '~/services/UsersService';

import Spinner from './Spinner';

class UserName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      user: null,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { userId } = this.props;

    this.setState({ isLoading: true, user: null, error: null }, async () => {
      const isLoading = false;

      try {
        // todo: refactor later to add client-side cache
        const user = await UsersService.getUser(userId);

        this.setState({ isLoading, user, userId });
      } catch (error) {
        this.setState({ isLoading, error });
      }
    });
  };

  render() {
    const { isLoading, error, user, userId } = this.state;
    const { asLink } = this.props;

    if (isLoading) {
      return <Spinner size={16} inline />;
    }

    if (error) {
      console.warn(error);
      return null;
    }

    if (asLink) {
      return <Link to={`/users/${user.id_member}`}>{user.fullName ?? userId}</Link>;
    }

    return <span>{user.fullName ?? userId}</span>;
  }
}

UserName.propTypes = {
  userId: PropTypes.string,
  asLink: PropTypes.bool,
};

UserName.defaultProps = {
  asLink: false,
};

export default UserName;
