import React, { useEffect, useState } from 'react';

import { Button, CircularProgress, Dialog, DialogContent, Stack, Typography } from '@mui/material';

import { request } from '~/utils/download';

interface Props {
  open: boolean;
  downloadParts: string[];
  onClose: () => void;
}

export default function DownloaderPopup({ open, downloadParts, onClose }: Props) {
  const [status, setStatus] = useState(false);
  const [receivedLength, setReceivedLength] = useState(0);
  const [chunks, setChunks] = useState([]);

  const download = (downloadParts, index = 0, currentReceivedLength = 0, currentChunks = []) => {
    if (downloadParts[index]) {
      request(downloadParts[index], (v) => {
        if (v) {
          currentReceivedLength = currentReceivedLength + v.length;
          currentChunks.push(v);
          setReceivedLength(currentReceivedLength);
        } else if (downloadParts[index + 1]) {
          download(downloadParts, index + 1, currentReceivedLength, currentChunks);
        } else {
          setChunks(currentChunks);
          setReceivedLength(currentReceivedLength);
          setStatus(true);
        }
      });
    }
  };

  const saveFile = () => {
    const elem = document.createElement('a');
    elem.href = URL.createObjectURL(new Blob(chunks));
    elem.download = 'bedbank-properties.csv.gz';
    elem.target = 'hiddenIframe';
    elem.click();

    setTimeout(() => onClose(), 2000);
  };

  useEffect(() => {
    setStatus(false);
    setReceivedLength(0);
    setChunks([]);

    if (open) {
      download(downloadParts);
    }
  }, [open, downloadParts]);

  return (
    <Dialog open={open} onClose={() => status && onClose()}>
      <DialogContent sx={{ padding: 4 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          {!status && (
            <>
              <Typography>{(receivedLength / (1024 * 1024)).toFixed(2)} MB</Typography>
              <CircularProgress />
            </>
          )}
          {status && (
            <Button variant="contained" type="button" onClick={saveFile}>
              Save File
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
