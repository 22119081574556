import React from 'react';

import { Box, Stack } from '@mui/material';

import { REFUND_METHOD_CREDITS, REFUND_METHOD_DEFAULT, REFUND_METHOD_MANUAL } from '~/consts/refund';

import { withTenant } from '../hoc';

class RefundMethod extends React.Component {
  renderLatitudeMethods() {
    const { handleValueChange, refund_method } = this.props;
    return (
      <Stack direction="row" spacing={2}>
        <Box>
          <input
            type="radio"
            key={REFUND_METHOD_CREDITS}
            name="refund_method"
            value={REFUND_METHOD_CREDITS}
            onChange={handleValueChange}
            checked={refund_method == REFUND_METHOD_CREDITS}
          />
          <label>Credits</label>
        </Box>
        <Box>
          <input
            type="radio"
            key={REFUND_METHOD_MANUAL}
            name="refund_method"
            value={REFUND_METHOD_MANUAL}
            onChange={handleValueChange}
            checked={refund_method == REFUND_METHOD_MANUAL}
          />
          <label>Latitude Manual Refund</label>
        </Box>
      </Stack>
    );
  }

  renderNormalMethods() {
    const { handleValueChange, refund_method, methods } = this.props;

    const NON_LE_CREDIT_BRANDS = ['zoomzoom'];
    return (
      <Stack direction="row" spacing={2}>
        {methods.includes(REFUND_METHOD_DEFAULT) && (
          <Box>
            <input
              type="radio"
              key={REFUND_METHOD_DEFAULT}
              name="refund_method"
              value={REFUND_METHOD_DEFAULT}
              onChange={handleValueChange}
              checked={refund_method == REFUND_METHOD_DEFAULT}
            />
            <label>Default</label>
          </Box>
        )}
        {!NON_LE_CREDIT_BRANDS.includes(this.props.tenant.brand) && methods.includes(REFUND_METHOD_CREDITS) && (
          <Box>
            <input
              type="radio"
              key={REFUND_METHOD_CREDITS}
              name="refund_method"
              value={REFUND_METHOD_CREDITS}
              onChange={handleValueChange}
              checked={refund_method == REFUND_METHOD_CREDITS}
            />
            <label>Credits</label>
          </Box>
        )}
        {methods.includes(REFUND_METHOD_MANUAL) && (
          <Box>
            <input
              type="radio"
              key={REFUND_METHOD_MANUAL}
              name="refund_method"
              value={REFUND_METHOD_MANUAL}
              onChange={handleValueChange}
              checked={refund_method == REFUND_METHOD_MANUAL}
            />
            <label>Offline/Manual</label>
          </Box>
        )}
      </Stack>
    );
  }
  render() {
    const { hasLatitudePayment } = this.props;
    if (hasLatitudePayment === true) {
      return this.renderLatitudeMethods();
    }
    return this.renderNormalMethods();
  }
}

export default withTenant(RefundMethod);
