import React, { useMemo } from 'react';

import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { Control, Controller, useController, useFieldArray } from 'react-hook-form';
import { theme } from '~/theme';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import ArrayField from '../fields/ArrayField';
import ArrayFieldItem from '../fields/ArrayFieldItem';

interface Props {
  control: Control<any>;
  schema: any;
  setValue: (name: string, value: unknown, config?: object) => void;
}

const TagsAndMarketingAccordion = ({ control, schema, setValue }: Props) => {
  const partnershipArray = useFieldArray({
    control,
    name: 'partnerships',
  });
  const {
    field: { value: partnershipsValue, onChange: onChangeArray },
    fieldState: { error: partnershipsError },
  } = useController({
    control,
    name: 'partnerships',
  });

  const sortablePartnerships = useMemo<UniqueIdentifier[]>(
    () => partnershipArray.fields?.map((item, index) => `${item.id}-${index}`) ?? [],
    [partnershipArray.fields],
  );

  // Do not provide content team 'velocity' as an option to be set during offer edit
  // https://aussiecommerce.atlassian.net/browse/PP-1477
  schema.properties.partnerships.items.properties.code.enum =
    schema.properties.partnerships.items.properties.code.enum.filter((item) => item !== 'velocity');

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = sortablePartnerships.indexOf(active?.id);
      const newIndex = sortablePartnerships.indexOf(over?.id);
      const newArray = arrayMove(partnershipsValue, oldIndex, newIndex);
      setValue('partnerships', newArray);
    }
  }

  const onPartnershipsChange = (e, name, index) => {
    const newArray = [...partnershipsValue];
    const updatedValue = { ...newArray[index], [name]: e.target.value };
    newArray.splice(index, 1, updatedValue);
    setValue(e.target.name, e.target.value);
    onChangeArray(newArray);
  };

  return (
    <Accordion disableGutters>
      <AccordionSummary
        aria-controls="tags-and-marketing"
        expandIcon={<ExpandMoreIcon />}
        id="tags-and-marketing"
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Typography variant="h5">Tags & Marketing</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack mt={2} spacing={2}>
          <ArrayField
            control={control}
            label="Location Tags"
            name="locations"
            render={(props) => (
              <Select {...props} fullWidth>
                {schema.properties.locations.items.enum.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <ArrayField
            control={control}
            label="Holiday Type Tags"
            name="holiday_types"
            render={(props) => (
              <Select {...props} fullWidth>
                {schema.properties.holiday_types.items.enum.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <ArrayField
            control={control}
            label="Campaign Tags"
            name="campaigns"
            render={(props) => (
              <Select {...props} fullWidth>
                {schema.properties.campaigns.items.enum.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Stack>
            <Typography variant="h6">Partnerships</Typography>
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={sortablePartnerships}>
                {partnershipArray.fields.map((item, index) => (
                  <FormControl
                    error={partnershipsError?.[index]}
                    key={item.id}
                    sx={{ display: 'flex', mb: 2, width: 1 }}
                  >
                    <ArrayFieldItem itemKey={item.id} index={index}>
                      <>
                        <Stack spacing={2} sx={{ width: 1 }}>
                          <Controller
                            render={({ field }) => (
                              <Select {...field} onChange={(e) => onPartnershipsChange(e, 'code', index)} fullWidth>
                                {schema.properties.partnerships.items.properties.code.enum.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                            name={`partnerships.${index}.code`}
                            control={control}
                          />
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                onChange={(e) => onPartnershipsChange(e, 'upsell_text', index)}
                                fullWidth
                                label="Upsell text"
                                variant="outlined"
                              />
                            )}
                            name={`partnerships.${index}.upsell_text`}
                            control={control}
                          />
                        </Stack>
                        <Stack>
                          <IconButton onClick={() => partnershipArray.remove(index)} color="error">
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Stack>
                      </>
                    </ArrayFieldItem>
                  </FormControl>
                ))}
                {!partnershipArray.fields.length && (
                  <Box
                    sx={{
                      p: 2,
                      width: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="body2">Empty</Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    width: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    onClick={() =>
                      partnershipArray.append({
                        code: '',
                        upsell_text: '',
                      })
                    }
                    size="large"
                    color="primary"
                  >
                    <AddIcon fontSize="large" />
                  </IconButton>
                </Box>
              </SortableContext>
            </DndContext>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default TagsAndMarketingAccordion;
