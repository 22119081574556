import React, { useCallback } from 'react';

import { useParams } from 'react-router';

import { Alert } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import { RoomResponse, listRooms } from '~/services/AccommodationService';

const PAGE_SIZE = 20;

const columns: Array<GridColDef<RoomResponse>> = [
  {
    field: 'name',
    headerName: 'Room Name',
    minWidth: 200,
    display: 'flex',
    flex: 1,
    hideable: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 240,
    minWidth: 200,
    display: 'flex',
    hideable: true,
  },
];

export default function AccommodationPropertyRoomsResultsTable() {
  const { propertyId } = useParams<{ propertyId: string }>();
  const getRooms = useCallback(() => listRooms({ propertyId }), [propertyId]);

  const { fetchReqState } = useAccommodationServiceFetch({
    fetchFn: getRooms,
  });

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <DataGrid
        className="accommodation-property-rooms-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.length : undefined}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[PAGE_SIZE]}
        pagination
        loading={fetchReqState.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </>
  );
}
