import { GridColDef } from '@mui/x-data-grid';

export interface PropertyLocationOverride {
  id: string;
  propertyName: string;
  propertyId: string;
  placeName: string;
  placeId: string;
  notes: string;
  lastModifiedBy: string;
}

const defaultFields: Partial<GridColDef> = {
  sortable: false,
  disableColumnMenu: true,
  flex: 1,
  display: 'flex',
  type: 'singleSelect',
};

export const defaultColumns: GridColDef<PropertyLocationOverride>[] = [
  {
    ...defaultFields,
    field: 'propertyName',
    headerName: 'Property Name',
  },
  {
    ...defaultFields,
    field: 'propertyId',
    headerName: 'Property Id',
  },
  {
    ...defaultFields,
    field: 'placeName',
    headerName: 'Place Name',
  },
  {
    ...defaultFields,
    field: 'placeId',
    headerName: 'Place Id',
  },
  {
    ...defaultFields,
    field: 'notes',
    headerName: 'Remarks',
  },
  {
    ...defaultFields,
    field: 'lastModifiedBy',
    headerName: 'Last Modified By',
  },
];
