import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { Skeleton } from '@mui/material';

import UsersService from '~/services/UsersService';

type Props = {
  userId: string;
};

export default function QuoteAgentUser({ userId }: Props) {
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState<Utils.FetchingState>('idle');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading('loading');
        const result = await UsersService.getUser(userId);
        setUserName(result.fullName);
        setLoading('success');
      } catch (error) {
        setLoading('failed');
        enqueueSnackbar(`Error fetching user info: ${error}`, { variant: 'error' });
      }
    };

    fetchUser();
  }, [userId]);

  if (loading === 'loading') {
    return <Skeleton variant="text" width="80%" />;
  }

  return (
    <Link target="_blank" rel="noreferrer" to={`/users/${userId}`}>
      {loading === 'failed' ? userId : userName}
    </Link>
  );
}
