import React, { useCallback, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';

import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { addDays, diffDays, formatDateISO, isAfter } from '~/services/TimeService';

import DateWidget from '../../../Common/Forms/DateWidget';

const CenteredValue = styled.div`
  text-align: center;
  padding-top: 4px;
`;

export type InsuranceForm = {
  coveredAmount?: number;
  startDate: string;
  endDate: string;
  createdDate: string;
  travellerDetails: App.InsuranceTraveller[];
};

type Props = {
  currentInsurance: App.InsuranceItem;
  currentOrderAmount: number;
  totalTicketPrice: number;
  onContinue: (data: InsuranceForm) => void;
  onClose: () => void;
};

export default function StepForm(props: Props) {
  const { currentInsurance, totalTicketPrice, currentOrderAmount } = props;
  const [validationErrors, setValidationErrors] = useState([]);

  const [data, setData] = useState<InsuranceForm>({
    coveredAmount: totalTicketPrice,
    startDate: currentInsurance.start_date,
    endDate: currentInsurance.end_date,
    createdDate: currentInsurance.created_at,
    travellerDetails: currentInsurance.travellers_details,
  });

  const formRef = useRef<HTMLFormElement>();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: Number(value) });
  };

  const handleTravellerChange = (e, index) => {
    const { name, value } = e.target;
    const { travellerDetails } = data;
    travellerDetails[index][name] = value;
    setData({ ...data, travellerDetails });
  };

  const [newTraveller, setNewTraveller] = useState<App.InsuranceTraveller>({
    first_name: '',
    surname: '',
  });

  const handleNewTravellerChange = (e) => {
    const { name, value } = e.target;
    setNewTraveller({ ...newTraveller, [name]: value });
  };

  const handleAddNewTraveller = () => {
    setData({
      ...data,
      travellerDetails: [...data.travellerDetails, newTraveller],
    });
    setNewTraveller({ first_name: '', surname: '' });
  };

  const calculateMinCoverageStart = useMemo(() => {
    const dateDiff = diffDays(currentInsurance.end_date, currentInsurance.start_date, 'day');

    return formatDateISO(addDays(-1 * dateDiff, data.endDate));
  }, [data.endDate, currentInsurance]);

  const validForm = useCallback(() => {
    setValidationErrors([]);
    let errors = [];
    let valid = true;

    if (formRef.current) {
      valid = formRef.current.reportValidity();
    }

    if (isAfter(data.startDate, calculateMinCoverageStart)) {
      errors = [...errors, `Invalid Travel dates, coverage range was reduced`];
      valid = false;
    }

    if (data.coveredAmount > 10000 && new Date('2022-09-21') > new Date(data.createdDate)) {
      errors = [
        ...errors,
        `Total coverage amount should be within 10 000 if the policy was created prior to 21st of September.`,
      ];
      valid = false;
    }

    setValidationErrors(errors);
    return valid;
  }, [data.startDate, data.coveredAmount, data.createdDate, calculateMinCoverageStart]);

  const handleStep = () => {
    if (!validForm()) {
      return;
    }
    props.onContinue(data);
  };

  return (
    <>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <p>
              <strong>Total coverage amount</strong> <br />
              You may update the total amount of coverage required by the customer
            </p>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={data.coveredAmount || totalTicketPrice}
              onChange={handleChange}
              name="coveredAmount"
              type="number"
              inputProps={{
                min: 1,
                max: 10000,
              }}
            />
          </Grid>
        </Grid>

        <Grid container className="mt-20">
          <Grid item xs={12}>
            <p>
              <strong>Current order amount</strong> <br />
              This is the current total of active accommodation, flights and experiences on this order. You can use this
              as a guide to indicate the required coverage amount.
            </p>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={currentOrderAmount}
              name="currentAmount"
              type="number"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container className="mt-20">
          <Grid item xs={12}>
            <p>
              <strong>Travel dates</strong> <br />
              Adjust the dates the customer will be traveling to extend the cover.
            </p>
          </Grid>
        </Grid>

        <Grid container className="active-dates">
          <Grid item xs={5}>
            <DateWidget
              maxDate={data.endDate}
              value={data.startDate}
              onChange={(date) => setData({ ...data, startDate: date })}
            />
          </Grid>
          <Grid item xs={1}>
            <CenteredValue>to</CenteredValue>
          </Grid>
          <Grid item xs={5}>
            <DateWidget value={data.endDate} onChange={(date) => setData({ ...data, endDate: date })} static />
          </Grid>
        </Grid>

        <Grid container className="mt-20">
          <Grid item xs={12}>
            <p>
              <strong>Upgrade coverage</strong> <br />
              Upgrade the level of coverage for the customer (if applicable)
            </p>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              defaultValue={currentInsurance.product_name}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container className="mt-20">
          <Grid item xs={12}>
            <p>
              <strong>Edit the customers' names</strong> <br />
              This is there names we currently have in the policy. Just edit and click continue to update.
            </p>
          </Grid>
        </Grid>

        <form ref={formRef}>
          {data.travellerDetails.map((traveller, index) => (
            <Box key={index} mt={index !== 0 ? 2 : 0}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    value={traveller.first_name}
                    name="first_name"
                    type="text"
                    required
                    onChange={(event) => handleTravellerChange(event, index)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={traveller.surname}
                    name="surname"
                    type="text"
                    required
                    onChange={(event) => handleTravellerChange(event, index)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id={`age-range-label-${index}`}>Age Range</InputLabel>
                    <Select
                      labelId={`age-range-label-${index}`}
                      value={traveller.age_range}
                      name="age_range"
                      onChange={(event) => handleTravellerChange(event, index)}
                      required
                    >
                      <MenuItem value="senior">Senior &gt;65</MenuItem>
                      <MenuItem value="adult">Adult 18-65</MenuItem>
                      <MenuItem value="child">Child 2-17</MenuItem>
                      <MenuItem value="infant">Infant 0-1</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          ))}
        </form>

        <Grid container className="mt-20">
          <Grid item xs={12}>
            <p>
              <strong>Add a person</strong> <br />
              To add a person to the policy, click here. (Please insure you add their first and last name)
            </p>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              value={newTraveller.first_name}
              name="first_name"
              type="text"
              onChange={handleNewTravellerChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField value={newTraveller.surname} name="surname" type="text" onChange={handleNewTravellerChange} />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="age-range-label">Age Range</InputLabel>
              <Select
                labelId="age-range-label"
                value={newTraveller.age_range}
                name="age_range"
                onChange={handleNewTravellerChange}
              >
                <MenuItem value="adult">Adult 18-65</MenuItem>
                <MenuItem value="senior">Senior &gt;65</MenuItem>
                <MenuItem value="child">Child 2-17</MenuItem>
                <MenuItem value="infant">Infant 0-1</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1}>
            <Button
              variant="contained"
              disabled={!newTraveller.first_name || !newTraveller.surname || !newTraveller.age_range}
              onClick={handleAddNewTraveller}
            >
              +&nbsp;Add
            </Button>
          </Grid>
        </Grid>

        <Grid container className="mt-20">
          <Grid item xs={12}>
            {!!validationErrors.length &&
              validationErrors.map((error) => (
                <Alert key={error} severity="warning">
                  {error}
                </Alert>
              ))}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={handleStep}>
          Continue
        </Button>
        <Button onClick={props.onClose}>Cancel</Button>
      </DialogActions>
    </>
  );
}
