import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { PropertyLocationOverride } from '~/components/Content/PropertyLocationOverride/Columns';
import UpdatePropertyLocationOverrideForm from '~/components/Content/PropertyLocationOverride/UpdatePropertyLocationOverrideForm';

type props = {
  open: boolean;
  onClose: () => void;
  rowToUpdate?: PropertyLocationOverride;
  data?: PropertyLocationOverride[];
};

const UpdatePropertyLocationOverrideModal = ({ open, onClose, rowToUpdate, data }: props) => {
  if (!rowToUpdate) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Property Location Override</DialogTitle>
      <DialogContent>
        <UpdatePropertyLocationOverrideForm onClose={onClose} rowToUpdate={rowToUpdate} data={data} />
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePropertyLocationOverrideModal;
