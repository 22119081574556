import React from 'react';

import SearchHealthCheck from './SearchHealthCheck';
import SearchOrdering from './SearchOrdering';

interface Props {
  offerId: string;
  propertyIds: Array<string>;
  propertyName: string;
}

function SearchPane(props: Props) {
  const { offerId, propertyIds, propertyName } = props;

  return (
    <div>
      <h1 className="page-header">Search</h1>
      <SearchOrdering vertical="hotel" offerId={offerId} />
      <SearchHealthCheck propertyIds={propertyIds} propertyName={propertyName} offerId={offerId} />
    </div>
  );
}

export default SearchPane;
