import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Autocomplete, FormControl, TextField } from '@mui/material';

import ShipService, { Ship } from '~/services/cruises/ShipService';

type ShipsResponse = {
  total: number;
  result: Ship[];
};

type Option = {
  value: string;
  label: string;
};

type SelectedShip = Pick<Ship, 'id' | 'name'>;

const DEFAULT_RESPONSE: ShipsResponse = { result: [], total: 0 };

export type Props = {
  selectedShip: SelectedShip;
  onChange: (ship: SelectedShip) => void;
  cruiseLineName?: string;
  requiredCruiseLine?: boolean;
};

export default function ShipAutocomplete(props: Props) {
  const { selectedShip, onChange, cruiseLineName, requiredCruiseLine } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');
  const [ships, setShips] = useState<ShipsResponse>(DEFAULT_RESPONSE);

  const fetchShips = useCallback(
    async (cruiseLineName?: string): Promise<void> => {
      setFetching('loading');

      try {
        const response = await ShipService.listWithPagination({
          skip: 0,
          take: 1000,
          cruiseLine: cruiseLineName,
        });

        setFetching('success');

        if (!response || response.result.length === 0) {
          enqueueSnackbar('No ships found', { autoHideDuration: 5000, variant: 'warning' });
        }

        setShips({
          total: response.total,
          result: response.result,
        });
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setShips({ total: 0, result: [] });
      }
    },
    [enqueueSnackbar],
  );

  const onShipChange = useCallback(
    (_, selectedOption: Option) => {
      onChange({
        id: selectedOption?.value ?? '',
        name: selectedOption?.label ?? '',
      });
    },
    [onChange],
  );

  const shipOptions = useMemo(() => {
    return ships.result?.map((item, i) => ({
      value: item.id,
      label: `${i}. ${item.name}`, // THIS IS UNFORTUNATELY NECESSARY BECAUSE SOME SHIPS HAVE THE SAME NAME
    }));
  }, [ships]);

  const shipSelected = useMemo(() => {
    return {
      value: selectedShip.id,
      label: selectedShip.name,
    };
  }, [selectedShip]);

  useEffect(() => {
    fetchShips(cruiseLineName);
  }, [fetchShips, cruiseLineName]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        size="small"
        aria-required
        id="select-ship"
        value={shipSelected}
        options={shipOptions}
        onChange={onShipChange}
        loading={fetching === 'loading'}
        getOptionLabel={(option) => option.label}
        disabled={requiredCruiseLine && !cruiseLineName}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} fullWidth label="Select Ship" placeholder={'Please enter a Ship'} />
        )}
      />
    </FormControl>
  );
}
