import { json_headers, request } from './common';

export default class PackagesService {
  static basePath(idOffer) {
    return `${window.configs.API_HOST}/api/offers/${idOffer}/packages`;
  }

  static getPackageSchema(idOffer) {
    return request(this.basePath(idOffer), { method: 'OPTIONS' });
  }

  static updatePackage(object, idOffer, idPackage) {
    var jsonData = JSON.stringify(object);
    return request(this.basePath(idOffer) + '/' + idPackage, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }
}
