import React, { ComponentProps, useEffect, useMemo, useReducer } from 'react';

import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

import { formatDateISO, newDate } from '~/services/TimeService';

type ScheduleType = 'sell' | 'tactical';

type Schedule = {
  id?: string; // might be new or existing
  activePeriod: {
    from: string;
    to: string;
  };
  travelPeriod: {
    from: string;
    to: string;
  };
  type: ScheduleType;
  parent: string;
};

interface SchedulesProps {
  value: Schedule;
  onChange: (value: Schedule) => void;
  sx?: ComponentProps<typeof Stack>['sx'];
}

type Action =
  | {
      type: 'setActivePeriodFrom' | 'setActivePeriodTo' | 'setTravelPeriodFrom' | 'setTravelPeriodTo';
      date: string;
    }
  | {
      type: 'setScheduleType';
      value: ScheduleType;
    };

type State = Schedule;

function reducer(oldState: State, action: Action) {
  switch (action.type) {
    case 'setActivePeriodFrom':
      return { ...oldState, activePeriod: { ...oldState.activePeriod, from: action.date } };

    case 'setActivePeriodTo':
      return { ...oldState, activePeriod: { ...oldState.activePeriod, to: action.date } };

    case 'setTravelPeriodFrom':
      return { ...oldState, travelPeriod: { ...oldState.travelPeriod, from: action.date } };

    case 'setTravelPeriodTo':
      return { ...oldState, travelPeriod: { ...oldState.travelPeriod, to: action.date } };

    case 'setScheduleType':
      return { ...oldState, type: action.value };
  }
}

export default function Schedules(props: SchedulesProps) {
  const [state, dispatch] = useReducer(reducer, props.value);
  const activeDayjs = useMemo(
    () => ({
      from: newDate(state.activePeriod.from),
      to: newDate(state.activePeriod.to),
    }),
    [state.activePeriod.from, state.activePeriod.to],
  );
  const travelDayjs = useMemo(
    () => ({
      from: newDate(state.travelPeriod.from),
      to: newDate(state.travelPeriod.to),
    }),
    [state.travelPeriod.from, state.travelPeriod.to],
  );

  // If the internal state has changed, the parent should be updated.
  // We do not need to listen for props.onChange, as whatever value
  // is within scope for each render is what should be used.
  useEffect(() => {
    props.onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Stack direction="column" sx={props.sx}>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start">
        <Stack spacing={2}>
          <Typography>Active Period</Typography>
          <DateTimeWidget
            label="From"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.activePeriod.from}
            maxDate={activeDayjs.to}
            onChange={(date) => dispatch({ type: 'setActivePeriodFrom', date: formatDateISO(date) })}
          />
          <DateTimeWidget
            label="To"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.activePeriod.to}
            minDate={activeDayjs.from}
            onChange={(date) => dispatch({ type: 'setActivePeriodTo', date: formatDateISO(date) })}
          />
        </Stack>

        <Stack spacing={2}>
          <Typography>Travel Period</Typography>
          <DateTimeWidget
            label="From"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.travelPeriod.from}
            maxDate={travelDayjs.to}
            onChange={(date) => dispatch({ type: 'setTravelPeriodFrom', date: formatDateISO(date) })}
          />
          <DateTimeWidget
            label="To"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.travelPeriod.to}
            minDate={travelDayjs.from}
            onChange={(date) => dispatch({ type: 'setTravelPeriodTo', date: formatDateISO(date) })}
          />
        </Stack>

        <Stack spacing={2}>
          <Typography>Type</Typography>
          <FormControl variant="outlined">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={state.type}
              fullWidth
              onChange={(e) => dispatch({ type: 'setScheduleType', value: e.target.value as ScheduleType })}
              label="Type"
              sx={{ minWidth: 150 }}
            >
              <MenuItem value="sell">Sell</MenuItem>
              <MenuItem value="tactical">Tactical</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
}
