import React, { useEffect, useState } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';

// redux
import { addOffer, emptyCart, setCustomer } from '~/actions/cart';

import { CurrencySelector } from '~/components/Common/CurrencySelector';
import OfferSearchForm from '~/components/Common/Forms/OfferSearchForm';
import OfferList from '~/components/Common/OfferList';

// constants
import { ADMIN_PURCHASE_SUPPORTED_OFFER_TYPES, TOUR_OFFER_TYPES } from '~/consts/offerTypes';

// services
import OffersService from '~/services/OffersService';
import PublicOfferService from '~/services/PublicOfferService';

import OrderFooter from '../OrderFooter';

interface OfferSelectionProps {
  backStep: () => void;
  nextStepLabel: string;
  proceedStep: () => void;
}

export function OfferSelection({
  addOffer,
  backStep,
  cart,
  emptyCart,
  nextStepLabel,
  proceedStep,
  tenant,
  setCustomer,
}: OfferSelectionProps & ConnectedProps<typeof connector>) {
  const [resultOffers, setResultOffers] = useState<App.Offer[]>([]);
  const [loadingOffers, setLoadingOffers] = useState<boolean>(false);
  const [resultsTotal, setResultsTotal] = useState<number>(0);
  const [resultPage, setResultsPage] = useState<number>(1);
  const [searchQueryString, setSearchQueryString] = useState<string>(null);
  const [addingOfferToCart, setAddingOfferToCart] = useState<boolean>(false);
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    OffersService.getAllLocations().then((response: App.LocationsResponse) => {
      setDestinations(response.result || []);
    });
  }, []);

  // user searches for offers based on criteria from search form
  const handleSearchOffers = (queryString: string, page = 1) => {
    const { brand } = tenant;

    setLoadingOffers(true);
    setResultOffers([]);
    setResultsPage(page);
    setSearchQueryString(queryString);

    OffersService.searchOffers({
      queryString: `${queryString}&page=${page}`,
      brand,
    })
      .then(({ result, total }) => {
        setResultOffers(result);
        setResultsTotal(total);
        setLoadingOffers(false);
      })
      .catch(() => {
        setResultOffers([]);
        setResultsTotal(0);
        setLoadingOffers(false);
      });
  };

  const getOfferById = async (id: string, regionCode: string) => {
    const { brand } = tenant;

    try {
      const offer = await PublicOfferService.getOfferById(id, regionCode, brand);
      addOffer(offer);
      setAddingOfferToCart(false);
    } catch (e) {
      console.error(e);
      setAddingOfferToCart(false);
    }
  };

  // user selects offer to be added to cart
  const handleAddOfferToCart = ({ id_salesforce_external }) => {
    setAddingOfferToCart(true);

    return getOfferById(id_salesforce_external, cart.regionCode);
  };

  // when currency is change empty cart and reinstate any cart offers with new currency
  const handleCurrencyChange = (regionCode: string) => {
    const { offers, customer } = cart;
    emptyCart();
    setCustomer(customer);
    offers.map((offer) => {
      return getOfferById(offer.id_salesforce_external, regionCode);
    });
  };

  // check to see if offer passes validation and can be added to cart
  const canAddOffer = (offer: App.Offer) => {
    if (addingOfferToCart) return false;

    const { offers } = cart;

    const offerExistsInCart = !!offers.find(
      (cartOffer) => cartOffer.id_salesforce_external === offer.id_salesforce_external,
    );

    if (offerExistsInCart) return false;

    // disallow adding an offer if a tour type is already in the cart
    if (
      offers.length &&
      (offers.some((cartOffer) => TOUR_OFFER_TYPES.some((tourOfferType) => tourOfferType === cartOffer.type)) ||
        TOUR_OFFER_TYPES.some((tourOfferType) => tourOfferType === offer.type))
    )
      return false;

    return ADMIN_PURCHASE_SUPPORTED_OFFER_TYPES.some((offerType) => offerType === offer.type);
  };

  // user navigates via table pagination
  const onPaginationPageChange = (page: number) => {
    handleSearchOffers(searchQueryString, page);
  };

  return (
    <>
      <OfferSearchForm destinations={destinations} searchQuery={handleSearchOffers} />

      <CurrencySelector onChangeCurrencyCode={handleCurrencyChange} />

      <OfferList
        sizePerPage={resultOffers === null ? 0 : resultOffers.length}
        offers={resultOffers?.map((offer) => ({
          ...offer,
          disableSelection: !canAddOffer(offer),
        }))}
        total={resultsTotal}
        page={resultPage}
        onPageChange={onPaginationPageChange}
        onRowClick={handleAddOfferToCart}
        selectedOffers={cart.offers}
        loading={loadingOffers}
      />

      <OrderFooter backStep={backStep} cart={cart} proceedStep={proceedStep} canProceed nextStepLabel={nextStepLabel} />
    </>
  );
}

function mapStateToProps({ cart, tenant }: App.State) {
  return {
    cart,
    tenant,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      addOffer,
      emptyCart,
      setCustomer,
    },
    dispatch,
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OfferSelection);
