import React from 'react';

import { formatDateNumeralMonths } from '~/services/TimeService';

const DateRange = ({ startDate, endDate }) => {
  return (
    <div className="date-range">
      {formatDateNumeralMonths(startDate)} - {formatDateNumeralMonths(endDate)}
    </div>
  );
};

export default DateRange;
