import { RequestStatus } from '~/consts/requestConstants';

export function isRequestInitial<R, E, P>(request: Utils.RequestState<R, E, P>): request is Utils.InitialRequest<R, P> {
  return request?.status === RequestStatus.INITIAL;
}

export function isRequestPending<R, E, P>(request: Utils.RequestState<R, E, P>): request is Utils.PendingRequest<R, P> {
  return request?.status === RequestStatus.PENDING;
}

/**
 * Request is either initial or pending
 */
export function isRequestUnresolved<R, E, P>(
  request: Utils.RequestState<R, E, P>,
): request is Utils.InitialRequest<R, P> | Utils.PendingRequest<R, P> {
  return request?.status === RequestStatus.INITIAL || request?.status === RequestStatus.PENDING;
}

export function isRequestFulfilled<R, E, P>(
  request: Utils.RequestState<R, E, P>,
): request is Utils.FulfilledRequest<R, P> {
  return request?.status === RequestStatus.FULFILLED;
}

export function isRequestRejected<R, E, P>(
  request: Utils.RequestState<R, E, P>,
): request is Utils.RejectedRequest<E, P> {
  return request?.status === RequestStatus.REJECTED;
}
