import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Box, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import ReSyncPropertyButton from '~/components/Vendors/Properties/ReSyncPropertyButton';

import { requireHotelMetaEmail } from '~/services/HotelMetaService';

import AddImagesButton from './AddImagesButton';

interface Props {
  vendorId: string;
  propertyId: string;
  hotelMetaCode?: string;
  hotelMetaProvider?: string;
}

interface Context {
  user: App.User;
}

const PropertyMetaTitle = () => (
  <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
    Property Meta
  </Typography>
);

export default function PropertyMetaPane(props: Props, { user }: Context) {
  const { vendorId, propertyId, hotelMetaCode, hotelMetaProvider } = props;
  const [triggeringGetMetaJob, setTriggeringGetMetaJob] = useState<boolean>(false);
  const [showGetMetaModal, toggleShowGetMetaModal] = useState<boolean>(false);

  const sendZipToUserEmailAddress = async () => {
    setTriggeringGetMetaJob(true);
    toggleShowGetMetaModal(true);
    await requireHotelMetaEmail({ propertyId, email: user.email });
    setTriggeringGetMetaJob(false);
  };

  if (!hotelMetaProvider) {
    return (
      <div>
        <PropertyMetaTitle />
        <Typography fontSize={16}>Property not mapped to any meta provider.</Typography>
      </div>
    );
  }

  if (!hotelMetaCode) {
    return (
      <div>
        <PropertyMetaTitle />
        <Typography fontSize={16}>
          Property was mapped to {hotelMetaProvider} but not provided with a <em>hotelMetaCode</em>.
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <PropertyMetaTitle />

      <Stack direction="row" spacing={1}>
        <Box flexGrow={5}>
          <Button onClick={sendZipToUserEmailAddress} type="button" variant="contained" disabled={triggeringGetMetaJob}>
            Email Images
          </Button>
        </Box>
        <Box flexGrow={7}>
          <AddImagesButton vendorId={vendorId} propertyId={propertyId} />
        </Box>
      </Stack>

      <Box mt={1}>
        <ReSyncPropertyButton propertyId={propertyId} />
      </Box>

      <Dialog open={showGetMetaModal} onClose={() => toggleShowGetMetaModal(false)}>
        <DialogContent>
          <Typography variant="h5">
            {triggeringGetMetaJob ? 'Sending property meta...' : 'Property meta sent'}
          </Typography>
          <Typography>
            Property meta is sent as a zip file to your email address. It might take a while until the email reaches
            your mailbox.
          </Typography>

          {triggeringGetMetaJob && <Spinner />}
        </DialogContent>
      </Dialog>
    </div>
  );
}

PropertyMetaPane.contextTypes = {
  user: PropTypes.object,
};
