import { Reservation } from '@luxuryescapes/contract-svc-reservation';

export interface Schedule {
  id?: string;
  activePeriod: {
    from: string;
    to: string;
  };
  travelPeriod: {
    from: string;
    to: string;
  };
  type: 'tactical' | 'sell';
  parent?: string;
}

export const prepareSchedulesList = (schedules_list: Schedule, parentId?: string) => {
  if (!schedules_list) {
    return [];
  }

  return Object.values(schedules_list)
    .filter(
      (schedule) =>
        schedule.activePeriod.from &&
        schedule.activePeriod.to &&
        schedule.travelPeriod.from &&
        schedule.travelPeriod.to &&
        schedule.type,
    )
    .map((schedule) => ({
      id: schedule.id || undefined, // ensure empty strings are not passed
      parent: schedule.parent || parentId || undefined,
      type: schedule.type,
      activePeriod: {
        from: schedule.activePeriod.from,
        to: schedule.activePeriod.to,
      },
      travelPeriod: {
        from: schedule.travelPeriod.from,
        to: schedule.travelPeriod.to,
      },
    }));
};

export const ingestSchedules = (schedules_list: Reservation.Schedule[]): any => {
  if (!schedules_list) {
    return {};
  }

  const output = {};
  schedules_list
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .sort((a: Schedule, b: Schedule) => new Date(a.activePeriod.from) - new Date(b.activePeriod.from)) // not picking up dayjs type ¯\_(ツ)_/¯
    .forEach((s: Schedule) => {
      output[s.id] = s; // all existing schedules will have an id already
    });
  return output;
};
