import React, { useEffect, useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { Button } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import RatePlansGroupsList from '~/components/Common/RatePlansGroupsList';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

type Props = { vendorName?: string };

export default function VendorRatePlansGroupsPageContainer(_: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [ratePlansGroups, setRatePlansGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    ReservationService.getRatePlansGroups(vendorId)
      .then((response) => {
        setRatePlansGroups(response.result);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vendorId]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <>
      <RatePlansGroupsList ratePlansGroups={ratePlansGroups} />

      <Button component={Link} variant="contained" to={`/vendors/${vendorId}/rate-plans-groups`} sx={{ mt: 2 }}>
        Edit
      </Button>
    </>
  );
}
