import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { WidgetProps } from '@rjsf/utils';

import { Autocomplete, CircularProgress, FormControl, FormHelperText, TextField } from '@mui/material';
import { debounce } from '@mui/material/utils';

import { OFFER_TYPE_HOTEL } from '~/consts/offerTypes';

import useToggleState from '~/hooks/useToggleState';

import OffersService from '~/services/OffersService';

interface OfferOption {
  id: string;
  label: string;
  value: string;
  type?: string;
}

interface OfferResult {
  name: string;
  id_salesforce_external: string;
}

interface Props extends Omit<WidgetProps, 'onChange' | 'onBlur'> {
  onChange: (id: string, value: unknown) => void;
  formErrors: string[];
  loading?: boolean;
  placeholder?: string;
}

function LELocationSearchWidget({
  id,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  formErrors,
  placeholder,
  loading,
}: Props) {
  const { isToggled: isLoading, toggleOn: startLoading, toggleOff: endLoading } = useToggleState();

  const [offerSuggestions, setOfferSuggestions] = useState<OfferResult[]>([]);
  const [offerIdInputValue, setOfferIdInputValue] = useState<string>('');

  useEffect(() => {
    if (value) {
      setOfferIdInputValue(value);
    }
  }, [value]);

  const offerOptions = useMemo<OfferOption[]>(
    () =>
      offerSuggestions.map((offer) => {
        return {
          id: offer.id_salesforce_external,
          label: offer.name,
          value: offer.id_salesforce_external,
          typeahead: offer,
        };
      }),
    [offerSuggestions],
  );

  const fetchOffers = useCallback(
    async (query) => {
      if (!query || query.length < 2 || isLoading) return;

      startLoading();
      const suggestions = await new Promise<OfferResult[]>(
        debounce(async (resolve) => {
          await OffersService.getOffers({
            page: 0,
            limit: 50,
            type: OFFER_TYPE_HOTEL,
            queryString: query,
            region: 'any',
            orderByScheduleDate: 1,
          })
            .then((res) => {
              resolve(res.result);
            })
            .catch((e) => {
              alert(`There was an error while fetching offers [${JSON.stringify(e, null, 2)}]`);
            })
            .finally(() => {
              endLoading();
            });
        }),
      );

      setOfferSuggestions(suggestions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, value],
  );

  const deboucedFetchOffers = useMemo(() => debounce(fetchOffers, 500), [fetchOffers]);

  const getOffers = useCallback(
    (query) => {
      deboucedFetchOffers(query);
    },
    [deboucedFetchOffers],
  );

  return (
    <FormControl error={formErrors?.includes(id)} sx={{ minWidth: 240 }} fullWidth>
      <Autocomplete
        id={id}
        multiple={false}
        // value={defaultOffer}
        freeSolo
        disabled={disabled}
        readOnly={readonly}
        options={offerOptions}
        getOptionLabel={(option: OfferOption) => option.label}
        groupBy={(option) => option.type}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={(o) => o}
        loading={isLoading}
        loadingText="Looking through our offers..."
        onChange={(e, newOption: OfferOption) => {
          onChange(id, newOption?.value ?? '');
          setOfferIdInputValue(newOption?.value ?? '');
        }}
        onInputChange={(e, inputValue) => {
          setOfferSuggestions([]);
          if (inputValue) {
            getOffers(inputValue);
          }
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              required={required}
              label={label}
              placeholder={placeholder || 'Enter a flash offer name/ID/email'}
              fullWidth
            />
            {formErrors?.includes(id) && (
              <FormHelperText id="component-error-text">Please select value from a dropdown</FormHelperText>
            )}
            {loading && <CircularProgress size={12} sx={{ ml: 2, mt: 0.5 }} />}
            <input type="hidden" name={id} value={offerIdInputValue} />
          </>
        )}
      />
    </FormControl>
  );
}

export default LELocationSearchWidget;
