import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import CruiseService from '~/services/cruises/OfferService';

import getDateDayDiff from '~/utils/getDateDayDiff';

import BaseOfferBox from './BaseOfferBox';

interface Props {
  bk: string;
  index: number;
  onRemove: () => void;
}

function CruiseOfferBox({ bk, index, onRemove }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: App.State) => state.tenant);
  const [offer, setOffer] = useState<Awaited<ReturnType<typeof CruiseService.getById>>['result']>();
  useEffect(() => {
    CruiseService.getById(bk)
      .then((res) => {
        setOffer(res.result);
      })
      .catch((err) => {
        if (err.status === 404) {
          onRemove && onRemove();
          return;
        }
        enqueueSnackbar(`Cruise ${bk} could not be found`, { variant: 'error' });
      });
  }, [bk]);

  if (!offer) {
    return <BaseOfferBox tenant={tenant} index={index} />;
  }

  return (
    <BaseOfferBox
      tenant={tenant}
      title={offer.displayName || offer.name}
      imageId={offer.images[0]?.id}
      subtitle={[offer.cruiseLine.name, offer.ship.name].filter(Boolean).join(' / ')}
      path={`/cruises/${bk}`}
      vertical="cruise"
      index={index}
      remainingDays={getDateDayDiff(offer?.promotions[0]?.endDate)}
    />
  );
}

export default CruiseOfferBox;
