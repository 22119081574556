const OFFER_TYPE_RENTAL = 'rental';
const OFFER_TYPE_TOUR = 'tour';
const OFFER_TYPE_TOUR_V2 = 'tour_v2';
const OFFER_TYPE_HOTEL = 'hotel';
const OFFER_TYPE_LAST_MINUTE_HOTEL = 'last_minute_hotel';
const OFFER_TYPE_TACTICAL_AO_HOTEL = 'tactical_ao_hotel';
const OFFER_TYPE_BUNDLE_AND_SAVE = 'bundle_and_save';
const OFFER_TYPE_BED_BANK = 'bedbank_hotel';
const OFFER_TYPE_EXPERIENCE = 'experience';
const OFFER_TYPE_FLIGHT = 'flight';
const OFFER_TYPE_ALL = 'all (except bedbanks and flights)';
const OFFER_TYPE_CRUISE = 'cruise';
const OFFER_TYPE_CRUISE_V2 = 'cruise_v2';
const OFFER_TYPE_CUSTOM_OFFER = 'custom_offer';
const OFFER_TYPE_CAR_HIRE = 'car_hire';
const OFFER_TYPE_TTC_TOUR = 'connection_tour';
const OFFER_TYPE_PARTNER_TOUR = 'partner_tour';
const OFFER_TYPE_DIRECT_TOUR = 'direct_tour';
const OFFER_TYPE_ULTRA_LUX_TOUR = 'ultra_lux_tour';

const HOTEL_OFFER_TYPES = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_RENTAL,
];
const DYNAMIC_PRICED_HOTEL_OFFER_TYPES = [
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_RENTAL,
];

const TOUR_OFFER_TYPES = [OFFER_TYPE_TOUR];

const ADMIN_PURCHASE_SUPPORTED_OFFER_TYPES =
  window.configs.MULTI_OFFER === 'true'
    ? [OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_LAST_MINUTE_HOTEL, OFFER_TYPE_TACTICAL_AO_HOTEL, OFFER_TYPE_RENTAL]
    : [OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR];

const OFFER_FACING_NAMES = {
  [OFFER_TYPE_HOTEL]: 'Limited Time Lux Exclusive',
  [OFFER_TYPE_TOUR]: 'Tour',
  [OFFER_TYPE_LAST_MINUTE_HOTEL]: 'Last Minute Hotel',
  [OFFER_TYPE_TACTICAL_AO_HOTEL]: 'Lux Premium Collection',
  [OFFER_TYPE_BUNDLE_AND_SAVE]: 'Bundle & Save',
  [OFFER_TYPE_EXPERIENCE]: 'Experiences',
  [OFFER_TYPE_FLIGHT]: 'Flights',
  [OFFER_TYPE_BED_BANK]: 'BedBank',
  [OFFER_TYPE_RENTAL]: 'Rental',
  [OFFER_TYPE_TTC_TOUR]: 'TTC Tour',
  [OFFER_TYPE_PARTNER_TOUR]: 'Lux Partner Tour',

  // these have the same names when presented in admin
  [OFFER_TYPE_DIRECT_TOUR]: 'Lux Exclusive Tour',
  [OFFER_TYPE_ULTRA_LUX_TOUR]: 'Lux Exclusive Tour',

  [OFFER_TYPE_CRUISE]: 'Cruise',
  [OFFER_TYPE_CRUISE_V2]: 'CruiseBank',
  [OFFER_TYPE_CUSTOM_OFFER]: 'Custom Offer',
  [OFFER_TYPE_CAR_HIRE]: 'Car Hire',
};

const DEFAULT_ALLOWED_OFFER_TYPES = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_EXPERIENCE,
  OFFER_TYPE_RENTAL,
];

const OFFER_OPTIONS = [...DEFAULT_ALLOWED_OFFER_TYPES, OFFER_TYPE_FLIGHT, OFFER_TYPE_BED_BANK, OFFER_TYPE_ALL];

export {
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_RENTAL,
  OFFER_TYPE_TTC_TOUR,
  OFFER_TYPE_PARTNER_TOUR,
  OFFER_TYPE_DIRECT_TOUR,
  OFFER_TYPE_ULTRA_LUX_TOUR,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_CRUISE_V2,
  HOTEL_OFFER_TYPES,
  DYNAMIC_PRICED_HOTEL_OFFER_TYPES,
  TOUR_OFFER_TYPES,
  ADMIN_PURCHASE_SUPPORTED_OFFER_TYPES,
  OFFER_FACING_NAMES,
  OFFER_TYPE_FLIGHT,
  OFFER_TYPE_EXPERIENCE,
  OFFER_TYPE_ALL,
  DEFAULT_ALLOWED_OFFER_TYPES,
  OFFER_OPTIONS,
  OFFER_TYPE_CUSTOM_OFFER,
  OFFER_TYPE_CAR_HIRE,
};
