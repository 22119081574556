import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, FormControl, TextField } from '@mui/material';

interface Props {
  label: string;
  value: string;
}

export const LuxMomentsPageField = ({ label, value }: Props) => {
  return (
    <FormControl fullWidth variant="standard">
      <label>{label}</label>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <TextField sx={{ marginRight: 2 }} fullWidth disabled variant="outlined" value={value} />
        {value ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
      </Box>
    </FormControl>
  );
};
