import React, { Component } from 'react';

import uniqueId from 'lodash/uniqueId';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { FINANCE_CURRENCIES } from '~/consts/currencies';

import ReservationService from '~/services/ReservationService';
import {
  addDays,
  dateNowUtc,
  datesHaveSameDayMonthYear,
  formatDateISO,
  isAfter,
  isBefore,
  subDays,
} from '~/services/TimeService';

import { convertValueByFX } from '~/utils/convertValueByFX';
import { daysOfWeek } from '~/utils/daysOfWeek';
import { reportError } from '~/utils/reportError';
import roomRateName from '~/utils/roomRateName';

import CustomNumberInput, { CustomInputAdornment } from '../CustomNumberInput';
import ConfirmButton from '../Elements/ConfirmButton';
import DateWidget from '../Elements/DateWidget';
import DropdownButton from '../Elements/DropdownButton';

import { buttonStates } from './states/submitButton';

function newSurchargeDateBlock(vendorCurrencyCode, startDate, endDate, currencyCodes, surchargeType) {
  const surchargeDateBlock = {
    ranges: [
      {
        start_date: formatDateISO(startDate),
        end_date: formatDateISO(endDate),
      },
    ],
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    is_allowed_zero_selling_price: false,
    cost_currency: vendorCurrencyCode,
    cost_amount: 0,
    prices: {},
    surcharge_type: surchargeType,
  };
  currencyCodes.forEach((currencyCode) => {
    surchargeDateBlock.prices[currencyCode] = 0;
  });
  return surchargeDateBlock;
}

const addRanges = (surchargeDateBlock) => {
  surchargeDateBlock.ranges = [
    {
      start_date: surchargeDateBlock.start_date,
      end_date: surchargeDateBlock.end_date,
    },
  ];
  delete surchargeDateBlock.start_date;
  delete surchargeDateBlock.end_date;
  return surchargeDateBlock;
};

const getErrorMessage = (e) => {
  if (e.errors && e.errors.length) {
    return e.errors.map(({ message }) => message).join('\n');
  }

  return 'Something has gone wrong';
};

export default class SurchargeDateForm extends Component {
  constructor(props) {
    super(props);
    this.currencyCodes = Object.keys(props.surchargeSchema.properties.prices.properties);
    this.currencyCodeOptions = this.currencyCodes.map((c) => ({
      value: c,
      label: c,
    }));
    const surchargeDateBlock = props.surchargeDateBlock
      ? // deep clone
        addRanges(JSON.parse(JSON.stringify(props.surchargeDateBlock)))
      : newSurchargeDateBlock(
          props.vendorCurrencyCode,
          props.startDate,
          props.endDate,
          this.currencyCodes,
          props.surchargeType,
        );
    const isEdit = Boolean(props.surchargeDateBlock);
    const selectDays = !(
      surchargeDateBlock.monday &&
      surchargeDateBlock.tuesday &&
      surchargeDateBlock.wednesday &&
      surchargeDateBlock.thursday &&
      surchargeDateBlock.friday &&
      surchargeDateBlock.saturday &&
      surchargeDateBlock.sunday
    );

    this.state = {
      isEdit,
      selectDays,
      surchargeDateBlock,
      saveState: buttonStates.default,
      successMessages: [],
      errorMessages: [],
    };

    this.submitNew = this.submitNew.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.calculateForeignExchangeRates = this.calculateForeignExchangeRates.bind(this);
  }

  getToggleDayFunc(dayKey) {
    const self = this;
    return (e) => {
      e.preventDefault && e.preventDefault();
      self.toggleDay(dayKey);
    };
  }

  toggleDay(dayKey) {
    let newBlock = this.state.surchargeDateBlock;
    newBlock[dayKey] = !newBlock[dayKey];

    this.setState({
      surchargeDateBlock: newBlock,
    });
  }

  setSelectDays(selectDays) {
    let newBlock = selectDays
      ? this.state.surchargeDateBlock
      : Object.assign({}, this.state.surchargeDateBlock, {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        });

    this.setState({
      selectDays,
      surchargeDateBlock: newBlock,
    });
  }

  setIsAllowedZeroSellingPrice(checked) {
    this.setState({
      surchargeDateBlock: {
        ...this.state.surchargeDateBlock,
        is_allowed_zero_selling_price: checked,
      },
    });
  }

  setValue(key, value) {
    let newBlock = this.state.surchargeDateBlock;
    newBlock[key] = value;

    this.setState({
      surchargeDateBlock: newBlock,
    });
  }

  setPrice(currencyCode, amount) {
    let newBlock = this.state.surchargeDateBlock;
    newBlock.prices[currencyCode] = amount;

    this.setState({
      surchargeDateBlock: newBlock,
    });
  }

  setDate(type, index, date) {
    let newBlock = this.state.surchargeDateBlock;
    const currentEndDate = newBlock.ranges[index]['end_date'];
    const currentStartDate = newBlock.ranges[index]['start_date'];
    const isSameStartAndEnd = datesHaveSameDayMonthYear(currentStartDate, currentEndDate);
    newBlock.ranges[index][type] = date;

    const shouldAutoChange =
      type === 'start_date'
        ? isSameStartAndEnd || isAfter(newBlock.ranges[index][type], currentEndDate)
        : isSameStartAndEnd || isBefore(newBlock.ranges[index][type], currentStartDate);

    if (shouldAutoChange) {
      const autoDate = type === 'start_date' ? addDays(1, dateNowUtc(date)) : subDays(1, dateNowUtc(date));
      const autoType = type === 'start_date' ? 'end_date' : 'start_date';
      newBlock.ranges[index][autoType] = autoDate;
    }
    this.setState({
      surchargeDateBlock: newBlock,
    });
  }

  addDateRange = () => {
    let newBlock = this.state.surchargeDateBlock;
    newBlock.ranges.push({
      start_date: formatDateISO(),
      end_date: formatDateISO(),
    });

    this.setState({
      surchargeDateBlock: newBlock,
    });
  };

  removeDateRange = (index) => {
    let newBlock = this.state.surchargeDateBlock;
    newBlock.ranges.splice(index, 1);
    this.setState({ surchargeDateBlock: newBlock });
  };

  calculateForeignExchangeRates() {
    let approved = confirm('This will overwrite any existing pricing. Are you sure? Y/N');
    if (approved) {
      const fxRates = this.props.foreignExchangeRates;
      let newBlock = this.state.surchargeDateBlock;
      const audValue = newBlock.prices['AUD'];
      if (audValue !== undefined) {
        this.currencyCodes.map((currencyCode) => {
          let converted = convertValueByFX({
            fxRates,
            fromCurrency: currencyCode,
            toCurrency: 'AUD',
            value: audValue,
          });
          newBlock.prices[currencyCode] = Math.round(converted);
        });
        this.setState({ surchargeDateBlock: newBlock });
      }
    }
    return false;
  }

  getRoomRatesToUpdate(applyToAllRoomTypes) {
    const { propertyRoomTypes, propertyId, roomType, roomRate } = this.props;

    if (!applyToAllRoomTypes) {
      return [
        {
          propertyId,
          roomType: roomType,
          roomRate: roomRate,
        },
      ];
    }

    const ratePlanId = roomRate.rate_plan.id;

    return propertyRoomTypes.reduce((accumulator, roomType) => {
      roomType.room_rates.forEach((roomRate) => {
        if (roomRate.rate_plan.id === ratePlanId) {
          accumulator.push({
            propertyId,
            roomType: roomType,
            roomRate: roomRate,
          });
        }
      });

      return accumulator;
    }, []);
  }

  isVendorSurcharge = () => {
    return this.props.surchargeType === 'vendor';
  };

  async submitNew(applyToAllRoomTypes) {
    const { onSurchargeDateBlocksAdded, onSuccess } = this.props;

    const cost_amount = this.state.surchargeDateBlock['cost_amount'];

    if (
      cost_amount == 0 &&
      this.isVendorSurcharge() &&
      !confirm('The cost price entered for this surcharge is $0 - are you sure you wish to proceed?')
    ) {
      return;
    }

    this.setState({
      saveState: buttonStates.saving,
    });

    const successes = [];
    const failures = [];
    const addedSurchargeBlocks = {};

    const roomRatesToUpdate = this.getRoomRatesToUpdate(applyToAllRoomTypes);

    for (const update of roomRatesToUpdate) {
      const name = roomRateName(update.roomType, update.roomRate);

      try {
        const response = await ReservationService.createSurchargeDateBlock(
          this.state.surchargeDateBlock,
          update.propertyId,
          update.roomType.id,
          update.roomRate.id,
        );

        successes.push(`Created surcharge for ${name}`);

        addedSurchargeBlocks[update.roomType.id] = response.result;
      } catch (e) {
        const message = getErrorMessage(e);
        const error = `Failed to create surcharge for ${name} ` + message;
        failures.push(error);
        reportError(e);
      }

      if (successes.length > 0) {
        onSurchargeDateBlocksAdded && onSurchargeDateBlocksAdded(addedSurchargeBlocks);
        this.setState({ successMessages: successes });
        if (failures.length === 0) {
          onSuccess && onSuccess();
          this.setState({ saveState: buttonStates.saved });
        }
      }

      if (failures.length > 0) {
        this.setState({
          saveState: buttonStates.failed,
          errorMessages: failures,
        });
      }
    }
  }

  async submitUpdate() {
    const { propertyId, roomType, roomRate, onSurchargeDateBlockUpdated, onSuccess } = this.props;

    const cost_amount = this.state.surchargeDateBlock['cost_amount'];

    if (
      cost_amount == 0 &&
      this.isVendorSurcharge() &&
      !confirm('The cost price entered for this surcharge is $0 - are you sure you wish to proceed?')
    ) {
      return;
    }

    this.setState({
      saveState: buttonStates.saving,
    });

    const name = roomRateName(roomType, roomRate);

    try {
      const response = await ReservationService.updateSurchargeDateBlock(
        this.state.surchargeDateBlock,
        this.state.surchargeDateBlock.id,
        propertyId,
        roomType.id,
        roomRate.id,
      );
      this.setState({
        saveState: buttonStates.saved,
        errors: [],
      });
      onSurchargeDateBlockUpdated && onSurchargeDateBlockUpdated(response.result);
      onSuccess && onSuccess();
    } catch (e) {
      const message = getErrorMessage(e);
      const error = `Failed to update surcharge for ${name} ` + message;
      this.setState({
        saveState: buttonStates.failed,
        errorMessages: [error],
      });
      reportError(e);
    }
  }

  getDateRange(index = 0) {
    return (
      <div key={uniqueId(index)}>
        <Typography>Date range</Typography>

        <Stack direction="row" alignItems="center" spacing={2}>
          <div>
            <DateWidget
              value={this.state.surchargeDateBlock.ranges[index].start_date}
              onChange={(date) => this.setDate('start_date', index, date)}
            />
          </div>

          <div>&#8680;</div>

          <div>
            <DateWidget
              value={this.state.surchargeDateBlock.ranges[index].end_date}
              onChange={(date) => this.setDate('end_date', index, date)}
            />
          </div>

          {!!index && (
            <ConfirmButton
              title="Delete date range"
              confirmTitle="Delete date range"
              confirmQuestion="Are you sure you want to delete this date range?"
              confirmAnswer="Yes, delete"
              onConfirm={() => {
                this.removeDateRange(index);
              }}
              asIcon
            >
              <DeleteIcon />
            </ConfirmButton>
          )}
        </Stack>
      </div>
    );
  }

  render() {
    const { successMessages, errorMessages, saveState, isEdit } = this.state;
    const saving = saveState === buttonStates.saving;
    const cost_currency = this.state.surchargeDateBlock.cost_currency || this.props.vendorCurrencyCode;

    return (
      <Stack direction="column" spacing={2}>
        <div>
          <Stack direction="column" spacing={1}>
            {this.state.surchargeDateBlock.ranges.map((item, index) => this.getDateRange(index))}
          </Stack>

          {!isEdit && (
            <Box mt={1}>
              <Button startIcon={<AddIcon />} variant="outlined" onClick={this.addDateRange}>
                Add
              </Button>
            </Box>
          )}
        </div>

        {this.state.surchargeDateBlock.surcharge_type === 'vendor' && (
          <div>
            <FormControl>
              <FormControlLabel
                key="is_allowed_zero_selling_price"
                control={
                  <Checkbox
                    value="1"
                    checked={this.state.surchargeDateBlock.is_allowed_zero_selling_price}
                    onChange={(e) => this.setIsAllowedZeroSellingPrice(e.target.checked)}
                    sx={{ py: 0 }}
                  />
                }
                label="Allow Zero Selling Price"
              />
            </FormControl>
          </div>
        )}

        <div>
          <FormControl>
            <FormLabel>Days of week</FormLabel>
            <RadioGroup
              row
              value={this.state.selectDays.toString()}
              onChange={(_, value) => this.setSelectDays(value === 'true')}
              name="selectDays"
            >
              <FormControlLabel value="false" control={<Radio />} label="All days in range" />
              <FormControlLabel value="true" control={<Radio />} label="Selected days of week" />
            </RadioGroup>
          </FormControl>

          <Box display={this.state.selectDays ? 'block' : 'none'}>
            <ButtonGroup variant="contained" disableElevation>
              {daysOfWeek.map((day) => (
                <Button
                  variant={this.state.surchargeDateBlock[day.key] ? 'contained' : 'outlined'}
                  key={day.key}
                  onClick={this.getToggleDayFunc(day.key)}
                >
                  {day.shortName}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </div>

        <Stack direction="row" spacing={2}>
          {this.isVendorSurcharge() && (
            <div>
              <Typography color="secondary" fontWeight="medium">
                Cost Price
              </Typography>
              <Stack direction="row" spacing={1} mt={1}>
                <TextField
                  type="number"
                  step="1"
                  min="0"
                  value={this.state.surchargeDateBlock.cost_amount}
                  onChange={(event) => this.setValue('cost_amount', parseInt(event.target.value))}
                />

                <TextField
                  value={cost_currency}
                  onChange={(e) => this.setValue('cost_currency', e.target.value)}
                  select
                >
                  {FINANCE_CURRENCIES.map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </div>
          )}

          <Box flexGrow={1}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography color="secondary" fontWeight="medium">
                Sell Price
              </Typography>

              <Button
                type="submit"
                variant="text"
                size="small"
                onClick={this.calculateForeignExchangeRates}
                title="Calculate sell price for all currencies using AUD as a base"
              >
                Calculate from AUD
              </Button>
            </Stack>
            <Box display="grid" gap={2} gridTemplateColumns="repeat(auto-fit, minmax(150px, 1fr))">
              {this.isVendorSurcharge() &&
                this.currencyCodes.map((currencyCode) => (
                  <TextField
                    key={currencyCode}
                    type="number"
                    step="1"
                    min="0"
                    value={this.state.surchargeDateBlock.prices[currencyCode]}
                    onChange={(event) => this.setPrice(currencyCode, event.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{currencyCode}</InputAdornment>,
                    }}
                  />
                ))}

              {!this.isVendorSurcharge() &&
                this.currencyCodes.map((currencyCode) => (
                  <CustomNumberInput
                    key={currencyCode}
                    type="number"
                    value={this.state.surchargeDateBlock.prices[currencyCode]}
                    onInputChange={(event) => this.setPrice(currencyCode, event.target.value)}
                    startAdornment={<CustomInputAdornment>{currencyCode}</CustomInputAdornment>}
                  />
                ))}
            </Box>
          </Box>
        </Stack>

        <div>
          {successMessages.length > 0 && (
            <Alert severity="success" onClose={() => this.setState({ successMessages: [] })}>
              <ul>
                {successMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </Alert>
          )}

          {errorMessages.length > 0 && (
            <Alert severity="error" onClose={() => this.setState({ errorMessages: [] })}>
              <ul>
                {errorMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </Alert>
          )}

          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Button onClick={this.props.onCancel} variant="text">
              Cancel
            </Button>

            {this.state.surchargeDateBlock.id ? (
              <Button variant="contained" type="submit" onClick={this.submitUpdate} disabled={saving}>
                Update surcharge
              </Button>
            ) : (
              <DropdownButton
                id="save-btn"
                buttonTitle="Apply to this room rate"
                buttonClick={() => this.submitNew(false)}
                disabled={saving}
              >
                <MenuList>
                  <MenuItem onClick={() => this.submitNew(false)}>Apply to this room rate</MenuItem>
                  <MenuItem onClick={() => this.submitNew(true)}>Apply to all room types with room rate</MenuItem>
                </MenuList>
              </DropdownButton>
            )}
          </Stack>
        </div>
      </Stack>
    );
  }
}
