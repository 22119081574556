import React, { ReactElement, useEffect, useState } from 'react';

import classnames from 'clsx';
import currencyFormatter from 'currency-formatter';
import groupBy from 'lodash/groupBy';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { PACKAGE_STATUS_APPROVED } from '~/consts/package';

import OfferLocationHeading from '../../../Common/Booking/OfferLocationHeading';

import { getAccommodationPackages } from './utils';

const PackageOptionsBox = styled.div`
  border: solid 1px;
  border-color: #d6d6d6;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 16px;
`;

const CartPackages = styled.div`
  clear: both;
  display: flex;
  flex-wrap: wrap;
`;

const CartPackage = styled.div`
  position: relative;
  padding: 16px;
  padding-right: 40px;
  border: 1px solid #d6d6d6;
  width: 300px;

  & + & {
    margin-left: 16px;
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  border: 1px solid #039ff5;
  display: inline-block;
  background: none;
  border-radius: 100%;
  color: #039ff5;
  width: 33px;
  font-size: 20px;
  vertical-align: middle;
`;

const DurationSelect = styled.select`
  margin-left: -16px;
  margin-bottom: 16px;
`;

interface GroupedPackagesProps {
  cart: App.Cart;
  currencyCode: string;
  getPackagePrice: (pkg: App.Package) => number;
  offer: App.CartAccommodationOffer;
  offerNumber: number;
  onAddOfferToCart: (pkg: App.Package) => unknown;
  onRemoveFromCart: (packageId: string, offer: App.CartOffer) => unknown;
}

type Dictionary<T> = { [key: string]: T };
type PackageOptionsState = Dictionary<App.Package[]>;

function GroupedPackages({
  cart,
  currencyCode,
  getPackagePrice,
  offer,
  offerNumber,
  onAddOfferToCart,
  onRemoveFromCart,
}: GroupedPackagesProps): ReactElement {
  const [selectedNights, setSelectedNights] = useState<string>(null);
  const [packageOptions, setPackageOptions] = useState<PackageOptionsState>({});
  const [cartPackageOptions, setCartPackageOptions] = useState<string[]>([]);

  useEffect(() => {
    const allPackages = getAccommodationPackages(offer);
    const groupedByNights = groupBy(allPackages, (pkg: App.AccommodationPackage) => pkg.number_of_nights);
    const existingPackageOptions = cart.offers.flatMap((cartOffer) =>
      cartOffer.items.map((cartItem) => cartItem.pkg.unique_key),
    );

    setSelectedNights(Object.keys(groupedByNights)[0]);
    setPackageOptions(groupedByNights);
    setCartPackageOptions([...cartPackageOptions, ...existingPackageOptions]);
  }, []);

  const packagePriceDisplay = (pkg: App.Package) => {
    const packagePrice = getPackagePrice(pkg);
    let displayPackagePrice = ' Unavailable';

    if (packagePrice) {
      displayPackagePrice = currencyFormatter.format(packagePrice, {
        code: currencyCode,
      });
    }

    return (
      <>
        {currencyCode}
        <span>{displayPackagePrice}</span>
      </>
    );
  };

  const addPackageToCart = (pkg: App.Package) => {
    setCartPackageOptions([...cartPackageOptions, pkg.unique_key]);
    onAddOfferToCart(pkg);
  };

  const removeOfferfromCart = (pkg: App.Package, offer: App.CartOffer) => {
    const index = cartPackageOptions.findIndex((option) => option === pkg.unique_key);

    if (index > -1) {
      cartPackageOptions.splice(index, 1);
    }

    setCartPackageOptions([...cartPackageOptions]);
    onRemoveFromCart(pkg.unique_key, offer);
  };

  const getPackageQuantity = (pkg: App.Package) => {
    return cartPackageOptions.filter((option) => option === pkg.unique_key).length;
  };

  const handleDurationSelectChange = (e) => {
    setSelectedNights(e.target.value);
  };

  return (
    <PackageOptionsBox>
      <OfferLocationHeading offerNumber={offerNumber} offer={offer} />
      {packageOptions && (
        <div className="col-xs-2">
          <DurationSelect
            className="form-control capitalize"
            onChange={handleDurationSelectChange}
            value={selectedNights || '0'}
          >
            {Object.keys(packageOptions).map((duration) => (
              <option key={duration} value={duration}>
                {pluralize('night', parseInt(duration), true)}
              </option>
            ))}
          </DurationSelect>
        </div>
      )}
      {cart.offers.map((cartOffer, i) => {
        return (
          cartOffer.items.length > 0 && (
            <CartPackages key={i}>
              {cartOffer.items.map(({ id, pkg }) => {
                return (
                  pkg.offer_id_salesforce_external === offer.id_salesforce_external && (
                    <CartPackage key={id}>
                      <p>{pkg.room_type.name}</p>
                      <p className="capitalize">{pkg.room_rate.rate_plan.name}</p>
                      <p>{pkg.name}</p>
                      <p>{packagePriceDisplay(pkg)}</p>
                      <p className="capitalize">{pluralize('night', pkg.number_of_nights, true)}</p>
                      <RemoveButton
                        onClick={() => {
                          removeOfferfromCart(pkg, offer);
                        }}
                        className="quantity-btn"
                      >
                        &mdash;
                      </RemoveButton>
                    </CartPackage>
                  )
                );
              })}
            </CartPackages>
          )
        );
      })}
      <table className="table table-striped table-hover T-package-table">
        <thead>
          <tr>
            {offer.type === 'hotel' && (
              <>
                <th>Room Type</th>
                <th>Room Rate</th>
              </>
            )}
            <th>Package</th>
            <th>From Price</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {packageOptions &&
            selectedNights &&
            packageOptions[selectedNights.toString()]
              .filter(
                (pkg: App.AccommodationPackage) =>
                  pkg.status === PACKAGE_STATUS_APPROVED &&
                  (pkg.regions.includes(cart.regionCode) || pkg.regions.includes('world')),
              )
              .map((pkg: App.AccommodationPackage) => (
                <tr key={pkg.unique_key} className={classnames('booking-form')}>
                  {offer.type === 'hotel' && (
                    <>
                      <td>{pkg.room_type.name}</td>
                      <td className="capitalize">{pkg.room_rate.rate_plan.name}</td>
                    </>
                  )}
                  <td>{pkg.name}</td>
                  <td>{packagePriceDisplay(pkg)}</td>
                  <td>
                    <input
                      role="textbox"
                      type="number"
                      className="form-control quantity-value data-hj-whitelist"
                      value={getPackageQuantity(pkg)}
                      disabled
                    />
                    <button
                      onClick={() => {
                        removeOfferfromCart(pkg, offer);
                      }}
                      className="quantity-btn"
                      disabled={!cartPackageOptions.includes(pkg.unique_key)}
                    >
                      &mdash;
                    </button>
                    <button
                      onClick={() => {
                        addPackageToCart(pkg);
                      }}
                      className="quantity-btn T-quantity-plus"
                    >
                      +
                    </button>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </PackageOptionsBox>
  );
}

const mapStateToProps = (state: App.State) => ({
  cart: state.cart,
});

export default connect(mapStateToProps, null)(GroupedPackages);
