import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { Alert, Box, Button, Stack } from '@mui/material';

import CorporateAirAgreementItem from '~/components/LeBusinessTraveller/components/CorporateAirAgreementItem';
import FrequentFlyerItem from '~/components/LeBusinessTraveller/components/FrequentFlyerItem';

import {
  createFrequentFlyer,
  getFrequentFlyerList,
  patchFrequentFlyerList,
} from '~/services/BusinessTraveller/BusinessTravellerService';

export interface FrequentFlyerItemType {
  id?: string;
  code: string;
  airlineName: string;
  verified: boolean;
  isCorporateAgreement: boolean;
}

interface Params {
  businessId: string;
  tab: string;
}

export default function FrequentFlyerTab() {
  const [frequentFlyerItems, setFrequentFlyerItems] = useState<FrequentFlyerItemType[]>([]);
  const [corporateAirAgreementItems, setCorporateAirAgreementItems] = useState<FrequentFlyerItemType[]>([]);
  const [fetchFrequentFlyerError, setFetchFrequentFlyerError] = useState(false);
  const [uniquenessError, setUniquenessError] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const { businessId } = useParams<Params>();

  async function fetchFrequentFlyer() {
    try {
      const result = await getFrequentFlyerList(businessId);

      // Separate and set frequentFlyerItems and corporateAirAgreementItems
      const frequentFlyers = result.result.filter((item) => !item.isCorporateAgreement);
      const corporateAgreements = result.result.filter((item) => item.isCorporateAgreement);

      setFrequentFlyerItems(frequentFlyers);
      setCorporateAirAgreementItems(corporateAgreements);
    } catch (e) {
      setFetchFrequentFlyerError(true);
      console.error(e);
    }
  }

  useEffect(() => {
    fetchFrequentFlyer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setHasSaved(false);

    const combinedFrequentFlyerItems = [...frequentFlyerItems, ...corporateAirAgreementItems];

    if (!checkAirlineNamesAreUnique(combinedFrequentFlyerItems)) {
      setUniquenessError(true);
      return;
    } else {
      setUniquenessError(false);
    }

    // this is a security feature to ensure that if we don't check
    // verified, there is no way to accidentally send a code to the airline
    const verifiedFrequentFlyerItems = combinedFrequentFlyerItems.map((item) => {
      if (item.verified === false) {
        item.code = '';
      }
      return item;
    });

    const itemsToCreate = verifiedFrequentFlyerItems.filter((item) => item.id === undefined && item.airlineName);
    itemsToCreate.length > 0 && (await createFrequentFlyer(itemsToCreate, businessId));

    const itemsToPatch = verifiedFrequentFlyerItems.filter((item) => item.id !== undefined);
    itemsToPatch.length > 0 && (await patchFrequentFlyerList(itemsToPatch, businessId));

    setHasSaved(true);
    await fetchFrequentFlyer();
  }

  function addRow() {
    const frequentFlyerCopy = [...frequentFlyerItems];

    const blankRow = {
      code: '',
      airlineName: '',
      verified: false,
      isCorporateAgreement: false,
    };

    frequentFlyerCopy.unshift(blankRow);
    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  function addCorporateAirAgreementRow() {
    const corporateAgreementCopy = [...corporateAirAgreementItems];

    const blankRow = {
      code: '',
      airlineName: '',
      verified: false,
      isCorporateAgreement: true,
    };

    corporateAgreementCopy.unshift(blankRow);
    setCorporateAirAgreementItems(corporateAgreementCopy);
    setHasSaved(false);
  }

  function checkAirlineNamesAreUnique(items) {
    const seenNames = new Set();

    for (let i = 0; i < items.length; i++) {
      const name = items[i].airlineName;
      if (seenNames.has(name)) {
        return false; // Found a duplicate
      }
      seenNames.add(name);
    }
    return true;
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={4} sx={{ wordWrap: 'anywhere' }}>
        {fetchFrequentFlyerError && <Alert severity="error">Error fetching frequent flyer</Alert>}
        {uniquenessError && (
          <Alert severity="error">
            Error: A company may only have <b>1</b> code per airline
          </Alert>
        )}
        <Button onClick={addRow} variant="outlined">
          + Add Frequent Flyer membership
        </Button>
        <Button onClick={addCorporateAirAgreementRow} variant="outlined">
          + Add Corporate Air Agreement
        </Button>
        {corporateAirAgreementItems.map((item, index) => (
          <CorporateAirAgreementItem
            key={index}
            businessId={businessId}
            item={item}
            index={index}
            frequentFlyerItems={corporateAirAgreementItems}
            setFrequentFlyerItems={setCorporateAirAgreementItems}
            setHasSaved={setHasSaved}
          />
        ))}
        {frequentFlyerItems.map((item, index) => (
          <FrequentFlyerItem
            key={index}
            businessId={businessId}
            item={item}
            index={index}
            frequentFlyerItems={frequentFlyerItems}
            setFrequentFlyerItems={setFrequentFlyerItems}
            setHasSaved={setHasSaved}
          />
        ))}
        <Box display="flex" justifyContent="flex-start">
          <Button type="submit" variant={hasSaved ? 'outlined' : 'contained'}>
            {(hasSaved && 'Saved') || 'Save'}
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
