import React, { ComponentProps, useRef } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';

import TENANTS from '~/consts/tenant';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useToggleState from '~/hooks/useToggleState';

const tenantEntries = Object.entries(TENANTS);

interface Props {
  size?: ComponentProps<typeof Button>['size'];
  variant?: ComponentProps<typeof Button>['variant'];
}

function TenantSelect(props: Props) {
  const { size, variant } = props;
  const { tenant: currentTenant, onTenantSelect } = useCurrentTenant();

  const menuTriggerRef = useRef<HTMLButtonElement>(null);
  const { isToggled: isMenuOpen, toggleOn: openMenu, toggleOff: closeMenu } = useToggleState();

  return (
    <>
      <Button
        ref={menuTriggerRef}
        size={size}
        variant={variant}
        color="inherit"
        sx={{
          flexShrink: 0,
          minWidth: 0,
          p: 0,
          '& .MuiButton-endIcon': { ml: 0 },
        }}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={openMenu}
      ></Button>
      <Menu open={isMenuOpen} anchorEl={menuTriggerRef.current} onClose={closeMenu}>
        {tenantEntries.map(([key, tenant]) => (
          <MenuItem
            key={key}
            defaultValue={tenant.value}
            selected={tenant.value === currentTenant.value}
            disabled={tenant.value === currentTenant.value}
            onClick={() => {
              onTenantSelect(tenant.value);
              closeMenu();
            }}
          >
            {tenant.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default TenantSelect;
