import React from 'react';

import { WidgetProps } from '@rjsf/utils';

import { Autocomplete, Checkbox, TextField } from '@mui/material';

function ComboBoxWidget(props: WidgetProps) {
  const { defaultValue, disabled, id, label, multiple, options, placeholder, readonly, required, value, onChange } =
    props;

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      readOnly={readonly}
      options={options.enumOptions}
      getOptionLabel={(option) => option.label ?? option}
      isOptionEqualToValue={(option, value) => option.value === value || option === value}
      onChange={(e, newValue) => {
        onChange(newValue.map((o) => o.value ?? o));
      }}
      openOnFocus
      disableCloseOnSelect
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} fullWidth />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox required={required} checked={selected} />
          {option.label ?? option}
        </li>
      )}
    />
  );
}

export default ComboBoxWidget;
