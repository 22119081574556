import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import { Alert, Container } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getOrderDetail } from '~/services/OrdersService';

import GiftCardDetailPage from './Page';

export default function GiftCardDetailPageContainer() {
  const { id_orders } = useParams<{ id_orders: string }>();

  const [loadingState, setLoadingState] = useState('loading');
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    setLoadingState('loading');

    getOrderDetail(id_orders)
      .then((order) => {
        setOrder(order);
        setLoadingState('success');
      })
      .catch((error) => {
        setError(error);
        setLoadingState('failed');
      });
  }, [id_orders]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Alert severity="error">Error: {error.message}</Alert>
      </Container>
    );
  }

  return <GiftCardDetailPage order={order} />;
}
