export type Action = {
  message?: string;
  type: 'LOAD' | 'SUCCESS' | 'ERROR' | 'RESET';
};

export type State = {
  error: boolean;
  message?: string;
  loading: boolean;
  success: boolean;
};

export const initialValues: State = {
  error: false,
  message: null,
  success: false,
  loading: false,
};

export const createExperiencesReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'LOAD':
      return { error: false, success: false, loading: true };
    case 'RESET':
      return { error: false, success: false, loading: false };
    case 'ERROR':
      return {
        error: true,
        success: false,
        loading: false,
        message: action?.message,
      };
    case 'SUCCESS':
      return {
        error: false,
        success: true,
        loading: false,
        message: action?.message,
      };
    default:
      throw new Error('Error in reducer (create-experiences) action.type');
  }
};
