import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Button, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';

import { SettingsFormData } from '~/components/SupportAssistant/pages/Settings/SettingsPage';

import { setSettings } from '~/services/SupportService';

interface Props {
  settings: SettingsFormData | null;
  onError: (error: Error) => void;
}

export function SettingsForm(props: Props) {
  const { settings, onError } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [saving, setSaving] = useState(false);

  const { control, handleSubmit } = useForm<SettingsFormData>({
    defaultValues: settings,
  });

  const onSubmit = (data: SettingsFormData) => {
    setSaving(true);
    setSettings({
      values: data,
    })
      .then(() => enqueueSnackbar('Settings successfully updated', { variant: 'success' }))
      .catch((error) => onError(error))
      .finally(() => setSaving(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <FormGroup>
          <Controller
            control={control}
            name={`GENESYS.enabled`}
            render={({ field }) => (
              <FormControlLabel control={<Switch {...field} checked={field.value} />} label="Genesys" />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            control={control}
            name={`CHRISTMAS.enabled`}
            render={({ field }) => (
              <FormControlLabel control={<Switch {...field} checked={field.value} />} label="Christmas" />
            )}
          />
        </FormGroup>
      </Stack>
      <Stack direction="row" mt={2} spacing={2}>
        <Button
          disabled={saving}
          type="submit"
          variant="contained"
          startIcon={saving ? <HourglassTopIcon /> : <SettingsApplicationsIcon />}
        >
          Save
        </Button>
      </Stack>
    </form>
  );
}
