import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

const Content = ({ items }) => {
  if (!items || !items.length) {
    return <span>There are no taxes and fees set for this property.</span>;
  }

  return (
    <dl className="dl-horizontal">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <dt>{item.name}</dt>
          <dd>
            {item.value} {item.unit === 'percentage' ? '%' : '(amount)'}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

export default function TaxesAndFeesPane({ vendorId, propertyId, items }) {
  return (
    <>
      <PageSubheader title="Taxes and fees">
        <Button
          variant="text"
          component={Link}
          to={`/vendors/${vendorId}/properties/${propertyId}/edit-property-taxes-fees`}
        >
          Edit
        </Button>
      </PageSubheader>

      <Content items={items} />
    </>
  );
}
