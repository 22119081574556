import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import cruisesAPI from './CruisesApiService';

const resource = 'cruise-lines';
export type CruiseLine = API.CruiseLine;
type CruiseLineResponse = API.Response<API.CruiseLine>;
type CruiseLineOrders = Pick<API.CruiseLine, 'id' | 'order'>;

const listWithPagination = async (paginationParams: API.CruiseLineParams): Promise<API.Response<API.CruiseLine[]>> => {
  return await cruisesAPI.http.get({ resource, paginationParams });
};

const updateCruiseLine = async (cruiseLineId: string, body: API.CruiseLine): Promise<CruiseLineResponse> => {
  const response = await cruisesAPI.http.patch<API.CruiseLine>({
    resource: `${resource}/${cruiseLineId}`,
    body,
  });

  return response;
};

const sortCruiseLines = async (cruiseLines: CruiseLineOrders[]): Promise<CruiseLineResponse> => {
  return cruisesAPI.http.patch({
    resource: `${resource}/sort`,
    body: { vendors: cruiseLines },
  });
};

const getCruiseLineById = async (cruiseLineId: string): Promise<API.CruiseLine> => {
  const response = await cruisesAPI.http.get<API.CruiseLine>({
    resource: `${resource}/${cruiseLineId}`,
  });

  return response.result;
};

export default {
  listWithPagination,
  updateCruiseLine,
  sortCruiseLines,
  getCruiseLineById,
};
