import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { TrendingPlace } from '~/components/Content/TrendingPlaces/Columns';
import UpdateTrendingPlaceForm from '~/components/Content/TrendingPlaces/UpdateTrendingPlaceForm';

type Props = {
  open: boolean;
  onClose: () => void;
  rowToUpdate: TrendingPlace;
};

const UpdateTrendingPlaceModal = ({ open, onClose, rowToUpdate }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Update Trending Place</DialogTitle>
      <DialogContent>
        <UpdateTrendingPlaceForm rowToUpdate={rowToUpdate} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default UpdateTrendingPlaceModal;
