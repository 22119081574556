import React, { ComponentProps, useCallback, useEffect, useState } from 'react';

import { Autocomplete, Checkbox, TextField } from '@mui/material';

import { getCountriesVisitedList } from '~/services/ToursService';
import { ReqInstance } from '~/services/types';

interface Props {
  value: string[];
  onChange: (newValue?: string[]) => void;
}

export default function TourOfferCountryInput(props: Props) {
  const { value, onChange } = props;
  const [countriesReq, setCountriesReq] = useState<ReqInstance<string[]>>({
    status: 'uninitiated',
  });

  const fetchCountries = useCallback(async () => {
    setCountriesReq({ status: 'pending' });
    try {
      const res = await getCountriesVisitedList();
      setCountriesReq({ status: 'succeeded', result: res.result.countries });
    } catch (err) {
      setCountriesReq({ status: 'failed', error: err });
    }
  }, []);

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback<ComponentProps<typeof Autocomplete>['onChange']>(
    (e, value: string[]) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      fullWidth
      multiple
      disabled={countriesReq.status === 'failed'}
      loading={countriesReq.status === 'pending'}
      options={countriesReq.status === 'succeeded' ? countriesReq.result : []}
      openOnFocus
      disableCloseOnSelect
      getOptionLabel={(option: string) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} />
          {option}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Countries" />}
    />
  );
}
