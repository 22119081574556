import React, { useMemo } from 'react';

import { WidgetProps } from '@rjsf/utils';

import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';

import { snakeCaseToStartCase } from '~/utils/stringUtils';

const DenseTableCell = styled(TableCell)({
  padding: 8,
  marginRight: 4,
  minWidth: 50,
});

interface PaymentTemplate {
  id: string;
  name: string;
  description: string;
  payment_schedules: Array<object>;
  minimum_lead_days?: number;
}

const getHeadingLabel = (heading) => {
  switch (heading) {
    case 'due_date_value':
      return 'Due Days';
    case 'due_date_Type':
      return 'From Booking/Supplier';
    case 'payment_type':
    case 'amount_type':
    case 'amount_value':
    default:
      return snakeCaseToStartCase(heading);
  }
};

type TemplateId = string | null;

function PaymentScheduleTemplateDetailsWidget({ value, formContext }: WidgetProps) {
  const selectedTemplateId: TemplateId = formContext?.paymentScheduleTemplateId;
  const scheduleList: Array<PaymentTemplate> = value;
  const template: PaymentTemplate = useMemo(() => {
    return scheduleList?.find((template) => template?.id === selectedTemplateId) || null;
  }, [scheduleList, selectedTemplateId]);
  if (!template) {
    return null;
  }
  return (
    <>
      <TableContainer component={Paper}>
        <TableHead>
          <DenseTableCell>ID</DenseTableCell>
          <DenseTableCell>Name</DenseTableCell>
          <DenseTableCell>Description</DenseTableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <DenseTableCell>{template?.id}</DenseTableCell>
            <DenseTableCell>{template?.name}</DenseTableCell>
            <DenseTableCell>{template?.description}</DenseTableCell>
          </TableRow>
        </TableBody>
      </TableContainer>
      <TableContainer component={Paper}>
        <TableHead>
          <DenseTableCell>Payment Schedule</DenseTableCell>
        </TableHead>
        <TableBody>
          {template?.payment_schedules?.map((charge, key) => (
            <TableRow key={key}>
              <TableContainer>
                <TableHead>
                  {Object.keys(charge).map((heading) => (
                    <DenseTableCell key={heading}> {getHeadingLabel(heading)}</DenseTableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {Object.values(charge).map((value, idx) => (
                    <DenseTableCell key={idx}> {JSON.stringify(value)}</DenseTableCell>
                  ))}
                </TableBody>
              </TableContainer>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </>
  );
}

export default PaymentScheduleTemplateDetailsWidget;
