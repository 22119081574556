import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { queueCreditSummary } from '~/services/ReportingService';
import { addMonths, formatDateISO, startOfMonth } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

function validate(formData, errors) {
  if (typeof formData.start_at === 'undefined') {
    errors.start_at.addError('Please enter start at date');
  }
  if (typeof formData.end_at === 'undefined') {
    errors.end_at.addError('Please enter end at');
  }
  if (typeof formData.currency === 'undefined') {
    errors.end_at.addError('Please select a currency');
  }

  const start_date = new Date(formData.start_at);
  const end_date = new Date(formData.end_at);

  if (start_date >= end_date) {
    errors.start_at.addError('Start date should be before end date');
  }

  return errors;
}

//Get unique currency list
const regions = libRegions.getRegions();
const currencies = ['All', ...new Set(regions.map((regions) => regions.currencyCode).sort())];

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    start_at: {
      title: 'Start at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(-1, startOfMonth())),
    },
    end_at: {
      title: 'End at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(0, startOfMonth())),
    },
    currency: {
      title: 'Currency',
      type: 'string',
      enum: currencies,
    },
  },
};

const formData = {
  currency: 'All',
};

const uiSchema = {
  start: {
    'ui:widget': 'date',
  },
  end: {
    'ui:widget': 'date',
  },
};

export default function ExportCreditSummaryForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    queueCreditSummary(event.formData.start_at, event.formData.end_at, event.formData.currency, 'email')
      .then(() => {
        setSuccessMessage(true);
      })
      .catch(function (e) {
        if (e.name == 'Empty data set') {
          setError("There aren't credits of this currency for this period");
        } else {
          setError('Error: ' + e.message);
        }
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validate}
      formData={formData}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email credit summary report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
