import React, { useEffect, useState } from 'react';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import { Body } from '~/components/Experiences/Home/Content/styles';
import { Field, FlexWrapper, Text } from '~/components/Experiences/components';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB, themes } from '~/consts/experiences';

import { sortBy } from '~/utils/arrayUtils';

const getHumanizedType = (type: Experiences.BookingField['type']) => {
  switch (type) {
    case 'BIG-TEXT':
      return 'Big text';
    case 'TEXT':
      return 'Short text';
    case 'INTEGER':
      return 'Integer number';
    case 'FLOAT':
      return 'Decimal number';
    case 'BOOLEAN':
      return 'Boolean';
    case 'DATE':
      return 'Date';
    case 'PHONE':
      return 'Phone';
    case 'SIMPLE-SELECT':
      return 'Single option';
    case 'MULTI-SELECT':
      return 'Multi options';
    case 'EMAIL':
      return 'Email';
    default:
      return type;
  }
};

const SortableList = SortableContainer(({ items, areOriginalValues, onValueChange, onOptionChange }) => (
  <Body height="500px">
    {items.map((bookingField, index) => (
      <SortableItem
        bookingField={bookingField}
        index={index}
        onValueChange={onValueChange}
        onOptionChange={onOptionChange}
        areOriginalValues={areOriginalValues}
        key={bookingField.id}
      />
    ))}
  </Body>
));

const SortableItem = SortableElement(({ bookingField, onOptionChange, onValueChange, areOriginalValues }) => (
  <Box
    key={`${bookingField.id}-container`}
    border={`1px solid ${themes?.primaryLight}`}
    p="5px"
    mb="10px"
    display={'flex'}
  >
    <FlexWrapper key={bookingField.id} mb="10px" style={{ flexWrap: 'wrap' }}>
      <FlexWrapper style={{ flexDirection: 'row', width: '100%', marginBottom: '20px' }}>
        <FlexWrapper style={{ flexDirection: 'column', width: '20%' }}>
          <Text weight={400} title={bookingField.id} mb="10px">
            ID
          </Text>
          <Text weight={600} title={bookingField.id}>
            {bookingField.id}
          </Text>
        </FlexWrapper>
        <FlexWrapper style={{ flexDirection: 'column', width: '20%' }}>
          <Text weight={400} title={bookingField.id} mb="10px">
            Type
          </Text>
          <Text weight={600} title={bookingField.id}>
            {getHumanizedType(bookingField.type)}
          </Text>
        </FlexWrapper>
        <FlexWrapper style={{ flexDirection: 'column', width: '20%' }}>
          <Text weight={400} title={bookingField.id} mb="10px">
            Required on provider
          </Text>
          <Text weight={600} title={bookingField.id}>
            {bookingField.required ? 'Yes' : 'No'}
          </Text>
        </FlexWrapper>
        <FlexWrapper style={{ flexDirection: 'column', width: '20%' }}>
          <Text weight={400} title={bookingField.id} mb="10px">
            Sort
          </Text>
          <Text weight={600} title={bookingField.id}>
            #{bookingField.sort}
          </Text>
        </FlexWrapper>
      </FlexWrapper>

      <FlexWrapper style={{ marginBottom: '20px' }}>
        <FlexWrapper style={{ width: '30%' }} mr="10px">
          <Field
            style={{ width: '100%' }}
            name="label"
            onChange={({ value }) => onValueChange(bookingField.id, { label: value })}
            labelName="Label"
            value={bookingField.label}
            disabled={areOriginalValues}
          />
        </FlexWrapper>
        <FlexWrapper style={{ width: '30%' }} mr="10px">
          <Field
            style={{ width: '100%' }}
            name="placeholder"
            onChange={({ value }) => onValueChange(bookingField.id, { placeholder: value })}
            labelName="Placeholder"
            value={bookingField.placeholder}
            disabled={areOriginalValues}
          />
        </FlexWrapper>
        <FlexWrapper style={{ width: '30%', flexDirection: 'row' }} mr="10px">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={bookingField.showOnCheckout}
                  disabled={areOriginalValues}
                  onChange={(event) => onValueChange(bookingField.id, { showOnCheckout: event.target.checked })}
                />
              }
              label="Show on checkout"
            />
          </FormGroup>
        </FlexWrapper>
      </FlexWrapper>

      {!!bookingField.options && (
        <FlexWrapper style={{ flexDirection: 'column', alignItems: 'start' }}>
          <FlexWrapper style={{ width: '100%' }}>
            <Text weight={400} title={bookingField.id} mb="10px">
              Options
            </Text>
          </FlexWrapper>
          {bookingField.options.map((option, optionIndex) => (
            <FlexWrapper style={{ width: '20%' }} key={optionIndex}>
              <Field
                style={{ width: '100%' }}
                name="placeholder"
                onChange={({ value }) => onOptionChange(bookingField.id, optionIndex, { label: value })}
                labelName={option.value}
                value={option.label}
                disabled={areOriginalValues}
              />
            </FlexWrapper>
          ))}
        </FlexWrapper>
      )}
    </FlexWrapper>

    <Box>
      <MenuIcon color="primary" />
    </Box>
  </Box>
));

const ExperienceCurationBookingFields: React.FC = () => {
  const { contentTab, updateValues, values } = useUpdateExperienceValues();
  const [sortedItems, setSortedItems] = useState([]);

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  const handleValue = (id: string, data: Partial<Experiences.BookingField>) => {
    setSortedItems((sortedItems) => {
      const index = sortedItems.findIndex((item) => item.id === id);

      sortedItems[index] = {
        ...sortedItems[index],
        ...data,
      };
      updateValues({
        bookingFields: sortedItems,
      });

      return sortedItems;
    });
  };

  const handleOptions = (id: string, optionIndex, data: Partial<Experiences.BookingField['options'][number]>) => {
    setSortedItems((sortedItems) => {
      const index = sortedItems.findIndex((item) => item.id === id);

      sortedItems[index].options[optionIndex] = {
        ...sortedItems[index].options[optionIndex],
        ...data,
      };

      updateValues({
        bookingFields: sortedItems,
      });

      return sortedItems;
    });
  };

  const handleSort = ({ oldIndex, newIndex }) => {
    const newBookingsFields = arrayMove(sortedItems, oldIndex, newIndex).map((item, index) => ({
      ...item,
      sort: index + 1,
    }));

    setSortedItems(newBookingsFields);
    updateValues({ bookingFields: newBookingsFields });
  };

  useEffect(() => {
    if (values && !sortedItems.length) {
      const sorted = sortBy(values.bookingFields, (item) => item.sort, 'asc');
      setSortedItems(sorted);
    }
  }, [sortedItems.length, values]);

  return (
    <Accordion variant="outlined" disabled={!sortedItems.length}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Booking field</AccordionSummary>
      <AccordionDetails>
        <SortableList
          items={sortedItems}
          areOriginalValues={areOriginalValues}
          onOptionChange={handleOptions}
          onValueChange={handleValue}
          onSortEnd={handleSort}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ExperienceCurationBookingFields;
