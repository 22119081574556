import React, { useCallback, useMemo, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

// import dayjs from '~/timeInit';
import { Box, Button, TextField, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import { CheapestPrice, getCalendarSearchAdmin } from '~/services/FlightsService';
import { formatDateISO, getDayJs, isAfter, isBefore } from '~/services/TimeService';

import DateTimeWidget from '../Common/Elements/DateTimeWidget';
import GridPagination from '../Common/Elements/GridPagination';
import QuickSearchToolbar from '../Common/Elements/QuickSearchToolbar';

interface InputFieldsData {
  startDate: string;
  endDate: string;
  origin: string;
  destination: string;
  region: string;
  numberOfNights: string;
  flexibilityInDays: string;
  adults: string;
  children: string;
  infants: string;
}

export default function FlightCacheToolPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<CheapestPrice> | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [inputData, setInputData] = useState<InputFieldsData>({
    startDate: '',
    endDate: '',
    origin: 'SYD',
    destination: 'MEL',
    region: 'AU',
    numberOfNights: '1',
    flexibilityInDays: '5',
    adults: '1',
    children: '0',
    infants: '0',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      [e.target.id]: e.target.value,
    });
  };

  const handleDateChange = (value: string, id: string) => {
    setInputData({
      ...inputData,
      [id]: formatDateISO(value),
    });
  };

  const fetchTableData = useCallback(async () => {
    setLoading(true);
    try {
      setError(undefined);
      const data = await getCalendarSearchAdmin({
        startDate: inputData.startDate,
        endDate: inputData.endDate,
        origin: inputData.origin,
        destination: inputData.destination,
        numberOfNights: Number(inputData.numberOfNights),
        flexibilityInDays: Number(inputData.flexibilityInDays),
        numberOfAdults: Number(inputData.adults),
        numberOfChildren: Number(inputData.children),
        numberOfInfants: Number(inputData.infants),
        currency: 'AUD',
        region: inputData.region,
      });

      const formatedData = data.result.map((item) => {
        for (const [key, values] of Object.entries(item)) {
          if (values === null) {
            item[key] = '-';
          }
        }
        return item;
      });

      setData(formatedData);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  }, [inputData]);

  const isNumeric = (value: string) => Number.isFinite(+value);
  const checkFieldRequired = (value: string) => (value.trim().length === 0 ? 'This field is required' : undefined);
  const isStartDateInFuture = useCallback(
    (value: string) => (isAfter(value, inputData.endDate) ? 'Start date cannot be in the future' : undefined),
    [inputData.endDate],
  );
  const isEndDateInPast = useCallback(
    (value: string) => (isBefore(value, inputData.startDate) ? 'End date cannot be in the past' : undefined),
    [inputData.startDate],
  );
  const isStringOnly = useCallback((value: string) => {
    return isNumeric(value) ? 'Only string is allowed' : undefined;
  }, []);
  const isNumberOnly = useCallback((value: string) => (!isNumeric(value) ? 'Only number is allowed' : undefined), []);
  const isPositiveNumber = useCallback(
    (value: string) => (isNumeric(value) && Number(value) < 0 ? 'Negative number is not allowed' : undefined),
    [],
  );

  const fieldValidators = useMemo(() => {
    return {
      startDate: [checkFieldRequired, isStartDateInFuture],
      endDate: [checkFieldRequired, isEndDateInPast],
      origin: [checkFieldRequired, isStringOnly],
      destination: [checkFieldRequired, isStringOnly],
      region: [checkFieldRequired, isStringOnly],
      numberOfNights: [checkFieldRequired, isNumberOnly, isPositiveNumber],
      flexibilityInDays: [isNumberOnly, isPositiveNumber],
      adults: [checkFieldRequired, isNumberOnly, isPositiveNumber],
      children: [isNumberOnly, isPositiveNumber],
      infants: [isNumberOnly, isPositiveNumber],
    };
  }, [isStartDateInFuture, isEndDateInPast, isStringOnly, isNumberOnly, isPositiveNumber]);

  const inputErrors = useMemo(() => {
    const errors: InputFieldsData = {
      startDate: '',
      endDate: '',
      origin: '',
      destination: '',
      region: '',
      numberOfNights: '',
      flexibilityInDays: '',
      adults: '',
      children: '',
      infants: '',
    };
    for (const [key, value] of Object.entries(inputData)) {
      for (const validation of fieldValidators[key]) {
        const error = validation(value);
        if (error) {
          errors[key] = error;
          break;
        } else if (error === undefined) {
          errors[key] = '';
        }
      }
    }
    return errors;
  }, [fieldValidators, inputData]);

  const isSearchEnabled = useCallback(() => {
    const errors = Object.values(inputErrors);
    return !errors.every((error) => error === '');
  }, [inputErrors]);

  const tableColumns: Array<GridColDef> = [
    { field: 'provider', headerName: 'Provider', width: 150 },
    { field: 'departureDate', headerName: 'Departure Date', width: 150 },
    { field: 'returnDate', headerName: 'Return Date', width: 150 },
    { field: 'totalPrice', headerName: 'Total Price', width: 150 },
    { field: 'departurePrice', headerName: 'Departure Price', width: 150 },
    { field: 'returnPrice', headerName: 'Return Date', width: 150 },
    { field: 'fees', headerName: 'Fees', width: 150 },
    { field: 'markup', headerName: 'Markup', width: 150 },
  ];

  const inputFields: Array<{ id: string; label: string; type: string; required?: boolean; minDate?: Dayjs }> = [
    {
      id: 'startDate',
      label: 'Start Date',
      type: 'date',
      minDate: dayjs().startOf('day'),
      required: true,
    },
    {
      id: 'endDate',
      label: 'End Date',
      type: 'date',
      minDate: getDayJs(inputData.startDate),
      required: true,
    },
    {
      id: 'origin',
      label: 'Origin',
      type: 'text',
      required: true,
    },
    {
      id: 'destination',
      label: 'Destination',
      type: 'text',
      required: true,
    },
    {
      id: 'region',
      label: 'Region',
      type: 'text',
      required: true,
    },
    {
      id: 'numberOfNights',
      label: 'Number of Nights',
      type: 'text',
      required: true,
    },
    {
      id: 'flexibilityInDays',
      label: 'Flexibility in Days',
      type: 'text',
    },
    {
      id: 'adults',
      label: 'Adults',
      type: 'text',
      required: true,
    },
    {
      id: 'children',
      label: 'Children',
      type: 'text',
    },
    {
      id: 'infants',
      label: 'Infants',
      type: 'text',
    },
  ];

  return (
    <Box flexDirection="column" display="flex" gap={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
        columnGap="16px"
        rowGap="24px"
        sx={{ '& .MuiFormHelperText-root.Mui-error': { position: 'absolute', top: '100%' } }}
      >
        {inputFields.map((field) => {
          switch (field.type) {
            case 'date':
              return (
                <Box>
                  <DateTimeWidget
                    key={field.id}
                    showDateOnly
                    value={inputData[field.id]}
                    minDate={field.minDate ? field.minDate : undefined}
                    onChange={(value: string) => handleDateChange(value, field.id)}
                    format="YYYY/MM/DD"
                    label={field.label}
                  />
                </Box>
              );
            case 'text':
              return (
                <TextField
                  required={field.required}
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  variant="outlined"
                  size="small"
                  error={!!inputErrors[field.id]}
                  helperText={inputErrors[field.id]}
                  value={inputData[field.id]}
                  onChange={handleChange}
                />
              );
          }
        })}
        <Button disabled={isSearchEnabled()} variant="contained" onClick={() => fetchTableData()}>
          Search
        </Button>
      </Box>

      {error && <Typography variant="h6">Failed to fetch table data: {error}</Typography>}

      {!error && data?.length === 0 && <Typography variant="h6">No data was found</Typography>}

      {!error && (
        <Box height="800px">
          <DataGridPro
            columns={tableColumns}
            loading={loading}
            pagination
            paginationMode="client"
            getRowId={(row) =>
              `${row.provider}-${row.departureDate}-${row.returnDate}-${row.totalPrice}-${row.departurePrice}-${row.returnPrice}-${row.fees}`
            }
            slots={{
              pagination: GridPagination,
              toolbar: QuickSearchToolbar,
            }}
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            disableRowSelectionOnClick
            disableColumnMenu
            rows={data ? data : []}
            density="compact"
          />
        </Box>
      )}
    </Box>
  );
}
