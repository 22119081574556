import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { gdprBulkRemoval, getGdprBulkRemovalJobStatus } from '~/services/OrdersService';

const AccountDeletionsPage: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const timerIdRef = useRef(null);

  const showConfirmDialog = useConfirmDialog();

  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [currentJobId, setCurrentJobId] = useState<number>(null);
  const [currentJobProgress, setCurrentJobProgress] = useState(0);
  const [deletionErrors, setDeletionsErrors] = useState<Record<string, string>>(null);
  const [deletionStatus, setDeletionStatus] = useState<Utils.ProcessingState>('idle');

  const onDeleteAccountsClick = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Delete accounts?',
      description:
        'This will remove all identifying information from the customer’s account and from our databases. This is a permanent action, and should only be done with written request from the customer. Are you sure you want to proceed?',
    });

    if (!confirmed) {
      return;
    }

    setDeletionStatus('processing');

    gdprBulkRemoval({ userEmails, brand })
      .then(({ jobId }) => {
        setCurrentJobId(jobId);
        setDeletionStatus('success');
        enqueueSnackbar('Accounts added to deletion queue', { variant: 'info' });
      })
      .catch(() => {
        setDeletionStatus('failed');
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      });
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }

      const { result } = evt.target;
      if (typeof result !== 'string') {
        return;
      }

      const { data: emailsData } = Papa.parse(result, { skipEmptyLines: true });

      // removes headers
      emailsData.shift();

      // extract first column from each row
      const emails = emailsData.map((row) => row[0]);

      setUserEmails(emails);
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    const pollingCallback = () =>
      getGdprBulkRemovalJobStatus(currentJobId)
        .then((result) => {
          if (result.job_state === 'completed') {
            setDeletionsErrors(result.errors);
            clearInterval(timerIdRef.current);
            setCurrentJobId(null);
            setCurrentJobProgress(0);

            enqueueSnackbar('Accounts deletion job completed', { variant: 'success' });
          }

          setCurrentJobProgress(result.progress);
        })
        .catch(() => {
          clearInterval(timerIdRef.current);
          setCurrentJobId(null);
          setCurrentJobProgress(0);
        });

    const startPolling = () => {
      // Polling every 3 seconds
      timerIdRef.current = setInterval(pollingCallback, 3000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (currentJobId && currentJobProgress < 100) {
      startPolling();
    } else {
      stopPolling();
    }

    return stopPolling;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJobId]);

  return (
    <>
      <Helmet>
        <title>Account deletions</title>
      </Helmet>
      <Container maxWidth="xl">
        <PageHeader title="Account deletions" />
        <Box mb={2}>
          Upload csv file with emails of the users that you need to delete for GDPR reasons. Make sure emails are in the
          first column and start from second row.
        </Box>
        <TextField
          inputProps={{ accept: '.csv' }}
          type="file"
          fullWidth
          onChange={handleFileUpload}
          helperText={userEmails.length ? `Selected ${userEmails.length} accounts` : null}
        />
        <Button
          variant="outlined"
          color="error"
          disabled={userEmails?.length < 1 || deletionStatus === 'processing'}
          onClick={onDeleteAccountsClick}
          sx={{ mt: 2, mb: 3 }}
        >
          Delete accounts
        </Button>
        <Grid container spacing={2}>
          {currentJobId && (
            <Grid md={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={currentJobProgress} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="h6" component="div" color="text.secondary">{`${currentJobProgress}%`}</Typography>
              </Box>
            </Grid>
          )}
          <Grid item md={6}>
            <Typography variant="h6">Accounts to delete:</Typography>
            <List dense>
              {userEmails.map((email) => (
                <ListItem key={email}>
                  <ListItemText primary={email} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={6}>
            {deletionErrors && (
              <>
                <Typography variant="h6">Errors:</Typography>
                <List dense>
                  {Object.keys(deletionErrors).map((email) => (
                    <ListItem key={email}>
                      <ListItemText
                        sx={{ color: 'red' }}
                        primary={email}
                        secondary={deletionErrors[email]}
                        secondaryTypographyProps={{ color: ' red' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AccountDeletionsPage;
