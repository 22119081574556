const tenants: Record<string, App.Tenant> = {
  lux: {
    title: 'Luxury Escapes',
    value: 'lux',
    brand: 'luxuryescapes',
  },
  scoopon: {
    title: 'Scoopon',
    value: 'scoopon',
    brand: 'scoopontravel',
  },
  cudo: {
    title: 'Cudo',
    value: 'cudo',
    brand: 'cudotravel',
  },
  deals: {
    title: 'Deals',
    value: 'deals',
    brand: 'dealstravel',
  },
  treatme: {
    title: 'Treat Me',
    value: 'treatme',
    brand: 'treatmetravel',
  },
  kogantravel: {
    title: 'Kogan Travel',
    value: 'kogantravel',
    brand: 'kogantravel',
  },
  lebusinesstraveller: {
    title: 'LE Business Traveller',
    value: 'lebusinesstraveller',
    brand: 'lebusinesstraveller',
  },
  leagenthub: {
    title: 'LE Agent Hub',
    value: 'leagenthub',
    brand: 'leagenthub',
  },
};

if (window.configs.ZOOMZOOM_ENABLED === 'true') {
  tenants.zoomzoom = {
    title: 'Zoom Zoom',
    value: 'zoomzoom',
    brand: 'zoomzoom',
  };
}

export default tenants;
