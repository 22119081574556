import { GridColDef } from '@mui/x-data-grid';

import {
  actionsFormatter,
  customerDataFormatter,
  internalInformationFormatter,
  orderInformationFormatter,
  packageInformationFormatter,
} from '../formatters';

export const columns: GridColDef[] = [
  {
    field: 'fk_customer_id',
    headerName: 'Customer',
    sortable: false,
    width: 250,
    renderCell: customerDataFormatter,
    display: 'flex',
  },
  {
    field: 'order_information',
    headerName: 'Order information',
    sortable: false,
    flex: 3,
    renderCell: orderInformationFormatter,
    display: 'flex',
  },
  {
    field: 'package_information',
    headerName: 'Package Information',
    sortable: false,
    flex: 2,
    renderCell: packageInformationFormatter,
    display: 'flex',
  },
  {
    field: 'internal_information',
    headerName: 'Internal Information',
    sortable: false,
    flex: 3,
    renderCell: internalInformationFormatter,
    display: 'flex',
  },
  { field: 'actions', headerName: 'Details', sortable: false, renderCell: actionsFormatter, display: 'flex' },
];
