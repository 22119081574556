import React, { useEffect, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getDetails, getTravellerDetailRequirements } from '~/services/TravellerService';

import TourTraveller from './TourTraveller';

const TourTravellersContainer = ({ orderId, productType }) => {
  const [travellers, setTravellers] = useState([]);
  const [travelRequirement, setTravelRequirement] = useState({
    flightsRequired: true,
    weightRequired: false,
  });
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = (_, isExpanded: boolean) => {
    setIsOpen(isExpanded);
  };

  useEffect(() => {
    (async function fetchTravellerData() {
      if (isOpen) {
        try {
          const travellersResponse = await getTravellers(orderId);

          const offerId = travellersResponse?.[0]?.purchased_item?.offer_id;
          const travellerRequirement = await getTravellerRequirements(offerId);
          if (travellerRequirement) {
            setTravelRequirement(travellerRequirement);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);
          setError(`Couldn't fetch traveller details`);
        }
      }
    })();
  }, [isOpen, orderId]);

  const getTravellers = async (orderId: string) => {
    const travellersResponse = await getDetails(orderId, false, false);
    setTravellers(travellersResponse ?? []);
    return travellersResponse;
  };

  const sortTourTravellers = (travellers: any[], sortProperties: string[]) => {
    if (sortProperties.length === 0) {
      return travellers;
    }

    const sortCriteria = new Map(
      sortProperties.map((property: string, index: number) => [sortProperties[index - 1], property]),
    );

    function getTourTravellerSortComparator(propertyName: string) {
      return function (travellerOne: any, travellerTwo: any) {
        let nextProperty;

        if (travellerOne[propertyName] < travellerTwo[propertyName]) {
          return -1;
        } else if (travellerOne[propertyName] > travellerTwo[propertyName]) {
          return 1;
        } else if ((nextProperty = sortCriteria.get(propertyName))) {
          return getTourTravellerSortComparator(nextProperty)(travellerOne, travellerTwo);
        }
      };
    }

    return travellers.sort(getTourTravellerSortComparator(sortProperties[0]));
  };

  const getTravellerRequirements = async (offerId: string | undefined | null) => {
    if (!offerId) {
      return undefined;
    }
    const travellerRequirementsResponse = await getTravellerDetailRequirements(offerId);
    if (travellerRequirementsResponse && travellerRequirementsResponse.status === 200) {
      const { flight_details_required: flightsRequired, weight_required: weightRequired } =
        travellerRequirementsResponse.result;
      return { flightsRequired: flightsRequired as boolean, weightRequired: weightRequired as boolean };
    }
    return undefined;
  };

  return (
    <Accordion expanded={isOpen} onChange={handleClick} variant="outlined">
      <AccordionSummary>Travellers</AccordionSummary>

      <AccordionDetails>
        {loading && <Spinner size={36} />}

        {error && <ErrorDisplay message={error} />}

        {!loading &&
          !error &&
          sortTourTravellers(travellers, ['room_number', 'room_type', 'first_name', 'last_name']).map(
            (traveller, index) => (
              <TourTraveller
                key={index}
                traveller={traveller}
                flightsRequired={travelRequirement.flightsRequired}
                weightRequired={travelRequirement.weightRequired}
                setTravellers={getTravellers}
                productType={productType}
              />
            ),
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TourTravellersContainer;
