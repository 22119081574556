import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import { ORDER_STATUS_COMPLETED } from '~/consts/order';

import { getSuccessfulItemsTotal } from '~/utils/order';

const orderTotalFormatter = (params: GridRenderCellParams<Order.Order>) => {
  const row = params.row;

  //credits do not exist at the line item level, they cannot be broken down
  const credit = row?.business_credit_items?.length > 0 ? row.business_credit_items[0].total : 0;
  const total = row.status !== ORDER_STATUS_COMPLETED ? row.total : getSuccessfulItemsTotal(row);

  return (
    <Stack direction="column">
      <Typography>{(total + credit).toFixed(2)}</Typography>
    </Stack>
  );
};

export default orderTotalFormatter;
