import React, { useCallback } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';
import columns from '~/components/Content/OfferBlocklist/columns';

interface Props {
  data: any;
  isLoading: boolean;
  page: number;
  sizePerPage: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  selected: GridRowSelectionModel;
  handleSelect: (selectedIds: GridRowSelectionModel) => void;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const BlockedOffersGrid = ({
  data,
  isLoading,
  rowCount,
  onPageChange,
  handleSelect,
  selected,
  handleDelete,
  page,
  sizePerPage,
}: Props) => {
  const handleBulkDelete = useCallback(
    (event) => {
      event.preventDefault();
      handleDelete(event);
    },
    [handleDelete],
  );

  return (
    <Box mt={2}>
      <Grid item xs={4}>
        <Button disabled={selected.length === 0} onClick={handleBulkDelete}>
          Remove Selected offers
        </Button>
      </Grid>

      <DataGrid
        columns={columns}
        checkboxSelection
        className="T-offers-table"
        rows={data || []}
        pagination
        paginationMode="server"
        paginationModel={{ page: page - 1, pageSize: sizePerPage }}
        slots={{ pagination: GridPagination }}
        pageSizeOptions={[20]}
        onPaginationModelChange={({ page }) => onPageChange(page + 1)}
        loading={isLoading}
        getRowId={(row: { offerId: string; view: string; added: string }) => row.offerId}
        getRowHeight={() => 'auto'}
        onRowSelectionModelChange={handleSelect}
        rowSelectionModel={selected}
        sx={{ '.MuiDataGrid-cell': { alignItems: 'start' } }}
        rowCount={rowCount}
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight
      />
    </Box>
  );
};

export default BlockedOffersGrid;
