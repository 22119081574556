import React, { useState } from 'react';

import { Button } from '@mui/material';

interface TemplateParamsProps {
  hotelDetails?: string;
  flightDescription?: string;
  whoIsProtected?: string;
  numberOfProtectedMembers?: number;
  flightPrice?: number;
  totalCost?: number;
  hotelPrice?: number;
}

const AtolEdit = ({
  templateParams,
  editCertificate,
}: {
  templateParams: TemplateParamsProps;
  editCertificate: (params: TemplateParamsProps) => void;
}) => {
  const [flightDescription, setFlightDescription] = useState(templateParams.flightDescription || '');
  const [hotelDetails, setHotelDetails] = useState(templateParams.hotelDetails || '');
  const [flightPrice, setFlightPrice] = useState(templateParams.flightPrice || 0);
  const [hotelPrice, setHotelPrice] = useState(templateParams.hotelPrice || 0);
  const [totalCost, setTotalCost] = useState(templateParams.totalCost || 0);
  const [whoIsProtected, setWhoIsProtected] = useState(templateParams.whoIsProtected || '');
  const [numberOfProtectedMembers, setNumberOfProtectedMembers] = useState(
    templateParams.numberOfProtectedMembers || 0,
  );

  const submitChanges = () => {
    editCertificate({
      flightDescription,
      hotelDetails,
      flightPrice: flightPrice || 0,
      hotelPrice: hotelPrice || 0,
      totalCost: totalCost || 0,
      whoIsProtected,
      numberOfProtectedMembers: numberOfProtectedMembers || 0,
    });
  };

  return (
    <div>
      <div>Edit Details</div>
      <div>
        <div>
          <div>
            <div>
              <label>Flight Description</label>
              <input
                onChange={(e) => setFlightDescription((e.target as HTMLInputElement).value)}
                value={flightDescription}
                key={'flightDescription'}
              />
            </div>
          </div>

          <div>
            <div>
              <label>Hotel Details</label>
              <input
                onChange={(e) => setHotelDetails((e.target as HTMLInputElement).value)}
                value={hotelDetails}
                key={'hotelDetails'}
              />
            </div>
          </div>
          <div>
            <div>
              <label>Flight Price</label>
              <input
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setFlightPrice(parseFloat(value));
                }}
                type={'number'}
                step={'0.01'}
                value={flightPrice}
                key={'flightPrice'}
              />
            </div>
          </div>
          <div>
            <div>
              <label>Hotel Price</label>
              <input
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setHotelPrice(parseFloat(value));
                }}
                type={'number'}
                step={'0.01'}
                value={hotelPrice}
                key={'hotelPrice'}
              />
            </div>
          </div>
          <div>
            <div>
              <label>Total Cost</label>
              <input
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setTotalCost(parseFloat(value));
                }}
                type={'number'}
                step={'0.01'}
                value={totalCost}
                key={'totalCost'}
              />
            </div>
          </div>
          <div>
            <div>
              <label>Who Is Protected?</label>
              <input
                onChange={(e) => setWhoIsProtected((e.target as HTMLInputElement).value)}
                value={whoIsProtected}
                key={'whoIsProtected'}
              />
            </div>
          </div>

          <div>
            <div>
              <label>Number of Protected Members</label>
              <input
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setNumberOfProtectedMembers(parseFloat(value));
                }}
                type={'number'}
                step={'1.00'}
                value={numberOfProtectedMembers}
                key={'numberOfProtectedMembers'}
              />
            </div>
          </div>
        </div>

        <div>
          <Button variant="contained" onClick={submitChanges}>
            Edit & Resend
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AtolEdit;
