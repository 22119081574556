import React, { Fragment } from 'react';

import { LIST_VISIBILITY } from '../../../../consts/schedule';
import OffersService from '../../../../services/OffersService';
import { reportError } from '../../../../utils/reportError';
import { buttonStates } from '../../../Common/Forms/states/submitButton';

import LuxuryEscapesScheduleContainer from './LuxuryEscapesScheduleContainer';
import NewAddScheduleFrontPage from './NewAddScheduleFrontPage';

export default class NewScheduleFrontPage extends React.Component {
  state = {
    isUpdated: false,
    isFetched: false,
    schedules: [],
  };

  componentDidMount() {
    this.fetchData(this.props.offerId);
  }

  onFrontPageClick = () => {
    this.setState({
      isFrontPageOpen: !this.state.isFrontPageOpen,
      clearRegions: true,
    });
  };

  onFrontPageClose = () => {
    this.setState({
      isFrontPageOpen: false,
    });
  };

  onSaveFinished = () => {
    this.setState(
      {
        isUpdated: !this.state.isUpdated,
      },
      () => {
        this.fetchData(this.props.offerId);
      },
    );
  };

  onDelete = (idList) => {
    if (this.props.shouldMigrateSchedule) {
      return;
    }
    const payload = {
      idList,
    };
    OffersService.deleteScheduleList(this.props.offerId, payload)
      .then(() => {
        this.setState({
          schedule: null,
          saveState: buttonStates.default,
        });
        this.onSaveFinished();
      })
      .catch((e) => {
        this.setState({
          saveState: buttonStates.failed,
        });
        this.onError(e);
        reportError(e);
      });
  };

  fetchData(offerId) {
    OffersService.getOffer(offerId)
      .then((response) => {
        this.setState({
          schedules: response.result.brand_schedules,
          isFetched: true,
        });
      })
      .catch((e) => {
        this.setState({
          saveState: buttonStates.failed,
        });
        this.onError(e);
        reportError(e);
      });
  }

  render() {
    const { offerId, shouldMigrateSchedule } = this.props;
    const { schedules, isFetched } = this.state;
    return (
      <div className="fp-schedule-container">
        <h3>Luxury Escapes</h3>
        {isFetched && (
          <Fragment>
            <LuxuryEscapesScheduleContainer
              schedules={schedules}
              type={LIST_VISIBILITY}
              onDelete={this.onDelete}
              onSaveFinished={this.onSaveFinished}
              shouldMigrateSchedule={shouldMigrateSchedule}
            />
            <NewAddScheduleFrontPage
              offerId={offerId}
              type={LIST_VISIBILITY}
              onSaveFinished={this.onSaveFinished}
              shouldMigrateSchedule={shouldMigrateSchedule}
            />
          </Fragment>
        )}
      </div>
    );
  }
}
