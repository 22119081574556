import React from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import offerService from '~/services/cruises/OfferService';

import { parseSearchString } from '~/utils/url';

interface Filter {
  name?: string;
  status?: API.OfferStatus | 'ALL';
  cruiseLine?: string;
  departureDate?: string;
  shipName?: string;
  marketName?: string;
  rateCode?: string;
  id?: string;
  departureExternalId?: string;
}

interface CruiseOffersData {
  page: number;
  total: number;
  cruisesPerPage: number;
  offers: API.OfferSummary[];
  sortedOffers: API.OfferSummary[] | API.Offer[];
  loadingOffers: boolean;
  setFilter: (value: Filter) => void;
  filter: Filter;
  paginate: (page: number) => void;
}

interface FetchOffer {
  result: API.OfferSummary[];
  loading: boolean;
}

interface CruiseOffersProviderProps {
  children?: React.ReactNode;
}

export const CruiseOffersContext: React.Context<CruiseOffersData> = React.createContext({} as CruiseOffersData);

export const CruiseOffersProvider = ({ children }: CruiseOffersProviderProps): JSX.Element => {
  const CRUISES_PER_PAGE = 15;
  const { search } = useLocation();
  const { push: setQueryString } = useHistory();

  const [filter, setFilter] = React.useState<Filter>({ status: 'ALL' });
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [offers, setOffers] = React.useState<FetchOffer>({
    result: [],
    loading: false,
  });
  const [sortedOffers, setSortedOffers] = React.useState<API.OfferSummary[] | API.Offer[]>([]);

  const getOffersList = async () => {
    setOffers((prev) => ({ ...prev, loading: true }));
    const skip = page * CRUISES_PER_PAGE;

    const { result, total } = await offerService.getListWithPagination({
      ...(filter.name && { name: filter.name }),
      ...(filter.cruiseLine && { cruiseLine: filter.cruiseLine }),
      ...(filter.status !== 'ALL' && { status: filter.status }),
      ...(filter.shipName && { shipName: filter.shipName }),
      ...(filter.departureDate && { departureDate: filter.departureDate }),
      ...(filter.marketName && { marketName: filter.marketName }),
      ...(filter.rateCode && { rateCode: filter.rateCode }),
      ...(filter.id && { id: filter.id }),
      ...(filter.departureExternalId && { departureExternalId: filter.departureExternalId }),
      skip,
      take: CRUISES_PER_PAGE,
    });

    setTotal(total);
    setOffers({ loading: false, result });
  };

  const getOffersOrdered = async () => {
    setOffers((prev) => ({ ...prev, loading: true }));

    try {
      const { result } = await offerService.getSortedOffers();

      if (!result) {
        return setSortedOffers([]);
      }

      const getOffers = result.map(({ offer_id }) => {
        return offerService.getById(offer_id);
      });

      const offers = await Promise.all(getOffers);

      const nonExpiredOffers = offers.filter((offer) => Object.entries(offer.result.departures ?? {}).length > 0);

      setSortedOffers(nonExpiredOffers.map((response) => response.result));
    } catch (error) {
      setSortedOffers([]);
    }
  };

  const setCurrentPage = (): void => {
    const queryString = parseSearchString(search);
    const pageToGo = parseInt(queryString?.page as string);
    if (pageToGo) setPage(pageToGo);
  };

  const paginate = (page: number): void => {
    const queryString = new URLSearchParams({ page: page.toString() });
    setQueryString({ search: queryString.toString() });
    setPage(page);
  };

  React.useEffect(() => {
    setCurrentPage();
  }, []);

  React.useEffect(() => {
    getOffersList();
  }, [page, filter]);

  React.useEffect(() => {
    getOffersOrdered();
  }, []);

  return (
    <CruiseOffersContext.Provider
      value={{
        page,
        total,
        setFilter,
        paginate,
        filter,
        offers: offers.result,
        loadingOffers: offers.loading,
        cruisesPerPage: CRUISES_PER_PAGE,
        sortedOffers: sortedOffers,
      }}
    >
      {children}
    </CruiseOffersContext.Provider>
  );
};

export const useCruiseOffers = (): CruiseOffersData => React.useContext(CruiseOffersContext);
