import { getToursSellingRegions } from '~/utils/tourV2';

export const TTC_TO_LE_REGION_MAP = new Map([
  ['eu', ['FR', 'DE', 'IE', 'IT', 'NL', 'ES']],
  ['uk', ['GB']],
]);

export enum TourStatus {
  DRAFT = 'draft',
  CONTENT_APPROVED = 'content-approved',
  UNSPECIFIED = '',
}

export enum TourScheduleState {
  UNAVAILABLE = 'unavailable',
  NOT_APPLICABLE = 'not-applicable',
  ONLINE = 'online',
  OFFLINE = 'offline',
}

interface Option<T = string> {
  label: string;
  value: T;
}

const regionDisplayName = new Intl.DisplayNames('en-AU', { type: 'region' });
export const TOUR_OFFER_SELLING_REGION_OPTIONS: Option<string>[] = getToursSellingRegions().map((regionCode) => {
  const displayName = regionDisplayName?.of(regionCode);
  const label = displayName ? `${displayName} (${regionCode})` : regionCode;
  return { value: regionCode, label };
});
