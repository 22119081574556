import React from 'react';

import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

const orderLinkFormatter = (params: GridRenderCellParams<Order.Order>) => {
  return (
    <Button variant="text" size="small" href={`/purchases/${params.row.id}`} target="_blank" rel="noreferrer">
      Details
    </Button>
  );
};

export default orderLinkFormatter;
