import isObject from './isObject';

function deleteNulls(data, keysToDeleteIfNull) {
  const specifiedKeys = Array.isArray(keysToDeleteIfNull);
  let newData = data;
  if (isObject(data)) {
    newData = Object.assign({}, data);
    Object.keys(newData).forEach((key) => {
      if (newData[key] === null && (!specifiedKeys || keysToDeleteIfNull.includes(key))) {
        delete newData[key];
      } else {
        newData[key] = deleteNulls(data[key], keysToDeleteIfNull);
      }
    });
  } else if (Array.isArray(data)) {
    newData = newData.map((data) => deleteNulls(data, keysToDeleteIfNull));
  }
  return newData;
}

export default deleteNulls;
