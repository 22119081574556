import React, { PropsWithChildren, createContext, useState } from 'react';

import { PropertyDetailResponse } from '~/services/AccommodationService';

interface PropertyDetailsState {
  propertyDetails?: PropertyDetailResponse;
  setPropertyDetails: (propertyDetails: PropertyDetailResponse) => void;
}

export const AccommodationPropertyDetailsContext = createContext<PropertyDetailsState>({} as PropertyDetailsState);

interface Props {
  details: PropertyDetailResponse;
}

export function AccommodationPropertyDetailsContextProvider({ details, children }: PropsWithChildren<Props>) {
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetailResponse>(details);

  return (
    <AccommodationPropertyDetailsContext.Provider value={{ propertyDetails, setPropertyDetails }}>
      {children}
    </AccommodationPropertyDetailsContext.Provider>
  );
}

export default AccommodationPropertyDetailsContextProvider;
