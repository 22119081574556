import React from 'react';

import classNames from 'clsx';
import currencyFormatter from 'currency-formatter';
import { marked } from 'marked';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import fileDownload from 'react-file-download';

import {
  AirplaneTicketOutlined as AirplaneTicketOutlinedIcon,
  Apartment as ApartmentIcon,
  CalendarToday as CalendarTodayIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  Hotel as HotelIcon,
  Info as InfoIcon,
  ReportOutlined as ReportOutlinedIcon,
} from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, Link, Stack, Tooltip, Typography } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';
import { vendor } from '@luxuryescapes/lib-global';
import {
  connectionBuildCancellationPolicies,
  connectionCancellationPolicyMap,
  formatDate as fd,
} from '@luxuryescapes/lib-refunds';

import TravellerModalForm from '~/components/Common/Forms/TravellerForm/TravellerModalForm';
import OrderTag from '~/components/Common/OrderTag';
import RestrictedComponent from '~/components/Common/RestrictedComponent';
import { withTenant } from '~/components/hoc';

import { LE_BUSINESS_TRAVELLER } from '~/consts/businessTraveller';
import { OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR } from '~/consts/offerTypes';
import { PAYMENT_METHODS_NOT_ALLOWED_REFUND } from '~/consts/payment';
import { ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import { getRefundRequestsByOrderItemId } from '~/services/OrdersService';
import { getCommissionStatementPDF } from '~/services/PDFService';
import { isDepositBalancePaid } from '~/services/PaymentHelper';
import ReservationService from '~/services/ReservationService';
import { datesHaveSameDayMonthYear, isAfter } from '~/services/TimeService';

import { isAdmin } from '~/utils/adminPermission';
import { isOfferTypeDynamicPricedHotel, isOfferTypeHotel, isOfferTypeTour } from '~/utils/offer';
import { formatOrderDate } from '~/utils/order';

import OrderItemCredit from '../BusinessTraveller/OrderItemBusinessCredit';

import ChannelManagerReservationSummary from './ChannelManagerReservationSummary';
import ConnectionItemsStatus from './ConnectionItemsStatus';
import InsuranceLetter from './InsuranceLetter';
import ItemStatus from './ItemsStatus';
import Log from './Log';
import PricingSummary from './PricingSummary';
import ReservationInfo from './ReservationInfo';
import ReservationSummary from './ReservationSummary';
import { GeneralInformationComponent } from './components/GeneralInformationComponent';
import { canBookDates, getGuestName, getPropertyLink, getRoomRate, getRoomTypeLink } from './utils';

function isSameOrAfter(date) {
  return datesHaveSameDayMonthYear(date) || isAfter(date);
}

function allowedAutomaticRefund({
  order,
  item,
  cancellationPolicies,
  hasConnection,
  hasAllowedRefund,
  propertyTimezone,
}) {
  if (!hasAllowedRefund) {
    return false;
  }

  if (!hasConnection) {
    return false;
  }

  if (order.payments.some((payment) => PAYMENT_METHODS_NOT_ALLOWED_REFUND.includes(payment.type))) {
    return false;
  }

  if (
    cancellationPolicies.policies.length &&
    cancellationPolicies.policies.some(
      (policy) => isSameOrAfter(fd.formattedDateByTimezone(policy.deadline, propertyTimezone)) || policy.nonRefundable,
    )
  ) {
    return false;
  }

  if (isSameOrAfter(item.reservation.check_in)) {
    return false;
  }

  return true;
}

const hasInvalidRoomTypeRate = (item, offer) => {
  if (item.offer.type === OFFER_TYPE_HOTEL) {
    const originalRoomRate = item.offer_package.fk_room_rate_id;
    const originalRoomType = item.offer_package.fk_room_type_id;

    const hasValidPackage = offer.packages.some((offerPackage) => {
      return offerPackage.fk_room_rate_id === originalRoomRate && offerPackage.fk_room_type_id === originalRoomType;
    });

    return !hasValidPackage;
  }
  return false;
};

interface Props {
  cart: App.Cart;
  checkInStatus: {
    addons: Array<any>;
    check_in_status?: string;
  };
  count: number;
  currencyCode: string;
  customer: App.User;
  disableInteraction?: any;
  doAddAddonToCart: any;
  doAddOfferToCart: any;
  doAllowToggleReservationType: any;
  doEmptyCart: any;
  doInitCart: any;
  doSelectOffer: any;
  doSetBookDatesForOrder: any;
  doSetCustomer: any;
  doSetOrderId: any;
  doSetReservationType: any;
  doToggleBookingDates: any;
  doUpdateReservation: any;
  getTravellers: any;
  hasAllowedRefund: boolean;
  history: any;
  item: App.OrderItem;
  offer: App.AccommodationOffer;
  offerPackage: App.AccommodationPackage;
  onToggleBookingDates: any;
  onToggleChangingDates: any;
  onToggleConvertToBNBL: any;
  onTogglePutOnHold: any;
  order: App.Order;
  refreshData: any;
  setIsBookingDates: any;
  setIsChangingDates: any;
  setIsConvertingToBNBL: any;
  setIsPuttingOnHold: any;
  showRefundModal: any;
  showWarningModal: any;
  traveller: any;
  tenant: App.Tenant;
}

interface State {
  isAdmin: boolean;
  isTravellerModalVisible: boolean;
  property: any;
  reservationConnectionStatus?: Reservation.ConnectionState;
  isInvalidItem: boolean;
  invoiceDownloading: boolean;
  downloadFailed: boolean;
  refundRequest?: RefundRequest;
}

class OrderItemDefault extends React.Component<Props & WithSnackbarProps, State> {
  static contextTypes = {
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props);

    this.state = {
      isAdmin: isAdmin(context.user),
      isTravellerModalVisible: false,
      property: null,
      isInvalidItem: hasInvalidRoomTypeRate(props.item, props.offer),
      invoiceDownloading: false,
      downloadFailed: false,
    };
  }

  toggleTravellerModal = () => {
    this.setState((prevState) => ({
      isTravellerModalVisible: !prevState.isTravellerModalVisible,
    }));
  };

  componentDidMount() {
    if (this.props.offerPackage?.fk_property_id) {
      ReservationService.getProperty(this.props.offerPackage.fk_property_id).then((response) => {
        this.setState({
          property: response.result,
        });
      });
    }

    if (this.props.item.reservation?.id && this.props.item.fullReservation.property) {
      ReservationService.getReservationById(this.props.item.reservation.id)
        .then((response) => {
          if (response.result.connection_state) {
            this.setState({
              reservationConnectionStatus: response.result.connection_state,
            });
          }
        })
        .catch((err) => {
          this.props.enqueueSnackbar(err.message || 'Error! Unable fetch appropriate reservation.', {
            variant: 'error',
          });
        });
    }

    if (this.props.order.id_orders && this.props.item.id) {
      getRefundRequestsByOrderItemId(this.props.order.id_orders, this.props.item.id)
        .then((response) => {
          this.setState({
            refundRequest: response,
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar(err.message || 'Error! Unable fetch appropriate refund request.', {
            variant: 'error',
          });
        });
    }
  }

  createPayload = () => {
    const { item, traveller } = this.props;
    const { reservation, fullReservation, offer, offer_package, inclusions } = item;
    const { number_of_adults, number_of_children, number_of_infants, commission_report } = reservation;
    const { number_of_nights } = fullReservation;

    if (!commission_report) {
      console.error('No commission-report in reservation');
      return false;
    }

    const package_inclusions =
      inclusions?.total_inclusions || offer_package.description || inclusions?.inclusion_highlights;
    return {
      confirmation_number: fullReservation.booking_number,
      name_of_guest: getGuestName(reservation, traveller),
      booking_details: {
        number_of_nights,
        checkin_date: formatOrderDate(reservation.check_in || reservation.start_date),
        checkout_date: formatOrderDate(reservation.check_out || reservation.end_date),
        room_type: fullReservation.room_type.name,
        number_of_rooms: 1,
        number_of_adults,
        number_of_infants,
        number_of_children,
        inclusions: marked(package_inclusions),
      },
      hotel_details: {
        name: fullReservation.property.name || '',
        address: offer_package.property.address || '',
        phone_number: offer.vendor_contact_phone || 0,
        emailid: offer.vendor_booking_email || '',
        gstin: offer_package.property.gstin || '',
      },

      payment_breakdown: {
        total_booking_cost: reservation.room_rate_amount_in_cost,
        cost_currency: reservation.room_rate_cost_currency,
        ...commission_report,
      },
    };
  };

  downloadCommissionReport = async () => {
    this.setState({
      invoiceDownloading: true,
      downloadFailed: false,
    });
    const payload = this.createPayload();
    if (!payload) {
      this.setState({
        downloadFailed: true,
        invoiceDownloading: false,
      });
      return;
    }
    try {
      const pdf = await getCommissionStatementPDF(payload);
      const fileName = `${payload.confirmation_number}_commission_statement.pdf`;
      await fileDownload(pdf, fileName);
      this.setState({
        invoiceDownloading: false,
      });
    } catch (error) {
      this.setState({
        downloadFailed: true,
        invoiceDownloading: false,
      });
    }
  };

  isInstalmentFullyPaidOff = (order) => {
    const instalmentDetails = order.payments[0]?.instalmentDetails;
    if (instalmentDetails) {
      return ['payment_taken_complete', 'payment_manual_debit_taken_complete'].includes(
        instalmentDetails?.instalment_status,
      );
    }
    return true;
  };

  showChangeBooking = (item) => {
    return item.offer.type === OFFER_TYPE_HOTEL || item.offer.type === OFFER_TYPE_TOUR;
  };

  formatChildrenAges = (children_ages: Array<any>) => {
    const ages = children_ages.map((age) => `${age}${age > 1 ? 'yrs' : 'yr'}`).join(', ');
    return `(${ages})`;
  };

  render() {
    const {
      order,
      item,
      showRefundModal,
      hasAllowedRefund,
      currencyCode,
      count,
      onToggleChangingDates,
      onToggleBookingDates,
      onToggleConvertToBNBL,
      onTogglePutOnHold,
      disableInteraction,
      traveller,
      getTravellers,
      offerPackage,
      checkInStatus,
      tenant,
    } = this.props;

    const { property, invoiceDownloading, isAdmin, downloadFailed, reservationConnectionStatus, refundRequest } =
      this.state;

    const { reservation, fullReservation, fk_vendor_id, offer, offer_package } = item;

    const hasConnection = !!(reservation && reservation.connection && property);

    // We can't allow change dates if order has booking protection items
    const showChangeBookingBtn = this.showChangeBooking(item);

    const hasNoPendingPayments = this.isInstalmentFullyPaidOff(order) && isDepositBalancePaid(order);

    const connectionCancellationPolicies = hasConnection
      ? connectionBuildCancellationPolicies(
          reservation.connection.cancellation_policies.map(connectionCancellationPolicyMap),
          {
            timezone: property.timezone,
            regionCode: order.region_code,
            currencyCode: order.currency_code,
          },
        )
      : null;

    const hasAllowedAutomaticRefund = allowedAutomaticRefund({
      order,
      item,
      hasConnection,
      cancellationPolicies: connectionCancellationPolicies,
      hasAllowedRefund,
      propertyTimezone: property?.timezone,
    });

    const itemNotCancelled = item.status !== 'cancelled';

    const canConvertToBNBL =
      itemNotCancelled &&
      order.brand !== LE_BUSINESS_TRAVELLER &&
      hasNoPendingPayments &&
      item.offer.type === OFFER_TYPE_HOTEL &&
      !item.is_comped_package;

    const isRefundAllowed = hasAllowedRefund && (!item.is_comped_package || isAdmin);

    const canPutTourOnHold =
      itemNotCancelled &&
      window.configs.PUT_TOUR_ON_HOLD &&
      !item.on_hold &&
      item.offer.type === OFFER_TYPE_TOUR &&
      hasNoPendingPayments;

    const showRoomWithNoReservation =
      !reservation && offer.type === OFFER_TYPE_HOTEL && offerPackage?.room_type && offerPackage?.room_rate;

    const classes = classNames('order-item', {
      cancelled: item.status === 'cancelled',
    });

    let informationDetail;
    if (traveller && traveller.all_forms_submitted) {
      informationDetail = (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            color: 'green',
          }}
        >
          <CheckCircleOutlinedIcon />
          <Typography display="inline" fontWeight="bold">
            Information complete
          </Typography>
        </Box>
      );
    } else if (traveller && traveller.traveller_submitted && !traveller.flights_submitted) {
      informationDetail = (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              color: 'green',
            }}
          >
            <CheckCircleOutlinedIcon />
            <Typography display="inline" fontWeight="bold">
              Traveller Details submitted
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              color: 'red',
            }}
          >
            <AirplaneTicketOutlinedIcon />
            <Typography display="inline" fontWeight="bold">
              Flight Information required
            </Typography>
          </Box>
        </>
      );
    } else if (traveller) {
      informationDetail = (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            color: 'red',
          }}
        >
          <ReportOutlinedIcon />
          <Typography display="inline" fontWeight="bold">
            Information required
          </Typography>
        </Box>
      );
    }

    return (
      <Box p={3} className={classes}>
        {this.state.isInvalidItem && (
          <Alert severity="warning">
            Selecting new dates for this order is not possible due to an error. This package has been changed or deleted
            since purchase. Please escalate to a team leader.
          </Alert>
        )}

        <Stack direction="row" spacing={4} alignItems="start">
          <Box flexGrow={1}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h5">
                <strong>{count}:</strong> {item.name}
              </Typography>

              <Typography variant="subtitle1">
                <OrderTag items={order.items} item={item} />
              </Typography>
            </Stack>

            <Stack direction="row" spacing={4} alignItems="center">
              <ItemStatus item={item} />
              {reservationConnectionStatus && (
                <ConnectionItemsStatus reservationConnectionStatus={reservationConnectionStatus} />
              )}

              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Booking number:</Typography>
                <Typography color="green">{this.props.item.booking_number}</Typography>
              </Stack>

              {item.offer_package?.bundle_package_id && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Bundle Package ID:</Typography>
                  <Typography color="grey.800">{item.offer_package?.bundle_package_id}</Typography>
                </Stack>
              )}
            </Stack>

            <Box mt={2} display="grid" gap={3} gridTemplateColumns="repeat(auto-fit, minmax(260px, 1fr))">
              {/* General information start */}
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  General information
                </Typography>

                {showRoomWithNoReservation && (
                  <Stack my={1} direction="column" spacing={1}>
                    {item.number_of_nights && (
                      <Stack direction="row" spacing={2}>
                        <CalendarTodayIcon />
                        <Typography>{item.number_of_nights} Night(s)</Typography>
                      </Stack>
                    )}
                    {property && (
                      <Stack direction="row" spacing={2}>
                        <ApartmentIcon />
                        <Link
                          href={getPropertyLink(fk_vendor_id, offerPackage.room_type)}
                          target="_blank"
                          rel="noreferrer"
                          underline="hover"
                        >
                          {property.name}
                        </Link>
                      </Stack>
                    )}
                    {offerPackage.room_rate && Object.keys(offerPackage.room_rate).length > 0 && (
                      <Stack direction="row" spacing={2}>
                        <HotelIcon />
                        <Link
                          href={getRoomTypeLink(
                            fk_vendor_id,
                            offerPackage.room_type,
                            getRoomRate(fullReservation, offerPackage),
                          )}
                          target="_blank"
                          rel="noreferrer"
                          underline="hover"
                        >
                          {offerPackage.room_type.name} | {offerPackage.room_rate.rate_plan.name}
                        </Link>
                      </Stack>
                    )}
                  </Stack>
                )}

                {reservation && Object.keys(reservation).length > 0 && (
                  <GeneralInformationComponent
                    item={item}
                    traveller={traveller}
                    order={order}
                    offerPackage={offerPackage}
                  />
                )}
              </Box>
              {/* General information end */}

              {reservation && Object.keys(reservation).length > 0 && (
                <ReservationInfo
                  reservation={reservation}
                  item={item}
                  order={order}
                  traveller={traveller}
                  connectionCancellationPolicies={connectionCancellationPolicies}
                />
              )}

              <PricingSummary item={item} currencyCode={currencyCode} />

              {tenant.brand === 'lebusinesstraveller' && (
                <OrderItemCredit
                  orderId={item.fk_order_id}
                  orderItemId={item.id}
                  customerId={order.fk_customer_id}
                  location={item.name}
                />
              )}
            </Box>
          </Box>

          <Box>
            <Stack direction="column" spacing={1} alignItems="end">
              <Typography variant="subtitle1" fontWeight="bold">
                Changes and refunds
              </Typography>

              {fullReservation && fullReservation.extra_guest_surcharge_amount > 0 && (
                <Typography>
                  Includes extra guest surcharge of{' '}
                  {currencyFormatter.format(fullReservation.extra_guest_surcharge_amount, {
                    code: currencyCode,
                  })}
                </Typography>
              )}

              <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
                {isRefundAllowed && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      showRefundModal({ itemId: item.id });
                    }}
                    disabled={disableInteraction || (!!refundRequest?.refund_option && !isAdmin)}
                    className="T-issue-refund"
                  >
                    Issue refund
                  </Button>
                )}

                {canBookDates(item) && (
                  <div className="T-HasNoReservation">
                    <Button
                      variant="text"
                      size="small"
                      onClick={onToggleBookingDates}
                      disabled={disableInteraction}
                      className="T-SelectDatesButton"
                    >
                      Select dates
                      {order.gift_status && order.gift_status !== 'redeemed' && (
                        <Tooltip title="Can't select dates because this gift has not been redeemed">
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </Button>
                  </div>
                )}

                {itemNotCancelled && showChangeBookingBtn && (
                  <Button
                    variant="text"
                    size="small"
                    className="T-Change-Dates"
                    onClick={onToggleChangingDates}
                    disabled={disableInteraction}
                  >
                    Change dates or name
                  </Button>
                )}

                {canConvertToBNBL && (
                  <Button variant="text" size="small" onClick={onToggleConvertToBNBL} disabled={disableInteraction}>
                    Change to BNBL
                  </Button>
                )}

                {canPutTourOnHold && (
                  <Button variant="text" size="small" onClick={onTogglePutOnHold} disabled={disableInteraction}>
                    Put booking on hold
                  </Button>
                )}
              </RestrictedComponent>

              {isOfferTypeTour(offer) && vendor.requiresTravellerDetails(item.fk_vendor_id) && (
                <Box>
                  <Typography textAlign="right">{informationDetail}</Typography>

                  <Button variant="text" size="small" onClick={this.toggleTravellerModal}>
                    Travellers details
                  </Button>

                  <TravellerModalForm
                    isTravellerModalVisible={this.state.isTravellerModalVisible}
                    hideTravellerModal={this.toggleTravellerModal}
                    tourFlightDetailsRequired={item.offer.tour_flight_details_required}
                    bookingNumber={item.booking_number}
                    orderId={item.fk_order_id}
                    getTravellers={getTravellers}
                  />
                </Box>
              )}

              {hasAllowedAutomaticRefund && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    showRefundModal({
                      itemId: item.id,
                      auto: true,
                    });
                  }}
                  className="T-issue-refund"
                  disabled={disableInteraction}
                >
                  Automatic refund
                </Button>
              )}

              {offer_package.property &&
                offer_package.property.commission_statement &&
                offer.type !== OFFER_TYPE_HOTEL && (
                  <Box>
                    <Button
                      variant="text"
                      size="small"
                      startIcon={invoiceDownloading ? <CircularProgress size={16} /> : undefined}
                      onClick={this.downloadCommissionReport}
                      disabled={invoiceDownloading}
                    >
                      {!invoiceDownloading && !downloadFailed && <>Download commission</>}
                      {invoiceDownloading && <>Downloading</>}
                      {!invoiceDownloading && downloadFailed && <>Download Failed! Try Again</>}
                    </Button>
                  </Box>
                )}

              {(isOfferTypeTour(offer) || isOfferTypeHotel(offer)) && (
                <ReservationSummary guestName={getGuestName(reservation, traveller)} item={item} order={order} />
              )}

              {refundRequest && (
                <InsuranceLetter item={item} refundRequest={refundRequest} order={order} offer={offer} />
              )}

              {isOfferTypeDynamicPricedHotel(offer) && (
                <ChannelManagerReservationSummary
                  guestName={getGuestName(reservation, traveller)}
                  item={item}
                  order={order}
                />
              )}

              {checkInStatus && checkInStatus.check_in_status && (
                <Typography>Check in status: {checkInStatus.check_in_status}</Typography>
              )}
            </Stack>
          </Box>
        </Stack>

        <Box mt={2}>
          <Log orderId={item.fk_order_id} itemId={item.id} />
        </Box>
      </Box>
    );
  }
}

export default withTenant(withSnackbar(OrderItemDefault));
