import React, { Component } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';

const attributeWhitelist = [
  'description',
  'size',
  'room_inclusions',
  'amenities',
  'additional_guest_amount_description',
];

const getDefaultState = () => {
  return {
    selectedAttributes: [],
    modalIsVisible: false,
    selectedRoom: '',
  };
};

export default class CopyRoomTypeAttributeForm extends Component {
  constructor(props) {
    super(props);

    this.attributes = attributeWhitelist.filter((attribute) => this.props.schemaProperties[attribute]);

    this.state = getDefaultState();
  }

  toggleModalVisibility = () => {
    this.setState((prevState) => ({
      modalIsVisible: !prevState.modalIsVisible,
    }));
  };

  toggleCheckedField = (e) => {
    const attribute = e.target.value;

    this.setState((prevState) => {
      let selectedAttributes = [...prevState.selectedAttributes];

      if (selectedAttributes.includes(attribute)) {
        selectedAttributes = selectedAttributes.filter((a) => a !== attribute);
      } else {
        selectedAttributes.push(attribute);
      }

      return { selectedAttributes };
    });
  };

  getSelectOptions() {
    return this.props.roomTypeNames.filter((roomType) => roomType.value !== this.props.roomTypeId);
  }

  handleSubmit = () => {
    this.props.onCopyAttributes(this.state.selectedRoom, this.props.roomTypeId, this.state.selectedAttributes);

    this.setState(getDefaultState());
  };

  handleRoomSelected = (e) => {
    this.setState({ selectedRoom: e.target.value });
  };

  canSubmit() {
    return !!this.state.selectedRoom && !!this.state.selectedAttributes.length;
  }

  render() {
    if (this.props.roomTypeNames.length === 1) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="flex-end">
        <Button variant="text" startIcon={<ContentCopyIcon />} onClick={this.toggleModalVisibility}>
          Copy attributes
        </Button>

        <Dialog
          open={this.state.modalIsVisible}
          onClose={this.toggleModalVisibility}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          scroll="paper"
          maxWidth="md"
        >
          <DialogTitle id="scroll-dialog-title">Copy editable details from another room type</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <DialogContentText>
                This will copy over existing details but will not automatically save the room type. You will have the
                opportunity to review and make changes.
              </DialogContentText>

              <TextField
                label="Room type"
                placeholder="Select a room type"
                value={this.state.selectedRoom}
                onChange={this.handleRoomSelected}
                fullWidth
                select
              >
                {this.getSelectOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <FormGroup>
                {this.attributes.map((attribute) => (
                  <FormControlLabel
                    key={attribute}
                    label={this.props.schemaProperties[attribute].title}
                    control={
                      <Checkbox
                        value={attribute}
                        onChange={this.toggleCheckedField}
                        checked={this.state.selectedAttributes.includes(attribute)}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={this.toggleModalVisibility}>
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleSubmit} disabled={!this.canSubmit()}>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    );
  }
}
