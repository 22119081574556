import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { Container, Stack } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import BlacklistedOfferSearchForm from '~/components/Common/Forms/BlacklistedOfferSearchForm';
import Spinner from '~/components/Common/Spinner';
import { AddToBlocklist } from '~/components/Content/OfferBlocklist/AddToBlocklist';
import BlockedOffersGrid from '~/components/Content/OfferBlocklist/BlockedOffersGrid';
import { getAdminOfferUrl } from '~/components/Content/OfferBlocklist/utils';

import searchService, { BlockedOffer } from '~/services/SearchService';

const SIZE_PER_PAGE = 20;
const DEFAULT_ROW_COUNT = 0;

function ViewBlockedOffers() {
  const tenant = useSelector((state: App.State) => state.tenant);

  const [searchString, setSearchString] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(DEFAULT_ROW_COUNT);

  const handleSearchTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchString(e.target.value);
  };

  const fetchData = useCallback(
    async (page: number, q?: string) => {
      let rowCount;
      setLoading(true);
      try {
        const { result } = await searchService.getBlockedOffersList(tenant.brand, page, SIZE_PER_PAGE, q);

        const data = result.offers.map(({ offerId, createdAt }: BlockedOffer) => ({
          offerId: offerId,
          view: getAdminOfferUrl(offerId.trim()),
          added: createdAt,
        }));
        rowCount = result.resultCount;
        setData(data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setData([]);
      }

      if (rowCount && currentPage === 1) {
        setRowCount(rowCount);
      }
    },
    [tenant.brand],
  );

  useEffect(() => {
    fetchData(currentPage, searchString);
  }, [fetchData]);

  const handleSearchSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      await fetchData(currentPage, searchString);
    },
    [searchString, fetchData],
  );

  const onPageChange = useCallback(async (page: number) => {
    setCurrentPage(page);
    await fetchData(page, searchString);
  }, []);

  const handleSelect = useCallback((ids: GridRowSelectionModel) => {
    setSelected(ids);
  }, []);

  const handleDelete = useCallback(async () => {
    const offerIds = selected.map((id: string) => id);
    await searchService.removeBlockedOffer(tenant.brand, offerIds);
    await fetchData(currentPage, searchString);
  }, [selected, fetchData]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Search Blocked Offers</title>
      </Helmet>

      <Stack direction="column" gap={4}>
        <AddToBlocklist />

        <PageSubheader title={`Search`} />
        <BlacklistedOfferSearchForm
          searchString={searchString}
          handleChange={handleSearchTextChange}
          handleSubmit={handleSearchSubmit}
        />

        {isLoading && <Spinner />}

        <BlockedOffersGrid
          selected={selected}
          isLoading={isLoading}
          data={data}
          rowCount={rowCount}
          onPageChange={onPageChange}
          handleSelect={handleSelect}
          handleDelete={handleDelete}
          page={currentPage}
          sizePerPage={SIZE_PER_PAGE}
        />
      </Stack>
    </Container>
  );
}

export default ViewBlockedOffers;
