// Schedule filters
const BOOK_BY_DATE_START = 'book_by_dates_range_start';
const BOOK_BY_DATE_END = 'book_by_dates_range_end';
const AVAILABLE_OFFLINE_START = 'available_offline_range_start';
const AVAILABLE_OFFLINE_END = 'available_offline_range_end';
const FRONT_PAGE_START = 'front_page_visibility_range_start';
const FRONT_PAGE_END = 'front_page_visibility_range_end';

// Availbility filters
const CURRENT_LIVE = 'current_live';
const CURRENT_OFFLINE = 'current_offline';
const PREVIOUS_LIVE = 'previous_front_page_visibility';
const CURRENT_LIVE_OR_PREVIOUSLY_CONTRACTED = 'currently_live_or_previously_contracted';

module.exports = {
  BOOK_BY_DATE_START,
  BOOK_BY_DATE_END,
  AVAILABLE_OFFLINE_START,
  AVAILABLE_OFFLINE_END,
  FRONT_PAGE_START,
  FRONT_PAGE_END,
  CURRENT_LIVE,
  CURRENT_OFFLINE,
  PREVIOUS_LIVE,
  CURRENT_LIVE_OR_PREVIOUSLY_CONTRACTED,
};
