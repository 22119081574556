import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerCountries from '~/actions/customerCommunication/fetchHeroPlannerCountries';

import { useAppSelector } from '../store';

function useHeroPlannerCountries(countryGroupId: string) {
  const brand = useAppSelector((state) => state.tenant.brand);
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerCountries> = [brand, countryGroupId];
    return [getRecordKeyFor(...params), params];
  }, [brand, countryGroupId]);
  const countriesReq = useAppSelector((state) => state.customerCommunication.countryGroupCountriesRecords[key]);
  const appDispatch = useDispatch();
  const fetchCountries = useCallback(() => {
    appDispatch(fetchHeroPlannerCountries(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  return { countriesReq, fetchCountries };
}

export default useHeroPlannerCountries;
