import React from 'react';

import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

const actionsFormatter = (params: GridRenderCellParams<Order.Order>) => {
  const order = params.row;

  return (
    <Button target="_blank" href={`/purchases/${order.id_orders}`}>
      Order
    </Button>
  );
};

export default actionsFormatter;
