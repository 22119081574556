import React, { useCallback, useState } from 'react';

import { Alert, Button, Card, CardContent, CardHeader, Container, Grid } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { sendAtolCertificate } from '~/services/FlightsService';

import { MIN_PASSENGER_AGE } from '~/consts/flight';

import AirlineDetailsForm from './AirlineDetailsForm';
import CarrierDetailsForm from './CarrierDetailsForm';
import PassengerForm from './PassengerForm';

export type Passenger = FlightTraveller & {
  middleName?: string;
  givenName: string;
  age: number;
};

export type AtolForm = {
  passengers: Passenger[];
  email: string;
  totalCost: number;
  flightPnr: string;
  originAirport: string;
  originAirportName: string;
  destinationAirport: string;
  destinationAirportName: string;
  searchType: string;
  carrier: string;
  carrierName: string;
  departureDate: string;
  returnDate: string;
};

const DEFAULT_PASSENGER = {
  type: null,
  title: null,
  givenName: '',
  middleName: '',
  surname: '',
  age: MIN_PASSENGER_AGE,
} as Passenger;

const DEFAULT_ATOL_FORM = {
  passengers: [DEFAULT_PASSENGER],
  email: '',
  totalCost: 0,
  flightPnr: '',
  originAirport: '',
  originAirportName: '',
  destinationAirport: '',
  destinationAirportName: '',
  searchType: null,
  carrier: '',
  carrierName: '',
  departureDate: null,
  returnDate: null,
} as AtolForm;

function AtolPage() {
  const [loading, setLoading] = useState(false);
  const [atolData, setAtolData] = useState<AtolForm>(DEFAULT_ATOL_FORM);
  const [status, setStatus] = useState({
    success: null,
    message: '',
  });

  const onAddPassenger = useCallback(() => {
    setAtolData({
      ...atolData,
      passengers: [...atolData.passengers, DEFAULT_PASSENGER],
    });
  }, [atolData]);

  const handleFormChange = useCallback(
    (name: string, value: string) => {
      setAtolData({ ...atolData, [name]: value });
    },
    [atolData],
  );

  const handlePassengerChange = useCallback(
    (passengerIndex: number, name: string, value: string) => {
      setAtolData({
        ...atolData,
        passengers: atolData.passengers.map((passenger, index) => {
          if (index === passengerIndex) {
            return { ...passenger, [name]: value };
          }
          return passenger;
        }),
      });
    },
    [atolData],
  );

  const onRemovePassenger = useCallback(
    (passengerIndex: number) => {
      setAtolData({
        ...atolData,
        passengers: atolData.passengers.filter((_, index) => index !== passengerIndex),
      });
    },
    [atolData],
  );

  const handleFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);

      try {
        const response = await sendAtolCertificate({ ...atolData, totalCost: Number(atolData.totalCost) });
        setLoading(false);

        if (response.status === 200) {
          setAtolData(DEFAULT_ATOL_FORM);
          setStatus({ success: true, message: 'ATOL email was sent successfully.' });
        } else {
          setStatus({ success: false, message: response.message });
        }
      } catch (error) {
        setLoading(false);
        setStatus({ success: false, message: 'Failed to send the ATOL email.' });
      }
    },
    [atolData],
  );

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader title="Send Atol Certificate" />
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={6}>
              <CarrierDetailsForm atolData={atolData} onUpdate={handleFormChange} />
              <AirlineDetailsForm atolData={atolData} onUpdate={handleFormChange} />
              <Grid container item spacing={2} sm={12}>
                {atolData.passengers.map((passenger, index) => (
                  <PassengerForm
                    key={index}
                    index={index}
                    passenger={passenger}
                    onUpdate={handlePassengerChange}
                    onRemove={onRemovePassenger}
                  />
                ))}
                <Grid item xs={12} sm={12}>
                  <Button color="secondary" variant="outlined" onClick={onAddPassenger}>
                    +&nbsp;Passenger
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} display="flex" direction="row" gap={2}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={loading}
                  endIcon={loading ? <Spinner size={24} color="grey" /> : null}
                >
                  Send
                </Button>
                {status.success !== null && !loading && (
                  <Alert severity={status.success ? 'success' : 'error'}>{status.message}</Alert>
                )}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}

export default AtolPage;
