import { OFFER_TYPE_TOUR_V2 } from '../consts/offerTypes';

import { request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

export async function resendVendorBookingConfirmation({ orderId, offerId, offerType, sendToAgent = false }) {
  if (offerType === OFFER_TYPE_TOUR_V2) {
    return request(basePath() + 'v2/le-notify/vendor-tour-booking-confirmation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        send_to_agent: sendToAgent,
        resend: true,
      }),
    });
  }

  return request(basePath() + 'le-notify/vendor-booking-confirmation/resend', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id_orders: orderId,
      id_offers: offerId,
      send_to_agent: sendToAgent,
    }),
  });
}

export async function resendDepositBookingConfirmation({ orderId, offerId, sendToAgent = false }) {
  const resend = true;

  const body = offerId
    ? {
        id_orders: orderId,
        id_offer: offerId,
        send_to_agent: sendToAgent,
      }
    : {
        orderId: orderId,
        resend,
        send_to_agent: sendToAgent,
      };

  return request(basePath() + `v2/le-notify/booking-confirmation-deposit/resend`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export async function resendBookingConfirmation({ orderId, offerId, offerType, brand, sendToAgent = false }) {
  if (offerType === OFFER_TYPE_TOUR_V2) {
    return request(basePath() + `v2/le-notify/booking-confirmation-tour/resend`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        sendToAgent,
        resend: true,
      }),
    });
  }

  const version = offerId ? '' : 'v2/';
  const resend = true;

  const body = offerId
    ? {
        id_orders: orderId,
        id_offer: offerId,
        send_to_agent: sendToAgent,
        brand,
      }
    : {
        orderId: orderId,
        resend,
        send_to_agent: sendToAgent,
        brand,
      };

  return request(basePath() + `${version}le-notify/booking-confirmation/resend`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export async function resendGiftBookingConfirmation({ orderId, offerId, sendToAgent = false }) {
  const body = {
    id_orders: orderId,
    id_offer: offerId,
    send_to_agent: sendToAgent,
  };

  return request(basePath() + 'le-notify/gift-booking-confirmation/resend', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export async function resendOrderGiftDeliveryEmail({ orderId }) {
  const body = {
    id_orders: orderId,
    gift_delivery: true,
  };

  const featurePath = window.configs.ENABLE_NEW_GIFT_REDEMPTION_INVITATION_EMAIL
    ? 'le-notify/gift-booking-confirmation/resend'
    : 'le-notify/booking-confirmation/resend';

  return request(basePath() + featurePath, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export async function resendGiftCardConfirmation({ orderId }) {
  return request(`${basePath()}le-notify/gift-card-confirmation/resend`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id_orders: orderId,
    }),
  });
}

export async function resendAtolCertificate({ pnrId, orderId }) {
  return request(`${basePath()}le-notify/atol-certificate/resend`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      pnrId,
      orderId,
    }),
  });
}

export async function editAtolCertificate(data) {
  return request(`${basePath()}le-notify/atol-certificate/edit`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data }),
  });
}

export async function sendBusinessCreditsAppliedEmail(data) {
  return request(`${basePath()}v2/le-notify/business-credits-applied`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data }),
  });
}

export async function sendGDPRRequestNotification(data) {
  return request(`${basePath()}le-notify/gdpr-removal-request`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data }),
  });
}

export async function getAllFailedEmails(templateName?: string) {
  const query = templateName ? `?templateName=${templateName}` : '';
  return request(`${basePath()}le-notify/get-all-failed-emails${query}`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'omit',
  });
}
