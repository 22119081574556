import React from 'react';

import ExportCruisePurchasesReportForm from '../Common/Forms/ExportCruisePurchasesReportForm';
import ExportCruiseRefundAfterPaymentReportForm from '../Common/Forms/ExportCruiseRefundAfterPaymentForm';
import ExportCruiseRefundReportForm from '../Common/Forms/ExportCruiseRefundReportForm';
import ExportRevelexPayableForm from '../Common/Forms/ExportRevelexPayableForm';
import ExportRevelexPaymentsReportForm from '../Common/Forms/ExportRevelexPaymentsReportForm';

import ContentWithSidebar from './ContentWithSidebar';
import UploadRevelexPayments from './UploadRevelexPayments';

const DEFAULT_ITEMS = 'cruise-bookings';

const items = [
  {
    slug: 'revelex-payments-due',
    title: 'Payments Due Report',
    component: ExportRevelexPayableForm,
  },
  {
    slug: 'revelex-payments-made',
    title: 'Payments Made Report',
    component: ExportRevelexPaymentsReportForm,
  },
  {
    slug: 'upload-revelex-payments',
    title: 'Upload Payments',
    component: UploadRevelexPayments,
  },
  {
    slug: 'cruise-refunds',
    title: 'Cruise Refunds Report',
    component: ExportCruiseRefundReportForm,
  },
  {
    slug: 'cruise-refunds-after-payment',
    title: 'Cruise Refunds After Payment Report',
    component: ExportCruiseRefundAfterPaymentReportForm,
  },
  {
    slug: 'cruise-bookings',
    title: 'Cruise Bookings Report',
    component: ExportCruisePurchasesReportForm,
  },
];

export default function CruisePage() {
  return <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEMS} />;
}
