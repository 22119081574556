import React from 'react';

import { Button, Grid, TextField } from '@mui/material';

type Props = {
  id: string;
  searchString: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  type?: string;
  name?: string;
  label?: string;
  placeholder?: string;
};

export default function SearchForm(props: Props) {
  const {
    id,
    searchString,
    handleChange,
    handleSubmit,
    type = 'text',
    name = 'searchString',
    label = '',
    placeholder = 'Search here',
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            id={id}
            type={type}
            name={name}
            label={label}
            placeholder={placeholder}
            value={searchString}
            onChange={handleChange}
            autoFocus
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button type="submit" variant="contained" className="T-search-user">
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
