import React from 'react';

import { Android, Apple, Computer, Warning } from '@mui/icons-material';
import { Box } from '@mui/material';

import { operations } from '@luxuryescapes/contract-svc-promo';

type PromoRequestDeviceTypeProps = {
  discountRequest?: operations['calculateDiscount']['parameters']['body']['Discount Request'];
};

function PromoRequestDeviceType({ discountRequest }: PromoRequestDeviceTypeProps) {
  if (!discountRequest || !discountRequest?.order?.deviceType) {
    return (
      <Box title={'Unknown request source'}>
        <Warning />
      </Box>
    );
  }

  const shared = 'requestSource' in discountRequest ? `${discountRequest.requestSource}` : 'missing';

  switch (discountRequest.order.deviceType) {
    case 'android':
      return (
        <Box title={`Request via android app [${shared}]`}>
          <Android />
        </Box>
      );
    case 'ios':
      return (
        <Box title={`Request via ios [${shared}]`}>
          <Apple />
        </Box>
      );
    case 'web':
      return (
        <Box title={`Request made via website [${shared}]`}>
          <Computer />
        </Box>
      );
    default:
      return (
        <Box title={`Request made via unknown request source: "${discountRequest.order.deviceType}" [${shared}]`}>
          <Warning />
        </Box>
      );
  }
}

export { PromoRequestDeviceType };
