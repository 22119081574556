import React, { ComponentProps, useCallback, useContext, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { TourDetailsFormResources, useTourDetailsFormQuery } from '~/queries/tours/useTourDetailsFormQueries';

import { Alert, AlertTitle, Button, CircularProgress } from '@mui/material';

import ExtendedRJSForm from '../RJSF/ExtendedRJSForm';
import TourDetailsContext from '../TourDetailsContext';

interface Props {
  tourId: string;
}

export default function TourRootDetailsForm(props: Props) {
  const { tourId } = props;
  const { setTourDetails } = useContext(TourDetailsContext);
  const { enqueueSnackbar } = useSnackbar();

  const { fetchRequestInstance, fetch, patchRequestInstance, patch } = useTourDetailsFormQuery(
    TourDetailsFormResources.ROOT,
  );

  useEffect(() => {
    fetch(tourId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId]);

  useEffect(() => {
    if (fetchRequestInstance.status === 'succeeded') {
      const { id, title, vendorId } = fetchRequestInstance.result.formData.tour;
      setTourDetails({ id, title, vendorId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRequestInstance]);

  const handleSubmission = useCallback<ComponentProps<typeof ExtendedRJSForm>['onSubmit']>(
    ({ formData }) => {
      patch(tourId, formData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourId],
  );

  useEffect(() => {
    switch (patchRequestInstance.status) {
      case 'succeeded':
        enqueueSnackbar('Tour details updated successfully.', {
          variant: 'success',
        });
        break;
      case 'failed':
        enqueueSnackbar(`Submission failed! ${patchRequestInstance.error}`, {
          variant: 'error',
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequestInstance]);

  if (fetchRequestInstance.status === 'pending' || fetchRequestInstance.status === 'uninitiated') {
    return <CircularProgress />;
  }

  if (fetchRequestInstance.status === 'failed') {
    return (
      <Alert
        severity="error"
        action={
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              fetch(tourId);
            }}
          >
            Retry
          </Button>
        }
      >
        <AlertTitle>Failed to fetch the form.</AlertTitle>
        {fetchRequestInstance.error}
      </Alert>
    );
  }

  const { result } = fetchRequestInstance;
  return (
    <>
      <ExtendedRJSForm
        id="tour-details-form"
        schema={result.schema}
        uiSchema={result.uiSchema}
        formData={result.formData}
        onSubmit={handleSubmission}
        busy={patchRequestInstance.status === 'pending'}
      />
      {patchRequestInstance.status === 'failed' && <Alert severity="error">{patchRequestInstance.error}</Alert>}
    </>
  );
}
