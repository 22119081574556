import React from 'react';

import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import { formatDateSlashes } from '~/services/TimeService';

import { daysOfWeekMessage } from '~/utils/daysOfWeek';

const roomRateLink = (vendorId, propertyId, roomTypeId, roomRateId) => {
  return `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}`;
};

interface Props {
  selectedSurcharges: Array<Reservation.PresentedSalesforceSurchargePeriod>;
  selectedRoomRates: Array<{
    propertyId: string;
    uniqueId: string;
    roomType: App.RoomType;
    roomRate: App.RoomRate;
  }>;
  conflictingSurcharges: Array<string>;
  conflictingRoomRates: Array<string>;
  propertyId: string;
  vendorId: string;
  setStep: (step) => void;
}

export function SalesforceSurchargesSummary({
  selectedSurcharges,
  selectedRoomRates,
  conflictingSurcharges,
  conflictingRoomRates,
  propertyId,
  vendorId,
  setStep,
}: Props) {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid
        container
        spacing={3}
        sx={{
          maxHeight: 395,
          overflow: 'auto',
          mb: 0,
        }}
      >
        <Grid item xs>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple dense table" size="small">
                <TableHead
                  sx={{
                    bgcolor: 'secondary.dark',
                    '& th': {
                      bgcolor: 'inherit',
                      color: 'white',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>
                      Salesforce surcharge
                      <Button size="small" variant="text" color="primary" onClick={() => setStep(1)}>
                        Reselect
                      </Button>
                    </TableCell>
                    <TableCell align="right">Range</TableCell>
                    <TableCell align="right">Days of week</TableCell>
                    <TableCell align="right">Cost price</TableCell>
                    <TableCell align="right">Sell price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSurcharges.map((surcharge) => (
                    <TableRow
                      key={surcharge.name}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        bgcolor: conflictingSurcharges.includes(surcharge.id) ? 'error.dark' : undefined,
                        '& td': {
                          color: conflictingSurcharges.includes(surcharge.id) ? 'white' : undefined,
                        },
                      }}
                    >
                      <TableCell scope="row" title={surcharge.name}>
                        {surcharge.name}
                      </TableCell>
                      <TableCell align="right">
                        {formatDateSlashes(surcharge.start_date)} {'->'}
                        {formatDateSlashes(surcharge.end_date)}
                      </TableCell>
                      <TableCell align="right">{daysOfWeekMessage(surcharge)}</TableCell>
                      <TableCell align="right">
                        {surcharge.currency_code} {surcharge.cost_price}
                      </TableCell>
                      <TableCell align="right">{surcharge.sell_price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple dense table" size="small">
                <TableHead
                  sx={{
                    bgcolor: 'secondary.dark',
                    '& th': {
                      bgcolor: 'inherit',
                      color: 'white',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>
                      Room Rate
                      <Button size="small" variant="text" color="primary" onClick={() => setStep(2)}>
                        Reselect
                      </Button>
                    </TableCell>
                    <TableCell align="right">Rate Plan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRoomRates.map((roomRate) => (
                    <TableRow
                      key={roomRate.uniqueId}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        bgcolor: conflictingRoomRates.includes(roomRate.roomRate.id) ? 'error.dark' : undefined,
                        '& td': {
                          color: conflictingRoomRates.includes(roomRate.roomRate.id) ? 'white' : undefined,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Link
                          href={roomRateLink(vendorId, propertyId, roomRate.roomType.id, roomRate.roomRate.id)}
                          target="_blank"
                        >
                          {roomRate.roomType.name}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{roomRate.roomRate.rate_plan.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        {!!conflictingSurcharges?.length && (
          <Grid item xs>
            <Alert sx={{ mt: 1 }} severity="error">
              A room rate has an existing surcharge with clashing dates. Please check room rates.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
