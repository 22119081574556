import React, { useCallback, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Button, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import VimeoWidget from '~/components/Common/Forms/widgets/VimeoWidget';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

import { formatError } from '~/utils/errorFormatter';

import cleanProperty from './helpers/cleanProperty';

const fetchProperty = (propertyId) => {
  return ReservationService.getProperty(propertyId);
};

export default function VideosEditPage(props) {
  const { id_property: propertyId, id_vendor: vendorId } = useParams<{ id_property: string; id_vendor: string }>();

  const [property, setProperty] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [leVimeoId, setLeVimeoId] = React.useState(null);
  const [whitelabelVimeoId, setWhitelabelVimeoId] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    fetchProperty(propertyId)
      .then((propFetch) => {
        setProperty(cleanProperty(propFetch.result));
        setLeVimeoId(propFetch.result.videos.luxuryescapes?.vimeo_id ?? '');
        setWhitelabelVimeoId(propFetch.result.videos.whitelabel?.vimeo_id ?? '');
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  }, [enqueueSnackbar, propertyId]);

  const doUpdate = useCallback(async () => {
    try {
      setIsSaving(true);
      await ReservationService.updateProperty(
        {
          ...property,
          videos: {
            luxuryescapes: {
              vimeo_id: leVimeoId || '',
            },
            whitelabel: {
              vimeo_id: whitelabelVimeoId || '',
            },
          },
        },
        property.id,
      );
      setIsSaving(false);
      enqueueSnackbar('Saved', {
        variant: 'success',
      });
    } catch (err) {
      setIsSaving(false);
      enqueueSnackbar(formatError(err), {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, leVimeoId, property, whitelabelVimeoId]);

  return (
    <Container maxWidth="xl">
      <PageHeader title="Edit property videos" backButton={`/vendors/${vendorId}/properties/${propertyId}`} />
      {loading && <div>Loading...</div>}
      {!loading && (
        <>
          <h3>Vimeo video (Luxury Escapes)</h3>
          <VimeoWidget
            value={leVimeoId}
            onChange={setLeVimeoId}
            previousValue={property.videos?.luxuryescapes?.vimeo_id}
          />

          <h3>Vimeo video (Whitelabel Escapes)</h3>
          <VimeoWidget
            value={whitelabelVimeoId}
            onChange={setWhitelabelVimeoId}
            previousValue={property.videos?.whitelabel?.vimeo_id}
          />

          <Button sx={{ width: 100 }} onClick={doUpdate} variant="contained">
            {isSaving ? <Spinner size={20} /> : 'Save'}
          </Button>
        </>
      )}
    </Container>
  );
}
