import React, { forwardRef, useRef } from 'react';

import { Checkbox, Stack, Typography } from '@mui/material';

interface Props {
  isApprovedForTpfmInitial: boolean;
}

enum SCHEDULE_TPFM_INPUT_NAMES {
  CHECKBOX_NAME = 'checkbox_name',
}

export function parseScheduleTpfmFormData(
  formData: FormData,
): Pick<CustomerCommunication.UnsavedHeroPlannerSchedule, 'isApprovedForTpfm'> {
  const isChecked = String(formData.get(SCHEDULE_TPFM_INPUT_NAMES.CHECKBOX_NAME)) === 'on';
  return {
    isApprovedForTpfm: isChecked,
  };
}

const ScheduleTpfmForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { isApprovedForTpfmInitial } = props;
  const checkboxRef = useRef<HTMLInputElement>(null);

  return (
    <Stack component="form" ref={ref} direction="row" gap={1} position="relative" flexGrow="1" alignItems="center">
      <Checkbox
        ref={checkboxRef}
        defaultChecked={isApprovedForTpfmInitial}
        name={SCHEDULE_TPFM_INPUT_NAMES.CHECKBOX_NAME}
        size="large"
      />
      <Typography variant="h4">Include Hero Deal In TPFM Email</Typography>
    </Stack>
  );
});

export default ScheduleTpfmForm;
