import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import { camelCaseToSpaceSeparated } from '~/utils/stringUtils';

import { cloneFormData } from './formUtils';

const DIALOG_TRANSITION_PROPS: ComponentProps<typeof Dialog>['TransitionProps'] = { unmountOnExit: true };

export interface ArrayItemCopyDefinition {
  currentItemIndex: number;
  fieldDepth: string[];
  itemTitleProperty?: string;
  uncopyableProperties?: string[];
}

interface Props<T> {
  formData: T;
  copyDefinition: ArrayItemCopyDefinition;
  onCopyAndReplace: (fieldDepth: string[], item: unknown) => void;
  onDismiss: () => void;
}

export default function ArrayItemCopyAndReplaceModal<T>(props: Props<T>) {
  const { formData, copyDefinition, onCopyAndReplace, onDismiss } = props;
  const [copyTarget, setCopyTarget] = useState<string>('');

  const { copyOptions, itemTitle, parentInstance, parentArray } = useMemo(() => {
    if (!copyDefinition) return {};

    const { currentItemIndex, fieldDepth, itemTitleProperty } = copyDefinition;

    const itemType = fieldDepth[fieldDepth.length - 1];
    const itemTitle = camelCaseToSpaceSeparated(itemType).toLowerCase();
    const currentParentIndex = parseInt(fieldDepth[fieldDepth.length - 2]) ?? 0;
    const parentType = fieldDepth[fieldDepth.length - 3];
    const parentTypeTitle = camelCaseToSpaceSeparated(parentType).toLowerCase();

    const parentInstance: unknown[] = get(formData, fieldDepth.slice(0, fieldDepth.length - 2));
    const parentArray: unknown[] = get(formData, fieldDepth);
    let copyOptions;
    if (parentInstance) {
      copyOptions = parentInstance.map((parent, parentIndex) => {
        const options = parent[itemType].map((item, index) => {
          let label = `${itemTitle} ${index + 1}`;
          const title: string | undefined = item[itemTitleProperty];
          if (title) {
            label += ` [${title}]`;
          }
          const isCurrent = index === currentItemIndex && parentIndex === currentParentIndex;
          if (isCurrent) {
            label += ' [CURRENT]';
          }
          return {
            disabled: isCurrent,
            label,
            value: `${parentIndex}-${index}`,
          };
        });
        return {
          title: parent[itemTitleProperty] ?? `${parentTypeTitle} ${parentIndex}`,
          currentParentIndex,
          options,
        };
      });
    } else {
      copyOptions = [
        {
          title: `${itemTitle}`,
          currentParentIndex: 0,
          options: parentArray.map((item, index) => {
            let label = `${itemTitle} ${index + 1}`;
            const title: string | undefined = item[itemTitleProperty];
            if (title) {
              label += ` [${title}]`;
            }
            const isCurrent = index === currentItemIndex;
            if (isCurrent) {
              label += ' [CURRENT]';
            }
            return {
              disabled: isCurrent,
              label,
              value: `0-${index}`,
            };
          }),
        },
      ];
    }

    return { copyOptions, itemTitle, parentInstance, parentArray };
  }, [copyDefinition, formData]);

  const handleRadioChange = useCallback<ComponentProps<typeof RadioGroup>['onChange']>((e) => {
    setCopyTarget(e.target.value);
  }, []);

  const handleCopyAndReplace = useCallback(() => {
    const { fieldDepth, currentItemIndex } = copyDefinition;
    const itemProperty = fieldDepth[fieldDepth.length - 1];
    const [parentTarget, target] = copyTarget.split('-');
    const currentItem = parentArray[currentItemIndex];
    const targetItem = parentInstance ? parentInstance[parentTarget][itemProperty][target] : parentArray[target];
    const targetItemCopy = cloneFormData(targetItem);
    copyDefinition.uncopyableProperties.forEach((property) => {
      targetItemCopy[property] = currentItem[property];
    });
    onCopyAndReplace([...copyDefinition.fieldDepth, String(currentItemIndex)], targetItemCopy);
  }, [copyDefinition, copyTarget, onCopyAndReplace, parentArray, parentInstance]);

  useEffect(() => {
    setCopyTarget('');
  }, [copyDefinition]);

  if (!copyDefinition) {
    return null;
  }

  return (
    <Dialog open={!!copyDefinition} TransitionProps={DIALOG_TRANSITION_PROPS} onClose={onDismiss}>
      <DialogTitle>
        Replacing{' '}
        <Typography color="primary" variant="inherit" component="span">
          {itemTitle} {copyDefinition.currentItemIndex + 1}
        </Typography>{' '}
        with:
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup value={copyTarget} onChange={handleRadioChange}>
          {copyOptions.map(
            (parentOptions, index) =>
              parentOptions.options.length && (
                <Accordion defaultExpanded={parentOptions.currentParentIndex === index} key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{parentOptions.title}</AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="column">
                      {parentOptions.options.map((itemOption) => (
                        <FormControlLabel
                          key={`item-${itemOption.value}`}
                          value={itemOption.value}
                          disabled={itemOption.disabled}
                          control={<Radio />}
                          label={itemOption.label}
                        />
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ),
          )}
          {copyOptions.every((parentOption) => parentOption.options.length === 0) && (
            <Alert severity="warning">No sibling available</Alert>
          )}
        </RadioGroup>
        {!!copyDefinition?.uncopyableProperties?.length && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="caption">These properties won't be copied:</Typography>
              {copyDefinition.uncopyableProperties.map((property, index) => (
                <Chip key={`${property}-${index}`} size="small" variant="outlined" label={property} />
              ))}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Dismiss</Button>
        <Button variant="contained" disabled={!copyTarget} onClick={handleCopyAndReplace}>
          Copy and replace
        </Button>
      </DialogActions>
    </Dialog>
  );
}
