/**
 * group by schedules by their Start and End and returns an object with regions and Start  and End
 * @param {schedules, type}
 * @returns {object}
 */
import { brands, isMultiRegionSchedule } from '~/consts/brands';

import { groupBy } from './groupBy';

export const groupRegionsBySchedule = (schedules, type) => {
  const filteredList = schedules.filter((r) => r.type === type && isMultiRegionSchedule(r.brand));

  const listWithMergedStartEnd = filteredList.map((item) =>
    Object.assign(
      {
        startAndEnd: `${item.start}${item.end}`,
      },
      item,
    ),
  );

  const groupByStartAndEnd = groupBy('startAndEnd');
  const groupedByObj = groupByStartAndEnd(listWithMergedStartEnd);

  let scheduleRowObj = [];
  // this is currently Luxury Escapes and LE Agent Hub
  let brandsToDisplayRegionsFor = brands.filter((brand) => isMultiRegionSchedule(brand.value));
  for (const i in groupedByObj) {
    const group = groupedByObj[i];
    brandsToDisplayRegionsFor.forEach((brand) => {
      const filteredGroupByBrand = group.filter((innerItem) => {
        return innerItem.brand === brand.value;
      });
      if (filteredGroupByBrand.length > 0) {
        const first = filteredGroupByBrand[0];
        scheduleRowObj.push({
          brand: brand.title,
          offerId: first.offer_id_salesforce_external,
          id: first.id,
          idList: filteredGroupByBrand.map((el) => el.id),
          start: first.start,
          end: first.end,
          regionsObj: filteredGroupByBrand.map((el) => {
            return { region: el.region, id: el.id };
          }),
          regions: filteredGroupByBrand.map((el) => el.region),
        });
      }
    });
  }
  return scheduleRowObj;
};
