import React from 'react';

import pluralize from 'pluralize';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Alert, Typography } from '@mui/material';

import LedOffersService from '~/services/LedOffersService';
import OffersService from '~/services/OffersService';
import VendorsService from '~/services/VendorsService';

import { addQuery, parseSearchString } from '~/utils/url';

import OfferList from './OfferList';
import Spinner from './Spinner';

const FINANCE_PATH = '/finance';
const OFFERS_PATH = '/offers';

type Props = RouteComponentProps & {
  searchString: string;
  ledExperience?: boolean;
  showCountMessage?: boolean;
  urlBuilder?: (row: App.Offer) => string;
  onRowClick?: (row: App.Offer) => void;
};

type State = {
  loading: boolean;
  offerData: { total: number; result: App.Offer[] };
  error: string;
  page: number;
};

class OfferListContainer extends React.Component<Props, State> {
  sizePerPage: number;
  isOffersPage: boolean;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      offerData: null,
      error: '',
      page: this.currentPage(),
    };

    this.sizePerPage = 10;
    this.isOffersPage = location.pathname === OFFERS_PATH;
  }

  currentPage = () => {
    const { location } = this.props;
    const { page } = parseSearchString(location.search);
    return parseInt(page as string) || 1;
  };

  onPageChange = (page: number) => {
    this.setState({ page }, () => {
      const { location, history } = this.props;
      const newLocation = addQuery(location, { page });
      history.push(newLocation);
    });
  };

  updateOffers(searchString, ledExperience) {
    this.setState({ offerData: null, loading: true, error: '' }, () => {
      const service = ledExperience ? LedOffersService : OffersService;

      service
        .getFilterOffers({
          page: this.state.page,
          limit: this.sizePerPage,
          queryString: searchString,
          filter: location.pathname === FINANCE_PATH ?? 'finance',
        })
        .then((offers) => {
          const offersData = offers;

          if (!this.isOffersPage || !offersData.total) {
            this.setState({ offerData: offers, loading: false });
          }

          const vendorIds = offers.result.map((x) => x.vendor_account_id).join(',');

          if (!vendorIds) {
            return;
          }

          VendorsService.getVendorsByIds(vendorIds).then((vendors) => {
            const vendorsMap = new Map();

            vendors.result.forEach((vendor) => vendorsMap.set(vendor.sfid, vendor.name));

            offersData.result = offers.result.map((offer) =>
              Object.assign(offer, {
                vendor_name: vendorsMap.get(offer.vendor_account_id) ?? '-',
              }),
            );

            this.setState({ offerData: offersData, loading: false });
          });
        })
        .catch((error) => {
          this.setState({ error: error.message, loading: false });
        });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchString !== this.props.searchString || prevState.page !== this.state.page) {
      this.updateOffers(this.props.searchString, this.props.ledExperience);
    }
  }

  render() {
    const errorMessage = this.state.error;

    if (this.state.loading) {
      return <Spinner />;
    }

    if (!this.state.offerData) {
      return null;
    }

    const offerPluralized = pluralize('offer', this.state.offerData.total, true);

    const paginationCount = `${offerPluralized} found by your search criteria`;

    return (
      <>
        {this.props.showCountMessage && <Typography variant="h4">{paginationCount}</Typography>}

        {errorMessage && (
          <Alert severity="error" onClose={() => this.setState({ error: '' })}>
            {errorMessage}
          </Alert>
        )}

        <OfferList
          sizePerPage={this.sizePerPage}
          offers={this.state.offerData.result}
          total={this.state.offerData.total}
          page={this.state.page}
          isOffersPage={this.isOffersPage}
          urlBuilder={this.props.urlBuilder}
          onRowClick={this.props.onRowClick}
          onPageChange={this.onPageChange}
        />
      </>
    );
  }
}

export default withRouter(OfferListContainer);
