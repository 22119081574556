import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

import ErrorDisplay from '../ErrorDisplay';

function validateForm(formData, errors) {
  if (typeof formData.dateOfPayment === 'undefined') {
    errors.dateOfPayment.addError('Please enter card issue date');
  }

  if (typeof formData.contractsGeo === 'undefined') {
    errors.contractsGeo.addError('Please select an Entity Region');
  }

  return errors;
}

const contractsGeoOptions = ['Australia', 'International', 'New Zealand'];
const VCCPaymentMethodOptions = ['VCC(Citibank)', 'Gross Payment VCC'];

const formSchema: RJSFSchema = {
  description: 'Please enter a card issue date and select the LE Entity Region to generate an VCC file',
  type: 'object',
  properties: {
    dateOfPayment: {
      title: 'Card Issue Date',
      type: 'string',
      format: 'date',
    },
    paymentMethod: {
      title: 'VCC Payment Method',
      type: 'string',
      enum: VCCPaymentMethodOptions,
    },
    contractsGeo: {
      title: 'LE Entity Region',
      type: 'string',
      enum: contractsGeoOptions,
    },
    fileFormat: {
      title: 'File Format',
      type: 'string',
      enum: ['csv', 'xlsx', 'xml'],
    },
  },
};

const uiSchema = {
  dateOfPayment: {
    'ui:widget': 'date',
  },
  paymentMethod: {
    'ui:widget': 'radio',
  },
  fileFormat: {
    'ui:widget': 'radio',
  },
};

export default function VCCFileForm() {
  const [error, setError] = useState(null);
  const [comment, setComment] = useState(null);

  const resetMessageState = () => {
    setError(null);
    setComment(null);
  };

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setComment('Downloading VCC File...');

      await VendorsService.downloadVCCFile(
        event.formData.dateOfPayment,
        event.formData.paymentMethod,
        event.formData.contractsGeo,
        event.formData.fileFormat,
      );

      resetMessageState();
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Download VCC File
      </Button>
      {error && <ErrorDisplay message={error} />}

      {comment && (
        <Alert severity="info">
          <p>{comment}</p>
        </Alert>
      )}
    </Form>
  );
}
