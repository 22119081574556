import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import SearchService, { ANYWHERE_PLACE_ID } from '~/services/SearchService';

import HealthCheckRow from './HealthCheckRow';

export type EndpointStatus = 'loading' | 'success' | 'failed';

export interface PositionAndStatus {
  position: number | 'N/A';
  status: EndpointStatus;
}

const DEFAULT_STATE: PositionAndStatus = {
  position: 'N/A',
  status: 'loading',
};

const FAILED_STATE: PositionAndStatus = {
  position: 'N/A',
  status: 'failed',
};

interface Props {
  propertyIds: Array<string>;
  propertyName: string;
  offerId: string;
}

export default function SearchHealthCheck({ propertyIds, propertyName, offerId }: Props) {
  const regionCodes = libRegions.getRegions().map((region) => region.code);
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAvailableInTypeahead, setIsAvailableInTypeahead] = useState<PositionAndStatus>(DEFAULT_STATE);
  const [isAvailableInPropertySearch, setIsAvailableInPropertySearch] = useState<PositionAndStatus>(DEFAULT_STATE);
  const [isAvailableInSearchListAnywhere, setIsAvailableInSearchListAnywhere] =
    useState<PositionAndStatus>(DEFAULT_STATE);
  const [isAvailableInHomepage, setIsAvailableInHomepage] = useState<PositionAndStatus>(DEFAULT_STATE);
  const [regionCode, setRegionCode] = useState(regionCodes[0]);

  const fetchProperty = useCallback(async () => {
    let isAvailable = true;
    setIsAvailableInPropertySearch(DEFAULT_STATE);
    try {
      for (const propertyId of propertyIds) {
        const response = await SearchService.getLeProperty(propertyId);
        if (!response.result) {
          isAvailable = false;
          break;
        }
      }
    } catch (error) {
      enqueueSnackbar(`Failed to fetch response from property search: ${error.message}`, { variant: 'error' });
    }
    if (isAvailable) {
      setIsAvailableInPropertySearch({ position: 1, status: 'success' });
    } else {
      setIsAvailableInPropertySearch(FAILED_STATE);
    }
  }, [enqueueSnackbar, propertyIds]);

  const fetchTypeahead = useCallback(async () => {
    setIsAvailableInTypeahead(DEFAULT_STATE);
    try {
      const response = await SearchService.typeahead(propertyName, {
        type: ['le_property', 'le_property_unique_stays'] as ('le_property' | 'le_property_unique_stays')[],
        brand: 'luxuryescapes',
        region: regionCode as any,
        limit: 20,
        priority: 'current',
      });
      const results = response.result.results;
      const offerIndex = results.findIndex(
        (result) => 'offer_id' in result.metadata && result.metadata.offer_id === offerId,
      );
      if (offerIndex > -1) {
        setIsAvailableInTypeahead({ position: offerIndex + 1, status: 'success' });
      } else {
        setIsAvailableInTypeahead(FAILED_STATE);
      }
    } catch (error) {
      enqueueSnackbar(`Failed to fetch response from typeahead: ${error.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, offerId, propertyName, regionCode]);

  const fetchSearchList = useCallback(async () => {
    setIsAvailableInSearchListAnywhere(DEFAULT_STATE);
    try {
      const response = await SearchService.getHotelOffers({ region: regionCode });
      const offerIndex = response.result.findIndex((result) => result.id === offerId);
      if (offerIndex > -1) {
        setIsAvailableInSearchListAnywhere({ position: offerIndex + 1, status: 'success' });
      } else {
        setIsAvailableInSearchListAnywhere(FAILED_STATE);
      }
    } catch (error) {
      enqueueSnackbar(`Failed to fetch response from search list: ${error.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, offerId, regionCode]);

  const fetchHomepage = useCallback(async () => {
    setIsAvailableInHomepage(DEFAULT_STATE);
    try {
      const response = await SearchService.getAllUnifiedOffers(regionCode, ANYWHERE_PLACE_ID, 'default');
      const offerIndex = response.results.findIndex((result) => result.bk === offerId);
      if (offerIndex > -1) {
        setIsAvailableInHomepage({ position: offerIndex + 1, status: 'success' });
      } else {
        setIsAvailableInHomepage(FAILED_STATE);
      }
    } catch (error) {
      enqueueSnackbar(`Failed to fetch response from unified endpoint: ${error.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, offerId, regionCode]);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      fetchProperty();
      fetchTypeahead();
      fetchSearchList();
      fetchHomepage();
    }
  }, [fetchHomepage, fetchProperty, fetchSearchList, fetchTypeahead, isModalOpen, propertyIds, regionCode]);

  const onClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  return (
    <>
      <Dialog open={isModalOpen} onClose={onClose}>
        <Box padding={4}>
          <Box display="flex" justifyContent="space-between">
            <Select
              labelId="offer-search-ordering-region-selector-label"
              id="offer-search-ordering-region-selector"
              defaultValue={regionCode === undefined ? regionCodes[0] : regionCodes.find((code) => code === regionCode)}
              value={regionCode}
              onChange={(event: SelectChangeEvent) => setRegionCode(event.target.value)}
            >
              {regionCodes
                .map((code) => {
                  return <MenuItem key={code} value={code}>{`${code}`}</MenuItem>;
                })
                .sort((a, b) => a.props.value.localeCompare(b.props.value))}
            </Select>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <h2>Search Health Checks</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Health check</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Position</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <HealthCheckRow
                position={isAvailableInPropertySearch.position}
                status={isAvailableInPropertySearch.status}
                title="Property Search"
                tooltipText='"Does it appear in the results when doing a property search?"'
              />
              <HealthCheckRow
                position={isAvailableInTypeahead.position}
                status={isAvailableInTypeahead.status}
                title="Typeahead"
                tooltipText='"Does it appear in the typeahead when searched by name?"'
              />
              <HealthCheckRow
                position={isAvailableInSearchListAnywhere.position}
                status={isAvailableInSearchListAnywhere.status}
                title="Search List (Anywhere)"
                tooltipText='"Does it appear in the results of the Anywhere search list?"'
              />
              <HealthCheckRow
                position={isAvailableInHomepage.position}
                status={isAvailableInHomepage.status}
                title="Homepage"
                tooltipText='"Does it appear in the results of the homepage list?"'
              />
            </TableBody>
          </Table>
        </Box>
      </Dialog>
      <h2 className="page-header">Search Health Check</h2>
      <Button onClick={onClick}>Run Health Check</Button>
    </>
  );
}
