import React, { useState } from 'react';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Order } from '@luxuryescapes/contract-svc-order';
import { CancellationPolicy, formatDate } from '@luxuryescapes/lib-refunds';

interface Props {
  room: Order.BedbankItemRoom;
  cancellationPolicy: CancellationPolicy;
  propertyTimezone: string;
}

const CancellationPolicies: React.FC<Props> = ({ room, cancellationPolicy, propertyTimezone }) => {
  const [showPartialRefundDetails, setShowPartialRefundDetails] = useState(false);
  if (room.refundable) {
    return (
      <Stack direction="row" spacing={1}>
        <CurrencyExchangeIcon color="success" />
        <Typography sx={{ color: 'green' }} component="div">
          Free cancellation{' '}
          {!!cancellationPolicy.policies.length && (
            <Typography sx={{ color: grey[800] }} component="span">
              until {formatDate.formattedDateByTimezone(cancellationPolicy.policies[0]?.start, propertyTimezone)} (local
              time of property)
            </Typography>
          )}
        </Typography>
      </Stack>
    );
  }
  if (room.partially_refundable) {
    return (
      <>
        <Stack direction="row" spacing={1}>
          <CurrencyExchangeIcon color="success" />
          <Typography sx={{ color: 'green' }} component="div">
            Partially Refundable
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Button
            sx={{ color: grey[800], fontSize: 'sm' }}
            onClick={() => setShowPartialRefundDetails((state) => !state)}
          >
            {showPartialRefundDetails ? 'Hide Details' : 'Show Details'}
            {showPartialRefundDetails ? (
              <ExpandLessIcon sx={{ color: grey[800] }} />
            ) : (
              <ExpandMoreIcon sx={{ color: grey[800] }} />
            )}
          </Button>
          {showPartialRefundDetails && !!cancellationPolicy.description.length && (
            <span style={{ color: '#333333' }}>
              <ul>
                {cancellationPolicy.description.map((description, i) => (
                  <li key={i}>{description}</li>
                ))}
              </ul>
            </span>
          )}
        </Stack>
      </>
    );
  }

  if (!room.partially_refundable && !room.refundable) {
    return (
      <Stack direction="row" spacing={1}>
        <CurrencyExchangeIcon color="error" />
        <Typography sx={{ color: 'red' }} component="div">
          Non-refundable
        </Typography>
      </Stack>
    );
  }

  return null;
};

export default CancellationPolicies;
