import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Alert, Button } from '@mui/material';

import { downloadTravellersWithMissingDetails } from '~/services/ReportingService';
import { formatDateISO } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please specify file format',
  type: 'object',
  properties: {
    fileFormat: {
      title: 'File Format',
      type: 'string',
      enum: ['csv', 'email'],
    },
  },
};

const uiSchema = {
  fileFormat: {
    'ui:widget': 'radio',
  },
};

export default function ExportTravellersReportForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { fileFormat } = event.formData;

    const today = formatDateISO();
    const fileName = `TravellersWithMissingDetailsReport-_${today}.csv`;

    downloadTravellersWithMissingDetails({ fileFormat })
      .then(function (text) {
        if (fileFormat !== 'email') {
          fileDownload(text, fileName);
        } else {
          setSuccessMessage(true);
        }
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Download Travellers With Missing Details Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
