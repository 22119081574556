import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';

import Image from '../../Common/Image';

export default class BadgesPane extends React.Component {
  render() {
    let offer = this.props.offer.result;

    return (
      <span>
        <h1>
          <Link to={'/edit-offers/' + offer.id_salesforce_external + '/badges'}>
            <ModeEditTwoToneIcon fontSize="large" />
            <span className="sr-only">Edit</span>
          </Link>
        </h1>
        <div className="badges-list">
          {offer.badges.map((badge, index) => (
            <div className="badge-tile" key={`${badge.name}-${index}`}>
              <div className="cover">
                <Image className="img-responsive img-thumbnail" publicId={badge.image} options={{ width: 130 }} />
              </div>
              <div>{badge.name}</div>
            </div>
          ))}
        </div>
      </span>
    );
  }
}
