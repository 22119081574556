import React from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';

import { Button, Stack } from '@mui/material';

import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import ConfirmButton from '../Elements/ConfirmButton';

import { buttonMessages, buttonStates } from './states/submitButton';

const uiSchema = {
  content: {
    'ui:widget': 'textarea',
    'ui:title': 'Review Content',
  },
  source: {
    'ui:title': 'Source',
    'ui:placeholder': 'ex. TripAdvisor',
  },
};

export default class TourReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDeleteReview = this.onDeleteReview.bind(this);
    this.state = {
      tour: props.tour,
      reviewData: props.reviewData,
      saveState: buttonStates.default,
    };
  }

  onSubmit(form) {
    this.setState({
      saveState: buttonStates.saving,
      reviewData: form.formData,
    });

    const tourId = this.state.tour.id;

    let submitType = 'createTourReview';
    let reviewId = null;
    if (this.state.reviewData.id) {
      submitType = 'updateTourReview';
      reviewId = this.state.reviewData.id;
    }

    ReservationService[submitType](form.formData, tourId, reviewId)
      .then((response) => {
        this.setState({
          reviewData: response.result,
          saveState: buttonStates.saved,
        });
      })
      .catch((e) => {
        this.setState({ saveState: buttonStates.failed });
        reportError(e);
      });
  }

  onChange(edit) {
    this.setState({
      reviewData: edit.formData,
      saveState: buttonStates.default,
    });
  }

  onDeleteReview() {
    ReservationService.deleteTourReview(this.state.tour.id, this.state.reviewData.id)
      .then(() => {
        this.props.onRemoveTourReview(this.props.formKey);
        this.props.reRender();
      })
      .catch((e) => {
        reportError(e);
      });
  }

  render() {
    return (
      <div>
        <Form
          schema={this.props.schema}
          formData={this.state.reviewData}
          uiSchema={uiSchema}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          validator={validator}
        >
          <Stack direction="row" spacing={1} mt={1}>
            <Button type="submit" variant="contained">
              {buttonMessages[this.state.saveState]}
            </Button>

            {this.state.reviewData.id ? (
              <ConfirmButton
                confirmTitle="Delete review"
                confirmQuestion="Are you sure you want to delete this review?"
                confirmAnswer="Yes, delete"
                onConfirm={this.onDeleteReview}
              >
                Delete
              </ConfirmButton>
            ) : null}
          </Stack>
        </Form>
      </div>
    );
  }
}
