import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { DeleteOutline } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';

import OfferBox from './OfferBox';
import { OrderListItemWithSortId } from './SortableOfferListOrder';

const OverlayContainerStyles = {
  position: 'relative',
};

interface Props {
  index: number;
  offer: Exclude<OrderListItemWithSortId, { vertical: 'ev' }>;
  onRemove: () => void;
  region: string;
}

function SortableOfferBox({ offer, onRemove, region, index }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
    id: offer.id,
  });

  const style = {
    width: '100%',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '2px',
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid container ref={setNodeRef} style={style} {...attributes} alignItems="center" borderColor="grey.500">
      <Grid item xs ref={setActivatorNodeRef} {...listeners}>
        <Box sx={OverlayContainerStyles}>
          <Box sx={{ opacity: 0.8 }}>
            <OfferBox offer={offer} region={region} index={index} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs="auto">
        <IconButton size="large" onClick={onRemove}>
          <DeleteOutline />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default SortableOfferBox;
