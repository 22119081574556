import React from 'react';

import ExportTTCPayableForm from '../Common/Forms/ExportTTCPayableForm';
import ExportTTCPaymentsReportForm from '../Common/Forms/ExportTTCPaymentsReportForm';
import ExportTourPurchasesReportForm from '../Common/Forms/ExportTourPurchasesReportForm';
import ExportTourRefundReportForm from '../Common/Forms/ExportTourRefundReportForm';

import ContentWithSidebar from './ContentWithSidebar';
import UploadTTCPayments from './UploadTTCPayments';

const DEFAULT_ITEMS = 'tour-bookings';

const items = [
  {
    title: 'TTC Payments Due report',
    slug: 'tcc-payments-due',
    component: ExportTTCPayableForm,
  },
  {
    title: 'TTC Payments Made report',
    slug: 'tcc-payments-made',
    component: ExportTTCPaymentsReportForm,
  },
  {
    title: 'Upload TTC Payments',
    slug: 'upload-tcc-payments',
    component: UploadTTCPayments,
  },
  {
    title: 'Tour Refunds report',
    slug: 'tour-refunds',
    component: ExportTourRefundReportForm,
  },
  {
    title: 'Tour Bookings report',
    slug: 'tour-bookings',
    component: ExportTourPurchasesReportForm,
  },
];

export default function TourPage() {
  return <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEMS} />;
}
