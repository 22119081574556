import React from 'react';

import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { OPERATION_SET_TRAVELLER_REFUND_STATUS, TRAVELLER_REFUND_STATUS_OPTIONS } from '~/consts/flight';

const FlightRefundStatus = ({
  orderId,
  itemId,
  travellerId,
  travellerRefundStatus = null,
  onUpdateTravellerRefundStatus,
}) => {
  const onSetRefundStatus = React.useCallback((value) => {
    const payload = {
      op: OPERATION_SET_TRAVELLER_REFUND_STATUS,
      data: { refund_status: value, traveller_id: travellerId },
    };
    return onUpdateTravellerRefundStatus(orderId, itemId, payload);
  }, []);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Select onChange={(e) => onSetRefundStatus(e.target.value)} value={travellerRefundStatus}>
        {TRAVELLER_REFUND_STATUS_OPTIONS.map((field) => (
          <MenuItem key={`refund-status-${field}`} value={field}>
            {field}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FlightRefundStatus;
