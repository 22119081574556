import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { downloadLebtCreditReport } from '~/services/ReportingService';
import { addMonths, formatDateISO, startOfMonth } from '~/services/TimeService';

import ErrorDisplay from '../../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    start_at: {
      title: 'Start at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(-1, startOfMonth())),
    },
    end_at: {
      title: 'End at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(0, startOfMonth())),
    },
  },
};

const uiSchema = {
  start: {
    'ui:widget': 'date',
  },
  end: {
    'ui:widget': 'date',
  },
};

export default function ExportLebtCreditForm() {
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { start_at, end_at } = event.formData;
    const fileName = `LEBTCreditReport_${start_at}_${end_at}.csv`;

    downloadLebtCreditReport({ start_at, end_at })
      .then(function (text) {
        fileDownload(text, fileName);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Download LEBT credit report
      </Button>

      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
