import React, { useCallback, useEffect, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';

import * as ReportingService from '~/services/ReportingService';

import { noop } from '~/utils/noop';

import DualDatePicker from './DualDatePicker';
import ProblemPaymentDismissDialog from './ProblemPaymentDismissDialog';

const urlBuilder = (orderItemId: string) => {
  return `https://admin.luxuryescapes.com/purchases/${orderItemId}`;
};

export interface ProblemPaymentsResponse {
  rows: OrderItem[];
}

export interface OrderItem {
  offer_bk: string;
  item_bk: string;
  payment_method: string;
  payable_date: string;
  booking_number: string;
  cost_currency: string;
  paid: number;
  total_payable: number;
  paid_aud: number;
  total_payable_aud: number;
  order_bk: string;
}

function ProblematicPayments() {
  const [orderItems, setOrderItems] = useState<Array<OrderItem>>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orderItemId, setOrderItemId] = useState('');
  const [deletedOrderItemIds, setDeletedOrderItemIds] = useState<Array<string>>([]);
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchData() {
      if (startAt && endAt) {
        try {
          const response = await ReportingService.getVendorPaymentProblems({ start_at: startAt, end_at: endAt });
          setOrderItems(response?.rows);
        } catch (error) {
          enqueueSnackbar(`Failed to fetch: ${error.message}`, { variant: 'error' });
        }
      }
    }
    fetchData();
  }, [endAt, enqueueSnackbar, startAt]);

  const handleDelete = useCallback(
    (e) => {
      const id = e.currentTarget.dataset.id;
      if (deletedOrderItemIds.includes(id)) return noop();
      setOrderItemId(id);
      setDialogOpen(true);
    },
    [deletedOrderItemIds],
  );

  const onClose = useCallback(() => setDialogOpen(false), []);

  const onConfirm = useCallback(() => {
    try {
      ReportingService.postVendorPaymentManualAdjustment({ item_id: orderItemId });
      setDeletedOrderItemIds([...deletedOrderItemIds, orderItemId]);
      setOrderItemId('');
    } catch (error) {
      enqueueSnackbar(`Failed to post: ${error.message}`, { variant: 'error' });
    }
    onClose();
  }, [deletedOrderItemIds, enqueueSnackbar, orderItemId, onClose]);

  return (
    <div>
      <DualDatePicker setStartDate={setStartAt} setEndDate={setEndAt} />
      <ProblemPaymentDismissDialog dialogOpen={dialogOpen} onClose={onClose} onConfirm={onConfirm} />
      <Table>
        <TableHead>
          <TableCell>
            <h3>Booking Number</h3>
          </TableCell>
          <TableCell>
            <h3>Offer BK</h3>
          </TableCell>
          <TableCell>
            <h3>Currency</h3>
          </TableCell>
          <TableCell>
            <h3>Total Payable</h3>
          </TableCell>
          <TableCell>
            <h3>Paid</h3>
          </TableCell>
          <TableCell>
            <h3>Payable Date</h3>
          </TableCell>
          <TableCell>
            <h3>Dismiss</h3>
          </TableCell>
        </TableHead>
        {!!orderItems?.length && (
          <TableBody>
            {orderItems?.map((item) => {
              return (
                <TableRow
                  key={item.item_bk}
                  style={{ textDecoration: deletedOrderItemIds.includes(item.item_bk) ? 'line-through' : 'none' }}
                >
                  <TableCell>
                    <Link target="_blank" href={urlBuilder(item.order_bk)}>
                      {item.booking_number}
                    </Link>
                  </TableCell>
                  <TableCell>{item.offer_bk}</TableCell>
                  <TableCell>{item.cost_currency}</TableCell>
                  <TableCell>
                    {currencyFormatter.format(item.total_payable_aud, {
                      code: item.cost_currency,
                    })}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(item.paid_aud, {
                      code: item.cost_currency,
                    })}
                  </TableCell>
                  <TableCell>{item.payable_date}</TableCell>
                  <TableCell>
                    <Tooltip title={deletedOrderItemIds.includes(item.item_bk) ? 'Already deleted' : 'Delete'}>
                      <IconButton onClick={handleDelete} data-id={item.item_bk}>
                        <DeleteIcon
                          fontSize="medium"
                          color={deletedOrderItemIds.includes(item.item_bk) ? 'disabled' : 'action'}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

export default ProblematicPayments;
