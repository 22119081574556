import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import { DataFeedDiffs } from './constants';

const FULL_DESCRIPTION_KEYS = ['returnPortId', 'departurePortId', 'shipId', 'destinationId'];

export const formatDataFeedDiffs = (diffs: CruisesContract.DataFeedDiffsByDepartureIdResponse): DataFeedDiffs[] => {
  return diffs.core
    .map((diff) => {
      let dataFeedContent = '';
      let databaseContent = '';

      if (FULL_DESCRIPTION_KEYS.includes(diff.key)) {
        dataFeedContent = `${diff.new.value} (${diff.new.name})`;
        databaseContent = `${diff.current.value} (${diff.current.name})`;
      } else {
        dataFeedContent = `${diff.new.value}`;
        databaseContent = `${diff.current.value}`;
      }

      return {
        id: diff.key,
        name: diff.key,
        dataFeed: dataFeedContent,
        database: databaseContent,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};
