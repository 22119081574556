import { RESERVE_FOR_ZERO_STATUS } from '~/consts/reserveForZero';

import { formatDateWithClock } from '~/services/TimeService';

export interface ReserveForZeroDetails {
  booking_status: RESERVE_FOR_ZERO_STATUS;
  scheduled_payment_date: string;
  metadata: {
    subscription: {
      id: string;
      livemode: string;
    };
  };
}

export const formatDate = (cell) => {
  const date = new Date(cell);
  return formatDateWithClock(date);
};

export const getStripeSubscriptionLink = (reserveForZeroDetails: ReserveForZeroDetails) => {
  const subscription = reserveForZeroDetails?.metadata?.subscription;
  if (subscription?.id) {
    const isTest = subscription.livemode ? '' : '/test';
    return `https://dashboard.stripe.com${isTest}/subscriptions/${subscription.id}`;
  } else {
    return 'NA';
  }
};

export const getPaymentDueDate = (reserveForZeroDetails: ReserveForZeroDetails) => {
  return reserveForZeroDetails?.metadata?.subscription?.id
    ? formatDate(reserveForZeroDetails.scheduled_payment_date)
    : 'NA';
};
