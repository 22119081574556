import React from 'react';

import { Helmet } from 'react-helmet';

import { Paper } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import ReservationService from '../../../services/ReservationService';
import VendorsService from '../../../services/VendorsService';
import Asinc from '../../Common/Asinc';
import PropertyForm from '../../Common/Forms/PropertyForm';

export default class PropertyNewContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = props;
    this.vendorId = match.params.id_vendor;

    this.fetchData = this.fetchData.bind(this);
  }

  async fetchData() {
    const [reservationSchema, vendor] = await Promise.all([
      ReservationService.getPropertiesSchema(),
      VendorsService.getVendorById(this.vendorId),
    ]);

    return {
      schema: reservationSchema.post.body.schema,
      vendor: vendor.result,
    };
  }

  render() {
    return (
      <div className="container property-edit-form">
        <PageHeader title="Create Property" />

        <Asinc
          promise={this.fetchData()}
          then={({ schema, vendor }) => {
            const property = { id_salesforce_external: this.vendorId };

            return (
              <Paper id="overview" className="property-deal-section">
                <Helmet>
                  <title>Vendors | {vendor?.name || this.vendorId} | Create Property</title>
                </Helmet>

                <PropertyForm schema={schema} property={property} vendorId={this.vendorId} />
              </Paper>
            );
          }}
        />
      </div>
    );
  }
}
