import React, { useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import ReservationService from '~/services/ReservationService';

// todo remove once contract updated ಠ_ಠ
interface RoomType extends Reservation.RoomType {
  amenities_ordering: Record<string, number>;
}
interface Props {
  amenities: { name: string; built_in: boolean; id: string | number }[];
  roomType: RoomType;
  vendorId: string;
  propertyId: string;
}

const SortableAmenitiesList = SortableContainer(({ items, removeItem }) => (
  <Stack>
    {items.map((value, index) => (
      <SortableAmenityItem key={value.id} index={index} value={value} removeItem={removeItem} />
    ))}
  </Stack>
));

const SortableAmenityItem = SortableElement(({ value, removeItem }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Box
      flexGrow={3}
      p={1}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #e0e0e0',
        alignItems: 'center',
        cursor: 'grab',
      }}
    >
      <Typography>{value.name}</Typography>
    </Box>
    <IconButton onClick={() => removeItem(value.id)} size="small" color="error" sx={{ cursor: 'pointer' }}>
      <DeleteIcon />
    </IconButton>
  </Stack>
));

export default function AmenitiesPane({ amenities: sourceAmenities, roomType: sourceRoomType, propertyId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const transformInput = (amenitiesOrdering) => {
    return Object.keys(amenitiesOrdering)
      .map((key) => ({
        id: key,
        name: amenities.find((amenity) => amenity.id == key)?.name ?? 'Unknown', // intentionally using == for string/number comparison
        order: amenitiesOrdering[key],
      }))
      .sort((a, b) => a.order - b.order);
  };
  // because of how the tabs are setup we need to use state to keep track of the current room type local to this tab
  const [roomType, setRoomType] = useState(sourceRoomType);
  const [amenities, setAmenities] = useState(sourceAmenities);

  const [amenitiesOrdering, setAmenitiesOrdering] = useState<any[]>(transformInput(roomType.amenities_ordering ?? {}));
  const [saving, setSaving] = useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [saveButtonMessage, setSaveButtonMessage] = React.useState('No changes');
  const removeAmenityFromOrder = (id) => {
    setAmenitiesOrdering(amenitiesOrdering.filter((a) => a.id !== id));
    setHasChanges(true);
    setSaveButtonMessage('Save Changes');
  };

  const dispatchUpdate = async () => {
    setSaving(true);
    try {
      await ReservationService.updateRoomType(
        // as patch request required props only + amenities_ordering
        {
          name: roomType.name,
          description: roomType.description,
          property_id: roomType.property_id,
          category: roomType.category,
          amenities_ordering: formattedOutput,
        },
        propertyId,
        roomType.id,
      );
      const { result: latest } = await ReservationService.getRoomType(propertyId, roomType.id);
      setRoomType(latest);
      setAmenities(latest.amenities);
      setAmenitiesOrdering(transformInput(latest.amenities_ordering));
      enqueueSnackbar('Amenities order updated', { variant: 'success' });
      setHasChanges(false);
      setSaveButtonMessage('No changes');
      setSaving(false);
    } catch (error) {
      enqueueSnackbar('Failed to update amenities order', { variant: 'error' });
      setSaving(false);
    }
  };

  const resetToPreviousOrder = () => {
    setAmenitiesOrdering(transformInput(roomType.amenities_ordering));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newOrdering = arrayMove(amenitiesOrdering, oldIndex, newIndex);
    newOrdering = recalculateOrdering(newOrdering);

    setAmenitiesOrdering(newOrdering);
    setHasChanges(true);
    setSaveButtonMessage('Save Changes');
  };

  const recalculateOrdering = (ordering) => {
    return ordering
      .filter((a) => a && a.id) // getting some weird items being empty or only having order
      .map((amenity, index) => ({
        ...amenity,
        order: index + 1,
      }))
      .sort((a, b) => a.order - b.order);
  };

  const disableDragAndDrop = (e) => {
    // Disable drag and drop when you press an element inside the sortable
    //container, when the tag matches
    const tag = e.target.tagName.toLowerCase();
    if (tag === 'button' || tag === 'svg' || tag === 'path') {
      return true;
    }
  };

  const addToOrder = (amenity) => {
    setAmenitiesOrdering([
      ...amenitiesOrdering,
      { id: amenity.id, name: amenity.name, order: amenitiesOrdering.length },
    ]);
    setHasChanges(true);
    setSaveButtonMessage('Save Changes');
  };

  const saveColor = useMemo(() => {
    switch (true) {
      case saving:
        return 'warning';
      case hasChanges:
        return 'primary';
      default:
        return 'info';
    }
  }, [saving, hasChanges]);

  const formattedOutput = useMemo(() => {
    return amenitiesOrdering.reduce((acc, cur) => {
      acc[cur.id] = cur.order;
      return acc;
    }, {});
  }, [amenitiesOrdering]);

  return (
    <>
      <PageSubheader title="Amenities" />

      <Grid container spacing={6}>
        <Grid item md={6}>
          <Typography variant="h6" color="secondary" textTransform="uppercase">
            Amenities via API
          </Typography>

          {amenities.map((amenity, index) => (
            <Stack key={index} direction="row" justifyContent="space-between" spacing={2} mb={1}>
              <Typography>
                {amenity.name}
                {amenity.built_in ? ' (Built-in)' : ''}
              </Typography>
              {!formattedOutput[amenity.id] && (
                <Button variant="outlined" color="primary" onClick={() => addToOrder(amenity)}>
                  Customise order
                </Button>
              )}
            </Stack>
          ))}
        </Grid>
        <Grid item md={6}>
          <Stack direction="row" spacing={2} justifyContent="space-between" mb={2}>
            <Typography variant="h6" color="secondary" textTransform="uppercase">
              Amenities Custom Ordering
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 2,
                gap: 2,
              }}
            >
              {roomType && roomType.amenities_ordering && hasChanges && (
                <Button onClick={resetToPreviousOrder} color="error" variant="outlined">
                  Reset to previous order
                </Button>
              )}
              <Button onClick={dispatchUpdate} disabled={saving || !hasChanges} variant="contained" color={saveColor}>
                {saveButtonMessage}
              </Button>
            </Box>
          </Stack>

          <SortableAmenitiesList
            items={amenitiesOrdering}
            removeItem={removeAmenityFromOrder}
            onSortEnd={onSortEnd}
            shouldCancelStart={disableDragAndDrop}
            lockAxis="y"
            lockOffset={0}
          />
        </Grid>
      </Grid>
    </>
  );
}
