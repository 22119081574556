import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Link,
  Typography,
} from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';

import { getSettingsHistory } from '~/services/SupportService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import userUtils from '~/utils/userUtils';

const LE_BRAND = 'luxuryescapes';

const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Created at',
    disableColumnMenu: true,
    minWidth: 100,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value as string)),
    display: 'flex',
  },
  {
    field: 'values',
    headerName: 'Values',
    disableColumnMenu: true,
    minWidth: 100,
    flex: 1,
    renderCell: (params: GridCellParams) => {
      return <pre>{JSON.stringify(params.value, null, 2)}</pre>;
    },
    display: 'flex',
  },
  {
    field: 'user',
    renderCell: (params: GridCellParams) => (
      <>
        {params.value && (
          <Link
            target="_blank"
            color={userUtils.isEmployeeEmail(params.row.user?.email) ? '#ff0000' : '#000'}
            href={`/users/${params.row.user?.id_member}`}
          >
            <Typography variant="body2">{params.row.user?.full_name}</Typography>
            <Typography variant="body2">{params.row.user?.email}</Typography>
          </Link>
        )}
      </>
    ),
    headerName: 'User',
    disableColumnMenu: true,
    sortable: false,
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
];

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function SettingsHistoryModal(props: Props) {
  const { open, onClose } = props;
  const [settingsHistory, setSettingsHistory] = useState([]);
  const [historyUsers, setHistoryUsers] = useState<Map<string, App.User>>(new Map());
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('loading');

  const settingsHistoryWithUserDetails = useMemo(
    () =>
      settingsHistory.map((history) => ({
        ...history,
        user: historyUsers.get(history.userId),
      })),
    [settingsHistory, historyUsers],
  );

  const fetchSettingsHistory = async () => {
    setFetchingState('loading');
    try {
      const { result } = await getSettingsHistory();
      setSettingsHistory(result);
      setFetchingState('success');
    } catch (error) {
      setFetchingState('failed');
    }
  };

  useEffect(() => {
    if (open) {
      fetchSettingsHistory();
    }
  }, [open]);

  useEffect(() => {
    if (settingsHistory.length > 0) {
      const users = settingsHistory.map((history) => history.userId).filter((history) => history);

      if (users.length === 0) {
        return;
      }

      UsersService.getUsersSummaryByIds([...new Set(users)], LE_BRAND).then((users) => setHistoryUsers(users));
    }
  }, [settingsHistory]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Settings history</DialogTitle>
        <DialogContent>
          <DataGrid
            autoHeight
            columns={columns}
            getRowHeight={() => 'auto'}
            density="compact"
            loading={fetchingState === 'loading'}
            rowCount={setSettingsHistory.length}
            rows={settingsHistoryWithUserDetails || []}
            slots={{
              loadingOverlay: LinearProgress,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
