import React from 'react';

import { Stack, TableCell, TableRow } from '@mui/material';

import { Badge, Tag, Text } from '~/components/Experiences/components';

import { getStatusByCurated, themes } from '~/consts/experiences';

import { CategoryItem, ExperienceOffer } from '~/services/ExperiencesService';
import { formatDateShort } from '~/services/TimeService';

import { currencyFormatter } from '../../helpers';

type RowProps = {
  onClick: () => void;
  experience: ExperienceOffer;
};

export const ExperienceRowContent: React.FC<RowProps> = (props) => {
  const { experience, onClick } = props;

  const hasRating = !!experience?.providerRating?.number;
  const status = getStatusByCurated(experience.curationStatus);

  const salesPrice = experience?.baseSalesPrice?.amount;
  const retailPrice = experience?.baseRetailPrice?.amount;

  const retailPriceFormatted = () => {
    return currencyFormatter(experience?.baseRetailPrice?.currencyCode, experience?.baseRetailPrice?.amount);
  };

  const salesPriceFormatted = () => {
    return currencyFormatter(experience?.baseSalesPrice?.currencyCode, experience?.baseSalesPrice?.amount);
  };

  const markup = () => (salesPrice / retailPrice - 1) * 100;

  return (
    <TableRow>
      <TableCell onClick={onClick}>
        <Badge title={status.title} color={status.color} background={'transparent'} borderColor={status.color} />
      </TableCell>

      <TableCell onClick={onClick}>
        <div>
          <Stack direction="row" alignItems="self-start">
            <Text weight={600} title={experience.title}>
              {experience.title}
            </Text>
            {experience?.leExclusive && <Tag name={'LE Exclusive'} background={themes.secondaryGreen} />}
            {experience?.isFutureOffer && <Tag name={'Scheduled'} background={themes.secondaryGrey} />}
          </Stack>
          <Text weight={300} title={experience?.shortDescription ?? experience?.description}>
            {experience?.shortDescription ?? experience?.description ?? <i>no description</i>}
          </Text>
          <Text weight={300} title={experience.title} mt="10px">
            id: {experience.id}
          </Text>
          {experience?.isFutureOffer && experience?.scheduledStartDate && (
            <Text weight={300} title={experience.title}>
              Start date: {formatDateShort(new Date(experience?.scheduledStartDate))}
            </Text>
          )}
          {experience?.isFutureOffer && experience?.scheduledEndDate && (
            <Text weight={300} title={experience.title}>
              End date: {formatDateShort(new Date(experience?.scheduledEndDate))}
            </Text>
          )}
        </div>
      </TableCell>

      <TableCell onClick={onClick}>{retailPriceFormatted()}</TableCell>

      <TableCell onClick={onClick}>{salesPriceFormatted()}</TableCell>

      <TableCell onClick={onClick}>{markup().toFixed(1) + '%'}</TableCell>

      <TableCell onClick={onClick}>
        {experience?.brands?.map((brand, i: number) => (
          <Tag outline name={brand} key={`${i}-${brand}`} />
        ))}
      </TableCell>

      <TableCell onClick={onClick}>
        {hasRating && (
          <>
            <Text>{experience?.providerRating?.average} stars</Text>
            <Text weight={300} size={'14px'}>
              ({experience?.providerRating?.number} votes)
            </Text>
          </>
        )}
        {!hasRating && (
          <Text weight={300} size={'14px'}>
            has no rating
          </Text>
        )}
      </TableCell>

      <TableCell onClick={onClick}>
        {experience.categories.map((category: CategoryItem, i: number) => (
          <Tag outline name={category.label} key={`${i}-${category.label}`} />
        ))}
      </TableCell>
    </TableRow>
  );
};
