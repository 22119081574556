import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import { formatDateShort } from '~/services/TimeService';

import { daysOfWeekMessage } from '~/utils/daysOfWeek';

import PermissionComponent from '../../Common/PermissionedComponent';
import { useConfirmDialog } from '../../Common/Providers/ConfirmDialogProvider';

export default function SurchargeDateBlock({ surchargeDateBlock, onDelete, onRequestEdit }) {
  const startDate = formatDateShort(surchargeDateBlock.start_date);
  const endDate = formatDateShort(surchargeDateBlock.end_date);

  const showConfirmDialog = useConfirmDialog();

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete this surcharge date?',
      description:
        'Surcharges should not be deleted after a deal has been set live - ' +
        'please contact sales operations (OCM) or an account manager before proceeding.',
    });

    if (!confirmed) return;

    onDelete(surchargeDateBlock.id);
  };

  const handleEdit = () => {
    onRequestEdit(surchargeDateBlock);
  };

  return (
    <Grid container spacing={0} className="box-list-item">
      <Grid item xs={11}>
        <Grid container spacing={0} direction="column">
          <Grid item>
            <div>
              {startDate} &nbsp; &mdash; &nbsp; {endDate}
            </div>
          </Grid>
          <Grid item>
            <div>
              <i>{daysOfWeekMessage(surchargeDateBlock)}</i>
            </div>
          </Grid>
          <Grid item>
            {['AUD', 'USD', 'NZD']
              .map(
                (currencyCode) =>
                  currencyFormatter.format(surchargeDateBlock.prices[currencyCode], {
                    code: currencyCode,
                    precision: 0,
                  }) +
                  ' ' +
                  currencyCode,
              )
              .join(' | ')}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} textAlign="right">
        <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Button title="Delete" variant="text" startIcon={<Delete />} onClick={handleDelete} fullWidth>
                Delete
              </Button>
            </Grid>
            <Grid item>
              <Button title="Edit" variant="text" startIcon={<EditIcon />} onClick={handleEdit} fullWidth>
                Edit
              </Button>
            </Grid>
          </Grid>
        </PermissionComponent>
      </Grid>
    </Grid>
  );
}
