import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { Info as InfoIcon } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { Review } from '~/services/ReviewService';

const DIALOG_TRANSITION_PROPS: ComponentProps<typeof Dialog>['TransitionProps'] = { unmountOnExit: true };

interface ChangedValues {
  response?: string;
  isPubliclyVisible?: boolean;
}

interface Props {
  isOpen: boolean;
  isRental?: boolean;
  review: Review;
  onRequestClose: () => void;
  onConfirm: (reviewId: string, changes: ChangedValues) => Promise<void>;
  onDelete: (reviewId: string) => void;
}

const UserReviewDetailsModal = (props: Props) => {
  const { isOpen, isRental, review, onRequestClose, onConfirm, onDelete } = props;

  const [response, setResponse] = useState<string>('');
  const [isPubliclyVisible, setIsPubliclyVisible] = useState<boolean>(false);
  const [reviewWording, setReviewWording] = useState<string>('');
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const reviewData = useMemo(() => {
    if (!review) return null;
    return {
      ...review,
      createdAt: new Date(review.createdAt).toLocaleString(),
    };
  }, [review]);

  const disableSave = useMemo(() => {
    const reviewResponse = review?.response?.message ?? '';
    return (
      isConfirming ||
      (response === reviewResponse &&
        isPubliclyVisible === review?.isPubliclyVisible &&
        reviewWording === review?.review)
    );
  }, [isConfirming, review, isPubliclyVisible, reviewWording, response]);

  const handleResponseChange = useCallback((e) => {
    setResponse(e.target.value);
  }, []);

  const handleVisibilityChange = useCallback((e) => {
    setIsPubliclyVisible(e.target.checked);
  }, []);

  const handleReviewWordingChange = useCallback((e) => {
    setReviewWording(e.target.value);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setResponse(review?.response?.message ?? '');
      setIsPubliclyVisible(!!review?.isPubliclyVisible);
      setReviewWording(review?.review ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let changes = {};
      if (isPubliclyVisible != review.isPubliclyVisible) {
        changes = { isPubliclyVisible };
      }
      if (response !== (review.response?.message ?? '')) {
        changes = {
          ...changes,
          response,
        };
      }
      if (reviewWording !== review.review) {
        changes = {
          ...changes,
          reviewWording,
        };
      }
      setIsConfirming(true);
      try {
        await onConfirm(review.id, changes);
      } finally {
        setIsConfirming(false);
      }
    },
    [isPubliclyVisible, review, response, reviewWording, onConfirm],
  );

  const handleDelete = useCallback(
    async (e) => {
      e.preventDefault();

      setIsConfirming(true);
      try {
        await onDelete(review.id);
      } finally {
        setIsConfirming(false);
      }
    },
    [onDelete, review],
  );

  return (
    <Dialog open={isOpen} onClose={onRequestClose} TransitionProps={DIALOG_TRANSITION_PROPS}>
      <DialogTitle>Review Details</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          {!reviewData?.id && (
            <Stack direction="row" spacing={2}>
              <span>Review could not be found!</span>
            </Stack>
          )}
          {!!reviewData?.id && (
            <>
              <Grid container spacing={2} marginLeft={0}>
                <Grid item sm={12} md={6}>
                  <Stack>
                    <b>Review ID:</b>
                    <span>{reviewData.id}</span>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack>
                    <b>Created at:</b>
                    <span>{reviewData.createdAt}</span>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={11}>
                  {isRental ? (
                    <TextField
                      name="Review"
                      label="Edit the wording for this review"
                      multiline
                      rows={4}
                      defaultValue={reviewData.review}
                      onChange={handleReviewWordingChange}
                      fullWidth
                    />
                  ) : (
                    <Stack>
                      <b>Review:</b>
                      <span>{reviewData.review}</span>
                    </Stack>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={12} md={2}>
                  <Stack>
                    <b>Rating: </b>
                    <span>{reviewData.rating}</span>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Stack>
                    <b>User ID: </b>
                    <a href={`/users/${reviewData.userId}`} target="_blank" rel="noreferrer">
                      {reviewData.userId}
                    </a>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Stack>
                    <b>User Name: </b>
                    <span>{reviewData.userName}</span>
                  </Stack>
                </Grid>
              </Grid>
              <Divider variant="middle" />
              <Stack>
                <h4>Visibility</h4>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleVisibilityChange}
                        defaultChecked={review.isPubliclyVisible}
                        name="isPubliclyVisible"
                      />
                    }
                    label="Is Publicly Visible"
                    style={{
                      marginBottom: 0,
                    }}
                  />
                </FormGroup>
                <Tooltip title="Whether this review should be shown in Customer Portal or not.">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Stack>
              <Divider variant="middle" />
              <Stack>
                <h4>Response</h4>
              </Stack>
              <Stack direction="row">
                <TextField
                  name="response"
                  label="Write a response for this review"
                  multiline
                  rows={4}
                  defaultValue={review.response?.message}
                  onChange={handleResponseChange}
                  fullWidth
                />
              </Stack>
              <Divider variant="middle" />
              <Button color="error" variant="contained" disabled={isConfirming} onClick={handleDelete}>
                Delete review
              </Button>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={disableSave} onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserReviewDetailsModal;
