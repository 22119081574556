import React from 'react';

import { Alert, Box, Button, Stack, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import VendorsService from '~/services/VendorsService';

const SuccessMessage = () => {
  return (
    <Alert severity="success">
      <strong>Success!</strong>
      <p>File upload successful</p>
    </Alert>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <Alert severity="error">
      <strong>Oops!</strong>
      <p>File upload failed</p>
      <pre>{message}</pre>
    </Alert>
  );
};

export default class Upload extends React.Component {
  state = {
    successMessage: false,
    showErrorMessage: false,
    errorMessage: '',
    file: null,
    total: null,
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await this.fileUpload(this.state.file);
      this.setState({
        successMessage: true,
        showErrorMessage: false,
        errorMessage: '',
        file: null,
        total: result.total,
      });
    } catch (err) {
      this.setState({
        successMessage: false,
        showErrorMessage: true,
        errorMessage: err.message,
        file: null,
        total: null,
      });
    }
  };

  onChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  fileUpload = (file) => {
    const formData = new FormData();
    formData.append('csv', file);
    return VendorsService.uploadFxRates(formData);
  };

  render() {
    const { successMessage, showErrorMessage, errorMessage } = this.state;

    return (
      <Box>
        <PageSubheader title="FX Rates Upload" />

        {successMessage && <SuccessMessage />}
        {showErrorMessage && <ErrorMessage message={errorMessage} />}

        <Typography variant="body1" fontSize={14}>
          Upload the CSV FX rate template (to create a new template you can run the FX rate file download below <br />
          and change the data as necessary) here. The upload date should be the 1st of the month.
        </Typography>

        <form onSubmit={this.onFormSubmit}>
          <Stack direction="column" spacing={2}>
            <h5>File Upload</h5>
            <input type="file" onChange={this.onChange} />

            <div>
              <Button type="submit" variant="contained">
                Upload rates
              </Button>
            </div>
          </Stack>
        </form>
      </Box>
    );
  }
}
