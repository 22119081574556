import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  refreshGroups: () => void;
}

export default function NewAmenityGroupModal({ isModalOpen, closeModal, refreshGroups }: Props) {
  const [loading, setLoading] = useState<Utils.FetchingState>('idle');
  const [name, setName] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(async () => {
    if (!name) {
      enqueueSnackbar('Please fill in all fields', { variant: 'error' });
      return;
    }
    try {
      setLoading('loading');
      await ReservationService.createAmenityGroup(name);
      setLoading('success');
      refreshGroups();
      closeModal();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setLoading('failed');
    }
  }, [name]);

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogTitle>Create New Amenity Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={'Name'}
        />
        <Typography sx={{ marginTop: 2 }}>New groups will be placed last in order</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal} disabled={loading === 'loading'}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={loading === 'loading'}>
          {loading === 'loading' ? <Spinner size={15} inline /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
