import React, { useCallback, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { buildDepositDetails, buildOnBoardCreditDetails } from '../../RateCodeManagement/helpers';

const dataGridInitialState = {
  pagination: { paginationModel: { pageSize: 10 } },
};

type Props = {
  page: number;
  total: number;
  perPage: number;
  loading: boolean;
  promotions: CruisesContract.Promotion[];
  onPaginate: (page: number) => void;
  onDelete: (promotionId: string) => Promise<void>;
};

const PromotionList = (props: Props) => {
  const { loading, promotions, page, perPage, total, onPaginate, onDelete } = props;
  const [deleteButton, setDeleteButton] = useState(null);

  const columns: GridColDef[] = [
    {
      flex: 3,
      editable: true,
      field: 'name',
      headerName: 'Name',
      display: 'flex',
    },
    {
      flex: 2,
      editable: true,
      field: 'startDate',
      headerName: 'Start Date',
      display: 'flex',
    },
    {
      flex: 2,
      editable: true,
      field: 'endDate',
      headerName: 'End Date',
      display: 'flex',
    },
    {
      flex: 2,
      editable: true,
      field: 'rates',
      headerName: 'Rate Codes',
      display: 'flex',
    },
    {
      flex: 2,
      editable: true,
      field: 'vendors',
      headerName: 'Vendor Code',
      display: 'flex',
    },
    {
      flex: 2,
      editable: true,
      field: 'depositDetails',
      headerName: 'Deposit Details',
      display: 'flex',
    },
    {
      flex: 2,
      editable: true,
      field: 'onBoardCreditDetails',
      headerName: 'OBC Details',
      display: 'flex',
    },
    {
      flex: 1,
      field: 'actionEdit',
      headerName: '',
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          size="small"
          variant="text"
          component={Link}
          to={`/cruises/promotion-management/${row.promotionId}/edit`}
        >
          Edit
        </Button>
      ),
      display: 'flex',
    },
    {
      flex: 2,
      field: 'actionDelete',
      headerName: '',
      sortable: false,
      renderCell: (params) => actionDeleteColumn(params.value, params.row),
      display: 'flex',
    },
  ];

  const actionDeleteColumn = useCallback(
    (_, row) => (
      <div>
        {deleteButton !== row.id && (
          <Button
            size="small"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setDeleteButton(row.id);
            }}
          >
            Delete
          </Button>
        )}

        {deleteButton === row.id && (
          <div>
            <Button
              size="small"
              variant="text"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await onDelete(row.promotionId);
              }}
            >
              Confirm
            </Button>

            <Button
              size="small"
              variant="text"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(null);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    ),
    [deleteButton, onDelete],
  );

  const dataRows = useMemo(() => {
    return promotions.map((promotion, id) => ({
      id,
      promotionId: promotion.id,
      name: promotion.name,
      startDate: promotion.startDate,
      endDate: promotion.endDate,
      rates: promotion.rates?.map((rate) => rate.code).join(', '),
      vendors: [...new Set(promotion.rates?.map((rate) => rate.vendorCode))].join(', '),
      depositDetails: promotion.deposit && buildDepositDetails(promotion.deposit),
      onBoardCreditDetails: promotion.onBoardCredit && buildOnBoardCreditDetails(promotion.onBoardCredit),
    }));
  }, [promotions]);

  return (
    <Box>
      <DataGrid
        pagination
        rows={dataRows}
        rowCount={total}
        columns={columns}
        loading={loading}
        pageSizeOptions={[10]}
        paginationMode="server"
        getRowId={(row) => row.id}
        initialState={dataGridInitialState}
        slots={{ pagination: GridPagination }}
        paginationModel={{ page, pageSize: perPage }}
        onPaginationModelChange={({ page }) => onPaginate(page)}
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default PromotionList;
