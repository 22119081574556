import React, { useState } from 'react';

import ReservationService from '../../../../services/ReservationService';
import Asinc from '../../../Common/Asinc';

import ChainPropertiesPage from './ChainPropertiesPage';

const ChainPropertiesPageContainer = (props) => {
  const [parentId, setParentId] = useState(props.parentId);

  const fetchProperties = () => {
    return ReservationService.getPropertiesByChainId(parentId, props.country);
  };

  return (
    <Asinc
      promise={fetchProperties()}
      then={(data) => {
        return <ChainPropertiesPage parentId={parentId} properties={data.result} />;
      }}
    />
  );
};

export default ChainPropertiesPageContainer;
