import React, { useEffect } from 'react';

import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import UserActions from '~/stormpath/actions/UserActions';

function SocialGoogleButton(props) {
  useEffect(() => {
    /* global google */
    (google as any).accounts.id.initialize({
      client_id: window.configs.GOOGLE_APP_ID,
      callback: onSuccess,
    });

    (google as any).accounts.id.renderButton(document.getElementById('my-signin2'), {
      theme: 'filled_blue',
      text: 'continue_with',
      locale: 'en_AU',
      size: 'large',
    });
  }, []);

  function onSuccess(data) {
    const tokenData = jwt_decode(data.credential) as any;
    const loginWithGoogleData = {
      id_google: tokenData.sub,
      email: tokenData.email,
      token_id: data.credential,
      givenName: tokenData.given_name,
      surname: tokenData.family_name,
    };
    UserActions.loginWithGoogle(loginWithGoogleData, (err, result) => {
      let redirectUrl = '/';
      if (props.location) {
        redirectUrl = props.location.pathname + props.location.search;
      }
      props.history.push(redirectUrl);
    });
  }

  return <div id="my-signin2">Continue with google</div>;
}

export default withRouter(SocialGoogleButton);
