import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Skeleton, Typography } from '@mui/material';

import { searchSentEmails } from '~/services/SailthruService';

export default function QuoteEmailAddress({ dedupeKey }) {
  const [loading, setLoading] = useState('idle');
  const [emails, setEmails] = useState<Array<string>>([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchEmailLog = async () => {
      try {
        setLoading('loading');
        const emailsRes = await searchSentEmails({ key: dedupeKey, limit: 1, offset: 0, email: '' });
        const emails = emailsRes.result?.[0]?.email || '';
        setEmails(emails.split(','));
        setLoading('success');
      } catch (error) {
        setLoading('failed');
        enqueueSnackbar(`Error fetching email info: ${error}`, { variant: 'error' });
      }
    };

    fetchEmailLog();
  }, [dedupeKey, enqueueSnackbar]);

  if (loading === 'loading') {
    return <Skeleton width={100} />;
  }

  if (loading === 'failed') {
    return null;
  }

  return (
    <Box>
      {emails.map((email) => (
        <Typography key={email} variant="body2">
          {email}
        </Typography>
      ))}
    </Box>
  );
}
