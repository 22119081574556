import React, { useCallback, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { enqueueCancelledBookingPaymentsReport } from '~/services/ReportingService';

const formSchema: RJSFSchema = {
  description: 'Please enter your date to enqueue the Cancelled Booking Payments report',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
  },
  required: ['startAt', 'endAt'],
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function CancelledBookingPayments() {
  const [success, setSuccess] = useState<boolean | undefined>();

  const handleSubmit = useCallback(async (e: IChangeEvent) => {
    const { startAt, endAt } = e.formData;

    await enqueueCancelledBookingPaymentsReport({ startAt, endAt });
    setSuccess(true);
  }, []);

  return (
    <>
      <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator}>
        <Button type="submit" variant="contained">
          Email report
        </Button>

        {success && <Alert severity="success">Your report is queued for delivery and will be emailed shortly.</Alert>}
      </Form>
    </>
  );
}
