import { ExperienceActionTypes, ExperienceOffers } from '~/services/ExperiencesService';

export type ExperiencesAction = {
  total?: number;
  message?: string;
  payload?: ExperienceOffers;
  type: ExperienceActionTypes;
};

export type ExperiencesState = {
  total?: number;
  error: boolean;
  message?: string;
  loading: boolean;
  items: ExperienceOffers;
};

export const initialExperiences: ExperiencesState = {
  total: 0,
  items: [],
  error: false,
  message: null,
  loading: false,
};

export const fetchExperiencesReducer = (state: ExperiencesState, action: ExperiencesAction) => {
  switch (action.type) {
    case 'LOAD':
      return { error: false, loading: true, items: [], total: 0 };
    case 'RESET':
      return { error: false, loading: false, items: [], total: 0 };
    case 'ERROR':
      return {
        total: 0,
        items: [],
        error: true,
        loading: false,
        message: action.message,
      };
    case 'FETCH':
      return {
        error: false,
        loading: false,
        items: action.payload,
        total: action.total || action.payload.length,
      };
    default:
      throw new Error('Error in reducer (fetch-experiences) action.type');
  }
};
