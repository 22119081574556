import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import {
  Brands,
  CabinCategoriesGroup,
  getCabinCategoriesGroupList,
} from '~/services/cruises/CabinCategoriesGroupService';

import ShipColumnDetails from '../CabinCategories/ShipColumnDetails';
import VendorColumnDetails from '../CabinCategories/VendorColumnDetails';
import LinkContent from '../Sailings/LinkContent';

import CabinCategoryDetailsColumnDetails from './CabinCategoryDetailsColumnDetails';
import { SearchFilters } from './constants';

const DEFAULT_ROWS_RESPONSE: CabinCategoriesGroupResponse = { result: [], total: 0 };

type FetchCabinCategoriesGroupParams = {
  page: number;
  brand: App.Brands;
  sizePerPage: number;
  searchFilters: SearchFilters;
};

interface Props {
  page: number;
  brand: App.Brands;
  sizePerPage: number;
  currentPage: number;
  searchFilters: SearchFilters;
  onPageChange: (page: number) => void;
}

const urlBuilder = (id: string): string => `/cruises/cabin-categories-groups/${id}`;

const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Group Name',
    renderCell: (params) => <LinkContent title={params.row.name} url={urlBuilder(params.row.id)} />,
    display: 'flex',
  },
  {
    flex: 2,
    sortable: false,
    field: 'cabinCategories',
    headerName: 'Cabin Categories Codes',
    renderCell: (params) => <CabinCategoryDetailsColumnDetails cabinCategories={params.row.cabinCategories} />,
    display: 'flex',
  },
  {
    flex: 1,
    field: 'ship',
    sortable: false,
    headerName: 'Ship Details',
    renderCell: (params) => <ShipColumnDetails name={params.row.ship.name} externalId={params.row.ship.externalId} />,
    display: 'flex',
  },
  {
    flex: 1,
    field: 'vendor',
    sortable: false,
    headerName: 'Vendor Details',
    renderCell: (params) => (
      <VendorColumnDetails name={params.row.vendor.name} externalId={params.row.vendor.externalId} />
    ),
    display: 'flex',
  },
];

type CabinCategoriesGroupResponse = {
  total: number;
  result: CabinCategoriesGroup[];
};

export default function CabinCategoriesGroupList(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { brand, page, currentPage, searchFilters, sizePerPage, onPageChange } = props;

  const [rows, setRows] = useState<CabinCategoriesGroupResponse>(DEFAULT_ROWS_RESPONSE);
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');

  const fetchData = useCallback(
    async (params: FetchCabinCategoriesGroupParams): Promise<void> => {
      const { brand, searchFilters } = params;

      setFetching('loading');

      let shipExternalId: number | undefined = undefined;
      if (searchFilters.shipExternalId) shipExternalId = Number(searchFilters.shipExternalId);

      const take = params.sizePerPage;
      const skip = (params.page - 1) * params.sizePerPage;

      try {
        const fetchedCabinCategoriesGroup = await getCabinCategoriesGroupList({
          ...searchFilters,
          skip,
          take,
          shipExternalId,
          brand: brand as Brands,
        });

        setFetching('success');

        if (!fetchedCabinCategoriesGroup || fetchedCabinCategoriesGroup.result.length === 0) {
          enqueueSnackbar('No results found', { autoHideDuration: 5000, variant: 'warning' });
        }

        setRows({
          total: fetchedCabinCategoriesGroup.total,
          result: fetchedCabinCategoriesGroup.result,
        });
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setRows({ total: 0, result: [] });
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchData({
      brand,
      sizePerPage,
      searchFilters,
      page: currentPage,
    });
  }, [currentPage, fetchData, searchFilters, sizePerPage, brand]);

  return (
    <DataGrid
      pagination
      autoHeight
      columns={columns}
      disableColumnMenu
      disableColumnFilter
      rows={rows.result}
      rowCount={rows.total}
      paginationMode="server"
      disableRowSelectionOnClick
      getRowHeight={() => 'auto'}
      pageSizeOptions={[sizePerPage]}
      loading={fetching === 'loading'}
      slots={{ pagination: GridPagination }}
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
    />
  );
}
