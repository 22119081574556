import React from 'react';

import * as S from './styles';

type CardProps = {
  children: React.ReactElement;
};

export const Card: React.FC<CardProps> = (props) => {
  const { children } = props;

  return <S.CardWrapper>{children}</S.CardWrapper>;
};
