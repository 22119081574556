/* src/components/Users/Orders/AddonsSelection/index.js */
import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { addAddonToCart, removeAddonFromCart } from '../../../../actions/cart';
import { ITEM_TYPE_OFFER } from '../../../../consts/order';
import OfferLocationHeading from '../../../Common/Booking/OfferLocationHeading';
import NoDataWarning from '../NoDataWarning';
import OrderFooter from '../OrderFooter';
import withOffers from '../withOffers';

import AddonsContent from './content';

const AddonsListBox = styled.div`
  border: solid 1px;
  border-color: #d6d6d6;
  padding: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

class AddonsSelection extends React.Component {
  handleAddAddon = (addon, item, offer, offerPackage) => {
    this.props.addAddonToCart(addon, item, offer, offerPackage);
  };

  handleRemoveAddon = (addon) => {
    const {
      offer: { offerCartId, id_salesforce_external },
      removeAddonFromCart,
    } = this.props;
    removeAddonFromCart({
      addonID: addon.id_salesforce_external,
      offerId: id_salesforce_external,
      offerCartId,
    });
  };

  getAddonQuantity = (addon) => {
    const { cart } = this.props;

    return cart.addons.filter((addonInCart) => addonInCart.addonId === addon.id_salesforce_external).length;
  };

  isStateValid = () => {
    const { offer } = this.props;

    return !!offer;
  };

  render() {
    const { cart, proceedStep, nextStepLabel, backStep, offer, offerNumber } = this.props;
    const { customer, currencyCode } = cart;

    const canProceed = true;

    if (!this.isStateValid()) {
      return <NoDataWarning userId={customer.memberId} orderType={ITEM_TYPE_OFFER} />;
    }

    return (
      <div>
        <AddonsListBox>
          <OfferLocationHeading offerNumber={offerNumber} offer={offer}></OfferLocationHeading>
          <AddonsContent
            items={offer.items}
            offer={offer}
            currencyCode={currencyCode}
            onAddAddon={this.handleAddAddon}
            onRemoveAddon={this.handleRemoveAddon}
            getAddonQuantity={this.getAddonQuantity}
          />
        </AddonsListBox>

        <OrderFooter
          cart={cart}
          proceedStep={proceedStep}
          canProceed={canProceed}
          backStep={backStep}
          nextStepLabel={nextStepLabel}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  addAddonToCart,
  removeAddonFromCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOffers(AddonsSelection)));
