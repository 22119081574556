import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerMemberships from '~/queries/customerCommunication/requestGetHeroPlannerMemberships';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type MembershipsParams = NonNullable<
  ValueOf<
    CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitySegmentCadenceMembershipsRecords']
  >['params']
>;

const fetchHeroPlannerMemberships =
  (
    brandId: string,
    countryGroupId: string,
    countryId: string,
    stateId: string,
    cityId: string,
    segmentId: string,
    cadenceId: string,
  ): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();

    const params = [brandId, countryGroupId, countryId, stateId, cityId, segmentId, cadenceId] as MembershipsParams;
    const key = getRecordKeyFor(...params);
    const existing = appState.customerCommunication.countryGroupCountryStateCitySegmentsRecords[key];
    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FETCHING,
        params,
      });
      try {
        const memberships = await requestGetHeroPlannerMemberships(...params);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FULFILLED,
          params,
          memberships,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerMemberships;
