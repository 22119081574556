import styled from 'styled-components';

import { themes } from '../../../../consts/experiences';
import { Spacing, margin, padding } from '../../helpers';

type BadgeProps = {
  color?: string;
  radius?: string;
  background?: string;
  lineHeight?: string;
  borderColor?: string;
} & Spacing;

export const BadgeWrapper = styled.div<BadgeProps>`
  max-width: 110px;

  > span {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border-radius: ${({ radius }) => radius || '50px'};

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    color: ${({ color }) => color || themes?.white};
    line-height: ${({ lineHeight }) => lineHeight || '20px'};
    background-color: ${({ background }) => background || themes?.primaryBlue};

    margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

    padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr }, '5px 10px')};

    border: 1px solid
      ${({ background, borderColor }) => (borderColor ? borderColor : background || themes?.primaryBlue)};
  }
`;
