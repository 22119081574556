import { ROOM_RATE_LPC_TYPE } from '~/consts/ratePlans';
import ReservationService from '../../../../../services/ReservationService';
import {
  COPY_OPERATION_SURCHARGES,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM,
  COPY_OPTIONS_MODE_ALL_RATES,
  COPY_OPTIONS_MODE_ALL_ROOM_TYPES,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY,
} from '../../constants';

const NO_RATES_MESSAGE = 'no room rates found for this option!';
const NO_EGS_MESSAGE = 'no extra guest surcharge found to copy!';

type CommonCopyMode = 'all_LPC_rates_for_room' | 'all_LPC_rates_for_property' | 'allRoomTypes' | 'allRates';

interface CopyExtraGuestSurchargesParams {
  // Copy mode
  mode: CommonCopyMode;
  // Data
  surcharges: Array<App.ExtraGuestSurcharges>;
  propertyId: string;
  propertyRoomTypes: Array<App.RoomType>;
  roomRate: App.RoomRate;
  roomType: App.RoomType;
}

async function copyExtraGuestsurcharges(params: CopyExtraGuestSurchargesParams) {
  const { mode } = params;

  switch (mode) {
    // LPC modes
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM: {
      await copyRatesForRoomAndProductType(params, ROOM_RATE_LPC_TYPE);
      break;
    }
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY: {
      await copyRatesForPropertyAndProductType(params, ROOM_RATE_LPC_TYPE);
      break;
    }
    // Rates / Room Types
    case COPY_OPTIONS_MODE_ALL_ROOM_TYPES: {
      await copyToRoomTypes(params);
      break;
    }
    case COPY_OPTIONS_MODE_ALL_RATES:
    default: {
      await copyToRates(params);
      break;
    }
  }
}
// across rates for a product type under this room
const copyRatesForRoomAndProductType = async (params: CopyExtraGuestSurchargesParams, productType: string) => {
  const { propertyId, surcharges, roomType, roomRate } = params;
  const roomRatesLPC = roomType.room_rates.filter(
    (roomTypeRoomRate) =>
      roomTypeRoomRate.id !== roomRate.id && roomTypeRoomRate.rate_plan.product_type === productType,
  );

  try {
    await callCreateOperationInBulk(roomRatesLPC, surcharges, propertyId, roomType.id);
  } catch (e) {
    throw new Error(
      `Error while copying extra guest surcharges across all ${productType} rates under this room: ${e.message}`,
    );
  }
};

// across all rates for a product type under this property
const copyRatesForPropertyAndProductType = async (params: CopyExtraGuestSurchargesParams, productType: string) => {
  const { propertyId, propertyRoomTypes, surcharges, roomRate } = params;
  const propertyRoomRates = propertyRoomTypes
    .map((_roomType) => {
      return _roomType.room_rates
        .filter((_roomRate) => _roomRate.id !== roomRate.id && _roomRate.rate_plan.product_type === productType)
        .map((rate) => {
          return {
            id: rate.id,
            roomTypeId: _roomType.id,
            rate_plan: null,
          };
        });
    })
    .flat();

  try {
    await Promise.all(
      propertyRoomRates.map(async (_roomRate) => {
        await ReservationService.createOperationInBulk(
          surcharges,
          propertyId,
          _roomRate.roomTypeId,
          _roomRate.id,
          COPY_OPERATION_SURCHARGES,
        );
      }),
    );
  } catch (e) {
    throw new Error(
      `Error while copying extra guest surcharges across all ${productType} rates under this property: ${e.message}`,
    );
  }
};

// allRoomTypes - across all room types for this rate
const copyToRoomTypes = async (params: CopyExtraGuestSurchargesParams) => {
  const { propertyRoomTypes, roomType, roomRate, surcharges, propertyId } = params;
  const roomTypes = propertyRoomTypes.filter((_roomType) => {
    return _roomType.id !== roomType.id;
  });

  const ratePlanId = roomRate.rate_plan.id;

  try {
    await Promise.all(
      roomTypes.map(async (_roomType) => {
        let roomRateId;
        for (const _roomRate of _roomType.room_rates) {
          if (_roomRate.rate_plan.id === ratePlanId) {
            roomRateId = _roomRate.id;
            break;
          }
        }

        if (roomRateId) {
          await ReservationService.createOperationInBulk(
            surcharges,
            propertyId,
            _roomType.id,
            roomRateId,
            COPY_OPERATION_SURCHARGES,
          );
        }
      }),
    );
  } catch (e) {
    throw new Error(`Error while copying extra guest inclusions over room types: ${e.message}`);
  }
};

// all_LPC_rates_for_room - allRates - across all rates under this room
const copyToRates = async (params: CopyExtraGuestSurchargesParams) => {
  const { propertyId, surcharges, roomType, roomRate } = params;

  const roomRates = roomType.room_rates.filter((_roomRate) => {
    return _roomRate.id !== roomRate.id;
  });

  try {
    await callCreateOperationInBulk(roomRates, surcharges, propertyId, roomType.id);
  } catch (e) {
    throw new Error(`Error while copying extra guest surcharges under this room: ${e.message}`);
  }
};

const validate = (rates, surcharges) => {
  if (!rates.length) {
    throw new Error(NO_RATES_MESSAGE);
  }
  if (!surcharges.length) {
    throw new Error(NO_EGS_MESSAGE);
  }
};

async function callCreateOperationInBulk(roomRates, surcharges, propertyId, roomTypeId) {
  validate(roomRates, surcharges);

  await Promise.all(
    roomRates.map(async (roomRate) => {
      await ReservationService.createOperationInBulk(
        surcharges,
        propertyId,
        roomTypeId,
        roomRate.id,
        COPY_OPERATION_SURCHARGES,
      );
    }),
  );
}

export default copyExtraGuestsurcharges;
