import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

function ConfirmCustomOfferRefundModal({ open, onClose, onConfirm }: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Important notice</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <DialogContentText>
          This will not cancel the order in any 3rd party portal. This needs to be done separately otherwise Luxury
          Escapes remains liable for payment.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={onConfirm}>
          I understand, submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmCustomOfferRefundModal;
