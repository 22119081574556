import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { PRODUCT_TYPES_OPTIONS, RuleCondition } from '~/consts/agentHub';

interface Props {
  setCreatedConditions: (condition: RuleCondition) => void;
}

function AgentHubCommissionsProductTypeInput(props: Props) {
  const { setCreatedConditions } = props;
  const [selectedProductType, setSelectedProductType] = useState<Array<string>>([]);

  function handleSaveRule() {
    setCreatedConditions({
      type: 'productType',
      value: selectedProductType.map((productType) => ({
        value: productType,
        label: productType,
      })),
    });
  }

  function handleChange(event: SelectChangeEvent<string[] | string>) {
    const {
      target: { value },
    } = event;

    setSelectedProductType(typeof value === 'string' ? value.split(',') : value);
  }

  function renderValueText() {
    if (selectedProductType.length === 1) {
      return selectedProductType[0];
    }

    return `${selectedProductType.length} Products Selected`;
  }

  return (
    <Stack gap={2}>
      <FormControl fullWidth>
        <InputLabel id="agent-hub-commissions-product-type-input-label">Product Type</InputLabel>
        <Select
          labelId="agent-hub-commissions-product-type-input-label"
          multiple
          value={selectedProductType}
          onChange={handleChange}
          input={<OutlinedInput label="Product Type" />}
          renderValue={renderValueText}
        >
          {PRODUCT_TYPES_OPTIONS.map((productType) => (
            <MenuItem key={productType} value={productType}>
              <Checkbox checked={selectedProductType.indexOf(productType) > -1} />
              <ListItemText primary={productType} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button fullWidth onClick={handleSaveRule} variant="contained">
        Save Condition
      </Button>
    </Stack>
  );
}

export default AgentHubCommissionsProductTypeInput;
