import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { json_headers, request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerSchedulePostContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/send-test-email']['post'];

function mapNewExternalHeroPlannerTestEmailPayload(
  brand: string,
  scheduleId: string,
  subjectLine: string,
  recipientEmails: Array<string>,
): HeroPlannerSchedulePostContract['parameters']['body']['payload'] {
  return {
    // @ts-expect-error string doesn't satisfy the list of possible string values
    brand,
    scheduleId,
    subjectLine,
    recipientEmails,
  };
}

async function requestPostHeroPlannerTestEmail(
  brand: string,
  scheduleId: string,
  subjectLine: string,
  recipientEmails: Array<string>,
) {
  if (!scheduleId) throw 'missing scheduleId';
  if (!subjectLine) throw 'missing subjectLine';
  if (!recipientEmails.length) throw 'missing recipientEmails';

  const url = makeCustomerCommunicationV1URL('/todays-escapes-schedule/hero-planner/send-test-email');

  return request(url.toString(), {
    method: 'POST',
    credentials: 'include',
    headers: json_headers,
    body: JSON.stringify(mapNewExternalHeroPlannerTestEmailPayload(brand, scheduleId, subjectLine, recipientEmails)),
  })
    .then(() => {
      return undefined;
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestPostHeroPlannerTestEmail;
