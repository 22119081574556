import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import useToggleState from '~/hooks/useToggleState';

import { getOrderItemInsuranceLetterPDF, updateRefundRequest } from '~/services/OrdersService';
import { formatDateISO } from '~/services/TimeService';

interface InsuranceLetterProps {
  item: App.AnyItem;
  order: App.Order;
  offer?: App.Offer;
  refundRequest: RefundRequest;
}

const getOrderInsuranceLetterFilename = (order: App.Order) => {
  return `Insurance-Letter-${formatDateISO(order.created_at)}-${order.number}.pdf`;
};

function InsuranceLetter({ item, order, offer, refundRequest }: InsuranceLetterProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { isToggled, toggle, toggleOff } = useToggleState(false);
  const [fetching, setFetching] = useState(false);

  const handleDownload = async () => {
    try {
      setFetching(true);
      const blob = await getOrderItemInsuranceLetterPDF(order, item, refundRequest, offer);
      const fileName = getOrderInsuranceLetterFilename(order);
      fileDownload(blob, fileName);
      enqueueSnackbar('Insurance letter has been downloaded successfully.', { variant: 'success' });
    } catch {
      enqueueSnackbar('Your insurance letter could not be downloaded. Please contact us for support.', {
        variant: 'error',
      });
    } finally {
      setFetching(false);
    }
    toggleOff();
  };

  const cancelItem = async () => {
    try {
      setFetching(true);
      await updateRefundRequest(refundRequest.id_refund_request, {
        op: 'update_refund_option',
        refund_option: 'letter',
      });
      enqueueSnackbar('Item was successfully cancelled.', { variant: 'success' });
      await handleDownload();
      location.reload();
    } catch (error) {
      enqueueSnackbar(`An error occrued ${error.mesage}`, { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        onClick={item.status === 'cancelled' ? handleDownload : toggle}
        title="CS often need to send this info to vendors. This makes it easy to copy/paste."
      >
        Download Insurance Letter
      </Button>
      <Dialog
        open={isToggled}
        onClose={toggleOff}
        maxWidth="md"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Are you sure you would like to proceed?
          <IconButton aria-label="close" onClick={toggleOff} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography id="dialog-description" sx={{ mt: 2 }}>
            Downloading this insurance letter will cancel the booking, and the customer will no longer be eligible for
            refund.
          </Typography>
        </DialogContent>
        <DialogActions>
          {!fetching && (
            <Stack width="100%" direction="row" justifyContent="space-between">
              <Button variant="outlined" color="secondary" onClick={handleDownload}>
                Only Download Insurance Letter
              </Button>
              <Button variant="outlined" color="error" size="small" onClick={cancelItem}>
                Cancel Item and Download Insurance Letter
              </Button>
            </Stack>
          )}
          {fetching && (
            <Box justifyContent="center" width="100%">
              <Spinner size={48} />
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InsuranceLetter;
