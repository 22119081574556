import React from 'react';

import { Box, Typography } from '@mui/material';

import currencyFormatter from '~/utils/currencyFormatter';

import { SurchargeSuggestionBQ } from '../types';

export default function StatisticsFormatter({ row }: { row: SurchargeSuggestionBQ }) {
  return (
    <Box>
      <Typography variant="body1">
        <b>Sales last 3 days:</b> {row.sales_last_3_days}
      </Typography>
      <Typography variant="body1">
        <b>Booked / Allocated:</b> {row.booked + ' / ' + row.allocated}
      </Typography>
      <Typography variant="body1">
        <b>Current Vendor Surcharge:</b> {currencyFormatter('AUD', row.vendor_surcharge_total, 2)}
      </Typography>
      <Typography variant="body1">
        <b>Current Internal Surcharge:</b> {currencyFormatter('AUD', row.internal_surcharge_total, 2)}
      </Typography>
      <Typography variant="body1">
        <b>Current Base Nightly Price:</b> {currencyFormatter('AUD', row.nightly_price_aud, 2)}
      </Typography>
      <Typography variant="body1">
        <b>Suggested Multiplier:</b> {row.multiplier}
      </Typography>
      <Typography variant="body1">
        <b>Current Nightly Price:</b> {currencyFormatter('AUD', row.before_price, 2)}
      </Typography>
      <Typography variant="body1">
        <b>Suggested Nightly Price:</b> {currencyFormatter('AUD', row.after_price, 2)}
      </Typography>
      <Typography variant="body1">
        <b>Suggested Surcharge:</b> {currencyFormatter('AUD', row.after_price - row.before_price, 2)}
      </Typography>
    </Box>
  );
}
