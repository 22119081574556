import React, { useCallback, useEffect, useState } from 'react';

import { Button, Stack } from '@mui/material';

import { Brand } from '@luxuryescapes/lib-regions/lib/regions';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { LUX_PLUS } from '~/consts/membership';
import { ROLE_ADMIN_USER, ROLE_HOTEL_COORDINATOR } from '~/consts/roles';
import { LUX_PLUS_SCHEDULE } from '~/consts/schedule';

import { allRegions } from '../../../../consts/allRegions';
import OffersService from '../../../../services/OffersService';
import { buttonMessages, buttonStates } from '../../../Common/Forms/states/submitButton';
import ScheduleTableRow from '../common/ScheduleTableRow';
import { ScheduleUpdateResult } from '../common/types';
import { getRegionName, updateResultToErrorMessage } from '../common/utils';

interface Props {
  brand: Brand;
  schedules: App.Schedule[];
  types: string[];
  shouldMigrateSchedule: boolean;
  onSaveFinished: () => void;
  onDelete: (id: number) => void;
  onError: (error: Error) => void;
  onUpdateErrors: (error: string[]) => void;
  offerId: string;
}

interface ScheduleState {
  modified: boolean;
  schedule: App.Schedule;
}

async function updateSchedules(
  scheduleStatesByType: Record<string, ScheduleState[]>,
  offerId: string,
): Promise<ScheduleUpdateResult[]> {
  const scheduleStatesToUpdate = Object.values(scheduleStatesByType)
    .flat()
    .filter((s) => s.modified);

  const updates = scheduleStatesToUpdate.map((s) => {
    const { id, start, end, region, restrict_purchase_to_lux_plus_members, brand, type } = s.schedule;
    const updatePayload = {
      start,
      end,
      region,
      brand,
      type,
      restrict_purchase_to_lux_plus_members,
    };
    return OffersService.updateSchedule(updatePayload, offerId, id);
  });

  const results = await Promise.allSettled(updates);

  return results.map((result, i) => ({
    label: getRegionName(scheduleStatesToUpdate[i].schedule.region),
    result,
  }));
}

function scheduleRegionNameCompare(a: App.Schedule, b: App.Schedule) {
  return getRegionName(a.region).localeCompare(getRegionName(b.region));
}

export default function AgentHubScheduleTable(props: Props) {
  const {
    brand = 'luxuryescapes',
    schedules,
    types,
    shouldMigrateSchedule,
    onSaveFinished,
    onDelete,
    onUpdateErrors,
    offerId,
  } = props;

  const [saveState, setSaveState] = useState(buttonStates.default);
  const [leScheduleStatesByType, setLeScheduleStatesByType] = useState<Record<string, ScheduleState[]>>({});

  const cleanStateByType = useCallback(() => {
    const leScheduleStatesByType = types.reduce((acc, type) => {
      const leScheduleStates = schedules
        .filter((s) => s.type === type && s.brand === brand)
        .sort(scheduleRegionNameCompare)
        .map((schedule) => ({
          modified: false,
          schedule: { ...schedule },
        }));
      return { ...acc, [type]: leScheduleStates };
    }, {});
    setLeScheduleStatesByType(leScheduleStatesByType);
  }, [schedules, types]);

  useEffect(cleanStateByType, [schedules, types, cleanStateByType]);

  const onScheduleChange = useCallback(
    (schedule: App.Schedule, type: string) => {
      const newLeScheduleStates = leScheduleStatesByType[type].map((s) => {
        if (s.schedule.id === schedule.id) {
          return { modified: true, schedule };
        }
        return s;
      });

      setLeScheduleStatesByType({ ...leScheduleStatesByType, [type]: newLeScheduleStates });
    },
    [leScheduleStatesByType, setLeScheduleStatesByType],
  );

  const anyModified =
    Object.values(leScheduleStatesByType)
      .flat()
      .filter((s) => s.modified).length > 0;

  const saveChanges = useCallback(async () => {
    setSaveState(buttonStates.saving);

    const updateResults = await updateSchedules(leScheduleStatesByType, offerId);
    onSaveFinished();

    if (updateResults.some((r) => r.result.status === 'rejected')) {
      const updateErrorMessages = updateResults.map(updateResultToErrorMessage).filter((m) => m);
      onUpdateErrors(updateErrorMessages);
    }

    setSaveState(buttonStates.default);
  }, [leScheduleStatesByType, offerId, onUpdateErrors, onSaveFinished]);

  const saving = saveState === buttonStates.saving;

  return (
    <div>
      <Stack direction="column" gap={8} alignItems="flex-start">
        {types.map((type) => {
          const leScheduleStates = leScheduleStatesByType[type] ?? [];

          return (
            <table className="fp-schedule-table" key={type}>
              <thead>
                <tr>
                  <th>Country</th>
                  <th>{type === LUX_PLUS_SCHEDULE && `${LUX_PLUS.PROGRAM_NAME} `}Schedule</th>
                </tr>
              </thead>
              <tbody>
                {leScheduleStates
                  .filter(({ schedule }) => allRegions.find((r) => r.code === schedule.region))
                  .map(({ schedule, modified }) => (
                    <ScheduleTableRow
                      label={allRegions.find((r) => r.code === schedule.region).name}
                      onChange={(schedule) => onScheduleChange(schedule, type)}
                      type={type}
                      isModified={modified}
                      key={schedule.id}
                      schedule={schedule}
                      disabled={shouldMigrateSchedule || saving}
                      onDelete={() => onDelete(schedule.id)}
                    />
                  ))}
              </tbody>
            </table>
          );
        })}
      </Stack>

      <div className="fp-schedule-buttons">
        <Button onClick={cleanStateByType} disabled={!anyModified || saving}>
          Clear changes
        </Button>
        <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_HOTEL_COORDINATOR]}>
          <Button
            variant="contained"
            onClick={saveChanges}
            disabled={!anyModified || saveState !== buttonStates.default}
          >
            {buttonMessages[saveState]}
          </Button>
        </PermissionedComponent>
      </div>
    </div>
  );
}
