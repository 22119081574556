import { Request } from './types';

export const GENERIC_INITIAL_REQUEST_STATE = {
  result: null,
  loading: false,
};

export const INITIAL_REQUEST_STATE: Request<[]> = {
  result: [],
  loading: false,
};
