import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerOfferDetails from '~/queries/customerCommunication/requestGetHeroPlannerOfferDetails';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type OfferDetailsParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['offerDetailsRecords']>['params']
>;

const fetchHeroPlannerOfferDetails =
  (
    brand: string,
    countryGroupId: string,
    countryId: string,
    offerIdToFetch: string | Array<string>,
  ): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();
    const offerIds = Array.isArray(offerIdToFetch) ? offerIdToFetch : [offerIdToFetch];

    for (const offerId of offerIds) {
      const params = [brand, countryGroupId, countryId, offerId] as OfferDetailsParams;
      const key = getRecordKeyFor(...params);
      const existing = appState.customerCommunication.offerDetailsRecords[key];
      if (!existing || isRequestInitial(existing)) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_OFFER_DETAILS_FETCHING,
          params,
        });
        try {
          const result = await requestGetHeroPlannerOfferDetails(...params);
          dispatch({
            type: CustomerCommunicationActionTypes.SET_OFFER_DETAILS_FULFILLED,
            params,
            offerDetails: result,
          });
        } catch (error) {
          dispatch({
            type: CustomerCommunicationActionTypes.SET_OFFER_DETAILS_REJECTED,
            params,
            error,
          });
        }
      }
    }
  };

export default fetchHeroPlannerOfferDetails;
