import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerOfferVisibilities from '~/queries/customerCommunication/requestGetHeroPlannerOfferVisibilities';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { arrayToObject } from '~/utils/arrayUtils';
import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type OfferVisibilitiesParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['offerVisibilitiesRecords']>['params']
>;

const fetchHeroPlannerOfferVisibilities =
  (
    brand: string,
    countryGroupId: string,
    countryId: string,
    offerIdToFetch: string,
  ): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();
    const params = [brand, countryGroupId, countryId, offerIdToFetch] as OfferVisibilitiesParams;
    const key = getRecordKeyFor(...params);
    const existingVisibilities = appState.customerCommunication.offerVisibilitiesRecords[key];

    if (!existingVisibilities || isRequestInitial(existingVisibilities)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_FETCHING,
        params,
      });

      try {
        const result = await requestGetHeroPlannerOfferVisibilities(...params);
        const records = arrayToObject(result, (i) => i.countryId);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_FULFILLED,
          params,
          offerVisibilities: records,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerOfferVisibilities;
