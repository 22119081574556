import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container } from '@mui/material';

import UsersService from '~/services/UsersService';

import PageHeader from '../Common/Elements/PageHeader';

import PermissionsUsers from './PermissionsUsers';

export default function PermissionsContainer() {
  const history = useHistory();
  const { role } = useParams<{ role: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [roleLabel, setRoleLabel] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(0);
  const [sizePerPage] = useState(10);

  useEffect(() => {
    setLoading(true);

    UsersService.getUsersByRole(role, page + 1)
      .then((data) => {
        setUsers(data.result);
        setTotalSize(data.total);
        setRoleLabel(data.result[0].inline_role_label);
      })
      .catch(() => {
        enqueueSnackbar('Error loading users', { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [page, role, enqueueSnackbar]);

  return (
    <Container maxWidth="xl">
      <Button startIcon={<ChevronLeftIcon />} onClick={history.goBack}>
        Back
      </Button>

      <PageHeader title={'Permissions' + (roleLabel ? `: ${roleLabel}` : '')} />

      <PermissionsUsers
        role={role}
        isLoading={isLoading}
        users={users}
        page={page}
        sizePerPage={sizePerPage}
        total={totalSize}
        onPageChange={(page) => setPage(page)}
      />
    </Container>
  );
}
