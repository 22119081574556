import pluralize from 'pluralize';
import dayjs from '~/timeInit';

import { QantasPartnership } from '~/consts/partnerships';

import { request } from './common';

const basePath = () => window.configs.API_HOST + '/api/';

const isUpperCase = (aCharacter) => aCharacter >= 'A' && aCharacter <= 'Z';

export const accountFieldsMap = (partnership) => {
  let data = [];

  for (let field of partnership.accountFields) {
    if (field === 'account_id') {
      data.push({
        name: 'Account',
        field: `${partnership.prefix}_number`,
        accountField: field,
      });
    } else if (field === 'last_name') {
      data.push({
        name: 'Surname',
        field: `${partnership.prefix}_surname`,
        accountField: field,
      });
    } else if (field === 'first_name') {
      data.push({
        name: 'GivenName',
        field: `${partnership.prefix}_given_name`,
        accountField: field,
      });
    }
  }

  return data;
};

export const getBonusUnitPluralize = (partnership, number = 0) => {
  return pluralize(partnership.bonusUnit, number);
};

export const convertCodeToSlug = (code) => {
  let word = [];

  for (let count = 0; count < code.length; count++) {
    let letter = code.charAt(count);
    if (isUpperCase(letter)) {
      word.push(`-${letter.toLowerCase()}`);
    } else {
      word.push(letter);
    }
  }

  return word.join('');
};

export const getPartnerships = async (region = '', brand = 'luxuryescapes') => {
  const response = await request(`${basePath()}loyalty/partnerships?region=${region}&brand=${brand}`, {
    method: 'GET',
  });

  // We are adding Qantas to the list of partnerships for the time being in order to view reports.
  response.result.push(QantasPartnership);
  response.count++;

  return response;
};

export const getPartnershipByCode = async (code) => {
  return request(`${basePath()}loyalty/partnerships/${code}`, {
    method: 'GET',
  });
};

export const getLoyaltyBurn = async (id_member, code, brand) => {
  return request(`${basePath()}loyalty/${convertCodeToSlug(code)}/burn?member_id=${id_member}&brand=${brand}`, {
    method: 'GET',
  });
};

export const getLoyaltyEarn = async (id_member, code, brand) => {
  return request(`${basePath()}loyalty/${convertCodeToSlug(code)}?member_id=${id_member}&brand=${brand}`, {
    method: 'GET',
  });
};

export const getEarnOrder = (order, allPartnerships) => {
  let data = [];
  for (let partnership of allPartnerships) {
    let orderPartnership = order.partnerships && order.partnerships[partnership.prefix];
    if (orderPartnership) {
      data.push(
        new Promise((resolve, reject) => {
          request(
            `${basePath()}loyalty/${convertCodeToSlug(partnership.code)}?member_id=${order.fk_customer_id}&order_id=${
              order.id_orders
            }&brand=${order.brand}`,
            { method: 'GET' },
          )
            .then((result) => {
              resolve({
                prefix: partnership.prefix,
                code: partnership.code,
                result: result.result,
              });
            })
            .catch(reject);
        }),
      );
    }
  }

  return data;
};

export function earnData(data, partnership) {
  const accountFields = accountFieldsMap(partnership);
  let postData = {};

  if (partnership.prefix === 'qff') {
    postData = {
      points_base_multiplier: data.points_base_multiplier,
      has_bonus_multiplier: data.has_bonus_multiplier,
      points_bonus_multiplier: data.points_bonus_multiplier,
    };
  } else if (partnership.prefix === 'kfp') {
    postData = {
      miles_base_earned: data.base_points,
      miles_bonus_earned: data.bonus_points,
      miles_total_earned: data.base_points + data.bonus_points,
    };
  }

  accountFields.forEach((fieldData) => {
    postData[fieldData.field] = data[fieldData.accountField] || '';
  });

  return {
    member_id: data.member_id,
    member_given_name: data.member_given_name,
    member_surname: data.member_surname,
    item_id: data.item_id,
    order_id: data.order_id,
    order_item_name: data.order_item_name,
    order_date: data.order_date,
    order_payment_amount: parseInt(data.points_requested),
    currency_code: data.currency_code,
    request_by: data.request_by,
    request_reason: data.request_reason,
    request_comment: data.request_comment,
    ...postData,
  };
}

export async function postEarnData(data, code) {
  return request(`${basePath()}loyalty/${convertCodeToSlug(code)}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

export function determineLoyaltyStatus(order, allPartnerships) {
  const orderDate = dayjs(order.created_at).tz('Australia/Sydney').format('YYYY-MM-DD');
  const launchDate = dayjs('01/02/2018', 'DD/MM/YYYY');
  const isBeforeLaunch = dayjs(orderDate).isBefore(launchDate);
  const isCompletedPending = order.status === 'completed' || order.status === 'pending';

  const eligibleDate = dayjs().tz('Australia/Sydney').subtract(30, 'days').format('YYYY-MM-DD');
  const isPostThirtyDays = dayjs(orderDate).isBefore(eligibleDate);
  const isEligible = !isBeforeLaunch && isCompletedPending;

  let data = {};
  for (let partnership of allPartnerships) {
    let orderPartnership = order.partnerships && order.partnerships[partnership.prefix];
    if (orderPartnership) {
      data[partnership.prefix] = {
        prefix: partnership.prefix,
        code: partnership.code,
        isEligible: isEligible,
        isPostThirtyDays: isPostThirtyDays,
        isCompletedPending: isCompletedPending,
        message: '',
      };

      if (isBeforeLaunch || !isCompletedPending) {
        data[partnership.prefix].message = `This order does not qualify for ${partnership.rewardName}`;
      } else if (!isPostThirtyDays) {
        data[partnership.prefix].message = `This order is still within the 30-day waiting period`;
      }
    }
  }

  return data;
}
