import React from 'react';

import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

const giftCardOrderLinkFormatter = (params: GridRenderCellParams<Order.Order>) => {
  return (
    <Button variant="text" size="small" href={`/purchases/${params.row.id}/gift-card`} target="_blank" rel="noreferrer">
      Order Detail
    </Button>
  );
};

export default giftCardOrderLinkFormatter;
