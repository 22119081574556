import React, { useCallback, useState } from 'react';

import { TextField, TextFieldProps } from '@mui/material';

import { Spinner } from '..';
import * as S from './styles';

type FieldSearchProps = {
  options?: string[];
  labelName?: string;
  onChange: (value: string) => void;
  onClickOption: (option: string) => void;
  showOptions?: boolean;
} & Omit<TextFieldProps, 'onChange'>;

export const FieldSearch: React.FC<FieldSearchProps> = (props) => {
  const {
    id,
    name,
    value,
    options,
    onChange,
    labelName,
    placeholder,
    onClickOption,
    showOptions = true,
    ...rest
  } = props;

  const [selected, setSelected] = useState<boolean>(false);

  const onClick = useCallback((item: string) => {
    setSelected(true);
    onClickOption(item);
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (selected) setSelected(false);
      onChange(e.target.value);
    },
    [onChange, selected],
  );

  return (
    <S.FieldWrapper>
      {labelName && <S.Label htmlFor={id}>{labelName}</S.Label>}
      <TextField {...rest} id={id} name={name} value={value} onChange={handleChange} placeholder={placeholder} />

      {!selected && value && showOptions && (
        <S.OptionsWrapper>
          {options.length === 0 && (
            <div className="center">
              <Spinner size={25} />
            </div>
          )}

          {options.length > 0 &&
            options.map((item: string, i: number) => (
              <div onClick={() => onClick(item)} key={`${i}-${item}`}>
                {item}
              </div>
            ))}
        </S.OptionsWrapper>
      )}
    </S.FieldWrapper>
  );
};
