export const FINANCE_CURRENCIES = [
  'AED',
  'AUD',
  'AZN',
  'BGN',
  'BHD',
  'BRL',
  'BWP',
  'CAD',
  'CHF',
  'CNY',
  'COP',
  'CUC',
  'CZK',
  'DKK',
  'DOP',
  'EGP',
  'EUR',
  'FJD',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JOD',
  'JPY',
  'KHR',
  'KRW',
  'KWD',
  'KZT',
  'LSL',
  'MAD',
  'MOP',
  'MXN',
  'MYR',
  'NAD',
  'NZD',
  'OMR',
  'PGK',
  'PHP',
  'PLN',
  'QAR',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TND',
  'TRY',
  'TWD',
  'USD',
  'UZS',
  'VND',
  'VUV',
  'XCD',
  'XPF',
  'ZAR',
  'ZMW',
] as const;
