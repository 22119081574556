import {
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
  VARIANT_OF_REFUND_ALL,
  VARIANT_OF_REFUND_SURCHARGE,
} from '~/consts/refund';

import { Refund } from '../NewModal';

export function hasRefundSurcharge(refund: Refund) {
  const isFullRefund = refund.variant_of_refund === VARIANT_OF_REFUND_ALL;

  const isLateChangeOfMindAndFullRefund =
    refund.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS && isFullRefund;

  return (
    refund.has_surcharge &&
    (isLateChangeOfMindAndFullRefund ||
      (isFullRefund && refund.is_refunded_surcharge) ||
      refund.variant_of_refund === VARIANT_OF_REFUND_SURCHARGE)
  );
}
