import React, { Context, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { CruisesContract as Api } from '@luxuryescapes/contract-svc-cruise';

import promoBannerService from '~/services/cruises/PromoBannerService';

import { parseSearchString } from '~/utils/url';

interface Filter {
  name?: string;
  cruiseLine?: string;
}

interface CruisesData {
  promoBanners: Api.PromoBanner[];
  page: number;
  total: number;
  loading: boolean;
  promoBannersPerPage: number;
  paginate: (page: number) => void;
  setFilter: (value: Filter) => void;
  filter: Filter;
  getPromoBannerList: () => Promise<void>;
}

interface CruisePromoBannerProviderProps {
  children?: React.ReactNode;
}

export const CruisePromoBannerContext: Context<CruisesData> = createContext({} as CruisesData);

const promoBannersPerPage = 15;

export const CruisePromoBannerProvider = ({ children }: CruisePromoBannerProviderProps): JSX.Element => {
  const [filter, setFilter] = useState<Filter>({});
  const { search } = useLocation();
  const { push: setQueryString } = useHistory();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [promoBanners, setPromoBanners] = useState<Api.PromoBanner[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getPromoBannerList = useCallback(async () => {
    const skip = (page - 1) * promoBannersPerPage;
    setLoading(true);
    const res = await promoBannerService.listWithPagination({
      skip,
      take: promoBannersPerPage,
    });
    setPromoBanners(res.result);
    setTotal(res.total);
    setLoading(false);
  }, [page]);

  const setCurrentPage = useCallback((): void => {
    const queryString = parseSearchString(search);
    if (queryString) {
      const pageToGo = parseInt(queryString.page as string);
      if (pageToGo) setPage(pageToGo);
    }
  }, [search]);

  const paginate = useCallback(
    (page: number): void => {
      const queryString = new URLSearchParams({
        page: page.toString(),
      }).toString();
      setQueryString({ search: queryString });
      setPage(page);
    },
    [setQueryString],
  );

  useEffect(() => {
    setCurrentPage();
  }, [setCurrentPage]);

  useEffect(() => {
    getPromoBannerList();
  }, [getPromoBannerList]);

  return (
    <CruisePromoBannerContext.Provider
      value={{
        filter,
        setFilter,
        promoBanners,
        page,
        total,
        promoBannersPerPage,
        paginate,
        getPromoBannerList,
        loading,
      }}
    >
      {children}
    </CruisePromoBannerContext.Provider>
  );
};

export const useCruisePromoBanners = (): CruisesData => useContext(CruisePromoBannerContext);
