import UsersService from '~/services/UsersService';

export const FETCH_USER_START = 'user/FETCH_USER_START';
export const FETCH_USER_SUCCEED = 'user/FETCH_USER_SUCCEED';
export const FETCH_USER_FAIL = 'user/FETCH_USER_FAIL';

function startFetchingUser() {
  return { type: FETCH_USER_START };
}

function succeedFetchingUser(user) {
  return { type: FETCH_USER_SUCCEED, user };
}

function failFetchingUser(error) {
  return { type: FETCH_USER_FAIL, error };
}

export function fetchUserData(userId) {
  return (dispatch) => {
    dispatch(startFetchingUser());
    return UsersService.getUser(userId)
      .then((user) => {
        dispatch(succeedFetchingUser(user));
      })
      .catch((error) => {
        dispatch(failFetchingUser(error));
      });
  };
}
