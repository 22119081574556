import React from 'react';

import currencyFormatter from 'currency-formatter';

import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import { Grid, Stack, Typography } from '@mui/material';

const renderTravellerNumSummary = (travellers) => {
  let numAdults = 0;
  let numChildren = 0;
  let numInfants = 0;
  travellers.forEach((traveller) => {
    switch (traveller.type) {
      case 'Adult':
        numAdults += 1;
        break;
      case 'Child':
        numChildren += 1;
        break;
      case 'Infant':
        numInfants += 1;
        break;
    }
  });
  return (
    <Stack direction="row" sx={{ mt: 1, alignItems: 'center' }}>
      <PersonIcon />
      <Typography variant="body1" as="span" className="data-hj-whitelist">
        {` ${numAdults} `} adults,
      </Typography>
      <Typography variant="body1" as="span" className="data-hj-whitelist">
        {` ${numChildren}`} children,
      </Typography>
      <Typography variant="body1" as="span" className="data-hj-whitelist">
        {` ${numInfants}`} infants
      </Typography>
    </Stack>
  );
};

const renderTravellerDetails = (travellers, currencyCode) =>
  travellers.map((traveller, index) => (
    <Grid container key={`${traveller.firstName}-${traveller.middleName}-${traveller.lastName}-${index}`}>
      <Grid item sm={10}>
        <Stack direction="row" sx={{ mt: 1, alignItems: 'center' }}>
          <ListAltIcon />
          <Typography variant="body1">
            {` ${traveller.firstName} ${traveller.middleName} ${traveller.lastName} (${traveller.type})`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item sm={2} className="text-right">
        {currencyFormatter.format(traveller.price, {
          code: currencyCode,
          precision: traveller.price % 1 ? 2 : 0,
        })}
      </Grid>
    </Grid>
  ));

const FlightOrderSummaryList = ({ items: [flightItem], currencyCode }) => {
  return (
    <div className="order-summary-panel">
      <div className="offer-meta">
        <div className="offer-title">Flight</div>
      </div>

      <ul className="summary-item-list">
        {flightItem.pnrs.map((pnr, pnrIndex) => (
          <li className="summary-item-item" key={`${pnr}-${pnrIndex}`}>
            <div className="row">
              <div className="col-xs-12">
                <div className="item-detail-container">
                  <div className="item-number">{pnrIndex + 1}</div>
                  <div className="item-detail">
                    <div className="item-name data-hj-whitelist">{`PNR ${pnr.PNR}`}</div>
                    {renderTravellerNumSummary(pnr.travellers)}
                    {renderTravellerDetails(pnr.travellers, currencyCode)}
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="item-amounts">
                  <div className="item-price data-hj-whitelist">
                    {currencyFormatter.format(
                      pnr.travellers.reduce((priceSum, traveller) => priceSum + traveller.price, 0),
                      {
                        code: currencyCode,
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FlightOrderSummaryList;
