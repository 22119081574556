import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import VendorsService from '~/services/VendorsService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter an offer and vendor id to export all bookings',
  type: 'object',
  properties: {
    idOffer: {
      title: 'Offer ID',
      type: 'string',
    },
    idVendor: {
      title: 'Vendor ID',
      type: 'string',
    },
  },
};

const uiSchema = {
  idOffer: {
    'ui:widget': 'text',
  },
  idVendor: {
    'ui:widget': 'text',
  },
};

export default function ExportVendorBookingPaymentsReportForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    VendorsService.downloadPaymentsByBookingVendor({
      idOffer: event.formData.idOffer,
      idVendor: event.formData.idVendor,
      format: 'xlsx',
    })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Download Vendor Booking Payments Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
