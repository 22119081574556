import React from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';

import { Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';

import { property as propertyGlobals } from '@luxuryescapes/lib-global';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

import featureToggle from '~/utils/featureToggle';
import presentRoomType from '~/utils/presentRoomType';
import { reportError } from '~/utils/reportError';

import { DeleteButton } from '../DeleteButton';
import ErrorDisplay from '../ErrorDisplay';

import AmenityForm from './AmenityForm';
import CopyRoomTypeAttributeForm from './CopyRoomTypeAttributeForm';
import ImagesForm from './ImagesForm';
import Amenities from './fields/Amenities';
import { buttonMessages, buttonStates } from './states/submitButton';
import AIGeneratorTextAreaWidget from './widgets/AIGeneratorTextAreaWidget';
import MarkdownEditor from './widgets/MarkdownEditor';

const fields = { amenities: Amenities };

const errorMessage = `This room type is currently attached to an offer. You must
  detach it from any offers before you can delete.`;
export default class RoomTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.vendorId = props.vendorId;
    this.property = props.property;

    this.state = {
      saveState: buttonStates.default,
      deleteState: 'delete',
      showingAmenitiesModal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteRoomType = this.handleDeleteRoomType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggleAmenitiesModal = this.handleToggleAmenitiesModal.bind(this);
    this.getFormPayload = this.getFormPayload.bind(this);
    this.getPossibleAmenityOptions = this.getPossibleAmenityOptions.bind(this);
    this.onAddImage = this.onAddImage.bind(this);
    this.onUpdateImages = this.onUpdateImages.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);

    this.schema = props.schema;

    // there may be a rare case when room inclusions is a text (usually empty)
    const roomInclusions = props.roomType.room_inclusions
      ? Array.isArray(props.roomType.room_inclusions)
        ? props.roomType.room_inclusions.join(', ')
        : props.roomType.room_inclusions
      : '';

    this.uiSchema = {
      property_id: { 'ui:widget': 'hidden' },
      amenities: { 'ui:field': 'amenities' },
      room_inclusions: { 'ui:widget': MarkdownEditor },
      additional_guest_amount_description: { 'ui:widget': MarkdownEditor },
      description: {
        'ui:widget': AIGeneratorTextAreaWidget,
        'ui:generatorContext': {
          propertyName: props.property.name,
          roomName: props.roomType.name,
          amenities: props.roomType.amenities,
          inclusions: roomInclusions,
          model: 'room-type',
          field: 'description',
        },
      },
      amenities_ordering: { 'ui:widget': 'hidden' },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.saveAll !== prevProps.saveAll) {
      this.handleSubmit({
        formData: {
          ...this.props.roomType,
        },
      });
    }
  }

  isUniqueStays() {
    return propertyGlobals.isUniqueStay(this.props.roomType.category);
  }

  // getFormPayload returns a payload ready to be sent to the server for either
  // updating or creating a room type
  getFormPayload(form) {
    const ret = Object.assign({}, form.formData);

    // _id is used internally to identify forms
    delete ret._id;

    return ret;
  }

  // getPossibleAmenityOptions returns an array of all possible options for
  // amenities. Concretely, it combines the built-in amenity options returned by
  // the API and any custom amenity option that the room type has.
  getPossibleAmenityOptions() {
    const { amenityOptions } = this.props;
    const { roomType } = this.props;

    // first, duplicate amenityOptions array
    const ret = amenityOptions.slice();
    roomType.amenities.forEach((amenity) => {
      if (ret.indexOf(amenity) === -1) {
        ret.push(amenity);
      }
    });

    return ret.sort();
  }

  handleSubmit(form) {
    this.setState({ saveState: buttonStates.saving, roomType: form.formData });

    let submitType = 'updateRoomType';

    if (!this.props.roomType.id) {
      submitType = 'createRoomType';
    }

    const propertyId = this.props.roomType.property_id;
    const roomTypeId = this.props.roomType.id;
    const payload = this.getFormPayload(form);
    delete payload.amenities_ordering; // this is not a field we want to send to the server for non-rentals now

    ReservationService[submitType](payload, propertyId, roomTypeId)
      .then((response) => {
        const roomType = Object.assign({}, presentRoomType(response.result), {
          _id: this.props.roomType._id,
        });

        this.setState({
          roomType: roomType,
          saveState: buttonStates.saved,
        });

        this.props.onEditRoomType(roomType);
        this.props.onSaveCompleted();
      })
      .catch((e) => {
        this.props.onSaveFailed();
        this.setState({ saveState: buttonStates.failed });
        reportError(e);
      });
  }

  handleChange(edit) {
    this.props.onEditRoomType(edit.formData);

    if (this.state.saveState !== buttonStates.default) {
      this.setState({
        saveState: buttonStates.default,
      });
    }
  }

  async handleDeleteRoomType() {
    this.setState({ deleteState: 'deleting' });

    const roomTypeInUse = await this.findUsage();

    if (roomTypeInUse) {
      this.setState({ deleteState: 'failed' });
      return false;
    }

    ReservationService.deleteRoomType(this.props.roomType.property_id, this.props.roomType.id)
      .then(() => {
        this.props.onRemoveRoomType(this.props.formKey);
      })
      .catch(function (e) {
        reportError(e);
      });
    this.setState({ deleteState: 'delete' });
  }

  handleToggleAmenitiesModal(nextState) {
    this.setState((prevState) => {
      if (typeof nextState !== 'undefined') {
        return { showingAmenitiesModal: nextState };
      }

      return { showingAmenitiesModal: prevState.showingAmenitiesModal };
    });
  }

  onAddImage(cloudinaryId) {
    return ReservationService.createRoomTypeImage(
      { id_cloudinary_external: cloudinaryId },
      this.props.roomType.property_id,
      this.props.roomType.id,
    );
  }

  onUpdateImages(newImageList) {
    const updatePayload = newImageList.map((image) => ({
      id: image.id,
      id_cloudinary_external: image.id_cloudinary_external,
      order: image.order,
      title: image.title,
      hidden: image.hidden ?? false,
    }));

    return ReservationService.updateRoomTypeImages(
      updatePayload,
      this.props.roomType.property_id,
      this.props.roomType.id,
    );
  }

  onDeleteImage(imageId) {
    return ReservationService.deleteRoomTypeImage(imageId, this.props.roomType.property_id, this.props.roomType.id);
  }

  findUsage = async () => {
    const { properties, offers } = await this.fetchData();

    const relatedRoomTypes = properties.reduce((acc, property) => {
      property?.room_types?.forEach((roomType) => {
        if (roomType?.id === this.props.roomType.id) {
          acc.add(roomType.id);
        }
      });
      return acc;
    }, new Set());

    const typeOffers = offers.filter((offer) => {
      return offer.packages.some(
        (p) =>
          relatedRoomTypes.has(p.fk_room_type_id) ||
          p.package_options?.some((o) => relatedRoomTypes.has(o.fk_room_type_id)),
      );
    });

    return typeOffers.length;
  };

  async fetchData() {
    const [properties, offers] = await Promise.all([
      ReservationService.getProperties(this.vendorId),
      OffersService.getOffers({
        queryString: this.vendorId,
      }),
    ]).catch(function (e) {
      reportError(e);
    });

    return {
      properties: properties.result,
      offers: offers.result,
    };
  }

  render() {
    const { onAddAmenityOption, roomType } = this.props;
    const { showingAmenitiesModal } = this.state;

    const allAmenityOptions = this.getPossibleAmenityOptions();

    // Pass data to custom fields
    const formContext = {
      amenityOptions: allAmenityOptions,
      onStartAddingAmenities: this.handleToggleAmenitiesModal.bind(this, true),
    };

    if (!roomType.size_sqm) {
      delete roomType.size_sqm;
    }

    if (!roomType.availability_threshold) {
      delete roomType.availability_threshold;
    }

    if (!roomType.category) {
      roomType.category = this.property.category;
    }

    this.uiSchema.room_type_code = {
      'ui:widget': this.props.channelManager !== PROPERTY_CHANNEL_MANAGERS.selfManaged ? 'text' : 'hidden',
    };

    const isUniqueStays = this.isUniqueStays();
    this.schema.properties.number_of_bedrooms.minimum = isUniqueStays ? 1 : 0;

    if (isUniqueStays) {
      this.uiSchema.number_of_bedrooms = { 'ui:widget': 'text' };
      if (!this.schema.required.includes('number_of_bedrooms')) {
        this.schema.required = [...this.props.schema.required, 'number_of_bedrooms'];
      }
    } else {
      roomType.number_of_bedrooms = 0;

      this.schema.required = this.schema.required.filter((key) => key !== 'number_of_bedrooms');
      this.uiSchema.number_of_bedrooms = { 'ui:widget': 'hidden' };
    }

    const images = roomType.images.map((i) => ({
      ...i,
      publicImageId: i.id_cloudinary_external,
    }));

    this.schema.amenities_ordering = { 'ui:widget': 'hidden' };

    const contentEnabled = featureToggle.availableToShow('ADDITIONAL_PROPERTY_CONTENT_ENABLED');
    if (!contentEnabled) {
      this.uiSchema.room_policies = {
        'ui:widget': 'hidden',
      };
    }

    return (
      <div>
        <CopyRoomTypeAttributeForm
          schemaProperties={this.props.schema.properties}
          roomTypeNames={this.props.roomTypeNames}
          roomTypeId={roomType._id}
          onCopyAttributes={this.props.onCopyAttributes}
        />

        <Form
          schema={this.schema}
          uiSchema={{ ...this.uiSchema }}
          fields={fields}
          formData={roomType}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          formContext={formContext}
          validator={validator}
        >
          <ImagesForm
            images={images}
            onAddImage={this.onAddImage}
            onUpdateImages={this.onUpdateImages}
            onDeleteImage={this.onDeleteImage}
            hasHiddenToggle
          />

          {this.state.deleteState == 'failed' ? <ErrorDisplay message={errorMessage} /> : null}

          <div className="button-container">
            <Stack direction="row">
              <Button variant="contained" type="submit" disabled={this.state.saveState === buttonStates.saving}>
                {buttonMessages[this.state.saveState]}
              </Button>
              {roomType.id ? (
                <DeleteButton onClick={this.handleDeleteRoomType} buttonText={this.state.deleteState} />
              ) : null}
            </Stack>
          </div>
        </Form>

        <Dialog
          open={showingAmenitiesModal}
          onClose={() => this.handleToggleAmenitiesModal(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Add a new amenity type</DialogTitle>
          <DialogContent>
            <AmenityForm
              onSubmit={onAddAmenityOption}
              onCancel={() => this.handleToggleAmenitiesModal(false)}
              onAfterSubmit={() => this.handleToggleAmenitiesModal(false)}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
