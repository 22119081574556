import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { getChatTopics } from '~/services/SupportService';

export function useGetChatTopics() {
  const [chatTopics, setChatTopics] = useState([]);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const { enqueueSnackbar } = useSnackbar();

  const fetchChatTopics = useCallback(async () => {
    try {
      setFetchingState('loading');
      const topics = await getChatTopics();

      setChatTopics(topics.result);
      setFetchingState('success');
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'warning' });
      setFetchingState('failed');
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchChatTopics();
  }, [fetchChatTopics]);

  useEffect(() => {
    if (fetchingState === 'failed') {
      setTimeout(fetchChatTopics, 5000);
    }
  }, [fetchChatTopics, fetchingState]);

  const loading = useMemo(() => {
    return fetchingState === 'loading';
  }, [fetchingState]);

  return {
    loading,
    chatTopics,
  };
}
