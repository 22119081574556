import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import useHeroPlannerCitySegments from '~/hooks/customerCommunication/useHeroPlannerCitySegments';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props {
  countryGroupId: string;
  countryId: string;
  stateId: string;
  cityId: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
}

function ScheduleSegmentInput(props: Props) {
  const { countryGroupId, countryId, stateId, cityId, name, value, onChange } = props;
  const { segmentsReq } = useHeroPlannerCitySegments(countryGroupId, countryId, stateId, cityId);
  const options = segmentsReq?.result ?? EmptyArray;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (options.length && (!value || !options.find((i) => i.id === value))) {
      onChange(options[0].id);
    }
  }, [onChange, options, value]);

  const initialStateSegment = useMemo<CustomerCommunication.HeroPlannerStateSegment | undefined>(() => {
    if (value && !options.find((v) => v.id === value)) return { id: value, name: value };
  }, [options, value]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink>Segment</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        required
        variant="standard"
        disabled={isRequestUnresolved(segmentsReq) || isRequestRejected(segmentsReq)}
        error={isRequestRejected(segmentsReq)}
      >
        {!!initialStateSegment && (
          <MenuItem value={initialStateSegment.id}>
            <i>{initialStateSegment.name}</i>
          </MenuItem>
        )}
        {options.map((segment) => (
          <MenuItem key={segment.id} value={segment.id}>
            {segment.name}
          </MenuItem>
        ))}
      </Select>
      {isRequestRejected(segmentsReq) && <FormHelperText error>{segmentsReq.error}</FormHelperText>}
      {isRequestPending(segmentsReq) && <LinearProgress sx={{ position: 'absolute' }} />}
    </FormControl>
  );
}

export default ScheduleSegmentInput;
