import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { Alert, Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Image from '~/components/Common/Image';

import { themes } from '~/consts/experiences';

import offerService from '~/services/cruises/OfferService';

interface Props {
  offer: API.Offer;
  heroImageId: string;
  setHeroImageId: (id: string) => void;
}

const CruiseOfferImages = ({ offer, heroImageId, setHeroImageId }: Props) => {
  const [offerImages, setOfferImages] = useState([]);
  const [dynamicOfferImages, setDynamicOfferImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageType, setImageType] = useState<string>('ship');

  const fetchCruiseDetails = useCallback(async () => {
    const [{ result: images }, { result: dynamicImages }] = await Promise.all([
      offerService.getOfferImages(offer.id),
      offerService.getDynamicOfferImages(offer.id),
    ]);

    const buildImages = (images) =>
      images.map((image) => ({
        ...image,
        id: image.id,
        publicImageId: image.imageId,
      }));

    setOfferImages(buildImages(images));
    setDynamicOfferImages(buildImages(dynamicImages));
  }, [offer]);

  useEffect(() => {
    if (offer && !imagesLoaded) {
      fetchCruiseDetails();
      setImagesLoaded(true);
    }

    if (offerImages?.length) {
      setImageType('offer');
    }
  }, [offer, offerImages, fetchCruiseDetails, setImageType, imagesLoaded, setImagesLoaded]);

  const images = useMemo(() => {
    if (imageType === 'offer') {
      return offerImages;
    }

    return dynamicOfferImages || [];
  }, [imageType, offerImages, dynamicOfferImages]);

  return (
    <div>
      <FormControl>
        <FormLabel id="promo-banner-type-filter">Images</FormLabel>
        <RadioGroup
          row
          aria-labelledby="promo-banner-type-filter"
          name="row-radio-buttons-group"
          sx={{ alignItems: 'center' }}
        >
          <FormControlLabel
            value="rateCode"
            control={<Radio checked={imageType === 'ship'} onChange={() => setImageType('ship')} />}
            label="Use ship & port images"
          />

          <FormControlLabel
            value="filters"
            control={<Radio checked={imageType === 'offer'} onChange={() => setImageType('offer')} />}
            label="Use offer images"
          />

          <Link to={`/cruises/offer/${offer?.id}/images`}>
            <Button variant="contained" size="small" startIcon={<EditIcon />}>
              Set offer-specific images
            </Button>
          </Link>
        </RadioGroup>
      </FormControl>

      {images?.length > 0 && (
        <Box
          mt={2}
          sx={{
            display: 'grid',
            gap: '0.6rem',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(5, 1fr)',
            },
          }}
        >
          {images.map((image) => {
            const imageId = image.idImage || image.imageId;
            return (
              <Box
                key={imageId}
                className={imageId === heroImageId ? 'selected' : ''}
                sx={{
                  cursor: 'pointer',
                  position: 'relative',
                  border: '2px solid #e5e5e5',
                  '&:hover > div': { opacity: 1 },
                  '> img': { width: '100%', height: 'inherit' },
                  '& > div': {
                    opacity: 0,
                    transition: 'opacity 0.3s ease-in-out',
                  },
                  '&.selected': {
                    boxShadow: `0 0 0 4px ${themes.primaryBlue}`,
                  },
                }}
              >
                <Image publicId={imageId} />

                {imageType !== 'offer' && (
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      position: 'absolute',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#0000007f',
                    }}
                  >
                    {imageId !== heroImageId && (
                      <Button variant="contained" onClick={() => setHeroImageId(imageId)}>
                        Set as hero
                      </Button>
                    )}

                    {imageId === heroImageId && (
                      <Button variant="contained" onClick={() => setHeroImageId('')}>
                        Remove hero
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}

      {images?.length === 0 && (
        <Alert variant="outlined" severity="info" sx={{ display: 'flex', justifyContent: 'center' }}>
          There are no images for this {imageType}
        </Alert>
      )}
    </div>
  );
};

export default CruiseOfferImages;
