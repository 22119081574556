import { ExperiencePayload } from '~/components/Experiences/hooks';

import { ExperienceOffer, ExperienceOfferImage, UploadedImage, uploadOfferImage } from '~/services/ExperiencesService';

export const handleImagesPayload = async (payload: ExperiencePayload, experience: ExperienceOffer) => {
  let images: ExperienceOfferImage[] = payload?.images ?? experience.images;
  let uploadedImages: UploadedImage[] = undefined;

  if (!payload?.imagesToUpload && !payload?.imagesToRemove && !payload?.images) {
    return;
  }

  if (payload?.imagesToUpload) {
    uploadedImages = await uploadOfferImage(payload.imagesToUpload);

    const mappedImages = uploadedImages.map((uploadedImage) => ({
      id: uploadedImage.id,
      description: experience.title,
      isHero: false,
      urls: [
        {
          url: uploadedImage.url,
          height: 0,
          width: 0,
        },
      ],
    }));

    images = [...images, ...mappedImages];
  }

  if (payload?.imagesToRemove) {
    images = images.filter((image) => !payload.imagesToRemove.includes(image.id));
  }

  // these keys are front-end only
  // we don't need to send to the backend
  // because the backend will not use it.
  delete payload.imagesToRemove;
  delete payload.imagesToUpload;

  return images;
};
