import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';

import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from '@mui/material';

import TourOfferCountryInput from '../ToursListing/TourOfferCountryInput';
import TourOfferStatusInput from '../ToursListing/TourOfferStatusInput';

import TourOfferSellingRegionInput from './TourOfferSellingRegionInput';
import TourOfferTTCBrandInput from './TourOfferTTCBrandInput';

export interface TTCTourSearchInput {
  brands?: string[];
  countries?: string[];
  sellingRegion?: string;
  status?: string;
  tourName?: string;
}

const DEFAULT_TTC_TOUR_SEARCH_INPUT: TTCTourSearchInput = {
  brands: [],
  countries: [],
  sellingRegion: 'any',
  status: 'any',
  tourName: '',
};

interface Props {
  initialInput?: TTCTourSearchInput;
  onSubmit: (formObject: Record<string, string>) => void;
}

function TTCTourSearchForm(props: Props) {
  const { initialInput, onSubmit } = props;

  const [tourName, setTourName] = useState<string>(DEFAULT_TTC_TOUR_SEARCH_INPUT.tourName);
  const [status, setStatus] = useState<string>(DEFAULT_TTC_TOUR_SEARCH_INPUT.status);
  const [sellingRegion, setSellingRegion] = useState<string>(DEFAULT_TTC_TOUR_SEARCH_INPUT.sellingRegion);
  const [brands, setBrands] = useState<string[]>(DEFAULT_TTC_TOUR_SEARCH_INPUT.brands);
  const [countries, setCountries] = useState<string[]>(DEFAULT_TTC_TOUR_SEARCH_INPUT.countries);

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      const formObject: Record<string, string> = {};

      if (tourName) formObject.tourName = tourName;
      if (status && status !== 'any') formObject.status = status;
      if (sellingRegion && sellingRegion !== 'any') formObject.sellingRegion = sellingRegion;
      if (brands?.length) formObject.brands = brands.join(',');
      if (countries?.length) formObject.countries = countries.join(',');

      onSubmit(formObject);
    },
    [brands, countries, onSubmit, sellingRegion, status, tourName],
  );

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();

    setBrands(DEFAULT_TTC_TOUR_SEARCH_INPUT.brands);
    setCountries(DEFAULT_TTC_TOUR_SEARCH_INPUT.countries);
    setSellingRegion(DEFAULT_TTC_TOUR_SEARCH_INPUT.sellingRegion);
    setStatus(DEFAULT_TTC_TOUR_SEARCH_INPUT.status);
    setTourName(DEFAULT_TTC_TOUR_SEARCH_INPUT.tourName);
  }, []);

  useEffect(() => {
    setBrands(initialInput?.brands ?? DEFAULT_TTC_TOUR_SEARCH_INPUT.brands);
    setCountries(initialInput?.countries ?? DEFAULT_TTC_TOUR_SEARCH_INPUT.countries);
    setSellingRegion(initialInput?.sellingRegion ?? DEFAULT_TTC_TOUR_SEARCH_INPUT.sellingRegion);
    setTourName(initialInput?.tourName ?? DEFAULT_TTC_TOUR_SEARCH_INPUT.tourName);
    setStatus(initialInput?.status ?? DEFAULT_TTC_TOUR_SEARCH_INPUT.status);
  }, [initialInput]);

  return (
    <form onSubmit={handleSubmission} onReset={handleReset}>
      <Card>
        <CardHeader title="Search for TTC Tours" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField label="Tour name" value={tourName} onChange={(e) => setTourName(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TourOfferStatusInput value={status} onChange={setStatus} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TourOfferSellingRegionInput value={sellingRegion} onChange={setSellingRegion} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TourOfferTTCBrandInput value={brands} onChange={setBrands} />
            </Grid>
            <Grid item xs={12}>
              <TourOfferCountryInput value={countries} onChange={setCountries} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button variant="text" size="large" type="reset">
            Reset
          </Button>
          <Button variant="contained" size="large" type="submit">
            Search
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default TTCTourSearchForm;
