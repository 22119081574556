import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Container } from '@mui/material';

import { getSentEmail } from '~/services/SailthruService';

const RenderEmailPage: React.FC = () => {
  const [htmlContent, setHtmlContent] = useState<string>('');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    if (!id) {
      return;
    }

    getSentEmail({ id }).then((data) => setHtmlContent(data));
  }, []);

  const title = `View Email`;
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    </Container>
  );
};

export default RenderEmailPage;
