import React, { ReactElement, createContext, useContext, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface DialogOptions {
  title: string;
  description?: string;
}

interface PromiseInfo {
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  reject: (reason?: any) => void;
}

type ShowDialogHandler = (options: DialogOptions) => Promise<boolean>;

interface Props {
  children: ReactElement;
}

const ConfirmDialogContext = createContext<ShowDialogHandler>(() => {
  throw new Error('Component is not wrapped with a ConfirmDialogProvider.');
});

const ConfirmDialogProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DialogOptions>({ title: '' });
  const [promiseInfo, setPromiseInfo] = useState<PromiseInfo>();

  const showConfirmDialog: ShowDialogHandler = (options) => {
    return new Promise<boolean>((resolve, reject) => {
      setPromiseInfo({ resolve, reject });
      setOptions(options);
      setOpen(true);
    });
  };

  const handleConfirm = () => {
    setOpen(false);
    promiseInfo?.resolve(true);
    setPromiseInfo(undefined);
  };

  const handleCancel = () => {
    setOpen(false);
    promiseInfo?.resolve(false);
    setPromiseInfo(undefined);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{options.title}</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          {options.description && <DialogContentText>{options.description}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialogContext.Provider value={showConfirmDialog}>{children}</ConfirmDialogContext.Provider>
    </>
  );
};

export const useConfirmDialog = () => {
  return useContext(ConfirmDialogContext);
};

export default ConfirmDialogProvider;
