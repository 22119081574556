import React from 'react';

import HotelBookingModal from '~/components/Common/Booking/HotelBookingModal';
import TourBookingModal from '~/components/Common/Booking/TourBookingModal';

import { HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

import BookingItem from './BookingItem';

const getBookingDatesItem = (item: App.CartItem) => (item.isBookingDates ? item : null);

const closeBookingDateModal = (props) => {
  const { doToggleBookingDates, offer, item } = props;
  const bookingDatesItem = getBookingDatesItem(item);

  if (!bookingDatesItem) {
    return;
  }
  doToggleBookingDates(bookingDatesItem, offer, false);
};

function BookingDateModal(props) {
  const { offer, currencyCode, item } = props;
  const bookingDatesItem = getBookingDatesItem(item);

  const handleUpdateReservation = (item, reservationData) => {
    const { doUpdateReservation, offer } = props;
    doUpdateReservation(item.id, reservationData, offer, offer.offerCartId);
  };

  if (HOTEL_OFFER_TYPES.includes(offer.type)) {
    return (
      <HotelBookingModal
        isOpen
        closeModal={() => closeBookingDateModal({ ...props, item: bookingDatesItem })}
        item={bookingDatesItem}
        surchargePaidDirectToVendor={offer.surcharge_paid_direct_to_vendor}
        offer={offer}
        currencyCode={currencyCode}
        onUpdateReservation={handleUpdateReservation}
      />
    );
  }

  if (offer.type === 'tour') {
    return (
      <TourBookingModal
        isOpen
        closeModal={() => closeBookingDateModal({ ...props, item: bookingDatesItem })}
        item={bookingDatesItem}
        onUpdateReservation={handleUpdateReservation}
        offer={offer}
      />
    );
  }
}

type Props = {
  offer: App.CartOffer;
  items: App.CartItem[];
  bookingType: string;
  currencyCode: string;
  onUpdateReservation: (item, reservationData) => void;
  onUpdateStateOfResidence: (stateOfResidence) => void;
  doToggleBookingDates: (item, offer, isBookingDates) => void;
  doUpdateReservation: (itemId, reservationData, offer) => void;
};

export default function BookingList(props: Props) {
  const { items, bookingType, offer, currencyCode, onUpdateReservation, onUpdateStateOfResidence } = props;

  return (
    <div className="booking-information-list">
      {items.map((item, idx) => {
        return (
          <React.Fragment key={item.id}>
            <BookingItem
              item={item}
              bookingType={bookingType}
              offer={offer}
              itemIdx={idx}
              currencyCode={currencyCode}
              onUpdateReservation={onUpdateReservation}
              onUpdateStateOfResidence={onUpdateStateOfResidence}
            />

            {getBookingDatesItem(item) && <BookingDateModal item={item} {...props} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
