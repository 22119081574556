import styled from 'styled-components';

import { themes } from '../../../../consts/experiences';

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 20px 10px;
  border-radius: 10px;
  background-color: ${themes?.white};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
`;
