import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { FormValidation, RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueUnpaidBookings } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

interface FormData {
  date?: string;
  recordType?: string;
}

function validateForm(formData: FormData, errors: FormValidation): FormValidation {
  if (!formData.date) {
    errors.date.addError('Please enter date');
  }
  if (!formData.recordType) {
    errors.recordType.addError('Please choose a recordType option');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please enter a date to export a list of all unpaid bookings payable on the selected date',
  type: 'object',
  properties: {
    date: {
      title: 'Select Date',
      type: 'string',
      format: 'date',
    },
    recordType: {
      title: 'Select Record Type',
      type: 'string',
      enum: ['default', 'rental'],
      default: 'default',
    },
  },
};

const uiSchema = {
  date: {
    'ui:widget': 'date',
  },
};

export default function ExportUnpaidBookingsForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent<FormData>) => {
    queueUnpaidBookings({
      date: event.formData.date,
      recordType: event.formData.recordType,
    })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };
  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email Unpaid Bookings Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
