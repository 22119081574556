import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Container } from '@mui/material';

import CustomOfferForm from '~/components/Common/CustomOffers/CustomOfferForm';
import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';

import UsersService from '~/services/UsersService';

export default function Page() {
  const { id_user: userId } = useParams<{ id_user: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [user, setUser] = useState<App.User | null>(null);

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const isLoading = fetchingState == 'loading';
  const showForm = fetchingState == 'success';

  useEffect(() => {
    setFetchingState('loading');
    UsersService.getUser(userId, { brand })
      .then((user) => {
        setUser(user);
        setFetchingState('success');
      })
      .catch((err) => {
        enqueueSnackbar(`Error loading user: ${err.message}`, { variant: 'error' });
        setFetchingState('failed');
      });
  }, [userId, brand, enqueueSnackbar]);

  return (
    <Container maxWidth="xl">
      <PageHeader title="New custom offer" backButton={`/users/${userId}`} />

      {isLoading && <Spinner />}

      {showForm && <CustomOfferForm user={user} />}
    </Container>
  );
}
