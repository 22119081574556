export const getEarliestStart = (filteredSchedules) => {
  const result = filteredSchedules.reduce((earliestSchedule, schedule) => {
    if (new Date(schedule['start']) < new Date(earliestSchedule['start'])) {
      return schedule;
    }
    return earliestSchedule;
  });
  if (result) {
    return result['start'];
  }
};

export const getLatestEnd = (filteredSchedules) => {
  const result = filteredSchedules.reduce((latestSchedule, schedule) => {
    if (new Date(schedule['end']) > new Date(latestSchedule['end'])) {
      return schedule;
    }
    return latestSchedule;
  });
  if (result) {
    return result['end'];
  }
};

export const scheduleForType = (schedules, type, scheduleKey) => {
  const filteredSchedules = schedules.filter((schedule) => schedule.type === type);

  if (filteredSchedules.length === 0) {
    return;
  }

  const result = {
    start: getEarliestStart(filteredSchedules),
    end: getLatestEnd(filteredSchedules),
  };

  return result[scheduleKey];
};
