import React from 'react';

import GiftCardOrderSummaryItem from './GiftCardOrderSummaryItem';

const OrderSummaryList = ({ items, currencyCode }) => {
  return (
    <div className="order-summary-panel">
      <div className="offer-meta">
        <div className="offer-title">Gift card</div>
      </div>

      <ul className="summary-item-list">
        {items.map((item, idx) => {
          return <GiftCardOrderSummaryItem itemIdx={idx} item={item} currencyCode={currencyCode} key={item.id} />;
        })}
      </ul>
    </div>
  );
};

export default OrderSummaryList;
