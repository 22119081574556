import React from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';

interface Props {
  reason: CollectionReason;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  defaultComment: string;
}

export default function ChangeOfMindWithinRefundGuaranteePeriod({
  reason,
  onSubmit,
  decrementStep,
  defaultComment,
}: Props) {
  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <CaseIDAndReasons reason={reason} defaultComment={defaultComment} />
      </DialogContent>

      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained">
          Continue
        </Button>
      </DialogActions>
    </form>
  );
}
