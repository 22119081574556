import React, { useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import RoomTypeIncludedGuestForm from '../../Common/Forms/RoomTypeIncludedGuestForm';
import RoomTypeIncludedGuestsForm from '../../Common/Forms/RoomTypeIncludedGuestsForm';
import PermissionComponent from '../../Common/PermissionedComponent';

import RoomTypeIncludedGuest from './RoomTypeIncludedGuest';

interface RoomTypeIncludedGuestsProps {
  propertyId: string;
  roomType: {
    id: string;
  };
  roomRate: {
    id: string;
  };
  includedGuests: Array<{
    id: string;
  }>;
  deleteRoomTypeIncludedGuest: (id: string) => void;
  onDeleteAll: () => void;
  isDeletingAll: boolean;
  onUpdateRoomTypeIncludedGuests: () => void;
}

export default function RoomTypeIncludedGuests(props: RoomTypeIncludedGuestsProps) {
  const [editing, setEditing] = useState(null);
  const [mode, setMode] = useState(null);

  const showConfirmDialog = useConfirmDialog();

  const openIncludedGuestsModal = useCallback(() => {
    setMode('included-guests');
  }, []);

  const openEditBox = useCallback((includedGuest) => {
    setMode('included-guest');
    setEditing(includedGuest);
  }, []);

  const closeAddBox = useCallback(() => {
    setMode(null);
    setEditing(null);
  }, []);

  const onRoomTypeIncludedGuestUpdated = useCallback(() => {
    props.onUpdateRoomTypeIncludedGuests();
    setMode(null);
    setEditing(null);
  }, [props]);

  const renderIncludedGuestModal = () => (
    <Dialog open={mode === 'included-guest'} onClose={closeAddBox}>
      <DialogTitle>Set Room Rate Included Guest</DialogTitle>
      <DialogContent>
        <RoomTypeIncludedGuestForm
          includedGuest={editing}
          propertyId={props.propertyId}
          roomTypeId={props.roomType.id}
          roomRateId={props.roomRate.id}
          onCancel={closeAddBox}
          onRoomTypeIncludedGuestAdded={onRoomTypeIncludedGuestUpdated}
          onRoomTypeIncludedGuestUpdated={onRoomTypeIncludedGuestUpdated}
        />
      </DialogContent>
    </Dialog>
  );

  const renderIncludedGuestsModal = () => (
    <Dialog open={mode === 'included-guests'} onClose={closeAddBox}>
      <DialogTitle>Set Room Rate Included Guests</DialogTitle>
      <DialogContent>
        <RoomTypeIncludedGuestsForm
          includedGuest={editing}
          propertyId={props.propertyId}
          roomType={props.roomType}
          roomRate={props.roomRate}
          onCancel={closeAddBox}
          onRoomTypeIncludedGuestUpdated={onRoomTypeIncludedGuestUpdated}
        />
      </DialogContent>
    </Dialog>
  );

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete all? This action cannot be undone.',
    });

    if (!confirmed || props.isDeletingAll) return;

    props.onDeleteAll();
  };

  return (
    <div className="RoomTypeIncludedGuests">
      <Typography color="secondary" textTransform="uppercase">
        Room Rate Included Guests
      </Typography>

      {props.includedGuests.length === 0 ? (
        <p>
          <i>No included guests set</i>
        </p>
      ) : (
        <div className="room-type-capacity-list">
          {props.includedGuests.map((includedGuest) => (
            <RoomTypeIncludedGuest
              key={includedGuest.id}
              includedGuest={includedGuest}
              deleteRoomTypeIncludedGuest={props.deleteRoomTypeIncludedGuest}
              onRequestEdit={openEditBox}
            />
          ))}
        </div>
      )}

      <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
        <Stack direction="row" spacing={2} justifyContent="space-between" mt={2}>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => openEditBox(null)} startIcon={<AddIcon />}>
              Add
            </Button>
            <Button variant="contained" onClick={openIncludedGuestsModal} startIcon={<AddIcon />}>
              Advanced
            </Button>
          </Stack>

          {props.includedGuests && props.includedGuests.length > 0 && (
            <div>
              <Button title="Delete" variant="text" startIcon={<DeleteIcon />} onClick={handleDelete}>
                Delete All
              </Button>
            </div>
          )}
        </Stack>
      </PermissionComponent>

      {mode === 'included-guest' && renderIncludedGuestModal()}
      {mode === 'included-guests' && renderIncludedGuestsModal()}
    </div>
  );
}
