import React, { ReactElement, useCallback } from 'react';

import SyncIcon from '@mui/icons-material/Sync';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';

import { getCancellationPolicyLabel } from '~/utils/cancellationPolicy';
import { useToggle } from '~/utils/hooks';

import { RoomRateTableHeader } from './RoomRateTableHeader';
import { RoomRatesTable } from './RoomRatesTable';
import { SyncCancellationPolicyModalProps } from './types';
import { useSyncCancellationPolicies } from './useSyncCancellationPolicies';

export function SyncCancellationPolicyModal({
  packages,
  propertyData,
  newCancellationPolicy,
  refreshData,
}: SyncCancellationPolicyModalProps): ReactElement {
  const { value, on, off } = useToggle();

  const { syncCancellationPolicies, syncState, hasRatesToSync, packageRoomRates } = useSyncCancellationPolicies({
    packages,
    propertyData,
    newCancellationPolicy,
    refreshData,
  });

  const onSubmit = useCallback(async () => {
    await syncCancellationPolicies();
    off();
  }, [off, syncCancellationPolicies]);

  return (
    <>
      <Button onClick={on} disabled={!hasRatesToSync} startIcon={<SyncIcon />}>
        Sync Cancellation Policy
      </Button>
      <Dialog open={value} onClose={off} maxWidth="md" fullWidth>
        <DialogTitle>Sync Cancellation Policy</DialogTitle>
        <DialogTitle>
          <Alert severity="warning">
            This will update the cancellation policy on all rate plans attached to this offer, even if the rate plan is
            attached to multiple offers. Do you want to continue?
          </Alert>
        </DialogTitle>
        <DialogTitle>
          New Cancellation Policy:{' '}
          <Typography variant="h6" sx={{ display: 'inline', textDecoration: 'bold', color: 'green' }}>
            {getCancellationPolicyLabel(newCancellationPolicy)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table stickyHeader>
              <RoomRateTableHeader />
              <RoomRatesTable packageRoomRates={packageRoomRates} />
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={off} disabled={syncState === 'loading'}>
            Cancel
          </Button>
          {syncState === 'loading' ? (
            <Button variant="contained">
              <div style={{ marginLeft: '-4px', marginRight: '8px', height: '1.5em', width: '1.5em' }}>
                <CircularProgress size="1.5em" sx={{ color: 'inherit' }} />
              </div>
              Sync Cancellation Policy
            </Button>
          ) : (
            <Button onClick={onSubmit} variant="contained" startIcon={<SyncIcon />}>
              Sync Cancellation Policy
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
