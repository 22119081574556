import {
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_EXPERIENCE,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_TOUR,
} from './offerTypes';

export const PROMO_TYPES = [
  {
    key: 'percentage',
    value: 'Percentage',
  },
  {
    key: 'fixed_amount',
    value: 'Fixed Amount',
  },
];

export const PROMO_DEVICE_TYPES = [
  {
    key: 'all',
    value: 'All Devices',
    short_value: 'All',
  },
  {
    key: 'web',
    value: 'Web Site',
    short_value: 'Web',
  },
  {
    key: 'ios',
    value: 'iOS App',
    short_value: 'iOS',
  },
  {
    key: 'android',
    value: 'Android App',
    short_value: 'Android',
  },
];

export const DEPARTMENT_TAGS = [
  {
    key: 'consumer_marketing',
    value: 'Consumer Marketing',
  },
  {
    key: 'vendor_marketing',
    value: 'Vendor Marketing',
  },
  {
    key: 'customer_service',
    value: 'Customer Service',
  },
  {
    key: 'inbound_sales',
    value: 'Inbound Sales',
  },
  {
    key: 'outbound_sales',
    value: 'Outbound Sales',
  },
  {
    key: 'platinum',
    value: 'Platinum',
  },
  {
    key: 'referral',
    value: 'Referral',
  },
  {
    key: 'asia',
    value: 'Asia',
  },
  {
    key: 'india',
    value: 'India',
  },
  {
    key: 'usa',
    value: 'United States',
  },
  {
    key: 'charity',
    value: 'Charity',
  },
  {
    key: 'people_and_culture',
    value: 'People and Culture',
  },
  {
    key: 'vax21',
    value: 'Vaccination 2021 Promotion',
  },
  {
    key: 'marketing',
    value: 'Marketing',
  },
  {
    key: 'others',
    value: 'Others',
  },
];

export const REQUIRED_FIELDS = {
  code_name: 'Promo code name',
  description: 'Description',
  promo_type: 'Promo Type',
  code_limit: 'Usage Limit',
  limit_per_user: 'Usage Limit per Customer',
  department_tag: 'Department Tag',
};

export const PROMO_TYPE_PERCENTAGE = 'percentage';
export const PROMO_TYPE_FIXED = 'fixed_amount';

export const PROMO_ALLOWED_OFFER_TYPES = [
  {
    key: 'all',
    value: 'All Offer types',
    short_value: 'All',
  },
  {
    key: OFFER_TYPE_HOTEL,
    value: 'Hotel',
    short_value: 'Hotel',
  },
  {
    key: OFFER_TYPE_TOUR,
    value: 'Tour',
    short_value: 'Tour',
  },
  {
    key: OFFER_TYPE_LAST_MINUTE_HOTEL,
    value: 'Last Minute Hotel',
    short_value: 'Last Minute Hotel',
  },
  {
    key: OFFER_TYPE_TACTICAL_AO_HOTEL,
    value: 'Tactical Always On',
    short_value: 'Tactical Always On',
  },
  {
    key: OFFER_TYPE_BED_BANK,
    value: 'Bedbank',
    short_value: 'Bedbank',
  },
  {
    key: OFFER_TYPE_EXPERIENCE,
    value: 'Experience',
    short_value: 'Experience',
  },
];
