import React from 'react';

import classnames from 'clsx';

const Checkbox = ({ labelText, checked, onToggle, wrapperClassName = '', buttonClassName }) => {
  return (
    <div className={classnames('le-checkbox-container', wrapperClassName)}>
      <button
        className={classnames('le-checkbox', buttonClassName)}
        role="checkbox"
        aria-checked={checked.toString()}
        onClick={onToggle}
      >
        {checked && <i className="fa fa-check tick-icon" />}
      </button>
      <div className="le-checkbox-label" onClick={onToggle}>
        {labelText}
      </div>
    </div>
  );
};

export default Checkbox;
