import React, { useCallback, useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import { CruiseInclusionFilters } from '~/components/Cruises/pages/Inclusions/types';

const INITIAL_SEARCH_STATE = {
  rateCodes: '',
  vendorCodes: '',
  departures: '',
  name: '',
};

type Props = {
  loading: boolean;
  onSearch: (searchValues: CruiseInclusionFilters) => void;
};

function parseFilterValue(value: string) {
  return value ? value.split(',').map((item) => item.trim()) : undefined;
}

function InclusionSearchForm({ loading, onSearch }: Props) {
  const [searchValues, setSearchValues] = useState<{
    rateCodes: string;
    vendorCodes: string;
    departures: string;
    name: string;
  }>(INITIAL_SEARCH_STATE);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target && event?.target?.name) {
        setSearchValues({
          ...searchValues,
          [event.target.name]: event.target.value,
        });
      }
    },
    [searchValues],
  );

  const handleSearch = useCallback(() => {
    onSearch({
      rateCodes: parseFilterValue(searchValues.rateCodes),
      vendorCodes: parseFilterValue(searchValues.vendorCodes),
      departures: parseFilterValue(searchValues.departures),
      name: searchValues.name || undefined,
    });
  }, [searchValues, onSearch]);

  return (
    <Stack direction="column" spacing={2} border={1} borderColor="divider" borderRadius={1} p={2}>
      <Stack direction="row" spacing={2}>
        <Box flexGrow={2}>
          <TextField
            fullWidth
            name="name"
            label="Inclusion Name"
            onChange={handleChangeField}
            value={searchValues.name}
          />
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box flexGrow={2}>
          <TextField
            fullWidth
            name="rateCodes"
            label="Rate IDs"
            onChange={handleChangeField}
            value={searchValues.rateCodes}
            placeholder="Use comma to search multiple rate IDs"
          />
        </Box>
        <Box flexGrow={2}>
          <TextField
            fullWidth
            name="departures"
            label="Departure IDs"
            onChange={handleChangeField}
            value={searchValues.departures}
            placeholder="Use comma to search multiple departures"
          />
        </Box>
        <Box flexGrow={1}>
          <TextField
            fullWidth
            name="vendorCodes"
            label="Vendor Code"
            onChange={handleChangeField}
            value={searchValues.vendorCodes}
          />
        </Box>

        <Box>
          <Button variant="contained" disabled={loading} onClick={handleSearch}>
            Search Content
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

export default InclusionSearchForm;
