import React, { useCallback, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { getGiftCardLogs } from '~/services/PromoService';
import { formatDateLongISO } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

const LE_BRAND = 'luxuryescapes';

const formatDate = (value) => {
  if (!value) {
    return '';
  }

  return formatDateLongISO(value);
};

const formatUser = (user) => {
  return `${user.full_name} <${user.email}>`;
};

export default function Log({ item, order }) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [logs, setLogs] = useState([]);

  const handleOpen = useCallback(
    async (_, expanded: boolean) => {
      if (!expanded) {
        setLoadingState('idle');
        return;
      }

      try {
        setLoadingState('loading');

        const response = await getGiftCardLogs(item.code, order.brand);

        const userIds = [...new Set(response.result.map((item) => item.modified_by))];
        const userSummaries = await UsersService.getUsersSummaryByIds(userIds, order.brand);

        //serice users are owned by LE brand and also generate logs
        const luxUserSummaries = await UsersService.getUsersSummaryByIds(userIds, LE_BRAND);

        const logs = response.result.map((item) => {
          item.modified_by = userSummaries.get(item.modified_by) ?? luxUserSummaries.get(item.modified_by);
          return item;
        });

        setLogs(logs);
        setLoadingState('success');
      } catch (err) {
        setLoadingState('failed');
      }
    },
    [item.code, order.brand],
  );

  return (
    <Accordion onChange={handleOpen} variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Gift card logs</AccordionSummary>
      <AccordionDetails>
        {loadingState === 'failed' && <Alert severity="error">Can't load gift card logs</Alert>}
        <DataGrid
          loading={loadingState === 'loading'}
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              sortable: false,
              flex: 1,
              valueGetter: (_value, row) => row.created_at,
              display: 'flex',
            },
            {
              field: 'created_at',
              headerName: 'Date',
              sortable: false,
              flex: 1,
              valueFormatter: (value) => formatDate(value),
              display: 'flex',
            },
            {
              field: 'modified_by',
              headerName: 'User',
              sortable: false,
              flex: 2,
              valueFormatter: (value) => formatUser(value),
              display: 'flex',
            },
            { field: 'gift_card_status', sortable: false, width: 200, headerName: 'Gift Card Status', display: 'flex' },
          ]}
          rows={logs}
          getRowId={(row) => row.created_at}
          autoHeight
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </AccordionDetails>
    </Accordion>
  );
}
