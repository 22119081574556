import React, { useState } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';

import { VARIANT_OF_REFUND_ALL, VARIANT_OF_REFUND_PARTIAL } from '~/consts/refund';

import UpdateVCCBalanceForm from './UpdateVCCBalanceForm';

interface Props {
  defaultVccChecked: boolean;
}

export default function SelectRefundVariant({ defaultVccChecked }: Props) {
  const [variantOfRefund, setVariantOfRefund] = useState<string>();
  return (
    <Stack>
      <FormControl>
        <RadioGroup
          name="variantOfRefund"
          row
          value={variantOfRefund}
          onChange={(e) => {
            setVariantOfRefund(e.target.value);
          }}
        >
          <FormControlLabel
            key="full-refund"
            value={VARIANT_OF_REFUND_ALL}
            control={
              <Radio
                inputProps={{
                  required: true,
                }}
              />
            }
            data-cy="Full Package Refund"
            label="Full Package Refund"
          />
          <FormControlLabel
            key="partial-refund"
            value={VARIANT_OF_REFUND_PARTIAL}
            data-cy="Partial Refund"
            control={
              <Radio
                inputProps={{
                  required: true,
                }}
              />
            }
            label="Partial Refund"
          />
        </RadioGroup>
      </FormControl>
      {variantOfRefund === VARIANT_OF_REFUND_PARTIAL && (
        <Stack spacing={1}>
          <TextField
            label="Refund Amount to Customer"
            required
            type="number"
            data-cy="RefundAmountToCustomer"
            name="refundAmountToCustomer"
          />
          <UpdateVCCBalanceForm defaultVccChecked={defaultVccChecked} />
        </Stack>
      )}
    </Stack>
  );
}
