import DestinationImagePage from '~/components/Content/DestinationImage/DestinationImagePage';
import ViewBlockedOffers from '~/components/Content/OfferBlocklist/ViewBlockedOffers';
import PropertyLocationOverrideList from '~/components/Content/PropertyLocationOverride/PropertyLocationOverrideList';
import TrendingPlacesPage from '~/components/Content/TrendingPlaces/TrendingPlacesPage';

import GeometrySearch from './GeometrySearch';
import OfferListOrder from './OfferListOrder/OfferListOrderContainer';
import SearchRankingPage from './SearchRanking/SearchRankingPage';
import DynamicTypeaheadPage from './TypeaheadTemplating/DynamicTypeaheadPage';

export const contentTabs = {
  'offer-list-order': {
    title: 'Offer List Order',
    component: OfferListOrder,
  },
  'search-dashboard': {
    title: 'Search Dashboard',
    component: GeometrySearch,
  },
  'search-ranking': {
    title: 'Search Ranking',
    component: SearchRankingPage,
  },
  'trending-places': {
    title: 'Trending Places',
    component: TrendingPlacesPage,
  },
  'destination-image': {
    title: 'Destination Image',
    component: DestinationImagePage,
  },
  'dynamic-typeahead': {
    title: 'Dynamic Typeahead',
    component: DynamicTypeaheadPage,
  },
  'blocked-offers': {
    title: 'Blocked offers',
    component: ViewBlockedOffers,
  },
  'property-location-override': {
    title: 'Property Location Override',
    component: PropertyLocationOverrideList,
  },
} as const;

export const contentTabsList = Object.keys(contentTabs);

export type Tab = keyof typeof contentTabs;
