import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Dialog, IconButton, Tooltip } from '@mui/material';

interface Props {
  dialogOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function ProblemPaymentDismissDialog(props: Props) {
  const { dialogOpen, onClose, onConfirm } = props;
  return (
    <Dialog open={dialogOpen} onClose={onClose}>
      <Container>
        <h3>You are about to permanently dismiss this item</h3>
        <div>Once dismissed, this item will not show up in any future reports</div>
        <Box display="flex" justifyContent="space-around">
          <Tooltip title="Confirm dismiss">
            <IconButton onClick={onConfirm}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Container>
    </Dialog>
  );
}

export default ProblemPaymentDismissDialog;
