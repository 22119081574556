import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Alert, Button, CircularProgress } from '@mui/material';

import { downloadExpiringCreditsReport } from '~/services/ReportingService';
import { addMonths, formatDateISO, startOfMonth } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description:
    'This report will create a CSV of users that have credits more than specified amount, and will expire within the specified date range',
  type: 'object',
  properties: {
    startDate: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endDate: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    minimumCreditsValue: {
      title: 'Specify the minimum credit value',
      type: 'number',
    },
  },
};

const uiSchema = {
  startDate: {
    'ui:widget': 'date',
  },
  endDate: {
    'ui:widget': 'date',
  },
  minimumCreditsValue: {
    'ui:widget': 'updown',
  },
};

export default function ExpiringCreditReportForm() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    startDate: formatDateISO(startOfMonth()),
    endDate: formatDateISO(startOfMonth(addMonths(1))),
    minimumCreditsValue: 1000,
  });

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setIsLoading(true);
      setError(null);
      const { startDate, endDate, minimumCreditsValue } = event.formData;
      const fileName = `expiring_credits_report_${startDate}_${endDate}_${minimumCreditsValue}.csv`;
      const responseText = await downloadExpiringCreditsReport({
        startAt: startDate,
        endAt: endDate,
        minimumCreditsValue,
      });
      fileDownload(responseText, fileName);
    } catch (e) {
      setError(e.message || e.toString());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Alert severity="info">This report is accurate within 3 months of the current date.</Alert>
      <Form
        schema={formSchema}
        uiSchema={uiSchema}
        formData={formData}
        validator={validator}
        onSubmit={handleSubmit}
        showErrorList={false}
        onChange={({ formData }) => setFormData(formData)}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button type="submit" variant="contained" disabled={isLoading}>
            Download Expiring Credit Report (.csv)
          </Button>
        )}
        {error && <ErrorDisplay message={error} />}
      </Form>
    </>
  );
}
