import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { enquireTourReservation } from '~/actions/cart';

import getDateGroup from '~/utils/getDateGroup';

import RadioDatePicker from './RadioDatePicker';

class TourBookingModal extends React.Component {
  constructor(props) {
    super(props);

    const { item } = props;

    // initialize the selected dates for the calendar based on the reservation
    const selectedDate = {
      startDate: item.reservation.startDate,
      endDate: item.reservation.endDate,
    };

    this.state = {
      selectedDate,
    };
  }

  componentDidMount() {
    this.handleMakeEnquiry();
  }

  handleMakeEnquiry = () => {
    const { item, doEnquireTourReservation, offer } = this.props;
    const { pkg } = item;

    return doEnquireTourReservation(
      item.id,
      pkg.fk_tour_id,
      pkg.fk_tour_option_id,
      {
        days: pkg.number_of_days,
      },
      offer,
    );
  };

  handleSelectDate = (date) => {
    this.setState({
      selectedDate: {
        startDate: date.startDate,
        endDate: date.endDate,
      },
    });
  };

  // handleFinalizeDateSelection actually updates the reservation of the item
  // with the current date selection
  handleFinalizeDateSelection = () => {
    const { item, onUpdateReservation, closeModal } = this.props;
    const { selectedDate } = this.state;

    onUpdateReservation(item, {
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
    });

    closeModal();
  };

  render() {
    const { isOpen, closeModal, item } = this.props;
    const { selectedDate } = this.state;
    const { enquiry } = item;
    const { dates } = enquiry;

    const dateGroups = getDateGroup(dates);

    return (
      <Dialog open={isOpen} onClose={closeModal}>
        <DialogTitle>Selecting Dates</DialogTitle>
        <DialogContent>
          <div className="modal-desc">
            <ul className="list-unstyled">
              <li>{item.pkg.name}</li>
            </ul>
          </div>
          <RadioDatePicker
            onSelectDate={this.handleSelectDate}
            startDate={selectedDate.startDate}
            dateGroups={dateGroups}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            className="T-finalize-date"
            disabled={!selectedDate}
            onClick={this.handleFinalizeDateSelection}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doEnquireTourReservation: enquireTourReservation,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(TourBookingModal);
