import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Stack } from '@mui/material';

import {
  addTravellerToPNR,
  removePnr,
  removeTravellerFromPNR,
  updatePNRId,
  updateTravellerDetails,
} from '~/actions/cart';

import OfflineFlightItemWrapper from '../../../Common/Booking/OfflineFlightItemWrapper';

import BusinessTravellerInformation from './BusinessTraveller/BusinessTravellerInformation';
import TravellerInformation from './TravellerInformation';

interface Props {
  pnr: App.OfflineFlightPnr;
  pnrs: App.OfflineFlightPnr[];
  pnrIndex: number;
  currencyCode: string;
  onUpdateTravellerDetails: Utils.ActionDispatcher<typeof updateTravellerDetails>;
  onRemoveTravellerFromPNR: Utils.ActionDispatcher<typeof removeTravellerFromPNR>;
  onAddTravellerToPNR: Utils.ActionDispatcher<typeof addTravellerToPNR>;
  onRemovePnr: Utils.ActionDispatcher<typeof removePnr>;
  onUpdatePNRId: Utils.ActionDispatcher<typeof updatePNRId>;
}

function PNRInformation(props: Props) {
  const {
    pnr,
    pnrs,
    pnrIndex,
    currencyCode,
    onUpdateTravellerDetails,
    onRemoveTravellerFromPNR,
    onAddTravellerToPNR,
    onRemovePnr,
    onUpdatePNRId,
  } = props;

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const handleAddTravellerClick = useCallback(() => {
    onAddTravellerToPNR(pnrIndex);
  }, [onAddTravellerToPNR, pnrIndex]);

  const handleRemovePnrClick = useCallback(() => {
    // his form should never be able to be submitted without a PNR and Traveller.
    if (pnrs.length === 1) {
      return;
    }
    onRemovePnr(pnrIndex);
  }, [onRemovePnr, pnrIndex, pnrs.length]);

  return (
    <OfflineFlightItemWrapper pnr={pnr} pnrIndex={pnrIndex} currencyCode={currencyCode} onUpdatePNRId={onUpdatePNRId}>
      {pnr.travellers.map((traveller, travellerIndex) => {
        return (
          <>
            {brand === 'lebusinesstraveller' && (
              <BusinessTravellerInformation
                pnr={pnr}
                key={`pnr-${pnrIndex}-traveller-${travellerIndex}`}
                traveller={traveller}
                travellerIndex={travellerIndex}
                pnrIndex={pnrIndex}
                onUpdateTravellerDetails={onUpdateTravellerDetails}
                onRemoveTravellerFromPNR={onRemoveTravellerFromPNR}
              />
            )}
            {brand !== 'lebusinesstraveller' && (
              <TravellerInformation
                pnr={pnr}
                key={`pnr-${pnrIndex}-traveller-${travellerIndex}`}
                traveller={traveller}
                travellerIndex={travellerIndex}
                pnrIndex={pnrIndex}
                onUpdateTravellerDetails={onUpdateTravellerDetails}
                onRemoveTravellerFromPNR={onRemoveTravellerFromPNR}
              />
            )}
          </>
        );
      })}

      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="text"
          size="small"
          onClick={handleAddTravellerClick}
          classes={{
            root: 'T-quantity-plus-traveller', // Required for e2e test
          }}
          startIcon={<AddIcon />}
        >
          Add Traveller
        </Button>
        <Button
          variant="text"
          size="small"
          disabled={pnrs.length === 1} // Prevent delete of the last PNR.
          onClick={handleRemovePnrClick}
          startIcon={<DeleteIcon />}
        >
          Remove PNR
        </Button>
      </Stack>
    </OfflineFlightItemWrapper>
  );
}

export default PNRInformation;
