import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { regions as allRegions } from '@luxuryescapes/lib-regions/lib/regions';

import Image from '~/components/Common/Image';
import { TrendingPlace } from '~/components/Content/TrendingPlaces/Columns';
import { TRENDING_PLACES_BRANDS } from '~/components/Content/TrendingPlaces/TrendingPlacesPage';
import { handleChangeUtil } from '~/components/Content/TrendingPlaces/utils';

import { uploadImage } from '~/services/ImageService';
import SearchService from '~/services/SearchService';

type Props = {
  onClose: () => void;
  rowToUpdate: TrendingPlace;
};

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledButton = styled(IconButton)`
  margin-left: 10px;
`;

const UpdateTrendingPlaceForm = ({ onClose, rowToUpdate }: Props) => {
  const [manualPosition, setManualPosition] = useState<number | null>(
    Number.isNaN(rowToUpdate.manualPosition) ? null : (rowToUpdate.manualPosition as number),
  );
  const [showManualPosition, setShowManualPosition] = useState<boolean>(!isNaN(rowToUpdate.manualPosition as number));
  const [imageId, setImageId] = useState<string | null>(rowToUpdate.imageId ?? null);
  const [brands, setBrands] = useState<Array<string>>([rowToUpdate.brand]);
  const [regions, setRegions] = useState<Array<string>>([rowToUpdate.region]);
  const allRegionCodes = allRegions['luxuryescapes'].map((region) => region.code);
  const allBrandValues = TRENDING_PLACES_BRANDS.map((region) => region.value);
  const [error, setError] = useState<JSX.Element | null>(null);

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    const res = await uploadImage(file);
    setImageId(res.body.public_id);
  };

  const handleManualPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Allow only integers starting at 1
    if (inputValue === '' || (Number.isInteger(Number(inputValue)) && Number(inputValue) >= 1)) {
      setManualPosition(Number(inputValue));
    } else {
      setManualPosition(null);
    }
  };

  const handleRemoveManualPosition = () => {
    setShowManualPosition(false);
    setManualPosition(null);
  };

  const handleBrandChange = (event: SelectChangeEvent<string[]>) => {
    event.preventDefault();
    const newBrands = handleChangeUtil({
      eventValue: event.target.value as string[],
      rowValue: rowToUpdate.brand,
      currentValues: brands,
      allValues: allBrandValues,
    });

    setBrands(newBrands);
  };

  const handleRegionChange = (event: SelectChangeEvent<string[]>) => {
    event.preventDefault();
    const newRegions = handleChangeUtil({
      eventValue: event.target.value as string[],
      rowValue: rowToUpdate.region,
      currentValues: regions,
      allValues: allRegionCodes,
    });

    setRegions(newRegions);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    if (
      regions.length === 1 &&
      regions[0] === rowToUpdate.region &&
      brands.length === 1 &&
      brands[0] === rowToUpdate.brand &&
      manualPosition === rowToUpdate.manualPosition &&
      (imageId === rowToUpdate.imageId || (!imageId && !rowToUpdate.imageId))
    ) {
      setError(<Alert severity="error">No changes made</Alert>);
      return;
    }

    const failedToAdd: Array<{ brand: string; region: string }> = [];
    for (const region of regions.filter((region) => region !== 'selectAll')) {
      for (const brand of brands.filter((region) => region !== 'selectAll')) {
        try {
          const { status } = await SearchService.upsertTrendingPlace(
            rowToUpdate.placeId,
            brand,
            region,
            imageId,
            manualPosition,
          );
          if (status !== 200) {
            failedToAdd.push({
              brand: TRENDING_PLACES_BRANDS.find((item) => item.value === brand).name,
              region: allRegions['luxuryescapes'].find((item) => item.code === region).name,
            });
          }
        } catch (error) {
          failedToAdd.push({ brand, region });
        }
      }
    }

    if (failedToAdd.length > 0) {
      const errorMessage = (
        <Alert severity="error">
          <strong>Failed to update the following combinations:</strong>
          <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
            {failedToAdd.map((item, index) => (
              <li key={index}>
                <strong> - Brand:</strong> {item.brand}
                <strong> | Region:</strong> {item.region}
              </li>
            ))}
          </ul>
        </Alert>
      );

      setError(errorMessage);
      return;
    }

    onClose();
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 3 }}>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" gap={4}>
          <Box>
            <Typography fontWeight="bold" variant="subtitle1">
              Place Name: {rowToUpdate.placeName}
            </Typography>
            <Typography fontWeight="bold" variant="subtitle1">
              Place ID: {rowToUpdate.placeId}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="flex-start" gap={2}>
            {showManualPosition && (
              <>
                <TextField
                  label="Manual Position"
                  type="number"
                  value={manualPosition}
                  onChange={handleManualPositionChange}
                  inputProps={{ min: 1, step: 1 }}
                  variant="outlined"
                  helperText={
                    'A manual position of 1 is at the top of the results.' +
                    ' Removing a manual position will make the place fall back to its impression score.' +
                    ' If the place does not have an impression score then it will not be shown as a trending place.'
                  }
                  fullWidth
                  required={showManualPosition}
                />
                <Button variant="outlined" component="label" color="error" onClick={handleRemoveManualPosition}>
                  Remove
                </Button>
              </>
            )}
            {!showManualPosition && (
              <Button variant="outlined" component="label" onClick={() => setShowManualPosition(true)}>
                Add Manual Position
              </Button>
            )}
          </Box>

          <FormControl fullWidth variant="outlined">
            <InputLabel id="brands-select-label">Select Regions (Required)</InputLabel>
            <Select
              labelId="brands-select-label"
              multiple
              value={regions}
              onChange={handleRegionChange}
              renderValue={(selected) => selected.filter((region) => region !== 'selectAll').join(', ')}
              label="Select Regions (Required)"
              required
            >
              {[{ name: 'Select All', code: 'selectAll' }, ...allRegions['luxuryescapes']].map((region) => (
                <MenuItem key={region.code} value={region.code}>
                  <Checkbox checked={regions.indexOf(region.code) > -1} />
                  <ListItemText primary={region.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel id="brands-select-label">Select Brands (Required)</InputLabel>
            <Select
              labelId="brands-select-label"
              multiple
              value={brands}
              onChange={handleBrandChange}
              renderValue={(selected) => selected.filter((brand) => brand !== 'selectAll').join(', ')}
              label="Select Brands (Required)"
              required
            >
              {[{ name: 'Select All', value: 'selectAll' }, ...TRENDING_PLACES_BRANDS].map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  <Checkbox checked={brands.indexOf(brand.value) > -1} />
                  <ListItemText primary={brand.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!imageId && (
            <Box display="flex" justifyContent="flex-start">
              <Button variant="outlined" component="label">
                Upload Image
                <VisuallyHiddenInput onChange={(event) => onImageChange(event)} type="file" />
              </Button>
            </Box>
          )}

          {imageId && (
            <Box display="flex" alignItems="center" gap={2}>
              <Image options={{ width: 150, dpr: 3 }} publicId={imageId} loading="lazy" />

              <StyledButton sx={{ height: 10 }} onClick={() => setImageId(undefined)}>
                <CloseIcon />
              </StyledButton>
            </Box>
          )}
          {error}
          <Stack direction="column" gap={2}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Container>
  );
};

export default UpdateTrendingPlaceForm;
