// This should really be fetched from a template
export const LUX_PLUS_FINEPRINT_CONTENT_LPC = `
**LuxPlus+ Terms & Conditions:**

- LuxPlus+ pricing and bonus inclusions apply to LuxPlus+ members only. Please ensure you are signed into the account associated with your LuxPlus+ membership while booking.
- Bonus inclusions will be automatically applied during checkout when you make a booking with a participating hotel using the account associated with your LuxPlus+ membership.
- **Please note:** Bonus inclusions vary depending on the participating hotel. Please refer to your package inclusions upon checkout/purchase to confirm the bonus inclusions associated with your booking.
- Bonus inclusions are only available when the LuxPlus+ member is the primary traveller on the booking.
- LuxPlus+ bonus inclusions are not available for bookings made back-to-back (separate bookings for consecutive nights’ stay) at the same hotel. In the instance of back-to-back bookings at the same hotel being made, you will only receive the included benefits once.

LuxPlus+ benefits will not be awarded in relation to any purchases, transactions or non-transactional actions that are reversed, cancelled and/or refunded.

LuxPlus+ member discounts and bonus inclusions cannot be converted or exchanged for cash.

Luxury Escapes reserves the right to review all bookings and bonus inclusions to ensure compliance with LuxPlus+ terms and conditions.

LuxPlus+ member pricing cannot be combined with any other promotions, offers or discount codes unless explicitly stated by Luxury Escapes.

To receive member-only pricing, discounts, early access and bonus inclusions, sign up to LuxPlus+. Learn more [here](https://luxuryescapes.com/lux-plus).
`;

export const LUX_PLUS_FINEPRINT_CONTENT_FLASH = `
**LuxPlus+ Terms & Conditions:**

LuxPlus+ benefits will not be awarded in relation to any purchases, transactions or non-transactional actions that are reversed, cancelled and/or refunded.

LuxPlus+ member discounts cannot be converted or exchanged for cash.

Luxury Escapes reserves the right to review all bookings to ensure compliance with LuxPlus+ terms and conditions.

LuxPlus+ member pricing cannot be combined with any other promotions, offers or discount codes unless explicitly stated by Luxury Escapes.

To receive member-only pricing, discounts, early access and bonus inclusions on select offers, sign up to LuxPlus+. Learn more [here](https://luxuryescapes.com/lux-plus).
`;
