import React, { useMemo } from 'react';

import { Alert, AlertTitle, Button, CircularProgress, Container, Stack } from '@mui/material';

import useHeroPlannerBrands from '~/hooks/customerCommunication/useHeroPlannerBrands';
import { useAppSelector } from '~/hooks/store';

import { isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

import SchedulesFilters from '../Components/SchedulesFilters';
import SchedulesTable from '../Components/SchedulesTable';

function HeroPlannerSchedulesListPage() {
  const { brandsReq, fetchBrands } = useHeroPlannerBrands();
  const tenant = useAppSelector((state) => state.tenant);
  const isBrandSupported = useMemo(() => {
    return !!brandsReq?.result?.find((b) => b.id === tenant.brand);
  }, [tenant.brand, brandsReq?.result]);

  if (isRequestUnresolved(brandsReq)) {
    return (
      <Container maxWidth="xl">
        <CircularProgress variant="indeterminate" />
      </Container>
    );
  }

  if (isRequestRejected(brandsReq)) {
    return (
      <Container maxWidth="xl">
        <Alert
          severity="error"
          action={
            <Button size="small" color="inherit" onClick={fetchBrands}>
              Retry
            </Button>
          }
        >
          <AlertTitle>There was a problem fetching Hero Planner supported brands.</AlertTitle>
          {brandsReq.error}
        </Alert>
      </Container>
    );
  }

  if (!isBrandSupported) {
    return (
      <Container maxWidth="xl">
        <Alert severity="warning">Hero Planner is currently not supported for {tenant.title}</Alert>
      </Container>
    );
  }

  return (
    <Stack direction="column" gap={3}>
      <Container maxWidth="lg">
        <SchedulesFilters />
      </Container>
      <Container maxWidth={false} sx={{ width: '100%', maxWidth: 'min(100vw, 1800px)' }}>
        <SchedulesTable />
      </Container>
    </Stack>
  );
}

export default HeroPlannerSchedulesListPage;
