import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

export default function TourOptionPane({ tourOptions, vendorId, tourId }) {
  return (
    <div>
      <PageSubheader title="Tour Options">
        <Button variant="text" component={Link} to={`/vendors/${vendorId}/tours/${tourId}/edit-tour-options/`}>
          Edit
        </Button>
      </PageSubheader>

      <Box display="grid" rowGap={1} columnGap={4} gridTemplateColumns="1fr 1fr">
        {tourOptions.map((tourOption) => (
          <Stack direction="row" spacing={2} key={tourOption.id}>
            <Box pt="2px">
              <DefinitionTitle>Name</DefinitionTitle>
            </Box>

            <Box flexGrow={1}>
              <DefinitionText>{tourOption.name}</DefinitionText>
            </Box>

            <Box>
              <Button
                variant="text"
                size="small"
                component={Link}
                to={`/vendors/${vendorId}/tours/${tourId}/tour-options/${tourOption.id}/dates`}
              >
                Edit dates
              </Button>
            </Box>
          </Stack>
        ))}
      </Box>
    </div>
  );
}
