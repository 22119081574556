import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableCell, Tooltip } from '@mui/material';

import { FlexTableCell } from '~/components/Content/SearchRanking/FlexTableCell';

interface Props {
  segmentScore: App.SegmentedOfferScore;
  backgroundColor: string;
}

function SegmentCellGroup({ segmentScore, backgroundColor }: Props) {
  return (
    <>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segmentScore?.numberOfViews ? segmentScore?.numberOfViews.toLocaleString() : '-'}
      </TableCell>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segmentScore?.salesCount ? segmentScore.salesCount.toLocaleString() : '-'}
      </TableCell>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segmentScore?.totalSales ? Math.round(segmentScore.totalSales).toLocaleString() : '-'}
      </TableCell>
      <FlexTableCell align="right" sx={{ backgroundColor }}>
        {segmentScore?.expectedValue ? segmentScore.expectedValue.toFixed(3) : '-'}
        {segmentScore?.expectedValueInfo && (
          <Tooltip title={segmentScore.expectedValueInfo} placement="top" arrow>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        )}
      </FlexTableCell>
      <TableCell align="right" sx={{ backgroundColor }}>
        {segmentScore?.expectedValueScore || '-'}
      </TableCell>
    </>
  );
}

export default SegmentCellGroup;
