import React, { useCallback, useEffect, useState } from 'react';

import { Button, Stack, TextField } from '@mui/material';

import ImagesForm from '~/components/Common/Forms/ImagesForm';

import { CabinCategoriesGroupById } from '~/services/cruises/CabinCategoriesGroupService';
import { CabinCategory } from '~/services/cruises/CabinCategoriesService';

import CabinCategoriesAutocomplete from '../../CruisesAutocomplete/CabinCategoriesAutocomplete';
import { SubmitParams } from '../constants';

interface Image {
  id: string | number;
  publicImageId: string;
  order: number;
  title?: string;
  hidden?: boolean;
  filename?: string;
}

type FormValues = {
  name: string;
  description: string;
};

export const DEFAULT_FORM_VALUES: FormValues = {
  name: '',
  description: '',
};

type SelectedCabinCategories = Pick<CabinCategory, 'id' | 'code'>[];

type Props = {
  categoriesGroup: CabinCategoriesGroupById;
  handlerDelete: (id: string) => void;
  handlerSubmit: (params: SubmitParams) => void;
};

export default function CabinCategoriesGroupEditableForm(props: Props) {
  const { categoriesGroup, handlerDelete, handlerSubmit } = props;
  const [images, setImages] = React.useState<Image[]>([]);

  const [formValues, setFormValues] = useState<FormValues>(DEFAULT_FORM_VALUES);

  const [selectedCabinCategories, setSelectedCabinCategories] = useState<SelectedCabinCategories>([]);

  const handleCabinCategoriesChange = useCallback((cabinCategories: { id: string; code: string }[]) => {
    setSelectedCabinCategories(
      cabinCategories.map((category) => ({
        id: category.id,
        code: category.code,
      })),
    );
  }, []);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();

      if (event?.target && event?.target?.name) {
        setFormValues((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    },
    [setFormValues],
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      handlerSubmit({
        id: categoriesGroup.id,
        name: formValues.name,
        description: formValues.description,
        cabinCategoriesIds: selectedCabinCategories.map((item) => item.id),
        images: images.map((image) => ({
          order: image.order,
          fileName: image.title,
          imageId: image.publicImageId,
        })),
      });
    },
    [categoriesGroup.id, formValues.description, formValues.name, handlerSubmit, images, selectedCabinCategories],
  );

  const onDelete = useCallback(() => {
    handlerDelete(categoriesGroup.id);
  }, [categoriesGroup.id, handlerDelete]);

  const onAddImage = useCallback(
    async (cloudinaryId: string, filename: string) => {
      const image = {
        title: filename,
        id: cloudinaryId,
        order: images.length,
        publicImageId: cloudinaryId,
      };

      setImages((prev) => [...prev, image]);

      return Promise.resolve(image);
    },
    [images.length],
  );

  const onUpdateImages = useCallback((images: Image[]) => {
    const newImages = images.map((image, index) => ({
      order: index,
      title: image.title,
      id: image.publicImageId,
      publicImageId: image.publicImageId,
    }));

    setImages(newImages);
  }, []);

  const onDeleteImage = useCallback((imageId: string | number) => {
    setImages((prev) => prev.filter((image) => image.id !== imageId));
  }, []);

  useEffect(() => {
    if (categoriesGroup.cabinCategories.length) {
      setSelectedCabinCategories(
        categoriesGroup.cabinCategories.map((item) => ({
          id: item.id,
          code: item.code,
        })),
      );
    }
  }, [categoriesGroup.cabinCategories]);

  useEffect(() => {
    if (categoriesGroup.images.length) {
      const sortedImages = categoriesGroup.images
        .map((image) => ({
          id: image.imageId,
          publicImageId: image.imageId,
          order: image.order,
          title: image.filename,
        }))
        .sort((a, b) => a.order - b.order);

      setImages(sortedImages);
    }
  }, [categoriesGroup.images]);

  useEffect(() => {
    setFormValues({
      name: categoriesGroup.name,
      description: categoriesGroup.description,
    });
  }, [categoriesGroup.description, categoriesGroup.name]);

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Stack spacing={3}>
        <Stack spacing={2} direction="row">
          <TextField
            required
            style={{ width: '40%' }}
            name="name"
            value={formValues.name}
            onChange={handleChangeField}
            label="Cabin Categories Group Name"
          />
          <CabinCategoriesAutocomplete
            requiredShip
            shipId={categoriesGroup.ship.id}
            onChange={handleCabinCategoriesChange}
            selectedCabinCategories={selectedCabinCategories}
          />
        </Stack>

        <Stack spacing={2} direction="row">
          <TextField
            required
            fullWidth
            multiline
            minRows={4}
            name="description"
            onChange={handleChangeField}
            value={formValues.description}
            label="Cabin Categories Group Description"
          />
        </Stack>

        <ImagesForm
          images={images}
          showSaveButton={false}
          onAddImage={onAddImage}
          onDeleteImage={onDeleteImage}
          onUpdateImages={onUpdateImages}
        />

        <Stack spacing={2} direction="row" justifyContent="end">
          <Button onClick={onDelete} variant="outlined" color="error">
            Delete Group
          </Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
