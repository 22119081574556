import React from 'react';

import { Box, Stack } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import PageHeader from '~/components/Common/Elements/PageHeader';

import useAccommodationPropertyDetailsContext from './context/useAccommodationPropertyDetailsContext';

export default function AccommodationPropertyDetailsOverviewPane() {
  const { propertyDetails } = useAccommodationPropertyDetailsContext();
  return (
    <Stack direction="column">
      <PageHeader title="Property overview" />

      <Box display="grid" gap={2} gridTemplateColumns="repeat(5, 1fr)">
        <Box>
          <DefinitionTitle>Name</DefinitionTitle>
          <DefinitionText>{propertyDetails.name}</DefinitionText>
        </Box>
      </Box>

      <Box mt={4} display="grid" gap={2} gridTemplateColumns="repeat(8, 1fr)">
        <Box gridColumn="span 2">
          <DefinitionTitle>ID</DefinitionTitle>
          <DefinitionText>{propertyDetails.id}</DefinitionText>
        </Box>
      </Box>
    </Stack>
  );
}
