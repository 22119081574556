/* eslint-disable react/display-name */
import React, { useState } from 'react';

import difference from 'lodash/difference';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';

interface Props {
  lengthOfStay: number;
  numberOfAdults: number;
  childrenAges: number[];
  getRoomRateDatesByOptions: (los, noa, childrenAges) => void;
  changeAmountInCalendar: () => void;
  showWithoutTax: boolean;
}

export default ({
  lengthOfStay,
  numberOfAdults,
  childrenAges: initChildrenAges,
  getRoomRateDatesByOptions,
  changeAmountInCalendar,
  showWithoutTax,
}: Props) => {
  const [los, updateLengthOfStay] = useState(lengthOfStay);
  const [noa, updateNumberOfGuests] = useState(numberOfAdults);
  const [childrenAges, setChildrenAges] = useState(initChildrenAges);
  const haveValuesChanged =
    Number(los) !== lengthOfStay ||
    Number(noa) !== numberOfAdults ||
    initChildrenAges.length !== childrenAges.length ||
    difference(childrenAges, initChildrenAges).length > 0;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
      <Stack spacing={1} direction="row" alignItems="center">
        <Box>
          <TextField
            type="number"
            InputProps={{
              inputProps: {
                max: 99,
                min: 1,
              },
            }}
            value={los}
            aria-label="Confirm"
            onChange={(e) => updateLengthOfStay(parseInt(e.target.value))}
            label="Nights"
          />
        </Box>
        <Box>
          <TextField
            type="number"
            InputProps={{
              inputProps: {
                max: 99,
                min: 1,
              },
            }}
            value={noa}
            aria-label="Confirm"
            onChange={(e) => updateNumberOfGuests(parseInt(e.target.value))}
            label="Adults"
          />
        </Box>
        <Autocomplete
          sx={{ width: '450px' }}
          defaultValue={[]}
          multiple
          id="tags-filled"
          options={[]}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} size="small" variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          onChange={(event, values: string[]) => {
            setChildrenAges(values.map((value) => parseInt(value)));
          }}
          renderInput={(params) => (
            <TextField {...params} label="Children ages:" type="number" placeholder="Type and press Enter" />
          )}
        />
        <Button
          variant="contained"
          onClick={() => {
            getRoomRateDatesByOptions(los, noa, childrenAges);
          }}
          disabled={!haveValuesChanged}
        >
          Update
        </Button>
      </Stack>
      <Box sx={{ textAlign: 'left' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="amount-toggle"
                value="selection"
                defaultChecked={showWithoutTax}
                onClick={changeAmountInCalendar}
              />
            }
            label="Show amount without tax (ABT)"
          />
        </FormGroup>
      </Box>
    </Stack>
  );
};
