export type Tag = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

export type TagsAction = {
  message?: string;
  payload?: Tag[];
  type: 'LOAD' | 'FETCH' | 'ERROR' | 'RESET';
};

export type TagsState = {
  error: boolean;
  message?: string;
  loading: boolean;
  tags: Tag[];
};

export const initialTagsState: TagsState = {
  tags: [],
  error: false,
  message: null,
  loading: false,
};

export const fetchTagsReducer = (state: TagsState, action: TagsAction) => {
  switch (action.type) {
    case 'LOAD':
      return { error: false, loading: true, tags: [] };
    case 'RESET':
      return { error: false, loading: false, tags: [] };
    case 'ERROR':
      return {
        tags: [],
        error: true,
        loading: false,
        message: action.message,
      };
    case 'FETCH':
      return {
        error: false,
        loading: false,
        tags: action?.payload,
      };
    default:
      throw new Error('Error in reducer (fetch-tags) action.type');
  }
};
