import React, { Context, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { CruiseInclusion } from '~/components/Cruises/pages/Inclusions/types';

import inclusionsService, { InclusionSearchFormValues } from '~/services/cruises/InclusionsService';

import { parseSearchString } from '~/utils/url';

interface Filter {
  name?: string;
  cruiseLine?: string;
}

interface CruisesData {
  inclusions: Array<CruiseInclusion>;
  page: number;
  total: number;
  loading: boolean;
  recordsPerPage: number;
  paginate: (page: number) => void;
  setFilter: (value: Filter) => void;
  filter: Filter;
  getInclusionList: (params?: InclusionSearchFormValues) => Promise<void>;
}

interface CruiseInclusionsProviderProps {
  children?: React.ReactNode;
}

export const CruiseInclusionsContext: Context<CruisesData> = createContext({} as CruisesData);

const RECORDS_PER_PAGE = 15;

export default function CruiseInclusionsProvider() {
  const [filter, setFilter] = useState<Filter>({});
  const { search } = useLocation();
  const { push: setQueryString } = useHistory();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [inclusions, setInclusions] = useState<Array<CruiseInclusion>>([]);
  const [loading, setLoading] = useState(false);

  const getInclusionList = useCallback(
    async (params?: InclusionSearchFormValues) => {
      try {
        const skip = (page - 1) * RECORDS_PER_PAGE;
        setLoading(true);
        const res = await inclusionsService.listWithPagination({
          ...(params || {}),
          skip,
          take: RECORDS_PER_PAGE,
        });
        setInclusions(res.result);
        setTotal(res.total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [page],
  );

  const setCurrentPage = useCallback((): void => {
    const queryString = parseSearchString(search);
    if (queryString) {
      const pageToGo = parseInt(queryString.page as string);
      if (pageToGo) {
        setPage(pageToGo);
      }
    }
  }, [search]);

  const paginate = useCallback(
    (page: number): void => {
      const queryString = new URLSearchParams({
        page: page.toString(),
      }).toString();
      setQueryString({ search: queryString });
      setPage(page);
    },
    [setQueryString],
  );

  useEffect(() => {
    setCurrentPage();
  }, [setCurrentPage]);

  useEffect(() => {
    getInclusionList();
  }, [getInclusionList]);

  return {
    filter,
    setFilter,
    inclusions,
    page,
    total,
    RECORDS_PER_PAGE,
    paginate,
    getInclusionList,
    loading,
  };
}

export const useCruiseInclusions = (): CruisesData => useContext(CruiseInclusionsContext);
