import React from 'react';

import pluralize from 'pluralize';

import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import KingBedOutlinedIcon from '@mui/icons-material/KingBedOutlined';
import SingleBedOutlinedIcon from '@mui/icons-material/SingleBedOutlined';
import { Box, Stack } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

export default class RoomConfigurationPane extends React.Component {
  roomConfigurations: any;

  constructor(props) {
    super(props);

    this.roomConfigurations = props.roomConfigurations;
  }

  getBedIcon(bedType, bedCount, bedIndex) {
    let Icon;
    switch (bedType) {
      case 'KING_SIZE_BED':
        Icon = KingBedOutlinedIcon;
        break;
      case 'SINGLE_BED':
        Icon = SingleBedOutlinedIcon;
        break;
      default:
        Icon = BedOutlinedIcon;
    }

    // Render the icon multiple times to represent the number of beds
    const icons = [];
    for (let i = 0; i < bedCount; i++) {
      icons.push(<Icon sx={{ fontSize: 60 }} key={`${bedIndex}-${i}`} />);
    }
    return <Stack direction="row">{icons}</Stack>;
  }

  render() {
    return (
      <Box>
        <PageSubheader title="Bedrooms" />

        <Stack direction="row" spacing={1}>
          {this.roomConfigurations.map((roomConfiguration, index) => {
            if (roomConfiguration.beds.length === 0) {
              return null;
            }
            return (
              <Box key={index} sx={{ border: '1px solid grey', minWidth: '150px' }}>
                <Stack m={1}>
                  <Stack direction="row" spacing={1}>
                    {roomConfiguration.beds.map((bed, bedIndex) => (
                      <Stack key={bedIndex}>
                        {this.getBedIcon(bed.bedType, bed.count, bedIndex)}
                        <span>
                          {bed.count} {pluralize(bed.bedTypeDescription, parseInt(bed.count))}
                        </span>
                      </Stack>
                    ))}
                  </Stack>
                  {roomConfiguration.roomTypeDescription.toLowerCase() === 'bedroom' && (
                    <h4>{`Bedroom ${index + 1}`}</h4>
                  )}
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </Box>
    );
  }
}
