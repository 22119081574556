import React from 'react';

import { theme } from '~/theme';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, TableCell as MUITableCell, TableRow } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import { currencyFormatter } from '../../../helpers';

type ExperienceListRowProps = {
  exp: Experiences.Offer;
  selectedExperiences: Experiences.Offer[];
  addOnclick: (exp: Experiences.Offer) => void;
  removeOnclick: (exp: Experiences.Offer) => void;
};

const TableCell = ({ children, ...props }) => (
  <MUITableCell sx={{ padding: 1, fontSize: 16, verticalAlign: 'top', borderBottom: 'none' }} {...props}>
    {children}
  </MUITableCell>
);

const ExperienceListRow: React.FC<ExperienceListRowProps> = ({
  exp,
  selectedExperiences,
  removeOnclick,
  addOnclick,
}) => {
  const selected = selectedExperiences.some((item) => item.id === exp.id);
  const disabled = selectedExperiences.length > 2 && !selected;

  return (
    <TableRow
      sx={{
        background: theme.palette.grey[50],
        ':nth-of-type(even)': {
          background: theme.palette.grey[100],
        },
        ':hover': {
          background: theme.palette.grey[200],
        },
      }}
    >
      <TableCell width={300}>
        <div>
          <strong>{exp.id}</strong>
        </div>
        <div>{exp.title}</div>
      </TableCell>
      <TableCell width={200}>{exp.provider}</TableCell>
      <TableCell width={200}>{currencyFormatter(exp.baseSalesPrice.currencyCode, exp.baseSalesPrice.amount)}</TableCell>
      <TableCell width={200} style={{ textAlign: 'end' }}>
        <IconButton size="large" disabled={disabled} onClick={() => (selected ? removeOnclick(exp) : addOnclick(exp))}>
          {selected ? <RemoveCircleIcon /> : <AddCircleIcon color="info" />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ExperienceListRow;
