import React, { ComponentProps, useCallback } from 'react';

import { Autocomplete, Checkbox, TextField } from '@mui/material';

const TTC_TOUR_BRANDS = {
  aatkings: 'AATKings',
  contiki: 'Contiki',
  luxurygold: 'Luxury Gold',
  trafalgar: 'Trafalgar',
  insightvacations: 'Insight Vacations',
  costsaver: 'Cost Saver',
};

const TTC_TOUR_BRAND_OPTIONS = Object.keys(TTC_TOUR_BRANDS);

interface Props {
  value: string[];
  onChange: (newValue?: string[]) => void;
}

export default function TourOfferTTCBrandInput(props: Props) {
  const { value, onChange } = props;

  const handleChange = useCallback<ComponentProps<typeof Autocomplete>['onChange']>(
    (e, value: string[]) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      fullWidth
      multiple
      options={TTC_TOUR_BRAND_OPTIONS}
      openOnFocus
      disableCloseOnSelect
      getOptionLabel={(option: string) => TTC_TOUR_BRANDS[option]}
      renderOption={(props, option: string, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} />
          {TTC_TOUR_BRANDS[option]}
        </li>
      )}
      renderInput={(params) => (
        <>
          <TextField {...params} label="TTC Brands" />
        </>
      )}
    />
  );
}
