import React from 'react';

import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

interface Props {
  order: App.Order;
}

const signatureFormatter = (cell) => {
  if (!cell) {
    return '';
  }

  return 'Signed with signature';
};

const columns: GridColDef[] = [
  {
    field: 'id_customer_signature',
    headerName: 'ID',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'signature_pic',
    headerName: 'Signed?',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => signatureFormatter(value),
    display: 'flex',
  },
  {
    field: 'created_at',
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value)),
    display: 'flex',
  },
];

const OrderTermsAndConditions = ({ order }: Props) => {
  const customerSignatures = order.customer_signatures;

  if (!customerSignatures || customerSignatures.length === 0) {
    return null;
  }

  // todo: implement showing signature
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Terms and Conditions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          columns={columns}
          rows={customerSignatures}
          getRowId={(row) => row.id_customer_signature}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderTermsAndConditions;
