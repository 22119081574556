import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack } from '@mui/material';

import { MappedAuditLog } from '~/services/AuditService';
import { formatDateShort } from '~/services/TimeService';

import { daysOfWeekMessage } from '~/utils/daysOfWeek';

import { AuditLog } from './AuditLog';

type Props = {
  blackoutDateBlocks: MappedAuditLog[];
};

export default function BlackoutDateBlockHistory({ blackoutDateBlocks }: Props) {
  const firstLog = blackoutDateBlocks[0];

  return (
    <Accordion disableGutters sx={{ paddingLeft: '2em' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container item spacing={1}>
          <Grid item>
            {formatDateShort(firstLog.diff.added?.start_date)} &nbsp; &mdash; &nbsp;
            {formatDateShort(firstLog.diff.added?.end_date)}
          </Grid>
          <Grid item>{daysOfWeekMessage(firstLog.diff?.added)}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {blackoutDateBlocks.map((block) => (
            <AuditLog key={block.logId} auditLog={block} />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
