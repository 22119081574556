import React from 'react';

import currencyFormatter from 'currency-formatter';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import PermissionComponent from '../../Common/PermissionedComponent';

const formatAmount = (amount, currency) => {
  return currencyFormatter.format(amount, { code: currency, precision: 0 }) + ' ' + currency;
};

export default function RoomRateExtraGuestSurchargeItem({ extraGuestSurchargeItem, onDelete, onRequestEdit }) {
  const {
    currency,
    adult_cost,
    adult_amount,
    child_cost,
    child_amount,
    infant_cost,
    infant_amount,
    payable_at_property,
  } = extraGuestSurchargeItem;

  const showConfirmDialog = useConfirmDialog();

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete this surcharge?',
      description:
        'Surcharges should not be deleted after a deal has been set live - please contact sales operations (OCM) or an account manager before proceeding.',
    });

    if (!confirmed) return;

    onDelete(extraGuestSurchargeItem.id);
  };

  const handleEditRequest = () => {
    onRequestEdit(extraGuestSurchargeItem);
  };

  return (
    <Grid container spacing={2} direction="row" className="box-list-item">
      <Grid xs={9}>
        <div>
          Adults {formatAmount(adult_amount, currency)} (Cost Price: {formatAmount(adult_cost, currency)})
        </div>
        <div>
          Children {formatAmount(child_amount, currency)} (Cost Price: {formatAmount(child_cost, currency)})
        </div>
        <div>
          Infants {formatAmount(infant_amount, currency)} (Cost Price: {formatAmount(infant_cost, currency)})
        </div>
        <div>EGS are payable at property: {payable_at_property === true ? 'Yes' : 'No'}</div>
      </Grid>
      <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
        <Grid xs={3} display="flex" gap={2} justifyContent="start">
          <Button variant="text" startIcon={<EditIcon />} onClick={handleEditRequest}>
            Edit
          </Button>
          <Button variant="text" startIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button>
        </Grid>
      </PermissionComponent>
    </Grid>
  );
}
