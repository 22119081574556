import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerMembershipGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/country-groups/{countryGroup}/countries/{country}/states/{state}/cities/{city}/segments/{segment}/cadences/{cadence}/loyalties']['get'];

function mapHeroPlannerMembership(
  external: Utils.ArrayElement<
    HeroPlannerMembershipGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerStateSegment {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetHeroPlannerMemberships(
  brand: string,
  countryGroup: string,
  country: string,
  state: string,
  city: string,
  segment: string,
  cadence: string,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!state) throw 'missing state';
  if (!city) throw 'missing city';
  if (!segment) throw 'missing segment';
  if (!cadence) throw 'missing cadence';

  const url = makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/country-groups/${countryGroup}/countries/${country}/states/${state}/cities/${city}/segments/${segment}/cadences/${cadence}/loyalties`,
  );
  url.searchParams.set('brand', brand);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerMembershipGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerMembership);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerMemberships;
