/* Purchases/Home/PurchasesPageContainer.js */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import CartPaneContainer from '~/components/Cart/CartPaneContainer';
import PageHeader from '~/components/Common/Elements/PageHeader';

import { ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';
import useQuery from '~/hooks/useQuery';

import canShowTab from '~/utils/canShowTab';

import AddonsPage from './AddonsPageContainer';
import OrdersPage from './AllOrders/OrdersPageContainer';
import BedbankPage from './BedbankPageContainer';
import BundleAndSavePage from './BundleAndSavePageContainer';
import CarHirePage from './CarHire/CarHirePurchasePage';
import CruisesPage from './Cruises/CruisesPurchasePage';
import CustomOffersPage from './CustomOffers';
import FlightsPage from './FlightsPageContainer';
import GiftCardsPage from './GiftCardsPageContainer';
import InsurancesPage from './InsurancesPageContainer';
import { LuxPlusPageContainer } from './LuxPlus';
import PackagesPage from './PackagesPageContainer';
import ToursPage from './ToursPageContainer';

const TAB_ALL_ORDERS = 'allOrders';
type Tab = {
  label: string;
  pageTitle: string;
  component: React.ComponentType;
  showTab?: boolean;
};

function defaultTabByRole(user) {
  if (user?.roles?.includes(ROLE_EXPERIENCES_COORDINATOR)) {
    return 'packages';
  }
  return TAB_ALL_ORDERS;
}

export default function PurchasesPageContainer() {
  const { user } = useCurrentUser();
  const { section = defaultTabByRole(user) } = useParams<{ section?: Tabs }>();
  const query = useQuery();
  const customerId = query.get('customer_id');

  const TABS_MAP = {
    [TAB_ALL_ORDERS]: {
      label: 'All Orders',
      pageTitle: 'Latest orders - All Types',
      component: OrdersPage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    packages: {
      label: 'Packages',
      pageTitle: 'Latest orders',
      component: PackagesPage,
    } as Tab,
    giftCards: {
      label: 'Gift Cards',
      pageTitle: 'Latest gift cards',
      component: GiftCardsPage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    experiences: {
      label: 'Experiences',
      pageTitle: 'Latest experiences',
      component: AddonsPage,
    } as Tab,
    flights: {
      label: 'Flights',
      pageTitle: 'Latest flights',
      component: FlightsPage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    bedbanks: {
      label: 'Bedbanks',
      pageTitle: 'Bedbank orders',
      component: BedbankPage,
    } as Tab,
    tours: {
      label: 'Tours',
      pageTitle: 'Latest tours',
      component: ToursPage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    packagesWithInsurance: {
      label: 'Insurance',
      pageTitle: 'Latest insurance',
      component: InsurancesPage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    carts: {
      label: 'Carts',
      pageTitle: 'Carts',
      component: CartPaneContainer,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    cruises: {
      label: 'Cruises',
      pageTitle: 'Latest cruises',
      component: CruisesPage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    carHire: {
      label: 'Car Hire',
      pageTitle: 'Latest bookings',
      component: CarHirePage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    bundle: {
      label: 'Bundle and Save',
      pageTitle: 'Bundle and Save',
      component: BundleAndSavePage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    customOffers: {
      label: 'Custom Offers',
      pageTitle: 'Custom Offers',
      component: CustomOffersPage,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
    luxPlus: {
      label: 'Lux Plus',
      pageTitle: 'Lux Plus',
      component: LuxPlusPageContainer,
      showTab: canShowTab(user, [ROLE_EXPERIENCES_COORDINATOR]),
    } as Tab,
  } as const;

  type Tabs = keyof typeof TABS_MAP;

  const tabList = Object.keys(TABS_MAP);
  const currentTab: Tab = TABS_MAP[section];

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{`Orders / ${currentTab.label}`}</title>
      </Helmet>

      <PageHeader title={currentTab.pageTitle} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={section} id="orders" variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
          {tabList
            .filter((tabKey) => {
              const tab: Tab = TABS_MAP[tabKey];

              if (tab.showTab !== undefined) {
                return tab.showTab;
              }

              return true;
            })
            .map((tabKey) => {
              const tab: Tab = TABS_MAP[tabKey];

              return (
                <Tab
                  key={tabKey}
                  label={tab.label}
                  value={tabKey}
                  to={`/purchases/section/${tabKey}${customerId ? `?customer_id=${customerId}` : ''}`}
                  component={Link}
                />
              );
            })}
        </Tabs>
      </Box>

      <Box pt={4}>
        <Box component={currentTab.component} />
      </Box>
    </Container>
  );
}
