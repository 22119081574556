import { isBoolean, isString } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ITEM_TYPE_CAR_HIRE, ITEM_TYPE_SERVICE_FEE, ITEM_TYPE_SUBSCRIPTION } from '~/consts/order';
import {
  CAR_HIRE_REASONS,
  COLLECTION_REASONS,
  EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES,
  EXPIRED_TOUR_REFUND_ALLOWED_TYPES,
  REASONS_WITH_ADDITIONAL_INFO,
  SERVICE_FEE_ALLOWED_TYPES,
  SUBSCRIPTION_REASONS,
  SUBSCRIPTION_REASONS_V2,
  TNFH_REASON_SOURCE,
} from '~/consts/refund';

import currencyFormatter from '~/utils/currencyFormatter';

import { AdditionalInfo, CollectionReasons, EnrichedRefundMetadata, RefundMetadata, RefundPayload } from '../../types';
import { RefundSteps } from '../RefundV2UI';

export function getItemType(item): string {
  if (!item.type) {
    throw new Error(`Does not have an item type: ${item}`);
  }
  return item.type;
}

interface ReasonByTypeDict {
  refundTypes: Array<string>;
  reasons: CollectionReasons;
}

export function getReasonsByItemType(
  itemType: string,
  order: any,
  isRefundable: boolean,
  isExpiredTourRefund = false,
): ReasonByTypeDict {
  let possibleCollectionReasons = {
    refundTypes: Object.keys(COLLECTION_REASONS),
    reasons: COLLECTION_REASONS,
  };

  if (isExpiredTourRefund) {
    possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter((key) =>
      EXPIRED_TOUR_REFUND_ALLOWED_TYPES.includes(key),
    );
    return possibleCollectionReasons;
  }
  switch (itemType) {
    case ITEM_TYPE_CAR_HIRE:
      possibleCollectionReasons = {
        refundTypes: Object.keys(CAR_HIRE_REASONS),
        reasons: CAR_HIRE_REASONS,
      };
      break;
    case ITEM_TYPE_SERVICE_FEE:
      possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter((key) =>
        SERVICE_FEE_ALLOWED_TYPES.includes(key),
      );
      break;
    case ITEM_TYPE_SUBSCRIPTION:
      possibleCollectionReasons = {
        refundTypes: Object.keys(SUBSCRIPTION_REASONS),
        reasons: SUBSCRIPTION_REASONS_V2,
      };
      break;
    default:
      possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter(
        (key) => !EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES.includes(key),
      );
  }

  const hasAccommodation = !!order.accommodation_items.length || !!order.bedbank_items.length;
  const hasCruise = !!order.cruise_items.length;
  const hasTour = !!order.tour_items.length;

  // possibleCollectionReasons.refundTypes
  if (hasAccommodation || hasCruise || hasTour) {
    //   Change refund reason type when outside cancellation period
    if (!isRefundable) {
      possibleCollectionReasons.reasons['INSURANCE_BREAKAGE'].defaults.source = TNFH_REASON_SOURCE;
    }
  } else {
    possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter(
      (key) => key === 'INSURANCE_BREAKAGE',
    );
  }

  return possibleCollectionReasons;
}

export interface CostBreakdown {
  packageCost: string;
  numberOfNights: number;
  surcharges: string;
  avgPricePerNight: string;
  total: string;
}

function buildComment(isTestPurchase, userFullName, refundApprover, commentFallback) {
  let comment = '';

  if (refundApprover) {
    comment += 'Approver: ' + refundApprover + '\n';
  }
  comment += isTestPurchase ? userFullName : commentFallback;

  return comment;
}

export function getDefaultValues(
  refundMetadata: EnrichedRefundMetadata,
  selectedReasonKey: string,
  userFullName: string,
  refundApprover: string,
): RefundPayload {
  let additionalInfo: AdditionalInfo = {};
  if (REASONS_WITH_ADDITIONAL_INFO.includes(selectedReasonKey)) {
    additionalInfo = {
      number_of_nights: 0,
      percentage: 100,
      package_nights: refundMetadata.package_nights,
      total_nights: refundMetadata.total_nights,
      extra_nights: refundMetadata.extra_nights,
    };
  }

  const merchantFeeAmount = refundMetadata.merchant_fee_amount ?? 0;
  const cashAmount = refundMetadata.item_metadata.cash_amount ?? 0;
  const amountToRefund = cashAmount + merchantFeeAmount;

  return {
    transaction_key: uuidv4(),
    reason: refundMetadata?.reason ?? '',
    refund_method: refundMetadata?.refund_method ?? '',
    amount: amountToRefund,
    comment: buildComment(
      selectedReasonKey === 'REFUND_TEST_PURCHASE',
      userFullName,
      refundApprover,
      refundMetadata.comment,
    ),
    ticket_id: refundMetadata.ticket_id,
    room_id: refundMetadata?.room_id ?? undefined,
    currency_code: refundMetadata.currency_code,
    mark_cancelled: refundMetadata.mark_cancelled,
    update_vcc_balance: refundMetadata.update_vcc_balance,
    send_refund_notification: refundMetadata.send_refund_notification,
    send_customer_refund_notification: refundMetadata.send_customer_refund_notification,
    accounting_metadata: [
      {
        source: refundMetadata.item_metadata.source,
        cash_amount: refundMetadata.item_metadata.cash_amount ?? 0,
        accounting_amount: refundMetadata.item_metadata.accounting_amount ?? 0,
        charge_component_key: refundMetadata.item_metadata.charge_component_key,
        refund_fee: refundMetadata.item_metadata.refund_fee,
      },
    ],
    additional_info: additionalInfo,
    disable_credits_promo_refund: refundMetadata.disable_credits_promo_refund,
    remaining_deposit_items: refundMetadata.remaining_deposit_items,
    current_deposit_item: refundMetadata.current_deposit_item,
    is_deposit: refundMetadata.is_deposit,
    is_final_deposit_refund: refundMetadata.is_final_deposit_refund,
    is_instalment: refundMetadata.is_instalment,
    remaining_instalment_items: refundMetadata.remaining_instalment_items,
    current_instalment_item: refundMetadata.current_instalment_item,
    is_pending_deferred_payment: refundMetadata.is_pending_deferred_payment,
    remaining_deferred_payment_items: refundMetadata.remaining_deferred_payment_items,
    current_deferred_payment_item: refundMetadata.current_deferred_payment_item,
    merchant_fee_amount: refundMetadata.merchant_fee_amount,
    variant_of_refund: refundMetadata.variant_of_refund,
  };
}

export type RefundMethods = 'Back To Original' | 'LE Credits' | 'Manual';
export function mapRefundMethodToLabel(refundMethod: RefundMethods) {
  switch (refundMethod) {
    case 'Back To Original': {
      return 'Default';
    }
    case 'LE Credits': {
      return 'Credits';
    }
    case 'Manual': {
      return 'Offline/Manual';
    }
  }
}

export function convertStrOrBoolToBool(value: boolean | string) {
  if (isBoolean(value)) {
    return value;
  }
  return value === 'true';
}

function convertStrOrNumToNum(value: string | number) {
  let numberValue: number;
  if (isString(value)) {
    numberValue = parseFloat(value);
  } else {
    numberValue = value;
  }

  return parseFloat(numberValue.toFixed(2));
}

export function buildRefundPayload(refund: RefundPayload): RefundPayload {
  const formattedAccountingMetadata = refund.accounting_metadata.map((metadata) => {
    // there's an edge case where, if you are doing a refund with fee refund
    // and no NOT mark as cancelled
    // we set the refund fee to 0
    const shouldZeroRefundFee =
      convertStrOrNumToNum(metadata.refund_fee) > 0 && !convertStrOrBoolToBool(refund.mark_cancelled);
    return {
      ...metadata,
      cash_amount: convertStrOrNumToNum(metadata.cash_amount),
      refund_fee: shouldZeroRefundFee ? 0 : convertStrOrNumToNum(metadata.refund_fee),
    };
  });

  const formattedAdditionalInfo = {
    ...refund.additional_info,
    number_of_nights: refund?.additional_info?.number_of_nights?.toString(),
  };
  return {
    ...refund,
    send_customer_refund_notification: convertStrOrBoolToBool(refund.send_customer_refund_notification),
    mark_cancelled: convertStrOrBoolToBool(refund.mark_cancelled),
    amount: convertStrOrNumToNum(refund.amount),
    update_vcc_balance: convertStrOrBoolToBool(refund.update_vcc_balance),
    accounting_metadata: formattedAccountingMetadata,
    additional_info: refund.additional_info ? formattedAdditionalInfo : refund.additional_info,
  };
}

/**
 * @param currency AUD
 * @returns $
 */
export function getCurrencySymbol(currency) {
  return (
    new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: currency,
    })
      .formatToParts(0)
      .find((part) => part.type === 'currency')?.value || currency
  );
}

export function getVendorContribution(refundMetadata: RefundMetadata, refund: RefundPayload, item?: any) {
  let vendorContribution = 0;
  const packageCostPrice = refundMetadata?.package_cost_price_data?.cost_price;
  refund.accounting_metadata.forEach((accountingMetadata) => {
    const source = accountingMetadata.source;
    if (
      accountingMetadata.charge_component_key.includes('merchant_fee') ||
      accountingMetadata.charge_component_key.includes('surcharge')
    ) {
      // Future TODO: consider vendor surcharges
      return;
    }

    switch (source) {
      case 'Default': {
        vendorContribution +=
          // Future TODO: bedbanks are booked in same currency as sell, but need to consider currency here
          (accountingMetadata.accounting_amount / item.total) * packageCostPrice;
        break;
      }
      case 'Custom': {
        let ratio = 0;
        if (refund.additional_info.refund_vendor_agrees_to === 'number') {
          ratio =
            Number(refund.additional_info.number_of_nights) /
            (refund.additional_info.package_nights + refund.additional_info.extra_nights);
        }
        if (refund.additional_info.refund_vendor_agrees_to === 'percentage') {
          ratio = convertStrOrNumToNum(refund.additional_info.percentage) / 100;
        }
        const vendorHoldbackAmount = packageCostPrice - packageCostPrice * ratio;
        vendorContribution += packageCostPrice - vendorHoldbackAmount;
        break;
      }
      case 'TNFH': {
        // no addition to vendor contribution here
        break;
      }
    }
  });

  return vendorContribution;
}

export function formatModalHeader(step: RefundSteps, refundMetadata: RefundMetadata): string {
  let typeOfItem,
    typeOfStep = '';
  if (refundMetadata?.is_addon) {
    typeOfItem = 'Addon price';
  } else if (refundMetadata?.is_bedbank) {
    typeOfItem = 'Room price';
  } else if (refundMetadata?.is_tour_v2) {
    typeOfItem = 'TourV2 price';
  } else if (refundMetadata?.is_deposit) {
    typeOfItem = 'Refund amount';
  } else if (refundMetadata?.is_instalment) {
    typeOfItem = 'Refund amount';
  } else if (refundMetadata?.is_pending_deferred_payment) {
    typeOfItem = 'Refund amount';
  }

  switch (step) {
    case RefundSteps.Reason: {
      typeOfStep = 'Select Type Of Refund';
      break;
    }
    case RefundSteps.Detail: {
      typeOfStep = 'Issue Refund';
      break;
    }
    case RefundSteps.Confirm: {
      typeOfStep = 'Confirm Refund';
      break;
    }
  }

  if (!refundMetadata) {
    return `${typeOfStep}`;
  }
  return `${typeOfStep} - ${typeOfItem} - ${currencyFormatter(
    refundMetadata.currency_code,
    refundMetadata.total_sale_price,
    2,
  )} ${refundMetadata.currency_code}`;
}
