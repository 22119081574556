import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerStateCities from '~/queries/customerCommunication/requestGetHeroPlannerStateCities';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type StateCitiesParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitiesRecords']>['params']
>;

const fetchHeroPlannerCities =
  (
    brand: string,
    countryGroupId: string,
    countryId: string,
    stateId: string,
  ): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();

    const params = [brand, countryGroupId, countryId, stateId] as StateCitiesParams;
    const key = getRecordKeyFor(...params);
    const existing = appState.customerCommunication.countryGroupCountryStatesRecords[key];
    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FETCHING,
        params,
      });
      try {
        const stateCities = await requestGetHeroPlannerStateCities(...params);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FULFILLED,
          params,
          stateCities,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerCities;
