import React from 'react';

import { Helmet } from 'react-helmet';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Container } from '@mui/material';

import Asinc from '~/components/Common/Asinc';

import { healthCheck } from '~/services/PaymentsService';
import UsersService from '~/services/UsersService';

import PageHeader from '../Common/Elements/PageHeader';

const ORDERS_GUI_HOST = `${window.configs.API_HOST}/jobs/orders`;

export default function HealthPage() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Services Statuses</title>
      </Helmet>

      <PageHeader title="Service statuses" />

      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Payment service</AccordionSummary>
        <AccordionDetails>
          <Asinc
            promise={healthCheck()}
            then={(promise_results) => (
              <div>
                <p>Service has both redis and postgres connectivity</p>
                <p>Worker Backlog: {promise_results.result.queue_backlog}</p>
                <p>Worker Processing: {promise_results.result.queue_processing}</p>
              </div>
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Auth service</AccordionSummary>
        <AccordionDetails>
          <Asinc
            promise={UsersService.healthCheck()}
            then={(promise_results) => (
              <div>
                <p>Service has both redis and postgres connectivity</p>
                <p>Worker Backlog: {promise_results.result.queue_backlog}</p>
                <p>Worker Processing: {promise_results.result.queue_processing}</p>
              </div>
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Order service</AccordionSummary>
        <AccordionDetails>
          <iframe className="order-job-frame" src={ORDERS_GUI_HOST} />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
