import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import fetchHeroPlannerBrands from '~/actions/customerCommunication/fetchHeroPlannerBrands';

import { useAppSelector } from '../store';

function useHeroPlannerBrands() {
  const brandsReq = useAppSelector((state) => state.customerCommunication.brands);
  const appDispatch = useDispatch();
  const fetchBrands = useCallback(() => {
    appDispatch(fetchHeroPlannerBrands());
  }, [appDispatch]);
  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  return { brandsReq, fetchBrands };
}

export default useHeroPlannerBrands;
