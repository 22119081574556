import React from 'react';

import { Helmet } from 'react-helmet';

import { Alert, Box, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import InclusionForm from './components/InclusionForm';

function InclusionsCreate() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Create New Inclusion</title>
      </Helmet>

      <PageHeader title="Create New Inclusion" backButton="/cruises/inclusions" />

      <Box mt={1}>
        <InclusionForm />
      </Box>

      <Box mt={3}>
        <Alert severity="info">Save the inclusion before managing items and filters</Alert>
      </Box>
    </Container>
  );
}

export default InclusionsCreate;
