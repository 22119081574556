import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { downloadLatitudeFinanceRefundsReport } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter your dates to export the Latitude Finance Refunds report',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportLatitudeFinanceRefundsForm() {
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt } = event.formData;
    const fileName = `latitude_finance_refunds_report_${startAt}_${endAt}.csv`;

    downloadLatitudeFinanceRefundsReport({ startAt, endAt })
      .then(function (text) {
        fileDownload(text, fileName);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Download Latitude Refunds Report
      </Button>

      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
