import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Button, Stack } from '@mui/material';

import ReservationService from '~/services/ReservationService';

import { formatValidationMessages } from '~/utils/formatValidationMessages';
import { reportError } from '~/utils/reportError';

import ErrorListDisplay from '../ErrorListDisplay';

import { buttonMessages, buttonStates } from './states/submitButton';

export default class CapacityForm extends Component {
  constructor(props) {
    super(props);

    const capacity = props.capacity
      ? // deep clone
        JSON.parse(JSON.stringify(props.capacity))
      : { adults: 0, children: 0, infants: 0 };

    this.state = {
      capacity,
      saveState: buttonStates.default,
      errors: null,
    };
  }

  setValue(key, value) {
    let newBlock = this.state.capacity;
    newBlock[key] = value;

    this.setState({
      capacity: newBlock,
    });
  }

  submit = () => {
    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    let promise;

    if (this.state.capacity.id) {
      promise = ReservationService.updateRoomTypeCapacity(
        this.state.capacity,
        this.state.capacity.id,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      );
    } else {
      promise = ReservationService.createRoomTypeCapacity(
        this.state.capacity,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      );
    }

    return promise
      .then((response) => {
        this.setState({
          saveState: buttonStates.saved,
        });
        this.props.onRoomTypeCapacityUpdated(response.result);
      })
      .catch((e) => {
        const errors = e.name === 'ValidationError' ? e.errors : null;
        this.setState({
          saveState: buttonStates.failed,
          errors,
        });
        reportError(e);
      });
  };

  formRow(label, fieldName) {
    return (
      <div className="row capacity-form-row">
        <label className="col-sm-9">{label}</label>
        <div className="col-sm-3">
          <input
            type="number"
            step="1"
            min="0"
            value={this.state.capacity[fieldName]}
            onChange={(event) => this.setValue(fieldName, parseInt(event.target.value))}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="capacity-form form-horizontal">
        {this.formRow('Adults', 'adults')}
        {this.formRow('Children', 'children')}
        {this.formRow('Infants', 'infants')}

        <div className="modal-form-group">
          {this.state.errors && <ErrorListDisplay messages={formatValidationMessages(this.state.errors)} />}
        </div>

        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button variant="text" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={this.state.saveState === buttonStates.saving}
            onClick={this.submit}
          >
            {buttonMessages[this.state.saveState]}
          </Button>
        </Stack>
      </div>
    );
  }
}

CapacityForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onRoomTypeCapacityUpdated: PropTypes.func.isRequired,
  includedGuest: PropTypes.object,
};
