import React from 'react';

import { TableCell, TableRow } from '@mui/material';

export function SearchExperienceHead() {
  return (
    <TableRow>
      <TableCell> Offer </TableCell>
      <TableCell align="right">Vertical</TableCell>
      <TableCell align="right">Offer Type</TableCell>
      <TableCell align="right">Views</TableCell>
      <TableCell align="right">Sales Count</TableCell>
      <TableCell align="right">Sales Amount</TableCell>
      <TableCell align="right">Margin Amount</TableCell>
      <TableCell align="right">Daily Sales</TableCell>
      <TableCell align="right">Click Through Rate</TableCell>
      <TableCell align="right">MPV</TableCell>
      <TableCell align="right">Adjusted MPV</TableCell>
    </TableRow>
  );
}
