import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Box, Container, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import RoomTypesGroupsForm from '~/components/Common/Forms/RoomTypesGroupsForm';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

export default function RoomTypesGroupEditContainer() {
  const { id_vendor: vendorId, id_property: propertyId } = useParams<{ id_vendor: string; id_property: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [roomTypesGroups, setRoomTypesGroups] = useState(null);
  const [schema, setSchema] = useState(null);
  const [property, setProperty] = useState(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getProperty(propertyId),
      ReservationService.getRoomTypesGroupsSchema(propertyId),
      ReservationService.getRoomTypesGroups(propertyId),
    ])
      .then(([property, schema, roomTypesGroups]) => {
        setProperty(property.result);
        setSchema(schema.put.body.schema);
        setRoomTypesGroups(roomTypesGroups.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [propertyId]);

  return (
    <Container maxWidth="xl" className="room-types-groups-edit-form">
      {loadingState === 'loading' && <Spinner />}
      {loadingState === 'failed' && <Typography>Something went wrong. Can't load room types groups.</Typography>}
      {loadingState === 'success' && (
        <>
          <Helmet>
            <title>{property?.name || propertyId} | Edit Room Groups</title>
          </Helmet>

          <PageHeader
            title={`${property?.name || propertyId} | Edit Room Groups`}
            backButton={`/vendors/${vendorId}/properties/${propertyId}/room-types-groups`}
          />

          <Box id="overview" className="property-deal-section">
            <RoomTypesGroupsForm
              schema={schema}
              roomTypesGroups={roomTypesGroups}
              roomTypes={property?.room_types}
              propertyId={propertyId}
            />
          </Box>
        </>
      )}
    </Container>
  );
}
