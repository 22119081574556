import React, { useCallback, useState } from 'react';

import { CustomOfferFormValues } from '@/typings/formValues';
import { useSnackbar } from 'notistack';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  populateFields: (bookingNumber: string, isRebooking: boolean) => void;
  createdFromBookingNumber: string;
  setValue: UseFormSetValue<CustomOfferFormValues>;
  watch: UseFormWatch<CustomOfferFormValues>;
}

export default function RebookingModal({
  isModalOpen,
  closeModal,
  populateFields,
  createdFromBookingNumber,
  setValue,
}: Props) {
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const populateWithRebooking = useCallback(
    async (isRebooking: boolean) => {
      try {
        setLoading(true);
        await populateFields(createdFromBookingNumber, isRebooking);
        setValue('payment_metadata.is_rebooking', isRebooking);
        enqueueSnackbar('Successfully populated LPP', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar('Issues with population:', err);
      } finally {
        setLoading(false);
        closeModal();
      }
    },
    [createdFromBookingNumber],
  );

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogTitle>Are you rebooking a cancelled LPP?</DialogTitle>
      <DialogContent>
        <Typography>
          If you are rebooking an LPP, this will link the original LPP with this custom offer order.
        </Typography>
        <Typography>All cancellations/refunding must be done manually.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            populateWithRebooking(false);
          }}
          disabled={isLoading}
        >
          No, do not link these orders
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            populateWithRebooking(true);
          }}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size={15} inline /> : 'Yes, link these orders'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
