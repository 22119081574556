import React, { useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import { getReasonsByItemType } from '../Utils/RefundUtils';

interface Props {
  itemType: string;
  isRefundable: boolean;
  order: Order.Order;
  handleReasonSubmission: (key: string) => void;
}

export default function RefundReasons({ itemType, order, isRefundable, handleReasonSubmission }: Props) {
  const { refundTypes, reasons } = getReasonsByItemType(itemType, order, isRefundable);
  const [selectedReason, setSelectedReason] = useState<string>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <DialogContent dividers>
        <FormControl>
          <RadioGroup value={selectedReason} onChange={handleChange}>
            {refundTypes
              // filter COVID19 here, to be removed in future
              .filter((key) => !reasons[key]?.hidden && key !== 'COVID_19')
              .map((key) => {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={
                      <Box>
                        <Typography fontWeight="bold" component="span" display="block">
                          {reasons[key]?.label ?? reasons[key].key}
                        </Typography>
                        <Typography component="span" display="block">
                          {reasons[key].description}
                        </Typography>
                      </Box>
                    }
                    disableTypography
                  />
                );
              })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleReasonSubmission(selectedReason);
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
