import React from 'react';

import { connect } from 'react-redux';

import * as libRegions from '@luxuryescapes/lib-regions';

import {
  addGiftCardToCart,
  changeCurrencyCode,
  reduceGiftCardQuantity,
  setOrderId,
  updateGiftCardPrice,
} from '../../../../actions/cart';
import { ITEM_TYPE_GIFT_CARD } from '../../../../consts/order';
import NoDataWarning from '../NoDataWarning';

import PackageSelection from './Component';

class PackageSelectionContainer extends React.Component {
  constructor(props) {
    super(props);

    const region = libRegions.getRegionByCode(this.props.cart.regionCode, this.props.tenant.brand);

    this.state = {
      creatingOrder: false,
      regions: libRegions.getRegions(this.props.tenant.brand).filter((x) => x.paymentMethods.includes('giftcard')),
      fixedPriceOptions: region.giftCardOptions || [],
    };
  }

  isStateValid = () => {
    const { cart } = this.props;

    return !cart.orderId;
  };

  handleChangeCurrencyCode = (regionCode) => {
    const { doChangeCurrencyCode } = this.props;
    const region = libRegions.getRegionByCode(regionCode, this.props.tenant.brand);

    doChangeCurrencyCode(region.currencyCode, region.code);
  };

  handleAddToCart = (price) => {
    const {
      doAddGiftCardToCart,
      cart: { currencyCode },
    } = this.props;

    doAddGiftCardToCart(price, currencyCode);
  };

  handleRemoveFromCart = (price) => {
    this.props.doRemoveFromCart(price);
  };

  getAllPrices() {
    return this.state.fixedPriceOptions.concat(this.getCustomPrices());
  }

  getCustomPrices() {
    return this.props.cart.items.reduce(
      (prices, item) =>
        prices.concat(
          this.state.fixedPriceOptions.includes(item.price) || prices.includes(item.price) ? [] : [item.price],
        ),
      [],
    );
  }

  addNewCustomPrice = () => {
    const currentPrices = this.getAllPrices();
    let newPrice = 2000;
    while (currentPrices.includes(newPrice)) {
      newPrice++;
    }
    this.handleAddToCart(newPrice);
  };

  getPackageQuantity = (price) => {
    return this.props.cart.items.reduce((acc, item) => acc + (item.price == price ? 1 : 0), 0);
  };

  onUpdatePrice = (oldPrice, newPrice) => {
    const { doUpdateGiftCardPrice } = this.props;
    doUpdateGiftCardPrice(oldPrice, newPrice);
  };

  render() {
    const { cart, backStep, nextStepLabel, proceedStep, disableChangeCurrency, tenant } = this.props;

    const { currencyCode, customer } = cart;
    const { creatingOrder } = this.state;

    const dataPresent = this.isStateValid();
    const brand = tenant.brand || 'luxuryescapes';
    const brandTitle = tenant.title || 'Luxury Escapes';

    if (!dataPresent) {
      return <NoDataWarning userId={customer.id_member} orderType={ITEM_TYPE_GIFT_CARD} />;
    }

    return (
      <PackageSelection
        fixedPriceOptions={this.state.fixedPriceOptions}
        customPriceOptions={this.getCustomPrices()}
        cart={cart}
        onAddToCart={this.handleAddToCart}
        onRemoveFromCart={this.handleRemoveFromCart}
        onUpdatePrice={this.onUpdatePrice}
        currencyCode={currencyCode}
        onChangeCurrencyCode={this.handleChangeCurrencyCode}
        backStep={backStep}
        nextStepLabel={nextStepLabel}
        getPackageQuantity={this.getPackageQuantity}
        onProceedStep={proceedStep}
        disableChangeCurrency={disableChangeCurrency}
        regions={this.state.regions}
        creatingOrder={creatingOrder}
        brand={brand}
        brandTitle={brandTitle}
        addNewCustomPrice={this.addNewCustomPrice}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    tenant: state.tenant,
  };
}

const mapDispatchToProps = {
  doAddGiftCardToCart: addGiftCardToCart,
  doRemoveFromCart: reduceGiftCardQuantity,
  doUpdateGiftCardPrice: updateGiftCardPrice,
  doChangeCurrencyCode: changeCurrencyCode,
  doSetOrderId: setOrderId,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageSelectionContainer);
