import { Reducer } from 'redux';

import { SET_CURRENT_USER_ACTION } from '~/actions/currentUser';

import type { UserType } from '~/types/responses';

type CurrentUserState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: UserType | null;
};

const initialState: CurrentUserState = {
  isLoading: false,
  isAuthenticated: false,
  user: null,
};

const currentUserReducer: Reducer<CurrentUserState> = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default currentUserReducer;
