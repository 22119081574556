import { ROOM_RATE_LAST_MINUTE_TYPE, ROOM_RATE_LPC_TYPE } from '~/consts/ratePlans';
import ReservationService from '../../../../../services/ReservationService';
import {
  COPY_OPERATION_INCLUSIONS,
  COPY_OPTIONS_MODE_ALL_LM_FOR_PROPERTY,
  COPY_OPTIONS_MODE_ALL_LM_FOR_ROOM,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM,
  COPY_OPTIONS_MODE_ALL_RATES,
  COPY_OPTIONS_MODE_ALL_ROOM_TYPES,
} from '../../constants';

const NO_RATES_MESSAGE = 'no room rates found for this option!';
const NO_INCLUSIONS_MESSAGE = 'no inclusions found to copy!';

type RoomRateInclusionsCopyMode =
  | 'allRoomTypes'
  | 'allRates'
  | 'all_LPC_rates_for_room'
  | 'all_LPC_rates_for_property'
  | 'all_LM_rates_for_room'
  | 'all_LM_rates_for_property';

interface CopyInclusionsParams {
  // Copy mode
  mode: RoomRateInclusionsCopyMode;
  //  Configs
  overrideExisting: boolean;
  copyToStandardPlans: boolean;
  // Data
  inclusions: Array<App.RoomRateInclusion>;
  propertyId: string;
  propertyRoomTypes: Array<App.RoomType>;
  roomRate: App.RoomRate;
  roomType: App.RoomType;
}

async function copyInclusions(params: CopyInclusionsParams) {
  switch (params.mode) {
    // LPC mode
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM: {
      await copyRatesForRoomAndProductType(params, ROOM_RATE_LPC_TYPE);
      break;
    }
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY: {
      await copyRatesForPropertyAndProductType(params, ROOM_RATE_LPC_TYPE);
      break;
    }

    // LM mode
    case COPY_OPTIONS_MODE_ALL_LM_FOR_ROOM: {
      await copyRatesForRoomAndProductType(params, ROOM_RATE_LAST_MINUTE_TYPE);
      break;
    }
    case COPY_OPTIONS_MODE_ALL_LM_FOR_PROPERTY: {
      await copyRatesForPropertyAndProductType(params, ROOM_RATE_LAST_MINUTE_TYPE);
      break;
    }

    // Rates / Room Types
    case COPY_OPTIONS_MODE_ALL_ROOM_TYPES: {
      await copyToRoomTypes(params);
      break;
    }
    case COPY_OPTIONS_MODE_ALL_RATES:
    default: {
      await copyToRates(params);
      break;
    }
  }
}

// across all rates of a specific product type under this room
const copyRatesForRoomAndProductType = async (params: CopyInclusionsParams, productType: string) => {
  const { inclusions, propertyId, roomType, roomRate } = params;

  let rates = roomType.room_rates.filter(
    (roomTypeRoomRate) =>
      roomTypeRoomRate.id !== roomRate.id && roomTypeRoomRate.rate_plan.product_type === productType,
  );

  if (!params.copyToStandardPlans) {
    rates = rates.filter(removeStandardPlans);
  }

  try {
    if (params.overrideExisting) {
      await deleteInclusions(rates, inclusions, propertyId, roomType.id);
    }
    await createInclusionsInBulk(rates, inclusions, propertyId, roomType.id, roomRate.inclusions_hide_value);
  } catch (e) {
    throw new Error(
      `Error while copying room rate inclusions across all ${productType} rates under this room: ${e.message}`,
    );
  }
};

// across all rates of a specific product type under this property
const copyRatesForPropertyAndProductType = async (params: CopyInclusionsParams, productType: string) => {
  const { copyToStandardPlans, propertyRoomTypes, roomRate } = params;
  const rates = getRatesFromPropertyRoomTypes(propertyRoomTypes, roomRate.id, productType);

  await copyPropertyRates(params, copyToStandardPlans ? rates : rates.filter(removeStandardPlans));
};

const getRatesFromPropertyRoomTypes = (propertyRoomTypes, roomRateId, productType) => {
  return propertyRoomTypes
    .map((_roomType) => {
      return _roomType.room_rates
        .filter((_roomRate) => _roomRate.id !== roomRateId && _roomRate.rate_plan.product_type === productType)
        .map((rate) => {
          return {
            id: rate.id,
            roomTypeId: _roomType.id,
            rate_plan: {
              default_plan: rate.rate_plan.default_plan,
            },
          };
        });
    })
    .flat();
};

// allRoomTypes - across all room types for this rate
const copyToRoomTypes = async (params: CopyInclusionsParams) => {
  const { propertyRoomTypes, roomType, roomRate, inclusions, propertyId } = params;
  const roomTypes = propertyRoomTypes.filter((propertyRoomType) => propertyRoomType.id !== roomType.id);

  try {
    await Promise.all(
      roomTypes.map(async (propertyRoomType) => {
        const roomTypeRoomRate = params.copyToStandardPlans
          ? propertyRoomType.room_rates.find((rate) => rate.rate_plan.id === roomRate.rate_plan.id)
          : propertyRoomType.room_rates.find(
              (rate) => rate.rate_plan.id === roomRate.rate_plan.id && !rate.rate_plan.default_plan,
            );

        if (roomTypeRoomRate) {
          if (params.overrideExisting) {
            await deleteInclusions([roomTypeRoomRate], inclusions, propertyId, propertyRoomType.id);
          }
          await ReservationService.createOperationInBulk(
            inclusions,
            propertyId,
            propertyRoomType.id,
            roomTypeRoomRate.id,
            COPY_OPERATION_INCLUSIONS,
          );
          await ReservationService.patchRoomRateInclusionHideValue(
            propertyId,
            propertyRoomType.id,
            roomTypeRoomRate.id,
            roomRate.inclusions_hide_value,
          );
        }
      }),
    );
  } catch (e) {
    throw new Error(`Error while copying room rate inclusions over room types: ${e.message}`);
  }
};

// all_LPC_rates_for_room - allRates - across all rates under this room
const copyToRates = async (params: CopyInclusionsParams) => {
  const { propertyId, inclusions, roomType, roomRate } = params;

  const roomRates = !params.copyToStandardPlans
    ? roomType.room_rates.filter((roomTypeRates) => roomTypeRates.id !== roomRate.id).filter(removeStandardPlans)
    : roomType.room_rates.filter((roomTypeRates) => roomTypeRates.id !== roomRate.id);

  try {
    if (params.overrideExisting) {
      await deleteInclusions(roomRates, inclusions, propertyId, roomType.id);
    }
    await createInclusionsInBulk(roomRates, inclusions, propertyId, roomType.id, roomRate.inclusions_hide_value);
  } catch (e) {
    throw new Error(`Error while copying room rate inclusions under this room: ${e.message}`);
  }
};

const removeStandardPlans = (rate) => rate.rate_plan.default_plan === false;

const validate = (rates: Array<App.RoomRate>, inclusions: Array<App.RoomRateInclusion>) => {
  if (!rates.length) {
    throw new Error(NO_RATES_MESSAGE);
  }
  if (!inclusions.length) {
    throw new Error(NO_INCLUSIONS_MESSAGE);
  }
};

async function copyPropertyRates(params: CopyInclusionsParams, propertyRates) {
  const { propertyId, roomRate, inclusions } = params;
  try {
    await Promise.all(
      propertyRates.map(async (_roomRate) => {
        if (params.overrideExisting) {
          await deleteInclusions([_roomRate], inclusions, propertyId, _roomRate.roomTypeId);
        }
        await ReservationService.createOperationInBulk(
          inclusions,
          propertyId,
          _roomRate.roomTypeId,
          _roomRate.id,
          COPY_OPERATION_INCLUSIONS,
        );
        await ReservationService.patchRoomRateInclusionHideValue(
          propertyId,
          _roomRate.roomTypeId,
          _roomRate.id,
          roomRate.inclusions_hide_value,
        );
      }),
    );
  } catch (e) {
    throw new Error(
      `Error while copying room rate inclusions across all Lux Premium Collection rates under this property: ${e.message}`,
    );
  }
}

async function deleteInclusions(
  roomRates: Array<App.RoomRate>,
  inclusions: Array<App.RoomRateInclusion>,
  propertyId: string,
  roomTypeId: string,
) {
  validate(roomRates, inclusions);
  await Promise.all(
    roomRates.map(async (rate) => {
      await ReservationService.deleteRoomRateInclusionsBulk(propertyId, roomTypeId, rate.id);
    }),
  );
}

async function createInclusionsInBulk(roomRates, inclusions, propertyId, roomTypeId, hideValue) {
  validate(roomRates, inclusions);
  await Promise.all(
    roomRates.map(async (roomRate) => {
      await ReservationService.createOperationInBulk(
        inclusions,
        propertyId,
        roomTypeId,
        roomRate.id,
        COPY_OPERATION_INCLUSIONS,
      );
      await ReservationService.patchRoomRateInclusionHideValue(propertyId, roomTypeId, roomRate.id, hideValue);
    }),
  );
}

export default copyInclusions;
