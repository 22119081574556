import React, { ReactElement, useCallback, useState } from 'react';

import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import PublicOfferService from '~/services/PublicOfferService';

interface Props {
  offerId: string;
  propertyId: string;
}

const DataResyncModal = (props: Props): ReactElement => {
  const { offerId, propertyId } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<string | null>(null);

  const resync = useCallback(async () => {
    setInProgress(true);
    setError(null);
    setShowConfirmationMessage(null);

    try {
      await PublicOfferService.resyncData(offerId, propertyId);
      setShowConfirmationMessage('Successfully resynced data.');
      setTimeout(() => {
        setShowModal(false);
        setShowConfirmationMessage(null);
      }, 2500);
    } catch (e) {
      setError(e.errors?.at(0)?.message ?? `Something went wrong [status code ${e.status}]. Please try again.`);
    } finally {
      setInProgress(false);
    }
  }, [offerId, propertyId]);

  return (
    <>
      <Button onClick={() => setShowModal(true)} style={{ marginLeft: '10px' }} variant="contained">
        Resync Offer Data
      </Button>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <>
          <DialogTitle>Resync Offer to Public Offer?</DialogTitle>
          <DialogContent>
            {!inProgress && (
              <>
                By clicking confirm, you will re-sync the data for this rate/offer & property.
                <Stack my={1}>
                  {error && (
                    <Box>
                      <ErrorDisplay message={error} />
                    </Box>
                  )}

                  {showConfirmationMessage && <Alert variant="standard">{showConfirmationMessage}</Alert>}
                </Stack>
              </>
            )}
            {inProgress && <Spinner />}
          </DialogContent>
          {!showConfirmationMessage && (
            <DialogActions>
              <Button onClick={() => setShowModal(false)} variant="text">
                Cancel
              </Button>
              <Button onClick={resync} variant="contained">
                Confirm
              </Button>
            </DialogActions>
          )}
        </>
      </Dialog>
    </>
  );
};

export default DataResyncModal;
