import React, { useState } from 'react';

import { Box, Button, TextField } from '@mui/material';

const AmenityForm = ({ onSubmit, onAfterSubmit, onCancel }) => {
  const [amenityName, setAmenityName] = useState('');

  const handleInputChange = (e) => {
    setAmenityName(e.target.value);
  };

  const handleSave = () => {
    onSubmit(amenityName);

    if (onAfterSubmit) {
      onAfterSubmit();
    }
  };

  return (
    <Box paddingY={2} gap={2} display="flex" flexDirection="column">
      <TextField
        label="Amenity Name"
        variant="outlined"
        id="amenity-name"
        type="text"
        placeholder="e.g. Sauna"
        value={amenityName}
        className="form-control"
        onChange={handleInputChange}
      />

      <div>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" className="pull-right" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Box>
  );
};

export default AmenityForm;
