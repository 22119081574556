import React, { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import requestGetHeroPlannerOfferDetails from '~/queries/customerCommunication/requestGetHeroPlannerOfferDetails';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, AlertTitle, Stack, TextField } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { isRequestPending, isRequestRejected } from '~/utils/requestUtils';

const OFFER_ID_INPUT_NAME = 'schedule-new-offer-id';

interface Props {
  countryGroupId: string;
  countryId: string;
  currentOfferIds: Array<string>;
  onOfferDetailsReady: (offerDetails: CustomerCommunication.HeroPlannerOfferDetails) => void;
}

function ScheduleNewHeroOfferForm(props: Props) {
  const { countryGroupId, countryId, currentOfferIds, onOfferDetailsReady } = props;
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const formRef = useRef<HTMLFormElement>(null);
  const offerIdInputRef = useRef<HTMLInputElement>(null);

  const [offerDetailsRequest, setOfferDetailsRequest] = useState<
    Utils.RequestState<CustomerCommunication.HeroPlannerOfferDetails, string>
  >({
    status: RequestStatus.INITIAL,
  });
  const requestOfferDetails = useCallback(
    async (offerId: string) => {
      const params = [brand, countryGroupId, countryId, offerId] as const;
      setOfferDetailsRequest({
        status: RequestStatus.PENDING,
        params,
      });

      try {
        const result = await requestGetHeroPlannerOfferDetails(...params);
        setOfferDetailsRequest({
          status: RequestStatus.FULFILLED,
          params,
          result,
        });
      } catch (error) {
        setOfferDetailsRequest({
          status: RequestStatus.REJECTED,
          params,
          error,
        });
      }
    },
    [brand, countryId, countryGroupId],
  );

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const offerId = String(formData.get(OFFER_ID_INPUT_NAME));

      offerIdInputRef.current.setCustomValidity('');
      if (currentOfferIds.includes(offerId)) {
        offerIdInputRef.current.setCustomValidity('Offer already exists in the list!');
        return;
      } else {
        offerIdInputRef.current.setCustomValidity('');
      }

      requestOfferDetails(offerId);
    },
    [currentOfferIds, requestOfferDetails],
  );

  useEffect(() => {
    if (offerDetailsRequest.result) {
      onOfferDetailsReady(offerDetailsRequest.result);
      formRef.current.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerDetailsRequest.result]);

  return (
    <Stack direction="column" gap={1}>
      <form ref={formRef} onSubmit={handleSubmission}>
        <Stack direction="row" gap={1} alignItems="start">
          <TextField
            inputRef={offerIdInputRef}
            name={OFFER_ID_INPUT_NAME}
            required
            placeholder="Enter offer ID to add"
            disabled={isRequestPending(offerDetailsRequest)}
          />
          <LoadingButton type="submit" loading={isRequestPending(offerDetailsRequest)}>
            Add Offer
          </LoadingButton>
        </Stack>
      </form>
      {isRequestRejected(offerDetailsRequest) && (
        <Alert severity="error">
          <AlertTitle>Offer details could not be fetched!</AlertTitle>
          <pre>{offerDetailsRequest.error}</pre>
        </Alert>
      )}
    </Stack>
  );
}

export default ScheduleNewHeroOfferForm;
