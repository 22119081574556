export const types = [
  {
    code: 'availability',
    name: 'Book By Dates',
  },

  {
    code: 'online_purchase',
    name: 'Available Offline',
  },
];
