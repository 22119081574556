import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Alert, Button } from '@mui/material';

import { downloadMusmentRefundReport } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter your dates to export the Musement refund report',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    fileFormat: {
      title: 'File Format',
      type: 'string',
      enum: ['csv', 'email'],
    },
  },
};

const formData = {
  startAt: null,
  endAt: null,
  fileFormat: 'email',
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  fileFormat: {
    'ui:widget': 'radio',
  },
};

const MusementRefundForm = () => {
  const [successMessage, setSucessMessage] = useState(false);
  const [error, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setErrorMessage(null);
      const { startAt, endAt, fileFormat } = event.formData;

      const data = await downloadMusmentRefundReport({
        startAt,
        endAt,
        fileFormat,
      });

      if (fileFormat != 'email') {
        const fileName = `Musement_refund_report_${startAt}_${endAt}.${fileFormat}`;

        fileDownload(data, fileName);
      } else {
        setSucessMessage(true);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Download/Email Refund Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
};

export default MusementRefundForm;
