import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerCitySegments from '~/actions/customerCommunication/fetchHeroPlannerCitySegments';

import { useAppSelector } from '../store';

function useHeroPlannerCitySegments(countryGroupId: string, countryId: string, stateId: string, cityId: string) {
  const brand = useAppSelector((state) => state.tenant.brand);
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerCitySegments> = [brand, countryGroupId, countryId, stateId, cityId];
    return [getRecordKeyFor(...params), params];
  }, [brand, countryGroupId, countryId, stateId, cityId]);
  const segmentsReq = useAppSelector(
    (state) => state.customerCommunication.countryGroupCountryStateCitySegmentsRecords[key],
  );
  const appDispatch = useDispatch();
  const fetchSegments = useCallback(() => {
    appDispatch(fetchHeroPlannerCitySegments(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchSegments();
  }, [fetchSegments]);

  return { segmentsReq, fetchSegments };
}

export default useHeroPlannerCitySegments;
