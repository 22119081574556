import OffersService from '~/services/OffersService';
import PackagesService from '~/services/PackagesService';
import VimeoService from '~/services/VimeoService';

const copyPackagesToOffer = async (packages) => {
  for (const singlePackage of packages) {
    await PackagesService.updatePackage(
      singlePackage,
      singlePackage.offer_id_salesforce_external,
      singlePackage.id_salesforce_external ?? singlePackage.le_package_id,
    );
  }
};

const deleteOfferImages = async (idOffer) => {
  const getImgResponse = await OffersService.getImages(idOffer);

  for (const image of getImgResponse.result) {
    await OffersService.deleteImage(idOffer, image.id_image);
  }
};

interface ErrorMessage {
  message: string;
  [key: string]: any; // allow additional keys
}

function extractErrorMessages(errors: Record<string, any>): ErrorMessage[] {
  const messages: ErrorMessage[] = [];

  // Check if current level has an error message
  if (errors?.message) {
    messages.push(errors.message);
  }

  // Check if current level has nested errors
  for (const key in errors) {
    const nestedError = errors[key];

    if (Array.isArray(nestedError)) {
      for (const item of nestedError) {
        messages.push(...extractErrorMessages(item));
      }
    } else if (typeof nestedError === 'object') {
      messages.push(...extractErrorMessages(nestedError));
    }
  }

  return messages;
}

const getVimeoData = async (data, field) => {
  const vimeoData = await VimeoService.getVimeoDetails(data.videos[field].vimeo_id);

  if (vimeoData?.pictures?.sizes)
    data.videos[field].mobile_vimeo_thumb_url = vimeoData.pictures.sizes.find(
      (size) => size.width == 640,
    ).link_with_play_button;

  if (vimeoData?.files)
    data.videos[field].mobile_vimeo_video_url = vimeoData.files.find((file) => file.quality == 'hls').link;

  return data;
};

const labelToOfferFieldMapping = (label) => {
  const offerFields = {
    Location: 'getting_there',
    location: 'getting_there',
    'Fine Print': 'fine_print',
    fine_print: 'fine_print',
  };

  return offerFields[label];
};

// it works on the basis that all port names end with port code in brackets
const getFlightPortLabel = (flightPortCode, portNames) => {
  return portNames.find((port) => {
    // get last part of the name eg. (BER)
    const portCodeWithBrackets = port.split(' ')[port.split(' ').length - 1];

    return `(${flightPortCode})` === portCodeWithBrackets;
  });
};

export default {
  copyPackagesToOffer,
  deleteOfferImages,
  extractErrorMessages,
  getFlightPortLabel,
  getVimeoData,
  labelToOfferFieldMapping,
};
