import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

export function useSessionStorage<T>(
  key: string,
  initialValue?: T,
  onError?: (errorMessage: string) => void,
): [T, Dispatch<SetStateAction<T>>] {
  const deserializer = useCallback<(value: string) => T>(
    (value) => {
      if (value === 'undefined') {
        return undefined;
      }

      let parsed: unknown;
      try {
        parsed = JSON.parse(value);
      } catch (error) {
        onError('Error parsing JSON from sessionStorage');
        return initialValue;
      }

      return parsed as T;
    },
    [initialValue],
  );

  const readValue = useCallback((): T => {
    try {
      const raw = window.sessionStorage.getItem(key);
      return raw ? deserializer(raw) : initialValue;
    } catch (error) {
      onError(`Error reading sessionStorage key “${key}”`);
      return initialValue;
    }
  }, [initialValue, key, deserializer]);

  const [storedValue, setStoredValue] = useState<T>(() => {
    return initialValue;
  });

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      try {
        window.sessionStorage.setItem(key, JSON.stringify(value));

        setStoredValue(value);

        window.dispatchEvent(new StorageEvent('session-storage', { key }));
      } catch (error) {
        onError(`Error setting sessionStorage key “${key}”`);
      }
    },
    [key],
  );

  useEffect(() => {
    setStoredValue(readValue());
  }, [readValue]);

  // Include the error state in the return value
  return [storedValue, setValue];
}
