import { Feedback, Request } from './types';

export const INITIAL_FEEDBACK_STATE: Feedback = {
  status: null,
  message: null,
  loading: false,
};

export const INITIAL_REQUEST_STATE: Request<[]> = {
  result: [],
  loading: false,
};

export const GENERIC_INITIAL_REQUEST_STATE = {
  result: null,
  loading: false,
};

export const RATE_CODE_BASE_PATH = `/cruises/rate-code-management`;
