import React from 'react';

import { Step, StepLabel, Stepper } from '@mui/material';

type Props = { currentStepIdx: number; steps: string[]; stepNames: string[] };

export default function StepIndicator({ currentStepIdx, steps, stepNames }: Props) {
  return (
    <Stepper activeStep={currentStepIdx}>
      {steps.map((step, index) => (
        <Step key={step} completed={currentStepIdx > index}>
          <StepLabel>{stepNames[index]}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
