import React, { useEffect, useState } from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { LoginForm } from '~/stormpath';

import { Alert, Box, Button, Container, TextField, Typography } from '@mui/material';

import ExpiredTokenMessage from './Auth/Messages/ExpiredTokenMessage';
import SocialGoogleButton from './Auth/SocialLogin/SocialGoogleButton';

const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [showExpiredTokenMessage, setShowExpiredTokenMessage] = useState(false);
  const location = useLocation();

  const onSubmitError = ({ error }, callback) => {
    setErrorMessage(error.message);
    callback();
  };

  useEffect(() => {
    setShowExpiredTokenMessage(localStorage.getItem('showExpiredTokenMessage') === 'true');
    return () => {
      localStorage.removeItem('showExpiredTokenMessage');
    };
  }, []);

  const recaptchaKey = window.configs.RECAPTCHA_KEY;

  return (
    <Container>
      {showExpiredTokenMessage && <ExpiredTokenMessage />}
      <Helmet>
        <title>Login</title>
      </Helmet>

      <Box className="row flex-centered">
        <Box>
          <h1 className="page-header">Login</h1>
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} useEnterprise={true}>
            {window.configs.GOOGLE_LOGIN_ENABLED === 'true' && window.google && (
              <>
                <SocialGoogleButton location={location} />
                <Typography component="p" sx={{ my: 2 }}>
                  {' '}
                  - or -
                </Typography>
              </>
            )}

            <LoginForm onSubmitError={onSubmitError} redirectTo={`${location.pathname}${location.search}`}>
              <Box className="sp-login-form">
                <TextField
                  id="sp-login-0"
                  name="login"
                  type="text"
                  placeholder="adam@luxuryescapes.com"
                  label="Username or email"
                  fullWidth
                  required
                />

                <TextField
                  id="sp-password-1"
                  name="password"
                  type="password"
                  placeholder="SuperSecret"
                  label="Password"
                  fullWidth
                  required
                />

                <Box>
                  <Alert severity="warning" data-spif="form.error" sx={{ my: 2 }}>
                    <Typography component="span">{errorMessage || 'Username or password was incorrect'}</Typography>
                  </Alert>

                  <Button variant="contained" size="large" type="submit" fullWidth>
                    Login with credentials
                  </Button>
                </Box>
              </Box>
            </LoginForm>
          </GoogleReCaptchaProvider>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
