import { GridColDef } from '@mui/x-data-grid';

export interface TrendingPlace {
  id: string;
  position: number;
  manualPosition: number | string;
  zscore: number | string;
  placeName: string;
  placeId: string;
  imageId: string;
  brand: string;
  region: string;
}

export const defaultFields: Partial<GridColDef> = {
  sortable: false,
  disableColumnMenu: true,
  flex: 1,
  display: 'flex',
  type: 'singleSelect',
};

export const defaultColumns: GridColDef<TrendingPlace>[] = [
  {
    ...defaultFields,
    field: 'position',
    headerName: 'Position',
  },
  {
    ...defaultFields,
    field: 'manualPosition',
    headerName: 'Manual Position',
  },
  {
    ...defaultFields,
    field: 'zscore',
    headerName: 'Impression Score',
  },
  {
    ...defaultFields,
    field: 'placeName',
    headerName: 'Place Name',
  },
  {
    ...defaultFields,
    field: 'placeId',
    headerName: 'Place Id',
  },
];
