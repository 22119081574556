import React from 'react';

import PropTypes from 'prop-types';

class StickyElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stuck: false,
      containerStyle: this.generateContainerStyle(props, false),
      elementStyle: this.generateElementStyle(props, false),
    };
  }

  handleScroll = () => {
    const containerTop = this.container.offsetTop;
    const shouldBeStuck = window.pageYOffset > containerTop;

    if (this.state.stuck !== shouldBeStuck) {
      this.setState({
        stuck: shouldBeStuck,
        containerStyle: this.generateContainerStyle(this.props, shouldBeStuck),
        elementStyle: this.generateElementStyle(this.props, shouldBeStuck),
      });
    }
  };

  generateContainerStyle(props, stuck) {
    if (stuck) {
      return {
        height: this.element.offsetHeight,
        width: this.element.offsetWidth,
      };
    }
    return {};
  }

  generateElementStyle(props, stuck) {
    const baseStyle = {
      justifyContent: props.right ? 'flex-end' : 'flex-start',
    };

    if (stuck) {
      return {
        ...baseStyle,
        position: 'fixed',
        top: 0,
        left: this.element.offsetLeft,
        right: document.documentElement.clientWidth - (this.element.offsetLeft + this.element.offsetWidth) - 1,
      };
    }
    return baseStyle;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { children } = this.props;
    const { elementStyle, containerStyle } = this.state;

    return (
      <div
        className="sticky-element-container"
        style={containerStyle}
        ref={(a) => {
          this.container = a;
        }}
      >
        <div
          className="sticky-element"
          style={elementStyle}
          ref={(a) => {
            this.element = a;
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

StickyElement.propTypes = {
  right: PropTypes.bool,
};

export default StickyElement;
