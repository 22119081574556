import React from 'react';

import PrimaryGuestName from './PrimaryGuestName';
import SpecialRequests from './SpecialRequests';
import StateOfResidenceSelect from './StateOfResidenceSelect';
import TravelDates from './TravelDates';

const BookingFormTour = ({
  item,
  onToggleBookingDates,
  onUpdateReservation,
  onUpdateStateOfResidence,
  currencyCode,
  stateOfResidence,
}) => {
  const { reservation } = item;

  return (
    <div className="booking-form">
      <div className="booking-form-row">
        <div className="booking-form-item">
          <label
            onClick={() => {
              onToggleBookingDates(item);
            }}
          >
            Travel Dates
          </label>
          <TravelDates
            startDate={reservation.startDate}
            endDate={reservation.endDate}
            onStartBookingDates={() => {
              onToggleBookingDates(item);
            }}
          />
        </div>
        <PrimaryGuestName
          firstname={reservation.firstname}
          lastname={reservation.lastname}
          setPrimaryGuest={(data) => {
            onUpdateReservation(item, data);
          }}
        />
      </div>
      {currencyCode === 'USD' && (
        <div className="booking-form-row">
          <div className="booking-form-item">
            <label>State of Residence</label>
            <StateOfResidenceSelect
              selectedValue={stateOfResidence}
              onUpdateStateOfResidence={(data) => {
                onUpdateStateOfResidence(data);
              }}
            />
          </div>
        </div>
      )}
      <div className="booking-form-row">
        <SpecialRequests
          value={reservation.guestSpecialRequests || ''}
          onUpdateReservation={onUpdateReservation}
          item={item}
        />
      </div>
    </div>
  );
};

export default BookingFormTour;
