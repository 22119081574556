import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { DataGridPro, GridColDef, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { arrayToObject } from '~/utils/arrayUtils';

import { getFDATableActionButtons, getFDATableIDButton } from './FDATableUtils';

interface Props {
  columns: {
    field: string;
    headerName: string;
  }[];
  loading?: boolean;
  data: any[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onView: (id: string) => void;
  page: number;
  pageSize: number;
  pageSizes?: number[];
  count: number;
  onPageChange: (data: { page: number; pageSize: number; sortField?: string; sortDirection?: string }) => void;
  sortableFields: string[];
  onSortChange: (sortField: string, sortDirection: string) => void;
  sortModel?: { field: string; sort: string };
}

function FDATableServer(props: Props) {
  const {
    columns,
    data,
    loading,
    onDelete,
    onEdit,
    page,
    pageSize,
    pageSizes = [25, 50, 100],
    onPageChange,
    count,
    onView,
    sortableFields,
    onSortChange,
    sortModel,
  } = props;
  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const model = sortModel[0];
      const sortField = model?.field || '';
      const sortDirection = model?.sort || '';
      onSortChange(sortField, sortDirection);
    },
    [onSortChange],
  );

  const sortable = useMemo(() => {
    return arrayToObject(sortableFields, (val: string) => val);
  }, [sortableFields]);

  const dataColumns: GridColDef[] = useMemo(() => {
    return [
      ...columns.map((c) => ({
        ...c,
        flex: 1,
        minWidth: 120,
        renderCell: c.field === 'Id' ? ({ row }) => getFDATableIDButton(row, onView) : undefined,
        sortable: Boolean(sortable[c.field]),
      })),
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => getFDATableActionButtons(row, onDelete, onEdit),
        width: 130,
        minWidth: 130,
        sortable: false,
      },
    ];
  }, [columns, onDelete, onEdit, onView, sortable]);

  return (
    <Box height="800px">
      <DataGridPro
        columns={dataColumns}
        loading={loading}
        pagination
        paginationMode="server"
        pageSizeOptions={pageSizes}
        paginationModel={{ page, pageSize }}
        getRowId={(row) => row.Id}
        onPaginationModelChange={({ page, pageSize }) =>
          onPageChange({ page, pageSize, sortDirection: sortModel?.sort, sortField: sortModel?.field })
        }
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel ? [{ field: sortModel.field, sort: sortModel.sort as GridSortDirection }] : []}
        slots={{
          pagination: GridPagination,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        density="compact"
        disableRowSelectionOnClick
        disableColumnMenu
        rowCount={count}
        rows={data}
      />
    </Box>
  );
}

export default React.memo(FDATableServer);
