import React from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

interface Props {
  defaultVccChecked: boolean;
}

export default function UpdateVCCBalanceForm({ defaultVccChecked }: Props) {
  return (
    <FormControl required>
      <FormLabel
        sx={{
          fontWeight: 'bold',
        }}
      >
        Should the VCC balance be updated?
      </FormLabel>
      <RadioGroup name="updateVCCBalance" row defaultValue={defaultVccChecked}>
        <FormControlLabel
          data-cy="should-vcc-be-updated-true"
          key="should-vcc-be-updated-yes"
          value
          control={<Radio />}
          label="Yes"
        />
        <FormControlLabel
          key="should-vcc-be-updated-no"
          value={false}
          data-cy="should-vcc-be-updated-false"
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </FormControl>
  );
}
