import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import PermissionComponent from '../../Common/PermissionedComponent';
import { useConfirmDialog } from '../../Common/Providers/ConfirmDialogProvider';

export default function RoomTypeCapacity({ capacity, onRequestEdit, deleteRoomTypeCapacity }) {
  const showConfirmDialog = useConfirmDialog();

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete this capacity?',
    });

    if (!confirmed) return;

    deleteRoomTypeCapacity(capacity.id);
  };

  return (
    <Grid container spacing={2} direction="row">
      <Grid xs={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" component="div">
          Adults
        </Typography>
        <Typography variant="h6" component="div">
          {capacity.adults}
        </Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid xs={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="div">Children</Typography>
        <Typography variant="h6" component="div">
          {capacity.children}
        </Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid xs={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="div">Infants</Typography>
        <Typography variant="h6" component="div">
          {capacity.infants}
        </Typography>
        <Divider orientation="vertical" />
      </Grid>
      {!capacity.is_dynamic && (
        <Grid xs={6} display="flex" gap={2} justifyContent="end">
          <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
            <Button variant="text" startIcon={<EditIcon />} onClick={() => onRequestEdit(capacity)}>
              Edit
            </Button>
            <Button variant="text" startIcon={<DeleteIcon />} onClick={handleDelete}>
              Delete
            </Button>
          </PermissionComponent>
        </Grid>
      )}
    </Grid>
  );
}
