import deleteDates from '../../../../utils/deleteDates';
import deleteLinks from '../../../../utils/deleteLinks';
import nullsToEmptyStrings from '../../../../utils/nullsToEmptyStrings';

/**
 * Remove the _links & *_at fields and set nulls to empty strings to prevent
 * unnecessary, incorrect & invalid data being sent in properties updates
 * @param property
 * @returns {*}
 */
export default function cleanProperty(property) {
  return nullsToEmptyStrings(deleteDates(deleteLinks(property)));
}
