import { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import * as PublicOfferFeed from '~/services/PublicOfferFeedService';

type UseModificationLogsResult = {
  modificationLogs: Array<PublicOfferFeed.ModificationLog>;
  isLoading: boolean;
  count: number;
  getModifications: (filter: PublicOfferFeed.ModificationFilters) => void;
};

type UseModificationLogsProps = {
  filters: PublicOfferFeed.ModificationFilters;
};

const useModificationLogs = ({ filters }: UseModificationLogsProps): UseModificationLogsResult => {
  const [modificationLogs, setModificationLogs] = useState<Array<PublicOfferFeed.ModificationLog> | null>(null);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const getModifications = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await PublicOfferFeed.getModifications(filters);

      setModificationLogs(response.result || []);
      setCount(response.count || 0);
    } catch (error) {
      enqueueSnackbar(error.response?.data || error.message);
    } finally {
      setIsLoading(false);
    }
  }, [filters]);

  return {
    modificationLogs,
    isLoading,
    count,
    getModifications,
  };
};

export default useModificationLogs;
