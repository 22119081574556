import React from 'react';

import { SubmitButtonProps, UiSchema } from '@rjsf/utils';

import { Box } from '@mui/material';
import Fab from '@mui/material/Fab';

const DEFAULT_SUBMIT_BUTTON_OPTIONS: UiSchema = {
  norender: false,
  submitText: 'Submit',
};

export default function FloatingSubmitButtonTemplate(props: SubmitButtonProps) {
  const { uiSchema } = props;

  const { norender = DEFAULT_SUBMIT_BUTTON_OPTIONS.norender, submitText = DEFAULT_SUBMIT_BUTTON_OPTIONS.submitText } =
    uiSchema['ui:submitButtonOptions'] ?? DEFAULT_SUBMIT_BUTTON_OPTIONS;

  if (norender) return null;

  return (
    <Box position="sticky" p={2} left="100%" bottom="0" display="inline-block" zIndex="appBar">
      <Fab size="large" variant="extended" color="success" type="submit">
        {submitText}
      </Fab>
    </Box>
  );
}
