import { ThunkAction } from 'redux-thunk';
import requestGetHeroPlannerBrands from '~/queries/customerCommunication/requestGetHeroPlannerBrands';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

const fetchHeroPlannerBrands =
  (): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> => async (dispatch, getState) => {
    const appState = getState();
    const existing = appState.customerCommunication.brands;
    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_BRANDS_FETCHING,
      });
      try {
        const brands = await requestGetHeroPlannerBrands();
        dispatch({
          type: CustomerCommunicationActionTypes.SET_BRANDS_FULFILLED,
          brands,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_BRANDS_REJECTED,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerBrands;
