import React, { useCallback, useState } from 'react';

import { Alert, Button, Dialog, DialogContent, DialogContentText, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import ReservationService from '~/services/ReservationService';

interface Props {
  vendorId: string;
  propertyId?: string;
  channelManager?: string;
}

export default function PropertyUploadData(props: Props) {
  const hiddenFileInput = React.useRef(null);
  const { vendorId, propertyId, channelManager } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const supportsPullingOccupancies = channelManager === PROPERTY_CHANNEL_MANAGERS.derbysoft;

  const triggerValidation = useCallback(
    async (file) => {
      setShowModal(true);
      setInProgress(true);
      setError(null);
      setShowConfirmationMessage(false);

      try {
        await fileUpload(file, vendorId, propertyId);
        setShowConfirmationMessage(true);
      } catch (e) {
        if (e.errors) {
          setError(e.errors[0].message);
        } else {
          setError(e.message);
        }
      } finally {
        setInProgress(false);
      }
    },
    [vendorId, propertyId],
  );

  const triggerProcessing = useCallback(async () => {
    setShowModal(true);
    setInProgress(true);
    setError(null);
    setShowConfirmationMessage(false);

    try {
      await ReservationService.triggerHotelUpdates(propertyId);
      await setShowConfirmationMessage(true);
    } catch (e) {
      if (e.errors) {
        setError(e.errors[0].message);
      } else {
        setError(e.message);
      }
    } finally {
      setInProgress(false);
    }
  }, [propertyId]);

  const handleClick = () => {
    hiddenFileInput.current.value = '';
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    await triggerValidation(fileUploaded);

    // Automatically pull occupancy data for derbysoft properties
    if (supportsPullingOccupancies) {
      await triggerProcessing();
    }
  };

  const fileUpload = async (file, vendorId, propertyId) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('vendorId', vendorId);
    if (propertyId) {
      await ReservationService.importPropertiesData(propertyId, formData);
    } else {
      await ReservationService.importProperties(formData);
    }
  };

  return (
    <div>
      <div>
        <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
          Property Data
        </Typography>
        <Button onClick={handleClick} type="button" variant="contained">
          Upload Data File
        </Button>

        <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
      </div>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <DialogContentText variant="h5">Data file upload</DialogContentText>
          <DialogContentText>Please wait while the data file is being uploaded.</DialogContentText>

          {inProgress && <Spinner />}
          {error && <ErrorDisplay message={error} />}
          {showConfirmationMessage && (
            <Alert severity="success">
              <strong>Success!</strong> Imported
            </Alert>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
