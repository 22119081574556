import React, { ComponentProps } from 'react';

import { Chip } from '@mui/material';

interface Props {
  status: 'APPROVED' | 'PENDING' | 'DRAFT' | string;
  /**
   * @default small
   */
  size?: ComponentProps<typeof Chip>['size'];
}

const STATUS_COLOURS: Record<Props['status'], ComponentProps<typeof Chip>['color']> = {
  DRAFT: 'info',
  PENDING: 'warning',
  APPROVED: 'success',
};

function ScheduleStatusChip(props: Props) {
  const { status, size = 'small' } = props;

  return <Chip label={status} color={STATUS_COLOURS[status]} size={size} />;
}

export default ScheduleStatusChip;
