import React, { ComponentProps, useCallback, useEffect, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';
import { TourDetailsFormResources, useTourDetailsFormQuery } from '~/queries/tours/useTourDetailsFormQueries';

import { Alert, AlertTitle, Button, CircularProgress, Menu, MenuItem } from '@mui/material';

import { downloadTourOptionPriceHistory } from '~/services/ToursService';

import ExtendedRJSForm from '../RJSF/ExtendedRJSForm';

// TODO: consume the contract Response.TourOptionsFormResponse['formData']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateItienraryDaysLength = (formData: any): string[] => {
  const errors: string[] = [];

  formData?.tourOptions.forEach((tourOption, tourOptionIndex) => {
    // sum the itinerary durations
    const lastItineraryDay = tourOption.itinerary.map((it) => it.duration ?? 1).reduce((acc, curr) => acc + curr, 0);

    if (tourOption.numberOfDays !== lastItineraryDay) {
      errors.push(`Number of days for tour option ${tourOptionIndex + 1} does not match itinerary duration.\n`);
    }
  });

  return errors;
};

interface Props {
  tourId: string;
}

export default function TourOptionsDetailsForm(props: Props) {
  const { tourId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { fetchRequestInstance, fetch, patchRequestInstance, patch } = useTourDetailsFormQuery(
    TourDetailsFormResources.TOUR_OPTIONS,
  );

  useEffect(() => {
    fetch(tourId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId]);

  const handleSubmission = useCallback<ComponentProps<typeof ExtendedRJSForm>['onSubmit']>(
    ({ formData }) => {
      const itineraryDaysLengthValidation = validateItienraryDaysLength(formData);
      if (
        !itineraryDaysLengthValidation.length ||
        confirm(`${itineraryDaysLengthValidation.join('\n')}\nSubmit anyway?`)
      ) {
        patch(tourId, formData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourId],
  );

  const downloadCSVPrices = useMemo(() => {
    const pricesLinks = new Map();
    if (fetchRequestInstance.status === 'succeeded') {
      const { result } = fetchRequestInstance;
      result.formData.tourOptions.forEach((tourOption, index) => {
        const filename = `prices-tour-option-${index + 1}.csv`;
        pricesLinks.set(tourOption.id, () =>
          downloadTourOptionPriceHistory(tourOption.id)
            .then((response) => {
              fileDownload(response, filename);
              enqueueSnackbar('Price history downloaded successfully.', {
                variant: 'success',
              });
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar(`Price history downloaded failed.`, {
                variant: 'error',
              });
            }),
        );
      });
    }
    return pricesLinks;
  }, [fetchRequestInstance.status]);

  useEffect(() => {
    switch (patchRequestInstance.status) {
      case 'succeeded':
        enqueueSnackbar('Tour options updated successfully.', {
          variant: 'success',
        });
        break;
      case 'failed':
        enqueueSnackbar(`Submission failed! ${patchRequestInstance.error}`, {
          variant: 'error',
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequestInstance]);

  if (fetchRequestInstance.status === 'pending' || fetchRequestInstance.status === 'uninitiated') {
    return <CircularProgress />;
  }

  if (fetchRequestInstance.status === 'failed') {
    return (
      <Alert
        severity="error"
        action={
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              fetch(tourId);
            }}
          >
            Retry
          </Button>
        }
      >
        <AlertTitle>Failed to fetch the form.</AlertTitle>
        {fetchRequestInstance.error}
      </Alert>
    );
  }

  const { result } = fetchRequestInstance;
  return (
    <>
      <ExtendedRJSForm
        id="tour-options-details-form"
        schema={result.schema}
        uiSchema={result.uiSchema}
        formData={result.formData}
        onSubmit={handleSubmission}
        busy={patchRequestInstance.status === 'pending'}
      />
      {patchRequestInstance.status === 'failed' && <Alert severity="error">{patchRequestInstance.error}</Alert>}
      <h4>Price logs</h4>
      <div>
        <Button onClick={handleOpen}>Download per tour option</Button>
        <Menu anchorEl={anchorEl} open={showMenu} onClose={handleClose}>
          {result.formData.tourOptions.map((tourOption, index) => (
            <>
              {downloadCSVPrices.has(tourOption.id) && (
                <MenuItem key={index} onClick={downloadCSVPrices.get(tourOption.id)}>
                  {tourOption.title}
                </MenuItem>
              )}
            </>
          ))}
        </Menu>
      </div>
    </>
  );
}
