import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Divider, Stack, Typography } from '@mui/material';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { toOrdinal } from '~/utils/toOrdinal';

interface Props {
  roomIndex: number;
  rebookableData: App.Bedbank.ReservationRebookInfo;
  currencyCode: string;
  shortInfo?: boolean;
}

function RebookInfo({ rebookableData, currencyCode, roomIndex, shortInfo }: Props) {
  return (
    <Stack direction="column" sx={{ '& strong': { fontWeight: 500 } }}>
      <Typography>
        <strong>Room</strong>: {roomIndex}
      </Typography>

      {rebookableData.rebookCount > 1 && <Typography>{toOrdinal(rebookableData.rebookCount)} Opportunity</Typography>}

      <Divider />

      {!shortInfo && (
        <>
          <Typography>
            <strong>Current sell price</strong>:{' '}
            {currencyFormatter.format(rebookableData.originalPrice, {
              code: currencyCode,
            })}
          </Typography>
          <Typography>
            <strong>New sell price</strong>:{' '}
            {currencyFormatter.format(rebookableData.newPrice, {
              code: currencyCode,
            })}
          </Typography>
          <Typography>
            <strong>Current cost price</strong>:{' '}
            {currencyFormatter.format(rebookableData.originalCost, {
              code: currencyCode,
            })}
          </Typography>
          <Typography>
            <strong>New cost price</strong>:{' '}
            {currencyFormatter.format(rebookableData.newCost, {
              code: currencyCode,
            })}
          </Typography>
          <Typography>
            <strong>Original Margin</strong>:{' '}
            {currencyFormatter.format(rebookableData.originalMarketingFee, {
              code: currencyCode,
            })}{' '}
            ({((rebookableData.originalMarketingFee / rebookableData.originalPrice) * 100).toFixed(2)}%)
          </Typography>
          <Typography>
            <strong>New Margin</strong>:{' '}
            {currencyFormatter.format(rebookableData.originalPrice - rebookableData.newCost, {
              code: currencyCode,
            })}{' '}
            (
            {(((rebookableData.originalPrice - rebookableData.newCost) / rebookableData.originalPrice) * 100).toFixed(
              2,
            )}
            %)
          </Typography>
          <Typography>
            <strong>Incremental Margin</strong>:{' '}
            {currencyFormatter.format(rebookableData.marginIncrease, {
              code: currencyCode,
            })}{' '}
            ({rebookableData.marginIncreasePercentage.toFixed(2)}
            %)
          </Typography>
        </>
      )}

      <Typography>
        <strong>Incremental Margin (AUD)</strong>:{' '}
        {currencyFormatter.format(rebookableData.marginIncreaseInAUD, {
          code: 'AUD',
        })}{' '}
        ({rebookableData.marginIncreasePercentage.toFixed(2)}
        %)
      </Typography>
      {rebookableData.originalSupplier && (
        <Typography>
          <strong>Original Supplier</strong>: {rebookableData.originalSupplier}
        </Typography>
      )}
      {rebookableData.newSupplier && (
        <Typography>
          <strong>New Supplier</strong>: {rebookableData.newSupplier}
        </Typography>
      )}

      <Typography>
        <strong>Last Scanned</strong>: {formatDateOrdinalWithClock(rebookableData.createdAt)}
      </Typography>
    </Stack>
  );
}

export default RebookInfo;
