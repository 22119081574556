import React from 'react';

import { useParams } from 'react-router-dom';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import InclusionItemForm from './components/InclusionFilterForm';

type Props = {
  vendorId: string;
  onSave: () => void;
};

function InclusionsFilterCreate({ vendorId, onSave }: Props) {
  const { inclusionId } = useParams<{ inclusionId: string }>();

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>Create Inclusion Filter</DialogTitle>
      <DialogContent>
        <InclusionItemForm fkInclusionId={inclusionId} vendorId={vendorId} onSave={onSave} />
      </DialogContent>
    </Dialog>
  );
}

export default InclusionsFilterCreate;
