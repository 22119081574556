import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Autocomplete, Chip, TextField, createFilterOptions } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';
import { RateCodeListResponse } from '@luxuryescapes/contract-svc-cruise/dist/types/cruises';

import { Option } from '~/components/Cruises/pages/Promotions/types';

import rateCodeService from '~/services/cruises/RateCodeService';

const filter = createFilterOptions<Option>();

type RateCodeResponse = {
  loading: boolean;
  result: Array<CruisesContract.RateCodeListResponse>;
};

const INITIAL_REQUEST_STATE = {
  result: [],
  loading: false,
};

interface Props {
  selectedValues: Array<CruisesContract.RateCodeListResponse>;
  setSelectedValues: (values: (prevState: RateCodeListResponse[]) => RateCodeListResponse[]) => void;
  disabled: boolean;
}

const RateCodeCreationAutocomplete = (props: Props) => {
  const { selectedValues, setSelectedValues, disabled = false } = props;
  const [rateCodes, setRateCodes] = useState<RateCodeResponse>(INITIAL_REQUEST_STATE);

  const getRateCodeList = useCallback(async (rateCode?: string) => {
    setRateCodes((prev) => ({ ...prev, loading: true }));
    const res = await rateCodeService.getRateCodeList({
      ...(!!rateCode && { rateCodes: rateCode }),
    });
    const resultMapped = res.result?.map((item) => ({
      code: item.code,
      vendor: item.vendor,
    }));

    setRateCodes({ loading: false, result: resultMapped } as RateCodeResponse);
  }, []);

  const rateCodesSelected = useMemo(() => {
    return selectedValues?.map((item) => ({
      label: `${item.code} | ${item.vendor?.code ?? ''}`,
      value: item.code,
    }));
  }, [selectedValues]);

  const handleSailingsChange = useCallback(
    (_, selectedOptions: Option[] | null) => {
      setSelectedValues((prevState: CruisesContract.RateCodeListResponse[]) => {
        if (!selectedOptions) {
          return prevState;
        }
        return selectedOptions
          .map((selectedOffer) => ({
            code: selectedOffer.value,
            vendor: { code: selectedOffer.label.split(' | ')[1] },
          }))
          .filter((item) => !!item) as CruisesContract.RateCodeListResponse[];
      });
    },
    [setSelectedValues],
  );

  const departureSelectOptions = useMemo(() => {
    return rateCodes.result
      ?.filter((item) => !selectedValues.find((selected) => selected.code === item.code))
      ?.map((item) => ({
        label: `${item.code} | ${item.vendor?.code ?? ''}`,
        value: item.code,
      }));
  }, [rateCodes.result, selectedValues]);

  useEffect(() => {
    getRateCodeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      data-testid="cruise-input-rate-code"
      multiple
      selectOnFocus
      clearOnBlur
      disabled={disabled}
      value={rateCodesSelected}
      loading={rateCodes.loading}
      onChange={handleSailingsChange}
      options={departureSelectOptions}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="small"
            variant="outlined"
            key={option.value}
            label={option.label ?? ''}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} required fullWidth label="Rate Codes" placeholder={`Please Enter Rate Code`} />
      )}
      onInputChange={(_, value) => getRateCodeList(value)}
      filterOptions={(options, state) => {
        const filtered = filter(options, state);

        // Suggest the creation of a new value
        if (state.inputValue !== '') {
          filtered.push({
            label: `Add "${state.inputValue}"`,
            value: state.inputValue,
          });
        }

        return filtered;
      }}
    />
  );
};

export default RateCodeCreationAutocomplete;
