import {
  BEGIN_QUOTE,
  CUSTOMER_INTERESTED,
  CUSTOMER_NOT_INTERESTED,
  EDIT_QUOTE,
  SET_DESTINATION_COUNTRIES,
  SET_END_DATE,
  SET_INITIAL_STATE,
  SET_QUOTE_ERRORS,
  SET_QUOTE_RESULT,
  SET_START_DATE,
  SET_TRAVELLERS,
} from '../actions/insurance';

const initialState = {
  fetched: false,
  products: [],
  productsFetched: false,
  startDate: null,
  endDate: null,
  destinationCountries: [],
  customerCountryCode: null,
  currencyCode: null,
  travellers: [],
  quoteErrors: [],
  quoteResults: [
    {
      quoteId: null,
      total: 0,
      excessAmount: 0,
      productId: null,
      productName: null,
    },
  ],
  quoteFetched: false,
  notInterested: false,
  notInterestedReason: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...state,
        ...action.state,
      };
    }
    case BEGIN_QUOTE: {
      return {
        ...state,
        fetched: false,
        quoteFetched: false,
      };
    }
    case EDIT_QUOTE: {
      return {
        ...state,
        quoteResults: [...initialState.quoteResults],
        quoteFetched: false,
        notInterested: false,
        notInterestedReason: null,
      };
    }
    case SET_QUOTE_ERRORS: {
      return {
        ...state,
        fetched: true,
        quoteFetched: false,
        quoteErrors: action.errors,
      };
    }
    case SET_QUOTE_RESULT: {
      return {
        ...state,
        fetched: true,
        quoteFetched: true,
        quoteErrors: [],
        quoteResults: action.results,
      };
    }
    case SET_DESTINATION_COUNTRIES: {
      return {
        ...state,
        destinationCountries: action.destinationCountries,
      };
    }
    case SET_START_DATE: {
      return {
        ...state,
        startDate: action.startDate,
      };
    }
    case SET_END_DATE: {
      return {
        ...state,
        endDate: action.endDate,
      };
    }
    case SET_TRAVELLERS: {
      return {
        ...state,
        travellers: action.travellers,
      };
    }
    case CUSTOMER_NOT_INTERESTED: {
      return {
        ...state,
        notInterested: true,
        notInterestedReason: action.reason,
      };
    }
    case CUSTOMER_INTERESTED: {
      return {
        ...state,
        notInterested: false,
        notInterestedReason: null,
      };
    }
    default:
      return state;
  }
}
