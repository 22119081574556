import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerCadences from '~/actions/customerCommunication/fetchHeroPlannerCadences';

import { useAppSelector } from '../store';

function useHeroPlannerCadences(
  brandId: string,
  countryGroupId: string,
  countryId: string,
  stateId: string,
  cityId: string,
  segmentId: string,
) {
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerCadences> = [
      brandId,
      countryGroupId,
      countryId,
      stateId,
      cityId,
      segmentId,
    ];
    return [getRecordKeyFor(...params), params];
  }, [brandId, countryGroupId, countryId, stateId, cityId, segmentId]);
  const dataReq = useAppSelector(
    (state) => state.customerCommunication.countryGroupCountryStateCitySegmentCadencesRecords[key],
  );

  const appDispatch = useDispatch();
  const fetchCadences = useCallback(() => {
    appDispatch(fetchHeroPlannerCadences(...params));
  }, [appDispatch, params]);

  useEffect(() => {
    fetchCadences();
  }, [fetchCadences]);

  return { dataReq, fetchCadences };
}

export default useHeroPlannerCadences;
