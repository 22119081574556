export const CREDIT_RESERVATION_LIST_ATTRIBUTES = [
  'id',
  'state',
  'origin',
  'destination',
  'region',
  'total',
  'total_with_fees',
  'currency',
  'contact_phone_number',
  'contact_email_address',
  'previous_pnr_id',
  'new_pnr_ids',
  'created_at',
  'comments',
  'passengers',
];

export const CREDIT_RESERVATION_CSV_ATTRIBUTES = [
  'id',
  'state',
  'origin',
  'destination',
  'region',
  'total',
  'total_with_fees',
  'currency',
  'contact_phone_number',
  'contact_email_address',
  'previous_pnr_id',
  'new_pnr_ids',
  'created_at',
  'comments',
  'passengers',
  'journey',
];

export const REGIONS = ['AU', 'NZ', 'SG', 'HK', 'GB', 'DE', 'US'];

export const STATES = ['applied', 'inprogress', 'completed'];

export const FLIGHT_DEALS_LIST_ATTRIBUTES = [
  'id',
  'region',
  'carrier_code',
  'fare_type',
  'deal_name',
  'category_group',
  'origin_airport_code',
  'destination_airport_code',
  'sales_start_date',
  'sales_end_date',
  'departure_date_1',
  'departure_date_2',
  'departure_date_3',
  'arrival_date_1',
  'arrival_date_2',
  'arrival_date_3',
];
