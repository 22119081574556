import React from 'react';

import { Link } from 'react-router-dom';

import { Button, Stack } from '@mui/material';

import { CruisePromoBannerProvider } from '~/components/Cruises/context/useCruisePromoBanners';

import { PromoBannerList } from './components/PromoBannerList';

const PromoBannerPage: React.FC = (): JSX.Element => (
  <CruisePromoBannerProvider>
    <Stack direction="row-reverse" spacing={1}>
      <Button component={Link} to={'/cruises/promo-banner/order'} variant="text" size="small">
        Change ordering
      </Button>

      <Button component={Link} to={'/cruises/promo-banner/create'} variant="text" size="small">
        New promo tile
      </Button>
    </Stack>

    <PromoBannerList />
  </CruisePromoBannerProvider>
);

export default PromoBannerPage;
