import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { InternalSupplier, ProgressStatusResponse, getProgressStatuses } from '~/services/AccommodationService';
import { HttpResponse } from '~/services/types';

import asyncPoll from '~/utils/asyncPoll';

import ProgressStateItem from './ProgressStateItem';

type Props = {
  open: boolean;
  apiCall: (propertyId: string, internalSupplier: InternalSupplier) => Promise<HttpResponse<ProgressStatusResponse>>;
  onClose: () => void;
};

interface InternalSupplierOption {
  value: InternalSupplier;
  label: string;
  description: ReactNode;
}

const INTERNAL_SUPPLIERS: Array<InternalSupplierOption> = [
  {
    value: 'reservation',
    label: 'LE',
    description: (
      <>
        Enter the LE property ID to import
        <br />
        Clicking 'Import' will kick off a background import job
        <br />
        This will import any associated bedbank properties
        <br />
        Running progress will be displayed below
      </>
    ),
  },
  {
    value: 'bedbank',
    label: 'Bedbank',
    description: (
      <>
        Enter the Bedbank property ID to import
        <br />
        Clicking 'Import' will kick off a background import job
        <br />
        Running progress will be displayed below
      </>
    ),
  },
];

function AccommodationAddPropertyProgressModal({ open, apiCall, onClose }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [selectedInternalSupplierType, setSelectedInternalSupplierType] = useState<InternalSupplier | null>(null);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [propertyId, setPropertyId] = useState('');
  const [propertyImportSvcRes, setPropertyImportSvcRes] = useState<ProgressStatusResponse | null>(null);
  const [propertyImportSvcBedbank, setPropertyImportSvcBedbank] = useState<ProgressStatusResponse | null>(null);
  const [createBaseProperty, setCreateBaseProperty] = useState<ProgressStatusResponse | null>(null);

  const TWO_MINUTES_IN_MS = 60000 * 2;
  const POLL_RATE_IN_MS = 500;

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setStatusMessage(null);
    setIsLoading(false);
  }, []);

  const resetAndClose = useCallback(() => {
    setIsLoading(false);
    setIsComplete(false);
    setStatusMessage(null);
    setErrorMessage(null);
    setPropertyId('');
    setPropertyImportSvcRes(null);
    setPropertyImportSvcBedbank(null);
    setCreateBaseProperty(null);
    onClose();
  }, [onClose]);

  const handleInputChange = useCallback((e) => {
    setPropertyId(e.target.value);
  }, []);

  const setJobStatuses = useCallback((result: Array<ProgressStatusResponse>) => {
    for (const status of result) {
      switch (status.type) {
        case 'propertyImportSvcRes':
          setPropertyImportSvcRes(status);
          break;
        case 'propertyImportSvcBedbank':
          setPropertyImportSvcBedbank(status);
          break;
        case 'createBaseProperty':
          setCreateBaseProperty(status);
          break;
      }
      if (status.state === 'failed') {
        handleError(status.message);
      }
    }
  }, []);

  const pollImportStatus = useCallback(
    async (id: string) => {
      try {
        await asyncPoll({
          apiCall: () => getProgressStatuses(id),
          validateFunction: async ({ result }) => {
            const propertyImport = result.find((status) => status.type === 'propertyImport');
            if (!propertyImport) {
              return false;
            }
            setJobStatuses(result);
            if (propertyImport.state === 'completed') {
              setIsLoading(false);
              setIsComplete(true);
              setStatusMessage(propertyImport.message);
              return true;
            } else if (propertyImport.state === 'failed') {
              setIsLoading(false);
              setIsComplete(true);
              return true;
            } else {
              return false;
            }
          },
          maxTime: TWO_MINUTES_IN_MS,
          pollInterval: POLL_RATE_IN_MS,
        });
      } catch (e) {
        handleError(e.message);
        setIsLoading(false);
        return;
      }
    },
    [TWO_MINUTES_IN_MS, handleError, setJobStatuses],
  );

  const runStartJob = useCallback(async () => {
    setIsLoading(true);
    setIsComplete(false);
    setErrorMessage(null);

    const { result } = await apiCall(propertyId, selectedInternalSupplierType);
    if (!result?.id) {
      handleError('Failed to start job');
      return;
    }
    pollImportStatus(result.id);
  }, [propertyId, pollImportStatus, handleError, apiCall, selectedInternalSupplierType]);

  const handleSelectInternalSupplierType = useCallback((event) => {
    setSelectedInternalSupplierType(event.target.value);
  }, []);

  const selectedInternalSupplier = useMemo(() => {
    return INTERNAL_SUPPLIERS.find((option) => option.value === selectedInternalSupplierType);
  }, [selectedInternalSupplierType]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Import Property</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <Stack spacing={2}>
          <DialogContentText>Please Select an Internal Supplier</DialogContentText>
          <FormControl fullWidth>
            <InputLabel>Internal Supplier</InputLabel>
            <Select
              value={selectedInternalSupplierType}
              label="Internal Supplier"
              onChange={handleSelectInternalSupplierType}
            >
              {INTERNAL_SUPPLIERS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!!selectedInternalSupplier && (
            <>
              <DialogContentText>{selectedInternalSupplier.description}</DialogContentText>
              <TextField
                label="Property ID"
                value={propertyId}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </>
          )}

          {propertyImportSvcRes && (
            <ProgressStateItem label="Importing LE property" state={propertyImportSvcRes.state} />
          )}
          {propertyImportSvcBedbank && (
            <ProgressStateItem label="Importing Bedbank property" state={propertyImportSvcBedbank.state} />
          )}
          {createBaseProperty && <ProgressStateItem label="Creating mapping" state={createBaseProperty.state} />}
          {isComplete && statusMessage && <DialogContentText color="primary">{statusMessage}</DialogContentText>}
          {errorMessage && <DialogContentText color="error">{errorMessage}</DialogContentText>}
        </Stack>
      </DialogContent>
      {!isComplete && (
        <DialogActions>
          <Button variant="text" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={runStartJob} disabled={isLoading || !propertyId}>
            {isLoading ? <Spinner size={15} inline /> : 'Import'}
          </Button>
        </DialogActions>
      )}
      {isComplete && (
        <DialogActions>
          <Button variant="contained" onClick={resetAndClose} disabled={isLoading}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default AccommodationAddPropertyProgressModal;
