import React from 'react';

import { Box } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

export default function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <GridToolbarQuickFilter style={{ flex: 1 }} />
    </Box>
  );
}
