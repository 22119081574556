import React, { useEffect, useState } from 'react';

import { SortableContainer } from 'react-sortable-hoc';
import { v4 as uuid } from 'uuid';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Button, IconButton, ImageList, ImageListItem, Typography } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import RoomTypeImagesForm, { RoomTypeImage } from '~/components/Common/Forms/RoomTypeImagesForm';
import { buttonMessages as baseButtonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';
import Image from '~/components/Common/Image';

const buttonMessages = { ...baseButtonMessages };
buttonMessages[buttonStates.default] = 'Save changes';

const ApiList = SortableContainer(({ images, toggleImageSelection }) => (
  <ImageList cols={4} gap={10} sx={{ mt: 2 }}>
    {images.map((item) => (
      <ImageListItem
        key={item.id_cloudinary_external}
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.preventDefault();
          toggleImageSelection(item.id, item.selected);
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            position: 'absolute',
            right: 10,
            zIndex: 1,
          }}
        >
          <IconButton
            color="info"
            title={item.selected ? 'Select Image' : 'Unselect Image'}
            sx={{
              zIndex: 2,
            }}
          >
            {item.selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </IconButton>
        </Box>
        <Image
          className="img-responsive mx-auto"
          publicId={item.id_cloudinary_external}
          options={{
            width: 266,
            height: 200,
          }}
        />
        <Box m={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>{item.title}</Typography>
        </Box>
      </ImageListItem>
    ))}
  </ImageList>
));

interface ApiImageData extends Reservation.Image {
  selected: boolean;
}

interface Props {
  images: Reservation.Image[];
  apiImages: Reservation.Image[];
  onImagesChange: (images: Reservation.Image[]) => void;
  saveImages: (images: RoomTypeImage[]) => Promise<void>;
}

function RentalImagesForm(props: Props) {
  const [apiImages, setApiImages] = useState<ApiImageData[]>([]);
  const [heroImages, setHeroImages] = useState<Reservation.Image[]>([]);
  const [saveButtonState, setSaveButtonState] = useState(buttonStates.saved);
  const isButtonStateSaving = saveButtonState === buttonStates.saving;

  useEffect(() => {
    setHeroImages([...props.images]);
    setApiImages(
      props.apiImages.map<ApiImageData>((image) => ({
        ...image,
        selected: false,
      })),
    );
  }, [props.apiImages, props.images]);

  const sortImages = (images) => {
    return [...images]
      .map((i) => ({
        ...i,
        publicImageId: i.id_cloudinary_external,
      }))
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
  };

  const onCreateImage = async (imageServiceId: string) => {
    const imageData = {
      id: uuid(), // All array manipulation is done by id, so we need to generate a unique id for unsaved images
      id_cloudinary_external: imageServiceId,
      title: 'New image',
      order: heroImages.length + 1,
    };
    return imageData;
  };

  const onUpdateImages = async (images: any[]) => {
    setSaveButtonState(buttonStates.default);
    setHeroImages(images);
  };

  const onSaveImages = () => {
    setSaveButtonState(buttonStates.saving);
    props
      .saveImages(heroImages)
      .then(() => {
        setSaveButtonState(buttonStates.saved);
      })
      .catch(() => {
        setSaveButtonState(buttonStates.failed);
      });
  };

  const moveImagesToHero = () => {
    const newImages = [...apiImages]
      .filter((i) => i.selected)
      .map((p, index) => ({
        ...p,
        order: heroImages.length + index + 1,
      }));
    setApiImages([...apiImages].filter((i) => !i.selected));
    onUpdateImages([...heroImages, ...newImages]);
  };

  const toggleImageSelection = (id, oldValue) => {
    const newImages = [...apiImages].map((p) => ({
      ...p,
      selected: p.id === id ? !oldValue : p.selected,
    }));
    setApiImages(newImages);
  };

  return (
    <>
      <PageSubheader title="Property Images" />

      <Box>
        <Typography variant="h4">Hero images</Typography>
        <div style={{ justifyContent: 'end' }}>
          <Button variant="contained" onClick={onSaveImages} disabled={isButtonStateSaving}>
            {buttonMessages[saveButtonState]}
          </Button>
        </div>
      </Box>

      <RoomTypeImagesForm
        images={sortImages(heroImages)}
        hasHiddenToggle={true}
        onAddImage={onCreateImage}
        onUpdateImages={onUpdateImages}
        hideDeleteButton={true}
      />

      {apiImages.length > 0 && (
        <>
          <Box mt={7}>
            <Typography variant="h4">API images</Typography>
          </Box>
          <ApiList
            images={apiImages}
            toggleImageSelection={toggleImageSelection}
            axis="xy"
            distance={3}
            helperClass="sortable-helper"
          />
          <div style={{ justifyContent: 'end' }}>
            <Button variant="contained" color="primary" onClick={moveImagesToHero}>
              Move to Hero images
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default RentalImagesForm;
