import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import useHeroPlannerBrands from '~/hooks/customerCommunication/useHeroPlannerBrands';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

function ScheduleBrandInput(props: Props) {
  const { name, value, onChange } = props;
  const { brandsReq } = useHeroPlannerBrands();
  const options = brandsReq?.result ?? (EmptyArray as Array<CustomerCommunication.HeroPlannerBrand>);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (options.length && (!value || !options.find((i) => i.id === value))) {
      onChange(options[0].id);
    }
  }, [onChange, options, value]);

  const initialBrand = useMemo<CustomerCommunication.HeroPlannerBrand | undefined>(() => {
    if (value && !options.find((v) => v.id === value)) return { id: value, name: value };
  }, [options, value]);

  return (
    <FormControl variant="standard" sx={{ width: 200 }}>
      <InputLabel shrink>Brand</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        required
        variant="standard"
        disabled={isRequestUnresolved(brandsReq) || isRequestRejected(brandsReq)}
        error={isRequestRejected(brandsReq)}
      >
        {!!initialBrand && (
          <MenuItem value={initialBrand.id}>
            <i>{initialBrand.name}</i>
          </MenuItem>
        )}
        {options.map((brand) => (
          <MenuItem key={brand.id} value={brand.id}>
            {brand.name}
          </MenuItem>
        ))}
      </Select>
      {isRequestRejected(brandsReq) && <FormHelperText error>{brandsReq.error}</FormHelperText>}
      {isRequestPending(brandsReq) && <LinearProgress sx={{ position: 'absolute' }} />}
    </FormControl>
  );
}

export default ScheduleBrandInput;
