import React, { useContext, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import { PackageFormContext } from '../PackageForm';

export default function PackageOptionsWidget(props) {
  const {
    value,
    label,
    options: { enumOptions = [] },
  } = props;

  const handleChange = (e) => {
    const { onChange } = props;
    onChange(e.target.value);
  };

  const context = useContext(PackageFormContext);

  const {
    vendorId,
    mapRoomRateToRatePlan,
    propertyData,
    packageData,
    packageData: { fk_property_id },
  } = context;

  const ratePlanId = useMemo<App.RoomRate>(
    () => mapRoomRateToRatePlan(propertyData, fk_property_id, value),
    [mapRoomRateToRatePlan, propertyData, fk_property_id, value],
  );
  return (
    <Box>
      <FormControl fullWidth sx={{ position: 'relative' }}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select onChange={handleChange} value={value} label={label}>
          <MenuItem value={undefined}> </MenuItem>
          {Array.isArray(enumOptions) &&
            enumOptions.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {ratePlanId && (
            <Link target="_blank" to={'/vendors/' + vendorId + '/rate-plans/' + ratePlanId?.rate_plan?.id}>
              (show rate plan)
            </Link>
          )}
          {value && (
            <Link
              target="_blank"
              to={`/vendors/${vendorId}/properties/${fk_property_id}/room-types/${packageData.fk_room_type_id}/room-rates/${value}`}
              style={{ marginLeft: '10px' }}
            >
              (view availability &amp; rates)
            </Link>
          )}
          {value && <span>&nbsp;RoomRateID: {value}</span>}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
