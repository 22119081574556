import React from 'react';

import { Buffer } from 'buffer';

import EditIcon from '@mui/icons-material/Edit';
import { Accordion, AccordionDetails, AccordionSummary, Button, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER } from '~/consts/roles';
import TENANTS from '~/consts/tenant';

import { formatDateLong } from '~/services/TimeService';
import { customerPortalHost } from '~/services/common';

const columns: GridColDef[] = [
  {
    field: 'gift_status',
    headerName: 'Status',
    sortable: false,
    disableColumnMenu: true,
    flex: 1.2,
    display: 'flex',
  },
  {
    field: 'gifter_user_id',
    headerName: "Gifter's user ID",
    sortable: false,
    disableColumnMenu: true,
    flex: 1.5,
    display: 'flex',
  },
  {
    field: 'recipient_email',
    headerName: "Recipient's Email",
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    display: 'flex',
  },
  {
    field: 'recipient_name',
    headerName: "Recipient's name",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'scheduled_date',
    headerName: 'Scheduled to be sent by',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'redemption_link',
    headerName: 'Redemption Link',
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    display: 'flex',
    renderCell: (params) => {
      return (
        <Link href={params.value} target="_blank" rel="noreferrer">
          {params.value}
        </Link>
      );
    },
  },
];
interface Props {
  order: App.Order;
  resendRedemptionEmail: () => void;
}

/**
 * This logic is the same as svc-notification-proxy to generate experience faretype parameter for the redemption link
 * @param order
 * @returns base64 faretype data
 */
const getExperienceFareType = (order: App.Order) => {
  const items = order.experience_items;
  const offerId = order.experience_items[0].provider_offer_id;
  const tickets = items.filter((item) => item.provider_offer_id === offerId).map((item) => item.ticket);

  const ticketsCountByFareType = tickets.reduce((ticketsCountByFareType, ticket) => {
    const { fareType } = ticket;

    if (!ticketsCountByFareType[fareType]) {
      ticketsCountByFareType[fareType] = 0;
    }

    ticketsCountByFareType[fareType]++;

    return ticketsCountByFareType;
  }, {});

  const ticketCounts = Object.entries(ticketsCountByFareType).map(([fareType, count]) => ({ fareType, count }));

  return Buffer.from(JSON.stringify(ticketCounts)).toString('base64');
};

export default function OrderGift({ order, resendRedemptionEmail }: Props) {
  const getRedemptionLink = (order) => {
    const tenant = Object.values(TENANTS).find((tenant) => tenant.brand === order.brand);
    const customerPortalUrl = customerPortalHost(tenant);

    if (order.has_experience) {
      // Add parameters for experience gift as svc-notification-proxy
      const offerId = order.experience_items[0].provider_offer_id;

      return `${customerPortalUrl}/redeem/${order.order_gift?.redemption_code}/${
        order.id
      }/${offerId}?type=experience&fareType=${getExperienceFareType(order)}`;
    } else {
      const offerId = order.items[0].fk_offer;
      return `${customerPortalUrl}/redeem/${order.order_gift?.redemption_code}/${order.id}/${offerId}`;
    }
  };

  const data = [
    {
      gift_status: order.gift_status,
      gifter_user_id: order.order_gift?.giver_user_id,
      recipient_email: order.order_gift?.receiver_email,
      recipient_name: order.order_gift?.receiver_name,
      scheduled_date: formatDateLong(new Date(order.order_gift?.scheduled_date)),
      redemption_link: getRedemptionLink(order),
    },
  ];

  return (
    <Accordion>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Typography>Gift</Typography>
          <PermissionedComponent requiredRoles={[ROLE_EMPLOYEE_USER, ROLE_ADMIN_USER]}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              {order.gift_status == 'pending_redemption' && (
                <Button variant="contained" onClick={resendRedemptionEmail}>
                  Resend Redemption Email
                </Button>
              )}
              {order.gift_status !== 'redeemed' && (
                <Link href={`/purchases/${order.id_orders}/gift/edit`}>
                  <EditIcon />
                </Link>
              )}
            </Stack>
          </PermissionedComponent>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          columns={columns}
          rows={data}
          getRowId={(row) => row.gift_status}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </AccordionDetails>
    </Accordion>
  );
}
