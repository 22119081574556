import React, { useMemo } from 'react';

import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { Control, useController } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControl, FormHelperText, IconButton, Stack, Typography } from '@mui/material';

import ArrayFieldItem from './ArrayFieldItem';

interface Props {
  render: (props: any) => React.ReactElement;
  control: Control<any>;
  label: string;
  name: string;
  itemSchema?: any;
  hideAddButton?: boolean;
}

export default function ArrayField({ render, control, name, label, itemSchema = '', hideAddButton = false }: Props) {
  const {
    field: { onChange: onChangeArray, value },
    fieldState: { error: arrayFieldError },
  } = useController({
    control,
    name,
  });

  const arrayValue = useMemo(() => Array.from(value ?? []), [value]);

  const sortableItems = useMemo<UniqueIdentifier[]>(
    () => arrayValue?.map((item, index) => `${item}-${index}`) ?? [],
    [arrayValue],
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = sortableItems.indexOf(active?.id);
      const newIndex = sortableItems.indexOf(over?.id);
      const newArray = arrayMove(arrayValue, oldIndex, newIndex);
      onChangeArray(newArray);
    }
  }

  const onChange = (e, index) => {
    const newArray = [...arrayValue];
    newArray.splice(index, 1, e.target.value);
    onChangeArray(newArray);
  };

  const onRemove = (index) => {
    const newArray = [...arrayValue];
    newArray.splice(index, 1);
    onChangeArray(newArray);
  };

  const onAdd = () => {
    const newArray = [...arrayValue, itemSchema];
    onChangeArray(newArray);
  };

  return (
    <Stack>
      <Typography variant="h6">{label}</Typography>
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext items={sortableItems}>
          {arrayValue?.map((key, index) => (
            <FormControl error={arrayFieldError?.[index]} key={`${key}-${index}`} sx={{ mb: 2 }}>
              <ArrayFieldItem itemKey={key} index={index}>
                <>
                  {render({
                    name,
                    index,
                    value: key,
                    onChange: (value) => onChange(value, index),
                  })}
                  <IconButton onClick={() => onRemove(index)} color="error">
                    <DeleteIcon fontSize="medium" />
                  </IconButton>
                </>
              </ArrayFieldItem>
              <FormHelperText>{arrayFieldError?.[index] && arrayFieldError?.[index].message}</FormHelperText>
            </FormControl>
          ))}
        </SortableContext>
      </DndContext>
      {!arrayValue.length && (
        <Box sx={{ p: 2, width: 1, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2">Empty</Typography>
        </Box>
      )}
      {!hideAddButton && (
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={onAdd} size="large" color="primary">
            <AddIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
}
