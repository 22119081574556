import React from 'react';

import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';

import { Box, Button } from '@mui/material';

import { queueRevelexPayableReport } from '~/services/ReportingService';
import { getDateStartOf } from '~/services/TimeService';

const formSchema: RJSFSchema = {
  description: 'Please enter a date to export a report for all Revelex payments due up to and including that date',
  type: 'object',
  properties: {
    endAt: {
      type: 'string',
      format: 'date',
      title: 'Select Date',
    },
  },
};

const formData = {
  format: 'email',
  endAt: getDateStartOf('month'),
};

const uiSchema = {
  endAt: { 'ui:widget': 'date' },
};

const ExportRevelexPayableForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    const { endAt, format } = event.formData;
    const data = queueRevelexPayableReport({ endAt, format });

    data
      .then(() => {
        enqueueSnackbar(`Your report is queued for delivery and will be emailed shortly.`, { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  return (
    <Box>
      <Form
        schema={formSchema}
        formData={formData}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={handleSubmit}
        className="package-form"
      >
        <Button type="submit" variant="contained">
          Email Cruise Payable Report
        </Button>
      </Form>
    </Box>
  );
};

export default ExportRevelexPayableForm;
