import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerOfferVisibilities from '~/actions/customerCommunication/fetchHeroPlannerOfferVisibilities';

import { useAppSelector } from '../store';

function useHeroPlannerOfferVisibilities(countryGroupId: string, countryId: string, offerId: string) {
  const brand = useAppSelector((state) => state.tenant.brand);
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerOfferVisibilities> = [brand, countryGroupId, countryId, offerId];
    return [getRecordKeyFor(...params), params];
  }, [brand, countryGroupId, countryId, offerId]);
  const offerVisibilitiesReq = useAppSelector((state) => state.customerCommunication.offerVisibilitiesRecords[key]);
  const appDispatch = useDispatch();
  const fetchOfferVisibilities = useCallback(() => {
    appDispatch(fetchHeroPlannerOfferVisibilities(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchOfferVisibilities();
  }, [fetchOfferVisibilities]);

  return { offerVisibilitiesReq, fetchOfferVisibilities };
}

export default useHeroPlannerOfferVisibilities;
