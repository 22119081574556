import React, { memo } from 'react';

import Spinner from '../../../Common/Spinner';

import ToggleButton from './ToggleButton';

interface Props {
  inclusions?: Array<App.TourV2Inclusion>;
}

const TourInclusions = ({ inclusions }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ToggleButton title="Inclusions" isOpen={isOpen} handleClick={handleClick}>
      {inclusions?.length ? (
        <div className="section inclusions bedbank-fineprint">
          The following are included in this package:
          {inclusions?.map((item, index) => (
            <div className="section inclusions bedbank-fineprint" key={index}>
              <div className="bedbank-fineprint-value">{item.title}</div>
              {item.items.map((inclusionItem, index) => (
                <li key={index}>{inclusionItem}</li>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </ToggleButton>
  );
};

export default memo(TourInclusions);
