import React, { useEffect, useState } from 'react';

import { Paper } from '@mui/material';
import type { GridFilterItem, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { GridSortItem } from '@mui/x-data-grid';

import { getFlightDeals } from '~/services/FlightsService';

import FlightDealsForm from '../Common/Forms/FlightDealsForm';

import MerchandisingFlightDealsTable from './MerchandisingFlightDealsTable';
import { FLIGHT_DEALS_LIST_ATTRIBUTES } from './constants';

export const MerchandisingFlightDeals = () => {
  const sizePerPage = 20;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(undefined);
  const [sort, setSort] = useState(undefined);

  const fetchTableData = async () => {
    const filterValues = getFilterValues(filters);
    const sortValues = getSortValues(sort);

    try {
      const response = await getFlightDeals({
        page: page + 1,
        pageSize: sizePerPage,
        attributes: FLIGHT_DEALS_LIST_ATTRIBUTES,
        ...(filterValues !== undefined ? { filter: filterValues } : {}),
        ...(sortValues !== undefined ? { orderBy: sortValues } : {}),
      });

      setLoading(false);
      setError(null);
      setTableData(response.result);
    } catch (err) {
      setLoading(false);
      setError(err.message);
      setTableData({ rows: [], count: 0 });
    }
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDelete = () => {
    fetchTableData();
  };

  const getFilterValues = (filterModel: GridFilterModel) => {
    const item: GridFilterItem | undefined = filterModel?.items[0];

    if (item === undefined || typeof item.field !== 'string') {
      return;
    }

    if (typeof item.value === 'string') {
      return { column: item.field, value: item.value };
    } else if (item.value instanceof Date) {
      const value = item.value.toISOString();
      if (item.field === 'sales_period' && item.operator === 'onOrAfter') {
        return { column: 'sales_start_date', value };
      } else if (item.field === 'sales_period') {
        return { column: 'sales_end_date', value };
      } else if (item.field === 'travel_period' && item.operator === 'onOrAfter') {
        return { column: 'travel_start_date', value };
      } else if (item.field === 'travel_period') {
        return { column: 'travel_end_date', value };
      }
    }
  };

  const getSortValues = (sortModel: GridSortModel) => {
    const item: GridSortItem = sortModel?.[0];
    if (item === undefined || typeof item.field !== 'string') {
      return;
    }
    const sort = item.sort ?? 'desc';
    let field;
    switch (item.field) {
      case 'sales_period':
        field = 'sales_start_date';
        break;
      case 'travel_period':
        field = 'travel_start_date';
        break;
      default:
        field = item.field;
        break;
    }
    return { column: field, sort };
  };

  useEffect(() => {
    async function fetchFromAPI() {
      setLoading(true);
      setError(null);
      await fetchTableData();
    }

    fetchFromAPI();
  }, [page, sort, filters]);

  if (error) {
    return <div className="alert alert-danger">Failed to flight deals data: {error}</div>;
  }

  return (
    <Paper className="branded-panel">
      <FlightDealsForm fetchTableData={fetchTableData} />
      <MerchandisingFlightDealsTable
        isLoading={loading}
        onPageChange={onPageChange}
        page={page}
        records={tableData.rows}
        total={tableData.count}
        onDelete={handleDelete}
        onFilterChange={setFilters}
        onSortChange={setSort}
        activeSorts={sort}
        activeFilters={filters}
      />
    </Paper>
  );
};

export default MerchandisingFlightDeals;
