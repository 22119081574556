import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { PromoMeta, getPromoMeta } from '~/services/PromoService';

export default function usePromoMeta() {
  const { enqueueSnackbar } = useSnackbar();
  const [promoMeta, setPromoMeta] = useState<PromoMeta>(null);
  const [isLoading, setLoading] = useState(true);

  const fetchPromoMetaData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPromoMeta();
      if (response.status === 200) {
        setPromoMeta(response.result);
      } else {
        enqueueSnackbar(JSON.stringify(response) || 'Failed to fetch promo meta', { variant: 'error' });
      }
    } catch (err) {
      if ('errors' in err && err.errors?.length > 0) {
        err.errors.forEach((e) => {
          enqueueSnackbar(`Error: ${e.path} "${e.value}" ${e.message}`, { variant: 'error', autoHideDuration: 5000 });
        });
      } else {
        enqueueSnackbar(`Error: ${JSON.stringify(err)}`, { variant: 'error', autoHideDuration: 5000 });
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchPromoMetaData();
  }, [fetchPromoMetaData]);

  return {
    promoMeta,
    isLoading,
    fetchPromoMetaData,
  };
}
