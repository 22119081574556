import React, { useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

import {
  OFFER_FACING_NAMES,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_TOUR,
} from '~/consts/offerTypes';

import { STATUS_APPROVED, STATUS_DRAFT } from './Constants';

interface Props {
  propsTemplate?: App.OfferTemplate;
  submit: (templateToUpsert: App.OfferTemplate) => void;
}

const emptyTemplate: App.OfferTemplate = {
  content: '',
  created_at: '',
  description: '',
  id: 0,
  offer_type: undefined,
  status: undefined,
  template_type: undefined,
  title: '',
  updated_at: '',
};

export default function TemplateCreateContainer(props: Props) {
  const [currTemplate, setCurrTemplate] = useState<App.OfferTemplate>(
    props.propsTemplate ? props.propsTemplate : emptyTemplate,
  );
  const [formErrors, setFormErrors] = useState(null);

  function onChange(e) {
    setFormErrors(null);
    if (typeof e === 'string') {
      //workaround for MarkDownEditor
      currTemplate['content'] = e;
      return;
    }

    const { name, value } = e.target;

    if (name === 'template_type' && value !== 'location' && currTemplate.offer_type === 'all') {
      setFormErrors({
        ...formErrors,
        ['offer_type']: "Value 'All' can be used only with Locaton template type",
      });
    }
    setCurrTemplate({ ...currTemplate, [name]: value });
  }

  function onSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }

    props.submit(currTemplate);
  }

  function createOfferTypeOption(offerType: string) {
    return <MenuItem value={offerType}>{OFFER_FACING_NAMES[offerType]}</MenuItem>;
  }

  const RequiredField = ({ children }) => {
    return (
      <>
        {children}
        <Typography color="error" display="inline">
          {' '}
          *
        </Typography>
      </>
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel htmlFor="template_title">
          <RequiredField>Template title</RequiredField>
        </InputLabel>
        <OutlinedInput
          id="template_title"
          label="Template title"
          placeholder="Enter name of template"
          name="title"
          value={currTemplate.title}
          onChange={onChange}
          required
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id="template_type-label">
          <RequiredField>Template Type </RequiredField>
        </InputLabel>
        <Select
          labelId="template_type-label"
          id="template_type"
          label="Template Type"
          name="template_type"
          onChange={onChange}
          value={currTemplate.template_type}
          required
        >
          {!currTemplate.template_type && <MenuItem disabled value={''}></MenuItem>}
          <MenuItem value="location">Location</MenuItem>
          <MenuItem value="fine_print">Fine print</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id="offer_type-label">
          <RequiredField>Offer Type </RequiredField>
        </InputLabel>
        <Select
          error={!!formErrors?.['offer_type']}
          labelId="offere_type-label"
          id="offer_type"
          label="Offer Type"
          name="offer_type"
          onChange={onChange}
          value={currTemplate.offer_type}
          required
        >
          {!currTemplate.offer_type && <MenuItem disabled value={''}></MenuItem>}
          {createOfferTypeOption(OFFER_TYPE_HOTEL)}
          {createOfferTypeOption(OFFER_TYPE_TOUR)}
          {createOfferTypeOption(OFFER_TYPE_LAST_MINUTE_HOTEL)}
          {createOfferTypeOption(OFFER_TYPE_TACTICAL_AO_HOTEL)}
          <MenuItem
            disabled={currTemplate.template_type !== 'location'}
            value={currTemplate.template_type === 'location' ? 'all' : ''}
          >
            All offer types (location template only)
          </MenuItem>
        </Select>
        {!!formErrors?.['offer_type'] && (
          <FormHelperText id="offer_type_error">{formErrors['offer_type']}</FormHelperText>
        )}
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <TextField
          fullWidth
          id="template-description"
          label="Template description"
          placeholder="Enter description of template"
          name="description"
          value={currTemplate.description}
          onChange={onChange}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <Box>
          <label htmlFor="markdown-label">
            <Typography variant="body1">
              <RequiredField>Template content</RequiredField>
            </Typography>
          </label>
        </Box>
        <MarkdownEditor id="markdown-label" value={currTemplate.content} onChange={onChange}></MarkdownEditor>
      </FormControl>
      <FormControl sx={{ mt: 2 }} fullWidth>
        <InputLabel id="offer_type-label">
          <RequiredField>Status </RequiredField>
        </InputLabel>
        <Select
          labelId="status-label"
          id="status"
          label="Status"
          name="status"
          onChange={onChange}
          value={currTemplate.status}
          required
        >
          {!currTemplate.status && <MenuItem disabled value={''}></MenuItem>}
          <MenuItem value={STATUS_DRAFT}>Draft</MenuItem>
          <MenuItem value={STATUS_APPROVED}>Approved</MenuItem>
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color={currTemplate.id === 0 ? 'primary' : 'warning'} sx={{ mt: 2 }}>
        {currTemplate.id === 0 ? 'Create' : 'Update'}
      </Button>
    </form>
  );
}
