export default (inclusionAsset: any = {}): any => ({
  id: inclusionAsset?.id,
  id_salesforce_external: inclusionAsset?.id_salesforce_external,
  vendor_salesforce_id: inclusionAsset?.vendor_salesforce_id,
  name: inclusionAsset?.name ?? '',
  salesforce_name: inclusionAsset?.salesforce_name ?? '',
  description: inclusionAsset?.description ?? '',
  currency: inclusionAsset?.currency ?? '',
  type: inclusionAsset?.type ?? 'Flash',
  category_id: inclusionAsset?.category_id || null,
  unit: inclusionAsset?.unit ?? '',
  rrp: inclusionAsset?.rrp ?? 0,
  is_hidden: inclusionAsset?.is_hidden ?? false,
  last_validated_on: inclusionAsset?.last_validated_on ?? '',
});
