import React, { useEffect, useState } from 'react';

import { Button, FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { regions } from '@luxuryescapes/lib-regions/lib/regions';

import Image from '~/components/Common/Image';

import { uploadImage } from '~/services/ImageService';
import SearchService, { TrendingDestinationsResponse } from '~/services/SearchService';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function DestinationImagePage() {
  const [destinations, setDestinations] = useState<TrendingDestinationsResponse[]>([]);
  const [region, setRegion] = useState<string>('AU');
  const [amount, setAmount] = useState<number>(50);

  useEffect(() => {
    async function getDestinations() {
      const res = await SearchService.getTrendingDestinations(region, 'luxuryescapes', amount, true);
      setDestinations(res.result);
    }
    getDestinations();
  }, [amount, region]);

  const onImageChange = async (placeId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    const res = await uploadImage(file);
    await SearchService.putDestinationImage([{ placeId, imageId: res.body.public_id as string }]);
    setDestinations((prev) => {
      return prev.map((destination) => {
        if (destination.placeId === placeId) {
          return { ...destination, imageId: res.body.public_id as string };
        }
        return destination;
      });
    });
  };

  return (
    <>
      <FormControl sx={{ minWidth: 160 }}>
        <InputLabel id="region-select-label">Region</InputLabel>
        <Select
          labelId="region-select-label"
          label="Region"
          value={region}
          onChange={(event) => setRegion(event.target.value)}
        >
          {regions['luxuryescapes'].map((region) => (
            <MenuItem key={region.code} value={region.code}>
              {region.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DataGrid
        rows={destinations}
        getRowId={(params) => params.placeId}
        getRowHeight={(params) => (params.model.imageId ? 150 : 50)}
        columns={[
          { field: 'primaryText', headerName: 'Destination', width: 300, display: 'flex' },
          { field: 'placeId', headerName: 'Place Id', width: 300, display: 'flex' },
          {
            field: 'imageId',
            headerName: 'Image',
            width: 300,
            renderCell: (params) => {
              return params.value ? <Image publicId={params.value} loading="lazy" /> : 'No Image';
            },
            display: 'flex',
          },
          {
            field: 'add',
            headerName: 'Add',
            width: 300,
            renderCell: (params) => {
              return (
                <Button component="label" variant="contained">
                  Upload Image
                  <VisuallyHiddenInput onChange={(event) => onImageChange(params.id as string, event)} type="file" />
                </Button>
              );
            },
            display: 'flex',
          },
        ]}
        hideFooterPagination
        hideFooterSelectedRowCount
      />
      {amount <= destinations.length && <Button onClick={() => setAmount((prev) => prev + 50)}>Load more</Button>}
    </>
  );
}
