import React from 'react';

import ExportCarHireBookingsForm from '../Common/Forms/ExportCarHireBookingsForm';
import ExportCarHirePaymentStatusForm from '../Common/Forms/ExportCarHirePaymentStatusForm';
import ExportCarHireRefundsReportForm from '../Common/Forms/ExportCarHireRefundsReportForm';

import ContentWithSidebar from './ContentWithSidebar';
import UploadCitibankStatements from './UploadCitibankStatements';

const DEFAULT_ITEMS = 'car-hire-bookings-report';

const items = [
  {
    slug: 'car-hire-bookings-report',
    title: 'Car Hire Bookings Report',
    component: ExportCarHireBookingsForm,
  },
  {
    slug: 'car-hire-payment-status',
    title: 'Car Hire Payment Status',
    component: ExportCarHirePaymentStatusForm,
  },
  {
    slug: 'car-hire-refunds-report',
    title: 'Car Hire Refunds Report',
    component: ExportCarHireRefundsReportForm,
  },
  {
    slug: 'upload-citibank-statement',
    title: 'Upload Citibank Statement',
    component: UploadCitibankStatements,
  },
];

export default function CarHirePage() {
  return <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEMS} />;
}
