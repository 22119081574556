import React from 'react';

import { Alert, Box } from '@mui/material';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getFlightDetailsByOrderAndItem, updateFlightDetails } from '~/services/OrdersService';

import OrderOfflineFlightItem from './OrderOfflineFlightItem';

class OrderOfflineFlightItemContainer extends React.Component {
  state = {
    offlineFlightDetails: null,
    error: null,
    successMessage: null,
  };

  async componentDidMount() {
    this.getFlightDetails();
  }

  updateTravellerRefundStatus = async (orderId, itemId, payload) => {
    try {
      await updateFlightDetails(orderId, itemId, payload);
      this.setState({
        successMessage: `Successfully updated traveller refund status to be ${payload.data.refund_status}`,
      });
    } catch (error) {
      this.setState({
        error: `There has been an error updating traveller refund status. Please try again. Error message: ${error.message}`,
        successMessage: null,
      });
    }
    this.getFlightDetails();
  };

  getFlightDetails = async () => {
    try {
      const offlineFlightDetails = await getFlightDetailsByOrderAndItem(
        this.props.item.fk_order_id,
        this.props.item.id,
      );
      this.setState({
        offlineFlightDetails,
      });
    } catch (error) {
      this.setState({
        error: `There has been an error retrieving the offline flight details. Please try again. Error message: ${error.message}`,
        successMessage: null,
      });
    }
  };

  render() {
    const statusSuccess = !this.state.error && this.state.offlineFlightDetails;
    const statusLoading = !this.state.error && !this.state.offlineFlightDetails;
    const statusError = this.state.error;

    return (
      <Box mt={2}>
        <FoldableSection title="Offline flights" initiallyExpanded>
          {statusLoading && <Spinner size={36} />}

          {statusError && <ErrorDisplay message={this.state.error} />}

          {this.state.successMessage && (
            <Box mb={1}>
              <Alert severity="success">{this.state.successMessage}</Alert>
            </Box>
          )}

          {statusSuccess && (
            <OrderOfflineFlightItem
              orderId={this.props.item.fk_order_id}
              itemId={this.props.item.id}
              offlineFlightDetails={this.state.offlineFlightDetails}
              updateOfflineFlightItem={this.updateOfflineFlightItem}
              refreshData={this.props.refreshData}
              updateTravellerRefundStatus={this.updateTravellerRefundStatus}
            />
          )}
        </FoldableSection>
      </Box>
    );
  }
}

export default OrderOfflineFlightItemContainer;
