import React, { ReactElement, ReactNode } from 'react';

import { Button, Popover } from '@mui/material';

export function ColumnControlForm({ children }: { children: ReactNode }): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ marginBottom: '1em' }}>
      <Button variant="contained" size="small" onClick={handleClick}>
        Show/Hide columns
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </div>
  );
}
