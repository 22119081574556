import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueRefundReport } from '~/services/ReportingService';
import { addMonths, diffDays, formatDateISO, startOfMonth } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

function validate(formData, errors) {
  if (typeof formData.start_at === 'undefined') {
    errors.start_at.addError('Please enter start at date');
  }
  if (typeof formData.end_at === 'undefined') {
    errors.end_at.addError('Please enter end at');
  }

  const startDate = new Date(formData.start_at);
  const endDate = new Date(formData.end_at);
  const idOffer = formData.idOffer;
  const leIdOffer = formData.leIdOffer;

  if (startDate >= endDate) {
    errors.start_at.addError('Start date should be before end date');
  } else if (diffDays(startDate, endDate, 'months') > 2 && !idOffer && !leIdOffer) {
    errors.start_at.addError('Dates range should be less than 3 months');
  }

  return errors;
}
const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    start_at: {
      title: 'Start at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(-1, startOfMonth())),
    },
    end_at: {
      title: 'End at',
      type: 'string',
      format: 'date',
      default: formatDateISO(addMonths(0, startOfMonth())),
    },
    idOffer: {
      title: 'Offer ID',
      type: 'string',
    },
    leIdOffer: {
      title: 'Le Offer ID',
      type: 'string',
    },
    paymentMethod: {
      title: 'Payment Method',
      type: 'string',
      anyOf: [
        {
          type: 'string',
          enum: [''],
          title: 'All',
        },
        {
          type: 'string',
          enum: ['Bank Transfer(EFT)'],
          title: 'Bank Transfer(EFT)',
        },
        {
          type: 'string',
          enum: ['Gross Payment'],
          title: 'Gross Payment',
        },
        {
          type: 'string',
          enum: ['VCC(Citibank)'],
          title: 'VCC(Citibank)',
        },
        {
          type: 'string',
          enum: ['Gross Payment VCC'],
          title: 'Gross Payment VCC',
        },
      ],
    },
    itemStatus: {
      title: 'Booking Status',
      type: 'string',
      anyOf: [
        {
          type: 'string',
          enum: [''],
          title: 'All',
        },
        {
          type: 'string',
          enum: ['completed'],
          title: 'Completed',
        },
        {
          type: 'string',
          enum: ['cancelled'],
          title: 'Cancelled',
        },
      ],
    },
  },
};

const uiSchema = {
  start: {
    'ui:widget': 'date',
  },
  end: {
    'ui:widget': 'date',
  },
  paymentMethod: {
    'ui:widget': 'radio',
    'ui:options': {
      inline: true,
    },
  },
  itemStatus: {
    'ui:widget': 'radio',
    'ui:options': {
      inline: true,
    },
  },
};

export default function ExportRefundsForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      await queueRefundReport(
        event.formData.start_at,
        event.formData.end_at,
        event.formData.idOffer,
        event.formData.leIdOffer,
        event.formData.paymentMethod,
        event.formData.itemStatus,
      );

      setSuccessMessage(true);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      customValidate={validate}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Email refunds report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
