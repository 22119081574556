import { AccountingMetaPayload, RefundAttributes } from '../NewModal';

export default function buildAccountingMetadataPayload(refundAttributes: RefundAttributes[]): AccountingMetaPayload[] {
  return refundAttributes.map((item: RefundAttributes) => {
    let difference = item.accounting_amount - item.cash_amount;

    if (item.fee) {
      difference = difference - item.fee;
    }

    return {
      source: item.source,
      cash_amount: String(item.cash_amount),
      accounting_amount: String(item.accounting_amount),
      charge_component_key: item.charge_component_key,
      refund_fee: (item.fee + difference).toFixed(2),
    };
  });
}
