import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';

import UserSearchForm from '~/components/Common/Forms/UserSearchForm';
import PrivilegedUserSearchResults from '~/components/Common/PrivilegedUserSearchResults';

import UsersService from '~/services/UsersService';

export default function UsersTab() {
  const location = useLocation<{ searchString?: string; searchQuerySubmit?: string }>();

  const [searchQuery, setSearchQuery] = useState(location.state?.searchQuerySubmit ?? '');
  const [searchString, setSearchString] = useState(location.state?.searchString ?? '');
  const [usersResult, setUsersResult] = useState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchString(e.target.value);
  };

  const handleSearchSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setSearchQuery(searchString);
  };

  useEffect(() => {
    if (searchQuery.length === 0 || searchQuery !== searchString) return;

    setLoading(true);

    UsersService.getAdminUsers(searchQuery)
      .then(setUsersResult)
      .catch(() => {
        enqueueSnackbar('Error fetching users', { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [searchQuery, searchString, enqueueSnackbar]);

  return (
    <div>
      <UserSearchForm
        searchString={searchString}
        handleChange={handleSearchTextChange}
        handleSubmit={handleSearchSubmit}
      />

      <PrivilegedUserSearchResults data={usersResult} searchQuerySubmit={searchQuery} loading={loading} />
    </div>
  );
}
