import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { ExtendedRegion } from '@luxuryescapes/lib-regions/lib/extended';

import {
  addPnr,
  addTravellerToPNR,
  changeCurrencyCode,
  removePnr,
  removeTravellerFromPNR,
  updatePNRId,
  updateTravellerDetails,
} from '../../../../actions/cart';
import { ITEM_TYPE_OFFLINE_FLIGHT } from '../../../../consts/order';
import FlightInformationFooter from '../FlightInformationFooter';

import BusinessTravellerBusiness from './BusinessTraveller/BusinessTravellerBusiness';
import PNRInformation from './PNRInformation';

const isTravellerInvalid = ({ firstName, lastName, price, title }) => !(firstName && lastName && price && title);
const isPNRInvalid = (PNR) => !PNR || PNR.length !== 6 || !/^[a-z0-9]+$/i.test(PNR);

const validateFlight = (flightItem) => {
  if (
    !flightItem ||
    !flightItem.pnrs ||
    !flightItem.pnrs.length ||
    !flightItem.price ||
    flightItem.type !== ITEM_TYPE_OFFLINE_FLIGHT
  ) {
    return false;
  }

  const pnrs = flightItem.pnrs;
  for (const pnr of pnrs) {
    if (isPNRInvalid(pnr.PNR) || !pnr.travellers.length) {
      return false;
    }
    for (const traveller of pnr.travellers) {
      if (isTravellerInvalid(traveller)) {
        return false;
      }
    }
  }

  return true;
};

interface Props {
  regions: ExtendedRegion[];
  backStep: () => void;
  cart: App.Cart;
  nextStepLabel: string;
  onProceedStep: () => void;
  onRemoveTravellerFromPNR: Utils.ActionDispatcher<typeof removeTravellerFromPNR>;
  onUpdateTravellerDetails: Utils.ActionDispatcher<typeof updateTravellerDetails>;
  onChangeCurrencyCode: Utils.ActionDispatcher<typeof changeCurrencyCode>;
  onAddPnr: Utils.ActionDispatcher<typeof addPnr>;
  onRemovePnr: Utils.ActionDispatcher<typeof removePnr>;
  onUpdatePNRId: Utils.ActionDispatcher<typeof updatePNRId>;
  onAddTravellerToPNR: Utils.ActionDispatcher<typeof addTravellerToPNR>;
}

function Component(props: Props) {
  const {
    regions,
    onAddPnr,
    onRemovePnr,
    backStep,
    cart,
    nextStepLabel,
    onProceedStep,
    onUpdateTravellerDetails,
    onRemoveTravellerFromPNR,
    onChangeCurrencyCode,
    onUpdatePNRId,
    onAddTravellerToPNR,
  } = props;
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const handleCurrencyChange = useCallback(
    (event: SelectChangeEvent) => {
      const currencyCode = regions[event.target.value]?.currencyCode;
      onChangeCurrencyCode(event.target.value, currencyCode);
    },
    [onChangeCurrencyCode, regions],
  );

  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={4}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h6">Currency: </Typography>
          <Select
            id="currency-selection"
            name="currency-selection"
            value={cart.regionCode}
            onChange={handleCurrencyChange}
          >
            {regions.map((region) => {
              return (
                <MenuItem key={region.code} value={region.code}>
                  {`${region.name} (${region.currencyCode})`}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        {brand === 'lebusinesstraveller' && <BusinessTravellerBusiness />}
      </Stack>

      {/* check to make sure the cart has been initialised with the PNR array */}
      {cart.items[0] && cart.items[0].pnrs && (
        <ul className="booking-information-list">
          {cart.items[0].pnrs.map((pnr, pnrIndex) => (
            <PNRInformation
              pnr={pnr}
              pnrs={cart.items[0].pnrs}
              key={`${pnrIndex}`}
              pnrIndex={pnrIndex}
              currencyCode={cart.currencyCode}
              onUpdatePNRId={onUpdatePNRId}
              onUpdateTravellerDetails={onUpdateTravellerDetails}
              onRemoveTravellerFromPNR={onRemoveTravellerFromPNR}
              onRemovePnr={onRemovePnr}
              onAddTravellerToPNR={onAddTravellerToPNR}
            />
          ))}
        </ul>
      )}
      <Box display="flex" justifyContent="flex-start" mb={6}>
        <Button
          variant="text"
          size="small"
          onClick={onAddPnr}
          classes={{
            root: 'T-quantity-plus-pnr', // Required for e2e test
          }}
          startIcon={<AddIcon />}
        >
          Add PNR
        </Button>
      </Box>

      <FlightInformationFooter
        cart={cart}
        proceedStep={onProceedStep}
        canProceed={validateFlight(cart.items[0])}
        backStep={backStep}
        nextStepLabel={nextStepLabel}
      />
    </Stack>
  );
}

export default Component;
