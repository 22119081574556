import React from 'react';

import { Dayjs } from 'dayjs';
import dayjs from '~/timeInit';

import { DateTimePicker } from '@mui/x-date-pickers-pro';

type Props = {
  value: string;
  onChange: (value: string) => void;
  label?: string | null;
  defaultTimezone?: string;
  showDateOnly?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  format?: string;
  disabled?: boolean;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  timeSteps?: { hours?: number; minutes?: number; seconds?: number };
};

export default function DateTimeWidget(props: Props) {
  const {
    defaultTimezone = window.configs.DEFAULT_TIMEZONE,
    value,
    label,
    onChange,
    showDateOnly,
    disablePast,
    disableFuture,
    format,
    disabled,
    maxDate,
    minDate,
    timeSteps = { hours: 1, minutes: 5 },
  } = props;

  const widgetLabel = label ? `${label} (${defaultTimezone})` : defaultTimezone;
  const widgetValue = dayjs(dayjs(value).tz(defaultTimezone).format()).tz(defaultTimezone);

  const handleChange = (newValue: Dayjs | null) => {
    const value = newValue?.tz(defaultTimezone).format();
    onChange(value);
  };

  return (
    <DateTimePicker
      format={format}
      label={widgetLabel}
      value={widgetValue}
      onChange={handleChange}
      disablePast={disablePast}
      disableFuture={disableFuture}
      views={showDateOnly ? ['year', 'month', 'day'] : undefined}
      slotProps={{ textField: { fullWidth: true } }}
      maxDate={maxDate}
      minDate={minDate}
      disabled={disabled}
      timeSteps={timeSteps}
    />
  );
}
