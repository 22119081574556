import styled from 'styled-components';

import { themes } from '../../../../consts/experiences';
import { Spacing, margin } from '../../helpers';

type TagProps = {
  color?: string;
  outline?: boolean;
  minWidth?: string;
  maxWidth?: string;
  background?: string;
  selectable?: boolean;
} & Spacing;

export const TagWrapper = styled.div<TagProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: ${({ minWidth }) => minWidth ?? 'none'};
  max-width: ${({ maxWidth }) => maxWidth ?? 'none'};

  cursor: pointer;
  padding: 4px 12px;
  border-radius: 20px;

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  background-color: ${({ outline, background }) => (!outline ? background ?? themes?.primaryBlue : 'transparent')};

  border: 1px solid
    ${({ outline, color, background }) => (outline ? color ?? themes?.primaryBlue : background ?? themes?.primaryBlue)};

  :hover {
    opacity: 0.8;

    background-color: ${({ selectable, background }) => selectable && (background ?? themes?.primaryBlue)};

    > span {
      color: ${({ selectable, color }) => selectable && (color ?? themes?.white)};
    }
  }

  > svg {
    margin-right: 8px;

    > path {
      fill: ${({ selectable, background }) =>
        !selectable ? themes?.white : background || themes?.primaryBlue} !important;
    }
  }

  > span {
    margin: 0;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: ${({ outline, color }) => (!outline ? color ?? themes?.white : color ?? themes?.primaryBlue)};
  }
`;
