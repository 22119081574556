import { dateNow } from '~/services/TimeService';

function getAbsoluteMonths(date: Date) {
  const months = date.getMonth() + 1;
  const years = date.getFullYear();

  return months + years * 12;
}

// getPureMonthDiff returns a positive integer denoting the difference in month
// between two dates. The difference is purely based on months and not on days,
// and hence there is no rounding up
export default function getPureMonthDiff(date1, date2) {
  const d1 = dateNow(date1);
  const d2 = dateNow(date2);

  const d1Months = getAbsoluteMonths(d1);
  const d2Months = getAbsoluteMonths(d2);

  return Math.abs(d1Months - d2Months);
}
