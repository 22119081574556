import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';

import UsersService from '~/services/UsersService';

const columns: GridColDef[] = [
  {
    field: 'inline_role_label',
    headerName: 'Role',
    flex: 2,
    sortable: false,
    display: 'flex',
  },
  {
    field: 'roles',
    headerName: 'Role slug',
    flex: 1,
    sortable: false,
    display: 'flex',
  },
];

export default function RolesTab() {
  const [isLoading, setLoading] = useState(false);
  const [roles, setRoles] = useState<App.Role[]>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleRowClick: GridEventListener<'rowClick'> = ({ row }: { row: App.Role }) => {
    history.push({
      pathname: '/system/permissions/' + row.roles,
      state: { inline_role_label: row.inline_role_label },
    });
  };

  useEffect(() => {
    setLoading(true);
    UsersService.getRoles()
      .then((roles) => {
        setRoles(roles.result);
      })
      .catch(() => {
        enqueueSnackbar('Failed to get roles', { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar]);

  return (
    <div>
      <DataGrid
        columns={columns}
        rows={roles || []}
        loading={isLoading}
        getRowId={(row) => row.roles}
        onRowClick={handleRowClick}
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
    </div>
  );
}
