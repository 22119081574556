import React from 'react';

import { Helmet } from 'react-helmet';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import TourLegForm from '../../Common/Forms/TourLegForm';

export default class TourLegsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.vendorId = props.vendorId;
    this.vendor = props.vendor;
    this.tourId = props.tourId;
    this.tour = props.tour;
    this.schema = props.schema;

    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.createTourLegForm = this.createTourLegForm.bind(this);

    var self = this;
    const tourLegForms = props.tourLegs.map(function (tourLeg, i) {
      return self.createTourLegForm(`${i}`, tourLeg);
    });

    this.state = {
      tourLegForms: tourLegForms,
    };
  }

  nextFormKey() {
    if (this.state.tourLegForms.length === 0) {
      return 0;
    }
    return `${Math.max(...this.state.tourLegForms.map((form) => parseInt(form.key))) + 1}`;
  }

  handleAddNew() {
    const forms = this.state.tourLegForms;
    const tourLeg = { tour_id: this.tourId };

    forms.push(this.createTourLegForm(this.nextFormKey(), tourLeg));

    this.setState({
      tourLegForms: forms,
    });
  }

  handleRemove(key) {
    const forms = this.state.tourLegForms.filter(function (tourLegForm) {
      return key !== tourLegForm.key;
    });

    this.setState({
      tourLegForms: forms,
    });
  }

  createTourLegForm(key, tourLeg) {
    return (
      <Box key={key}>
        <TourLegForm
          formKey={key}
          tourLeg={tourLeg}
          schema={this.schema}
          handleRemove={this.handleRemove}
          handleSave={this.handleSave}
        />
      </Box>
    );
  }

  render() {
    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>
            Vendors | {this.vendor?.name || this.vendorId} | {this.tour?.name || this.tourId} | Edit Tour Legs
          </title>
        </Helmet>

        <PageHeader title="Edit tour legs" backButton={`/vendors/${this.vendorId}/tours/${this.tourId}`} />

        <div className="tour-legs-edit-section">{this.state.tourLegForms}</div>

        <Box>
          <Button variant="contained" startIcon={<AddIcon />} onClick={this.handleAddNew}>
            Add
          </Button>
        </Box>
      </Container>
    );
  }
}
