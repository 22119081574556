import { orderSelector } from '../selectors/orderSelector';
import { getOrder, updateOrder } from '../services/OrdersService';
import { reportError } from '../utils/reportError';

import { fetchUserData } from './user';

export const FETCH_ORDER_START = 'order/FETCH_ORDER_START';
export const FETCH_ORDER_SUCCEED = 'order/FETCH_ORDER_SUCCEED';
export const FETCH_ORDER_FAIL = 'order/FETCH_ORDER_FAIL';

export const UPDATE_ORDER_START = 'order/UPDATE_ORDER_START';
export const UPDATE_ORDER_SUCCEED = 'order/UPDATE_ORDER_SUCCEED';
export const UPDATE_ORDER_FAIL = 'order/UPDATE_ORDER_FAIL';

function startFetchingOrder() {
  return { type: FETCH_ORDER_START };
}

function succeedFetchingOrder(order) {
  return { type: FETCH_ORDER_SUCCEED, order };
}

function failFetchingOrder(error) {
  return { type: FETCH_ORDER_FAIL, error };
}

function startSubmitUpdates() {
  return { type: UPDATE_ORDER_START };
}

function succeedSubmitUpdates() {
  return { type: UPDATE_ORDER_SUCCEED };
}

function failSubmitUpdates(error) {
  return { type: UPDATE_ORDER_FAIL, error };
}

export function fetchOrderData(orderId) {
  return (dispatch) => {
    dispatch(startFetchingOrder());
    return getOrder(orderId)
      .then(({ result }) => {
        dispatch(succeedFetchingOrder(result));
      })
      .catch((error) => {
        reportError(error);
        dispatch(failFetchingOrder(error));
      });
  };
}

export function fetchOrderAndPurchaserData(orderId) {
  return (dispatch, getState) => {
    return dispatch(fetchOrderData(orderId)).then(() => {
      const order = orderSelector(getState(), orderId);
      const purchaserId = order.fk_purchaser_id;
      return dispatch(fetchUserData(purchaserId));
    });
  };
}

export function submitUpdatesForm(orderId, updates, history) {
  return (dispatch) => {
    dispatch(startSubmitUpdates());
    return updateOrder(orderId, updates)
      .then(() => {
        return dispatch(fetchOrderData(orderId));
      })
      .then(() => {
        dispatch(succeedSubmitUpdates());
        history.push(`/purchases/${orderId}`);
      })
      .catch((error) => {
        reportError(error);
        dispatch(failSubmitUpdates(error));
      });
  };
}
