import React from 'react';

import { brands } from '../../../consts/brands';
import OffersService from '../../../services/OffersService';
import shouldMigrateScheduleData from '../../../utils/shouldMigrateScheduleData';
import { buttonStates } from '../../Common/Forms/states/submitButton';

import NewSchedulePageEdit from './NewSchedulePageEdit';

export default class NewScheduleContainer extends React.Component {
  state = {
    schedules: [],
    isFetched: false,
    isMigratingData: false,
    migrationError: null,
    migrationButtonState: buttonStates.default,
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id_offer: offerId } = match.params;
    const response = await OffersService.getOffer(offerId);
    const shouldMigrateSchedule = shouldMigrateScheduleData(
      response.result.brand_schedules,
      brands.map((brand) => brand.value),
    );
    this.setState({
      offerName: response.result.name,
      schedules: response.result.brand_schedules,
      isFetched: true,
      shouldMigrateSchedule,
    });
  }

  migrateScheduleData = async () => {
    const sure = confirm('This button clears all schedules. Are you sure you want to proceed?');

    if (!sure) {
      return;
    }

    this.setState({
      isMigratingData: true,
      migrationError: null,
      migrationButtonState: buttonStates.default,
    });
    const { match } = this.props;
    const { id_offer: offerId } = match.params;
    let migrationError;
    let migrationButtonState = buttonStates.default;
    const brandValueList = brands.map((brand) => brand.value);
    try {
      await OffersService.migrateScheduleData({ brands: brandValueList }, offerId);
    } catch (error) {
      migrationError = error.name;
    }
    const response = await OffersService.getOffer(offerId);
    const shouldMigrateSchedule = shouldMigrateScheduleData(response.result.brand_schedules, brandValueList);

    if (shouldMigrateSchedule && migrationError) {
      migrationButtonState = buttonStates.failed;
    } else {
      migrationError = null;
    }
    this.setState({
      shouldMigrateSchedule,
      schedules: response.result.brand_schedules,
      isMigratingData: false,
      migrationError,
      migrationButtonState,
    });
  };

  render() {
    const {
      isFetched,
      offerName,
      schedules,
      shouldMigrateSchedule,
      isMigratingData,
      migrationError,
      migrationButtonState,
    } = this.state;
    const { match } = this.props;
    const { id_offer: offerId } = match.params;
    return (
      isFetched && (
        <NewSchedulePageEdit
          offerName={offerName}
          schedules={schedules}
          offerId={offerId}
          shouldMigrateSchedule={shouldMigrateSchedule}
          migrateScheduleData={this.migrateScheduleData}
          isMigratingData={isMigratingData}
          migrationError={migrationError}
          migrationButtonState={migrationButtonState}
        />
      )
    );
  }
}
