import React, { useState } from 'react';

import * as Sentry from '@sentry/browser';

import { Button, Stack, TextField, Typography } from '@mui/material';

type MessageSenderProps = {
  feedbackType: string;
  placeholderText?: string;
  description: string;
};
const SentryFeedbackForm: React.FC<MessageSenderProps> = ({ feedbackType, description, placeholderText }) => {
  const [message, setMessage] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (message.trim()) {
      Sentry.captureMessage(`FEEDBACK: ${feedbackType}: ${message}`, 'info');
      setMessage('');
      setSubmitted(true);
    }
  };

  if (submitted) {
    return <p>Thanks for your feedback 😃</p>;
  }

  return (
    <Stack direction="column" spacing={2} alignItems="left">
      <Typography variant="h6">{description}</Typography>
      <TextField
        id="name"
        data-testid="name"
        type="text"
        name="name"
        variant="outlined"
        label={placeholderText ?? 'Feedback'}
        onChange={(e) => setMessage(e.target.value)}
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
        Send Feedback
      </Button>
    </Stack>
  );
};

export default SentryFeedbackForm;
