import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { Helmet } from 'react-helmet';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Paper } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import TourOptionForm from '~/components/Common/Forms/TourOptionForm';

type Props = {
  tourId: string;
  tour: any;
  tourOptions: any[];
  tourOptionSchema: RJSFSchema;
  tourLegs: any[];
  vendorId: string;
  vendor: App.Vendor;
};

type State = {
  tourOptionForms: any[];
};

export default class TourOptionsEdit extends React.Component<Props, State> {
  vendorId: string;
  vendor: App.Vendor;
  tourId: string;
  tour: any;
  tourOptionSchema: RJSFSchema;
  tourLegs: any;
  handleSave: any;

  constructor(props) {
    super(props);
    this.vendorId = props.vendorId;
    this.vendor = props.vendor;
    this.tourId = props.tourId;
    this.tour = props.tour;
    this.tourOptionSchema = props.tourOptionSchema;
    this.tourLegs = props.tourLegs;

    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.createTourOptionForm = this.createTourOptionForm.bind(this);

    const tourOptionForms = props.tourOptions.map((tourOption, i) => this.createTourOptionForm(`${i}`, tourOption));

    this.state = {
      tourOptionForms: tourOptionForms,
    };
  }

  nextFormKey() {
    if (this.state.tourOptionForms.length === 0) {
      return 0;
    }
    return `${Math.max(...this.state.tourOptionForms.map((form) => parseInt(form.key))) + 1}`;
  }

  handleAddNew() {
    const forms = this.state.tourOptionForms;
    const tourOption = { tour_id: this.tourId };

    forms.push(this.createTourOptionForm(this.nextFormKey(), tourOption));

    this.setState({
      tourOptionForms: forms,
    });
  }

  handleRemove(key) {
    const forms = this.state.tourOptionForms.filter((tourOptionForm) => key !== tourOptionForm.key);

    this.setState({
      tourOptionForms: forms,
    });
  }

  createTourOptionForm(key, tourOption) {
    return (
      <Paper variant="outlined" key={key}>
        <Box px={2} pb={2}>
          <TourOptionForm
            formKey={key}
            tourOption={tourOption}
            tourLegs={this.tourLegs}
            tourOptionSchema={this.tourOptionSchema}
            handleRemove={this.handleRemove}
            handleSave={this.handleSave}
          />
        </Box>
      </Paper>
    );
  }

  render() {
    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>
            Vendors | {this.vendor?.name || this.vendorId} | {this.tour?.name || this.tourId} | Edit Tour Options
          </title>
        </Helmet>

        <PageHeader title="Edit tour options" backButton={`/vendors/${this.vendorId}/tours/${this.tourId}`} />

        <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
          {this.state.tourOptionForms}
        </Box>

        <Box mt={2}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={this.handleAddNew}>
            Add
          </Button>
        </Box>
      </Container>
    );
  }
}
