import React from 'react';

import { Control } from 'react-hook-form';
import { theme } from '~/theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Stack, Typography } from '@mui/material';

import { Select } from '~/components/Common/Forms/fields';

import { OFFER_FACING_NAMES } from '~/consts/offerTypes';

interface Props {
  control: Control<any>;
  schema: any;
}

const GeneralSettingsAccordion = ({ control, schema }: Props) => {
  return (
    <Accordion defaultExpanded disableGutters>
      <AccordionSummary
        aria-controls="general-settings"
        expandIcon={<ExpandMoreIcon />}
        id="general-settings"
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Typography variant="h5">General Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack mt={2} spacing={2}>
          <Select control={control} name="type" label="Type">
            {schema.properties.type.enum.map((type) => (
              <MenuItem key={type} value={type}>
                {OFFER_FACING_NAMES[type]}
              </MenuItem>
            ))}
          </Select>
          <Select control={control} name="status" label="Status">
            {schema.properties.status.enum.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default GeneralSettingsAccordion;
