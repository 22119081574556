import React from 'react';

import ExportBedBankBookingsForm from '../Common/Forms/ExportBedBankBookingsForm';
import ExportBedbankPaymentsReportForm from '../Common/Forms/ExportBedbankPaymentsReportForm';
import ExportBedbankRefundReportForm from '../Common/Forms/ExportBedbankRefundReportForm';
import ExportExpediaMarketingFeesReportForm from '../Common/Forms/ExportExpediaMarketingFeesReportForm';
import UploadBedBankPaymentsForm from '../Common/Forms/finance/bedbank/UploadBedbankPaymentsForm';

import ContentWithSidebar from './ContentWithSidebar';
import UploadExpediaMarketingFees from './UploadExpediaMarketingFees';
import UploadExpediaPayments from './UploadExpediaPayments';

const DEFAULT_ITEM = 'bedbank-bookings';

const items = [
  {
    title: 'Bedbank Bookings',
    slug: 'bedbank-bookings',
    component: ExportBedBankBookingsForm,
  },
  {
    title: 'Bedbank Refunds report',
    slug: 'bedbank-refunds',
    component: ExportBedbankRefundReportForm,
  },
  {
    title: 'Upload Bedbank Payments',
    slug: 'upload-bedbank-payments',
    component: UploadBedBankPaymentsForm,
  },
  {
    title: 'Bedbank Payments report',
    slug: 'bedbank-payments',
    component: ExportBedbankPaymentsReportForm,
  },
  {
    title: 'Expedia Marketing Fees report',
    slug: 'expedia-marketing-fees',
    component: ExportExpediaMarketingFeesReportForm,
  },
  {
    title: 'Upload Expedia Payments',
    slug: 'upload-expedia-payments',
    component: UploadExpediaPayments,
  },
  {
    title: 'Upload Expedia Marketing Fees Statement',
    slug: 'upload-expedia-marketing-fees',
    component: UploadExpediaMarketingFees,
  },
];

export default function BedBankPage() {
  return <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEM} />;
}
