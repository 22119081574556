import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

export default function Inclusions({ facilities }) {
  return (
    <List disablePadding dense>
      {facilities.map((facility) => (
        <ListItem key={facility} disableGutters>
          <ListItemIcon sx={{ minWidth: 24 }}>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={facility} primaryTypographyProps={{ variant: 'body2' }} />
        </ListItem>
      ))}
    </List>
  );
}
