import React, { ComponentProps, FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { getBusinessAdminSpoofingLink } from '~/services/BusinessTraveller/BusinessTravellerService';

const INPUT_PROPS: ComponentProps<typeof TextField>['inputProps'] = { readOnly: true };

type SpoofingLinkState =
  | { status: 'pending' }
  | { status: 'success'; value: string }
  | { status: 'failed'; error: string };

interface Props {
  user: App.User;
}

function BusinessAdminSpoofButton(props: Props) {
  const { user } = props;
  const textFieldRef = useRef<HTMLInputElement>(null);
  const copiedTooltipTimeout = useRef<NodeJS.Timeout>();
  const { isToggled: isModalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggleState(false);
  const {
    isToggled: isCopiedTooltipOpen,
    toggleOn: openCopiedTooltip,
    toggleOff: closeCopiedTooltip,
  } = useToggleState(false);
  const [spoofingLinkState, setSpoofingLinkState] = useState<SpoofingLinkState>({ status: 'pending' });

  const generateSpoofingLink = useCallback(async () => {
    setSpoofingLinkState({ status: 'pending' });
    try {
      const spoofingLink = await getBusinessAdminSpoofingLink({ customerId: user.id_member });
      setSpoofingLinkState({ status: 'success', value: spoofingLink });
    } catch (error) {
      setSpoofingLinkState({ status: 'failed', error: String(error.message ?? error) });
    }
  }, [user.id_member]);

  const triggerCopiedTooltip = useCallback(() => {
    openCopiedTooltip();
    copiedTooltipTimeout.current = setTimeout(() => {
      closeCopiedTooltip();
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTextFieldText = useCallback<FocusEventHandler>(() => {
    textFieldRef.current?.select();
  }, []);

  const copySpoofingLinkToClipboard = useCallback(() => {
    if (spoofingLinkState.status === 'success') {
      navigator.clipboard.writeText(spoofingLinkState.value);
      triggerCopiedTooltip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spoofingLinkState]);

  const handleModalOpen = useCallback(() => {
    closeCopiedTooltip();
    generateSpoofingLink();
    openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateSpoofingLink]);

  useEffect(() => {
    return () => {
      if (copiedTooltipTimeout.current) {
        clearTimeout(copiedTooltipTimeout.current);
      }
    };
  }, []);

  return (
    <>
      <Button variant="contained" color="success" onClick={handleModalOpen}>
        Business admin spoofing link
      </Button>
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>
          Spoofing link for business admin <em>{user.email}</em>
        </DialogTitle>
        <DialogContent>
          {spoofingLinkState.status === 'pending' && (
            <Stack direction="column" alignItems="center" justifyContent="center">
              <CircularProgress />
              <Typography variant="overline">Generating link...</Typography>
            </Stack>
          )}

          {spoofingLinkState.status === 'success' && (
            <Stack direction="column" gap={2}>
              <Alert severity="warning">
                <AlertTitle>Caution!</AlertTitle>
                Only use the link in <b>private/incognito</b> browser mode.
              </Alert>
              <TextField
                inputRef={textFieldRef}
                inputProps={INPUT_PROPS}
                label="Spoofing link"
                defaultValue={spoofingLinkState.value}
                multiline
                autoFocus
                onFocus={selectTextFieldText}
              />
            </Stack>
          )}

          {spoofingLinkState.status === 'failed' && (
            <Alert
              severity="error"
              action={
                <Button color="inherit" size="small" onClick={generateSpoofingLink}>
                  Retry
                </Button>
              }
            >
              {spoofingLinkState.error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Dismiss</Button>
          <Tooltip title="Copied to clipboard" open={isCopiedTooltipOpen} placement="top">
            <Button
              variant="contained"
              disabled={spoofingLinkState.status !== 'success'}
              onClick={copySpoofingLinkToClipboard}
            >
              Copy to clipboard
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BusinessAdminSpoofButton;
