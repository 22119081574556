import React, { ReactElement } from 'react';

import { marked } from 'marked';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type Props = {
  offer: App.Offer;
  onClose: () => void;
  open: boolean;
};

export function FinePrintModal({ offer, open, onClose }: Props): ReactElement {
  return (
    <Dialog open={open} onClose={onClose} scroll="paper" maxWidth="md" fullWidth>
      <DialogTitle>Fine Print</DialogTitle>

      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: marked.parse(offer.fine_print as string) }} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
