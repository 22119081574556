import React, { FormEvent, useRef, useState } from 'react';

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import { STATUS, VENDORS } from '~/components/Cruises/constants';
import { useCruiseOffers } from '~/components/Cruises/context/useCruiseOffers';

import { formatDateISO } from '~/services/TimeService';

const CruiseSearch: React.FC = () => {
  const [date, setDate] = useState<string>(null);

  const formRef = useRef(null);
  const { filter, setFilter } = useCruiseOffers();

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    const form = formRef.current;
    event.preventDefault();

    setFilter({
      id: form['offerId']?.value,
      name: form['offerName']?.value,
      status: form['offerStatus']?.value,
      shipName: form['shipName']?.value,
      rateCode: form['rateCode']?.value,
      cruiseLine: form['cruiseLine']?.value,
      marketName: form['marketName']?.value,
      departureDate: date && formatDateISO(new Date(date)),
      departureExternalId: form['departureExternalId']?.value,
    });
  };

  return (
    <Box
      mt={5}
      sx={{
        borderRadius: '8px',
        padding: '32px 24px',
        backgroundColor: '#fafafa',
        border: '1px solid #f2f2f2',
      }}
    >
      <form ref={formRef} onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid xs={4}>
            <TextField fullWidth name="offerId" label="Offer ID" />
          </Grid>

          <Grid xs={4}>
            <TextField fullWidth name="offerName" label="Offer Name" />
          </Grid>

          <Grid xs={4}>
            <FormControl fullWidth>
              <InputLabel>Offer Status</InputLabel>
              <Select fullWidth defaultValue={filter.status} label="Offer Status" name="offerStatus">
                {STATUS.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={4}>
            <TextField fullWidth name="departureExternalId" label="Sailing ID" type="number" />
          </Grid>

          <Grid xs={4}>
            <FormControl fullWidth>
              <InputLabel>Cruise Lines</InputLabel>
              <Select fullWidth label="Cruise Line" name="cruiseLine">
                {VENDORS.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={4}>
            <TextField fullWidth name="rateCode" label="Rate Code" />
          </Grid>

          <Grid xs={4}>
            <TextField fullWidth name="shipName" label="Ship Name" />
          </Grid>

          <Grid xs={3}>
            <TextField fullWidth name="marketName" label="Market Name" />
          </Grid>

          <Grid xs={3}>
            <DateTimeWidget
              showDateOnly
              value={date}
              onChange={setDate}
              disablePast={false}
              format="DD/MM/YYYY"
              label="Departure Date"
            />
          </Grid>

          <Grid xs={2}>
            <Button fullWidth size="large" variant="contained" type="submit">
              Search Content
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CruiseSearch;
