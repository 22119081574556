import { EditorState, convertFromRaw } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';

// this is necessary because the editor will lose the
// cursor position when the value changes
// src: https://github.com/jpuri/react-draft-wysiwyg/issues/652#issuecomment-530366117
const createEditorState = (value, oldEditorState) => {
  const oldSelectionState = oldEditorState.getSelection();

  const rawData = markdownToDraft(value || '');
  const contentState = convertFromRaw(rawData);
  const newEditorState = EditorState.createWithContent(contentState);

  // update cursor position base on old selection state
  const updateSelection = newEditorState.getSelection().merge({
    anchorOffset: oldSelectionState.getAnchorOffset(),
    focusOffset: oldSelectionState.getFocusOffset(),
    isBackward: false,
  });

  // check whether editor on focus, if onfocus use forceSelection to manually update the position
  // if not on focus, use acceptSelection
  if (oldSelectionState.getHasFocus()) {
    return EditorState.forceSelection(newEditorState, newEditorState.getSelection().merge(updateSelection));
  }

  return EditorState.acceptSelection(newEditorState, newEditorState.getSelection().merge(updateSelection));
};

export { createEditorState };
