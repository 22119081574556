import React, { Fragment } from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

const bedbankCheckInFormatter = ({ row }: GridRenderCellParams<App.Order>) => {
  if (!row.bedbank_items.length) {
    return;
  }

  return (
    <Stack direction="column">
      {row.bedbank_items.map((item) => (
        <Fragment key={item.id}>
          {item.status === 'cancelled' && (
            <Typography sx={{ textDecoration: 'line-through' }}>{item.check_in ?? 'no date'}</Typography>
          )}
          {item.status !== 'cancelled' && <Typography>{item.check_in ?? 'no date'}</Typography>}
        </Fragment>
      ))}
    </Stack>
  );
};

export default bedbankCheckInFormatter;
