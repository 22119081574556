import React from 'react';

import { ExperiencesState } from '~/reducers/experiences';
import { theme } from '~/theme';

import {
  Box,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { FlexWrapper, Spinner } from '../../../components';

import ExperienceListRow from './ExperienceListRow';

type ContentProps = {
  experiences: ExperiencesState;
  currentPage: number;
  sizePerPage: number;
  experiencesTotal: number;
  handlePage: (_, page: number) => void;
  selectedExperiences: Experiences.Offer[];
  onAddExperience: (experience: Experiences.Offer) => void;
  onRemoveExperience: (experience: Experiences.Offer) => void;
};

const ExperiencesList: React.FC<ContentProps> = (props) => {
  const {
    experiences,
    handlePage,
    currentPage,
    sizePerPage,
    experiencesTotal,
    selectedExperiences,
    onAddExperience,
    onRemoveExperience,
  } = props;

  const columns: string[] = ['ID/Title', 'Provider', 'Sales Price', ''];
  const pageLength = Math.ceil(experiencesTotal / sizePerPage) ?? 1;

  return (
    <Box>
      <PageSubheader title="Experiences" />
      {experiences.loading ? (
        <FlexWrapper justify="center">
          <Spinner />
        </FlexWrapper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column}
                    sx={{
                      padding: 1,
                      fontSize: 16,
                      color: 'white',
                      backgroundColor: theme.palette.primary.dark,
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {experiences.items.map((exp) => (
                <ExperienceListRow
                  addOnclick={onAddExperience}
                  removeOnclick={onRemoveExperience}
                  exp={exp}
                  selectedExperiences={selectedExperiences}
                  key={exp.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Stack spacing={2} mt={2}>
        <Pagination
          hidePrevButton
          hideNextButton
          count={pageLength}
          page={currentPage}
          onChange={handlePage}
          color="primary"
        />
      </Stack>
    </Box>
  );
};

export default ExperiencesList;
