import React, { Fragment } from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

type CustomerOrder = {
  shipName?: string;
  vendorName?: string;
} & App.Order;

const cruiseOfferDetailsFormatter = ({ row }: GridRenderCellParams<CustomerOrder>) => {
  return (
    <Stack direction="column" overflow="auto">
      <Fragment>
        <Typography component="span">{row.vendorName}</Typography>
        <Typography component="span">{row.shipName}</Typography>
      </Fragment>
    </Stack>
  );
};

export default cruiseOfferDetailsFormatter;
