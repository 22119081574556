import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { currencyFormatter } from '~/components/Experiences/helpers';

import { dateFormatter, orderLinkFormatter, userLinkFormatter } from '../formatters';

interface Props {
  page: number;
  total: number;
  sizePerPage: number;
  rerender: () => void;
  orders: API.Order.Order[];
  onPageChange: (page: number) => void;
}

const columns: GridColDef[] = [
  { field: 'number', headerName: 'Booking ID', width: 150, sortable: false, display: 'flex' },
  {
    field: 'customer_email',
    headerName: 'Customer Email',
    sortable: false,
    flex: 1,
    renderCell: userLinkFormatter,
    display: 'flex',
  },
  { field: 'customer_full_name', headerName: 'Customer Name', flex: 1, sortable: false, display: 'flex' },
  {
    field: 'created_at',
    headerName: 'Order Placed',
    sortable: false,
    width: 200,
    renderCell: (params) => dateFormatter(params.value),
    display: 'flex',
  },
  { field: 'status', headerName: 'Order Status', width: 150, sortable: false, display: 'flex' },
  { field: 'brand', headerName: 'Brand', width: 150, sortable: false, display: 'flex' },
  { field: 'currency_code', headerName: 'Currency', sortable: false, display: 'flex' },
  {
    field: 'total',
    headerName: 'Order Total',
    sortable: false,
    width: 120,
    renderCell: (params) => currencyFormatter(params.row.currency_code, params.row.total, 2),
    display: 'flex',
  },
  {
    field: 'id',
    headerName: 'View Order',
    sortable: false,
    width: 120,
    renderCell: orderLinkFormatter,
    display: 'flex',
  },
];

export default function CarHirePurchaseList(props: Props) {
  const { orders, total, page, sizePerPage, onPageChange } = props;

  return (
    <DataGrid
      rows={orders}
      rowCount={total}
      columns={columns}
      pagination
      paginationMode="server"
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      pageSizeOptions={[sizePerPage]}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      slots={{ pagination: GridPagination }}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}
