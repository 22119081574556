import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getAffiliationList, getAgency, listUsers, updateAgency } from '~/services/AgentHub/AgentService';

import { Agency, AvailableAffiliations, MappedAgent } from '~/types/services/agentHub';

import AgencyDetails from './AgencyDetails';
import AgencyEditor from './AgencyEditor';

const DEFAULT_SIZE_PER_PAGE = 10;

interface Props {
  id: string;
  onClose?: () => void;
  opened?: boolean;
}

export default function AgencyDetailsModal({ id, onClose, opened = false }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(opened);
  const [data, setData] = useState<Agency | undefined>(undefined);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [users, setUsers] = useState<Array<MappedAgent>>([]);
  const [loadingUserState, setLoadingUserState] = useState<Utils.FetchingState>('idle');
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [isEditing, setIsEditing] = useState(false);
  const [availableAffiliations, setAvailableAffiliations] = useState<AvailableAffiliations>([]);

  const fetchUserList = useCallback(
    async (agencyId: string): Promise<void> => {
      try {
        setLoadingUserState('loading');
        const { rows, total } = await listUsers({
          agencyId: agencyId,
          page: page.toString(),
          pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
        });

        setTotal(total);
        setUsers(rows);

        setLoadingUserState('success');
      } catch (e) {
        setLoadingUserState('failed');
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, page],
  );

  const onSave = useCallback(
    async (editedData: Partial<Agency>) => {
      try {
        setLoadingState('loading');

        if (Object.entries(editedData).some(([_, value]) => !value)) {
          setLoadingState('failed');
          enqueueSnackbar('Please fill all the fields', { variant: 'error' });
          return;
        }

        const { id, name, businessCodeValue, address, city, state, postcode, country, affiliation } = editedData;
        const result = await updateAgency(id, {
          name,
          businessCodeValue,
          address,
          city,
          state,
          postcode,
          country,
          affiliation,
        });
        setData(result);
        setLoadingState('success');
        enqueueSnackbar('Register updated sucessfully');
        setIsEditing(false);
      } catch (err) {
        setLoadingState('failed');
        enqueueSnackbar('There was an error updating the agency', { variant: 'error' });
      }
    },
    [enqueueSnackbar, setLoadingState],
  );

  const onCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  const openModal = useCallback(async () => {
    if (!id) {
      closeModal();
    }

    setOpen(true);

    try {
      setLoadingState('loading');

      const [result, , affiliations] = await Promise.all([getAgency(id), fetchUserList(id), getAffiliationList()]);

      setData(result);
      setAvailableAffiliations(affiliations);
      setLoadingState('success');
    } catch (err) {
      setLoadingState('failed');
    }
  }, [closeModal, id, fetchUserList]);

  useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [openModal, opened]);

  return (
    <Dialog open={open}>
      <DialogTitle>Agency Details</DialogTitle>

      <DialogContent>
        {loadingState == 'loading' && <Spinner />}
        {loadingState == 'success' && isEditing && data && (
          <AgencyEditor
            availableAffiliations={availableAffiliations}
            setLoadingState={setLoadingState}
            data={data}
            onSave={onSave}
            onCancel={onCancel}
            loadingState={loadingState}
          />
        )}
        {loadingState == 'success' && !isEditing && (
          <AgencyDetails
            data={data}
            users={users}
            page={page}
            setPage={setPage}
            total={total}
            loadingUserState={loadingUserState}
          />
        )}
      </DialogContent>

      <DialogActions>
        {!isEditing && (
          <Button variant="text" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
        <Button variant="text" onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
