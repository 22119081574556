import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Autocomplete, Chip, FormControl, Stack, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';
import { Option, Request } from '~/components/Cruises/pages/Promotions/types';

import rateCodeService from '~/services/cruises/RateCodeService';

const RATE_CODE_LIMIT = 500;

interface Props {
  selectedRateIds: Array<string>;
  onChange: (selectedRateIds: Array<string>) => void;
  vendorCode: string;
  isLoading?: boolean;
}

function InputRateCodeSelect({ selectedRateIds, isLoading, onChange, vendorCode }: Props) {
  const [loadedVendorCode, setLoadedVendorCode] = useState<string | null>(null);
  const [cruiseRateCodes, setCruiseRateCodes] = useState<Request<Array<API.RateCodeListResponse>>>({
    result: [],
    loading: false,
  });

  useEffect(() => {
    const fetchRateCodes = async () => {
      setCruiseRateCodes((prev) => ({ ...prev, loading: true }));
      const res = await rateCodeService.getRateCodeList({
        take: RATE_CODE_LIMIT,
        vendorCode,
      });

      if (res.status === 200 && res.result) {
        setLoadedVendorCode(vendorCode);
        setCruiseRateCodes({ loading: false, result: res.result });
      }
    };

    if (!cruiseRateCodes.loading && !!vendorCode && loadedVendorCode !== vendorCode) {
      fetchRateCodes();
    }
  }, [cruiseRateCodes.loading, vendorCode, loadedVendorCode]);

  const ratesSelectOptions = useMemo(() => {
    return cruiseRateCodes.result?.map((item) => ({
      value: item.id,
      label: item.code,
    }));
  }, [cruiseRateCodes.result]);

  const ratesSelected = useMemo(() => {
    return selectedRateIds
      .map((rateId) => {
        const rate = cruiseRateCodes.result?.find((rate) => rate.id === rateId);
        return { value: rateId, label: rate?.code };
      })
      .filter((item) => !!item.label);
  }, [selectedRateIds, cruiseRateCodes.result]);

  const handleRatesChange = useCallback(
    (_, selectedOptions: Array<Option> | null) => {
      onChange(selectedOptions?.map((selectedRate) => selectedRate.value) || []);
    },
    [onChange],
  );

  return (
    <Stack spacing={2} direction="row">
      <FormControl fullWidth>
        <Autocomplete
          multiple
          id="select-rates"
          disableCloseOnSelect
          value={ratesSelected}
          options={ratesSelectOptions}
          onChange={handleRatesChange}
          disabled={!vendorCode || isLoading}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} required fullWidth label="Rate Codes" placeholder="Please Enter Rate Codes" />
          )}
        />
      </FormControl>
      {(cruiseRateCodes.loading || isLoading) && <Spinner size={20} />}
    </Stack>
  );
}

export default InputRateCodeSelect;
