import cloneDeep from 'lodash/cloneDeep';

import { addMonths, getCalendarMonthWeeks } from '~/services/TimeService';

const monthsArray = (firstDay, numMonths, offset) => {
  const accum = Array(numMonths);
  for (let i = 0; i < numMonths; i++) {
    const firstDayClone = cloneDeep(firstDay);
    accum[i] = addMonths(offset + i, firstDayClone);
  }
  return accum;
};

const getMonths = (firstDay, numMonths, offset) => {
  return monthsArray(firstDay, numMonths, offset);
};

const getMonthWeeks = (month) => {
  return getCalendarMonthWeeks(month, false);
};

const getCalendarGrid = (firstDay, numMonths, offset) => {
  return getMonths(firstDay, numMonths, offset).map((month) => {
    return {
      date: month,
      weeks: getMonthWeeks(month),
    };
  });
};

export default getCalendarGrid;
