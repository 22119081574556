import React, { Component } from 'react';

import classNames from 'clsx';
import isValidCoordinate from 'geolib/es/isValidCoordinate';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import withScriptjs from 'react-google-maps/lib/withScriptjs';

import { GEOCODE_STATUS_OK } from '../../utils/googlePlace';

const PropertyGoogleMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      ref={props.onMapLoad}
      defaultZoom={props.defaultZoom}
      defaultCenter={props.defaultCenter}
      onClick={props.onMapClick}
    >
      {props.children}
    </GoogleMap>
  )),
);

function googleMapsScriptURL() {
  return `https://maps.googleapis.com/maps/api/js?key=${window.configs.GOOGLE_MAPS_API_KEY}&language=en-GB&libraries=places`;
}

function validCoords(lat, lng) {
  if (Number.isNaN(lat) || Number.isNaN(lng) || isValidCoordinate({ latitude: lat, longitude: lng }) === false) {
    return false;
  }
  return typeof lat === 'number' && typeof lng === 'number';
}

class PropertyMap extends Component {
  onMapClick(location) {
    const lat = location.latLng.lat();
    const lng = location.latLng.lng();
    const placeId = location.placeId || null;

    if (this.props.onMapClick) {
      this.props.onMapClick(lat, lng, placeId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.geocoder || !this.googlePlaces) return;

    const { name, address } = this.props;

    if (!name || !address) return;
    // Start geocoding once the name & address have a few characters
    if (name.length < 3 || address.length < 5) return;
    if (name === prevProps.name && address === prevProps.address) return;

    this.googlePlaces.findPlaceFromQuery(
      {
        query: `${name} ${address}`,
        fields: ['place_id'],
      },
      (results) => {
        this.doReverseGeocode(results && results[0]?.place_id);
      },
    );
  }

  doReverseGeocode(placeId) {
    const { onReverseGeocode, setErrorMsg } = this.props;

    this.geocoder.geocode({ placeId: placeId }, (results, status) => {
      if (!results?.length) {
        setErrorMsg('No address found. Please try another pin.');
        onReverseGeocode(this.emptyResult());
      }

      if (status === GEOCODE_STATUS_OK) {
        if (placeId) results[0].place_id = placeId; // If we already have a placeId, override it
        const result = this.buildReverseGeocodeResult(results[0]);

        onReverseGeocode(result);
        setErrorMsg('');
        return;
      }
    });
  }

  emptyResult() {
    return this.buildReverseGeocodeResult({
      address_components: [],
      place_id: '',
    });
  }

  buildReverseGeocodeResult(address) {
    const components = address.address_components;
    const addressComponentValue = (componentType, field = 'long_name') => {
      const component = components.find((component) => component.types.includes(componentType));
      return component ? component[field] : '';
    };

    const result = {
      country: addressComponentValue('country'),
      country_code: addressComponentValue('country', 'short_name'),
      administrative_area_level_1: addressComponentValue('administrative_area_level_1', 'long_name'),
      administrative_area_level_2: addressComponentValue('administrative_area_level_2', 'long_name'),
      administrative_area_level_3: addressComponentValue('administrative_area_level_3', 'long_name'),
      administrative_area_level_4: addressComponentValue('administrative_area_level_4', 'long_name'),
      administrative_area_level_5: addressComponentValue('administrative_area_level_5', 'long_name'),
      locality: addressComponentValue('locality', 'short_name'),
      route: addressComponentValue('route'),
      street_number: addressComponentValue('street_number'),
      place_id: address.place_id,
    };
    return result;
  }

  initialiseGeocoder = () => {
    if (this.props.reverseGeocode) {
      if (window.google && window.google.maps) {
        this.geocoder = new window.google.maps.Geocoder();

        // Yes this is ugly but without server-side endpoint and things we can't do this legally with Google
        var map = new window.google.maps.Map(document.createElement('div'));
        this.googlePlaces = new window.google.maps.places.PlacesService(map);
      } else {
        console.error('Google maps not properly initialised');
      }
    }
  };

  render() {
    const lat = parseFloat(this.props.lat);
    const lng = parseFloat(this.props.lng);

    let defaultCenter, defaultZoom, marker;

    if (validCoords(lat, lng)) {
      defaultCenter = { lat, lng };
      defaultZoom = 12;
      marker = <Marker position={{ lat, lng }} />;
    } else {
      defaultCenter = { lat: 0, lng: 0 };
      defaultZoom = 1;
      marker = null;
    }

    const classes = classNames('PropertyMap', this.props.className);

    return (
      <div className={classes} style={{ height: 500 }}>
        <PropertyGoogleMap
          googleMapURL={googleMapsScriptURL()}
          loadingElement={<div>Loading...</div>}
          containerElement={<div style={{ height: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
          onMapClick={this.onMapClick.bind(this)}
          onMapLoad={this.initialiseGeocoder}
        >
          {marker}
        </PropertyGoogleMap>
      </div>
    );
  }
}

PropertyMap.defaultProps = {
  onReverseGeocode: (result) => null,
};

export default PropertyMap;
