import React, { Fragment } from 'react';

import { Link, Typography } from '@mui/material';

type Props = {
  url: string;
  title: string;
};

export default function LinkContent(props: Props) {
  return (
    <Fragment>
      <Typography>
        <Link target="_blank" href={props.url} rel="noreferrer" underline="hover">
          {props.title}
        </Link>
      </Typography>
    </Fragment>
  );
}
