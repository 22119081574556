import { formatDateWithClock } from '~/services/TimeService';

const dateFormatter = (date) => {
  if (date) {
    return formatDateWithClock(date);
  }

  return '';
};

export default dateFormatter;
