import React, { Fragment, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useSailingsSearchFilter } from '~/components/Cruises/components/hooks/useSailingsSearchFilter';
import { onSearchNewLocation } from '~/components/Cruises/utils/onSearchNewLocation';

import useQuery from '~/hooks/useQuery';

import { addQuery, removeQuery } from '~/utils/url';

import SailingList from './SailingList';
import SailingSearchFilters from './SailingSearchFilters';
import { SearchFilters } from './constants';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function CruisesPurchasePage() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const currentPage = useMemo(() => parseInt(query.get('page')) || 1, [query]);

  const searchFilters = useSailingsSearchFilter();

  const onSearch = useCallback(
    (params: SearchFilters) => {
      const { filtersToRemove, newLocation } = onSearchNewLocation(params, location);

      history.push(removeQuery(newLocation, ...filtersToRemove));
    },
    [history, location],
  );

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  return (
    <Fragment>
      <SailingSearchFilters querySearchFilters={searchFilters} onSearch={onSearch} />

      <SailingList
        brand={brand}
        page={currentPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
        searchFilters={searchFilters}
        sizePerPage={DEFAULT_SIZE_PER_PAGE}
      />
    </Fragment>
  );
}
