import React, { useState } from 'react';

import { Alert, Box, CircularProgress, Dialog, Link, Tab, Tabs, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarQuickFilter } from '@mui/x-data-grid';

import ReservationService from '~/services/ReservationService';
import { formatDateSlashes, sortByDate } from '~/services/TimeService';

import { daysOfWeekMessage } from '~/utils/daysOfWeek';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const roomRateLink = (vendorId, propertyId, roomTypeId, roomRateId) => {
  return `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}`;
};

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <GridToolbarQuickFilter style={{ flex: 1 }} />
    </Box>
  );
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface Props {
  roomTypes: Array<App.RoomType>;
  propertyId: string;
  closeModal: () => void;
  isOpen: boolean;
  vendorId: string;
}

export function AllSurchargesViewModal({ closeModal, isOpen, roomTypes, propertyId, vendorId }: Props) {
  const [value, setValue] = React.useState<string | false>(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState(null);
  const [surchargeDates, setSurchargeDates] = useState({});
  const fetchSurchargeDates = async (propertyId, roomTypeId) => {
    setLoading(true);
    try {
      const { result } = await ReservationService.getSurchargeDatesByRoomType(propertyId, roomTypeId, {
        product: 'limited_time_exclusive',
      });
      setSurchargeDates({
        ...surchargeDates,
        [roomTypeId]: result?.dates,
      });
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (!surchargeDates[newValue]) {
      await fetchSurchargeDates(propertyId, newValue);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'ratePlan',
      headerName: 'Rate Plan',
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'start_date',
      headerName: 'Range',
      minWidth: 170,
      sortComparator: sortByDate,
      valueGetter: (_value, row) => `${formatDateSlashes(row.start_date)}-${formatDateSlashes(row.end_date)}`,
      display: 'flex',
    },
    {
      field: 'daysOfWeek',
      headerName: 'Days of week',
      minWidth: 175,
      valueGetter: (_value, row) => daysOfWeekMessage(row),
      display: 'flex',
    },
    {
      field: 'cost_currency',
      headerName: 'Cost $',
      maxWidth: 60,
      display: 'flex',
    },
    {
      field: 'cost_amount',
      headerName: 'Cost price',
      maxWidth: 75,
      display: 'flex',
    },
    {
      field: 'sell_price',
      headerName: 'Sell price',
      maxWidth: 75,
      valueGetter: (_value, row) => row.prices?.['AUD'],
      display: 'flex',
    },
    {
      field: 'Link',
      headerName: 'Link',
      maxWidth: 70,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          href={roomRateLink(vendorId, propertyId, params.row.room_type_id, params.row.room_rate_id)}
          target="_blank"
        >
          Open
        </Link>
      ),
      display: 'flex',
    },
  ];

  return (
    <Dialog onClose={closeModal} open={isOpen} maxWidth="md" fullWidth>
      <Box
        sx={{
          minHeight: 44,
          p: 2,
          backgroundColor: 'secondary.dark',
          color: 'white',
        }}
      >
        <Typography variant="h5" component="h5" m={0}>
          All surcharges
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {roomTypes.map((roomType) => (
            <Tab label={roomType.name} value={roomType.id} key={`tab-${roomType.id}`} />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flex: '1 1 auto',
          justifyContent: 'center',
          position: 'relative',
          minHeight: 310,
          height: '100%',
        }}
      >
        {error && (
          <Alert sx={{ mt: 1 }} severity="error">
            {error}
          </Alert>
        )}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {!value && (
            <Box>
              <Typography>Select Room Type</Typography>
            </Box>
          )}
          {loading && <CircularProgress />}
        </Box>
        {!loading &&
          value &&
          roomTypes.map((roomType) => (
            <TabPanel value={value} index={roomType.id} key={roomType.id}>
              <DataGrid
                rows={surchargeDates[value] ?? []}
                columns={columns}
                density="compact"
                pageSizeOptions={[10]}
                sx={{
                  minWidth: 700,
                  height: surchargeDates[value]?.length > 5 ? 460 : 310,
                  minHeight: 310,
                }}
                slots={{ toolbar: QuickSearchToolbar }}
              />
            </TabPanel>
          ))}
      </Box>
    </Dialog>
  );
}
