import React from 'react';

import { MarkdownPreview } from 'react-marked-markdown';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

import { BONUS_INCLUSIONS_MAX_TO_NIGHTS } from '~/consts/ratePlans';

const columns: GridColDef[] = [
  { field: 'from_nights', headerName: 'Duration', sortable: false, display: 'flex' },
  {
    field: 'to_nights',
    headerName: 'To Nights',
    sortable: false,
    display: 'flex',
    valueGetter: (_value, row) =>
      row.to_nights === BONUS_INCLUSIONS_MAX_TO_NIGHTS ? row.from_nights + '+' : row.to_nights,
  },
  {
    field: 'content',
    headerName: 'Content',
    sortable: false,
    flex: 1,
    renderCell: (params) => <MarkdownPreview value={params.row.content} />,
    display: 'flex',
  },
];

export default function RatePlanBonusInclusions({ bonusInclusions }) {
  return (
    <div>
      <DefinitionTitle>Rate Plan Bonus Inclusions</DefinitionTitle>
      <div>
        <DataGrid
          columns={columns}
          rows={bonusInclusions}
          getRowHeight={() => 'auto'}
          disableRowSelectionOnClick
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnFilter
        />
      </div>
    </div>
  );
}
