import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import dayjs from '~/timeInit';

class DateWidget extends Component {
  constructor(props) {
    super(props);
    dayjs.tz.setDefault(props.defaultTimezone || window.configs.DEFAULT_TIMEZONE);
    this.state = {
      date: this.props.value,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(dateTime) {
    const newDayjs = dayjs(dateTime[0]);
    this.props.onChange(newDayjs.format('YYYY-MM-DD'));
  }

  render() {
    const options = {
      enableTime: false,
      defaultDate: this.state.date,
      altInput: true,
      altFormat: 'D d/m/Y',
      static: this.props.static || false,
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
    };

    return <Flatpickr onChange={this.onChange} options={options} />;
  }
}

DateWidget.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  static: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

DateWidget.defaultProps = {
  onChange: () => {}, // eslint-disable-line no-empty-function
  static: false,
  minDate: '',
  maxDate: '',
};

export default DateWidget;
