import memoize from 'lodash/memoize';

import { CREDIT_PAYMENT_TYPE, PROMO_PAYMENT_TYPE } from '~/consts/payment';

import { formatDateDay } from './TimeService';

// Some price calculations for order. Taken from customer portal and improved a little bit

type ArrayOrObject<T> = Array<T> | { [key: string]: T };

function numberIdentity(value: any) {
  return value as number;
}

const returnValFunc = (val) => val;

export function groupBy<K, T, R = T>(
  vals: ArrayOrObject<T> = [],
  keySelector: (val: T, index: number) => K,
  valueSelector: (val: T) => R = returnValFunc,
): Map<K, Array<R>> {
  const valsAsArray = Array.isArray(vals) ? vals : Object.values(vals);

  return valsAsArray.reduce((grouped, val, index) => {
    const groupKey = keySelector(val, index);
    const newValue = valueSelector(val);
    let groupValues = grouped.get(groupKey);
    if (!groupValues) {
      groupValues = [];
      grouped.set(groupKey, groupValues);
    }
    groupValues.push(newValue);
    return grouped;
  }, new Map());
}

function sortBy<T, V>(
  values: Array<T> | IterableIterator<T>,
  valueFunc: (val: T) => V,
  direction: 'desc' | 'asc' = 'desc',
) {
  return [...values].sort((v1, v2) => {
    const a = valueFunc(v1);
    const b = valueFunc(v2);
    if (a > b) {
      return direction === 'desc' ? -1 : 1;
    } else if (a < b) {
      return direction === 'desc' ? 1 : -1;
    } else {
      return 0;
    }
  });
}

function sum<T>(values: ArrayOrObject<T>, selector: (val: T, index: number) => number = numberIdentity): number {
  const valsAsArray = Array.isArray(values) ? values : Object.values(values);
  return valsAsArray.reduce((sum, value, i) => sum + selector(value, i), 0);
}

const isInstalmentFullyPaidOff = (instalmentDetails): boolean => {
  return ['payment_taken_complete', 'payment_manual_debit_taken_complete'].includes(
    instalmentDetails.instalment_status,
  );
};

export function getTotalsByItemTypes(order: App.Order) {
  return {
    accommodationTotal: order.items.reduce((orderTotal, item) => {
      let itemTotal = Number(item.package_price);

      if (item.reservation && item.reservation.surcharge && !item.reservation.surcharge_paid_direct_to_vendor) {
        itemTotal = itemTotal + Number(item.reservation.surcharge);
      }

      return orderTotal + itemTotal;
    }, 0),
    addonsTotal: sum(order.addon_items, (i) => i.total),
    experiencesTotal: sum(order.experience_items, (i) => i.total),
    insuranceTotal: sum(order.insurance_items, (i) => i.total),
    flightsTotal: sum(order.flight_items, (i) => i.total),
  };
}

export function getTotalsExperiencesTickets(order: App.Order) {
  return sum(order.experience_items, (i) => i.total);
}

export function getTotalPrice(order: App.Order) {
  return sum(getTotalsByItemTypes(order), (total) => total);
}

export function getPaymentSummary(payments: Array<any>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.type === PROMO_PAYMENT_TYPE || payment.status === 'failed' || Number(payment.amount) < 0) {
      return paymentsTotal;
    }
    return paymentsTotal + Number(payment.amount);
  }, 0);
}

export function getCreditPaymentSummary(payments: Array<any>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.status === 'failed' || payment.type !== CREDIT_PAYMENT_TYPE || Number(payment.amount) < 0) {
      return paymentsTotal;
    }
    return paymentsTotal + Number(payment.amount);
  }, 0);
}

export function getPromoPaymentSummary(payments: Array<any>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.status === 'failed' || payment.type !== PROMO_PAYMENT_TYPE) {
      return paymentsTotal;
    }
    return paymentsTotal + Number(payment.amount);
  }, 0);
}

export function getRefundSummary(payments: Array<any>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.type === PROMO_PAYMENT_TYPE) {
      return paymentsTotal;
    }
    if (payment.status === 'failed') {
      return paymentsTotal;
    }
    if (Number(payment.amount) > 0) {
      return paymentsTotal;
    }
    return paymentsTotal - Number(payment.amount);
  }, 0);
}

export function getRefundableAmount(payments: Array<any>) {
  return payments.reduce((paymentsTotal, payment) => {
    if (payment.type === PROMO_PAYMENT_TYPE || payment.status === 'failed') {
      return paymentsTotal;
    }
    // N.B. Already refunded amounts are negative payment amounts
    return paymentsTotal + Number(payment.amount);
  }, 0);
}

export function getGiftCardSummary(items: Array<any>) {
  return items.reduce((total, item) => total + item.total, 0);
}

export const getPaymentTotalsByDate = memoize(
  (order: App.Order, refundMeta, reserveForZeroDetails, instalmentDetails) => {
    // deposits
    const isDepositOrder = !!order.payments[0]?.depositDetails;
    const isDepositBalancePaid = !!order.payments[0]?.depositDetails?.balance_paid_date;
    const isInitialDepositOrder = isDepositOrder && !isDepositBalancePaid;

    // instalments
    const isPendingInstalmentOrder = !!instalmentDetails && !isInstalmentFullyPaidOff(instalmentDetails);

    // reserveForZero
    const isPendingReserveForZero = reserveForZeroDetails;

    const hasDowngradedItem = order.items.some((item) => item.is_downgraded);
    const completedPayments = order.payments.filter((payment) => payment.status === 'completed');
    const grouped = groupBy(completedPayments, (payment) => formatDateDay(new Date(payment.createdAt)));
    const paymentGroups = Array.from(grouped.entries()).map(([date, payments]) => {
      const promo = getPromoPaymentSummary(payments);
      const refunds = getRefundSummary(payments);
      const paid = getPaymentSummary(payments);
      const credits = getCreditPaymentSummary(payments);

      return {
        date: new Date(date),
        subTotal: paid + promo,
        paidTotal: paid - credits,
        promoTotal: promo,
        creditTotal: credits,
        refundTotal: refunds,
      };
    });

    // This is a hack to get the amounts out of the order
    // if changes to cheaper date were performed
    // When the surcharge is downgraded we make full refund on surcharges and then
    // make a new payment for the new surcharge.
    // This information is internal and should not be exposed to the user.
    let cheaperDateRefund: number, cheaperDatePayment: number, cheaperDateCredits: number;
    cheaperDateRefund = cheaperDatePayment = cheaperDateCredits = 0;
    if (refundMeta.length > 0) {
      refundMeta.forEach((element) => {
        if (element.reason === 'Customer changed to cheaper dates') {
          cheaperDateRefund += Number(element.cash_amount);
          cheaperDateCredits += Number(element.cash_amount);
          const paymentTransactionKey = element.comment.split(' ')[0];
          const payment = order.payments.find((payment) => payment.transaction_key === paymentTransactionKey);
          if (payment) {
            cheaperDateRefund -= Number(payment.amount);
          }
        }
      });
    }
    cheaperDatePayment = cheaperDateCredits - cheaperDateRefund;

    // Hack for self-service package upgrade
    let selfServicePackageUpgradeRefund: number, selfServicePackageUpgradeCredit: number;
    selfServicePackageUpgradeRefund = selfServicePackageUpgradeCredit = 0;
    if (refundMeta.length > 0) {
      refundMeta.forEach((element) => {
        if (element.comment === 'Customer self-service package upgrade') {
          selfServicePackageUpgradeRefund += Number(element.cash_amount);
          selfServicePackageUpgradeCredit += Number(element.cash_amount);
          const paymentTransactionKey = element.comment.split(' ')[0];
          const payment = order.payments.find((payment) => payment.transaction_key === paymentTransactionKey);
          if (payment) {
            selfServicePackageUpgradeRefund -= Number(payment.amount);
          }
        }
      });
    }

    // if incomplete deposit, instalment and reserveForZero orders use order total as grand total
    const grandTotal =
      isInitialDepositOrder || isPendingInstalmentOrder || isPendingReserveForZero
        ? order.total -
          sum(paymentGroups, (group) => group.creditTotal) -
          sum(paymentGroups, (group) => group.promoTotal)
        : sum(paymentGroups, (group) => group.paidTotal) - cheaperDateRefund;
    // if incomplete deposit or instalment order user order total minus credits total and promo total as sub total
    let subTotal =
      isInitialDepositOrder || isPendingInstalmentOrder || isPendingReserveForZero
        ? order.total
        : sum(paymentGroups, (group) => group.subTotal) - cheaperDatePayment - selfServicePackageUpgradeCredit;

    // business traveller credits
    let totalBusinessTravellerCredits = 0;
    if (order.business_credit_items?.length) {
      totalBusinessTravellerCredits = sum(order.business_credit_items, (item) => Number(item.total));
      subTotal -= totalBusinessTravellerCredits;
    }

    return {
      grandTotal,
      subTotal,
      refundedTotal:
        sum(paymentGroups, (group) => group.refundTotal) - cheaperDatePayment - selfServicePackageUpgradeRefund,
      promoTotal: sum(paymentGroups, (group) => group.promoTotal),
      creditsTotal:
        sum(paymentGroups, (group) => group.creditTotal) -
        cheaperDatePayment -
        selfServicePackageUpgradeCredit -
        totalBusinessTravellerCredits,
      paymentGroups: sortBy(paymentGroups, (g) => g.date.valueOf(), 'asc'),
      hasDowngradedItem,
      totalBusinessTravellerCredits,
    };
  },
);

export const isDepositBalancePaid = (order) => {
  if (order.payments[0]?.depositDetails) {
    return ['due_balance_manual_debit_taken', 'due_balance_auto_debit_taken'].includes(
      order.payments[0]?.depositDetails?.deposit_status,
    );
  }
  return true;
};

export const isDepositRefunded = (order) => {
  if (order.payments[0]?.depositDetails) {
    return ['deposit_refunded'].includes(order.payments[0]?.depositDetails?.deposit_status);
  }
  return false;
};

export const isDepositBalancePaidOrRefunded = (order) => {
  return isDepositBalancePaid(order) || isDepositRefunded(order);
};
