import React from 'react';

import { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps, ariaDescribedByIds } from '@rjsf/utils';

import { FormControl } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import AIGeneratorButton, { AIContext } from '~/components/AIGenerator/AIGenerateButton';

export default function AIGeneratorTextAreaWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({
  id,
  label,
  required,
  disabled,
  readonly,
  placeholder,
  value,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  options,
  rawErrors = [],
  ...textFieldProps
}: WidgetProps<T, S, F>) {
  const _onChange = ({ target: { value } }: React.ChangeEvent<{ value: string }>) => onChange(value);
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        id={id}
        name={id}
        value={value || ''}
        required={required}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        placeholder={placeholder}
        error={rawErrors.length > 0}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        multiline={true}
        {...(textFieldProps as TextFieldProps)}
        InputLabelProps={{
          ...textFieldProps.InputLabelProps,
          shrink: !value,
        }}
        aria-describedby={ariaDescribedByIds<T>(id)}
      />
      <AIGeneratorButton
        context={options?.generatorContext as AIContext}
        content={value}
        buttonLabel={options?.buttonLabel?.toString() ?? `${value ? 'Rewrite' : 'Generate'}${label}`}
        updateContext={options?.updateContext as () => any}
        onChoose={(result: string) => onChange(result)}
      />
    </FormControl>
  );
}
