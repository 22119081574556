import React, { useCallback, useEffect, useState } from 'react';

import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import PageHeader from '~/components/Common/Elements/PageHeader';

import promoBannerService from '~/services/cruises/PromoBannerService';

import PromoBannerOrderingItem from './PromoBannerOrderingItem';

const PromoBannerOrdering: React.FC = (): JSX.Element => {
  const [promoBanners, setPromoBanners] = useState<API.PromoBanner[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10, // Enable sort function when dragging 10px   💡 here!!!
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  useEffect(() => {
    promoBannerService.listWithPagination({ take: 50 }).then((res) => {
      const results = res.result.filter(
        (promoBanner) =>
          !promoBanner.startDate ||
          !promoBanner.endDate ||
          (new Date(promoBanner.startDate) <= new Date() && new Date(promoBanner.endDate) >= new Date()),
      );

      setPromoBanners(results);
    });
  }, []);

  const handleDragSort = useCallback(
    ({ active, over }) => {
      if (active.id !== over.id) {
        const oldIndex = promoBanners.map((e) => e.id).indexOf(active.id);
        const newIndex = promoBanners.map((e) => e.id).indexOf(over.id);
        const newList = arrayMove(promoBanners, oldIndex, newIndex).map((promoBanner, index) => ({
          ...promoBanner,
          order: index + 1,
        }));

        setPromoBanners(newList);
      }
    },
    [promoBanners, setPromoBanners],
  );

  const savePromoBanners = useCallback(() => {
    const orderPromoBanners = async () => {
      const updateData = promoBanners.map(({ id }, index) => ({ id, order: index + 1 }));
      try {
        await promoBannerService.sortPromoBanners(updateData);
        enqueueSnackbar('Successfully updated', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('There was an error updating the order of promo tiles', { variant: 'error' });
      }
    };

    orderPromoBanners();
  }, [promoBanners, enqueueSnackbar]);

  const handlerPinPromoTile = useCallback(
    (promoBanner: API.PromoBanner) => {
      const pinPromoTile = async () => {
        const oldPinnedBanner = promoBanners.find((banner) => banner.isFixed);
        const unpinBanner = promoBanner.id === oldPinnedBanner?.id;

        if (oldPinnedBanner) {
          await promoBannerService.updatePromoBanner(oldPinnedBanner.id, {
            ...oldPinnedBanner,
            isFixed: false,
          });
        }

        if (!unpinBanner) {
          await promoBannerService.updatePromoBanner(promoBanner.id, {
            ...promoBanner,
            isFixed: true,
          });
        }

        const newPromoBanners = promoBanners.map((banner) => ({
          ...banner,
          isFixed: banner.id === promoBanner.id && !unpinBanner,
        }));

        setPromoBanners(newPromoBanners);
        enqueueSnackbar('Successfully updated', { variant: 'success' });
      };

      pinPromoTile();
    },
    [promoBanners, enqueueSnackbar, setPromoBanners],
  );

  return (
    <Box px={5}>
      <Helmet>
        <title>Promo tile ordering</title>
      </Helmet>
      <Link to={'/cruises/promo-banners'} className="btn btn-default">
        Return to promo tiles
      </Link>

      <PageHeader title="Promo tile ordering" />
      <Typography variant="h6">Drag and drop to change the display order of promo tiles in the carousel</Typography>

      <DndContext onDragEnd={handleDragSort} sensors={sensors}>
        <SortableContext items={promoBanners || []}>
          <Grid container spacing={2} mt={4}>
            {promoBanners?.map((promoBanner) => (
              <Grid key={promoBanner.id} item xs={3}>
                <PromoBannerOrderingItem promoBanner={promoBanner} onPinClick={handlerPinPromoTile} />
              </Grid>
            ))}
          </Grid>
        </SortableContext>
      </DndContext>

      <Box mt={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="success" size="large" onClick={savePromoBanners}>
          Save promo tiles
        </Button>
      </Box>
    </Box>
  );
};

export default PromoBannerOrdering;
