import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { Box, MenuItem, TextField } from '@mui/material';

import { getCarts } from '~/services/CartService';

import Spinner from '../Common/Spinner';

import CartPane from './CartPane';

const TYPES = ['abandoned', 'completed', 'pending'] as const;

type CartType = (typeof TYPES)[number];

export default function CartPaneContainer() {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(true);
  const [carts, setCarts] = useState(null);
  const [type, setType] = useState<CartType>('abandoned');
  const [page, setPage] = useState(0);

  const onTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value as CartType);
  }, []);

  const onPageChange = useCallback((page: number) => setPage(page), []);

  useEffect(() => {
    setLoading(true);

    getCarts(type, page + 1, 10, brand)
      .then((carts) => setCarts(carts))
      .catch((error) => {
        enqueueSnackbar('Error loading carts: ' + error.message, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [type, page, brand, enqueueSnackbar]);

  return (
    <>
      <Box width={250}>
        <TextField label="Type" value={type} onChange={onTypeChange} fullWidth select>
          {TYPES.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {isLoading && !carts && <Spinner />}

      {carts && (
        <Box mt={2}>
          <CartPane
            isLoading={isLoading}
            carts={carts.result}
            total={carts.total}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </>
  );
}
