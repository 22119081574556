import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import PushPin from '@mui/icons-material/PushPin';
import PushPinOutlined from '@mui/icons-material/PushPinOutlined';
import { Card, CardContent, IconButton } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Image from '~/components/Common/Image';

interface Props {
  promoBanner: API.PromoBanner;
  onPinClick: (promoBanner: API.PromoBanner) => void;
}

const PromoBannerOrderingItem = ({ promoBanner, onPinClick }: Props) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: promoBanner.id });

  return (
    <Card
      {...listeners}
      {...attributes}
      raised={isDragging}
      ref={setNodeRef}
      sx={{
        flexGrow: 1,
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isDragging ? 'grabbing' : 'grab',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      variant="outlined"
    >
      <CardContent sx={{ position: 'relative' }} data-no-dnd="true">
        <IconButton
          color="success"
          title="Pin tile"
          size="small"
          onClick={() => onPinClick(promoBanner)}
          sx={{
            position: 'absolute',
            top: '-6px',
            left: '-6px',
            minWidth: 0,
          }}
        >
          {promoBanner.isFixed ? <PushPin /> : <PushPinOutlined />}
        </IconButton>
        <Image className="img-responsive mx-auto" publicId={promoBanner.imageId} />
      </CardContent>
    </Card>
  );
};

export default PromoBannerOrderingItem;
