import React from 'react';

import { Rating } from 'react-simple-star-rating';

import { Box, Button, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';

import { Badge, FlexWrapper, Text } from '~/components/Experiences/components';
import { currencyFormatter } from '~/components/Experiences/helpers';

import { getStatusByCurated } from '~/consts/experiences';

import { ExperienceOffer } from '~/services/ExperiencesService';

import { CardHeader, RelativeBadge, RelativeRating } from './styles';

type CardContentProps = {
  onClick: () => void;
  experience: ExperienceOffer;
};

export const ExperienceCardContent: React.FC<CardContentProps> = (props) => {
  const { experience, onClick } = props;

  const status = getStatusByCurated(experience.curationStatus);

  const salesPrice = experience?.baseSalesPrice?.amount;
  const retailPrice = experience?.baseRetailPrice?.amount;

  const retailPriceFormatted = () => {
    return currencyFormatter(experience?.baseRetailPrice?.currencyCode, experience?.baseRetailPrice?.amount);
  };

  const salesPriceFormatted = () => {
    return currencyFormatter(experience?.baseSalesPrice?.currencyCode, experience?.baseSalesPrice?.amount);
  };

  const markup = () => (salesPrice / retailPrice - 1) * 100;
  const averageRating = ((experience?.providerRating?.average ?? 0) * 100) / 5;

  return (
    <>
      {experience.images[0] && (
        <CardMedia
          component="img"
          alt={`${experience.images[0].description}`}
          height="200"
          image={experience.images[0].urls[0].url}
        />
      )}
      <CardContent>
        <CardHeader>
          {experience.images[0] ? null : (
            <FlexWrapper>
              <Text size={'18px'} lineHeight={'21px'} align="center">
                No images
              </Text>
            </FlexWrapper>
          )}

          <RelativeBadge>
            <Badge lineHeight={'10px'} title={status.title} background={status.color} />
          </RelativeBadge>

          <RelativeRating>
            {experience?.providerRating?.number === 0 && (
              <Text weight={300} size={'14px'}>
                <i>has no rating</i>
              </Text>
            )}

            {experience?.providerRating?.number > 0 && (
              <FlexWrapper align="start">
                <Rating readonly size={18} ratingValue={averageRating} />
              </FlexWrapper>
            )}
          </RelativeRating>
        </CardHeader>

        <Box mt={2}>
          <FlexWrapper>
            <Text weight={700} size={'18px'} className="title" lineHeight={'21px'} title={experience.title}>
              {experience.title}
            </Text>
          </FlexWrapper>

          <Stack mt={2} direction="row" spacing={1} justifyContent="space-between">
            <div>
              <Text size={'12px'} lineHeight={'15px'}>
                Merchant Price:
              </Text>
              <Text weight={500} size={'16px'} lineHeight={'19px'}>
                {retailPriceFormatted()}
              </Text>
            </div>

            <div>
              <Text size={'12px'} lineHeight={'15px'}>
                Sale Price:
              </Text>
              <Text weight={500} size={'16px'} lineHeight={'19px'}>
                {salesPriceFormatted()}
              </Text>
            </div>

            <div>
              <Text size={'12px'} lineHeight={'15px'} align="right">
                Margin:
              </Text>
              <Text weight={500} size={'16px'} align="right" lineHeight={'19px'}>
                {markup().toFixed(1) + '%'}
              </Text>
            </div>
          </Stack>

          <FlexWrapper mt={'5px'} mb={'15px'}>
            <Typography>{experience?.description ?? experience?.shortDescription ?? <i>no description</i>}</Typography>
          </FlexWrapper>
        </Box>
      </CardContent>

      <CardActions>
        <Button variant="text" onClick={onClick}>
          Details
        </Button>
      </CardActions>
    </>
  );
};
