import React, { Context, createContext, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { CruisesContract as Api } from '@luxuryescapes/contract-svc-cruise';

import cruiseLineService from '~/services/cruises/CruiseLineService';

import { parseSearchString } from '~/utils/url';

interface Filter {
  name?: string;
  cruiseLine?: string;
}

interface CruisesData {
  cruiseLines: Api.CruiseLine[];
  page: number;
  total: number;
  resultsPerPage: number;
  paginate: (page: number) => void;
  setFilter: (value: Filter) => void;
  filter: Filter;
}

interface CruiseLineProviderProps {
  children?: React.ReactNode;
}

export const CruiseLineContext: Context<CruisesData> = createContext({} as CruisesData);

const resultsPerPage = 15;

export const CruiseLineProvider = ({ children }: CruiseLineProviderProps): JSX.Element => {
  const [filter, setFilter] = useState<Filter>({});
  const { search } = useLocation();
  const { push: setQueryString } = useHistory();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [cruiseLines, setCruiseLines] = useState<Api.CruiseLine[]>([]);

  const getCruiseLineList = async () => {
    const skip = (page - 1) * resultsPerPage;
    const res = await cruiseLineService.listWithPagination({
      ...(filter.name && { name: filter.name }),
      skip,
      take: resultsPerPage,
    });
    setCruiseLines(res.result);
    setTotal(res.total);
  };

  const setCurrentPage = (): void => {
    const queryString = parseSearchString(search);
    if (queryString) {
      const pageToGo = parseInt(queryString.page as string);
      if (pageToGo) setPage(pageToGo);
    }
  };

  const paginate = (page: number): void => {
    const queryString = new URLSearchParams({
      page: page.toString(),
    }).toString();
    setQueryString({ search: queryString });
    setPage(page);
  };

  useEffect(() => {
    setCurrentPage();
  }, []);

  useEffect(() => {
    getCruiseLineList();
  }, [page, filter]);

  return (
    <CruiseLineContext.Provider
      value={{
        filter,
        setFilter,
        cruiseLines,
        page,
        total,
        resultsPerPage,
        paginate,
      }}
    >
      {children}
    </CruiseLineContext.Provider>
  );
};

export const useCruiseLines = (): CruisesData => useContext(CruiseLineContext);
