import React, { Fragment, useState } from 'react';

import { Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import RoomRateForm from '~/components/Common/Forms/RoomRateForm';
import PermissionComponent from '~/components/Common/PermissionedComponent';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

export default function RoomTypeRoomRates({
  vendorId,
  propertyId,
  roomTypeId,
  roomRates,
  roomRate,
  ratePlans,
  propertyIsRental,
  deleteRoomRate,
  onUpdateRoomRates,
}) {
  const [editing, setEditing] = useState(false);

  const showConfirmDialog = useConfirmDialog();

  const toggleEditMode = () => {
    setEditing(!editing);
  };

  const toRoomRate = (roomRateId) => {
    return `/vendors/${vendorId}/properties/${propertyId}/room-types/${roomTypeId}/room-rates/${roomRateId}`;
  };

  const nameFormatter = (roomRate) => {
    return (
      roomRate.rate_plan.name + (roomRate.rate_plan.rate_plan_code ? ` (${roomRate.rate_plan.rate_plan_code})` : '')
    );
  };

  const handleDelete = async (roomRateId) => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete all? This action cannot be undone.',
      description: 'Please delete or re-map packages and packages options with this room rate before deleting.',
    });

    if (!confirmed) return;

    deleteRoomRate(roomRateId);
  };

  const renderModal = () => {
    if (!editing) {
      return <Dialog open={false} />;
    }

    return (
      <Dialog open onClose={toggleEditMode} maxWidth="md" fullWidth>
        <DialogTitle>Room rates</DialogTitle>

        <DialogContent>
          <Grid container spacing={0}>
            {roomRates.map((roomRt) => (
              <Fragment key={roomRt.id}>
                <Grid xs={8}>
                  {roomRt.rate_plan.name}
                  {roomRt.id === roomRate.id ? ' (selected)' : ''}
                </Grid>
                <Grid key={roomRt.id} xs={4} display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                  <Link to={`/vendors/${vendorId}/rate-plans/${roomRt.rate_plan.id}`}>View rate plan</Link>
                  <Divider orientation="vertical" />
                  <Button
                    title="Delete"
                    color="warning"
                    variant="text"
                    startIcon={<DeleteIcon />}
                    onClick={handleDelete}
                    disabled={roomRt.id === roomRate.id}
                  >
                    Delete
                  </Button>
                </Grid>
              </Fragment>
            ))}
          </Grid>
          <RoomRateForm
            propertyId={propertyId}
            roomTypeId={roomTypeId}
            ratePlans={ratePlans}
            onUpdateRoomRates={onUpdateRoomRates}
          />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Stack spacing={2} direction="column">
      <div>
        <Typography className="text-uppercase text-muted">Room Rate</Typography>
      </div>

      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start">
        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
          {roomRates.map(({ id, ...current }) => (
            <Button
              component={Link}
              key={id}
              to={toRoomRate(id)}
              variant={roomRate.id === id ? 'contained' : 'outlined'}
            >
              {nameFormatter(current)}
            </Button>
          ))}
        </Stack>

        {!propertyIsRental && (
          <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
            <div>
              <Button variant="text" onClick={toggleEditMode}>
                Edit
              </Button>

              {renderModal()}
            </div>
          </PermissionComponent>
        )}
      </Stack>
    </Stack>
  );
}
