import { useMemo } from 'react';

const HOST = window.configs.IMAGE_HOST;

export interface ImageUrlGeneratorOptions {
  width?: string | number;
  height?: string | number;
  dpr?: number;
}

export default function useImageUrlGenerator(publicId: string, options?: ImageUrlGeneratorOptions) {
  const query = useMemo<string>(() => {
    if (!options) return '';

    const parts = [];

    if (options?.width) {
      parts.push(`w_${options.width}`);
    }

    if (options?.height) {
      parts.push(`h_${options.height}`);
    }

    if (options?.dpr) {
      parts.push(`dpr_${options.dpr.toFixed(1)}`);
    }

    return parts.join(',');
  }, [options]);

  const url = useMemo(() => {
    if (!publicId) return '';
    if (query) return `${HOST}/${query}/${publicId}`;
    return `${HOST}/${publicId}`;
  }, [publicId, query]);

  return url;
}
