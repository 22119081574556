import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';

interface Props {
  status: string;
}

export default function TourStatusIndicatorIcon(props: Props) {
  const { status } = props;

  return (
    <>
      {status === 'content-approved' && (
        <Tooltip title="Available">
          <CircleIcon color="success" />
        </Tooltip>
      )}
      {status === 'draft' && (
        <Tooltip title="Unavailable">
          <CircleIcon color="disabled" />
        </Tooltip>
      )}
    </>
  );
}
