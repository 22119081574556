import { request } from './common';

function basePath() {
  return window.configs.AUTH_HOST + '/api/auth/stoplist';
}

export async function addStoplistGuest(object) {
  return request(`${basePath()}/post-guest`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function getStoplistGuests(page, per_page, first_name, last_name) {
  return request(
    `${basePath()}/stoplist?page=${page}&limit=${per_page}&first_name=${first_name}&last_name=${last_name}`,
    { method: 'GET' },
  );
}

export async function deleteStoplistGuest(guest_id) {
  return request(`${basePath()}/delete-stoplist-guest/${guest_id}`, {
    method: 'POST',
    body: JSON.stringify({ guest_id: guest_id }),
  });
}
