import { Refund } from '../NewModal';

export function getItemRefundAmount(refund: Refund) {
  if (
    refund.is_experience &&
    !refund.refund_provider_fee &&
    refund.item_metadata.cash_amount >= refund.item_metadata.vendor_refund_fee
  ) {
    return refund.item_metadata.cash_amount - refund.item_metadata.vendor_refund_fee;
  }

  return refund.item_metadata.cash_amount;
}
