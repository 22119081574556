import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';

import {
  addonsBookingNumbersFormatter,
  addonsVendorFormatter,
  dateFormatter,
  orderLinkFormatter,
  orderTotalFormatter,
  userLinkFormatter,
} from './formatters';

interface Props {
  orders: API.Order.Order[];
  total: number;
  page: number;
  sizePerPage: number;
  onPageChange: (page: number) => void;
  rerender: () => void;
}

const columns: GridColDef[] = [
  {
    field: 'customer_email',
    headerName: 'Customer Email',
    sortable: false,
    flex: 1,
    renderCell: userLinkFormatter,
    display: 'flex',
  },
  { field: 'customer_full_name', headerName: 'Customer Name', flex: 1, sortable: false, display: 'flex' },
  {
    field: 'created_at',
    headerName: 'Order Placed',
    sortable: false,
    width: 200,
    renderCell: (params) => dateFormatter(params.value),
    display: 'flex',
  },
  { field: 'status', headerName: 'Order Status', width: 150, sortable: false },
  {
    field: 'addon_items',
    headerName: 'Booking Numbers',
    width: 150,
    sortable: false,
    renderCell: (params) => addonsBookingNumbersFormatter(params.row),
    display: 'flex',
  },
  { field: 'utm_source', headerName: 'Source', width: 150, sortable: false, display: 'flex' },
  {
    field: 'vendor_id',
    headerName: 'Vendor ID',
    width: 150,
    sortable: false,
    renderCell: (params) => addonsVendorFormatter(params.row),
    display: 'flex',
  },
  { field: 'currency_code', headerName: 'Currency', sortable: false, display: 'flex' },
  {
    field: 'total',
    headerName: 'Order Total',
    sortable: false,
    width: 120,
    renderCell: orderTotalFormatter,
    display: 'flex',
  },
  {
    field: 'id',
    headerName: 'View Order',
    sortable: false,
    width: 120,
    renderCell: orderLinkFormatter,
    display: 'flex',
  },
];

export default function AddonsPage(props: Props) {
  const { orders, total, sizePerPage, onPageChange, page } = props;

  return (
    <DataGrid
      rows={orders}
      rowCount={total}
      columns={columns}
      pagination
      paginationMode="server"
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      pageSizeOptions={[sizePerPage]}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      slots={{ pagination: GridPagination }}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}
