import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';

import { Autocomplete, Button, Container, Grid, TextField } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { countriesObjectOptions } from '~/consts/vendorFilterOptions';

import ErrorDisplay from '../../Common/ErrorDisplay';

import ChainVendorsPageContainer from './ChainVendorsPageContainer';
import ChainPropertiesPageContainer from './vendors/ChainPropertiesPageContainer';

const SELECT_ALL_OPTION = 'All';

const ChainsDetailContainer = (props) => {
  const fetchVendors = () => {
    const vendorsPageContainer = <ChainVendorsPageContainer parentId={parentId} />;
    return vendorsPageContainer;
  };

  const { match } = props;
  const parentId = match?.params?.id_parent;

  const [vendor, setVendor] = useState(null);
  const [error, setError] = useState(null);
  const [country, setCountry] = useState([]);
  const [vendorsTable, setVendorsTable] = useState(fetchVendors());

  const handleCountryChange = (selected) => {
    setCountry(selected);
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Chain | {vendor?.name || parentId}</title>
      </Helmet>
      {error && <ErrorDisplay message={error} />}
      <Grid>
        <Grid item sx={{ mt: 4, mb: 3 }}>
          <PageHeader title={`Chain (${vendor?.parent_name || parentId})`}>
            <Button variant="text" component={Link} to={`/vendors/chain/${parentId}/settings`}>
              Edit
            </Button>
          </PageHeader>
        </Grid>
        <Grid item sx={{ mt: 4 }}>
          {vendorsTable}
        </Grid>
        <Grid item sx={{ mt: 4 }}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={[SELECT_ALL_OPTION, ...countriesObjectOptions.map((type) => type.value)]}
            onChange={(_, values) => {
              if (values.filter((item) => item === SELECT_ALL_OPTION).length === 1) {
                handleCountryChange(countriesObjectOptions.map((type) => type.value));
              } else {
                handleCountryChange(values);
              }
            }}
            limitTags={5}
            value={country}
            renderInput={(params) => <TextField {...params} label="Select countries" />}
            getOptionLabel={(option) => countriesObjectOptions.find((type) => type.value === option)?.label || option}
          />
        </Grid>
        <Grid item sx={{ mt: 4 }}>
          <ChainPropertiesPageContainer parentId={parentId} country={country} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(ChainsDetailContainer);
