import React from 'react';

import { MarkdownPreview } from 'react-marked-markdown';

import Spinner from '../../../Common/Spinner';

import ToggleButton from './ToggleButton';

interface Props {
  finePrint?: App.TourV2FinePrint;
}

const TourFinePrint = ({ finePrint }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ToggleButton title="Fine Print" isOpen={isOpen} handleClick={handleClick}>
      {finePrint ? (
        <div>
          {finePrint.items.map((item, index) => (
            <div key={index}>
              <h4>{item.title}</h4>
              <MarkdownPreview value={item.body} />
            </div>
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </ToggleButton>
  );
};

export default TourFinePrint;
