import React, { useCallback } from 'react';

import { Button, DialogActions, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { PropertyLocationOverride } from '~/components/Content/PropertyLocationOverride/Columns';

type props = {
  open: boolean;
  onClose: () => void;
  itemsToRemove: PropertyLocationOverride[];
  handleDelete: () => void;
};

const ConfirmDeleteModal = ({ open, onClose, itemsToRemove, handleDelete }: props) => {
  const onDeleteClick = useCallback(() => {
    onClose();
    handleDelete();
  }, [handleDelete, onClose]);

  if (!itemsToRemove.length) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Are you sure you want to remove the following {itemsToRemove.length > 1 ? `overrides` : 'override'}?
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={4}>
          <ul>
            {itemsToRemove.map((value, index) => (
              <li key={index}>
                {value.propertyName} | {value.placeName}
              </li>
            ))}
          </ul>
          <DialogActions>
            <Button variant="contained" color="error" onClick={onDeleteClick}>
              Remove
            </Button>
            <Button variant="contained" color="primary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
