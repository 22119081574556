import { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router';

import useLocalStorage from '~/hooks/useLocalStorage';

import { AutoCompleteActionConfig, speedNavigationConfig } from './speedNavigationConfig';

const scoreOption = (option: AutoCompleteActionConfig, searchString) => {
  let score = 0;
  const searchStringLower = searchString.toLowerCase();
  const optionKeyLower = option.key.toLowerCase();

  // Base score for any match
  if (optionKeyLower.startsWith(searchStringLower)) {
    score += 10;
  }

  // Additional score for exact matches
  if (optionKeyLower == searchStringLower || `${searchStringLower}:` == optionKeyLower) {
    score += 20;
  }

  // Bonus points for options requiring additional data that is correctly provided
  if (option.inputAllowsAction != null && option.inputAllowsAction && option.inputAllowsAction(searchString)) {
    score += 15;
  }

  if (option.defaultScore) {
    score += option.defaultScore * 0.1 * 0.1;
  }

  return score;
};

type AutoCompleteOption = {
  key: string;
  description: string;
  /**
   * Will display as hover text
   */
  subtitle?: string;
  identifer?: string;
  action: () => void;
  isLink?: boolean;
  score?: number;
};

const PROMPT_KEY = 'recently-used-prompt-list';

const useSpeedNavigationOptions = (initalValue: string) => {
  const [inputValue, setInputValue] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [recentlyUsedPrompts, setRecentlyUsedPrompts, clearRecentlyUsedPrompts] = useLocalStorage(PROMPT_KEY, []);
  const inputRef = useRef(null);
  const history = useHistory();

  const storePrompt = (promptString: string) => {
    if (!recentlyUsedPrompts.includes(promptString)) {
      const newPrompts = [promptString, ...recentlyUsedPrompts];
      setRecentlyUsedPrompts(newPrompts);
    }
  };

  const deleteRecentPrompt = (prompt: string) => {
    setRecentlyUsedPrompts((prevPrompts) => prevPrompts.filter((p) => p !== prompt));
  };

  const deleteAllRecentPrompts = () => {
    clearRecentlyUsedPrompts();
  };

  const updateOptions = useCallback((searchString: string) => {
    setIsLoading(true);

    const startSearchParts = searchString.split(':');
    const searchStringStart = startSearchParts[0];
    const filteredOptions = speedNavigationConfig
      .filter((config) => {
        return (
          searchString === '' ||
          config.key.toLowerCase().startsWith(searchStringStart.toLowerCase()) ||
          searchStringStart == config.key.toLowerCase()
        );
      })
      .map((config): AutoCompleteOption => {
        let warnings = [];
        if (config.inputAllowsAction != null) {
          warnings = warnings.concat(config.inputAllowsAction(searchString));
        }

        const inputRequired = () => {
          setInputMessage('input required');
          setInputValue(config.key.endsWith(':') ? config.key : `${config.key}:`);
        };

        const action = () => {
          setInputMessage('');
          const urlSearchString = startSearchParts.length > 1 ? startSearchParts[1].trim() : '';
          storePrompt(searchString);
          config.action(history, urlSearchString);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        };

        const description =
          warnings.length === 0 && startSearchParts.length > 1 && startSearchParts[1].length > 0
            ? `${config.description}: ${startSearchParts[1] ?? ''}`
            : config.description;

        return {
          key: config.key,
          description: description,
          score: scoreOption(config, searchString),
          subtitle: config.subtitle,
          isLink: warnings.length === 0,
          action: warnings.length > 0 ? inputRequired : action,
        };
      })
      .sort((a, b) => b.score - a.score);

    setIsLoading(false);

    setOptions(filteredOptions);
  }, []);

  const onChange = useCallback(
    (inputValue: string) => {
      const newInputValue = inputValue;
      setInputValue(newInputValue);
      updateOptions(newInputValue);
    },
    [updateOptions],
  );

  useEffect(() => {
    updateOptions(initalValue);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onOpen = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setInputValue('');
    updateOptions('');
  };

  return {
    inputValue,
    inputRef,
    onChange,
    options,
    isLoading,
    inputMessage,
    recentlyUsedPrompts,
    deleteRecentPrompt,
    deleteAllRecentPrompts,
    onOpen,
  };
};

export { useSpeedNavigationOptions };
