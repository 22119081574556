import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerCountryStates from '~/actions/customerCommunication/fetchHeroPlannerCountryStates';

import { useAppSelector } from '../store';

function useHeroPlannerCountryStates(countryGroupId: string, countryId: string) {
  const brand = useAppSelector((state) => state.tenant.brand);
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerCountryStates> = [brand, countryGroupId, countryId];
    return [getRecordKeyFor(...params), params];
  }, [brand, countryGroupId, countryId]);
  const countryStatesReq = useAppSelector((state) => state.customerCommunication.countryGroupCountryStatesRecords[key]);
  const appDispatch = useDispatch();
  const fetchCountryStates = useCallback(() => {
    appDispatch(fetchHeroPlannerCountryStates(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchCountryStates();
  }, [fetchCountryStates]);

  return { countryStatesReq, fetchCountryStates };
}

export default useHeroPlannerCountryStates;
