import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';

interface Props {
  recentlyUsedPrompts: Array<string>;
  deletePrompt: (key: string) => void;
  deleteAllPrompt: () => void;
  setInputValue: (value: string) => void;
}

function SpeedNavigationRecentlyUsed({ recentlyUsedPrompts, deletePrompt, deleteAllPrompt, setInputValue }: Props) {
  return (
    <>
      <Divider />
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowY: 'scroll',
          maxHeight: '75vh',
        }}
      >
        Recently used
        <IconButton aria-label="delete" onClick={deleteAllPrompt} size="small">
          <DeleteIcon sx={{ pointerEvents: 'none' }} fontSize="inherit" />
        </IconButton>
      </Typography>
      <List>
        {recentlyUsedPrompts.map((prompt: string) => {
          return (
            <ListItem
              key={prompt}
              disablePadding
              secondaryAction={
                <IconButton aria-label="delete" onClick={() => deletePrompt(prompt)} size="small">
                  <CloseIcon sx={{ pointerEvents: 'none' }} fontSize="inherit" />
                </IconButton>
              }
            >
              <ListItemButton role="button" onClick={() => setInputValue(prompt)} dense>
                <ListItemText id={prompt} primary={prompt} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default SpeedNavigationRecentlyUsed;
