import React from 'react';

import classnames from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import { IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridRenderCellParams } from '@mui/x-data-grid';

import ResponsiveImage from '~/components/Common/Elements/ResponsiveImage';

import { OFFER_FACING_NAMES, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR } from '~/consts/offerTypes';

import OffersPageTable from '../Offers/Home/OffersPageTable';

import OfferCommonTable from './OfferCommonTable';

const LinkCell = ({ row, children, urlBuilder, onClick }) => {
  if (row.disableSelection) {
    return (
      <Typography component="span" color="secondary">
        {children}
      </Typography>
    );
  }

  if (urlBuilder) {
    return (
      <Link component={RouterLink} to={urlBuilder(row)} color={grey['800']} sx={{ textDecoration: 'none' }}>
        {children}
      </Link>
    );
  }

  return (
    <Link color={grey['800']} onClick={() => onClick(row)} sx={{ textDecoration: 'none' }}>
      {children}
    </Link>
  );
};

type Props = {
  sizePerPage: number;
  total: number;
  page: number;
  offers: App.Offer[];
  selectedOffers?: App.Offer[];
  loading?: boolean;
  isOffersPage?: boolean;
  fromVendor?: boolean;
  onPageChange: (page: number) => void;
  urlBuilder?: (row: App.Offer) => string;
  onRowClick?: (row: App.Offer) => void;
};

export default class OfferList extends React.Component<Props> {
  getImage = (params: GridRenderCellParams<App.Offer, App.OfferImage[]>) => {
    const { value, row } = params;

    if (value.length > 0) {
      const imageId = value[0].id_cloudinary_external;
      return (
        <LinkCell row={row} urlBuilder={this.props.urlBuilder} onClick={this.props.onRowClick}>
          <ResponsiveImage imageId={imageId} />
        </LinkCell>
      );
    }

    if (row.type === 'add-on' && row.panel_cloudinary_id) {
      return (
        <LinkCell row={row} urlBuilder={this.props.urlBuilder} onClick={this.props.onRowClick}>
          <ResponsiveImage imageId={row.panel_cloudinary_id} />
        </LinkCell>
      );
    }
  };

  getLinkedCell = (params: GridRenderCellParams<App.Offer, string>) => (
    <LinkCell row={params.row} urlBuilder={this.props.urlBuilder} onClick={this.props.onRowClick}>
      {params.value}
    </LinkCell>
  );

  getHumanizedLinkedCell = (params: GridRenderCellParams<App.Offer, string>) => (
    <LinkCell row={params.row} urlBuilder={this.props.urlBuilder} onClick={this.props.onRowClick}>
      {OFFER_FACING_NAMES[params.value]}
    </LinkCell>
  );

  getCountCell = (params: GridRenderCellParams<App.Offer & { disableSelection?: boolean }, string>) => {
    const { row } = params;

    const count = this.props.selectedOffers.filter(
      (offer) => offer.id_salesforce_external === row.id_salesforce_external,
    ).length;

    return (
      <TextField
        label="In cart"
        className="quantity-value"
        inputProps={{ readOnly: true }}
        value={count}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className="T-quantity-plus"
                edge="end"
                disabled={row.disableSelection}
                onClick={() => this.props.onRowClick(row)}
                data-testid="quantity-plus-btn"
              >
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  getStatusIcon = (params: GridRenderCellParams<App.Offer, string>) => {
    const cell = params.value;

    switch (cell) {
      case 'content-approved':
        return <span title="Approved Content" className="icon-circle primary" />;
      case 'content-live':
        return <span title="Live Content" className="icon-circle success" />;
      case 'content-new':
        return (
          <span title="New Content">
            <StarIcon color="secondary" />
          </span>
        );
      default:
        return <span title="Draft Content" className="icon-circle muted" />;
    }
  };

  formatTrClass(row: App.Offer) {
    const offer = row;

    return classnames('T-offer-row', {
      'T-voucher data-hj-whitelist': offer.booking_type === 'voucher',
      'T-reservation data-hj-whitelist': offer.booking_type === 'reservation',
      'T-hotel data-hj-whitelist': offer.type === OFFER_TYPE_HOTEL,
      'T-tour data-hj-whitelist': offer.type === OFFER_TYPE_TOUR,
    });
  }

  render() {
    if (this.props.isOffersPage) {
      return (
        <OffersPageTable {...this.props} onPageChange={this.props.onPageChange} formatTrClass={this.formatTrClass} />
      );
    }

    return (
      <OfferCommonTable
        {...this.props}
        getImage={this.getImage}
        getLinkedCell={this.getLinkedCell}
        getHumanizedLinkedCell={this.getHumanizedLinkedCell}
        getCountCell={this.getCountCell}
        getStatusIcon={this.getStatusIcon}
        formatTrClass={this.formatTrClass}
      />
    );
  }
}
