import React, { useEffect, useMemo, useState } from 'react';

import classnames from 'clsx';
import currencyFormatter from 'currency-formatter';
import groupBy from 'lodash/groupBy';
import pluralize from 'pluralize';
import { ConnectedProps, connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';

import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { addOfferToCart, reduceOfferItemQuantity } from '~/actions/cart';

import OfferLocationHeading from '~/components/Common/Booking/OfferLocationHeading';

import { HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

import { getDurationText } from '~/utils/cart';

type PackageOptionsState = Record<number, App.CartPackage[]>;

interface GroupedPackagesProps {
  offer: App.CartOffer;
  offerNumber: number;
}

export function GroupedPackages({
  cart,
  offer,
  offerNumber,
  addPackageOptionToCart,
  removePackageOptionFromCart,
}: GroupedPackagesProps & ConnectedProps<typeof connector>) {
  const offerType = offer.type;
  const offerPackages = offer.packages;

  const [selectedDuration, setSelectedDuration] = useState<string>(null);
  const [packageOptions, setPackageOptions] = useState<PackageOptionsState>({});

  const hasRoomType = HOTEL_OFFER_TYPES.includes(offerType);

  const selectedPackageOptions = useMemo<App.CartPackage[]>(() => {
    if (!selectedDuration) return [];

    return packageOptions[selectedDuration] ?? [];
  }, [selectedDuration, packageOptions]);

  useEffect(() => {
    const groupedByDuration =
      offerType === 'tour'
        ? groupBy(offerPackages as App.CartTourPackage[], (pkg) => pkg.number_of_days)
        : groupBy(offerPackages as App.CartAccommodationPackage[], (pkg) => pkg.number_of_nights);

    setSelectedDuration(Object.keys(groupedByDuration)[0]);
    setPackageOptions(groupedByDuration);
  }, [offerType, offerPackages]);

  // change the selected nights state
  const handleDurationSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDuration(e.target.value);
  };

  // formats the package option price to include currency code
  const packagePriceDisplay = (price: number) => (
    <>
      {cart.currencyCode}
      <span>{price ? currencyFormatter.format(price, { code: cart.currencyCode }) : 'Unavailable'}</span>
    </>
  );

  const getOfferFromCart = () => {
    return cart.offers.filter((cartOffer) => cartOffer.id_salesforce_external === offer.id_salesforce_external);
  };

  // find how many of this option type are in the current cart
  const getOptionQuantity = (option: App.CartPackage) => {
    return cart.offers
      .flatMap((cartOffer) => cartOffer.items.map((cartItem) => cartItem.pkg.unique_key))
      .filter((cartOfferOption: string) => cartOfferOption === option.unique_key).length;
  };

  // add option to cart
  const handleAddPackageOptionToCart = (option: App.CartPackage) => {
    const { currencyCode } = cart;
    addPackageOptionToCart({ currencyCode, offer, pkg: option });
  };

  // remove option from cart
  const handleRemovePackageOptionFromCart = (offerPackage: App.CartPackage) => {
    removePackageOptionFromCart(offerPackage.unique_key, offer);
  };

  // returns a table row with package option details

  const renderHotelOfferPackageRow = (pkg: App.CartAccommodationPackage) => {
    return (
      <TableRow
        key={pkg.unique_key}
        className={classnames('booking-form')}
        data-testid={pkg.unique_key}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {hasRoomType && <TableCell>{pkg.room_type?.name}</TableCell>}
        <TableCell>{pkg.name}</TableCell>
        <TableCell>{packagePriceDisplay(pkg.price)}</TableCell>
        <TableCell>
          <input
            role="textbox"
            type="number"
            className="quantity-value data-hj-whitelist"
            value={getOptionQuantity(pkg)}
            disabled
          />

          <button
            onClick={() => {
              handleRemovePackageOptionFromCart(pkg);
            }}
            className="quantity-btn"
            disabled={getOptionQuantity(pkg) === 0}
          >
            &mdash;
          </button>
          <button
            onClick={() => handleAddPackageOptionToCart(pkg)}
            className="quantity-btn T-quantity-plus"
            data-testid={`quantity-plus`}
          >
            +
          </button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper data-testid={offer.id_salesforce_external} sx={{ border: 'solid 1px #d6d6d6', px: 2 }}>
      <OfferLocationHeading offerNumber={offerNumber} offer={offer} />

      {packageOptions && (
        <Box width={200}>
          <TextField
            data-testid="duration-select"
            onChange={handleDurationSelectChange}
            value={selectedDuration || '0'}
            fullWidth
            select
          >
            {Object.keys(packageOptions).map((duration) => (
              <MenuItem key={duration} value={duration}>
                {pluralize(offer.type == 'tour' ? 'day' : 'night', parseInt(duration), true)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {getOfferFromCart().map(
        (cartOffer) =>
          cartOffer.items.length > 0 && (
            <Stack key={cartOffer.id_salesforce_external} mt={2} direction="row" spacing={2} flexWrap="wrap">
              {cartOffer.items.map(({ pkg }, i) => (
                <Card key={i + 1} sx={{ border: 'solid 1px #d6d6d6', maxWidth: 400 }}>
                  <CardHeader
                    title={pkg.room_type ? pkg.room_type.name : pkg.name}
                    subheader={pkg.room_type ? pkg.name : undefined}
                    action={
                      <IconButton onClick={() => handleRemovePackageOptionFromCart(pkg)} className="T-remove-package">
                        <RemoveIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Typography>{getDurationText(cartOffer.items[i])}</Typography>
                    <Typography>{packagePriceDisplay(pkg.price)}</Typography>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          ),
      )}

      <Box my={2}>
        <TableContainer component={Paper} sx={{ border: 'solid 1px #d6d6d6' }}>
          <Table className="T-package-table">
            <TableHead>
              <TableRow>
                {hasRoomType && <TableCell>Room Type</TableCell>}
                <TableCell>Package</TableCell>
                <TableCell>From Price</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedPackageOptions
                .filter((pkg) => !!pkg.price)
                .map((pkg: App.CartAccommodationPackage) => renderHotelOfferPackageRow(pkg))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

function mapStateToProps({ cart }: App.State) {
  return {
    cart,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      addPackageOptionToCart: addOfferToCart,
      removePackageOptionFromCart: reduceOfferItemQuantity,
    },
    dispatch,
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(GroupedPackages);
