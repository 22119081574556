import React from 'react';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { useCruiseLines } from '~/components/Cruises/context/useCruiseLines';

export const CruiseLineList: React.FC = (): JSX.Element => {
  const { cruiseLines, page, total, resultsPerPage, paginate } = useCruiseLines();
  const history = useHistory();

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Code',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },
  ];

  return (
    <Box mt={1}>
      <Stack mb={1} direction="row-reverse">
        <Button component={Link} to={'/cruises/cruise-lines/order'} variant="text" size="small">
          Change ordering
        </Button>
      </Stack>

      <DataGrid
        className="T-offers-table"
        rows={cruiseLines || []}
        columns={columns}
        pagination
        paginationModel={{ page: page - 1, pageSize: resultsPerPage }}
        pageSizeOptions={[10]}
        getRowId={(row: API.CruiseLine) => row.id}
        paginationMode="server"
        rowCount={total}
        onPaginationModelChange={({ page }) => paginate(page + 1)}
        slots={{ pagination: GridPagination }}
        onRowClick={(row: GridRowParams<API.CruiseLine>) => history.push(`/cruises/cruise-lines/${row.id}`)}
        autoHeight // dangerous if number of rows per page > 25
      />
    </Box>
  );
};
