import React from 'react';

import { Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

export default function caseIdRenderer(params: GridRenderCellParams<App.PromoData, string[]>) {
  return (
    <Stack direction="column" spacing={1}>
      {params?.value ?? 'N/A'}
    </Stack>
  );
}
