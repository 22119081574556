import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerOfferDetailsGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/offer/{offerId}']['get'];

function mapHeroPlannerOfferDetails(
  external: HeroPlannerOfferDetailsGetContract['responses']['200']['content']['application/json']['result'],
): CustomerCommunication.HeroPlannerOfferDetails {
  let durationLabel: string | undefined = undefined;
  if ('durationLabel' in external) {
    durationLabel = external.durationLabel;
  } else if (external.offerType === 'tour_v2') {
    // @ts-expect-error missing from contract
    durationLabel = `${external.numDays} days`;
  }

  return {
    productType: external.productType as string,
    offerId: external.id,
    offerType: external.offerType,
    offerLink: external.offerLink,
    imageLink: external.imageLink,
    name: external.title,
    formattedPrice: external.price,
    durationLabel,
    // @ts-expect-error missing from contract
    roomType: external.roomType,
    saleUnit: external.saleUnit,
    // @ts-expect-error missing from contract
    startLocation: external.startLocation,
    // @ts-expect-error missing from contract
    endLocation: external.endLocation,
    // @ts-expect-error missing from contract
    onSale: !!external.onSale,
    // @ts-expect-error missing from contract
    location: external.location,
    // @ts-expect-error missing from contract
    propertyName: external.propertyName,
  };
}

async function requestGetHeroPlannerOfferDetails(
  brand: string,
  countryGroup: string,
  country: string,
  offerId: string,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!offerId) throw 'missing offerId';

  const url = makeCustomerCommunicationV1URL(`/offer/${offerId}`);
  url.searchParams.set('brand', brand);
  url.searchParams.set('countryGroup', countryGroup);
  url.searchParams.set('country', country);
  url.searchParams.set('isPreview', 'true');

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerOfferDetailsGetContract['responses']['200']['content']['application/json']) => {
      return mapHeroPlannerOfferDetails(response.result);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export type HeroPlannerOfferDetailsGetRequestParams = Parameters<typeof requestGetHeroPlannerOfferDetails>;

export async function requestGetMultipleHeroPlannerOfferDetails(
  brand: string,
  countryGroup: string,
  country: string,
  offerIds: Array<string>,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!offerIds.length) throw 'missing offerIds';

  const promises = offerIds.map((id) => requestGetHeroPlannerOfferDetails(brand, countryGroup, country, id));

  return Promise.all(promises);
}

export default requestGetHeroPlannerOfferDetails;
