import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Autocomplete, FormControl, TextField } from '@mui/material';

import CruiseLineService, { CruiseLine } from '~/services/cruises/CruiseLineService';

type CruiseLinesResponse = {
  total: number;
  result: CruiseLine[];
};

type Option = {
  value: string;
  label: string;
  name?: string;
};

type SelectedCruiseLine = Pick<CruiseLine, 'id' | 'code' | 'name'>;

const DEFAULT_RESPONSE: CruiseLinesResponse = { result: [], total: 0 };

export type Props = {
  selectedCruiseLine: SelectedCruiseLine;
  onChange: (cruiseLine: SelectedCruiseLine) => void;
};

export default function CruiseLineAutocomplete(props: Props) {
  const { selectedCruiseLine, onChange } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');

  const [cruiseLines, setCruiseLines] = useState<CruiseLinesResponse>(DEFAULT_RESPONSE);

  const fetchCruiseLines = useCallback(async (): Promise<void> => {
    setFetching('loading');

    try {
      const response = await CruiseLineService.listWithPagination({
        skip: 0,
        take: 100,
      });

      setFetching('success');

      if (!response || response.result.length === 0) {
        enqueueSnackbar('No cruise lines found', { autoHideDuration: 5000, variant: 'warning' });
      }

      setCruiseLines({
        total: response.total,
        result: response.result,
      });
    } catch (error) {
      setFetching('failed');
      enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

      setCruiseLines({ total: 0, result: [] });
    }
  }, [enqueueSnackbar]);

  const onCruiseLineChange = useCallback(
    (_, selectedOption: Option) => {
      onChange({
        id: selectedOption?.value ?? '',
        name: selectedOption?.name ?? '',
        code: selectedOption?.label ?? '',
      });
    },
    [onChange],
  );

  const cruiseLinesOptions = useMemo(() => {
    return cruiseLines.result?.map((item) => ({
      value: item.id,
      name: item.name,
      label: `${item.code} | ${item.name}`,
    }));
  }, [cruiseLines]);

  const cruiseLineSelected = useMemo(() => {
    return {
      value: selectedCruiseLine.id,
      label: selectedCruiseLine.code,
    };
  }, [selectedCruiseLine]);

  useEffect(() => {
    fetchCruiseLines();
  }, [fetchCruiseLines]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        size="small"
        aria-required
        id="select-cruise-line"
        value={cruiseLineSelected}
        options={cruiseLinesOptions}
        onChange={onCruiseLineChange}
        loading={fetching === 'loading'}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} fullWidth label="Select Cruise Line" placeholder={'Please enter a Cruise Line'} />
        )}
      />
    </FormControl>
  );
}
