import React, { useCallback, useEffect } from 'react';

import {
  Box,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Input from '@mui/material/Input';

import SentryFeedbackForm from '~/utils/SentryFeedbackForm';

import SpeedNavigationRecentlyUsed from './SpeedNavigationRecentlyUsed';
import { useSpeedNavigationOptions } from './useSpeedNavigationOptions';

const sx: SxProps = {
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    marginBlockStart: '50px',
  },
};

function SpeedNavigation() {
  const [open, setOpen] = React.useState(false);

  const {
    inputValue,
    onOpen,
    onChange,
    options,
    inputMessage,
    recentlyUsedPrompts,
    deleteRecentPrompt,
    deleteAllRecentPrompts,
    inputRef,
  } = useSpeedNavigationOptions('');

  const handleNavOpen = useCallback(() => {
    onOpen();
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const selectOpt = (opt) => {
    if (opt === undefined) {
      return;
    }

    if (inputRef && inputRef.current && !opt.isLink) {
      inputRef.current.focus();
    } else {
      opt.action();
      handleClose();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'p' && document.body === document.activeElement) {
        event.preventDefault();
        handleNavOpen();
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
        onOpen();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleNavOpen]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      selectOpt(options[0]);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        sx={sx}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        keepMounted
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <Input
          sx={{
            padding: '16px',
            fontSize: '1rem',
            fontWeight: 500,
            appearance: 'none',
            background: 'transparent',
            border: 0,
            flex: 1,
            font: 'inherit',
            height: '100%',
            outline: 'none',
            paddingInlineEnd: '70px',
          }}
          inputRef={inputRef}
          placeholder="What are you looking for?"
          required={inputMessage.length > 0}
          margin="dense"
          id="prompt"
          name="prompt"
          fullWidth
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          type="text"
          value={inputValue}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          error={inputMessage.length > 0}
          endAdornment={
            inputMessage.length > 0 && (
              <FormHelperText sx={{ whiteSpace: 'nowrap' }} error>
                {inputMessage}
              </FormHelperText>
            )
          }
        />
        <Button
          sx={{
            position: 'absolute',
            right: 0,
            top: '10px',
          }}
          onClick={handleClose}
        >
          esc
        </Button>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <List>
                {options.length == 0 && (
                  <SentryFeedbackForm feedbackType="speed" description="suggest new speed navigation option:" />
                )}
                {options.map((opt) => {
                  const labelId = `checkbox-list-label-${opt.key}`;
                  return (
                    <ListItem key={opt.key} secondaryAction={<>{opt.isLink && <>🔗</>}</>} disablePadding>
                      <ListItemButton role="submit" onClick={() => selectOpt(opt)} dense>
                        <ListItemIcon>{opt.key}</ListItemIcon>
                        <Box
                          component="span"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <ListItemText id={labelId} primary={opt.description} title={opt.subtitle ?? ''} />
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={4}>
              <SpeedNavigationRecentlyUsed
                recentlyUsedPrompts={recentlyUsedPrompts}
                deletePrompt={deleteRecentPrompt}
                deleteAllPrompt={deleteAllRecentPrompts}
                setInputValue={(newValue) => onChange(newValue)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default SpeedNavigation;
