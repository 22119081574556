import React from 'react';

import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';
import QuickSearchToolbar from '~/components/Common/Elements/QuickSearchToolbar';

import { getFDATableActionButtons, getFDATableIDButton } from './FDATableUtils';

interface Props {
  columns: {
    field: string;
    headerName: string;
  }[];
  loading?: boolean;
  data: any[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onView: (id: string) => void;
}

function FDATable(props: Props) {
  const { columns, data, loading, onDelete, onEdit, onView } = props;

  const dataColumns: GridColDef[] = [
    ...columns.map((c) => ({
      ...c,
      flex: 1,
      minWidth: 120,
      renderCell: c.field === 'Id' ? ({ row }) => getFDATableIDButton(row, onView) : undefined,
    })),
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => getFDATableActionButtons(row, onDelete, onEdit),
      width: 130,
      minWidth: 130,
    },
  ];

  return (
    <Box height="800px">
      <DataGridPro
        columns={dataColumns}
        loading={loading}
        pagination
        paginationMode="client"
        getRowId={(row) => row.Id}
        slots={{
          pagination: GridPagination,
          toolbar: QuickSearchToolbar,
        }}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        disableRowSelectionOnClick
        disableColumnMenu
        rows={data}
        density="compact"
      />
    </Box>
  );
}

export default React.memo(FDATable);
