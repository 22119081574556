export const TYPE_NIGHTLY = 'nightly';

export const METRIC_DESCRIPTIONS = {
  inventoryPerProperty: 'Inventory available per Property over the range tested',
  inventoryPerRoomRate: 'Inventory available per Room Rate over the range tested',
  inventoryPerRoomType: 'Inventory available per Room Type over the range tested',
  activeRoomRatesPerProperty: 'Total active Room Rates per Property over the range tested',
  activeRoomTypesPerProperty: 'Total active Room Rates per Property over the range tested',
  newlyInactiveRoomRate: 'Room Rates that were previously active, but now have no inventory over the range tested',
  newlyInactiveRoomType: 'Room Rates that were previously active, but now have no inventory over the range tested',
};

export const METRIC_NAMES = {
  inventoryPerProperty: 'Inventory per Property',
  inventoryPerRoomRate: 'Inventory per Room Rate',
  inventoryPerRoomType: 'Inventory per Room Type',
  activeRoomRatesPerProperty: 'Active Room Rates per Property',
  activeRoomTypesPerProperty: 'Active Room Rates per Property',
  newlyInactiveRoomRate: 'Newly inactive Room Rates',
  newlyInactiveRoomType: 'Newly inactive Room Types',
};

export const METRIC_ID_NAMES = {
  inventoryPerProperty: 'Property Id',
  inventoryPerRoomRate: 'Room Rate Id',
  inventoryPerRoomType: 'Room Type Id',
  activeRoomRatesPerProperty: 'Property Id',
  activeRoomTypesPerProperty: 'Property Id',
  newlyInactiveRoomRate: 'Room Rates Id',
  newlyInactiveRoomType: 'Room Types Id',
};
