import React from 'react';

import { Helmet } from 'react-helmet';
import { UserProfileForm } from '~/stormpath';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

export default class ProfilePage extends React.Component {
  render() {
    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>My Account</title>
        </Helmet>

        <PageHeader title="My Account" />

        <UserProfileForm />
      </Container>
    );
  }
}
