import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import dayjs from '~/timeInit';

class DateTimeWidget extends Component {
  constructor(props) {
    super(props);
    dayjs.tz.setDefault(props.defaultTimezone);
    this.onChange = this.onChange.bind(this);
  }

  onChange(dateTime) {
    let newDate = dayjs(dateTime[0]);
    this.props.onChange(newDate.format());
  }

  render() {
    let cssClass = 'flatpickr-form-control';
    let options = {
      enableTime: true,
      defaultDate: dayjs(this.props.value).format(),
      altInput: true,
      altFormat: 'D d/m/Y h:i K',
      static: this.props.static,
    };

    if (this.props.maxDate) {
      options.maxDate = this.props.maxDate;
    }

    if (this.props.disabled) {
      options.clickOpens = false;
      cssClass += ' flatpickr-disabled';
    }

    if (this.props.minDate) {
      options.minDate = dayjs(this.props.minDate).format();
    }

    let key = 'flatpickr';
    if (this.props.disabled) {
      key += '-disabled';
    }
    // If you're wondering why we need to create this key:
    // It turns out there's a really annoying bug with flatpickr (https://github.com/chmln/flatpickr/issues/597);
    // trying to change the clickOpens option after creation doesn't work.
    // This is compounded by the fact that react is smart enough to reuse components even if the props or classes or whatnot passed in change.
    // Another issue is that flatpickr is designed to receive an initial date and handle its own state from there,
    // whereas we want it to behave like a stateless component.
    // By explicitly setting a key, react is forced to create a new element, solving both problems.
    // It doesn't matter what the key is, as long it changes when the "disabled" prop changes.
    return (
      <Flatpickr
        className={cssClass}
        onChange={this.onChange}
        value={this.props.value}
        options={options}
        key={key}
        data-enable-time
      />
    );
  }
}

DateTimeWidget.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  disabled: PropTypes.bool,
  static: PropTypes.bool,
  defaultTimezone: PropTypes.string,
  maxDate: PropTypes.string,
};

DateTimeWidget.defaultProps = {
  onChange: () => {}, // eslint-disable-line no-empty-function
  static: false,
  defaultTimezone: window.configs.DEFAULT_TIMEZONE,
};

export default DateTimeWidget;
