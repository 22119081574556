import React, { useState } from 'react';

import OfferSearchForm from '~/components/Common/Forms/OfferSearchForm';
import OfferList from '~/components/Common/OfferListContainer';

interface Props {
  onRowClickHandler: (value: string) => void;
}

export default function OfferSearchField({ onRowClickHandler }: Props) {
  const [searchString, setSearchString] = useState('');
  const searchQuery = (searchString: string) => {
    const query = `q=${searchString}`;

    setSearchString(query);
  };

  const offerSearchOnRowCLick = (offer: App.Offer) => {
    onRowClickHandler(offer.id_salesforce_external);
  };

  return (
    <>
      <OfferSearchForm minimal destinations={[]} searchQuery={searchQuery} />
      <OfferList onRowClick={offerSearchOnRowCLick} searchString={searchString} showCountMessage />
    </>
  );
}
