import * as ES from '../../services/ExperiencesService';

import { themes } from './themes';

type StatusCurated = {
  color: string;
  title: string;
};

export const getStatusByCurated = (curated: ES.CurationStatus): StatusCurated => {
  if (curated === 'REJECTED') return { title: 'REJECTED', color: themes?.primaryRed };

  if (curated === 'APPROVED') return { title: 'APPROVED', color: themes?.primaryGreen };

  return { title: 'NOT CURATED', color: themes?.primaryGrey };
};

export type StatusOptions = {
  name: string;
  value: ES.CurationStatus;
};

export const statusOptions: StatusOptions[] = [
  { name: 'Approved', value: 'APPROVED' },
  { name: 'Rejected', value: 'REJECTED' },
  { name: 'Not Curated', value: 'NOT_CURATED' },
];
