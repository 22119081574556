interface ButtonStates {
  [key: string]: 'warning' | 'inherit' | 'error' | 'primary' | 'secondary' | 'info' | 'success';
}
const buttonStates: ButtonStates = {
  default: 'primary',
  saving: 'info',
  saved: 'success',
  failed: 'error',
  unsaved: 'primary',
  loaded: 'secondary',
};

const buttonMessages = {};

buttonMessages[buttonStates.default] = 'Save';
buttonMessages[buttonStates.saving] = 'Saving...';
buttonMessages[buttonStates.saved] = 'Saved';
buttonMessages[buttonStates.failed] = 'Failed to save';
buttonMessages[buttonStates.unsaved] = 'Save';
buttonMessages[buttonStates.loaded] = 'Saved';

export { buttonStates, buttonMessages };
