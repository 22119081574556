import React from 'react';

import styled from 'styled-components';

import { Button, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

import {
  addFlightDeal,
  deleteFlightDeal,
  getDealFromId,
  structureTravelDaysForApi,
  transformTravelDaysFromApi,
  updateFlightDeal,
  validateTravelDays,
} from '~/services/FlightsService';
import { isAfter } from '~/services/TimeService';

import ErrorDisplay from '../../components/Common/ErrorDisplay';
import DateTimeWidget from '../../components/Common/Forms/DateTimeWidget';
import DateWidget from '../../components/Common/Forms/DateWidget';
import ImagesForm from '../../components/Common/Forms/ImagesForm';
import Image from '../../components/Common/Image';
import ConfirmButton from '../Common/Elements/ConfirmButton';
import Spinner from '../Common/Spinner';

const UpdateButton = styled(Button)`
  margin-left: 12px;
`;

const CloneButton = styled(Button)`
  min-width: 96px;

  > * {
    margin-left: 8px;
  }
`;

const Filename = styled.div`
  padding-bottom: '5px';
`;

class FlightDealsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      refreshKey: 1,
      errors: [],
      carrierCode: '',
      region: '',
      originAirportCode: '',
      destinationAirportCode: '',
      fareType: '',
      perAdultPrice: '',
      searchText: 'View flights',
      salesStartDate: '',
      salesEndDate: '',
      dealName: '',
      dealDescription: '',
      categoryGroup: '',
      fareClass: '',
      bookingClassCode: '',
      baggageIncluded: true,
      farename: '',
      adultCount: 1,
      fareBasis: '',
      discountLabel: 'Sale',
      departureDate1: '',
      arrivalDate1: '',
      departureDate2: '',
      arrivalDate2: '',
      departureDate3: '',
      arrivalDate3: '',
      cloningDeal: false,
      deletingDeal: false,
      termsAndConditions: '',
      travelDays: '',
      heroImage: null,
      selectingImage: false,
      hierarchy: null,
    };

    this.onAddImage = this.onAddImage.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);

    this.handleHeroImageButtonClick = this.handleHeroImageButtonClick.bind(this);
    this.handleDealSubmit = this.handleDealSubmit.bind(this);
  }

  handleHeroImageButtonClick(event) {
    event.preventDefault();
    this.setState({ selectingImage: true });
  }

  onAddImage(cloudinaryId, filename) {
    const image = { publicImageId: cloudinaryId, filename };
    return new Promise((resolve) => {
      this.setState({ heroImage: image, selectingImage: false });
      resolve();
    });
  }

  onDeleteImage() {
    return new Promise((resolve) => {
      this.setState({ heroImage: null, selectingImage: false });
      resolve();
    });
  }

  async handleDealSubmit(event) {
    event.preventDefault();
    const { match } = this.props;
    const dealId = match.params.id;
    const deal = {
      carrierCode: this.state.carrierCode,
      region: this.state.region,
      originAirportCode: this.state.originAirportCode,
      destinationAirportCode: this.state.destinationAirportCode,
      fareType: this.state.fareType,
      perAdultPrice: parseFloat(this.state.perAdultPrice),
      searchText: this.state.searchText,
      salesStartDate: new Date(this.state.salesStartDate).toISOString(),
      salesEndDate: new Date(this.state.salesEndDate).toISOString(),
      dealName: this.state.dealName,
      dealDescription: this.state.dealDescription,
      heroImagePublicId: this.state.heroImage?.publicImageId ?? null,
      categoryGroup: this.state.categoryGroup,
      fareClass: this.state.fareClass,
      bookingClassCode: this.state.bookingClassCode,
      baggageIncluded: this.state.baggageIncluded,
      fareName: this.state.farename,
      adultCount: parseInt(this.state.adultCount),
      fareBasis: this.state.fareBasis,
      offerDurations: this.getDurations(),
      discountLabel: this.state.discountLabel,
      termsAndConditions: this.state.termsAndConditions,
      travelDays: structureTravelDaysForApi(this.state.travelDays),
      hierarchy: parseInt(this.state.hierarchy),
    };

    if (this.isFormValid(deal)) {
      try {
        const flightDealResponse = await updateFlightDeal(deal, dealId);
        if (flightDealResponse.errors) {
          const errors = [];
          errors.push(flightDealResponse.errors.message);
          this.setState({
            success: false,
            errors,
          });
        } else {
          this.setState({
            success: true,
            errors: [],
          });
          this.fetchData();
        }
      } catch (e) {
        const errors = [];
        errors.push(e.message);
        this.setState({
          errors: errors,
          success: false,
        });
      }
    }
  }

  isFormValid = (deal) => {
    let errorList = [];
    if (
      !deal.region ||
      !deal.carrierCode ||
      !deal.originAirportCode ||
      !deal.destinationAirportCode ||
      !deal.fareType ||
      deal.offerDurations.length === 0 ||
      !deal.perAdultPrice ||
      !deal.searchText ||
      !deal.salesStartDate ||
      !deal.salesEndDate ||
      !deal.categoryGroup ||
      !deal.bookingClassCode ||
      !deal.termsAndConditions ||
      !deal.hierarchy ||
      !this.state.departureDate1 ||
      !this.state.arrivalDate1
    ) {
      errorList.push('Please fill all mandatory fields');
    }

    if (!validateTravelDays(deal.travelDays)) {
      errorList.push('Travel days should contain either a comma separated list of days, "daily" or "twice daily"');
    }

    if (isAfter(this.state.salesStartDate, this.state.salesEndDate)) {
      errorList.push('Sale end date should not be before start date');
    }

    if (isAfter(this.state.departureDate1, this.state.arrivalDate1)) {
      errorList.push('First departure date should not be before first arrival date');
    }

    if (isAfter(this.state.departureDate2, this.state.arrivalDate2)) {
      errorList.push('Second departure date should not be before second arrival date');
    }

    if (isAfter(this.state.departureDate3, this.state.arrivalDate3)) {
      errorList.push('Third departure date should not be before third arrival date');
    }

    if (this.state.departureDate2 && !this.state.arrivalDate2) {
      errorList.push("Second departure date can't be blank");
    }

    if (!this.state.departureDate2 && this.state.arrivalDate2) {
      errorList.push("Second arrival date can't be blank");
    }

    if (this.state.departureDate3 && !this.state.arrivalDate3) {
      errorList.push("Third departure date can't be blank");
    }

    if (!this.state.departureDate3 && this.state.arrivalDate3) {
      errorList.push("Third arrival date can't be blank");
    }

    if (this.state.departureDate3 && this.state.arrivalDate3 && !this.state.departureDate2) {
      errorList.push('Should add second departure and arrival date before adding third date range');
    }

    if (errorList.length > 0) {
      this.setState({
        errors: errorList,
        success: false,
      });
      return false;
    }
    return true;
  };

  getDurations = () => {
    let { departureDate1, arrivalDate1, departureDate2, arrivalDate2, departureDate3, arrivalDate3 } = this.state;

    let durationsArray = [];

    if (departureDate1 !== '' && arrivalDate1 !== '' && arrivalDate1) {
      durationsArray.push({
        departureDate: departureDate1,
        arrivalDate: arrivalDate1,
      });
    }
    if (departureDate2 !== '' && arrivalDate2 !== '' && arrivalDate2) {
      durationsArray.push({
        departureDate: departureDate2,
        arrivalDate: arrivalDate2,
      });
    }
    if (departureDate3 !== '' && arrivalDate3 !== '' && arrivalDate3) {
      durationsArray.push({
        departureDate: departureDate3,
        arrivalDate: arrivalDate3,
      });
    }

    return durationsArray;
  };

  async fetchData() {
    try {
      const { match } = this.props;
      const dealId = match.params.id;
      const response = await getDealFromId(dealId);

      const {
        carrierCode,
        dealName,
        dealDescription,
        heroImagePublicId,
        categoryGroup,
        region,
        perAdultPrice,
        salesStartDate,
        salesEndDate,
        originAirportCode,
        destinationAirportCode,
        fareType,
        fareClass,
        bookingClassCode,
        discountLabel,
        offerDuration,
        termsAndConditions,
        travelDays,
        hierarchy,
      } = response.result;

      this.setState({
        loading: false,
        error: null,
        carrierCode,
        dealName,
        dealDescription,
        heroImage: { publicImageId: heroImagePublicId },
        categoryGroup,
        region,
        perAdultPrice,
        salesStartDate: new Date(salesStartDate),
        salesEndDate: new Date(salesEndDate),
        originAirportCode,
        destinationAirportCode,
        fareType,
        fareClass,
        bookingClassCode,
        discountLabel,
        departureDate1: offerDuration[0].departureDate,
        arrivalDate1: offerDuration[0].arrivalDate,
        departureDate2: offerDuration[1].departureDate,
        arrivalDate2: offerDuration[1].arrivalDate,
        departureDate3: offerDuration[2].departureDate,
        arrivalDate3: offerDuration[2].arrivalDate,
        termsAndConditions,
        travelDays: transformTravelDaysFromApi(travelDays),
        hierarchy,
        refreshKey: this.state.refreshKey + 1,
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err.message,
        listData: [],
      });
    }
  }

  handleTextFieldChange = (fieldName) => (event) => {
    this.setState({
      [fieldName]: event.target.value,
      error: false,
      success: false,
    });
  };

  handleMarkdownEditorChange = (fieldName) => (value) => {
    if (typeof value !== 'string') {
      return;
    }
    this.setState({
      [fieldName]: value,
      error: false,
      success: false,
    });
  };

  handleDepartureDateOneChange = (selectedDate) => {
    this.setState({
      departureDate1: selectedDate,
      error: false,
      success: false,
    });
  };

  handleDepartureDateTwoChange = (selectedDate) => {
    this.setState({
      departureDate2: selectedDate,
      error: false,
      success: false,
    });
  };

  handleDepartureDateThreeChange = (selectedDate) => {
    this.setState({
      departureDate3: selectedDate,
      error: false,
      success: false,
    });
  };

  handleArrivalDateOneChange = (selectedDate) => {
    this.setState({
      arrivalDate1: selectedDate,
      error: false,
      success: false,
    });
  };

  handleArrivalDateTwoChange = (selectedDate) => {
    this.setState({
      arrivalDate2: selectedDate,
      error: false,
      success: false,
    });
  };

  handleArrivalDateThreeChange = (selectedDate) => {
    this.setState({
      arrivalDate3: selectedDate,
      error: false,
      success: false,
    });
  };

  handleSaleEndDateChange = (selectedDate) => {
    this.setState({
      salesEndDate: selectedDate,
      error: false,
      success: false,
    });
  };

  handleSaleStartDateChange = (selectedDate) => {
    this.setState({
      salesStartDate: selectedDate,
      error: false,
      success: false,
    });
  };

  onCloneDeal = async () => {
    try {
      this.setState({ cloningDeal: true });
      const dealId = this.props.match.params.id;
      const flightDealResponse = await getDealFromId(dealId);

      const {
        adultCount,
        carrierCode,
        categoryGroup,
        dealName,
        dealDescription,
        heroImagePublicId,
        destinationAirportCode,
        discountLabel,
        fareClass,
        bookingClassCode,
        fareType,
        offerDuration,
        originAirportCode,
        perAdultPrice,
        region,
        salesStartDate,
        salesEndDate,
        termsAndConditions,
        travelDays,
        hierarchy,
      } = flightDealResponse.result;

      const offerDurations = offerDuration.filter((duration) => duration.departureDate !== null);

      const createReqParams = {
        carrierCode,
        region,
        originAirportCode,
        destinationAirportCode,
        fareType,
        perAdultPrice: Number(perAdultPrice),
        salesStartDate,
        salesEndDate,
        dealName,
        heroImagePublicId,
        categoryGroup,
        fareClass,
        bookingClassCode,
        adultCount,
        discountLabel,
        searchText: 'View flights',
        dealDescription,
        baggageIncluded: true,
        fareName: '',
        fareBasis: '',
        offerDurations,
        termsAndConditions,
        travelDays,
        hierarchy,
      };

      // Redirect to a new page with cloned deal
      const clonedDealRes = await addFlightDeal(createReqParams);
      const clonedDealId = clonedDealRes.result.id;
      this.setState({ cloningDeal: false });

      window.open(`/flights/flight-deal/${clonedDealId}/edit`, '_blank');
    } catch (err) {
      this.setState({
        cloningDeal: false,
        error: err.message,
      });
    }
  };

  onDeleteDeal = async () => {
    this.setState({ deletingDeal: true });
    await deleteFlightDeal(this.props.match.params.id);
    this.setState({ deletingDeal: false });

    window.open('/flights', '_self');
  };

  async componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Container>
        <Typography variant="h3" mb={2}>
          Edit Deal
        </Typography>
        <Grid container spacing={2}>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Category Name"
              required
              onChange={this.handleTextFieldChange('categoryGroup')}
              value={this.state.categoryGroup}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Deal Name"
              required
              onChange={this.handleTextFieldChange('dealName')}
              value={this.state.dealName}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Region"
              required
              onChange={this.handleTextFieldChange('region')}
              value={this.state.region}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Per Adult Price"
              required
              type="number"
              onChange={this.handleTextFieldChange('perAdultPrice')}
              value={this.state.perAdultPrice}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Product Tag"
              required
              onChange={this.handleTextFieldChange('discountLabel')}
              value={this.state.discountLabel}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Hierarchy"
              onChange={this.handleTextFieldChange('hierarchy')}
              type="number"
              value={this.state.hierarchy}
              required
            />
          </Grid>
          <Grid md={4}>
            <Typography>Start Date *</Typography>
            <DateTimeWidget
              key={this.state.salesStartDate}
              onChange={this.handleSaleStartDateChange}
              value={this.state.salesStartDate}
            />
          </Grid>
          <Grid md={4}>
            <Typography>End Date *</Typography>
            <DateTimeWidget
              key={this.state.salesEndDate}
              onChange={this.handleSaleEndDateChange}
              value={this.state.salesEndDate}
            />
          </Grid>
          <Grid md={12}>
            <Typography>Campaign Description</Typography>
            <MarkdownEditor
              key={`dealDescription_${this.state.refreshKey}`}
              value={this.state.dealDescription}
              onChange={this.handleMarkdownEditorChange('dealDescription')}
            />
          </Grid>
          <Grid md={12}>
            <Typography>Hero Image</Typography>
          </Grid>
          {!this.state.selectingImage ? (
            <>
              <Grid md={2}>
                <Button onClick={this.handleHeroImageButtonClick} variant="contained">
                  {this.state.heroImage ? 'Update Image' : 'Add Image'}
                </Button>
              </Grid>
              {this.state.heroImage ? (
                <Grid md={3}>
                  <Image className="img-responsive" publicId={this.state.heroImage.publicImageId} options={{}} />
                  <Filename>{this.state.heroImage.filename}</Filename>
                </Grid>
              ) : (
                <Grid md={3} />
              )}
            </>
          ) : (
            <Grid md={8}>
              <ImagesForm
                images={this.state.heroImage ? [this.state.heroImage] : []}
                onAddImage={this.onAddImage}
                onUpdateImages={() => {}}
                onDeleteImage={this.onDeleteImage}
                showSaveButton={false}
              />
            </Grid>
          )}
          <Grid md={12}>
            <Typography>Terms & Conditions *</Typography>
            <MarkdownEditor
              key={`termsAndConditions_${this.state.refreshKey}`}
              value={this.state.termsAndConditions}
              onChange={this.handleMarkdownEditorChange('termsAndConditions')}
            />
          </Grid>
          <Grid md={12}>
            <Typography variant="h4">Flight Information</Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Departure Airport Code"
              required
              onChange={this.handleTextFieldChange('originAirportCode')}
              value={this.state.originAirportCode}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Arrival Airport Code"
              required
              onChange={this.handleTextFieldChange('destinationAirportCode')}
              value={this.state.destinationAirportCode}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Airline Code"
              required
              onChange={this.handleTextFieldChange('carrierCode')}
              value={this.state.carrierCode}
            />
          </Grid>
          <Grid md={4}>
            <FormControl required fullWidth>
              <InputLabel id="demo-simple-select-label">Fare Type</InputLabel>
              <Select
                componentClass="select"
                key={this.state.fareType}
                label="Fare Type"
                labelId="demo-simple-select-label"
                onChange={this.handleTextFieldChange('fareType')}
                placeholder="select"
                value={this.state.fareType}
              >
                <MenuItem value="oneWay">One Way</MenuItem>
                <MenuItem value="return">Return</MenuItem>
                <MenuItem value="multiCity">Multi City</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl required fullWidth>
              <InputLabel id="fare-class-label">Fare Class</InputLabel>
              <Select
                componentClass="select"
                key={this.state.fareClass}
                label="Fare Type"
                labelId="fare-class-label"
                onChange={this.handleTextFieldChange('fareClass')}
                placeholder="select"
                value={this.state.fareClass}
              >
                <MenuItem value="Economy">Economy</MenuItem>
                <MenuItem value="Business">Business</MenuItem>
                <MenuItem value="Premium">Premium</MenuItem>
                <MenuItem value="First">First</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Booking Class Code"
              required
              onChange={this.handleTextFieldChange('bookingClassCode')}
              value={this.state.bookingClassCode}
            />
          </Grid>
          <Grid md={4}>
            <TextField
              fullWidth
              label="Travel Days"
              onChange={this.handleTextFieldChange('travelDays')}
              value={this.state.travelDays}
            />
          </Grid>
          <Grid md={12}>
            <Typography variant="h4">Departure & Arrival Date</Typography>
          </Grid>
          <Grid md={3}>
            <Typography>First Departure Date & Arrival Date*</Typography>
            <DateWidget
              key={this.state.departureDate1}
              onChange={this.handleDepartureDateOneChange}
              value={this.state.departureDate1 ? this.state.departureDate1 : null}
            />
          </Grid>
          <Grid md={3}>
            <Typography>&emsp;</Typography>
            <DateWidget
              key={this.state.arrivalDate1}
              onChange={this.handleArrivalDateOneChange}
              value={this.state.arrivalDate1 ? this.state.arrivalDate1 : null}
            />
          </Grid>
          <Grid md={6} />
          <Grid md={3}>
            <Typography>Second Departure Date & Arrival Date</Typography>
            <DateWidget
              key={this.state.departureDate2}
              onChange={this.handleDepartureDateTwoChange}
              value={this.state.departureDate2 ? this.state.departureDate2 : null}
            />
          </Grid>
          <Grid md={3}>
            <Typography>&emsp;</Typography>
            <DateWidget
              key={this.state.departureDate2}
              onChange={this.handleArrivalDateTwoChange}
              value={this.state.arrivalDate2 ? this.state.arrivalDate2 : null}
            />
          </Grid>
          <Grid md={6} />
          <Grid md={3}>
            <Typography>Third Departure Date & Arrival Date</Typography>
            <DateWidget
              key={this.state.departureDate3}
              onChange={this.handleDepartureDateThreeChange}
              value={this.state.departureDate3 ? this.state.departureDate3 : null}
            />
          </Grid>
          <Grid md={3}>
            <Typography>&emsp;</Typography>
            <DateWidget
              key={this.state.arrivalDate3}
              onChange={this.handleArrivalDateThreeChange}
              value={this.state.arrivalDate3 ? this.state.arrivalDate3 : null}
            />
          </Grid>
          <Grid md={6} />
        </Grid>
        <hr />
        <div className="clearfix">
          <UpdateButton
            className="pull-right"
            type="submit"
            onClick={this.handleDealSubmit}
            variant="contained"
            size="large"
          >
            Update
          </UpdateButton>
          <ConfirmButton
            confirmTitle="Delete deal"
            confirmQuestion="Are you sure you want to delete this deal?"
            confirmAnswer="Yes, delete"
            className="pull-right"
            onConfirm={this.onDeleteDeal}
            variant="contained"
            size="large"
            color="error"
          >
            Delete
            {this.state.deletingDeal && <Spinner size={25} />}
          </ConfirmButton>
          <CloneButton className="pull-right" onClick={this.onCloneDeal} size="large" disabled={this.state.cloningDeal}>
            Clone
            {this.state.cloningDeal && <Spinner size={25} />}
          </CloneButton>
        </div>
        <br />
        {this.state.errors.map((error) => (
          <ErrorDisplay key={error} message={`Error: ${error}`} />
        ))}
      </Container>
    );
  }
}

export default FlightDealsEdit;
