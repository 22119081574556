import type { Action, ActionCreator } from 'redux';

import type { UserType } from '~/types/responses';

export const SET_CURRENT_USER_ACTION = 'SET_CURRENT_USER';
// const LOGIN = 'LOGIN';
// const LOGOUT = 'LOGOUT';

export type SetCurrentUserPayload = { isLoading: boolean; isAuthenticated: boolean; user: UserType | null };

export const setCurrentUser: ActionCreator<Action<'SET_CURRENT_USER'> & { payload: SetCurrentUserPayload }> = ({
  isLoading,
  isAuthenticated,
  user,
}) => {
  return {
    type: SET_CURRENT_USER_ACTION,
    payload: { isLoading, isAuthenticated, user },
  };
};
