import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Radio,
  Stack,
} from '@mui/material';

import { updateChat } from '~/services/SupportService';

import { Input, RadioGroup, Select } from '../../Common/Forms/fields';
import { Chat, ChatQuality } from '../types/Chat';

type QualityAssuranceModalProps = {
  chatQuality: ChatQuality;
  onUpdatingChat: (value: boolean) => void;
  onUpdateChatQuality: (value: ChatQuality) => void;
  chat: Chat;
};

const requiredFollowUpSchema = object({
  team: string().required('Please select a team'),
  message: string().required('Please leave a comment'),
});

const noFollowUpSchema = object({
  team: string().nullable(),
  message: string().nullable(),
});

export function QualityAssuranceModal(props: QualityAssuranceModalProps) {
  const { chatQuality, onUpdatingChat, onUpdateChatQuality, chat } = props;
  const requiredFollowUp = chatQuality === ChatQuality.LOW;

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      team: chat.followUp?.team || null,
      message: chat.followUp?.message || null,
      status: chat.followUp?.status || 'ACTIVE',
    },
    mode: 'onBlur',
    resolver: requiredFollowUp ? yupResolver(requiredFollowUpSchema) : yupResolver(noFollowUpSchema),
  });

  const onSubmit = useCallback(
    (data) => {
      const followUp = data?.team ? data : null;

      if (chatQuality === ChatQuality.LOW && !data?.team) {
        enqueueSnackbar('Follow Up is required when setting LOW chat quality', { variant: 'warning' });
        return;
      }

      const body = {
        id: chat.id,
        quality: chatQuality,
        followUp,
      };

      updateChat(body).finally(() => onUpdatingChat(false));
    },
    [chat.id, chatQuality, enqueueSnackbar, onUpdatingChat],
  );

  const onCancel = useCallback(() => {
    onUpdatingChat(false);
    onUpdateChatQuality(chat.quality);
  }, [chat.quality, onUpdatingChat, onUpdateChatQuality]);

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="scroll-dialog-title">{'Quality Assurance'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description">
            Please select Team to follow up and leave a comment
          </DialogContentText>
          <Stack direction="column" spacing={2} mt={2}>
            <RadioGroup control={control} name="team">
              <FormControlLabel value="CS" label="CS Follow Up" control={<Radio />} />
              <FormControlLabel value="TECH" label="Tech Follow Up" control={<Radio />} />
              <FormControlLabel value="SALES" label="Sales Follow Up" control={<Radio />} />
            </RadioGroup>
            <Input control={control} name="message" muiLabel="Follow Up comment" rows={4} />
            <Select control={control} muiLabel="Status" name="status">
              <MenuItem key="ACTIVE" value="ACTIVE">
                Active
              </MenuItem>
              <MenuItem key="CLOSED" value="CLOSED">
                Closed
              </MenuItem>
            </Select>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
