import allObjectPropertiesEmpty from '~/utils/allObjectPropertiesEmpty';

// GEO data is associated with a tour or property
// This function receives an object of geo data e.g. { street_number: 201, route: 'Kent St',  country: 'AU' }
// And returns a single human readable string e.g. 123 Kent St, AU

export default function (geoData, typeName) {
  if (!geoData || allObjectPropertiesEmpty(geoData)) {
    return `None - Edit ${typeName} and click location on map to fill in data`;
  }

  let string = '';
  if (geoData.street_number) {
    string += `${geoData.street_number} `;
  }
  [
    'route',
    'administrative_area_level_5',
    'administrative_area_level_4',
    'administrative_area_level_3',
    'administrative_area_level_2',
    'administrative_area_level_1',
  ].forEach((field) => {
    if (geoData[field]) {
      string += `${geoData[field]}, `;
    }
  });
  if (geoData.country) {
    string += geoData.country;
  }

  return string;
}
