import React, { useMemo } from 'react';

import OrderItem from './OrderItem';

const OrderItemList = ({
  order,
  offer,
  items,
  customer,
  travellers,
  getTravellers,
  onShowRefundModal,
  hasAllowedRefund,
  refreshData,
  setIsBookingDates,
  setIsChangingDates,
  setIsConvertingToBNBL,
  setIsPuttingOnHold,
  isBookingDates,
  isChangingDates,
  isConvertingToBNBL,
  history,
  showWarningModal,
}) => {
  const sortedItems = useMemo(
    () => [...items].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    [items],
  );

  return (
    <div className="T-Order-Item-List">
      {sortedItems.map((item, index) => {
        const count = index + 1;
        const disableInteraction =
          isBookingDates ||
          isChangingDates ||
          isConvertingToBNBL ||
          (order.gift_status && order.gift_status !== 'redeemed');

        let traveller;
        if (travellers) {
          traveller = travellers.find((i) => i.booking_number === item.booking_number) || {};
        }

        const offerPackage = offer.packages.find(
          (p) =>
            p.id_salesforce_external === item.offer_package.id_salesforce_external ||
            p.le_package_id === item.offer_package.le_package_id,
        );

        return (
          <OrderItem
            order={order}
            offer={offer}
            offerPackage={offerPackage}
            key={item.id_items}
            item={{ ...item, offer: offer }}
            currencyCode={order.currency_code}
            count={count}
            showRefundModal={onShowRefundModal}
            hasAllowedRefund={hasAllowedRefund}
            customer={customer}
            traveller={traveller}
            getTravellers={getTravellers}
            refreshData={refreshData}
            setIsBookingDates={setIsBookingDates}
            setIsChangingDates={setIsChangingDates}
            setIsConvertingToBNBL={setIsConvertingToBNBL}
            setIsPuttingOnHold={setIsPuttingOnHold}
            disableInteraction={disableInteraction}
            history={history}
            checkInStatus={item.checkInStatus}
            showWarningModal={showWarningModal}
          />
        );
      })}
    </div>
  );
};

export default OrderItemList;
