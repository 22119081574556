import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button, CircularProgress } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { downloadOutsidePolicyDatesRequestsReport } from '~/services/ReportingService';
import { addMonths, formatDateISO, startOfMonth } from '~/services/TimeService';

const formSchema: RJSFSchema = {
  description: 'This report will create a CSV of outside policy dates requests within the specified date range',
  type: 'object',
  properties: {
    startDate: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endDate: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  startDate: {
    'ui:widget': 'date',
  },
  endDate: {
    'ui:widget': 'date',
  },
};

export default function DateChangeOutsidePolicyReportForm() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    startDate: formatDateISO(startOfMonth()),
    endDate: formatDateISO(startOfMonth(addMonths(1))),
  });

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setIsLoading(true);
      setError(null);
      const { startDate, endDate } = event.formData;
      const fileName = `outside_policy_dates_requests_report_${startDate}_${endDate}.csv`;
      const responseText = await downloadOutsidePolicyDatesRequestsReport({
        startAt: startDate,
        endAt: endDate,
      });
      fileDownload(responseText, fileName);
    } catch (e) {
      setError(e.message || e.toString());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      validator={validator}
      onSubmit={handleSubmit}
      showErrorList={false}
      onChange={({ formData }) => setFormData(formData)}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Button type="submit" variant="contained" disabled={isLoading}>
          Download Date Change Report (.csv)
        </Button>
      )}
      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
