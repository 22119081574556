import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerSchedulePushStatusPutContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules/{scheduleId}/push/status/{statusId}']['put'];

async function requestPutHeroPlannerSchedulePushStatus(
  scheduleId: HeroPlannerSchedulePushStatusPutContract['parameters']['path']['scheduleId'],
  statusId: HeroPlannerSchedulePushStatusPutContract['parameters']['path']['statusId'],
) {
  if (!scheduleId) throw 'missing scheduleId';
  if (!statusId) throw 'missing statusId';

  const url = makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/schedules/${scheduleId}/push/status/${statusId}`,
  );

  return request(url.toString(), {
    method: 'PUT',
    credentials: 'include',
  })
    .then((response: HeroPlannerSchedulePushStatusPutContract['responses']['200']['content']['application/json']) => {
      return response.result.pushStatus as CustomerCommunication.HeroPlannerSchedule['pushStatus'];
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestPutHeroPlannerSchedulePushStatus;
