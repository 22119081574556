import React, { Fragment } from 'react';

import classNames from 'clsx';
import { MarkdownPreview } from 'react-marked-markdown';

import VerifiedUserIcon from '@mui/icons-material/AccountBox';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BedIcon from '@mui/icons-material/Bed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/ListAlt';
import MailIcon from '@mui/icons-material/Mail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Box, Button, Link, Stack, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';
import {
  buildCancellationPolicies,
  buildPartiallyRefundableCancellationPolicies,
  formatDate,
} from '@luxuryescapes/lib-refunds';

import OrderTag from '~/components/Common/OrderTag';
import RestrictedComponent from '~/components/Common/RestrictedComponent';
import UserIDPermissionedComponent from '~/components/Common/UserIDPermissionedComponent';
import { currencyFormatter } from '~/components/Experiences/helpers';
import Promotions from '~/components/Purchases/OrderDetail/BedbankList/Promotions';
import CancellationPolicyWarningModal from '~/components/Refund/CancellationPolicyWarningModal';
import RefundV2UI from '~/components/Refund/RefundV2/RefundV2UI';
import { withTenant } from '~/components/hoc';

import { PAYMENT_METHODS_NOT_ALLOWED_REFUND } from '~/consts/payment';
import { REFUND_V2_BETA_TESTERS } from '~/consts/refund';
import { ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import { getRefundRequestsByOrderItemId } from '~/services/OrdersService';
import { datesHaveSameDayMonthYear, formatDateOrdinalShortMonth, isAfter } from '~/services/TimeService';

import { humanize } from '~/utils/humanize';

import OrderItemCredit from '../BusinessTraveller/OrderItemBusinessCredit';
// import InsuranceLetter from '../OrderItem/InsuranceLetter';
import TemplateForCSButton from '../OrderItem/TemplateForCSButton';
import TemplateForCSModal from '../OrderItem/TemplateForCSModal';
import { formatChildrenAges } from '../OrderItem/utils';

import CancellationPolicies from './CancellationPolicies';
import Inclusions from './Inclusions';

function formattedDate(cell) {
  const date = new Date(cell);

  return formatDateOrdinalShortMonth(date);
}

function isSameOrAfter(date1) {
  const now = new Date();
  return datesHaveSameDayMonthYear(now, date1) || isAfter(now, date1);
}

function allowedAutomaticRefund({ order, room, item, cancellationPolicies, hasAllowedRefund, propertyTimezone }) {
  if (!hasAllowedRefund) {
    return false;
  }

  if (!room.refundable && !room.partially_refundable) {
    return false;
  }

  if (order.payments.some((payment) => PAYMENT_METHODS_NOT_ALLOWED_REFUND.includes(payment.type))) {
    return false;
  }

  if (
    cancellationPolicies.policies.length &&
    isSameOrAfter(formatDate.convertDateByTimezone(cancellationPolicies.policies[0]?.start, propertyTimezone))
  ) {
    return false;
  }

  if (isSameOrAfter(item.check_in)) {
    return false;
  }

  return room.status === 'booked';
}

interface Props {
  count: number;
  item: Order.BedbankItem;
  room: Order.BedbankItemRoom;
  order: Order.Order;
  bedbankRoomInfo: {
    status: string;
  };
  currencyCode: string;
  propertyTimezone: string;
  regionCode: string;
  offerUrl: string;
  hasAllowedRefund: boolean;
  disableInteraction: boolean;
  promotions: Array<Order.BedbankOfferPromotion>;
  showRefundModal: (props: { itemId: string; roomId?: string; auto?: boolean; itemType?: string }) => void;
  tenant: App.Tenant;
  enqueueSnackbar: (message: string, options: { variant: 'error' }) => void;
  shouldShowCancellationWarning: boolean;
}

interface State {
  showPartialRefundDetails: boolean;
  isSummaryOpen: boolean;
  isRefundModalOpen: boolean;
  refundRequest?: RefundRequest;
  refundApprover?: string;
  isCancellationWarningModalOpen?: boolean;
}

class OrderItem extends React.Component<Props, State> {
  state = {
    showPartialRefundDetails: false,
    isSummaryOpen: false,
    isRefundModalOpen: false,
    refundRequest: undefined,
    refundApprover: undefined,
    isCancellationWarningModalOpen: false,
  };

  toggleSummary = () => {
    this.setState((prevState) => ({
      isSummaryOpen: !prevState.isSummaryOpen,
    }));
  };

  toggleShowPartialRefundDetails = () => {
    this.setState((prevState) => ({
      showPartialRefundDetails: !prevState.showPartialRefundDetails,
    }));
  };

  onRefundV2Open = () => {
    if (this.props.shouldShowCancellationWarning) {
      this.setState({
        isCancellationWarningModalOpen: true,
      });
    } else {
      this.toggleShowRefundV2Modal();
    }
  };

  toggleShowRefundV2Modal = () => {
    this.setState((prevState) => ({
      isRefundModalOpen: !prevState.isRefundModalOpen,
    }));
  };

  setRefundApprover = (newRefundApprover) => {
    this.setState({
      refundApprover: newRefundApprover,
    });
  };

  componentDidMount() {
    if (this.props.order.id_orders && this.props.item.id) {
      getRefundRequestsByOrderItemId(this.props.order.id_orders, this.props.item.id)
        .then((response) => {
          this.setState({
            refundRequest: response,
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar('Error! Unable fetch appropriate refund request.', {
            variant: 'error',
          });
        });
    }
  }

  render() {
    const {
      count,
      item,
      room,
      order,
      bedbankRoomInfo,
      currencyCode,
      propertyTimezone,
      regionCode,
      offerUrl,
      hasAllowedRefund,
      disableInteraction,
      showRefundModal,
      promotions,
      tenant,
    } = this.props;

    const totalBusinessCreditEarned = order.business_credit_items.reduce((accum, item) => accum + item.total, 0);

    const bookingDetails = {
      'Name:': order.customer_full_name,
      'LE Booking Number:': item.booking_number,
      'Dates:': `${formattedDate(item.check_in)} - ${formattedDate(item.check_out)}`,
      'Email:': order.customer_email,
      'Parent Booking Number:': item.parent_booking_number,
      'Package Type:': item.offer.room.name,
      'Property Name:': item.offer.name,
    };
    const classes = classNames('order-item', {
      cancelled: room.status === 'cancelled',
    });

    const cancellationPolicies = room.partially_refundable
      ? buildPartiallyRefundableCancellationPolicies(
          {
            checkIn: item.check_in,
            checkOut: item.check_out,
            policies: room.cancellation_policies,
            nonRefundableDates: room.nonrefundable_dateranges,
          },
          { timezone: propertyTimezone },
        )
      : buildCancellationPolicies(room.cancellation_policies, {
          regionCode,
          currencyCode,
          timezone: propertyTimezone,
        });

    const hasAllowedAutomaticRefund = allowedAutomaticRefund({
      order,
      item,
      room,
      cancellationPolicies,
      hasAllowedRefund,
      propertyTimezone,
    });

    const mergedFacilities = room.facilities.concat(room.promotions);

    return (
      <Box className={classes}>
        <Stack direction="row" spacing={4}>
          <Box>
            <Typography className="count">{count}</Typography>
          </Box>

          <Box flexGrow={1}>
            <div className="item-header">
              <div className="item-name data-hj-whitelist">
                {room.name}{' '}
                {!room.is_verified && (
                  <Typography component="span" color="error" fontWeight={500}>
                    (Unverified)
                  </Typography>
                )}
              </div>
              <div className="item-info data-hj-whitelist">ID: {room.id}</div>
              <OrderTag items={order.bedbank_items} item={item} />
            </div>
            <div className="item-reservation T-HasReservation">
              <Stack spacing={1}>
                {room.confirmation_id && (
                  <Stack direction="row" spacing={1}>
                    <CheckIcon />
                    <span>{room.confirmation_id}</span>
                  </Stack>
                )}
                <Stack direction="row" spacing={1}>
                  <VerifiedUserIcon />
                  <span className="data-hj-whitelist">{room.number_of_adults} adults</span>
                  <span className="data-hj-whitelist">
                    {room.number_of_children} children{' '}
                    {room.children_ages.length > 0 ? formatChildrenAges(room.children_ages) : ''}
                  </span>
                  <span className="data-hj-whitelist">{room.number_of_infants || 0} infants</span>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <ListIcon />
                  <span>
                    {room.guest_first_name} {room.guest_last_name}
                  </span>
                </Stack>

                {count === 1 && (
                  <>
                    <Stack direction="row" spacing={1}>
                      <MailIcon />
                      <span>{item.booking_email}</span>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <SmartphoneIcon />
                      <span>{item.booking_phone}</span>
                    </Stack>
                  </>
                )}

                <Stack direction="row" spacing={1}>
                  <CalendarMonthIcon />
                  <span className="T-ReservationDate data-hj-whitelist">{formattedDate(item.check_in)}</span>
                  <span>-</span>
                  <span>{formattedDate(item.check_out)}</span>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <CalendarMonthIcon />
                  <span>{item.nights} Night(s)</span>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <HomeIcon />
                  <Link href={offerUrl} target="_blank" rel="noreferrer" underline="hover">
                    {item.offer.name}
                  </Link>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <BedIcon />
                  <span>{room.bed_group.description}</span>
                </Stack>

                <div>
                  <CancellationPolicies
                    room={room}
                    cancellationPolicy={cancellationPolicies}
                    propertyTimezone={propertyTimezone}
                  />
                </div>

                {room.guest_special_requests && (
                  <Stack spacing={1}>
                    <div className="section-header">Special requests</div>
                    <p>{room.guest_special_requests}</p>
                  </Stack>
                )}

                {promotions.length > 0 && (
                  <>
                    <Stack direction="row" spacing={1}>
                      <AttachMoneyIcon />
                      <span>Special Promotions</span>
                    </Stack>
                    <Stack spacing={1}>
                      <Promotions promotions={promotions} />
                    </Stack>
                    <Stack spacing={1}>
                      <div className="section-header">Inclusions</div>
                      <MarkdownPreview value={promotions[0].rate_inclusions_long} />
                    </Stack>
                  </>
                )}

                {mergedFacilities.length > 0 && !promotions.length && (
                  <Box>
                    <Typography pt={1} fontWeight={500}>
                      Inclusions
                    </Typography>
                    <Inclusions facilities={mergedFacilities} />
                  </Box>
                )}
              </Stack>
            </div>
          </Box>

          <Box>
            {!room.rebooked && (
              <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                  Cost: {currencyFormatter(currencyCode, room.cost_price, 2)}
                </Typography>
                <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                  Current Margin: {currencyFormatter(currencyCode, room.price - room.cost_price, 2)} (
                  {(((room.price - room.cost_price) / room.price) * 100).toFixed(2)}%)
                </Typography>
                {this.props.order.business_id !== null && (
                  <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                    Business Credits: {currencyFormatter(currencyCode, totalBusinessCreditEarned)}
                  </Typography>
                )}
                <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                  Sell Price: {currencyFormatter(currencyCode, room.price, 2)}
                </Typography>
              </Box>
            )}

            {room.rebooked && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                  <Typography pt={1} fontWeight={500} sx={{ display: 'flex', justifyContent: 'end' }}>
                    Original
                  </Typography>
                  <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                    Cost: {currencyFormatter(currencyCode, room.rebooked.parent_cost_price)}
                  </Typography>
                  <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                    Margin: {currencyFormatter(currencyCode, room.rebooked.parent_marketing_fee)} (
                    {((room.rebooked.parent_marketing_fee / room.rebooked.parent_price) * 100).toFixed(2)}%)
                  </Typography>
                  <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                    Sell Price: {currencyFormatter(currencyCode, room.rebooked.parent_price)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}>
                  <Typography pt={1} fontWeight={500} sx={{ display: 'flex', justifyContent: 'end' }}>
                    Rebooked
                  </Typography>
                  <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                    Cost: {currencyFormatter(currencyCode, room.rebooked.cost_price)}
                  </Typography>
                  <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                    Margin: {currencyFormatter(currencyCode, room.rebooked.parent_price - room.rebooked.cost_price)} (
                    {(
                      ((room.rebooked.parent_price - room.rebooked.cost_price) / room.rebooked.parent_price) *
                      100
                    ).toFixed(2)}
                    %)
                  </Typography>
                  <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                    Incremental Margin:{' '}
                    {currencyFormatter(
                      currencyCode,
                      room.rebooked.parent_price - room.rebooked.cost_price - room.rebooked.parent_marketing_fee,
                    )}{' '}
                    (
                    {(
                      ((room.rebooked.parent_price - room.rebooked.cost_price - room.rebooked.parent_marketing_fee) /
                        room.rebooked.parent_price) *
                      100
                    ).toFixed(2)}
                    %)
                  </Typography>
                </Box>
              </>
            )}

            {tenant.brand === 'lebusinesstraveller' && (
              <OrderItemCredit
                orderId={item.fk_order_id}
                orderItemId={item.id}
                customerId={order.fk_customer_id}
                location={item.offer.name}
              />
            )}
          </Box>

          <Box className="status-column">
            <Typography>{item.status === 'cancelled' ? 'Cancelled' : humanize(room.status)}</Typography>
            <Typography>
              {bedbankRoomInfo?.status ? `Supplier status: ${humanize(bedbankRoomInfo.status)}` : null}
            </Typography>
          </Box>

          <Box className="refund-column">
            <Stack direction="column" spacing={0.5} alignItems="end">
              <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
                {hasAllowedRefund && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      showRefundModal({ itemId: item.id, roomId: room.id });
                    }}
                    className="T-issue-refund"
                    disabled={disableInteraction}
                  >
                    Issue refund
                  </Button>
                )}

                {hasAllowedAutomaticRefund && (
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      showRefundModal({
                        itemId: item.id,
                        roomId: room.id,
                        auto: true,
                      });
                    }}
                    className="T-issue-refund"
                    disabled={disableInteraction}
                  >
                    Automatic refund
                  </Button>
                )}

                <UserIDPermissionedComponent userIDs={REFUND_V2_BETA_TESTERS} permitInTest>
                  {hasAllowedRefund && <Button onClick={this.onRefundV2Open}>Show Beta Refund UI</Button>}
                </UserIDPermissionedComponent>
              </RestrictedComponent>

              <TemplateForCSButton bookingDetails={bookingDetails} toggleSummary={this.toggleSummary} />
              <TemplateForCSModal
                isSummaryOpen={this.state.isSummaryOpen}
                toggleSummary={this.toggleSummary}
                bookingDetails={bookingDetails}
              />

              {/* Disable for now, simse like we have some issue with bedbanks insurance letters */}
              {/* {item.status === 'cancelled' && this.state.refundRequest && (
                <InsuranceLetter item={item} refundRequest={this.state.refundRequest} order={this.props.order} />
              )} */}
            </Stack>
          </Box>
        </Stack>

        {this.state.isRefundModalOpen && (
          <RefundV2UI
            order={order}
            item={item}
            show={this.state.isRefundModalOpen}
            toggleRefundModal={this.toggleShowRefundV2Modal}
            roomId={room.id}
            showModal={this.state.isRefundModalOpen}
            approver={this.state.refundApprover}
          />
        )}

        <CancellationPolicyWarningModal
          showModal={this.state.isCancellationWarningModalOpen}
          closeModal={() => {
            this.setState({
              isCancellationWarningModalOpen: false,
            });
          }}
          showRefundModal={this.toggleShowRefundV2Modal}
          setRefundComment={this.setRefundApprover}
        />

        <div className="order-item-cell col-sm-2 col-md-1 count data-hj-whitelist"></div>
        <div className="order-item-cell col-sm-10 col-md-4"></div>
        <div className="order-item-cell col-sm-4 col-md-3"></div>
        <div className="order-item-cell col-sm-4 col-md-2 status-column"></div>
        <div className="order-item-cell col-sm-4 col-md-2 refund-column"></div>
      </Box>
    );
  }
}

export default withTenant(OrderItem);
