import { useCallback, useEffect, useMemo, useState } from 'react';

import requestGetHeroPlannerSchedulesList from '~/queries/customerCommunication/requestGetHeroPlannerSchedulesList';

import { RequestStatus } from '~/consts/requestConstants';

import { EmptyArray } from '~/utils/arrayUtils';

import { useAppSelector } from '../store';

function useHeroPlannerSchedulesList(
  /**
   * YYYY-MM-DD
   */
  fromDate: string,
  /**
   * YYYY-MM-DD
   */
  toDate: string,
  countryGroupId: string,
  countryId: string,
  countryStateId: string,
) {
  const brand = useAppSelector((state) => state.tenant.brand);
  const [schedulesListReq, setSchedulesListReq] = useState<
    Utils.RequestState<Array<CustomerCommunication.HeroPlannerSchedulesListItem>, string>
  >({
    status: RequestStatus.INITIAL,
  });
  const params = useMemo<Parameters<typeof requestGetHeroPlannerSchedulesList>>(() => {
    return [brand, fromDate, toDate, countryGroupId, countryId, countryStateId];
  }, [brand, fromDate, toDate, countryGroupId, countryId, countryStateId]);
  const fetchSchedulesList = useCallback(async () => {
    setSchedulesListReq({
      status: RequestStatus.PENDING,
      params,
      result: EmptyArray,
    });

    try {
      const result = await requestGetHeroPlannerSchedulesList(...params);
      setSchedulesListReq({
        status: RequestStatus.FULFILLED,
        params,
        result,
      });
    } catch (error) {
      setSchedulesListReq({
        status: RequestStatus.REJECTED,
        params,
        error,
      });
    }
  }, [params]);
  useEffect(() => {
    fetchSchedulesList();
  }, [fetchSchedulesList]);

  return { schedulesListReq, fetchSchedulesList };
}

export default useHeroPlannerSchedulesList;
