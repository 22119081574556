import React, { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

interface Props {
  vendorId: string;
  property: App.Property;
}

const VideosPane = (props: Props): ReactElement => {
  const { vendorId, property } = props;

  return (
    <Stack>
      <PageSubheader title="Videos">
        <Button variant="text" component={Link} to={`/vendors/${vendorId}/properties/${property.id}/edit-videos`}>
          Edit
        </Button>
      </PageSubheader>

      {property.videos.luxuryescapes?.vimeo_id && property.videos.luxuryescapes.vimeo_id != 'undefined' && (
        <Box>
          <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
            Luxury Escapes
          </Typography>
          <div className="embed-responsive embed-responsive-16by9" style={{ marginTop: '10px' }}>
            <iframe
              width="560"
              height="315"
              src={`https://player.vimeo.com/video/${property.videos.luxuryescapes.vimeo_id}`}
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </Box>
      )}
      {property.videos.whitelabel?.vimeo_id && property.videos.whitelabel.vimeo_id != 'undefined' && (
        <Box>
          <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
            Whitelabel
          </Typography>
          <div className="embed-responsive embed-responsive-16by9" style={{ marginTop: '10px' }}>
            <iframe
              width="560"
              height="315"
              src={`https://player.vimeo.com/video/${property.videos.whitelabel.vimeo_id}`}
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </Box>
      )}
    </Stack>
  );
};

export default VideosPane;
