import * as libRegions from '@luxuryescapes/lib-regions';

const zeroDecimalCurrencies = libRegions.getZeroDecimalCurrencies();

// getStripeAmount returns the amounts in cents or cents equivalent for currencies
// with zero decimals. Otherwise it returns the original amount.
export default function (amount, currencyCode) {
  if (zeroDecimalCurrencies.indexOf(currencyCode.toUpperCase()) === -1) {
    return Math.ceil(amount * 100);
  }

  return amount;
}
