/* eslint-disable react/destructuring-assignment */
// would need to redefine all of the fields of the TextField for this to work
import React from 'react';

import { NumericFormat } from 'react-number-format';

import { TextField } from '@mui/material';

export default function NumberFormatCustom(props) {
  const { inputRef, onChange, name, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      required
      customInput={TextField}
      decimalScale={2}
      fixedDecimalScale
    />
  );
}
