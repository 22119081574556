import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { InfoRounded } from '@mui/icons-material';
import { Box, Button, Collapse, Stack, TextField, Tooltip, Typography } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { updateOrderItem } from '~/services/OrdersService';

interface Props {
  item: App.OrderItem;
  order: App.Order;
  isVisible: boolean;
  specialRequestText?: string;
}

export function SpecialRequests({ item, order, isVisible, specialRequestText }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    isToggled: editingSpecialRequests,
    toggleOn: enableEditSpecialRequests,
    toggleOff: disableEditSpecialRequests,
  } = useToggleState(false);

  const [newSpecialRequestText, setNewSpecialRequestText] = useState(specialRequestText);
  const [displaySpecialRequestText, setDisplaySpecialRequestText] = useState(specialRequestText);

  const canSubmit = useMemo(() => {
    return newSpecialRequestText !== '' && specialRequestText !== newSpecialRequestText;
  }, [newSpecialRequestText, specialRequestText]);

  const handleSaveSpecialRequests = useCallback(async () => {
    if (
      confirm("This will update the customer's special request and send a notification to the hotel. Are you sure?")
    ) {
      try {
        const body = {
          guest_special_requests: newSpecialRequestText,
          op: 'change_guest_special_requests', //operation
          cn: '', //case number
          transaction_key: uuid(),
        };

        await updateOrderItem(order.id, item.id, body);
        setDisplaySpecialRequestText(newSpecialRequestText);
        disableEditSpecialRequests();
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'warning' });
      }
    }
  }, [disableEditSpecialRequests, enqueueSnackbar, item.id, newSpecialRequestText, order.id]);

  const onChangeSpecialRequests = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewSpecialRequestText(event.target.value);
  }, []);

  const handleCancel = () => {
    setNewSpecialRequestText(specialRequestText);
    disableEditSpecialRequests();
  };

  return (
    <Stack direction="column" spacing={1} mt={1}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" fontWeight="bold">
            Special Requests
          </Typography>
          {!isVisible && (
            <Tooltip title="If the booking is within 7 days of the check-in date, contact the hotel directly to issue a special request">
              <span style={{ display: 'inline-flex', padding: '0 5px', cursor: 'pointer' }}>
                <InfoRounded />
              </span>
            </Tooltip>
          )}
        </Box>
        {isVisible &&
          (editingSpecialRequests ? (
            <>
              <Button onClick={handleCancel} variant="text" size="small">
                Close
              </Button>
              <Button onClick={handleSaveSpecialRequests} disabled={!canSubmit} variant="contained" size="small">
                Save
              </Button>
            </>
          ) : (
            <Button onClick={enableEditSpecialRequests} variant="text" size="small">
              Update
            </Button>
          ))}
      </Stack>

      {isVisible && (
        <Collapse in={editingSpecialRequests} timeout="auto" unmountOnExit>
          <TextField
            id="outlined-multiline-static"
            label="Special requests"
            onChange={onChangeSpecialRequests}
            value={newSpecialRequestText === 'N/A' ? '' : newSpecialRequestText}
            minRows={3}
            multiline
            fullWidth
          />
        </Collapse>
      )}

      {!editingSpecialRequests && <Typography>{displaySpecialRequestText}</Typography>}
    </Stack>
  );
}
