import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import SurchargeDateForm from '~/components/Common/Forms/SurchargeDateForm';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';
import Spinner from '~/components/Common/Spinner';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import ReservationService from '~/services/ReservationService';
import { unix } from '~/services/TimeService';

import SurchargeDateBlock from './SurchargeDateBlock';

export default function SurchargeDates({
  propertyId,
  roomType,
  roomRate,
  propertyRoomTypes,
  vendorCurrencyCode,
  surchargeDates,
  surchargeType,
  deleteSurchargeDateBlock,
  deleteSurchargeDateBlockByRoomRateId,
  deleteSurchargeDateBlockByRatePlanId,
  onUpdateSurchargeDates,
  surchargeSchema,
  foreignExchangeRates,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const bulkDeleteByRoomRateId = 'room-rates';
  const showConfirmDialog = useConfirmDialog();
  const [addingNew, setAddingNew] = useState(false);
  const [editing, setEditing] = useState(null);
  const [bulkDeleteModalVisible, setBulkDeleteModalVisible] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [selectedBulkDeleteOption, setSelectedBulkDeleteOption] = useState(bulkDeleteByRoomRateId);

  const openAddBox = () => {
    setAddingNew(true);
    setEditing(null);
  };

  const openEditBox = (surchargeDateBlock) => {
    setAddingNew(false);
    setEditing(surchargeDateBlock);
  };

  const closeAddBox = () => {
    setAddingNew(false);
    setEditing(null);
  };

  const toggleBulkDeleteModalVisible = () => {
    setBulkDeleteModalVisible(true);
  };

  const closeBulkDeleteBox = () => {
    setBulkDeleteModalVisible(false);
    setDeleteInProgress(false);
  };

  const handleBulkDeleteSelect = (event: SelectChangeEvent) => {
    const selectedOption = event.target.value;
    setSelectedBulkDeleteOption(selectedOption);
  };

  const handleBulkDelete = async () => {
    setBulkDeleteModalVisible(false);
    setDeleteInProgress(false);

    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete these surcharge dates?',
      description:
        'Surcharges should not be deleted after a deal has been set live - please contact sales operations (OCM) or an account manager before proceeding.',
    });

    if (!confirmed) {
      setBulkDeleteModalVisible(false);
      setDeleteInProgress(false);
      return;
    }

    setBulkDeleteModalVisible(true);
    setDeleteInProgress(true);

    try {
      if (selectedBulkDeleteOption === bulkDeleteByRoomRateId) {
        await deleteSurchargeDateBlockByRoomRateId(surchargeType);
      } else {
        await deleteSurchargeDateBlockByRatePlanId(selectedBulkDeleteOption, surchargeType);
      }
    } catch (error) {
      enqueueSnackbar(`Error fetching property relations: ${error}`, { variant: 'error' });
    } finally {
      setBulkDeleteModalVisible(false);
      setDeleteInProgress(false);
    }
  };

  const onSurchargeDateBlocksAdded = async (newSurchargeDateBlocks) => {
    if (newSurchargeDateBlocks[roomType.id]) {
      const data = await ReservationService.getSurchargeDates(propertyId, roomType.id, roomRate.id, surchargeType);

      onUpdateSurchargeDates(data.result.dates);
      closeAddBox();
    }
  };

  const onSurchargeDateBlockUpdated = (updatedSurchargeDateBlock) => {
    const index = surchargeDates.findIndex((block) => block.id === updatedSurchargeDateBlock.id);
    surchargeDates[index] = updatedSurchargeDateBlock;
    onUpdateSurchargeDates(surchargeDates);
    closeAddBox();
  };

  const surchargeDatesCompare = (a, b) => {
    return unix(a.start_date) - unix(b.start_date);
  };

  const isVendorSurcharge = () => {
    return surchargeType === 'vendor';
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid xs={12}>
        <Typography textTransform="uppercase" color="secondary" component={'span'}>
          {isVendorSurcharge() ? (
            <Stack direction="row" justifyContent="space-between">
              Room Rate Surcharge Dates
              <Button
                title="Delete all"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={toggleBulkDeleteModalVisible}
              >
                Delete all
              </Button>
            </Stack>
          ) : (
            'Revenue Management Dates'
          )}
        </Typography>
      </Grid>
      <Grid xs={12}>
        {surchargeDates.length === 0 && (
          <p>
            <i>{isVendorSurcharge() ? 'No surcharge dates set' : 'No revenue management dates set'}</i>
          </p>
        )}
        {surchargeDates.length > 0 && (
          <div>
            {surchargeDates.sort(surchargeDatesCompare).map((surchargeDateBlock) => (
              <SurchargeDateBlock
                key={surchargeDateBlock.id}
                surchargeDateBlock={surchargeDateBlock}
                onDelete={deleteSurchargeDateBlock}
                onRequestEdit={() => openEditBox(surchargeDateBlock)}
              />
            ))}
          </div>
        )}
      </Grid>
      <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
        <Grid xs>
          <Button onClick={openAddBox} startIcon={<AddIcon />} variant="contained">
            Add
          </Button>
        </Grid>
      </PermissionedComponent>
      <Dialog open={addingNew || !!editing} onClose={closeAddBox} maxWidth="md" fullWidth>
        <DialogTitle>
          {editing ? 'Edit' : 'Apply'} {isVendorSurcharge() ? 'Surcharge Dates' : 'Revenue Management Dates'}
        </DialogTitle>
        <DialogContent>
          <SurchargeDateForm
            surchargeDateBlock={editing}
            propertyId={propertyId}
            roomType={roomType}
            roomRate={roomRate}
            propertyRoomTypes={propertyRoomTypes}
            surchargeType={surchargeType}
            vendorCurrencyCode={vendorCurrencyCode}
            onCancel={closeAddBox}
            onSurchargeDateBlocksAdded={onSurchargeDateBlocksAdded}
            onSurchargeDateBlockUpdated={onSurchargeDateBlockUpdated}
            surchargeSchema={surchargeSchema}
            foreignExchangeRates={foreignExchangeRates}
          />
        </DialogContent>
      </Dialog>
      <Dialog fullWidth={true} open={bulkDeleteModalVisible} onClose={closeBulkDeleteBox}>
        <DialogTitle>Delete all surcharge dates in rate plan</DialogTitle>
        <DialogContent>
          {deleteInProgress && <Spinner />}
          {!deleteInProgress && (
            <Stack mt={1} spacing={2}>
              <FormControl fullWidth={true}>{roomRate.rate_plan.name}</FormControl>
              <FormControl fullWidth={true}>
                <MuiSelect
                  labelId="select-operation-label"
                  onChange={handleBulkDeleteSelect}
                  value={selectedBulkDeleteOption}
                >
                  <MenuItem key={bulkDeleteByRoomRateId} value={bulkDeleteByRoomRateId}>
                    {roomType.name}
                  </MenuItem>
                  <MenuItem key={roomRate.rate_plan.id} value={roomRate.rate_plan.id}>
                    All rooms with {roomRate.rate_plan.name}
                  </MenuItem>
                </MuiSelect>
              </FormControl>
            </Stack>
          )}
          <DialogActions>
            <Button variant="text" onClick={closeBulkDeleteBox}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleBulkDelete}>
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
