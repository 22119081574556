import React, { useEffect, useState } from 'react';

import { Box, Collapse, Paper, Stack, Typography } from '@mui/material';

import ExpandModeButton from '../Elements/ExpandModeButton';

type Props = React.PropsWithChildren<{
  title: string;
  actions?: React.ReactNode;
  initiallyExpanded?: boolean;
  onChange?: (isExpanded: boolean) => void;
  titleComponent?: React.ElementType;
  manualState?: boolean;
}>;

export default function FoldableSection({
  title,
  initiallyExpanded = false,
  actions,
  children,
  onChange,
  titleComponent = 'h2',
  manualState,
}: Props) {
  const [open, setOpen] = useState(initiallyExpanded);

  const handleToggle = () => {
    setOpen(!open);
    onChange?.(!open);
  };

  useEffect(() => {
    if (manualState !== undefined) {
      setOpen(manualState);
    }
  }, [manualState]);

  return (
    <Paper variant="outlined">
      <Box>
        <Stack direction="row" alignItems="center" spacing={1} pr={2}>
          <ExpandModeButton expand={open} onClick={handleToggle} iconSize="large" />

          <Typography
            flex={1}
            component={titleComponent}
            color="grey.800"
            fontSize={titleComponent === 'h2' ? '1.5rem' : 'inherit'}
            fontWeight={500}
            gutterBottom
            onClick={handleToggle}
            sx={{ cursor: 'pointer', ':hover': { color: 'grey.900' } }}
          >
            {title}
          </Typography>

          {actions && <Box>{actions}</Box>}
        </Stack>
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={2}>{children}</Box>
      </Collapse>
    </Paper>
  );
}
