import React from 'react';

import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateOrdinalWithClock, unixToDate } from '~/services/TimeService';

import GridPagination from '../Common/Elements/GridPagination';

type Props = {
  isLoading?: boolean;
  carts: {
    transactionKey: string;
  }[];
  total: number;
  page: number;
  onPageChange: (page: number) => void;
};

const columns: GridColDef[] = [
  { field: 'transactionKey', headerName: 'Transaction key', sortable: false, width: 350, display: 'flex' },
  {
    field: 'user.fullName',
    headerName: 'Customer name',
    sortable: false,
    flex: 1,
    renderCell: (params) => params.row.user.fullName,
    display: 'flex',
  },
  {
    field: 'user.email',
    headerName: 'Customer email',
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography title={params.row.user.email} noWrap>
        {params.row.user.email}
      </Typography>
    ),
    display: 'flex',
  },
  {
    field: 'offer_name',
    headerName: 'Offer name',
    sortable: false,
    flex: 1,
    renderCell: (params) => (params.row.items?.length > 0 ? params.row.items[0].offer_name : null),
    display: 'flex',
  },
  {
    field: 'package_name',
    headerName: 'Package name',
    sortable: false,
    flex: 1,
    renderCell: (params) => (params.row.items?.length > 0 ? params.row.items[0].package_name : null),
    display: 'flex',
  },
  {
    field: 'item_count',
    headerName: 'Packages',
    sortable: false,
    renderCell: (params) => params.row.items?.length ?? 0,
    display: 'flex',
  },
  {
    field: 'updatedAt',
    headerName: 'Date',
    sortable: false,
    width: 200,
    renderCell: (params) => formatDateOrdinalWithClock(unixToDate(params.row.updatedAt)),
    display: 'flex',
  },
];

export default function CartPane(props: Props) {
  return (
    <DataGrid
      rows={props.carts}
      rowCount={props.total}
      columns={columns}
      pageSizeOptions={[10]}
      pagination
      paginationMode="server"
      paginationModel={{
        page: props.page,
        pageSize: 10,
      }}
      loading={props.isLoading}
      slots={{ pagination: GridPagination }}
      onPaginationModelChange={({ page }) => props.onPageChange(page)}
      getRowId={(row) => row.transactionKey}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
    />
  );
}
