import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { Box, Button } from '@mui/material';

import RoomTypeImagesForm from '~/components/Common/Forms/RoomTypeImagesForm';
import { buttonMessages as baseButtonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';

import { formatError } from '~/utils/errorFormatter';

const buttonMessages = { ...baseButtonMessages };
buttonMessages[buttonStates.default] = 'Save changes';

export interface PropertyImage {
  id: string;
  property_id: string;
  id_cloudinary_external: string;
  filename: string;
  title?: string;
  order: number;
  hidden?: boolean;
}

interface Props {
  images: PropertyImage[];
  onImagesChange: (images: PropertyImage[]) => void;
  createImage: (image) => Promise<void>;
  updateImages: (images) => Promise<void>;
  deleteImage: (imageId: string) => Promise<void>;
  propertyId: string;
}

function ImagesForm(props: Props) {
  const [propertyImages, setPropertyImages] = useState<PropertyImage[]>([]);
  const [saveButtonState, setSaveButtonState] = useState(buttonStates.default);
  const isButtonStateSaving = saveButtonState === buttonStates.saving;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPropertyImages([...props.images]);
  }, [props.images]);

  const sortImagesByOrder = (images) => {
    return [...images]
      .map((i) => ({
        ...i,
        publicImageId: i.id,
      }))
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
  };

  const onCreateImage = async (cloudinaryId: string, filename: string) => {
    try {
      const createdImage = {
        id: uuid(),
        id_cloudinary_external: cloudinaryId,
        property_id: props.propertyId,
        filename,
        title: 'New image',
        order: propertyImages.length + 1,
      };
      await props.createImage(createdImage);
      setPropertyImages(propertyImages.concat(createdImage));
      return createdImage;
    } catch (err) {
      enqueueSnackbar(formatError(err), {
        variant: 'error',
      });
    }
  };

  const onDeleteImage = async (propertyImageId: string) => {
    try {
      const newImages = propertyImages.filter((i) => i.id !== propertyImageId);
      for (let i = 0; i < newImages.length; i++) {
        newImages[i].order = i + 1;
      }
      setPropertyImages(newImages);
      return props.deleteImage(propertyImageId);
    } catch (err) {
      enqueueSnackbar(formatError(err), {
        variant: 'error',
      });
    }
  };

  const onUpdateImages = async (images: any[]) => {
    setSaveButtonState(buttonStates.default);
    setPropertyImages(images);
  };

  const onSaveImages = () => {
    const updatedImages = propertyImages.map((i) => {
      const { id, id_cloudinary_external, title, order } = i;
      return {
        id,
        id_cloudinary_external,
        title,
        order,
      };
    });
    setSaveButtonState(buttonStates.saving);
    props
      .updateImages(updatedImages)
      .then(() => {
        setSaveButtonState(buttonStates.saved);
      })
      .catch(() => {
        setSaveButtonState(buttonStates.failed);
      });
  };

  return (
    <>
      <RoomTypeImagesForm
        images={sortImagesByOrder(propertyImages)}
        hasHiddenToggle={false}
        onAddImage={onCreateImage}
        onUpdateImages={onUpdateImages}
        onDeleteImage={onDeleteImage}
        hideDeleteButton={false}
      />

      <Box mt={3}>
        <Button variant="contained" onClick={onSaveImages} disabled={isButtonStateSaving}>
          {buttonMessages[saveButtonState]}
        </Button>
      </Box>
    </>
  );
}

export default ImagesForm;
