import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import Spinner from '~/components/Common/Spinner';

import { MappedAuditLog, getLogsByUrl, groupLogsByModelId, groupLogsByModelType } from '~/services/AuditService';
import { sortByDate } from '~/services/TimeService';

import SurchargeDateBlockHistory from './SurchargeDateBlockHistory';

interface Props {
  propertyId: string;
  channelManaged: boolean;
  roomType: API.Reservation.RoomType;
  roomRate: API.Reservation.RoomRate;
  surchargeType: App.SurchargeType;
}

function surchargeDatesCompare(a: MappedAuditLog, b: MappedAuditLog) {
  return sortByDate(a.timestamp, b.timestamp);
}

export default function SurchargeDates({ channelManaged, propertyId, roomType, roomRate, surchargeType }: Props) {
  const surchargeDatePath = useMemo(() => {
    return `/properties/${propertyId}/room-types/${roomType.id}/room-rates/${roomRate.id}/surcharge-dates`;
  }, [propertyId, roomRate, roomType]);

  const [loading, setLoading] = useState<boolean>();
  const [logs, setLogs] = useState<{ [key: string]: MappedAuditLog[] }>({});

  const getSurchargeDateLogs = useCallback(async () => {
    setLoading(true);
    const res = await getLogsByUrl(surchargeDatePath);

    const logsByType = groupLogsByModelType(res.result);
    const modelType = surchargeType === 'internal' ? 'InternalSurchargeDateBlock' : 'SurchargeDateBlock';
    const results = groupLogsByModelId(logsByType[modelType] ?? [], (log) => log.payload.params.surchargeDateId);
    setLogs(results);

    setLoading(false);
  }, [surchargeDatePath]);

  useEffect(() => void getSurchargeDateLogs(), [getSurchargeDateLogs]);

  return (
    <Grid container direction="column" spacing={2}>
      {loading ? (
        <Spinner />
      ) : (
        <Grid>
          {Object.entries(logs)
            .map(([key, surchargeDateBlocks]) => {
              const sortedLogs = surchargeDateBlocks.sort(surchargeDatesCompare).reverse();
              return { key, sortedLogs };
            })
            .filter(({ sortedLogs }) => sortedLogs[0].action !== 'DELETE')
            .map(({ key, sortedLogs }) => (
              <SurchargeDateBlockHistory key={key} surchargeDateBlocks={sortedLogs} />
            ))}
        </Grid>
      )}

      {channelManaged && (
        <Grid>
          <i>* surcharge dates controlled by channel manager</i>
        </Grid>
      )}
    </Grid>
  );
}
