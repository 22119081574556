import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Card, CardContent, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import OffersService from '../../../services/OffersService';
import ReservationService from '../../../services/ReservationService';
import mergeRoomPolicyNames from '../../../utils/mergeRoomPolicyNames';
import RoomPolicyForm from '../../Common/Forms/RoomPolicyForm';

export default function RoomPoliciesEditContainer() {
  const { id_offer: idOffer } = useParams<{ id_offer: string }>();

  const [roomPoliciesWithNames, setRoomPoliciesWithNames] = useState([]);
  const [roomPoliciesSchema, setRoomPoliciesSchema] = useState({});
  const [offer, setOffer] = useState(null);

  useEffect(() => {
    OffersService.getOffer(idOffer).then((response) => {
      setOffer(response.result);
    });
  }, [idOffer]);

  useEffect(() => {
    if (offer) {
      Promise.all([
        OffersService.getRoomPolicySchema(idOffer),
        ReservationService.getProperties(offer.vendor_account_id),
      ]).then(([schemaResponse, propertyResponse]) => {
        const propertyData = propertyResponse.count ? propertyResponse.result : [];

        setRoomPoliciesSchema(schemaResponse.schema);
        setRoomPoliciesWithNames(mergeRoomPolicyNames(offer.packages, propertyData));
      });
    }
  }, [idOffer, offer]);

  return (
    <Container maxWidth="xl">
      <Button component={Link} to={'/offers/' + idOffer}>
        <ChevronLeftIcon /> Return to offer
      </Button>
      <PageHeader title="Edit Room Policies" />
      {roomPoliciesWithNames.map((roomPolicy, index) => (
        <Card raised sx={{ m: 2 }} key={index}>
          <CardContent>
            <RoomPolicyForm schema={roomPoliciesSchema} roomPolicy={roomPolicy} idOffer={idOffer} />
          </CardContent>
        </Card>
      ))}
    </Container>
  );
}
