import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import { Box, Container, Stack, TextField } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { formatDateISO } from '~/services/TimeService';
import promotionService from '~/services/cruises/PromotionService';

import { Promotion } from '../../types';

import PromotionForm from './components/PromotionForm';
import { GENERIC_INITIAL_REQUEST_STATE } from './constants';
import { hasRequiredFields } from './helpers';
import { PromoCurrencies, PromotionFormValues, Request } from './types';

const PROMO_BASE_PATH = `/cruises/promotion-management`;

const EditPromotionPage: React.FC = (): JSX.Element => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const rateCode = params.get('rateCode');
  const vendorCode = params.get('vendorCode');
  const { promotionId } = useParams<{ promotionId: string }>();

  const { enqueueSnackbar } = useSnackbar();
  const [promotion, setPromotion] = useState<Request<Promotion>>(GENERIC_INITIAL_REQUEST_STATE);

  const promoFormValues: PromotionFormValues | null = useMemo(() => {
    if (!promotion.result) return null;

    return {
      promotionName: promotion.result.name,
      leExclusive: promotion.result.leExclusive,
      promotionStartDate: promotion.result.startDate,
      promotionEndDate: promotion.result.endDate,
      promotionSellingPoint1: promotion.result.sellingPoints[0],
      promotionSellingPoint2: promotion.result.sellingPoints[1],
      promotionSellingPoint3: promotion.result.sellingPoints[2],
      depositType: promotion.result.deposit?.type ?? 'VALUE',
      depositAmount: promotion.result.deposit?.amount ?? 0,
      depositCurrency: (promotion.result.deposit?.currencyCode as PromoCurrencies) ?? 'AUD',
      onBoardCreditAmount: promotion.result.onBoardCredit?.amount ?? 0,
      onBoardCreditCurrency: (promotion.result.onBoardCredit?.currencyCode as PromoCurrencies) ?? 'AUD',
    };
  }, [promotion]);

  const fetchPromotionById = useCallback(
    async (promotionId: string) => {
      setPromotion({ loading: true, result: null });
      const res = await promotionService.getPromotionsById(promotionId);

      if (!res || !res?.result || res?.status !== 200) {
        setPromotion({ loading: false, result: null });
        enqueueSnackbar('Something went wrong, please contact cruises team', { variant: 'error' });
      } else {
        setPromotion({ loading: false, result: res.result });
      }
    },
    [enqueueSnackbar],
  );

  const handleSubmit = useCallback(
    async (formValues: PromotionFormValues) => {
      if (!hasRequiredFields(formValues)) {
        enqueueSnackbar(`Please fill all required fields`, { variant: 'error' });
      } else {
        enqueueSnackbar(`The information is being processed, please wait a moment.`, { variant: 'info' });

        const res = await promotionService.editPromotionById(promotionId, {
          name: formValues.promotionName,
          leExclusive: formValues.leExclusive,
          startDate: formatDateISO(formValues.promotionStartDate),
          endDate: formatDateISO(formValues.promotionEndDate),
          sellingPoints: [
            formValues.promotionSellingPoint1,
            formValues.promotionSellingPoint2,
            formValues.promotionSellingPoint3,
          ].filter((item: string | undefined) => !!item),
          deposit: {
            type: formValues.depositType,
            amount: formValues.depositAmount,
            currencyCode: formValues.depositCurrency,
          },
          onBoardCredit: {
            amount: formValues.onBoardCreditAmount,
            currencyCode: formValues.onBoardCreditCurrency,
          },
        });

        if (res?.status === 201 || res?.status === 200) {
          enqueueSnackbar(`Promotion edited successfully`, { variant: 'success' });
        } else {
          enqueueSnackbar('Something went wrong, please contact cruises team', { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, promotionId],
  );

  useEffect(() => {
    fetchPromotionById(promotionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Edit Cruise Promotion</title>
      </Helmet>

      <PageHeader title="Edit Promotion" backButton={PROMO_BASE_PATH} />

      <Box>
        {vendorCode && rateCode && (
          <Stack direction="row" spacing={2}>
            <TextField fullWidth value={vendorCode} label="Cruise Line Code" disabled />
            <TextField fullWidth value={rateCode} label="Rate Code" disabled />
          </Stack>
        )}

        <PromotionForm promoFormValues={promoFormValues} onSubmit={handleSubmit} />
      </Box>
    </Container>
  );
};

export default EditPromotionPage;
