import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props<T> {
  userProfileInputId: string;
  userProfileInputTitle: string;
  userProfileInputName: string;
  onChange: (value: string) => void;
  useHeroPlannerInputData: () => { dataReq: Utils.RequestState<T> };
}

export default function ScheduleUserProfileInput<T extends Array<CustomerCommunication.HeroPlannerMembership>>(
  props: Props<T>,
) {
  const { userProfileInputId, userProfileInputTitle, userProfileInputName, useHeroPlannerInputData, onChange } = props;

  const { dataReq } = useHeroPlannerInputData();

  const options = dataReq?.result ?? EmptyArray;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (options.length && (!userProfileInputId || !options.find((i) => i.id === userProfileInputId))) {
      onChange(options[0].id);
    }
  }, [onChange, options, userProfileInputId]);

  const initialStateSegment = useMemo<CustomerCommunication.HeroPlannerStateSegment | undefined>(() => {
    if (userProfileInputId && !options.find((v) => v.id === userProfileInputId))
      return { id: userProfileInputId, name: userProfileInputId };
  }, [options, userProfileInputId]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink>{userProfileInputTitle}</InputLabel>
      <Select
        value={userProfileInputId}
        onChange={handleChange}
        name={userProfileInputName}
        required
        variant="standard"
        disabled={isRequestUnresolved(dataReq) || isRequestRejected(dataReq)}
        error={isRequestRejected(dataReq)}
      >
        {!!initialStateSegment && (
          <MenuItem value={initialStateSegment.id}>
            <i>{initialStateSegment.name}</i>
          </MenuItem>
        )}
        {options.map((segment) => (
          <MenuItem key={segment.id} value={segment.id}>
            {segment.name}
          </MenuItem>
        ))}
      </Select>
      {isRequestRejected(dataReq) && <FormHelperText error>{dataReq.error}</FormHelperText>}
      {isRequestPending(dataReq) && <LinearProgress sx={{ position: 'absolute' }} />}
    </FormControl>
  );
}
