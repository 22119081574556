import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import useHeroPlannerCountryGroups from '~/hooks/customerCommunication/useHeroPlannerCountryGroups';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

function ScheduleCountryGroupInput(props: Props) {
  const { name, value, onChange } = props;
  const { countryGroupsReq } = useHeroPlannerCountryGroups();
  const options = countryGroupsReq?.result ?? EmptyArray;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (options.length && (!value || !options.find((i) => i.id === value))) {
      onChange(options[0].id);
    }
  }, [onChange, options, value]);

  const initialCountryGroup = useMemo<CustomerCommunication.HeroPlannerCountryGroup | undefined>(() => {
    if (value && !options.find((v) => v.id === value)) return { id: value, name: value };
  }, [options, value]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink>Country Group</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        required
        variant="standard"
        disabled={isRequestUnresolved(countryGroupsReq) || isRequestRejected(countryGroupsReq)}
        error={isRequestRejected(countryGroupsReq)}
      >
        {!!initialCountryGroup && (
          <MenuItem value={initialCountryGroup.id}>
            <i>{initialCountryGroup.name}</i>
          </MenuItem>
        )}
        {options.map((countryGroup) => (
          <MenuItem key={countryGroup.id} value={countryGroup.id}>
            {countryGroup.name}
          </MenuItem>
        ))}
      </Select>
      {isRequestRejected(countryGroupsReq) && <FormHelperText error>{countryGroupsReq.error}</FormHelperText>}
      {isRequestPending(countryGroupsReq) && <LinearProgress sx={{ position: 'absolute' }} />}
    </FormControl>
  );
}

export default ScheduleCountryGroupInput;
