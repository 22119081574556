import React, { useState } from 'react';

import { TraverseObject } from './utils';

export default function CommonFilteredDetail(props) {
  const [filterBy] = useState<string>('');
  const { details } = props;

  return (
    <>
      <TraverseObject obj={details} filterBy={filterBy} />
    </>
  );
}
