import React from 'react';

import { Helmet } from 'react-helmet';

import { Box } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import InclusionList from './components/InclusionList';

function InclusionsListPage() {
  return (
    <Box>
      <Helmet>
        <title>Inclusions Management</title>
      </Helmet>

      <PageHeader title="Inclusions Management" />

      <Box mt={1}>
        <InclusionList />
      </Box>
    </Box>
  );
}

export default InclusionsListPage;
