import React from 'react';

import { Button, CircularProgress, Tooltip } from '@mui/material';

interface Props {
  disabled: boolean;
  disabledTooltip: string;
  isLoading: boolean;
  label: string;
  onClick: () => void;
  tooltip: string;
}

const RemittanceBulkActionButton = ({ disabled, disabledTooltip, isLoading, label, onClick, tooltip }: Props) => {
  return (
    <Tooltip arrow title={!disabled ? tooltip : disabledTooltip}>
      <span>
        <Button disabled={disabled} onClick={onClick} variant="contained">
          {isLoading && <CircularProgress sx={{ marginRight: '8px' }} size={22} />}
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};

export default RemittanceBulkActionButton;
