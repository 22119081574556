import React, { useCallback, useMemo } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Button, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { ModificationFilters, ModificationLog } from '~/services/PublicOfferFeedService';

import dateFormatter from '~/utils/dateFormatter';

import CopyableUserLink from '../Common/CopyableUserLink';
import GridPagination from '../Common/Elements/GridPagination';
import PageSubheader from '../Common/Elements/PageSubheader';
import Spinner from '../Common/Spinner';
import DebugModal from '../DebugModal/DebugModal';

import useModificationLogs from './useModficationLogs';

type ModificationLogsProps = ModificationFilters & {
  isDev: boolean;
};

function ModificationLogs({ isDev, customerId, limit, page, adGroupIds, campaignIds }: ModificationLogsProps) {
  const [filters, setFilters] = React.useState<ModificationFilters>({
    customerId,
    limit,
    page,
    adGroupIds,
    campaignIds,
  });

  const memoizedFilters = useMemo(
    () => ({
      page: 0,
      limit: 10,
      campaignIds: filters.campaignIds,
      customerId: filters.customerId,
      adGroupIds: filters.adGroupIds,
    }),
    [filters.campaignIds, filters.customerId, filters.adGroupIds],
  );

  const { modificationLogs, count, getModifications, isLoading } = useModificationLogs({
    filters: memoizedFilters,
  });

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page,
        limit: pageSize,
      }));
    },
    [filters.campaignIds],
  );

  const onSubmit = useCallback(() => {
    getModifications(memoizedFilters);
  }, [getModifications, memoizedFilters]);

  const sharedOpts: Partial<GridColDef<ModificationLog>> = {
    editable: false,
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const columns: Array<GridColDef<ModificationLog>> = [
    {
      field: 'created_at',
      headerName: 'Created At',
      editable: false,
      renderCell: (params) => dateFormatter(params.row.createdAt),
      ...sharedOpts,
    },
    {
      field: 'campaignId',
      headerName: 'Campaign ID',
      ...sharedOpts,
    },
    {
      field: 'adGroupId',
      headerName: 'AdGroup ID',
      ...sharedOpts,
    },
    {
      field: 'modType',
      headerName: 'Type',
      ...sharedOpts,
    },
    {
      field: 'value',
      headerName: 'Value',
      renderCell: (params) => <span>{'amountMicros' in params.row ? params.row.amountMicros : ''}</span>,
      ...sharedOpts,
    },
    {
      field: 'modified_by',
      headerName: 'Modified by',
      renderCell: (params) => <CopyableUserLink userId={params.row.modifiedBy} label="Referrer" />,
      ...sharedOpts,
    },
    {
      field: 'debug',
      headerName: 'Raw',
      renderCell: (params) => <DebugModal type="generic" data={params.row ?? { data: 'None' }} fullScreen />,
      ...sharedOpts,
    },
  ];

  return (
    <Box>
      <Helmet>
        <title>Google Ads - Bid Modification Log</title>
      </Helmet>

      <PageSubheader
        title={`Load Modification Updates for ${
          filters.campaignIds.length > 0 ? filters.campaignIds.join('|') : 'all'
        }`}
      />
      <Grid container spacing={2}>
        {isDev && <DebugModal type="generic" title="search" data={filters} fullScreen />}
        <Grid item sm={1}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button type="submit" variant="contained" onClick={onSubmit}>
              Load
            </Button>
          )}
        </Grid>
      </Grid>
      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          loading={isLoading}
          getRowId={(row) => row.modificationId}
          rows={modificationLogs ?? []}
          columns={columns}
          autoHeight
          rowCount={count ?? 0}
          paginationMode="server"
        />
      </Box>
    </Box>
  );
}

export default ModificationLogs;
