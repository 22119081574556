import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

import SearchService from '~/services/SearchService';

interface Props {
  templateId?: string;
  onSavedAs?: (templateId?: string) => void;
  onHide?: () => void;
  onBackToParent?: (templateId: string) => void;
}

const emptyTemplate: App.TypeaheadSearchTemplate = {
  name: '',
  template: '',
  status: 'pending',
};

function TemplateDetails({ templateId, onSavedAs, onHide, onBackToParent }: Props) {
  const [isLoading, setIsLoading] = useState(!!templateId);
  const [isEditing, setIsEditing] = useState(!templateId);
  const [template, setTemplate] = useState<App.TypeaheadSearchTemplate | null>(emptyTemplate);
  const [parentTemplate, setParentTemplate] = useState<App.TypeaheadSearchTemplate | null>(null);

  const isSplitView = !!parentTemplate && !!template;
  const isCreateNew = !templateId;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchTemplate = async () => {
      setIsLoading(true);
      const template = await SearchService.getTypeaheadTemplate(templateId);
      setTemplate(template);
      setIsEditing(false);
      setIsLoading(false);

      if (template.parent_id) {
        try {
          const parentTemplate = await SearchService.getTypeaheadTemplate(template.parent_id);
          setParentTemplate(parentTemplate);
        } catch (e) {
          enqueueSnackbar('Error fetching parent template', { variant: 'error' });
          setParentTemplate(null);
        }
      } else {
        setParentTemplate(null);
      }
    };

    if (templateId) {
      fetchTemplate();
    } else {
      setTemplate(emptyTemplate);
      setIsEditing(true);
      setIsLoading(false);
    }
  }, [enqueueSnackbar, templateId]);

  const onUpdateName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTemplate({ ...template, name: e.target.value });
    },
    [template],
  );

  const onUpdateTemplate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTemplate({ ...template, template: e.target.value });
    },
    [template],
  );

  const onSaveAsTemplate = useCallback(() => {
    const newTemplate = {
      parent_id: template.id,
      name: template.name,
      template: template.template,
      status: 'pending',
    };
    SearchService.putTypeaheadTemplate(newTemplate)
      .then((response) => onSavedAs(response.result))
      .catch((e) => {
        enqueueSnackbar(`Error saving template: ${e}`, { variant: 'error' });
      });
  }, [enqueueSnackbar, onSavedAs, template]);

  const onHideTemplate = useCallback(() => {
    SearchService.hideTypeaheadTemplate(template.id)
      .then(onHide)
      .catch((e) => {
        enqueueSnackbar(`Error hiding template: ${e}`, { variant: 'error' });
      });
  }, [enqueueSnackbar, onHide, template.id]);

  const onBackToParentClick = useCallback(() => {
    if (parentTemplate) {
      onBackToParent?.(parentTemplate.id);
    }
  }, [onBackToParent, parentTemplate]);

  return (
    <Box margin={2}>
      {isLoading && <CircularProgress />}
      {!isLoading && isEditing && (
        <Box component="form" display="flex" flexDirection="column" gap={2}>
          <TextField
            id="template-name"
            variant="standard"
            value={template.name}
            onInput={onUpdateName}
            placeholder="Start a new template name or select one from the list"
          />
          <TextField
            multiline
            rows={15}
            inputProps={{ style: { height: '65vh' } }}
            value={template.template}
            onInput={onUpdateTemplate}
          />
          <Box display="flex" flexDirection="row" justifyContent="flex-end" gap={2}>
            {!isCreateNew && (
              <Button aria-label="delete" color="primary" size="small" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={onSaveAsTemplate}>
              {isCreateNew ? 'Save' : 'Save as'}
            </Button>
          </Box>
        </Box>
      )}
      {!isLoading && !isEditing && (
        <Box component="form" display="flex" flexDirection="column" gap={2}>
          {!isSplitView && (
            <Typography variant="h5" id="template-name">
              {template.name}
            </Typography>
          )}
          <Box height={isSplitView ? '70vh' : '65vh'} fontSize="small" overflow="auto">
            <ReactDiffViewer
              oldValue={parentTemplate?.template}
              newValue={template.template}
              leftTitle={parentTemplate?.name}
              rightTitle={template.name}
              splitView={isSplitView}
              extraLinesSurroundingDiff={5}
              compareMethod={DiffMethod.WORDS}
            />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              {parentTemplate && (
                <Button color="primary" onClick={onBackToParentClick}>
                  Back to parent
                </Button>
              )}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" gap={2}>
              <Button aria-label="delete" color="primary" size="small" onClick={onHideTemplate}>
                Hide
              </Button>
              <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default TemplateDetails;
