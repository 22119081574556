import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Link, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

const customerDataFormatter = (params: GridRenderCellParams<App.Order>) => {
  const row = params.row;

  return (
    <Stack direction="column" overflow="hidden">
      <Link component={RouterLink} to={`/users/${row.fk_customer_id}`} title={row.customer_email} noWrap>
        {row.customer_email}
      </Link>
      <Typography variant="body1" title={row.customer_full_name} noWrap>
        {row.customer_full_name}
      </Typography>
    </Stack>
  );
};

export default customerDataFormatter;
