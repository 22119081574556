import React, { useCallback, useRef } from 'react';

import PropTypes from 'prop-types';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import UserActions from '~/stormpath/actions/UserActions';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from '@mui/material';

import useNavigationLinks from '~/hooks/useNavigationLinks';
import useToggleState from '~/hooks/useToggleState';

import TenantSelect from '../TenantSelect';

import ProductsDropdown from './ProductsDropdown';
import TenantIcon from './TenantIcon';

const TENANT_COLOURS: Record<App.Tenant['value'], { fg: string; bg: string }> = {
  lux: { fg: '#ffffff', bg: '#222' },
  scoopon: { fg: '#eee', bg: '#eb2f3a' },
  cudo: {
    fg: '#fff',
    bg: 'linear-gradient(90deg, rgb(0, 173, 179), rgb(20, 127, 196))',
  },
  deals: {
    fg: '#fff',
    bg: 'linear-gradient(90deg, rgb(20, 127, 211), rgb(0, 170, 245))',
  },
  treatme: {
    fg: '#fff',
    bg: 'linear-gradient(90deg, rgb(255, 111, 0), rgb(255, 65, 0))',
  },
  kogantravel: { fg: '#fff', bg: '#222' },
  lebusinesstraveller: { fg: '#fff', bg: '#009688' },
  zoomzoom: { fg: '#ddd', bg: '#222' },
  leagenthub: { fg: '#eee', bg: '#3f51b5' },
};

interface SiteHeaderProps {
  tenant: App.Tenant;
}

interface SiteHeaderContextProps {
  user: App.User;
}

function SiteHeader({ tenant }: SiteHeaderProps, { user }: SiteHeaderContextProps) {
  const { pathname } = useLocation();
  const history = useHistory();
  let navLinks = useNavigationLinks(user);
  navLinks = navLinks.filter((navLink) => {
    return navLink.label === 'Business' && tenant.brand !== 'lebusinesstraveller' ? false : true;
  });

  const userMenuTriggerRef = useRef<HTMLButtonElement>(null);
  const { isToggled: isDrawerOpen, toggleOff: closeDrawer, toggle: toggleDrawer } = useToggleState();
  const { isToggled: isUserMenuOpen, toggleOn: openUserMenu, toggleOff: closeUserMenu } = useToggleState();

  const logUserOut = useCallback(() => {
    UserActions.logout(() => {
      history.push('/');
    });
  }, [history]);

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: TENANT_COLOURS[tenant.value].bg,
          color: TENANT_COLOURS[tenant.value].fg,
          zIndex: 9,
        }}
      >
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Toolbar sx={{ px: { xs: '4px', md: 0 } }}>
              <IconButton color="inherit" edge="start" onClick={toggleDrawer} sx={{ display: { md: 'none' } }}>
                <MenuIcon />
              </IconButton>

              <Box pr={[1, 2]}>
                <IconButton color="inherit" component={NavLink} to="/">
                  <TenantIcon />
                </IconButton>
                <TenantSelect size="large" />
              </Box>

              <ProductsDropdown />

              {!!navLinks.length && (
                <Box display={{ xs: 'none', md: 'block' }}>
                  {navLinks.map((navLink, navLinkIndex) => (
                    <Button
                      key={`${navLink.href}-${navLinkIndex}`}
                      color="inherit"
                      to={navLink.href}
                      component={NavLink}
                    >
                      {navLink.label}
                    </Button>
                  ))}
                </Box>
              )}
            </Toolbar>

            <Toolbar sx={{ px: { xs: 0 } }}>
              {!user && (
                <Button to="/login" component={NavLink}>
                  Login
                </Button>
              )}
              {!!user && (
                <>
                  <Button
                    className="T-account"
                    color="inherit"
                    ref={userMenuTriggerRef}
                    endIcon={<ArrowDropDownIcon />}
                    onClick={openUserMenu}
                  >
                    {user.givenName}
                  </Button>

                  <Menu
                    anchorEl={userMenuTriggerRef.current}
                    open={isUserMenuOpen}
                    autoFocus={false}
                    MenuListProps={{
                      subheader: <ListSubheader>{user.fullName}</ListSubheader>,
                    }}
                    onClose={closeUserMenu}
                  >
                    <MenuItem to="/profile" component={NavLink} onClick={closeUserMenu}>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText>Account Settings</ListItemText>
                    </MenuItem>

                    <Divider />

                    <MenuItem onClick={logUserOut}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText>Log out</ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Toolbar>
          </Stack>
        </Container>
      </AppBar>

      <Drawer
        open={isDrawerOpen}
        variant="temporary"
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{ width: '240px' }}>
          {!!navLinks.length && (
            <List>
              {navLinks.map((navLink, navLinkIndex) => (
                <ListItem key={`${navLink.href}-${navLinkIndex}`} disablePadding>
                  <ListItemButton
                    selected={pathname?.startsWith(navLink.href)}
                    to={navLink.href}
                    component={NavLink}
                    onClick={closeDrawer}
                  >
                    <ListItemText primary={navLink.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Drawer>
    </>
  );
}

// required due to using React.Context legacy
SiteHeader.contextTypes = { user: PropTypes.object };

export { SiteHeader };
