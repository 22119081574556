import React, { ReactElement, useCallback, useState } from 'react';

import { Alert, Button, Dialog, DialogContent, DialogContentText } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import ReservationService from '~/services/ReservationService';

interface Props {
  propertyId: string;
  channelManager: string;
}

export default function PropertyPullOccupanciesPane(props: Props): ReactElement {
  const { propertyId, channelManager } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const supportsPullingOccupancies = channelManager === PROPERTY_CHANNEL_MANAGERS.derbysoft;

  const triggerProcessing = useCallback(async () => {
    setShowModal(true);
    setInProgress(true);
    setError(null);
    setShowConfirmationMessage(false);

    try {
      await ReservationService.triggerHotelUpdates(propertyId);
      await setShowConfirmationMessage(true);
    } catch (e) {
      if (e.errors) {
        setError(e.errors[0].message);
      } else {
        setError(e.message);
      }
    } finally {
      setInProgress(false);
    }
  }, [propertyId]);

  const handleClick = async () => {
    if (!window.confirm('this will pull occupancies and regenerate capacities for all room rates, are you sure?')) {
      return;
    }

    await triggerProcessing();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    supportsPullingOccupancies && (
      <dl className="col-sm-3">
        <dd>
          <Button onClick={handleClick} type="button" variant="contained">
            Pull Occupancies
          </Button>
        </dd>

        <Dialog open={showModal} onClose={handleClose}>
          <DialogContent>
            <DialogContentText variant="h6">Pulling occupancies</DialogContentText>
            <DialogContentText>It might take some time for occupancy data to be processed</DialogContentText>

            {inProgress && <Spinner inline />}
            {error && <ErrorDisplay message={error} />}
            {showConfirmationMessage && (
              <Alert severity="success">
                <strong>Success!</strong> Occupancies pulled and capacities updated
              </Alert>
            )}
          </DialogContent>
        </Dialog>
      </dl>
    )
  );
}
