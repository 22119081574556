import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Button, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import VendorSearchForm from '~/components/Common/Forms/VendorSearchForm';

import featureToggle from '~/utils/featureToggle';

import ChainsSearchContainer from './ChainsSearchContainer';
import NewRentalModal from './NewRentalModal';
import PropertiesSearchContainer from './PropertiesSearchContainer';
import PropertyRelationsContainer from './PropertyRelationsContainer';

const TAB_VENDOR = 'vendor';

const TabsMap = {
  [TAB_VENDOR]: {
    title: 'Vendors',
    component: VendorSearchForm,
  },
  chains: {
    title: 'Chains',
    component: ChainsSearchContainer,
  },
  properties: {
    title: 'Properties',
    component: PropertiesSearchContainer,
  },
  relations: {
    title: 'Relations',
    component: PropertyRelationsContainer,
  },
} as const;

type TabKey = keyof typeof TabsMap;

const allTabs = Object.keys(TabsMap);

export default function VendorsPageContainer() {
  const [key, setKey] = useState<TabKey>(TAB_VENDOR);
  const [isNewRentalModalVisible, setIsNewRentalModalVisible] = useState(false);

  const currentTab = TabsMap[key];

  const title = `Vendors / ${currentTab.title}`;

  const handleChange = (_: React.SyntheticEvent, newKey: TabKey) => {
    setKey(newKey);
  };

  const showNewRentalModal = () => {
    setIsNewRentalModalVisible(true);
  };

  const hideNewRentalModal = () => {
    setIsNewRentalModalVisible(false);
  };

  const newRentalEnabled = featureToggle.availableToShow('RENTALS_ENABLED');

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title}>
        <Button
          variant="contained"
          onClick={showNewRentalModal}
          sx={{ marginLeft: 'auto', visibility: !newRentalEnabled ? 'hidden' : 'visible' }}
        >
          New Rental
        </Button>
      </PageHeader>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row' }}>
        <Tabs value={key} onChange={handleChange} id="vendors">
          {allTabs.map((tabKey) => (
            <Tab key={tabKey} label={TabsMap[tabKey].title} value={tabKey} />
          ))}
        </Tabs>
      </Box>

      <Box pt={4}>
        <Box component={currentTab.component} />
      </Box>
      <NewRentalModal isModalOpen={isNewRentalModalVisible} closeModal={hideNewRentalModal} />
    </Container>
  );
}
