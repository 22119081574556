import React, { useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { Button, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

interface Props {
  promotion: App.Bedbank.PromotionsEdit;
  isOpen: boolean;
  onClose: () => void;
  handleArchivedPromotionUpdate: (promotion: App.Bedbank.PromotionsEdit) => void;
}

export default function ArchivedPromotionUpdateDialog(props: Props) {
  const { isOpen, onClose, promotion, handleArchivedPromotionUpdate } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      startDate: promotion.promoPeriodSchedule.promoPeriodFrom,
      endDate: promotion.promoPeriodSchedule.promoPeriodTo,
    },
    mode: 'onBlur',
  });

  const handleSubmission = useMemo(
    () =>
      handleSubmit((data) => {
        const promotionToUpdate: App.Bedbank.PromotionsEdit = {
          ...promotion,
          promoPeriodSchedule: {
            promoPeriodFrom: data.startDate,
            promoPeriodTo: data.endDate,
          },
        };

        handleArchivedPromotionUpdate(promotionToUpdate);
        onClose();
      }),
    [handleSubmit, promotion, handleArchivedPromotionUpdate, onClose],
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmission}>
        <DialogTitle>Update Archived Promotion</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Typography variant="body2">
              The archived promotion will be moved to the active promotions tab on click. You will need to save the
              property for the changes to take affect.
            </Typography>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Promotion start date"
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  error={fieldState.invalid}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  type="date"
                  fullWidth
                />
              )}
            />
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Promotion end date"
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  error={fieldState.invalid}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  type="date"
                  fullWidth
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" type="submit">
            Update promotion
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
