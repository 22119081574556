import React, { useEffect } from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import RemoveIcon from '@mui/icons-material/Remove';
import SouthIcon from '@mui/icons-material/South';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

import {
  availabilityFiltersOptions,
  brandOptions,
  marketOptions,
  scheduleFiltersOptions,
} from '~/consts/offerFilterOptions';
import { CURRENT_OFFLINE, FRONT_PAGE_END, FRONT_PAGE_START } from '~/consts/offerFilterTypes';

type ScheduleProps = {
  filter: string;
  brand: string;
  idx: number;
  market: string;
  start: string;
  end: string;
  removeScheduleFilter: (index: number) => void;
  handleFilterChange: (index: number, field: string, value: unknown) => void;
};

export const ScheduleFilter = (props: ScheduleProps) => {
  const isFrontPageFilter = props.filter === FRONT_PAGE_START || props.filter === FRONT_PAGE_END;

  const currentIndex = props.idx;

  const handleFilterChange = props.handleFilterChange;

  const removeFilter = () => {
    props.removeScheduleFilter(currentIndex);
  };

  return (
    <>
      <Grid xs={12} sm={2} lg={3}>
        <TextField
          label="Filter by"
          variant="outlined"
          value={props.filter}
          onChange={(e) => handleFilterChange(currentIndex, 'filter', e.target.value)}
          select
          fullWidth
        >
          {scheduleFiltersOptions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid container xs={12} sm={8} lg={8} spacing={2}>
        {isFrontPageFilter && (
          <>
            <Grid xs={12} md={6}>
              <TextField
                label="Brand"
                variant="outlined"
                value={props.brand}
                onChange={(e) => handleFilterChange(currentIndex, 'brand', e.target.value)}
                select
                fullWidth
              >
                {brandOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid xs={12} md={6}>
              <TextField
                label="Market"
                variant="outlined"
                value={props.market}
                onChange={(e) => handleFilterChange(currentIndex, 'market', e.target.value)}
                select
                fullWidth
              >
                {marketOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )}

        <Grid xs={12}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" gap={2}>
            <DateTimeWidget
              value={props.start}
              onChange={(time) => handleFilterChange(currentIndex, 'start', time)}
              label="From"
              defaultTimezone={isFrontPageFilter ? 'UTC' : undefined}
            />

            <ArrowRightAltIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
            <SouthIcon sx={{ display: { xs: 'block', sm: 'none' } }} />

            <DateTimeWidget
              value={props.end}
              onChange={(time) => handleFilterChange(currentIndex, 'end', time)}
              label="To"
              defaultTimezone={isFrontPageFilter ? 'UTC' : undefined}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid xs={12} sm={2} lg={1} display="flex" alignItems="center">
        <Button variant="outlined" onClick={removeFilter} startIcon={<RemoveIcon />} fullWidth>
          Remove
        </Button>
      </Grid>
    </>
  );
};

type AvailabilityProps = {
  idx: number;
  filter: string;
  brand: string;
  market: string;
  removeAvailabilityFilter: (index: number) => void;
  handleFilterChange: (index: number, field: string, value: unknown) => void;
};

export const AvailabilityFilter = (props: AvailabilityProps) => {
  const currentIndex = props.idx;

  const handleFilterChange = props.handleFilterChange;

  const removeFilter = () => {
    props.removeAvailabilityFilter(currentIndex);
  };

  const isCurrentOffline = props.filter === CURRENT_OFFLINE;

  useEffect(() => {
    if (!isCurrentOffline) {
      handleFilterChange(currentIndex, 'brand', 'luxuryescapes');
      handleFilterChange(currentIndex, 'market', 'any');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentOffline, currentIndex]);

  return (
    <>
      <Grid xs={12} sm={4} md={5}>
        <TextField
          variant="outlined"
          label="Filter by"
          onChange={(e) => handleFilterChange(currentIndex, 'filter', e.target.value)}
          value={props.filter}
          select
          fullWidth
        >
          {availabilityFiltersOptions.map((field) => (
            <MenuItem key={field.value} value={field.value}>
              {field.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid xs={12} sm={3} md={3}>
        <TextField
          variant="outlined"
          label="Brand"
          onChange={(e) => handleFilterChange(currentIndex, 'brand', e.target.value)}
          value={props.brand}
          select
          fullWidth
        >
          {isCurrentOffline && (
            <MenuItem key="all" value="all">
              All
            </MenuItem>
          )}
          {!isCurrentOffline &&
            brandOptions.map((field) => (
              <MenuItem key={field.value} value={field.value}>
                {field.title}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid xs={12} sm={3} md={3}>
        <TextField
          variant="outlined"
          label="Market"
          onChange={(e) => handleFilterChange(currentIndex, 'market', e.target.value)}
          value={props.market}
          select
          fullWidth
        >
          {isCurrentOffline && (
            <MenuItem key="all" value="all">
              All
            </MenuItem>
          )}
          {!isCurrentOffline &&
            marketOptions.map((field) => (
              <MenuItem key={field.value} value={field.value}>
                {field.label}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid xs={12} sm={2} md={1}>
        <Button variant="outlined" onClick={removeFilter} startIcon={<RemoveIcon />} fullWidth>
          Remove
        </Button>
      </Grid>
    </>
  );
};
