import React from 'react';

import { useHistory } from 'react-router-dom';

import ChainSearchResults from './ChainsSearchResults';

const ChainSearchResultsContainer = (props) => {
  const history = useHistory();

  return (
    <ChainSearchResults
      onRowClick={(row) => {
        if (props.tableType === 'id') {
          history.push('/vendors/chain/' + row.sfid);
        } else {
          history.push('/vendors/chain/' + row.parentid);
        }
      }}
      {...props}
    />
  );
};

export default ChainSearchResultsContainer;
