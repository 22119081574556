import React, { useCallback, useState } from 'react';

import { Autocomplete, Button, FormGroup, Stack, TextField, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { Agency, AvailableAffiliations } from '~/types/services/agentHub';

export interface AgencyEditorProps {
  data: Agency;
  onSave: (agencyData: Agency) => void;
  onCancel: () => void;
  setLoadingState: (loadingState: Utils.FetchingState) => void;
  loadingState: Utils.FetchingState;
  availableAffiliations: AvailableAffiliations;
  isEditing?: boolean;
  editedData?: Partial<Agency>;
}

export default function AgencyEditor({
  data,
  onSave,
  onCancel,
  availableAffiliations,
  loadingState,
}: AgencyEditorProps) {
  const [editedData, setEditedData] = useState<Agency>(data);

  const handleAgencyUpdate = useCallback(async () => {
    onSave(editedData);
  }, [editedData, onSave]);

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} style={{ minWidth: '500px' }}>
        <Stack mt={2} direction="column" spacing={2}>
          <FormGroup>
            <TextField
              label="Name"
              value={editedData.name}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={editedData.businessCodeType}
              value={editedData.businessCodeValue}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, businessCodeValue: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Autocomplete
              value={availableAffiliations?.find((affiliation) => affiliation.name === editedData.affiliation)}
              options={availableAffiliations}
              getOptionLabel={(option) => `${option.region} - ${option.name}`}
              onChange={(_, value) => setEditedData({ ...editedData, affiliation: value ? value.name : '' })}
              renderInput={(params) => <TextField {...params} label="Affiliation" />}
            />
          </FormGroup>
          <Typography mt={2} variant="h6" sx={{ gridColumn: 'span 2' }}>
            Address
          </Typography>

          <FormGroup>
            <TextField
              label="Street"
              value={editedData.address}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, address: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="City"
              value={editedData.city}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, city: e.target.value })}
            />
          </FormGroup>

          <FormGroup>
            <TextField
              label="Postal Code"
              value={editedData.postcode}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, postcode: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="State"
              value={editedData.state}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, state: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Country"
              value={editedData.country}
              required
              fullWidth
              onChange={(e) => setEditedData({ ...editedData, country: e.target.value })}
            />
          </FormGroup>
          {loadingState == 'failed' && (
            <ErrorDisplay message="There was an error updating the agency" severity="error" />
          )}
        </Stack>
      </form>
      <Stack mt={2} display="flex" justifyContent="space-around" direction="row">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleAgencyUpdate}>
          Save
        </Button>
      </Stack>
    </>
  );
}
