import React from 'react';

import { Typography, type TypographyProps } from '@mui/material';

export default function DefinitionText({ children, ...props }: TypographyProps<'div'>) {
  return (
    <Typography variant="body1" component="div" fontSize={16} {...props}>
      {children}
    </Typography>
  );
}
