import React from 'react';

import { LIST_VISIBILITY } from '../../../consts/schedule';
import { scheduleForBrandAndType } from '../../../utils/scheduleForBrandAndType';
import NewScheduleForm from '../../Common/Forms/NewScheduleForm';

export default class NewScheduleByBrand extends React.Component {
  state = {
    editing: false,
    start: '',
    end: '',
  };

  onError = (data) => {
    this.setState({
      editing: false,
      errors: data.errors,
    });
  };

  onSuccess = () => {
    this.setState({
      editing: false,
      errors: [],
    });
  };

  onSave = () => {
    this.setState({
      editing: true,
    });
  };

  onApplyToAll = (schedule) => {
    this.setState({
      start: schedule.schedule.start,
      end: schedule.schedule.end,
    });
  };

  setScheduleForBrand = ({ schedules, brand, type, scheduleKey }) => {
    let schedule;
    schedule = this.state.start
      ? this.state[scheduleKey]
      : scheduleForBrandAndType({ schedules, brand, type, scheduleKey });
    return schedule;
  };

  render() {
    const { schedules, brands, offerId, shouldMigrateSchedule } = this.props;
    const scheduleIdListForBrands = (brand, type) => {
      return schedules.filter((s) => s.brand === brand && s.type === type).map((s) => s.id);
    };
    return (
      <ul className="schedule-brands-container">
        {brands.map((brand, index) => {
          return (
            <li key={`${brand}-${index}`} className="schedule-brand-row">
              <h2>{brand.title}</h2>
              <NewScheduleForm
                brands={[brand.value]}
                disabled={shouldMigrateSchedule || this.state.editing}
                offerId={offerId}
                onSave={this.onSave}
                onError={this.onError}
                onSuccess={this.onSuccess}
                type={LIST_VISIBILITY}
                regions={[brand.region]}
                showSave={true}
                start={this.setScheduleForBrand({
                  schedules,
                  brand: brand.value,
                  type: LIST_VISIBILITY,
                  scheduleKey: 'start',
                })}
                end={this.setScheduleForBrand({
                  schedules,
                  brand: brand.value,
                  type: LIST_VISIBILITY,
                  scheduleKey: 'end',
                })}
                idList={scheduleIdListForBrands(brand.value, LIST_VISIBILITY)}
                isDeleteAllowed={true}
                enableUtilBtn={true}
                onApplyToAll={this.onApplyToAll}
                schedules={schedules}
                brand={brand.value}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
