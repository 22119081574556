import React, { useCallback, useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';

import { Alert, Box, Button } from '@mui/material';

import AsincCreateNewUser from '~/components/Common/AsincCreateNewUser';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import PrivilegedUserSearchResults from '~/components/Common/PrivilegedUserSearchResults';
import Spinner from '~/components/Common/Spinner';

import UsersService from '~/services/UsersService';

type Props = { vendorName?: string };

export default function VendorUsersPageContainer(_: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [users, setUsers] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCreateNewUserModal, setShowCreateNewUserModal] = useState(false);
  const [userCreated, setUserCreated] = useState(false);

  const closeCreateNewUser = useCallback(() => {
    setShowCreateNewUserModal(false);
  }, []);

  const openCreateNewUser = useCallback(() => {
    setShowCreateNewUserModal(true);
  }, []);

  const userRegistrationSuccess = useCallback(() => {
    setShowCreateNewUserModal(false);
    setUserCreated(true);
  }, []);

  useEffect(() => {
    setLoading(true);
    UsersService.getVendorUsers(vendorId)
      .then((val) => {
        setUsers(val);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vendorId]);

  return (
    <div>
      <Box mb={2}>
        <Button variant="contained" onClick={openCreateNewUser}>
          New Vendor User
        </Button>
      </Box>

      <AsincCreateNewUser
        show={showCreateNewUserModal}
        onHide={closeCreateNewUser}
        userRegistrationSuccess={userRegistrationSuccess}
        userData={{
          vendors: [vendorId],
          roles: ['vendor-user'],
        }}
      />

      {userCreated && (
        <Alert severity="success">
          <strong>Success!</strong> User has been registered.
        </Alert>
      )}

      {isLoading && <Spinner />}

      {error && <ErrorDisplay message={error} />}

      <PrivilegedUserSearchResults data={users} />
    </div>
  );
}
