// validateOfferSchedule checks if the current datetime fits within the
// availability schedule of the offer
export default function (offer, brand) {
  const brandSchedules = offer.brand_schedules.filter((schedule) => schedule.brand === brand);

  if (brandSchedules.length === 0) {
    return false;
  }

  let availabilitySchedule;
  for (var i = 0; i < brandSchedules.length; i++) {
    const schedule = brandSchedules[i];

    if (schedule.type === 'availability' && (schedule.region === 'world' || schedule.region === 'AU')) {
      availabilitySchedule = schedule;
    }
  }
  return (
    Boolean(availabilitySchedule) &&
    new Date(availabilitySchedule.start) < Date.now() &&
    new Date(availabilitySchedule.end) > Date.now()
  );
}
