import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

import UpdateInsuranceModal from './UpdateInsuranceModal';

type Props = {
  order: App.Order;
  currentInsurance: App.InsuranceItem;
  onSuccess: () => void;
  refunds: {
    status: number;
    result: Array<{ accounting_amount: string }>;
  };
};

export default function UpdateInsurance(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const [totalTicketPrice, setTotalTicketPrice] = useState(0);
  const [currentOrderAmount, setCurrentOrderAmount] = useState(0);
  const refunds = props.refunds?.result;

  useEffect(() => {
    const insurancesTotal = props.order.insurance_items
      .filter((i) => i.status_reason !== 'insurance_upgraded')
      .reduce((acc, curr) => acc + curr.total, 0);
    const orderTotal = Math.round((props.order.total - insurancesTotal) * 100) / 100;

    const orderAmount = parseFloat(
      (orderTotal - refunds?.reduce((acc, refund) => acc + Number(refund.accounting_amount), 0)).toFixed(2),
    );

    setCurrentOrderAmount(orderAmount);
    setTotalTicketPrice(props.currentInsurance.cover_amount);

    if (!props.currentInsurance.cover_amount) {
      setTotalTicketPrice(orderAmount);
    }
  }, []);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      {props.currentInsurance && props.currentInsurance.status !== 'cancelled' && (
        <>
          <Button onClick={toggleModal} variant="text">
            Update insurance
          </Button>

          <UpdateInsuranceModal
            totalTicketPrice={totalTicketPrice}
            currentOrderAmount={currentOrderAmount}
            currentInsurance={props.currentInsurance}
            order={props.order}
            show={showModal}
            onSuccess={props.onSuccess}
            onClose={toggleModal}
          />
        </>
      )}
    </>
  );
}
