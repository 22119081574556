import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container, Stack } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import { LuxMomentsPageField } from '~/components/Offers/Edit/LuxMomentsPageField';

import { getLuxMomentByOfferId } from '~/services/LuxMomentsService';

interface RouteParams {
  id_offer: string;
}

function LuxMomentsPage() {
  const { id_offer: offerId } = useParams<RouteParams>();

  const [luxMomentsPage, setLuxMomentsPage] = useState(null);

  useEffect(() => {
    getLuxMomentByOfferId(offerId)
      .then((res) => {
        if (res.status === 200) {
          setLuxMomentsPage({
            ...res.result?.[0].fields,
            contentfulUrl: res.result?.[0].contentfulUrl,
          });
        }
      })
      .catch(() => {
        setLuxMomentsPage(null);
      });
  }, [offerId]);

  return (
    <Container>
      <Button variant="outlined" component={Link} to={'/offers/' + offerId} sx={{ mb: 2 }}>
        <ChevronLeftIcon /> Return to offer
      </Button>
      <PageHeader title=" Lux Moments Page">
        {luxMomentsPage && (
          <Button variant="contained" href={luxMomentsPage.contentfulUrl} target="_blank">
            Open in Contentful
          </Button>
        )}
      </PageHeader>
      {luxMomentsPage && (
        <Stack direction="column" spacing={2}>
          <LuxMomentsPageField label="Story ID" value={luxMomentsPage.storyId} />
          <LuxMomentsPageField label="Offer ID" value={luxMomentsPage.offerId} />
          <LuxMomentsPageField
            label="Story Tile Image"
            value={luxMomentsPage.storyTileImage ? 'Story Tile Image added' : null}
          />
          <LuxMomentsPageField
            label="Tile Preview Image"
            value={luxMomentsPage.tilePreviewImage ? 'Tile Preview Image added' : null}
          />
          <LuxMomentsPageField label="Story Tile Heading" value={luxMomentsPage.storyTileHeading} />
          <LuxMomentsPageField label="Story Tile Sub Heading" value={luxMomentsPage.storyTileSubHeading} />
          <LuxMomentsPageField label="Regions" value={luxMomentsPage.regions?.length > 0 ? 'Region added' : null} />
          <LuxMomentsPageField label="Order" value={luxMomentsPage.order} />
          <LuxMomentsPageField label="Vimeo Video ID" value={luxMomentsPage.vimeoId} />
          <LuxMomentsPageField label="Video" value={luxMomentsPage.video ? 'Video added' : null} />
        </Stack>
      )}
    </Container>
  );
}

export default LuxMomentsPage;
