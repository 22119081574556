import React from 'react';

import { Stack } from '@mui/material';

import CustomOfferOrderItem from './CustomOfferOrderItem';

interface Props {
  customOfferItem: App.CustomOfferOrderItem;
  showRefundModal: ({ itemId }) => void;
}

export default function CustomOfferOrderList({ customOfferItem, showRefundModal }: Props) {
  return (
    <Stack className="T-Order-Item-List">
      {customOfferItem.custom_offer.items.map((item, index) => {
        const count = index + 1;
        return (
          <CustomOfferOrderItem
            key={customOfferItem.id}
            customOfferItem={customOfferItem}
            item={item}
            count={count}
            showRefundModal={showRefundModal}
          />
        );
      })}
    </Stack>
  );
}
