import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { PROMO_DEVICE_TYPES } from '~/consts/promo';

const getDeviceType = (key: string) => {
  return PROMO_DEVICE_TYPES.find((device) => device.key === key);
};

export default function devicesRenderer(params: GridRenderCellParams<App.PromoData, string[]>) {
  if (!params.value?.length) {
    return <Typography>All devices</Typography>;
  }

  return (
    <Stack direction="column" spacing={1}>
      {params.value.map((key) => {
        const deviceType = getDeviceType(key);
        return <Typography key={key}>{deviceType.value}</Typography>;
      })}
    </Stack>
  );
}
