import React, { useCallback, useState } from 'react';

import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material';

import { Option, PromotionSearchFormValues } from '../types';

const PROMO_STATUS_OPTIONS: Option[] = [
  { label: 'ALL', value: 'ALL' },
  { label: 'LIVE', value: 'LIVE' },
  { label: 'EXPIRED', value: 'EXPIRED' },
  { label: 'UPCOMING', value: 'UPCOMING' },
];

const INITIAL_SEARCH_STATE: PromotionSearchFormValues = {
  rateCodes: '',
  vendorCode: '',
  promotionName: '',
  status: { label: 'ALL', value: 'ALL' },
};

type Props = {
  loading: boolean;
  onSearch: (searchValues: PromotionSearchFormValues) => void;
};

const PromotionSearchForm = (props: Props): JSX.Element => {
  const { loading, onSearch } = props;
  const [searchValues, setSearchValues] = useState<PromotionSearchFormValues>(INITIAL_SEARCH_STATE);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target && event?.target?.name) {
        setSearchValues({
          ...searchValues,
          [event.target.name]: event.target.value,
        });
      }
    },
    [searchValues],
  );

  const handleCruiseLineChange = (_, selectedOption: Option | null) => {
    setSearchValues((prev) => ({
      ...prev,
      status: selectedOption,
    }));
  };

  const handleSearch = useCallback(() => {
    onSearch({
      rateCodes: searchValues.rateCodes,
      vendorCode: searchValues.vendorCode,
      promotionName: searchValues.promotionName,
      ...(searchValues.status.value !== 'ALL' && { status: searchValues.status }),
    });
  }, [searchValues, onSearch]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <Box flexGrow={2}>
          <TextField
            fullWidth
            name="promotionName"
            label="Promotion Name"
            onChange={handleChangeField}
            value={searchValues.promotionName}
          />
        </Box>
        <Box flexGrow={1}>
          <Autocomplete
            openOnFocus
            id="select-promotion-status"
            value={searchValues.status}
            options={PROMO_STATUS_OPTIONS}
            onChange={handleCruiseLineChange}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} fullWidth label="Status" />}
          />
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box flexGrow={2}>
          <TextField
            fullWidth
            name="rateCodes"
            label="Rate Codes"
            onChange={handleChangeField}
            value={searchValues.rateCodes}
            placeholder="Use comma to search multiple rate codes (DSC, BESTFARE)"
          />
        </Box>
        <Box flexGrow={1}>
          <TextField
            fullWidth
            name="vendorCode"
            label="Vendor Code"
            onChange={handleChangeField}
            value={searchValues.vendorCode}
          />
        </Box>

        <Box>
          <Button variant="contained" disabled={loading} onClick={handleSearch}>
            Search Content
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default PromotionSearchForm;
