import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import RoomTypeCapacityForm from '~/components/Common/Forms/RoomTypeCapacityForm';
import RoomTypeOccupancyForm from '~/components/Common/Forms/RoomTypeOccupancyForm';
import PermissionComponent from '~/components/Common/PermissionedComponent';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import RoomTypeCapacity from './RoomTypeCapacity';

interface RoomTypeCapacitiesProps {
  propertyId: string;
  roomType: {
    id: string;
  };
  roomRate: {
    id: string;
  };
  capacities: Array<{
    id: string;
    is_dynamic: boolean;
  }>;
  deleteRoomTypeCapacity: (id: string) => void;
  onRequestEdit?: (capacity: unknown) => void;
  onUpdateRoomTypeCapacities: () => void;
  onDeleteAll: () => void;
  isDeletingAll: boolean;
}

export default function RoomTypeCapacities(props: RoomTypeCapacitiesProps) {
  const [editing, setEditing] = useState(null);
  const [mode, setMode] = useState(null);

  const showConfirmDialog = useConfirmDialog();

  const openOccupancyModal = () => {
    setMode('occupancy');
  };

  const openEditBox = (capacity) => {
    setMode('capacity');
    setEditing(capacity);
  };

  const closeAddBox = () => {
    setMode(null);
    setEditing(null);
  };

  const onRoomTypeCapacityUpdated = () => {
    props.onUpdateRoomTypeCapacities();
    setMode(null);
    setEditing(null);
  };

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete all? This action cannot be undone.',
    });

    if (!confirmed || props.isDeletingAll) return;

    props.onDeleteAll();
  };

  const renderCapacityModal = () => {
    return (
      <Dialog open={true} onClose={closeAddBox}>
        <DialogTitle>Set Room Rate Capacity</DialogTitle>

        <DialogContent>
          <RoomTypeCapacityForm
            capacity={editing}
            propertyId={props.propertyId}
            roomTypeId={props.roomType.id}
            roomRateId={props.roomRate.id}
            onCancel={closeAddBox}
            onRoomTypeCapacityUpdated={onRoomTypeCapacityUpdated}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const renderOccupancyModal = () => {
    return (
      <Dialog open={true} onClose={closeAddBox}>
        <DialogTitle>Set Room Rate Capacity</DialogTitle>

        <DialogContent>
          <RoomTypeOccupancyForm
            capacity={editing}
            propertyId={props.propertyId}
            roomType={props.roomType}
            roomRate={props.roomRate}
            onCancel={closeAddBox}
            onRoomTypeCapacityUpdated={onRoomTypeCapacityUpdated}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const renderModal = () => {
    if (mode === 'capacity') {
      return renderCapacityModal();
    }

    if (mode === 'occupancy') {
      return renderOccupancyModal();
    }

    return <Dialog open={false} />;
  };

  const externalProviderData = props.capacities.filter((x) => x.is_dynamic);

  return (
    <Grid className="RoomTypeCapacities">
      <Grid>
        <Typography color="secondary" textTransform="uppercase">
          Room Rate Capacity
        </Typography>
      </Grid>

      {props.capacities.length === 0 ? (
        <p>
          <i>No capacity set</i>
        </p>
      ) : (
        <div className="room-type-capacity-list">
          {props.capacities.map((capacity) => (
            <RoomTypeCapacity
              key={capacity.id}
              capacity={capacity}
              deleteRoomTypeCapacity={props.deleteRoomTypeCapacity}
              onRequestEdit={openEditBox}
            />
          ))}
        </div>
      )}

      {externalProviderData.length > 0 && (
        <p>
          <i>* Capacities controlled by channel manager</i>
        </p>
      )}

      <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
        <Stack direction="row" spacing={2} justifyContent="space-between" mt={2}>
          <Stack direction="row" spacing={2}>
            <Button onClick={() => openEditBox(null)} variant="contained" startIcon={<AddIcon />}>
              Add
            </Button>
            <Button onClick={openOccupancyModal} variant="contained" startIcon={<AddIcon />}>
              Advanced
            </Button>
          </Stack>

          {props.capacities && props.capacities.length > 0 && externalProviderData.length == 0 && (
            <div>
              <Button title="Delete all Capacities" variant="text" startIcon={<DeleteIcon />} onClick={handleDelete}>
                Delete All
              </Button>
            </div>
          )}
        </Stack>
      </PermissionComponent>

      {renderModal()}
    </Grid>
  );
}
