/* src/components/Common/Payment/OrderAddonsSummary.js */
import React from 'react';

import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';

class OrderAddonsSummary extends React.Component {
  render() {
    const { addons, currencyCode } = this.props;

    return (
      <div className="order-summary-panel">
        <div className="offer-meta">
          <div className="offer-title">Add Ons</div>
        </div>

        <div className="summary-item-list">
          {addons.map((addon, i) => (
            <div className="summary-item-item" key={i}>
              <div className="row">
                <div className="col-xs-12 col-sm-10">
                  <div className="item-detail-container">
                    <div className="item-detail item-header">
                      <div className="item-name">{addon.name}</div>
                      Qty: {addon.qty}
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-2">
                  <div className="item-amounts">
                    <div className="item-price">
                      {currencyFormatter.format(addon.price * addon.qty, {
                        code: currencyCode,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

OrderAddonsSummary.propTypes = {
  addons: PropTypes.array,
  currencyCode: PropTypes.string,
};

export default OrderAddonsSummary;
