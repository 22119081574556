import React from 'react';

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';

import { copyImage } from '../../../services/ImageService';
import OffersService from '../../../services/OffersService';
import ErrorDisplay from '../../Common/ErrorDisplay';

interface Props {
  isCopyModalVisible: boolean;
  hideCopyModal: () => void;
  copyOfferImagesData: () => void;
  setAsNotReady: () => void;
  onAddImage: (cloudinaryId: string, filename: string) => Promise<void>;
  currentOfferStatus: string;
}

export default function CopyOfferImagesModalContainer(props: Props) {
  const [copyOfferId, setCopyOfferId] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const hideCopyModal = () => {
    setCopyOfferId('');
    setHasError(false);
    setErrorMessage('');
    props.hideCopyModal();
  };

  const checkOffersCompatibility = (copiedOffer, currentOfferStatus) => {
    let areOffersCompatible = true;
    let errorMessage = '';

    if (copiedOffer.status !== 'content-approved') {
      areOffersCompatible = false;
      errorMessage = 'The offer you want to copy is not content-approved';
    }

    if (areOffersCompatible && currentOfferStatus === 'content-approved') {
      areOffersCompatible = false;
      errorMessage = "You can't overwrite the details of a content-approved offer";
    }

    return {
      areOffersCompatible,
      errorMessage,
    };
  };

  const onAddImage = async (cloudinaryId, filename) => {
    return new Promise((resolve, reject) => {
      props.onAddImage(cloudinaryId, filename).then(resolve).catch(reject);
    });
  };

  const searchAndCopyOfferDetails = async (event) => {
    event.preventDefault();

    // remove old errors if any
    setHasError(false);
    setErrorMessage('');

    let offerData;
    let offerImagesData;

    try {
      offerData = await OffersService.getOffer(copyOfferId.trim());
    } catch (err) {
      setHasError(true);
      setErrorMessage('There is no such offer');
      return;
    }

    const check = checkOffersCompatibility(offerData.result, props.currentOfferStatus);

    if (!check.areOffersCompatible) {
      setHasError(true);
      setErrorMessage(check.errorMessage);
      return;
    }

    try {
      offerImagesData = await OffersService.getImages(copyOfferId.trim());
    } catch (err) {
      setHasError(true);
      setErrorMessage("Offer does't have images");
      return;
    }

    props.setAsNotReady();

    for (const image of offerImagesData.result) {
      try {
        const copyImgResponse = await copyImage(image.id_cloudinary_external);
        await onAddImage(copyImgResponse.body.result.id, image.filename);
      } catch (e) {
        setHasError(true);
        setErrorMessage(e.message);
        return;
      }
    }

    props.copyOfferImagesData();
  };

  const handleChange = (event) => {
    setCopyOfferId(event.target.value);
  };

  return (
    <Container maxWidth="xl">
      <Dialog open={props.isCopyModalVisible} onClose={hideCopyModal}>
        <DialogTitle>Copy editable details from another content-approved offer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that this action will pre-fill the details and will let you review and save the result. If you
            have data in any of the editable fields, it will be overwritten.
          </DialogContentText>
          <form onSubmit={searchAndCopyOfferDetails}>
            <Stack direction="column" spacing={2} mt={1}>
              {hasError && <ErrorDisplay message={errorMessage} />}
              <Stack direction="row" spacing={2}>
                <TextField type="text" placeholder="Type offer ID here" value={copyOfferId} onChange={handleChange} />
                <Button type="submit" color="primary">
                  Copy
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideCopyModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
