import React, { Fragment, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import useQuery from '~/hooks/useQuery';

import { addQuery, removeQuery } from '~/utils/url';

import BookingList from './BookingList';
import BookingSearchFilters from './BookingSearchFilters';
import { SearchFilters } from './constants';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function BookingsPage() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const currentPage = useMemo(() => parseInt(query.get('page')) || 1, [query]);

  const searchFilters = useMemo(() => {
    const filters: SearchFilters = {};

    if (query.get('bookingNumber')) filters.bookingNumber = query.get('bookingNumber');
    if (query.get('sessionId')) filters.sessionId = query.get('sessionId');
    if (query.get('orderId')) filters.orderId = query.get('orderId');
    if (query.get('customerId')) filters.customerId = query.get('customerId');
    if (query.get('departureId')) filters.departureId = query.get('departureId');

    return filters;
  }, [query]);

  const onSearch = useCallback(
    (params: SearchFilters) => {
      const filtersToRemove: string[] = [];

      if (!params.bookingNumber) filtersToRemove.push('bookingNumber');
      if (!params.sessionId) filtersToRemove.push('sessionId');
      if (!params.orderId) filtersToRemove.push('orderId');
      if (!params.customerId) filtersToRemove.push('customerId');
      if (!params.departureId) filtersToRemove.push('departureId');

      const newLocation = addQuery(location, {
        bookingNumber: params.bookingNumber,
        sessionId: params.sessionId,
        orderId: params.orderId,
        customerId: params.customerId,
        departureId: params.departureId,
      });

      history.push(removeQuery(newLocation, ...filtersToRemove));
    },
    [history, location],
  );

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  return (
    <Fragment>
      <BookingSearchFilters querySearchFilters={searchFilters} onSearch={onSearch} />

      <BookingList
        brand={brand}
        page={currentPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
        searchFilters={searchFilters}
        sizePerPage={DEFAULT_SIZE_PER_PAGE}
      />
    </Fragment>
  );
}
