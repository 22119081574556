import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';

import PlaceLookUp from '~/components/Common/Forms/PlaceLookUp';
import { PropertyLocationOverride } from '~/components/Content/PropertyLocationOverride/Columns';
import {
  TYPEAHEAD_PLACE_TYPES,
  TYPEAHEAD_PROPERTY_TYPES,
} from '~/components/Content/PropertyLocationOverride/constants';

import useCurrentUser from '~/hooks/useCurrentUser';

import SearchService, { TypeaheadSearchResult } from '~/services/SearchService';

type Props = {
  onClose: () => void;
  rowToUpdate: PropertyLocationOverride;
  data?: PropertyLocationOverride[];
};

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const StyledButton = styled(IconButton)`
  margin-left: 10px;
`;

const UpdatePropertyLocationOverrideForm = ({ onClose, rowToUpdate, data = [] }: Props) => {
  const [property, setProperty] = useState<TypeaheadSearchResult | undefined>(undefined);
  const [place, setPlace] = useState<TypeaheadSearchResult | undefined>(undefined);
  const [remarks, setRemarks] = useState<string>(rowToUpdate.notes);
  const [isEditingProperty, setIsEditingProperty] = useState(false);
  const [isEditingPlace, setIsEditingPlace] = useState(false);

  const { user } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectedProperty = useCallback((selection: TypeaheadSearchResult) => {
    setProperty(selection);
  }, []);

  const handleSelectedPlace = useCallback((selection: TypeaheadSearchResult) => {
    setPlace(selection);
  }, []);

  const handleToggleEdit = useCallback((setEditing: React.Dispatch<React.SetStateAction<boolean>>) => {
    setEditing((prev) => !prev);
  }, []);

  const handleSubmit = async () => {
    const propertyId = property?.id.split(':')[1] ?? rowToUpdate.propertyId;
    const placeId = place?.id ?? rowToUpdate.placeId;
    const newRemarks = remarks ?? rowToUpdate.notes;

    const matchingAssociations = data.filter(
      ({ propertyId: id1, placeId: id2 }) => id1 === propertyId && id2 === placeId,
    );

    const isUnchanged =
      propertyId === rowToUpdate.propertyId && placeId == rowToUpdate.placeId && rowToUpdate.notes === remarks;
    const isDuplicateAssociation =
      matchingAssociations.length > 1 ||
      (matchingAssociations.length === 1 && matchingAssociations[0].id !== rowToUpdate.id);

    if (isUnchanged) {
      enqueueSnackbar('No changes made', { variant: 'error' });
      return;
    }

    if (isDuplicateAssociation) {
      enqueueSnackbar('Offer Association already exists', { variant: 'error' });
      return;
    }

    try {
      const { status } = await SearchService.patchOfferAssociationList(
        rowToUpdate.id,
        propertyId,
        placeId,
        user.fullName,
        newRemarks,
      );

      if (status === 200) {
        enqueueSnackbar('Association updated successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to update association', { variant: 'error' });
      }

      onClose();
    } catch (e) {
      enqueueSnackbar(`Failed to update association: ${e}`, { variant: 'error' });
    }
  };

  return (
    <Box display="flex" gap={2} flexDirection="column" mb={2}>
      <NameContainer>
        {isEditingProperty ? (
          <>
            <PlaceLookUp
              onCurrentValueChange={handleSelectedProperty}
              types={TYPEAHEAD_PROPERTY_TYPES}
              label="Property"
            />
            <StyledButton onClick={() => handleToggleEdit(setIsEditingProperty)}>
              <CloseIcon />
            </StyledButton>
          </>
        ) : (
          <>
            <Typography>{rowToUpdate.propertyName}</Typography>
            <StyledButton onClick={() => handleToggleEdit(setIsEditingProperty)}>
              <EditIcon />
            </StyledButton>
          </>
        )}
      </NameContainer>

      <NameContainer>
        {isEditingPlace ? (
          <>
            <PlaceLookUp onCurrentValueChange={handleSelectedPlace} types={TYPEAHEAD_PLACE_TYPES} label="Place" />
            <StyledButton onClick={() => handleToggleEdit(setIsEditingPlace)}>
              <CloseIcon />
            </StyledButton>
          </>
        ) : (
          <>
            <Typography>{rowToUpdate.placeName}</Typography>
            <StyledButton onClick={() => handleToggleEdit(setIsEditingPlace)}>
              <EditIcon />
            </StyledButton>
          </>
        )}
      </NameContainer>

      <TextField
        label="Remarks (Optional)"
        variant="outlined"
        multiline
        rows={2}
        fullWidth
        placeholder="Enter any remarks here"
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
      />

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
      <Button variant="contained" color="secondary" onClick={onClose}>
        Cancel
      </Button>
    </Box>
  );
};

export default UpdatePropertyLocationOverrideForm;
