import React from 'react';

import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Alert, Button, Stack, TextField } from '@mui/material';

import { redeemGiftCard } from '../../../services/PromoService';
import ErrorDisplay from '../ErrorDisplay';

class UseGiftCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.api = context.api;

    this.state = {
      code: '',
      processing: false,
      error: null,
      finished: false,
      creditedAmount: 0,
      creditedCurrency: null,
    };
  }

  onCodeChange = (e) => {
    this.setState({
      code: e.target.value,
    });
  };

  submit = async () => {
    const {
      tenant: { brand },
      onGiftCardRedeemed,
      customerId,
    } = this.props;
    const { code } = this.state;

    this.setState({ processing: true, finished: false });
    let creditedAmount = null;
    let creditedCurrency = null;

    try {
      const sanitisedCode = code.toUpperCase().replace(/[^0-9A-Z]/g, '');
      const response = await redeemGiftCard(sanitisedCode, customerId, brand);
      creditedAmount = parseFloat(response.result.credits.amount);
      creditedCurrency = response.result.credits.currency;
    } catch (e) {
      this.setState({
        error: 'Error applying gift card: ' + e.message,
        processing: false,
      });
      return;
    }

    this.setState({
      processing: false,
      finished: true,
      error: null,
      creditedAmount,
      creditedCurrency,
    });

    onGiftCardRedeemed && onGiftCardRedeemed();
  };

  render() {
    const { processing, error, finished, creditedAmount, creditedCurrency } = this.state;

    return (
      <div>
        {error && <ErrorDisplay message={error} />}

        {finished && (
          <Alert severity="success">
            Success!{' '}
            {currencyFormatter.format(creditedAmount, {
              code: creditedCurrency,
            })}{' '}
            redeemed.
          </Alert>
        )}

        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            label="Gift Card Code"
            placeholder="Enter 16-digit code here"
            value={this.state.code}
            onChange={this.onCodeChange}
            className="data-hj-whitelist"
            sx={{ width: 300 }}
          />

          <div>
            <Button type="submit" onClick={this.submit} variant="contained" color="primary" disabled={processing}>
              Apply Gift Card
            </Button>
          </div>
        </Stack>
      </div>
    );
  }
}

UseGiftCard.propTypes = {
  onGiftCardRedeemed: PropTypes.func,
  customerId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  tenant: state.tenant,
});

export default connect(mapStateToProps, null)(UseGiftCard);
