export function isArchivedPromotion(promotion: App.Bedbank.PromotionsEdit) {
  const pattern = /^(?!(0))([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
  const hasValidPromoDates =
    promotion.promoId &&
    pattern.test(promotion.promoPeriodSchedule?.promoPeriodFrom) &&
    pattern.test(promotion.promoPeriodSchedule?.promoPeriodTo);

  const promoDateHasEnded = hasValidPromoDates && new Date(promotion.promoPeriodSchedule.promoPeriodTo) < new Date();
  return !!promotion.deletedAt || !!promoDateHasEnded;
}
