import { CategoryItem, ExperienceActionTypes } from '~/services/ExperiencesService';

export type CategoriesAction = {
  message?: string;
  payload?: CategoryItem[];
  type: ExperienceActionTypes;
};

export type CategoriesState = {
  error: boolean;
  message?: string;
  loading: boolean;
  items: CategoryItem[];
};

export const initialCategories: CategoriesState = {
  items: [],
  error: false,
  message: null,
  loading: false,
};

export const fetchCategoriesReducer = (state: CategoriesState, action: CategoriesAction) => {
  switch (action.type) {
    case 'LOAD':
      return { error: false, loading: true, items: [] };
    case 'RESET':
      return { error: false, loading: false, items: [] };
    case 'ERROR':
      return {
        items: [],
        error: true,
        loading: false,
        message: action.message,
      };
    case 'FETCH':
      return {
        error: false,
        loading: false,
        items: action?.payload,
      };
    default:
      throw new Error('Error in reducer (fetch-categories) action.type');
  }
};
