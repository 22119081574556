export const offerRecordTypes = [
  'Add-On Opportunity',
  'Cruises Approved',
  'Escapes Approved',
  'Experiences Approved',
  'LE Tours',
  'Tours Approved',
  'Dynamic Rate Products - Approved - Last Minute',
  'Dynamic Rate Products - Post Deal Review',
  'Dynamic Rate Products - Approved - Tactical AO',
];
