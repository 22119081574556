import React from 'react';

import { Helmet } from 'react-helmet';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import useQuery from '~/hooks/useQuery';

import usePromoMeta from '../Promo/hooks/usePromoMeta';

import PromoFormV3 from './PromoFormV3';

const PromoContainer = () => {
  const { promoMeta } = usePromoMeta();
  const query = useQuery();
  const isDev = !!query.get('isDev');
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Promo Form V3</title>
      </Helmet>
      <PageHeader title="Create Promo Code" />
      <PromoFormV3 promoMeta={promoMeta} isDev={isDev} />
    </Container>
  );
};

export default PromoContainer;
