import React from 'react';

import { Helmet } from 'react-helmet';

import { Container } from '@mui/material';

import AccommodationProperties from '../Properties/AccommodationProperties';

export default function AccommodationHomePage() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Accommodation - Properties</title>
      </Helmet>
      <AccommodationProperties />
    </Container>
  );
}
