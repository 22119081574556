export const PACKAGES = 'packages';
export const IMAGES = 'images';
export const CONTENT = 'content';
export const CONTENT_FIELDS = [
  'satisfaction_survey_link',
  'type',
  'booking_type',
  'name',
  'description',
  'location',
  'highlights',
  'what_we_like',
  'facilities',
  'tour_flight_details_required',
  'no_index',
  'enable_customer_portal_date_change',
  'booking_guarantee',
  'getting_there',
  'fine_print',
  'locations',
  'holiday_types',
  'sale_unit',
  'panel_cloudinary_id',
  'videos',
];
