import React, { ComponentProps, useEffect, useMemo, useReducer, useState } from 'react';

import { Stack, TextField, Typography } from '@mui/material';

import { formatDateISO, newDate } from '~/services/TimeService';

import DateTimeWidget from '../../Elements/DateTimeWidget';

// subset of Reservation.Discount
// typing is a bit wack here
interface ScheduledDiscount {
  id?: string;
  rate_plan_id: string;
  discount_type: string;
  discount_percent: number;
  min_los: number | null;
  max_los: number | null;
  min_days_to_arrival: null;
  max_days_to_arrival: null;
  discount_name: string | null;
  is_displayed: boolean;
  source: 'internal';
  paid_by: 'vendor';
  schedules: {
    activePeriod: {
      from: string;
      to: string;
    };
    travelPeriod: {
      from: string;
      to: string;
    };
    type: 'sell';
    id?: string;
    parent?: string;
  }[];
}

interface ScheduledDiscountProps {
  value: ScheduledDiscount;
  onChange: (value: ScheduledDiscount) => void;
  sx?: ComponentProps<typeof Stack>['sx'];
}

type Action =
  | {
      type: 'setActivePeriodFrom' | 'setActivePeriodTo' | 'setTravelPeriodFrom' | 'setTravelPeriodTo';
      date: string;
    }
  | {
      type: 'setDiscountName';
      value: string | null;
    }
  | {
      type: 'setDiscountPercentage' | 'setMinLos' | 'setMaxLos';
      value: number | null;
    };

type State = ScheduledDiscount;

function reducer(oldState: State, action: Action) {
  switch (action.type) {
    case 'setDiscountName':
      return {
        ...oldState,
        discount_name: action.value,
      };
    case 'setDiscountPercentage':
      return {
        ...oldState,
        discount_percent: action.value,
      };
    case 'setMinLos':
      return {
        ...oldState,
        min_los: action.value,
      };
    case 'setMaxLos':
      return {
        ...oldState,
        max_los: action.value,
      };
    case 'setActivePeriodFrom':
      return {
        ...oldState,
        schedules: [
          {
            ...oldState.schedules[0],
            activePeriod: { ...oldState.schedules[0].activePeriod, from: action.date },
          },
        ],
      };

    case 'setActivePeriodTo':
      return {
        ...oldState,
        schedules: [
          {
            ...oldState.schedules[0],
            activePeriod: { ...oldState.schedules[0].activePeriod, to: action.date },
          },
        ],
      };

    case 'setTravelPeriodFrom':
      return {
        ...oldState,
        schedules: [
          {
            ...oldState.schedules[0],
            travelPeriod: { ...oldState.schedules[0].travelPeriod, from: action.date },
          },
        ],
      };

    case 'setTravelPeriodTo':
      return {
        ...oldState,
        schedules: [
          {
            ...oldState.schedules[0],
            travelPeriod: { ...oldState.schedules[0].travelPeriod, to: action.date },
          },
        ],
      };
  }
}

function ScheduledDiscount(props: ScheduledDiscountProps) {
  const [state, dispatch] = useReducer(reducer, props.value);
  const [discountPercentageError, setDiscountPercentageError] = useState<boolean>(false);

  const activeDayjs = useMemo(
    () => ({
      from: newDate(state.schedules[0].activePeriod.from),
      to: newDate(state.schedules[0].activePeriod.to),
    }),
    [state.schedules],
  );
  const travelDayjs = useMemo(
    () => ({
      from: newDate(state.schedules[0].travelPeriod.from),
      to: newDate(state.schedules[0].travelPeriod.to),
    }),
    [state.schedules],
  );

  useEffect(() => {
    props.onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (!isNaN(Number(value))) {
      dispatch({ type: 'setDiscountPercentage', value: Number(value) });

      const isValidDiscountPercentage = Number(value) >= 0 && Number(value) <= 100;
      setDiscountPercentageError(!isValidDiscountPercentage);
    }
  };

  const handleMinLosChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value === '') {
      dispatch({ type: 'setMinLos', value: null });
    } else if (!isNaN(Number(value))) {
      dispatch({ type: 'setMinLos', value: Number(value) });
    }
  };

  const handleMaxLosChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value === '') {
      dispatch({ type: 'setMaxLos', value: null });
    } else if (!isNaN(Number(value))) {
      dispatch({ type: 'setMaxLos', value: Number(value) });
    }
  };

  return (
    <Stack direction="column" sx={props.sx}>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start">
        <Stack spacing={2}>
          <Typography>Discount Details</Typography>
          <TextField
            label="Discount Name"
            value={state.discount_name || ''}
            onChange={(event) => dispatch({ type: 'setDiscountName', value: event.target.value })}
          />
          <TextField
            label="Discount Percentage"
            value={state.discount_percent}
            onChange={handleDiscountChange}
            error={discountPercentageError}
            helperText={discountPercentageError ? 'Value must be between 0 and 100' : ' '}
          />
        </Stack>

        <Stack spacing={2}>
          <Typography>Length of Stay options</Typography>
          <TextField label="Min LOS" value={state.min_los || ''} onChange={handleMinLosChange} />
          <TextField label="Max LOS" value={state.max_los || ''} onChange={handleMaxLosChange} />
        </Stack>

        <Stack spacing={2}>
          <Typography>Active Period</Typography>
          <DateTimeWidget
            label="From"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.schedules[0].activePeriod.from}
            maxDate={activeDayjs.to}
            onChange={(date) => dispatch({ type: 'setActivePeriodFrom', date: formatDateISO(date) })}
          />
          <DateTimeWidget
            label="To"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.schedules[0].activePeriod.to}
            minDate={activeDayjs.from}
            onChange={(date) => dispatch({ type: 'setActivePeriodTo', date: formatDateISO(date) })}
          />
        </Stack>

        <Stack spacing={2}>
          <Typography>Travel Period</Typography>
          <DateTimeWidget
            label="From"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.schedules[0].travelPeriod.from}
            maxDate={travelDayjs.to.subtract(1, 'day')}
            onChange={(date) => dispatch({ type: 'setTravelPeriodFrom', date: formatDateISO(date) })}
          />
          <DateTimeWidget
            label="To"
            format="DD-MM-YYYY"
            showDateOnly
            value={state.schedules[0].travelPeriod.to}
            minDate={travelDayjs.from.add(1, 'day')}
            onChange={(date) => dispatch({ type: 'setTravelPeriodTo', date: formatDateISO(date) })}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ScheduledDiscount;
