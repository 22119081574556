import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { date, number, object, string } from 'yup';

import { Button, Grid, Stack, TextField } from '@mui/material';

import DateWidget from '~/components/Common/Elements/DateWidget';

import { addCredit } from '~/services/PaymentsService';
import { addDays, formatDateISO } from '~/services/TimeService';

import { CREDIT_TYPE_CRUISE_OFFER_PACKAGE_CREDIT } from '../../../consts/credits';

type FormData = {
  amount: number;
  currency: string;
  fk_member: string;
  credit_type: string;
  comments: string;
  expires_at: string;
  brand: string;
};

type Props = {
  offerId: string;
  agentId: string;
  userId: string;
  currency: string;
};

const OfferPackageCreditsForm = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { offerId, agentId, userId, currency } = props;
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      amount: 0,
      currency: currency,
      fk_member: userId,
      credit_type: CREDIT_TYPE_CRUISE_OFFER_PACKAGE_CREDIT,
      comments: `{ offer_id: ${offerId}, agent_id: ${agentId} }`,
      expires_at: formatDateISO(addDays(60)),
      brand,
    },
    resolver: yupResolver(
      object({
        amount: number().required('Amount is required'),
        currency: string().required('Currency is required'),
        fk_member: string().required('Member is required'),
        credit_type: string().required('Credit type is required'),
        comments: string().required('Comments is required'),
        expires_at: date().required('Expiry date is required'),
        brand: string().required('Brand is required'),
      }),
    ),
  });

  const onSubmit = async (data: FormData) => {
    try {
      await addCredit(data);
      reset();
      enqueueSnackbar('Credits successfuly added', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Error adding credits: ${e.message}`, { variant: 'error' });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" justifyContent="space-between">
        <Grid mt={1} columns={6} spacing={2} container>
          <Grid item xs={3}>
            <TextField
              {...register('amount')}
              helperText={errors.amount}
              error={!!errors.amount}
              label="Amount"
              type="number"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="expires_at"
              control={control}
              render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
                <DateWidget
                  {...field}
                  label="Expires at"
                  slotProps={{ textField: { fullWidth: true, helperText: error?.message, error: !!error } }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              {...register('currency')}
              helperText={errors.currency}
              error={!!errors.currency}
              label="Currency"
              type="text"
              disabled
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              {...register('credit_type')}
              helperText={errors.credit_type}
              error={!!errors.credit_type}
              label="Credit type"
              type="text"
              disabled
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('comments')}
              helperText={errors.comments}
              error={!!errors.comments}
              label="Comments"
              type="text"
              disabled
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default OfferPackageCreditsForm;
