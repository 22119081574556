import React from 'react';

import { Helmet } from 'react-helmet';

import DebugSearch from './DebugSearch';

export default function GeometrySearch() {
  return (
    <>
      <Helmet>
        <title>Debug Search</title>
      </Helmet>
      <DebugSearch />
    </>
  );
}
