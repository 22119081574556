import React, { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { getDatesRequestsByOrderItemId, updateDatesRequest } from '~/services/OrdersService';

interface Props {
  item: App.OrderItem;
  order: App.Order;
  offer: App.Offer;
}

export function DatesRequests({ item, order, offer }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [datesRequest, setDatesRequest] = useState<DateChangeRequest | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isToggled: isRespondModalOpen,
    toggleOn: openRespondModal,
    toggleOff: closeRespondModal,
  } = useToggleState(false);

  const fetchRefundRequest = async (orderId: string, itemId: string) => {
    if (orderId && itemId) {
      const result = await getDatesRequestsByOrderItemId(orderId, itemId);
      setDatesRequest(result);
    }
  };

  useEffect(() => {
    fetchRefundRequest(order.id_orders, item.id);
  }, [item.id, order.id_orders]);

  const vendorEmailLog = useMemo(() => {
    if (offer.vendor_booking_email && datesRequest) {
      const emailKey = `${datesRequest.vendor_salesforce_id}:${datesRequest.id_dates_request}`;

      return `/email/sent?email=${offer.vendor_booking_email}&key=${emailKey}`;
    }
  }, [offer.vendor_booking_email, datesRequest]);

  const handleResponse = async (status: 'approved_bnbl' | 'approved' | 'rejected') => {
    if (datesRequest) {
      const updateData = {
        status,
        op: 'update_status',
      };

      setIsLoading(true);
      try {
        await updateDatesRequest(datesRequest.id_dates_request, updateData);
        await fetchRefundRequest(order.id_orders, item.id);
        enqueueSnackbar(`Refund request ${status} successfully`, { autoHideDuration: 3000, variant: 'success' });
        closeRespondModal();
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'warning' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const approveDatesRequest = () => {
    handleResponse('approved');
  };

  const onApproveAsBNBL = () => {
    handleResponse('approved_bnbl');
  };

  const rejectDatesRequest = () => {
    handleResponse('rejected');
  };

  return (
    <Stack direction="column" mt={1}>
      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle1" fontWeight="bold">
          Dates requests outside of policy
        </Typography>
        {datesRequest && datesRequest.status === 'pending' && (
          <Button size="small" onClick={openRespondModal}>
            Respond
          </Button>
        )}
      </Stack>
      {datesRequest && <Typography>{datesRequest.status}</Typography>}
      {!datesRequest && <Typography>N/A</Typography>}
      {datesRequest && vendorEmailLog && (
        <Button href={vendorEmailLog} target="_blank">
          View refund email log
        </Button>
      )}

      <Dialog open={isRespondModalOpen} onClose={closeRespondModal}>
        <DialogTitle>Warning: Responding to Outside Policy Dates Request on behalf of Vendor</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to respond to this dates request on behalf of the vendor. Please choose whether to approve or
            reject the request.
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              Reason for Request:
            </Typography>
            <Typography>{datesRequest?.reason}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={closeRespondModal} variant="text" color="primary">
            Cancel
          </Button>
          <Stack direction="row" gap={1}>
            <LoadingButton
              onClick={rejectDatesRequest}
              variant="outlined"
              color="error"
              loading={isLoading}
              disabled={!datesRequest}
            >
              Reject
            </LoadingButton>
            <LoadingButton
              onClick={onApproveAsBNBL}
              variant="outlined"
              color="warning"
              loading={isLoading}
              disabled={!datesRequest}
            >
              Approve as BNBL
            </LoadingButton>
            <LoadingButton
              onClick={approveDatesRequest}
              variant="outlined"
              color="primary"
              loading={isLoading}
              disabled={!datesRequest}
            >
              Approve
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
