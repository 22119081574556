import React, { useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';

import { Button, Grid } from '@mui/material';

import * as PromoService from '~/services/PromoService';
import { formatDateISOWithTime } from '~/services/TimeService';

import Spinner from '../Common/Spinner';

type PromoChildExportProps = {
  promoId: string | null;
  codeName: string;
};
const PromoChildExport = ({ promoId, codeName }: PromoChildExportProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const csvDownloadRef = useRef<CSVLink>();
  const [listData, setListData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const getChildPromos = async () => {
    try {
      setLoading(true);
      const response = await PromoService.getPromoClones({
        id_promo_code: promoId,
        page: 1,
        limit: 9999,
      });

      if (!!response && 'result' in response && 'promos' in response.result) {
        if (response.result.promos.length === 0) {
          enqueueSnackbar(`No promos found`, {
            variant: 'warning',
          });
          return;
        }
        setListData(response.result.promos);
        setFileName(`promo-${codeName}-${formatDateISOWithTime()}.csv`);
        if (csvDownloadRef?.current) {
          csvDownloadRef.current.link.click();
        }
        enqueueSnackbar(`Success! Export in progress, standby for download`, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(JSON.stringify(response.message), {
          variant: 'error',
        });
      }
    } catch (err) {
      enqueueSnackbar(JSON.stringify(err), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item mt={4}>
      {loading ? (
        <Spinner />
      ) : (
        <Button variant="contained" onClick={getChildPromos} disabled={loading}>
          Export Existing Promo Codes
        </Button>
      )}
      <CSVLink data={listData} filename={fileName} ref={csvDownloadRef} target="_blank" />
    </Grid>
  );
};

export default PromoChildExport;
