import React, { useCallback } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import { PropertyLocationOverride, defaultColumns } from '~/components/Content/PropertyLocationOverride/Columns';

interface Props {
  data: PropertyLocationOverride[];
  isLoading: boolean;
  selected: GridRowSelectionModel;
  handleSelect: (selectedIds: GridRowSelectionModel) => void;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleUpdate: (row: PropertyLocationOverride) => void;
}

const PropertyLocationOverrideTable = ({
  data,
  isLoading,
  handleSelect,
  selected,
  handleDelete,
  handleUpdate,
}: Props) => {
  const columns: GridColDef<PropertyLocationOverride>[] = [
    ...defaultColumns,
    {
      field: 'update',
      headerName: 'Update',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleUpdate(params.row)}>
          Update
        </Button>
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 100,
    },
  ];

  const handleBulkDelete = useCallback(
    (event) => {
      event.preventDefault();
      handleDelete(event);
    },
    [handleDelete],
  );

  return (
    <Box mt={2}>
      <Grid item xs={4}>
        <Button disabled={selected.length === 0} onClick={handleBulkDelete} color="error">
          Remove Selected Associations
        </Button>
      </Grid>

      <DataGrid
        columns={columns}
        checkboxSelection
        className="T-offers-table"
        rows={data || []}
        loading={isLoading}
        getRowId={(row: {
          propertyId: string;
          propertyName: string;
          placeName: string;
          placeId: string;
          notes: string;
          lastModifiedBy: string;
          id: string;
        }) => row.id}
        getRowHeight={() => 'auto'}
        onRowSelectionModelChange={handleSelect}
        rowSelectionModel={selected}
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight
      />
    </Box>
  );
};

export default PropertyLocationOverrideTable;
