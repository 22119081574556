import React, { useMemo, useState } from 'react';

import { Box, Button, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';

import Image from '~/components/Common/Image';

interface BadgesContainerProps {
  badges: App.Badge[];
  sizePerPage: number;
  selectRowProp?: unknown;
  extended?: boolean;
  selectedBadges?: any[];
  onBadgeSelect?: (id: any, isSelected: boolean) => void;
}

const BadgesPage = ({
  badges: propBadges = [],
  sizePerPage,
  extended = true,
  selectedBadges,
  onBadgeSelect,
}: BadgesContainerProps) => {
  const [badgesSearch, setBadgesSearch] = useState('');

  const searchedBadges = useMemo(() => {
    return propBadges.filter((b) => {
      return b.name.toLowerCase().includes(badgesSearch.toLowerCase());
    });
  }, [badgesSearch, propBadges]);

  const searchedBadgesTotal = useMemo(() => {
    return searchedBadges.length;
  }, [searchedBadges]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBadgesSearch(e.target.value);
  };

  const imageCell = (params: GridRenderCellParams<App.Badge>) => {
    const badge = params.row;

    return (
      <>
        <Image className="img-responsive" publicId={badge.image} options={{ width: 170 }} />
      </>
    );
  };

  const actionsCell = (params: GridRenderCellParams<App.Badge>) => {
    const badge = params.row;

    return (
      <>
        <Button variant="text" href={`/badges/${badge.id}`} target="_blank">
          Edit
        </Button>
      </>
    );
  };

  const onRowSelect = (rowSelectionModel: GridRowSelectionModel) => {
    const deSelectedBadge = selectedBadges.find((b) => !rowSelectionModel.includes(b));
    if (deSelectedBadge) {
      onBadgeSelect(deSelectedBadge, false);
      return;
    }
    const selectedBadge = rowSelectionModel.find((b) => !selectedBadges.includes(b));
    if (selectedBadge) {
      onBadgeSelect(selectedBadge, true);
      return;
    }
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'name', headerName: 'Badge Name', flex: 1, display: 'flex' },
      { field: 'tag_text', headerName: 'Badge Text', flex: 1, display: 'flex' },
      { field: 'tag_tooltip', headerName: 'Badge Tagline Tooltip', flex: 1, display: 'flex' },
      { field: 'image', headerName: 'Image', width: 200, renderCell: imageCell, display: 'flex' },
      ...(extended ? [{ field: 'id', headerName: '', width: 100, renderCell: actionsCell }] : []),
    ],
    [extended],
  );

  return (
    <>
      <Box my={2}>
        <TextField
          type="text"
          name="search"
          label="Badge search"
          value={badgesSearch}
          onChange={onSearchChange}
          fullWidth
        />
      </Box>

      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: sizePerPage,
            },
          },
        }}
        rows={searchedBadges || []}
        columns={columns}
        pageSizeOptions={[sizePerPage]}
        getRowHeight={() => 'auto'}
        rowCount={searchedBadgesTotal}
        disableColumnMenu
        checkboxSelection
        onRowSelectionModelChange={onRowSelect}
        rowSelectionModel={selectedBadges}
        autoHeight
      />
    </>
  );
};

export default BadgesPage;
