import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import ReservationService from '~/services/ReservationService';

interface ResolvedConfirmationDialogProps {
  reservationFailure?: reservationFailure;
  setReservationFailure: (value: reservationFailure) => void;
  resolveReservation: (reservationId: string) => void;
}

interface ConfirmationFields {
  posted: string;
  confirmationInput: string;
}

interface reservationFailure {
  reservationId: string;
  actionName: string;
  order?: {
    accommodation_items: Array<{ reservation: { channel_manager: string; [key: string]: any } }>;
    [key: string]: any;
  };
  [key: string]: any;
}

export default function ResolvedConfirmationDialog(props: ResolvedConfirmationDialogProps) {
  const { reservationFailure, setReservationFailure, resolveReservation } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [confirmationFields, setConfirmationFields] = useState<ConfirmationFields>({
    posted: '',
    confirmationInput: '',
  });

  const validateResponses = useCallback(() => {
    if (
      (confirmationFields.posted !== 'manualResolution' && confirmationFields.posted !== 'falsePositive') ||
      confirmationFields.confirmationInput !== 'resolved'
    ) {
      enqueueSnackbar('All fields are required in confirmation form, please check your inputs and try again.', {
        variant: 'warning',
      });
      return false;
    }
    return true;
  }, [confirmationFields, enqueueSnackbar]);

  const markResolved = useCallback(async () => {
    if (!validateResponses()) return;
    try {
      await ReservationService.clearFailedJob(
        reservationFailure.order.accommodation_items[0].reservation.channel_manager,
        reservationFailure.reservationId,
        reservationFailure.actionName,
        confirmationFields.posted,
      );
      resolveReservation(reservationFailure.reservationId);
      resetConfirmation();
    } catch (e) {
      if (e.status === 404) {
        enqueueSnackbar('Job not found may have already been resolved by someone else.', { variant: 'warning' });
        resolveReservation(reservationFailure.reservationId);
        resetConfirmation();
      } else {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    }
  }, [confirmationFields, enqueueSnackbar, reservationFailure, resolveReservation, validateResponses]);

  const resetConfirmation = () => {
    setReservationFailure(null);
    setConfirmationFields({
      posted: '',
      confirmationInput: '',
    });
  };

  return (
    <Dialog open={!!reservationFailure}>
      {!!reservationFailure && (
        <>
          <DialogTitle>
            Are you sure you want to remove{' '}
            <em>
              {reservationFailure.reservationId}_{reservationFailure.actionName}
            </em>{' '}
            job from {reservationFailure.order.accommodation_items[0].reservation.channel_manager}?
          </DialogTitle>
          <DialogContent>
            <Typography pb={2}>Ensure all these actions have been taken before removing any jobs.</Typography>
            <Stack direction="column" spacing={2} mb={2}>
              <FormControl>
                <FormLabel id="manual-resolution">
                  Has been posted to connections team for manual resolution or is false failure?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="manual-resolution"
                  name="posted"
                  onChange={(e) => setConfirmationFields({ ...confirmationFields, posted: e.target.value })}
                >
                  <FormControlLabel value="manualResolution" control={<Radio />} label="Posted for Manual Resolution" />
                  <FormControlLabel value="falsePositive" control={<Radio />} label="False Failure" />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel id="confirmation-input">Enter "resolved" to confirm job removal:</FormLabel>
                <TextField
                  id="confirmation-input"
                  onChange={(e) => setConfirmationFields({ ...confirmationFields, confirmationInput: e.target.value })}
                  value={confirmationFields.confirmationInput}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="space-around">
              <Button variant="outlined" onClick={() => resetConfirmation()}>
                Cancel
              </Button>
              <Button variant="contained" onClick={() => markResolved()} color="error">
                Remove
              </Button>
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
