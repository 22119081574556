import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, FormControl, Stack, TextField } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import VendorsService from '~/services/VendorsService';

import VendorBookingPaymentsListContainer from './VendorBookingPaymentsListContainer';

type Props = {
  id_offer: string;
  id_vendor: string;
};

export default function VendorBookingPaymentsPane(props: Props) {
  const [currentInput, setCurrentInput] = useState('');
  const [searchString, setSearchString] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInput(event.target.value.trim());
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSearchString(currentInput);
  };

  const handleDownload = async () => {
    try {
      await VendorsService.downloadPaymentsByBookingVendor({
        idOffer: props.id_offer,
        idVendor: props.id_vendor,
        format: 'xlsx',
      });
      enqueueSnackbar('Vendor payments downloaded', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(`Cannot download vendor payments: ${err.message}`, { variant: 'error' });
    }
  };

  return (
    <Box>
      <PageSubheader title="Vendor Booking Payments">
        <Button
          startIcon={<AddIcon />}
          variant="text"
          component={Link}
          to={'/finance/offers/' + props.id_offer + '/booking-payments/' + props.id_vendor}
        >
          Add booking payment
        </Button>
      </PageSubheader>

      <Stack direction="column" spacing={2} id="vendor-payments">
        <form onSubmit={handleSubmit}>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth variant="outlined">
              <TextField placeholder="Enter Booking Number" value={currentInput} onChange={handleChange} fullWidth />
            </FormControl>
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Stack>
        </form>

        <VendorBookingPaymentsListContainer
          id_offer={props.id_offer}
          id_vendor={props.id_vendor}
          search_string={searchString}
        />

        <Box>
          <Button type="submit" variant="outlined" onClick={handleDownload}>
            Download Vendor Booking Payments
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
