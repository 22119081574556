import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueLiveVendorHoldback } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please click the button below to generate and email the report',
  type: 'object',
  properties: {
    overpaidVendorsOnly: {
      type: 'boolean',
      title: 'Include Overpaid Vendors Only',
      default: false,
    },
  },
};

const uiSchema = {
  overpaidVendorsOnly: {
    'ui:widget': 'checkbox',
  },
};

export default function ExportVendorHoldbackForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    queueLiveVendorHoldback({
      overpaidVendorsOnly: event.formData.overpaidVendorsOnly,
    })
      .then(function () {
        setSuccessMessage(true);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Email Vendor Holdback Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
