import styled from 'styled-components';
import { theme } from '~/theme';

import { Spacing, margin, padding } from '~/components/Experiences/helpers';

interface BodyProps extends Spacing {
  height?: string;
}

export const CardHeader = styled.div`
  position: relative;
`;

export const RelativeBadge = styled.div`
  top: -25px;
  left: 25px;
  max-width: 120px;
  position: absolute;
`;

export const RelativeRating = styled.div`
  top: -25px;
  right: 25px;
  position: absolute;
  border-radius: 25px;
  padding: 5px 8px 0px 8px;
  background: ${theme.palette.background.default};
`;

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 10px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const Body = styled.div<BodyProps>`
  width: 100%;
  height: ${({ height }) => height || '300px'};
  overflow-y: scroll;
  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};
`;

export const ExperienceImage = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 4px;
`;

export const ExperienceTableRow = styled.tr`
  background: ${theme.palette.grey[50]};

  &:nth-of-type(even) {
    background: ${theme.palette.grey[100]};
  }

  &:hover {
    background: ${theme.palette.grey[200]};
  }

  > td {
    cursor: pointer;
    max-width: 325px;
    padding: 24px 8px !important;
    vertical-align: middle !important;
  }

  > .experience-title-description {
    padding-right: 35px !important;

    > div .le-tag {
      opacity: 1;
      width: 120px;
      padding: 2px 0px;
      margin-left: 5px;

      span {
        font-size: 11px;
        font-weight: 500;
        font-style: italic;
      }
    }

    > p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  > .experience-categories {
    column-gap: 10px;
    display: grid !important;
    max-width: 200px !important;
    grid-template-columns: repeat(2, 1fr);

    > div {
      opacity: 1;
      margin-left: 0;
      margin-top: 5px;
      max-width: 78px;
      margin-bottom: 5px;
    }
  }
`;
