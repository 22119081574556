import 'isomorphic-fetch';

import ApiError from '~/types/ApiError';

import ReactStormpath from '../stormpath';

export async function request(destination: string, progressCallBack: (p: Uint8Array | null) => void, options?: any) {
  if (!options) {
    options = {};
  }

  const token = await ReactStormpath.getOrRefreshAccessToken();

  if (!token) {
    throw new ApiError({
      message: 'You are not logged in.',
      name: 'AuthorizationError',
    });
  }

  if (!options.headers) {
    options.headers = {};
  }

  if (options.credentials !== 'omit') {
    options.headers['Authorization'] = 'Bearer ' + token;
  }
  options.credentials = options.credentials || 'include';

  const response = await fetch(destination, options);

  const reader = response.body.getReader();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // done is true for the last chunk
    // value is Uint8Array of the chunk bytes
    const { done, value } = await reader.read();

    if (done) {
      progressCallBack(null);
      break;
    }

    if (progressCallBack) {
      progressCallBack(value);
    }
  }
}
