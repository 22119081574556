import React from 'react';

import { TableCell, TableRow } from '@mui/material';

import SegmentHeaderGroups from '~/components/Content/SearchRanking/Segment/SegmentHeaderGroups';

interface Props {
  segments: App.Segment[];
}

export function SearchHotelHead({ segments }: Props) {
  return (
    <TableRow>
      <TableCell sx={{ minWidth: 150 }}> Offer </TableCell>
      <TableCell align="right">Offer Type</TableCell>
      <TableCell sx={{ minWidth: 150 }} align="right">
        List Visibility Schedule
      </TableCell>
      <TableCell align="right">Views</TableCell>
      <TableCell align="right">Sales Count</TableCell>
      <TableCell align="right">Sales Amount</TableCell>
      <TableCell align="right">Margin Amount</TableCell>
      <TableCell align="right">Margin %</TableCell>
      <TableCell align="right">Margin Per View</TableCell>
      <TableCell align="right">Adjusted Margin Per View</TableCell>
      <TableCell align="right">Tier Score (Offer Launch)</TableCell>
      <TableCell align="right">Manual Override Score</TableCell>
      <SegmentHeaderGroups segments={segments} />
      <TableCell align="right">Click Through Rate</TableCell>
      <TableCell align="right">MPV Score</TableCell>
      <TableCell align="right">Final Score</TableCell>
    </TableRow>
  );
}
