import React, { useMemo } from 'react';

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import RemittanceActionCell from '~/components/Finance/VendorPayments/RemittanceActionCell';

import { Remittance } from '~/consts/remittance';

import { formatDateSlashes } from '~/services/TimeService';

import DateWidget from '../../Common/Forms/DateWidget';

import { DateOfPayment } from './RemittanceContainer';
import { REMITTANCE_TYPE_NEW, REMITTANCE_TYPE_SENT } from './constants/sentType';

interface Props {
  data: Remittance[];
  dateOfPayment: DateOfPayment;
  handleChangeDateOfPayment: (dateOfPayment: DateOfPayment) => void;
  handleChangeType: (newType: string) => void;
  handleCreateRemittance: (vendorId: string, dateOfPayment: string, costCurrency: string) => void;
  handleDeleteRemittance: (id: string) => void;
  handleDownloadRemittance: (id: string, type: string) => void;
  handleEmailRemittance: (id: string) => void;
  isLoading: boolean;
  remittanceAuthors: Map<string, App.User>;
  sentType: string;
  setSelectedRemittances: (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const RemittanceTable = ({
  data,
  dateOfPayment,
  handleChangeDateOfPayment,
  handleChangeType,
  handleCreateRemittance,
  handleDeleteRemittance,
  handleDownloadRemittance,
  handleEmailRemittance,
  isLoading,
  remittanceAuthors,
  sentType,
  setSelectedRemittances,
}: Props) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'remittance_number',
        headerName: 'Remittance number',
        renderCell: ({ row }) => row.remittance_number || 'draft',
        sortable: false,
        type: 'string',
        width: 100,
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'vendor_id',
        headerName: 'Vendor ID',
        sortable: false,
        width: 180,
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'vendor_name',
        headerName: 'Vendor',
        width: 200,
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'vendor_email',
        headerName: 'Vendor Email',
        width: 240,
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'cost_currency',
        headerName: 'Cost Currency',
        width: 80,
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'payment_amount',
        headerName: 'Payment Amount',
        sortable: false,
        width: 100,
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'date_of_payment',
        headerName: 'Date Of Payment',
        width: 120,
        valueFormatter: (value) => formatDateSlashes(value),
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'added_by',
        headerName: 'Added By',
        width: 140,
        display: 'flex',
      },
      {
        disableColumnMenu: true,
        field: 'action',
        headerName: '',
        renderCell: (cellProps) => (
          <RemittanceActionCell
            {...cellProps}
            downloadRemittance={handleDownloadRemittance}
            handleCreateRemittance={handleCreateRemittance}
            handleEmailRemittance={handleEmailRemittance}
            handleDeleteRemittance={handleDeleteRemittance}
          />
        ),
        width: 250,
        display: 'flex',
      },
    ],
    [handleCreateRemittance, handleDeleteRemittance, handleDownloadRemittance, handleEmailRemittance],
  );

  const dataWithAuthors = useMemo(
    () =>
      data.map((remittance) => ({
        ...remittance,
        added_by: remittanceAuthors.get(remittance.added_by)?.email,
      })),
    [data, remittanceAuthors],
  );

  return (
    <Stack>
      <Stack direction="row" sx={{ alignItems: 'end' }} my={2} spacing={5}>
        <ToggleButtonGroup
          color="primary"
          exclusive
          onChange={(_, newType: string) => handleChangeType(newType)}
          value={sentType}
          sx={{ height: '38px' }}
        >
          <ToggleButton value={REMITTANCE_TYPE_NEW}>New</ToggleButton>
          <ToggleButton value={REMITTANCE_TYPE_SENT}>Sent</ToggleButton>
        </ToggleButtonGroup>
        <div>
          Start Date:
          <DateWidget
            value={dateOfPayment.from}
            onChange={(date) =>
              handleChangeDateOfPayment({
                ...dateOfPayment,
                from: date,
              })
            }
          />
        </div>
        <div>
          End Date:
          <DateWidget
            value={dateOfPayment.to}
            onChange={(date) =>
              handleChangeDateOfPayment({
                ...dateOfPayment,
                to: date,
              })
            }
            static
          />
        </div>
      </Stack>
      <DataGrid
        autoHeight
        checkboxSelection
        columns={columns}
        density="compact"
        disableRowSelectionOnClick
        loading={isLoading}
        onRowSelectionModelChange={setSelectedRemittances}
        rows={dataWithAuthors}
        slots={{ toolbar: CustomToolbar }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            lineHeight: 1,
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
          },
          '&  .MuiDataGrid-root .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important',
          },
        }}
      />
    </Stack>
  );
};

export default RemittanceTable;
