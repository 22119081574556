import React, { useEffect, useState } from 'react';

import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import VendorsSearchResults from '~/components/Vendors/Home/VendorsSearchResults';

import useQuery from '~/hooks/useQuery';

import VendorsService from '~/services/VendorsService';

import { SEARCH_ID_LENGTH } from '../../Vendors/Properties/constants';

const PAGE_SIZE = 10;

const VendorSearchForm = () => {
  const query = useQuery();
  const startingQuery = query.get('q');

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuerySubmitted, setSearchQuerySubmitted] = useState('');

  const [vendors, setVendors] = useState<App.Vendor[]>([]);
  const [isSearching, setSearching] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchQuerySubmitted(searchQuery);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleRowClick = (row: { sfid: string }) => {
    const uri = '/vendors/' + row.sfid;
    window.open(uri, '_blank', 'noreferrer');
  };

  useEffect(() => {
    if (startingQuery && startingQuery !== searchQuerySubmitted) {
      setSearchQuery(startingQuery);
      setSearchQuerySubmitted(startingQuery);
    }
  }, [startingQuery]);

  useEffect(() => {
    if (!searchQuerySubmitted.length || searchQuery !== searchQuerySubmitted) {
      return;
    }

    // Flush results
    setSearching(true);
    setVendors([]);

    if (
      searchQuery.length === SEARCH_ID_LENGTH &&
      searchQuery.indexOf(' ') == -1 &&
      searchQuery.match(/^[0-9a-zA-Z]{18}$/)
    ) {
      VendorsService.getVendorById(searchQuery)
        .then((vendor) => {
          setVendors([vendor.result]);
          setTotal(1);
        })
        .finally(() => setSearching(false));
    } else if (searchQuery.length > 0) {
      VendorsService.getVendorByName(searchQuery, PAGE_SIZE, page + 1)
        .then((data) => {
          setVendors(data.result);
          setTotal(data.total);
        })
        .finally(() => setSearching(false));
    }
  }, [searchQuery, searchQuerySubmitted, page]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box flex={1}>
            <TextField
              id="VendorSearch"
              data-testid="VendorSearch"
              type="text"
              name="searchString"
              label="Enter Vendor ID or Name"
              placeholder="Enter Vendor ID or Name"
              value={searchQuery}
              onChange={handleChange}
              autoFocus
              fullWidth
            />
          </Box>

          <Button type="submit" variant="contained" className="T-search-user">
            Search
          </Button>
        </Stack>
      </form>

      {isSearching && (
        <Box mt={4}>
          <Spinner />
        </Box>
      )}

      {!isSearching && !vendors.length && searchQuerySubmitted.length > 0 && (
        <Box mt={4}>
          <Typography variant="subtitle1">No vendors found by your request...</Typography>
        </Box>
      )}

      {vendors.length > 0 && (
        <Box mt={4}>
          <VendorsSearchResults
            data={vendors}
            onRowClick={handleRowClick}
            onPageChange={setPage}
            page={page}
            pageSize={PAGE_SIZE}
            total={total}
          />
        </Box>
      )}
    </Box>
  );
};

export default VendorSearchForm;
