import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Card, CardContent, Stack } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

export default function TourReviewsPane({ vendorId, tourId, reviews }) {
  return (
    <div>
      <PageSubheader title="Reviews">
        <Button variant="text" component={Link} to={`/vendors/${vendorId}/tours/${tourId}/edit-tour-reviews`}>
          Edit
        </Button>
      </PageSubheader>

      {reviews.length === 0 && <span>There are no reviews for this tour.</span>}

      <Box display="grid" gap={2} gridTemplateColumns="repeat(2, 1fr)">
        {reviews.map((review) => (
          <Card variant="outlined" key={review.id}>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <div>
                  <DefinitionTitle>Review</DefinitionTitle>
                  <DefinitionText>{review.content}</DefinitionText>
                </div>

                <div>
                  <DefinitionTitle>Source</DefinitionTitle>
                  <DefinitionText>{review.source}</DefinitionText>
                </div>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Box>
    </div>
  );
}
