import { request } from './common';

function getBaseURL() {
  return window.configs.API_HOST + '/api';
}

function dataRequest(path, data, method) {
  return request(path, {
    method,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function postRequest(path, data) {
  return dataRequest(path, data, 'POST');
}

export async function logInsuranceNotInterested(reason, brand) {
  const baseURL = getBaseURL();

  return postRequest(`${baseURL}/povolytics/insurance-not-interested`, {
    reason,
    brand,
  });
}
