import React, { Component } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { WidgetProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { v4 as uuid } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import ReactHookFormAdapter from '~/components/Common/Forms/widgets/ReactHookFormAdapter';

import ReservationService from '~/services/ReservationService';

import deleteNulls from '~/utils/deleteNulls';
import { ingestSchedules, prepareSchedulesList } from '~/utils/schedulesHelpers';

import Schedules from './fields/Schedules';
import { buttonMessages, buttonStates } from './states/submitButton';
import CategoryIconWidget from './widgets/CategoryIconWidget';

const DEFAULT_VALUES = {
  id: '',
  description: '',
  amount: '',
  currency: 'AUD',
  minimum_los: 1,
  maximum_los: 999,
  unit: '',
  is_bonus: false,
  set_schedules: false,
  schedules_list: [],
  external_id: '',
  lux_plus_tier: null,
  type: null,
};

interface Props {
  inclusionItem: App.RoomRateInclusion;
  propertyId: string;
  roomTypeId: string;
  roomRateId: string;
  inclusionsSchema: any;
  vendorCurrencyCode: string;
  onRoomRateInclusionUpdated: (response: unknown) => void;
  onCancel: () => void;
}

interface State {
  inclusionItem: App.RoomRateInclusion;
  saveState: string;
  errors: Array<string>;
  schema: any;
}

const luxuryPlusFormExtension = {
  is_bonus: {
    'ui:widget': (props: WidgetProps) => (
      <FormControlLabel
        label="Inclusion is bonus"
        control={
          <Checkbox
            checked={props.value}
            disabled={props.formContext.lux_plus_tier !== null}
            onChange={(event) => {
              props.onChange(event.target.checked);
            }}
          />
        }
      />
    ),
  },
  lux_plus_tier: {
    'ui:widget': (props: WidgetProps) => (
      <FormControlLabel
        label="Inclusion is LuxPlus"
        control={
          <Checkbox
            disabled={props.formContext.is_bonus}
            checked={props.value === 'base'}
            required={props.required}
            onChange={(e) => {
              props.onChange(e.target.checked ? 'base' : null);
            }}
          />
        }
      />
    ),
  },
  type: {
    'ui:widget': (props: WidgetProps) => (
      <FormControl fullWidth required={props.formContext.lux_plus_tier !== null}>
        <InputLabel required={props.formContext.lux_plus_tier !== null}>{props.label}</InputLabel>
        <Select
          label={props.label}
          onChange={(e) => props.onChange(e.target.value)}
          required={props.formContext.lux_plus_tier !== null}
          defaultValue={props.value}
        >
          <MenuItem>None</MenuItem>
          {props.options.enumOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  },
};

const uiSchema = {
  room_rate_id: { 'ui:widget': 'hidden' },
  order: { 'ui:widget': 'hidden' },
  description: {},
  category_icon: {
    'ui:widget': ReactHookFormAdapter,
    'ui:options': { ReactHookFormComponent: CategoryIconWidget },
  },
  amount: {},
  currency: { 'ui:widget': 'select' },
  minimum_los: {},
  maximum_los: {},
  unit: { 'ui:widget': 'select' },
  set_schedules: { 'ui:widget': 'hidden' },
  period_schedule: {
    'ui:widget': 'hidden',
  },
  travel_period: {
    'ui:widget': 'hidden',
  },
  tactical_schedule: {
    'ui:widget': 'hidden',
  },
  schedules_list: {
    'ui:widget': 'hidden',
  },
  ...luxuryPlusFormExtension,
};

const inclusionKeysToDeleteIfNull = ['external_id', 'category_icon'];

export default class RoomRateInclusionsForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    const inclusionItem = props.inclusionItem
      ? JSON.parse(JSON.stringify(props.inclusionItem))
      : { ...DEFAULT_VALUES, currency: props.vendorCurrencyCode };

    inclusionItem.schedules_list = ingestSchedules(inclusionItem?.schedules_list);

    const inclusionsSchema = this.props.inclusionsSchema;
    delete inclusionsSchema.properties.schedules_list;

    this.state = {
      inclusionItem: deleteNulls(inclusionItem, inclusionKeysToDeleteIfNull),
      saveState: buttonStates.default,
      errors: null,
      schema: inclusionsSchema,
    };
  }

  handleChange = ({ formData }: IChangeEvent) => {
    this.setState({ inclusionItem: formData });
  };

  handleSubmit = ({ formData }: IChangeEvent) => {
    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    let promise;

    formData.schedules_list = prepareSchedulesList(formData?.schedules_list, this.state.inclusionItem.id);

    if (this.state.inclusionItem.id) {
      promise = ReservationService.updateRoomRateInclusion(
        formData,
        this.state.inclusionItem.id,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      );
    } else {
      promise = ReservationService.createRoomRateInclusion(
        formData,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      );
    }

    return promise
      .then((response) => {
        this.setState({
          saveState: buttonStates.saved,
        });
        this.props.onRoomRateInclusionUpdated(response.result);
      })
      .catch((e) => {
        const errors = e.name === 'ValidationError' ? e.errors : null;
        this.setState({
          saveState: buttonStates.failed,
          errors,
        });
      });
  };

  handleScheduleChange = (value, index) => {
    const schedules = { ...this.state.inclusionItem.schedules_list };
    schedules[index] = value;

    this.setState((prevState) => ({
      inclusionItem: {
        ...prevState.inclusionItem,
        schedules_list: schedules,
      },
    }));
  };

  addNewSchedule = () => {
    const schedules = { ...this.state.inclusionItem.schedules_list };
    schedules[uuid()] = {
      activePeriod: {
        from: null,
        to: null,
      },
      travelPeriod: {
        from: null,
        to: null,
      },
      type: 'sell',
      parent: this.state.inclusionItem.id,
    };

    this.setState((prevState) => ({
      inclusionItem: {
        ...prevState.inclusionItem,
        schedules_list: schedules,
      },
    }));
  };

  removeSchedule = (index) => {
    const schedules = { ...this.state.inclusionItem.schedules_list };
    delete schedules[index];

    this.setState((prevState) => ({
      inclusionItem: {
        ...prevState.inclusionItem,
        schedules_list: schedules,
      },
    }));
  };

  render() {
    return (
      <Form
        schema={this.state.schema}
        uiSchema={uiSchema}
        onSubmit={this.handleSubmit}
        formData={this.state.inclusionItem}
        onChange={this.handleChange}
        validator={validator}
        formContext={this.state.inclusionItem}
      >
        <Box mt={2}>
          <Typography variant="h6">Schedules</Typography>

          {this.state.inclusionItem.schedules_list && (
            <Stack direction="column" spacing={2}>
              {Object.keys(this.state.inclusionItem.schedules_list).map((key, idx) => (
                <Stack direction="row" key={key} spacing={2}>
                  <Typography>{idx + 1}.</Typography>

                  <Box flexGrow={1}>
                    <Schedules
                      key={key}
                      value={this.state.inclusionItem.schedules_list[key]}
                      onChange={(value) => this.handleScheduleChange(value, key)}
                    />
                  </Box>

                  <Box>
                    <IconButton aria-label="delete" onClick={() => this.removeSchedule(key)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ))}
            </Stack>
          )}

          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={this.addNewSchedule} startIcon={<AddIcon />}>
              Add schedule
            </Button>
          </Box>
        </Box>

        <Stack direction="row" justifyContent="space-between" spacing={2} mt={2}>
          <Button type="button" variant="text" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={this.state.saveState === buttonStates.saving}>
            {buttonMessages[this.state.saveState]}
          </Button>
        </Stack>
      </Form>
    );
  }
}
