import React from 'react';

import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

export default function LELogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32.61 33.77">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M28.2 29.66c.32-.26.18-.15 0 0ZM28.17 29.69c-.22.18-.36.31 0 0ZM26 31.18h.07ZM21.41 13c.42-.76 1.18-2.31.55-3.14s-1.76.61-2.09 1a13.41 13.41 0 0 0-2.63 6A7.13 7.13 0 0 0 21.41 13Z" />
        <path d="M26.89 30.39a14.3 14.3 0 0 0 3.05-2.54A18.31 18.31 0 0 0 32.61 24 16 16 0 0 1 29 28.52a23.71 23.71 0 0 0 2.62-3.13c-3 4.05-11.79 7.85-17 6-.28-.11.44-.06.45-.1A15.63 15.63 0 0 0 31.58 16.9v-.93A15.63 15.63 0 0 0 1.9 8.83 17.23 17.23 0 0 0 9.51 32c-.23-.14-.46-.28-.68-.44a16.64 16.64 0 0 0 4.9 1.9A17.34 17.34 0 0 1 9.51 32c4 2.4 10 2.13 14.11.42-.43.13-.85.24-1.26.34a17.87 17.87 0 0 1-3.62.66 35.08 35.08 0 0 0 3.62-.66A15.85 15.85 0 0 0 26 31.18c-.13.07 0 0 .53-.35a12.88 12.88 0 0 0 1.57-1.11l.08-.07a16.71 16.71 0 0 0 2.66-2.77l-.92 1a12.57 12.57 0 0 1-3.03 2.51ZM19.56 8.3a2.5 2.5 0 0 1 2.1.32 6.43 6.43 0 0 1 2 1.52c1.16 1.5-.06 3.25-1.09 4.44a10.38 10.38 0 0 1-4.19 2.82c-.27.1-1.13.22-1.23.44a3.13 3.13 0 0 0 0 1.34c.09.68.32 1.63 1.1 1.81A2.17 2.17 0 0 0 21 18.56a2.67 2.67 0 0 0-.09-.51 2 2 0 0 1-.17-.48c0-.46.57-.4.87-.27.77.34 1 .44.85 1.22a3.8 3.8 0 0 1-3.67 3.22c-2.21.07-3.17-2.14-3.36-4 0 .13 0 .22 0 0 0-.42 0-.19 0 0a12.17 12.17 0 0 1 1-5.52c.62-1.48 1.57-3.33 3.13-3.92Zm-7.62 18a3.84 3.84 0 0 0-1.67.13c-.45.16-.7.55-1.13.73s-.8 0-.63-.52c.08-.22.26-.38.36-.6s.2-.47.29-.71a24.15 24.15 0 0 0 .89-3.41c1-4.7 2.89-14.16 2.89-14.16A16.57 16.57 0 0 0 10.85 10c-.59 1-.88 3.16-.62 3.42a.84.84 0 0 1 .16 1.17c-.29.41-1.53-.06-2-.49-1.7-1.49-.11-3.29.86-4.42s3.55-3 3.73-3.22c0-.12.51-1.14.74-1.58a2.74 2.74 0 0 1 1.83-1.6c.26-.07.87-.23 1.08 0s-.08.84-.2 1.09a15 15 0 0 0-.86 2.65C15 9.19 14.5 11.27 14 13.35s-1 4.15-1.58 6.21c-.28 1.05-.6 2.1-1 3.13a23.66 23.66 0 0 1-1.13 2.87c1.77-.43 3.59 0 5.38-.06a8 8 0 0 0 2.54-.5c.48-.18 1.56-1.14 1.74-.19a2.29 2.29 0 0 1-1.48 2.55A4.4 4.4 0 0 1 15 27.1a9.67 9.67 0 0 0-3.07-.84ZM26.1 31.14l.55-.33ZM26.64 30.82c.23-.15.48-.31.63-.42l-.63.42Z" />
      </g>
    </SvgIcon>
  );
}
