import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DealsLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 120 32.05">
      <defs>
        <linearGradient
          id="a"
          x1="15.42"
          y1="37.93"
          x2="15.42"
          y2="18.5"
          gradientTransform="matrix(1 0 0 -1 0 53.89)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff6f00" />
          <stop offset="1" stopColor="#ff4100" />
        </linearGradient>
        <linearGradient id="b" x1="37.89" y1="37.93" x2="37.89" y2="18.5" xlinkHref="#a" />
        <linearGradient id="c" x1="62.06" y1="37.93" x2="62.06" y2="18.5" xlinkHref="#a" />
        <linearGradient id="d" x1="84.9" y1="37.93" x2="84.9" y2="18.5" xlinkHref="#a" />
        <linearGradient id="e" x1="104.89" y1="37.9" x2="104.89" y2="17.83" xlinkHref="#a" />
      </defs>
      <g data-name="Layer 2">
        <g data-name="Deals Logo">
          <path
            d="M96.68 30.53v1.15H64.94L64 28.53h-3.79l-.91 3.15h-34v-3.45a17.61 17.61 0 0 1-11 3.45H0V.36h14.3a17.56 17.56 0 0 1 11 3.46V.36h25.34v12.12L55.06.36h14.18l4 11v-11h14.41v17.81h6.18a9.57 9.57 0 0 1-2.42-6.78C91.35 4.85 96.92 0 104.92 0a19.29 19.29 0 0 1 12.48 4.18L120 6.3l-4.54 6.06a9.24 9.24 0 0 1 3.81 7.69c0 7.45-5.63 12-13.93 12a21.47 21.47 0 0 1-8.66-1.51Z"
            fill="#fff"
          />
          <path
            d="M14.17 3.85H3.51v24.23h10.73c7.51 0 13.08-4.42 13.08-12.18S21.75 3.85 14.17 3.85Zm0 17.81h-3.45V10.21h3.45c3.88 0 5.76 2.42 5.76 5.69a5.5 5.5 0 0 1-5.76 5.76Z"
            fill="url(#a)"
          />
          <path d="M28.83 28.08h18.12v-6.12H36.1v-3.09h10.6v-6.12H36.1V9.97h10.85V3.85H28.83v24.23z" fill="url(#b)" />
          <path
            d="M57.55 3.85 48.7 28.08h7.88l.91-3.15h9.14l.91 3.15h7.88L66.63 3.85Zm1.81 15 2.73-8.54 2.73 8.54Z"
            fill="url(#c)"
          />
          <path d="M83.96 3.85h-7.21v24.23h16.29v-6.42h-9.08V3.85z" fill="url(#d)" />
          <path
            d="M105.76 12.63c-2.54-.42-3.51-.79-3.51-1.69 0-.61.43-1.28 2.18-1.28a11.57 11.57 0 0 1 6.67 2.43l3.9-5.21a15.68 15.68 0 0 0-10.11-3.39c-6.55 0-9.94 3.75-9.94 7.87 0 6.06 6 7 10 7.75 2.36.43 3.39.91 3.39 1.88s-1.27 1.39-2.67 1.39A11 11 0 0 1 98 19.23l-3.76 5.46c2.55 2.3 6 3.87 11.09 3.87 6.42 0 10.3-3.09 10.3-8.42-.05-5.63-5.93-6.84-9.87-7.51Z"
            fill="url(#e)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
