import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Box, Button } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import VendorsService from '~/services/VendorsService';

import ErrorDisplay from '../ErrorDisplay';

function validateForm(formData, errors) {
  if (typeof formData.effectiveDate === 'undefined') {
    errors.effectiveDate.addError('Please enter effective date');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description:
    'To download the Finance FX budgeted and spot rates for the month run the report below - please select the 1st of month when running this report',
  type: 'object',
  properties: {
    effectiveDate: {
      title: 'Effective Month',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  effectiveDate: {
    'ui:widget': 'date',
  },
};

export default function FxRatesForm() {
  const [comment, setComment] = useState(null);
  const [error, setError] = useState(null);

  const resetMessageState = () => {
    setComment(null);
    setError(null);
  };

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setComment('Downloading FX Rates File...');

      await VendorsService.downloadFxRatesFile(event.formData.effectiveDate);

      resetMessageState();
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  return (
    <Box>
      <PageSubheader title="FX Rates File" />

      <Form
        schema={formSchema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
        customValidate={validateForm}
        validator={validator}
        showErrorList={false}
      >
        <Button type="submit" variant="contained">
          Download FX Rates File
        </Button>

        {error && <ErrorDisplay message={error} />}

        {comment && (
          <Alert severity="info">
            <p>{comment}</p>
          </Alert>
        )}
      </Form>
    </Box>
  );
}
