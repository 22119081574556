import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerCitySegmentGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/country-groups/{countryGroup}/countries/{country}/states/{state}/cities/{city}/segments']['get'];

function mapHeroPlannerStateSegment(
  external: Utils.ArrayElement<
    HeroPlannerCitySegmentGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerStateSegment {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetHeroPlannerCitySegments(
  brand: string,
  countryGroup: string,
  country: string,
  state: string,
  city: string,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!state) throw 'missing state';
  if (!city) throw 'missing city';

  const url = makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/country-groups/${countryGroup}/countries/${country}/states/${state}/cities/${city}/segments`,
  );
  url.searchParams.set('brand', brand);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerCitySegmentGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerStateSegment);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerCitySegments;
