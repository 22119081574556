import React, { Fragment } from 'react';

import { useSnackbar } from 'notistack';

import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

import ReservationSummaryField from '~/components/Purchases/OrderDetail/OrderItem/ReservationSummaryField';

import useToggleState from '~/hooks/useToggleState';

import currencyFormatter from '~/utils/currencyFormatter';
import { isLTLEHotelType, isOfferTypeDynamicPricedHotel, isOfferTypeTour } from '~/utils/offer';
import { formatOrderDate, getCustomerPhoneNumberFromOrder } from '~/utils/order';

import { getChannelManagerRate, getCostCurrencyCode, getPackageCostPrice, getTotalCostPrice } from './utils';

interface Props {
  item: App.OrderItem;
  guestName: string;
  order: App.Order;
}

const copyToClipboard = (data: object) => {
  // filter out null values
  const parsedObject = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
  // convert bookingDetails into a multiline string
  const text = Object.entries(parsedObject)
    .map(([key, value]) => {
      if (value) {
        return `${key} ${value}`;
      }
    })
    .join('\r\n');

  navigator.clipboard.writeText(
    `Hi (contact name),\n\nCan you please confirm the below test booking is correctly priced and was successfully and correctly received in your PMS:\r\n${text}\n\nKind regards,`,
  );
};

export default function ReservationSummary({ guestName, item, order }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { fullReservation, offer_package } = item;
  const fromDate = item.reservation && formatOrderDate(item.reservation.check_in || item?.reservation.start_date);
  const toDate = item.reservation && formatOrderDate(item.reservation.check_out || item.reservation.end_date);
  const { isToggled: isSummaryOpen, toggle: toggleSummary } = useToggleState(false);

  const packageCostPrice = getPackageCostPrice(offer_package, item);

  const totalCostPrice = getTotalCostPrice(packageCostPrice, item.reservation);

  const totalCostPriceDisplayed = isLTLEHotelType(item.offer) ? totalCostPrice : packageCostPrice;

  const costCurrencyCode = getCostCurrencyCode(offer_package, item);

  const channelManagerRate = getChannelManagerRate(item);
  const bookingDetails = {
    'Name:': guestName || order.customer_full_name,
    'Booking Number:': item.booking_number,
    'Dates:': item.reservation ? `${fromDate} - ${toDate}` : null,
    'Phone Number:': getCustomerPhoneNumberFromOrder(order),
    'Email:': order.customer_email,
    'Package Type:': fullReservation.property
      ? `${fullReservation.room_type.name} | ${fullReservation.rate_plan.name}`
      : null,
    'Property Name:': isOfferTypeTour(item.offer) ? item.offer.name : item.offer_package.property.name,
    'Offer Name:': item.offer.name,
    'Total sell price:':
      'price' in item && `${currencyFormatter(order.currency_code, item.total as number)} ${order.currency_code}`,
    'Total cost price:':
      'cost_price' in item && `${currencyFormatter(costCurrencyCode, totalCostPriceDisplayed as number)}`,
    'Channel Manager Rate:': isOfferTypeDynamicPricedHotel(item.offer)
      ? `${currencyFormatter(costCurrencyCode, channelManagerRate)}`
      : undefined,
  };

  const handleCopyClick = () => {
    copyToClipboard(bookingDetails);
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <IconButton title="Copy user details" onClick={handleCopyClick}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>

        <Button
          variant="text"
          size="small"
          onClick={toggleSummary}
          title="(CM's) / CAM's complete testing prior to release, they send through the test booking to the partner"
        >
          Template for CM
        </Button>
      </Box>

      <Dialog
        open={isSummaryOpen}
        onClose={toggleSummary}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Booking Details are as follows:</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Object.entries(bookingDetails).map(([key, value]) => (
              <Fragment key={key}>{value && <ReservationSummaryField label={key} value={value} />}</Fragment>
            ))}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={toggleSummary}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
