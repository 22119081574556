import React, { useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';

type Props = {
  buttonTitle: string;
  buttonClick: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactElement;
  disabled?: boolean;
  id?: string;
  className?: string;
};

export default function DropdownButton(props: Props) {
  const { buttonTitle, buttonClick, className, children, disabled } = props;

  const [isOpen, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} disabled={disabled}>
        <Button onClick={buttonClick} className={className}>
          {buttonTitle}
        </Button>
        <Button onClick={() => setOpen((prevOpen) => !prevOpen)} size="small">
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper open={isOpen} anchorEl={anchorRef.current} placement="bottom-end" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper elevation={1}>
              <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
