import React from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
  name: string;
  externalId: string;
};

export default function ShipColumnDetails(props: Props) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography>
        <b>External ID: </b>
        {props.externalId}
      </Typography>
      <Typography>
        <b>Name: </b>
        {props.name}
      </Typography>
    </Box>
  );
}
