import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import RoomRateExtraGuestSurchargeForm from '~/components/Common/Forms/RoomRateExtraGuestSurchargeForm';
import PermissionComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import RoomRateExtraGuestSurchargeItem from './RoomRateExtraGuestSurchargeItem';

export default function RoomRateExtraGuestSurcharges(props) {
  const [addingNew, setAddingNew] = useState(false);
  const [editing, setEditing] = useState(null);

  const openAddBox = () => {
    setAddingNew(true);
  };

  const openEditBox = (extraGuestSurcharge) => {
    setEditing(extraGuestSurcharge);
  };

  const closeForm = () => {
    setAddingNew(false);
    setEditing(null);
  };

  const onRoomRateExtraGuestSurchargeUpdated = () => {
    props.onUpdateRoomRateExtraGuestSurcharge();
    setAddingNew(false);
    setEditing(null);
  };

  return (
    <Grid container spacing={2} direction="row" className="RoomRateExtraGuestSurcharges">
      <Grid xs={12}>
        <Typography color="secondary" textTransform="uppercase">
          Extra Guest Surcharges
        </Typography>
      </Grid>
      {props.extraGuestSurcharges.length === 0 ? (
        <Grid xs={12}>
          <i>Surcharges have not been defined</i>
        </Grid>
      ) : (
        <Grid xs={12}>
          {props.extraGuestSurcharges.map((extraGuestSurchargeItem) => (
            <RoomRateExtraGuestSurchargeItem
              key={extraGuestSurchargeItem.id}
              extraGuestSurchargeItem={extraGuestSurchargeItem}
              onDelete={props.deleteExtraGuestSurcharge}
              onRequestEdit={openEditBox}
            />
          ))}
        </Grid>
      )}

      {props.extraGuestSurcharges.length === 0 && (
        <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
          <div>
            <Button onClick={openAddBox} variant="contained" startIcon={<AddIcon />}>
              Add
            </Button>
          </div>
        </PermissionComponent>
      )}

      <Dialog open={addingNew || !!editing} onClose={closeForm}>
        <DialogTitle>Set Extra Guest Surcharges</DialogTitle>
        <DialogContent>
          <RoomRateExtraGuestSurchargeForm
            vendorCurrencyCode={props.vendorCurrencyCode}
            extraGuestSurchargeItem={editing}
            propertyId={props.propertyId}
            roomTypeId={props.roomType.id}
            roomRateId={props.roomRate.id}
            onCancel={closeForm}
            onRoomRateExtraGuestSurchargeUpdated={onRoomRateExtraGuestSurchargeUpdated}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
