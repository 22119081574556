import React, { Component } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema, StrictRJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import deepEqual from 'deep-equal';
import cloneDeep from 'lodash/cloneDeep';

import { Alert } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import UsersService from '~/services/UsersService';

const defaultFieldsOrder = ['givenName', 'surname', 'email', 'email_verified', 'dob', 'customer_support_code', '*'];

const initUiSchema: UiSchema = {
  'ui:order': [...defaultFieldsOrder, 'resetPassword'],
  email: { 'ui:widget': 'email' },
  email_verified: { 'ui:readonly': true },
  dob: { 'ui:widget': 'date' },
  customer_support_code: { 'ui:readonly': true, 'ui:order': 4 },
  recently_used_airport_code: { 'ui:emptyValue': null },
};

type Props = {
  user: App.User;
  schema: RJSFSchema;
  brand: App.Brands;
  update?: (user: App.User) => void;
};

type FormData = Omit<App.User, 'gdpr'> & {
  resetPassword?: boolean;
  password?: string;
  vendors?: string[];
  gdpr: boolean | string;
};

type State = {
  formData: FormData;
  schema: RJSFSchema;
  alertSuccess: boolean;
  alertError: boolean;
  errorMessage: string;
  partnershipsAlertError: boolean;
  partnershipsErrorMessage: string;
  flightEnabled: boolean;
  referralProgramEnabled: boolean;
  shadowBanUser: boolean;
};

export default class UpdateUser extends Component<Props, State> {
  submitButton: HTMLButtonElement;

  constructor(props) {
    super(props);

    const formData: FormData = cloneDeep(this.props.user);
    const schema = cloneDeep(this.props.schema);

    this.state = {
      schema,
      formData,
      alertSuccess: false,
      alertError: !formData.country_code,
      errorMessage: !formData.country_code ? 'Country is a required field!' : '',
      partnershipsAlertError: false,
      partnershipsErrorMessage: '',
      flightEnabled: formData.flights_enabled,
      referralProgramEnabled: formData.referral_program_enabled,
      shadowBanUser: formData.shadow_ban_user,
    };
  }

  handleStateData = () => {
    const schema = { ...this.state.schema };
    const formData = { ...this.state.formData };
    let uiSchema: UiSchema;

    //reset password
    schema.properties = {
      ...schema.properties,
      email_verified: { type: 'boolean', title: 'Email Verified' },
      resetPassword: {
        type: 'boolean',
        title: 'Reset Password',
      },
      customer_support_code: {
        type: 'string',
        title: 'Customer Support Code',
      },
      roles: {
        ...(schema.properties.roles as object),
        items: {
          ...((schema.properties.roles as StrictRJSFSchema).items as object),
          title: 'Role',
        },
      },
      recently_used_airport_code: {
        ...(schema.properties.recently_used_airport_code as object),
        type: ['string', 'null'],
      },
      postcode: {
        ...(schema.properties.postcode as object),
        type: ['string', 'null'],
      },
      phone_prefix: {
        ...(schema.properties.phone_prefix as object),
        type: ['string', 'null'],
      },
    };

    if (formData.resetPassword) {
      schema.properties = {
        ...schema.properties,
        password: { type: 'string', title: 'Password' },
      };
      uiSchema = {
        'ui:order': [...defaultFieldsOrder, 'resetPassword', 'password'],
      };
    } else {
      delete formData.password;
      delete schema.properties.password;
      uiSchema = { ...initUiSchema };
    }

    //partnerships
    if (window.configs.KRIS_FLYER_ENABLED !== 'true') {
      delete (schema.properties.partnerships as StrictRJSFSchema).properties.kfp;
    }

    if (window.configs.QANTAS_EARN_ORDER_BASED_ENABLED === 'true') {
      delete (schema.properties.partnerships as StrictRJSFSchema).properties.qff;
    }

    //vendor
    if (!formData.roles.includes('vendor-user')) {
      schema.properties = { ...schema.properties };
      if (formData.vendors) {
        delete formData.vendors;
      }
      if (schema.properties.vendors) {
        delete schema.properties.vendors;
      }
    } else {
      schema.properties = {
        ...schema.properties,
        vendors: {
          type: 'array',
          title: 'Vendor ID',
          items: { type: 'string' },
        },
      };

      uiSchema = { ...initUiSchema };
    }

    if (formData.roles.length === 0 && formData.status === 'ENABLED') {
      formData.roles = ['customer'];
    }

    if (formData.gdpr) {
      formData.gdpr = true;
    } else {
      delete formData.gdpr;
    }

    if (!formData.phone) {
      delete formData.phone;
    }

    if (!formData.country_code) {
      delete formData.country_code;
    }

    if (!formData.dob) {
      delete formData.dob;
    }

    uiSchema.banned_led_user = { 'ui:widget': 'hidden' };

    return { schema, formData, uiSchema };
  };

  handleChange = (data: IChangeEvent<FormData>) => {
    const { formData } = data;

    this.setState({
      formData,
      alertSuccess: false,
      alertError: false,
      errorMessage: '',
      partnershipsAlertError: false,
      partnershipsErrorMessage: '',
      flightEnabled: formData.flights_enabled,
      referralProgramEnabled: formData.referral_program_enabled,
      shadowBanUser: formData.shadow_ban_user,
    });
  };

  handleSubmit = async (event: IChangeEvent<FormData>) => {
    const { formData } = event;
    delete formData.flights_enabled;
    delete formData.referral_program_enabled;
    delete formData.shadow_ban_user;
    const shouldUpdateShadowBanUser = typeof this.state.shadowBanUser === 'boolean';

    if (!formData.dob) {
      formData.dob = null;
    }

    let user = formData as App.User;
    let userSaved = false;
    try {
      user = await UsersService.updateUser(formData, this.props.brand);

      // todo: check if we can toggle all flags in parallel
      //       potential can lead to lock
      const toggleFlight = await UsersService.toggle({
        id_member: formData.id_member,
        toggle_name: 'flights_enabled',
        toggle_value: this.state.flightEnabled,
      });

      const toggleReferralProgram = await UsersService.toggle({
        id_member: formData.id_member,
        toggle_name: 'referral_program_enabled',
        toggle_value: this.state.referralProgramEnabled,
      });

      let toggleShadowBanUser;
      if (shouldUpdateShadowBanUser) {
        toggleShadowBanUser = await UsersService.toggle({
          id_member: formData.id_member,
          toggle_name: 'shadow_ban_user',
          toggle_value: this.state.shadowBanUser,
        });
      }
      if (
        user &&
        toggleFlight &&
        toggleReferralProgram &&
        (!shouldUpdateShadowBanUser || (shouldUpdateShadowBanUser && toggleShadowBanUser))
      ) {
        userSaved = true;
      }
    } catch (err) {
      const errorMessage = err.errors && err.errors.length ? err.errors[0] : err.message;
      this.setState({
        alertSuccess: false,
        alertError: true,
        errorMessage: errorMessage,
      });
    }

    if (userSaved && !deepEqual(formData.partnerships, this.props.user.partnerships)) {
      try {
        await UsersService.updatePartnershipsDetails(formData.id_member, formData.partnerships);
      } catch (err) {
        const errorMessage = err.errors && err.errors.length ? err.errors[0] : err.message;
        this.setState({
          partnershipsAlertError: true,
          partnershipsErrorMessage: errorMessage,
        });
      }
    }

    if (userSaved) {
      user = await UsersService.getUser(formData.id_member, {
        brand: this.props.brand,
      });

      this.setState(
        {
          formData: cloneDeep(user),
          alertSuccess: true,
          alertError: false,
          errorMessage: '',
        },
        () => {
          if (this.props?.update) {
            this.props.update(user);
          }
          //this.props.update causes handleChange to fire, which resets alertSuccess
          this.setState({
            alertSuccess: true,
          });
        },
      );
    }
  };

  nativeCurrencyCode() {
    const region = libRegions.getRegionByCode(this.state.formData.country_code);
    return region ? region.currencyCode : 'Currency is not available as country is not set';
  }

  render() {
    const alertError = (
      <Alert severity="error">
        <strong>Error!</strong> Unable to save user. {this.state.errorMessage}.
      </Alert>
    );

    const alertSuccess = (
      <Alert severity="success">
        <strong>Success!</strong> User Information Saved
      </Alert>
    );

    const partnershipsAlertError = (
      <Alert severity="error">
        <strong>Error!</strong> Unable to save partnership details. {this.state.partnershipsErrorMessage}.
      </Alert>
    );

    const { schema, formData, uiSchema } = this.handleStateData();

    return (
      <div>
        {this.state.alertError && alertError}
        {this.state.alertSuccess && alertSuccess}
        {this.state.partnershipsAlertError && partnershipsAlertError}
        <Form
          formData={formData}
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
          validator={validator}
        >
          <dl>
            <dt className="h6 text-muted text-uppercase">Native Currency Code</dt>
            <dd>{this.nativeCurrencyCode()}</dd>
          </dl>

          <button
            ref={(btn) => {
              this.submitButton = btn;
            }}
            className="hidden"
          />
        </Form>
        {this.state.alertError && alertError}
        {this.state.alertSuccess && alertSuccess}
        {this.state.partnershipsAlertError && partnershipsAlertError}
      </div>
    );
  }
}
