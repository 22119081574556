import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import GridPagination from '~/components/Common/Elements/GridPagination';

import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';

const columns: GridColDef[] = [
  { field: 'id_salesforce_external', headerName: 'Offer ID', width: 200, sortable: false, display: 'flex' },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,
    renderCell: (params) => <Link to={`/offers/${params.row.id_salesforce_external}`}>{params.row.name}</Link>,
    display: 'flex',
  },
];

export default function RatePlanOffersContainer(props) {
  const { vendorId, ratePlanId } = props;
  const [offers, setOffersVendorOffers] = useState([]);

  useEffect(() => {
    async function gatherRatePlanOffers() {
      const { result: properties } = await ReservationService.getProperties(vendorId);

      const relatedRoomRates = properties.reduce((acc, property) => {
        property?.room_types?.forEach((roomType) => {
          roomType.room_rates.forEach((roomRate) => {
            if (roomRate?.rate_plan.id === ratePlanId) {
              acc.add(roomRate.id);
            }
          });
        });
        return acc;
      }, new Set());

      const { result: offers } = await OffersService.getOffers({
        queryString: vendorId,
      });

      const rateOffers = offers.filter((offer) => {
        return offer.packages.some(
          (p) =>
            relatedRoomRates.has(p.fk_room_rate_id) ||
            p.package_options?.some((o) => relatedRoomRates.has(o.fk_room_rate_id)),
        );
      });
      setOffersVendorOffers(rateOffers);
    }

    gatherRatePlanOffers();
  }, [ratePlanId, vendorId]);

  return (
    <div>
      <DefinitionTitle>Linked Offers</DefinitionTitle>
      <div>
        <DataGrid
          columns={columns}
          rows={offers}
          getRowId={(row) => row.id_salesforce_external}
          slots={{ pagination: GridPagination }}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
        />
      </div>
    </div>
  );
}
