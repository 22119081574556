import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';

import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from '@mui/material';

import TourOfferCountryInput from './TourOfferCountryInput';
import TourOfferStatusInput from './TourOfferStatusInput';

export interface LETourSearchInput {
  countries?: string[];
  salesforceId?: string;
  status?: string;
  tourName?: string;
  ids?: string;
}

const DEFAULT_LE_TOUR_SEARCH_INPUT: LETourSearchInput = {
  countries: [],
  salesforceId: '',
  status: 'any',
  tourName: '',
  ids: '',
};

interface Props {
  initialInput?: LETourSearchInput;
  onSubmit: (formObject: Record<string, string>) => void;
}

function LETourSearchForm(props: Props) {
  const { initialInput, onSubmit } = props;

  const [tourName, setTourName] = useState<string>(DEFAULT_LE_TOUR_SEARCH_INPUT.tourName);
  const [salesforceId, setSalesforceId] = useState<string>(DEFAULT_LE_TOUR_SEARCH_INPUT.salesforceId);
  const [status, setStatus] = useState<string>(DEFAULT_LE_TOUR_SEARCH_INPUT.status);
  const [countries, setCountries] = useState<string[]>(DEFAULT_LE_TOUR_SEARCH_INPUT.countries);
  const [ids, setIds] = useState<string>(DEFAULT_LE_TOUR_SEARCH_INPUT.ids);

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      const formObject: Record<string, string> = {};

      if (countries?.length) formObject.countries = countries.join(',');
      if (salesforceId) formObject.salesforceId = salesforceId;
      if (status && status !== 'any') formObject.status = status;
      if (tourName) formObject.tourName = tourName;
      if (ids)
        formObject.ids =
          ids.length > 0
            ? ids
                .split(',')
                .map((id) => {
                  id = id.trim();
                  if (!id.startsWith('tour-')) return `tour-${id}`;
                  return id;
                })
                .join(',')
                .toString()
            : ids;

      onSubmit(formObject);
    },
    [countries, onSubmit, salesforceId, status, tourName, ids],
  );

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();

    setCountries(DEFAULT_LE_TOUR_SEARCH_INPUT.countries);
    setSalesforceId(DEFAULT_LE_TOUR_SEARCH_INPUT.salesforceId);
    setStatus(DEFAULT_LE_TOUR_SEARCH_INPUT.status);
    setTourName(DEFAULT_LE_TOUR_SEARCH_INPUT.tourName);
    setIds(DEFAULT_LE_TOUR_SEARCH_INPUT.ids);
  }, []);

  useEffect(() => {
    setCountries(initialInput?.countries ?? DEFAULT_LE_TOUR_SEARCH_INPUT.countries);
    setSalesforceId(initialInput?.salesforceId ?? DEFAULT_LE_TOUR_SEARCH_INPUT.salesforceId);
    setStatus(initialInput?.status ?? DEFAULT_LE_TOUR_SEARCH_INPUT.status);
    setTourName(initialInput?.tourName ?? DEFAULT_LE_TOUR_SEARCH_INPUT.tourName);
    setIds(initialInput?.ids ?? DEFAULT_LE_TOUR_SEARCH_INPUT.ids);
  }, [initialInput]);

  return (
    <form onSubmit={handleSubmission} onReset={handleReset}>
      <Card>
        <CardHeader title="Search for LE Tours" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField label="Tour name" value={tourName} onChange={(e) => setTourName(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TourOfferStatusInput value={status} onChange={setStatus} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Salesforce ID"
                value={salesforceId}
                onChange={(e) => setSalesforceId(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TourOfferCountryInput value={countries} onChange={setCountries} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Tour IDs" value={ids} onChange={(e) => setIds(e.target.value)} fullWidth />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button variant="text" type="reset">
            Reset
          </Button>
          <Button variant="contained" type="submit">
            Search
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default LETourSearchForm;
