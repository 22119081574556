import cloneDeep from 'lodash/cloneDeep';

export function formIdToFieldDepthConverter(id: string): string[] {
  return id.replace('root_', '').split('_');
}

export function splitTitleFieldIdIntoTitles(id: string): string[] {
  const matches = id
    .replace('root__', '')
    .replace('__title', '')
    .match(/([a-zA-Z]+_\d+|([a-zA-Z]+)$)/gis); // match anyText_numbers and then consecutive letters

  if (!matches) return [];

  return matches.map(
    (match) =>
      match
        .replace(/_\d+$/g, (match) => `_${Number(match.substring(1)) + 1}`) // bump index bit by one
        .replace('_', ' ')
        .replace(/([A-Z])/g, ' $1') // camelCase to space-seperated
        .toLowerCase(), // CSS will take care of casing
  );
}

function traverseAndMarkCloned(obj: unknown, propertiesToExclude?: string[]): void {
  if (!obj || typeof obj !== 'object') {
    return;
  }
  for (const value of Object.values(obj)) {
    if (typeof value === 'object') {
      traverseAndMarkCloned(value);
    }
  }
  if (!Array.isArray(obj)) {
    (obj as Record<string | number, unknown>).hasBeenCloned = true;
    if (propertiesToExclude?.length) {
      propertiesToExclude.forEach((property) => {
        delete obj[property];
      });
    }
  }
}

export function cloneFormData<T>(formData: T, propertiesToExclude?: string[]): T {
  const clonedFormData = cloneDeep(formData);
  traverseAndMarkCloned(clonedFormData, propertiesToExclude);

  return clonedFormData;
}
