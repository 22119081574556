import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerMemberships from '~/actions/customerCommunication/fetchHeroPlannerMemberships';

import { useAppSelector } from '../store';

function useHeroPlannerMemberships(
  brandId: string,
  countryGroupId: string,
  countryId: string,
  stateId: string,
  cityId: string,
  segmentId: string,
  cadenceId: string,
) {
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerMemberships> = [
      brandId,
      countryGroupId,
      countryId,
      stateId,
      cityId,
      segmentId,
      cadenceId,
    ];
    return [getRecordKeyFor(...params), params];
  }, [brandId, countryGroupId, countryId, stateId, cityId, segmentId, cadenceId]);
  const dataReq = useAppSelector(
    (state) => state.customerCommunication.countryGroupCountryStateCitySegmentCadenceMembershipsRecords[key],
  );

  const appDispatch = useDispatch();
  const fetchMemberships = useCallback(() => {
    appDispatch(fetchHeroPlannerMemberships(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchMemberships();
  }, [fetchMemberships]);

  return { dataReq, fetchMemberships };
}

export default useHeroPlannerMemberships;
