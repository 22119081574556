import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import OfferLocationHeading from '~/components/Common/Booking/OfferLocationHeading';

import { ITEM_TYPE_OFFER } from '~/consts/order';

import { checkItemComplete } from '~/utils/cart';

import NoDataWarning from '../NoDataWarning';
import OrderFooter from '../OrderFooter';
import { getAllItems } from '../selectors';
import withOffers from '../withOffers';

import BookingContent from './BookingContent';
import ContactPhone from './ContactPhone';

const MIN_PHONE_LENGTH = 7;

type Props = {
  cart: App.Cart;
  items: App.CartItem[];
  offer: App.CartOffer;
  user: App.User;
  offerNumber: number;
  nextStepLabel: string;
  proceedStep: () => void;
  backStep: () => void;
};

function BookingInformation(props: Props) {
  const { cart, offer, items, offerNumber, proceedStep, nextStepLabel, backStep, user } = props;
  const { id_user: userId } = useParams<{ id_user: string }>();

  const { currencyCode } = cart;

  const isStateValid = useMemo(() => items.length > 0, [items]);

  const canProceed = useMemo(() => {
    const { contactPhone, contactPhonePrefix } = cart;

    if (items.length === 0) {
      return false;
    }

    if (!contactPhone || contactPhone.length < MIN_PHONE_LENGTH || !contactPhonePrefix) {
      return false;
    }

    return items.every((item) => {
      return checkItemComplete(item, offer.booking_type, offer.type);
    });
  }, [cart, items, offer.booking_type, offer.type]);

  if (!isStateValid) {
    return <NoDataWarning userId={userId} orderType={ITEM_TYPE_OFFER} />;
  }

  return (
    <Box border="solid 1px #d6d6d6" borderRadius={1} px={2} mb={4} data-testid={offer.id_salesforce_external}>
      <Box mb={6}>
        <OfferLocationHeading offerNumber={offerNumber} offer={offer}></OfferLocationHeading>

        <ContactPhone user={user} />

        <BookingContent items={items} offer={offer} offerNumber={offerNumber} currencyCode={currencyCode} />
      </Box>

      <OrderFooter
        cart={cart}
        proceedStep={proceedStep}
        canProceed={canProceed}
        backStep={backStep}
        nextStepLabel={nextStepLabel}
      />
    </Box>
  );
}

function mapStateToProps(state: App.State) {
  return {
    cart: state.cart,
    items: getAllItems(state),
  };
}

export default connect(mapStateToProps)(withOffers(BookingInformation));
