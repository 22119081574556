import useCurrentUser from '~/hooks/useCurrentUser';

interface Props {
  // a user must be in a list of user IDs
  userIDs?: Array<string>;
  permitInTest?: boolean;
  children?: any;
}

export default function UserIDPermissionedComponent({ userIDs = [], permitInTest, children }: Props) {
  const { user } = useCurrentUser();

  if (permitInTest && process.env.NODE_ENV !== 'production') {
    return children;
  }
  if (userIDs.includes(user.id_member)) {
    return children;
  }

  return null;
}
