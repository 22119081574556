import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { CruisePromoBannerProvider } from '~/components/Cruises/context/useCruisePromoBanners';

import PromoBannerForm from './components/PromoBannerForm/PromoBannerForm';

const PromoBannerCreate: React.FC = (): JSX.Element => {
  return (
    <CruisePromoBannerProvider>
      <Box px={5}>
        <Helmet>
          <title>New promo tile</title>
        </Helmet>
        <Link to={'/cruises/promo-banners'} className="btn btn-default">
          Return to promo tiles
        </Link>

        <h1 className="page-header">New promo tile</h1>

        <PromoBannerForm />
      </Box>
    </CruisePromoBannerProvider>
  );
};

export default PromoBannerCreate;
