import React, { useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { getChatStats } from '~/services/SupportService';
import { formatSeconds } from '~/services/TimeService';

const COLUMNS: GridColDef[] = [
  {
    field: 'chatId',
    headerName: 'Chat Id',
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <Link target="_blank" href={`/support-assistant/chats-history?chat=${params.value}`}>
        {params.value}
      </Link>
    ),
    display: 'flex',
  },
  {
    field: 'timeToAgentResponse',
    headerName: 'Agent response time',
    width: 300,
    sortable: false,
    renderCell: (params) => formatSeconds(params.value),
    display: 'flex',
  },
  {
    field: 'totalAgentChatTime',
    headerName: 'Total time w/ agent',
    width: 300,
    sortable: false,
    renderCell: (params) => formatSeconds(params.value),
    display: 'flex',
  },
  {
    field: 'totalChatTime',
    headerName: 'Total chat time',
    width: 300,
    sortable: false,
    renderCell: (params) => formatSeconds(params.value),
    display: 'flex',
  },
  {
    field: 'createdAt',
    headerName: 'Chat Created',
    width: 300,
    sortable: false,
    renderCell: (params) => params.value,
    display: 'flex',
  },
];

const SIZE_PER_PAGE = 25;

export const ChatStats = () => {
  const [loading, setIsLoading] = useState(false);
  const [stats, setStats] = useState(null);
  const [totalChats, setTotalChats] = useState(0);
  const [page, setPage] = useState(0);
  const [agentChatsOnly, setAgentChatsOnly] = useState(false);
  const [hasMessagesOnly, setHasMessagesOnly] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    getChatStats({ agentChatsOnly, offset: page * SIZE_PER_PAGE, limit: SIZE_PER_PAGE, hasMessagesOnly }).then(
      ({ result }) => {
        setStats(result.chats);
        setTotalChats(result.total);
        setIsLoading(false);
      },
    );
  }, [page, agentChatsOnly, hasMessagesOnly]);

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={agentChatsOnly} onChange={(event) => setAgentChatsOnly(event.target.checked)} />}
        label="Agent chats only"
      />
      <FormControlLabel
        control={<Checkbox checked={hasMessagesOnly} onChange={(event) => setHasMessagesOnly(event.target.checked)} />}
        label="Hide Empty Chats"
      />
      <DataGridPro
        autoHeight
        columns={COLUMNS}
        density="compact"
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.chatId}
        loading={loading}
        onPaginationModelChange={({ page }) => setPage(page)}
        pageSizeOptions={[100]}
        pagination
        paginationMode="server"
        paginationModel={{ page: page, pageSize: SIZE_PER_PAGE }}
        rowCount={totalChats}
        rows={stats ?? []}
        slots={{ pagination: GridPagination }}
      />
    </>
  );
};
