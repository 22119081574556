import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Alert, Button } from '@mui/material';

import { downloadAgentSalesReport } from '~/services/ReportingService';
import { formatDateISO, startOfMonth, subMonths } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';
import PermissionedComponent from '../PermissionedComponent';

const formSchema: RJSFSchema = {
  description: 'This report shows sales attributed to Lux Group agents made in a specified date range',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    fileFormat: {
      title: 'File Format',
      type: 'string',
      enum: ['csv', 'email'],
    },
  },
};

const formData = {
  startAt: formatDateISO(startOfMonth(subMonths(1))),
  endAt: formatDateISO(startOfMonth()),
  fileFormat: 'csv',
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  fileFormat: {
    'ui:widget': 'radio',
  },
};

export default function ExportAgentSalesReportForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt, fileFormat } = event.formData;
    const fileName = `agent_sales_${startAt}_${endAt}.${fileFormat}`;

    downloadAgentSalesReport({ startAt, endAt, fileFormat })
      .then(function (text) {
        if (fileFormat !== 'email') {
          fileDownload(text, fileName);
        } else {
          setSuccessMessage(true);
        }
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <PermissionedComponent>
      <Form
        schema={formSchema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={handleSubmit}
        validator={validator}
        showErrorList={false}
      >
        <Button type="submit" variant="contained">
          Download Agent Sales Report
        </Button>

        {error && <ErrorDisplay message={error} />}

        {successMessage && (
          <Alert severity="success">
            <p>Your report is queued for delivery and will be emailed shortly.</p>
          </Alert>
        )}
      </Form>
    </PermissionedComponent>
  );
}
