import { convertValueByFX } from '~/utils/convertValueByFX';
import { isOfferTypeDynamicPricedHotel } from '~/utils/offer';

export const guestFullName = (reservation) => {
  return reservation.guest_first_name + ' ' + reservation.guest_last_name;
};

export const travellerFullName = (traveller) => {
  return traveller.first_name + ' ' + traveller.last_name;
};

export const getPropertyLink = (vendorId, roomType) => {
  return `/vendors/${vendorId}/properties/${roomType.property_id}`;
};

export const getRoomTypeLink = (vendorId, roomType, roomRate) => {
  const propertyLink = getPropertyLink(vendorId, roomType);
  return `${propertyLink}/room-types/${roomType.id}/room-rates/${roomRate.id}`;
};

export const getTourLink = (vendorId, offerPackage) => {
  return `/vendors/${vendorId}/tours/${offerPackage.fk_tour_id}`;
};

export const getTourOptionLink = (vendorId, offerPackage) => {
  return `${getTourLink(vendorId, offerPackage)}/tour-options/${offerPackage.fk_tour_option_id}/dates`;
};

export const getGuestName = (reservation: App.OrderItemReservation, traveller) => {
  if (!reservation) {
    return '';
  }

  return traveller && traveller.traveller_submitted ? travellerFullName(traveller) : guestFullName(reservation);
};

export const getRoomRate = (fullReservation, offerPackage) => {
  let roomRate = false;

  if (!roomRate) {
    const ratePlanName = fullReservation.rate_plan?.name;
    if (ratePlanName) {
      const ratePlan = offerPackage.room_type.room_rates.find((room_rate) => room_rate.rate_plan.name === ratePlanName);
      if (ratePlan !== undefined) {
        roomRate = ratePlan;
      }
    }
  }

  if (!roomRate) {
    roomRate = offerPackage.room_rate;
  }

  if (!roomRate) {
    roomRate = offerPackage.room_type.room_rates.find((room_rate) => room_rate.rate_plan.default_plan);
  }

  return roomRate;
};

export const canBookDates = (item) => {
  return (
    item.status !== 'cancelled' &&
    item.offer.booking_type === 'reservation' &&
    item.reservation_type === 'buy_now_book_later' &&
    !item.reservation
  );
};

export const formatChildrenAges = (children_ages: any[]) => {
  const ages = children_ages.map((age) => `${age}${age > 1 ? 'yrs' : 'yr'}`).join(', ');
  return `(${ages})`;
};

export const getBlackballCostPriceData = (offerPackage, item) => {
  const pkg = item.offer.packages.find((p) => p.fk_room_rate_id === offerPackage.fk_room_rate_id);
  if (pkg) {
    return {
      costPrice: pkg.cost_price,
      costCurrency: pkg.cost_currency,
    };
  }

  return {
    costPrice: 0,
    costCurrency: 'AUD', // Default costCurrency value
  };
};

export const getCostCurrencyCode = (offerPackage, item) => {
  let costCurrencyCode;

  if (isOfferTypeDynamicPricedHotel(item.offer)) {
    costCurrencyCode = item.reservation.room_rate_cost_currency;
  } else {
    costCurrencyCode = offerPackage.cost_currency;
    if (!costCurrencyCode) {
      const costPriceData = getBlackballCostPriceData(offerPackage, item);
      costCurrencyCode = costPriceData.costCurrency;
    }
  }

  if (!costCurrencyCode) {
    costCurrencyCode = 'AUD';
  }

  return String(costCurrencyCode);
};

export const getTotalPrice = (item) => item.total;

export const getTaxesAndFees = (item) => {
  if (item.reservation?.tax_breakdown?.length > 0) {
    return (
      item.reservation?.tax_breakdown.reduce((acc, tax) => acc + tax.sell, 0) +
      item.reservation?.room_rate_tax_sell_amount
    );
  }
  return item.taxes_and_fees + item.reservation?.room_rate_tax_sell_amount;
};

export const getCostTaxesAndFees = (item) => item.cost_taxes_and_fees + item.reservation?.room_rate_tax_cost_amount;

export const getPackageCostPrice = (offerPackage, item) => {
  if (isOfferTypeDynamicPricedHotel(item.offer)) {
    return Number(item.reservation?.room_rate_cost_amount);
  } else {
    let costPrice = offerPackage.cost_price;

    if (!costPrice) {
      const costPriceData = getBlackballCostPriceData(offerPackage, item);
      costPrice = costPriceData.costPrice;
    }

    if (offerPackage.nightly_cost_price) {
      costPrice = costPrice + offerPackage.nightly_cost_price * item.extra_nights;
    }

    return Number(costPrice);
  }
};

export const getChargedPrice = (offerPackage, item) => {
  const packagePrice =
    item.subscriber_tier && offerPackage.lux_plus_price > 0
      ? getMemberPrice(offerPackage, item)
      : getNonMemberPrice(offerPackage, item);
  return item.reservation?.room_rate_offer_amount ?? packagePrice;
};

export const getMemberPrice = (offerPackage, item) => {
  const basePackagePrice = offerPackage.lux_plus_price > 0 ? offerPackage.lux_plus_price : offerPackage.price;
  const nightlyPrice =
    offerPackage.lux_plus_nightly_price > 0 ? offerPackage.lux_plus_nightly_price : offerPackage.nightly_price;

  const extraNightsPrice = item.extra_nights > 0 ? item.extra_nights * nightlyPrice : 0;

  return basePackagePrice + extraNightsPrice;
};

export const getNonMemberPrice = (offerPackage, item) => {
  const basePackagePrice = offerPackage.price;
  const extraNightsPrice = item.extra_nights > 0 ? item.extra_nights * offerPackage.nightly_price : 0;
  return basePackagePrice + extraNightsPrice;
};

export const getTotalCostPrice = (packageCostPrice, reservation) => {
  const surchargeCostTotal = reservation?.surcharge_cost_total ?? 0;
  return packageCostPrice + surchargeCostTotal;
};

export const getChannelManagerRate = (item) => {
  if (isOfferTypeDynamicPricedHotel(item.offer)) {
    return Number(item.reservation?.room_rate_base_amount);
  }
};

export const convertToAUD = (fxRates, currencyCode, price) => {
  // If i want to convert from GBP to AUD i need to set fromCurrency to AUD. WHY?
  return convertValueByFX({
    fxRates,
    fromCurrency: 'AUD',
    toCurrency: currencyCode,
    value: price,
  });
};

export const copyToClipboard = (data: object) => {
  // filter out null values
  const parsedObject = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
  // convert bookingDetails into a multiline string
  const text = Object.entries(parsedObject)
    .map(([key, value]) => {
      if (value) {
        return `${key} ${value}`;
      }
    })
    .join('\r\n');

  navigator.clipboard.writeText(`Booking Details are as follows:\r\n${text}`);
};
