import React from 'react';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import Asinc from '../../Common/Asinc';

import TourLegsEdit from './TourLegsEdit';

export default class TourLegsEditContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.tourId = match.params.id_tour;
    this.vendorId = match.params.id_vendor;
  }

  async fetchData() {
    const [tour, schema, vendor] = await Promise.all([
      ReservationService.getTour(this.tourId),
      ReservationService.getTourLegsSchema(this.tourId),
      VendorsService.getVendorById(this.vendorId),
    ]);

    return {
      tour: tour.result,
      schema: schema.post.body.schema,
      vendor: vendor.result,
    };
  }

  render() {
    return (
      <Asinc
        promise={this.fetchData()}
        then={({ tour, schema, vendor }) => {
          const tourLegs = tour.tour_legs_count ? tour.tour_legs : [];

          return (
            <TourLegsEdit
              tourLegs={tourLegs}
              vendorId={this.vendorId}
              vendor={vendor}
              tourId={tour.id}
              tour={tour}
              schema={schema}
            />
          );
        }}
      />
    );
  }
}
