import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CudoLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 100 40">
      <defs>
        <path
          id="a"
          d="M87.24 34.07c-3 0-4.1-5.64-4.1-9.83 0-4.01.88-9 3.88-9 2.61 0 3.98 3.95 4.09 9 .1 4.4-.88 9.83-3.87 9.83zm11.75-9.55c.27 9.17-3.96 15.3-11.92 15.3-7.36 0-11.83-5.37-11.83-14.76 0-9.99 4.96-14.88 11.94-14.88 8.78 0 11.59 7.28 11.8 14.34zm-59.15-9.16c0-5.65 6.25-5.25 7.95-5.18v18.86c0 1.4-1.52 10.78-11.22 10.79-9.7 0-11.22-9.4-11.22-10.79V10.18c1.69-.07 7.94-.47 7.94 5.18v12.86c0 1.35.06 5.85 3.28 5.85 3.2 0 3.27-4.5 3.27-5.85V15.36zM16.96 29.68c1.42-1.57 4.81-1.32 6.52-.68 0 3.85-2.33 10.83-11.1 10.83C4.15 39.83 0 31.67 0 25.07s4.15-14.75 12.38-14.75c8.77 0 11.1 6.97 11.1 10.83-1.7.63-5.1.88-6.52-.68-.94-1.46-2.47-3.31-4.43-3.31-3.56 0-4.63 2.33-4.63 7.91S8.97 33 12.53 33c1.96 0 3.49-1.86 4.43-3.32zM65.4 5.62C65.4-.23 71.75.2 73.3.25v31.09c-.03 1.03 0 3.96.15 4.97-.08.43-6.67 3.51-10.82 3.51-9.14 0-13.07-5.86-13.07-14.24 0-8.28 4.25-15.73 11.64-15.73 2.23 0 3.4.66 4.2 1.1V5.6zm0 25.5V15.16a3.98 3.98 0 0 0-2.18-.72c-3.56 0-5.76 4.83-5.76 9.98s2.2 9.91 5.65 9.91c2.23 0 2.29-2.02 2.29-3.23z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g fill="#FFF" mask="url(#b)">
          <path d="M0 0h120v40H0z" />
        </g>
      </g>
    </SvgIcon>
  );
}
