import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import PageHeader from '~/components/Common/Elements/PageHeader';

interface Props {
  commissionableTaxes: Reservation.CommissionableTaxesAndFees[];
  apiCommissionableTaxes: Reservation.CommissionableTaxesAndFees[];
  isPropertyUpdating: boolean;
  updateProperty: (changes: Partial<Reservation.Property>) => void;
}

export default function RentalTaxesPane(props: Props) {
  const { commissionableTaxes, apiCommissionableTaxes, updateProperty, isPropertyUpdating } = props;
  const [taxes, setTaxes] = useState<Reservation.CommissionableTaxesAndFees[]>([]);
  const [allTaxesAndFeesAsNonCommssionable, setAllTaxesAndFeesAsNonCommssionable] = useState<boolean>(false);

  useEffect(() => {
    const apiCommissionableTaxesThatAreNotMapped: Reservation.CommissionableTaxesAndFees[] =
      apiCommissionableTaxes.filter((tax) => {
        return !commissionableTaxes.find((commissionableTax) => commissionableTax.content === tax.content);
      });
    setTaxes(commissionableTaxes.concat(apiCommissionableTaxesThatAreNotMapped));
  }, [apiCommissionableTaxes, commissionableTaxes]);

  const handleCheckbox = useCallback(
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newTaxes: Reservation.CommissionableTaxesAndFees[] = taxes.map((tax) => {
        if (tax.content === field) {
          return { ...tax, commissionable_type: e.target.checked ? 'commissionable' : 'non-commissionable' };
        }
        return tax;
      });
      setTaxes(newTaxes);
    },
    [taxes],
  );

  const handleAllTaxesAndFeesAsNonCommssionableCheckbox = useCallback(
    () => (e: React.ChangeEvent<HTMLInputElement>) => {
      setAllTaxesAndFeesAsNonCommssionable(!!e.target.checked);
    },
    [],
  );

  const doUpdate = useCallback(() => {
    updateProperty({
      commissionable_taxes_and_fees: taxes,
      all_taxes_and_fees_as_non_commssionable: allTaxesAndFeesAsNonCommssionable,
    });
  }, [taxes, allTaxesAndFeesAsNonCommssionable, updateProperty]);

  return (
    <Box>
      <PageHeader title="Taxes" />
      <Stack spacing={2} direction="column">
        {taxes.length === 0 && <Typography fontWeight="bold">This property has no taxes</Typography>}
        <FormControlLabel
          control={
            <Checkbox
              checked={allTaxesAndFeesAsNonCommssionable}
              onChange={handleAllTaxesAndFeesAsNonCommssionableCheckbox()}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Designate all taxes as non-commssionable"
        />
        {taxes.length > 0 && (
          <Box>
            <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
              {taxes.map((tax, index) => (
                <Stack key={index} direction="column" alignItems="flex-start">
                  <Typography fontWeight="bold" variant="h6">
                    {tax.content}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tax.commissionable_type === 'commissionable'}
                        onChange={handleCheckbox(tax.content)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Commissionable"
                  />
                </Stack>
              ))}
            </Stack>
          </Box>
        )}
        <Box>
          <Button variant="contained" disabled={isPropertyUpdating} onClick={doUpdate}>
            {isPropertyUpdating ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
