import React, { PropsWithChildren, createContext, useState } from 'react';

export interface TourDetails {
  title: string;
  id: string;
  vendorId?: string;
}

export interface TourDetailsTab {
  type: string;
  title: string;
}

interface TourDetailsState {
  tourDetails?: TourDetails;
  tabs: TourDetailsTab[];
  setTabs: (tabs: TourDetailsTab[]) => void;
  setTourDetails: (tourDetails: TourDetails) => void;
}

const TourDetailsContext = createContext<TourDetailsState>({
  tabs: [],
} as TourDetailsState);

export function TourDetailsContextProvider(props: PropsWithChildren<unknown>) {
  const { children } = props;
  const [tourDetails, setTourDetails] = useState<TourDetails>(undefined);
  const [tabs, setTabs] = useState<TourDetailsTab[]>([]);

  return (
    <TourDetailsContext.Provider value={{ tourDetails, tabs, setTabs, setTourDetails }}>
      {children}
    </TourDetailsContext.Provider>
  );
}

export default TourDetailsContext;
