import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import {
  customerDateChangeOptions,
  flightsEnableOptions,
  ledExperienceEnableOptions,
  searchableOfferTypesOptions,
} from '~/consts/offerFilterOptions';
import {
  BOOK_BY_DATE_START,
  CURRENT_LIVE,
  CURRENT_LIVE_OR_PREVIOUSLY_CONTRACTED,
  CURRENT_OFFLINE,
  FRONT_PAGE_END,
  FRONT_PAGE_START,
  PREVIOUS_LIVE,
} from '~/consts/offerFilterTypes';

import { addDays, formatDateLongISO } from '~/services/TimeService';

import { AvailabilityFilter, ScheduleFilter } from './OfferFilters';

type Props = {
  destinations: { value: string; label: string }[];
  searchQuery: (value: string) => void;
  minimal?: boolean;
  setLedExperience?: (value: boolean) => void;
};

export default function OfferSearchForm(props: Props) {
  const [error, setError] = useState('');

  const [locationsSelected, setLocationsSelected] = useState([]);
  const [offerTypesSelected, setOfferTypesSelected] = useState([]);

  //- Customer portal date change
  const [customerDateChangeEnabled, setCustomerDateChangeEnabled] = useState('');

  //- Flights
  const [flightsEnabled, setFlightsEnabled] = useState('');

  //- Experience Enabled search
  const [ledExperienceEnabled, setLedExperienceEnabled] = useState('');

  //- Schedule Filter
  const [scheduleFilters, setScheduleFilters] = useState([]);

  //- Availability Filters
  const [availabilityFilters, setAvailabilityFilters] = useState([]);

  const [searchString, setSearchString] = useState('');

  // experienceEnabled and all other filters are mutually exclusive
  useEffect(() => {
    if (
      availabilityFilters.length > 0 ||
      scheduleFilters.length > 0 ||
      locationsSelected.length > 0 ||
      offerTypesSelected.length > 0 ||
      customerDateChangeEnabled !== '' ||
      flightsEnabled !== ''
    ) {
      setLedExperienceEnabled('');
    }
  }, [
    availabilityFilters,
    scheduleFilters,
    locationsSelected,
    offerTypesSelected,
    customerDateChangeEnabled,
    flightsEnabled,
  ]);

  // Check if the filters are available
  function hasNoDuplicatedFilters() {
    const uniqueScheduleFilters = new Set(scheduleFilters.map((s) => s.filter));
    const uniqueAvailableFilters = new Set(availabilityFilters.map((s) => s.filter));

    if (
      uniqueScheduleFilters.size < scheduleFilters.length ||
      uniqueAvailableFilters.size < availabilityFilters.length
    ) {
      return false;
    }

    return true;
  }

  function hasNoConflictFilters() {
    const availabilityFiltersKey = availabilityFilters.map((x) => x.filter);
    if (
      (availabilityFiltersKey.includes(CURRENT_LIVE) && availabilityFiltersKey.includes(CURRENT_OFFLINE)) ||
      (availabilityFiltersKey.includes(CURRENT_LIVE_OR_PREVIOUSLY_CONTRACTED) && availabilityFiltersKey.length > 1)
    ) {
      return false;
    }

    return true;
  }

  function handleSubmit(e) {
    if (e.keyCode !== 13 && e.target.name !== 'offer-search-submit') {
      return;
    }
    e.preventDefault();

    setError('');

    if (props.minimal) {
      props.searchQuery(searchString);
      return;
    }

    const ledExperiencesAreEnabled = ledExperienceEnabled === 'true';
    // If Experiences is requested, set the experience to true/false
    // based on the dropdown
    if (props.setLedExperience) {
      props.setLedExperience(ledExperiencesAreEnabled);
    }

    // If Experiences is true, then only filter value q is to be set
    if (props.setLedExperience && ledExperiencesAreEnabled) {
      const query = `q=${searchString}`;
      props.searchQuery(query);
      return;
    }

    if (!hasNoDuplicatedFilters()) {
      setError('Duplicated Filters');
    } else if (!hasNoConflictFilters()) {
      setError('Currently Live In/Currently Available Has Conflict Filters');
    } else {
      const locations = locationsSelected.join(',');

      const locationQuery = locationsSelected.length ? `&locations=${locations}` : '';

      const offerTypes = offerTypesSelected.join(',');

      const offerTypeQuery = offerTypes.length ? `&type=${offerTypes}` : '';

      const scheduleQuery = getScheduleQuery();

      const availabilityQuery = getAvailabilityQuery();

      const query =
        `enable_customer_portal_date_change=${customerDateChangeEnabled}` +
        `&flights_enabled=${flightsEnabled}&q=${searchString}` +
        `${locationQuery}${offerTypeQuery}${scheduleQuery}${availabilityQuery}`;

      props.searchQuery(query);
    }
  }

  function getAvailabilityQuery() {
    let filterQuery = '';
    availabilityFilters.forEach((x) => {
      const { filter: filterName, brand, market } = x;
      if (filterName === CURRENT_LIVE) {
        filterQuery = filterQuery + `&filter=browsable&region=${market}&brand=${brand}`;
      }
      if (filterName === CURRENT_OFFLINE) {
        filterQuery = filterQuery + `&filter=offline`;
      }
      if (filterName === CURRENT_LIVE_OR_PREVIOUSLY_CONTRACTED) {
        filterQuery = filterQuery + `&filter=content-approved&${filterName}=${brand}, ${market}`;
      }
      if (filterName === PREVIOUS_LIVE) {
        filterQuery = filterQuery + `&${filterName}=${brand}, ${market}`;
      }
    });
    return filterQuery;
  }

  function getScheduleQuery() {
    const allScheduleFilters = scheduleFilters.map((x) => {
      const { filter: filterName, end, start, brand, market } = x;
      if (x.filter === FRONT_PAGE_START || x.filter === FRONT_PAGE_END) {
        return `${filterName}=${encodeURIComponent(start)},${encodeURIComponent(end)},${brand},${market}`;
      }
      return `${filterName}=${encodeURIComponent(start)},${encodeURIComponent(end)}`;
    });

    const scheduleQuery = allScheduleFilters.length ? '&' + allScheduleFilters.join('&') : '';

    return scheduleQuery;
  }

  function handleClearAllFilters() {
    setScheduleFilters([]);
    setAvailabilityFilters([]);
    setLocationsSelected([]);
    setOfferTypesSelected([]);
    setCustomerDateChangeEnabled('');
    setFlightsEnabled('');
    setLedExperienceEnabled('');
    setSearchString('');
    setError('');
  }

  /* Schedule Filter Change Handle */
  function handleAddScheduleFilter() {
    const newScheduleFilters = [
      ...scheduleFilters,
      {
        filter: BOOK_BY_DATE_START,
        start: formatDateLongISO(addDays(-1)),
        end: formatDateLongISO(),
        market: 'AU',
        brand: 'luxuryescapes',
      },
    ];
    setScheduleFilters(newScheduleFilters);
  }

  function handleScheduleFilterChange(index, key, value) {
    const newScheduleFilters = JSON.parse(JSON.stringify(scheduleFilters));
    newScheduleFilters[index][key] = value;
    setScheduleFilters(newScheduleFilters);
  }

  function removeScheduleFilter(filterIndex) {
    const newScheduleFilters = JSON.parse(JSON.stringify(scheduleFilters));
    newScheduleFilters.splice(filterIndex, 1);
    setScheduleFilters(newScheduleFilters);
  }

  /* Availability Filter Change Handle*/
  function handleAddAvailabilityFilter() {
    const newAvailabilityFilters = [
      ...availabilityFilters,
      {
        filter: CURRENT_LIVE,
        market: 'any',
        brand: 'luxuryescapes',
      },
    ];
    setAvailabilityFilters(newAvailabilityFilters);
  }

  function handleLedExperienceFilterChange(e) {
    // Experiences do not work in conjunction with other filter searches
    setScheduleFilters([]);
    setAvailabilityFilters([]);
    setLocationsSelected([]);
    setOfferTypesSelected([]);
    setCustomerDateChangeEnabled('');
    setFlightsEnabled('');

    setLedExperienceEnabled(e.target.value);
  }

  function handleAvailabilityFilterChange(index, key, value) {
    const newAvailabilityFilters = JSON.parse(JSON.stringify(availabilityFilters));
    if (value === CURRENT_OFFLINE) {
      newAvailabilityFilters[index].market = 'any';
      newAvailabilityFilters[index].brand = 'any';
      newAvailabilityFilters[index].filter = CURRENT_OFFLINE;
    } else {
      newAvailabilityFilters[index][key] = value;
    }
    setAvailabilityFilters(newAvailabilityFilters);
  }

  function removeAvailabilityFilter(filterIndex) {
    const newAvailabilityFilter = JSON.parse(JSON.stringify(availabilityFilters));
    newAvailabilityFilter.splice(filterIndex, 1);
    setAvailabilityFilters(newAvailabilityFilter);
  }

  if (props.minimal) {
    return (
      <Box onKeyDown={handleSubmit} display="flex" flexDirection="row" alignItems="center" gap={1} mb={2}>
        <TextField
          size="small"
          variant="outlined"
          id="OfferSearch"
          data-testid="OfferSearch"
          type="text"
          label="Search query"
          placeholder="Enter Offer Title / Offer ID / LE Offer ID / Vendor ID"
          className="data-hj-whitelist"
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          fullWidth
        />

        <Button variant="contained" onClick={handleSubmit} name="offer-search-submit">
          Search
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Stack direction="column" spacing={1} onKeyDown={handleSubmit}>
        <Typography variant="h5">Search for an offer</Typography>

        <Box>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField
                variant="outlined"
                id="OfferSearch"
                data-testid="OfferSearch"
                type="text"
                label="Search query"
                placeholder="Enter Offer Title / Offer ID / LE Offer ID / Vendor ID"
                className="data-hj-whitelist"
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
                fullWidth
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <TextField
                variant="outlined"
                label="Offer type"
                placeholder="Select one or more offer types"
                value={offerTypesSelected}
                SelectProps={{
                  multiple: true,
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={(e: SelectChangeEvent) => setOfferTypesSelected(e.target.value as unknown as string[])}
                select
                fullWidth
              >
                {searchableOfferTypesOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid xs={12} sm={6}>
              {props.destinations.length > 0 && (
                <Autocomplete
                  options={props.destinations}
                  value={locationsSelected}
                  renderInput={(params) => (
                    <TextField {...params} label="Destinations" placeholder="Select one or more destination" />
                  )}
                  onChange={(_, values: string[]) => setLocationsSelected(values)}
                  multiple
                />
              )}
            </Grid>

            <Grid container xs={12} md={6} spacing={2}>
              <Grid xs={6}>
                <TextField
                  variant="outlined"
                  label="Customer Portal Date Change"
                  value={customerDateChangeEnabled}
                  onChange={(e) => setCustomerDateChangeEnabled(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  select
                  fullWidth
                >
                  {customerDateChangeOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid xs={6}>
                <TextField
                  variant="outlined"
                  label="Flights enabled"
                  value={flightsEnabled}
                  onChange={(e) => setFlightsEnabled(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  select
                  fullWidth
                >
                  {flightsEnableOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {props.setLedExperience && (
              <Grid xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  label="LED Experience"
                  value={ledExperienceEnabled}
                  onChange={handleLedExperienceFilterChange}
                  select
                  fullWidth
                >
                  {ledExperienceEnableOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Box>

        <Typography pt={1} variant="h5">
          Schedule
        </Typography>

        {scheduleFilters.length > 0 && (
          <Box>
            <Grid container spacing={2}>
              {scheduleFilters.map((values, index) => (
                <ScheduleFilter
                  start={values.start}
                  end={values.end}
                  filter={values.filter}
                  brand={values.brand}
                  market={values.market}
                  idx={index}
                  key={index}
                  removeScheduleFilter={removeScheduleFilter}
                  handleFilterChange={handleScheduleFilterChange}
                />
              ))}
            </Grid>
          </Box>
        )}

        <Box>
          <Button variant="outlined" onClick={handleAddScheduleFilter} startIcon={<AddIcon />}>
            Add schedule
          </Button>
        </Box>

        <Typography pt={1} variant="h5">
          Availability
        </Typography>

        {availabilityFilters.length > 0 && (
          <Box>
            <Grid container spacing={2}>
              {availabilityFilters.map((values, index) => (
                <AvailabilityFilter
                  filter={values.filter}
                  brand={values.brand}
                  market={values.market}
                  idx={index}
                  key={index}
                  removeAvailabilityFilter={removeAvailabilityFilter}
                  handleFilterChange={handleAvailabilityFilterChange}
                />
              ))}
            </Grid>
          </Box>
        )}

        <Box>
          <Button variant="outlined" onClick={handleAddAvailabilityFilter} startIcon={<AddIcon />}>
            Add availability
          </Button>
        </Box>

        {error && (
          <Alert severity="warning" onClose={() => setError('')}>
            {error}
          </Alert>
        )}

        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button variant="text" data-testid="clear-all-filters" onClick={handleClearAllFilters}>
            Clear all filters
          </Button>

          <Button variant="contained" name="offer-search-submit" onClick={handleSubmit} className="T-search-offers">
            Search
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
