/**
 * This file contains the mappers for the debug modal
 * each mapper should receive the props.data & be named exactly by the props.type in the debug modal
 */
type Mapper = (data: any) => any;
type Mappers = {
  [key: string]: Mapper;
};

const maps: Mappers = {
  generic: (data) => data,
  offer: (data) => {
    const {
      id_salesforce_external,
      vendor_account_id,
      packages,
      name,
      travel_from_date,
      travel_to_date,
      book_by_date,
    } = data;
    return {
      name,
      id_salesforce_external,
      vendor_account_id,
      book_by_date,
      travel_from_date,
      travel_to_date,
      packages: (packages ?? []).map((p) => ({
        name: p.name,
        le_package_id: p.le_package_id,
        salesforce_id: p.id_salesforce_external,
        status: p.status,
        prices: p.prices_count,
        aris: {
          __html: `<a href="/vendors/${vendor_account_id}/properties/${p.fk_property_id}/room-types/${p.fk_room_type_id}/room-rates/${p.fk_room_rate_id}" target="_blank">View Room Rate Availability</a>`,
        },
      })),
    };
  },
  discountItems: (data) => {
    if (!data) {
      return [];
    }
    if (!data.discountItems || !data.discountItems.length) {
      return [];
    }
    return data.discountItems
      .map((d) => ({
        discountAmount: d.discountAmount,
        discountableTotal: d.item.reqItem.discountableTotal,
        offerId: d.item.reqItem.offerId,
        productBK: d.item?.poffer?.productBK,
        categoryBK: d.item?.poffer?.categoryBK,
        subCategoryBK: d.item?.poffer?.subCategoryBK,
      }))
      .reduce((acc, item, idx) => {
        const key = item.productBK || item.categoryBK;
        acc[`${idx}) ${key}`] = item;
        return acc;
      }, {});
  },
  property: (data) => {
    const {
      id,
      name,
      id_salesforce_external,
      room_types,
      channel_manager,
      hotel_code,
      type,
      has_availability,
      category,
    } = data;
    return {
      id,
      name,
      hotel_code,
      type,
      vendorId: id_salesforce_external,
      channel_manager,
      has_availability,
      category,
      room_types: room_types?.map((rt) => ({
        name: rt.name,
        id: rt.id,
        availability: rt.availability,
        room_rates: rt.room_rates?.map((r) => ({
          capacities: rt.capacities,
          name: r.name,
          id: r.id,
          rate_plan: {
            name: r.rate_plan.name,
            id: r.rate_plan.id_salesforce_external,
            type: r.rate_plan.rate_type,
            product_type: r.rate_plan.product_type,
          },
        })),
      })),
    };
  },
};

export default maps;
