import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ResponsiveImage from '~/components/Common/Elements/ResponsiveImage';

import PropertyMap from '../../Common/PropertyMap';
import formatGeoData from '../helpers/formatGeoData';

export default function TourPane(props) {
  return (
    <div id={props.tour.id}>
      <PageSubheader title="Tour details">
        <div>
          <Button component={Link} to={`/vendors/${props.vendorId}/edit-tour/${props.tour.id}`} variant="text">
            Edit
          </Button>
        </div>
      </PageSubheader>

      <Box display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
        <Box gridColumn="1 / span 3">
          <Box display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
            <div>
              <DefinitionTitle>Title</DefinitionTitle>
              <DefinitionText>{props.tour.name}</DefinitionText>
            </div>

            <div>
              <DefinitionTitle>Location description</DefinitionTitle>
              <DefinitionText>{props.tour.location_description}</DefinitionText>
            </div>

            <div></div>
            <div></div>

            <Box gridColumn="1 / span 2">
              <DefinitionTitle>Minimum number of travellers for guaranteed tour departure</DefinitionTitle>
              <DefinitionText>{props.tour.min_pax_count}</DefinitionText>
            </Box>

            <div></div>
            <div></div>

            <div>
              <DefinitionTitle>Latitude</DefinitionTitle>
              <DefinitionText>{props.tour.latitude}</DefinitionText>
            </div>

            <div>
              <DefinitionTitle>Longitude</DefinitionTitle>
              <DefinitionText>{props.tour.longitude}</DefinitionText>
            </div>

            <Box gridColumn="1 / span 2">
              <DefinitionTitle>Geolocation Data</DefinitionTitle>
              <DefinitionText>{formatGeoData(props.tour.geo_data, 'tour')}</DefinitionText>
            </Box>
          </Box>
        </Box>

        <div>
          {props.tour.logo_id && (
            <>
              <DefinitionTitle>Logo</DefinitionTitle>
              <ResponsiveImage imageId={props.tour.logo_id} width={360} height={240} />
            </>
          )}
        </div>
      </Box>

      <PropertyMap lat={props.tour.latitude} lng={props.tour.longitude} />
    </div>
  );
}
