import React, { useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';

import { MappedAuditLog } from '~/services/AuditService';

import { AuditLog } from './AuditLog';
import { SurchargeDateBlockContent } from './SurchargeDateBlockContent';

type Props = {
  surchargeDateBlocks: MappedAuditLog[];
};

export default function SurchargeDateBlockHistory({ surchargeDateBlocks }: Props) {
  const firstLog = surchargeDateBlocks[0];

  const summaryLog = useMemo(() => {
    if (firstLog.action === 'CREATE') return firstLog.diff.added;
    return JSON.parse(firstLog.meta).returnedValue;
  }, [firstLog.action, firstLog.diff.added, firstLog.meta]);

  return (
    <Accordion disableGutters sx={{ paddingLeft: '2em' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <SurchargeDateBlockContent surchargeDateBlock={summaryLog} />
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {surchargeDateBlocks.map((block) => {
            switch (block.action) {
              case 'DELETE':
                return <AuditLog key={block.logId} auditLog={block} />;
              case 'CREATE':
                return (
                  <AuditLog key={block.logId} auditLog={block}>
                    <SurchargeDateBlockContent surchargeDateBlock={block.diff.added} />
                  </AuditLog>
                );
              case 'UPDATE':
                return (
                  <AuditLog key={block.logId} auditLog={block}>
                    <SurchargeDateBlockContent surchargeDateBlock={JSON.parse(block.meta).returnedValue} />
                  </AuditLog>
                );
            }
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
