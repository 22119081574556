import { ValueOf } from 'type-fest';

export enum CustomerCommunicationActionTypes {
  // OFFER VISIBILITIES
  SET_OFFER_VISIBILITIES_FETCHING = 'customer-comm/SET_OFFER_VISIBILITIES_FETCHING',
  SET_OFFER_VISIBILITIES_FULFILLED = 'customer-comm/SET_OFFER_VISIBILITIES_FULFILLED',
  SET_OFFER_VISIBILITIES_REJECTED = 'customer-comm/SET_OFFER_VISIBILITIES_REJECTED',

  // BRANDS
  SET_BRANDS_FETCHING = 'customer-comm/SET_BRANDS_FETCHING',
  SET_BRANDS_FULFILLED = 'customer-comm/SET_BRANDS_FULFILLED',
  SET_BRANDS_REJECTED = 'customer-comm/SET_BRANDS_REJECTED',

  // OFFER DETAILS
  SET_OFFER_DETAILS_FETCHING = 'customer-comm/SET_OFFER_DETAILS_FETCHING',
  SET_OFFER_DETAILS_FULFILLED = 'customer-comm/SET_OFFER_DETAILS_FULFILLED',
  SET_OFFER_DETAILS_REJECTED = 'customer-comm/SET_OFFER_DETAILS_REJECTED',

  // COUNTRY GROUPS
  SET_COUNTRY_GROUPS_FETCHING = 'customer-comm/SET_COUNTRY_GROUPS_FETCHING',
  SET_COUNTRY_GROUPS_FULFILLED = 'customer-comm/SET_COUNTRY_GROUPS_FULFILLED',
  SET_COUNTRY_GROUPS_REJECTED = 'customer-comm/SET_COUNTRY_GROUPS_REJECTED',

  // COUNTRIES
  SET_COUNTRY_GROUP_COUNTRIES_FETCHING = 'customer-comm/SET_COUNTRY_GROUP_COUNTRIES_FETCHING',
  SET_COUNTRY_GROUP_COUNTRIES_FULFILLED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRIES_FULFILLED',
  SET_COUNTRY_GROUP_COUNTRIES_REJECTED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRIES_REJECTED',

  // STATES
  SET_COUNTRY_GROUP_COUNTRY_STATES_FETCHING = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATES_FETCHING',
  SET_COUNTRY_GROUP_COUNTRY_STATES_FULFILLED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATES_FULFILLED',
  SET_COUNTRY_GROUP_COUNTRY_STATES_REJECTED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATES_REJECTED',

  // CITIES
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FETCHING = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FETCHING',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FULFILLED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FULFILLED',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_REJECTED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_REJECTED',

  // SEGMENTS
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FETCHING = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FETCHING',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FULFILLED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FULFILLED',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_REJECTED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_REJECTED',

  // CADENCES
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FETCHING = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FETCHING',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FULFILLED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FULFILLED',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_REJECTED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_REJECTED',

  // MEMBERSHIPS
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FETCHING = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FETCHING',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FULFILLED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCESMEMBERSHIPS__MEMBERSHIPS_FULFILLED',
  SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_REJECTED = 'customer-comm/SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_MMEMBERSHIPS_EMBERSHIPS_REJECTED',
}

type OfferVisibilitiesParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['offerVisibilitiesRecords']>['params']
>;

type OfferDetailsParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['offerDetailsRecords']>['params']
>;
type CountryGroupParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['countryGroupRecords']>['params']
>;
type CountryGroupCountryParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountriesRecords']>['params']
>;
type CountryGroupCountryStateParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStatesRecords']>['params']
>;
type CountryGroupCountryStateCityParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitiesRecords']>['params']
>;
type CountryGroupCountryStateCitySegmentParams = NonNullable<
  ValueOf<
    CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitySegmentsRecords']
  >['params']
>;
type CountryGroupCountryStateCitySegmentCadenceParams = NonNullable<
  ValueOf<
    CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitySegmentCadencesRecords']
  >['params']
>;
type CountryGroupCountryStateCitySegmentCadenceMembershipParams = NonNullable<
  ValueOf<
    CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitySegmentCadenceMembershipsRecords']
  >['params']
>;

type CustomerCommunicationAction =
  // OFFER VISIBILITIES
  | {
      type: CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_FETCHING;
      params: OfferVisibilitiesParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_FULFILLED;
      params: OfferVisibilitiesParams;
      offerVisibilities: { [countryId: string]: CustomerCommunication.HeroPlannerOfferVisibility };
    }
  | {
      type: CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_REJECTED;
      params: OfferVisibilitiesParams;
      error: string;
    }

  // BRANDS
  | {
      type: CustomerCommunicationActionTypes.SET_BRANDS_FETCHING;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_BRANDS_FULFILLED;
      brands: Array<CustomerCommunication.HeroPlannerBrand>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_BRANDS_REJECTED;
      error: string;
    }

  // OFFER DETAILS
  | {
      type: CustomerCommunicationActionTypes.SET_OFFER_DETAILS_FETCHING;
      params: OfferDetailsParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_OFFER_DETAILS_FULFILLED;
      params: OfferDetailsParams;
      offerDetails: CustomerCommunication.HeroPlannerOfferDetails;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_OFFER_DETAILS_REJECTED;
      params: OfferDetailsParams;
      error: string;
    }
  // COUNTRY GROUPS
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_FETCHING;
      params: CountryGroupParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_FULFILLED;
      params: CountryGroupParams;
      countryGroups: Array<CustomerCommunication.HeroPlannerCountryGroup>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_REJECTED;
      params: CountryGroupParams;
      error: string;
    }
  // COUNTRIES
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_FETCHING;
      params: CountryGroupCountryParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_FULFILLED;
      params: CountryGroupCountryParams;
      countries: Array<CustomerCommunication.HeroPlannerCountry>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_REJECTED;
      params: CountryGroupCountryParams;
      error: string;
    }
  // STATES
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATES_FETCHING;
      params: CountryGroupCountryStateParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATES_FULFILLED;
      params: CountryGroupCountryStateParams;
      countryStates: Array<CustomerCommunication.HeroPlannerCountryState>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATES_REJECTED;
      params: CountryGroupCountryStateParams;
      error: string;
    }
  // CITIES
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FETCHING;
      params: CountryGroupCountryStateCityParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FULFILLED;
      params: CountryGroupCountryStateCityParams;
      stateCities: Array<CustomerCommunication.HeroPlannerCity>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_REJECTED;
      params: CountryGroupCountryStateCityParams;
      error: string;
    }
  // SEGMENTS
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FETCHING;
      params: CountryGroupCountryStateCitySegmentParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FULFILLED;
      params: CountryGroupCountryStateCitySegmentParams;
      citySegments: Array<CustomerCommunication.HeroPlannerStateSegment>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_REJECTED;
      params: CountryGroupCountryStateCitySegmentParams;
      error: string;
    }

  // CADENCES
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FETCHING;
      params: CountryGroupCountryStateCitySegmentCadenceParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FULFILLED;
      params: CountryGroupCountryStateCitySegmentCadenceParams;
      cadences: Array<CustomerCommunication.HeroPlannerCadence>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_REJECTED;
      params: CountryGroupCountryStateCitySegmentCadenceParams;
      error: string;
    }

  // MEMBERSHIPS
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FETCHING;
      params: CountryGroupCountryStateCitySegmentCadenceMembershipParams;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FULFILLED;
      params: CountryGroupCountryStateCitySegmentCadenceMembershipParams;
      memberships: Array<CustomerCommunication.HeroPlannerMembership>;
    }
  | {
      type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_REJECTED;
      params: CountryGroupCountryStateCitySegmentCadenceMembershipParams;
      error: string;
    };

export default CustomerCommunicationAction;
