const mergeRoomPolicyNames = (packages = [], propertyData = []) => {
  const roomPolicies = [];
  const roomTypeDetails = propertyData.map((property) => property.room_types).flat();

  for (const pkg of packages) {
    if (roomPolicies.some((roomPolicy) => roomPolicy.room_type_id === pkg.fk_room_type_id)) {
      continue;
    }

    const roomTypeName = roomTypeDetails.find((roomType) => roomType && roomType.id === pkg.fk_room_type_id)?.name;

    roomPolicies.push({
      id: pkg.fk_room_policy_id || null,
      room_type_id: pkg.fk_room_type_id,
      room_type_name: roomTypeName,
      description: pkg.room_policy_description || '',
    });
  }
  return roomPolicies;
};

export default mergeRoomPolicyNames;
