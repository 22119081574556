import React, { useCallback, useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import { SEARCH_FILTERS_DEFAULT, SearchFilters } from './constants';

type Props = {
  querySearchFilters: SearchFilters;
  onSearch: (params: SearchFilters) => void;
};

export default function BookingSearchFilters(props: Props) {
  const { querySearchFilters, onSearch } = props;

  const [searchFilters, setSearchFilter] = useState<SearchFilters>({
    ...SEARCH_FILTERS_DEFAULT,
    ...querySearchFilters,
  });

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();

      if (event?.target && event?.target?.name) {
        setSearchFilter((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    },
    [setSearchFilter],
  );

  const handleSearch = useCallback(() => {
    onSearch({
      sessionId: searchFilters.sessionId,
      bookingNumber: searchFilters.bookingNumber,
      customerId: searchFilters.customerId,
      orderId: searchFilters.orderId,
      departureId: searchFilters.departureId,
    });
  }, [searchFilters, onSearch]);

  return (
    <Box my={4} display="flex" flexDirection="column" gap={2}>
      <Stack display="flex" direction="row" gap={2}>
        <TextField
          fullWidth
          name="bookingNumber"
          label="Booking Number"
          onChange={handleChangeField}
          value={searchFilters.bookingNumber}
        />
        <TextField
          fullWidth
          name="sessionId"
          label="Session Id"
          onChange={handleChangeField}
          value={searchFilters.sessionId}
        />
        <TextField
          fullWidth
          name="orderId"
          label="Order ID"
          onChange={handleChangeField}
          value={searchFilters.orderId}
        />
        <TextField
          fullWidth
          name="customerId"
          label="Customer ID"
          onChange={handleChangeField}
          value={searchFilters.customerId}
        />
        <TextField
          fullWidth
          name="departureId"
          label="Departure ID"
          onChange={handleChangeField}
          value={searchFilters.departureId}
        />
        <Box>
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
