import React, { ComponentProps, useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Stack } from '@mui/material';

import AccommodationPropertiesResultsTable, {
  AccommodationPropertiesResultsTableRef,
} from './components/AccommodationPropertiesResultsTable';
import AccommodationPropertiesSearchForm, {
  AccommodationPropertiesSearchInput,
} from './components/AccommodationPropertiesSeachForm';

function getSearchInputFromSearchParams(searchParams: URLSearchParams): AccommodationPropertiesSearchInput {
  const input: AccommodationPropertiesSearchInput = {};
  const status = searchParams.get('status');
  if (status) {
    input.status = status;
  }
  const propertyName = searchParams.get('propertyName');
  if (propertyName) {
    input.propertyName = propertyName;
  }
  const id = searchParams.get('id');
  if (id) {
    input.id = id;
  }

  return input;
}

export default function AccommodationProperties() {
  const history = useHistory();
  const location = useLocation();
  const propertiesTableRef = React.createRef<AccommodationPropertiesResultsTableRef>();

  const handleSearchFormSubmission = useCallback<ComponentProps<typeof AccommodationPropertiesSearchForm>['onSubmit']>(
    (formObject) => {
      const urlSearchParamsToPush = new URLSearchParams(formObject);
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [history],
  );

  const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

  const { searchInput, pageNumber } = useMemo(() => {
    const pageFromSearchParams = urlSearchParams.get('page');
    return {
      searchInput: getSearchInputFromSearchParams(urlSearchParams),
      pageNumber: pageFromSearchParams ? Number(pageFromSearchParams) : 1,
    };
  }, [urlSearchParams]);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
      if (pageIndex) {
        urlSearchParamsToPush.set('page', String(pageIndex + 1));
      } else {
        urlSearchParamsToPush.delete('page');
      }
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [urlSearchParams, history],
  );

  const refreshProperties = useCallback(() => {
    propertiesTableRef.current?.refresh();
  }, [propertiesTableRef]);

  return (
    <Stack direction="column" spacing={2}>
      <AccommodationPropertiesSearchForm
        initialInput={searchInput}
        onSubmit={handleSearchFormSubmission}
        refreshProperties={refreshProperties}
      />
      <AccommodationPropertiesResultsTable
        ref={propertiesTableRef}
        searchInput={searchInput}
        pageIndex={pageNumber - 1}
        onPageChange={handlePageChange}
      />
    </Stack>
  );
}
