export const STATUS = ['ALL', 'DRAFT', 'APPROVED'];

export const VENDORS = [
  'Azamara',
  'Carnival Cruise Line',
  'Celebrity Cruises',
  'Cunard',
  'Disney Cruise Line',
  'Explora Journeys',
  'Holland America Line',
  'Hurtigruten Cruise Line',
  'MSC Cruises',
  'Norwegian Cruise Line',
  'Oceania Cruises',
  'P&O Cruises Australia',
  'Princess Cruises',
  'Regent Seven Seas Cruises',
  'Royal Caribbean International',
  'Seabourn Cruise Line',
  'Silversea Cruises',
  'Virgin Voyages',
];
