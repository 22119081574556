import React from 'react';

import ReactJson from '@microlink/react-json-view';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { retrievePNRBookingDetails } from '~/services/FlightsService';

const ToggleButton = ({ handleClick }) => {
  return (
    <Button onClick={handleClick} endIcon={<ExpandMoreIcon />}>
      View JSON
    </Button>
  );
};

const JsonViewer = ({ json }) => {
  return <ReactJson collapsed={3} src={json} />;
};

export default class Log extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      isOpen: false,
      isLoading: false,
      json: [],
    };
  }

  async handleClick() {
    const isOpen = !this.state.isOpen;

    this.setState({
      isOpen,
      isLoading: isOpen,
    });

    if (!isOpen) {
      return;
    }

    const json = await this.getPnrJson();

    this.setState({
      json,
      isLoading: false,
    });
  }

  async getPnrJson() {
    const { pnrId, region, provider } = this.props;

    try {
      const pnrDetails = await retrievePNRBookingDetails({
        pnrId,
        region,
        provider,
      });
      return pnrDetails;
    } catch (error) {
      return {
        type: 'Error in fetching PNR',
      };
    }
  }

  render() {
    const { isOpen, isLoading, json } = this.state;

    if (!isOpen) {
      return (
        <div>
          <ToggleButton isOpen={isOpen} handleClick={this.handleClick} />
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <ToggleButton isOpen={isOpen} handleClick={this.handleClick} />
          <Spinner />
        </div>
      );
    }

    return (
      <div>
        <ToggleButton isOpen={isOpen} handleClick={this.handleClick} />
        <JsonViewer json={json} />
      </div>
    );
  }
}
