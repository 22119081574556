import * as libRegions from '@luxuryescapes/lib-regions';

export function mapCountryCodeToRegion(code: string) {
  const regions = libRegions.getRegions();
  const correctRegion = regions.filter((region) => {
    return code === region.code;
  });
  if (correctRegion.length) {
    return correctRegion[0];
  }
}
