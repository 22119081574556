import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { Box, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ImagesForm from '~/components/Common/Forms/ImagesForm';

import portService from '~/services/cruises/PortService';

interface Props {
  port: API.Port;
}

const PortForm = ({ port }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [images, setImages] = useState([]);

  const { portId } = useParams<{ portId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const onAddImage = useCallback(
    async (imageId: string) => {
      const maxOrder = Math.max(...images.map((image) => image.order));
      const order = images.length > 0 && maxOrder > 0 ? maxOrder + 1 : 1;

      return portService.createPortImage(portId, { imageId, order });
    },
    [portId, images],
  );

  const onUpdateImages = useCallback(
    async (newImageList) => {
      const updatePayload = newImageList.map((image) => ({
        id: image.id,
        imageId: image.imageId,
        order: image.order,
        description: image.title,
      }));

      await portService.updatePortImages(portId, updatePayload);
    },
    [portId],
  );

  const onDeleteImage = useCallback(
    async (imageId) => {
      await portService.deletePortImage(portId, imageId);
      enqueueSnackbar('Port image deleted with success', { variant: 'success' });
    },
    [enqueueSnackbar, portId],
  );

  const fetchPortImages = useCallback(async () => {
    const { result: images } = await portService.getPortImages(portId);

    setImages(
      images
        .map((image) => ({
          ...image,
          id: image.id,
          publicImageId: image.imageId,
          title: image.description,
        }))
        .sort((a, b) => a.order - b.order),
    );
    setIsReady(true);
  }, [portId]);

  useEffect(() => {
    fetchPortImages();
  }, [fetchPortImages]);

  return (
    <Box>
      <Box>
        <TextField fullWidth label="Name" value={port.name} disabled />
      </Box>

      {isReady && (
        <Box mt={2}>
          <PageSubheader title="Port Images" />

          <ImagesForm
            images={images}
            onAddImage={onAddImage}
            onUpdateImages={onUpdateImages}
            onDeleteImage={onDeleteImage}
          />
        </Box>
      )}
    </Box>
  );
};

export default PortForm;
