import React, { useCallback, useState } from 'react';

import dayjs from '~/timeInit';

import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPagination } from '@mui/x-data-grid';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';
import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Image from '~/components/Common/Image';
import SetRegionModal from '~/components/Common/Modals/SetRegionModal';
import { Badge } from '~/components/Marketing/Badges/BadgesForm';

import TENANTS from '~/consts/tenant';

import { removeBadgeFromOffer, updateOfferBadge } from '~/services/BadgesService';
import { addDuration, addHours, formatDateLongISO, formatDateWithClock, startOfDay } from '~/services/TimeService';

import { reportError } from '~/utils/reportError';

interface BadgesContainerProps {
  onUpdate: () => void;
  badges: Array<Badge>;
  offerId: string;
}

const COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Badge Name',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'tag_text',
    headerName: 'Badge Text',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'tag_tooltip',
    headerName: 'Badge tooltip',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'updated_at',
    headerName: 'Date Modified',
    flex: 1,
    valueFormatter: (value) => formatDateWithClock(value) ?? '',
    display: 'flex',
  },
  {
    field: 'image',
    headerName: 'Image',
    flex: 1,
    renderCell: (params) => {
      return <Image className="img-responsive" publicId={params.row.image} options={{ width: 170 }} />;
    },
    display: 'flex',
  },
  {
    field: 'regions',
    headerName: 'Regions',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => params.row.regions?.map((region) => region).join(', '),
    display: 'flex',
  },
  {
    field: 'brands',
    headerName: 'Brands',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => params.row.brands?.map((brand) => brand).join(', '),
    display: 'flex',
  },
  {
    field: 'start',
    headerName: 'Schedule Start',
    flex: 1,
    valueFormatter: (value) => (value ? formatDateWithClock(value) : ''),
    display: 'flex',
  },
  {
    field: 'end',
    headerName: 'Schedule End',
    flex: 1,
    valueFormatter: (value) => (value ? formatDateWithClock(value) : ''),
    display: 'flex',
  },
];

const tenantEntries = Object.values(TENANTS);

export default function OfferBadgesList({ onUpdate, badges, offerId }: BadgesContainerProps) {
  const deleteBadge = (badge) => {
    removeBadgeFromOffer(badge.id, offerId).then(() => {
      onUpdate();
    });
  };
  const [currentBadge, setCurrentBadge] = useState(null);
  const [regionsModalState, setRegionsModalState] = useState(false);
  const [regionsModalError, setRegionsModalError] = useState(null);
  const [error, setError] = useState(null);
  const [badgeSchedule, setBadgeSchedule] = useState({
    isScheduled: false,
    scheduleStart: '',
    scheduleEnd: '',
  });
  const [brands, setBrands] = useState([]);

  const handleChangeBrand = useCallback(
    (brand) => {
      if (brands.includes(brand)) {
        setBrands(brands.filter((b) => b !== brand));
      } else {
        setBrands([...brands, brand]);
      }
    },
    [brands],
  );

  const toggleRegionsModal = (badge: Badge = null) => {
    setBadgeSchedule({
      isScheduled: badge?.is_scheduled,
      scheduleStart: badge?.start || formatDateLongISO(addHours(6, startOfDay())),
      scheduleEnd:
        badge?.end ||
        formatDateLongISO(
          addDuration(
            {
              days: 1,
              hours: 23,
              minutes: 59,
            },
            startOfDay(),
          ),
        ),
    });
    setCurrentBadge((!regionsModalState && badge) || null);
    if (badge?.brands) {
      setBrands([...badge.brands]);
    }
    setRegionsModalState(!regionsModalState);
  };

  const setRegions = (regions) => {
    setRegionsModalError(null);
    const badgeId = currentBadge.id;

    const schedules = getSchedules();

    if (schedules.is_scheduled && !dayjs(schedules.end).isAfter(schedules.start)) {
      setRegionsModalError({
        message: 'Schedule end should be after schedule start',
      });
      return;
    }
    toggleRegionsModal();
    updateOfferBadge(offerId, badgeId, regions, schedules, brands)
      .then(() => {
        onUpdate();
      })
      .catch((e) => {
        reportError(e);
        if (e.name === 'ValidationError') {
          e = e.errors.shift();
        }
        setError(e);
      })
      .finally(() => setRegionsModalError(null));
  };

  const setScheduled = () => {
    setBadgeSchedule({
      ...badgeSchedule,
      isScheduled: !badgeSchedule.isScheduled,
    });
  };

  const updateScheduleStart = (scheduleStart) => {
    setBadgeSchedule({
      ...badgeSchedule,
      scheduleStart,
    });
  };

  const updateScheduleEnd = (scheduleEnd) => {
    setBadgeSchedule({
      ...badgeSchedule,
      scheduleEnd,
    });
  };

  const getSchedules = () => {
    if (badgeSchedule.isScheduled) {
      return {
        is_scheduled: badgeSchedule.isScheduled,
        start: badgeSchedule.scheduleStart,
        end: badgeSchedule.scheduleEnd,
      };
    }
    return {
      is_scheduled: badgeSchedule.isScheduled,
    };
  };

  const columns: GridColDef[] = [
    ...COLUMNS,
    {
      field: 'edit',
      headerName: 'Edit Badge',
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="column">
            <Button onClick={() => toggleRegionsModal(params.row)}>Edit Badge</Button>
            <ConfirmButton onConfirm={() => deleteBadge(params.row)}>Delete</ConfirmButton>
          </Stack>
        );
      },
      display: 'flex',
    },
  ];

  return (
    <Box>
      {error && <ErrorDisplay message={error.message} />}
      <DataGrid
        rows={badges}
        columns={columns}
        autoHeight
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.name}
        slots={{
          pagination: GridPagination,
        }}
        onRowClick={(params) => toggleRegionsModal(params.row)}
      />
      {regionsModalState && (
        <SetRegionModal
          open={regionsModalState}
          onRequestClose={toggleRegionsModal}
          onSet={setRegions}
          regions={currentBadge?.regions || []}
          name="Package Regions"
          label="Tag all regions that you want this package to be visible in"
          saveButton="Save Badge"
        >
          <Stack direction="column" spacing={2}>
            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox checked={badgeSchedule.isScheduled} onChange={() => setScheduled()} />}
                  label="Enable Schedules"
                />
              </Grid>
            </Grid>
            <Grid container columnGap={2} rowGap={2}>
              <Grid item sm>
                <DateTimeWidget
                  disabled={!badgeSchedule.isScheduled}
                  value={badgeSchedule.scheduleStart}
                  format="DD/MM/YYYY hh:mm a"
                  onChange={updateScheduleStart}
                />
              </Grid>
              <Grid item sm>
                <DateTimeWidget
                  disabled={!badgeSchedule.isScheduled}
                  value={badgeSchedule.scheduleEnd}
                  format="DD/MM/YYYY hh:mm a"
                  onChange={updateScheduleEnd}
                  timeSteps={{ minutes: 1 }}
                />
              </Grid>
              {regionsModalError && <ErrorDisplay message={regionsModalError.message} />}
            </Grid>
            <Divider />
            <Typography variant="h6">Brands</Typography>
            <Stack direction="row" useFlexGap flexWrap="wrap">
              {tenantEntries.map((tenant) => (
                <FormGroup key={tenant.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={currentBadge?.brands?.includes(tenant.brand)}
                        defaultChecked={currentBadge?.brands?.includes(tenant.brand)}
                        onChange={() => handleChangeBrand(tenant.brand)}
                      />
                    }
                    label={tenant.title}
                  />
                </FormGroup>
              ))}
            </Stack>
            <Divider />
          </Stack>
        </SetRegionModal>
      )}
    </Box>
  );
}
