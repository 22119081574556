import React, { useCallback, useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import { SearchValues } from '../types';

const initialSearchState: SearchValues = {
  rateCode: '',
  vendorCode: '',
  promotionName: '',
};

type Props = {
  loading: boolean;
  onSearch: (searchValues: SearchValues) => void;
};

const RateCodeManagementSearch = (props: Props): JSX.Element => {
  const { loading, onSearch } = props;
  const [searchValues, setSearchValues] = useState<SearchValues>(initialSearchState);

  const handleChangeField = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();

      if (e?.target && e?.target?.name) {
        setSearchValues({
          ...searchValues,
          [e.target.name]: e.target.value,
        });
      }
    },
    [searchValues],
  );

  const handleSearch = useCallback(() => {
    onSearch({
      rateCode: searchValues.rateCode,
      vendorCode: searchValues.vendorCode,
      promotionName: searchValues.promotionName,
    });
  }, [searchValues, onSearch]);

  return (
    <Stack direction="row" spacing={2}>
      <Box flexGrow={2}>
        <TextField
          fullWidth
          name="rateCode"
          label="Rate Code"
          value={searchValues.rateCode}
          onChange={handleChangeField}
          placeholder="Use comma to search multiple rate codes (DSC, BESTFARE)"
        />
      </Box>

      <Box flexGrow={1}>
        <TextField
          fullWidth
          name="vendorCode"
          label="Vendor Code"
          onChange={handleChangeField}
          value={searchValues.vendorCode}
        />
      </Box>

      <Box flexGrow={2}>
        <TextField
          fullWidth
          name="promotionName"
          label="Promotion Name"
          onChange={handleChangeField}
          value={searchValues.promotionName}
        />
      </Box>

      <Box>
        <Button variant="contained" disabled={loading} onClick={handleSearch}>
          Search Content
        </Button>
      </Box>
    </Stack>
  );
};

export default RateCodeManagementSearch;
