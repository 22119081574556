import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueLEDNonredemptions } from '~/services/ReportingService';

const formSchema: RJSFSchema = {
  description: 'Please enter an offer and date to export all bookings between the selected date range',
  type: 'object',
  properties: {
    startDate: {
      title: 'Start Date',
      type: 'string',
      format: 'date',
    },
    endDate: {
      title: 'End Date',
      type: 'string',
      format: 'date',
    },
    offerId: {
      title: 'Offer ID',
      type: 'string',
    },
  },
};

const uiSchema = {
  startDate: {
    'ui:widget': 'date',
  },
  endDate: {
    'ui:widget': 'date',
  },
  offerId: {
    'ui:widget': 'text',
  },
};

type RequestStatus = 'pending' | 'completed' | 'failed';

export default function ExportLEDNonredemptionsForm() {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setRequestStatus('pending');

      await queueLEDNonredemptions({
        startDate: event.formData.startDate,
        endDate: event.formData.endDate,
        offerId: event.formData.offerId,
      });

      setRequestStatus('completed');
    } catch (e) {
      setRequestStatus('failed');
    }
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Email LED Nonredemption Report
      </Button>

      {requestStatus === 'completed' && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
