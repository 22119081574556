import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { queueFinanceReconciliationReport } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter an offer and date to export all reconciliation data between the selected date range',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

export default function ExportFinanceRecReportForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    queueFinanceReconciliationReport({
      startAt: event.formData.startAt,
      endAt: event.formData.endAt,
    })
      .then(() => {
        setSuccessMessage(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} validator={validator} showErrorList={false}>
      <Button type="submit" variant="contained">
        Email Finance Reconciliation Report
      </Button>

      {error && <ErrorDisplay message={error} />}

      {successMessage && (
        <Alert severity="success">
          <p>Your report is queued for delivery and will be emailed shortly.</p>
        </Alert>
      )}
    </Form>
  );
}
