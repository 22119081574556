import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AddTrendingPlaceForm from '~/components/Content/TrendingPlaces/AddTrendingPlaceForm';

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddTrendingPlaceModal = ({ open, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add New Trending Place</DialogTitle>
      <DialogContent>
        <AddTrendingPlaceForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AddTrendingPlaceModal;
