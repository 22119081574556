import React from 'react';

import { useSnackbar } from 'notistack';

import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { isOfferTypeTour } from '~/utils/offer';
import { formatOrderDate, getCustomerPhoneNumberFromOrder } from '~/utils/order';

import TemplateForCS from './TemplateForCSModal';
import { copyToClipboard } from './utils';

interface Props {
  item: App.OrderItem;
  guestName: string;
  order: App.Order;
}

export default function ReservationSummary({ guestName, item, order }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { fullReservation } = item;
  const fromDate = item.reservation && formatOrderDate(item.reservation.check_in || item?.reservation.start_date);
  const toDate = item.reservation && formatOrderDate(item.reservation.check_out || item.reservation.end_date);
  const { isToggled: isSummaryOpen, toggle: toggleSummary } = useToggleState(false);

  const bookingDetails = {
    'Name:': guestName || order.customer_full_name,
    'Booking Number:': item.booking_number,
    'Dates:': item.reservation ? `${fromDate} - ${toDate}` : null,
    'Phone Number:': getCustomerPhoneNumberFromOrder(order),
    'Email:': order.customer_email,
    'Package Type:': fullReservation.property
      ? `${fullReservation.room_type.name} | ${fullReservation.rate_plan.name}`
      : null,
    'Property Name:': isOfferTypeTour(item.offer) ? item.offer.name : item.offer_package.property.name,
    'Tour:':
      isOfferTypeTour(item.offer) && fullReservation.tour ? fullReservation.tour && fullReservation.tour.name : null,
    'Tour Option:':
      isOfferTypeTour(item.offer) && fullReservation.tour
        ? fullReservation.tour_option && fullReservation.tour_option.name
        : null,
    'Deal ID:': isOfferTypeTour(item.offer) && fullReservation.tour ? fullReservation.deal_id : null,
    'Offer Name:': item.offer.name,
  };
  if (order?.permanent_account_number) {
    bookingDetails['PAN:'] = order.permanent_account_number;
  }

  const handleCopyClick = () => {
    copyToClipboard(bookingDetails);
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  };

  return (
    <div>
      <Box>
        <IconButton title="Copy user details" onClick={handleCopyClick}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>

        <Button
          variant="text"
          size="small"
          onClick={toggleSummary}
          title="CS often need to send this info to vendors. This makes it easy to copy/paste."
        >
          Template for CS
        </Button>
        <TemplateForCS isSummaryOpen={isSummaryOpen} toggleSummary={toggleSummary} bookingDetails={bookingDetails} />
      </Box>
    </div>
  );
}
