const addressLevelAdminAreaLevel1 = 'administrative_area_level_1';
const addressTypePolitical = 'political';

// getAdminAreaLevel1ReverseGeocodeAddress retrieves the address
// corresponding to the administrative_area_level_1 from the given
// reverse geocode response
export function getAdminAreaLevel1ReverseGeocodeAddress(results) {
  for (const result of results) {
    const { types } = result;

    if (
      types.length === 2 &&
      types.indexOf(addressLevelAdminAreaLevel1) > -1 &&
      types.indexOf(addressTypePolitical) > -1
    ) {
      return result;
    }
  }

  return null;
}

export function getAddressComponentByType(addressComponents, componentType) {
  if (!addressComponents) {
    return null;
  }

  return addressComponents.find((component) => component.types.includes(componentType));
}

export const GEOCODE_STATUS_ZERO_RESULTS = 'ZERO_RESULTS';
export const GEOCODE_STATUS_OK = 'OK';
