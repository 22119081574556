import React from 'react';

import { Button, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import AvailabilityCalendar from './AvailabilityCalendar';
import LengthOfStayToggle from './CalendarOptionsToggle';

interface Props {
  lengthOfStay: number;
  numberOfAdults: number;
  childrenAges: number[];
  getRoomRateDatesByOptions: (los, noa, childrenAges) => void;
  changeAmountInCalendar: () => void;
  ratePlanLink: string;
  showWithoutTax: boolean;
  availability: any[];
  blackoutDates: any[];
  surchargeDates: any[];
  roomRateDates: any[];
  propertyIsRental: boolean;
  propertyId: string;
  roomTypeId: string;
  roomRateId: string;
}

export function InventoryPane({
  lengthOfStay,
  numberOfAdults,
  childrenAges,
  getRoomRateDatesByOptions,
  changeAmountInCalendar,
  ratePlanLink,
  showWithoutTax,
  availability,
  blackoutDates,
  surchargeDates,
  roomRateDates,
  propertyId,
  roomTypeId,
  roomRateId,
  propertyIsRental = false,
}: Props) {
  return (
    <>
      {!propertyIsRental && (
        <PageSubheader title="Current inventory">
          {ratePlanLink && (
            <Button variant="text" href={ratePlanLink} target="_blank">
              View RatePlan
            </Button>
          )}
        </PageSubheader>
      )}
      <div>
        {availability.length ? (
          <div>
            {!propertyIsRental && (
              <>
                <Typography variant="subtitle1">(Rooms booked / Total allocated)</Typography>
                <LengthOfStayToggle
                  lengthOfStay={lengthOfStay}
                  numberOfAdults={numberOfAdults}
                  childrenAges={childrenAges}
                  getRoomRateDatesByOptions={getRoomRateDatesByOptions}
                  changeAmountInCalendar={changeAmountInCalendar}
                  showWithoutTax={showWithoutTax}
                />
              </>
            )}
            <AvailabilityCalendar
              availability={availability}
              blackoutDates={blackoutDates}
              surchargeDates={surchargeDates}
              roomRateDates={roomRateDates}
              propertyId={propertyId}
              roomTypeId={roomTypeId}
              roomRateId={roomRateId}
              showWithoutTax={showWithoutTax}
            />
          </div>
        ) : (
          <Typography sx={{ fontStyle: 'italic' }} variant="subtitle1">
            No inventory
          </Typography>
        )}
      </div>
    </>
  );
}
