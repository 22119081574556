import React, { ComponentProps, useMemo } from 'react';

import { ObjectFieldTemplateProps, RJSFSchema } from '@rjsf/utils';

import DataObjectIcon from '@mui/icons-material/DataObject';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';

import { useActiveAccordion } from './ActiveAccordionContext';
import BreadcrumbsLegendTemplate from './BreadcrumbsLegendTemplate';
import { ExtendedRJSFormContext } from './useExtendedRJSForm';

interface Props extends ObjectFieldTemplateProps<any, RJSFSchema, ExtendedRJSFormContext> {
  uiSchema: {
    'ui:options': {
      isInitiallyCollapsed: boolean; // collapse the array initially, because they're all expanded by default
      layout: 'grid' | 'stack'; // switch between vertical stack or grid layout
      noAccordion: boolean;
    };
  };
}

export default function ExtendedObjectFieldTemplate(props: Props) {
  const { properties, description, idSchema, uiSchema, title, required, schema, registry } = props;
  const options = uiSchema?.['ui:options'];

  const isRootObject = idSchema.$id === 'root';

  const { activeAccordionIndex, setActiveAccordion, displaySingleOption, targetedSection } = useActiveAccordion();

  const gridTemplateColumns = useMemo<ComponentProps<typeof Box>['gridTemplateColumns']>(() => {
    if (options?.layout !== 'grid') {
      return '1fr';
    }
    return properties.length > 1 ? `repeat(${properties.length}, 1fr)` : 'repeat(auto-fit, minmax(320px, 1fr))';
  }, [options?.layout, properties]);

  const hidden =
    displaySingleOption &&
    targetedSection === idSchema.$id.replace(/([0-9]|_)/g, '') &&
    activeAccordionIndex !== Number(title.slice(-1));

  const singleOptionProps = useMemo(() => {
    if (displaySingleOption && targetedSection === idSchema.$id.replace(/([0-9]|_)/g, '')) {
      return {
        defaultExpanded: !hidden && !options?.isInitiallyCollapsed && !!title,
        expanded: !hidden,
        onChange: () =>
          setActiveAccordion((prev: number) => (prev === Number(title.slice(-1)) ? -1 : Number(title.slice(-1)))),
      };
    }

    return {};
  }, [
    displaySingleOption,
    hidden,
    options?.isInitiallyCollapsed,
    title,
    setActiveAccordion,
    targetedSection,
    idSchema,
  ]);

  if (options?.noAccordion) {
    return (
      <fieldset id={idSchema.$id}>
        <Box display="grid" gap={2} gridTemplateColumns={gridTemplateColumns} justifyContent="center">
          {!!description && (
            <Typography variant="subtitle1" component="p">
              {description}
            </Typography>
          )}
          {!hidden &&
            properties.map((element) => (
              <Box
                key={idSchema[element.name]?.$id}
                sx={{ display: element.hidden && 'none' }} // HERE
              >
                {element.content}
              </Box>
            ))}
        </Box>
      </fieldset>
    );
  }
  return (
    <fieldset id={idSchema.$id}>
      <Accordion defaultExpanded={!options?.isInitiallyCollapsed && !!title} disableGutters {...singleOptionProps}>
        {!!title && (
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: 'background.paper',
              boxShadow: 1,
              position: 'sticky',
              zIndex: (theme) => theme.zIndex.appBar - 1,
              top: 0,
              mb: 3,
              backdropFilter: 'blur(4px)',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <BreadcrumbsLegendTemplate
                id={idSchema.$id}
                title={title}
                required={required}
                schema={schema}
                registry={registry}
              />

              {properties.length > 1 && <DataObjectIcon color="disabled" />}
            </Stack>
          </AccordionSummary>
        )}

        <AccordionDetails sx={{ p: isRootObject ? 0 : 2 }}>
          <Box display="grid" gap={2} gridTemplateColumns={gridTemplateColumns} justifyContent="center">
            {!!description && (
              <Typography variant="subtitle1" component="p">
                {description}
              </Typography>
            )}
            {!hidden &&
              properties.map((element) => (
                <Box
                  key={idSchema[element.name]?.$id}
                  sx={{ display: element.hidden && 'none' }} // HERE
                >
                  {element.content}
                </Box>
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </fieldset>
  );
}
