import React from 'react';

import { Stack, Typography } from '@mui/material';

import CopyableField from '~/components/Common/CopyableField';
import UserName from '~/components/Common/UserName';

type PromoCodeUser = {
  userId: string;
  userIdFor?: string;
};

function PromoCodeUser({ userId, userIdFor }: PromoCodeUser) {
  return (
    <Stack direction="column">
      <CopyableField value={userId} label={<UserName asLink userId={userId} />} />
      {userIdFor && userId !== userIdFor && (
        <>
          <Typography>(used on behalf of:</Typography>
          <Typography>
            <CopyableField value={userIdFor} label={<UserName asLink userId={userIdFor} />} />)
          </Typography>
        </>
      )}
    </Stack>
  );
}

export default PromoCodeUser;
