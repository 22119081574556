import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

const ExperienceCurationAditionalInformation: React.FC = () => {
  const { updateValues, values, contentTab } = useUpdateExperienceValues();

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  return (
    <>
      <PageSubheader title="Additional Information" />

      {values && (
        <Box>
          <Accordion id="dealDescription" variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Deal description</AccordionSummary>
            <AccordionDetails>
              <MarkdownEditor
                value={values.dealDescription}
                onChange={(value) => updateValues({ dealDescription: value })}
                disabled={areOriginalValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion id="shortDescription" variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Short description</AccordionSummary>
            <AccordionDetails>
              <MarkdownEditor
                value={values.shortDescription}
                onChange={(value) => updateValues({ shortDescription: value })}
                disabled={areOriginalValues}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion id="included" variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Inclusions</AccordionSummary>
            <AccordionDetails>
              <MarkdownEditor
                value={values.included}
                onChange={(value) => updateValues({ included: value })}
                disabled={areOriginalValues}
              />
            </AccordionDetails>
          </Accordion>

          <Box mt={4}>
            <PageSubheader title="Things To Know" />

            {Object.entries(values.thingsToKnowNew).map(
              ([key, item]: [keyof Experiences.ThingsToKnow, Experiences.ThingsToKnowItem], index) =>
                (key !== 'cancellationPolicy' || !values.isNonRefundable) && (
                  <Accordion variant="outlined" id={key} key={key}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{item.label}</AccordionSummary>
                    <AccordionDetails>
                      <MarkdownEditor
                        key={contentTab}
                        value={item.value}
                        onChange={(value) => {
                          updateValues({
                            thingsToKnowNew: {
                              ...values.thingsToKnowNew,
                              [key]: {
                                ...item,
                                value,
                              },
                            },
                          });
                        }}
                        disabled={areOriginalValues}
                      />
                    </AccordionDetails>
                  </Accordion>
                ),
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ExperienceCurationAditionalInformation;
