import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Button } from '@mui/material';

const GiftCardOrderFooter = ({ backStep, proceedStep, nextStepLabel, cart, canProceed }) => {
  const { items, amounts, currencyCode } = cart;

  return (
    <div className="new-order-footer">
      <Button variant="text" className="back-section" onClick={backStep}>
        <i className="fa fa-arrow-left" />
        <span className="text">Back</span>
      </Button>
      <div className="main-section">
        <div className="meta">
          <div className="offer-title">Gift card order</div>
        </div>

        <div className="amounts">
          <div className="amount">
            <div className="noun">Gift Cards</div>
            <div className="value data-hj-whitelist">{items.length}</div>
          </div>
          <div className="amount">
            <div className="noun">Total</div>
            <div className="value data-hj-whitelist">
              {currencyCode}{' '}
              {currencyFormatter.format(amounts.grandTotal, {
                code: currencyCode,
              })}
            </div>
          </div>
        </div>

        <div className="action">
          <Button className="T-proceed-step-btn" variant="contained" onClick={proceedStep} disabled={!canProceed}>
            {nextStepLabel} <i className="fa fa-caret-right" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GiftCardOrderFooter;
