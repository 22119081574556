import React, { useEffect, useState } from 'react';

import { SortableContainer } from 'react-sortable-hoc';

import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Button, IconButton, ImageList, ImageListItem, Typography } from '@mui/material';

import ImagesForm from '~/components/Common/Forms/ImagesForm';
import Image from '~/components/Common/Image';

const luxuryescapesSource = 'luxuryescapes';

const EpsList = SortableContainer(({ images, toggleImageSelection }) => (
  <ImageList cols={4} gap={10} className={'mt-10'}>
    {images.map((item) => (
      <ImageListItem
        key={item.id}
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.preventDefault();
          toggleImageSelection(item.id, item.selected);
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            position: 'absolute',
            right: 10,
            zIndex: 1,
          }}
        >
          <IconButton
            color="info"
            title={item.selected ? 'Select Image' : 'Unselect Image'}
            sx={{
              zIndex: 2,
            }}
          >
            {item.selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
          </IconButton>
        </Box>
        <Image
          className="img-responsive mx-auto"
          publicId={item.id}
          options={{
            width: 400,
            height: 300,
          }}
        />
      </ImageListItem>
    ))}
  </ImageList>
));

type PropertyImage = App.Bedbank.PropertyEdit['images'][0];

interface BaseImageData extends PropertyImage {
  selected: boolean;
}

interface Props {
  images: PropertyImage[];
  onImagesChange: (images: PropertyImage[]) => void;
  onCreateImage: (imageServiceId: string) => Promise<void>;
}

function BedbankImagesForm(props: Props) {
  const [epsImages, setEpsImages] = useState<BaseImageData[]>([]);

  useEffect(() => {
    setEpsImages(
      props.images
        .filter((i) => !i.is_hero && !i.is_deleted)
        .map((i) => ({
          ...i,
          selected: false,
        })),
    );
  }, [props.images]);

  const sortImages = (images) => {
    return [...images]
      .filter((i) => i.is_hero && !i.is_deleted)
      .map((i) => ({
        ...i,
        publicImageId: i.id,
      }))
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
  };

  const onCreateImage = async (imageServiceId) => {
    const copyImages = [...props.images];
    const sortedImages = sortImages(copyImages);
    copyImages.push({
      id: imageServiceId,
      hidden: false,
      is_hero: true,
      is_deleted: false,
      order: sortedImages.length,
      title: '',
      source: luxuryescapesSource + ':' + imageServiceId,
    });
    props.onCreateImage(imageServiceId).then(() => {
      props.onImagesChange(copyImages);
    });
  };

  const onUpdateImages = async (updatedImages) => {
    const copyImages = [...props.images];
    for (const img of updatedImages) {
      const currentImg = copyImages.findIndex((x) => x.id === img.id);
      copyImages[currentImg].title = img.title;
      copyImages[currentImg].hidden = img.hidden;
      copyImages[currentImg].order = img.order;
    }
    props.onImagesChange(copyImages);
  };

  const onDeleteImage = (imageId) => {
    const copyImages = [...props.images];
    const imageIndex = copyImages.findIndex((image) => image.id === imageId);

    if (copyImages[imageIndex]) {
      if (copyImages[imageIndex].source.includes(luxuryescapesSource)) {
        copyImages[imageIndex].is_hero = false;
        copyImages[imageIndex].is_deleted = true;

        props.onImagesChange(
          copyImages.map((p, index) => ({
            ...p,
            order: index,
          })),
        );
      } else {
        copyImages[imageIndex].is_hero = false;
        copyImages[imageIndex].hidden = false;

        props.onImagesChange(
          copyImages.map((p, index) => ({
            ...p,
            order: index,
          })),
        );
      }
    }
  };

  const moveImagesToHero = () => {
    const newImages = [...props.images].map((p, index) => ({
      ...p,
      is_hero: p.is_hero ? p.is_hero : epsImages.find((x) => x.id === p.id)?.selected,
      order: index,
    }));

    props.onImagesChange(newImages);
  };

  const toggleImageSelection = (id, oldValue) => {
    const newImages = [...epsImages].map((p) => ({
      ...p,
      selected: p.id === id ? !oldValue : p.selected,
    }));

    setEpsImages(newImages);
  };

  return (
    <>
      <Typography variant="h4">Hero images</Typography>
      <ImagesForm
        images={sortImages(props.images)}
        hasHiddenToggle={true}
        showSaveButton={false}
        onAddImage={onCreateImage}
        onUpdateImages={onUpdateImages}
        onDeleteImage={onDeleteImage}
      />
      {epsImages.length > 0 && (
        <>
          <Box mt={7}>
            <Typography variant="h4">EPS images</Typography>
          </Box>
          <EpsList
            images={epsImages}
            toggleImageSelection={toggleImageSelection}
            axis="xy"
            distance={3}
            helperClass="sortable-helper"
          />
          <div className="button-container" style={{ justifyContent: 'end' }}>
            <Button variant="contained" color="primary" onClick={moveImagesToHero}>
              Move to Hero images
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default BedbankImagesForm;
