import React from 'react';

import { Button } from '@mui/material';

import { asiaRegions } from '../../../../consts/allRegions';
import OffersService from '../../../../services/OffersService';
import { defaultEndDate, defaultStartDate } from '../../../../utils/DefaultDate';
import { getRegionsCodeByName } from '../../../../utils/getRegionsCodeByName';
import { reportError } from '../../../../utils/reportError';
import NewScheduleFormBase from '../../../Common/Forms/NewScheduleFormBase';
import { buttonStates } from '../../../Common/Forms/states/submitButton';

export default class FrontPageScheduleEdit extends React.Component {
  state = {
    schedule: {
      start: this.props.startDate,
      end: this.props.endDate,
    },

    prevPropsRegions: this.props.selectedRegion,
    startUpdated: false,
    endUpdated: false,
    saveState: buttonStates.default,
    clearRegions: this.props.clearRegions,
    errors: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.clearRegions !== prevState.clearRegions) {
      return {
        prevPropsRegions: nextProps.selectedRegion,
        enabledRegions: nextProps.enabledRegions,
        errors: [],
      };
    }
    return null;
  }

  updateStart = (time) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        start: time,
      },
      startUpdated: true,
    }));
  };

  updateEnd = (time) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        end: time,
      },
      endUpdated: true,
    }));
  };

  regionsChanges = (e) => {
    const region = e.target.name;
    const isChecked = e.target.checked;

    this.setState((prevState) => ({
      prevPropsRegions: prevState.prevPropsRegions.set(region, isChecked),
    }));
  };

  changeSelection(isChecked) {
    this.props.enabledRegions.map((region) => {
      this.setState((prevState) => ({
        prevPropsRegions: prevState.prevPropsRegions.set(region.name, isChecked),
      }));
    });
  }

  selectAsiaOnly = () => {
    this.props.enabledRegions.map((region) => {
      this.setState((prevState) => ({
        prevPropsRegions: prevState.prevPropsRegions.set(region.name, asiaRegions.includes(region.name)),
      }));
    });
  };

  deleteRegionsFromSchedule(regions) {
    const removedIdList = regions.map((r) => r.id);
    const { offerId } = this.props;
    const payloadDelete = {
      idList: removedIdList,
    };

    OffersService.deleteScheduleList(offerId, payloadDelete).catch((e) => {
      this.setState({
        saveState: buttonStates.failed,
      });
      this.onError(e);
      reportError(e);
    });
  }

  addRegionsToSchedule(regions) {
    const { offerId, type } = this.props;
    const { start, end } = this.state.schedule;
    const brands = ['luxuryescapes'];
    const payloadCreate = {
      regions,
      start,
      end,
      brands,
      type,
    };
    OffersService.createScheduleList(payloadCreate, offerId);
  }

  onReset = () => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        start: this.props.start || defaultStartDate(),

        end: this.props.end || defaultEndDate(),
      },
      saveState: buttonStates.default,
    }));
  };

  updateScheduleList(commonRegions) {
    const { offerId, type } = this.props;
    const { start, end } = this.state.schedule;
    const idList = commonRegions.map((r) => r.id);
    const brands = ['luxuryescapes'];
    const updatePayload = {
      idList,
      start,
      end,
      regions: commonRegions.map((r) => r.region),
      brands,
      type,
    };
    OffersService.updateScheduleList(updatePayload, offerId)
      .then((newSchedule) => {
        this.setState({
          schedule: {
            start: newSchedule[0].start,
            end: newSchedule[0].end,
          },
          errors: [],
        });
        this.props.onSaveFinished();
        this.props.onClose();
      })
      .catch((e) => {
        this.setState({
          saveState: buttonStates.failed,
        });
        this.onError(e);
        reportError(e);
      });
  }

  createSchedule(offerId) {
    const selectedCountries = this.filterCheckedRegions();
    const regions = getRegionsCodeByName(selectedCountries);

    const payload = {
      regions,
      start: this.state.schedule.start,
      end: this.state.schedule.end,
      brands: ['luxuryescapes'],
      type: this.props.type,
    };

    OffersService.createScheduleList(payload, offerId)
      .then((newSchedule) => {
        this.setState({
          schedule: {
            start: newSchedule[0].start,
            end: newSchedule[0].end,
          },
          saveState: buttonStates.saved,
          errors: [],
        });
        this.props.onSaveFinished();
        this.props.onClose();
      })
      .catch((e) => {
        this.setState({
          saveState: buttonStates.failed,
        });
        this.onError(e);
        reportError(e);
      });
  }

  saveSchedule = async () => {
    const { offerId, isNewSchedule, startDate } = this.props;

    if (startDate && !isNewSchedule) {
      const checkedRegionsCode = getRegionsCodeByName(this.filterCheckedRegions());
      const selectedRegions = this.props.selectedRegionObj.map((r) => r.region);
      const commonRegions = this.props.selectedRegionObj.filter((r) => checkedRegionsCode.includes(r.region));
      const removedRegions = this.props.selectedRegionObj.filter((r) => !checkedRegionsCode.includes(r.region));
      const addedRegions = checkedRegionsCode.filter((r) => !selectedRegions.includes(r));

      if (removedRegions.length) {
        await this.deleteRegionsFromSchedule(removedRegions);
      }

      if (addedRegions.length) {
        await this.addRegionsToSchedule(addedRegions);
      }
      await this.updateScheduleList(commonRegions);
    } else {
      await this.createSchedule(offerId);
    }
  };

  filterCheckedRegions() {
    let checkedRegions = [];
    for (let [k, v] of this.state.prevPropsRegions.entries()) {
      if (v) {
        checkedRegions.push(k);
      }
    }
    return checkedRegions;
  }

  onCancel = () => {
    this.setState({
      schedule: {
        start: this.props.startDate || defaultStartDate(),
        end: this.props.endDate || defaultEndDate(),
      },
      prevPropsRegions: this.props.selectedRegion,
    });
    this.props.onClose();
  };

  onError = (data) => {
    this.setState({
      editing: false,
      errors: data.errors,
    });
  };

  render() {
    return this.props.enabledRegions.length ? (
      <div>
        <div className="fp-schedule-inner">
          <div>
            {this.state.errors &&
              this.state.errors.map((error, index) => {
                return (
                  <p
                    key={index}
                    style={{
                      color: 'red',
                      marginTop: '30px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {error.message}
                  </p>
                );
              })}
          </div>
          <NewScheduleFormBase
            schedule={this.state.schedule}
            updateStart={this.updateStart}
            updateEnd={this.updateEnd}
            startUpdated={this.state.startUpdated}
            endUpdated={this.state.endUpdated}
            onReset={this.onReset}
          />
          <div className="button-link">
            <label className="control-label">Select Countries: </label>
            <a onClick={() => this.changeSelection(true)} className="select-link">
              Select All
            </a>
            <span className="bar">|</span>
            <a onClick={() => this.changeSelection(false)} className="select-link">
              Deselect All
            </a>
            <span className="bar">|</span>
            <a onClick={this.selectAsiaOnly} className="select-link">
              Asia only
            </a>
          </div>

          <div className="regions">
            <ul className="modal-regions-list">
              {this.props.enabledRegions.map((region) => (
                <div className="checkbox-element-wrapper" key={region.name}>
                  <input
                    type="checkbox"
                    name={region.name}
                    checked={this.state.prevPropsRegions.get(region.name)}
                    onChange={this.regionsChanges}
                  />
                  <label className="regions-checkbox-label">{region.name}</label>
                </div>
              ))}
            </ul>
            <div className="action-buttons">
              <Button type="button" variant="contained" color="secondary" onClick={this.onCancel}>
                Cancel
              </Button>
              <Button type="button" variant="contained" onClick={this.saveSchedule}>
                Save Schedule
              </Button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="empty-regions">
        <h4>No More Regions To Display</h4>
        <Button className="close-button" color="secondary" onClick={this.onCancel}>
          Return
        </Button>
      </div>
    );
  }
}
