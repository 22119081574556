import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link, useRouteMatch } from 'react-router-dom';

import { Button, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import RoomTypesGroupsList from '~/components/Common/RoomTypesGroupsList';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';

type Props = { vendorName?: string };

export default function RoomTypesGroupsPageContainer(_: Props) {
  const {
    params: { id_vendor: vendorId, id_property: propertyId },
  } = useRouteMatch<{ id_vendor: string; id_property: string }>();

  const [property, setProperty] = useState(null);
  const [roomTypesGroups, setRoomTypesGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    Promise.all([ReservationService.getProperty(propertyId), ReservationService.getRoomTypesGroups(propertyId)])
      .then(([property, roomTypesGroups]) => {
        setProperty(property.result);
        setRoomTypesGroups(roomTypesGroups.result);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [propertyId]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{property?.name || propertyId} | Room Groups</title>
      </Helmet>

      <PageHeader
        title={`${property?.name || propertyId} | Room Groups`}
        backButton={`/vendors/${vendorId}/properties/${propertyId}`}
      />

      <RoomTypesGroupsList roomTypesGroups={roomTypesGroups} />

      <Button
        component={Link}
        variant="contained"
        to={`/vendors/${vendorId}/properties/${propertyId}/edit-room-types-groups`}
        sx={{ mt: 2 }}
      >
        Edit
      </Button>
    </Container>
  );
}
