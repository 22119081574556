import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AddPropertyLocationOverrideForm from '~/components/Content/PropertyLocationOverride/AddPropertyLocationOverrideForm';
import { PropertyLocationOverride } from '~/components/Content/PropertyLocationOverride/Columns';

type props = {
  open: boolean;
  onClose: () => void;
  data?: PropertyLocationOverride[];
};

const AddPropertyLocationOverrideModal = ({ open, onClose, data }: props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Property Location Override</DialogTitle>
      <DialogContent>
        <AddPropertyLocationOverrideForm onClose={onClose} data={data} />
      </DialogContent>
    </Dialog>
  );
};

export default AddPropertyLocationOverrideModal;
