import { ROOM_RATE_LPC_TYPE } from '~/consts/ratePlans';
import ReservationService from '../../../../../services/ReservationService';
import {
  COPY_OPERATION_CAPACITIES,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM,
  COPY_OPTIONS_MODE_ALL_RATES,
} from '../../constants';

const NO_RATES_MESSAGE = 'no room rates found for this option!';
const NO_CAPACITIES_MESSAGE = 'no capacities found to copy!';

async function copyCapacities(mode, capacities, propertyId, roomRate, roomType, advancePayload) {
  switch (mode) {
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM: {
      const roomRatesLPC = getRatesForRoomAndProductType(roomType, roomRate, ROOM_RATE_LPC_TYPE);
      try {
        await callCreateOperationInBulk(roomRatesLPC, capacities, propertyId, roomType.id, advancePayload);
      } catch (e) {
        throw new Error(
          `Error while copying room rate capacities across all Lux Premium Collection rates under this room: ${e.message}`,
        );
      }
      break;
    }
    case COPY_OPTIONS_MODE_ALL_RATES:
    default: {
      const roomRates = getRatesForAll(roomType, roomRate);
      try {
        await callCreateOperationInBulk(roomRates, capacities, propertyId, roomType.id, advancePayload);
      } catch (e) {
        throw new Error(`Error while copying room rate capacities under this room: ${e.message}`);
      }
      break;
    }
  }
}

function getRatesForRoomAndProductType(roomType, roomRate, productType) {
  return roomType.room_rates.filter((_roomRate) => {
    return _roomRate.id !== roomRate.id && _roomRate.rate_plan.product_type === productType;
  });
}

function getRatesForAll(roomType: App.RoomType, roomRate: App.RoomRate) {
  return roomType.room_rates.filter((_roomRate) => _roomRate.id !== roomRate.id);
}

const validate = (rates, capacities) => {
  if (!rates.length) {
    throw new Error(NO_RATES_MESSAGE);
  }
  if (!capacities.length) {
    throw new Error(NO_CAPACITIES_MESSAGE);
  }
};

async function callCreateOperationInBulk(roomRates, capacities, propertyId, roomTypeId, advancePayload) {
  validate(roomRates, capacities);
  const payload = advancePayload ? { capacities, advanced: advancePayload } : { capacities };

  await Promise.all(
    roomRates.map(async (roomRate) => {
      await ReservationService.createOperationInBulk(
        payload,
        propertyId,
        roomTypeId,
        roomRate.id,
        COPY_OPERATION_CAPACITIES,
      );
    }),
  );
}

export default copyCapacities;
