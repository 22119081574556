import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';

import { LIST_VISIBILITY } from '../../../consts/schedule';
import { types } from '../../../consts/types';
import { scheduleForType } from '../../../utils/scheduleForType';

import Visibility from './Visibility';

export default class SchedulePane extends React.Component {
  formatDate = (date_in) => {
    let date = new Date(date_in);
    return date.toDateString();
  };
  render() {
    const { offer, schedules } = this.props;
    const offerId = offer.result.id_salesforce_external;

    return (
      <span>
        <h1 className="page-header">
          Schedule
          <Link to={`/edit-offers/${offerId}/schedules`}>
            <ModeEditTwoToneIcon fontSize="large" />
            <span className="sr-only">Edit</span>
          </Link>
        </h1>
        <div className="panel-section">
          <div className="schedule-summary">
            {types.map((type, index) => {
              return (
                <div key={`${index}-${type.code}`}>
                  <span className="h6 text-uppercase text-muted">{type.name}</span>
                  <span>
                    <div className="date">
                      {scheduleForType(schedules, type.code, 'start') ? (
                        <span className="date-labels">
                          <div className="date-start">
                            {this.formatDate(scheduleForType(schedules, type.code, 'start'))}
                          </div>
                          <div className="date-arrow">&#8594;</div>
                          <div className="date-end">
                            {this.formatDate(scheduleForType(schedules, type.code, 'end'))}
                          </div>
                        </span>
                      ) : (
                        <div className="empty">No Schedule Set</div>
                      )}
                    </div>
                  </span>
                </div>
              );
            })}
          </div>
          <Visibility offerId={offerId} schedules={schedules} type={LIST_VISIBILITY} />
        </div>
      </span>
    );
  }
}
