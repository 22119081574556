import React from 'react';

import currencyFormatter from 'currency-formatter';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Container, Stack, Typography } from '@mui/material';

const FlightInformationFooter = ({ backStep, proceedStep, nextStepLabel, cart, canProceed }) => {
  const { items, amounts, currencyCode } = cart;

  return (
    <Box className="new-order-footer" py={2}>
      <Container maxWidth="xl">
        <Stack direction="row" spacing={2}>
          <Box display="flex" alignItems="center">
            <Button variant="text" startIcon={<ArrowBackIcon />} onClick={backStep}>
              Back
            </Button>
          </Box>

          <Box display="flex" alignItems="center">
            <div className="meta">
              <div className="offer-title">
                <Typography variant="h6">Flight order</Typography>
              </div>
            </div>
          </Box>

          <div className="main-section">
            <div className="amounts">
              <div className="amount">
                <div className="noun">PNRs</div>
                <div className="value data-hj-whitelist">{items[0] && items[0].pnrs ? items[0].pnrs.length : 0}</div>
              </div>
              <div className="amount">
                <div className="noun">Total</div>
                <div className="value data-hj-whitelist">
                  {currencyCode}{' '}
                  {currencyFormatter.format(amounts.grandTotal, {
                    code: currencyCode,
                  })}
                </div>
              </div>
            </div>
          </div>

          <Box display="flex" alignItems="center">
            <Button
              className="T-proceed-step-btn"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={proceedStep}
              disabled={!canProceed}
            >
              {nextStepLabel}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default FlightInformationFooter;
