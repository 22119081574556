import { offer } from './offer';
import { property } from './property';
import { ICondition, IConditionChecks } from './types';
import { pluralisedCount } from './utils';

// Composite checks are for when base data could exist on either the offer or the property
const composite: { [key: string]: ICondition } = {
  images: {
    thresholds: {
      type: 'gt',
      levels: [7, 1, 0],
    },
    check: (offer, property) => {
      const propertyImages = property?.images ?? [];
      const offerImages = offer.images ?? [];
      return [...propertyImages, ...offerImages].length;
    },
    result: (offer, property) => {
      const propertyImages = property?.images ?? [];
      const offerImages = offer.images ?? [];
      return [...propertyImages, ...offerImages].map((i) => ({
        id: i.image_id ?? i.id_cloudinary_external ?? i.id ?? i.url,
      }));
    },
    summary: (resultOutput) => {
      return pluralisedCount('image', resultOutput.length);
    },
    label: 'Images',
    helpText:
      'Red indicates we are missing images, orange indicates we may need to source more. 8 or greater is considered healthy.',
  },
};

export const ConditionChecks: IConditionChecks = {
  offer,
  property,
  composite,
};

export default ConditionChecks;
