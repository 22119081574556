import React from 'react';

import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridPaginationModel, GridRowParams } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 400, display: 'flex' },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: true,
    display: 'flex',
  },
];

interface Props {
  onPaginationModelChange: any;
  paginationModel: GridPaginationModel;
  rows: App.Business[];
  total: number;
}

export default function BusinessGrid(props: Props) {
  const { onPaginationModelChange, paginationModel, rows, total } = props;

  function handleRowClick(row) {
    const url = `/lebusiness/${row.id}/business`;

    window.open(url, '_blank');
  }

  return (
    <Grid2 xs={12}>
      <Box width="100%" height={600}>
        <DataGrid
          columns={columns}
          onPaginationModelChange={onPaginationModelChange}
          onRowClick={(row: GridRowParams<App.Business>) => handleRowClick(row.row)}
          paginationMode="server"
          paginationModel={paginationModel}
          rows={rows || []}
          rowCount={total}
        />
      </Box>
    </Grid2>
  );
}
