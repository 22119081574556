import React from 'react';

import { Spacing } from '../../helpers';

import * as S from './styles';

type OutlineButtonProps = {
  title?: string;
  color?: string;
  onClick: () => void;
  borderColor?: string;
  children?: React.ReactElement;
} & Spacing;

export const OutlineButton: React.FC<OutlineButtonProps> = (props) => {
  const { title, onClick, children, ...rest } = props;

  return (
    <S.ButtonWrapper onClick={onClick} className="button">
      <S.OutlineButton {...rest}>{title ?? children ?? ''}</S.OutlineButton>
    </S.ButtonWrapper>
  );
};
