import React from 'react';

import { Clear, Delete, FileDownload, UploadFile } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import VendorsService from '~/services/VendorsService';

interface Props {
  id_offer_payment: string;
  attachments: any[];
  refetch: () => void;
}

interface State {
  isUploading: boolean;
  isDialogOpen: boolean;
  forUpload: any[];
  isDownloading: any;
  error: string;
  currentFile: any;
  isDeleting: boolean;
}

export default class VendorOfferFilesContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isUploading: false,
      isDialogOpen: false,
      forUpload: [],
      isDownloading: {},
      error: null,
      currentFile: null,
      isDeleting: false,
    };
  }

  handleFileDownload = async (fileId: string) => {
    if (!fileId) {
      return;
    }

    try {
      this.setState({ isDownloading: { ...this.state.isDownloading, [fileId]: true } });
      await VendorsService.downloadOfferPaymentFile(fileId);
      this.setState({ isDownloading: { ...this.state.isDownloading, [fileId]: false } });
    } catch (err) {
      this.setState({ error: err.message });
    }
  };

  handleDialogOpen = (file) => {
    this.setState({ isDialogOpen: true, currentFile: file });
  };

  handleFileDelete = async (fileId: string) => {
    if (!fileId) {
      return;
    }

    try {
      this.setState({ isDeleting: true });
      await VendorsService.deleteOfferPaymentFile(fileId);
      this.setState({ isDialogOpen: false, isDeleting: false });
      this.props.refetch();
    } catch (error) {
      this.setState({ error: error.message, isDialogOpen: false, isDeleting: false });
    }
  };

  addFiles = (event) => {
    const files = event.target.files;
    this.setState({ forUpload: Array.from(files) });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  removeFileForUpload = (idx) => {
    const { forUpload } = this.state;
    this.setState({ forUpload: forUpload.filter((_, index) => index !== idx) });
  };

  handleUpload = async () => {
    const { forUpload } = this.state;
    const { id_offer_payment } = this.props;

    if (!forUpload.length) {
      return;
    }

    try {
      this.setState({ isUploading: true });
      await VendorsService.uploadOfferPaymentFiles(id_offer_payment, forUpload);
      this.setState({ isUploading: false });
      this.props.refetch();
    } catch (error) {
      this.setState({ error: error.message, isUploading: false });
    }
  };

  render() {
    const { isDialogOpen, forUpload, isDownloading, isUploading, currentFile, isDeleting, error } = this.state;
    const { id_offer_payment, attachments } = this.props;

    const hasFilesForUpload = forUpload.length > 0;
    const hasAttachments = attachments.length > 0;

    if (!id_offer_payment) {
      return null;
    }

    return (
      <Paper sx={{ my: 2 }}>
        <Typography variant="h6">Attachments</Typography>
        <Divider />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            sx={{ my: 2 }}
            label="Add files"
            variant="outlined"
            type="file"
            inputProps={{ 
              multiple: true,
              accept: '.pdf,.doc,.docx,.png,.jpeg,.jpg,.xls,.xlsx'
            }}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => this.addFiles(event)}
          >
            Add files
          </TextField>
          {hasFilesForUpload && (
            <Button sx={{ ml: 2 }} variant="contained" color="success" onClick={() => this.handleUpload()}>
              Upload
            </Button>
          )}
        </Box>
        {hasFilesForUpload && (
          <List>
            {forUpload.map((file, index) => (
              <ListItem
                key={file.lastModified}
                secondaryAction={
                  <IconButton onClick={() => this.removeFileForUpload(index)}>
                    <Clear />
                  </IconButton>
                }
              >
                <ListItemIcon> {isUploading ? <Spinner size={15} /> : <UploadFile />} </ListItemIcon>
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        )}
        <Divider />
        {hasAttachments ? (
          <List>
            {attachments.map((attachment) => (
              <ListItem
                key={attachment.id}
                secondaryAction={
                  <IconButton onClick={() => this.handleDialogOpen(attachment)}>
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemButton onClick={() => this.handleFileDownload(attachment.id)}>
                  <ListItemIcon>
                    {' '}
                    {isDownloading[attachment.id] ? <Spinner size={15} /> : <FileDownload />}
                  </ListItemIcon>
                  <ListItemText primary={attachment.filename} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography sx={{ my: 2 }}>No uploaded attachments.</Typography>
        )}
        <Divider />

        {currentFile && (
          <Dialog open={isDialogOpen} fullWidth>
            <DialogTitle>Delete File - {currentFile.filename}</DialogTitle>
            <DialogContent>
              <Typography>Are you sure?</Typography>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={this.handleDialogClose} disabled={isDeleting}>
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => this.handleFileDelete(currentFile.id)}
                disabled={isDeleting}
              >
                {isDeleting ? <Spinner size={20} /> : 'Confirm'}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {error && <Typography color="error">{error}</Typography>}
      </Paper>
    );
  }
}
