import React from 'react';

import { useHistory } from 'react-router-dom';

import PropertyRelationsSearchResults from '~/components/Vendors/Home/PropertyRelationsSearchResults';

const PropertyRelationsSearchResultsContainer = (containerProps) => {
  const history = useHistory();

  const navigate = (vendorId: string, propertyId: string, path: string) => {
    history.push(`/vendors/${vendorId}/properties/${propertyId}${path}`);
  };

  const onCellClick = (cellProps: { field: any; row: any }) => {
    const { field, row } = cellProps;
    const { vendor_id, property_id, room_rate_id, room_type_id, rate_plan_id } = row;

    if (field === 'property_name' && vendor_id && property_id) {
      navigate(vendor_id, property_id, '');
    } else if (field === 'room_rate_id' && vendor_id && property_id && room_type_id && room_rate_id) {
      navigate(vendor_id, property_id, `/room-types/${room_type_id}/room-rates/${room_rate_id}`);
    } else if (field === 'rate_plan_name' && vendor_id && rate_plan_id) {
      history.push(`/vendors/${vendor_id}/rate-plans/${rate_plan_id}`);
    }
  };

  return <PropertyRelationsSearchResults onCellClick={onCellClick} {...containerProps} />;
};

export default PropertyRelationsSearchResultsContainer;
