import React from 'react';

import { Control, useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  Autocomplete as MuiAutocomplete,
  TextField,
  createFilterOptions,
} from '@mui/material';

type Option = {
  value: string;
  label: string;
};

type Props = {
  control: Control<any>;
  getOptionLabel?: (option: Option | string) => string;
  helperText?: string;
  label?: string;
  limitTags?: number;
  multiple?: boolean;
  muiLabel?: string;
  name: string;
  options: Option[] | string[];
};

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 500,
});

// important: intended to be used inside react hook form
const Autocomplete = ({
  control,
  getOptionLabel,
  helperText,
  label,
  limitTags = 3,
  muiLabel,
  multiple = false,
  name,
  options,
}: Props) => {
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    control,
    name,
  });

  return (
    <FormControl error={invalid} fullWidth>
      {label && <label htmlFor={`${name}-select`}>{label}</label>}
      <MuiAutocomplete
        {...inputProps}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel || ((option) => option || '')}
        multiple={multiple}
        limitTags={limitTags}
        onChange={(_, value) => onChange(value)}
        options={options}
        renderInput={(field) => (
          <TextField {...field} error={invalid} inputRef={ref} variant="outlined" label={muiLabel} />
        )}
      />
      <FormHelperText>{error ? error.message : helperText}</FormHelperText>
    </FormControl>
  );
};

export default Autocomplete;
