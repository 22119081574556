import React, { ReactElement } from 'react';

import { Control, useController } from 'react-hook-form';

import { FormControl, FormHelperText, InputLabel, Select as MuiSelect } from '@mui/material';

type Props = {
  control: Control<any>;
  children: ReactElement | ReactElement[];
  disabled?: boolean;
  helperText?: string;
  label?: string;
  muiLabel?: string;
  multiple?: boolean;
  name: string;
};

// important: intended to be used inside react hook form
const Select = ({ control, children, disabled, helperText, label, muiLabel, multiple = false, name }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <FormControl error={!!error} fullWidth sx={{ position: 'relative' }}>
      {label && <label htmlFor={`${name}-select`}>{label}</label>}
      {muiLabel && <InputLabel>{muiLabel}</InputLabel>}
      <MuiSelect disabled={disabled} label={muiLabel} multiple={multiple} {...field} labelId={`${name}-select`}>
        {children}
      </MuiSelect>
      <FormHelperText>{error ? error.message : helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
