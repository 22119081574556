import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { downloadStripeBalanceTransactionsReport } from '~/services/ReportingService';
import { formatDateISO, startOfMonth, subMonths } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    account: {
      title: 'Select Account',
      type: 'string',
      anyOf: [
        {
          type: 'string',
          enum: ['luxgroup'],
          title: 'Lux Group',
        },
        {
          type: 'string',
          enum: ['le_au'],
          title: 'LE Australia',
        },
        {
          type: 'string',
          enum: ['le_sg'],
          title: 'LE Singapore',
        },
        {
          type: 'string',
          enum: ['le_nz'],
          title: 'LE New Zealand',
        },
        {
          type: 'string',
          enum: ['le_eu'],
          title: 'LE Europe',
        },
        {
          type: 'string',
          enum: ['le_hk'],
          title: 'LE Hong Kong',
        },
        {
          type: 'string',
          enum: ['le_in'],
          title: 'LE India',
        },
        {
          type: 'string',
          enum: ['le_sg_uk'],
          title: 'LE SG UK',
        },
        {
          type: 'string',
          enum: ['le_uk'],
          title: 'LE United Kingdom',
        },
        {
          type: 'string',
          enum: ['le_us'],
          title: 'LE USA',
        },
        {
          type: 'string',
          enum: ['le_us_ny'],
          title: 'LE USA - New York',
        },
        {
          type: 'string',
          enum: ['le_flights_au'],
          title: 'LE Flights Australia',
        },
        {
          type: 'string',
          enum: ['le_flights_us'],
          title: 'LE Flights USA',
        },
        {
          type: 'string',
          enum: ['le_flights_int'],
          title: 'LE Flights International',
        },
        {
          type: 'string',
          enum: ['le_sg_uk_flights'],
          title: 'LE Flights SG UK',
        },
        {
          type: 'string',
          enum: ['le_business_traveller'],
          title: 'LE Business Traveller',
        },
        {
          type: 'string',
          enum: ['cudo_travel'],
          title: 'Cudo Travel',
        },
        {
          type: 'string',
          enum: ['deals_travel'],
          title: 'Deals Travel',
        },
        {
          type: 'string',
          enum: ['scoopon_travel'],
          title: 'Scoopon Travel',
        },
        {
          type: 'string',
          enum: ['treatme_travel'],
          title: 'Treat Me Travel',
        },
        {
          type: 'string',
          enum: ['kogan_travel'],
          title: 'Kogan Travel',
        },
        {
          type: 'string',
          enum: ['kogan_travel_flights'],
          title: 'Kogan Travel Flights',
        },
        {
          type: 'string',
          enum: ['cudo'],
          title: 'Cudo',
        },
        {
          type: 'string',
          enum: ['deals'],
          title: 'Deals',
        },
        {
          type: 'string',
          enum: ['scoopon'],
          title: 'Scoopon',
        },
        {
          type: 'string',
          enum: ['treatme'],
          title: 'Treat Me',
        },
      ],
    },
    fileFormat: {
      title: 'File Format',
      type: 'string',
      enum: ['csv', 'xlsx'],
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  fileFormat: {
    'ui:widget': 'radio',
  },
};

const formData = {
  startAt: formatDateISO(startOfMonth(subMonths(1))),
  endAt: formatDateISO(startOfMonth()),
  fileFormat: 'csv',
};

export default function ExportStripeBalanceTransactionsForm() {
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt, account, fileFormat } = event.formData;

    const fileName = `stripe-balance-transactions_${account}_${startAt}_${endAt}.${fileFormat}`;

    downloadStripeBalanceTransactionsReport({
      startAt,
      endAt,
      account,
      fileFormat,
    })
      .then(function (text) {
        fileDownload(text, fileName);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Download Stripe Balance Transactions Report
      </Button>

      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
