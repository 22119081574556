import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { downloadAllPurchases } from '~/services/ReportingService';
import { diffDays } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

function validate(formData, errors) {
  if (typeof formData.start_at === 'undefined') {
    errors.start_at.addError('Please enter start at date');
  }
  if (typeof formData.end_at === 'undefined') {
    errors.end_at.addError('Please enter end at');
  }

  const startDate = new Date(formData.startAt);
  const endDate = new Date(formData.endAt);

  if (startDate >= endDate) {
    errors.start_at.addError('Start date should be before end date');
  } else if (diffDays(startDate, endDate, 'months') > 2) {
    errors.start_at.addError('Dates range should be less than 3 months');
  }

  return errors;
}

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export',
  type: 'object',
  properties: {
    start_at: {
      title: 'Start at',
      type: 'string',
      format: 'date',
    },
    end_at: {
      title: 'End at',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  start: {
    'ui:widget': 'date',
  },
  end: {
    'ui:widget': 'date',
  },
};

export default function ExportPurchasesForm() {
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { start_at, end_at } = event.formData;

    downloadAllPurchases(start_at, end_at)
      .then(function (text) {
        fileDownload(text, 'orders' + start_at + '_' + end_at + '.csv');
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      onSubmit={handleSubmit}
      validator={validator}
      customValidate={validate}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Download purchases
      </Button>

      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
