/* Purchases/GiftCardDetail/Buttons/ResendConfirmationEmailContainer.js */
import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button } from '@mui/material';

import { resendGiftCardConfirmation } from '~/services/NotificationService';

type Props = {
  orderId: string;
};

export default function ResendConfirmationEmailContainer({ orderId }: Props) {
  const [processingState, setProcessingState] = useState<Utils.ProcessingState>('idle');

  const { enqueueSnackbar } = useSnackbar();

  const sendEmail = async () => {
    try {
      setProcessingState('processing');

      await resendGiftCardConfirmation({ orderId });

      enqueueSnackbar('Successfully resent confirmation email', { variant: 'success' });
      setProcessingState('success');
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setProcessingState('failed');
    }
  };

  return (
    <Button disabled={processingState === 'processing'} variant="text" onClick={sendEmail}>
      Resend Confirmation Email
    </Button>
  );
}
