export type SearchFilters = {
  sessionId?: string;
  bookingNumber?: string;
  customerId?: string;
  orderId?: string;
  departureId?: string;
};

export const SEARCH_FILTERS_DEFAULT: SearchFilters = {
  sessionId: '',
  bookingNumber: '',
  customerId: '',
  orderId: '',
  departureId: '',
};
