import React, { useCallback } from 'react';

import ChainSearchForm from '~/components/Common/Forms/ChainSearchForm';

export default function ChainsSearchContainer() {
  const handleSubmitChain = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }, []);

  return <ChainSearchForm handleSubmitChain={handleSubmitChain} placeholderText="Enter Chain ID or Name" />;
}
