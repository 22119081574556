import React from 'react';

import * as Sentry from '@sentry/browser';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import ConfirmDialogProvider from '~/components/Common/Providers/ConfirmDialogProvider';

import App from './components/App';
import store from './configureStore';
import './scss/main.scss';
import { HomeRoute, init as ReactStormpathInit, Router } from './stormpath';
import { theme } from './theme';
import './timeInit';

/***** configurations */
ReactStormpathInit({
  endpoints: {
    baseUri: window.configs.AUTH_HOST,
  },
});

const releaseVersion = document.getElementById('app-container').getAttribute('data-release-version');

const appEnvironment = document.getElementById('app-container').getAttribute('data-app-env');

if (appEnvironment.startsWith('test') || appEnvironment.startsWith('production')) {
  Sentry.init({
    dsn: 'https://122f65dfe1d549b5a5b5c4ef9ac0073d@sentry.io/1760579',
    environment: appEnvironment,
    release: releaseVersion || 'unreleased',
    ignoreErrors: ['CustomerSubscriptions not found', 'PermissionError'],
  });
}

const appContainerEl = document.getElementById('app-container');

LicenseInfo.setLicenseKey(window.configs.MUI_X_LICENSE_KEY);

// Render app
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
      <Provider store={store}>
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} maxSnack={10}>
          <ConfirmDialogProvider>
            <Router>
              <HomeRoute path="/">
                <App />
              </HomeRoute>
            </Router>
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </Provider>
    </LocalizationProvider>
  </ThemeProvider>,
  appContainerEl,
);
