import React from 'react';

import { LUX_PLUS_SCHEDULE } from '~/consts/schedule';

import { allRegions } from '../../../consts/allRegions';
import { brands, isMultiRegionSchedule, isUnusedBrand } from '../../../consts/brands';
import { getRegionsNameByCode } from '../../../utils/getRegionsNameByCode';
import { groupRegionsBySchedule } from '../../../utils/groupRegionsBySchedule';
import { scheduleForBrandAndType } from '../../../utils/scheduleForBrandAndType';

import ScheduleHistory from './ScheduleHistory';

export default class Visibility extends React.Component {
  formatDate = (date_in) => {
    let date = new Date(date_in);
    return date.toDateString();
  };
  render() {
    const { offerId, schedules, type } = this.props;
    const multiRegionSchedules = groupRegionsBySchedule(schedules, type);
    const groupedLuxPlusSchedules = groupRegionsBySchedule(schedules, LUX_PLUS_SCHEDULE);
    return (
      <div className="fp-visibility">
        {brands
          .filter((brand) => isMultiRegionSchedule(brand.value))
          .map((brand) => {
            const schedules = multiRegionSchedules.filter((offer) => offer.brand === brand.title);
            if (schedules.length > 0) {
              return schedules.map((row) => (
                <div className="schedule-pane" key={row.id}>
                  <div className="lux-schedule-row">
                    <span className="title">{row.brand}</span>
                    <div className="regions">
                      {getRegionsNameByCode(row.regions, allRegions)
                        .filter((region) => !!region)
                        .join(', ')}
                    </div>
                    <div className="lux-schedule-second-column">
                      <div className="date">
                        <div className="date-start">{this.formatDate(row.start)}</div>
                        <div className="arrow">&#8594;</div>
                        <div className="date-end">{this.formatDate(row.end)}</div>
                        <ScheduleHistory offerId={offerId} type={type} brand="luxuryescapes" />
                      </div>
                    </div>
                  </div>
                </div>
              ));
            }
            // if there's no regions, keep default No Schedules row
            return (
              <div className="schedule-pane" key={brand.value}>
                <div className="lux-schedule-row">
                  <span className="title">{brand.title}</span>
                  <div className="lux-schedule-date">
                    <div className="fp-empty">No Schedule Set</div>
                    <ScheduleHistory offerId={offerId} type={type} brand={brand.value} />
                  </div>
                </div>
              </div>
            );
          })}
        {groupedLuxPlusSchedules.length > 0 &&
          groupedLuxPlusSchedules.map((row) => (
            <div className="schedule-pane" key={row.id}>
              <div className="lux-schedule-row">
                <span className="title">Lux Plus</span>
                <div className="regions">
                  {getRegionsNameByCode(row.regions, allRegions)
                    .filter((region) => !!region)
                    .join(', ')}
                </div>
                <div className="lux-schedule-second-column">
                  <div className="date">
                    <div className="date-start">{this.formatDate(row.start)}</div>
                    <div className="arrow">&#8594;</div>
                    <div className="date-end">{this.formatDate(row.end)}</div>
                    <ScheduleHistory offerId={offerId} type={type} brand="luxuryescapes" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        {brands
          .filter((item) => !isMultiRegionSchedule(item.value) && !isUnusedBrand(item.value))
          .map((brand) => {
            const hasScheduleForBrandAndType = scheduleForBrandAndType({
              schedules,
              brand: brand.value,
              type,
              scheduleKey: 'end',
            });
            if (hasScheduleForBrandAndType) {
              return (
                <div className="schedule-pane" key={brand.value}>
                  <div className="lux-schedule-row">
                    <span className="title">{brand.title}</span>
                    <div className="lux-schedule-date">
                      <span>
                        <div className="date-labels">
                          <div className="date-start">
                            {this.formatDate(
                              scheduleForBrandAndType({
                                schedules,
                                brand: brand.value,
                                type,
                                scheduleKey: 'start',
                              }),
                            )}
                          </div>
                          <div className="arrow">&#8594;</div>
                          <div className="date-end">
                            {this.formatDate(
                              scheduleForBrandAndType({
                                schedules,
                                brand: brand.value,
                                type,
                                scheduleKey: 'end',
                              }),
                            )}
                          </div>
                        </div>
                      </span>
                      <ScheduleHistory offerId={offerId} type={type} brand={brand.value} />
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    );
  }
}
