import React from 'react';

import { getFXRates } from '~/services/ReportingService';
import { dateNow } from '~/services/TimeService';

import presentFXRates from '~/utils/presentFXRates';
import { reportError } from '~/utils/reportError';

import ReservationService from '../../../services/ReservationService';
import VendorsService from '../../../services/VendorsService';
import ErrorDisplay from '../../Common/ErrorDisplay';
import Spinner from '../../Common/Spinner';

import RoomTypeDatesEdit from './RoomTypeDatesEdit';
import { dateHasSurcharge, dateIsBlackout, getAvailabilityForDate } from './helpers/availability';
import getCalendarGrid from './helpers/getCalendarGrid';

export default class RoomTypeDatesEditContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.vendorId = match.params.id_vendor;
    this.propertyId = match.params.id_property;
    this.roomTypeId = match.params.id_room_type;
    this.roomRateId = match.params.id_room_rate;

    this.state = {
      loading: true,
      hasError: false,
    };
  }

  componentDidMount() {
    this.fetchData().then(this.setStateFromResponse.bind(this)).catch(this.handleFetchError.bind(this));
  }

  getProperty() {
    return ReservationService.getProperty(this.propertyId);
  }

  getRoomRates() {
    return ReservationService.getRoomRates(this.propertyId, this.roomTypeId);
  }

  getAvailabilitySchema() {
    return ReservationService.getAvailabilitySchema(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getAvailability() {
    return ReservationService.getAvailability(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getBlackoutDates() {
    return ReservationService.getBlackoutDates(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getSurchargeDates() {
    return ReservationService.getSurchargeDates(this.propertyId, this.roomTypeId, this.roomRateId);
  }

  getSurchargeSchema() {
    return ReservationService.getSurchargeDateBlockSchema(this.propertyId, this.roomTypeId);
  }

  getVendor() {
    return VendorsService.getVendorById(this.vendorId);
  }

  getFxRates() {
    return getFXRates();
  }

  async fetchData() {
    const [
      property,
      roomRates,
      availabilitySchema,
      availability,
      blackoutDates,
      surchargeSchema,
      surchargeDates,
      vendor,
      fxRates,
    ] = await Promise.all([
      this.getProperty(),
      this.getRoomRates(),
      this.getAvailabilitySchema(),
      this.getAvailability(),
      this.getBlackoutDates(),
      this.getSurchargeSchema(),
      this.getSurchargeDates(),
      this.getVendor(),
      this.getFxRates(),
    ]);

    return {
      property,
      roomRates,
      availabilitySchema,
      availability,
      blackoutDates,
      surchargeSchema,
      surchargeDates,
      vendor,
      fxRates,
    };
  }

  setStateFromResponsePropertyPartial(response) {
    const roomType = response.result.room_types.find((room_type) => room_type.id === this.roomTypeId);

    return {
      property: response.result,
      channelManaged: response.result.channel_managed,
      roomType,
    };
  }

  setStateFromResponseRoomRates(response) {
    return {
      roomRates: response.result,
    };
  }
  setStateFromResponse(responses) {
    this.setState({
      loading: false,
      ...this.setStateFromResponsePropertyPartial(responses.property),
      availabilitySchema: responses.availabilitySchema.patch.body.schema,
      availability: responses.availability.result,
      blackoutDates: responses.blackoutDates.result.dates,
      fxRates: presentFXRates(responses.fxRates),
      surchargeSchema: responses.surchargeSchema.post.body.schema,
      surchargeDates: responses.surchargeDates.result.dates,
      roomRates: responses.roomRates.result,
      vendor: responses.vendor.result,
    });
  }

  updateAvailability = (updatedRoomRateInventories) => {
    if (updatedRoomRateInventories[this.roomRateId]) {
      this.setState({
        availability: updatedRoomRateInventories[this.roomRateId],
      });
    }
  };

  addBlackoutDateBlocks = (newBlocks) => {
    if (newBlocks[this.roomRateId]) {
      this.setState({
        blackoutDates: [...this.state.blackoutDates, ...newBlocks[this.roomRateId].dates],
      });
    }
  };

  addSurchargeDateBlocks = (newBlocks) => {
    if (newBlocks[this.state.roomType.id]) {
      this.setState({
        surchargeDates: [...this.state.surchargeDates, ...newBlocks[this.state.roomType.id].dates],
      });
    }
  };

  handleFetchError(error) {
    this.setState({
      hasError: true,
      error,
    });
  }

  getGridWithDates = () => {
    const self = this;
    const grid = getCalendarGrid(dateNow(), 40, 0);

    return grid.map((month) => ({
      date: month.date,
      weeks: month.weeks.map((week) => {
        return week.map((day) => ({
          day,
          availability: getAvailabilityForDate(day, self.state.availability),
          blackout: dateIsBlackout(day, self.state.blackoutDates),
          surcharge: dateHasSurcharge(day, self.state.surchargeDates),
        }));
      }),
    }));
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    if (this.state.hasError) {
      return <ErrorDisplay message={this.state.error.message} report={reportError(this.state.error)} />;
    }

    const roomRate = this.state.roomRates.find((roomRate) => roomRate.id === this.roomRateId);

    return (
      <RoomTypeDatesEdit
        grid={this.getGridWithDates()}
        channelManaged={this.state.channelManaged}
        vendorId={this.vendorId}
        vendor={this.state.vendor}
        propertyId={this.propertyId}
        propertyRoomTypes={this.state.property.room_types}
        roomType={this.state.roomType}
        roomRate={roomRate}
        updateAvailability={this.updateAvailability}
        addBlackoutDateBlocks={this.addBlackoutDateBlocks}
        addSurchargeDateBlocks={this.addSurchargeDateBlocks}
        surchargeSchema={this.state.surchargeSchema}
        fxRates={this.state.fxRates}
      />
    );
  }
}
