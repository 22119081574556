import React, { Component } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import RoomRateLeadDaysForm from '~/components/Common/Forms/RoomRateLeadDaysForm';
import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import LeadDaysCalendar from './LeadDaysCalendar';

interface Props {
  leadDays: Array<App.RoomRateLeadDay>;
  leadDaysSchema: any;
  roomType: API.Reservation.RoomType;
  roomRate: API.Reservation.RoomRate;
  property: API.Reservation.Property;
  onUpdate: () => void;
}

interface State {
  manage: boolean;
}

export default class LeadDays extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.openManageBox = this.openManageBox.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.onRoomRateLeadDaysUpdated = this.onRoomRateLeadDaysUpdated.bind(this);

    this.state = {
      manage: false,
    };
  }

  openManageBox() {
    this.setState({ manage: true });
  }

  closeForm() {
    this.setState({
      manage: false,
    });
  }

  onRoomRateLeadDaysUpdated() {
    this.props.onUpdate();
    this.setState({
      manage: false,
    });
  }

  render() {
    return (
      <div className="RoomRateLeadDays">
        <Typography textTransform="uppercase" color="secondary">
          Room Rate Lead Days
        </Typography>

        {this.props.leadDays.length === 0 ? (
          <p>
            <i>Lead days have not been defined</i>
          </p>
        ) : (
          <>
            <LeadDaysCalendar leadDays={this.props.leadDays} />
          </>
        )}
        {this.props.property.lead_days.manage && (
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
            <div>
              <Button variant="contained" startIcon={<AddIcon />} onClick={this.openManageBox}>
                Add
              </Button>
            </div>
          </PermissionedComponent>
        )}

        <Dialog open={this.state.manage} onClose={this.closeForm} maxWidth="xs" fullWidth>
          <DialogContent>
            <RoomRateLeadDaysForm
              leadDaysSchema={this.props.leadDaysSchema}
              propertyId={this.props.property.id}
              roomTypeId={this.props.roomType.id}
              roomRateId={this.props.roomRate.id}
              onCancel={this.closeForm}
              onRoomRateLeadDaysUpdated={this.onRoomRateLeadDaysUpdated}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
