import React, { useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  getGridStringOperators,
} from '@mui/x-data-grid';

import { Inclusion } from '~/components/Common/Forms/InclusionsAssetForm/InclusionsAssetForm';

import InclusionsService from '~/services/InclusionsService';

import { inclusionHasPackage } from '~/utils/inclusionHasPackage';

const containsOnlyFilter = getGridStringOperators().filter((operator) => operator.value === 'contains');

const offersColumns: GridColDef[] = [
  {
    field: 'opportunity_name',
    filterOperators: containsOnlyFilter,
    headerName: 'Offer Opportunity Name',
    sortable: false,
    flex: 2,
    display: 'flex',
  },
  {
    field: 'id_salesforce_external',
    filterOperators: containsOnlyFilter,
    headerName: 'Offer ID',
    sortable: false,
    width: 200,
    display: 'flex',
  },
];

const packagesColumns: GridColDef[] = [
  {
    field: 'deal_option_name',
    filterOperators: containsOnlyFilter,
    headerName: 'Deal Option Name',
    sortable: false,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'offer_name',
    filterOperators: containsOnlyFilter,
    headerName: 'Offer Opportunity Name',
    sortable: false,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'offer_id_salesforce_external',
    filterOperators: containsOnlyFilter,
    headerName: 'Offer ID',
    sortable: false,
    width: 200,
    display: 'flex',
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const STEPS = {
  SELECT_OFFERS: {
    label: 'Select offers',
    index: 0,
  },
  SELECT_PACKAGES: {
    label: 'Select packages',
    index: 1,
  },
  REVIEW_AND_CONFIRM: {
    label: 'Review & confirm',
    index: 2,
  },
};

interface Props {
  onClose: (isSaved?: boolean) => void;
  inclusion: Inclusion;
  isOpen: boolean;
  offers: App.Offer[];
  isOffersDataLoading: boolean;
}

export const AddPackagesToInclusionModal = ({ onClose, inclusion, isOpen, offers, isOffersDataLoading }: Props) => {
  const { id_vendor: vendorId } = useParams<{ id_vendor: string }>();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedOfferIds, setSelectedOfferIds] = useState<GridRowSelectionModel>([]);
  const [selectedPackageIds, setSelectedPackageIds] = useState<GridRowSelectionModel>([]);

  const { enqueueSnackbar } = useSnackbar();

  const isLastStep = activeStep === Object.values(STEPS).length - 1;

  const packages = useMemo(() => {
    if (selectedOfferIds.length === 0) {
      return [];
    }

    const selectedOffers = offers.filter((offer) => selectedOfferIds.includes(offer.id_salesforce_external));

    return selectedOffers
      .map((offer) =>
        offer.packages.map((packageItem) => ({
          ...packageItem,
          offer_name: offer.opportunity_name,
          offer_id_salesforce_external: offer.id_salesforce_external,
        })),
      )
      .flat();
  }, [offers, selectedOfferIds]);

  const isNextButtonDisabled = useMemo(() => {
    if (activeStep === STEPS.SELECT_OFFERS.index) {
      return selectedOfferIds.length === 0;
    }

    if (activeStep === STEPS.SELECT_PACKAGES.index) {
      return selectedPackageIds.length === 0;
    }

    return false;
  }, [activeStep, selectedOfferIds, selectedPackageIds]);

  const handleConfirm = () => {
    InclusionsService.saveInclusionAsset(
      vendorId,
      {
        id: inclusion.id,
        name: inclusion.name,
        is_hidden: inclusion.is_hidden,
        type: inclusion.type || undefined,
        vendor_salesforce_id: inclusion.vendor_salesforce_id,
        packages: selectedPackageIds.map((packageId) =>
          packages.find((pkg) => pkg.id_salesforce_external === packageId),
        ),
      },
      inclusion.id,
    ).then(() => {
      onClose(true);

      setSelectedOfferIds([]);
      setSelectedPackageIds([]);
      setActiveStep(0);

      enqueueSnackbar('Inclusion has been updated successfully', { variant: 'success' });
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    onClose();

    setSelectedOfferIds([]);
    setSelectedPackageIds([]);
    setActiveStep(0);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stepper activeStep={activeStep} sx={{ marginBottom: '20px', marginTop: '10px' }}>
          {Object.values(STEPS).map(({ label }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent sx={{ height: '500px' }}>
        {activeStep === STEPS.SELECT_OFFERS.index && (
          <DataGrid
            checkboxSelection
            columns={offersColumns}
            density="compact"
            getRowId={(row: App.Offer) => row.id_salesforce_external}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            loading={isOffersDataLoading}
            pagination
            rows={offers || []}
            slots={{ toolbar: CustomToolbar }}
            onRowSelectionModelChange={(newSelection) => setSelectedOfferIds(newSelection)}
            rowSelectionModel={selectedOfferIds}
            disableColumnMenu
          />
        )}
        {activeStep === STEPS.SELECT_PACKAGES.index && (
          <DataGrid
            checkboxSelection
            columns={packagesColumns}
            density="compact"
            getRowId={(row) => row.id_salesforce_external}
            initialState={{ pagination: { paginationModel: { pageSize: 10, page: 0 } } }}
            isRowSelectable={({ row }: GridRowParams) => !inclusionHasPackage(inclusion, row.id_salesforce_external)}
            pagination
            rows={packages}
            slots={{ toolbar: CustomToolbar }}
            onRowSelectionModelChange={setSelectedPackageIds}
            rowSelectionModel={selectedPackageIds}
            disableColumnMenu
          />
        )}
        {activeStep === STEPS.REVIEW_AND_CONFIRM.index && (
          <>
            <Typography mb={1}>
              Linking <b>{inclusion.name}</b> to the following packages:
            </Typography>
            <DataGrid
              columns={packagesColumns}
              density="compact"
              getRowId={(row) => row.id_salesforce_external}
              hideFooterPagination
              hideFooterSelectedRowCount
              rows={packages.filter((packageItem) => selectedPackageIds.includes(packageItem.id_salesforce_external))}
              disableColumnMenu
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mx: 2 }}>
        <Button variant="text" onClick={() => handleClose()}>
          Cancel
        </Button>
        <div>
          <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Button variant="contained" onClick={isLastStep ? handleConfirm : handleNext} disabled={isNextButtonDisabled}>
            {isLastStep ? 'Confirm' : 'Next'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
