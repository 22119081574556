import React, { ReactElement } from 'react';

import { Control, useController } from 'react-hook-form';

import { FormControl, FormHelperText, RadioGroup as MuiRadioGroup } from '@mui/material';

interface RadioGroupProps {
  control: Control<any>;
  children: ReactElement | ReactElement[];
  disabled?: boolean;
  helperText?: string;
  name: string;
}

export default function RadioGroup({ control, children, helperText, name }: RadioGroupProps) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  return (
    <FormControl error={!!error} fullWidth sx={{ position: 'relative' }}>
      <MuiRadioGroup {...field}>{children}</MuiRadioGroup>
      <FormHelperText>{error ? error.message : helperText}</FormHelperText>
    </FormControl>
  );
}
