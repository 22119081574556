import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box, Button, ButtonGroup, Container, Stack, Typography } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import { getPurchases } from '~/services/OrdersService';
import { customerPortalHost } from '~/services/common';
import DepartureService from '~/services/cruises/DepartureService';

import SailingCoreChangesDetails from './SailingCoreChangesDetails';
import SailingDetailsForm from './SailingDetailsForm';
import { DATA_FEED_DEFAULT, DEFAULT_SAILING_RESPONSE, PURCHASE_RESPONSE, PurchaseResponse } from './constants';

export default function SailingDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { id_sailing: sailingId } = useParams<{ id_sailing: string }>();
  const tenant = useSelector((state: App.State) => state.tenant);

  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');
  const [sailing, setSailing] = useState<CruisesContract.DepartureByIdResponse>(DEFAULT_SAILING_RESPONSE);

  const [fetchingDataFeed, setFetchingDataFeed] = useState<Utils.FetchingState>('idle');
  const [dataFeed, setDataFeed] = useState<CruisesContract.DataFeedResponse>(DATA_FEED_DEFAULT);

  const [fetchingOrders, setFetchingOrders] = useState<Utils.FetchingState>('idle');
  const [orders, setOrders] = useState<PurchaseResponse>(PURCHASE_RESPONSE);

  const fetchSailing = useCallback(
    async (sailingId: string): Promise<void> => {
      setFetching('loading');

      try {
        const fetchedSailing = await DepartureService.getById(sailingId);

        setFetching('success');

        if (!fetchedSailing) {
          enqueueSnackbar('No results found', { autoHideDuration: 5000, variant: 'warning' });
        }

        setSailing(fetchedSailing.result);
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setSailing(null);
      }
    },
    [enqueueSnackbar],
  );

  const fetchDataFeed = useCallback(
    async (departureId: string): Promise<void> => {
      setFetchingDataFeed('loading');

      const fetchedDataFeed = await DepartureService.getDataFeedByDepartureId(departureId);

      if (!fetchedDataFeed) {
        setFetchingDataFeed('failed');
        setDataFeed(DATA_FEED_DEFAULT);
        enqueueSnackbar('No results found', { autoHideDuration: 5000, variant: 'warning' });
      } else {
        setFetchingDataFeed('success');
        setDataFeed(fetchedDataFeed.result);
      }
    },
    [enqueueSnackbar],
  );

  const fetchPurchases = useCallback(
    async (departureId: string): Promise<void> => {
      if (!departureId) return;
      setFetchingOrders('loading');

      await getPurchases({
        page: 1,
        per_page: 10,
        item_type: 'cruise',
        brand: tenant.brand,
        filterBy: 'departure_id',
        filterValue: departureId,
      })
        .then((res) => {
          setFetchingOrders('success');
          setOrders(res);
        })
        .catch(() => {
          setFetchingOrders('failed');
          setOrders(PURCHASE_RESPONSE);
        });
    },
    [tenant.brand],
  );

  useEffect(() => {
    if (sailingId) {
      fetchSailing(sailingId);
      fetchDataFeed(sailingId);
      fetchPurchases(sailingId);
    }
  }, [fetchDataFeed, fetchPurchases, fetchSailing, sailingId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Cruises | Sailing Details Page</title>
      </Helmet>

      <Box>
        {fetching === 'loading' && <Spinner size={24} />}

        {fetching === 'success' && (
          <Fragment>
            <Box mb={4}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                {sailing.offer.id && (
                  <Stack direction="row" spacing={2}>
                    <Typography>Preview on:</Typography>
                    <ButtonGroup variant="outlined" size="small">
                      <Button
                        target="_blank"
                        rel="noreferrer"
                        href={`${customerPortalHost(tenant)}/cruises/${sailing.offer.id}`}
                      >
                        Customer Portal Cruise Offer Page
                      </Button>
                      <Button target="_blank" rel="noreferrer" href={`/cruises/offer/${sailing.offer.id}`}>
                        Admin Portal Cruise Offer Details Page
                      </Button>
                    </ButtonGroup>
                  </Stack>
                )}

                {fetchingOrders === 'success' && (
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Typography>
                      {/* TODO: create a link to cruise purchases filtered by departure */}
                      THIS SAILING WAS SOLD {orders.total} {pluralize('TIME', orders.total)}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>

            {fetchingDataFeed === 'success' && (
              <Fragment>
                <SailingCoreChangesDetails sailingId={sailingId} offerId={sailing.offer.id} />
              </Fragment>
            )}

            <Box mt={4}>
              <PageSubheader title="Sailing Overview" />
              <SailingDetailsForm sailing={sailing} />
            </Box>

            <Box mt={4}>
              <PageSubheader title={`Last Ingestion Sailing Data - ${dataFeed?.createdAt}`} />

              {fetchingDataFeed === 'loading' && (
                <Box mt={3}>
                  <Spinner size={24} />
                </Box>
              )}

              {fetchingDataFeed === 'success' && (
                <Box
                  sx={{
                    mt: 3,
                    overflow: 'scroll',
                    maxHeight: '700px',
                  }}
                >
                  <pre>{JSON.stringify(dataFeed, null, 2)}</pre>
                </Box>
              )}
            </Box>
          </Fragment>
        )}
      </Box>
    </Container>
  );
}
