import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Accordion, AccordionDetails, AccordionSummary, Link, Stack, Typography } from '@mui/material';

import { formatDateShort } from '~/services/TimeService';

const OrderDepositDetails = ({ depositDetails }) => {
  const formatDate = (cell) => {
    const date = new Date(cell);
    return formatDateShort(date);
  };

  const getStripeSubscriptionLink = () => {
    const subscription = depositDetails?.deposit_metadata?.subscription;
    if (subscription?.id) {
      const isTest = subscription.livemode ? '' : '/test';
      return `https://dashboard.stripe.com${isTest}/subscription_schedules/${subscription.id}`;
    } else {
      return 'N/A';
    }
  };

  const getBalanceAmount = () => {
    return depositDetails?.balance_amount
      ? currencyFormatter.format(depositDetails.balance_amount, {
          code: depositDetails.currency,
          precision: 2,
        })
      : 'N/A';
  };

  const getBalanceDueDate = () => {
    return depositDetails?.balance_due_date ? formatDate(depositDetails.balance_due_date) : 'N/A';
  };

  const getBalancePaidDate = () => {
    return depositDetails?.balance_paid_date ? formatDate(depositDetails.balance_paid_date) : 'N/A';
  };

  const isBalancePaid = !!depositDetails?.balance_paid_date;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Deposit Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Deposit Status
            </Typography>
            <Typography>{depositDetails.deposit_status}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Deposit Amount
            </Typography>
            <Typography>
              {currencyFormatter.format(depositDetails.paid_amount, {
                code: depositDetails.currency,
                precision: 2,
              })}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Due balance amount
            </Typography>
            <Typography>{getBalanceAmount()}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Balance due date
            </Typography>
            <Typography>{getBalanceDueDate()}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Balance Paid Date
            </Typography>
            <Typography>{getBalancePaidDate()}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Balance Paid Status
            </Typography>
            <Typography color={isBalancePaid ? 'green' : 'dark'}>{isBalancePaid ? 'True' : 'False'}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Stripe subscription link
            </Typography>
            <Typography>
              <Link href={getStripeSubscriptionLink()} target="_blank" rel="noreferrer">
                {getStripeSubscriptionLink()}
              </Link>
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Auto debit failure count*
            </Typography>
            <Typography>{depositDetails.due_balance_auto_debit_failed_count}</Typography>
          </Stack>
        </Stack>
        <Typography textAlign="right" variant="subtitle1">
          *indicates how many times the automatic payment for the due balance amount has failed. The booking is
          automatically cancelled after 3 failures.
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderDepositDetails;
