import * as libRegions from '@luxuryescapes/lib-regions';

import { brands } from './brands';
import {
  AVAILABLE_OFFLINE_END,
  AVAILABLE_OFFLINE_START,
  BOOK_BY_DATE_END,
  BOOK_BY_DATE_START,
  CURRENT_LIVE,
  CURRENT_LIVE_OR_PREVIOUSLY_CONTRACTED,
  CURRENT_OFFLINE,
  FRONT_PAGE_END,
  FRONT_PAGE_START,
  PREVIOUS_LIVE,
} from './offerFilterTypes';
import {
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE_HOTEL,
  OFFER_TYPE_RENTAL,
  OFFER_TYPE_TACTICAL_AO_HOTEL,
  OFFER_TYPE_TOUR,
} from './offerTypes';

const regions = libRegions.getRegions();

export const scheduleFiltersOptions = [
  {
    label: 'Book By Date (start date)',
    value: BOOK_BY_DATE_START,
  },
  {
    label: 'Book By Date (end date)',
    value: BOOK_BY_DATE_END,
  },
  {
    label: 'Available Offline (start date)',
    value: AVAILABLE_OFFLINE_START,
  },
  {
    label: 'Available Offline (end date)',
    value: AVAILABLE_OFFLINE_END,
  },
  {
    label: 'Front Page (start)',
    value: FRONT_PAGE_START,
  },
  {
    label: 'Front Page (end)',
    value: FRONT_PAGE_END,
  },
];

export const availabilityFiltersOptions = [
  { label: 'Currently live in', value: CURRENT_LIVE },
  { label: 'Has current offline schedule', value: CURRENT_OFFLINE },
  { label: 'Previously live in', value: PREVIOUS_LIVE },
  {
    label: 'Currently available for sale',
    value: CURRENT_LIVE_OR_PREVIOUSLY_CONTRACTED,
  },
];

export const marketOptions = [{ label: 'Any', value: 'any' }].concat(
  regions.map((region) => ({
    label: region.name,
    value: region.code,
  })),
);

export const searchableOfferTypesOptions = [
  { label: 'Hotel (Limited Time Lux Exclusive)', value: OFFER_TYPE_HOTEL },
  { label: 'Tour', value: OFFER_TYPE_TOUR },
  { label: 'Last Minute Hotel', value: OFFER_TYPE_LAST_MINUTE_HOTEL },
  {
    label: 'Tactical Always On (Lux Premium Collection)',
    value: OFFER_TYPE_TACTICAL_AO_HOTEL,
  },
  {
    label: 'Bundle & Save',
    value: OFFER_TYPE_BUNDLE_AND_SAVE,
  },
  {
    label: 'Rentals',
    value: OFFER_TYPE_RENTAL,
  },
];

export const brandOptions = [{ title: 'Any', value: 'any' }].concat(
  brands.map((brand) => ({
    title: brand.title,
    value: brand.value,
  })),
);

export const customerDateChangeOptions = [
  { label: 'Any', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const flightsEnableOptions = [
  { label: 'Any', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const ledExperienceEnableOptions = [
  { label: 'False', value: 'false' },
  { label: 'True', value: 'true' },
];
