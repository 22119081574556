import React, { useEffect, useState } from 'react';

import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Control, useController } from 'react-hook-form';

import { Box, FormControl, FormHelperText } from '@mui/material';

import { createEditorState } from '../../Forms/helpers/richTextEditorHelper';

import BrandContentSelector from './BrandContentSelector';

const localization = {
  locale: 'en',
  translations: {
    'components.controls.blocktype.h3': (
      <span title="Large Header">
        <img src="/assets/icons/large-header.svg" width="13" alt="Large Header" />
      </span>
    ),
    'components.controls.blocktype.h4': (
      <span title="Small Header">
        <img src="/assets/icons/small-header.svg" width="13" alt="Small Header" />
      </span>
    ),
  },
};

const toolbar = {
  options: ['inline', 'blockType', 'list', 'link', 'history'],
  inline: {
    options: ['bold'],
  },
  blockType: {
    inDropdown: false,
    options: ['H3', 'H4'],
  },
  list: {
    options: ['ordered', 'unordered'],
  },
  history: {
    options: ['undo', 'redo'],
    title: 'History',
  },
};

type Props = {
  control: Control<any>;
  disabled?: boolean;
  helperText?: string;
  label: string;
  name: string;
  canUseBrandTokens?: boolean;
  error?: boolean;
};

// important: intended to be used inside react hook form
// we should think about replacing this component with MUI RTE when it gets released
// https://aussiecommerce.atlassian.net/browse/ENGX-747
const RichTextEditor = ({ control, disabled, helperText, label, name, canUseBrandTokens, error = false }: Props) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  });

  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(markdownToDraft(value))));

  const onEditorStateChange = (editorState: EditorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markdown = draftToMarkdown(rawContentState);

    setEditorState(editorState);
    onChange(markdown);
  };

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const currentValue = draftToMarkdown(rawContentState);

    // we want to fire this only when the value changes from outside, not via the editor onChange event
    // without the if, on each change this would have been called along with onEditorStateChange
    if (currentValue !== value) {
      setEditorState(createEditorState(value, editorState));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const toolbarCustomButtons = [];
  if (canUseBrandTokens) {
    toolbarCustomButtons.push(
      <BrandContentSelector editorState={editorState} onChange={onEditorStateChange} key="BrandContentSelector" />,
    );
  }

  return (
    <FormControl variant="standard" sx={{ display: 'unset' }}>
      <div>
        {label && <label>{label}</label>}
        <Box
          sx={{
            border: '1px solid #ccc',
            backgroundColor: disabled ? '#eeeeee' : '#fff',
          }}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            readOnly={disabled}
            toolbar={toolbar}
            toolbarHidden={disabled}
            wrapperId={name}
            localization={localization}
            editorStyle={{ padding: '0 12px' }}
            toolbarCustomButtons={toolbarCustomButtons}
            error={error}
          />
        </Box>
      </div>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default RichTextEditor;
