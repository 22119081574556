import React, { useCallback, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { ADULT_TITLE_OPTIONS, CHILD_TITLE_OPTIONS, PASSENGER_TYPE_OPTIONS } from '~/consts/flight';

import { Passenger } from './AtolPage';

type Props = {
  index: number;
  passenger: Passenger;
  onRemove: (index: number) => void;
  onUpdate: (index: number, name: string, value: string) => void;
};

function PassengerForm(props: Props) {
  const { index, passenger, onRemove, onUpdate } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      onUpdate(index, name, value);
    },
    [index, onUpdate],
  );

  const travellerTitles = useMemo(() => {
    if (passenger.type) {
      if (passenger.type === 'adult') return ADULT_TITLE_OPTIONS;
      return CHILD_TITLE_OPTIONS;
    }
    return ADULT_TITLE_OPTIONS;
  }, [passenger.type]);

  return (
    <>
      <Grid item xs={12} md={2}>
        <FormControl fullWidth>
          <InputLabel id="title-label">Type</InputLabel>
          <Select required name="type" label="Type" value={passenger.type} onChange={handleChange}>
            {PASSENGER_TYPE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={1}>
        <FormControl fullWidth>
          <InputLabel id="title-label">Title</InputLabel>
          <Select required name="title" label="Title" value={passenger.title} onChange={handleChange}>
            {travellerTitles.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          required
          fullWidth
          name="givenName"
          label="First Name"
          value={passenger.givenName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          name="middleName"
          label="Middle Name (Optional)"
          value={passenger.middleName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={3} display="flex" flexDirection="row">
        <TextField
          required
          fullWidth
          label="Last Name"
          name="surname"
          value={passenger.surname}
          onChange={handleChange}
        />
        {index !== 0 && (
          <Button
            color="secondary"
            variant="text"
            onClick={() => onRemove(index)}
            sx={{ minHeight: 0, minWidth: 0, padding: 0, marginLeft: 2 }}
          >
            <CloseIcon />
          </Button>
        )}
      </Grid>
    </>
  );
}

export default PassengerForm;
