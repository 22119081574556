import React, { useCallback, useEffect, useState } from 'react';

import ReactJson, { CollapsedFieldProps } from '@microlink/react-json-view';
import { useParams } from 'react-router-dom';

import { Check, Close } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardContent, Container, Grid, Stack, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { formatDateOrdinalWithClock } from '~/services/TimeService';
import cruiseService, { BookingStepsByBookingIdResponse } from '~/services/cruises/BookingInfoService';

type BookingStep = BookingStepsByBookingIdResponse['result'][0];

export default function BookingLogsPage() {
  const params = useParams<{ booking_id: string }>();

  const bookingId = params.booking_id;

  const [bookingSteps, setBookingSteps] = useState<BookingStep[] | null>(null);
  const [bookingStep, setBookingStep] = useState<BookingStep | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getBookingSteps = useCallback(
    async (bookingId: string): Promise<void> => {
      try {
        const response = await cruiseService.getBookingStepsByBookingId(bookingId);

        if (!response || !response.result?.length) {
          setError('No booking logs found');

          return;
        }

        setBookingSteps(response.result);
      } catch (error) {
        setError(error.message);
      }
    },
    [bookingId],
  );

  const getBookingStepIcon = useCallback((bookingStep: BookingStep) => {
    const status = bookingStep.revelexResponse.status as number | null | undefined;
    const error = bookingStep.revelexResponse?.['data']?.['error'] as Record<string, unknown> | undefined;

    return status && status >= 200 && status <= 299 && !error ? <Check color="success" /> : <Close color="error" />;
  }, []);

  const [collapseRequest, setCollapseRequest] = useState(true);

  const collapseRequestConfigCallback = useCallback(
    (field: CollapsedFieldProps) => (field.name == 'root' ? false : collapseRequest),
    [collapseRequest],
  );

  const toggleCollapseRequest = useCallback(() => {
    setCollapseRequest((prev) => !prev);
  }, []);

  const [collapseResponse, setCollapseResponse] = useState(true);

  const collapseResponseConfigCallback = useCallback(
    (field: CollapsedFieldProps) => (field.name == 'root' ? false : collapseResponse),
    [collapseResponse],
  );

  const toggleCollapseResponse = useCallback(() => {
    setCollapseResponse((prev) => !prev);
  }, []);

  useEffect(() => {
    getBookingSteps(bookingId);
  }, [bookingId, getBookingSteps]);

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <Container maxWidth="xl">
      {bookingSteps && bookingSteps.length > 0 && (
        <Box sx={{ display: 'grid' }}>
          <Grid container spacing={1} columns={8}>
            <Grid item xs={2} boxShadow={1}>
              <Typography variant="overline" fontSize={24} p={2} gutterBottom>
                Booking Steps
              </Typography>
            </Grid>
            <Grid item xs={6} boxShadow={1}>
              <Typography variant="overline" fontSize={24} p={2} gutterBottom>
                {(bookingStep ? bookingStep.step + ' ' : '') + 'Request / Response'}
              </Typography>
            </Grid>
            <Grid item xs={2} boxShadow={1}>
              <Box position="sticky" top="0">
                {bookingSteps.map((_bookingStep, index) => (
                  <Card
                    sx={{
                      bgcolor: _bookingStep.id === bookingStep?.id ? 'lightcyan' : 'unset',
                      marginRight: '8px',
                      paddingRight: '1px',
                    }}
                  >
                    <CardActionArea onClick={() => setBookingStep(_bookingStep)}>
                      <CardContent>
                        <Stack alignItems="center" direction="row" gap={2}>
                          <Typography variant="overline" fontSize={16} gutterBottom noWrap>
                            {`${index + 1}. ${_bookingStep.step}`}
                          </Typography>
                          {getBookingStepIcon(_bookingStep)}
                        </Stack>
                        <Stack alignItems="flex-start" direction="row">
                          <Typography variant="body2" color="text.secondary">
                            {formatDateOrdinalWithClock(_bookingStep.createdAt)}
                          </Typography>
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            </Grid>
            <Grid item xs={6} boxShadow={1}>
              {!bookingStep && (
                <Typography variant="overline" fontSize={16} display="block" p={2} gutterBottom>
                  Nothing to show
                </Typography>
              )}
              {bookingStep && (
                <Box component="main" p={2}>
                  <Box component="span">
                    <Typography variant="overline" fontSize={16} display="block" gutterBottom>
                      Revelex Request
                    </Typography>
                    <Button onClick={toggleCollapseRequest} variant="outlined" size="small">
                      {collapseRequest ? 'Expand All' : 'Collapse All'}
                    </Button>
                    <Typography paragraph>
                      <ReactJson
                        src={bookingStep.revelexRequest}
                        displayDataTypes={false}
                        shouldCollapse={collapseRequestConfigCallback}
                      />
                    </Typography>
                  </Box>
                  <Box component="span">
                    <Typography variant="overline" fontSize={16} display="block" gutterBottom>
                      Revelex Response
                    </Typography>
                    <Button onClick={toggleCollapseResponse} variant="outlined" size="small">
                      {collapseResponse ? 'Expand All' : 'Collapse All'}
                    </Button>
                    <Typography paragraph>
                      <ReactJson
                        src={bookingStep.revelexResponse}
                        displayDataTypes={false}
                        shouldCollapse={collapseResponseConfigCallback}
                      />
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}
