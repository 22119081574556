import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { FDATableSchema } from '~/services/FlightsService';

import FDAFormInput from './FDAFormInput';

type FormData = Record<string, string | number | boolean>;

interface Props {
  onClose: () => void;
  onSave?: (data: FormData) => void;
  schema?: FDATableSchema[];
  initialValues?: FormData;
  loading?: boolean;
  open: boolean;
  readOnly?: boolean;
  title: string;
}

const getDefaultValues = (schema: FDATableSchema[]) => {
  return schema.reduce((acc, field) => {
    if (field.dataType === 'boolean') acc[field.name] = false;
    else acc[field.name] = null;

    return acc;
  }, {});
};

export default function FDAFormDialog(props: Props) {
  const { onClose, open, title, onSave, schema = [], initialValues = {}, loading, readOnly } = props;
  const [data, setData] = useState({ ...getDefaultValues(schema), ...initialValues });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        component: readOnly ? 'div' : 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSave?.({ ...getDefaultValues(schema), ...data });
        },
      }}
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} py={2}>
          {schema.map((field) => (
            <FDAFormInput
              key={field.name}
              onChange={(value) => setData((prevData) => ({ ...prevData, [field.name]: value }))}
              value={data[field.name]}
              dataType={field.dataType}
              displayName={field.displayName}
              name={field.name}
              required={!field.allowNull}
              maxLength={field.maxLength}
              readOnly={readOnly}
            />
          ))}
        </Box>
      </DialogContent>

      {!readOnly && (
        <DialogActions>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton variant="outlined" type="submit" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
