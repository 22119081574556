import React from 'react';

import { Box, Button } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

export default function AtolHeader({
  version_count,
  downloadCertificate,
  resendCertificate,
  toggleEditForm,
}: {
  version_count: number;
  downloadCertificate: () => void;
  resendCertificate: () => void;
  toggleEditForm: () => void;
}) {
  return (
    <Box display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
      <div>
        <DefinitionTitle>Version Count</DefinitionTitle>
        <DefinitionText>{version_count}</DefinitionText>
      </div>

      <div>
        <DefinitionTitle>Download</DefinitionTitle>
        <div>
          <Button onClick={downloadCertificate} type="button">
            Download
          </Button>
        </div>
      </div>

      <div>
        <DefinitionTitle>Resend</DefinitionTitle>
        <div>
          <Button onClick={resendCertificate} type="button">
            Resend
          </Button>
        </div>
      </div>
      <div>
        <DefinitionTitle>Edit</DefinitionTitle>
        <div>
          <Button onClick={toggleEditForm} type="button">
            Edit
          </Button>
        </div>
      </div>
    </Box>
  );
}
