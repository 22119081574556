import { GridRenderCellParams } from '@mui/x-data-grid';

const flightPNRIDFormatter = ({ row }: GridRenderCellParams<App.Order>) => {
  if (row.offline_flight_items && row.offline_flight_items.length) {
    return row.offline_flight_items.map((item) => (item.pnr_ids ? item.pnr_ids.join(', ') : null));
  }
  return row.flight_items.map((item) => item.pnr_id);
};

export default flightPNRIDFormatter;
