import React, { useMemo } from 'react';

import { Dayjs } from 'dayjs';

import { Skeleton, Typography } from '@mui/material';

import useHeroPlannerOfferVisibilities from '~/hooks/customerCommunication/useHeroPlannerOfferVisibilities';

import { isRequestUnresolved } from '~/utils/requestUtils';

interface Props {
  scheduleSendDate: Dayjs;
  countryGroupId: string;
  countryId: string;
  offerId: string;
}

function ScheduleOfferVisibilityBlock(props: Props) {
  const { scheduleSendDate, countryGroupId, countryId, offerId } = props;
  const { offerVisibilitiesReq } = useHeroPlannerOfferVisibilities(countryGroupId, countryId, offerId);

  const visibilities = useMemo(() => {
    if (!offerVisibilitiesReq || isRequestUnresolved(offerVisibilitiesReq)) return;

    return Object.values(offerVisibilitiesReq.result).map((v) => {
      const isLiveOnSendDate = scheduleSendDate.isBetween(v.start, v.end, 'date', '[]');

      return {
        countryId: v.countryId,
        dateRange: v.start && v.end ? `${v.start.format('YYYY-MM-DD')} — ${v.end.format('YYYY-MM-DD')}` : undefined,
        isLiveOnSendDate,
      };
    });
  }, [offerVisibilitiesReq, scheduleSendDate]);

  if (!visibilities)
    return (
      <Typography variant="body2">
        <Skeleton width={192} />
      </Typography>
    );

  return (
    <div>
      <Typography variant="body2"></Typography>
      {visibilities.map((visibility) => (
        <Typography
          key={visibility.countryId}
          variant="body2"
          color={!visibility.isLiveOnSendDate || !visibility.dateRange ? 'error' : undefined}
        >
          <span>{visibility.countryId}: </span>
          {!!visibility.dateRange && <b>{visibility.dateRange}</b>}
          {!visibility.dateRange && <i>Unavailable</i>}
        </Typography>
      ))}
    </div>
  );
}

export default ScheduleOfferVisibilityBlock;
