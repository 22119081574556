import React, { useEffect, useMemo, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';

import { Checkbox, Input, Select } from '~/components/Common/Forms/fields';
import { AddPackagesToInclusionModal } from '~/components/Vendors/Profile/AddPackagesToInclusionModal';
import { RemovePackagesFromInclusionModal } from '~/components/Vendors/Profile/RemovePackagesFromInclusionModal';

import { OFFER_TYPE_HOTEL } from '~/consts/offerTypes';

import useToggleState from '~/hooks/useToggleState';

import OffersService from '~/services/OffersService';

import InclusionsService from '../../../../services/InclusionsService';
import { useConfirmDialog } from '../../Providers/ConfirmDialogProvider';

import getDefaultValues from './defaultValues';
import { saveCustomInclusionAsset, saveSalesforceInclusionAsset } from './utils';
import inclusionAssetFormSchema from './validation';

export const COLUMNS: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'opportunity_name',
    headerName: 'Opportunity name',
    flex: 1,
    minWidth: 150,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'offer_id',
    headerName: 'Offer ID',
    minWidth: 200,
    display: 'flex',
    renderCell: (params: GridRenderCellParams) => (
      <Button variant="text" target="_blank" href={`/offers/${params.row.offer_id}`}>
        {params.row.offer_id}
      </Button>
    ),
  },
  {
    disableColumnMenu: true,
    field: 'deal_option_name',
    headerName: 'Package',
    flex: 1,
    minWidth: 150,
    display: 'flex',
  },
  {
    disableColumnMenu: true,
    field: 'package_id',
    filterable: false,
    headerName: 'Package',
    sortable: false,
    maxWidth: 125,
    display: 'flex',
    renderCell: (params: GridRenderCellParams) => (
      <Button variant="text" target="_blank" href={`/edit-offers/${params.row.offer_id}/packages`}>
        View
      </Button>
    ),
  },
];

export interface Inclusion {
  id?: string;
  id_salesforce_external?: string;
  vendor_salesforce_id: string;
  name: string;
  salesforce_name?: string;
  description?: string;
  currency?: string;
  type: string;
  category_id?: string;
  unit?: string;
  rrp?: number;
  is_hidden?: boolean;
  packages?: Package[];
  // last_validated_on: string;
}

interface Package extends Partial<Inclusion> {
  offer_id: string;
  package_id: string;
  deal_option_name: string;
}

interface InclusionsCategories {
  id: string;
  category: string;
  name: string;
  icon: string;
}

interface Props {
  fetchData: () => Promise<void>;
  inclusion: Inclusion;
  vendorId: string;
  isOpen: boolean;
  handleClose: (isSaved?: boolean) => void;
  selectedOfferId?: string;
  paginationModel?: GridPaginationModel;
}

export function InclusionsAssetForm({ fetchData, inclusion, vendorId, isOpen, handleClose }: Props) {
  const [inclusionsCategories, setInclusionCategories] = useState<InclusionsCategories[]>([]);
  const [currentCategory, setCurrentCategory] = useState<InclusionsCategories>(null);
  const [isLinkInclusionModalOpen, setIsLinkInclusionModalOpen] = useState(false);
  const [isUnlinkInclusionsModalOpen, setIsUnlinkInclusionsModalOpen] = useState(false);
  const [offers, setOffers] = useState([]);
  const [isOffersDataLoading, setOffersDataLoading] = useState(false);
  const {
    isToggled: isOfferInfoModalOpen,
    toggleOn: openOfferInfoModal,
    toggleOff: closeOfferInfoModal,
  } = useToggleState();
  const formRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const showConfirmDialog = useConfirmDialog();

  const { control, reset, handleSubmit, setValue, formState } = useForm({
    defaultValues: getDefaultValues(inclusion),
    mode: 'onBlur',
    resolver: yupResolver(inclusionAssetFormSchema),
  });

  const isCustomInclusion = !inclusion?.id_salesforce_external;
  const isInEditMode = !!inclusion;

  const onSubmit = async (data: Inclusion) => {
    const body = !inclusion?.id_salesforce_external
      ? saveCustomInclusionAsset(data, vendorId)
      : saveSalesforceInclusionAsset(data, vendorId);

    try {
      await InclusionsService.saveInclusionAsset(vendorId, body, data.id);
      handleClose(true);

      enqueueSnackbar('Inclusion saved', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Error saving inclusion asset: ${e.message}`, { variant: 'error' });
    }
  };

  const onAddPackagesModalClose = (isSaved = false) => {
    setIsLinkInclusionModalOpen(false);

    if (isSaved) {
      fetchData();
    }
  };

  const onRemovePackagesModalClose = (isSaved = false) => {
    setIsUnlinkInclusionsModalOpen(false);

    if (isSaved) {
      fetchData();
    }
  };

  const onAutocompleteChange = (newValue: InclusionsCategories | null, name: string) => {
    setCurrentCategory(newValue);
    setValue(name, newValue.id, { shouldDirty: true });
  };

  const clearAndClose = () => {
    setCurrentCategory(null);
    reset(getDefaultValues());
    handleClose();
  };

  const onInclusionDeleteClick = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete this inclusion?',
      description:
        mappedPackages.length > 0
          ? 'This inclusion is linked to packages. Deleting will unlink it from all the packages.'
          : 'Deleting will remove it permanently from the inclusion catalogue.',
    });

    if (confirmed) {
      try {
        await InclusionsService.deleteInclusion(inclusion.id);

        handleClose(true);
        enqueueSnackbar('Inclusion deleted', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting inclusion', { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    InclusionsService.getInclusionsCategories()
      .then(({ result }) => {
        setInclusionCategories(result);
        setCurrentCategory(result?.find((category) => category.id === inclusion?.category_id));
      })
      .catch((error) => enqueueSnackbar(`Error fetching inclusion categories: ${error.message}`, { variant: 'error' }));
  }, [vendorId, reset, enqueueSnackbar, inclusion?.category_id]);

  useEffect(() => {
    setOffersDataLoading(true);

    OffersService.getOffers({
      limit: 10000,
      orderByScheduleDate: 0,
      page: 0,
      queryString: vendorId,
      type: OFFER_TYPE_HOTEL,
    })
      .then((response) => setOffers(response.result))
      .finally(() => setOffersDataLoading(false));
  }, [vendorId]);

  const mappedPackages = useMemo(() => {
    const packagesMap = new Map(
      offers
        .flatMap((offer) => offer.packages)
        .map((offerPackage: Package) => [offerPackage.id_salesforce_external, offerPackage]),
    );

    return (
      inclusion?.packages?.map((pack) => {
        if (packagesMap.has(pack.package_id)) {
          const dealOption = packagesMap.get(pack.package_id);
          return { ...pack, deal_option_name: dealOption.deal_option_name };
        }
        return pack;
      }) ?? []
    );
  }, [inclusion?.packages, offers]);

  const shouldShowCategoryAutocomplete =
    (!inclusion && inclusionsCategories.length) ||
    (inclusion && !inclusion?.category_id) ||
    (inclusion?.category_id && currentCategory);

  // counst uniq offer ids in offers
  const numberOfUniqeOffers = useMemo(() => {
    const uniqeOffers = new Set(mappedPackages.map((pack) => pack.offer_id));
    return uniqeOffers.size;
  }, [mappedPackages]);

  const uniqueOfferIds = useMemo(() => {
    const uniqeOffers = new Set(mappedPackages.map((pack) => pack.offer_id));
    return Array.from(uniqeOffers).map((offerId) => {
      const mappedPac: any = mappedPackages.find((pack) => pack.offer_id === offerId);
      return { id: offerId, opportunity_name: mappedPac.opportunity_name };
    });
  }, [mappedPackages]);

  const onAgree = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={clearAndClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
      maxWidth="md"
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <DialogTitle id="scroll-dialog-title">{inclusion ? 'Edit' : 'Create'} Inclusion Asset</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container mt={1} spacing={2}>
              <Grid item sm={12}>
                <Input control={control} fullWidth muiLabel="Name" name="name" />
              </Grid>
              {!!inclusion?.id_salesforce_external && (
                <>
                  <Grid item sm={12}>
                    <Input
                      control={control}
                      disabled
                      fullWidth
                      muiLabel="Inclusion salesforce name"
                      name="salesforce_name"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Input
                      control={control}
                      disabled={!!inclusion?.id_salesforce_external}
                      fullWidth
                      muiLabel="Inclusion detail"
                      name="description"
                    />
                  </Grid>
                </>
              )}
              <Grid item sm={5}>
                <Select control={control} muiLabel="Type" name="type">
                  <MenuItem key="Flash" value="Flash">
                    LTLE
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item sm={7}>
                {shouldShowCategoryAutocomplete && (
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disableClearable
                        groupBy={(option) => option.category}
                        getOptionLabel={(option) => option.name || option}
                        isOptionEqualToValue={(option, value) => option.name === value}
                        onChange={(e: any, newValue: any) => onAutocompleteChange(newValue, 'category_id')}
                        options={inclusionsCategories?.sort((a, b) => -b.category.localeCompare(a.category))}
                        renderInput={(params) => <TextField {...params} label="Category" />}
                        value={inclusionsCategories.find((category) => category.id === field.value)}
                      />
                    )}
                    name="category_id"
                    control={control}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Input
                  control={control}
                  disabled={!!inclusion?.id_salesforce_external}
                  fullWidth
                  muiLabel="Currency"
                  name="currency"
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  control={control}
                  disabled={!!inclusion?.id_salesforce_external}
                  fullWidth
                  muiLabel="RRP"
                  name="rrp"
                />
              </Grid>
              <Grid item xs={4}>
                <Select control={control} muiLabel="Unit" name="unit">
                  <MenuItem key="Daily" value="Daily">
                    Daily
                  </MenuItem>
                  <MenuItem key="Per Stay" value="Per Stay">
                    Per Stay
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container mt={2} sm={6}>
              <Checkbox control={control} name="is_hidden" label="Hidden" />
            </Grid>
            {inclusion && (
              <Grid container mt={1} spacing={2} columns={24}>
                <Grid item sm={14}>
                  <Typography variant="h5" noWrap>
                    Linked Offers ({numberOfUniqeOffers})
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <Button disabled={mappedPackages.length === 0} onClick={() => setIsUnlinkInclusionsModalOpen(true)}>
                    Unlink packages
                  </Button>
                </Grid>
                <Grid item sm={5}>
                  <Button onClick={() => setIsLinkInclusionModalOpen(true)}>Assign to packages</Button>
                </Grid>
                <Grid item sm={24}>
                  <DataGrid
                    autoHeight
                    columns={COLUMNS}
                    hideFooter
                    rowCount={mappedPackages.length}
                    rows={mappedPackages || []}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isInEditMode && isCustomInclusion ? 'space-between' : 'flex-end',
            pl: 3,
            pr: 3,
          }}
        >
          {isInEditMode && isCustomInclusion && (
            <Button color="error" variant="contained" onClick={onInclusionDeleteClick}>
              Delete
            </Button>
          )}
          <div>
            <Button onClick={() => handleClose()}>Close</Button>
            <Button disabled={!formState.isDirty} onClick={openOfferInfoModal} variant="contained">
              Save
            </Button>
          </div>
        </DialogActions>
        <Dialog open={isOfferInfoModalOpen} onClose={closeOfferInfoModal} scroll="paper" maxWidth={false}>
          <DialogTitle id="scroll-dialog-title">This inclusion is linked to the following offers:</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <DataGrid
                autoHeight
                columns={[
                  {
                    field: 'id',
                    headerName: 'Offer ID',
                    flex: 3,
                    renderCell: (params: GridRenderCellParams) => (
                      <Button variant="text" target="_blank" href={`/offers/${params.row.id}`}>
                        {params.row.id}
                      </Button>
                    ),
                  },
                  { field: 'opportunity_name', headerName: 'Opportunity name', flex: 8 },
                ]}
                hideFooter
                rowCount={uniqueOfferIds.length}
                rows={uniqueOfferIds || []}
                sx={{ minWidth: 850 }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeOfferInfoModal}>
              Close
            </Button>
            <Button disabled={!formState.isDirty} onClick={onAgree} color="success" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>

      <AddPackagesToInclusionModal
        onClose={onAddPackagesModalClose}
        inclusion={inclusion}
        isOpen={isLinkInclusionModalOpen}
        offers={offers}
        isOffersDataLoading={isOffersDataLoading}
      />
      <RemovePackagesFromInclusionModal
        onClose={onRemovePackagesModalClose}
        inclusion={inclusion}
        isOpen={isUnlinkInclusionsModalOpen}
      />
    </Dialog>
  );
}
