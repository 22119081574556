export const LEVELS: { [key: string]: Level } = {
  PASS: 0,
  WARNING: 1,
  ERROR: 2,
};

export type Level = 0 | 1 | 2;

export interface IConditionChecks {
  [key: string]: {
    [key: string]: ICondition;
  }; // logical grouping of checks
}

export enum Status {
  Pass = 0, // Pass status
  Warning = 1, // Warning status
  Fail = 2, // Critical failure status
  Info = -1, // Informational status
}

export interface ICondition {
  thresholds?:
    | {
        type: 'gt' | 'lt' | 'eq';
        /**
         * An array of three numbers to compare against the check function result.
         * Each index represents a different status:
         * - 0: pass
         * - 1: warning
         * - 2: critical failure
         */
        levels: [number, number, number];
      }
    | ((resultOutput) => Level);
  prefetch?: (offer: App.Offer, property: App.Property) => Promise<any>; // async function to prefetch data for the check
  check: (offer: App.Offer, property: App.Property, prefetchResult?: any) => boolean | number; // pass fail or amount to check/threshold
  result: (offer: App.Offer, property: App.Property, prefetchResult?: any, linkBuilder?: any) => any; // result of the check, returned for display (i.e list of rate plans that fail a check)
  label: string; // Display title of the check
  summary?: (resultOutput: any) => string; // Display message for this check based on result
  level?: number; // lowest level of failure, 0 is pass, 1 is warning, 2 is critical failure, -1 is informational only (controls colour)
  hidden?: (offer: App.Offer, property: App.Property) => boolean; // if true will not be shown - for offer type specific checks
  nonCritical?: boolean; // if true will not be counted in critical errors
  helpText?: string; // if present will show a help icon with this text on hover
  linkBuilder?: (...args) => string; // function to build a link to the relevant page in the admin portal will be passed to result function
  canExpand?: boolean; // if true clicking summary expands to show the result - default true
  [key: string]: any;
}
