import React, { useCallback } from 'react';

import { Box, TextField, TextFieldProps } from '@mui/material';

import { Spacing } from '../../helpers';

import * as S from './styles';

type ChangeParams = {
  value: string;
  range?: 'min' | 'max';
};

type FieldProps = {
  labelName?: string;
  rangeName?: 'min' | 'max';
  onChange: (params: ChangeParams) => void;
} & Omit<TextFieldProps, 'onChange'> &
  Spacing;

export const Field: React.FC<FieldProps> = (props) => {
  const { id, name, value, onChange, placeholder, labelName, rangeName, ...rest } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ value: e.target.value, range: rangeName ?? null });
    },
    [onChange, rangeName],
  );

  return (
    <Box>
      {labelName && <S.Label htmlFor={id}>{labelName}</S.Label>}
      <TextField {...rest} id={id} name={name} value={value} onChange={handleChange} placeholder={placeholder} />
    </Box>
  );
};
