import React from 'react';

import { Button } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { formatDateSlashes } from '~/services/TimeService';

import Spinner from '../Common/Spinner';

import { Guest } from './StoplistPage';

interface Props {
  guests: Guest[];
  onDelete: (guestId: string) => void;
}

export const StoplistTable = ({ guests, onDelete }: Props) => {
  const showConfirmDialog = useConfirmDialog();

  const handleDeleteClick = async (guestId: string) => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete this guest?',
    });

    if (confirmed) {
      onDelete(guestId);
    }
  };

  const actionColumn = (params: GridRenderCellParams) => {
    return (
      <div>
        <Button
          color="error"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleDeleteClick(params.row.guest_id);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'guest_id',
      headerName: 'Guest Id',
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'email',
      headerName: 'Email',
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      disableColumnMenu: true,
      valueFormatter: (value) => (value ? formatDateSlashes(value) : ''),
      flex: 1,
      display: 'flex',
    },
    {
      field: 'passport_number',
      headerName: 'Passport Number',
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'contact_number',
      headerName: 'Contact Number',
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      valueFormatter: (value) => formatDateSlashes(value),
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'action',
      headerName: 'Action',
      disableColumnMenu: true,
      flex: 1,
      renderCell: actionColumn,
      display: 'flex',
    },
  ];

  if (!guests) {
    return <Spinner />;
  }

  return (
    <DataGrid
      autoHeight
      columns={columns}
      getRowId={(row) => row.guest_id}
      rows={guests}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      density={'compact'}
    />
  );
};
