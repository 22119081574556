import React, { useEffect } from 'react';

import { TourDetailsFormResources, useTourDetailsFormQuery } from '~/queries/tours/useTourDetailsFormQueries';

import { Alert, AlertTitle, Button, CircularProgress } from '@mui/material';

import ExtendedRJSForm from '../RJSF/ExtendedRJSForm';

interface Props {
  tourId: string;
}

export default function TourInventoryDetailsForm(props: Props) {
  const { tourId } = props;

  const { fetchRequestInstance, fetch } = useTourDetailsFormQuery(TourDetailsFormResources.INVENTORY);

  useEffect(() => {
    fetch(tourId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId]);

  if (fetchRequestInstance.status === 'pending' || fetchRequestInstance.status === 'uninitiated') {
    return <CircularProgress />;
  }

  if (fetchRequestInstance.status === 'failed') {
    return (
      <Alert
        severity="error"
        action={
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              fetch(tourId);
            }}
          >
            Retry
          </Button>
        }
      >
        <AlertTitle>Failed to fetch the form.</AlertTitle>
        {fetchRequestInstance.error}
      </Alert>
    );
  }

  const { result } = fetchRequestInstance;
  return (
    <>
      <ExtendedRJSForm
        id="tour-campaign-schedule-details-form"
        schema={result.schema}
        uiSchema={result.uiSchema}
        formData={result.formData}
        hideSubmitButton
      />
    </>
  );
}
