import styled from 'styled-components';

import { DataGrid } from '@mui/x-data-grid';

// Fix for no trips found overlay height being 0
export const TripPlannerDataGrid = styled(DataGrid)`
  &.no-trip-items {
    .MuiDataGrid-overlayWrapper {
      height: 50px;
    }
    .MuiDataGrid-overlayWrapperInner {
      height: 50px;
    }
  }
`;
