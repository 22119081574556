import React from 'react';

import { Box, Typography } from '@mui/material';

import AmenityGroupsSearchResults from '~/components/Amenities/AmenityGroupsSearchResults';
import Spinner from '~/components/Common/Spinner';

interface Props {
  groups: App.AmenityGroup[];
  isSearching: boolean;
}

export default function AmenityGroupContainer({ groups, isSearching }: Props) {
  return (
    <Box mt={4}>
      {isSearching && (
        <Box>
          <Spinner />
        </Box>
      )}

      {!isSearching && !groups.length && (
        <Box>
          <Typography variant="subtitle1">No amenity groups found</Typography>
        </Box>
      )}

      {groups.length > 0 && (
        <Box>
          <AmenityGroupsSearchResults data={groups} />
        </Box>
      )}
    </Box>
  );
}
