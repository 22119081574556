import { PromotionDeposit, PromotionOnBoardCredit } from '~/components/Cruises/types';

import { RateCodeFormValues } from './types';

export function buildDepositDetails(deposit: PromotionDeposit): string {
  const { type, amount, currencyCode } = deposit;
  if (type === 'PERCENTAGE') return `${amount}%`;
  else
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: 2,
    }).format(amount);
}

export function buildOnBoardCreditDetails(onBoardCredit: PromotionOnBoardCredit): string {
  const { amount, currencyCode } = onBoardCredit;
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 2,
  }).format(amount);
}

export const hasRequiredFields = (values: RateCodeFormValues): boolean => {
  return typeof values.isFlash !== 'undefined';
};
