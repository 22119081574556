import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Container, Stack, Typography } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import TourOptionPane from './TourOptionPane';
import TourPane from './TourPane';
import TourReviewsPane from './TourReviewsPane';

export default function TourPageContainer() {
  const { id_vendor: vendorId, id_tour: tourId } = useParams<{ id_vendor: string; id_tour: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState<App.Vendor | null>(null);
  const [tour, setTour] = useState<API.Reservation.Tour>(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([ReservationService.getTour(tourId), VendorsService.getVendorById(vendorId)])
      .then(([tour, vendor]) => {
        setTour(tour.result);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, tourId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (!vendor || !tour) {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load tour or vendor.</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor.name} | {tour.name}
        </title>
      </Helmet>

      <PageHeader title={`Vendors | ${vendor.name} | ${tour.name}`} backButton={`/vendors/${vendorId}`} />

      <Stack direction="column" spacing={4}>
        <div id="overview">
          <TourPane tour={tour} vendorId={vendorId} />
        </div>

        <div id="tour-options">
          <TourOptionPane
            tourOptions={tour.tour_options_count ? tour.tour_options : []}
            vendorId={vendorId}
            tourId={tour.id}
          />
        </div>

        <TourReviewsPane reviews={tour.reviews_count ? tour.reviews : []} vendorId={vendorId} tourId={tour.id} />
      </Stack>
    </Container>
  );
}
