import React, { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import BookIcon from '@mui/icons-material/Book';
import CancelIcon from '@mui/icons-material/Cancel';
import CopyIcon from '@mui/icons-material/ContentCopy';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { getPurchases } from '~/services/OrdersService';
import ReservationService from '~/services/ReservationService';

import ResolvedConfirmationDialog from './ResolvedConfirmationDialog';

interface ReservationFailureItem {
  reservationId: string;
  actionName: string;
  order?: {
    accommodation_items: Array<{ reservation: { channel_manager: string; [key: string]: any }; [key: string]: any }>;
    [key: string]: any;
  };
  [key: string]: any;
}

const ACTION_MAP = {
  commit: { label: 'Reservation', icon: <BookIcon /> },
  cancel: { label: 'Cancellation', icon: <CancelIcon /> },
  modify: { label: 'Modify Reservation', icon: <SettingsIcon /> },
  abandon: { label: 'Abandoned', icon: <CancelIcon /> },
};

export default function ReservationRemediation() {
  const [reservations, setReservations] = useState<{ [reservationId: string]: ReservationFailureItem }>({});
  const [manualEntry, setManualEntry] = useState('');
  const [showManual, setShowManual] = useState(false);
  const [selectedReservationFailure, setSelectedReservationFailure] = useState<ReservationFailureItem>(null);
  const [fetching, setFetching] = useState<Array<string>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const unresolvedReservations = useMemo(
    () => Object.values(reservations).filter((res: ReservationFailureItem) => !res.resolved),
    [reservations],
  );

  const addReservation = () => {
    try {
      const reservation = JSON.parse(manualEntry);
      setReservations({ ...reservations, [reservation.reservationId]: reservation });
      setShowManual(false);
      setManualEntry('');
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const updateReservation = (reservationId: string, reservation: any) => {
    // persist locally any previously marked as resolved
    const fromStorage = JSON.parse(localStorage.getItem('reservations-remediation') ?? '{}');
    setReservations({
      ...fromStorage,
      ...reservations,
      [reservationId]: {
        ...fromStorage[reservationId],
        ...reservations[reservationId],
        ...reservation,
      },
    });
  };

  const fetchFailed = async () => {
    try {
      const results: Array<any> = await ReservationService.getFailedReservations();
      setReservations({
        ...reservations,
        ...results.reduce((acc, cur) => ({ ...acc, [cur.data.reservationId]: cur.data }), {}),
      });
      enqueueSnackbar(results.length ? `Fetched ${results.length} failed jobs` : 'No new failures', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const fetchReservation = async (reservationId) => {
    let bookingNumber = null;
    try {
      const [{ results: logs }, { result: requests }, { result: responses }] = await Promise.all([
        ReservationService.getReservationLogs(reservationId),
        ReservationService.getReservationRequests(reservationId),
        ReservationService.getReservationResponses(reservationId),
      ]);
      if (logs.length > 0) {
        bookingNumber = logs[0].booking_number;
      } else {
        throw new Error('No logs found');
      }
      updateReservation(reservationId, { requests, responses, bookingNumber });
      const { result: purchase } = await getPurchases({
        page: 1,
        per_page: 1,
        brand: null,
        customer_id: null,
        filterBy: 'booking_numbers',
        filterValue: bookingNumber,
      });
      updateReservation(reservationId, { requests, responses, bookingNumber, order: purchase[0] });
    } catch (e) {
      updateReservation(reservationId, { error: e.message });
    }
  };

  const fetchData = async (reservationId) => {
    setFetching([...fetching, reservationId]);
    try {
      await fetchReservation(reservationId);
      setFetching(fetching.filter((id) => id !== reservationId));
      enqueueSnackbar('Details fetched', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setFetching(fetching.filter((id) => id !== reservationId));
    }
  };

  const guessState = (reservation) => {
    const { responses, actionName } = reservation;
    const lastResponse = responses?.at(-1) ?? {};
    const alreadyCommitted =
      actionName === 'commit' &&
      lastResponse.status === 'error' &&
      lastResponse.original_response.toLowerCase().includes('id already');
    const alreadyCancelled =
      actionName === 'cancel' &&
      lastResponse.status === 'error' &&
      (lastResponse.original_response.toLowerCase().includes('already cancelled') ||
        lastResponse.original_response.toLowerCase().includes('reservation cancelled'));
    const alreadyIgnored =
      actionName === 'abandon' &&
      lastResponse.status === 'error' &&
      lastResponse.original_response.toLowerCase().includes('reservation has already been ignored');

    if (alreadyCommitted || alreadyCancelled || alreadyIgnored || lastResponse.status === 'success') {
      return <Chip icon={<WarningIcon />} variant="outlined" color="info" label="Possible false failure" />;
    }
    return <Chip icon={<ErrorIcon />} variant="outlined" color="error" label="Needs manual intervention" />;
  };

  const buildOrderLink = (reservation) => {
    const { order } = reservation;
    return `${window.location.protocol}//${window.location.host}/purchases/${order.id}`;
  };

  const buildPasteable = (reservation) => {
    return `${ACTION_MAP[reservation.actionName].label} ${buildOrderLink(reservation)} ${reservation.bookingNumber}`;
  };

  useEffect(() => {
    fetchFailed();
  }, []);

  useEffect(() => {
    localStorage.setItem('reservations-remediation', JSON.stringify(reservations));
  }, [reservations]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Reservation Remediation</title>
      </Helmet>
      <PageHeader title="Reservation Remediation"></PageHeader>
      <section>
        {showManual ? (
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                multiline
                placeholder="Enter data object of failed job - must have reservationId & actionName properties"
                minRows={4}
                fullWidth
                onChange={(e) => setManualEntry((e.target as HTMLTextAreaElement).value)}
                value={manualEntry}
              ></TextField>
            </Grid>
            <Grid item xs={2} rowGap={1}>
              <Button variant="contained" onClick={addReservation}>
                Add
              </Button>
              <Button variant="outlined" onClick={() => setShowManual(false)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Stack pt={2} direction="row" justifyContent="space-between">
            <Button variant="contained" onClick={() => setShowManual(true)}>
              Manually Enter Reservation
            </Button>
            <Button variant="outlined" onClick={fetchFailed} endIcon={<RefreshIcon />}>
              Fetch Failed Reservations
            </Button>
          </Stack>
        )}
        <hr />
        {unresolvedReservations.length > 0 && (
          <Typography align="right" variant="caption" gutterBottom sx={{ display: 'block' }}>
            {unresolvedReservations.length} Failures To Fix
          </Typography>
        )}
        <Stack gap={2}>
          {unresolvedReservations.map((reservation: ReservationFailureItem) => (
            <Card key={reservation.reservationId} sx={{ borderBottom: '1px solid #eee' }}>
              <CardContent>
                <Grid container spacing={2} key={reservation.reservationId}>
                  <Grid item xs={8}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography>{reservation.reservationId}</Typography>
                      {ACTION_MAP[reservation.actionName] ? (
                        <Chip
                          variant="outlined"
                          icon={ACTION_MAP[reservation.actionName]?.icon}
                          label={ACTION_MAP[reservation.actionName]?.label}
                        />
                      ) : (
                        <Chip variant="outlined" label={reservation.actionName} />
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="space-around">
                      <Button variant="contained" onClick={() => fetchData(reservation.reservationId)}>
                        Get Details
                      </Button>
                      {reservation.bookingNumber && reservation.order && (
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => setSelectedReservationFailure(reservation)}
                        >
                          Resolve & Remove Job
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                {fetching.includes(reservation.reservationId) && <LinearProgress sx={{ margin: '1rem 0' }} />}
                {reservation.bookingNumber && reservation.order && (
                  <>
                    <Typography m={2}>
                      {ACTION_MAP[reservation.actionName].label}{' '}
                      <a href={buildOrderLink(reservation)} target="_blank" rel="noreferrer">
                        {buildOrderLink(reservation)}
                      </a>{' '}
                      {reservation.bookingNumber}
                      <IconButton
                        color="default"
                        onClick={() => {
                          navigator.clipboard.writeText(buildPasteable(reservation) as string);
                          enqueueSnackbar('Copied to clipboard', { variant: 'success' });
                        }}
                      >
                        <CopyIcon />
                      </IconButton>
                      {guessState(reservation)}
                    </Typography>
                  </>
                )}
                {reservation.error && (
                  <Box mt={2}>
                    <Alert severity="error">{reservation.error}</Alert>
                  </Box>
                )}
                {reservation.order && (
                  <Grid container spacing={2} m={2}>
                    <Grid item xs={4}>
                      <Typography>
                        <strong>Order Status</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{reservation.order.status}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        <strong>Reservation Item Status</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{reservation.order.accommodation_items[0].status}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        <strong>Channel Manager</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{reservation.order.accommodation_items[0].reservation.channel_manager}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        <strong>Reservation Dates</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>
                        {reservation.order.accommodation_items[0].reservation.check_in}
                        {' → '}
                        {reservation.order.accommodation_items[0].reservation.check_out}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        <strong>Last Action Dated</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{reservation.responses.at(-1).createdAt?.split('T')[0]}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        <strong>Last Response From Channel Manager</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{reservation.responses.at(-1).original_response}</Typography>
                    </Grid>
                  </Grid>
                )}
                {reservation.responses && reservation.responses.length > 0 && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Channel Manager Responses</AccordionSummary>
                    <AccordionDetails>
                      {reservation.responses.map((response, ind) => (
                        <Accordion key={'resp-' + ind}>
                          <AccordionSummary expandIcon={<VisibilityIcon />}>Response {ind}</AccordionSummary>
                          <AccordionDetails>
                            <pre>{JSON.stringify(response, null, 2)}</pre>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}
                {reservation.requests && reservation.requests.length > 0 && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Requests To Channel Manager</AccordionSummary>
                    <AccordionDetails>
                      {reservation.requests.map((request, ind) => (
                        <Accordion key={'resp-' + ind}>
                          <AccordionSummary expandIcon={<VisibilityIcon />}>Request {ind}</AccordionSummary>
                          <AccordionDetails>
                            <pre>{JSON.stringify(request, null, 2)}</pre>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}
              </CardContent>
            </Card>
          ))}
        </Stack>
      </section>
      <ResolvedConfirmationDialog
        reservationFailure={selectedReservationFailure}
        setReservationFailure={setSelectedReservationFailure}
        resolveReservation={(reservationId) => updateReservation(reservationId, { resolved: true })}
      />
    </Container>
  );
}
