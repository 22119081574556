import React from 'react';

import { Button } from '@mui/material';

type Props = {
  disabled: boolean;
  paymentWillStart: (cb: () => void) => void;
  onPayment: (paymentType: string, paymentInfo?: unknown, orderId?: string) => Promise<void>;
};

const CreditPayment = ({ paymentWillStart, onPayment, disabled }: Props) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      data-testid="le-credit-purchase-btn"
      className="le-credit-purchase-btn"
      onClick={() => {
        paymentWillStart(() => {
          onPayment('lecredit');
        });
      }}
      disabled={disabled}
      fullWidth
    >
      Buy with le credit
    </Button>
  );
};

export default CreditPayment;
