import React from 'react';

import currencyFormatter from 'currency-formatter';

import { PROMO_TYPE_FIXED, PROMO_TYPE_PERCENTAGE } from '../../../consts/promo';

const PaymentBreakdown = ({
  currencyCode,
  subTotal,
  savedAmount,
  creditPaymentAmount,
  promoDiscountValue,
  grandTotal,
  promoType,
}) => {
  return (
    <div>
      <div className="amount-row breakdown-row">
        <div className="amount-type">Subtotal</div>
        <div className="amount data-hj-whitelist">
          {currencyFormatter.format(subTotal, {
            code: currencyCode,
          })}
        </div>
      </div>
      {savedAmount > 0 && (
        <div className="amount-row breakdown-row" data-testid="save-amount">
          <div className="amount-type">
            Discount code:
            {promoType === PROMO_TYPE_FIXED && (
              <span>
                {' '}
                {currencyFormatter.format(promoDiscountValue, {
                  code: currencyCode,
                })}
              </span>
            )}
            {promoType === PROMO_TYPE_PERCENTAGE && (
              <span>
                {' '}
                {promoDiscountValue}%{' off'}
              </span>
            )}
          </div>
          <div className="amount">
            -
            {currencyFormatter.format(savedAmount, {
              code: currencyCode,
            })}
          </div>
        </div>
      )}
      {creditPaymentAmount > 0 && (
        <div className="amount-row breakdown-row" data-testid="credit-amount">
          <div className="amount-type">Luxury Escapes Credit</div>
          <div className="amount">
            -
            {currencyFormatter.format(creditPaymentAmount, {
              code: currencyCode,
            })}
          </div>
        </div>
      )}
      <div className="amount-row grand-total-row">
        <div className="amount-type">Grand total</div>
        <div className="amount data-hj-whitelist">
          {currencyFormatter.format(grandTotal, {
            code: currencyCode,
          })}
        </div>
      </div>
    </div>
  );
};

export default PaymentBreakdown;
