import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

interface Props {
  contextKey: string;
  value: string[];
  onValueChange: (value: string[]) => void;
}

const AdditionalUSPComponent: React.FC<Props> = ({ contextKey, value, onValueChange }) => {
  const addTextField = () => {
    onValueChange([...value, '']);
  };

  const removeTextField = (index: number) => {
    const newArray = [...value];
    newArray.splice(index, 1);
    onValueChange(newArray);
  };

  const updateTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newArray = [...value];
    newArray[index] = event.target.value;
    onValueChange(newArray);
  };

  return (
    <Grid sx={{ marginBottom: '1rem' }}>
      <Typography variant="h6" sx={{ marginBottom: '0.5rem' }}>
        Additional USP
      </Typography>
      <Stack
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {value.map((item, index) => (
          <div key={index}>
            <TextField value={item} onChange={(event) => updateTextField(event, index)} sx={{ flex: 1 }} />
            <IconButton onClick={() => removeTextField(index)} color="error">
              <DeleteIcon fontSize="medium" />
            </IconButton>
          </div>
        ))}
        <IconButton onClick={addTextField} size="large" color="primary">
          <AddIcon fontSize="large" />
        </IconButton>
      </Stack>
    </Grid>
  );
};

export default AdditionalUSPComponent;
