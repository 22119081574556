import generateRandomString from './generateRandomString';

function amenitiesMap(roomTypeResponse) {
  if (roomTypeResponse.amenities) {
    return roomTypeResponse.amenities.map((item) => item.name);
  }

  return [];
}

// presentRoomType transforms roomType response from the API into an object
// whose shape is acceptable by the jsonschema-form
export default function (roomTypeResponse) {
  return Object.assign({}, roomTypeResponse, {
    // _id is used internally to identify the room type form in an array of forms
    _id: generateRandomString(),
    amenities: amenitiesMap(roomTypeResponse),
  });
}
