import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Chip, Stack } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import { isBetween } from '~/services/TimeService';

interface Props {
  parent: {
    schedules_list: Array<Reservation.Schedule>;
    [key: string]: any;
  };
  listView?: boolean;
  showStatus?: boolean;
}

type ISchedule = {
  activePeriod: {
    from: string;
    to: string;
  };
  travelPeriod: {
    from: string;
    to: string;
  };
  type: 'tactical' | 'sell' | 'other';
  parent: string;
  id?: string;
};

interface ScheduleRowProps {
  schedule: ISchedule;
  showStatus?: boolean;
}

function isScheduleActive(schedule: ISchedule) {
  return isBetween(schedule.activePeriod.from, schedule.activePeriod.to);
}

export default function ReservationSchedule({ parent, listView = false, showStatus = true }: Props) {
  const ScheduleRow = ({ schedule, showStatus = true }: ScheduleRowProps) => {
    const isActive = isScheduleActive(schedule);
    return (
      <Stack direction={listView ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
        {showStatus ? (
          <Chip
            label={isActive ? 'Active' : 'Inactive'}
            color={isActive ? 'success' : 'error'}
            icon={isActive ? <CheckCircleIcon /> : <AccessTimeIcon />}
            sx={{
              maxWidth: isActive ? '85px' : '90px', // inactive is longer
            }}
          />
        ) : (
          <Box />
        )}
        <strong>
          {schedule.type.toLocaleUpperCase()}:{listView && ' '}
        </strong>
        <span>
          <em style={{ fontSize: '1.5rem' }}>Active:</em> {schedule.activePeriod.from} → {schedule.activePeriod.to}
        </span>
        {!listView && (
          <span>
            <em style={{ fontSize: '1.5rem' }}>Travel:</em> {schedule.travelPeriod.from} → {schedule.travelPeriod.to}
          </span>
        )}
      </Stack>
    );
  };

  return (
    <>
      {parent.schedules_list && (
        <Stack spacing={2}>
          {parent.schedules_list.map((schedule, index) => (
            <ScheduleRow key={schedule.id || index} schedule={schedule} showStatus={showStatus} />
          ))}
        </Stack>
      )}
    </>
  );
}
