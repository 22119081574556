import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { Brands, CabinCategory, getCabinCategoryList } from '~/services/cruises/CabinCategoriesService';

import LinkContent from '../Sailings/LinkContent';

import CabinColumnDetails from './CabinColumnDetails';
import CodeColumnDetails from './CodeColumnDetails';
import ShipColumnDetails from './ShipColumnDetails';
import VendorColumnDetails from './VendorColumnDetails';
import { SearchFilters } from './constants';

const DEFAULT_ROWS_RESPONSE: CabinCategoriesResponse = { result: [], total: 0 };

type FetchCabinCategoriesParams = {
  page: number;
  brand: App.Brands;
  sizePerPage: number;
  searchFilters: SearchFilters;
};

interface Props {
  page: number;
  brand: App.Brands;
  sizePerPage: number;
  currentPage: number;
  searchFilters: SearchFilters;
  onPageChange: (page: number) => void;
}

const urlBuilder = (id: string): string => `/cruises/cabin-categories/${id}`;
const urlCabinCategoryGroupBuilder = (id: string): string => `/cruises/cabin-categories-groups/${id}`;

const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'code',
    headerName: 'Code',
    renderCell: (params) => <CodeColumnDetails title={params.row.code} color={params.row.color} />,
    display: 'flex',
  },
  {
    flex: 2,
    field: 'categoryName',
    headerName: 'Category Name',
    renderCell: (params) => {
      const categoryNames = (params.row as CabinCategory).cabinInfo[0]?.name ?? '__NO_NAME__';
      return <LinkContent title={categoryNames} url={urlBuilder(params.row.id)} />;
    },
    display: 'flex',
  },
  {
    flex: 1,
    field: 'cabinType',
    headerName: 'Cabin Type',
    display: 'flex',
  },
  {
    flex: 2,
    field: 'categoryGroup',
    headerName: 'Cabin Category Group',
    renderCell: (params) => {
      return params.row.cabinCategoryGroup ? (
        <LinkContent
          title={params.row.cabinCategoryGroup?.name}
          url={urlCabinCategoryGroupBuilder(params.row.cabinCategoryGroup.id)}
        />
      ) : (
        <>-</>
      );
    },
    display: 'flex',
  },
  {
    flex: 2,
    field: 'ship',
    sortable: false,
    headerName: 'Ship Details',
    renderCell: (params) => <ShipColumnDetails name={params.row.ship.name} externalId={params.row.ship.externalId} />,
    display: 'flex',
  },
  {
    flex: 2,
    field: 'vendor',
    sortable: false,
    headerName: 'Vendor Details',
    renderCell: (params) => (
      <VendorColumnDetails name={params.row.vendor.name} externalId={params.row.vendor.externalId} />
    ),
    display: 'flex',
  },
  {
    flex: 2,
    sortable: false,
    field: 'id_externalId',
    headerName: 'Cabin Category IDs',
    renderCell: (params) => <CabinColumnDetails id={params.row.id} externalId={params.row.externalId} />,
    display: 'flex',
  },
];

type CabinCategoriesResponse = {
  total: number;
  result: CabinCategory[];
};

export default function CabinCategoriesList(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { brand, page, currentPage, searchFilters, sizePerPage, onPageChange } = props;

  const [rows, setRows] = useState<CabinCategoriesResponse>(DEFAULT_ROWS_RESPONSE);
  const [fetching, setFetching] = useState<Utils.FetchingState>('idle');

  const fetchData = useCallback(
    async (params: FetchCabinCategoriesParams): Promise<void> => {
      const { brand, searchFilters } = params;

      setFetching('loading');

      let cabinExternalId: number | undefined = undefined;
      if (searchFilters.externalId) cabinExternalId = Number(searchFilters.externalId);

      let shipExternalId: number | undefined = undefined;
      if (searchFilters.shipExternalId) shipExternalId = Number(searchFilters.shipExternalId);

      const take = params.sizePerPage;
      const skip = (params.page - 1) * params.sizePerPage;

      try {
        const fetchedCabinCategories = await getCabinCategoryList({
          ...searchFilters,
          skip,
          take,
          shipExternalId,
          brand: brand as Brands,
          externalId: cabinExternalId,
        });

        setFetching('success');

        if (!fetchedCabinCategories || fetchedCabinCategories.result.length === 0) {
          enqueueSnackbar('No results found', { autoHideDuration: 5000, variant: 'warning' });
        }

        setRows({
          total: fetchedCabinCategories.total,
          result: fetchedCabinCategories.result,
        });
      } catch (error) {
        setFetching('failed');
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });

        setRows({ total: 0, result: [] });
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchData({
      brand,
      sizePerPage,
      searchFilters,
      page: currentPage,
    });
  }, [currentPage, fetchData, searchFilters, sizePerPage, brand]);

  return (
    <DataGrid
      pagination
      autoHeight
      columns={columns}
      disableColumnMenu
      disableColumnFilter
      rows={rows.result}
      rowCount={rows.total}
      paginationMode="server"
      disableRowSelectionOnClick
      getRowHeight={() => 'auto'}
      pageSizeOptions={[sizePerPage]}
      loading={fetching === 'loading'}
      slots={{ pagination: GridPagination }}
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
    />
  );
}
