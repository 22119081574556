import React, { useState } from 'react';

import { Alert, Button, Stack } from '@mui/material';

import VendorsService from '~/services/VendorsService';

const SuccessMessage = ({ message }) => {
  return (
    <Alert severity="success">
      <strong>Success!</strong>
      <p>{message}</p>
    </Alert>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <Alert severity="error">
      <strong>Oops!</strong>
      <p>File upload failed</p>
      <pre>{message}</pre>
    </Alert>
  );
};

export type UploadStatus = 'not-started' | 'completed' | 'failed' | 'pending';

const UploadMusementPayableForm = () => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('not-started');
  const [uploadMessage, setUploadMessage] = useState<string | null>();

  const [file, setFile] = useState<File | null>(null);
  const [paymentDate, setPaymentDate] = useState<Date>(new Date());

  const checkUploadStatus = (jobId: string) => {
    const intervalId = setInterval(() => {
      VendorsService.checkUploadJobStatus(jobId).then((result) => {
        const rowsProcessed = result.rows_processed || 0;

        if (result.job_state !== 'completed' && result.job_state !== 'failed') {
          setUploadStatus('completed');
          setUploadMessage(`File processing... ${rowsProcessed} rows processed.`);
          return;
        }

        if (intervalId) {
          clearInterval(intervalId);
        }

        if (result.status === 'success') {
          const message =
            rowsProcessed > 0
              ? `File uploaded successfully. ${rowsProcessed} row(s) processed.`
              : 'File uploaded successfully but 0 rows processed. Please check file format and/or column names.';

          setUploadStatus('completed');
          setUploadMessage(message);
          return;
        }

        setUploadStatus('failed');
        setUploadMessage(result.result);
      });
    }, 2000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setUploadStatus('pending');

      const formData = new FormData();
      formData.append('musement_payments', file);
      formData.append('payment_date', paymentDate.toISOString());

      const result = await VendorsService.uploadMusementPayments(formData);

      setUploadMessage(result.message);
      setUploadStatus('completed');

      checkUploadStatus(result.jobId);
    } catch (e) {
      setUploadStatus('failed');
      setUploadMessage(e.message);
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files[0]);
  };

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentDate(new Date(event.target.value));
  };

  return (
    <form onSubmit={handleSubmit}>
      {uploadStatus === 'completed' && <SuccessMessage message={uploadMessage} />}
      {uploadStatus === 'failed' && <ErrorMessage message={uploadMessage} />}

      <h5>Date of Payment</h5>

      <Stack direction="column" spacing={2}>
        <input type="date" onChange={onDateChange} />

        <input
          type="file"
          onChange={onFileChange}
          accept=".csv, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />

        <div>
          <Button type="submit" disabled={uploadStatus === 'pending' || !file || !paymentDate} variant="contained">
            {uploadStatus !== 'pending' ? 'Upload' : 'Uploading...'}
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default UploadMusementPayableForm;
