export const prepareScheduledDiscountList = (discounts) => {
  if (!discounts) {
    return [];
  }

  return Object.values(discounts).map((discount: any) => ({
    id: discount.id,
    rate_plan_id: discount.rate_plan_id,
    discount_type: discount.discount_type,
    discount_percent: discount.discount_percent,
    min_los: discount.min_los || null, // ensure null is passed if min_los is an empty string
    max_los: discount.max_los || null,
    min_days_to_arrival: discount.min_days_to_arrival || null,
    max_days_to_arrival: discount.max_days_to_arrival || null,
    discount_name: discount.discount_name || null,
    is_displayed: discount.is_displayed,
    source: discount.source,
    paid_by: discount.paid_by,
    schedules: [
      {
        id: discount.schedules[0].id,
        parent: discount.schedules[0].parent,
        activePeriod: {
          from: discount.schedules[0].activePeriod.from,
          to: discount.schedules[0].activePeriod.to,
        },
        travelPeriod: {
          from: discount.schedules[0].travelPeriod.from,
          to: discount.schedules[0].travelPeriod.to,
        },
        type: discount.schedules[0].type,
      },
    ],
  }));
};
