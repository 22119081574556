import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';

import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import { getCreditReservations } from '~/services/FlightsService';
import { formatDateISOWithTime } from '~/services/TimeService';

import CreditReservationTable from './CreditReservationTable';
import { CREDIT_RESERVATION_CSV_ATTRIBUTES, CREDIT_RESERVATION_LIST_ATTRIBUTES, REGIONS, STATES } from './constants';

const SIZE_PER_PAGE = 100;

export default function CreditReservationListContainer() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({ rows: [], count: 0 });
  const [type, setType] = useState(STATES[0]);
  const [region, setRegion] = useState(REGIONS[0]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState([]);
  const [fileName, setFileName] = useState('');

  const csvDownloadRef = useRef<CSVLink>();

  const fetchTableData = useCallback(async () => {
    try {
      const response = await getCreditReservations({
        state: type,
        region,
        page,
        pageSize: SIZE_PER_PAGE,
        attributes: CREDIT_RESERVATION_LIST_ATTRIBUTES,
      });

      setLoading(false);
      setTableData(response.result);
    } catch (err) {
      setLoading(false);
      setListData([]);

      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, page, region, type]);

  const onTypeChange = (event) => {
    setType(event.target.value);
    setPage(1);
  };

  const onRegionChange = (event) => {
    setRegion(event.target.value);
    setPage(1);
  };

  const getTableData = async () => {
    const response = await getCreditReservations({
      state: type,
      region,
      page: page || 1,
      pageSize: SIZE_PER_PAGE,
      attributes: CREDIT_RESERVATION_CSV_ATTRIBUTES,
    });

    setListData(response.result.rows);
    setFileName(`credit-reservations-${type}-${region}` + `-page-${page}-${formatDateISOWithTime()}.csv`);

    if (csvDownloadRef?.current) {
      csvDownloadRef.current.link.click();
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchTableData();
  }, [fetchTableData]);

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant="h4">Flights Credit Reservation</Typography>
      </Grid>
      <Grid item md={3}>
        <FormControl fullWidth>
          <InputLabel>Fare Class</InputLabel>
          <Select label="State" onChange={onTypeChange} required value={type}>
            {STATES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <FormControl fullWidth>
          <InputLabel>Regions</InputLabel>
          <Select label="State" onChange={onRegionChange} required value={region}>
            {REGIONS.map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <Button variant="contained" onClick={getTableData}>
          Export CSV
        </Button>
        <CSVLink data={listData} filename={fileName} className="hidden" ref={csvDownloadRef} target="_blank" />
      </Grid>
      <Grid item md={12}>
        <CreditReservationTable
          loading={loading}
          records={tableData.rows}
          total={tableData.count}
          page={page}
          onPageChange={setPage}
        />
      </Grid>
    </Grid>
  );
}
