import React, { Fragment } from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { formatDateLong } from '~/services/TimeService';

const formatStartDate = (item: App.OrderItem) => {
  let dateString: string;

  if (item.reservation?.check_in) {
    dateString = item.reservation?.check_in;
  }

  if (item.reservation?.start_date) {
    dateString = item.reservation?.start_date;
  }

  const beginningDate = new Date(dateString);

  const formattedDate = formatDateLong(beginningDate);

  return dateString ? formattedDate : 'no date';
};

const packagesCheckInFormatter = (params: GridRenderCellParams<App.Order>) => {
  const row = params.row;

  if (!row.items.length) return null;

  return (
    <Stack direction="column" key={`${row.id_orders}-check-in`}>
      {row.items.map((item) => (
        <Fragment key={`${item.id}-date`}>
          {item.status === 'cancelled' && (
            <Typography sx={{ textDecoration: 'line-through' }}>{formatStartDate(item)}</Typography>
          )}
          {item.status !== 'cancelled' && <Typography>{formatStartDate(item)}</Typography>}
        </Fragment>
      ))}
    </Stack>
  );
};

export default packagesCheckInFormatter;
