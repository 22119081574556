import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerStateCitiesGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/country-groups/{countryGroup}/countries/{country}/states/{state}/cities']['get'];

function mapHeroPlannerStateCity(
  external: Utils.ArrayElement<
    HeroPlannerStateCitiesGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerCity {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetHeroPlannerStateCities(brand: string, countryGroup: string, country: string, state: string) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!state) throw 'missing state';

  const url = makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/country-groups/${countryGroup}/countries/${country}/states/${state}/cities`,
  );
  url.searchParams.set('brand', brand);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerStateCitiesGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerStateCity);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerStateCities;
