import React, { useCallback, useState } from 'react';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

enum PackageSettingStatus {
  DISABLED,
  ENABLED,
  NONE,
}
interface Props {
  onClose: () => void;
  showSettingsModal: boolean;
  onSave: (updates) => void;
}

const PackageSettingsModal = ({ onClose, showSettingsModal, onSave }: Props) => {
  const [flexibleNightsStatus, setFlexibleNightStatus] = useState<PackageSettingStatus>(PackageSettingStatus.NONE);
  const [chooseDatesLaterStatus, setChooseDatesLaterStatus] = useState<PackageSettingStatus>(PackageSettingStatus.NONE);
  const [inventoryRequestsStatus, setInventoryRequestsStatus] = useState<PackageSettingStatus>(
    PackageSettingStatus.NONE,
  );

  const switchDisabled = useCallback((prevStatus) => {
    if (prevStatus === PackageSettingStatus.DISABLED) {
      return PackageSettingStatus.NONE;
    }

    return PackageSettingStatus.DISABLED;
  }, []);

  const switchEnabled = useCallback((prevStatus) => {
    if (prevStatus === PackageSettingStatus.ENABLED) {
      return PackageSettingStatus.NONE;
    }

    return PackageSettingStatus.ENABLED;
  }, []);

  const onSaveButtonClick = () => {
    const updates = {};

    if (flexibleNightsStatus != PackageSettingStatus.NONE) {
      updates['flexible_nights'] = !!flexibleNightsStatus;
    }

    if (inventoryRequestsStatus != PackageSettingStatus.NONE) {
      updates['allow_dates_request'] = !!inventoryRequestsStatus;
    }

    if (chooseDatesLaterStatus != PackageSettingStatus.NONE) {
      updates['allow_buy_now_book_later'] = !!chooseDatesLaterStatus;
    }

    onSave(updates);

    setInventoryRequestsStatus(PackageSettingStatus.NONE);
    setChooseDatesLaterStatus(PackageSettingStatus.NONE);
    setFlexibleNightStatus(PackageSettingStatus.NONE);
  };

  return (
    <Dialog onClose={onClose} open={showSettingsModal}>
      <DialogTitle>Package settings</DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            '& > .MuiGrid-item': {
              padding: 0,
            },
          }}
        >
          <Grid item xs={6} />
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            Apply for all
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            Remove from all
          </Grid>
          <Grid item xs={6}>
            Flexible nights
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={flexibleNightsStatus === PackageSettingStatus.ENABLED}
              onClick={() => setFlexibleNightStatus(switchEnabled)}
            />
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={flexibleNightsStatus === PackageSettingStatus.DISABLED}
              onClick={() => setFlexibleNightStatus(switchDisabled)}
            />
          </Grid>
          <Grid item xs={6}>
            Buy now, choose dates later
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={chooseDatesLaterStatus === PackageSettingStatus.ENABLED}
              onClick={() => setChooseDatesLaterStatus(switchEnabled)}
            />
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={chooseDatesLaterStatus === PackageSettingStatus.DISABLED}
              onClick={() => setChooseDatesLaterStatus(switchDisabled)}
            />
          </Grid>
          <Grid item xs={6}>
            Inventory requests
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={inventoryRequestsStatus === PackageSettingStatus.ENABLED}
              onClick={() => setInventoryRequestsStatus(switchEnabled)}
            />
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={inventoryRequestsStatus === PackageSettingStatus.DISABLED}
              onClick={() => setInventoryRequestsStatus(switchDisabled)}
            />
          </Grid>
        </Grid>
        <Alert sx={{ marginTop: 2 }} severity="warning">
          Warning - this will override any current settings when saved.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onSaveButtonClick}>
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default PackageSettingsModal;
