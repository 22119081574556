import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Box, Stack, Typography } from '@mui/material';

import { HOTEL_OFFER_TYPES, OFFER_TYPE_TOUR } from '../../../consts/offerTypes';

import PriceInclusionDetail from './PriceInclusionDetail';
import ReservationDetailHotel from './ReservationDetailHotel';
import ReservationDetailTour from './ReservationDetailTour';

export default class OrderSummaryItem extends React.Component {
  renderReservationDetail = () => {
    const { bookingType, offerType, item } = this.props;

    if (bookingType === 'voucher') {
      return null;
    }

    if (HOTEL_OFFER_TYPES.includes(offerType)) {
      return <ReservationDetailHotel reservation={item.reservation} />;
    }

    if (offerType === OFFER_TYPE_TOUR) {
      return <ReservationDetailTour reservation={item.reservation} />;
    }
  };

  onToggleWaiveSurcharge = () => {
    const { item, isAdmin, offer } = this.props;
    if (!isAdmin) {
      return;
    }

    if (item.reservation?.waivedSurchargeAmount) {
      this.props.doUpdateReservation(
        item.id,
        {
          ...item.reservation,
          durationSurchargeTotal: item.reservation.waivedSurchargeAmount,
          waivedSurchargeAmount: undefined,
        },
        offer,
      );
    }

    if (item.surcharge > 0) {
      this.props.doUpdateReservation(
        item.id,
        {
          ...item.reservation,
          durationSurchargeTotal: 0,
          waivedSurchargeAmount: item.surcharge,
        },
        offer,
      );
    }
  };

  render() {
    const { item, currencyCode, itemIdx, surchargePaidDirectToVendor, bookDatesOnly, isAdmin } = this.props;
    const { pkg } = item;
    const payableSurcharge = surchargePaidDirectToVendor ? 0 : item.surcharge + (item.extraGuestSurcharge || 0);
    const payablePrice = bookDatesOnly ? payableSurcharge : item.price + payableSurcharge;

    return (
      <div className="summary-item-item" data-testid="order-summary-item">
        <Stack direction="row" spacing={2}>
          <Box flexGrow={3}>
            <div className="item-detail-container">
              <div className="item-number">{itemIdx + 1}</div>
              <div className="item-detail">
                <div className="item-name data-hj-whitelist">{pkg.name}</div>
                {this.renderReservationDetail()}
              </div>
            </div>
          </Box>

          <Box flexGrow={2}>
            <div className="item-amounts">
              <div className="item-price data-hj-whitelist">
                {currencyFormatter.format(payablePrice, {
                  code: currencyCode,
                })}
              </div>
            </div>
            {(item.surcharge > 0 || item.reservation?.waivedSurchargeAmount || item.extraGuestSurcharge > 0) && (
              <>
                <PriceInclusionDetail
                  extraGuestSurcharge={item.extraGuestSurcharge}
                  surcharge={item.surcharge}
                  waivedSurchargeAmount={item.reservation?.waivedSurchargeAmount}
                  currencyCode={currencyCode}
                  surchargePaidDirectToVendor={surchargePaidDirectToVendor}
                />
                <div className="text-right">
                  <div>
                    <input
                      type="checkbox"
                      checked={!!item.reservation?.waivedSurchargeAmount}
                      onChange={this.onToggleWaiveSurcharge}
                      disabled={!isAdmin}
                    />
                    <label className="spaced-label" onClick={this.onToggleWaiveSurcharge}>
                      Waive surcharge
                    </label>

                    <Typography color="secondary">Only available for use by team leaders</Typography>
                  </div>
                </div>
              </>
            )}
          </Box>
        </Stack>
      </div>
    );
  }
}
