import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';
import Spinner from '~/components/Common/Spinner';
import { withTenant } from '~/components/hoc';

import { OFFER_TYPE_BUNDLE_AND_SAVE } from '~/consts/offerTypes';

import useQuery from '~/hooks/useQuery';

import { getPurchases } from '~/services/OrdersService';

import { addQuery, removeQuery } from '~/utils/url';

import PackagesPage from './PackagesPage';

const DEFAULT_SIZE_PER_PAGE = 10;
const SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE = 50;

interface Props {
  tenant: App.Tenant;
}

const PackagesPageContainer = ({ tenant }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [purchases, setPurchases] = useState(null);
  const [filterField, setFilterField] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);
  const [sizePerPage, setSizePerPage] = useState(DEFAULT_SIZE_PER_PAGE);

  const currentPage = parseInt(query.get('page')) || 1;
  const customerId = query.get('customer_id');

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setPurchases(null);

    try {
      const fetchedPurchases = await getPurchases({
        page: currentPage,
        per_page: sizePerPage,
        brand: tenant.brand,
        customer_id: customerId,
        filterBy: filterField,
        filterValue,
        additionalFilter: additionalSearchParams,
        offer_type: OFFER_TYPE_BUNDLE_AND_SAVE,
      });

      if (!fetchedPurchases) {
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      }

      setPurchases(fetchedPurchases);
    } catch (error) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    customerId,
    enqueueSnackbar,
    filterField,
    filterValue,
    sizePerPage,
    additionalSearchParams,
    tenant.brand,
  ]);

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (customerId) {
      setSizePerPage(SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE);
    } else {
      setSizePerPage(DEFAULT_SIZE_PER_PAGE);
    }
  }, [customerId]);

  const searchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);

    const newLocation = removeQuery(location, 'customer_id', 'page');

    history.push(newLocation);
  };

  return (
    <>
      <OrderSearchForm
        searchQuery={searchQuery}
        bookingIdType="booking_numbers"
        setAdditionalParams={setAdditionalSearchParams}
      />

      <>
        {isLoading && <Spinner size={48} />}

        {!isLoading && (
          <PackagesPage
            purchases={purchases?.result}
            total={purchases?.total}
            page={currentPage}
            sizePerPage={sizePerPage}
            onPageChange={onPageChange}
          />
        )}
      </>
    </>
  );
};

export default withRouter(withTenant(PackagesPageContainer));
