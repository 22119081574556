import React, { useCallback, useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePlacesAutocomplete, * as UPA from 'use-places-autocomplete';
import * as RE from '~/reducers/experiences';

import { Box, Container, SelectChangeEvent, Tab, Tabs } from '@mui/material';

import { FILTER_RANGE_KEYS, FilerKeys } from '~/consts/experiences';

import * as ES from '~/services/ExperiencesService';
import { downloadExperiencesOffersReport } from '~/services/ReportingService';

import { addQuery } from '~/utils/url';

import HomepageCuration from './Content/HomepageCuration';
import { ExperiencesContent } from './ExperiencesContent';
import { ExperiencesFormFilters, SearchModeOptions } from './ExperiencesFormFilters';

export type FieldValueParams = {
  key: string;
  fieldValue: string;
  range?: 'min' | 'max';
};

export type Toggle = {
  [key: string]: boolean;
};

const toggleOptions = ['card', 'table'];

//Melbourn coordinates
const INITIAL_LOCATION = {
  coordinates: { lat: -37.828825993729765, lng: 144.9598820849199 },
  distance: '629KM',
};

type CurationTab = 'homepage-curation' | 'experiences-curation';

export default function Experiences() {
  const tenant = useSelector((state: App.State) => state.tenant);

  const history = useHistory();
  const cancelFetchRef = useRef<() => void | undefined>();

  const [toggle, setToggle] = useState<Toggle>({ table: true });
  const [fieldSearchValue, setFieldSearchValue] = useState<string>('');
  const [filters, setFilters] = useState<ES.Filters>({
    ...ES.initialFiltersValues,
    ...INITIAL_LOCATION,
  });
  const [tab, setTab] = useState<CurationTab>('homepage-curation');
  const [searchModeSelected, setSearchModeSelected] = useState(SearchModeOptions.LOCATION);

  const sizePerPage = tab === 'homepage-curation' ? 20 : 100;

  const [categories, categoriesDispatch] = React.useReducer<React.Reducer<RE.CategoriesState, RE.CategoriesAction>>(
    RE.fetchCategoriesReducer,
    RE.initialCategories,
  );

  const [experiences, experiencesDispatch] = React.useReducer<React.Reducer<RE.ExperiencesState, RE.ExperiencesAction>>(
    RE.fetchExperiencesReducer,
    RE.initialExperiences,
  );

  const [page, setPage] = useState<number>(1);

  const { enqueueSnackbar } = useSnackbar();

  const canFetchExperiences =
    searchModeSelected != SearchModeOptions.LOCATION ||
    (filters.coordinates && !!filters.distance && filters.distance !== '0KM');

  const {
    ready,
    setValue,
    clearSuggestions,
    suggestions: { status, data },
  } = usePlacesAutocomplete({ debounce: 300 });

  const onPageChange = (page: number): void => {
    const { search } = addQuery(location, { page });
    history.push(search);
    setPage(page);
  };

  const fetchCategories = async (): Promise<void> => {
    categoriesDispatch({ type: 'LOAD' });
    try {
      const res = await ES.getCategories(tenant.brand);

      if (res?.errors?.length > 0) {
        categoriesDispatch({
          type: 'ERROR',
          message: "[ERROR] Don't exists categories response.",
        });
      }

      categoriesDispatch({
        type: 'FETCH',
        payload: res.result,
      });
    } catch (error) {
      console.error('ERROR ON FETCH CATEGORIES: ', error);
      categoriesDispatch({ type: 'ERROR', message: error.message });
    }
  };

  const fetchExperiences = async (filters: ES.Filters, page: number): Promise<void> => {
    experiencesDispatch({ type: 'LOAD' });
    try {
      const offset: number = (page - 1) * sizePerPage;

      const res = await ES.getExperienceOffers({
        curationData: true,
        offset,
        filters:
          tab === 'homepage-curation'
            ? { ...filters, status: ['APPROVED'] } // force homepage-curation only list curated experiences
            : filters,
        limit: sizePerPage,
        brand: tenant.brand,
        getController: (controller) => {
          if (cancelFetchRef.current) cancelFetchRef.current();
          cancelFetchRef.current = controller.cancel;
        },
      });

      if (res?.errors?.length > 0) {
        experiencesDispatch({
          type: 'ERROR',
          message: "[ERROR] Don't exists experiences response.",
        });
      }

      experiencesDispatch({
        type: 'FETCH',
        total: res?.total,
        payload: res.result,
      });
    } catch (error) {
      console.error('ERROR ON FETCH EXPERIENCES: ', error);
      experiencesDispatch({
        type: 'ERROR',
        message: error.message,
      });
    }
  };

  const deboucedFetchExperiences = React.useMemo(() => debounce(fetchExperiences, 1000), [tab, tenant.brand]);

  const searchOptions: string[] = React.useMemo(
    () => (status === 'OK' ? data?.map((option) => option?.description) : []),
    [status, data],
  );

  const handlePage = useCallback((_, page: number) => setPage(page), []);

  const handleSearchFieldValue = useCallback((fieldValue: string) => {
    setValue(fieldValue);
    setFieldSearchValue(fieldValue);
  }, []);

  const handleSearchModeSelected = useCallback((event: SelectChangeEvent) => {
    setSearchModeSelected(event.target.value as SearchModeOptions);
  }, []);

  const handleFieldValue = useCallback(
    (params: FieldValueParams) => {
      const { key, range, fieldValue } = params;

      if (FILTER_RANGE_KEYS.includes(key)) {
        setFilters((filters) => ({
          ...filters,
          [key]: { ...filters[key], [range]: fieldValue },
        }));
      }
    },
    [filters.price, filters.rating],
  );

  const setFilter = useCallback((name: keyof ES.Filters, value: unknown) => {
    setFilters((filters) => ({ ...filters, [name]: value }));
  }, []);

  const resetFilters = useCallback(() => {
    setFilters((filter) => {
      const resetedFilters = {
        ...ES.initialFiltersValues,
        distance: filter.distance,
        coordinates: filter.coordinates,
      };

      Object.values(SearchModeOptions).forEach((key) => (resetedFilters[key] = filter[key] ?? null));

      return resetedFilters;
    });
  }, [filters]);

  const setFiltersByKey = useCallback(
    (key: FilerKeys, value: string) => {
      const items = filters[key] ?? [];
      setFilter(key, items.includes(value) ? items.filter((item) => item !== value) : [...items, value]);
    },
    [filters.categories, filters.status, filters.providerIn],
  );

  const onSelectLocation = useCallback(async (address: string) => {
    clearSuggestions();
    setFieldSearchValue(address);
    const position = await UPA.getGeocode({ address });
    const coordinates = await UPA.getLatLng(position[0]);

    setFilter('coordinates', coordinates);
  }, []);

  const onChangeSearchValue = (fieldSearchValue: string, searchModeSelected: string) => {
    if (searchModeSelected !== SearchModeOptions.LOCATION) {
      Object.values(SearchModeOptions).forEach((key) => delete filters[key]);
      filters[searchModeSelected] = fieldSearchValue;
      refetchExperiences();
      setFilters(filters);
    }
  };

  const refetchExperiences = useCallback(() => {
    deboucedFetchExperiences(filters, 1);
  }, [filters]);

  const handleToggle = useCallback((opt: string) => setToggle({ [opt]: true }), []);

  const fetchExperiencesWithFilters = useCallback(() => {
    if (!canFetchExperiences) return;

    onPageChange(1);
    deboucedFetchExperiences(filters, 1);
  }, [filters]);

  const [bulkProcessing, setBulkProcessing] = useState(false);
  const bulkUpdate = useCallback(() => {
    setBulkProcessing(true);
    ES.bulkUpdateOffers()
      .then(() => {
        enqueueSnackbar('Success', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Error', { variant: 'error' });
      })
      .finally(() => {
        setBulkProcessing(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [downloadingOffersReport, setDownloadingOffersReport] = useState(false);
  const downloadOffersReport = useCallback((format) => {
    setDownloadingOffersReport(true);
    downloadExperiencesOffersReport({ format })
      .then((data) => {
        const fileName = `experience-live-report.${format}`;
        fileDownload(data, fileName);
        enqueueSnackbar('Success', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Error', { variant: 'error' });
      })
      .finally(() => {
        setDownloadingOffersReport(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    deboucedFetchExperiences(filters, page);
    if (page !== 1) setPage(1);
  }, [tab, filters]);

  useEffect(() => {
    if (canFetchExperiences) deboucedFetchExperiences(filters, page);
  }, [filters.distance, filters.coordinates]);

  useEffect(() => {
    if (canFetchExperiences) deboucedFetchExperiences(filters, page);
  }, [page]);

  useEffect(() => {
    fetchCategories();
    if (canFetchExperiences) deboucedFetchExperiences(filters, page);
  }, [tenant]);

  useEffect(() => {
    onChangeSearchValue(fieldSearchValue, searchModeSelected);
  }, [fieldSearchValue]);

  useEffect(() => {
    setFieldSearchValue('');

    setFilters((filters) => {
      if (
        searchModeSelected === SearchModeOptions.ID ||
        searchModeSelected === SearchModeOptions.TITLE ||
        searchModeSelected === SearchModeOptions.VENDOR
      ) {
        filters.coordinates = null;
        filters.distance = null;
      } else if (searchModeSelected === SearchModeOptions.LOCATION) {
        filters[SearchModeOptions.ID] = null;
        filters[SearchModeOptions.TITLE] = null;
        filters[SearchModeOptions.VENDOR] = null;
      }

      return filters;
    });
  }, [searchModeSelected]);

  return (
    <Container maxWidth="xl">
      <Tabs sx={{ mb: 2 }} value={tab} onChange={(_, value) => setTab(value)} id="experience-tabs">
        <Tab value="homepage-curation" label="Homepage Curation" />
        <Tab value="experiences-curation" label="Experiences Curation" />
      </Tabs>

      <Box mt={4}>
        <ExperiencesFormFilters
          tab={tab}
          ready={ready}
          filters={filters}
          categories={categories}
          toggleSelected={toggle}
          resetFilters={resetFilters}
          toggleOptions={toggleOptions}
          onSelectToggle={handleToggle}
          searchOptions={searchOptions}
          setFiltersByKey={setFiltersByKey}
          fieldSearchValue={fieldSearchValue}
          handleFieldValue={handleFieldValue}
          onSelectLocation={onSelectLocation}
          handleSearchFieldValue={handleSearchFieldValue}
          fetchExperiencesWithFilters={fetchExperiencesWithFilters}
          searchModeSelected={searchModeSelected}
          setSearchModeSelected={handleSearchModeSelected}
          bulkUpdate={bulkUpdate}
          bulkProcessing={bulkProcessing}
          downloadOffersReport={downloadOffersReport}
          downloadingOffersReport={downloadingOffersReport}
        />
      </Box>

      <Box mt={4}>
        {tab === 'experiences-curation' && (
          <ExperiencesContent
            tenant={tenant}
            currentPage={page}
            handlePage={handlePage}
            toggleSelected={toggle}
            sizePerPage={sizePerPage}
            experiences={experiences}
            experiencesTotal={experiences?.total}
          />
        )}

        {tab === 'homepage-curation' && (
          <HomepageCuration
            experiences={experiences}
            experiencesTotal={experiences?.total}
            sizePerPage={sizePerPage}
            currentPage={page}
            handlePage={handlePage}
          />
        )}
      </Box>
    </Container>
  );
}
