import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import { property as propertyGlobals } from '@luxuryescapes/lib-global';

import PageHeader from '~/components/Common/Elements/PageHeader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import PropertyForm from '~/components/Common/Forms/PropertyForm';
import Spinner from '~/components/Common/Spinner';

import BedbankService from '~/services/BedbankService';
import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

type Props = RouteComponentProps<{ id_vendor: string; id_property: string }>;

export default function PropertyEditContainer(props: Props) {
  const { id_vendor: vendorId, id_property: propertyId } = props.match.params;

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [schema, setSchema] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [property, setProperty] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const [propertySchemaResult, propertyResult, vendorResult] = await Promise.all([
      ReservationService.getPropertySchema(propertyId),
      ReservationService.getProperty(propertyId),
      VendorsService.getVendorById(vendorId),
    ]);

    if (propertyResult.result.parent_property_id) {
      const parent = await ReservationService.getProperty(propertyResult.result.parent_property_id);
      if (parent && parent.result) {
        propertyResult.result.parentProperty = parent.result;
      }
    }

    if (propertyResult.result.bedbank_property_id) {
      const bedbankProperty = await BedbankService.getPropertyById(propertyResult.result.bedbank_property_id);
      if (bedbankProperty && bedbankProperty.result) {
        propertyResult.result.bedbankProperty = bedbankProperty.result;
      }
    }

    const propertySchema = propertySchemaResult.put.body.schema;
    propertySchema.properties.max_child_age.minimum = 1;

    // Do not display status for non-rental properties
    if (propertyResult.result.type === propertyGlobals.HOTEL_TYPE) {
      delete propertySchema.properties.status;
    }

    if (propertyResult.result.type === propertyGlobals.RENTAL_TYPE) {
      delete propertySchema.properties.logo_id_cloudinary_external;
    }

    setVendor(vendorResult.result);
    setProperty(propertyResult.result);
    setSchema(propertySchema);
    setLoading(false);
  }, [vendorId, propertyId]);

  useEffect(() => {
    fetchData().catch((err) => setError(err.mesage));
  }, [fetchData]);

  if (isLoading) {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="xl">
        <ErrorDisplay message={error} />
      </Container>
    );
  }

  const Form = PropertyForm as any;

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor?.name || vendorId} | {property?.name} | Edit Property
        </title>
      </Helmet>

      <PageHeader title="Edit property" backButton={`/vendors/${vendorId}/properties/${propertyId}`} />

      <Box>
        <Form property={property} schema={schema} vendorId={vendorId} />
      </Box>
    </Container>
  );
}
