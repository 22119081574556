/* Purchases/GiftCardDetail/Page.js */
import React from 'react';

import { Helmet } from 'react-helmet';

import { Container, Stack } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { brands } from '../../../consts/brands';
import OrderDetailPayments from '../OrderDetail/OrderDetailPayments';

import ResendConfirmationEmail from './Buttons/ResendConfirmationEmail';
import ItemList from './ItemList';
import OrderMeta from './Meta';

const LE_BRAND = 'luxuryescapes';

export default function GiftCardDetailPage({ order }) {
  const brand = brands.find((brand) => brand.value === (order.brand ?? LE_BRAND));

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Orders | {order.customer_full_name} | Gift Card</title>
      </Helmet>

      <PageHeader title="Order Summary" />

      <Stack direction="column" spacing={4}>
        <OrderMeta order={order} />

        <div>
          <PageSubheader title={`${brand.title} gift card`}>
            <ResendConfirmationEmail orderId={order.id} />
          </PageSubheader>

          <ItemList order={order} />
        </div>

        <OrderDetailPayments payments={order.payments} />
      </Stack>
    </Container>
  );
}
