import { CancellationModalSettingsFormValues } from '@/typings/formValues';
import dayjs from 'dayjs';

import { getCancellationModalSettings } from '~/services/SupportService';

const DEFAULTS = {
  DAYS_UNTIL_CANCELLATION_EXPIRY: 3,
  ORDER_TOTAL_VALUE: 5000,
  START_TIME: dayjs().hour(9).minute(0),
  END_TIME: dayjs().hour(17).minute(0),
  OFFER_TYPES: [],
  REGIONS: [],
};

/**
 * Shape the data from the form into a format accepted by the svc-support endpoint
 * POST /api/support/cancellation-modal-settings
 *
 * @param formData values from the form
 * @returns an object accepted by the cancellation modal settings endpoint
 */
export const formatRuleData = (formData: CancellationModalSettingsFormValues) => {
  let startTimeIso: string, endTimeIso: string;

  if (formData.startTime) {
    startTimeIso = formData.startTime.toISOString();
  }

  if (formData.endTime) {
    endTimeIso = formData.endTime.toISOString();
  }

  const orderTotalValueNumber = formData.orderTotalValue ? Number(formData.orderTotalValue) : undefined;
  const daysUntilCancellationExpiryNumber = formData.daysUntilCancellationExpiry
    ? Number(formData.daysUntilCancellationExpiry)
    : undefined;

  return {
    ...formData,
    startTime: startTimeIso,
    endTime: endTimeIso,
    orderTotalValue: orderTotalValueNumber,
    daysUntilCancellationExpiry: daysUntilCancellationExpiryNumber,
  };
};

export const fetchInitialValues = async () => {
  const data = await getCancellationModalSettings();
  const rules = data?.result?.rules;

  if (!rules || rules.length === 0) {
    return [];
  }

  const formattedRules = rules.map((rule: CancellationModalSettingsFormValues) => {
    return {
      ...rule,
      startTime: dayjs(rule.startTime),
      endTime: dayjs(rule.endTime),
    };
  });

  return formattedRules;
};

export const ruleInitialState: CancellationModalSettingsFormValues = {
  offerTypes: DEFAULTS.OFFER_TYPES,
  orderTotalValue: DEFAULTS.ORDER_TOTAL_VALUE,
  regions: DEFAULTS.REGIONS,
  startTime: DEFAULTS.START_TIME,
  endTime: DEFAULTS.END_TIME,
  daysUntilCancellationExpiry: DEFAULTS.DAYS_UNTIL_CANCELLATION_EXPIRY,
};
