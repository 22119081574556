import { useCallback, useState } from 'react';

import { Response } from '@luxuryescapes/contract-svc-tour/types/response';

import { basePath } from '~/services/ToursService';
import { json_headers, request } from '~/services/common';
import { ReqInstance } from '~/services/types';

export enum TourDetailsFormResources {
  ROOT = '',
  TOUR_OPTIONS = 'tour-options',
  TOUR_IMAGES = 'tour-images',
  TOUR_PRICINGS = 'tour-pricing',
  FINE_PRINT = 'fine-print',
  TRAVELLER_REQUIREMENTS = 'traveller-requirements',
  CAMPAIGN_SCHEDULE = 'campaign-schedule',
  INVENTORY = 'inventory',
  ACCOMMODATION_ADD_ONS = 'accommodation-add-ons',
  OPTIONAL_EXTRAS = 'optional-extras',
  USER_REVIEW_LINKED_TOURS = 'user-review-linked-tours',
}

export function useTourDetailsFormQuery(resource: TourDetailsFormResources.ROOT): {
  fetchRequestInstance: ReqInstance<Response.TourFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.TourFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.TOUR_OPTIONS): {
  fetchRequestInstance: ReqInstance<Response.TourOptionsFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.TourOptionsFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.TOUR_IMAGES): {
  fetchRequestInstance: ReqInstance<Response.TourOptionsFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.TourOptionsFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.TOUR_PRICINGS): {
  fetchRequestInstance: ReqInstance<Response.TourOptionsFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.TourOptionsFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.FINE_PRINT): {
  fetchRequestInstance: ReqInstance<Response.FinePrintFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.FinePrintFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.TRAVELLER_REQUIREMENTS): {
  fetchRequestInstance: ReqInstance<Response.TravelRequirementFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.TravelRequirementFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.CAMPAIGN_SCHEDULE): {
  fetchRequestInstance: ReqInstance<Response.CampaignScheduleFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.CampaignScheduleFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.ACCOMMODATION_ADD_ONS): {
  fetchRequestInstance: ReqInstance<Response.CampaignScheduleFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.CampaignScheduleFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.INVENTORY): {
  // This overload only has fetch
  fetchRequestInstance: ReqInstance<Response.InventoryFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.OPTIONAL_EXTRAS): {
  fetchRequestInstance: ReqInstance<Response.OptionalExtrasFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.OptionalExtrasFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery(resource: TourDetailsFormResources.USER_REVIEW_LINKED_TOURS): {
  fetchRequestInstance: ReqInstance<Response.UserReviewsLinkedToursFormResponse, string>;
  patchRequestInstance: ReqInstance<Response.UserReviewsLinkedToursFormResponse, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
};
export function useTourDetailsFormQuery<R = object>(
  resource: TourDetailsFormResources,
): {
  fetchRequestInstance: ReqInstance<R, string>;
  patchRequestInstance: ReqInstance<R, string>;
  fetch: (tourId: string) => Promise<void>;
  patch: (tourId: string, formData: unknown) => Promise<void>;
} {
  const getResourceUrl = useCallback(
    (tourId: string) =>
      resource === TourDetailsFormResources.ROOT
        ? `${basePath()}/forms/${tourId}`
        : `${basePath()}/forms/${tourId}/${resource}`,
    [resource],
  );

  // FETCH
  const [fetchRequestInstance, setFetchRequestInstance] = useState<ReqInstance<R, string>>({
    status: 'uninitiated',
  });
  const fetch = useCallback(
    async (tourId: string) => {
      setFetchRequestInstance({ status: 'pending' });
      try {
        const response = await request(getResourceUrl(tourId), {
          method: 'GET',
        });
        setFetchRequestInstance({
          status: 'succeeded',
          result: response.result,
        });
      } catch (error) {
        setFetchRequestInstance({
          status: 'failed',
          error: JSON.stringify(error),
        });
      }
    },
    [getResourceUrl],
  );

  // PATCH
  const [patchRequestInstance, setPatchRequestInstance] = useState<ReqInstance<R, string>>({
    status: 'uninitiated',
  });
  const patch = useCallback(
    async (tourId: string, formData: unknown) => {
      setPatchRequestInstance({ status: 'pending' });
      try {
        const response = await request(getResourceUrl(tourId), {
          method: 'PATCH',
          headers: json_headers,
          body: JSON.stringify(formData),
        });
        setPatchRequestInstance({
          status: 'succeeded',
          result: response.result,
        });
      } catch (error) {
        setPatchRequestInstance({
          status: 'failed',
          error: JSON.stringify(error),
        });
      }
    },
    [getResourceUrl],
  );

  return { fetchRequestInstance, fetch, patchRequestInstance, patch };
}

export function useTourDetailsContextQuery() {
  const [contextFetchReq, setContextFetchReq] = useState<ReqInstance<Response.TourContextResponse, string>>({
    status: 'uninitiated',
  });
  const fetchContext = useCallback(async (tourId: string) => {
    setContextFetchReq({ status: 'pending' });
    try {
      const response = await request(`${basePath()}/forms/${tourId}/context`, {
        method: 'GET',
      });
      setContextFetchReq({
        status: 'succeeded',
        result: response.result,
      });
    } catch (error) {
      setContextFetchReq({
        status: 'failed',
        error: JSON.stringify(error),
      });
    }
  }, []);

  return { contextFetchReq, fetchContext };
}
