import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';

import { ChatStats } from '~/components/SupportAssistant/components/ChatStats';
import { FeedbackStats } from '~/components/SupportAssistant/components/FeedbackStats';

const TabsMap = {
  feedback: {
    label: 'Feedback stats',
    slug: 'feedback-stats',
    component: () => <FeedbackStats />,
  },
  chats: {
    label: 'Chat stats',
    slug: 'chat-stats',
    component: () => <ChatStats />,
  },
} as const;

const allTabs = Object.keys(TabsMap);

const StatisticsPage = () => {
  const [currentTab, setCurrentTab] = useState('feedback');

  const title = `Support - ${TabsMap[currentTab].label}`;

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Typography variant="h3">Stats</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs onChange={handleTabChange} value={currentTab}>
          {allTabs.map((tab) => (
            <Tab
              key={tab}
              value={tab}
              label={TabsMap[tab].label}
              component="div"
              sx={{
                alignItems: 'start',
                '&.Mui-selected': {
                  backgroundColor: 'aliceblue',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box mt={3}>
        <Box component={TabsMap[currentTab].component} />
      </Box>
    </Stack>
  );
};

export default StatisticsPage;
