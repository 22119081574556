import React from 'react';

import { useHistory } from 'react-router';

import { Box, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import AmenitiesSearchResults from './AmenitiesSearchResults';

const PAGE_SIZE = 10;

interface Props {
  amenities: App.Amenity[];
  page: number;
  setPage: (page: number) => void;
  isSearching: boolean;
  total: number;
  searchName: string;
  setSearchName: (name: string) => void;
  searchBuiltIn: boolean;
  setSearchBuiltIn: (builtIn: boolean) => void;
}

export default function AmenitiesSearchForm({
  amenities,
  page,
  setPage,
  isSearching,
  total,
  searchName,
  setSearchName,
  searchBuiltIn,
  setSearchBuiltIn,
}: Props) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const history = useHistory();
  const handleRowClick = (row: { id: number }) => {
    const uri = '/amenities/' + row.id;
    history.push(uri);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box flex={1}>
            <TextField
              id="name"
              data-testid="name"
              type="text"
              name="name"
              label="Enter Amenity Name or Partial"
              placeholder="Enter Amenity Name or Partial"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              autoFocus
              fullWidth
            />
            <FormControlLabel
              control={<Checkbox checked={searchBuiltIn} onChange={(e) => setSearchBuiltIn(e.target.checked)} />}
              label="Built In"
            />
          </Box>
        </Stack>
      </form>

      {isSearching && (
        <Box mt={4}>
          <Spinner />
        </Box>
      )}

      {!isSearching && amenities && !amenities.length && (
        <Box mt={4}>
          <Typography variant="subtitle1">No amenities found for your request...</Typography>
        </Box>
      )}

      {amenities.length > 0 && (
        <Box mt={4}>
          <AmenitiesSearchResults
            data={amenities}
            onRowClick={handleRowClick}
            onPageChange={setPage}
            page={page}
            pageSize={PAGE_SIZE}
            total={total}
          />
        </Box>
      )}
    </Box>
  );
}
