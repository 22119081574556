import React from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowClassNameParams, GridRowParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import ResponsiveImage from '~/components/Common/Elements/ResponsiveImage';

import { OFFER_FACING_NAMES } from '~/consts/offerTypes';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Offer Name',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    display: 'flex',
  },
  {
    field: 'id_salesforce_external',
    headerName: 'Offer ID',
    sortable: false,
    disableColumnMenu: true,
    width: 200,
    display: 'flex',
  },
  {
    field: 'vendor_name',
    headerName: 'Vendor Name',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'type',
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter(value) {
      return OFFER_FACING_NAMES[value];
    },
    display: 'flex',
  },
  {
    field: 'locations',
    headerName: 'Locations',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter(value: string[] | null) {
      return value ? value.join(', ') : '-';
    },
    display: 'flex',
  },
  {
    field: 'images',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    width: 180,
    align: 'center',
    renderCell(params: GridRenderCellParams<App.Offer, App.OfferImage[]>) {
      if (params.value.length == 0) {
        return '';
      }

      const imageId = params.value[0].id_cloudinary_external;

      return <ResponsiveImage imageId={imageId} />;
    },
    display: 'flex',
  },
];

type Props = {
  offers: App.Offer[] | null;
  total: number;
  page: number;
  sizePerPage: number;
  loading?: boolean;
  onPageChange: (page: number) => void;
  formatTrClass: (row: App.Offer) => string;
  urlBuilder?: (row: App.Offer) => string;
};

export default function OffersPageTable(props: Props) {
  const { loading, offers, total, page, onPageChange, sizePerPage } = props;

  const handleRowClick = (row) => {
    window.open(props.urlBuilder(row), '_blank');
  };

  return (
    <>
      <Box mt={2}>
        <DataGrid
          className="T-offers-table"
          rows={offers || []}
          columns={columns}
          loading={loading}
          pagination
          paginationModel={{ page: page - 1, pageSize: sizePerPage }}
          pageSizeOptions={[10]}
          getRowId={(row: App.Offer) => row.id_salesforce_external}
          paginationMode="server"
          rowCount={total}
          getRowHeight={() => 'auto'}
          getRowClassName={(params: GridRowClassNameParams<App.Offer>) => props.formatTrClass(params.row)}
          onPaginationModelChange={({ page }) => onPageChange(page + 1)}
          onRowClick={(row: GridRowParams<App.Offer>) => handleRowClick(row.row)}
          slots={{ pagination: GridPagination }}
          autoHeight // dangerous if number of rows per page > 25
        />
      </Box>
    </>
  );
}
