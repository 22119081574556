import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container } from '@mui/material';

import { deselectBadge, fetchBadges, selectBadge, submitSelectedBadges } from '../../../actions/badges';
import OffersService from '../../../services/OffersService';
import BadgesPage from '../../Marketing/Badges/BadgesPage';

import OfferBadgesList from './OfferBadgesList';

interface Props {
  items: App.Badge[];
  selectedBadges: any[];
  fetchBadges: () => void;
  selectBadge: (id: any) => void;
  deselectBadge: (id: any) => void;
  submitSelectedBadges: (offerId: string, badges: App.Badge[]) => Promise<void>;
}
const BadgesEditContainer = (props: Props) => {
  const { id_offer: offerId } = useParams<{ id_offer: string }>();
  const { items: propBadges, selectedBadges } = props;
  const { fetchBadges, selectBadge, deselectBadge, submitSelectedBadges } = props;
  const sizePerPage = 5;
  const [offer, setOffer] = useState<App.Offer>(null);
  const [offerBadges, setOfferBadges] = useState([]);

  const fetchOffer = async () => {
    const { result: offer } = await OffersService.getOffer(offerId);
    setOffer(offer);
    setOfferBadges(offer.badges);
  };

  useEffect(() => {
    fetchBadges();
    fetchOffer();
  }, []);

  const onBadgeSelect = (row, isSelected) => {
    if (isSelected) {
      selectBadge(row);
    } else {
      deselectBadge(row);
    }
  };

  const addBadgesToOffer = () => {
    submitSelectedBadges(offerId, selectedBadges).then(() => {
      fetchOffer();
    });
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Offers | {offer?.name || offerId} | Edit Badges</title>
      </Helmet>
      <div className="badges T-badges-page">
        <Button component={Link} to={'/offers/' + offerId} startIcon={<ChevronLeftIcon />} className="T-return">
          Return to offer
        </Button>
        <h3 className="page-header">Add Badge</h3>
        <h4>Added Badges</h4>
        <OfferBadgesList onUpdate={fetchOffer} badges={offerBadges} offerId={offerId} />
        <h3>Search for a badge</h3>
        <BadgesPage
          badges={propBadges}
          sizePerPage={sizePerPage}
          extended={false}
          onBadgeSelect={onBadgeSelect}
          selectedBadges={selectedBadges}
        />
        <Button
          sx={{ marginLeft: 'auto' }}
          type="submit"
          onClick={addBadgesToOffer}
          variant="contained"
          color="primary"
        >
          Add to offer
        </Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { isLoading, isUpdating, error, items, selected } = state.badges;

  return {
    error,
    isLoading,
    isUpdating,
    items,
    selectedBadges: selected,
  };
};

const mapDispatchToProps = {
  fetchBadges,
  selectBadge,
  deselectBadge,
  submitSelectedBadges,
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgesEditContainer);
