import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Stack } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import { ConfirmDeleteDialog } from '~/components/Finance/VendorPayments/ConfirmDeleteDialog';

import { Remittance } from '~/consts/remittance';

import { addDays, formatDateISO } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import filterRemittance from '~/utils/filterRemittance';
import { isRemittanceCreated, isRemittanceDraft } from '~/utils/remittance';
import { reportError } from '~/utils/reportError';

import VendorsService from '../../../services/VendorsService';

import ConfirmActionDialog from './ConfirmActionDialog';
import RemittanceBulkActionButton from './RemittanceBulkActionButton';
import RemittanceTable from './RemittanceTable';
import { REMITTANCE_TYPE_NEW, REMITTANCE_TYPE_SENT } from './constants/sentType';

export type DateOfPayment = {
  from: string;
  to: string;
};

export enum BULK_ACTIONS {
  email = 'bulkEmail',
  create = 'bulkCreate',
}

const LE_BRAND = 'luxuryescapes';

const RemittanceContainer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const yesterday = formatDateISO(addDays(-1));

  const [remittanceAuthors, setRemittanceAuthors] = useState<Map<string, App.User>>(new Map());
  const [remittances, setRemittances] = useState<Remittance[]>([]);
  const [selectedRemittanceIds, setSelectedRemittanceIds] = useState<(string | number)[]>([]);
  const [sentType, setSentType] = useState<typeof REMITTANCE_TYPE_NEW>(REMITTANCE_TYPE_NEW);
  const [dateOfPayment, setDateOfPayment] = useState<DateOfPayment>({
    from: yesterday,
    to: yesterday,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isBulkActionLoading, setIsBulkActionLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [remittanceIdForDeletion, setRemittanceIdForDeletion] = useState(null);
  const [selectedBulkAction, setSelectedBulkAction] = useState<BULK_ACTIONS | null>(null);

  const areDraftRemittancesSelected = useMemo(
    () => selectedRemittanceIds.some(isRemittanceDraft),
    [selectedRemittanceIds],
  );
  const areCreatedRemittancesSelected = useMemo(
    () => selectedRemittanceIds.some(isRemittanceCreated),
    [selectedRemittanceIds],
  );
  const selectedRemittances = useMemo(() => {
    if (remittances.length === 0) {
      return [];
    }

    return remittances.filter((remittance) => selectedRemittanceIds.includes(remittance.id));
  }, [remittances, selectedRemittanceIds]);

  const onEmailSelectedClick = async () => {
    try {
      const createdRemittanceIds = selectedRemittanceIds.filter(isRemittanceCreated);

      setIsBulkActionLoading(true);
      await VendorsService.emailRemittance(createdRemittanceIds);
      setIsBulkActionLoading(false);

      enqueueSnackbar(`Emailing remittances completed`, {
        variant: 'success',
      });

      getRemittances(dateOfPayment, sentType);
    } catch (error) {
      reportError(error);
      enqueueSnackbar(`Emailing remittance failed: ${error.message}`, {
        variant: 'error',
      });
    }
  };

  const onCreateSelectedClick = async () => {
    try {
      const draftRemittances = selectedRemittanceIds.filter(isRemittanceDraft);

      setIsBulkActionLoading(true);
      // each draft remittanceId is a string like vendorId:costCurrency:dateOfPayment
      await VendorsService.createRemittances(
        draftRemittances.map((draftRemittance: string) => {
          const remittanceFields = draftRemittance.split(':');

          return {
            vendor_id: remittanceFields[0],
            cost_currency: remittanceFields[1],
            date_of_payment: remittanceFields[2],
          };
        }),
      );
      setIsBulkActionLoading(false);

      enqueueSnackbar(`Remittances created`, {
        variant: 'success',
      });

      getRemittances(dateOfPayment, sentType);
    } catch (error) {
      reportError(error);
      enqueueSnackbar(`Creating remittance failed: ${error.message}`, {
        variant: 'error',
      });
    }
  };

  const onBulkActionClick = (action: BULK_ACTIONS) => {
    setSelectedBulkAction(action);
    setIsConfirmDialogOpen(true);
  };

  const onBulkActionConfirm = () => {
    if (selectedBulkAction === BULK_ACTIONS.email) {
      onEmailSelectedClick();
    } else if (selectedBulkAction === BULK_ACTIONS.create) {
      onCreateSelectedClick();
    }

    setIsConfirmDialogOpen(false);
  };

  const getRemittances = useCallback(
    async (dateOfPayment, sentType) => {
      setIsLoading(true);

      try {
        const emailSent = sentType === REMITTANCE_TYPE_SENT;
        let page = 1;
        let remittanceData: {
          paginatedRemittance: unknown[];
          paginatedPotentialRemittance: unknown[];
        };
        let remittance = [];
        let potentialRemittance = [];

        while ((remittanceData = await VendorsService.getAllRemittance(dateOfPayment, emailSent, page))) {
          const { paginatedRemittance, paginatedPotentialRemittance } = remittanceData;
          if (
            paginatedRemittance &&
            paginatedPotentialRemittance &&
            (paginatedRemittance.length || paginatedPotentialRemittance.length) &&
            page < 10
          ) {
            remittance = remittance.concat(paginatedRemittance);
            potentialRemittance = potentialRemittance.concat(paginatedPotentialRemittance);
            page = page + 1;
          } else {
            break;
          }
        }

        const result = filterRemittance(remittance, potentialRemittance, emailSent);

        setRemittances(result);
        setIsLoading(false);
      } catch (error) {
        reportError(error);
        enqueueSnackbar(`Remittance lookup failed: ${error.message}`, {
          variant: 'error',
        });
        setIsLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleCreateRemittance = useCallback(
    async (vendorId, dateOfPayment, costCurrency) => {
      try {
        await VendorsService.createRemittances([
          {
            vendor_id: vendorId,
            cost_currency: costCurrency,
            date_of_payment: dateOfPayment,
          },
        ]);

        getRemittances(dateOfPayment, sentType);
      } catch (error) {
        reportError(error);
        enqueueSnackbar(`Creating remittance failed: ${error.message}`, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, getRemittances, sentType],
  );

  const handleDeleteRemittance = async () => {
    try {
      await VendorsService.deleteRemittance(remittanceIdForDeletion);

      getRemittances(dateOfPayment, sentType);
    } catch (error) {
      reportError(error);
      enqueueSnackbar(`Deleting remittance failed: ${error.message}`, {
        variant: 'error',
      });
    } finally {
      setRemittanceIdForDeletion(null);
    }
  };

  const handleDownloadRemittance = useCallback(
    async (id, type) => {
      try {
        enqueueSnackbar('Download started', {
          variant: 'info',
        });

        await VendorsService.downloadRemittance(id, type);
      } catch (error) {
        reportError(error);
        enqueueSnackbar(`Remittance download failed: ${error.message}`, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar],
  );

  const handleEmailRemittance = useCallback(
    async (id) => {
      try {
        enqueueSnackbar('Sending email...', {
          variant: 'info',
        });

        await VendorsService.emailRemittance([id]);

        await getRemittances(dateOfPayment, sentType);

        enqueueSnackbar('Email sent successfully', {
          variant: 'success',
        });
      } catch (error) {
        reportError(error);
        enqueueSnackbar(`Remittance email failed: ${error.message}`, {
          variant: 'error',
        });
      }
    },
    [dateOfPayment, enqueueSnackbar, getRemittances, sentType],
  );

  useEffect(() => {
    getRemittances(dateOfPayment, sentType);
  }, [dateOfPayment, getRemittances, sentType]);

  useEffect(() => {
    if (remittances.length > 0) {
      const remittanceAuthorIds = remittances.map((remittance) => remittance.added_by);

      UsersService.getUsersSummaryByIds([...new Set(remittanceAuthorIds)], LE_BRAND).then((users) =>
        setRemittanceAuthors(users),
      );
    }
  }, [remittances]);

  return (
    <>
      <ConfirmActionDialog
        handleClose={() => setIsConfirmDialogOpen(false)}
        handleConfirm={onBulkActionConfirm}
        isOpen={isConfirmDialogOpen}
        selectedBulkAction={selectedBulkAction}
        selectedRemittances={selectedRemittances}
      />
      <ConfirmDeleteDialog
        isOpen={remittanceIdForDeletion !== null}
        handleClose={() => setRemittanceIdForDeletion(null)}
        handleConfirm={handleDeleteRemittance}
      />
      <div className="remittance-container">
        <PageSubheader title="Remittance Advice" />
        <RemittanceTable
          data={remittances}
          dateOfPayment={dateOfPayment}
          handleChangeDateOfPayment={setDateOfPayment}
          handleChangeType={setSentType}
          handleCreateRemittance={handleCreateRemittance}
          handleDeleteRemittance={setRemittanceIdForDeletion}
          handleDownloadRemittance={handleDownloadRemittance}
          handleEmailRemittance={handleEmailRemittance}
          isLoading={isLoading}
          remittanceAuthors={remittanceAuthors}
          sentType={sentType}
          setSelectedRemittances={setSelectedRemittanceIds}
        />
        {!!selectedRemittanceIds?.length && !isLoading && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              backgroundColor: '#fff',
              bottom: 0,
              display: 'flex',
              padding: '10px 15px',
              position: 'sticky',
              zIndex: 200,
            }}
          >
            <RemittanceBulkActionButton
              disabled={!areCreatedRemittancesSelected || isBulkActionLoading}
              disabledTooltip="Please select created remittances"
              isLoading={isBulkActionLoading && selectedBulkAction === BULK_ACTIONS.email}
              label="Email selected"
              onClick={() => onBulkActionClick(BULK_ACTIONS.email)}
              tooltip="This will trigger emails for selected rows with remittance numbers"
            />
            <RemittanceBulkActionButton
              disabled={!areDraftRemittancesSelected || isBulkActionLoading}
              disabledTooltip="Please select draft remittances"
              isLoading={isBulkActionLoading && selectedBulkAction === BULK_ACTIONS.create}
              label="Create selected"
              onClick={() => onBulkActionClick(BULK_ACTIONS.create)}
              tooltip="This will create remittances for all selected items in draft status"
            />
          </Stack>
        )}
      </div>
    </>
  );
};

export default RemittanceContainer;
