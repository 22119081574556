import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import TourItinerary from './TourItinerary';

interface Props {
  visible?: boolean;
  onClose?: () => void;
  itinerary: Array<App.TourItineraryType>;
}

export default function TourItineraryModal({
  visible = false,
  onClose = () => {
    /* pass */
  },
  itinerary,
}: Props) {
  return (
    <div>
      <Dialog open={visible} onClose={onClose}>
        <DialogTitle>Itinerary</DialogTitle>

        <DialogContent>
          <TourItinerary itinerary={itinerary} />
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
