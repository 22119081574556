export type Padding = {
  p?: string;
  px?: string;
  py?: string;
  pt?: string;
  pb?: string;
  pr?: string;
  pl?: string;
};

export type Margin = {
  m?: string;
  mx?: string;
  my?: string;
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;
};

export type Spacing = Padding & Margin;

export const margin = (value: Margin, defaultValue?: string): string => {
  if (value?.m) return value.m;
  if (value?.mx || value?.my) return `${value.my || 0} ${value.mx || 0}`;
  if (value?.mt || value?.mr || value?.mb || value?.ml) {
    return `${value.mt || 0} ${value.mr || 0} ${value.mb || 0} ${value.ml || 0}`;
  }
  return defaultValue ?? '0px';
};

export const padding = (value: Padding, defaultValue?: string): string => {
  if (value?.p) return value.p;
  if (value?.px || value?.py) return `${value.py || 0} ${value.px || 0}`;
  if (value?.pt || value?.pr || value?.pb || value?.pl) {
    return `${value.pt || 0} ${value.pr || 0} ${value.pb || 0} ${value.pl || 0}`;
  }
  return defaultValue ?? '0px';
};
