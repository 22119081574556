import { addDays, formatDateISO } from '~/services/TimeService';

export const mapCustomOfferToCreatePayload = (customOffer: App.CustomOffer): App.CustomOfferCreatePayload => {
  const today = formatDateISO();

  // Need to reset historic dates so they are current for the duplicated custom offer
  // and mark all paid fields false
  return {
    cost_price: customOffer.cost_price,
    cost_price_currency: customOffer.cost_price_currency,
    customer_full_name: '',
    due_date: formatDateISO(addDays(1)),
    fk_customer_id: '',
    images: customOffer.images,
    items: customOffer.items,
    name: customOffer.name,
    notes: customOffer.notes,
    payment_terms: customOffer.payment_terms,
    payment_metadata: {
      ...customOffer.payment_metadata,
      vendor_was_paid: false,
      vendor_due_date: today,
      vendor_deposit_1_due_date: formatDateISO(addDays(1)),
      vendor_deposit_payment_made: false,

      customer_deposit_1_due_date: formatDateISO(addDays(1)),
      customer_deposit_payment_made: false,
    },
    price: customOffer.price,
    price_currency: customOffer.price_currency,
    sold_at: today,
  };
};
