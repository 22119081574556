import React from 'react';

import { Link } from 'react-router-dom';

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { formatDateLongMonthWithMeridiemNoSeconds } from '~/services/TimeService';

const getPax = (val) => {
  return (val?.adults || 0) + (val?.children || 0) + (val?.infants || 0);
};

const getComments = (val) => {
  if (val?.length > 20) {
    return val.substring(0, 20) + '...';
  }

  return val;
};

const columns: GridColDef[] = [
  {
    field: 'origin',
    headerName: 'Origin',
    flex: 3,
    display: 'flex',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'total_with_fees',
    headerName: 'Total w/ Fees',
    flex: 3,
    display: 'flex',
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'contact_phone_number',
    headerName: 'Phone No',
    flex: 3,
    display: 'flex',
  },
  {
    field: 'contact_email_address',
    headerName: 'Email',
    flex: 7,
    display: 'flex',
  },
  {
    field: 'previous_pnr_id',
    headerName: 'Previous PNR',
    flex: 3,
    display: 'flex',
  },
  {
    field: 'new_pnr_ids',
    headerName: 'New PNRs',
    flex: 3,
    display: 'flex',
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'passengers',
    headerName: 'Pax',
    flex: 2,
    valueGetter: (value) => getPax(value),
    display: 'flex',
  },
  {
    field: 'comments',
    headerName: 'Comments',
    flex: 3,
    valueGetter: (value) => getComments(value),
    display: 'flex',
  },
  {
    field: 'created_at',
    headerName: 'Created Date',
    flex: 3,
    valueFormatter: (value) => formatDateLongMonthWithMeridiemNoSeconds(value),
    display: 'flex',
  },
  {
    field: 'id',
    headerName: 'Action',
    flex: 3,
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`/flights/credit-reservation/${params.row.id}/details`}>View Request</Link>
    ),
    display: 'flex',
  },
];

export default function CreditReservationTable({ records, loading, page, total, onPageChange }) {
  return (
    <DataGrid
      autoHeight
      columns={columns}
      loading={loading}
      onPaginationModelChange={({ page }) => onPageChange(page + 1)}
      pagination
      paginationMode="server"
      paginationModel={{
        page: page - 1,
        pageSize: 100,
      }}
      rowCount={total}
      rows={records}
    />
  );
}
