import React, { useCallback, useMemo } from 'react';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { Grid } from '@mui/material';

import ReservationService from '~/services/ReservationService';

import AmenityGroupsSortableItem from './AmenityGroupsSortableItem';

type Props = {
  data: App.AmenityGroup[];
};

type SortableAmenityGroup = App.AmenityGroup & { checked: boolean };

export default function AmenityGroupSearchResults({ data }: Props) {
  const [groups, setGroups] = React.useState<SortableAmenityGroup[]>(
    data.map((group) => ({ ...group, checked: false })),
  );
  const [hasChanges, setHasChanges] = React.useState(false);
  const [saveButtonMessage, setSaveButtonMessage] = React.useState('No changes');

  const getEditUrl = (id: string) => {
    return '/amenity-groups/' + id;
  };

  const getAmenities = (amenities: App.Amenity[]) => {
    const amenitiesList = amenities.map((amenity) => amenity.name);
    return amenitiesList.join(' | ');
  };

  const SortableItem = SortableElement(({ value }) => {
    return (
      <AmenityGroupsSortableItem
        value={value}
        onGroupToggle={onGroupToggle}
        getAmenities={getAmenities}
        getEditUrl={getEditUrl}
      />
    );
  });

  const SortableList = SortableContainer(({ items }) => (
    <ul className="sortable-amenity-group-list">
      {items.map((group, index) => (
        <SortableItem key={group.id} index={index} value={{ index, group }} />
      ))}
    </ul>
  ));

  const recalculateListOrder = (groups: SortableAmenityGroup[]) => {
    let order = 1;
    for (const i in groups) {
      groups[i].order = order++;
    }
    return groups;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newGroupList = arrayMove(groups, oldIndex, newIndex);
    newGroupList = recalculateListOrder(newGroupList);

    setGroups(newGroupList);
    setHasChanges(true);
    setSaveButtonMessage('Save Changes');
  };

  const disableDragAndDrop = (e) => {
    // Disable drag and drop when you press an element inside the sortable
    //container, when the tag matches
    const tag = e.target.tagName.toLowerCase();
    if (tag === 'a' || tag === 'input') {
      return true;
    }
  };

  const onGroupToggle = useCallback(
    (index: number) => () => {
      const newGroups = [...groups];
      newGroups[index].checked = !newGroups[index].checked;
      setGroups(newGroups);
    },
    [groups],
  );

  const calculateDelta = (oldData: App.AmenityGroup[], changedData: App.AmenityGroup[]) => {
    const delta = [];
    for (const i in oldData) {
      if (oldData[i].id !== changedData[i].id) {
        delta.push(changedData[i]);
      }
    }
    return delta;
  };

  const saveChanges = useCallback(() => {
    const delta = calculateDelta(data, groups);
    ReservationService.updateAmenityGroups(delta);
    setHasChanges(false);
    setSaveButtonMessage('No changes');
  }, [data, groups]);

  const onPushToTopOrBottom = useCallback(
    (pushDirection: string) => () => {
      const checkedGroups = groups.filter((offer) => offer.checked === true);

      if (checkedGroups.length === 0) {
        return;
      }

      const uncheckedGroups = groups.filter((offer) => offer.checked === false);

      for (const offer of checkedGroups) {
        offer.checked = false;
      }

      let newGroups = [];
      switch (pushDirection) {
        case 'top':
          newGroups = checkedGroups.concat(uncheckedGroups);
          break;
        case 'bottom':
          newGroups = uncheckedGroups.concat(checkedGroups);
      }

      newGroups = recalculateListOrder(newGroups);
      setHasChanges(true);
      setSaveButtonMessage('Save Changes');
      setGroups(newGroups);
    },
    [groups],
  );

  const pushingDisabled: boolean = useMemo(() => {
    return groups.filter((group) => group.checked === true).length === 0;
  }, [groups]);

  return (
    <div className="group-list-order-page T-Group-List-Order">
      <Grid container spacing={2}>
        <Grid item md={10}>
          <p>Click and drag to re-order, the click Save to confirm the changes.</p>
        </Grid>
        <Grid item md={2}>
          <button type="button" className="btn btn-primary pull-right" disabled={!hasChanges} onClick={saveChanges}>
            {saveButtonMessage}
          </button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <button
            disabled={pushingDisabled}
            className={'group-default-button ' + (pushingDisabled ? 'group-default-button-disabled' : '')}
            onClick={onPushToTopOrBottom('top')}
          >
            Push to top
          </button>
          <button
            disabled={pushingDisabled}
            className={'group-default-button ' + (pushingDisabled ? 'group-default-button-disabled' : '')}
            onClick={onPushToTopOrBottom('bottom')}
          >
            Push to bottom
          </button>
        </Grid>
      </Grid>

      <SortableList
        items={groups}
        onSortEnd={onSortEnd}
        shouldCancelStart={disableDragAndDrop}
        lockAxis="y"
        lockOffset={0}
      />
    </div>
  );
}
