export default {
  // BuyMerchantRequestResponseParameters
  MERCHANT_ID: 'merchantID',
  REFERENCE_CODE: 'merchantReferenceCode',
  GRAND_TOTAL: 'purchaseTotals_grandTotalAmount',
  CURRENCY: 'purchaseTotals_currency',
  ITEM_COUNT: 'itemCount',
  PROMO_CODE: 'promoCode',
  PRODUCT_CODE: 'item_{}_productCode',
  TOTAL_AMOUNT: 'item_{}_totalAmount',
  PAGE_TIMESTAMP: 'orderPage_timestamp',
  PAGE_PUBLIC_SIGNATURE: 'orderPage_signaturePublic',
  PAGE_SIGNED_FIELDS: 'orderPage_signedFields',
  PAGE_VERSION: 'orderPage_version',
  PAGE_SERIAL_NUMBER: 'orderPage_serialNumber',
  HOP_DATA: 'eapps_hopData',
  AUTH_TYPE: 'authorizationType',
  AUTH_SERVICE_RUN: 'ccAuthService_run',
  CARD_ACCOUNT_NUMBER: 'card_accountNumber',
  CARD_EXP_YEAR: 'card_expirationYear',
  CARD_CV_NUMBER: 'card_cvNumber',
  CARD_EXP_MONTH: 'card_expirationMonth',
  AUTH_REPLY_CVCODE: 'ccAuthReply_cvCode',
  SIGNED_PUBLIC_SIGNATURE: 'signedDataPublicSignature',
  REASON_CODE: 'reasonCode',
  DECISION: 'decision',
  AUTH_REPLY_REASONCODE: 'ccAuthReply_reasonCode',
  AUTH_REPLY_CAVV_RESPONSE_CODE: 'ccAuthReply_cavvResponseCode',
  AUTH_REPLY_PROCESSOR_RESPONSE: 'ccAuthReply_processorResponse',
  AUTH_REPLY_AUTHORIZATION_TIME: 'ccAuthReply_authorizedDateTime',
  SIGNED_FIELDS: 'signedFields',
  AUTH_REPLY_CPS_TOKEN: 'ccAuthReply_cpsToken',
  REASON_MESSAGE: 'reasonMessage',
  AUTH_REPLY_AMOUNT: 'ccAuthReply_amount',
  AUTH_REPLY_PHONE_NUMBER: 'ccAuthReply_phoneNumber',
  SIGNED_FIELDS_PUBLIC_SIGNATURE: 'signedFieldsPublicSignature',

  // Apply related Constants

  SOURCE_FLAG_MERCHANT: '0',
  SOURCE_FLAG_HOP: '1',

  FIELD_SEPERATOR: '^',
  PARAMETER_SEPERATOR: '=',

  MANDATORY: 'M',
  OPTIONAL: 'O',

  AUTH_TYPE_CREDIT_CARD: 'C',
  AUTH_TYPE_SECURED_TOKEN: 'T',
  AUTH_TYPE_RETRIEVE_SMS: 'S',
  AUTH_TYPE_SMS_CODE: 'A',

  STREAM_UPSTREAM: 'Upstream',
  STREAM_DOWNSTREAM: 'DownStream',

  CHANNEL_ONLINE: 'Online',
  CHANNEL_RETAIL: 'Retail',

  //Merchant Input Parameters

  IN_DATA_BLOCK: 'merchant_eappsData',
  SOURCE_FLAG: 'source',

  CARD_TYPE: 'cardType',
  IP_ADDRESS: 'ipAddress',
  RETURN_URL: 'returnURL',
  STREAM: 'stream',
  CHANNEL: 'channel',
  GEMID1: 'gemid1',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  UNIT_NO: 'unitNo',
  STREET_NO: 'streetNo',
  PROPERTY_STREET_NAME: 'streetName',
  STREET_TYPE: 'streetType',
  CITY_TOWN_SUBURB: 'city',
  STATE: 'state',
  POST_CODE: 'postCode',
  MERCHANT_FIELD1: 'mField1',
  MERCHANT_FIELD2: 'mField2',
  MERCHANT_FIELD3: 'mField3',
  MERCHANT_FIELD4: 'mField4',

  //Merchant Output Parameters

  MERCHANT_OUT_DATA_BLOCK: 'eapps_merchantData',
  HOP_OUT_DATA_BLOCK: 'eapps_hopData',
  PARAM_VALID: 'validationFlag',
  APPLICATION_STATUS: 'eapps_appStatus',
  ACCOUNT_NUMBER: 'eapps_accountNumber',
  AVAILABLE_CREDIT_LIMIT: 'eapps_creditLimit',
  SECURE_TOKEN: 'eapps_geToken',
  TITLE: 'title',
  CONTACT_NO_HOME: 'contactNo',
  MOBILE_NO: 'mobileNo',
  EMAIL: 'email',
  PROCESS: 'process',
};
