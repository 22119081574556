import React, { Component } from 'react';

import PropertiesSearchResults from './PropertiesSearchResults';

interface PropertiesSearchContainerProps {
  history: any;
  total: number;
  sizePerPage: number;
  handlePageChange: (page: number) => void;
  page: number;
  data: Array<any>;
}

export default class PropertiesSearchResultsContainer extends Component<PropertiesSearchContainerProps> {
  render() {
    return (
      <PropertiesSearchResults
        onRowClick={(selection: any) => {
          const { history } = this.props;

          history.push(`/vendors/${selection.row.id_salesforce_external}/properties/${selection.row.id}`);
        }}
        {...this.props}
      />
    );
  }
}
