import React from 'react';

import { Button, Grid, TextField } from '@mui/material';

type Props = {
  searchString: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
};

export default function OfferAssociationsSearchForm(props: Props) {
  const { searchString, handleChange, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            id="OfferAssociationsSearch"
            type="text"
            name="searchString"
            placeholder="Search by any of the fields"
            value={searchString}
            onChange={handleChange}
            autoFocus
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button type="submit" variant="contained" className="T-search-user">
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
