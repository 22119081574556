import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerOfferDetails from '~/actions/customerCommunication/fetchHeroPlannerOfferDetails';

import { useAppSelector } from '../store';

function useHeroPlannerOfferDetails(countryGroupId: string, countryId: string, offerId: string) {
  const brand = useAppSelector((state) => state.tenant.brand);
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerOfferDetails> = [brand, countryGroupId, countryId, offerId];
    return [getRecordKeyFor(...params), params];
  }, [brand, countryGroupId, countryId, offerId]);
  const offerDetails = useAppSelector((state) => state.customerCommunication.offerDetailsRecords[key]);
  const appDispatch = useDispatch();
  const fetchOfferDetails = useCallback(() => {
    appDispatch(fetchHeroPlannerOfferDetails(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchOfferDetails();
  }, [fetchOfferDetails]);

  return { offerDetails, fetchOfferDetails };
}

export default useHeroPlannerOfferDetails;
