import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import { Button, Container, Stack, TextField } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ImagesForm from '~/components/Common/Forms/ImagesForm';

import { createCabinCategoriesGroup } from '~/services/cruises/CabinCategoriesGroupService';

import CabinCategoriesAutocomplete from '../../CruisesAutocomplete/CabinCategoriesAutocomplete';
import CruiseLineAutocomplete from '../../CruisesAutocomplete/CruiseLineAutocomplete';
import ShipAutocomplete from '../../CruisesAutocomplete/ShipAutocomplete';

interface Image {
  id: string | number;
  publicImageId: string;
  order: number;
  title?: string;
  hidden?: boolean;
  filename?: string;
}

type CreateCabinCategoriesGroupFormValues = {
  cabinCategoryGroupName: string;
  cabinCategoryGroupDescription: string;
  ship: {
    id: string;
    name: string;
  };
  cruiseLine: {
    id: string;
    code: string;
    name: string;
  };
  cabinCategoriesCodes: Array<{
    id: string;
    code: string;
  }>;
};

const DEFAULT_FORM_VALUES: CreateCabinCategoriesGroupFormValues = {
  cabinCategoryGroupName: '',
  cabinCategoryGroupDescription: '',
  ship: {
    id: '',
    name: '',
  },
  cruiseLine: {
    id: '',
    code: '',
    name: '',
  },
  cabinCategoriesCodes: [],
};

export default function CreateCabinCategoriesGroupPage() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [images, setImages] = React.useState<Image[]>([]);
  const [formValues, setFormValues] = useState<CreateCabinCategoriesGroupFormValues>(DEFAULT_FORM_VALUES);

  const onCruiseLineChange = useCallback((cruiseLine: { id: string; name: string; code: string }) => {
    setFormValues((prev) => ({ ...prev, cruiseLine }));
  }, []);

  const onShipChange = useCallback((ship: { id: string; name: string }) => {
    setFormValues((prev) => ({ ...prev, ship }));
  }, []);

  const onCabinCategoriesChange = useCallback((cabinCategories: { id: string; code: string }[]) => {
    setFormValues((prev) => ({ ...prev, cabinCategoriesCodes: cabinCategories }));
  }, []);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();

      if (event?.target && event?.target?.name) {
        setFormValues((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    },
    [setFormValues],
  );

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        const response = await createCabinCategoriesGroup({
          shipId: formValues.ship.id,
          name: formValues.cabinCategoryGroupName,
          description: formValues.cabinCategoryGroupDescription,
          cabinCategoriesIds: formValues.cabinCategoriesCodes.map((item) => item.id),
          images: images.map((image) => ({
            order: image.order,
            fileName: image.title,
            imageId: image.publicImageId,
          })),
        });

        if (response?.result) {
          enqueueSnackbar('Cabin Categories Group created successfully', {
            autoHideDuration: 5000,
            variant: 'success',
          });
        }

        history.push('/cruises/cabin-categories-groups');
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });
      }
    },
    [
      enqueueSnackbar,
      formValues.cabinCategoriesCodes,
      formValues.cabinCategoryGroupDescription,
      formValues.cabinCategoryGroupName,
      formValues.ship.id,
      history,
      images,
    ],
  );

  const onAddImage = useCallback(
    async (cloudinaryId: string, filename: string) => {
      const image = {
        title: filename,
        id: cloudinaryId,
        order: images.length,
        publicImageId: cloudinaryId,
      };

      setImages((prev) => [...prev, image]);

      return Promise.resolve(image);
    },
    [images.length],
  );

  const onUpdateImages = useCallback((images: Image[]) => {
    setImages(images);
    return images;
  }, []);

  const onDeleteImage = useCallback((imageId: string | number) => {
    setImages((prev) => prev.filter((image) => image.id !== imageId));
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Cruises | Create Cabin Categories Group</title>
      </Helmet>

      <PageSubheader title="Create Cabin Categories Group" />

      <Stack mt={3} spacing={3}>
        <form onSubmit={onSubmit} autoComplete="off">
          <Stack spacing={3}>
            <Stack spacing={2} direction="row">
              <CruiseLineAutocomplete selectedCruiseLine={formValues.cruiseLine} onChange={onCruiseLineChange} />
              <ShipAutocomplete
                requiredCruiseLine
                onChange={onShipChange}
                selectedShip={formValues.ship}
                cruiseLineName={formValues.cruiseLine.name}
              />
            </Stack>

            <Stack spacing={2} direction="row">
              <TextField
                required
                fullWidth
                onChange={handleChangeField}
                name="cabinCategoryGroupName"
                label="Cabin Categories Group Name"
                value={formValues.cabinCategoryGroupName}
              />
              <CabinCategoriesAutocomplete
                requiredShip
                shipId={formValues.ship.id}
                onChange={onCabinCategoriesChange}
                selectedCabinCategories={formValues.cabinCategoriesCodes}
              />
            </Stack>

            <Stack spacing={2} direction="row">
              <TextField
                required
                fullWidth
                multiline
                minRows={4}
                onChange={handleChangeField}
                name="cabinCategoryGroupDescription"
                label="Cabin Categories Group Description"
                value={formValues.cabinCategoryGroupDescription}
              />
            </Stack>

            <ImagesForm
              images={images}
              showSaveButton={false}
              onAddImage={onAddImage}
              onDeleteImage={onDeleteImage}
              onUpdateImages={onUpdateImages}
            />

            <Stack spacing={2} direction="row" justifyContent="end">
              <Button type="submit" variant="contained" color="primary">
                Create Group
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
