import React from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowClassNameParams } from '@mui/x-data-grid';

import GridPagination from './Elements/GridPagination';

type Props = {
  offers: App.Offer[];
  total: number;
  page: number;
  sizePerPage: number;
  loading?: boolean;
  fromVendor?: boolean;
  selectedOffers?: App.Offer[];
  onPageChange: (page: number) => void;
  getStatusIcon: (params: GridRenderCellParams<App.Offer, string>) => React.ReactNode;
  getLinkedCell: (params: GridRenderCellParams<App.Offer>) => React.ReactNode;
  getHumanizedLinkedCell: (params: GridRenderCellParams<App.Offer>) => React.ReactNode;
  getImage: (params: GridRenderCellParams<App.Offer>) => React.ReactNode;
  getCountCell: (params: GridRenderCellParams<App.Offer>) => React.ReactNode;
  formatTrClass: (row: App.Offer) => string;
};

export default function OfferCommonTable(props: Props) {
  const { loading, offers, total, page, onPageChange, sizePerPage, fromVendor, selectedOffers } = props;

  const columns: GridColDef<App.Offer>[] = [
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 70,
      renderCell: props.getStatusIcon,
      display: 'flex',
    },
    {
      field: 'id_salesforce_external',
      headerName: 'Id',
      sortable: false,
      width: 190,
      renderCell: props.getLinkedCell,
      display: 'flex',
    },
    {
      field: 'le_offer_id',
      headerName: 'LE Offer ID',
      sortable: false,
      width: 100,
      renderCell: props.getLinkedCell,
      display: 'flex',
    },
    { field: 'name', headerName: 'Title', sortable: false, flex: 1, renderCell: props.getLinkedCell, display: 'flex' },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      width: 210,
      renderCell: props.getHumanizedLinkedCell,
      display: 'flex',
    },
    {
      field: 'vendor_account_id',
      headerName: 'Vendor',
      hideable: true,
      sortable: false,
      width: 190,
      renderCell: props.getLinkedCell,
      display: 'flex',
    },
    {
      field: 'images',
      headerName: 'Offer Image',
      sortable: false,
      width: 180,
      renderCell: props.getImage,
      display: 'flex',
    },
    {
      field: 'counter',
      headerName: ' ',
      sortable: false,
      hideable: true,
      width: 120,
      renderCell: props.getCountCell,
      display: 'flex',
    },
  ];

  return (
    <Box mb={4}>
      <DataGrid
        className="T-offer-list-table"
        initialState={{
          columns: {
            columnVisibilityModel: {
              vendor_account_id: !!fromVendor,
              counter: !!selectedOffers,
            },
          },
        }}
        columns={columns}
        rows={offers ?? []}
        rowCount={total || 0}
        loading={loading}
        pagination
        paginationMode="server"
        paginationModel={{ page: page - 1, pageSize: sizePerPage }}
        pageSizeOptions={[0, sizePerPage]}
        onPaginationModelChange={({ page }) => onPageChange(page + 1)}
        getRowId={(row) => row.id_salesforce_external}
        getRowHeight={() => 'auto'}
        getRowClassName={(params: GridRowClassNameParams<App.Offer>) => props.formatTrClass(params.row)}
        slots={{ pagination: GridPagination }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </Box>
  );
}
