import React from 'react';

import classnames from 'clsx';
import currencyFormatter from 'currency-formatter';

import { formatDateDay } from '~/services/TimeService';

import { isBlackoutDay, isSurchargeDay } from './utils/day';

interface dayDate {
  date: any;
  day: any;
}

interface Props {
  currencyCode: string;
  dayDate: dayDate;
  isBlackoutDay: (dayDate: dayDate) => any;
  isCheckinDay: any;
  isCheckoutDay: any;
  isDayBlocked: any;
  isDayHighlighted: any;
  isSurchargeDay: (dayDate: dayDate) => any;
  key: any;
  onSelectDate: any;
}

class Day extends React.Component<Props> {
  formatDay = (date): string => {
    return formatDateDay(date);
  };

  formatSurcharge = (dayDate) => {
    const { currencyCode } = this.props;

    const date = dayDate.date;

    if (!date) {
      return currencyFormatter.format(0.0, {
        code: currencyCode,
        precision: 0,
      });
    }

    return currencyFormatter.format(date.check_in_surcharge_amount, {
      code: currencyCode,
      precision: 0,
    });
  };

  createDay = (day: React.ReactNode, className: string, isDisabled?: boolean, onClick?: any) => {
    const { dayDate } = this.props;

    return (
      <button disabled={isDisabled} className={classnames(className, 'day')} onClick={onClick}>
        <div className="date">{day}</div>
        {isSurchargeDay(dayDate) && Boolean(dayDate.date.check_in_surcharge_amount) && (
          <small className="surcharge-amount counts data-hj-whitelist">+ {this.formatSurcharge(dayDate)}</small>
        )}
      </button>
    );
  };

  render() {
    const { dayDate, onSelectDate, isDayBlocked, isDayHighlighted, isCheckinDay, isCheckoutDay } = this.props;

    const { day } = dayDate;

    if (!day) {
      return null;
    }

    if (isCheckinDay(day)) {
      return this.createDay(this.formatDay(day), classnames('day check-in'));
    }

    if (isBlackoutDay(dayDate) && isCheckoutDay(day)) {
      return this.createDay(<s>{this.formatDay(day)}</s>, classnames('day check-out'), true);
    }

    if (isBlackoutDay(dayDate)) {
      return this.createDay(<s>{this.formatDay(day)}</s>, classnames('day blocked'), true);
    }

    if (isDayBlocked(dayDate) && isDayHighlighted(day)) {
      return this.createDay(this.formatDay(day), classnames('day selected'), true);
    }

    if (isDayBlocked(dayDate) && isCheckoutDay(day)) {
      return this.createDay(this.formatDay(day), classnames('day check-out'), true);
    }

    if (isCheckoutDay(day)) {
      return this.createDay(this.formatDay(day), classnames('day check-out'), false, () => onSelectDate(dayDate));
    }

    if (isDayHighlighted(day)) {
      return this.createDay(this.formatDay(day), classnames('day selected'), false, () => onSelectDate(dayDate));
    }

    if (isDayBlocked(dayDate)) {
      return this.createDay(this.formatDay(day), classnames('day blocked'), true);
    }

    return this.createDay(this.formatDay(day), classnames('day T-availableDay data-hj-whitelist'), false, () =>
      onSelectDate(dayDate),
    );
  }
}

export default Day;
