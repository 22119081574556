import React, { useEffect, useState } from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { useParams } from 'react-router';

import { Container, Typography } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import TourReviewsEdit from './TourReviewsEdit';

export default function TourReviewsEditContainer() {
  const { id_vendor: vendorId, id_tour: tourId } = useParams<{ id_vendor: string; id_tour: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState<App.Vendor | null>(null);
  const [tour, setTour] = useState<API.Reservation.Tour | null>(null);
  const [schema, setSchema] = useState<RJSFSchema | null>(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getTour(tourId),
      ReservationService.getTourReviewSchema(tourId),
      VendorsService.getVendorById(vendorId),
    ])
      .then(([tour, schema, vendor]) => {
        setTour(tour.result);
        setSchema(schema.post.body.schema);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, tourId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load tour or vendor.</Typography>
      </Container>
    );
  }

  return (
    <TourReviewsEdit
      tour={tour}
      reviewsData={tour.reviews_count ? tour.reviews : []}
      tourId={tour.id}
      schema={schema}
      vendorId={vendorId}
      vendor={vendor}
    />
  );
}
