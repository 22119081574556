import React from 'react';

import ReactArrow from 'react-arrow';

const HEAD_LENGTH = 15;

class Arrow extends React.Component {
  getLength() {
    const { start, end } = this.props;
    return Math.max(Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)) - HEAD_LENGTH, 0);
  }

  getAngle() {
    const { start, end } = this.props;
    return Math.atan2(end.y - start.y, end.x - start.x);
  }

  generateStyle() {
    const { start } = this.props;

    return {
      position: 'absolute',
      top: start.y,
      left: start.x,
      zIndex: 1,
      transformOrigin: '0 9px',
      transform: `translate(-1px, -12px) rotate(${this.getAngle()}rad)`,
    };
  }

  render() {
    return (
      <div style={this.generateStyle()}>
        <ReactArrow
          direction="right"
          shaftWidth={2}
          shaftLength={this.getLength()}
          headWidth={14}
          headLength={HEAD_LENGTH}
          fill="black"
          stroke="black"
          strokeWidth={1}
        />
      </div>
    );
  }
}

export default Arrow;
