import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

const locationFormatter = (params: GridRenderCellParams<App.Order>) => {
  const row = params.row;

  if (!row.items.length) {
    return null;
  }

  return (
    <Stack direction="column">
      {row.items.map((item) => (
        <Typography key={item.booking_number} component="span">
          {item.offer?.location}
        </Typography>
      ))}
    </Stack>
  );
};

export default locationFormatter;
