import React from 'react';

import { Helmet } from 'react-helmet';

import { Box } from '@mui/material';

import DebugModal from '~/components/DebugModal/DebugModal';
import PromoLogs from '~/components/PromoV3/PromoLogs';

import useQuery from '~/hooks/useQuery';

import { withTenant } from '../../hoc';
import PromoFailures from '../PromoFailure/PromoFailures';
import PromoUsers from '../PromoUsers';
import usePromoMeta from '../hooks/usePromoMeta';

import PromoRequests from './PromoRequests';

interface Props {
  tenant: App.Tenant;
}

const DEFAULT_PAGE_SIZE = 10;

function PromoRequestsContainer({ tenant }: Props) {
  const query = useQuery();

  const userId = query.get('userId');
  const codeName = query.get('codeName');
  const page = Number(query.get('page'));
  const limit = Number(query.get('limit')) ?? DEFAULT_PAGE_SIZE;
  const defaultExpanded = query.get('exp') === 'true';

  const { promoMeta } = usePromoMeta();

  return (
    <div>
      <Helmet>
        <title>Promo Requests</title>
      </Helmet>
      <Box mt={2}>
        <PromoRequests
          initUserId={userId}
          initCodeName={codeName}
          initPage={page}
          initLimit={limit}
          brand={tenant.brand}
          promoMeta={promoMeta}
          defaultExpanded={defaultExpanded}
        />
        <PromoLogs
          promo={{
            code_name: codeName ?? 'ALL',
            promo_type: 'unknown',
          }}
          userIdFilter={userId}
          meta={promoMeta}
          defaultExpanded={defaultExpanded}
        />
        <PromoFailures
          initUserId={userId}
          initCodeName={codeName}
          brand={tenant.brand}
          promoMeta={promoMeta}
          initPage={0}
          initLimit={DEFAULT_PAGE_SIZE}
          defaultExpanded={defaultExpanded}
        />
        <PromoUsers
          initPage={0}
          initLimit={DEFAULT_PAGE_SIZE}
          initUserId={userId}
          initCodeName={codeName}
          showPromoCode={true}
          defaultExpanded={defaultExpanded}
        />
        <DebugModal data={promoMeta} type="generic" />
      </Box>
    </div>
  );
}

export default withTenant(PromoRequestsContainer);
