import React from 'react';

import { Box, Stack } from '@mui/material';

import BookingCancelledToggle from './BookingCancelledToggle';

export default class FraudAndComplianceAndDisputeContainer extends React.Component {
  render() {
    return (
      <Box id="FraudAndComplianceAndDisputeContainer">
        <Stack direction="row" spacing={2}>
          <Box>
            <input
              type="radio"
              key="default"
              name="source"
              value="Default"
              onChange={this.props.handleValueChange}
              checked={this.props.refund.source == 'Default'}
            />
            <label>
              <b>Both LE &amp; Vendor Are liable</b>
            </label>
          </Box>
          <Box>
            <input
              type="radio"
              key="tnfh"
              name="source"
              value="Luxury Escapes (TNFH)"
              onChange={this.props.handleValueChange}
              checked={this.props.refund.source == 'Luxury Escapes (TNFH)'}
            />
            <label>
              <b>Only LE is liable</b>
            </label>
          </Box>
        </Stack>

        {!this.props.refund.is_subscription && (
          <BookingCancelledToggle
            handleFlagChange={this.props.handleFlagChange}
            mark_cancelled={this.props.refund.mark_cancelled}
          />
        )}
      </Box>
    );
  }
}
