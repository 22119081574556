import React, { memo } from 'react';

import BedIcon from '@mui/icons-material/Bed';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import { sortBy } from '~/utils/arrayUtils';

interface Props {
  itinerary: Array<App.TourItineraryType>;
}

const TourItinerary = ({ itinerary }: Props) => {
  const sortedItinerary = sortBy<App.TourItineraryType, number>(itinerary, (item) => item.start_day, 'asc');

  return (
    <div className="mt-10">
      {sortedItinerary.map((itineraryItem) => (
        <div key={`${itineraryItem.start_day}`} className="row">
          <div className="price-info">
            <h5 className="price data-hj-whitelist">Day {itineraryItem.start_day}</h5>
          </div>
          <span>{itineraryItem.title}</span>

          <div>
            <span>{itineraryItem.locations_visited?.join(', ')}</span>
            <div>
              <span>{itineraryItem.text ?? itineraryItem.description}</span>
            </div>
            {(!!itineraryItem.meals?.length || !!itineraryItem.accommodation) && (
              <div className="section">
                <span>
                  <strong>Inclusions</strong>
                </span>
                {!!itineraryItem.accommodation && (
                  <div className="section">
                    <BedIcon />
                    <span className="gutter-left">{itineraryItem.accommodation}</span>
                  </div>
                )}
                {!!itineraryItem.meals?.length && (
                  <div className="section">
                    <RestaurantIcon />
                    <span className="gutter-left">
                      {[itineraryItem.meals.slice(0, -1).join(', '), itineraryItem.meals.slice(-1)]
                        .filter((item) => !!item)
                        .join(' and ')}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(TourItinerary);
