const getRate = ({ currency, fxRates }) => {
  const rate = fxRates[currency];
  if (!rate) {
    throw new Error(`Currency ${currency} is not supported`);
  }

  return rate;
};

export const convertValueByFX = ({ fxRates, fromCurrency, toCurrency, value }) => {
  if (value === 0 || fromCurrency === toCurrency) {
    return value;
  }

  const fromCurrencyRate = getRate({ currency: fromCurrency, fxRates });
  const toCurrencyRate = getRate({ currency: toCurrency, fxRates });

  //Convert to cents because JS floats are weird
  const valueAsCents = value * 100;

  return (valueAsCents * toCurrencyRate) / fromCurrencyRate / 100;
};
