import { convertValueByFX } from './convertValueByFX';

// filterCompatibleCapacities filters an array of capacities returning the ones
// that accommodates the current adults and children counts.
function filterCompatibleCapacities(currentAdults, currentChildren, currentInfants, capacities) {
  return capacities.filter(
    (capacity) =>
      currentAdults <= capacity.adults && currentChildren <= capacity.children && currentInfants <= capacity.infants,
  );
}

// canIncreaseAdults checks if the max capacity for adult is greater than the
// current adult count, given the current adults, children and infant count
export function canIncreaseAdults(currentAdults, currentChildren, currentInfants, capacities) {
  const compatibleCapacities = filterCompatibleCapacities(currentAdults, currentChildren, currentInfants, capacities);

  return compatibleCapacities.some((capacity) => {
    return capacity.adults > currentAdults;
  });
}

// canIncreaseChildren checks if the max capacity for children is greater
// than the current chidlren count, given the current adults, children and infant count
export function canIncreaseChildren(currentAdults, currentChildren, currentInfants, capacities) {
  const compatibleCapacities = filterCompatibleCapacities(currentAdults, currentChildren, currentInfants, capacities);

  return compatibleCapacities.some((capacity) => {
    return capacity.children > currentChildren;
  });
}

// canIncreaseInfants checks if the max capacity for infants is greater
// than the current infant count, given the current adults, children and infant count
export function canIncreaseInfants(currentAdults, currentChildren, currentInfants, capacities) {
  const compatibleCapacities = filterCompatibleCapacities(currentAdults, currentChildren, currentInfants, capacities);

  return compatibleCapacities.some((capacity) => {
    return capacity.infants > currentInfants;
  });
}

export function hasIncludedGuestsExceeded({ currentAdults, currentChildren, currentInfants, includedGuests }) {
  if (includedGuests && includedGuests.length) {
    return !includedGuests.some(
      (included) =>
        included.adults >= currentAdults && included.children >= currentChildren && included.infants >= currentInfants,
    );
  }

  return false;
}

export function getExtraGuests({ currentAdults, currentChildren, currentInfants, includedGuests }) {
  if (
    hasIncludedGuestsExceeded({
      currentAdults,
      currentChildren,
      currentInfants,
      includedGuests,
    })
  ) {
    return includedGuests.map((included) => ({
      adults: Math.max(currentAdults - included.adults, 0),
      children: Math.max(currentChildren - included.children, 0),
      infants: Math.max(currentInfants - included.infants, 0),
    }));
  }

  return [];
}

export const getRoomExtraGuestSurcharge = ({
  currentAdults,
  currentChildren,
  currentInfants,
  includedGuests,
  extraGuestSurcharges,
  numberOfNights,
  currencyCode,
  fxRates,
}) => {
  const extraGuests = getExtraGuests({
    currentAdults,
    currentChildren,
    currentInfants,
    includedGuests,
  });

  if (extraGuestSurcharges && extraGuestSurcharges.length && extraGuests.length > 0) {
    const amounts = extraGuests.reduce((acc, extraGuest) => {
      const amount = extraGuestSurcharges.reduce((acc, surcharge) => {
        const adults = surcharge.adult_amount * extraGuest.adults;
        const children = surcharge.child_amount * extraGuest.children;
        const infants = surcharge.infant_amount * extraGuest.infants;
        return convertValueByFX({
          fxRates,
          fromCurrency: surcharge.currency,
          toCurrency: currencyCode,
          value: acc + adults + children + infants,
        });
      }, 0);

      if (amount > 0) {
        acc.push(amount);
      }

      return acc;
    }, []);

    return amounts.length > 0 ? Math.floor(Math.min(...amounts) * numberOfNights) : 0;
  }

  return 0;
};
