import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

type Color = 'green' | 'orange' | 'red';

export default function ItemStatus(props: { reservationConnectionStatus: Reservation.ConnectionState }) {
  const getStatusColor = (connectionState: Reservation.ConnectionState): Color => {
    let color: Color;

    switch (connectionState) {
      case 'confirmed':
        color = 'green';
        break;
      case 'pending':
        color = 'orange';
        break;
      case 'cancelled':
        color = 'orange';
        break;
      case 'failed':
        color = 'red';
        break;
    }

    return color;
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>Channel Status:</Typography>
      <Typography color={getStatusColor(props.reservationConnectionStatus)} textTransform="capitalize">
        {props.reservationConnectionStatus}
      </Typography>
    </Stack>
  );
}
