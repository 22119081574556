import React from 'react';

import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

import { TraverseObject } from '~/components/DebugModal/utils';

import useToggleState from '~/hooks/useToggleState';

import { humanize } from '~/utils/humanize';

import type { SourceRoom } from './types';

interface Props {
  sourceContent: SourceRoom;
}

function RoomSourceContentModal(props: Props) {
  const { sourceContent } = props;
  const { isToggled, toggle, toggleOff } = useToggleState(false);

  if (!sourceContent)
    return (
      <Button variant="outlined" disabled>
        No Synced Source Room Content
      </Button>
    );

  return (
    <>
      <Button variant="outlined" onClick={toggle}>
        Extra source room details
      </Button>
      <Dialog open={isToggled} onClose={toggleOff} maxWidth="xl" fullWidth>
        <DialogContent>
          <Stack direction="column">
            {Object.entries(sourceContent).map(
              ([key, value]) =>
                !['isVerified', 'externalId', 'supplier', 'hasInfo'].includes(key) &&
                value && (
                  <div key={key}>
                    <Typography component="h6">{humanize(key)}</Typography>
                    {typeof value === 'object' && <TraverseObject obj={value} depth={0} />}
                    {typeof value !== 'object' && <Typography>{value}</Typography>}
                  </div>
                ),
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RoomSourceContentModal;
