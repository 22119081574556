import React, { useCallback, useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import OfferList from '~/components/Common/OfferList';
import Spinner from '~/components/Common/Spinner';

import OffersService from '~/services/OffersService';

type Props = { vendorName?: string };

export default function VendorOffersContainer(_: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(10);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onPageChange = useCallback((page) => setPage(page), []);

  const urlBuilder = useCallback((row) => `/offers/${row.id_salesforce_external}`, []);

  useEffect(() => {
    setLoading(true);
    setError(null);
    OffersService.getOffers({
      page,
      limit,
      queryString: vendorId,
      orderByScheduleDate: 1,
    })
      .then((data) => {
        setOffers(data.result);
        setTotal(data.total);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => setLoading(false));
  }, [vendorId, page, limit]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <OfferList
      total={total}
      offers={offers}
      urlBuilder={urlBuilder}
      page={page}
      sizePerPage={limit}
      onPageChange={onPageChange}
    />
  );
}
