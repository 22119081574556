import React from 'react';

import { Link } from '@mui/material';

import useCurrentTenant from '~/hooks/useCurrentTenant';

type Props = {
  tenant: App.Tenant;
};

const TenantTitleLink = ({ tenant }: Props) => {
  const { onTenantSelect } = useCurrentTenant();
  return (
    <>
      <Link sx={{ cursor: 'pointer' }} key={tenant.value} onClick={() => onTenantSelect(tenant.value)}>
        {tenant.title}
      </Link>
    </>
  );
};

export default TenantTitleLink;
