export const TRAVELLER_WEIGHT_OPTIONS = [
  'Under 50 kg',
  '50-60 kg',
  '60-70 kg',
  '80-90 kg',
  '90-100 kg',
  '100-110 kg',
  '110-120 kg',
  '120-130 kg',
  '130 kg+',
];

export const TRAVELLER_TITLE_OPTIONS = ['Mr', 'Ms', 'Mrs', 'Miss'];

export const TRAVELLER_COVID_VACCINATION_STATUS_OPTIONS = ['0', '1', '2+'];
