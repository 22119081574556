import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Tab, Tabs } from '@mui/material';

import { Offer } from '@luxuryescapes/contract-svc-offer';
import { Reservation } from '@luxuryescapes/contract-svc-reservation';
import { property as propertyGlobals } from '@luxuryescapes/lib-global';

import { RoomTypeImage } from '~/components/Common/Forms/RoomTypeImagesForm';
import Spinner from '~/components/Common/Spinner';
import UserReviewsTable from '~/components/Reviews/UserReviewsTable';
import AmenitiesPane from '~/components/Vendors/Properties/AmenitiesPane';
import PropertyPane from '~/components/Vendors/Properties/PropertyPane';
import RentalImagesForm from '~/components/Vendors/Properties/RentalImagesForm';
import RentalTaxesPane from '~/components/Vendors/Properties/RentalTaxesPane';
import RoomConfigurationPane from '~/components/Vendors/Properties/RoomConfigurationPane';

import ReservationService from '~/services/ReservationService';

import CuratedContent from './CuratedContent';
import PropertyHighlightsForm from './PropertyHighlightsForm';
import RentalAvailabilitiesAndRatePane from './RentalAvailabilitiesAndRatesPane';
import VIPPane from './VIPPane';

interface Props {
  property: Reservation.Property;
  ratePlans: Reservation.RatePlan[];
  vendorId: string;
  vendorCurrencyCode: string;
  downloadInProgress: boolean;
  downloadFailed: boolean;
  offers: Offer.Offer[];
  downloadTaxesAndFees: () => void;
  updateProperty: (property: Partial<Reservation.Property>) => void;
  isPropertyUpdating: boolean;
}

export default function RentalPropertyForm(props: Props): ReactElement {
  const { property, vendorId, vendorCurrencyCode, offers, updateProperty, isPropertyUpdating } = props;
  const roomTypes = property.room_types_count ? property.room_types : [];
  const [roomType, setRoomType] = useState(null);
  // Rentals have only 1 room type
  const amenities = useMemo(() => roomType?.amenities ?? [], [roomType]);

  const [channelPropertyData, setChannelPropertyData] = useState<Reservation.Property>(undefined);
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState<TabKey>('main');
  const { enqueueSnackbar } = useSnackbar();
  const [inclusions, setInclusions] = useState<any[]>([]);
  const [inclusionsSchema, setInclusionsSchema] = useState<any>(undefined);

  const fetchData = async () => {
    const channelPropertyDataResult = await ReservationService.getChannelPropertyData(property.id);
    setChannelPropertyData(channelPropertyDataResult.result);
    if (roomTypes.length != 1) {
      enqueueSnackbar(`This Rental Property is not setup properly expected 1 room type but got ${roomTypes.length}`, {
        variant: 'error',
      });
    }
    setRoomType(roomTypes[0]);
    setInclusions(roomTypes[0].room_rates[0] ? roomTypes[0].room_rates[0]?.inclusions : []);
    const inclusionsSchema = await ReservationService.getRoomRateInclusionsSchema(
      property.id,
      property.room_types[0].id,
      property.room_types[0].room_rates[0].id,
    );

    // we don't want to show these form fields if we're editing a rental's VIP inclusions
    delete inclusionsSchema.post.body.schema.title;
    delete inclusionsSchema.post.body.schema.properties.category_icon;
    delete inclusionsSchema.post.body.schema.properties.is_bonus;
    delete inclusionsSchema.post.body.schema.properties.external_id;
    setInclusionsSchema(inclusionsSchema.post.body.schema);
  };

  useEffect(() => {
    setLoading(true);
    fetchData()
      .finally(() => setLoading(false))
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  }, []);

  const handleImagesChange = (images) => {
    setRoomType({
      ...roomType,
      images,
    });
  };

  const saveImages = async (images: RoomTypeImage[]) => {
    return ReservationService.replaceRoomTypeImages(images, property.id, roomType.id);
  };

  const saveHighlights = async (highlights: Reservation.PropertyHighlight[]) => {
    return ReservationService.replacePropertyHighlights(highlights, property.id);
  };

  if (property.type === propertyGlobals.RENTAL_TYPE) {
    delete property.logo_id_cloudinary_external;
  }

  const TabsMap = {
    main: {
      label: 'Main',
      component: PropertyPane,
      props: { property, vendorId },
    },
    content: {
      label: 'Curated Content',
      component: CuratedContent,
      props: { channelPropertyData, property, fetchData },
    },
    bedrooms: {
      label: 'Bedrooms',
      component: RoomConfigurationPane,
      props: {
        roomConfigurations: roomType && roomType.room_configurations.length > 0 ? roomType.room_configurations : [],
      },
    },
    rooms: {
      label: 'Availability & Rates',
      component: RentalAvailabilitiesAndRatePane,
      props: { roomTypes, vendorId, propertyId: property.id, offers },
    },
    images: {
      label: 'Images',
      component: RentalImagesForm,
      props: {
        images: roomType && roomType.images ? roomType.images : [],
        apiImages:
          channelPropertyData && channelPropertyData.room_types.length > 0
            ? channelPropertyData.room_types[0].images
            : [],
        onImagesChange: handleImagesChange,
        saveImages,
      },
    },
    amenities: {
      label: 'Amenities',
      component: AmenitiesPane,
      props: { amenities, vendorId, propertyId: property.id, roomType: roomType ?? {} },
    },
    vip: {
      label: 'VIP Inclusions',
      component: VIPPane,
      props: {
        inclusions,
        hotelCode: property.hotel_code,
        channelPropertyData,
        vendorCurrencyCode,
        inclusionsSchema,
        onUpdate: fetchData,
      },
    },
    highlights: {
      label: 'Highlights',
      component: PropertyHighlightsForm,
      props: {
        highlights: property.highlights ?? [],
        apiHighlights: channelPropertyData ? channelPropertyData.highlights : [],
        onImagesChange: handleImagesChange,
        saveHighlights,
        hideDeleteButton: true,
      },
    },
    reviews: {
      label: 'Reviews',
      component: UserReviewsTable,
      props: {
        propertyId: property.id,
        isRental: true,
      },
    },
    taxes: {
      label: 'Taxes',
      component: RentalTaxesPane,
      props: {
        commissionableTaxes: property.commissionable_taxes_and_fees,
        apiCommissionableTaxes: channelPropertyData ? channelPropertyData.commissionable_taxes_and_fees : [],
        updateProperty,
        isPropertyUpdating,
      },
    },
  } as const;

  type TabKey = keyof typeof TabsMap;
  const allTabs = Object.keys(TabsMap);
  const currentTab = TabsMap[section];

  const handleTabChange = (_: React.SyntheticEvent, newKey: TabKey) => {
    setSection(newKey);
  };

  if (loading) return <Spinner />;

  return (
    <Box sx={{ paddingTop: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={section} onChange={handleTabChange}>
          {allTabs.map((tab) => (
            <Tab key={tab} value={tab} label={TabsMap[tab].label} />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box component={currentTab.component} {...currentTab.props} />
      </Box>
    </Box>
  );
}
