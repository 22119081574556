import React, { useMemo } from 'react';

import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface Props {
  roomTypes: Array<App.RoomType>;
  vendorId: string;
  propertyId: string;
  offers: Array<App.Offer>;
}

export default function RentalAvailabilitiesAndRatePane({ roomTypes, vendorId, propertyId, offers }: Props) {
  const mappedRoomTypes = roomTypes.map((roomType) => ({
    ...roomType,
    linkedOffers: offers.filter((offer) =>
      offer.packages.some((pkg: App.AccommodationPackage) => pkg.fk_room_type_id === roomType.id),
    ),
  }));

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        flex: 2,
        headerName: 'Room name',
        sortable: false,
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'room_type_code',
        flex: 2,
        sortable: false,
        headerName: 'Room code',
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'offerName',
        flex: 2,
        headerName: 'Linked offers',
        sortable: false,
        disableColumnMenu: true,
        display: 'flex',
        renderCell: (params) => {
          return (
            <ul>
              {params.row.linkedOffers.map((offer) => (
                <li key={offer.id_salesforce_external}>{offer.opportunity_name}</li>
              ))}
            </ul>
          );
        },
      },
      {
        field: 'offerId',
        flex: 2,
        headerName: 'Linked offer IDs',
        sortable: false,
        disableColumnMenu: true,
        display: 'flex',
        renderCell: (params) => {
          return (
            <ul>
              {params.row.linkedOffers.map((offer) => (
                <li key={offer.id_salesforce_external}>{offer.id_salesforce_external}</li>
              ))}
            </ul>
          );
        },
      },
      {
        field: 'link',
        flex: 2,
        headerName: 'Availability and rates',
        sortable: false,
        disableColumnMenu: true,
        display: 'flex',
        renderCell: (params) => {
          let defaultRoomRate = params.row.room_rates.find((room_rate) => room_rate.rate_plan.default_plan);

          if (!defaultRoomRate) {
            // Value doesn't matter. Just put something as a placeholder.
            defaultRoomRate = params.row.room_rates[0];
          }
          const { id } = defaultRoomRate;

          return (
            <Button
              variant="outlined"
              className="room-type-availability-preview-link"
              href={`/vendors/${vendorId}/properties/${propertyId}/room-types/${params.row.id}/room-rates/${id}`}
            >
              View Availability and Rates
            </Button>
          );
        },
      },
    ],
    [vendorId, propertyId],
  );

  return (
    <DataGrid
      columns={columns}
      rows={mappedRoomTypes}
      getRowId={(row) => row.id}
      autoHeight
      pageSizeOptions={[]}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
      getRowHeight={() => 'auto'}
    />
  );
}
