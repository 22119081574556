import React from 'react';

import currencyFormatter from 'currency-formatter';
import partition from 'lodash/partition';

import { INSTALMENT_STATUS, instalmentRefundStatuses } from '~/consts/instalment';

import { formatDateLong, formatDateShortDD, isBefore } from '~/services/TimeService';

export interface InstalmentScheduleData {
  id: number;
  instalments_number: number | string;
  instalment_amount: number;
  instalment_due_date: string;
  instalment_paid_date: string;
  currency: string;
  instalment_status: string;
  due_balance_auto_debit_failed_count: number;
  created_at?: string;
}

export const dateFormatterLong = (cell: string) => {
  const date = new Date(cell);
  return cell ? formatDateLong(date) : '';
};

export const dateFormatterShort = (cell: string) => {
  const date = new Date(cell);
  return cell ? formatDateShortDD(date) : '';
};

export const paymentFormatter = (cell: number, row: InstalmentScheduleData) => {
  const payments = currencyFormatter.format(cell, { code: row.currency });
  return <div>{payments}</div>;
};

export const failedCountFormatter = (cell: number) => {
  return cell > 0 ? cell.toString() : '';
};

const getInitialPaymentAmount = (payments, instalmentDetails) => {
  for (const payment of payments) {
    if (payment.amount === instalmentDetails.initial_payment_amount) {
      return payment;
    }
  }
  const defaultInitialPayment = payments[payments.length - 1]; // last payment in the list which is the earliest payment
  return defaultInitialPayment;
};

const isInitialCreditAppliedInstalment = (instalment) => {
  return (
    instalment.instalments_number === 0 &&
    instalment.instalment_status === INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN &&
    !!instalment.metadata?.is_credit
  );
};

const createInitialInstalmentRow = (initialPayment, instalmentDetails) => {
  return {
    id: 0,
    instalments_number: 'Initial Payment',
    instalment_amount: initialPayment.amount,
    instalment_due_date: '',
    instalment_paid_date: instalmentDetails.initial_payment_date,
    currency: instalmentDetails.currency,
    instalment_status: INSTALMENT_STATUS.INITIAL_PAYMENT_TAKEN,
    due_balance_auto_debit_failed_count: 0,
    created_at: instalmentDetails.initial_payment_date,
  };
};

const createUpcomingInstalmentRow = (index, isLastInstalment, instalmentDetails) => {
  return {
    id: index + 1,
    instalments_number: index + 1,
    instalment_amount: isLastInstalment
      ? instalmentDetails.last_instalment_amount
      : instalmentDetails.per_instalment_amount,
    instalment_due_date: instalmentDetails.scheduled_instalment_dates[index],
    instalment_paid_date: null,
    currency: instalmentDetails.currency,
    instalment_status: '',
    due_balance_auto_debit_failed_count: 0,
  };
};

const createCreditInstalmentRows = (initialCreditPayments) => {
  return [
    ...initialCreditPayments.map((payment) => {
      return {
        ...payment,
        instalments_number: '',
        instalment_status: INSTALMENT_STATUS.CREDIT_APPLIED,
      };
    }),
  ];
};

const createRefundInstalmentRows = (refundedPayments) => {
  return [
    ...refundedPayments.map((payment) => {
      return {
        ...payment,
        instalments_number: '',
        instalment_paid_date: payment.created_at,
      };
    }),
  ];
};

// We want to sum instalment amount for all entries with the same instalment number. This occurs when instalments are paid with multiple payment types
// e.g. stripe and credits. There will be 2 entries in instalmentPayments array. We only want to display 1 row per instalment.
const groupPaymentsByInstalmentNumber = (instalmentPayments) => {
  return instalmentPayments.reduce((acc, val) => {
    const multiplePayment = acc.find((payment) => payment.instalments_number === val.instalments_number);
    if (multiplePayment) {
      multiplePayment.instalment_amount = Number(multiplePayment.instalment_amount) + Number(val.instalment_amount); // instalment_amount type is string but need to add them as numbers
    } else {
      acc.push(val);
    }
    return acc;
  }, []);
};

export const generateInstalmentRows = (instalmentDetails, payments) => {
  const initialPayment = getInitialPaymentAmount(payments, instalmentDetails);
  const allInstalmentPayments = instalmentDetails?.instalmentPaymentDetails ?? [];
  const [refundedPayments, nonRefundedPayments] = partition(allInstalmentPayments, (payment) =>
    instalmentRefundStatuses.has(payment.instalment_status),
  );
  const [initialCreditPayments, instalmentPayments] = partition(nonRefundedPayments, isInitialCreditAppliedInstalment);

  const paymentsByInstalmentNumber = groupPaymentsByInstalmentNumber(instalmentPayments);

  const instalmentBreakdown: Array<InstalmentScheduleData> = [];

  instalmentBreakdown.push(createInitialInstalmentRow(initialPayment, instalmentDetails));

  if (initialCreditPayments.length > 0) {
    instalmentBreakdown.push(...createCreditInstalmentRows(initialCreditPayments));
  }

  if (instalmentDetails && instalmentDetails.is_active) {
    for (let index = 0; index < instalmentDetails.total_instalments_count; index++) {
      const currentInstalment = paymentsByInstalmentNumber[index];
      const isLastInstalment = index === instalmentDetails.total_instalments_count - 1;

      // if there is a payment and it is not a refund add it otherwise create instalment row
      if (currentInstalment && !instalmentRefundStatuses.has(currentInstalment.instalment_status)) {
        instalmentBreakdown.push(currentInstalment);
      } else {
        instalmentBreakdown.push(createUpcomingInstalmentRow(index, isLastInstalment, instalmentDetails));
      }
    }
  } else {
    // if not active order than push all non refunded payments
    instalmentBreakdown.push(...(paymentsByInstalmentNumber ?? []));
  }

  // push refunded payment row and sort by date
  if (refundedPayments.length > 0) {
    // set paid date as created date
    instalmentBreakdown.push(...createRefundInstalmentRows(refundedPayments));
    instalmentBreakdown.sort((a, b) => (isBefore(a.created_at, b.created_at) ? -1 : 1));
  }
  // loop through array and set ids to correct order after adding refund payment
  instalmentBreakdown.forEach((instalment, idx) => (instalment.id = idx));

  return instalmentBreakdown;
};
