import React, { useState } from 'react';

import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { formatDateLongISO, subDays } from '~/services/TimeService';

export type SearchFilters = {
  offerId: string;
  customer: string;
  orderStatus: string;
  startDate: string;
  endDate: string;
  bookingNumbers: string;
  supplierBookingNumber: string;
};

type Props = {
  onSearch: (filters: SearchFilters) => void;
  onClear: () => void;
};

const initialFilters: SearchFilters = {
  offerId: '',
  customer: '',
  bookingNumbers: '',
  orderStatus: 'all',
  startDate: formatDateLongISO(subDays(1)),
  endDate: formatDateLongISO(),
  supplierBookingNumber: '',
};

export default function SearchFilters(props: Props) {
  const [filters, setFilters] = useState<SearchFilters>(initialFilters);

  const handleSearch = async () => {
    props.onSearch(filters);
  };

  const handleClearFilters = () => {
    setFilters(initialFilters);
    props.onClear();
  };

  return (
    <Stack direction="column" spacing={2}>
      <Box>
        <Typography variant="h6">Filter By</Typography>
      </Box>

      <Box>
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <TextField
              label="Customer name / email / ID"
              value={filters.customer}
              onChange={(e) =>
                setFilters({
                  ...initialFilters,
                  customer: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>

          <Grid xs={12} md={4}>
            <TextField
              label="Offer ID"
              value={filters.offerId}
              onChange={(e) => setFilters({ ...initialFilters, offerId: e.target.value })}
              fullWidth
            />
          </Grid>

          <Grid xs={12} sm={6} lg={4}>
            <TextField
              variant="outlined"
              type="text"
              label="Booking Number"
              name="searchBookingNumber"
              value={filters.bookingNumbers}
              onChange={(e) => setFilters({ ...initialFilters, bookingNumbers: e.target.value })}
              fullWidth
            />
          </Grid>

          <Grid xs={12} sm={6} lg={4}>
            <TextField
              variant="outlined"
              type="text"
              label="Supplier booking number"
              name="searchSupplierBookingNumber"
              value={filters.supplierBookingNumber}
              onChange={(e) => setFilters({ ...initialFilters, supplierBookingNumber: e.target.value })}
              fullWidth
            />
          </Grid>

          <Grid xs={12} md={2}>
            <TextField
              label="Order Status"
              value={filters.orderStatus}
              onChange={(e) => setFilters({ ...initialFilters, orderStatus: e.target.value })}
              select
              fullWidth
            >
              <MenuItem value="all">All orders</MenuItem>
              <MenuItem value="pending">Pending orders</MenuItem>
              <MenuItem value="completed">Completed orders</MenuItem>
              <MenuItem value="cancelled">Cancelled orders</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>

      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={handleSearch}>
          Search
        </Button>

        <Button variant="text" onClick={handleClearFilters}>
          Clear filters
        </Button>
      </Stack>
    </Stack>
  );
}
