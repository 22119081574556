import React, { ReactElement } from 'react';

import { Stack, Typography } from '@mui/material';

import { formatDateShort } from '~/services/TimeService';

import { getGuestName } from '../utils';

import { Traveller } from './GeneralInformationComponent';

interface Props {
  offer: App.Offer;
  order: App.Order;
  reservation: App.OrderItemReservation;
  traveller: Traveller;
}

function parseGuestPhone(reservation: App.OrderItemReservation, order: App.Order): string | null {
  const guestPhone = reservation.guest_phone;
  if (guestPhone && guestPhone === 'N/A') {
    return null;
  }

  const phoneList = new Set<string>();
  for (const item of order.items) {
    const reservationPhone = item.reservation?.guest_phone;
    reservationPhone ? phoneList.add(item.reservation.guest_phone) : null;
  }

  if (phoneList.size > 1) {
    return guestPhone === order.customer_phone ? null : guestPhone;
  }

  return guestPhone;
}

export function GuestDetails({ offer, order, reservation, traveller }: Props): ReactElement {
  const isCruisesOffer = offer.holiday_types ? offer.holiday_types.includes('Cruises') : false;
  const phone = parseGuestPhone(reservation, order);

  return isCruisesOffer ? (
    <Stack direction="column">
      {reservation.guest_title && <Typography>Title: {reservation.guest_title}</Typography>}
      <Typography>First Name: {reservation.guest_first_name}</Typography>
      {reservation.guest_middle_name && <Typography>Middle Name: {reservation.guest_middle_name}</Typography>}
      <Typography>Last Name: {reservation.guest_last_name}</Typography>
      <Typography>Email: {order.customer_email}</Typography>
      {phone && <Typography>Phone: {`+${phone}`}</Typography>}
      {order.customer_postcode && <Typography>Postcode: {order.customer_postcode}</Typography>}
      <Typography>Date of birth: {formatDateShort(reservation.dob)}</Typography>
    </Stack>
  ) : (
    <Typography>{getGuestName(reservation, traveller)}</Typography>
  );
}
