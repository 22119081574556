/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useMemo, useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Grid, Stack, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';

import isContentEmpty from '~/utils/isContentEmpty';

import { humanize } from '../../utils/humanize';

const Item = (props) => (
  <Typography component="div" sx={{ background: '#efefef', ...props.sx }}>
    {props.children}
  </Typography>
);

const makeid = (length = 8) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

/**
 * WIP dynamic filtering of key and content with depth searching for objects
 * @param key
 * @param value
 * @param filterBy
 * @returns
 */
const filterCheck = (key: string, value: any, filterBy: string) => {
  if (!filterBy) return true;
  if (!key || key?.includes(filterBy)) return true;
  if (typeof value === 'object') return filterCheck(null, value, filterBy);
  if (value && typeof value === 'string' && (value as string)?.includes(filterBy)) return true;
  return false;
};

function BooleanDisplay({ value }) {
  return value ? <CheckBoxIcon sx={{ color: green[300] }} /> : <DisabledByDefaultIcon sx={{ color: red[300] }} />;
}

function Empty() {
  return <em style={{ color: 'rgba(0,0,0,0.6)' }}>Empty</em>;
}

const displayKeyValue = (key: string, value: string) => {
  return (
    <Fragment key={`key-${makeid()}-fragment`}>
      <Grid key={`key-${makeid()}-grid`} container spacing={1}>
        <Grid key={`key-${makeid()}`} item xs={4}>
          <Item sx={{ textAlign: 'right', background: 'transparent' }}>{humanize(key)}</Item>
        </Grid>

        <Grid item key={`value-${makeid()}`} xs={8}>
          {(key.toLowerCase() === 'link' || key.toLowerCase() === '_link') && (
            <Item>
              <a href={value} target="_blank" rel="noreferrer">
                {value}
              </a>
            </Item>
          )}
          {(key.toLowerCase() !== 'link' || key.toLowerCase() !== '_link') && typeof value === 'boolean' && (
            <BooleanDisplay value={value} />
          )}
          {key.toLowerCase() !== 'link' && key.toLowerCase() !== '_link' && typeof value !== 'boolean' && (
            <Item>{value ?? <Empty />}</Item>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

/**
 * This function allows for dynamically traversing an object and displaying it's key value pairs
 * accepts a filterBy string to filter out keys and values that don't include the filterBy string
 * @param obj object | array
 * @param filterBy string
 * @param depth number
 * @returns JSX.Element
 */
const TraverseObject = ({ obj, filterBy = '', depth = 0 }) => {
  switch (typeof obj) {
    case 'string':
    case 'number':
    case 'undefined':
    case 'function':
    case 'symbol':
      return (
        filterCheck(null, obj, filterBy) && (
          <Grid item xs={12} key={`${makeid()}-${typeof obj}`} data-prim-type={typeof obj}>
            <Item>{obj}</Item>
          </Grid>
        )
      );

    case 'boolean':
      return (
        <Grid item xs={12} key={`${makeid()}-boolean`} data-prim-type="boolean">
          <BooleanDisplay value={obj} />
        </Grid>
      );

    case 'object':
      if (obj === null) {
        return (
          <Typography variant="body1" data-prim-type="emptyOrNull">
            <em>Empty</em>
          </Typography>
        );
      } else {
        if (Array.isArray(obj)) {
          return (
            <>
              {obj
                .sort((a, b) => {
                  // alphabetically sort keys
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                })
                .sort((a, b) => {
                  // put simply values at the top, arrays & objects at the bottom
                  if (typeof a === 'object' && typeof b !== 'object') return 1;
                  if (typeof a !== 'object' && typeof b === 'object') return -1;
                  return 0;
                })
                .sort((a, b) => {
                  // put empties at the bottom
                  if (isContentEmpty(a) && !isContentEmpty(b)) return 1;
                  if (!isContentEmpty(a) && isContentEmpty(b)) return -1;
                  return 0;
                })
                .map((value, i) => {
                  if (typeof value === 'object') {
                    return (
                      <DisplayObject
                        key={`array-${i}-${makeid()}`}
                        label={null}
                        value={value}
                        filterBy={filterBy}
                        depth={depth}
                      />
                    );
                  } else {
                    return (
                      filterCheck(null, value, filterBy) && (
                        <Grid item xs={12} key={`array-${makeid()}-item-${i}`} data-prim-type={typeof value}>
                          {typeof value === 'boolean' ? (
                            <BooleanDisplay value={value} />
                          ) : (
                            <Item key={`array-${makeid()}-item-${i}`}>{value}</Item>
                          )}
                        </Grid>
                      )
                    );
                  }
                })}
            </>
          );
        } else {
          return (
            <>
              {Object.keys(obj)
                .sort((a, b) => {
                  // alphabetically sort keys
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                })
                .sort((a, b) => {
                  // put simply values at the top, arrays & objects at the bottom
                  if (typeof obj[a] === 'object' && !isContentEmpty(obj[a]) && typeof obj[b] !== 'object') return 1;
                  if (typeof obj[a] !== 'object' && typeof obj[b] === 'object') return -1;
                  return 0;
                })
                .sort((a, b) => {
                  // put empties at the bottom
                  if (isContentEmpty(obj[a]) && !isContentEmpty(obj[b])) return 1;
                  if (!isContentEmpty(obj[a]) && isContentEmpty(obj[b])) return -1;
                  return 0;
                })
                .map((key) => {
                  const value = obj[key];
                  if (typeof value === 'object' && value !== null) {
                    // check for html element
                    if (value.__html && Object.keys(value).length === 1) {
                      return (
                        <Fragment key={`key-${makeid()}-fragment`}>
                          <Grid key={`key-${makeid()}`} item xs={4}>
                            <Item
                              sx={{
                                textAlign: 'right',
                                background: 'transparent',
                              }}
                            >
                              {key}
                            </Item>
                          </Grid>

                          <Grid item key={`value-${makeid()}`} xs={8}>
                            <Item key={`object-html-${makeid()}`}>
                              <div dangerouslySetInnerHTML={value} />
                            </Item>
                          </Grid>
                        </Fragment>
                      );
                    }
                    return (
                      <DisplayObject
                        key={`object-${makeid()}`}
                        label={key}
                        value={value}
                        filterBy={filterBy}
                        depth={depth}
                      />
                    );
                  } else {
                    return filterCheck(key, value, filterBy) && displayKeyValue(key, value);
                  }
                })}
            </>
          );
        }
      }
  }
};

const DisplayObject = ({ label, value, filterBy = '', depth = 0 }) => {
  const [open, setOpen] = useState<boolean>(!label ?? true); // if has label, default to closed
  const display = useMemo(() => (open ? 'flex' : 'none'), [open]);

  return (
    <Container
      key={`object-${makeid()}`}
      sx={{
        my: 1,
        py: 1,
        border: 'solid 1px #eee',
        background: depth % 2 == 0 ? '#efefef' : '#fff',
      }}
      data-depth={depth}
    >
      {label && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(!open);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Typography variant="h6" fontSize={16} sx={{ p: 1 }}>
            {humanize(label)} {Array.isArray(value) && `(${value.length})`}
          </Typography>
          <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
        </Stack>
      )}

      <Grid container spacing={1} style={{ display }}>
        {!value || (value && Object.values(value).filter((v) => !isContentEmpty(v)).length === 0) ? (
          <Empty />
        ) : (
          <TraverseObject obj={value} filterBy={filterBy} depth={depth + 1} />
        )}
      </Grid>
    </Container>
  );
};

export { displayKeyValue, TraverseObject };

export default {
  displayKeyValue,
  TraverseObject,
};
