import React, { Fragment } from 'react';

import { Stack, TextField } from '@mui/material';

import { CabinCategoriesGroupById } from '~/services/cruises/CabinCategoriesGroupService';

type Props = {
  categoriesGroup: CabinCategoriesGroupById;
};

export default function CabinCategoriesGroupDetails(props: Props) {
  const { categoriesGroup } = props;
  return (
    <Fragment>
      <Stack spacing={2} direction="row">
        {categoriesGroup.id && (
          <TextField disabled fullWidth label="Cabin Categories Group ID" defaultValue={categoriesGroup.id} />
        )}
        {categoriesGroup.ship?.id && (
          <TextField disabled fullWidth label="Ship ID" defaultValue={categoriesGroup.ship?.id} />
        )}
        {categoriesGroup.ship?.externalId && (
          <TextField disabled fullWidth label="Ship External ID" defaultValue={categoriesGroup.ship?.externalId} />
        )}
        {categoriesGroup.ship?.name && (
          <TextField disabled fullWidth label="Ship Name" defaultValue={categoriesGroup.ship?.name} />
        )}
      </Stack>

      <Stack spacing={2} direction="row">
        {categoriesGroup.vendor?.id && (
          <TextField disabled fullWidth label="Vendor ID" defaultValue={categoriesGroup.vendor?.id} />
        )}
        {categoriesGroup.vendor?.externalId && (
          <TextField disabled fullWidth label="Vendor External ID" defaultValue={categoriesGroup.vendor?.externalId} />
        )}
        {categoriesGroup.vendor?.name && (
          <TextField disabled fullWidth label="Vendor Name" defaultValue={categoriesGroup.vendor?.name} />
        )}
        {categoriesGroup.vendor?.code && (
          <TextField disabled fullWidth label="Vendor Code" defaultValue={categoriesGroup.vendor?.code} />
        )}
      </Stack>
    </Fragment>
  );
}
