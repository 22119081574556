import React, { useCallback } from 'react';

import { WidgetProps } from '@rjsf/utils';

import { Typography } from '@mui/material';

import TourMarkdownEditor from '~/components/Tours/TourDetails/RJSF/TourMarkdownEditor';

export default function MarkdownEditorWidget(props: WidgetProps) {
  const { value, onChange, id, readonly, disabled } = props;

  const handleChange = useCallback(
    (newValue: string) => {
      if (newValue !== value) {
        onChange(newValue);
      }
    },
    [onChange, value],
  );

  return (
    <div>
      <TourMarkdownEditor value={value} disabled={disabled} readonly={readonly} onBlur={handleChange} wrapperId={id} />
      <Typography variant="caption" sx={{ color: 'warning.main' }}>
        ☝️ Changes in the markdown are not applied as you type. Make sure you click/tap outside the field before
        submission.
      </Typography>
    </div>
  );
}
