import React, { useCallback, useEffect, useState } from 'react';

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import { SortableContainer } from 'react-sortable-hoc';
import { v4 as uuid } from 'uuid';

import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Button, IconButton, ImageList, ImageListItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import HighlightPanel from '~/components/Common/Forms/HighlightPanel';
import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import { buttonMessages as baseButtonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';
import Image from '~/components/Common/Image';

import { reportError } from '~/utils/reportError';

const buttonMessages = { ...baseButtonMessages };
buttonMessages[buttonStates.default] = 'Save changes';

const ApiList = SortableContainer(({ highlights, toggleHighlightSelection }) => (
  <ImageList cols={4} gap={10} className={'mt-10'}>
    {highlights.map((item) => (
      <ImageListItem
        key={item.id_cloudinary_external}
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.preventDefault();
          toggleHighlightSelection(item.id, item.selected);
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            position: 'absolute',
            right: 10,
            zIndex: 1,
          }}
        >
          <IconButton
            color="info"
            title={item.selected ? 'Select Highlight' : 'Unselect Highlight'}
            sx={{
              zIndex: 2,
            }}
          >
            {item.selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
          </IconButton>
        </Box>
        <Image
          className="img-responsive mx-auto"
          publicId={item.id_cloudinary_external}
          options={{
            width: 266,
            height: 200,
          }}
        />
        <Box m={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>{item.title}</Typography>
        </Box>
        <Box m={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>{item.description}</Typography>
        </Box>
      </ImageListItem>
    ))}
  </ImageList>
));

interface ApiHighlightData extends Reservation.PropertyHighlight {
  selected: boolean;
}

interface Props {
  highlights: Reservation.PropertyHighlight[];
  apiHighlights: Reservation.PropertyHighlight[];
  onHighlightsChange: (highlights: Reservation.PropertyHighlight[]) => void;
  saveHighlights: (highlights: Reservation.PropertyHighlight[]) => Promise<void>;
  hideDeleteButton?: boolean;
}

export default function PropertyHighlightsForm(props: Props) {
  const [apiHighlights, setApiHighlights] = useState<ApiHighlightData[]>([]);
  const [heroHighlights, setHeroHighlights] = useState<Reservation.PropertyHighlight[]>([]);
  const [saveButtonState, setSaveButtonState] = useState(buttonStates.saved);
  const isButtonStateSaving = saveButtonState === buttonStates.saving;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setHeroHighlights([...props.highlights]);
    setApiHighlights(
      props.apiHighlights.map<ApiHighlightData>((highlight) => ({
        ...highlight,
        selected: false,
      })),
    );
  }, [props.apiHighlights, props.highlights]);

  const sortHighlights = (highlights) => {
    return [...highlights]
      .map((i) => ({
        ...i,
        publicImageId: i.id_cloudinary_external,
      }))
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
  };

  const onCreateHighlight = async (imageServiceId: string) => {
    const highlightData = {
      id: uuid(), // All array manipulation is done by id, so we need to generate a unique id for unsaved highlights
      id_cloudinary_external: imageServiceId,
      title: 'New highlight',
      description: '',
      order: heroHighlights.length + 1,
    };
    return highlightData;
  };

  const onUpdateHighlights = async (highlights: any[]) => {
    setSaveButtonState(buttonStates.default);
    setHeroHighlights(highlights);
  };

  const onDeleteHighlight = (highlightId) => {
    const modifiedHighlights = heroHighlights
      .filter((highlight) => highlight.id !== highlightId)
      .map((p, index) => ({
        ...p,
        order: index,
      }));
    setHeroHighlights(modifiedHighlights);
  };

  const onSaveHighlights = () => {
    setSaveButtonState(buttonStates.saving);
    props
      .saveHighlights(heroHighlights)
      .then(() => {
        setSaveButtonState(buttonStates.saved);
      })
      .catch(() => {
        setSaveButtonState(buttonStates.failed);
      });
  };

  const moveHighlightsToHero = () => {
    const newHighlights = [...apiHighlights]
      .filter((i) => i.selected)
      .map((p, index) => ({
        ...p,
        order: heroHighlights.length + index + 1,
      }));
    setApiHighlights([...apiHighlights].filter((i) => !i.selected));
    onUpdateHighlights([...heroHighlights, ...newHighlights]);
  };

  const toggleHighlightSelection = (id, oldValue) => {
    const newHighlights = [...apiHighlights].map((p) => ({
      ...p,
      selected: p.id === id ? !oldValue : p.selected,
    }));
    setApiHighlights(newHighlights);
  };

  const debouncedOnUpdateImages = useCallback(
    debounce((images) => {
      setHeroHighlights(images);
      onUpdateHighlights(images);
    }, 1000),
    [onUpdateHighlights],
  );

  const handleChangeTitle = (imageId, newTitle) => {
    const newImages = heroHighlights.map((image) => {
      if (image.id === imageId) {
        image.title = newTitle;
      }
      return image;
    });
    debouncedOnUpdateImages(newImages);
  };

  const handleChangeDescription = (imageId, newDescription) => {
    const newImages = heroHighlights.map((image) => {
      if (image.id === imageId) {
        image.description = newDescription;
      }
      return image;
    });
    debouncedOnUpdateImages(newImages);
  };

  const addImage = (_fieldKey, cloudinaryId, filename) => {
    onCreateHighlight(cloudinaryId)
      .then((response: Reservation.PropertyHighlight) => {
        const appendedImages = [...heroHighlights, response];
        setHeroHighlights(appendedImages);
        enqueueSnackbar(`Successfully uploaded image`, { variant: 'success' });
        return appendedImages;
      })
      .then((newImages) => {
        onUpdateHighlights(newImages);
      })
      .catch((e) => {
        enqueueSnackbar('Error uploading image: ' + e.message, {
          variant: 'error',
        });
        reportError(e);
      });
  };

  const handleDeleteImage = async (imageId) => {
    await onDeleteHighlight(imageId);
    let newImages;
    setHeroHighlights((images) => {
      newImages = images.filter((image) => image.id !== imageId);
      for (let i = 0; i < newImages.length; i++) {
        newImages[i].order = i + 1;
      }
      return newImages;
    });
    await onUpdateHighlights(newImages);
  };

  const handleHiddenImage = (imageId) => {
    return async () => {
      // toggle selected image
      const newImages = heroHighlights.map((image) => {
        if (image.id === imageId) {
          image.hidden = !image.hidden;
        }
        return image;
      });

      // reorder images if changing to hidden
      const hiddenImages = newImages.filter((image) => image.hidden);
      const visibleImages = newImages.filter((image) => !image.hidden);
      for (let i = 0; i < newImages.length; i++) {
        if (visibleImages.includes(newImages[i])) {
          newImages[i].order = visibleImages.indexOf(newImages[i]) + 1;
        } else {
          newImages[i].order = visibleImages.length + hiddenImages.indexOf(newImages[i]) + 1;
        }
      }

      newImages.sort((a, b) => a.order - b.order);

      await onUpdateHighlights(newImages);
    };
  };

  const handleDragSort = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = heroHighlights.map((e) => e.id).indexOf(active.id);
      const newIndex = heroHighlights.map((e) => e.id).indexOf(over.id);
      const newImageList = arrayMove(heroHighlights, oldIndex, newIndex);
      let order = 1;
      for (const i in newImageList) {
        newImageList[i].order = order++;
      }
      onUpdateHighlights(newImageList);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <>
      <PageSubheader title="Property Highlights" />

      <Box>
        <Typography variant="h4">Hero highlights</Typography>
        <div className="button-container" style={{ justifyContent: 'end' }}>
          <Button
            variant="contained"
            onClick={onSaveHighlights}
            disabled={isButtonStateSaving}
            style={{ float: 'right' }}
          >
            {buttonMessages[saveButtonState]}
          </Button>
        </div>
      </Box>

      <Grid container className="images-form">
        <Grid xs={12}>
          <Grid container spacing={2}>
            <DndContext onDragEnd={handleDragSort} sensors={sensors}>
              <SortableContext items={heroHighlights}>
                {sortHighlights(heroHighlights).map((value) => (
                  <HighlightPanel
                    {...value}
                    publicImageId={value.id_cloudinary_external}
                    key={value.id}
                    onChangeTitle={handleChangeTitle}
                    onChangeDescription={handleChangeDescription}
                    onDeleteImage={handleDeleteImage}
                    onHideImage={handleHiddenImage}
                    hasHiddenToggle={true}
                    hideDeleteButton={props.hideDeleteButton}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <ImageUploadField label="image" field_key="newimage" onUpload={addImage} multiple={true} />
        </Grid>
      </Grid>
      {apiHighlights.length > 0 && (
        <>
          <Box mt={7}>
            <Typography variant="h4">API highlights</Typography>
          </Box>
          <ApiList
            highlights={apiHighlights}
            toggleHighlightSelection={toggleHighlightSelection}
            axis="xy"
            distance={3}
            helperClass="sortable-helper"
          />
          <div className="button-container" style={{ justifyContent: 'end' }}>
            <Button variant="contained" color="primary" onClick={moveHighlightsToHero}>
              Move to Hero highlights
            </Button>
          </div>
        </>
      )}
    </>
  );
}
