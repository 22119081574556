const buttonStates = {
  default: 'btn-primary',
  saving: 'btn-info',
  saved: 'btn-success',
  failed: 'btn-danger',
  unsaved: 'btn-primary',
  loaded: 'btn-secondary',
};

const buttonMessages = {};

buttonMessages[buttonStates.default] = 'Save';
buttonMessages[buttonStates.saving] = 'Saving...';
buttonMessages[buttonStates.saved] = 'Saved';
buttonMessages[buttonStates.failed] = 'Failed to save';
buttonMessages[buttonStates.unsaved] = 'Save';
buttonMessages[buttonStates.loaded] = 'Saved';

module.exports = {
  buttonStates,
  buttonMessages,
};
