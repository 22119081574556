import React, { useCallback, useMemo, useState } from 'react';

import currencyFormatter from 'currency-formatter';

import { Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { formatDateWithClock } from '~/services/TimeService';

import VCCConfirmationResendModal from './VCCConfirmationResendModal';

const vendorPaymentFormatter = (value, row) =>
  currencyFormatter.format(value, {
    code: row.cost_currency,
  });

export default function VendorBookingPaymentsList(props) {
  const [showVCCModal, setShowVCCModal] = useState(false);
  const [bookingNumber, setBookingNumber] = useState(null);

  const totalPaid = useMemo(() => {
    let totalPaidToVendor = {
      amount: 0,
      currency: 'AUD',
    };

    if (props.data && props.data.total_paid_to_vendor) {
      totalPaidToVendor = Object.assign({}, totalPaidToVendor, props.data.total_paid_to_vendor);
    }

    return totalPaidToVendor;
  }, [props.data]);

  const payments = useMemo(() => {
    if (props.data && props.data.payments) {
      return props.data.payments;
    }

    return [];
  }, [props.data]);

  const actionFormatter = useCallback((_, row) => {
    if (!row.vcc_pan4) {
      return '';
    }

    return (
      <Button
        variant="text"
        onClick={() => {
          setShowVCCModal(true);
          setBookingNumber(row.booking_number);
        }}
      >
        Repush VCC
      </Button>
    );
  }, []);

  const hideVCCConfirmationResendModal = useCallback(() => {
    setShowVCCModal(false);
    setBookingNumber(null);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date_of_payment',
        headerName: 'Date Of Payments',
        width: 150,
        valueFormatter: (value) => formatDateWithClock(value),
        display: 'flex',
      },
      { field: 'booking_number', headerName: 'Booking Number', width: 150, display: 'flex' },
      {
        field: 'adjustment',
        headerName: 'Adjustment',
        width: 100,
        renderCell: (params) => vendorPaymentFormatter(params.value, params.row),
        display: 'flex',
      },
      { field: 'adjustment_reason', headerName: 'Adjustment Reason', width: 200, display: 'flex' },
      { field: 'cost_currency', headerName: 'Currency', width: 100, display: 'flex' },
      { field: 'vcc_pan4', headerName: 'Last 4 VCN', width: 120, display: 'flex' },
      { field: 'source', headerName: 'Source', width: 150, display: 'flex' },
      {
        field: 'fk_added_by',
        headerName: 'Added by',
        flex: 1,
        valueFormatter: (value: { fullName: string }) => value.fullName,
        display: 'flex',
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        width: 150,
        valueFormatter: (value) => formatDateWithClock(value),
        display: 'flex',
      },
      {
        field: 'id_booking_payment',
        headerName: 'Actions',
        width: 120,
        renderCell: (params) => actionFormatter(params.value, params.row),
        display: 'flex',
      },
    ],
    [actionFormatter],
  );

  return (
    <div>
      <Typography component="p" variant="body1" fontSize={18} mb={1}>
        Total Paid to Vendor {totalPaid.currency}
        &nbsp;
        {currencyFormatter.format(totalPaid.amount, {
          code: totalPaid.currency,
        })}
      </Typography>

      <DataGrid
        loading={props.isLoading}
        columns={columns}
        rows={payments}
        getRowId={(row) => row.id_booking_payment}
        slots={{ pagination: GridPagination }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />

      <VCCConfirmationResendModal
        isModalVisible={showVCCModal}
        bookingNumber={bookingNumber}
        hideModal={hideVCCConfirmationResendModal}
      />
    </div>
  );
}
