import React, { useState } from 'react';

import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Stack, TextField } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { generateUrl } from '~/utils/secureUrlGenerator';

const formSchema: RJSFSchema = {
  description: 'Please type what you would like the Header and Subheader to be below, and also choose which Page Type',
  type: 'object',
  properties: {
    header: {
      title: 'Input Header',
      type: 'string',
    },
    subHeader: {
      title: 'Input Sub-Header',
      type: 'string',
    },
    pageType: {
      title: 'Page Type',
      type: 'string',
      default: 'landing',
      enum: ['landing', 'signup'],
    },
  },
};

function SecureUrlContainer() {
  const [creationResult, setCreationResult] = useState(null);

  const onSubmit = async (form) => {
    const url = await generateUrl(JSON.stringify(form.formData));

    try {
      setCreationResult({ url: url, status: 'success' });
      navigator.clipboard.writeText(url);
    } catch (e) {
      setCreationResult({ message: e.message, status: 'reject' });
    }
  };

  return (
    <Stack spacing={2}>
      <h3 className="page-header">
        Create a secure url for custom headers on Destination Landing Pages and Signup Modals
      </h3>
      <Form schema={formSchema} onSubmit={onSubmit} validator={validator} />
      {creationResult?.status === 'reject' && (
        <ErrorDisplay message={`${creationResult.message ? creationResult.message : 'Unexpected error'}`} />
      )}
      {creationResult?.status === 'success' && (
        <>
          <Alert>Your query url has been copied, please add to the end of the LE link you are using</Alert>
          <TextField InputLabelProps={{ shrink: true }} defaultValue={creationResult.url} multiline fullWidth />
        </>
      )}
    </Stack>
  );
}

export default SecureUrlContainer;
