import React from 'react';

import { Box, MenuItem, TextField } from '@mui/material';

import { brands } from '~/consts/utm';

type Props = { source: string; setOfferSource: (source: string) => void };

export default function AttributionSource(props: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setOfferSource(e.target.value);
  };

  return (
    <Box width={200}>
      <TextField
        id="AttributionSource"
        label="Offer Source"
        onChange={handleChange}
        value={props.source}
        fullWidth
        select
      >
        {brands.map((brand, i) => {
          return (
            <MenuItem key={i} value={brand.value}>
              {brand.title}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
}
