import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';

import * as OrderService from '~/services/OrdersService';
import { cancelCustomOfferOrder } from '~/services/OrdersService';

import Spinner from '../../Spinner';

interface Props {
  offer?: App.CustomOffer;
  wasDepositPaid: boolean;
  isOpen: boolean;
  handleClose: () => void;
}

export default function CustomOfferCancelDialog({ isOpen, wasDepositPaid, handleClose }: Props) {
  const { offer_id: offerId } = useParams<{ offer_id: string }>();
  const [orderId, setOrderId] = useState(undefined);
  const [itemId, setItemId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const onConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      await cancelCustomOfferOrder(orderId, itemId);
      setIsLoading(false);
      location.reload();
    } catch (err) {
      setIsLoading(false);
      handleClose();
      enqueueSnackbar(`Error with cancellation: ${err?.message}`);
    }
  }, [orderId, itemId]);

  const fetchData = async () => {
    const order = await OrderService.getPurchases({
      item_type: 'custom_offer',
      filterBy: 'offer_id',
      filterValue: offerId,
      page: 1,
      per_page: 1,
      brand,
    });
    if (order.result[0]) {
      setOrderId(order.result[0].id);
      setItemId(order.result[0].custom_offer_items[0].id_item);
    }
  };

  useEffect(() => {
    fetchData();
  }, [offerId]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <form>
          <DialogTitle>
            {wasDepositPaid ? 'Cancel' : 'Delete'} Custom Offer
            <Typography variant="body2">
              <a href={`/purchases/${orderId}`}>This order</a> will be {wasDepositPaid ? 'cancelled' : 'deleted'}.
            </Typography>
            <Typography variant="body2">
              It will be {wasDepositPaid ? ' cancelled in the  ' : ' removed from the '}customer's My Escapes page.
            </Typography>
            <Typography variant="body2">All refunds must be processed manually.</Typography>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Go Back</Button>
            {!isLoading && orderId && itemId && (
              <Button variant="contained" color="error" onClick={onConfirm}>
                Confirm
              </Button>
            )}
            {isLoading && <Spinner size={30} inline />}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
