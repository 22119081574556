import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import promotionService from '~/services/cruises/PromotionService';

import PromotionList from './components/PromotionList';
import PromotionSearchForm from './components/PromotionSearchForm';
import { PromotionListParams, PromotionSearchFormValues, Request } from './types';

type PromoStatus = 'LIVE' | 'EXPIRED' | 'UPCOMING';

const INITIAL_REQUEST_STATE: Request<CruisesContract.Promotion[]> = {
  total: 0,
  result: [],
  loading: false,
};

const PromotionManagementPage: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [pagination, setPagination] = useState({ perPage: 10, page: 0 });
  const [promotions, setPromotions] = useState<Request<CruisesContract.Promotion[]>>(INITIAL_REQUEST_STATE);

  const getList = useCallback(
    async (params?: PromotionListParams) => {
      setPromotions({ loading: true, result: [], total: 0 });

      const res = await promotionService.getPromotions({
        ...(params?.skip && { skip: params.skip }),
        ...(pagination.perPage && { take: pagination.perPage }),
        ...(params?.rateCodes && { rateCodes: params.rateCodes }),
        ...(params?.vendorCode && { vendorCode: params.vendorCode }),
        ...(params?.status && { status: params.status as PromoStatus }),
        ...(params?.promotionName && { promotionName: params.promotionName }),
      });

      setPromotions({
        loading: false,
        total: res?.total ?? 0,
        result: res?.result ?? [],
      });
    },
    [pagination.perPage],
  );

  const handleSearch = async (searchValues: PromotionSearchFormValues) => {
    await getList({
      status: searchValues.status?.value,
      rateCodes: searchValues.rateCodes,
      vendorCode: searchValues.vendorCode,
      promotionName: searchValues.promotionName,
    });
  };

  const handleDelete = async (promotionId: string) => {
    enqueueSnackbar(`Processing...`, { variant: 'info' });
    const res = await promotionService.deletePromotionById(promotionId);

    if (res?.status === 200) {
      enqueueSnackbar(`Promotion deleted successfully`, { variant: 'success' });
      await getList();
    } else {
      enqueueSnackbar(`Something went wrong. Please contact cruises team`, { variant: 'error' });
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Promotion Management</title>
      </Helmet>

      <Box>
        <PromotionSearchForm onSearch={handleSearch} loading={promotions.loading} />
      </Box>

      <Stack mt={2} direction="row-reverse">
        <Button component={Link} to={`/cruises/promotion-management/create`}>
          Create New Promotion
        </Button>
      </Stack>

      <Box mt={1}>
        <PromotionList
          page={pagination.page}
          perPage={pagination.perPage}
          total={promotions.total}
          loading={promotions.loading}
          promotions={promotions.result}
          onDelete={handleDelete}
          onPaginate={(page) => {
            setPagination({ ...pagination, page });
            getList({ skip: page * pagination.perPage });
          }}
        />
      </Box>
    </Box>
  );
};

export default PromotionManagementPage;
