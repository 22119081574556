import React, { useState } from 'react';

import { Control } from 'react-hook-form';
import { theme } from '~/theme';

import BookmarksIcon from '@mui/icons-material/Bookmarks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FinePrintIcon from '@mui/icons-material/ManageSearch';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import AIGeneratorButton, { AIContext } from '~/components/AIGenerator/AIGenerateButton';
import AdditionalUSPComponent from '~/components/AIGenerator/AdditionalUSPComponent';
import { Input, OfferInclusionsSelect, RichTextEditor } from '~/components/Common/Forms/fields';
import OfferSearchField from '~/components/Common/Forms/fields/OfferSearchField';

import ArrayField from '../fields/ArrayField';

interface Context extends Omit<AIContext, 'field'> {
  model: string;
  field?: string;
}

interface Props {
  addOfferToBundle: (offerId: string) => void;
  control: Control<any>;
  fillingPropertyName: boolean;
  hasLocalizedName: boolean;
  hasLocalizedDescription: boolean;
  onShowTemplateModalClick: (templateType: string) => void;
  onFillBundlePropertyName: () => void;
  setValue: (name: string, value: unknown, config?: object) => void;
  onAppendLPFinePrint: () => void;
  schema: any;
  generatorContext: Context;
}

const BundleAndSaveFields = ({
  addOfferToBundle,
  setValue,
  control,
  fillingPropertyName,
  hasLocalizedDescription,
  hasLocalizedName,
  onShowTemplateModalClick,
  onFillBundlePropertyName,
  onAppendLPFinePrint,
  generatorContext,
}: Props) => {
  const [locationUsps, setLocationUsps] = useState<Array<string>>([]);

  return (
    <Accordion defaultExpanded disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="offer-fields"
        id="offer-fields"
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Typography variant="h5">Offer page</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Input
            control={control}
            helperText={
              hasLocalizedName && "Note: this field has market-specific localisation (check 'Localised Content' tab)"
            }
            label="Headline"
            name="name"
          />
          <Input control={control} label="Name override for EDM" name="name_override_for_edm" />
          <Input control={control} fullWidth label="Name of property" name="location" />
          <Button
            variant="outlined"
            startIcon={<BookmarksIcon />}
            color="secondary"
            onClick={() => onFillBundlePropertyName()}
            disabled={fillingPropertyName}
          >
            Fill property name
          </Button>
          {fillingPropertyName && <LinearProgress color="info" sx={{ margin: '0.5rem 0' }} />}
          <Input control={control} fullWidth label="Offer inclusion heading" name="offer_tile_inclusion_heading" />
          <OfferInclusionsSelect
            control={control}
            label="Offer inclusions"
            name="offer_inclusions"
            setValue={setValue}
          />
          <Input
            control={control}
            fullWidth
            helperText="Maximum 70 characters"
            inputProps={{ maxLength: 70 }}
            label="Offer inclusions summary"
            name="offer_inclusions_short"
          />
          <Input control={control} helperText="Leave blank to auto-generate slug" label="Slug" name="slug" />
          <Input control={control} fullWidth label="Email subject line" name="subject_line" />
          <Input control={control} fullWidth label="Email preheader" name="preheader" />
          <RichTextEditor control={control} label="Highlights" name="highlights" />
          <RichTextEditor
            control={control}
            helperText={
              hasLocalizedDescription &&
              "Note: this field has market-specific localisation (check 'Localised Content' tab)"
            }
            label="Description"
            name="description"
          />
          <Box sx={{ width: '100%' }}>
            <RichTextEditor control={control} label="Locations" name="getting_there" canUseBrandTokens />
            <Button
              variant="outlined"
              startIcon={<BookmarksIcon />}
              color="secondary"
              onClick={() => onShowTemplateModalClick('location')}
            >
              Show Templates
            </Button>
            <AIGeneratorButton
              context={{
                model: generatorContext.model,
                field: 'location',
                propertyName: generatorContext.name,
              }}
              content={generatorContext.getting_there}
              buttonLabel={generatorContext.getting_there ? 'Rewrite location' : 'Generate location'}
              onChoose={(value) => setValue('getting_there', value)}
            >
              <AdditionalUSPComponent contextKey="additionalUSP" value={locationUsps} onValueChange={setLocationUsps} />
            </AIGeneratorButton>
          </Box>
          <Box>
            <RichTextEditor control={control} label="Fine print" name="fine_print" />
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                startIcon={<BookmarksIcon />}
                color="secondary"
                onClick={() => onShowTemplateModalClick('fine_print')}
              >
                Show Templates
              </Button>
              <Button
                variant="outlined"
                startIcon={<FinePrintIcon />}
                color="secondary"
                onClick={() => onAppendLPFinePrint()}
              >
                Append Lux Plus Fine Print
              </Button>
            </Stack>
          </Box>
          <RichTextEditor control={control} label="Facilities" name="facilities" />
          <ArrayField
            control={control}
            label="Offer Bundle"
            name="offer_bundles"
            hideAddButton
            render={(props) => <TextField {...props} disabled fullWidth label="" />}
          />
          <OfferSearchField onRowClickHandler={addOfferToBundle} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default BundleAndSaveFields;
