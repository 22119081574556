import { FxRateContract, paths } from '@luxuryescapes/contract-fx/index';

import { json_headers, request } from './common';

export type GetFxRatesResponse = paths['/api/fx/rates']['get']['responses']['200']['content']['application/json'];
export type PostConvertCurrencyRequest = paths['/api/fx/convert-currency']['post']['parameters']['body']['payload'];
export type PostConvertCurrencyResponse =
  paths['/api/fx/convert-currency']['post']['responses']['200']['content']['application/json'];

export function basePath() {
  return window.configs.API_HOST + '/api/fx';
}

export async function getFxRates(): Promise<FxRateContract.FxRate[]> {
  const url = `${basePath()}/rates`;
  const response: GetFxRatesResponse = await request(url, { method: 'GET' });
  return response.result;
}

export async function postConvertedCurrency(payload: PostConvertCurrencyRequest): Promise<PostConvertCurrencyResponse> {
  const url = `${basePath()}/convert-currency`;
  const response: PostConvertCurrencyResponse = await request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(payload),
  });
  return response;
}
