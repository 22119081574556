import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { addOfferToCart, changeCurrencyCode, reduceOfferItemQuantity, setOrderId } from '../../../../actions/cart';
import { FILTER_BROWSABLE } from '../../../../consts/filters';
import { OFFER_TYPE_HOTEL } from '../../../../consts/offerTypes';
import { ITEM_TYPE_OFFER } from '../../../../consts/order';
import OffersService from '../../../../services/OffersService';
import UsersService from '../../../../services/UsersService';
import { preloadRoomType } from '../../../../utils/preloadOfferAssociations';
import Spinner from '../../../Common/Spinner';
import NoDataWarning from '../NoDataWarning';
import { getAllItems } from '../selectors';
import withOffers from '../withOffers';

import PackageSelection from './Component';

class PackageSelectionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offer: null,
      creatingOrder: false,
      spoof_url: '',
      regions: libRegions.getRegions(this.props.tenant.brand),
    };
  }

  componentDidMount() {
    const { offer } = this.props;

    if (!offer) {
      return;
    }

    OffersService.getOffer(offer.id_salesforce_external, {
      filter: FILTER_BROWSABLE,
    })
      .then((res) => {
        const offer = res.result;

        const promises = [];

        // preload associations of the offer
        offer.packages.forEach((offerPackage) => {
          if (offer.type === OFFER_TYPE_HOTEL) {
            promises.push(preloadRoomType(offerPackage));
          }
        });

        return Promise.all(promises).then(() => {
          return offer;
        });
      })
      .then((offer) => {
        this.setState({ offer, dataPresent: true });
      });
  }

  isStateValid = () => {
    const { offer, cart } = this.props;

    return Boolean(offer) && !cart.orderId;
  };

  handleChangerRegionCode = (regionCode) => {
    const { doChangeCurrencyCode } = this.props;
    const region = libRegions.getRegionByCode(regionCode, this.props.tenant.brand);

    doChangeCurrencyCode(region.currencyCode, region.code);
  };

  handleAddOfferToCart = (pkg) => {
    const { doAddOfferToCart, cart, offer } = this.props;
    const { currencyCode } = cart;

    doAddOfferToCart({ pkg, currencyCode, offer });
  };

  // getPackagePrice gets the price of the package for the currently selected
  // currency code. It assumes that the price is available for the
  // currently currency code.
  getPackagePrice = (item) => {
    const {
      cart: { currencyCode },
    } = this.props;

    const priceObj = item.prices.find((o) => o.currency_code === currencyCode);

    if (priceObj) {
      return priceObj.price;
    }

    return null;
  };

  getPackages = () => {
    const { offer } = this.state;

    if (!offer) {
      return [];
    }

    // Some packages have no prices and those packages should not be purchasable.
    return offer.packages
      .filter((pkg) => {
        return pkg.prices_count > 0;
      })
      .sort((a, b) => {
        return a.number_of_nights - b.number_of_nights;
      });
  };

  getPackageQuantity = (pkgId, offer) =>
    offer.items.filter(
      (item) =>
        (item.pkg.id_salesforce_external ?? item.pkg.le_package_id) === pkgId &&
        item.offerId === offer.id_salesforce_external,
    ).length;

  async handleSpoofUser() {
    let spoofUrl = await UsersService.spoofUserWithOfferLink(
      this.props.user.id_member,
      this.state.offer,
      this.props.tenant,
    );
    this.setState({ spoof_url: spoofUrl });
  }

  render() {
    const {
      doRemoveFromCart,
      cart,
      backStep,
      nextStepLabel,
      proceedStep,
      disableChangeCurrency,
      tenant,
      offer,
      items,
      isMultiBooking,
      offerCartId,
      offerNumber,
    } = this.props;
    const { currencyCode, customer } = cart;
    const { creatingOrder } = this.state;

    const dataPresent = this.isStateValid();
    const brand = tenant.brand || 'luxuryescapes';

    if (!dataPresent) {
      return <NoDataWarning userId={customer.id_member} orderType={ITEM_TYPE_OFFER} />;
    }

    if (!offer) {
      return <Spinner />;
    }

    const packages = this.getPackages();

    return (
      <>
        {!isMultiBooking && (
          <>
            <p className="h6 text-muted text-uppercase">Spoof Customer (EXTREME CAUTION, paste into incognito ONLY)</p>
            <Button variant="contained" color="warning" onClick={this.handleSpoofUser.bind(this)}>
              Generate Link
            </Button>
            <div>{this.state.spoof_url}</div>
            <hr />
          </>
        )}

        <PackageSelection
          offer={offer}
          cart={cart}
          onAddOfferToCart={this.handleAddOfferToCart}
          onRemoveFromCart={doRemoveFromCart}
          currencyCode={currencyCode}
          onChangeRegionCode={this.handleChangerRegionCode}
          backStep={backStep}
          nextStepLabel={nextStepLabel}
          getPackagePrice={this.getPackagePrice}
          getPackageQuantity={this.getPackageQuantity}
          packages={packages}
          onProceedStep={proceedStep}
          disableChangeCurrency={disableChangeCurrency}
          regions={this.state.regions}
          creatingOrder={creatingOrder}
          brand={brand}
          items={items}
          offerCartId={offerCartId}
          offerNumber={offerNumber}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    tenant: state.tenant,
    items: getAllItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doAddOfferToCart: addOfferToCart,
      doRemoveFromCart: reduceOfferItemQuantity,
      doChangeCurrencyCode: changeCurrencyCode,
      doSetOrderId: setOrderId,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withOffers(PackageSelectionContainer));
