import React, { useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import MetricsResultsTable from '~/components/Metrics/MetricsResultsTable';
import { METRIC_DESCRIPTIONS, METRIC_ID_NAMES, METRIC_NAMES } from '~/components/Metrics/constants';
import { SIZE_PER_PAGE } from '~/components/Purchases/Home/Cruises/constants';

import ReservationService from '~/services/ReservationService';

interface MetricResultsProps {
  sizePerPage: number;
  metricName: any;
  channel: string;
  date: string;
}

const columns = (metricIdName: string): GridColDef[] => {
  return [
    {
      field: 'metricId',
      headerName: metricIdName,
      flex: 3,
    },
    {
      field: 'previousTotal',
      headerName: 'Previous Total',
      flex: 1,
    },
    {
      field: 'currentTotal',
      headerName: 'Current Total',
      flex: 1,
    },
  ];
};

const MetricsResults = ({ sizePerPage, metricName, channel, date }: MetricResultsProps) => {
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [alerts, setAlerts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchPageForMetric = React.useCallback(async () => {
    setIsLoading(true);
    const { result } = await ReservationService.getMetricAlertsByName(
      channel,
      date,
      metricName,
      page * SIZE_PER_PAGE,
      SIZE_PER_PAGE,
    );

    setAlerts(result.alerts);
    setTotal(result.totalAlerts);
    setIsLoading(false);
  }, [page]);

  useEffect(() => {
    fetchPageForMetric();
  }, [fetchPageForMetric]);

  return (
    <Box>
      <Typography variant="h5">{METRIC_NAMES[metricName]}</Typography>
      <Typography variant="body1">{METRIC_DESCRIPTIONS[metricName]}</Typography>
      <MetricsResultsTable
        sizePerPage={sizePerPage}
        alerts={alerts}
        columns={columns(METRIC_ID_NAMES[metricName])}
        page={page}
        setPage={setPage}
        total={total}
        loading={isLoading}
      />
    </Box>
  );
};

export default MetricsResults;
