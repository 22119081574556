import { useMemo, useState } from 'react';

import { GridSortItem } from '@mui/x-data-grid';

import { SearchChatParams } from './types';

export function useSearchChatsParams(searchChatsParams?: Partial<SearchChatParams>) {
  const [searchString, setSearchString] = useState<string>(searchChatsParams.searchString);
  const [chatTopicsFilter, setChatTopicsFilter] = useState(searchChatsParams.chatTopicsFilter || []);
  const [sortModel, setSortModel] = useState<GridSortItem>(
    searchChatsParams.sortModel || { field: 'createdAt', sort: 'desc' },
  );
  const [filterByDeadEnd, setFilterByDeadEnd] = useState(searchChatsParams.filterByDeadEnd || false);
  const [hasMessagesOnly, setHasMessagesOnly] = useState<boolean>(searchChatsParams.hasMessagesOnly || true);
  const [customersOnly, setCustomersOnly] = useState(searchChatsParams.customersOnly || false);
  const [agentChatsOnly, setAgentChatsOnly] = useState(searchChatsParams.agentChatsOnly || false);
  const [followUpOnly, setFollowUpOnly] = useState(searchChatsParams.followUpOnly || false);
  const [limit] = useState(10);
  const [page, setPage] = useState(searchChatsParams.page || 0);

  const searchParams: SearchChatParams = useMemo(
    () => ({
      searchString,
      chatTopicsFilter,
      sortModel,
      filterByDeadEnd,
      customersOnly,
      agentChatsOnly,
      followUpOnly,
      hasMessagesOnly,
      limit,
      page,
    }),
    [
      agentChatsOnly,
      chatTopicsFilter,
      customersOnly,
      filterByDeadEnd,
      followUpOnly,
      hasMessagesOnly,
      limit,
      page,
      searchString,
      sortModel,
    ],
  );

  const setters = useMemo(
    () => ({
      setSearchString,
      setChatTopicsFilter,
      setSortModel,
      setFilterByDeadEnd,
      setCustomersOnly,
      setAgentChatsOnly,
      setFollowUpOnly,
      setHasMessagesOnly,
      setPage,
    }),
    [],
  );

  return {
    setters,
    searchParams,
  };
}
