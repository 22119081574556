import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { formatDateLong } from '~/services/TimeService';

const formatStartDate = (item: App.OrderItem) => {
  let dateString: string;

  if (item.reservation?.check_in) {
    dateString = item.reservation?.check_in;
  }

  if (item.reservation?.start_date) {
    dateString = item.reservation?.start_date;
  }

  const formattedDate = formatDateLong(new Date(dateString));

  return dateString ? formattedDate : 'no date';
};

const checkInFormatter = ({ row }: GridRenderCellParams<App.Order>) => {
  return (
    <Stack direction="column" key={`${row.id_orders}-check-in`}>
      {row.items.map((item) => (
        <Typography key={item.id}>
          {item.status === 'cancelled' ? <s>{item.booking_number}</s> : formatStartDate(item)}
        </Typography>
      ))}
    </Stack>
  );
};

export default checkInFormatter;
