import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@mui/material';

interface Props {
  name?: string;
  id: string;
  to: string;
}

export default function NameAndIdFormatter({ name, id, to }: Props) {
  return (
    <Box
      sx={{
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
      }}
    >
      {name && (
        <Typography variant="body1" title="name">
          {name}
        </Typography>
      )}
      <Link component={RouterLink} to={to} target="_blank" title="id">
        {id}
      </Link>
    </Box>
  );
}
