import React from 'react';

import { RouteChildrenProps, withRouter } from 'react-router';

import OfferSearchForm from '~/components/Common/Forms/OfferSearchForm';
import OfferList from '~/components/Common/OfferListContainer';

import OffersService from '~/services/OffersService';

import { addQuery } from '~/utils/url';

type Props = RouteChildrenProps;

type State = {
  searchString: string;
  ledExperience: boolean;
  destinations: { value: string; label: string }[];
};

class OffersSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchString: '',
      ledExperience: false,
      destinations: [],
    };
  }

  async componentDidMount() {
    const response = await OffersService.getAllLocations();
    this.setState({ destinations: response.result || [] });
  }

  searchQuery = (query: string) => {
    this.setState({ searchString: query }, () => {
      const { history, location } = this.props;

      // Remove query params
      const newLocation = addQuery(location, { page: null });
      history.push(newLocation);
    });
  };

  setLedExperience = (ledExperience: boolean) => {
    this.setState({ ledExperience });
  };

  urlBuilder = (row: App.Offer) => {
    return `/finance/offers/${row.id_salesforce_external}`;
  };

  render() {
    const { searchString, destinations } = this.state;

    return (
      <>
        <OfferSearchForm
          destinations={destinations}
          searchQuery={this.searchQuery}
          setLedExperience={this.setLedExperience}
        />

        <OfferList urlBuilder={this.urlBuilder} searchString={searchString} ledExperience={this.state.ledExperience} />
      </>
    );
  }
}

export default withRouter(OffersSearch);
