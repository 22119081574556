import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';
import { ExperienceCurationProvider } from '~/components/Experiences/hooks';

import { getCategories, getExperienceOfferById } from '~/services/ExperiencesService';

import { ExperiencesOverview } from './ExperiencesOverview';

type ParamType = {
  id_experience: string;
};

type LoadingProps = {
  categories: boolean;
  offerExperience: boolean;
};

export default function ExperienceDetails() {
  const { id_experience: experienceId } = useParams<ParamType>();

  const tenant = useSelector((state: App.State) => state.tenant);

  const [data, setData] = useState({
    experience: null,
    categories: null,
  });
  const [loading, setLoading] = useState<LoadingProps>({
    categories: false,
    offerExperience: false,
  });
  const [error, setError] = useState<string>(null);

  const fetchOfferExperienceById = useCallback(async (): Promise<void> => {
    setLoading((prev) => ({ ...prev, offerExperience: true }));
    try {
      const res = await getExperienceOfferById({
        id: experienceId,
        brand: tenant.brand,
        curationData: true,
      });
      if (res?.errors?.length > 0) {
        console.error("[ERROR] Don't exists experience offer response.");
        setError(res?.errors[0]);
      }
      setData((prev) => ({ ...prev, experience: res.result }));
    } catch (error) {
      console.error('ERROR ON FETCH EXPERIENCE OFFER: ', error);
      setError(error.message);
    } finally {
      setLoading((prev) => ({ ...prev, offerExperience: false }));
    }
  }, [experienceId, tenant.brand]);

  const fetchCategories = useCallback(async (): Promise<void> => {
    setLoading((prev) => ({ ...prev, categories: true }));
    try {
      const res = await getCategories(tenant.brand);
      if (res?.errors?.length > 0) {
        console.error("[ERROR] Don't exists categories response.");
        setError(res?.errors[0]);
      }
      setData((prev) => ({ ...prev, categories: res.result }));
    } catch (error) {
      console.error('ERROR ON FETCH CATEGORIES: ', error);
      setError(error.message);
    } finally {
      setLoading((prev) => ({ ...prev, categories: false }));
    }
  }, [tenant.brand]);

  useEffect(() => {
    fetchOfferExperienceById();
    fetchCategories();
  }, [fetchCategories, fetchOfferExperienceById]);

  if (loading.offerExperience || loading.categories) return <Spinner />;

  if (error) return <ErrorDisplay message={error} />;

  return (
    <ExperienceCurationProvider experience={data.experience}>
      <ExperiencesOverview tenant={tenant} experience={data.experience} categories={data.categories} />
    </ExperienceCurationProvider>
  );
}
