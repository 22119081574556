import { getCountries, getCountryObjs } from '@luxuryescapes/lib-countries';

export const countriesOptions = getCountries().map((country) => ({
  label: country,
  value: country,
}));

export const countriesObjectOptions = getCountryObjs().map((country) => ({
  label: country.countryName,
  value: country.iso2,
}));
