import React, { useEffect } from 'react';

import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { AdCampaign } from '~/services/PublicOfferFeedService';

import Spinner from '../Common/Spinner';

import CampaignMultiSelect from './CampaignMultiSelect';

interface Props {
  customerId: string;
  setCustomerId: (customerId: string) => void;
  customerList: Array<{ accountName: string; accountId: string }>;
  campaignMap: Map<string, Array<AdCampaign>>;
  campaignIds: Array<string>;
  setCampaignIds: (campaingIds: Array<string>) => void;
  isLoading: boolean;
  fetchCustomerList: () => void;
  searchAdGroups: () => void;
  fetchingState: string;
}

export function GoogleAdGroupSearch({
  customerId,
  setCustomerId,
  customerList,
  campaignIds,
  setCampaignIds,
  campaignMap,
  isLoading,
  fetchCustomerList,
  searchAdGroups,
  fetchingState,
}: Props) {
  useEffect(() => {
    if (customerId && campaignIds && campaignIds.length > 0) {
      searchAdGroups();
    }
  }, [customerId, campaignIds, searchAdGroups]);

  useEffect(() => {
    fetchCustomerList();
    if (customerId && campaignIds && campaignIds.length > 0) {
      searchAdGroups();
    }
  }, [fetchCustomerList]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Grid container spacing={2} mb={2} mt={2}>
      <Grid md={2}>
        <FormControl fullWidth>
          <InputLabel id="select-customer-label">Select Customer Account</InputLabel>
          <Select
            labelId="select-customer-label"
            value={customerId}
            label="Select Customer Account"
            onChange={(e) => setCustomerId(e.target.value)}
          >
            {customerList?.map(({ accountName, accountId }) => (
              <MenuItem key={accountId} value={accountId}>
                {accountName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid md={2}>
        <FormControl fullWidth>
          <InputLabel id="select-customer-label">Select Campaigns</InputLabel>
          {customerId && campaignMap && campaignMap.get(customerId) && (
            <CampaignMultiSelect
              id="campaignIds"
              value={campaignIds}
              isLoading={isLoading}
              onCampaignIdsSet={setCampaignIds}
              campaigns={campaignMap.get(customerId)}
            />
          )}
        </FormControl>
      </Grid>
      <Grid md={2}>
        <Button
          variant="contained"
          className="T-promo-search-button"
          type="submit"
          disabled={!customerId || !campaignIds || campaignIds.length == 0}
          onClick={() => searchAdGroups()}
        >
          {fetchingState === 'loading' ? 'Searching…' : 'Search'}
        </Button>
      </Grid>
    </Grid>
  );
}
