import React from 'react';

import { MarkdownPreview } from 'react-marked-markdown';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

export default function TaxesAndFeesPane(props) {
  const { vendorId, propertyId, taxesAndFeesContent, downloadInProgress, downloadFailed, downloadTaxesAndFees } = props;

  return (
    <div>
      <PageSubheader title="Taxes and fees content">
        <Stack direction="row" spacing={1}>
          <Button variant="text" onClick={downloadTaxesAndFees} sx={{ marginLeft: 'auto' }}>
            {downloadInProgress && <Spinner color="#2196f3" size={16} inline />}
            {!downloadInProgress && <span>Download Taxes and fees</span>}
            {downloadInProgress && <span style={{ marginLeft: '4px' }}>Downloading Taxes and fees</span>}
            {!downloadInProgress && downloadFailed && <span>Download Failed! Try Again</span>}
          </Button>

          <Button
            variant="text"
            component={Link}
            to={`/vendors/${vendorId}/properties/${propertyId}/edit-property-taxes-content`}
          >
            Edit
          </Button>
        </Stack>
      </PageSubheader>

      {taxesAndFeesContent && <MarkdownPreview value={taxesAndFeesContent} />}
      {!taxesAndFeesContent && <Typography>There is no Taxes and fees content set for this property.</Typography>}
    </div>
  );
}
