import React, { useState } from 'react';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';

type Value = Record<string, unknown> | App.Property;

type Props = {
  label?: string;
  placeholder?: string;
  loadingText?: string;
  loadOptions: (search: string, callback: (options: readonly Value[]) => void) => Promise<void>;
  onChange: (value: Value) => void | Promise<void>;
  getOptionLabel?: (option: Value) => string;
};

export default function AsyncAutocomplete(props: Props) {
  const { label, placeholder, loadingText = 'Searching...' } = props;
  const { loadOptions, getOptionLabel, onChange } = props;

  const [value, setValue] = useState<Value | null>(null);
  const [options, setOptions] = useState<readonly Value[]>([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (_: React.SyntheticEvent, value: string, reason: string) => {
    if (reason !== 'input') return;

    setLoading(true);

    loadOptions(value, (options: readonly Value[]) => {
      setOptions(options);
      setLoading(false);
    });
  };

  const handleChange = (_: React.SyntheticEvent, value: Value | null) => {
    setValue(value);
    onChange(value);
  };

  return (
    <Autocomplete
      placeholder={placeholder}
      options={options}
      value={value}
      loading={loading}
      loadingText={loadingText}
      slotProps={{ paper: { elevation: 1 } }}
      filterOptions={(x) => x}
      renderInput={(props) => (
        <TextField
          {...props}
          label={label}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {props.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      getOptionLabel={getOptionLabel}
      noOptionsText="No options"
      onInputChange={handleInputChange}
      onChange={handleChange}
      autoComplete
      fullWidth
    />
  );
}
