import React from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

interface Props {
  onChange?: any;
}

export default function MarkCancelled({ onChange }: Props) {
  return (
    <FormControl required>
      <FormLabel
        sx={{
          fontWeight: 'bold',
        }}
      >
        Should the booking be cancelled?
      </FormLabel>
      <RadioGroup name="markCancelled" row onChange={onChange ? onChange : undefined}>
        <FormControlLabel
          data-cy="mark-cancelled-true"
          key="should-booking-be-cancelled-yes"
          value
          control={
            <Radio
              inputProps={{
                required: true,
              }}
            />
          }
          label="Yes"
        />
        <FormControlLabel
          data-cy="mark-cancelled-false"
          key="should-booking-be-cancelled-no"
          value={false}
          control={
            <Radio
              inputProps={{
                required: true,
              }}
            />
          }
          label="No"
        />
      </RadioGroup>
    </FormControl>
  );
}
