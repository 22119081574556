import React from 'react';

import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

const columns: GridColDef<App.Amenity>[] = [
  {
    field: 'name',
    headerName: 'Amenity Name',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'group',
    headerName: 'Group',
    disableColumnMenu: true,
    valueGetter: (_value, row) => row?.group?.name,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'built_in',
    headerName: 'Built In',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

type Props = {
  data: App.Amenity[];
  onRowClick: (amenity: App.Amenity) => void;
  onPageChange: (page: number) => void;
  page: number;
  total: number;
  pageSize: number;
};

export default function AmenitySearchResults({ onRowClick, onPageChange, page, total, data, pageSize }: Props) {
  return (
    <DataGrid
      className="T-search-amenity-result"
      rows={data}
      columns={columns}
      getRowClassName={() => 'T-search-amenity-row'}
      onRowClick={(params: GridRowParams<App.Amenity>) => onRowClick(params.row)}
      rowCount={total}
      paginationMode="server"
      onPaginationModelChange={({ page }) => onPageChange(page)}
      pageSizeOptions={[pageSize]}
      paginationModel={{ page, pageSize }}
      slots={{ pagination: GridPagination }}
      disableColumnMenu
      disableColumnFilter
      autoHeight
    />
  );
}
