import qs from 'qs';

import { request } from './common';

export function basePath() {
  return window.configs.API_HOST + '/api/hotel-meta';
}

interface IRequireHotelMetaEmailParams {
  email: string;
  propertyId: string;
}

interface IRequireHotelMetaEmailResponse {
  status: number;
  message: string;
}

const requireHotelMetaEmail = async ({
  email,
  propertyId,
}: IRequireHotelMetaEmailParams): Promise<IRequireHotelMetaEmailResponse> => {
  const queryParams = {
    email,
  };

  const url = `${basePath()}/properties/${propertyId}?${qs.stringify(queryParams)}`;

  return request(url, {
    method: 'GET',
  });
};

const syncPropertyMeta = (propertyId: string): Promise<{ jobId: string }> => {
  const url = `${basePath()}/properties/${propertyId}/sync`;
  return request(url, {
    method: 'GET',
  });
};

const checkSyncPropertyMetaJob = (jobId: string): Promise<{ is_completed: boolean; message: string }> => {
  const url = `${basePath()}/sync-check/${jobId}`;

  return request(url, {
    method: 'GET',
  });
};

const populateImagesToRoomTypes = (
  propertyId: string,
  offerId?: string,
): Promise<{ status: number; message: string }> => {
  let url = `${basePath()}/properties/${propertyId}/populate-images`;
  if (offerId) {
    url = `${url}/${offerId}`;
  }

  return request(url, {
    method: 'PATCH',
  });
};

const checkUploadingStatus = (propertyId: string): Promise<{ status: number; message: string }> => {
  const url = `${basePath()}/properties/${propertyId}/uploading-status`;

  return request(url, {
    method: 'GET',
  });
};

export {
  requireHotelMetaEmail,
  syncPropertyMeta,
  checkSyncPropertyMetaJob,
  populateImagesToRoomTypes,
  checkUploadingStatus,
};
