export enum DateRequestStatuses {
  approved = 'approved',
  approvedBnbl = 'approved_bnbl',
  rejected = 'rejected',
  customerBooked = 'customer_booked',
  customerPutOnHold = 'customer_put_on_hold',
  customerCancelled = 'customer_cancelled',
  expired = 'expired',
  pending = 'pending',
  cancelled = 'cancelled',
  autoRejectedPending = 'auto_rejected_pending',
  autoRejected = 'auto_rejected',
}
