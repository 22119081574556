import React, { Component } from 'react';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Card, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

import ReservationSchedule from '../common/ReservationSchedule';

interface Props {
  inclusions: Array<App.RoomRateInclusion>;
  inclusionsSchema: any;
  onDelete: (string) => void;
  onEdit: (inclusion: App.RoomRateInclusion) => void;
  onInclusionsOrderUpdate: (any) => void;
}

interface State {
  inclusions: Array<App.RoomRateInclusion>;
}

export default class RoomRateInclusionsTable extends Component<Props, State> {
  constructor(props) {
    super(props);

    // Init reordering as a fallback for old records
    this.state = {
      inclusions: props.inclusions.map((inclusion, index) => {
        inclusion.order = inclusion.order && inclusion.order <= index ? inclusion.order : index;
        return inclusion;
      }),
    };
  }

  onDelete(item) {
    this.props.onDelete(item.id);
  }

  onEdit(item) {
    this.props.onEdit(item);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onInclusionsOrderUpdate } = this.props;
    const reorderedInclusions = arrayMove(this.state.inclusions, oldIndex, newIndex).filter((i: any) => i);

    // Reorder items
    let order = 0;
    for (const i in reorderedInclusions) {
      reorderedInclusions[i].order = order++;
    }

    this.setState({ inclusions: reorderedInclusions }, () => {
      onInclusionsOrderUpdate(reorderedInclusions.map((item) => item.id));
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.inclusions !== this.props.inclusions) {
      this.setState({ inclusions: this.props.inclusions });
    }
  }

  getUnit = (unit) => {
    const enums = this.props.inclusionsSchema.properties.unit.enum;
    for (const index in enums) {
      if (enums[index] === unit) {
        return this.props.inclusionsSchema.properties.unit.enumNames[index];
      }
    }
    return this.props.inclusionsSchema.properties.unit.enumNames[0];
  };

  getSortableContainer() {
    const SortableItem = this.generateSortableItem();
    return SortableContainer(({ items }) => (
      <TableBody>
        {items.map((value) => (
          <SortableItem key={`item-${value.id}`} index={value.order} value={value} />
        ))}
      </TableBody>
    ));
  }

  generateSortableItem() {
    return SortableElement(({ value }) => (
      <TableRow className="sortable-table-tr" key={value.id} data-list-order={value.order}>
        <TableCell sx={{ display: 'none' }}>{value.id}</TableCell>
        <TableCell>{value.description}</TableCell>
        <TableCell>{value.amount}</TableCell>
        <TableCell>{value.currency}</TableCell>
        <TableCell>{value.minimum_los}</TableCell>
        <TableCell>{value.maximum_los}</TableCell>
        <TableCell>{this.getUnit(value.unit)}</TableCell>
        <TableCell>{value.is_bonus ? 'Yes' : 'No'}</TableCell>
        <TableCell>{value.lux_plus_tier === 'base' ? 'Yes' : 'No'}</TableCell>
        <TableCell>{value.schedules_list && <ReservationSchedule parent={value} />}</TableCell>
        <TableCell>
          <Stack direction="row-reverse">
            <ConfirmButton
              title="Delete"
              confirmTitle="Confirm delete"
              confirmQuestion="Are you sure?"
              confirmAnswer="Delete"
              onConfirm={() => this.onDelete(value)}
              size="small"
              asIcon
            >
              <DeleteIcon />
            </ConfirmButton>

            <IconButton title="Edit" onClick={() => this.onEdit(value)} size="small">
              <EditIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    ));
  }

  render() {
    const SortableContainer = this.getSortableContainer();

    return (
      <Card variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Min LOS</TableCell>
              <TableCell>Max LOS</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Bonus</TableCell>
              <TableCell>LuxPlus</TableCell>
              <TableCell>Schedule</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <SortableContainer
            onSortEnd={this.onSortEnd.bind(this)}
            items={this.state.inclusions}
            helperClass="sortable-helper"
            distance={3}
          />
        </Table>
      </Card>
    );
  }
}
