import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { downloadPurchaseReport } from '~/services/ReportingService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please enter a date range to export all purchases. Offer ID is optional',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
    completedDateBased: {
      title: 'Filter by completed at',
      type: 'boolean',
    },
    idOffer: {
      title: 'Offer ID',
      type: 'string',
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
  completedDateBased: {
    'ui:widget': 'checkbox',
  },
  idOffer: {
    'ui:widget': 'text',
  },
};

export default function ExportPurchasesReportForm() {
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt, idOffer, completedDateBased } = event.formData;

    const data = downloadPurchaseReport({
      startAt,
      endAt,
      idOffer,
      completedDateBased,
    });

    const fileName = `purchase_report_${startAt}_${endAt}_${idOffer}.csv`;

    data
      .then(function (text) {
        fileDownload(text, fileName);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form schema={formSchema} uiSchema={uiSchema} onSubmit={handleSubmit} showErrorList={false} validator={validator}>
      {error && <ErrorDisplay message={error} />}

      <Button type="submit" variant="contained">
        Download Purchase Report
      </Button>
    </Form>
  );
}
