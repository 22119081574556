import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import * as publicOfferFeedService from '~/services/PublicOfferFeedService';
import { AdCampaign } from '~/services/PublicOfferFeedService';

type CustomerCampaignData = {
  customerList: Array<{ accountName: string; accountId: string }>;
  campaignMap: Map<string, Array<AdCampaign>>;
  isLoading: boolean;
  fetchCustomerList: () => void;
};

export const useCustomerCampaignData = (): CustomerCampaignData => {
  const { enqueueSnackbar } = useSnackbar();
  const [customerList, setCustomerList] = useState<Array<{ accountName: string; accountId: string }>>([]);
  const [campaignMap, setCampaignMap] = useState(new Map<string, Array<AdCampaign>>());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchCustomerList = useCallback(async () => {
    const customers = [];
    const campaigns = new Map();
    try {
      setIsLoading(true);
      const { result } = await publicOfferFeedService.getCustomers();
      result.forEach((customer) => {
        if (customer.campaigns.length > 0) {
          customers.push({ accountName: customer.descriptiveName, accountId: customer.id });
          campaigns.set(customer.id, customer.campaigns);
        }
      });

      setCustomerList(customers);
      setCampaignMap(campaigns);
    } catch (e) {
      enqueueSnackbar(`Failed to fetch campaign list : ${e.message}`, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchCustomerList();
  }, [fetchCustomerList]);

  return { customerList, campaignMap, isLoading, fetchCustomerList };
};
