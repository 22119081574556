import React, { useState } from 'react';

import pluralize from 'pluralize';
import { ExperiencesState } from '~/reducers/experiences';

import { Alert, Box } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import ExperiencesList from './ExperiencesList';
import HeroesList from './HeroesList';

type ContentProps = {
  experiences: ExperiencesState;
  currentPage: number;
  sizePerPage: number;
  experiencesTotal: number;
  handlePage: (_, page: number) => void;
};

export default function HomepageCuration(props: ContentProps) {
  const { experiences, handlePage, currentPage, sizePerPage, experiencesTotal } = props;

  const [selectedExperiences, setSelectedExperiences] = useState<Experiences.Offer[]>([]);

  const addExperience = (experience: Experiences.Offer) =>
    setSelectedExperiences((experiences) => [...experiences, experience]);

  const removeExperience = (experience: Experiences.Offer) =>
    setSelectedExperiences((experiences) => experiences.filter((item) => item.id !== experience.id));

  const notFoundExperiences = !experiences.error && !experiences.loading && experiences.items?.length === 0;
  const hasExperiences = experiences.items?.length > 0;
  const countExp = pluralize('experience', experiences?.total || 0, true);
  const experiencesTitle = `${countExp} found by your search criteria`;

  return (
    <Box>
      {hasExperiences && (
        <Alert severity="success">
          <strong>{experiencesTitle.toUpperCase()}</strong>
        </Alert>
      )}
      {experiences?.error && (
        <Alert severity="error">
          <strong>ERROR ON FETCH EXPERIENCES</strong>
        </Alert>
      )}
      {notFoundExperiences && (
        <Alert severity="warning">
          <strong>NO EXPERIENCES FOUND</strong>
        </Alert>
      )}

      <Box mt={2} display="grid" gap={2} gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr' }}>
        <ExperiencesList
          experiences={experiences}
          handlePage={handlePage}
          currentPage={currentPage}
          sizePerPage={sizePerPage}
          experiencesTotal={experiencesTotal}
          selectedExperiences={selectedExperiences}
          onAddExperience={addExperience}
          onRemoveExperience={removeExperience}
        />
        <HeroesList
          selectedExperiences={selectedExperiences}
          onRemoveExperience={removeExperience}
          onLoadSelectedExperiences={setSelectedExperiences}
        />
      </Box>
    </Box>
  );
}
