import React, { Fragment } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import ReservationSummaryField from './ReservationSummaryField';

interface Props {
  isSummaryOpen: boolean;
  toggleSummary: () => void;
  bookingDetails: { [key: string]: string };
}
export default function TemplateForCSModal({ bookingDetails, isSummaryOpen, toggleSummary }: Props) {
  return (
    <Dialog
      open={isSummaryOpen}
      onClose={toggleSummary}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Booking Details are as follows:</DialogTitle>
      <Dialog
        open={isSummaryOpen}
        onClose={toggleSummary}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Booking Details are as follows:</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Object.entries(bookingDetails).map(([key, value]) => (
              <Fragment key={key}>{value && <ReservationSummaryField label={key} value={value} />}</Fragment>
            ))}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={toggleSummary}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {Object.entries(bookingDetails).map(([key, value]) => (
            <Fragment key={key}>{value && <ReservationSummaryField label={key} value={value} />}</Fragment>
          ))}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={toggleSummary}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
