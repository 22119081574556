import { DateRequestStatuses } from '~/consts/dateRequests';

export const getDateChangeOutsidePolicyStatus = (status: string, type: string) => {
  switch (status) {
    case DateRequestStatuses.approved:
      return { text: 'Request Approved - Pending Customer Response', severity: 'success' };

    case DateRequestStatuses.approvedBnbl:
      return { text: 'Request Approved BNBL - Pending Customer Response', severity: 'success' };

    case DateRequestStatuses.rejected:
      return { text: 'Request Rejected', severity: 'error' };

    case DateRequestStatuses.customerBooked:
      return { text: 'New Dates Changed', severity: 'success' };

    case DateRequestStatuses.customerPutOnHold:
      return { text: 'Converted to BNBL', severity: 'success' };

    case DateRequestStatuses.customerCancelled:
      return { text: 'Cancelled Booking', severity: 'error' };

    case DateRequestStatuses.expired:
      if (type === 'change_dates') {
        return { text: 'Approved Date Change Expired', severity: 'success' };
      }
      if (type === 'bnbl') {
        return { text: 'Approved BNBL Expired', severity: 'success' };
      }
      return { text: 'Expired with unknown type', severity: 'info' };

    case DateRequestStatuses.autoRejectedPending:
    case DateRequestStatuses.autoRejected:
      return { text: 'Auto-Rejected', severity: 'error' };

    case DateRequestStatuses.pending:
      return { text: 'Request Pending', severity: 'none' };

    case DateRequestStatuses.cancelled:
      return { text: 'Request Withdrawn', severity: 'none' };

    default:
      return { text: 'Unknown Status', severity: 'info' };
  }
};
