import React from 'react';

import { Box, Stack } from '@mui/material';

export default function BedbankAutomaticRefundDetails(props) {
  return (
    <Box>
      <h4>Refund Summary</h4>

      <Stack direction="row" spacing={2}>
        <Box>
          <small>Customer Name</small>
        </Box>
        <Box>
          <small>{props.customer_full_name}</small>
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box>
          <small>Refund Category</small>
        </Box>
        <Box>
          <small>{props.reason}</small>
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box>
          <small>Customer's reason</small>
        </Box>
        <Box>
          <small>{props.comment}</small>
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box>
          <small>Source of Fund</small>
        </Box>
        <Box>
          <small>{props.source}</small>
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box>
          <small>Room Cancelled</small>
        </Box>
        <Box>
          <small>{props.markCancelled}</small>
        </Box>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box>
          <small>
            <b>Customer Refund Amount {props.currencyCode}</b>
          </small>
        </Box>
        <Box>
          <small>{props.totalRefund}</small>
        </Box>
      </Stack>
    </Box>
  );
}
