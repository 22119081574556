import React, { FormEventHandler, useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Dialog, DialogTitle } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import {
  CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD,
  COLLECTION_REASONS,
  CUSTOM_REASON_SOURCE,
  EXPIRED_BNBL_PACKAGE,
  FAILED_REFUND_FROM_PAYMENT_GATEWAYS,
  FRAUD_AND_COMPLIANCE_AND_DISPUTE,
  INSURANCE_BREAKAGE,
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
  MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION,
  PACKAGE_AMENDMENT,
  REFUND_TEST_PURCHASE,
  VARIANT_OF_REFUND_ALL,
  VARIANT_OF_REFUND_PARTIAL,
} from '~/consts/refund';

import useCurrentUser from '~/hooks/useCurrentUser';

import { cancelOrder, getOrderRefundInfo } from '~/services/OrdersService';

import isOrderRefundable from '~/utils/isOrderRefundable';

import { CollectionReason, EnrichedRefundMetadata, RefundMetadata, RefundPayload, VendorRefundTypes } from '../types';

import ChangeOfMindWithinRefundGuaranteePeriod from './Pages/ChangeOfMindWithinRefundGuaranteePeriod';
import ExpiredBNBLPackage from './Pages/ExpiredBNBLPackage';
import FailedRefundFromPaymentGateways from './Pages/FailedRefundFromPaymentGateways';
import FraudComplianceAndDispute from './Pages/FraudComplianceAndDispute';
import InsuranceBreakage from './Pages/InsuranceBreakage';
import LateChangeOfMindBadExperienceOrUnfortunateEvents, {
  RefundTabs,
} from './Pages/LateChangeOfMindBadExperienceOrUnfortunateEvents';
import MisleadingContentIncorrectPricingAndCustomerRetention from './Pages/MisleadingContentIncorrectPricingAndCustomerRetention';
import PackageAmendment from './Pages/PackageAmendment';
import RefundConfirmation from './Pages/RefundConfirmation';
import RefundReasons from './Pages/RefundReasons';
import RefundTestPurchase from './Pages/RefundTestPurchase';
import {
  buildRefundPayload,
  convertStrOrBoolToBool,
  formatModalHeader,
  getDefaultValues,
  getItemType,
  getVendorContribution,
} from './Utils/RefundUtils';

interface Props {
  order: Partial<App.Order> | any;
  item: any;
  show: boolean;
  toggleRefundModal: () => void;
  roomId?: string;
  approver?: string;
  showModal: boolean;
}

export enum RefundSteps {
  Reason,
  Detail,
  Confirm,
}

interface RefundSubmitFormData {
  refundMethod: string;
  customerRefundNotification: string;
}

interface ChangeOfMindWithinRefundGuaranteePeriodFormData {
  comment: string;
  caseId: string;
}

interface InsuranceBreakageFormData {
  comment: string;
  caseId: string;
}

interface PackageAmendmentFormData {
  comment: string;
  caseId: string;
  variantOfRefund: string;
  refundAmountToCustomer: string;
  markCancelled: string;
  updateVccBalance: string;
}

interface MisleadingContentFormData {
  comment: string;
  caseId: string;
  variantOfRefund: string;
  refundAmountToCustomer: string;
  markCancelled: string;
  updateVccBalance: string;
}

interface FraudComplianceAndDisputeFormData {
  comment: string;
  caseId: string;
  markCancelled: string;
  variantOfRefund: string;
}

interface FailedRefundFromPaymentGatewaysFormData {
  comment: string;
  caseId: string;
  variantOfRefund: string;
  refundAmountToCustomer: string;
  markCancelled: string;
  updateVccBalance: string;
}

interface RefundTestPurchaseFormData {
  comment: string;
  caseId: string;
}

interface ExpiredBNBLPackageFormData {
  comment: string;
  caseId: string;
}

interface LateChangeOfMindFormData {
  comment: string;
  caseId: string;
  feeOrCustom: string;
  percentageOrNumber: string;
  refundFee?: string;
  percentageRefund?: string;
  percentageRefundToCustomer?: string;
  numberOfNights?: string;
  totalRefundedToCustomer?: string;
  markCancelled: string;
}

function generateLateChangeOfMindPayload(
  formData: LateChangeOfMindFormData,
  refundPayload: RefundPayload,
  refundMetadata: RefundMetadata,
) {
  const {
    comment,
    caseId,
    feeOrCustom,
    percentageOrNumber,
    refundFee,
    markCancelled,
    // percentage values
    percentageRefund,
    percentageRefundToCustomer,
    // select nights values
    numberOfNights,
    totalRefundedToCustomer,
  } = formData;

  const updatedPayload = {
    ...refundPayload,
    ticket_id: caseId,
    comment,
  };

  if (parseInt(feeOrCustom) === RefundTabs.FULL_REFUND_WITH_FEE) {
    // full refund with fee
    updatedPayload.accounting_metadata[0].refund_fee = parseFloat(refundFee);

    const updatedCashValue = refundMetadata.item_metadata.cash_amount - parseFloat(refundFee);
    /**
     * If we mark it cancelled, we want the entire amount to be taken from the order, such that vendor pays the full cost price
     * If we don't mark it cancelled, we want to leave some amount in the order and for the vendor to pay a proportional amount
     */
    if (convertStrOrBoolToBool(markCancelled)) {
      updatedPayload.accounting_metadata[0].accounting_amount = refundMetadata.item_metadata.cash_amount;
    } else {
      updatedPayload.accounting_metadata[0].accounting_amount = updatedCashValue;
    }

    updatedPayload.accounting_metadata[0].cash_amount = updatedCashValue;
    updatedPayload.amount = parseFloat(
      (
        refundMetadata.item_metadata.cash_amount +
        (refundMetadata.merchant_fee_amount ?? 0) -
        parseFloat(refundFee)
      ).toFixed(2),
    );
    updatedPayload.variant_of_refund = VARIANT_OF_REFUND_ALL;

    // unlikely these fields are used, keeping for posterity to match previous implementation
    updatedPayload.additional_info.refund_vendor_agrees_to = VendorRefundTypes.number;
    updatedPayload.additional_info.number_of_nights = refundMetadata.total_nights;
  } else {
    // custom refund
    updatedPayload.variant_of_refund = VARIANT_OF_REFUND_PARTIAL;
    updatedPayload.accounting_metadata[0].source = CUSTOM_REASON_SOURCE;
    if (percentageOrNumber === VendorRefundTypes.percentage) {
      // select percentage
      updatedPayload.additional_info.refund_vendor_agrees_to = VendorRefundTypes.percentage;
      updatedPayload.amount = parseFloat(percentageRefundToCustomer);
      updatedPayload.accounting_metadata[0].accounting_amount = parseFloat(percentageRefundToCustomer);
      updatedPayload.accounting_metadata[0].cash_amount = parseFloat(percentageRefundToCustomer);
      updatedPayload.additional_info.percentage = percentageRefund;
      // unused, keeping to match existing payload
      updatedPayload.additional_info.number_of_nights = refundMetadata.total_nights;
    } else {
      // select nights
      updatedPayload.additional_info.refund_vendor_agrees_to = VendorRefundTypes.number;
      updatedPayload.amount = parseFloat(totalRefundedToCustomer);
      updatedPayload.accounting_metadata[0].accounting_amount = parseFloat(totalRefundedToCustomer);
      updatedPayload.accounting_metadata[0].cash_amount = parseFloat(totalRefundedToCustomer);
      updatedPayload.additional_info.number_of_nights = numberOfNights;
    }
  }

  updatedPayload.mark_cancelled = convertStrOrBoolToBool(markCancelled);
  return updatedPayload;
}

export default function RefundV2UI({
  order,
  item,
  toggleRefundModal,
  roomId = undefined,
  approver = '',
  showModal,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();
  const [refundPayload, setRefundPayload] = useState<RefundPayload>();
  const [step, setStep] = useState(RefundSteps.Reason);
  const [isLoading, setIsLoading] = useState(false);
  const [refundMetadata, setRefundMetadata] = useState<EnrichedRefundMetadata>(undefined);
  const [reasonInfo, setReasonInfo] = useState<CollectionReason>();
  const [vendorContribution, setVendorContribution] = useState(0);
  // its objecting to LE but lE looks worse
  // eslint-disable-next-line react/hook-use-state
  const [LEProfit, setLEProfit] = useState(0);
  // eslint-disable-next-line react/hook-use-state
  const [LEContribution, setLEContribution] = useState(0);
  const isAccommodationRefundable = isOrderRefundable(order, {});
  const customerName = order.customer_full_name ?? `${order.customer_given_name} ${order.customer_surname}`;
  const currentUserName = user.user.fullName;

  function updateVendorContribution(finalRefundAmount: number, updatedVendorContribution: number) {
    const roundedVendorContribution = Number(updatedVendorContribution.toFixed(2));
    const difference = finalRefundAmount - roundedVendorContribution;

    setVendorContribution(roundedVendorContribution);
    if (difference >= 0) {
      setLEContribution(Number(difference.toFixed(2)));
      setLEProfit(0);
    } else {
      setLEProfit(Number(Math.abs(difference * -1).toFixed(2)));
      setLEContribution(0);
    }
  }

  const handleReasonSubmission = useCallback(
    async (selectedReason) => {
      setIsLoading(true);
      const refundMetadataResponse = await getOrderRefundInfo({
        orderId: order.id_orders,
        itemId: item.id,
        roomId: roomId,
        enriched: true,
        approver: approver,
        selectedReasonKey: selectedReason,
      });

      // update the default
      setReasonInfo(COLLECTION_REASONS[selectedReason]);
      setRefundMetadata(refundMetadataResponse);
      const defaultPayload = getDefaultValues(refundMetadataResponse, selectedReason, currentUserName, approver);
      setRefundPayload(defaultPayload);
      // set a default vendor contribution amount
      const updatedVendorContribution = getVendorContribution(refundMetadataResponse, defaultPayload, item);
      updateVendorContribution(defaultPayload.amount, updatedVendorContribution);
      setStep(step + 1);
      setIsLoading(false);
    },
    [order.id_orders, item, roomId, approver, currentUserName, step],
  );

  const handleClose = useCallback(() => {
    const confirmedClose = window.confirm('Are you sure? Data will be wiped out');
    if (confirmedClose) {
      setStep(RefundSteps.Reason);
      setRefundMetadata(undefined);
      setRefundPayload(undefined);
      toggleRefundModal();
    }
  }, [toggleRefundModal]);

  const incrementStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const decrementStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleChangeOfMindWithinRefundGuaranteePeriodSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { caseId, comment } = Object.fromEntries([
        ...formData,
      ]) as unknown as ChangeOfMindWithinRefundGuaranteePeriodFormData;

      const updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
      };
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleInsuranceBreakageSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { caseId, comment } = Object.fromEntries([...formData]) as unknown as InsuranceBreakageFormData;

      const updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
        amount: 0,
        merchant_fee_amount: 0,
      };

      updatedPayload.accounting_metadata[0].cash_amount = 0;
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handlePackageAmendmentSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { refundAmountToCustomer, variantOfRefund, markCancelled, caseId, comment, updateVccBalance } =
        Object.fromEntries([...formData]) as unknown as PackageAmendmentFormData;

      const updatedPayload: RefundPayload = {
        ...refundPayload,
        amount: refundAmountToCustomer ? parseFloat(refundAmountToCustomer) : refundPayload.amount,
        variant_of_refund: variantOfRefund,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
        update_vcc_balance: convertStrOrBoolToBool(updateVccBalance),
      };

      updatedPayload.accounting_metadata[0].cash_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : refundPayload.amount;
      updatedPayload.accounting_metadata[0].accounting_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : refundPayload.amount;
      setRefundPayload(updatedPayload);

      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleMisleadingContentSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      const formData = new FormData(e.currentTarget);
      const { refundAmountToCustomer, variantOfRefund, markCancelled, caseId, comment, updateVccBalance } =
        Object.fromEntries([...formData]) as unknown as MisleadingContentFormData;

      const updatedPayload: RefundPayload = {
        ...refundPayload,
        amount: refundAmountToCustomer ? parseFloat(refundAmountToCustomer) : refundPayload.amount,
        variant_of_refund: variantOfRefund,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
        update_vcc_balance: convertStrOrBoolToBool(updateVccBalance),
      };

      updatedPayload.accounting_metadata[0].cash_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : refundPayload.amount;
      updatedPayload.accounting_metadata[0].accounting_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : refundPayload.amount;
      setRefundPayload(updatedPayload);

      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleFraudComplianceAndDisputeSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { comment, caseId, markCancelled, variantOfRefund } = Object.fromEntries([
        ...formData,
      ]) as unknown as FraudComplianceAndDisputeFormData;

      const updatedPayload: RefundPayload = {
        ...refundPayload,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
      };

      updatedPayload.accounting_metadata[0].source = variantOfRefund;

      setRefundPayload(updatedPayload);
      incrementStep();
    },
    [incrementStep, refundPayload, setRefundPayload],
  );

  const handleFailedRefundFromPaymentGatewaysSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { refundAmountToCustomer, variantOfRefund, markCancelled, caseId, comment, updateVccBalance } =
        Object.fromEntries([...formData]) as unknown as FailedRefundFromPaymentGatewaysFormData;

      const updatedPayload: RefundPayload = {
        ...refundPayload,
        amount: refundAmountToCustomer ? parseFloat(refundAmountToCustomer) : refundPayload.amount,
        variant_of_refund: variantOfRefund,
        mark_cancelled: markCancelled === 'true',
        ticket_id: caseId,
        comment,
        update_vcc_balance: convertStrOrBoolToBool(updateVccBalance),
      };

      updatedPayload.accounting_metadata[0].cash_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : refundPayload.amount;
      updatedPayload.accounting_metadata[0].accounting_amount = refundAmountToCustomer
        ? parseFloat(refundAmountToCustomer)
        : refundPayload.amount;
      setRefundPayload(updatedPayload);

      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleRefundTestPurchaseSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { comment, caseId } = Object.fromEntries([...formData]) as unknown as RefundTestPurchaseFormData;

      const updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
      };
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleExpiredBNBLPackageSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { caseId, comment } = Object.fromEntries([...formData]) as unknown as ExpiredBNBLPackageFormData;

      const updatedPayload = {
        ...refundPayload,
        ticket_id: caseId,
        comment: comment,
      };
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleLateChangeOfMindSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const formObject = Object.fromEntries([...formData]) as unknown as LateChangeOfMindFormData;
      const updatedPayload = generateLateChangeOfMindPayload(formObject, refundPayload, refundMetadata);
      setRefundPayload(updatedPayload);
      const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
      updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      incrementStep();
    },
    [incrementStep, item, refundMetadata, refundPayload],
  );

  const handleRefundSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const { refundMethod, customerRefundNotification } = Object.fromEntries([
        ...formData,
      ]) as unknown as RefundSubmitFormData;
      const updatedPayload = {
        ...refundPayload,
        refund_method: refundMethod,
        send_customer_refund_notification: customerRefundNotification,
      };

      const mappedRefund = buildRefundPayload(updatedPayload);

      try {
        await cancelOrder(order.id_orders, item.id, mappedRefund);
        toggleRefundModal();
        location.reload();
      } catch (err) {
        enqueueSnackbar('Failed to process refund', err);
      }
    },
    [enqueueSnackbar, item.id, order.id_orders, refundPayload, toggleRefundModal],
  );

  const handleLateChangeOfMindFieldChange = useCallback(
    (formData) => {
      // try and update the refund payload, if it hits any errors we are assuming its because we don't have enough information
      try {
        const formObject = Object.fromEntries([...formData]) as unknown as LateChangeOfMindFormData;
        const updatedPayload = generateLateChangeOfMindPayload(formObject, refundPayload, refundMetadata);

        const updatedVendorContribution = getVendorContribution(refundMetadata, updatedPayload, item);
        updateVendorContribution(updatedPayload.amount, updatedVendorContribution);
      } catch (e) {
        // assume this means not enough of the form has been filled out
        // to successfully generate this value e.g comment, caseId
        console.warn(e);
      }
    },
    [item, refundMetadata, refundPayload],
  );

  return (
    <Dialog open={showModal} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>{formatModalHeader(step, refundMetadata)}</DialogTitle>
      {!isLoading && step === RefundSteps.Reason && (
        <RefundReasons
          itemType={getItemType(item)}
          handleReasonSubmission={handleReasonSubmission}
          isRefundable={isAccommodationRefundable}
          order={order}
        />
      )}
      {isLoading && <Spinner />}
      {!isLoading && step === RefundSteps.Detail && refundPayload && (
        <>
          {refundPayload?.reason === CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD && (
            <ChangeOfMindWithinRefundGuaranteePeriod
              decrementStep={decrementStep}
              onSubmit={handleChangeOfMindWithinRefundGuaranteePeriodSubmit}
              reason={reasonInfo}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === PACKAGE_AMENDMENT && (
            <PackageAmendment
              decrementStep={decrementStep}
              onSubmit={handlePackageAmendmentSubmit}
              reason={reasonInfo}
              defaultVccChecked={refundPayload.update_vcc_balance}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION && (
            <MisleadingContentIncorrectPricingAndCustomerRetention
              decrementStep={decrementStep}
              onSubmit={handleMisleadingContentSubmit}
              reason={reasonInfo}
              defaultVccChecked={refundPayload.update_vcc_balance}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS && (
            <LateChangeOfMindBadExperienceOrUnfortunateEvents
              refundMetadata={refundMetadata}
              decrementStep={decrementStep}
              onSubmit={handleLateChangeOfMindSubmit}
              reason={reasonInfo}
              item={item}
              currencyCode={order.currency_code}
              vendorContribution={vendorContribution}
              LEContribution={LEContribution}
              handleLateChangeOfMindFieldChange={handleLateChangeOfMindFieldChange}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === FRAUD_AND_COMPLIANCE_AND_DISPUTE && (
            <FraudComplianceAndDispute
              decrementStep={decrementStep}
              onSubmit={handleFraudComplianceAndDisputeSubmit}
              reason={reasonInfo}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === FAILED_REFUND_FROM_PAYMENT_GATEWAYS && (
            <FailedRefundFromPaymentGateways
              decrementStep={decrementStep}
              onSubmit={handleFailedRefundFromPaymentGatewaysSubmit}
              reason={reasonInfo}
              defaultVccChecked={refundPayload.update_vcc_balance}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === REFUND_TEST_PURCHASE && (
            <RefundTestPurchase
              decrementStep={decrementStep}
              onSubmit={handleRefundTestPurchaseSubmit}
              reason={reasonInfo}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === EXPIRED_BNBL_PACKAGE && (
            <ExpiredBNBLPackage
              decrementStep={decrementStep}
              onSubmit={handleExpiredBNBLPackageSubmit}
              reason={reasonInfo}
              defaultComment={refundPayload.comment}
            />
          )}
          {refundPayload?.reason === INSURANCE_BREAKAGE && (
            <InsuranceBreakage
              decrementStep={decrementStep}
              onSubmit={handleInsuranceBreakageSubmit}
              reason={reasonInfo}
              defaultComment={refundPayload.comment}
            />
          )}
        </>
      )}
      {!isLoading && step === RefundSteps.Confirm && (
        <RefundConfirmation
          currencyCode={order.currency_code}
          refundPayload={refundPayload}
          vendorContribution={vendorContribution}
          LEContribution={LEContribution}
          LEProfit={LEProfit}
          onSubmit={handleRefundSubmit}
          decrementStep={decrementStep}
          refundMethods={reasonInfo.defaults.methods}
          customerName={customerName}
          defaultCustomerRefundNotification={reasonInfo.defaults.send_customer_refund_notification}
        />
      )}
    </Dialog>
  );
}
