/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import classnames from 'clsx';
import currencyFormatter from 'currency-formatter';
import pluralize from 'pluralize';
import styled from 'styled-components';

import { Alert } from '@mui/material';

import { ExtendedRegion } from '@luxuryescapes/lib-regions/lib/extended';

import { PACKAGE_STATUS_APPROVED } from '~/consts/package';

import validateOfferSchedule from '../../../../utils/validateOfferSchedule';
import OfferLocationHeading from '../../../Common/Booking/OfferLocationHeading';
import OrderFooter from '../OrderFooter';

import GroupedPackages from './GroupedPackages';

const PackageListBox = styled.div`
  border: solid 1px;
  border-color: #d6d6d6;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 16px;
`;

interface Props {
  onAddOfferToCart: (pkg: App.Package) => void;
  onRemoveFromCart: (packageId: string, offer: App.CartOffer) => unknown;
  backStep: () => void;
  offer: App.CartOffer;
  onChangeRegionCode: (regionCode: string) => void;
  cart: App.Cart;
  nextStepLabel: string;
  currencyCode: string;
  packages: App.Package[];
  getPackagePrice: (pkg: App.Package) => number;
  getPackageQuantity: (pkgId: string, offer: App.CartOffer) => number;
  onProceedStep: () => void;
  regions: ExtendedRegion[];
  disableChangeCurrency: boolean;
  creatingOrder: boolean;
  brand: string;
  items: App.CartItem[];
  offerNumber: number;
}

const PackageSelection = ({
  onAddOfferToCart,
  onRemoveFromCart,
  backStep,
  offer,
  onChangeRegionCode,
  cart,
  nextStepLabel,
  currencyCode,
  packages,
  getPackagePrice,
  getPackageQuantity,
  onProceedStep,
  regions,
  disableChangeCurrency,
  creatingOrder,
  brand,
  items,
  offerNumber,
}: Props) => {
  if (!validateOfferSchedule(offer, brand)) {
    return <Alert severity="warning">This offer is not scheduled to be available for purchase.</Alert>;
  }

  return (
    <div className="container">
      {creatingOrder && <div className="alert alert-info">Processing...</div>}
      {offerNumber === 1 && (
        <>
          <label htmlFor="currency-selection">Order Region / Currency</label>
          <select
            disabled={disableChangeCurrency}
            id="currency-selection"
            className="form-control"
            value={cart.regionCode}
            onChange={(e) => {
              const val = e.target.value;
              onChangeRegionCode(val);
            }}
          >
            {regions.map((region) => {
              return (
                <option key={region.code} value={region.code}>
                  {`${region.name} (${region.currencyCode})`}
                </option>
              );
            })}
          </select>
        </>
      )}
      {window.configs.BOOKING_FLOW_V2 === 'true' && offer.type === 'hotel' && (
        <GroupedPackages
          currencyCode={currencyCode}
          getPackagePrice={getPackagePrice}
          offer={offer}
          offerNumber={offerNumber}
          onAddOfferToCart={onAddOfferToCart}
          onRemoveFromCart={onRemoveFromCart}
        />
      )}
      {(window.configs.BOOKING_FLOW_V2 !== 'true' || offer.type !== 'hotel') && (
        <PackageListBox>
          <OfferLocationHeading offerNumber={offerNumber} offer={offer}></OfferLocationHeading>
          <table className="table table-striped table-hover T-package-table">
            <thead>
              <tr>
                {offer.type === 'hotel' && <th>Length of stay</th>}
                <th>Package</th>
                <th>From Price</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {packages
                .filter((pkg) => pkg.status === PACKAGE_STATUS_APPROVED)
                .map((pkg) => {
                  const packagePrice = getPackagePrice(pkg);
                  let displayPackagePrice = ' Unavailable';
                  if (packagePrice) {
                    displayPackagePrice = currencyFormatter.format(packagePrice, {
                      code: currencyCode,
                    });
                  }

                  const packageQuantity = getPackageQuantity(pkg.id_salesforce_external ?? pkg.le_package_id, offer);

                  return (
                    <tr
                      className={classnames('booking-form')}
                      key={(pkg.id_salesforce_external ?? pkg.le_package_id) + offer.offerCartId}
                    >
                      {offer.type === 'hotel' && (
                        <td className="column-nights data-hj-whitelist">
                          {pluralize('night', (pkg as App.AccommodationPackage).number_of_nights, true)}
                        </td>
                      )}
                      <td className="data-hj-whitelist">{pkg.name}</td>
                      <td>
                        {currencyCode}
                        <span className="data-hj-whitelist">{displayPackagePrice}</span>
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control quantity-value data-hj-whitelist"
                          value={packageQuantity}
                          disabled
                        />
                        <button
                          onClick={() => {
                            onRemoveFromCart(pkg.id_salesforce_external ?? pkg.le_package_id, offer);
                          }}
                          className="quantity-btn"
                          disabled={packageQuantity < 1}
                        >
                          &mdash;
                        </button>
                        <button
                          onClick={() => {
                            onAddOfferToCart(pkg);
                          }}
                          className="quantity-btn T-quantity-plus"
                        >
                          +
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </PackageListBox>
      )}

      <OrderFooter
        cart={cart}
        proceedStep={onProceedStep}
        canProceed={items.length > 0 && !creatingOrder}
        backStep={backStep}
        nextStepLabel={nextStepLabel}
      />
    </div>
  );
};

export default PackageSelection;
