import styled from 'styled-components';

import { themes } from '../../../../consts/experiences';
import { Spacing, margin, padding } from '../../helpers';

type ButtonProps = {
  color?: string;
  background?: string;
  borderColor?: string;
  disabled?: boolean;
} & Spacing;

export const ButtonWrapper = styled.div`
  width: 100%;
`;

export const Button = styled.div<ButtonProps>`
  width: 100%;
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  border-radius: 10px;
  white-space: nowrap;

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr }, '10px 24px')};

  color: ${({ color }) => color || themes?.white};
  background-color: ${({ background, disabled }) => (disabled ? themes.disabled : background || themes?.primaryBlue)};

  :hover {
    opacity: 0.8;
  }
`;

export const OutlineButton = styled(Button)<ButtonProps>`
  background-color: transparent;

  color: ${({ borderColor }) => borderColor || themes?.primaryBlue};
  border: 1px solid ${({ borderColor }) => borderColor || themes?.primaryBlue};

  :hover {
    color: ${themes?.white};
    background-color: ${({ borderColor }) => borderColor || themes?.primaryBlue};
  }
`;
