// Copied from lib-partnerships as we have turned off Qantas but still need to display reports on AP.
export const QantasPartnership = {
  accountFields: ['account_id', 'last_name'],
  accountFieldsLabels: {
    account_id: 'Qantas Number',
    last_name: 'Qantas Last Name',
  },
  bonusPointCost: 1,
  bonusUnit: 'point',
  brandName: 'Qantas',
  code: 'qantas',
  color: '#E5242A',
  confirmationText: 'Your Qantas Points will land in your Frequent Flyer account in 30 days.',
  currencyCodes: ['AUD'],
  hasBurn: true,
  hasEarn: true,
  icon: 'Qantas_2x_f6vhzx',
  iconReversed: 'qantas_logo_reversed',
  joinUrl: 'https://www.qantaspoints.com/join-now?code=LUXURYESCAPES',
  landingPage: 'qantas-frequent-flyer',
  landingPageLogo: 'Qantas-Luxury-Escapes_z3mtse',
  numberMaxLength: 14,
  prefix: 'qff',
  programLogo: 'QffLogo_2x_i99mv5',
  programName: 'Qantas frequent flyer',
  regionCodes: ['AU'],
  reverseLogo: 'QffLogoReverse_2x_sxllsy',
  rewardConversion: 1,
  rewardCurrency: 'AUD',
  rewardEarn: 1,
  rewardName: 'Qantas Points',
  rewardPer: '$1',
  rewardProgramName: 'Qantas',
};
