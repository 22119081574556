import React from 'react';

import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface Props {
  offers: Array<App.Offer>;
  onSelectOffer: (id: string) => void;
  selectedOffer: null | string;
}

export function OfferListSelection({ offers, onSelectOffer, selectedOffer }: Props) {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box
        sx={{
          maxHeight: 375,
          overflow: 'auto',
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="simple dense table" size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Opportunity name</TableCell>
                <TableCell>Salesforce ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map((offer) => (
                <TableRow
                  key={offer.id_salesforce_external}
                  sx={{ width: 'auto' }}
                  onClick={() => onSelectOffer(offer.id_salesforce_external)}
                >
                  <TableCell>
                    <Checkbox color="primary" sx={{ p: 0 }} checked={offer.id_salesforce_external === selectedOffer} />
                  </TableCell>
                  <TableCell>{offer.opportunity_name || offer.name}</TableCell>
                  <TableCell>{offer.id_salesforce_external}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
