import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField } from '@mui/material';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import Spinner from '~/components/Common/Spinner';
import InputCruiseLineSelect from '~/components/Cruises/components/Selects/InputCruiseLineSelect';
import { CruiseInclusion } from '~/components/Cruises/pages/Inclusions/types';

import { formatDateISO } from '~/services/TimeService';
import inclusionsService from '~/services/cruises/InclusionsService';

export const TYPES: { [key: string]: CruiseInclusion['type'] } = {
  STANDARD: 'STANDARD',
  LUX_EXCLUSIVE: 'LUX_EXCLUSIVE',
  LUX_PLUS: 'LUX_PLUS',
};

const INITIAL_FORM_STATE: Partial<CruiseInclusion> = {
  name: '',
  startDate: null,
  endDate: null,
  type: TYPES.STANDARD,
  fkVendorId: null,
};

type Props = {
  inclusion?: CruiseInclusion;
};

function InclusionForm({ inclusion }: Props) {
  const [status, setStatus] = useState<string>();
  const [formValues, setFormValues] = useState<Partial<CruiseInclusion>>(INITIAL_FORM_STATE);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const isSaveEnabled = useMemo(
    () =>
      formValues.name &&
      formValues.startDate &&
      formValues.endDate &&
      formValues.type &&
      formValues.fkVendorId &&
      status !== 'loading',
    [formValues, status],
  );

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target?.name) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value ?? undefined,
        });
      }
    },
    [formValues],
  );

  const handleChangeDateField = useCallback(
    (value: string, name: 'startDate' | 'endDate') => {
      setFormValues({ ...formValues, [name]: formatDateISO(value) });
    },
    [formValues],
  );

  const handleSubmit = useCallback(async () => {
    const isEditing = !!inclusion;
    let inclusionId = inclusion?.id;
    setStatus('loading');

    try {
      if (isEditing) {
        await inclusionsService.update(inclusion.id, formValues);
      } else {
        const newInclusion = await inclusionsService.create(formValues);
        inclusionId = newInclusion.id;
      }

      setStatus('success');
      enqueueSnackbar('Inclusion saved with success', { variant: 'success' });
      history.push(`/cruises/inclusions/edit/${inclusionId}/items`);
    } catch (error) {
      console.error(error);
      setStatus('error');
      enqueueSnackbar('Error saving inclusion', { variant: 'error' });
    }
  }, [formValues, history, enqueueSnackbar, inclusion]);

  useEffect(() => {
    if (inclusion) {
      setFormValues({
        name: inclusion.name,
        startDate: inclusion.startDate,
        endDate: inclusion.endDate,
        type: inclusion.type,
        fkVendorId: inclusion.fkVendorId,
      });
    }
  }, [inclusion]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            name="name"
            label="Inclusion Name"
            onChange={handleChangeField}
            inputProps={{ maxLength: 50 }}
            value={formValues.name}
            helperText="The maximum length is 50 characters."
          />
        </Grid>
        <Grid item xs={6}>
          <InputCruiseLineSelect
            label="Cruise line"
            value={formValues.fkVendorId}
            onChange={(cruiseLine) => setFormValues({ ...formValues, fkVendorId: cruiseLine.id })}
          />
        </Grid>
      </Grid>

      <Stack mt={2} spacing={2} direction="row">
        <DateTimeWidget
          disablePast
          showDateOnly
          format="YYYY/MM/DD"
          label="Inclusion Start Date *"
          value={formValues.startDate}
          onChange={(value) => handleChangeDateField(value, 'startDate')}
        />

        <DateTimeWidget
          disablePast
          showDateOnly
          format="YYYY/MM/DD"
          label="Inclusion End Date *"
          value={formValues.endDate}
          onChange={(value) => handleChangeDateField(value, 'endDate')}
        />
      </Stack>

      <Box mt={2}>
        <FormControl>
          <RadioGroup
            defaultValue={TYPES.STANDARD}
            name="type"
            value={formValues.type}
            onChange={handleChangeField}
            row
          >
            <FormControlLabel value={TYPES.STANDARD} control={<Radio />} label="Standard" />
            <FormControlLabel value={TYPES.LUX_EXCLUSIVE} control={<Radio />} label="Lux Exclusive" />
            <FormControlLabel value={TYPES.LUX_PLUS} control={<Radio />} label="Lux Plus" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleSubmit} disabled={!isSaveEnabled}>
          {status === 'loading' && <Spinner size={15} />}
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default InclusionForm;
