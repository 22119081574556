import React from 'react';

import { Link } from '@mui/material';

import CopyableField from '~/components/Common/CopyableField';

type PromoCodeNameProps = {
  codeName: string;
};

function PromoCodeName({ codeName }: PromoCodeNameProps) {
  return (
    <CopyableField
      value={codeName}
      label={
        <Link noWrap href={`/promos/code/${codeName}`} title={codeName}>
          {codeName}
        </Link>
      }
    />
  );
}

export { PromoCodeName };
