import React from 'react';

import TenantTitleLink from './TenantTitleLink';

type Props = {
  tenant: App.Tenant;
  userTenant: App.Tenant;
};

const TenancyWarningMessage = ({ tenant, userTenant }: Props) => {
  return (
    <>
      This user is a tenant of <TenantTitleLink tenant={userTenant} />, but not <strong>{tenant.title}</strong>. Please
      view the customer's account details in the site where they are a tenant to unlock additional actions.
    </>
  );
};

export default TenancyWarningMessage;
