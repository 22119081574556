import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Button } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

export const getFDATableActionButtons = (row, onDelete: (id: string) => void, onEdit: (id: string) => void) => {
  return (
    <Box display="flex" gap={1}>
      <Button variant="outlined" onClick={() => onEdit(row.Id)} style={{ minWidth: '40px', padding: 0 }}>
        <EditIcon />
      </Button>

      <ConfirmButton
        confirmTitle="Delete record"
        confirmQuestion="Are you sure you want to delete?"
        confirmAnswer="Yes, delete"
        onConfirm={() => onDelete(row.Id)}
        color="error"
        variant="outlined"
        size="small"
        style={{ minWidth: '40px' }}
      >
        <GridDeleteIcon />
      </ConfirmButton>
    </Box>
  );
};

export const getFDATableIDButton = (row, onView: (id: string) => void) => {
  return (
    <Button variant="text" onClick={() => onView(row.Id)}>
      {row.Id}
    </Button>
  );
};
