import React, { PropsWithChildren, useState } from 'react';

import { Alert, Box, Divider, Stack, Typography } from '@mui/material';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { AuditLog as IAuditLog } from '~/types/responses';

import { TraverseObject } from '../DebugModal/utils';

type Props = PropsWithChildren<{
  auditLog: IAuditLog;
  showFull?: boolean;
}>;

const ActionMapLabels: Record<string, 'info' | 'success' | 'warning' | 'error'> = {
  CREATE: 'success',
  UPDATE: 'info',
  DELETE: 'error',
};

export function AuditLog({ auditLog, children }: Props) {
  const [showFull, setShowFull] = useState(false);
  const { models, user_email, meta, payload, request_id, id } = auditLog;
  const hasModels = models && models.length > 0;
  const modelId =
    payload?.params?.modelId ||
    payload?.params?.id ||
    payload?.params?.model_id ||
    payload?.params?.model_id ||
    payload?.params?.modelId;
  return (
    <>
      <Divider orientation="horizontal" />
      <Stack spacing={1} paddingLeft="1em">
        <Stack
          direction="row"
          spacing={2}
          justifyContent={showFull && 'space-between'}
          alignItems="center"
          onClick={() => setShowFull(!showFull)}
          sx={{
            cursor: 'pointer',
          }}
          title={showFull ? 'Hide Details' : 'Show Details'}
        >
          <Typography>{formatDateOrdinalWithClock(auditLog.timestamp)}</Typography>
          <Typography variant="caption">
            <Alert
              icon={false}
              variant="outlined"
              severity={ActionMapLabels[auditLog.action] ?? 'info'}
              sx={{
                padding: '0.5em',
              }}
            >
              {auditLog.action}
            </Alert>
          </Typography>
          <Typography>{user_email}</Typography>
          {!showFull && models && models.length > 0 && (
            <Typography sx={{ border: '1px solid #ddd', padding: '0.5rem 1rem' }}>
              {models.length} Model Change{models.length > 1 ? 's' : ''}
            </Typography>
          )}
          {showFull && (
            <>
              {modelId && <Typography>ModelId: {modelId}</Typography>}
              <Typography>Request: {request_id}</Typography>
            </>
          )}
        </Stack>
        {showFull && (
          <Stack>
            <Typography variant="h6">Payload</Typography>
            {auditLog.url && <Typography>{auditLog.url}</Typography>}
            <TraverseObject obj={payload} />
            {meta && (
              <>
                <Typography variant="h6">Meta</Typography>
                <TraverseObject obj={meta} />
              </>
            )}
            {hasModels && (
              <>
                <Typography variant="h6">Model Changes</Typography>
                <TraverseObject obj={models} />
              </>
            )}
            <Box paddingLeft="2em">{children}</Box>
          </Stack>
        )}
      </Stack>
    </>
  );
}
