import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerCadences from '~/queries/customerCommunication/requestGetHeroPlannerCadences';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type CadencesParams = NonNullable<
  ValueOf<
    CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitySegmentCadencesRecords']
  >['params']
>;

const fetchHeroPlannerCadences =
  (
    brandId: string,
    countryGroupId: string,
    countryId: string,
    stateId: string,
    cityId: string,
    segmentId: string,
  ): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();

    const params = [brandId, countryGroupId, countryId, stateId, cityId, segmentId] as CadencesParams;
    const key = getRecordKeyFor(...params);
    const existing = appState.customerCommunication.countryGroupCountryStateCitySegmentsRecords[key];
    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FETCHING,
        params,
      });
      try {
        const cadences = await requestGetHeroPlannerCadences(...params);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FULFILLED,
          params,
          cadences,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerCadences;
