import React, { useCallback, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

interface Props {
  openModal: boolean;
  closeModal: () => void;
  promoId: string;
  promoName: string;
  expirePromo: (promoId: string) => void | Promise<void>;
}

const ExpirePromoModal = ({ openModal, closeModal, promoId, promoName, expirePromo }: Props) => {
  const [isProcessing, setProcessing] = useState(false);

  const handleExpirePromo = useCallback(() => {
    try {
      setProcessing(true);
      return expirePromo(promoId);
    } catch (err) {
      setProcessing(false);
    }
  }, [promoId, expirePromo]);

  return (
    <Dialog open={openModal} onClose={closeModal}>
      <DialogTitle>Expire Promo Code - {promoName}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography>Are you sure you want to expire this promotion code?</Typography>

          <Typography>You can't undo this action</Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeModal} disabled={isProcessing}>
          Cancel
        </Button>
        <Button onClick={handleExpirePromo} disabled={isProcessing} color="primary" variant="contained">
          Expire
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpirePromoModal;
