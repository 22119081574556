import React from 'react';

import { useHistory } from 'react-router';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateWithClock } from '~/services/TimeService';

import GridPagination from '../Common/Elements/GridPagination';

type User = {
  id_account: string;
  email: string;
  roles: string;
  inline_role_label: string;
  last_login: string;
  full_name: string;
};

type Props = {
  role: string;
  isLoading: boolean;
  users: User[];
  page: number;
  sizePerPage: number;
  total: number;
  onPageChange: (page: number) => void;
};

const columns: GridColDef[] = [
  { field: 'id_account', headerName: 'ID', width: 350, display: 'flex' },
  { field: 'full_name', headerName: 'Name', flex: 1, display: 'flex' },
  { field: 'email', headerName: 'Email', flex: 2, display: 'flex' },
  {
    field: 'last_login',
    headerName: 'Last Login',
    width: 200,
    valueFormatter: (value) => (value ? formatDateWithClock(value) : ''),
    display: 'flex',
  },
];

export default function PermissionsUsers({ isLoading, users, page, sizePerPage, total, onPageChange }: Props) {
  const history = useHistory();

  const handleRowClick = ({ row }) => {
    if (row.id_account) {
      history.push('/system/users/' + row.id_account);
    }
  };

  return (
    <DataGrid
      columns={columns}
      rows={users || []}
      rowCount={total}
      loading={isLoading}
      getRowId={(row) => row.id_account}
      pagination
      paginationMode="server"
      paginationModel={{ page, pageSize: sizePerPage }}
      pageSizeOptions={[sizePerPage]}
      slots={{ pagination: GridPagination }}
      onPaginationModelChange={({ page }) => onPageChange(page)}
      onRowClick={handleRowClick}
      disableColumnFilter
      disableColumnMenu
      autoHeight
    />
  );
}
