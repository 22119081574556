import React from 'react';

import { Stack } from '@mui/material';

import AccommodationPropertyRoomsResultsTable from './AccommodationPropertyRoomsResultsTable';

export default function AccommodationPropertyRoomsSection() {
  return (
    <Stack direction="column" gap={4}>
      <AccommodationPropertyRoomsResultsTable />
    </Stack>
  );
}
