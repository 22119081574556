import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Styled from 'styled-components';

import ImagesForm from '~/components/Common/Forms/ImagesForm';
import Spinner from '~/components/Common/Spinner';

import offerService from '~/services/cruises/OfferService';

const Container = Styled.div`
  .image-title-input {
    display: none;
  }
`;

const CruisePageImages = () => {
  const [isReady, setIsReady] = useState(false);
  const [images, setImages] = useState([]);

  const { offerId } = useParams<{
    offerId: string;
  }>();

  const fetchCruiseDetails = async () => {
    const { result: images } = await offerService.getOfferImages(offerId);

    setImages(
      images
        .map((image) => ({
          ...image,
          id: image.id,
          publicImageId: image.imageId,
        }))
        .sort((a, b) => a.order - b.order),
    );
    setIsReady(true);
  };

  const onAddImage = async (imageId: string) => {
    const maxOrder = Math.max(...images.map((image) => image.order));
    const order = images.length > 0 && maxOrder > 0 ? maxOrder + 1 : 1;

    return offerService.createOfferImage(offerId, { imageId, order });
  };

  const onUpdateImages = (newImageList) => {
    const updatePayload = newImageList.map((image) => ({
      id: image.id,
      imageId: image.imageId,
      order: image.order,
    }));

    return offerService.updateOfferImages(offerId, updatePayload);
  };

  const onDeleteImage = (imageId) => {
    return offerService.deleteOfferImage(offerId, imageId);
  };

  useEffect(() => {
    fetchCruiseDetails();
  }, []);

  return (
    <Container className="offer-image-edit container">
      <Helmet>
        <title>Offers | Edit Offer Images</title>
      </Helmet>
      <Link to={`/cruises/offer/${offerId}`} className="btn btn-default">
        Return to offers
      </Link>
      <h1 className="page-header">Edit Offer Images</h1>
      {isReady && (
        <div>
          <ImagesForm
            images={images}
            onAddImage={onAddImage}
            onUpdateImages={onUpdateImages}
            onDeleteImage={onDeleteImage}
          />
        </div>
      )}
      {!isReady && <Spinner />}
    </Container>
  );
};

export default CruisePageImages;
