import React from 'react';

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { GridCellProps } from '@mui/x-data-grid';

type Props = {
  row: GridCellProps;
};

export function TopicQualityBarChart({ row }: Props) {
  const rowQualitiesSum = row.qualities.HIGH + row.qualities.MEDIUM + row.qualities.LOW;

  return (
    <ResponsiveContainer height={50} width="100%">
      <BarChart
        layout="vertical"
        margin={{ top: 20 }}
        data={[
          {
            name: 'Quality',
            high: row.qualities.HIGH / rowQualitiesSum,
            medium: row.qualities.MEDIUM / rowQualitiesSum,
            low: row.qualities.LOW / rowQualitiesSum,
          },
        ]}
      >
        <XAxis type="number" hide />
        <YAxis type="category" hide />

        {rowQualitiesSum > 0 ? (
          <>
            <Bar dataKey="high" stackId="a" fill="#90e08a" />
            <Bar dataKey="medium" stackId="a" fill="#ebe773" />
            <Bar dataKey="low" stackId="a" fill="#ff8e8e" />
          </>
        ) : (
          <Bar dataKey="no_data" stackId="a" fill="#FFFFFF" />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
