import { useState } from 'react';

import { buttonStates } from '~/components/Common/Forms/states/muiSubmitButton';

import OffersService from '../../../../services/OffersService';
import { defaultEndDate, defaultStartDate } from '../../../../utils/DefaultDate';

export default function useScheduleUpdate({ offerId }) {
  const [schedule, setSchedule] = useState({
    start: defaultStartDate(),
    end: defaultEndDate(),
  });

  const [startUpdated, setStartUpdated] = useState(false);
  const [endUpdated, setEndUpdated] = useState(false);
  const [saveState, setSaveState] = useState(buttonStates.default);

  const updateStart = (start: string) => {
    setSchedule((prevState) => ({
      ...prevState,
      start,
    }));
    setStartUpdated(true);
  };

  const updateEnd = (end: string) => {
    setSchedule((prevState) => ({
      ...prevState,
      end,
    }));
    setEndUpdated(true);
  };

  const onReset = () => {
    setSchedule({
      start: defaultStartDate(),
      end: defaultEndDate(),
    });
    setSaveState(buttonStates.default);
    setStartUpdated(false);
    setEndUpdated(false);
  };

  const updateSchedule = async (schedules: App.Schedule[]) => {
    const idList = schedules.map((r) => r.id);
    const updatePayload = {
      idList,
      start: schedule.start,
      end: schedule.end,
    };

    return OffersService.updateScheduleList(updatePayload, offerId);
  };

  return {
    schedule,
    setSchedule,
    startUpdated,
    setStartUpdated,
    endUpdated,
    setEndUpdated,
    saveState,
    setSaveState,
    updateStart,
    updateEnd,
    onReset,
    updateSchedule,
  };
}
