import React from 'react';

import styled from 'styled-components';

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import Image from '~/components/Common/Image';

const PreviewImage = styled(Image)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: #ccc;
`;

interface FileDefinition {
  source: 'file';
  objectUrl: string; // use URL.createObjectUrl(file)
  filename?: string;
}

interface SvcImageDefinition {
  source: 'svc-image';
  publicId: string;
}

export type ImagePreviewDefinition = FileDefinition | SvcImageDefinition;

interface Props {
  imagePreviewDefinition?: ImagePreviewDefinition;
  onDismiss: () => void;
}

export default function ImagePreviewModal(props: Props) {
  const { imagePreviewDefinition, onDismiss } = props;

  if (!imagePreviewDefinition) return null;

  return (
    <Dialog open={!!imagePreviewDefinition} onClose={onDismiss}>
      {imagePreviewDefinition.source === 'svc-image' && <PreviewImage publicId={imagePreviewDefinition.publicId} />}
      {imagePreviewDefinition.source === 'file' && (
        <>
          <img src={imagePreviewDefinition.objectUrl} />
          <DialogContent>
            <Typography variant="caption">{imagePreviewDefinition.filename}</Typography>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={onDismiss}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
}
