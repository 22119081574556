import React from 'react';

import { Typography } from '@mui/material';

import LengthsOfStayCalendar from './LengthsOfStayCalendar';

export default function LengthsOfStay(props) {
  const { lengthsOfStay } = props;

  return (
    <div className="LengthsOfStayContainer">
      <Typography textTransform="uppercase" color="secondary">
        Room Rate Lengths of Stay
      </Typography>

      {!Array.isArray(lengthsOfStay) || lengthsOfStay.length === 0 ? (
        <p>
          <i>No Lengths of stay set</i>
        </p>
      ) : (
        <>
          <LengthsOfStayCalendar lengthsOfStay={lengthsOfStay} />
        </>
      )}
    </div>
  );
}
