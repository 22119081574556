import React, { useEffect, useMemo } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, FormControl, MenuItem, Select, Stack } from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import ReservationService from '~/services/ReservationService';

import { formatError } from '~/utils/errorFormatter';
import isContentEmpty from '~/utils/isContentEmpty';

import CuratedFieldPane from './CuratedFieldPane';

interface Props {
  property: Reservation.Property;
  channelPropertyData: Reservation.Property;
  fetchData: () => Promise<void>;
}

const LANGUAGE_ID_ENGLISH = 1;

export default function CuratedContent(props: Props) {
  const { property, channelPropertyData, fetchData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = React.useState(false);
  const [propertyMeta, setPropertyMeta] = React.useState<Partial<Reservation.Property> | null>(null);
  const [updatedFields, setUpdatedFields] = React.useState<any>({});
  const saveDisabled = useMemo(() => {
    return isSaving || isContentEmpty(updatedFields);
  }, [isSaving, updatedFields]);

  const doUpdate = async () => {
    if (Object.values(updatedFields).length === 0) {
      return;
    }
    try {
      setIsSaving(true);
      if (propertyMeta) {
        await ReservationService.updatePropertyMeta(property.id, updatedFields);
      } else {
        await ReservationService.createPropertyMeta(property.id, updatedFields);
      }
      setIsSaving(false);
      enqueueSnackbar('Saved', {
        variant: 'success',
      });
      await fetchMetaData();
      setUpdatedFields({});
      return fetchData();
    } catch (err) {
      setIsSaving(false);
      enqueueSnackbar(formatError(err), {
        variant: 'error',
      });
    }
  };

  const fetchMetaData = async () => {
    try {
      const propertyMetaResponse = await ReservationService.getPropertyMeta(property.id);
      setPropertyMeta(propertyMetaResponse.result);
    } catch (err) {
      // may not exist yet for this property
      if (err.status !== 404) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      }
      setPropertyMeta(null);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);

  const updateDirtyFields = (fieldName: string, value: any) => {
    setUpdatedFields({ ...updatedFields, [fieldName]: value });
  };

  // reference data filtering
  const filteredDescriptionReference =
    channelPropertyData?.channel_extra_data?.descriptions?.reduce((acc, d) => {
      // Skip all non english languages
      if (d.languageId !== LANGUAGE_ID_ENGLISH) {
        return acc;
      }

      for (const [key, value] of Object.entries(d)) {
        if (['languageId', 'image'].includes(key)) {
          continue;
        }
        acc[key] = Object.prototype.hasOwnProperty.call(acc, key) ? acc[key] + '<br/>' + value : value;
      }
      return acc;
    }, {} as any) ?? {};

  return (
    <>
      <PageSubheader title="Curated Content" />

      <Stack gap={1}>
        <CuratedFieldPane
          fieldName="content_description"
          customTitle="Property Description"
          initialValue={property.content_description}
          aiContext={{
            model: 'offer',
            field: 'description',
            propertyName: property.name,
          }}
          referenceData={filteredDescriptionReference}
          onChange={(value) => updateDirtyFields('content_description', value)}
          isSaving={isSaving}
          isSingleProperty={false}
          markdownPreviewAPIData={true}
        />
        <CuratedFieldPane
          fieldName="fine_print"
          initialValue={property.fine_print}
          aiContext={{
            model: 'offer',
            field: 'description',
            propertyName: property.name,
          }}
          referenceData={{ termsAndConditions: channelPropertyData?.channel_extra_data?.termsConditions }}
          onChange={(value) => updateDirtyFields('fine_print', value)}
          isSaving={isSaving}
          isSingleProperty={false}
          showAIGeneratorButton={false}
        />
        <CuratedFieldPane
          fieldName="deposits"
          initialValue={property.deposits}
          aiContext={{
            model: 'offer',
            field: 'description',
            propertyName: property.name,
          }}
          referenceData={channelPropertyData?.channel_extra_data?.deposits.map((deposit) => deposit.description)}
          onChange={(value) => updateDirtyFields('deposits', value)}
          isSaving={isSaving}
          isSingleProperty={false}
          showAIGeneratorButton={false}
        />
        <CuratedFieldPane
          fieldName="house_rules"
          initialValue={property.house_rules}
          aiContext={{
            model: 'offer',
            field: 'description',
            propertyName: property.name,
          }}
          referenceData={{ houseRules: channelPropertyData?.channel_extra_data?.houseRules }}
          onChange={(value) => updateDirtyFields('house_rules', value)}
          isSaving={isSaving}
          isSingleProperty={false}
          showAIGeneratorButton={false}
        />

        <PageSubheader title={'Check In Type'}></PageSubheader>

        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="select-label"
              id="simple-select"
              defaultValue={property.check_in_type}
              label="Check In Type"
              onChange={(event) => updateDirtyFields('check_in_type', event.target.value)}
            >
              <MenuItem value={'meet-and-greet'}>Meet and Greet</MenuItem>
              <MenuItem value={'self'}>Self Check In</MenuItem>
              <MenuItem value={''}>Unspecified</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button sx={{ m: 2 }} disabled={saveDisabled} variant="contained" onClick={() => doUpdate()}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Stack>
    </>
  );
}
