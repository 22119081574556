import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'clsx';

import { FormGroup, TextField } from '@mui/material';

import BedbankService from '~/services/BedbankService';
import SearchService, { TypeaheadResult } from '~/services/SearchService';

export interface Result {
  id?: string;
  name: string;
  type: string;
  secondary: string;
  fk?: string;
}

interface LETypeheadResult extends Omit<TypeaheadResult, 'metadata'> {
  metadata: {
    offer_id: string;
    offer_type: string;
    offer_discount: number;
  };
}

interface Props {
  onSelect: (selection: Result, id: string) => void;
  label: string;
  id: string;
  type: string;
}

export const HotelSearchForm = (props: Props) => {
  const { onSelect, id, type, label } = props;

  const [results, setResults] = useState<Result[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const search = async (query: string) => {
    setValue(query);
    setOpen(true);
    setResults([]);
  };

  const bedbankSearch = useCallback(async () => {
    if (value.length < 2) {
      setOpen(false);
      setResults([]);
      return;
    }

    if (type === 'le') {
      const res = await SearchService.typeahead(value, {
        type: ['le_property', 'le_property_unique_stays'] as ('le_property' | 'le_property_unique_stays')[],
        brand: 'luxuryescapes',
        region: 'AU',
        limit: 20,
        priority: 'current',
      });

      setResults(
        res.result.results.map((result: LETypeheadResult) => ({
          name: result.primary_text,
          type: result.type,
          secondary: result.secondary_text,
          id: result.metadata.offer_id,
          fk: result.fk,
        })),
      );
      return;
    }

    const baseParams = {
      status: 'content-approved',
    };
    const nameParams = {
      ...baseParams,
      query: value,
      type: BedbankService.isBedbankId(value) ? 'id' : 'name',
    };
    const countryParams = {
      ...baseParams,
      country: value,
    };
    const stateParams = {
      ...baseParams,
      stateProvince: value,
    };
    const cityParams = {
      ...baseParams,
      city: value,
    };

    const [nameRes, countryRes, stateRes, cityRes] = await Promise.all([
      BedbankService.getProperties(1, 20, nameParams),
      BedbankService.getProperties(1, 20, countryParams),
      BedbankService.getProperties(1, 20, stateParams),
      BedbankService.getProperties(1, 20, cityParams),
    ]);

    const allResults = [...nameRes.result, ...countryRes.result, ...stateRes.result, ...cityRes.result];
    const uniqueResults = allResults.reduce(
      (unique, item) =>
        unique.find((uniqueItem) => item.id === uniqueItem.id && item.domain !== 'led') ? unique : [...unique, item],
      [],
    );
    setResults(
      uniqueResults.map((result) => ({
        id: result.id,
        name: result.name,
        type: result.type,
        secondary: result.id,
      })),
    );
  }, [value]);

  useEffect(() => {
    const b = setTimeout(bedbankSearch, 500);

    return () => clearTimeout(b);
  }, [bedbankSearch]);

  const select = (e, result: Result) => {
    e.preventDefault();
    onSelect(result, id);
    setOpen(false);
  };

  return (
    <>
      <FormGroup>
        <TextField
          id="PlaceSearch"
          data-testid="PlaceSearch"
          type="text"
          name="searchString"
          label={label}
          placeholder={label}
          value={value}
          onChange={(e) => search((e.target as HTMLInputElement).value)}
          autoFocus
          fullWidth
          sx={{ bgcolor: 'white' }}
        />

        <div className="place-search__filters">
          <div
            className={classNames('place-search__item dropdown', {
              open,
            })}
          >
            <ul className="dropdown-menu">
              {results.map((result) => (
                <li key={result.id ?? result.fk}>
                  <a href="#" onClick={(e) => select(e, result)}>
                    <span>
                      {result.name} <small>{`${result.type} | ${result.secondary}`}</small>
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </FormGroup>
    </>
  );
};
