import React, { useEffect, useState } from 'react';

import { US_STATES_LIST } from '~/consts/usStates';

interface Props {
  selectedValue: string;
  onUpdateStateOfResidence: (param) => void;
}

export const StateOfResidenceSelect = (props: Props) => {
  const { selectedValue, onUpdateStateOfResidence } = props;

  const [stateOfResidence, setStateOfResidence] = useState('Does not reside in the US');

  const onChange = (event) => {
    setStateOfResidence(event.target.value);
  };

  useEffect(() => {
    if (selectedValue && !stateOfResidence) {
      setStateOfResidence(selectedValue);
      onUpdateStateOfResidence(selectedValue);
    }
  });

  useEffect(() => {
    onUpdateStateOfResidence(stateOfResidence);
  }, [stateOfResidence]);

  return (
    <div className="select-state-container">
      <select className="booking-select" name="stateOfResidence" onChange={onChange} value={stateOfResidence}>
        <option>Does not reside in the US</option>
        {US_STATES_LIST.map(function (item, index) {
          return (
            <option key={index} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default StateOfResidenceSelect;
