import React, { useCallback, useEffect, useState } from 'react';

import { Button, Checkbox, Chip, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import { TopicQualityBarChart } from '~/components/SupportAssistant/components/TopicQualityBarChart';

import { getChatsTopicsFrequency } from '~/services/SupportService';
import { endOfDayOrEndOrCurrentTime, formatDateWithClock, startOfDay, subDays } from '~/services/TimeService';

function TopicsPage() {
  const [fromDate, setFromDate] = useState(formatDateWithClock(startOfDay(subDays(7))));
  const [toDate, setToDate] = useState(formatDateWithClock());
  const [customersOnly, setCustomersOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [topicsData, setTopicsData] = useState([]);
  const [totalChats, setTotalChats] = useState(null);
  const [agentChatsOnly, setAgentChatsOnly] = useState(false);
  const [hasMessagesOnly, setHasMessagesOnly] = useState(true);

  const columns: Array<GridColDef> = [
    {
      disableColumnMenu: true,
      field: 'name',
      headerName: 'Topic',
      flex: 5,
      display: 'flex',
    },
    {
      disableColumnMenu: true,
      field: 'frequency',
      filterable: false,
      headerName: 'Frequency',
      flex: 2,
      display: 'flex',
    },
    {
      disableColumnMenu: true,
      sortable: false,
      field: 'qualities',
      filterable: false,
      headerName: 'Quality',
      flex: 1,
      renderCell: TopicQualityBarChart,
      display: 'flex',
    },
    {
      disableColumnMenu: true,
      field: 'HIGH',
      filterable: false,
      headerName: 'High',
      flex: 1,
      valueGetter: (_value, row) =>
        `${row.qualities.HIGH} (${Number(((row.qualities.HIGH / row.frequency) * 100).toFixed(2))}%)`,
      display: 'flex',
    },
    {
      disableColumnMenu: true,
      field: 'MEDIUM',
      filterable: false,
      headerName: 'Medium',
      flex: 1,
      valueGetter: (_value, row) =>
        `${row.qualities.MEDIUM} (${Number(((row.qualities.MEDIUM / row.frequency) * 100).toFixed(2))}%)`,
      display: 'flex',
    },
    {
      disableColumnMenu: true,
      field: 'LOW',
      filterable: false,
      headerName: 'Low',
      flex: 1,
      valueGetter: (_value, row) =>
        `${row.qualities.LOW} (${Number(((row.qualities.LOW / row.frequency) * 100).toFixed(2))}%)`,
      display: 'flex',
    },
    {
      disableColumnMenu: true,
      field: 'percentage',
      filterable: false,
      headerName: '% of chats',
      description: '% of chats that contain topic',
      valueGetter: (_value, row) => `${Number(((row.frequency / totalChats) * 100).toFixed(2))}%`,
      flex: 2,
      display: 'flex',
    },
  ];

  const getTopicsStats = useCallback(async () => {
    setIsLoading(true);

    getChatsTopicsFrequency({ from: fromDate, to: toDate, customersOnly, agentChatsOnly, hasMessagesOnly })
      .then(({ result }) => {
        setTopicsData(result.topicsData);
        setTotalChats(result.totalChats);
      })
      .finally(() => setIsLoading(false));
  }, [fromDate, toDate, customersOnly, agentChatsOnly, hasMessagesOnly]);

  useEffect(() => {
    getTopicsStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h3">Topics</Typography>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <DateTimeWidget
            format="DD/MM/YYYY HH:mm"
            disableFuture
            label="From"
            value={fromDate}
            onChange={setFromDate}
          />
        </Grid>
        <Grid item md={3}>
          <DateTimeWidget
            format="DD/MM/YYYY HH:mm"
            disableFuture
            label="To"
            value={endOfDayOrEndOrCurrentTime(toDate).toString()}
            onChange={setToDate}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={customersOnly} onChange={(event) => setCustomersOnly(event.target.checked)} />}
            label="Customers only"
          />
          <FormControlLabel
            control={
              <Checkbox checked={agentChatsOnly} onChange={(event) => setAgentChatsOnly(event.target.checked)} />
            }
            label="Agent chats only"
          />
          <FormControlLabel
            control={
              <Checkbox checked={hasMessagesOnly} onChange={(event) => setHasMessagesOnly(event.target.checked)} />
            }
            label="Hide Empty Chats"
          />
        </Grid>
        <Grid item md={1}>
          <Button disabled={isLoading} variant="contained" onClick={getTopicsStats}>
            {isLoading ? 'Loading' : 'Apply'}
          </Button>
        </Grid>
        <Grid item md={5} />
      </Grid>
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <Typography variant="h5">Quality:</Typography>
        <Chip
          style={{ background: '#90e08a' }}
          label={`HIGH ${topicsData.reduce((acc, topic) => acc + topic.qualities.HIGH, 0)}`}
        />
        <Chip
          style={{ background: '#ebe773' }}
          label={`MEDIUM ${topicsData.reduce((acc, topic) => acc + topic.qualities.MEDIUM, 0)}`}
        />
        <Chip
          style={{ background: '#ff8e8e' }}
          label={`LOW ${topicsData.reduce((acc, topic) => acc + topic.qualities.LOW, 0)}`}
        />
        <Chip
          style={{ background: '#73e3eb' }}
          label={`UNMARKED ${topicsData.reduce((acc, topic) => acc + topic.qualities.UNMARKED, 0)}`}
        />
      </Stack>
      <DataGrid
        autoHeight
        density="compact"
        getRowId={(row) => row.name}
        initialState={{
          sorting: {
            sortModel: [{ field: 'frequency', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        columns={columns}
        rows={topicsData}
      />
    </Stack>
  );
}

export default TopicsPage;
