import React from 'react';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import { formatDateSlashes, sortByDate } from '~/services/TimeService';

import { daysOfWeekMessage } from '~/utils/daysOfWeek';

interface Props {
  salesforceSurcharges?: Array<Reservation.PresentedSalesforceSurchargePeriod>;
  selectedSurcharges?: Array<Reservation.PresentedSalesforceSurchargePeriod>;
  onSelectSurcharges: (surchargeIds: unknown[]) => void;
  conflictingSurcharges: Array<string>;
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <GridToolbarQuickFilter style={{ flex: 1 }} />
    </Box>
  );
}

export function SurchargesSelection({
  salesforceSurcharges,
  selectedSurcharges,
  onSelectSurcharges,
  conflictingSurcharges,
}: Props) {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Surcharge Period',
      minWidth: 150,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'start_date',
      headerName: 'From',
      minWidth: 100,
      type: 'date',
      sortComparator: sortByDate,
      valueFormatter: (value) => formatDateSlashes(value),
      display: 'flex',
    },
    {
      field: 'end_date',
      headerName: 'To',
      minWidth: 100,
      type: 'date',
      sortComparator: sortByDate,
      valueFormatter: (value) => formatDateSlashes(value),
      display: 'flex',
    },
    {
      field: 'daysOfWeek',
      headerName: 'Days of week',
      minWidth: 150,
      flex: 1,
      valueGetter: (_value, row) => daysOfWeekMessage(row),
      display: 'flex',
    },
    {
      field: 'cost_price',
      headerName: 'Cost',
      maxWidth: 100,
      display: 'flex',
    },
    {
      field: 'sell_price',
      headerName: 'Sell',
      maxWidth: 90,
      display: 'flex',
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={salesforceSurcharges}
        columns={columns}
        density="compact"
        pageSizeOptions={[10]}
        sx={{
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'none',
          },
          '.MuiDataGrid-row.conflicting-row': {
            bgcolor: 'error.light',
            '&:hover': {
              bgcolor: 'error.main',
            },
          },
          '.MuiDataGrid-menuIcon': {
            width: 'auto',
            visibility: 'visible',
          },
          '.MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
            width: 'auto',
            '&:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
              opacity: 1,
            },
          },
          minWidth: 650,
          height: salesforceSurcharges.length >= 10 ? 460 : 310,
        }}
        getRowClassName={(params) => `${conflictingSurcharges.includes(params.row.id) ? 'conflicting-row' : ''}`}
        checkboxSelection
        onRowSelectionModelChange={onSelectSurcharges}
        rowSelectionModel={selectedSurcharges.map((s) => s.id)}
        slots={{ toolbar: QuickSearchToolbar }}
      />
    </Box>
  );
}
