import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { DataGrid, GridColDef, GridEventListener, GridPagination } from '@mui/x-data-grid';

import { formatDateWithClock } from '~/services/TimeService';

interface Props {
  data?: {
    result?: App.PrivilegedUser[];
  };
  searchQuerySubmit?: string;
  loading?: boolean;
}

const columns: GridColDef[] = [
  {
    field: 'full_name',
    headerName: 'Name',
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'last_login',
    headerName: 'Last login',
    minWidth: 150,
    flex: 1,
    valueFormatter: (value) => formatDateWithClock(value) ?? '',
    display: 'flex',
  },
  {
    field: 'inline_roles_label',
    headerName: 'Role',
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'tenant',
    headerName: 'Tenant',
    minWidth: 150,
    flex: 1,
    display: 'flex',
  },
];

export default function PrivilegedUserSearchResults(props: Props) {
  const [result, setResult] = useState(props.data?.result);
  const [page, setPage] = useState(0);
  const history = useHistory();

  const sanitizeInput = useCallback(() => {
    if (props.data?.result?.length > 0) {
      return true;
    } else {
      setResult([
        {
          id_member: '',
          full_name: 'No data to display',
          email: null,
          last_login: null,
          inline_roles: null,
        },
      ]);
    }
  }, [props.data?.result?.length]);

  useEffect(() => {
    sanitizeInput();
    setResult(props.data?.result);
  }, [props.data?.result, sanitizeInput]);

  const clickFormatter: GridEventListener<'rowClick'> = (params) => {
    const { searchQuerySubmit } = props;

    if (params.row.id_member) {
      history.push({
        pathname: '/system/users/' + params.row.id_member,
        state: { searchQuerySubmit: searchQuerySubmit ?? '' },
      });
    }
  };

  return (
    <DataGrid
      className="T-privileged-user-search-table"
      loading={props.loading}
      rows={result || []}
      columns={columns}
      pageSizeOptions={[10]}
      paginationModel={{ pageSize: 10, page }}
      onPaginationModelChange={({ page }) => setPage(page)}
      onRowClick={clickFormatter}
      getRowId={(row) => row.id_member}
      rowCount={result?.length}
      getRowHeight={() => 'auto'}
      slots={{
        pagination: GridPagination,
      }}
      autoHeight
      sx={{ mt: 2 }}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
    />
  );
}
