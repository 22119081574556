import React from 'react';

import { useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { useParams } from 'react-router';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import ExperienceDetails from './Home/ExperienceDetails';
import Experiences from './Home/Experiences';

const loadLibraries: Libraries = ['places', 'geometry'];

interface Params {
  id_experience: string;
}

function ExperiencesPage() {
  const { id_experience: experienceId } = useParams<Params>();

  const { isLoaded, loadError } = useLoadScript({
    libraries: loadLibraries,
    googleMapsApiKey: window.configs.GOOGLE_PLACE_API_KEY,
  });

  const Component = experienceId ? ExperienceDetails : Experiences;

  return isLoaded && !loadError && <Component />;
}

export default function PermissionedExperiencesPage() {
  return (
    <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EXPERIENCES_COORDINATOR]}>
      <ExperiencesPage />
    </PermissionedComponent>
  );
}
