import React, { useMemo, useState } from 'react';

import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert } from '@mui/material';

import * as PromoContract from '@luxuryescapes/contract-svc-promo';

import { createOrUpdateInAppMessagePromo } from '~/services/PromoService';

import ErrorDisplay from '../Common/ErrorDisplay';
import pruneObjectBasedOnSchema from '../Common/Forms/helpers/pruneObjectBasedOnSchema';
import { withTenant } from '../hoc';

const uiSchema = {
  codeName: {
    'ui:placeholder': 'Enter Promo Code Name',
  },
  heading: {
    'ui:placeholder': 'Enter Header Message',
  },
  details: {
    'ui:placeholder': 'Enter Details of the Message',
    'ui:widget': 'textarea',
  },
  id: {
    'ui:disabled': true,
  },
  brand: {
    'ui:disabled': true,
  },
  active: {
    'ui:widget': 'radio',
  },
};

interface Props {
  tenant: App.Tenant;
  inAppPromoMessage?: PromoContract.Promotion.InternalFeaturePromoSchedule;
  fetchData: () => void;
  schema: RJSFSchema;
}

function InAppPromoMessageForm({ tenant, inAppPromoMessage, fetchData, schema }: Props) {
  const [creationResult, setCreationResult] = useState(null);
  const formData = useMemo(() => {
    const data = pruneObjectBasedOnSchema(inAppPromoMessage, schema);
    return Object.assign({ brand: tenant.brand, ...data });
  }, [inAppPromoMessage, schema, tenant.brand]);

  const onSubmit = async (form) => {
    try {
      const result = await createOrUpdateInAppMessagePromo(
        form.formData.id,
        form.formData.codeName,
        form.formData.region,
        form.formData.active,
        tenant.brand,
        form.formData.startAt,
        form.formData.endAt,
        form.formData.heading,
        form.formData.details,
      );
      setCreationResult({ ...result, status: 'success' });
      fetchData();
    } catch (e) {
      setCreationResult({ message: e.message, status: 'reject' });
    }
  };

  return (
    <div>
      <Form schema={schema} uiSchema={uiSchema} onSubmit={onSubmit} formData={formData} validator={validator} />
      {creationResult?.status === 'reject' && (
        <ErrorDisplay
          message={`${tenant.brand.toUpperCase()}: ${
            creationResult.message ? creationResult.message : 'Unexpected error'
          }`}
        />
      )}
      {creationResult?.status === 'success' && (
        <Alert severity="success">
          {tenant.brand.toUpperCase()}: Promo Message Schedule {inAppPromoMessage ? 'updated' : 'created'}
        </Alert>
      )}
    </div>
  );
}

InAppPromoMessageForm.defaultProps = {
  inAppPromoMessage: {},
};

export default withTenant(InAppPromoMessageForm);
