import React from 'react';

import { Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { deleteFrequentFlyer } from '~/services/BusinessTraveller/BusinessTravellerService';

import { FrequentFlyerItemType } from '../pages/BusinessDetailsPage/Tabs/FrequentFlyerTab';

const AirlineNameMap = {
  qantas: 'Qantas Business Rewards',
  virgin: 'Virgin Australia Business Flyer',
};

const AirlineCodeMap = {
  virgin: 'ACC99',
};

const QantasCodeToLevelMap = {
  QFB01: '1',
  QFB02: '2',
  QFB03: '3',
};

const QantasLevelToCodeMap = {
  1: 'QFB01',
  2: 'QFB02',
  3: 'QFB03',
};

interface Props {
  businessId: string;
  item: FrequentFlyerItemType;
  index: number;
  frequentFlyerItems: FrequentFlyerItemType[];
  setFrequentFlyerItems: (value: React.SetStateAction<FrequentFlyerItemType[]>) => void;
  setHasSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FrequentFlyerItem(props: Props) {
  const { businessId, item, index, frequentFlyerItems, setFrequentFlyerItems, setHasSaved } = props;

  function deleteRow(indexToRemove, businessId) {
    const frequentFlyerCopy = [...frequentFlyerItems];

    if (frequentFlyerCopy[indexToRemove].id) {
      deleteFrequentFlyer(frequentFlyerCopy[indexToRemove].id, businessId);
    }

    frequentFlyerCopy.splice(indexToRemove, 1); // Remove the selected row
    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  function onSelectAirlineName(e, index: number) {
    const { name, value } = e.target;

    const frequentFlyerCopy = [...frequentFlyerItems];
    frequentFlyerCopy[index][name] = value;

    if (value !== 'qantas') {
      frequentFlyerCopy[index]['code'] = AirlineCodeMap[value];
    }

    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  function onSelectVerified(e, index: number) {
    const { name, value } = e.target;

    const frequentFlyerCopy = [...frequentFlyerItems];
    frequentFlyerCopy[index][name] = value === 'true';

    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  function onSelectQantasLevel(e, index: number) {
    const { name, value } = e.target;

    const frequentFlyerCopy = [...frequentFlyerItems];
    frequentFlyerCopy[index][name] = QantasLevelToCodeMap[value];

    setFrequentFlyerItems(frequentFlyerCopy);
    setHasSaved(false);
  }

  return (
    <Grid container gap={2} key={item.id} spacing={2}>
      <Grid alignItems="center" container flexDirection="row" xs={12}>
        <Grid>
          <Typography fontSize={32} fontWeight="bold">
            {AirlineNameMap[item.airlineName]}
          </Typography>
        </Grid>
        <Grid>
          <Button color="error" variant="outlined" onClick={() => deleteRow(index, businessId)}>
            Delete
          </Button>
        </Grid>
      </Grid>
      <Grid alignItems="center" container flexDirection="row" xs={12}>
        <Grid xs={1}>
          <Typography>Airline:</Typography>
        </Grid>
        <Grid xs={5}>
          <FormControl fullWidth>
            <Select
              label="AirlineName"
              name="airlineName"
              notched={false}
              onChange={(event) => onSelectAirlineName(event, index)}
              placeholder="Select airline name"
              value={item.airlineName}
            >
              <MenuItem value="qantas">Qantas</MenuItem>
              <MenuItem value="virgin">Virgin</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid alignItems="center" container flexDirection="row" xs={12}>
        <Grid xs={1}>
          <Typography>Membership Status:</Typography>
        </Grid>
        <Grid xs={5}>
          <FormControl fullWidth>
            <Select
              label="verified"
              name="verified"
              notched={false}
              onChange={(event) => onSelectVerified(event, index)}
              value={item.verified}
            >
              <MenuItem disabled></MenuItem>
              <MenuItem value="true">Verified membership</MenuItem>
              <MenuItem value="false">No membership</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {item.airlineName === 'qantas' && (
        <Grid alignItems="center" container flexDirection="row" xs={12}>
          <Grid xs={1}>
            <Typography>Level:</Typography>
          </Grid>
          <Grid xs={5}>
            <FormControl fullWidth>
              <Select
                label="QantasLevel"
                name="code"
                notched={false}
                onChange={(event) => onSelectQantasLevel(event, index)}
                value={QantasCodeToLevelMap[item.code]}
              >
                <MenuItem disabled></MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
