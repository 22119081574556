import React from 'react';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import isString from '~/utils/isString';

function isValidVersion(str) {
  return isString(str) && str.match(/^v\d+$/);
}

export default class UpdateMonitor extends React.Component {
  constructor() {
    super();

    this.state = {
      showUpdateMessage: false,
      offscreen: true,
    };
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer() {
    if (!this.interval) {
      this.interval = setInterval(this.checkUpdate, 1800000); // 30 min interval
    }
  }

  clearTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  checkUpdate = () => {
    const thisVersion = document.getElementById('app-container').getAttribute('data-release-version');

    if (!isValidVersion(thisVersion)) {
      console.warn('Current release version unknown');
      return;
    }

    fetch('/release-version')
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.text();
      })
      .then((latestVersion) => {
        if (!isValidVersion(latestVersion)) {
          console.warn('Latest release version not found');
          return;
        }

        if (latestVersion !== thisVersion) {
          console.warn(`Latest release version "${latestVersion}" does not match current version "${thisVersion}"`);
          this.setState({ showUpdateMessage: true });
          setTimeout(() => {
            this.setState({ offscreen: false });
          }, 500);
          this.clearTimer();
        } else {
          console.warn(`Latest release version "${latestVersion}" matches current version`);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch release version:', error);
      });
  };

  dismiss = () => {
    this.setState({ offscreen: true });
    setTimeout(() => {
      this.setState({ showUpdateMessage: false });
    }, 500);
    this.setTimer();
  };

  render() {
    const { showUpdateMessage, offscreen } = this.state;

    if (!showUpdateMessage || offscreen) {
      return null;
    }

    return (
      <Alert
        severity="warning"
        className="update-alert"
        sx={{
          display: 'inline-block',
          textAlign: 'center',
        }}
        icon={false}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <ReportProblemOutlinedIcon color="warning" fontSize="large" />
          <strong>A new version is available.</strong>Reload to make sure you&apos;re using the latest admin portal.
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Button variant="text" color="warning" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button variant="text" onClick={this.dismiss}>
            Remind me later
          </Button>
        </Stack>
      </Alert>
    );
  }
}
