import React from 'react';

import { Chip, Stack, Typography } from '@mui/material';

interface Props {
  value: string[] | string;
}

export default function TourChips(props: Props) {
  const { value } = props;

  return (
    <>
      {Array.isArray(value) && (
        <Stack direction="row" gap={0.5} justifyContent="center" flexWrap="wrap">
          {value.map((region) => (
            <Chip key={region} label={region} size="small" sx={{ textTransform: 'uppercase' }} />
          ))}
        </Stack>
      )}
      {!Array.isArray(value) && (
        <Typography variant="caption" color="error">
          {value}
        </Typography>
      )}
    </>
  );
}
