/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import set from 'lodash/set';
import { useForm } from 'react-hook-form';

/**
 * This component is used to adapt react-hook-form components to react-jsonschema-form
 * Implementation is based on defining in the schema:
 * `{ "ui:widget": "ReactHookFormAdapter", "ui:options": { ReactHookFormComponent": DESIRED_REACT_HOOK_FORM_COMPONENT }}`
 */
export default function ReactHookFormAdapter<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>({ id, value: defaultValue, name, onChange, onBlur, options, ...fieldProps }: WidgetProps<T, S, F>) {
  const { control, setValue, getValues } = useForm({
    defaultValues: { [id ?? name]: defaultValue ?? fieldProps.defaultValue },
    mode: 'onBlur',
  });
  const [internalValue, setInternalValueState] = useState(defaultValue ?? fieldProps.defaultValue);
  const { props } = options;

  const handleValueUpdate = (path: string, value: unknown) => {
    path = path.replace((id ?? name) + '.', ''); // remove name prefix

    if (path.includes('.')) {
      // multi value fields
      if (Array.isArray(internalValue)) {
        const [index, field] = path.split('.');
        const newInternalValue = [...internalValue];

        if (newInternalValue[index] === undefined) {
          newInternalValue[index] = {};
        }

        set(newInternalValue[index], field, value);
        setInternalValueState(newInternalValue);
        onChange(newInternalValue);
        onBlur(id ?? name, newInternalValue);
      } else if (typeof internalValue === 'object') {
        const newInternalValue = { ...internalValue };
        set(newInternalValue, path, value);
        setInternalValueState(newInternalValue);
        onChange(newInternalValue);
        onBlur(id ?? name, newInternalValue);
      }
    } else {
      setInternalValueState(value);
      onChange(value);
      onBlur(id ?? name, value);
    }
  };

  return (
    <div>
      {options.ReactHookFormComponent && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <options.ReactHookFormComponent
          control={control}
          name={id ?? name}
          setValue={(name: string, value: unknown, config?: object) => {
            setValue(name, value, config);
            handleValueUpdate(name, value);
          }}
          getValues={getValues}
          hideLabel={true}
          internalValue={defaultValue}
          {...(props as object)}
          {...fieldProps}
        />
      )}
    </div>
  );
}
