import React from 'react';

import { Spacing } from '../../helpers';

import * as S from './styles';

type BadgeProps = {
  title: string;
  color?: string;
  radius?: string;
  lineHeight?: string;
  background?: string;
  borderColor?: string;
} & Spacing;

export const Badge: React.FC<BadgeProps> = (props) => {
  const { title, ...rest } = props;

  return (
    <S.BadgeWrapper {...rest}>
      <span>{title}</span>
    </S.BadgeWrapper>
  );
};
