import styled from 'styled-components';

import { themes } from '../../../../consts/experiences';
import { Spacing, margin, padding } from '../../helpers';

type FlexProps = {
  align?: string;
  justify?: string;
  direction?: string;
} & Spacing;

type GridProps = {
  rows?: string;
  columns: string;
  rowGap?: string;
  justify?: string;
  align?: 'baseline' | 'center' | 'end' | 'flex-end' | 'flex-start' | 'inherit' | 'initial' | 'normal' | 'unset';
  columnGap?: string;
  height?: string;
} & Spacing;

export const FlexWrapper = styled.div<FlexProps>`
  width: 100%;
  display: flex;

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};

  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'unset'};
  flex-direction: ${({ direction }) => direction || 'row'};
`;

export const GridWrapper = styled.div<GridProps>`
  height: ${({ height }) => height};
  width: 100%;
  display: grid;

  row-gap: ${({ rowGap }) => rowGap || '0px'};
  grid-template-rows: ${({ rows }) => rows || ''};
  column-gap: ${({ columnGap }) => columnGap || '0px'};
  justify-items: ${({ justify }) => justify || 'center'};
  align-items: ${({ align }) => align || 'inherit'};
  grid-template-columns: ${({ columns }) => columns || 'repeat(2, 1fr)'};

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};
`;

export const LineDivisor = styled.div<Spacing>`
  height: 25px;
  border-right: 1px solid ${themes?.secondaryGrey};

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};
`;

export const LineHorizontalDivisor = styled.div<Spacing>`
  width: 100%;
  border-bottom: 1px solid ${themes?.secondaryGrey};

  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};
`;

export const Wrapper = styled.div<Spacing>`
  margin: ${({ m, mx, my, mt, mb, ml, mr }) => margin({ m, mx, my, mt, mb, ml, mr })};

  padding: ${({ p, px, py, pt, pb, pl, pr }) => padding({ p, px, py, pt, pb, pl, pr })};
`;
