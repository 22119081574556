import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerScheduleEmailContentGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules/schedule-email-content']['get'];

function mapHeroPlannerEmailContent(
  external: HeroPlannerScheduleEmailContentGetContract['responses']['200']['content']['application/json']['result'],
): CustomerCommunication.HeroPlannerScheduleEmailContent {
  return {
    preHeaders: external.emailPreheaders?.map(String) ?? [],
    subjectLine: external.emailSubjectLine,
  };
}

async function requestGetHeroPlannerScheduleEmailContent(
  brand: string,
  countryGroup: string,
  country: string,
  offerIds: Array<string>,
) {
  if (!brand) throw 'missing brand';
  if (!countryGroup) throw 'missing countryGroup';
  if (!country) throw 'missing country';
  if (!offerIds.length) throw 'missing offerIds';

  const url = makeCustomerCommunicationV1URL('/todays-escapes-schedule/hero-planner/schedules/schedule-email-content');
  url.searchParams.set('brand', brand);
  url.searchParams.set('countryGroup', countryGroup);
  url.searchParams.set('country', country);
  url.searchParams.set('offerIds', offerIds.join(','));

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((res: HeroPlannerScheduleEmailContentGetContract['responses']['200']['content']['application/json']) => {
      return mapHeroPlannerEmailContent(res.result);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerScheduleEmailContent;
