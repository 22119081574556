import React, { PropsWithChildren } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { MappedAuditLog } from '~/services/AuditService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

type Props = PropsWithChildren<{
  auditLog: MappedAuditLog;
}>;

export function AuditLog({ auditLog, children }: Props) {
  return (
    <>
      <Divider orientation="horizontal" />
      <Stack spacing={1} paddingLeft="1em">
        <Stack direction="row" spacing={2}>
          <Typography>{formatDateOrdinalWithClock(auditLog.timestamp)}</Typography>
          <Typography>{auditLog.action}</Typography>
          <Typography>{auditLog.userEmail}</Typography>
        </Stack>
        <Box paddingLeft="2em">{children}</Box>
      </Stack>
    </>
  );
}
