import React, { ComponentProps, useCallback, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ArrayFieldTemplateItemType, RJSFSchema } from '@rjsf/utils';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { ExtendedRJSFormContext } from './useExtendedRJSForm';

interface Props extends ArrayFieldTemplateItemType<unknown, RJSFSchema, ExtendedRJSFormContext> {
  identifier?: string;
  arrayTitle?: string;
  onItemCopyTrigger?: (currentItemIndex: number) => void;
  toolbarDirection?: 'row' | 'column';
}

export default function ExtendedArrayFieldItemTemplate(props: Props) {
  const {
    arrayTitle,
    children,
    className,
    disabled,
    hasMoveDown,
    hasMoveUp,
    hasRemove,
    identifier,
    index,
    readonly,
    onDropIndexClick,
    onItemCopyTrigger,
    onReorderClick,
    toolbarDirection = 'column',
  } = props;

  const isDraggable = !disabled && (hasMoveUp || hasMoveDown);

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: identifier,
    disabled: !isDraggable,
  });

  const [indexDropConfirmationAnchorEl, setIndexDropConfirmationAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    isToggled: indexDropConfirmation,
    toggleOn: openIndexDropConfirmation,
    toggleOff: closeIndexDropConfirmation,
  } = useToggleState();
  const handleIndexDropConfirmation = useCallback<ComponentProps<typeof Button>['onClick']>((e) => {
    setIndexDropConfirmationAnchorEl(e.currentTarget);
    openIndexDropConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIndexDrop = useCallback<ComponentProps<typeof Button>['onClick']>(
    (e) => {
      onDropIndexClick(index)(e);
      closeIndexDropConfirmation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index, onDropIndexClick],
  );

  const hasToolbar = hasMoveDown || hasMoveUp || hasRemove || !!onItemCopyTrigger;

  const handleItemCopyTrigger = useCallback(() => {
    onItemCopyTrigger(index);
  }, [onItemCopyTrigger, index]);

  return (
    <Box
      ref={setNodeRef}
      className={className}
      display="grid"
      gridTemplateColumns="minmax(1px, 1fr) min-content"
      gap={1}
      alignItems="stretch"
      sx={{ transform: CSS.Transform.toString(transform), transition }}
    >
      <Box>{children}</Box>
      {!!hasToolbar && (
        <Box>
          <Stack direction={toolbarDirection} spacing={1}>
            {isDraggable && (
              <Box order={toolbarDirection === 'row' ? 4 : ''}>
                <Tooltip placement="left" title={`Hold and drag to move ${arrayTitle} ${index + 1}`}>
                  <IconButton
                    ref={setActivatorNodeRef}
                    type="button"
                    color="secondary"
                    disabled={disabled || readonly}
                    {...listeners}
                    {...attributes}
                  >
                    <OpenWithIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {hasMoveUp && (
              <Box order={toolbarDirection === 'row' ? 3 : ''}>
                <Tooltip placement="left" title="Move up">
                  <IconButton
                    type="button"
                    color="primary"
                    disabled={disabled || readonly}
                    onClick={onReorderClick(index, index - 1)}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {!!onItemCopyTrigger && (
              <Tooltip placement="left" title="Copy from sibling">
                <IconButton
                  type="button"
                  color="warning"
                  disabled={disabled || readonly}
                  onClick={handleItemCopyTrigger}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {hasRemove && (
              <Box order={toolbarDirection === 'row' ? 1 : ''}>
                <Tooltip placement="left" title={`Delete ${arrayTitle} ${index + 1}`}>
                  <IconButton
                    type="button"
                    color="error"
                    disabled={disabled || readonly}
                    onClick={handleIndexDropConfirmation}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {hasMoveDown && (
              <Box order={toolbarDirection === 'row' ? 2 : ''}>
                <Tooltip placement="left" title="Move down">
                  <IconButton
                    type="button"
                    color="primary"
                    disabled={disabled || readonly}
                    onClick={onReorderClick(index, index + 1)}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Stack>

          <Popover
            open={indexDropConfirmation}
            anchorEl={indexDropConfirmationAnchorEl}
            onClose={closeIndexDropConfirmation}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Delete {arrayTitle} {index + 1}?
                </Typography>
                <Typography variant="subtitle2" component="div">
                  This action is irreversible!
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="text" color="secondary" onClick={closeIndexDropConfirmation}>
                  Dismiss
                </Button>
                <Button variant="contained" color="error" startIcon={<DeleteForeverIcon />} onClick={handleIndexDrop}>
                  Confirm Deletion
                </Button>
              </CardActions>
            </Card>
          </Popover>
        </Box>
      )}
    </Box>
  );
}
