import React, { useCallback } from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { AtolForm } from './AtolPage';

type Props = {
  atolData: AtolForm;
  onUpdate: (field: string, value: string) => void;
};

function CarrierDetailsForm(props: Props) {
  const { atolData, onUpdate } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      onUpdate(name, value);
    },
    [onUpdate],
  );

  return (
    <Grid container item spacing={2} sm={12}>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Email" name="email" value={atolData.email} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField fullWidth label="Carrier" name="carrier" value={atolData.carrier} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Carrier Name"
          name="carrierName"
          value={atolData.carrierName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField fullWidth label="PNR" name="flightPnr" value={atolData.flightPnr} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth>
          <InputLabel>Fare Type</InputLabel>
          <Select required name="searchType" label="Fare Type" value={atolData.searchType} onChange={handleChange}>
            <MenuItem value="return">Return</MenuItem>
            <MenuItem value="oneWay">One Way</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField fullWidth label="Total Cost" name="totalCost" value={atolData.totalCost} onChange={handleChange} />
      </Grid>
    </Grid>
  );
}

export default CarrierDetailsForm;
