import { Reducer } from 'redux';

import CustomerCommunicationAction, {
  CustomerCommunicationActionTypes,
} from '~/actions/customerCommunication/customerCommunicationAction';

import { EmptyArray } from '~/utils/arrayUtils';

export const INITIAL_CUSTOMER_COMMUNICATION_STATE: CustomerCommunication.CustomerCommunicationReduxState = {
  offerVisibilitiesRecords: {},
  brands: { status: 'initial', result: EmptyArray },
  offerDetailsRecords: {},
  countryGroupRecords: {},
  countryGroupCountriesRecords: {},
  countryGroupCountryStatesRecords: {},
  countryGroupCountryStateCitiesRecords: {},
  countryGroupCountryStateCitySegmentsRecords: {},
  countryGroupCountryStateCitySegmentCadencesRecords: {},
  countryGroupCountryStateCitySegmentCadenceMembershipsRecords: {},
};

export function getRecordKeyFor(...args: Array<unknown>): string {
  return JSON.stringify(args);
}

const customerCommunicationReducer: Reducer<
  CustomerCommunication.CustomerCommunicationReduxState,
  CustomerCommunicationAction
> = (customerCommState = INITIAL_CUSTOMER_COMMUNICATION_STATE, action) => {
  switch (action.type) {
    // OFFER VISIBILITIES
    case CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_FETCHING: {
      const offerDetailsKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        offerVisibilitiesRecords: {
          ...customerCommState.offerVisibilitiesRecords,
          [offerDetailsKey]: {
            status: 'pending',
            params: action.params,
          },
        },
      };
    }

    case CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_FULFILLED: {
      const offerDetailsKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        offerVisibilitiesRecords: {
          ...customerCommState.offerVisibilitiesRecords,
          [offerDetailsKey]: {
            status: 'fulfilled',
            params: action.params,
            result: action.offerVisibilities,
          },
        },
      };
    }

    case CustomerCommunicationActionTypes.SET_OFFER_VISIBILITIES_REJECTED: {
      const offerDetailsKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        offerVisibilitiesRecords: {
          ...customerCommState.offerVisibilitiesRecords,
          [offerDetailsKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }

    // BRAND
    case CustomerCommunicationActionTypes.SET_BRANDS_FETCHING: {
      return {
        ...customerCommState,
        brands: { status: 'pending', result: EmptyArray, params: undefined },
      };
    }
    case CustomerCommunicationActionTypes.SET_BRANDS_FULFILLED: {
      return {
        ...customerCommState,
        brands: { status: 'fulfilled', result: action.brands, params: undefined },
      };
    }
    case CustomerCommunicationActionTypes.SET_BRANDS_REJECTED: {
      return {
        ...customerCommState,
        brands: { status: 'rejected', error: action.error, params: undefined },
      };
    }

    // OFFER DETAILS
    case CustomerCommunicationActionTypes.SET_OFFER_DETAILS_FETCHING: {
      const offerDetailsKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        offerDetailsRecords: {
          ...customerCommState.offerDetailsRecords,
          [offerDetailsKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_OFFER_DETAILS_FULFILLED: {
      const offerDetailsKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        offerDetailsRecords: {
          ...customerCommState.offerDetailsRecords,
          [offerDetailsKey]: {
            status: 'fulfilled',
            params: action.params,
            result: action.offerDetails,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_OFFER_DETAILS_REJECTED: {
      const offerDetailsKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        offerDetailsRecords: {
          ...customerCommState.offerDetailsRecords,
          [offerDetailsKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }
    // COUNTRY GROUPS
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_FETCHING: {
      const countryGroupKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupRecords: {
          ...customerCommState.countryGroupRecords,
          [countryGroupKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_FULFILLED: {
      const countryGroupKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupRecords: {
          ...customerCommState.countryGroupRecords,
          [countryGroupKey]: {
            status: 'fulfilled',
            params: action.params,
            result: action.countryGroups,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUPS_REJECTED: {
      const countryGroupKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupRecords: {
          ...customerCommState.countryGroupRecords,
          [countryGroupKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }
    // COUNTRIES
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_FETCHING: {
      const countryGroupCountryKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountriesRecords: {
          ...customerCommState.countryGroupCountriesRecords,
          [countryGroupCountryKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_FULFILLED: {
      const countryGroupCountryKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountriesRecords: {
          ...customerCommState.countryGroupCountriesRecords,
          [countryGroupCountryKey]: {
            status: 'fulfilled',
            params: action.params,
            result: action.countries,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRIES_REJECTED: {
      const countryGroupCountryKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountriesRecords: {
          ...customerCommState.countryGroupCountriesRecords,
          [countryGroupCountryKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }
    // STATES
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATES_FETCHING: {
      const countryGroupCountryStateKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStatesRecords: {
          ...customerCommState.countryGroupCountryStatesRecords,
          [countryGroupCountryStateKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATES_FULFILLED: {
      const countryGroupCountryStateKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStatesRecords: {
          ...customerCommState.countryGroupCountryStatesRecords,
          [countryGroupCountryStateKey]: {
            status: 'fulfilled',
            params: action.params,
            result: action.countryStates,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATES_REJECTED: {
      const countryGroupCountryStateKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStatesRecords: {
          ...customerCommState.countryGroupCountryStatesRecords,
          [countryGroupCountryStateKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }
    // CITIES
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FETCHING: {
      const countryGroupCountryStateCityKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitiesRecords: {
          ...customerCommState.countryGroupCountryStateCitiesRecords,
          [countryGroupCountryStateCityKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_FULFILLED: {
      const countryGroupCountryStateCityKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitiesRecords: {
          ...customerCommState.countryGroupCountryStateCitiesRecords,
          [countryGroupCountryStateCityKey]: {
            status: 'fulfilled',
            params: action.params,
            result: action.stateCities,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITIES_REJECTED: {
      const countryGroupCountryStateCityKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitiesRecords: {
          ...customerCommState.countryGroupCountryStateCitiesRecords,
          [countryGroupCountryStateCityKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }
    // SEGMENTS
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FETCHING: {
      const countryGroupCountryStateSegmentKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentsRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentsRecords,
          [countryGroupCountryStateSegmentKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FULFILLED: {
      const countryGroupCountryStateSegmentKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentsRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentsRecords,
          [countryGroupCountryStateSegmentKey]: {
            status: 'fulfilled',
            params: action.params,
            result: action.citySegments,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_REJECTED: {
      const countryGroupCountryStateSegmentKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentsRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentsRecords,
          [countryGroupCountryStateSegmentKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }

    // CADENCES
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FETCHING: {
      const countryGroupCountryStateSegmentCadenceKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentCadencesRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentCadencesRecords,
          [countryGroupCountryStateSegmentCadenceKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_FULFILLED: {
      const countryGroupCountryStateSegmentCadenceKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentCadencesRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentCadencesRecords,
          [countryGroupCountryStateSegmentCadenceKey]: {
            status: 'fulfilled',
            result: action.cadences,
            params: action.params,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_REJECTED: {
      const countryGroupCountryStateSegmentCadenceKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentCadencesRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentCadencesRecords,
          [countryGroupCountryStateSegmentCadenceKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }

    // MEMBERSHIPS
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FETCHING: {
      const countryGroupCountryStateSegmentCadenceMembershipKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentCadenceMembershipsRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentCadenceMembershipsRecords,
          [countryGroupCountryStateSegmentCadenceMembershipKey]: { status: 'pending', params: action.params },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_FULFILLED: {
      const countryGroupCountryStateSegmentCadenceMembershipKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentCadenceMembershipsRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentCadenceMembershipsRecords,
          [countryGroupCountryStateSegmentCadenceMembershipKey]: {
            status: 'fulfilled',
            result: action.memberships,
            params: action.params,
          },
        },
      };
    }
    case CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_CADENCES_MEMBERSHIPS_REJECTED: {
      const countryGroupCountryStateSegmentCadenceMembershipKey = getRecordKeyFor(...action.params);
      return {
        ...customerCommState,
        countryGroupCountryStateCitySegmentCadenceMembershipsRecords: {
          ...customerCommState.countryGroupCountryStateCitySegmentCadenceMembershipsRecords,
          [countryGroupCountryStateSegmentCadenceMembershipKey]: {
            status: 'rejected',
            params: action.params,
            error: action.error,
          },
        },
      };
    }

    default:
      return customerCommState;
  }
};

export default customerCommunicationReducer;
