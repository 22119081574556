import React from 'react';

import { Box, Container, Divider, Typography } from '@mui/material';

export default function Footer() {
  return (
    <Container maxWidth="xl">
      <Divider sx={{ my: 2 }} />

      <Box mb={2}>
        <Typography color="secondary" variant="body2">
          &copy; 2017 - {new Date().getFullYear()}, Luxury Escapes
        </Typography>
      </Box>
    </Container>
  );
}
