import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {
  getLogsByKeyValue,
  getLogsByModel,
  getLogsByModelAndId,
  getLogsByUrl,
  getLogsByUserEmail,
} from '~/services/AuditService';

import { AuditLog as IAuditLog } from '~/types/responses';

import { AuditLog } from '../Common/AuditLog';
import PageHeader from '../Common/Elements/PageHeader';

export default function AuditLogSearch() {
  const { enqueueSnackbar } = useSnackbar();
  const [logs, setLogs] = useState<Array<IAuditLog>>([]);
  const [total, setTotal] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<string>('urlMatch');
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [urlMatch, setUrlMatch] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [modelId, setModelId] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);

  const validateFilter = () => {
    switch (filterType) {
      case 'keyValue':
        return key && value;
      case 'urlMatch':
        return urlMatch;
      case 'userEmail':
        return userEmail;
      case 'modelAndId':
        return model && modelId;
      case 'model':
        return model;
      default:
        return false;
    }
  };

  // an input changed so we need to reset the page count to 1 or the request will be invalid
  const resetPage = () => {
    setPage(1);
  };

  const fetchLogs = async (filterType) => {
    setIsFetching(true);
    setLogs([]);
    try {
      let resp;
      switch (filterType) {
        case 'keyValue':
          resp = await getLogsByKeyValue(key, value, page, pageSize);
          setLogs(resp.result);
          setTotal(parseInt(resp.total ?? resp.message.split(' ')[1]));
          break;
        case 'urlMatch':
          resp = await getLogsByUrl(urlMatch, page, pageSize);
          setLogs(resp.result);
          setTotal(parseInt(resp.total ?? resp.message.split(' ')[1]));
          break;
        case 'userEmail':
          resp = await getLogsByUserEmail(userEmail, page, pageSize);
          setLogs(resp.result);
          setTotal(parseInt(resp.total ?? resp.message.split(' ')[1]));
          break;
        case 'modelAndId':
          resp = await getLogsByModelAndId(model, modelId, page, pageSize);
          setLogs(resp.result);
          setTotal(parseInt(resp.total ?? resp.message.split(' ')[1]));
          break;
        case 'model':
          resp = await getLogsByModel(model, page, pageSize);
          setLogs(resp.result);
          setTotal(parseInt(resp.total ?? resp.message.split(' ')[1]));
          break;
        default:
          break;
      }
      setIsFetching(false);
      enqueueSnackbar(`Found ${resp.result.length} logs`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setLogs([]);
      setTotal(0);
      setIsFetching(false);
    }
  };

  const validateAndSearch = () => {
    if (filterType && validateFilter()) {
      fetchLogs(filterType);
    } else if (!filterType) {
      enqueueSnackbar(`Please select a filter type`, { variant: 'error' });
    } else {
      enqueueSnackbar(`${filterType} validation error, please check your input and try again.`, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Audit Logs</title>
      </Helmet>
      <PageHeader title="Audit Logs">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              label="Page Size"
              type="number"
              value={pageSize}
              onChange={(e) => {
                if (parseInt(e.target.value) < 1500) {
                  setPageSize(Number(e.target.value));
                } else {
                  enqueueSnackbar('Maximum page size is 1500', { variant: 'error' });
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Stack>
      </PageHeader>
      <Container sx={{ mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={6}>
            <Stack spacing={2}>
              <TextField
                select
                label="Filter by"
                value={filterType}
                onChange={(e) => {
                  setFilterType(e.target.value);
                  resetPage();
                }}
              >
                <MenuItem value="urlMatch">URL Match</MenuItem>
                <MenuItem value="keyValue">Key/Value</MenuItem>
                <MenuItem value="userEmail">User Email</MenuItem>
                <MenuItem value="modelAndId">Model and ID</MenuItem>
                <MenuItem value="model">Model</MenuItem>
              </TextField>
              {filterType === 'keyValue' && (
                <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Key"
                    onChange={(e) => {
                      setKey(e.target.value);
                      resetPage();
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Value"
                    onChange={(e) => {
                      setValue(e.target.value);
                      resetPage();
                    }}
                  />
                </Stack>
              )}
              {filterType === 'urlMatch' && (
                <TextField
                  label="URL Match"
                  onChange={(e) => {
                    setUrlMatch(e.target.value);
                    resetPage();
                  }}
                />
              )}
              {filterType === 'userEmail' && (
                <TextField
                  label="User Email"
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    resetPage();
                  }}
                />
              )}
              {filterType === 'modelAndId' && (
                <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Model"
                    onChange={(e) => {
                      setModel(e.target.value);
                      resetPage();
                    }}
                  />
                  <TextField
                    fullWidth
                    label="ID"
                    onChange={(e) => {
                      setModelId(e.target.value);
                      resetPage();
                    }}
                  />
                </Stack>
              )}
              {filterType === 'model' && (
                <TextField
                  label="Model"
                  onChange={(e) => {
                    setModel(e.target.value);
                    resetPage();
                  }}
                />
              )}
            </Stack>
          </Grid>
          <Grid item sm={6}>
            <Button fullWidth onClick={() => validateAndSearch()} variant="contained" disabled={isFetching}>
              Search
            </Button>
          </Grid>
        </Grid>
        {isFetching && <LinearProgress sx={{ margin: '2rem' }} />}
        {total > 0 && (
          <Stack my={2} justifyContent="center" direction="row">
            <Pagination
              count={Math.ceil(total / pageSize)}
              page={page}
              onChange={(e, p) => {
                setPage(p);
                setTimeout(validateAndSearch);
              }}
            />
            <Typography variant="caption" sx={{ mt: 1 }}>
              Showing {logs.length} of {total} logs
            </Typography>
          </Stack>
        )}
      </Container>
      <Box mt={2}>
        <Stack spacing={1}>
          {logs.length > 0 && logs.map((log) => <AuditLog key={log.id} auditLog={log} showFull />)}
          {logs.length === 0 && !isFetching && <Box>No logs found</Box>}
        </Stack>
      </Box>
      {total > 0 && (
        <Stack my={2} justifyContent="center" direction="row">
          <Pagination
            count={Math.ceil(total / pageSize)}
            page={page}
            onChange={(e, p) => {
              setPage(p);
              setTimeout(validateAndSearch);
            }}
          />
          <Typography variant="caption" sx={{ mt: 1 }}>
            Showing {logs.length} of {total} logs
          </Typography>
        </Stack>
      )}
    </Container>
  );
}
