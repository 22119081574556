import React, { Component } from 'react';

import { CardElement, Elements, StripeProvider, injectStripe } from 'react-stripe-elements';

import { Button } from '@mui/material';

import { getStripeCredentials } from '~/services/PaymentsService';

import ErrorDisplay from '../ErrorDisplay';

const STRIPE_STYLES = {
  base: {
    color: '#2d2926',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': { color: '#999999' },
  },
};

class StripeWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.handlePayment = this.handlePayment.bind(this);
    this.state = { error: false };
  }

  handlePayment(e) {
    e.preventDefault();

    const { onPayment, customerName, customerId, customerEmail, currencyCode, paymentWillStart } = this.props;

    if (!this._element._complete) {
      this.setState({
        error: { message: 'Please enter payment details' },
      });
      return;
    }

    paymentWillStart(() => {
      const transaction = {
        owner: { name: customerName },
        id_account: customerId,
        email: customerEmail,
        currency: currencyCode,
        save_card: false,
      };
      this.props.stripe
        .createSource({
          type: 'card',
          statement_descriptor: this.props.charge_descriptors[this.props.tenant.brand],
        })
        .then((stripeResponse) => {
          if (stripeResponse.error) {
            this.setState({
              error: stripeResponse.error,
            });
          }

          transaction.token = { id: stripeResponse.source.id };
          onPayment('stripe', transaction);
        })
        .catch((err) => {
          console.warn('Some Error Occured : ' + JSON.stringify(err, null, 2));
        });
    });
  }

  render() {
    const { disabled } = this.props;
    const { error } = this.state;

    return (
      <div>
        {error?.message && <ErrorDisplay message={error.message} />}

        <CardElement
          hidePostalCode
          onReady={(c) => {
            this._element = c;
          }}
          onFocus={this.useNewCardHandler}
          style={STRIPE_STYLES}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={this.handlePayment}
          className="submit-payment-btn"
          disabled={disabled}
          sx={{ mt: 1 }}
          fullWidth
        >
          Submit Payment
        </Button>
      </div>
    );
  }
}

const InjectedStripeWidgets = injectStripe(StripeWidgets);

class StripePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      stripePublishableKey: '',
    };
  }

  componentDidMount() {
    getStripeCredentials()
      .then((response) => {
        this.setState({
          stripePublishableKey: response.result.stripe_publishable_key,
          charge_descriptors: response.result.charge_descriptors,
          dataLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  render() {
    const { dataLoaded, error, stripePublishableKey } = this.state;

    if (!dataLoaded) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error loading the Stripe credentials: {error}</div>;
    }

    return (
      <StripeProvider apiKey={stripePublishableKey}>
        <Elements>
          <InjectedStripeWidgets
            paymentWillStart={this.props.paymentWillStart}
            onPayment={this.props.onPayment}
            customerId={this.props.customerId}
            customerEmail={this.props.customerEmail}
            customerName={this.props.customerName}
            currencyCode={this.props.currencyCode}
            tenant={this.props.tenant}
            getSavedCardsFromStripe={this.props.getSavedCardsFromStripe}
            charge_descriptors={this.state.charge_descriptors}
            disabled={this.props.disabled}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default StripePayment;
