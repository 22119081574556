import React, { Component } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { BOOKING_INFORMATION } from '~/consts/notes';
import { REASON_FOR_THE_WAIVED_SURCHARGE } from '~/consts/reservation';

import { addOrderNote } from '~/services/OrdersService';
import { createPendingLatitudeTransaction, getLatitudeCredentials } from '~/services/PaymentsService';

import centeredPopup from '~/utils/centeredPopup';
import latitudeConstants from '~/utils/latitudeConstants';
import { generateRequest } from '~/utils/latitudeHelpers';

import ErrorDisplay from '../ErrorDisplay';

export default class LatitudePayment extends Component {
  constructor() {
    super();

    this.state = {
      orderId: null,
      hasRequestFields: false,
      buyRequestFields: {},
      shouldPopupBeOpen: false,
      apiKey: null,
      error: null,
    };

    this.latitudePopup = null;
  }

  componentDidMount() {
    getLatitudeCredentials().then((response) => {
      this.setState({
        apiKey: response.result.latitude_publishable_key,
      });
    });

    window.addEventListener('message', this.receiveMessage);
  }

  componentWillUnmount() {
    this.popupClose();
    window.removeEventListener('message', this.receiveMessage);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.hasRequestFields && this.state.hasRequestFields) {
      // This is done here rather than in paymentHandler to ensure the hidden
      // fields have rendered before submitting the form.
      this.latitudeInterestFreeForm.submit();
    }
  }

  receiveMessage = (event) => {
    const { data } = event;
    if (data.type === 'latitudePaymentPopup') {
      this.handlePopupMessage(data);
    }
  };

  handlePopupMessage(message) {
    const { orderId } = this.state;

    if (message.returnToCart) {
      this.popupClose();
    } else if (orderId && message.order_id === orderId) {
      if (message.status === 'completed') {
        this.popupClose();
        this.props.onPayment('latitude');
      } else if (message.status === 'failed') {
        this.popupClose();
        this.setState({
          error: message.error || 'Something went wrong',
        });
      }
    }
  }

  purchaseDescription() {
    const { offer, items } = this.props.cart;
    const { pkg } = items[0];
    let description = `${offer.name} - ${pkg.name}`;
    if (items.length > 1) {
      description += ` (${items.length} x ${offer.saleUnit})`;
    }
    return description;
  }

  paymentHandler = () => {
    const {
      createOrder,
      brand,
      customerId,
      cart: {
        amounts: { grandTotal },
        currencyCode,
        transactionKey,
      },
      shouldPromoptWaiveSurcharge,
    } = this.props;

    let waiveSurchargeReason = null;

    if (shouldPromoptWaiveSurcharge) {
      do {
        waiveSurchargeReason = prompt(REASON_FOR_THE_WAIVED_SURCHARGE);
      } while (waiveSurchargeReason === '');

      if (!waiveSurchargeReason) {
        return;
      }
    }

    const total = grandTotal;
    const purchaseDescription = this.purchaseDescription();

    let orderId;
    let externalOrderId;

    this.openPopup();

    createOrder()
      .then((order) => {
        orderId = order.id;
        externalOrderId = order.external_order_id;
        return createPendingLatitudeTransaction({
          order_id: orderId,
          currency: currencyCode,
          external_id: `${externalOrderId}`,
          amount: total.toFixed(2),
          customer_id: customerId,
          brand,
          transaction_key: transactionKey,
        });
      })
      .then(() => {
        // Fields reference: https://applyandbuy.latitudefinancial.com/applyandbuy/api/api-buy-gehosted-fields.asp

        const interestFreeBuyOptions = {
          [latitudeConstants.MERCHANT_ID]: '000412106',
          [latitudeConstants.REFERENCE_CODE]: externalOrderId,
          [latitudeConstants.PROMO_CODE]: '2012',
          item_0_productCode: 1300,
          item_0_totalAmount: total,
          item_0_productDescription: purchaseDescription,
          [latitudeConstants.GRAND_TOTAL]: total,
          [latitudeConstants.ITEM_COUNT]: '1',
          [latitudeConstants.PAGE_SERIAL_NUMBER]: window.configs.LATITUDE_SERIAL,
          [latitudeConstants.PAGE_VERSION]: '7',
          [latitudeConstants.CURRENCY]: '036', // AUD
        };

        // sign the fields
        const signedFields = generateRequest(interestFreeBuyOptions, this.state.apiKey);

        // generate request map
        this.setState({
          orderId,
          hasRequestFields: true,
          buyRequestFields: signedFields,
        });

        if (waiveSurchargeReason) {
          addOrderNote(orderId, {
            comment: 'Waived surcharge: ' + waiveSurchargeReason,
            comment_type: BOOKING_INFORMATION,
          });
        }
      });
  };

  popupIsOpen() {
    return this.latitudePopup != null && !this.latitudePopup.closed;
  }

  openPopup(e) {
    if (e) {
      e.preventDefault();
    }

    if (!this.popupIsOpen()) {
      const popupWidth = Math.floor(window.top.outerWidth * 0.8);
      const popupHeight = Math.floor(window.top.outerHeight * 0.8);
      this.setState({
        shouldPopupBeOpen: true,
      });

      this.latitudePopup = centeredPopup(
        '',
        'LatitudePaymentPopup',
        window,
        popupWidth,
        popupHeight,
        'menubar=no,toolbar=no,location=no,personalbar=no,resizable=yes,scrollbars=yes',
      );
      if (this.state.hasRequestFields) {
        this.latitudeInterestFreeForm.submit();
      }
    } else {
      this.latitudePopup.focus();
    }
  }

  popupClose = () => {
    if (this.latitudePopup && !this.latitudePopup.closed) {
      this.latitudePopup.close();
      this.latitudePopup = null;
    }

    this.setState({
      shouldPopupBeOpen: false,
    });
  };

  render() {
    const { shouldPopupBeOpen, buyRequestFields, apiKey, error } = this.state;

    if (!apiKey) {
      return null;
    }

    return (
      <div>
        <Button bsStyle="primary" className="submit-payment-btn" onClick={this.paymentHandler}>
          Latitude Interest Free Payment
        </Button>

        {error && <ErrorDisplay message={error} />}

        <form
          ref={(el) => (this.latitudeInterestFreeForm = el)}
          name="submitform"
          action={window.configs.LATITUDE_URL}
          method="post"
          target="LatitudePaymentPopup"
        >
          {Object.keys(buyRequestFields).map((key) => (
            <input type="hidden" key={key} name={key} value={buyRequestFields[key]} readOnly />
          ))}
        </form>

        <Dialog open={shouldPopupBeOpen} onClose={this.popupClose}>
          <DialogTitle>Latitude Interest Free</DialogTitle>

          <DialogContent>
            A popup window should open for the Latitude payment gateway. If it doesn't open{' '}
            <a href="" onClick={this.openPopup}>
              click here
            </a>{' '}
            to try again.
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={this.popupClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
