import React, { FormEventHandler, useCallback, useRef, useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import { CollectionReason, RefundMetadata, VendorRefundTypes } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';
import MarkCancelled from '../Common/MarkCancelled';
import ShareOfRefund from '../Common/ShareOfRefund';

import CustomAmount from './CustomRefund/CustomAmount';
import SelectNights from './CustomRefund/SelectNights';

export enum RefundTabs {
  FULL_REFUND_WITH_FEE,
  CUSTOM_REFUND,
}

interface Props {
  refundMetadata: RefundMetadata;
  item: any;
  currencyCode: string;
  reason: CollectionReason;
  onSubmit: FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  vendorContribution: number;
  LEContribution: number;
  handleLateChangeOfMindFieldChange: any;
  defaultComment: string;
}

export default function LateChangeOfMindBadExperienceOrUnfortunateEvents({
  refundMetadata,
  item,
  currencyCode,
  onSubmit,
  reason,
  decrementStep,
  vendorContribution,
  LEContribution,
  handleLateChangeOfMindFieldChange,
  defaultComment,
}: Props) {
  const [feeOrCustom, setFeeOrCustom] = useState<RefundTabs>(RefundTabs.FULL_REFUND_WITH_FEE);
  const [percentageOrNumber, setPercentageOrNumber] = useState<VendorRefundTypes>(VendorRefundTypes.percentage);
  const formRef = useRef(null);
  const maxRefundAmount = refundMetadata.item_metadata.cash_amount + refundMetadata.merchant_fee_amount;

  const handleFeeOrCustomChange = useCallback((_, value: number) => {
    setFeeOrCustom(value);
  }, []);

  const handlePercentageOrNumberChange = useCallback(
    (_, value: VendorRefundTypes) => {
      const formData = new FormData(formRef.current);
      setPercentageOrNumber(value);
      formData.set('percentageOrNumber', value);
      if (value === VendorRefundTypes.percentage) {
        // values will default to 100%
        formData.set('percentageRefundToCustomer', maxRefundAmount.toString());
        formData.set('percentageRefund', '100');
      } else {
        // values will default to 0
        formData.set('numberOfNights', '0');
        formData.set('totalRefundedToCustomer', '0');
      }
      handleLateChangeOfMindFieldChange(formData);
    },
    [handleLateChangeOfMindFieldChange, maxRefundAmount],
  );

  return (
    <form onSubmit={onSubmit} ref={formRef}>
      <DialogContent dividers>
        <CaseIDAndReasons reason={reason} defaultComment={defaultComment} />
        {/* Full Refund With Fee, Or Custom Refund */}
        <Stack spacing={1}>
          <Box>
            <Tabs value={feeOrCustom} onChange={handleFeeOrCustomChange}>
              <Tab
                label="Full Refund with Fee"
                value={RefundTabs.FULL_REFUND_WITH_FEE}
                data-cy="Full Refund With Fee"
              />
              <Tab label="Custom Refund" value={RefundTabs.CUSTOM_REFUND} data-cy="Custom Refund" />
            </Tabs>
            {/* Hidden input to hold the feeOrCustom tab value */}
            <input type="hidden" name="feeOrCustom" value={feeOrCustom} />
          </Box>
          {feeOrCustom === RefundTabs.FULL_REFUND_WITH_FEE && (
            <Stack>
              <TextField
                type="number"
                name="refundFee"
                label="Fee Charged to Customer"
                data-cy="FeeChargedToCustomer"
                required
                inputProps={{
                  min: 0,
                  max: maxRefundAmount,
                  step: '.01',
                }}
              />
            </Stack>
          )}
          {feeOrCustom === RefundTabs.CUSTOM_REFUND && (
            <>
              <FormControl>
                <RadioGroup
                  name="percentageOrNumber"
                  row
                  value={percentageOrNumber}
                  onChange={handlePercentageOrNumberChange}
                >
                  <FormControlLabel
                    data-cy="CustomAmount"
                    value={VendorRefundTypes.percentage}
                    control={
                      <Radio
                        inputProps={{
                          required: true,
                        }}
                      />
                    }
                    label="Custom Amount"
                  />
                  <FormControlLabel
                    key="select-nights"
                    value={VendorRefundTypes.number}
                    data-cy="SelectNights"
                    control={
                      <Radio
                        inputProps={{
                          required: true,
                        }}
                      />
                    }
                    label="Select Nights"
                  />
                </RadioGroup>
              </FormControl>
              {percentageOrNumber === VendorRefundTypes.percentage && (
                <CustomAmount
                  maxRefundAmount={maxRefundAmount}
                  currencyCode={currencyCode}
                  formRef={formRef}
                  handleLateChangeOfMindFieldChange={handleLateChangeOfMindFieldChange}
                />
              )}
              {percentageOrNumber === VendorRefundTypes.number && (
                <SelectNights
                  refundMetadata={refundMetadata}
                  checkInDate={item.check_in}
                  checkOutDate={item.check_out}
                  currencyCode={currencyCode}
                  formRef={formRef}
                  handleLateChangeOfMindFieldChange={handleLateChangeOfMindFieldChange}
                />
              )}
              <Divider variant="fullWidth" />
              <Typography variant="h6" marginTop={2}>
                Share of Refund
              </Typography>
              <ShareOfRefund vendorContribution={vendorContribution} LEContribution={LEContribution} />
            </>
          )}
          <Stack spacing={2} direction="column">
            <MarkCancelled />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained">
          Continue
        </Button>
      </DialogActions>
    </form>
  );
}
