import React, { useCallback, useEffect, useMemo, useState } from 'react';

import difference from 'lodash/difference';

import {
  Alert,
  Box,
  Button,
  Grid,
  LinearProgress,
  Modal,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';

import { getFXRates } from '~/services/ReportingService';
import ReservationService from '~/services/ReservationService';
import { formatDateISO } from '~/services/TimeService';

import { convertValueByFX } from '~/utils/convertValueByFX';
import presentFXRates from '~/utils/presentFXRates';
import { surchargeHasDateConflict } from '~/utils/surchargeHasDateConflict';

import { OfferListSelection } from './OfferListSelection';
import { RoomRateSelection } from './RoomRateSelection';
import { SalesforceSurchargesSummary } from './SalesforceSurchargesSummary';
import { SurchargesSelection } from './SurchargesSelection';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: '95%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
};

const stepsList = ['Select Opportunity', 'Select Salesforce surcharges', 'Select Room Rates', 'Summary'];

const SELECT_OPPORTUNITY_STEP = 0;
const SELECT_SALESFORCE_SURCHARGES_STEP = 1;
const SELECT_ROOM_RATES_STEP = 2;
const SUMMARY_STEP = 3;

interface Props {
  roomTypes: Array<App.RoomType>;
  offers: Array<App.Offer>;
  propertyId: string;
  closeModal: () => void;
  isOpen: boolean;
  vendorId: string;
}

export function SalesforceSurchargesModal({ closeModal, isOpen, roomTypes, offers, propertyId, vendorId }: Props) {
  const [step, setStep] = useState<number>(0);
  const [stepError, setStepError] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);

  const [selectedOffer, setSelectedOffer] = useState<null | string>(null);
  const [conflictingSurcharges, setConflictingSurcharges] = useState([]);
  const [conflictingRoomRates, setConflictingRoomRates] = useState([]);

  const [selectedSurcharges, setSelectedSurcharges] = useState([]);

  const isStepFailed = (step: number) => {
    return step === stepError;
  };

  const filteredRoomRates = useMemo(() => {
    return roomTypes
      .map((roomType) => {
        const filteredRoomRates = roomType.room_rates.filter((roomRate) => {
          return roomRate.rate_plan.product_type === 'limited_time_exclusive';
        });
        return {
          ...roomType,
          room_rates: filteredRoomRates,
        };
      })
      .reduce((accumulator, roomType) => {
        roomType.room_rates.forEach((roomRate) => {
          accumulator.push({
            propertyId,
            uniqueId: `${roomType.id}-${roomRate.rate_plan.id}`,
            id: `${roomType.id}-${roomRate.rate_plan.id}`,
            roomType: roomType,
            roomRate: roomRate,
          });
        });

        return accumulator;
      }, []);
  }, [roomTypes, propertyId]);

  const [salesforceSurcharges, setSalesforceSurcharges] = useState([]);

  useEffect(() => {
    async function fechSalesforceSurcharges(selectedOffer) {
      setLoading(true);
      try {
        const { result: surcharges } = await ReservationService.getSalesforceSurcharges(selectedOffer);
        setSalesforceSurcharges(surcharges);
      } catch (e) {
        setErrors(e);
      } finally {
        setLoading(false);
      }
    }

    fechSalesforceSurcharges(selectedOffer);
  }, [selectedOffer]);

  const onSelectSurcharges = useCallback(
    (selected) => {
      setErrors(null);
      const newSelection = difference(
        selected,
        selectedSurcharges.map((s) => s.id),
      );
      let hasConflicts = [];
      newSelection.forEach((id) => {
        const surcharge = salesforceSurcharges.find((s) => s.id === id);
        hasConflicts = selectedSurcharges.filter((s) => surchargeHasDateConflict(s, surcharge)).map((s) => s.id);
      });
      if (hasConflicts.length) {
        setErrors("Can't select surcharge. Conflicts with already selected surcharges");
        // setConflictingSurcharges(hasConflict);
        return;
      }
      setSelectedSurcharges(salesforceSurcharges.filter((s) => selected.includes(s.id)));
    },
    [salesforceSurcharges, selectedSurcharges, setSelectedSurcharges],
  );

  const [selectedRoomRates, setSelectedRoomRates] = useState([]);

  const onSelectRoomRates = useCallback(
    (selected) => {
      setSelectedRoomRates(filteredRoomRates.filter((r) => selected.includes(r.id)));
    },
    [filteredRoomRates, setSelectedRoomRates],
  );

  const handleStep = (nextStep) => {
    if (nextStep > step) {
      if (nextStep >= SELECT_SALESFORCE_SURCHARGES_STEP && !selectedOffer) {
        setErrors('Please select an offer');
        setStepError(0);
        return;
      }
      if (nextStep >= SELECT_ROOM_RATES_STEP && !selectedSurcharges.length) {
        setErrors('Please select surcharges');
        setStepError(1);
        return;
      }
      if (nextStep >= SUMMARY_STEP && !selectedRoomRates.length) {
        setErrors('Please select room rates');
        setStepError(2);
        return;
      }
    }
    if (stepError !== SUMMARY_STEP) {
      setStepError(null);
    }
    setErrors(null);
    setStep(nextStep);
    return;
  };

  const getCurrencies = async (propertyId, roomTypeId, roomRateId) => {
    try {
      const surchargeSchema = await ReservationService.getSurchargeDateBlockSchema(propertyId, roomTypeId, roomRateId);
      const currencyCodes = Object.keys(surchargeSchema.post.body.schema.properties.prices.properties);
      return {
        [`${roomTypeId}-${roomRateId}`]: currencyCodes,
      };
    } catch (e) {
      setErrors(`Error fetching SurchargeDateBlockSchema: ${e}`);
    }
  };

  const mapPayload = useCallback(async () => {
    const surchargesMaper = (surcharge, currencyCodes, fxRates) => {
      // Maps salesforce surcharge to match
      // svc-reservation SurchargeDateBlock

      const surchargeDateBlock = {
        id: surcharge.id,
        ranges: [
          {
            start_date: formatDateISO(surcharge.start_date),
            end_date: formatDateISO(surcharge.end_date),
          },
        ],
        cost_amount: surcharge.cost_price,
        cost_currency: surcharge.currency_code,
        prices: {
          [surcharge.currency_code]: surcharge.sell_price,
        },
        monday: !!surcharge.monday,
        tuesday: !!surcharge.tuesday,
        wednesday: !!surcharge.wednesday,
        thursday: !!surcharge.thursday,
        friday: !!surcharge.friday,
        saturday: !!surcharge.saturday,
        sunday: !!surcharge.sunday,
      };

      currencyCodes.map((currencyCode) => {
        const converted = convertValueByFX({
          fxRates,
          fromCurrency: currencyCode,
          toCurrency: 'AUD',
          value: surcharge.sell_price,
        });
        surchargeDateBlock.prices[currencyCode] = Math.round(converted);
      });

      return surchargeDateBlock;
    };

    setLoading(true);
    setErrors(null);
    setStepError(null);
    setSuccess(null);
    setConflictingSurcharges([]);
    setConflictingRoomRates([]);
    try {
      const payloads = [];
      const fxRatesResponse = await getFXRates();
      const fxRates = presentFXRates(fxRatesResponse);

      const currenciesPayload = await Promise.all(
        selectedRoomRates.map((rr) => getCurrencies(propertyId, rr.roomType.id, rr.roomRate.id)),
      ).then((result) =>
        result.reduce((acc, current) => {
          return Object.assign(acc, current);
        }, {}),
      );

      for (const roomRate of selectedRoomRates) {
        const currencyKey = `${roomRate.roomType.id}-${roomRate.roomRate.id}`;
        const currencyCodes = currenciesPayload[currencyKey];

        for (const surcharge of selectedSurcharges) {
          const surchargeDateBlock = surchargesMaper(surcharge, currencyCodes, fxRates);
          payloads.push({
            surchargeDateBlock,
            propertyId,
            roomTypeId: roomRate.roomType.id,
            roomRateId: roomRate.roomRate.id,
          });
        }
      }
      const { count, result } = await ReservationService.surchargeRangeOverlapCheck({
        surcharges: payloads,
        propertyId,
      });

      if (count) {
        setStepError(3);
        setConflictingSurcharges([
          ...selectedSurcharges.filter((s) => result.surchargeConflicts.includes(s.id)).map((s) => s.id),
        ]);
        setConflictingRoomRates([
          ...selectedRoomRates
            .filter((r) => result.roomRateConflicts?.includes(r.roomRate.id))
            .map((r) => r.roomRate.id),
        ]);
        setLoading(false);
      } else {
        for (const update of payloads) {
          try {
            await ReservationService.createSurchargeDateBlock(
              update.surchargeDateBlock,
              update.propertyId,
              update.roomTypeId,
              update.roomRateId,
            );
            setLoading(false);
            setSuccess('Surcharges successfuly added');
          } catch (e) {
            setLoading(false);
            setErrors(e?.message);
            setStepError(3);
          }
        }
      }
    } catch (e) {
      setLoading(false);
      setErrors(e.message);
      setStepError(3);
    }
  }, [selectedRoomRates, selectedSurcharges, propertyId]);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            minHeight: 44,
            p: 2,
            backgroundColor: 'secondary.dark',
            color: 'white',
          }}
        >
          {step === SELECT_OPPORTUNITY_STEP && (
            <Typography variant="h5" component="h5" m={0}>
              Select an offer
            </Typography>
          )}
          {step === SELECT_SALESFORCE_SURCHARGES_STEP && (
            <Typography variant="h5" component="h5" m={0}>
              Select surcharges to import
            </Typography>
          )}
          {step === SELECT_ROOM_RATES_STEP && (
            <Typography variant="h5" component="h5" m={0}>
              Select room rates to apply surcharges to
            </Typography>
          )}
          {step === SUMMARY_STEP && (
            <Typography variant="h5" component="h5" m={0}>
              Review and confirm
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
            position: 'relative',
          }}
        >
          {loading && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              }}
            />
          )}
          <Grid container direction="column" justifyContent="space-between">
            <Grid item xs sx={{ mb: 3, mt: 1 }}>
              <Stepper nonLinear activeStep={step}>
                {stepsList.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                    error?: boolean;
                  } = {};
                  if (isStepFailed(index)) {
                    labelProps.optional = (
                      <Typography variant="caption" color="error">
                        Alert message
                      </Typography>
                    );
                    labelProps.error = true;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton color="inherit" onClick={() => handleStep(index)}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
            <Grid item xs>
              {step === SELECT_OPPORTUNITY_STEP && (
                <OfferListSelection offers={offers} onSelectOffer={setSelectedOffer} selectedOffer={selectedOffer} />
              )}
              {step === SELECT_SALESFORCE_SURCHARGES_STEP && (
                <SurchargesSelection
                  salesforceSurcharges={salesforceSurcharges}
                  selectedSurcharges={selectedSurcharges}
                  onSelectSurcharges={onSelectSurcharges}
                  conflictingSurcharges={conflictingSurcharges}
                />
              )}
              {step === SELECT_ROOM_RATES_STEP && (
                <RoomRateSelection
                  roomRates={filteredRoomRates}
                  propertyId={propertyId}
                  selectedRoomRates={selectedRoomRates}
                  conflictingRoomRates={conflictingRoomRates}
                  onSelectRoomRates={onSelectRoomRates}
                  vendorId={vendorId}
                />
              )}
              {step === SUMMARY_STEP && (
                <SalesforceSurchargesSummary
                  selectedSurcharges={selectedSurcharges}
                  selectedRoomRates={selectedRoomRates}
                  conflictingSurcharges={conflictingSurcharges}
                  conflictingRoomRates={conflictingRoomRates}
                  propertyId={propertyId}
                  vendorId={vendorId}
                  setStep={setStep}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {(errors || success) && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  {errors && (
                    <Alert sx={{ mt: 1 }} severity="error">
                      {errors}
                    </Alert>
                  )}
                  {success && (
                    <Alert sx={{ mt: 1 }} severity="success">
                      {success}
                    </Alert>
                  )}
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          <Grid container direction="row" justifyContent="end" alignItems="center" gap={2}>
            {step > SELECT_OPPORTUNITY_STEP && (
              <Button color="secondary" variant="text" onClick={() => setStep(step - 1)}>
                Back
              </Button>
            )}
            {step < SUMMARY_STEP && (
              <Button variant="contained" onClick={() => handleStep(step + 1)}>
                Continue
              </Button>
            )}
            {step === SUMMARY_STEP && !success && (
              <Button variant="contained" color="success" onClick={mapPayload} disabled={loading}>
                Save
              </Button>
            )}
            {step === SUMMARY_STEP && success && (
              <Button variant="contained" color="secondary" onClick={closeModal} disabled={loading}>
                Close
              </Button>
            )}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
