import { dateNowUtc } from '~/services/TimeService';

function groupDates(dates) {
  let dateGroups = [];

  dates.map(function (date) {
    dateGroups[date.monthGroup] ? dateGroups[date.monthGroup].push(date) : (dateGroups[date.monthGroup] = [date]);
  });
  return dateGroups;
}

// getDateGroup returns an object of dates grouped by months
export default function getDateGroup(dates) {
  return groupDates(
    dates.map((date) => {
      return {
        startDate: dateNowUtc(date.start_date),
        endDate: dateNowUtc(date.end_date),
        monthGroup: date.month_group,
        totalAvailable: date.total_available,
      };
    }),
  );
}
