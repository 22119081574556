import React, { Component } from 'react';

import { Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from '@mui/material';

import ReservationService from '~/services/ReservationService';

import { formatValidationMessages } from '~/utils/formatValidationMessages';
import { reportError } from '~/utils/reportError';

import ErrorListDisplay from '../ErrorListDisplay';

import { buttonMessages, buttonStates } from './states/submitButton';

const DEFAULT_SURCHARGE_ITEM = {
  currency: 'AUD',
  adult_cost: 0,
  adult_amount: 0,
  child_cost: 0,
  child_amount: 0,
  infant_cost: 0,
  infant_amount: 0,
};

type Props = {
  onCancel: () => void;
  onRoomRateExtraGuestSurchargeUpdated: (result: any) => void;
  extraGuestSurchargeItem: any;
  propertyId: string;
  roomTypeId: string;
  roomRateId: string;
  vendorCurrencyCode: any;
};

type State = {
  extraGuestSurchargeItem: any;
  saveState: any;
  errors: any;
};

export default class RoomRateExtraGuestSurchargeForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    const extraGuestSurchargeItem = props.extraGuestSurchargeItem
      ? // deep clone
        JSON.parse(JSON.stringify(props.extraGuestSurchargeItem))
      : { ...DEFAULT_SURCHARGE_ITEM, currency: props.vendorCurrencyCode };

    this.state = {
      extraGuestSurchargeItem,
      saveState: buttonStates.default,
      errors: null,
    };
  }

  setValue(key, value) {
    this.setState({
      extraGuestSurchargeItem: {
        ...this.state.extraGuestSurchargeItem,
        [key]: value,
      },
    });
  }

  submit = () => {
    this.setState({
      saveState: buttonStates.saving,
      errors: null,
    });

    let promise;

    if (this.state.extraGuestSurchargeItem.id) {
      promise = ReservationService.updateExtraGuestSurcharge(
        this.state.extraGuestSurchargeItem,
        this.state.extraGuestSurchargeItem.id,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      );
    } else {
      promise = ReservationService.createExtraGuestSurcharge(
        this.state.extraGuestSurchargeItem,
        this.props.propertyId,
        this.props.roomTypeId,
        this.props.roomRateId,
      );
    }

    return promise
      .then((response) => {
        this.setState({
          saveState: buttonStates.saved,
        });
        this.props.onRoomRateExtraGuestSurchargeUpdated(response.result);
      })
      .catch((e) => {
        const errors = e.name === 'ValidationError' ? e.errors : null;
        this.setState({
          saveState: buttonStates.failed,
          errors,
        });
        reportError(e);
      });
  };

  formRow(label, costFieldName, amountFieldName) {
    return (
      <div>
        <Typography variant="h6" mb={1}>
          {label}
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Cost price"
            onChange={(event) => this.setValue(costFieldName, parseInt(event.target.value))}
            type="number"
            inputProps={{ min: 0 }}
            value={this.state.extraGuestSurchargeItem[costFieldName]}
          />
          <TextField
            label="Sale price"
            onChange={(event) => this.setValue(amountFieldName, parseInt(event.target.value))}
            type="number"
            inputProps={{ min: 0 }}
            value={this.state.extraGuestSurchargeItem[amountFieldName]}
          />
        </Stack>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Typography variant="h6" mb={1}>
          Currency: {this.state.extraGuestSurchargeItem.currency}
        </Typography>

        {this.formRow('Adults', 'adult_cost', 'adult_amount')}
        {this.formRow('Children', 'child_cost', 'child_amount')}
        {this.formRow('Infants', 'infant_cost', 'infant_amount')}
        <FormGroup>
          <FormControlLabel
            key="payable_at_property"
            label="EGS are payable at property"
            control={
              <Checkbox
                onChange={(event) => this.setValue('payable_at_property', event.target.checked)}
                checked={this.state.extraGuestSurchargeItem['payable_at_property'] ?? false}
              />
            }
          />
        </FormGroup>

        <div className="modal-form-group">
          {this.state.errors && <ErrorListDisplay messages={formatValidationMessages(this.state.errors)} />}
        </div>

        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button onClick={this.props.onCancel} variant="text">
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={this.state.saveState === buttonStates.saving}
            onClick={this.submit}
          >
            {buttonMessages[this.state.saveState]}
          </Button>
        </Stack>
      </div>
    );
  }
}
