import React from 'react';

import { Box, Typography } from '@mui/material';

export default function SurchargeRefundVariant(props) {
  return (
    <Box id="SurchargeRefundVariant">
      <Typography>
        The surcharge of {props.surchargeInfo.currency_code} {props.surchargeInfo.price} will be refunded to customer
      </Typography>
    </Box>
  );
}
