import React, { useMemo } from 'react';

import currencyFormatter from 'currency-formatter';

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

enum BOOKING_STATUS {
  PENDING = 'pending',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

function OrderFlightFees({ flightItems, currencyCode }) {
  const [flightsTotal, bookingFee, serviceFee, atolFee, totalFees] = useMemo(() => {
    const fees = flightItems.reduce(
      (total, flightItem) => [
        total[0] + flightItem.total,
        total[1] + flightItem.journey?.bookingFee || 0,
        total[2] + flightItem.journey?.serviceFee || 0,
        total[3] + flightItem.journey?.atolFee || 0,
      ],
      [0, 0, 0, 0],
    );

    // Format fees with currency code
    return [
      currencyFormatter.format(fees[0], { code: currencyCode }),
      fees[1] ? currencyFormatter.format(fees[1], { code: currencyCode }) : 0,
      fees[2] ? currencyFormatter.format(fees[2], { code: currencyCode }) : 0,
      fees[3] ? currencyFormatter.format(fees[3], { code: currencyCode }) : 0,
      currencyFormatter.format(fees[1] + fees[2] + fees[3], {
        code: currencyCode,
      }),
    ];
  }, [flightItems, currencyCode]);

  const bookingStatus = useMemo(() => {
    if (flightItems.every(({ status }) => status === BOOKING_STATUS.COMPLETED)) {
      return BOOKING_STATUS.COMPLETED;
    } else if (
      flightItems.every(({ status }) => status === BOOKING_STATUS.COMPLETED || status === BOOKING_STATUS.PENDING)
    ) {
      return BOOKING_STATUS.PENDING;
    } else {
      return BOOKING_STATUS.FAILED;
    }
  }, [flightItems]);

  return (
    <Box mt={4}>
      <Divider>
        <Typography fontWeight="bold">Flight Fees</Typography>
      </Divider>
      <Grid container spacing={2} ml={1} mt={1}>
        <Grid xs={3}>
          <Stack mt={1} justifyContent="space-between" spacing={2}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography fontWeight="bold">Flights total:</Typography>
              <Typography fontWeight="bold">{flightsTotal}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography fontWeight="bold">Flight booking status:</Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>{bookingStatus}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography fontWeight="bold">Total Flight Fees:</Typography>
              <Typography>{totalFees}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} pl={2} justifyContent="space-between">
              <Typography fontWeight="bold">Booking fee:</Typography>
              <Typography>{bookingFee}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} pl={2} justifyContent="space-between">
              <Typography fontWeight="bold">Service fee:</Typography>
              <Typography>{serviceFee}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} pl={2} justifyContent="space-between">
              <Typography fontWeight="bold">ATOL fee:</Typography>
              <Typography>{atolFee}</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default OrderFlightFees;
