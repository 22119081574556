import React from 'react';

import PropTypes from 'prop-types';

import DateItem from './DateItem';

const DateRadioButtonPicker = ({ startDate, dateGroups, onSelectDate }) => {
  return (
    <div className="radio-datepicker">
      {Object.keys(dateGroups).map(function (dateGroup, i) {
        return (
          <div className="month-wrapper" key={i}>
            <div className="month-body">
              {dateGroups[dateGroup].map(function (date, idx) {
                return <DateItem key={idx} date={date} startDate={startDate} onSelectDate={onSelectDate} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

DateRadioButtonPicker.propTypes = {
  startDate: PropTypes.object,
  dateGroups: PropTypes.array,
  onSelectDate: PropTypes.func,
};

export default DateRadioButtonPicker;
