export function updateMessage(oldMessage: string): string | undefined {
  const nameStart = 'Hi';
  const nameEnd = ',\n\n';
  const oldNoteText1 = 'I’ve created this trip plan for your upcoming escape to ';
  const oldNoteText2 = '. Below is a list of activities and offers tailored for you during your stay at ';
  const oldNoteText3 = 'I hope these recommendations help you make the most of your time in';

  // Check if the oldMessage starts with "Hi" and contains expected markers
  if (
    oldMessage.startsWith(nameStart) &&
    oldMessage.includes(oldNoteText1) &&
    oldMessage.includes(oldNoteText2) &&
    oldMessage.includes(oldNoteText3)
  ) {
    // Extract the first name (if present)
    let name = '';
    const nameIndexStart = oldMessage.indexOf(nameStart) + nameStart.length;
    const nameIndexEnd = oldMessage.indexOf(nameEnd);

    // Check if there is a name or it's just "Hi,"
    if (nameIndexEnd > nameIndexStart) {
      name = oldMessage.slice(nameIndexStart, nameIndexEnd).trim();
    }

    // Extract the hotel name
    const hotelIndexStart = oldMessage.indexOf(oldNoteText2) + oldNoteText2.length;
    const hotelIndexEnd = oldMessage.indexOf('.\n', hotelIndexStart); // Find the next ".\n"
    const hotelName = oldMessage.slice(hotelIndexStart, hotelIndexEnd).trim();

    // New message template with extracted values
    const newMessage = `Hi${name ? ' ' + name : ''},

Exciting news! I've put together a personalised trip plan for your upcoming escape to ${hotelName}, including a recommendation for a private airport transfer. By adding airport transfers, you can ensure a hassle-free arrival, stepping off the plane and gliding straight to your hotel!

With your Trip Plan, you can easily organise your adventure and explore a range of enhancements to make your journey unforgettable. Feel free to add any additional Experiences, from guided tours to spa treatments, culinary adventures and beyond. It’s your trip — make it as extraordinary as you deserve!

Best Regards,
Leo Clarke`;

    return newMessage;
  }

  return undefined;
}
