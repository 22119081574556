import { v4 as uuidv4 } from 'uuid';

import { paths } from '@luxuryescapes/contract-svc-conn-tour-sf';

import { json_headers, request } from './common';

const TWO_MINUTES_IN_MILLISECONDS = 60000 * 2;
const POLL_RATE_IN_SECONDS = 5000;

type CreateOrRefreshSalesforceTourBody =
  paths['/api/connections/tour-sf/tour']['post']['parameters']['body']['payload'];

function basePath() {
  return window.configs.API_HOST + '/api/connections/tour-sf';
}

export function createSalesforceTour(salesforceId: string) {
  return doPollOnTimeout(_createSalesforceTour, [salesforceId]);
}

async function _createSalesforceTour(salesforceId: string) {
  const transactionKey = uuidv4();

  const body: CreateOrRefreshSalesforceTourBody = {
    id: salesforceId,
    op: 'create',
    transactionKey,
  };

  try {
    const response = await request(`${basePath()}/tour`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(body),
    });

    return {
      ...response,
      transactionKey,
    };
  } catch (error) {
    return { transactionKey };
  }
}

export function refreshSalesforceTour(salesforceId: string) {
  return doPollOnTimeout(_refreshSalesforceTour, [salesforceId]);
}

async function _refreshSalesforceTour(salesforceId: string) {
  const transactionKey = uuidv4();

  const body: CreateOrRefreshSalesforceTourBody = {
    id: salesforceId,
    op: 'update',
    transactionKey,
  };

  try {
    const response = await request(`${basePath()}/tour`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(body),
    });

    return {
      ...response,
      transactionKey,
    };
  } catch (error) {
    return { transactionKey };
  }
}

export function getTransactionStatus(transactionId: string) {
  return request(`${basePath()}/transactions/${transactionId}`, {
    method: 'GET',
    headers: json_headers,
  });
}

async function pollTransactionStatus(transactionId: string) {
  const startTimestamp = Date.now();

  for (
    let timestampDifference = Date.now() - startTimestamp, tries = 0;
    timestampDifference <= TWO_MINUTES_IN_MILLISECONDS;
    timestampDifference = Date.now() - startTimestamp
  ) {
    if (timestampDifference >= tries * POLL_RATE_IN_SECONDS) {
      tries++;

      const { status, result } = await getTransactionStatus(transactionId);

      if (status !== 200) {
        break;
      }

      if (result?.statusCode !== 0) {
        let message;
        try {
          message = JSON.parse(result?.response);
        } catch (e) {
          message = result?.response;
        }
        return {
          status: result?.statusCode === 1 ? 200 : 500, // statusCode = 1 => success, -1 => failure
          message,
        };
      }
    }
  }

  return {
    status: 500,
    message: 'Operation failed!',
  };
}

async function doPollOnTimeout(requestFunction: (...args: any[]) => any, args: any[]) {
  let transactionId = null;

  try {
    ({ transactionKey: transactionId } = await requestFunction(...args));

    if (!transactionId) {
      return {
        status: 500,
        message: 'Transaction key is missing',
      };
    }

    return await pollTransactionStatus(transactionId);
  } catch (error) {
    return await pollTransactionStatus(transactionId);
  }
}
