import React from 'react';

import { withRouter } from 'react-router-dom';

import UserActions from './../actions/UserActions';
import utils from './../utils';

class LogoutLink extends React.Component {
  state = {
    disabled: false,
  };

  _performRedirect() {
    const { history, redirectTo } = this.props;
    history.push(redirectTo || '/');
  }

  onClick(e) {
    e.preventDefault();

    if (!this.state.disabled) {
      this.setState({ disabled: true });

      UserActions.logout(() => {
        this._performRedirect();
      });
    }
  }

  render() {
    var selectedProps = utils.excludeProps(
      ['redirectTo', 'href', 'onClick', 'disabled', 'children', 'staticContext'],
      this.props,
    );

    return (
      <a href="#" onClick={this.onClick.bind(this)} disabled={this.state.disabled} {...selectedProps}>
        {this.props.children ? this.props.children : 'Logout'}
      </a>
    );
  }
}

export default withRouter(LogoutLink);
