import React from 'react';

import { useHistory } from 'react-router';

import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { RESULTS_PER_PAGE, usePorts } from '~/components/Cruises/context/usePorts';

const columns: GridColDef[] = [
  {
    field: 'countryCode',
    headerName: 'Country Code',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    display: 'flex',
  },
];

export default function PortsList() {
  const { ports, page, total, paginate } = usePorts();
  const history = useHistory();

  return (
    <Box mt={2}>
      <DataGrid
        className="T-offers-table"
        rows={ports || []}
        columns={columns}
        pagination
        paginationModel={{ page: page - 1, pageSize: RESULTS_PER_PAGE }}
        pageSizeOptions={[10]}
        getRowId={(row: API.CruiseLine) => row.id}
        paginationMode="server"
        rowCount={total}
        onPaginationModelChange={({ page }) => paginate(page + 1)}
        slots={{ pagination: GridPagination }}
        onRowClick={(row: GridRowParams<API.CruiseLine>) => history.push(`/cruises/ports/${row.id}`)}
        autoHeight // dangerous if number of rows per page > 25
      />
    </Box>
  );
}
