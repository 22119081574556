import React from 'react';

import { FormControl, FormControlLabel, Grid, Radio } from '@mui/material';

import { VARIANT_OF_REFUND_ALL } from '../../consts/refund';

import FullRefundVariant from './FullRefundVariant';

interface ExpiredLETourRefundContainerProps {
  handleVariantChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFlagChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTmpChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  surchargeInfo: any;
  tmpValue: any;
  refund: {
    variant_of_refund: string;
  };
}

const ExpiredLETourRefundContainer = ({
  handleVariantChange,
  handleFlagChange,
  handleTmpChange,
  surchargeInfo,
  tmpValue,
  refund,
}: ExpiredLETourRefundContainerProps) => {
  return (
    <FormControl fullWidth component="fieldset" id="ExpiredLETourRefundContainer">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <FormControlLabel
            key={VARIANT_OF_REFUND_ALL}
            control={
              <Radio
                name="variant_of_refund"
                value={VARIANT_OF_REFUND_ALL}
                onChange={handleVariantChange}
                checked={refund.variant_of_refund === VARIANT_OF_REFUND_ALL}
              />
            }
            label="Full refund with Fee"
          />
        </Grid>
      </Grid>
      {refund.variant_of_refund === VARIANT_OF_REFUND_ALL && (
        <FullRefundVariant
          handleFlagChange={handleFlagChange}
          surchargeInfo={surchargeInfo}
          handleTmpChange={handleTmpChange}
          tmpValue={tmpValue}
          isActive={refund.variant_of_refund === VARIANT_OF_REFUND_ALL}
          refund={refund}
          with_fee
        />
      )}
    </FormControl>
  );
};

export default ExpiredLETourRefundContainer;
