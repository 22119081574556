import React, { ChangeEvent, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';

import useQuery from '~/hooks/useQuery';

import { GetPromoCodeProps, getPromoMeta } from '~/services/PromoService';

import { getPromoTagArrays } from '~/utils/promoTags';

import Spinner from '../Spinner';

interface Props {
  searchQuery: (query: GetPromoCodeProps) => void;
  isLoading: boolean;
}

const PromoSearchForm = ({ searchQuery, isLoading }: Props) => {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [promoCodeName, setPromoCodeName] = useState(query.get('name') ?? '');
  const [caseId, setCaseId] = useState(query.get('caseId'));

  const [showErrors, setShowErrors] = useState(query.get('showErrors') == 'true');
  const [userIdInteracted, setUserIdInteracted] = useState(query.get('userIdInteracted'));

  const [promoEmailFilter, setPromoEmailFilter] = useState(query.get('email') ?? '');
  const [expiresAfterToday, setExpiresAfterToday] = useState<boolean>(query.get('expiresAfter') != 'false');
  const [noChildPromos, setNoChildPromos] = useState<boolean>(query.get('noChildPromos') == 'true');
  const [noReferralPromos, setNoReferralPromos] = useState<boolean>(query.get('noReferralPromos') == 'true');

  const [promoTagsLoading, setPromoTagsLoading] = useState<boolean>(true);
  const [promoTags, setPromoTags] = useState<Array<App.PromoTag>>(null);
  const [promoDeptTagOptions, setPromoDeptTagOptions] = useState<Array<string>>([]);
  const [promoDeptTag, setPromoDeptTag] = useState<string>(query.get('dept_tag'));
  const [promoCategoryTagOptions, setPromoCategoryTagOptions] = useState<Array<string>>([]);
  const [promoCategoryTag, setPromoCategoryTag] = useState<string>(query.get('category_tag'));
  const [promoSubCategoryTagOptions, setPromoSubCategoryTagOptions] = useState<Array<string>>([]);
  const [promoSubCategoryTag, setPromoSubCategoryTag] = useState<string>(query.get('sub_category_tag'));

  const [isDev, setIsDev] = useState<string>(query.get('isDev'));

  const [showAdvanced, setShowAdvanced] = useState<boolean>(
    Boolean(promoDeptTag) ||
      Boolean(promoCategoryTag) ||
      Boolean(promoSubCategoryTag) ||
      Boolean(noChildPromos) ||
      Boolean(noReferralPromos) ||
      Boolean(caseId),
  );

  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    const query: GetPromoCodeProps = {
      codeName: promoCodeName,
      page: 1,
      limit: 10,
      brand: brand,
      caseId,
      userIdInteracted: userIdInteracted,
      emailAddress: promoEmailFilter,
      expiresAfter: expiresAfterToday ? new Date().toISOString() : '',
      noChildPromos: !!noChildPromos,
      excludeReferralPromos: noReferralPromos,
      deptTag: promoDeptTag,
      categoryTag: promoCategoryTag,
      subCategoryTag: promoSubCategoryTag,
      showErrors: showErrors,
      isDev: isDev == 'true',
    };
    searchQuery(query);
  };

  const onChange =
    (setState: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
      setState(event.target.value);
    };

  const handleClearAll = (event) => {
    event.preventDefault();
    setPromoCodeName('');
    setPromoEmailFilter('');
    setCaseId('');
    setExpiresAfterToday(true);
    setNoChildPromos(false);
    setNoReferralPromos(false);
    setPromoDeptTag('');
    setPromoCategoryTag('');
    setPromoSubCategoryTag('');
    setIsDev('');
    history.push({ search: null });
  };

  const refreshMetaOptions = (promoTags: Array<App.PromoTag>, selectedDepartment: string, selectedCategory: string) => {
    const tagArrays = getPromoTagArrays({ promoTags, selectedDepartment, selectedCategory });
    setPromoDeptTagOptions(tagArrays.departmentTags);
    setPromoCategoryTagOptions(tagArrays.categoryTags);
    setPromoSubCategoryTagOptions(tagArrays.subCategoryTags);
    if (tagArrays.categoryTags.length == 1) {
      setPromoCategoryTag(tagArrays.categoryTags[0]);
    } else if (tagArrays.categoryTags.length == 0) {
      setPromoCategoryTag('');
    }
    if (tagArrays.subCategoryTags.length == 1) {
      setPromoSubCategoryTag(tagArrays.subCategoryTags[0]);
    } else if (tagArrays.subCategoryTags.length == 0) {
      setPromoSubCategoryTag('');
    }
  };

  useEffect(() => {
    if (promoTags !== null) {
      return;
    }
    setPromoTagsLoading(true);
    getPromoMeta()
      .then((res) => {
        setPromoTags(res.result.promo_tags);
      })
      .catch((e) => {
        enqueueSnackbar(e.message);
      })
      .finally(() => {
        setPromoTagsLoading(false);
      });
  }, [promoTags, enqueueSnackbar]);

  const searchAll = () => {
    setPromoCodeName('ALL');
    handleSubmit(new Event('submit', { cancelable: true }));
  };

  useEffect(() => {
    if (promoTags != null) {
      refreshMetaOptions(promoTags, promoDeptTag, promoCategoryTag);
    }
  }, [promoTags, promoDeptTag, promoCategoryTag]);

  const isSearching =
    isLoading && (promoCodeName !== '' || userIdInteracted !== '' || caseId !== '' || promoEmailFilter !== '');
  const isClearable =
    promoCodeName ||
    userIdInteracted ||
    caseId ||
    promoEmailFilter ||
    expiresAfterToday ||
    noChildPromos ||
    promoDeptTag ||
    promoCategoryTag ||
    promoSubCategoryTag;

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2} mt={2}>
          <TextField
            label="Promo code name"
            placeholder="Promo code name"
            value={promoCodeName}
            onChange={onChange(setPromoCodeName)}
            fullWidth
          />
          <Button variant="contained" className="T-promo-search-button" type="submit" disabled={isSearching}>
            {isSearching ? 'Searching…' : 'Search'}
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} mt={2}>
          <TextField
            id="UserIdInteracted"
            label="Filter by User ID Interacted"
            placeholder="User ID - includes codes requested, used/logged, or created by"
            value={userIdInteracted}
            onChange={onChange(setUserIdInteracted)}
            disabled={isSearching}
            fullWidth
          />
        </Stack>
        <Stack direction="row">
          <FormControlLabel
            control={
              <Checkbox
                checked={!!expiresAfterToday}
                onChange={onChange(() => setExpiresAfterToday(!expiresAfterToday))}
              />
            }
            label="Hide Expired"
            title="Only return Promo Codes that have not expired"
          />
        </Stack>
        <Box>
          <Button variant="text" onClick={() => setShowAdvanced(!showAdvanced)}>
            Advanced Options
          </Button>
        </Box>
        {showAdvanced && (
          <Stack direction="column">
            <Stack direction="row" spacing={2}>
              <TextField
                id="CaseIdSearch"
                label="Case Id"
                placeholder="Case Id"
                value={caseId}
                onChange={onChange(setCaseId)}
                disabled={isSearching}
                fullWidth
              />
              <TextField
                label="Promo Email Restriction"
                placeholder="Filter by email address restriction"
                title="Filter by email address restriction (note: does not currently include promo codes used by a specific user, only promo codes that are restricted to a specific email address)"
                value={promoEmailFilter}
                onChange={onChange(setPromoEmailFilter)}
                fullWidth
              />
              <DummyButton isSearching={isSearching} />
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <FormControlLabel
                control={
                  <Checkbox checked={!!noChildPromos} onChange={onChange(() => setNoChildPromos(!noChildPromos))} />
                }
                label="Hide child promos"
                title="Hide Promo Codes that have been cloned from another promo (i.e. child promos)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!noReferralPromos}
                    onChange={onChange(() => setNoReferralPromos(!noReferralPromos))}
                  />
                }
                label="Exclude Referral Promo"
                title="Check this box to hide Promo Codes that are the result of a referral event"
              />
              <FormControlLabel
                control={<Checkbox checked={!!showErrors} onChange={onChange(() => setShowErrors(!showErrors))} />}
                label="Show Errors"
                title="Search results will include (wip) warnings about the promos codes returned"
              />
            </Stack>
            <Stack direction="row" mt={2}>
              {promoTagsLoading ? <Spinner /> : null}
              <Box sx={{ flex: 1 }}>
                <Autocomplete
                  value={promoDeptTag}
                  options={promoDeptTagOptions}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Department" />}
                  onChange={(e: unknown, newValue: string | null) => {
                    setPromoDeptTag(newValue);
                    setPromoCategoryTag(null);
                    setPromoSubCategoryTag(null);
                  }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Autocomplete
                  value={promoCategoryTag}
                  options={promoCategoryTagOptions}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Category" />}
                  onChange={(e: unknown, newValue: string | null) => {
                    setPromoCategoryTag(newValue);
                    setPromoSubCategoryTag(null);
                  }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Autocomplete
                  value={promoSubCategoryTag}
                  options={promoSubCategoryTagOptions}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Sub Category" />}
                  onChange={(e: unknown, newValue: string | null) => {
                    setPromoSubCategoryTag(newValue);
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <Button variant="contained" className="T-promo-search-button" disabled={isSearching} onClick={searchAll}>
                Search all
              </Button>
              {isClearable && (
                <Button variant="text" onClick={handleClearAll} disabled={isLoading}>
                  Clear All
                </Button>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </form>
  );
};

const DummyButton = ({ isSearching }) => (
  // invisible dummy button that maintains layout space
  <Button variant="contained" className="T-promo-search-button" style={{ visibility: 'hidden' }}>
    {isSearching ? 'Searching…' : 'Search'}
  </Button>
);

export default PromoSearchForm;
