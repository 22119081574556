import React, { useContext, useEffect, useState } from 'react';

import { EXPERIENCE_CONTENT_TAB, EXP_PROVIDER_REZDY, ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

import * as ES from '~/services/ExperiencesService';

type ExperienceValues = ES.ExperienceOffer;
export type ExperiencePayload = Omit<ES.CurationParams, 'tickets'> & {
  imagesToUpload?: File[];
  tickets?: ES.TicketsPayload[];
  imagesToRemove?: string[];
};

const tabsConstants = [EXPERIENCE_CONTENT_TAB, ORIGINAL_EXPERIENCE_CONTENT_TAB] as const;
export type ContentTab = (typeof tabsConstants)[number];

interface ExperienceCurationData {
  values: ExperienceValues;
  updateValues: (values: ExperiencePayload) => void;
  payload: ExperiencePayload;
  contentTab: ContentTab;
  handleOriginalExperienceValues: (originalExperience: ES.ExperienceOffer) => void;
  handleContentTab: (tab: ContentTab) => void;
}

interface Props {
  children: React.ReactElement;
  experience: ES.ExperienceOffer;
}

const ExperienceCurationContext: React.Context<ExperienceCurationData> = React.createContext(
  {} as ExperienceCurationData,
);

export const ExperienceCurationProvider = ({ children, experience }: Props): JSX.Element => {
  const [contentTab, setContentTab] = useState<ContentTab>(EXPERIENCE_CONTENT_TAB);
  const [experienceValues, setExperienceValues] = useState<ExperienceValues>(null);
  const [originalExperienceValues, setOriginalExperienceValues] = useState<ExperienceValues>(null);
  const [values, setValues] = useState<ExperienceValues>(null);
  const [payload, setPayload] = useState<ExperiencePayload>(null);

  const isRezdy = experience?.provider === EXP_PROVIDER_REZDY;

  useEffect(() => {
    if (experience) {
      setValues(experience);
      setExperienceValues(experience);
    }
    return () => {
      setValues(null);
      setPayload(null);
      setExperienceValues(null);
      setOriginalExperienceValues(null);
      setContentTab(EXPERIENCE_CONTENT_TAB);
    };
  }, [experience]);

  const updateValues = (updatedValues) => {
    if (isRezdy && contentTab === EXPERIENCE_CONTENT_TAB) {
      setExperienceValues((experienceValues) => ({
        ...experienceValues,
        ...updatedValues,
      }));
    }
    setValues((values) => ({ ...values, ...updatedValues }));
    setPayload((payload) => ({ ...payload, ...updatedValues }));
  };

  const handleContentTab = (tab: ContentTab) => {
    tab === EXPERIENCE_CONTENT_TAB ? setValues(experienceValues) : setValues(originalExperienceValues);
    setContentTab(tab);
  };

  const handleOriginalExperienceValues = (originalExperience: ES.ExperienceOffer) => {
    setOriginalExperienceValues(originalExperience);
    setValues(originalExperience);
  };

  return (
    <ExperienceCurationContext.Provider
      value={{
        values,
        payload,
        contentTab,
        updateValues,
        handleContentTab,
        handleOriginalExperienceValues,
      }}
    >
      {children}
    </ExperienceCurationContext.Provider>
  );
};

export const useUpdateExperienceValues = (): ExperienceCurationData => useContext(ExperienceCurationContext);
