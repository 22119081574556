import React, { useState } from 'react';

import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { createOrUpdateBundle } from '~/services/PromoService';

import ErrorDisplay from '../Common/ErrorDisplay';
import Spinner from '../Common/Spinner';
import { withTenant } from '../hoc';

const uiSchema = {
  code_name: {
    'ui:placeholder': 'TSVOUCHER',
  },
  offer_id: {
    'ui:placeholder': '0062y00000G1vHQAAZ',
  },
  landing_page_url: {
    'ui:placeholder':
      '/experience/gold-coast-exclusive-professional-photoshoot-packages-at-your-chosen-location-with-edited-photo-gallery/led:0062y00000C4FKYAA3',
  },
};

const schema: RJSFSchema = {
  type: 'object',
  required: ['code_name', 'offer_id', 'landing_page_url'],
  properties: {
    code_name: {
      type: 'string',
      title: 'Enter promo code name',
    },
    offer_id: {
      type: 'string',
      title: 'Enter offer id',
    },
    landing_page_url: {
      type: 'string',
      title: 'Enter landing page URL',
    },
  },
};

type BundledFormDataType = {
  bundled_id: string;
  offer_id: string;
  cooldown_period?: number;
  landing_page_url: string;
  code_name?: string;
  isUpdate: boolean;
};

interface Props {
  tenant: App.Tenant;
  bundledFormData?: BundledFormDataType;
  setBundledFormData: (bundledFormData) => void;
  fetchData: () => void;
  clearSearch?: () => void;
}

function BundleForm({ tenant, bundledFormData, setBundledFormData, fetchData, clearSearch }: Props) {
  const [creationResult, setCreationResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (form) => {
    try {
      setIsLoading(true);

      const bundledPayload = {
        code_name: form.formData.code_name,
        offer_id: form.formData.offer_id,
        cooldown_period: form.formData.cooldown_period,
        landing_page_url: form.formData.landing_page_url,
        brand: tenant.brand,
        bundled_id: bundledFormData.bundled_id,
      };

      const response = await createOrUpdateBundle(bundledPayload);

      setCreationResult({ ...response, status: 'success' });
      // we set new form data to refresh form with updated data
      setBundledFormData({
        bundled_id: response.result.bundled_id,
        code_name: form.formData.code_name,
        offer_id: response.result.offer_id,
        cooldown_period: response.result.cooldown_period,
        landing_page_url: response.result.landing_page_url,
        isUpdate: bundledFormData.isUpdate,
      });
      setIsLoading(false);
      // clear search state so that after create/update we get full bundled list
      clearSearch();
      await fetchData();
    } catch (e) {
      setBundledFormData({
        bundled_id: bundledFormData.bundled_id,
        code_name: form.formData.code_name,
        offer_id: form.formData.offer_id,
        cooldown_period: form.formData.cooldown_period,
        landing_page_url: form.formData.landing_page_url,
        isUpdate: bundledFormData.isUpdate,
      });
      setCreationResult({ message: e.message, status: 'reject' });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>Please enter single values only</div>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        formData={bundledFormData}
        validator={validator}
        disabled={isLoading}
      >
        <Button sx={{ width: 100 }} type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? <Spinner size={20} /> : 'Submit'}
        </Button>
      </Form>

      {creationResult?.status === 'reject' && (
        <ErrorDisplay
          message={`${tenant.brand.toUpperCase()}: ${creationResult.message ? creationResult.message : 'Unexpected error'
            }`}
        />
      )}
      {creationResult?.status === 'success' && (
        <Alert severity="success">{`Bundle ${bundledFormData.isUpdate ? 'updated' : 'created'} successfully!`}</Alert>
      )}
    </div>
  );
}

BundleForm.defaultProps = {
  BundleForm: {},
};

export default withTenant(BundleForm);
// used for testing
export { BundleForm as PureBundleForm };
