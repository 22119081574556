import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Button, Dialog, DialogContent, DialogTitle, FormControl, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import PageHeader from '~/components/Common/Elements/PageHeader';

import useToggleState from '~/hooks/useToggleState';

import { deleteBundleById, getBundleByOfferIdRegionAndBrand } from '~/services/PromoService';

import DeleteBundle from '../Common/Modals/DeleteBundle';
import { withTenant } from '../hoc';

import BundledForm from './BundledForm';

interface Props {
  tenant: App.Tenant;
}

const BundledContainer = ({ tenant }: Props) => {
  const [dataState, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isToggled: isModalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggleState();
  const [bundledFormData, setBundledFormData] = useState(null);
  const [offerState, setOffer] = useState<string>(undefined);
  const [regionState, setRegion] = useState<string>(undefined);

  const [bundleToDelete, setBundleToDelete] = useState(null);
  const {
    isToggled: isDeleteMessageModalOpen,
    toggleOn: openDeleteMessageModal,
    toggleOff: closeDeleteMessageModal,
  } = useToggleState();

  const columns: GridColDef[] = [
    {
      field: 'code_name',
      headerName: 'Promo Code Name',
      flex: 3,
      minWidth: 200,
      display: 'flex',
    },
    {
      field: 'offer_id',
      headerName: 'Offer ID',
      flex: 3,
      minWidth: 200,
      display: 'flex',
    },
    {
      field: 'landing_page_url',
      headerName: 'Landing Page Url',
      flex: 3,
      minWidth: 500,
      display: 'flex',
    },
    {
      field: 'active',
      headerName: 'Actions',
      flex: 3,
      minWidth: 100,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <Button
            color="error"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setBundleToDelete({ bundle_id: params.row.bundled_id });
              openDeleteMessageModal();
            }}
            disabled={isLoading}
          >
            Delete
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              editBundle(params.row.bundled_id);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const bundles = await getBundleByOfferIdRegionAndBrand(offerState, regionState, tenant.brand);
    setData(bundles.result ?? []);
    setIsLoading(false);
  }, [offerState, regionState, tenant.brand]);

  useEffect(() => {
    fetchData();
  }, [tenant, fetchData, offerState, regionState]);

  const deleteBundle = async () => {
    try {
      setIsLoading(true);
      await deleteBundleById(bundleToDelete);
      // clear search state so that after delete we get full bundled list
      clearSearchFilters();
      await fetchData();
      setBundleToDelete(null);
      closeDeleteMessageModal();
    } finally {
      setIsLoading(false);
    }
  };

  const editBundle = (bundled_id: string) => {
    setBundledFormData(null);
    const editItem = dataState.find((data) => data.bundled_id === bundled_id);
    setBundledFormData({
      bundled_id: editItem.bundled_id,
      offer_id: editItem.offer_id,
      cooldown_period: editItem.cooldown_period,
      landing_page_url: editItem.landing_page_url,
      code_name: editItem.code_name,
      isUpdate: true,
    });
    openModal();
  };

  const createBundle = () => {
    setBundledFormData({ isUpdate: false });
    openModal();
  };

  const handleSearch = (value, stateSetter) => {
    // TODO: this should be handled by svc-promo
    return value ? stateSetter(value) : stateSetter(undefined);
  };

  const clearSearchFilters = () => {
    setRegion(undefined);
    setOffer(undefined);
  };

  return (
    <div>
      <Helmet>
        <title>Bundles</title>
      </Helmet>
      <PageHeader title="Search Bundles">
        <Button onClick={createBundle} variant="contained">
          Add Bundle
        </Button>
      </PageHeader>
      <div>Note: Bundles are an exclusive feature for Travelshoot only</div>
      <Stack direction="row" spacing={4} mb={2} mt={4}>
        <FormControl variant="standard">
          <label htmlFor="offer-id-input">Select offer</label>
          <TextField
            autoFocus
            id="offer-id-input"
            onChange={(e) => handleSearch(e.target.value, setOffer)}
            placeholder="Enter offer ID"
            type="text"
            value={offerState}
            variant="outlined"
          />
        </FormControl>
      </Stack>
      <DataGrid autoHeight columns={columns} getRowId={(row) => row.bundled_id} loading={isLoading} rows={dataState} />
      {deleteBundle && (
        <DeleteBundle
          openModal={isDeleteMessageModalOpen}
          closeModal={closeDeleteMessageModal}
          deleteBundle={deleteBundle}
          isLoading={isLoading}
        />
      )}
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>{`${bundledFormData?.isUpdate ? 'Update' : 'Create'} Bundle`}</DialogTitle>
        <DialogContent>
          <BundledForm
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            bundledFormData={bundledFormData}
            setBundledFormData={setBundledFormData}
            fetchData={fetchData}
            clearSearch={clearSearchFilters}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withTenant(BundledContainer);
// used for testing
export { BundledContainer as PureBundleContainer };
