import React from 'react';

import { Checkbox } from '@mui/material';

type Props = {
  onGroupToggle: (index: number) => void;
  getAmenities: (amenities: App.Amenity[]) => string;
  getEditUrl: (id: string) => string;
  value: {
    index: number;
    group: App.AmenityGroup & { checked: boolean };
  };
};

export default function SortableItem({ onGroupToggle, getAmenities, getEditUrl, value }: Props) {
  return (
    <li className="sortable-amenity-group" data-list-order={value.group.order}>
      <div className="sortable-amenity-group-inner">
        <div className="sortable-amenity-group-inner-left">
          <Checkbox checked={value.group.checked} onChange={(event) => onGroupToggle(value.index)} />
          <div className="sortable-amenity-group-index">{value.index + 1}.</div>{' '}
          <div className="sortable-amenity-group-location"> {value.group.name} </div>
          <span>&nbsp;| {getAmenities(value.group.amenities)} </span>
        </div>
        <div className="sortable-amenity-group-inner-right">
          <div className="sortable-amenity-group-link">
            <a href={getEditUrl(value.group.id)} target="_blank" rel="noreferrer">
              Edit
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}
