import React from 'react';

import PropTypes from 'prop-types';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Alert, Box, Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import UserActions from '../actions/UserActions';
import context from '../context';
import utils from '../utils';

class DefaultUserProfileForm extends React.Component {
  static contextTypes = {
    user: PropTypes.object,
  };

  state = {
    showPasswordVerification: false,
    showPassword: false,
    showExistingPassword: false,
  };

  onPasswordChanged = (e) => {
    this.setState({
      showPasswordVerification: e.target.value.length > 0,
    });
  };

  handleMouseDownPassword = (e) => e.preventDefault();

  toggleShowPassword = () => this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

  toggleShowExistingPassword = () =>
    this.setState((prevState) => ({
      showExistingPassword: !prevState.showExistingPassword,
    }));

  render() {
    const { showPassword, showExistingPassword } = this.state;

    return (
      <UserProfileForm {...this.props}>
        <Grid mt={4} justifyContent="center" container>
          <Grid xs={12} md={8} lg={4}>
            <Stack spacing={2} className="sp-update-profile-form">
              <TextField
                label="First name"
                id="givenName"
                name="givenName"
                type="text"
                placeholder="John"
                defaultValue={this.context.user.givenName}
                fullWidth
                required
              />

              <TextField
                label="Last name"
                id="surname"
                name="surname"
                type="text"
                placeholder="Snow"
                defaultValue={this.context.user.surname}
                fullWidth
                required
              />

              <TextField
                label="Email"
                id="email"
                name="email"
                type="email"
                placeholder="john.snow@example.com"
                defaultValue={this.context.user.email}
                fullWidth
                required
              />

              <TextField
                label="Password"
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="keep-it-secret"
                onChange={this.onPasswordChanged}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.toggleShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />

              <div>
                {this.state.showPasswordVerification && (
                  <TextField
                    label="Existing password"
                    id="existingPassword"
                    name="existingPassword"
                    type={showExistingPassword ? 'text' : 'password'}
                    placeholder="keep-it-secret"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.toggleShowExistingPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {showExistingPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    required
                  />
                )}
              </div>

              <Box key="update-button">
                <Alert severity="error" data-spif="form.error">
                  <span data-spBind="form.errorMessage" />
                </Alert>

                <Button variant="contained" type="submit">
                  <span data-spif="!form.processing">Update</span>
                  <span data-spif="form.processing">Updating...</span>
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </UserProfileForm>
    );
  }
}

export default class UserProfileForm extends React.Component {
  static contextTypes = {
    user: PropTypes.object,
  };

  state = {
    fields: {},
    defaultFields: this.context.user,
    errorMessage: null,
    isFormProcessing: false,
    isFormSuccessful: false,
  };

  _updateSessionData = (data, callback) => {
    var sessionStore = context.sessionStore;

    if (!sessionStore.empty()) {
      var hasChanged = false;
      var { account } = utils.clone(sessionStore.get());

      for (var key in data) {
        if (key in account) {
          if (account[key] != data[key]) {
            hasChanged = true;
            account[key] = data[key];
          }
        }
      }

      if (hasChanged) {
        context.userStore.resolveSession(callback, true);
      } else {
        return callback();
      }
    }
  };

  _onFormSubmit(e) {
    e.preventDefault();
    e.persist();

    var next = (err, data) => {
      if (err) {
        return this.setState({
          isFormProcessing: false,
          isFormSuccessful: false,
          errorMessage: err.message,
        });
      }

      // If the user didn't specify any data,
      // then simply default to what we have in state.
      data = data || this.state.fields;

      UserActions.updateProfile(data, (err) => {
        if (err) {
          return this.setState({
            isFormProcessing: false,
            isFormSuccessful: false,
            errorMessage: err.message,
          });
        }

        this._updateSessionData(data, () => {
          this.setState({
            isFormProcessing: false,
            isFormSuccessful: true,
            errorMessage: null,
          });
        });
      });
    };

    this.setState({
      isFormProcessing: true,
    });

    const formData = new FormData(e.target);
    const fields = {
      givenName: formData.get('givenName'),
      surname: formData.get('surname'),
      email: formData.get('surname'),
    };

    const password = formData.get('password');
    if (password) {
      fields['password'] = password;
      fields['existingPassword'] = formData.get('existingPassword');
    }

    if (this.props.onSubmit) {
      e.data = fields;
      this.props.onSubmit(e, next);
    } else {
      return next(null, fields);
    }
  }

  _mapFormFieldHandler(element, tryMapField) {
    var defaultValue = element.props.name ? utils.getFieldValue(this.state.defaultFields, element.props.name) : null;

    utils.mapFormField(element, tryMapField, defaultValue);
  }

  _spIfHandler(action) {
    var test = null;

    switch (action) {
      case 'form.successful':
        test = this.state.isFormSuccessful;
        break;
      case 'form.processing':
        test = this.state.isFormProcessing;
        break;
      case 'form.error':
        test = !!this.state.errorMessage;
        break;
    }

    return test;
  }

  _spBindHandler(bind, element) {
    var result = false;

    switch (bind) {
      case 'form.errorMessage':
        var className = element.props ? element.props.className : null;
        result = <span className={className}>{this.state.errorMessage}</span>;
        break;
    }

    return result;
  }

  render() {
    if (this.props.children) {
      let selectedProps = utils.excludeProps(['onSubmit', 'children'], this.props);

      return (
        <form onSubmit={this._onFormSubmit.bind(this)} {...selectedProps}>
          {utils.makeForm(
            this,
            this._mapFormFieldHandler.bind(this),
            this._spIfHandler.bind(this),
            this._spBindHandler.bind(this),
          )}
        </form>
      );
    } else {
      return <DefaultUserProfileForm {...this.props} />;
    }
  }
}
