import React from 'react';

import { dateNowUtc, formatDateISO } from '~/services/TimeService';

import { createItem, finalizeOrder, getOrder } from '../../../../services/OrdersService';
import ReservationService from '../../../../services/ReservationService';
import { getAddonItemPayload } from '../../../../utils/cart';
import Payment from '../../../Common/Payment';

export default class AddItemPayment extends React.Component {
  handleOrder = async (cart, brand) => {
    const { offers, customer, orderId } = cart;

    const promises = [];
    const addonPromises = [];

    offers.forEach((offer) =>
      offer.items.forEach((item) => {
        const data = {
          customer_email: customer.email,
          customer_id: customer.memberId,
          transaction_key: item.transactionKey,
          check_in: formatDateISO(dateNowUtc(item.reservation.checkIn)),
          check_out: formatDateISO(dateNowUtc(item.reservation.checkOut)),
          number_of_adults: item.reservation.numAdults,
          number_of_children: item.reservation.numChildren,
          number_of_infants: item.reservation.numInfants || 0,
          guest_first_name: item.reservation.firstname,
          guest_last_name: item.reservation.lastname,
          guest_special_requests: item.reservation.guestSpecialRequests,
          is_surcharge_waived: !!item.reservation.waivedSurchargeAmount,
          peak_period_surcharge: item.reservation.durationSurchargeTotal,
          extra_guest_surcharge: item.reservation.extraGuestSurcharge,
        };

        promises.push(ReservationService.createReservation(data, orderId, item.id));
      }),
    );

    await Promise.all(promises);

    offers.forEach((offer) =>
      offer.addons.map((addon) => {
        const data = getAddonItemPayload({
          cartItem: addon,
          offer,
          brand,
          packageId: offer.items.find((item) => item.id === addon.itemId)?.pkg.id_salesforce_external,
        });

        addonPromises.push(createItem(orderId, data));
      }),
    );
    await Promise.all(addonPromises);

    const orderResponse = await getOrder(orderId);
    return orderResponse.result;
  };

  onAfterPayment = (cart, orderId) => {
    finalizeOrder(orderId);
  };

  render() {
    return <Payment handleOrder={this.handleOrder} onAfterPayment={this.onAfterPayment} />;
  }
}
