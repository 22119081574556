import React from 'react';

import { RESERVATION_TYPE_BOOK_LATER } from '~/consts/reservation';

import Checkbox from '../Legacy/Checkbox';

import GuestPicker from './GuestPicker';
import PrimaryGuestName from './PrimaryGuestName';
import SpecialRequests from './SpecialRequests';
import StateOfResidenceSelect from './StateOfResidenceSelect';
import TravelDates from './TravelDates';

type Props = {
  item: App.CartItem;
  package: App.CartAccommodationPackage;
  currencyCode: string;
  allowToggleReservationType: boolean;
  stateOfResidence: string;
  reservationType?: string;
  onToggleReservationType: () => void;
  onToggleBookingDates: (item: App.CartItem) => void;
  onUpdateReservation: (item: App.CartItem, data: any) => void;
  onUpdateStateOfResidence: (stateOfResidence: string) => void;
};

export default class BookingFormHotel extends React.Component<Props> {
  checkBookLater = () => {
    const { item } = this.props;
    const { reservation } = item;

    return reservation.reservationType === RESERVATION_TYPE_BOOK_LATER;
  };

  handleToggleReservationType = () => {
    this.props.onToggleReservationType();
  };

  handleStartBookingDates = () => {
    const { onToggleBookingDates, item } = this.props;
    const isBookLater = this.checkBookLater();

    if (isBookLater) {
      return;
    }

    onToggleBookingDates(item);
  };

  render() {
    const {
      item,
      package: offerPkg,
      onUpdateReservation,
      onUpdateStateOfResidence,
      allowToggleReservationType,
      stateOfResidence,
    } = this.props;
    const { reservation } = item;

    const isBookLater = this.checkBookLater();

    const capacities = offerPkg.room_type.room_rates.find((rate) => rate.id === offerPkg.fk_room_rate_id).capacities;

    return (
      <div className="booking-form">
        <div className="booking-form-row">
          <div className="booking-form-item">
            <label>Guests</label>
            <GuestPicker
              capacities={capacities}
              numAdults={reservation.numAdults}
              numChildren={reservation.numChildren}
              numInfants={reservation.numInfants}
              onUpdateReservation={(data) => {
                onUpdateReservation(item, data);
              }}
              disabled={isBookLater}
            />
          </div>
          <PrimaryGuestName
            firstname={reservation.firstname}
            lastname={reservation.lastname}
            setPrimaryGuest={(data) => {
              onUpdateReservation(item, data);
            }}
            isBookLater={isBookLater}
          />
        </div>
        {this.props.currencyCode === 'USD' && (
          <div className="booking-form-row">
            <div className="booking-form-item">
              <label>State of Residence</label>
              <StateOfResidenceSelect
                selectedValue={stateOfResidence}
                onUpdateStateOfResidence={(data) => {
                  onUpdateStateOfResidence(data);
                }}
              />
            </div>
          </div>
        )}
        <div className="booking-form-row">
          <div className="booking-form-item">
            <label>Travel Dates</label>
            <TravelDates
              startDate={reservation.checkIn}
              endDate={reservation.checkOut}
              onStartBookingDates={this.handleStartBookingDates}
              disabled={isBookLater}
            />
          </div>
        </div>

        {allowToggleReservationType && (
          <div className="booking-form-row">
            <Checkbox
              buttonClassName="T-toggle-reservation-type"
              labelText="Buy Now Book Later"
              checked={isBookLater}
              onToggle={this.handleToggleReservationType}
            />
          </div>
        )}

        {!isBookLater && (
          <div className="booking-form-row">
            <SpecialRequests
              value={reservation.guestSpecialRequests || ''}
              onUpdateReservation={onUpdateReservation}
              item={item}
            />
          </div>
        )}
      </div>
    );
  }
}
