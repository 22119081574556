import { useContext } from 'react';

import { AccommodationPropertyDetailsContext } from './AccommodationPropertyDetailsContext';

export default function useAccommodationPropertyDetailsContext() {
  const context = useContext(AccommodationPropertyDetailsContext);

  if (!context) {
    throw new Error(
      'useAccommodationPropertyDetailsContext must be used within a AccommodationPropertyDetailsContextProvider',
    );
  }

  return context;
}
