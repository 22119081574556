import React, { useState } from 'react';

import fileDownload from 'react-file-download';

import { Alert, Button, Stack } from '@mui/material';

import { queueMusementPaidReport } from '~/services/ReportingService';

const ErrorMessage = () => (
  <Alert severity="error">
    <strong>Oops!</strong>
    <p>Occurred an error while downloading.</p>
  </Alert>
);

export type ExportStatus = 'not-started' | 'completed' | 'failed' | 'pending';

const ExportMusementPaidForm = () => {
  const [exportStatus, setExportStatus] = useState<ExportStatus>('not-started');

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const startAt = event.target.startAt.value;
    const endAt = event.target.endAt.value;

    setExportStatus('pending');

    const data = queueMusementPaidReport({ startAt, endAt });

    const fileName = `musement-payments-between-${startAt}-and-${endAt}.csv`;

    data
      .then((text) => {
        fileDownload(text, fileName);
        setExportStatus('completed');
      })
      .catch(() => {
        setExportStatus('failed');
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {exportStatus === 'failed' && <ErrorMessage />}

      <Stack direction="row" spacing={2}>
        <Stack direction="column">
          <label htmlFor="startDate">Start date</label>
          <input type="date" name="startAt" />
        </Stack>

        <Stack direction="column">
          <label htmlFor="endDate">End date</label>
          <input type="date" name="endAt" />
        </Stack>
      </Stack>

      <Button sx={{ mt: 2 }} type="submit" variant="contained" disabled={exportStatus === 'pending'}>
        {exportStatus !== 'pending' ? 'Download report' : 'Downloading...'}
      </Button>
    </form>
  );
};

export default ExportMusementPaidForm;
