import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import Spinner from '~/components/Common/Spinner';

import { MappedAuditLog, getLogsByUrl, groupLogsByModelId } from '~/services/AuditService';
import { sortByDate } from '~/services/TimeService';

import BlackoutDateBlockHistory from './BlackoutDateBlockHistory';

interface Props {
  propertyId: string;
  channelManaged: boolean;
  roomType: API.Reservation.RoomType;
  roomRate: API.Reservation.RoomRate;
}

function blackoutDatesCompare(a: MappedAuditLog, b: MappedAuditLog) {
  return sortByDate(a.timestamp, b.timestamp);
}

export default function BlackoutDates({ channelManaged, propertyId, roomType, roomRate }: Props) {
  const blackoutDatePath = useMemo(() => {
    return `/properties/${propertyId}/room-types/${roomType.id}/room-rates/${roomRate.id}/blackout-dates`;
  }, [propertyId, roomRate, roomType]);

  const [loading, setLoading] = useState<boolean>();
  const [logs, setLogs] = useState<{ [key: string]: MappedAuditLog[] }>({});

  const getBlackoutDateLogs = useCallback(async () => {
    setLoading(true);
    const res = await getLogsByUrl(blackoutDatePath);

    const results = groupLogsByModelId(res.result, (log) => log.payload.params.blackoutDateId);

    setLogs(results);

    setLoading(false);
  }, [blackoutDatePath]);

  useEffect(() => void getBlackoutDateLogs(), [getBlackoutDateLogs]);

  return (
    <Grid container direction="column" spacing={2}>
      {loading ? (
        <Spinner />
      ) : (
        <Grid>
          {Object.entries(logs)
            .map(([key, blackoutDateBlocks]) => {
              const sortedLogs = blackoutDateBlocks.sort(blackoutDatesCompare).reverse();
              return { key, sortedLogs };
            })
            .filter(({ sortedLogs }) => sortedLogs[0].action === 'CREATE')
            .map(({ key, sortedLogs }) => (
              <BlackoutDateBlockHistory key={key} blackoutDateBlocks={sortedLogs} />
            ))}
        </Grid>
      )}

      {channelManaged && (
        <Grid>
          <i>* Blackout dates controlled by channel manager</i>
        </Grid>
      )}
    </Grid>
  );
}
