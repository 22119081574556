import React, { Dispatch } from 'react';

import { Autocomplete, Button, TextField } from '@mui/material';

import { Checkbox } from '~/components/Common/Checkbox';

interface Props {
  experiences: Array<any>;
  expInFlowChecked: boolean;
  setExperiencesInFlow: Dispatch<any>;
  expCuratedChecked: boolean;
  showOnlyExperiencesCurated: boolean;
  setExperiencesCurated: Dispatch<any>;
  selectedExperiences: Array<{
    id: string;
    title: string;
  }>;
  setSelectedExperiences: Dispatch<any>;
  setShowOnlyExperiencesCurated: Dispatch<any>;
  onSubmitForm: () => void;
}

const SELECT_ALL_OPTION = 'All';

const OfferExperiencesForm = ({
  experiences,
  expInFlowChecked,
  setExperiencesInFlow,
  expCuratedChecked,
  setExperiencesCurated,
  selectedExperiences,
  setSelectedExperiences,
  onSubmitForm,
  setShowOnlyExperiencesCurated,
  showOnlyExperiencesCurated,
}: Props) => {
  const options = experiences
    .map((i) => ({
      label: `${i.title.trim()} (ID ${i.providerId})`,
      value: i.providerId,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div>
      <div className="form-group">
        <div className="checkbox">
          <label>
            <Checkbox checked={expInFlowChecked} onChange={setExperiencesInFlow} />
            Sale Experiences In-Flow?
          </label>
        </div>
      </div>
      <div className="form-group">
        <div className="checkbox">
          <label>
            <Checkbox checked={expCuratedChecked} onChange={setExperiencesCurated} />
            Curate Selection?
          </label>
        </div>
      </div>
      <div className="form-group">
        <div className="checkbox">
          <label>
            <Checkbox checked={showOnlyExperiencesCurated} onChange={setShowOnlyExperiencesCurated} />
            Show ONLY curated experiences
          </label>
        </div>
      </div>
      <div className="form-group">
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={[SELECT_ALL_OPTION, ...options.map((type) => type.value)]}
          onChange={(_, values) => {
            if (values.filter((item) => item === SELECT_ALL_OPTION).length === 1) {
              setSelectedExperiences(options.map((type) => type.value));
            } else {
              setSelectedExperiences(values);
            }
          }}
          limitTags={5}
          value={selectedExperiences}
          renderInput={(params) => <TextField {...params} label="Select Experiences" />}
          getOptionLabel={(option) => options.find((type) => type.value === option)?.label || option}
          sx={{ mt: 2 }}
        />
      </div>
      <Button sx={{ mt: 2 }} variant="contained" onClick={onSubmitForm}>
        Save
      </Button>
    </div>
  );
};

export default OfferExperiencesForm;
