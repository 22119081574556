import React, { Fragment, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import useQuery from '~/hooks/useQuery';

import { addQuery, removeQuery } from '~/utils/url';

import CabinCategoriesList from './CabinCategoriesList';
import CabinCategoriesSearchFilters from './CabinCategoriesSearchFilters';
import { SearchFilters } from './constants';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function CruisesCabinCategoriesPage() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const currentPage = useMemo(() => parseInt(query.get('page')) || 1, [query]);

  const searchFilters = useMemo(() => {
    const filters: SearchFilters = {};

    if (query.get('id')) filters.id = query.get('id');
    if (query.get('code')) filters.code = query.get('code');
    if (query.get('cabinType')) filters.cabinType = query.get('cabinType');
    if (query.get('externalId')) filters.externalId = query.get('externalId');
    if (query.get('shipId')) filters.shipId = query.get('shipId');
    if (query.get('shipName')) filters.shipName = query.get('shipName');
    if (query.get('shipExternalId')) filters.shipExternalId = query.get('shipExternalId');
    if (query.get('vendorId')) filters.vendorId = query.get('vendorId');
    if (query.get('cabinInfoCategoryName')) filters.cabinInfoCategoryName = query.get('cabinInfoCategoryName');

    return filters;
  }, [query]);

  const onSearch = useCallback(
    (params: SearchFilters) => {
      const filtersToRemove: string[] = [];

      if (!params.id) filtersToRemove.push('id');
      if (!params.code) filtersToRemove.push('code');
      if (!params.cabinType) filtersToRemove.push('cabinType');
      if (!params.externalId) filtersToRemove.push('externalId');
      if (!params.shipId) filtersToRemove.push('shipId');
      if (!params.shipName) filtersToRemove.push('shipName');
      if (!params.shipExternalId) filtersToRemove.push('shipExternalId');
      if (!params.cabinInfoCategoryName) filtersToRemove.push('cabinInfoCategoryName');
      if (!params.vendorId) filtersToRemove.push('vendorId');

      const newLocation = addQuery(location, {
        id: params.id,
        code: params.code,
        cabinType: params.cabinType,
        externalId: params.externalId,
        shipId: params.shipId,
        shipName: params.shipName,
        shipExternalId: params.shipExternalId,
        cabinInfoCategoryName: params.cabinInfoCategoryName,
        vendorId: params.vendorId,
      });

      history.push(removeQuery(newLocation, ...filtersToRemove));
    },
    [history, location],
  );

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  return (
    <Fragment>
      <CabinCategoriesSearchFilters onSearch={onSearch} querySearchFilters={searchFilters} />

      <CabinCategoriesList
        brand={brand}
        page={currentPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
        searchFilters={searchFilters}
        sizePerPage={DEFAULT_SIZE_PER_PAGE}
      />
    </Fragment>
  );
}
