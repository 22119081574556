import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';

import { Offer } from '@luxuryescapes/contract-svc-offer';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import Flag from '../../Common/Flag';
import ScheduleForm from '../../Common/Forms/ScheduleForm';

const types = [
  {
    code: 'availability',
    name: 'Purchase',
  },
  {
    code: 'online_purchase',
    name: 'Online Purchase',
  },
];

interface ScheduleComponentProps {
  offerId: string;
  schedule: Offer.BrandSchedule;
  type: string;
  name: string;
  regionCode: string;
  brand: string;
}

function ScheduleComponent({ offerId, schedule, type, name, regionCode, brand }: ScheduleComponentProps) {
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState([]);

  const onError = (data) => {
    setEditing(false);
    setErrors(data.errors);
  };

  const onSuccess = () => {
    setEditing(false);
    setErrors([]);
  };

  const onSave = () => {
    setEditing(true);
  };

  return (
    <Box>
      <h2>{name}</h2>
      {errors.map((error, index) => (
        <ErrorDisplay key={index} message={error.message} />
      ))}
      <ScheduleForm
        brand={brand}
        disabled={editing}
        schedule={schedule}
        offerId={offerId}
        onSave={onSave}
        onError={onError}
        onSuccess={onSuccess}
        type={type}
        region={regionCode}
      />
    </Box>
  );
}

interface RegionComponentProps {
  offerId: string;
  schedules: Array<Offer.BrandSchedule>;
  code: string;
  name: string;
  brand: string;
}

function RegionComponent({ offerId, name, code, brand, schedules }: RegionComponentProps) {
  const scheduleForType = (type) => {
    return schedules.find((schedule) => schedule.type === type);
  };

  const header = (
    <Stack direction="row">
      <Flag region={code} style={{ marginRight: 10 }} />
      <Typography
        variant="h5"
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'lighter',
        }}
      >
        {name}
      </Typography>
    </Stack>
  );

  return (
    <Accordion variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{header}</AccordionSummary>
      <AccordionDetails>
        {types.map((type) => (
          <ScheduleComponent
            brand={brand}
            key={type.code}
            offerId={offerId}
            regionCode={code}
            name={type.name}
            type={type.code}
            schedule={scheduleForType(type.code)}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

interface Props {
  regions: Array<any>;
  offer: App.Offer;
  brand: string;
}

function SchedulesPage({ regions, offer, brand }: Props) {
  const selectSchedules = (regionCode, brand): Array<Offer.BrandSchedule> => {
    return offer.brand_schedules.filter(
      (schedule: Offer.BrandSchedule) => schedule.brand === brand && schedule.region === regionCode,
    );
  };

  return (
    <Box>
      {regions.map((region) => (
        <Box key={region.code}>
          <RegionComponent
            brand={brand}
            offerId={offer.id_salesforce_external}
            schedules={selectSchedules(region.code, brand)}
            code={region.code}
            name={region.name}
          />
        </Box>
      ))}
    </Box>
  );
}

export default SchedulesPage;
