import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import RoomRateInclusionsForm from '~/components/Common/Forms/RoomRateInclusionsForm';

import ReservationService from '~/services/ReservationService';

interface Props {
  hotelCode: string;
  channelPropertyData: Reservation.Property;
  vendorCurrencyCode: string;
  inclusionsSchema: any;
  onUpdate: () => Promise<void>;
}

const DEFAULT_VALUES = {
  id: '',
  description: '',
  amount: '',
  currency: 'AUD',
  minimum_los: 1,
  maximum_los: 999,
  unit: '',
  is_bonus: false,
  set_schedules: false,
  schedules_list: [],
  external_id: '',
};

type APIInclusion = {
  id: number;
  name: string;
};

export default function VIPPane(props: Props) {
  const { hotelCode, channelPropertyData, vendorCurrencyCode, onUpdate, inclusionsSchema } = props;
  const [inclusionsFromAPI, setInclusionsFromAPI] = useState<APIInclusion[]>([]);
  const [editingInclusions, setEditingInclusions] = useState([]);
  const [editingInclusion, setEditingInclusion] = useState(null);

  const fetchInclusions = async () => {
    const inclusions = channelPropertyData?.room_types[0]?.room_rates[0]?.inclusions ?? [];
    const allAPIInclusions = channelPropertyData?.channel_extra_data?.inclusions ?? [];
    const filteredInclusions = allAPIInclusions.filter(
      (apiInclusion) => !inclusions.find((curated) => curated.external_id === apiInclusion.id.toString()),
    );
    setInclusionsFromAPI(filteredInclusions);
    setEditingInclusions(inclusions);
  };

  const refreshInclusions = async () => {
    onUpdate();
    fetchInclusions();
  };

  useEffect(() => {
    fetchInclusions();
  }, [hotelCode, channelPropertyData]);

  const handleAddClick = () => {
    setEditingInclusion(DEFAULT_VALUES);
  };

  const handleAddToCurationClick = (amenity) => {
    setEditingInclusion({ ...DEFAULT_VALUES, description: amenity.name, external_id: amenity.id.toString() });
  };

  const handleDeleteClick = async (index: number) => {
    await ReservationService.deleteRoomRateInclusion(
      editingInclusions[index].id,
      channelPropertyData.id,
      channelPropertyData.room_types[0].id,
      channelPropertyData.room_types[0].room_rates[0].id,
    );
    refreshInclusions();
  };

  const showModal = (inclusion) => {
    setEditingInclusion(inclusion);
  };

  const closeModal = () => {
    setEditingInclusion(null);
    refreshInclusions();
  };

  return (
    <>
      <PageSubheader title="VIP Services" />

      <Box>
        <PageSubheader title="Curated VIP Services"></PageSubheader>
        <Stack spacing={2}>
          {editingInclusions.map((amenity, index) => (
            <Stack
              key={amenity.id ?? amenity.external_id ?? `${amenity.description}-${index}`}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                <FiberManualRecordIcon fontSize="inherit" />
              </ListItemIcon>
              <Typography>{amenity.description}</Typography>
              <IconButton onClick={() => showModal(amenity)}>
                <EditIcon />
              </IconButton>
              <ConfirmButton
                title="Delete"
                confirmTitle="Confirm delete"
                confirmQuestion={'Are you sure?'}
                confirmAnswer="Delete"
                onConfirm={() => handleDeleteClick(index)}
                asIcon
              >
                <DeleteIcon />
              </ConfirmButton>
            </Stack>
          ))}
        </Stack>
        <Button variant="contained" onClick={handleAddClick} sx={{ mb: 3 }}>
          Create
        </Button>
      </Box>
      <Box>
        <PageSubheader title="VIP Services via API"></PageSubheader>
        <Stack spacing={2}>
          {inclusionsFromAPI.map((amenity, index) => (
            <Stack key={index} direction="row" alignItems="center" spacing={2}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                <FiberManualRecordIcon fontSize="inherit" />
              </ListItemIcon>
              <Typography>{`${amenity.name} (id ${amenity.id})`}</Typography>
              <Button variant="contained" onClick={() => handleAddToCurationClick(amenity)} style={{ float: 'right' }}>
                Add
              </Button>
            </Stack>
          ))}
        </Stack>
      </Box>

      <Dialog
        open={!!editingInclusion}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">Set VIP Service</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <RoomRateInclusionsForm
              vendorCurrencyCode={vendorCurrencyCode}
              inclusionItem={editingInclusion}
              inclusionsSchema={inclusionsSchema}
              propertyId={channelPropertyData.id}
              roomTypeId={channelPropertyData.room_types[0].id}
              roomRateId={channelPropertyData.room_types[0].room_rates[0].id}
              onCancel={closeModal}
              onRoomRateInclusionUpdated={refreshInclusions}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
