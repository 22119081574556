import React from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
  offerNumber: number;
  offer: App.Offer;
};

export default function OfferLocationHeading({ offerNumber, offer }: Props) {
  return (
    <Box my={2}>
      <Typography fontSize={16} color="secondary">{`${offerNumber}. ${offer.locations.join(', ')}`}</Typography>
      <Typography variant="h5">{offer.name}</Typography>
    </Box>
  );
}
