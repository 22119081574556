import pluralize from 'pluralize';

import { API } from '@luxuryescapes/lib-types';

import { dateNowUtc, formatDateISO } from '~/services/TimeService';

import { HOTEL_OFFER_TYPES } from '../consts/offerTypes';
import { ITEM_TYPE_ADDON, ITEM_TYPE_GIFT_CARD, ITEM_TYPE_OFFER, ITEM_TYPE_OFFLINE_FLIGHT } from '../consts/order';
import { RESERVATION_TYPE_BOOK_LATER, RESERVATION_TYPE_INSTANT_BOOKING } from '../consts/reservation';

interface ItemChangeDatesPayload {
  transaction_key: string;
  op: 'change_dates';
  check_in?: string;
  start_date?: string;
}

interface ItemChangeGuestNamePayload {
  transaction_key: string;
  op: 'change_guest_details';
  guest_first_name: string;
  guest_last_name: string;
}

interface OfferItemPayload {
  offer_id: string;
  package_id: string;
  customer_email: string;
  customer_name: string;
  customer_id: string;
  transaction_key: string;
  offer_booking_type: string;
  offer_type: string;
  reservation_type?: App.ReservationType;
  is_surcharge_waived?: boolean;
  check_in?: string;
  check_out?: string;
  number_of_adults?: number;
  number_of_children?: number;
  number_of_infants?: number;
  number_of_nights?: number;
  guest_first_name?: string;
  guest_last_name?: string;
  guest_special_requests?: string;
  peak_period_surcharge?: number;
  extra_guest_surcharge?: number;
  start_date?: string;
  room_rate_id?: string;
  room_rate_amount?: number;
}

// checkItemComplete returns a boolean indicating if the given item has all
// the information needed to proceed to booking
export function checkItemComplete(item, bookingType, offerType) {
  const { reservation } = item;

  if (bookingType === 'voucher') {
    return true;
  }

  if (offerType === 'tour') {
    return (
      Boolean(reservation.startDate) &&
      Boolean(reservation.endDate) &&
      Boolean(reservation.firstname) &&
      Boolean(reservation.lastname)
    );
  }

  if (reservation.reservationType === RESERVATION_TYPE_BOOK_LATER) {
    return true;
  }

  // Otherwise, offerType is hotel
  const isGuestComplete = reservation.numAdults > 0;

  return (
    isGuestComplete &&
    Boolean(reservation.firstname) &&
    Boolean(reservation.lastname) &&
    Boolean(reservation.checkIn) &&
    Boolean(reservation.checkOut) &&
    Boolean(reservation.lastNight)
  );
}

// reservationMap maps reservation object found in an API response to an
// appropriately formatted object with camel case
export function reservationMap(offerType, reservation) {
  if (!reservation) {
    return null;
  }

  const baseReservation = {
    firstname: reservation.guest_first_name,
    lastname: reservation.guest_last_name,
    guestSpecialRequests: reservation.guest_special_requests,
  };

  if (offerType === 'hotel') {
    return {
      ...baseReservation,
      checkIn: dateNowUtc(reservation.check_in),
      checkOut: dateNowUtc(reservation.check_out),
      numAdults: reservation.number_of_adults,
      numChildren: reservation.number_of_children,
      numInfants: reservation.number_of_infants || 0,
      numNights: reservation.number_of_nights,
      durationSurchargeTotal: reservation.duration_surcharge_total,
    };
  }

  if (offerType === 'tour') {
    return {
      ...baseReservation,
      endDate: reservation.end_date,
      startDate: reservation.start_date,
    };
  }

  throw new Error(`offer type "${offerType}" is not supported`);
}

export function getItemChangeDatesPayload(cartItem, offer): ItemChangeDatesPayload {
  const payload: ItemChangeDatesPayload = {
    transaction_key: cartItem.transactionKey,
    op: 'change_dates',
  };

  if (offer.type === 'hotel') {
    payload.check_in = formatDateISO(dateNowUtc(cartItem.reservation.checkIn));
  }

  if (offer.type === 'tour') {
    payload.start_date = formatDateISO(dateNowUtc(cartItem.reservation.startDate));
  }

  return payload;
}

export function getItemChangeGuestNamePayload(cartItem): ItemChangeGuestNamePayload {
  const payload: ItemChangeGuestNamePayload = {
    transaction_key: cartItem.transactionKey,
    op: 'change_guest_details',
    guest_first_name: cartItem.reservation.firstname,
    guest_last_name: cartItem.reservation.lastname,
  };

  return payload;
}

export const getOfflineFlightItemPayload = (cartItem) => ({
  type: ITEM_TYPE_OFFLINE_FLIGHT,
  transaction_key: cartItem.transactionKey,
  total: cartItem.price,
  pnrs: cartItem.pnrs,
});

// getItemPayload constructs a payload for an item that API endpoints can consume
export function getItemPayload(cartItem, user, offer, brand) {
  switch (cartItem.type) {
    case ITEM_TYPE_OFFER:
      if (!offer) {
        throw new Error('Can not create offer type item without offer');
      }
      return getOfferItemPayload(cartItem, user, offer);
    case ITEM_TYPE_GIFT_CARD:
      return getGiftCardItemPayload(cartItem, user, brand);
    case ITEM_TYPE_OFFLINE_FLIGHT:
      return getOfflineFlightItemPayload(cartItem);
    default:
      throw new Error('Invalid cart item');
  }
}

export function getAddonItemPayload({ cartItem, offer, brand, packageId }) {
  return {
    type: ITEM_TYPE_ADDON,
    brand,
    transaction_key: cartItem.transactionKey,
    offer_id: offer.id_salesforce_external,
    package_id: packageId,
    addon_id: cartItem.addonId,
  };
}

export function getGiftCardItemPayload(cartItem, user, brand) {
  return {
    type: ITEM_TYPE_GIFT_CARD,
    transaction_key: cartItem.transactionKey,
    gift_card_value: cartItem.price,
    customer_email: user.email,
    customer_name: user.fullName,
    personalised: {
      is_blank_message: true,
      method: 'print',
    },
    brand,
  };
}

export function getOfferItemPayload(
  cartItem: App.CartItem,
  user: API.Auth.User,
  offer: App.CartOffer,
): OfferItemPayload {
  const payload: OfferItemPayload = {
    offer_id: offer.id_salesforce_external,
    package_id: cartItem.pkg.id_salesforce_external ?? cartItem.pkg.le_package_id,
    customer_email: user.email,
    customer_name: user.fullName,
    customer_id: user.id_member,
    transaction_key: cartItem.transactionKey,
    offer_booking_type: offer.booking_type,
    offer_type: offer.type,
  };

  if (HOTEL_OFFER_TYPES.includes(offer.type)) {
    if (cartItem.reservation) {
      const cartItemReservationType = cartItem.reservation.reservationType;
      payload.reservation_type = cartItemReservationType;
      payload.room_rate_id = (cartItem.pkg as App.AccommodationPackage).fk_room_rate_id;
      payload.room_rate_amount = cartItem.price;
      payload.is_surcharge_waived = !!cartItem.reservation.waivedSurchargeAmount;
      payload.number_of_nights = (cartItem.pkg as App.AccommodationPackage).number_of_nights;

      if (cartItemReservationType === RESERVATION_TYPE_INSTANT_BOOKING) {
        payload.check_in = formatDateISO(dateNowUtc(cartItem.reservation.checkIn));
        payload.check_out = formatDateISO(dateNowUtc(cartItem.reservation.checkOut));

        payload.number_of_adults = cartItem.reservation.numAdults;
        payload.number_of_children = cartItem.reservation.numChildren;
        payload.number_of_infants = cartItem.reservation.numInfants;
        payload.guest_first_name = cartItem.reservation.firstname;
        payload.guest_last_name = cartItem.reservation.lastname;
        payload.guest_special_requests = cartItem.reservation.guestSpecialRequests;
        payload.peak_period_surcharge = cartItem.reservation.durationSurchargeTotal;
        payload.extra_guest_surcharge = cartItem.reservation.extraGuestSurcharge;
      }
    }
  } else if (offer.type === 'tour') {
    payload.start_date = formatDateISO(dateNowUtc(cartItem.reservation.startDate));
    payload.reservation_type = RESERVATION_TYPE_INSTANT_BOOKING;
    payload.guest_special_requests = cartItem.reservation.guestSpecialRequests;
    payload.guest_first_name = cartItem.reservation.firstname;
    payload.guest_last_name = cartItem.reservation.lastname;
  }

  return payload;
}

function getDuration(item: App.CartItem) {
  return item.numberOfNights ?? item.numberOfDays;
}

export function getDurationText(item: App.CartItem) {
  const word = item.numberOfNights ? 'night' : 'day';
  return pluralize(word, getDuration(item), true);
}
