import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Divider, Typography } from '@mui/material';

import { getCompedPackages } from '~/services/PromoService';
import PublicOffersService from '~/services/PublicOfferService';
import UsersService from '~/services/UsersService';

import CompedPackagesForm from './CompedPackagesForm';
import CompedPackagesPage from './CompedPackagesPage';

const SIZE_PER_PAGE = 10;
const BRAND = 'luxuryescapes';

const CompedPackagesContainer = () => {
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [compedPackagesList, setCompedPackagesList] = useState([]);

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const decorateCompedPackages = async (compedPackages: Array<any>) => {
    const userIds = new Set();
    const offerIds = new Set();

    compedPackages.forEach((compedPackage) => {
      userIds.add(compedPackage.fk_customer_id);
      userIds.add(compedPackage.added_by);
      offerIds.add(compedPackage.fk_offer_id);
    });

    const [userSummaries, offers] = await Promise.all([
      UsersService.getUsersSummaryByIds(Array.from(userIds), BRAND),
      PublicOffersService.getMultipleOffersById(Array.from(offerIds) as string[], 'AU', BRAND),
    ]);

    const offersMap = new Map(offers.map((offer) => [offer.id_salesforce_external, offer.name]));

    compedPackages.forEach((compedPackage) => {
      compedPackage.customer = userSummaries.get(compedPackage.fk_customer_id).full_name;
      compedPackage.customerEmail = userSummaries.get(compedPackage.fk_customer_id).email;
      compedPackage.addedBy = userSummaries.get(compedPackage.added_by).full_name;
      compedPackage.offerName = offersMap.get(compedPackage.fk_offer_id) || 'Offer has ended';
    });

    return compedPackages;
  };

  const fetchCompedPackages = useCallback(async () => {
    setLoading(true);

    try {
      const { result, total } = await getCompedPackages(BRAND, page, SIZE_PER_PAGE);

      const compedPackages = await decorateCompedPackages(result);

      setCompedPackagesList(compedPackages);
      setTotal(total);
    } catch (e) {
      setErrors(`Failed fetching list of famils and competition winners: ${e?.message}`);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchCompedPackages();
  }, [fetchCompedPackages]);

  return (
    <Box>
      <Helmet>
        <title>Famils and competition winners</title>
      </Helmet>
      <Typography component="h4" variant="h4" mt={5}>
        Famils and competition winners
      </Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <CompedPackagesForm onSuccess={fetchCompedPackages} />
      <CompedPackagesPage
        prizeWinnersList={compedPackagesList}
        sizePerPage={SIZE_PER_PAGE}
        page={page}
        setPage={setPage}
        total={total}
        loading={loading}
      />
    </Box>
  );
};

export default CompedPackagesContainer;
