import React, { useCallback } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';

export type CancellationPolicyWarningModalHandle = {
  showModal: boolean;
  closeModal: () => void;
  showRefundModal: () => void;
  setRefundComment: (string) => void;
};

type FormValues = {
  refundApprover: string;
};

function CancellationPolicyWarningModal(props) {
  const { showModal, closeModal, showRefundModal, setRefundComment } = props;
  const { control, handleSubmit } = useForm<FormValues>({
    mode: 'onBlur',
    values: { refundApprover: '' },
  });

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    (data) => {
      setRefundComment(data.refundApprover);
      showRefundModal();
      closeModal();
    },
    [closeModal, setRefundComment, showRefundModal],
  );

  return (
    <Dialog open={showModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Warning: This order is past its cancellation date</DialogTitle>
        <DialogContent>
          This is a non-refundable booking, please confirm you wish to continue with the refund process
        </DialogContent>
        <DialogContent>
          <Stack gap={1}>
            <div>Please provide the name of the approver of this refund:</div>
            <Controller
              control={control}
              name="refundApprover"
              render={({ field, fieldState }) => (
                <TextField
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label="Approver"
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  required
                />
              )}
              rules={{
                maxLength: { value: 100, message: 'Please enter a maximum of 100 characters' },
                minLength: { value: 2, message: 'Please enter a minimum of 2 characters' },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Ok</Button>
          <Button onClick={closeModal}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CancellationPolicyWarningModal;
