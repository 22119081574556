import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';

import { RateCodeFormValues } from '../types';

const INITIAL_FORM_STATE: RateCodeFormValues = {
  id: '',
  code: '',
  vendorId: '',
  isFlash: false,
};

type Props = {
  rateCodeFormValues?: RateCodeFormValues;
  onSubmit: (formValues: RateCodeFormValues) => void;
};

const RateCodeForm = (props: Props): JSX.Element => {
  const { rateCodeFormValues, onSubmit } = props;

  const [formValues, setFormValues] = useState<RateCodeFormValues>(INITIAL_FORM_STATE);

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (event.target.name) {
        setFormValues({ ...formValues, [event.target.name]: checked });
      }
    },
    [formValues],
  );

  const handleSubmit = useCallback(() => {
    onSubmit(formValues);
  }, [formValues, onSubmit]);

  useEffect(() => {
    if (rateCodeFormValues) {
      setFormValues(rateCodeFormValues);
    }
  }, [rateCodeFormValues]);

  return (
    <Box>
      <Stack mt={2} spacing={2} direction="row">
        <TextField
          disabled
          fullWidth
          name="rateCode"
          label="Rate Code"
          value={formValues.code}
          InputProps={{ readOnly: true }}
        />

        <TextField
          disabled
          fullWidth
          name="vendorId"
          label="Vendor ID"
          value={formValues.vendorId}
          InputProps={{ readOnly: true }}
        />
      </Stack>

      <Box mt={2}>
        <FormControlLabel
          label="Is Flash?"
          control={<Checkbox required name="isFlash" onChange={handleCheck} checked={formValues.isFlash} />}
        />
      </Box>

      <Box mt={2}>
        <Button variant="contained" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default RateCodeForm;
