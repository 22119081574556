export type Themes = {
  white?: string;
  black?: string;
  overlay?: string;
  disabled?: string;
  thirdBlue?: string;
  primaryRed?: string;
  fourthBlue?: string;
  primaryGrey?: string;
  primaryDark?: string;
  primaryBlue?: string;
  primaryGreen?: string;
  primaryLight?: string;
  primaryYellow?: string;
  secondaryBlue?: string;
  secondaryGrey?: string;
  secondaryGreen?: string;
  primaryLightBlue?: string;
  primaryLightGrey?: string;
};

export const themes: Themes = {
  white: '#ffffff',
  black: '#000000',
  disabled: '#e3e3e3',
  overlay: '#000000ab',
  primaryLight: '#f2f2f2',
  primaryDark: '#1d2026',
  primaryRed: '#ff4200',
  primaryYellow: '#ecda09',
  primaryGreen: '#5cb85c',
  secondaryGreen: '#00a380',
  primaryLightGrey: '#f5f5f5',
  primaryGrey: '#a9a9a9',
  secondaryGrey: '#918f8f',
  primaryLightBlue: '#85c7e142',
  primaryBlue: '#00a5e5',
  secondaryBlue: '#0074B7',
  thirdBlue: '#145DA0',
  fourthBlue: '#003B73',
};
