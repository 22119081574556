import React from 'react';

import classnames from 'clsx';

import { formatDateLong } from '~/services/TimeService';

// getLabel returns a label to be shown in <TravelDates />
function getLabel(startDate, endDate, disabled) {
  if (disabled) {
    return 'Book Later';
  }

  if (!startDate || !endDate) {
    return 'Select dates';
  }

  return `${formatDateLong(startDate)} - ${formatDateLong(endDate)}`;
}

const TravelDates = ({ startDate, endDate, onStartBookingDates, disabled }) => {
  const label = getLabel(startDate, endDate, disabled);

  return (
    <div className={classnames('travel-dates booking-input', { disabled })} onClick={onStartBookingDates} role="button">
      <span
        className={classnames('date-label data-hj-whitelist', {
          empty: !startDate || !endDate,
        })}
      >
        {label}
      </span>
      <button onClick={onStartBookingDates} className="datepicker-trigger" disabled={disabled}>
        <i className="fa fa-calendar" />
      </button>
    </div>
  );
};

export default TravelDates;
