import React from 'react';

import { Stack, Typography } from '@mui/material';

type OrderItem = App.OrderItem | App.CustomOfferOrderItem;
type Props = { item: OrderItem };

const isCustomOfferItem = (item: OrderItem): item is App.CustomOfferOrderItem =>
  (item as App.CustomOfferOrderItem).fk_custom_offer_id !== undefined;

const getItemStatus = (item: OrderItem): { statusColor: string; statusText: string } => {
  if (item.status === 'cancelled') {
    return { statusColor: 'red', statusText: 'Cancelled' };
  }
  if ('on_hold' in item && item.on_hold) {
    return { statusColor: 'orange', statusText: 'On hold' };
  }

  if (isCustomOfferItem(item)) {
    if (item.status === 'created') {
      const paymentMetadata = item.custom_offer?.payment_metadata;
      const statusText =
        paymentMetadata?.payment_type === 'full_payment' || paymentMetadata?.customer_deposit_payment_made
          ? 'Pending full payment'
          : 'Pending deposit payment';
      return { statusColor: 'orange', statusText };
    }
    if (item.status === 'completed') {
      return { statusColor: 'green', statusText: 'Completed' };
    }
  }

  switch (item.status) {
    case 'awaiting_dates':
      return { statusColor: 'orange', statusText: 'Awaiting dates' };
    case 'completed':
      return { statusColor: 'green', statusText: 'Completed' };
    case 'failed':
      return { statusColor: 'red', statusText: 'Failed' };
    case 'created':
      return { statusColor: 'orange', statusText: 'Created' };
    default:
      return { statusColor: 'black', statusText: 'Unknown' };
  }
};

export default function ItemStatus({ item }: Props) {
  const { statusColor, statusText } = getItemStatus(item);
  return (
    <Stack direction="row" spacing={1}>
      <Typography>Status:</Typography>
      <Typography color={statusColor}>{statusText}</Typography>
    </Stack>
  );
}
