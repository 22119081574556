import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { ROLE_ADMIN_USER, ROLE_COORDINATOR } from '~/consts/roles';

import PermissionComponent from '../../Common/PermissionedComponent';

export default function RoomTypeIncludedGuest({ includedGuest, onRequestEdit, deleteRoomTypeIncludedGuest }) {
  const showConfirmDialog = useConfirmDialog();

  const handleDelete = async () => {
    const confirmed = await showConfirmDialog({
      title: 'Are you sure you want to delete this included guests capacity?',
    });

    if (!confirmed) return;

    deleteRoomTypeIncludedGuest(includedGuest.id);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" component="div">
          Adults
        </Typography>
        <Typography variant="h6" component="div">
          {includedGuest.adults}
        </Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid xs={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="div">Children</Typography>
        <Typography variant="h6" component="div">
          {includedGuest.children}
        </Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid xs={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="div">Infants</Typography>
        <Typography variant="h6" component="div">
          {includedGuest.infants}
        </Typography>
        <Divider orientation="vertical" />
      </Grid>
      <PermissionComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_COORDINATOR]}>
        <Grid xs={6} display="flex" gap={2} justifyContent="end">
          <Button variant="text" startIcon={<EditIcon />} onClick={() => onRequestEdit(includedGuest)}>
            Edit
          </Button>
          <Button variant="text" startIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button>
        </Grid>
      </PermissionComponent>
    </Grid>
  );
}
