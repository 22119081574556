import { useEffect, useState } from 'react';

type Options<T> = {
  initialData?: T;
  autoDismissAfterMs?: number;
};

type Return<T> = [T, (data: T) => void, () => void];

const useDismissible = <T>(options: Options<T> = {}): Return<T> => {
  const [data, setData] = useState(options.initialData ?? null);

  const dismiss = () => setData(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout = null;
    if (options.autoDismissAfterMs && data !== null) {
      timeout = setTimeout(dismiss, options.autoDismissAfterMs);
    }

    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [data]);

  return [data, setData, dismiss];
};

export default useDismissible;
