import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { RJSFSchema, WidgetProps } from '@rjsf/utils';
import cn from 'clsx';
import { useSnackbar } from 'notistack';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';
import useToggleState from '~/hooks/useToggleState';

import { uploadImage } from '~/services/ImageService';

import { formatBytes } from '~/utils/stringUtils';

import { formIdToFieldDepthConverter } from './formUtils';
import { ExtendedRJSFormContext } from './useExtendedRJSForm';

const DropZoneCard = styled(Card)`
  --bg-stripe-0-color: #eee;
  --bg-stripe-1-color: #fff;
  background: rgba(0, 0, 0, 0.05);
  background: repeating-linear-gradient(
    45deg,
    var(--bg-stripe-0-color),
    var(--bg-stripe-0-color) 2px,
    var(--bg-stripe-1-color) 2px,
    var(--bg-stripe-1-color) 16px
  );
  cursor: pointer;
  position: relative;
  flex-grow: 1;

  &:active {
    border-color: #aaa;
  }

  &.is-disabled,
  &.is-read-only {
    --bg-stripe-0-color: #fff;
    --bg-stripe-1-color: #eee;

    cursor: not-allowed;
  }

  &.is-busy {
    cursor: wait;
  }
`;

interface Props extends WidgetProps<unknown, RJSFSchema, ExtendedRJSFormContext> {
  options: {
    allowMultipleInArray?: boolean; // to enable the mechanism for uploading multiple images in an array
    maxFilesCap?: number; // defaults to 10
    removable?: boolean;
  };
}

function ImageUploadWidget(props: Props) {
  const { label, max, options, accept, name, id, required, disabled, readonly, value, onChange, formContext } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState<File | undefined>();
  const [uploadedImage, setUploadedImage] = useState<{ name: string; formattedSize: string } | undefined>();
  const { isToggled: isUploading, toggleOn: startUpoading, toggleOff: endUploading } = useToggleState();
  const [svcImageId, setSvcImageId] = useState<string>(typeof value === 'string' ? value : '');

  const { fieldDepth, fieldName } = useMemo(() => {
    const depth = formIdToFieldDepthConverter(id);
    const fieldName = depth.pop();
    return {
      fieldDepth: depth.slice(0, depth.length - 1),
      fieldName,
    };
  }, [id]);

  const uploadImageFile = useCallback(
    async (file: File) => {
      startUpoading();
      try {
        const response = await uploadImage(file);
        setSvcImageId(response.body.public_id);
        setUploadedImage({
          name: file.name,
          formattedSize: formatBytes(file.size),
        });
        setImageFile(undefined);
        enqueueSnackbar(`"${file.name}" was uploaded.`, { variant: 'info' });
        onChange(response.body.public_id);
      } catch (err) {
        setSvcImageId('');
        setUploadedImage(undefined);
        enqueueSnackbar(`"${file.name}" upload failed! ${JSON.stringify(err, null, 1)}`, {
          variant: 'error',
        });
      } finally {
        endUploading();
      }
    },
    [endUploading, enqueueSnackbar, onChange, startUpoading],
  );

  const onDrop = useCallback<DropzoneOptions['onDrop']>(
    (acceptedFiles) => {
      const [toBeUploadedFile, ...restOfFiles] = acceptedFiles;
      setImageFile(toBeUploadedFile);
      if (restOfFiles?.length) {
        formContext?.pushToFormDataArrayUpdateQueue?.(
          fieldDepth,
          restOfFiles.map((file) => ({ [fieldName]: file })),
        );
      }
    },
    [fieldDepth, fieldName, formContext],
  );

  useEffect(() => {
    if (value) {
      if (value instanceof File) {
        setImageFile(value);
      } else {
        setSvcImageId(value);
      }
    }
  }, [value]);

  useEffect(() => {
    if (imageFile && !isUploading) {
      uploadImageFile(imageFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageFile]);

  const shouldAllowMultiple = !svcImageId && !!options?.allowMultipleInArray;

  const maxFilesCap = useMemo(() => options?.maxFilesCap ?? 10, [options]);

  const {
    getInputProps,
    getRootProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
    inputRef,
  } = useDropzone({
    accept,
    multiple: shouldAllowMultiple,
    maxSize: max,
    maxFiles: maxFilesCap,
    disabled,
    onDrop,
  });

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const rootProps = useMemo(() => {
    const { onClick, ...props } = getRootProps();

    return {
      ...props,
      onClick: disabled || readonly ? undefined : onClick,
      className: cn({
        'is-accepted': isDragAccept || acceptedFiles?.length,
        'is-active': isDragActive || isFileDialogActive,
        'is-rejected': isDragReject || fileRejections?.length,
        'is-busy': isUploading,
        'is-disabled': disabled,
        'is-read-only': readonly,
      }),
    };
  }, [
    isDragAccept,
    isDragActive,
    isDragReject,
    isFileDialogActive,
    acceptedFiles,
    fileRejections,
    isUploading,
    disabled,
    readonly,
    getRootProps,
  ]);

  const acceptedFormatsText = useMemo<string | null>(() => {
    if (accept?.length) {
      if (Array.isArray(accept)) {
        return accept.reduce((currentText, format, index, formats) => {
          let text = index === 0 ? '' : index === formats.length - 1 ? ' and ' : ', ';
          text += format.toUpperCase();

          return currentText + text;
        }, `Accepted formats: `);
      } else {
        return `Accepted format: ${accept.toUpperCase()}`;
      }
    } else {
      return null;
    }
  }, [accept]);

  const fileSizeLimitText = useMemo<string | null>(() => (max ? `max ${formatBytes(max)}` : null), [max]);

  const dropZoneCaption = useMemo(() => {
    if (isUploading) return 'Uploading...';

    if (svcImageId) return 'To replace, drop image here, or click/tap to browse';

    if (shouldAllowMultiple) return `Drop image/s here, or click/tap to browse (max ${maxFilesCap} at a time)`;

    return 'Drop image here, or click/tap to browse';
  }, [isUploading, maxFilesCap, shouldAllowMultiple, svcImageId]);

  const previewSrc = useImageUrlGenerator(svcImageId, { width: 200 });
  const previewBlurSrc = useImageUrlGenerator(svcImageId, { width: 10 });

  const openPreview = useCallback(() => {
    formContext.triggerImagePreview({
      source: 'svc-image',
      publicId: svcImageId,
    });
  }, [formContext, svcImageId]);

  const deleteImage = useCallback(() => {
    onChange('');
    setSvcImageId('');
  }, [onChange]);

  return (
    <Paper variant="outlined">
      <FormControl variant="outlined" required={required} disabled={disabled} fullWidth>
        <InputLabel variant="outlined" shrink={!!svcImageId || isUploading || isFileDialogActive}>
          {label}
        </InputLabel>
        <input type="hidden" id={id} name={name} value={svcImageId} onChange={handleChange} />
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
          gridTemplateRows="repeat(auto-fit, 200px)"
          gap={1}
          p={1}
        >
          {!!svcImageId && !isUploading && (
            <Tooltip placement="top" title="Click to preview">
              <Card
                sx={{
                  backgroundImage: `url(${previewBlurSrc})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  position: 'relative',
                }}
              >
                <CardActionArea onClick={openPreview} sx={{ height: '100%' }}>
                  <CardMedia
                    component="img"
                    src={previewSrc}
                    alt="uploaded image preview"
                    sx={{ height: '100%', objectFit: 'contain' }}
                  />
                </CardActionArea>
                {options?.removable && (
                  <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                    <IconButton onClick={deleteImage} color="error">
                      <DeleteForeverIcon fontSize="large" />
                    </IconButton>
                  </Box>
                )}
              </Card>
            </Tooltip>
          )}

          <Stack direction="column" gap={1}>
            <DropZoneCard variant="outlined">
              <input {...getInputProps()} disabled={disabled} ref={inputRef} />

              <CardActionArea {...rootProps} sx={{ height: '100%' }}>
                <Stack direction="column" justifyContent="center" alignItems="center" height="100%" px={2} py={3}>
                  <Typography variant="overline" lineHeight={1.4} component="div">
                    {dropZoneCaption}
                  </Typography>
                  {!!acceptedFormatsText && (
                    <Typography variant="overline" component="div">
                      {acceptedFormatsText}
                    </Typography>
                  )}
                  {!!fileSizeLimitText && (
                    <Typography variant="overline" component="div">
                      {fileSizeLimitText}
                    </Typography>
                  )}
                </Stack>
              </CardActionArea>

              {isUploading && (
                <LinearProgress
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                  }}
                />
              )}
            </DropZoneCard>

            {!!uploadedImage && (
              <TextField
                defaultValue={uploadedImage.name}
                helperText={uploadedImage.formattedSize}
                label="File"
                variant="outlined"
                inputProps={{ readOnly: true }}
              />
            )}
          </Stack>
        </Box>
      </FormControl>
    </Paper>
  );
}

export default ImageUploadWidget;
