import React from 'react';

import { Helmet } from 'react-helmet';

import { CruiseOfferByIdProvider } from '~/components/Cruises/context/useCruiseOfferById';
import { withTenant } from '~/components/hoc';

import CruiseOfferDetails from './components/CruiseOfferDetails/CruiseOfferDetails';

interface Props {
  tenant: App.Tenant;
  match: { params: { id_offer: string } };
}

const CruisePageDetails = (props: Props) => {
  const { match, tenant } = props;
  const cruiseOfferId = match?.params?.id_offer;

  return (
    <CruiseOfferByIdProvider>
      <Helmet>
        <title>Cruises | Details Page</title>
      </Helmet>
      <CruiseOfferDetails tenant={tenant} offerId={cruiseOfferId} />
    </CruiseOfferByIdProvider>
  );
};

export default withTenant(CruisePageDetails);
