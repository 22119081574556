import React, { useMemo } from 'react';

import { Box, Stack, TextField } from '@mui/material';

import { CabinCategoryById } from '~/services/cruises/CabinCategoriesService';

interface Props {
  cabinCategory: CabinCategoryById;
}

export default function CabinCategoriesProviderDetails(props: Props) {
  const { cabinCategory } = props;

  const shipFields = useMemo(() => {
    return [
      { label: 'Ship ID', value: cabinCategory.ship?.id },
      { label: 'Ship External ID', value: cabinCategory.ship?.externalId },
      { label: 'Ship Name', value: cabinCategory.ship?.name },
    ];
  }, [cabinCategory.ship]);

  const vendorFields = useMemo(() => {
    return [
      { label: 'Vendor ID', value: cabinCategory.vendor.id },
      { label: 'Vendor External ID', value: cabinCategory.vendor.externalId },
      { label: 'Vendor Name', value: cabinCategory.vendor.name },
      { label: 'Vendor Code', value: cabinCategory.vendor.code },
    ];
  }, [cabinCategory.vendor]);

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <div
          style={{ height: 'auto', width: '6px', borderRadius: '4px', background: cabinCategory.color ?? '#efefef' }}
        />
        <Stack width="100%" direction="column" spacing={4}>
          <Stack spacing={2} direction="row">
            {cabinCategory.id && (
              <TextField disabled fullWidth label="Cabin Category ID" defaultValue={cabinCategory.id} />
            )}
            {cabinCategory.externalId && (
              <TextField
                disabled
                fullWidth
                label="Cabin Category External ID"
                defaultValue={cabinCategory.externalId}
              />
            )}
            {cabinCategory.code && (
              <TextField disabled fullWidth label="Cabin Category Code" defaultValue={cabinCategory.code} />
            )}
            {cabinCategory.color && (
              <TextField disabled fullWidth label="Cabin Category Color" defaultValue={cabinCategory.color} />
            )}
            {cabinCategory.cabinType && (
              <TextField disabled fullWidth label="Cabin Category Type" defaultValue={cabinCategory.cabinType} />
            )}
          </Stack>

          <Stack spacing={2} direction="row">
            {shipFields.map((field, index) =>
              field.value ? (
                <TextField key={index} disabled fullWidth label={field.label} defaultValue={field.value} />
              ) : null,
            )}
          </Stack>

          <Stack spacing={2} direction="row">
            {vendorFields.map((field, index) =>
              field.value ? (
                <TextField key={index} disabled fullWidth label={field.label} defaultValue={field.value} />
              ) : null,
            )}
          </Stack>

          <Stack spacing={2} direction="column">
            {cabinCategory?.cabinInfo?.map((cabinInfo) => (
              <React.Fragment key={cabinInfo.id}>
                <Stack spacing={2} direction="row">
                  <TextField disabled fullWidth label="Cabin Info ID" defaultValue={cabinInfo.id} />
                  <TextField disabled fullWidth label="Cabin Info Name" defaultValue={cabinInfo.name} />
                  <TextField disabled fullWidth label="Cabin Info Category ID" defaultValue={cabinInfo.categoryId} />
                </Stack>
                <Stack spacing={2} direction="row">
                  <TextField disabled fullWidth label="Cabin Info Description" defaultValue={cabinInfo.description} />
                </Stack>
              </React.Fragment>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
