import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import { rangeOverlap } from '~/services/TimeService';

import { daysOfWeek } from './daysOfWeek';

export function surchargeHasDateConflict(
  a: Reservation.PresentedSalesforceSurchargePeriod,
  b: Reservation.PresentedSalesforceSurchargePeriod,
): boolean {
  const rangeConflict = rangeOverlap(a.start_date, a.end_date, b.start_date, b.end_date);

  const daysConflict = daysOfWeek.some((d) => {
    return a[d.key] && b[d.key];
  });
  return rangeConflict && daysConflict;
}
