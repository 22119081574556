import React from 'react';

import { Grid } from '@mui/material';

export function BookingBox(props) {
  return (
    <div className="container">
      <Grid>
        <Grid xs={12}>
          <h4>Successfully booked:</h4>
          <h3>Booking Number: {props.booking.booking_number}</h3>
          <h3>Booked for: {props.booking.fk_customer_id}</h3>
          <h3>Booked by: {props.booking.fk_purchaser_id}</h3>
          <hr />
        </Grid>
      </Grid>
    </div>
  );
}
