import React, { FormEventHandler } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';
import MarkCancelled from '../Common/MarkCancelled';
import SelectRefundVariant from '../Common/SelectRefundVariant';

interface Props {
  reason: CollectionReason;
  onSubmit: FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  defaultVccChecked: boolean;
  defaultComment: string;
}

export default function FailedRefundFromPaymentGateways({
  reason,
  onSubmit,
  decrementStep,
  defaultVccChecked,
  defaultComment,
}: Props) {
  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <CaseIDAndReasons reason={reason} defaultComment={defaultComment} />
        <SelectRefundVariant defaultVccChecked={defaultVccChecked} />
        <MarkCancelled />
      </DialogContent>

      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained">
          Continue
        </Button>
      </DialogActions>
    </form>
  );
}
