import React from 'react';

import { Cancel, Done, Pending } from '@mui/icons-material';
import { Stack } from '@mui/material';

import { ProgressStatusResponse } from '~/services/AccommodationService';

type Props = {
  state: ProgressStatusResponse['state'];
  label: string;
};

function ProgressStateItem({ state, label }: Props) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {state === 'completed' && <Done />}
      {(state === 'processing' || state === 'accepted') && <Pending />}
      {state === 'failed' && <Cancel />}
      <span>{label}</span>
    </Stack>
  );
}

export default ProgressStateItem;
