import qs from 'qs';

import { json_headers, request } from './common';

const BASE_PATH = `${window.configs.API_HOST}/api/content`;

export const getLuxMomentByOfferId = (offerId: string) => {
  const query = qs.stringify({ offerId });

  const uri = `${BASE_PATH}/lux-moments/pages?${query}`;

  return request(uri, { method: 'GET' });
};

export const createLuxMomentsPage = (offerData: Partial<App.Offer>) => {
  const uri = `${BASE_PATH}/lux-moments/pages`;
  const jsonData = JSON.stringify({
    ...offerData,
    location: offerData.locations?.[0],
  });

  return request(uri, {
    method: 'POST',
    headers: json_headers,
    body: jsonData,
  });
};
