type HandleChangeParams = {
  eventValue: string[];
  rowValue?: string;
  currentValues: string[];
  allValues: string[];
};

export function handleChangeUtil({ eventValue, rowValue, currentValues, allValues }: HandleChangeParams): string[] {
  if (rowValue && !eventValue.includes(rowValue)) {
    return currentValues;
  }

  const hasSelectAll = eventValue.includes('selectAll');
  const hadSelectAll = currentValues.includes('selectAll');
  const isAllSelected = eventValue.length === allValues.length && !eventValue.includes('selectAll');

  if (hasSelectAll && !hadSelectAll) {
    return ['selectAll', ...allValues];
  } else if (!hasSelectAll && hadSelectAll) {
    return rowValue ? [rowValue] : [];
  } else if (hadSelectAll && hasSelectAll && !isAllSelected) {
    return eventValue.filter((value) => value !== 'selectAll');
  } else if (isAllSelected && !hasSelectAll) {
    return ['selectAll', ...allValues];
  } else {
    return eventValue;
  }
}
