import React, { useCallback, useEffect } from 'react';

import { connect } from 'react-redux';

import * as libRegions from '@luxuryescapes/lib-regions';

import {
  addBusinessFlightToCart,
  addBusinessPnr,
  addBusinessTravellerToPNR,
  addFlightToCart,
  addPnr,
  addTravellerToPNR,
  changeCurrencyCode,
  removePnr,
  removeTravellerFromPNR,
  updatePNRId,
  updateTravellerDetails,
} from '../../../../actions/cart';
import { ITEM_TYPE_OFFLINE_FLIGHT } from '../../../../consts/order';
import NoDataWarning from '../NoDataWarning';

import FlightInformation from './Component';

interface Props {
  user: App.User;
  backStep: () => void;
  proceedStep: () => void;
  nextStepLabel: string;
  orderType: string;
}

interface MappedStateProps {
  cart: App.Cart;
  tenant: App.Tenant;
}

interface MappedDispatchProps {
  doChangeCurrencyCode: Utils.ActionDispatcher<typeof changeCurrencyCode>;
  doAddFlightToCart: Utils.ActionDispatcher<typeof addFlightToCart>;
  doAddBusinessFlightToCart: Utils.ActionDispatcher<typeof addBusinessFlightToCart>;
  doAddPnr: Utils.ActionDispatcher<typeof addPnr>;
  doAddBusinessPnr: Utils.ActionDispatcher<typeof addBusinessPnr>;
  doRemovePnr: Utils.ActionDispatcher<typeof removePnr>;
  doUpdatePNRId: Utils.ActionDispatcher<typeof updatePNRId>;
  doAddTravellerToPNR: Utils.ActionDispatcher<typeof addTravellerToPNR>;
  doAddBusinessTravellerToPNR: Utils.ActionDispatcher<typeof addBusinessTravellerToPNR>;
  doUpdateTravellerDetails: Utils.ActionDispatcher<typeof updateTravellerDetails>;
  doRemoveTravellerFromPNR: Utils.ActionDispatcher<typeof removeTravellerFromPNR>;
}

function FlightInformationContainer(props: Props & MappedStateProps & MappedDispatchProps) {
  const {
    cart,
    backStep,
    proceedStep,
    nextStepLabel,
    tenant,
    doChangeCurrencyCode,
    doAddBusinessPnr,
    doAddPnr,
    doAddBusinessFlightToCart,
    doAddFlightToCart,
    doUpdateTravellerDetails,
    doRemovePnr,
    doRemoveTravellerFromPNR,
    doAddBusinessTravellerToPNR,
    doAddTravellerToPNR,
    doUpdatePNRId,
  } = props;

  const { customer } = cart;
  const regions = libRegions.getRegions(tenant.brand).filter((x) => x.paymentMethods.includes('stripe'));

  const isStateValid = useCallback(() => {
    return !cart.orderId;
  }, [cart.orderId]);

  const handleChangeCurrencyCode = useCallback(
    (regionCode: string) => {
      const region = libRegions.getRegionByCode(regionCode, tenant.brand);
      doChangeCurrencyCode(region.currencyCode, region.code);
    },
    [doChangeCurrencyCode, tenant.brand],
  );

  const handleAddPnr = useCallback(() => {
    if (tenant.brand === 'lebusinesstraveller') {
      doAddBusinessPnr();
    } else {
      doAddPnr();
    }
  }, [doAddBusinessPnr, doAddPnr, tenant.brand]);

  const handleAddToCart = useCallback(() => {
    if (tenant.brand === 'lebusinesstraveller') {
      doAddBusinessFlightToCart();
    } else {
      doAddFlightToCart();
    }
  }, [doAddBusinessFlightToCart, doAddFlightToCart, tenant.brand]);

  const handleRemovePnr = useCallback(
    (pnrIndex: number) => {
      const confirmed = window.confirm(`Do you really want to remove PNR?`);
      if (confirmed) {
        doRemovePnr(pnrIndex);
      }
    },
    [doRemovePnr],
  );

  const handleUpdateTravellerDetails = useCallback(
    (pnrIndex: number, travellerIndex: number, travellerDetails: App.OfflineFlightTraveller) => {
      doUpdateTravellerDetails(pnrIndex, travellerIndex, travellerDetails);
    },
    [doUpdateTravellerDetails],
  );

  const handleRemoveTravellerFromPNR = useCallback(
    (pnrIndex: number, travellerIndex: number) => {
      doRemoveTravellerFromPNR(pnrIndex, travellerIndex);
    },
    [doRemoveTravellerFromPNR],
  );

  const handleAddTravellerToPNR = useCallback(
    (pnrIndex) => {
      if (tenant.brand === 'lebusinesstraveller') {
        doAddBusinessTravellerToPNR(pnrIndex);
      } else {
        doAddTravellerToPNR(pnrIndex);
      }
    },
    [doAddBusinessTravellerToPNR, doAddTravellerToPNR, tenant.brand],
  );

  const handleUpdatePNRId = useCallback(
    (e, pnrIndex: number) => {
      if (e.target.value && (!/^[a-z0-9]+$/i.test(e.target.value) || e.target.value.length > 6)) {
        return;
      }
      doUpdatePNRId(pnrIndex, e.target.value.toUpperCase());
    },
    [doUpdatePNRId],
  );

  useEffect(() => {
    handleAddToCart();
  }, [handleAddToCart]);

  const dataPresent = isStateValid();
  if (!dataPresent) {
    return <NoDataWarning userId={customer.id_member} orderType={ITEM_TYPE_OFFLINE_FLIGHT} />;
  }

  return (
    <FlightInformation
      cart={cart}
      regions={regions}
      onAddPnr={handleAddPnr}
      onRemovePnr={handleRemovePnr}
      onChangeCurrencyCode={handleChangeCurrencyCode}
      onUpdateTravellerDetails={handleUpdateTravellerDetails}
      onRemoveTravellerFromPNR={handleRemoveTravellerFromPNR}
      onUpdatePNRId={handleUpdatePNRId}
      onAddTravellerToPNR={handleAddTravellerToPNR}
      backStep={backStep}
      nextStepLabel={nextStepLabel}
      onProceedStep={proceedStep}
    />
  );
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    tenant: state.tenant,
  };
}

const mapDispatchToProps = {
  doChangeCurrencyCode: changeCurrencyCode,
  doAddFlightToCart: addFlightToCart,
  doAddBusinessFlightToCart: addBusinessFlightToCart,
  doAddPnr: addPnr,
  doAddBusinessPnr: addBusinessPnr,
  doRemovePnr: removePnr,
  doUpdatePNRId: updatePNRId,
  doAddTravellerToPNR: addTravellerToPNR,
  doAddBusinessTravellerToPNR: addBusinessTravellerToPNR,
  doUpdateTravellerDetails: updateTravellerDetails,
  doRemoveTravellerFromPNR: removeTravellerFromPNR,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightInformationContainer);
