import React from 'react';

import { TableCell, TableHead, TableRow } from '@mui/material';

interface Props {
  columnNames: string[];
}

export default function TableHeader({ columnNames }: Props) {
  return (
    <TableHead>
      <TableRow>
        {columnNames.map((name, i) => (
          <TableCell key={i}>{name}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
