import React from 'react';

import { useForm } from 'react-hook-form';

import { Button, MenuItem, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Autocomplete, Input, Select } from '~/components/Common/Forms/fields';

import { BEDBANK_COMMENTS_ENUM, BEDBANK_PROFILES_ENUM, BEDBANK_PROPERTY_DOMAINS_ENUM } from '~/consts/bedbank';
import {
  countriesOptions,
  promotionsOptions,
  ratingOperatoins,
  ratingValues,
  statusOptions,
} from '~/consts/bedbankFilterOptions';

const DEFAULT_RATING_COMPARE_OPERATION = 'gte';

type ChainOption = {
  label: string;
  value: string;
};

interface Props {
  query: string;
  country: Array<string>;
  status: Array<string>;
  chainId: Array<string>;
  chains: ChainOption[];
  profile: string;
  ratingOp: string;
  ratingVal: string;
  city: string;
  stateProvince: string;
  domain: string;
  comment: string;
  promotions: boolean;
  handleSubmit: (Query) => void;
  handleExportSubmit: (Query) => void;
}

function SearchForm(props: Props) {
  const { control, getValues, handleSubmit } = useForm({
    defaultValues: {
      query: props.query,
      country: props.country,
      status: props.status,
      chainId: props.chainId,
      profile: props.profile,
      ratingOp: props.ratingOp ?? DEFAULT_RATING_COMPARE_OPERATION,
      ratingVal: props.ratingVal,
      city: props.city,
      stateProvince: props.stateProvince,
      domain: props.domain,
      comment: props.comment,
      promotions: props.promotions !== undefined ? props.promotions?.toString() : promotionsOptions[0].value,
    },
    mode: 'onBlur',
  });

  const exportProperties = () => {
    props.handleExportSubmit(getValues());
  };

  return (
    <form onSubmit={handleSubmit(props.handleSubmit)}>
      <Grid spacing={2} container mb={2}>
        <Grid md={6}>
          <Input fullWidth control={control} muiLabel="Enter Property ID, External ID or Name" name="query" />
        </Grid>
        <Grid md={2}>
          <Select control={control} multiple name="status" muiLabel="Status">
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid md={4}>
          <Autocomplete
            multiple
            control={control}
            getOptionLabel={(option: ChainOption) => option.label}
            name="country"
            muiLabel="Countries"
            options={countriesOptions}
          />
        </Grid>
        <Grid md={2}>
          <Select control={control} muiLabel="Domain" name="domain">
            {BEDBANK_PROPERTY_DOMAINS_ENUM.map((domain) => (
              <MenuItem key={domain} value={domain}>
                {domain}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid md={2}>
          <Input fullWidth control={control} muiLabel="State / Province" name="stateProvince" />
        </Grid>
        <Grid md={2}>
          <Input fullWidth control={control} muiLabel="City" name="city" />
        </Grid>
        <Grid md={3}>
          <Stack direction="row" spacing={1}>
            <Select control={control} name="ratingOp" muiLabel="Rating operator">
              {ratingOperatoins.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Select control={control} name="ratingVal" muiLabel="Rating value">
              {ratingValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid md={2}>
          <Select control={control} name="profile" muiLabel="Profile">
            {BEDBANK_PROFILES_ENUM.map((profile) => (
              <MenuItem key={profile} value={profile}>
                {profile}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid md={4}>
          <Autocomplete
            multiple
            control={control}
            getOptionLabel={(option: ChainOption) => option.label}
            name="chainId"
            muiLabel="Chain Names"
            options={props.chains}
          />
        </Grid>
        <Grid md={2}>
          <Select control={control} name="promotions" muiLabel="Has promotions?">
            {promotionsOptions.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid md={2}>
          <Select control={control} muiLabel="Comment" name="comment">
            {BEDBANK_COMMENTS_ENUM.map((comment) => (
              <MenuItem key={comment} value={comment}>
                {comment}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid md={4}>
          <Stack direction="row" spacing={2}>
            <Button onClick={exportProperties}>Export</Button>
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export default SearchForm;
