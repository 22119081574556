import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { regions } from '@luxuryescapes/lib-regions/lib/regions';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import SearchForm from '~/components/Common/Forms/SearchForm';
import Spinner from '~/components/Common/Spinner';
import AddTrendingPlaceModal from '~/components/Content/TrendingPlaces/AddTrendingPlaceModal';
import { TrendingPlace } from '~/components/Content/TrendingPlaces/Columns';
import TrendingPlacesTable from '~/components/Content/TrendingPlaces/TrendingPlacesTable';
import UpdateTrendingPlaceImageModal from '~/components/Content/TrendingPlaces/UpadteTrendingPlaceImageModal';
import UpdateTrendingPlaceModal from '~/components/Content/TrendingPlaces/UpdateTrendingPlaceModal';

import SearchService from '~/services/SearchService';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

const DEFAULT_BRAND = 'luxuryescapes';
const DEFAULT_REGION = 'AU';
export const TRENDING_PLACES_BRANDS = [
  { value: 'luxuryescapes', name: 'Luxury Escapes' },
  { value: 'scoopontravel', name: 'Scoopon Travel' },
  { value: 'cudotravel', name: 'Cudo Travel' },
  { value: 'treatmetravel', name: 'Treat Me Travel' },
  { value: 'dealstravel', name: 'Deals Travel' },
  { value: 'lebusinesstraveller', name: 'LE Business Traveller' },
  { value: 'leagenthub', name: 'LE Agent Hub' },
];

function TrendingPlacesPage() {
  const [brand, setBrand] = useState<string>(DEFAULT_BRAND);
  const [region, setRegion] = useState<string>(DEFAULT_REGION);
  const [searchString, setSearchString] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [trendingPlaces, setTrendingPlaces] = useState<Array<TrendingPlace>>([]);
  const [displayedTrendingPlaces, setDisplayedTrendingPlaces] = useState<Array<TrendingPlace>>([]);
  const [rowToUpdate, setRowToUpdate] = useState<TrendingPlace | undefined>(undefined);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);

  const handleSearchTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchString(e.target.value);
  };

  useEffect(() => {
    const enteredString = searchString.trim().toLowerCase();
    if (!enteredString) {
      setDisplayedTrendingPlaces(trendingPlaces); // Reset to original list if searchString is empty
    } else {
      setDisplayedTrendingPlaces(
        trendingPlaces.filter(
          (item) =>
            item.placeName.toLowerCase().includes(enteredString) || item.placeId.toLowerCase().includes(enteredString),
        ),
      );
    }
  }, [searchString, trendingPlaces]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { result } = await SearchService.getTrendingPlace(brand, region);
      const fetchedData = result.map(
        ({ id, placeId, manualPosition, zscore, imageId, primaryText, brand, region }, index: number) => ({
          id,
          position: index + 1,
          manualPosition: manualPosition ?? 'Not Set',
          zscore: zscore ?? 'null',
          placeName: primaryText,
          placeId,
          imageId,
          brand,
          region,
        }),
      );
      setTrendingPlaces(fetchedData);
      setDisplayedTrendingPlaces(fetchedData);
    } catch (e) {
      setTrendingPlaces([]);
    } finally {
      setLoading(false);
    }
  }, [brand, region]);

  useEffect(() => {
    fetchData();
  }, [fetchData, brand, region]);

  const handleSearchSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      setSearchString(searchString);
    },
    [searchString],
  );

  const handleUpdate = useCallback(async (row: TrendingPlace) => {
    setUpdateModalOpen(true);
    setRowToUpdate(row);
  }, []);

  const handleAddOpenModal = () => {
    setAddModalOpen(true);
  };

  const handleImageOpenModal = () => {
    setImageModalOpen(true);
  };

  const handleAddCloseModal = async () => {
    setAddModalOpen(false);
    await fetchData();
  };

  const handleImageCloseModal = async () => {
    setImageModalOpen(false);
    await fetchData();
  };

  const handleUpdateCloseModal = async () => {
    setUpdateModalOpen(false);
    await fetchData();
  };

  const onRegionChange = useCallback((event: SelectChangeEvent) => {
    const region = event.target.value;
    setRegion(region);
  }, []);

  const onBrandChange = useCallback((event: SelectChangeEvent) => {
    const brand = event.target.value;
    setBrand(brand);
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Trending Places</title>
      </Helmet>

      <Stack direction="column" gap={4}>
        <AddTrendingPlaceModal open={isAddModalOpen} onClose={handleAddCloseModal} />
        <UpdateTrendingPlaceImageModal open={isImageModalOpen} onClose={handleImageCloseModal} />
        <UpdateTrendingPlaceModal open={isUpdateModalOpen} onClose={handleUpdateCloseModal} rowToUpdate={rowToUpdate} />

        <PageSubheader title={`Trending Place`} />

        <HeaderContainer>
          <Box width="450px">
            <SearchForm
              id="TrendingPlacesSearch"
              placeholder="Search by place name or Id"
              searchString={searchString}
              handleChange={handleSearchTextChange}
              handleSubmit={handleSearchSubmit}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Button sx={{ width: '400px' }} variant="contained" color="primary" onClick={handleAddOpenModal}>
              Add New Trending Place
            </Button>
            <Button sx={{ width: '400px' }} variant="contained" color="primary" onClick={handleImageOpenModal}>
              Update Images
            </Button>
          </Box>
        </HeaderContainer>

        <Box display="flex" gap={2}>
          <FormControl sx={{ maxWidth: 250 }}>
            <InputLabel id="sort-select-label">Region</InputLabel>
            <Select labelId="version-select-label" label="Version" value={region} onChange={onRegionChange}>
              {regions['luxuryescapes'].map((region) => (
                <MenuItem key={region.code} value={region.code}>
                  {region.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ maxWidth: 250 }}>
            <InputLabel id="sort-select-label">Brand</InputLabel>
            <Select labelId="version-select-label" label="Version" value={brand} onChange={onBrandChange}>
              {TRENDING_PLACES_BRANDS.map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {isLoading && <Spinner />}

        <TrendingPlacesTable
          isLoading={isLoading}
          trendingPlaces={displayedTrendingPlaces}
          handleUpdate={handleUpdate}
        />
      </Stack>
    </Container>
  );
}

export default TrendingPlacesPage;
