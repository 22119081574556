import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Skeleton, Stack, Typography } from '@mui/material';

import useHeroPlannerOfferDetails from '~/hooks/customerCommunication/useHeroPlannerOfferDetails';

import { isRequestFulfilled, isRequestPending, isRequestRejected } from '~/utils/requestUtils';

interface Props {
  countryGroupId: string;
  countryId: string;
  offerId: string;
}

function ScheduleDealDetailsBlock(props: Props) {
  const { countryGroupId, countryId, offerId } = props;

  const { offerDetails } = useHeroPlannerOfferDetails(countryGroupId, countryId, offerId);

  return (
    <Stack direction="column">
      <Typography variant="caption">
        <b>Offer ID:</b> {offerId}
      </Typography>
      <Typography variant="body2" color={isRequestRejected(offerDetails) ? 'error' : undefined}>
        <b>Price:</b> {(!offerDetails || isRequestPending(offerDetails)) && <Skeleton width={100} />}
        {isRequestFulfilled(offerDetails) && (
          <>
            {offerDetails.result.durationLabel} {offerDetails.result.formattedPrice} {offerDetails.result.roomType}
          </>
        )}
        {isRequestRejected(offerDetails) && <i>COULD NOT FETCH</i>}
      </Typography>
      <Typography variant="body2" color={isRequestRejected(offerDetails) ? 'error' : undefined}>
        <b>Name:</b> {(!offerDetails || isRequestPending(offerDetails)) && <Skeleton width={100} />}
        {isRequestFulfilled(offerDetails) && offerDetails.result.name}
        {isRequestRejected(offerDetails) && <i>COULD NOT FETCH</i>}
      </Typography>
      <Typography variant="body2" color={isRequestRejected(offerDetails) ? 'error' : undefined}>
        <LoadingButton
          variant="text"
          size="small"
          loading={!offerDetails || isRequestPending(offerDetails)}
          disabled={isRequestRejected(offerDetails)}
          href={offerDetails?.result?.offerLink}
          target="_blank"
        >
          View offer
        </LoadingButton>
      </Typography>
    </Stack>
  );
}

export default ScheduleDealDetailsBlock;
