import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetHeroPlannerCitySegments from '~/queries/customerCommunication/requestGetHeroPlannerCitySegments';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type CitySegmentsParams = NonNullable<
  ValueOf<
    CustomerCommunication.CustomerCommunicationReduxState['countryGroupCountryStateCitySegmentsRecords']
  >['params']
>;

const fetchHeroPlannerCitySegments =
  (
    brand: string,
    countryGroupId: string,
    countryId: string,
    stateId: string,
    cityId: string,
  ): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();

    const params = [brand, countryGroupId, countryId, stateId, cityId] as CitySegmentsParams;
    const key = getRecordKeyFor(...params);
    const existing = appState.customerCommunication.countryGroupCountryStateCitySegmentsRecords[key];
    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FETCHING,
        params,
      });
      try {
        const citySegments = await requestGetHeroPlannerCitySegments(...params);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_FULFILLED,
          params,
          citySegments,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_COUNTRY_GROUP_COUNTRY_STATE_CITY_SEGMENTS_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchHeroPlannerCitySegments;
