import { Refund } from '../NewModal';

import { hasRefundItem } from './checkHasRefundItem';
import { hasRefundSurcharge } from './checkHasRefundSurcharge';
import { getItemRefundAmount } from './getItemRefundAmount';
import { getRefundableMerchantFee } from './getMerchantFeesForRefund';
import { getSurchargeRefundAmount } from './getSurchargeRefundAmount';

export function calculateTotalRefund(refund: Refund) {
  let amountItem = parseFloat('0.0');
  let amountSurcharge = parseFloat('0.0');
  let refundableMerchantFee = parseFloat('0.0');
  if (hasRefundItem(refund)) {
    amountItem = parseFloat(`${getItemRefundAmount(refund)}`);
  }
  if (hasRefundSurcharge(refund)) {
    amountSurcharge = parseFloat(`${getSurchargeRefundAmount(refund)}`);
  }
  refundableMerchantFee = parseFloat(`${getRefundableMerchantFee(refund)}`);
  return parseFloat(`${amountItem + amountSurcharge + refundableMerchantFee}`).toFixed(2);
}
