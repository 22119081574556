import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const ToggleButton = ({ handleClick, isOpen, title, children }) => {
  return (
    <Accordion variant="outlined" onChange={() => handleClick()}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
      <AccordionDetails>{isOpen && children}</AccordionDetails>
    </Accordion>
  );
};

export default ToggleButton;
