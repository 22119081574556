import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Alert, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';

import { useSailingsSearchFilter } from '~/components/Cruises/components/hooks/useSailingsSearchFilter';
import SailingList from '~/components/Cruises/pages/Home/components/Sailings/SailingList';
import SailingSearchFilters from '~/components/Cruises/pages/Home/components/Sailings/SailingSearchFilters';
import { SearchFilters } from '~/components/Cruises/pages/Home/components/Sailings/constants';
import RateCodeCreationFormPage from '~/components/Cruises/pages/RateCodeCreation/components/RateCodeCreationFormPage';
import { onSearchNewLocation } from '~/components/Cruises/utils/onSearchNewLocation';

import useQuery from '~/hooks/useQuery';
import useToggleState from '~/hooks/useToggleState';

import { addQuery, removeQuery } from '~/utils/url';

const RateCodeCreationPage: React.FC = (): JSX.Element => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const [selectedSailings, setSelectedSailings] = useState<GridRowSelectionModel>([]);
  const { isToggled: isLoading, toggle: toggleLoading } = useToggleState(false);
  const { isToggled: isSelectAll, toggleOn: activeIsSelectAll, toggleOff: deactiveIsSelectAll } = useToggleState(false);
  const currentPage = useMemo(() => parseInt(query.get('page')) || 1, [query]);

  const searchFilters = useSailingsSearchFilter();

  const onSearch = useCallback(
    (params: SearchFilters) => {
      toggleLoading();
      const { filtersToRemove, newLocation } = onSearchNewLocation(params, location);

      history.push(removeQuery(newLocation, ...filtersToRemove));
      deactiveIsSelectAll();
      setSelectedSailings([]);
      setTimeout(() => {
        toggleLoading();
      }, 1000);
    },
    [deactiveIsSelectAll, history, location, toggleLoading],
  );

  const onPageChange = useCallback(
    (page) => {
      const newLocation = addQuery(location, { page });
      history.push(newLocation);
    },
    [history, location],
  );

  return (
    <Fragment>
      <Typography variant="h5">Filters</Typography>
      <Alert severity="info">Please, select a cruise line to filter the departure list content.</Alert>
      <SailingSearchFilters querySearchFilters={searchFilters} onSearch={onSearch} cruiseLineRequired />
      {!isLoading && (
        <RateCodeCreationFormPage
          selectedSailings={selectedSailings}
          isSelectAll={isSelectAll}
          searchFilters={searchFilters}
        />
      )}
      <SailingList
        brand={brand}
        page={currentPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
        searchFilters={searchFilters}
        sizePerPage={10}
        showCheckboxSelection={!isLoading}
        onSelectionChange={(row) => {
          setSelectedSailings(row);
        }}
        onClickSelectAll={() => {
          if (!selectedSailings.length) {
            activeIsSelectAll();
          } else {
            deactiveIsSelectAll();
          }
        }}
      />
    </Fragment>
  );
};

export default RateCodeCreationPage;
