import React from 'react';

import { TextField } from '@mui/material';

export default function RefundAmount(props) {
  return (
    <TextField
      id="PartialRefundVariant"
      label="Refund Amount to Customer"
      name="amount"
      value={props.tmpValue('amount')}
      onChange={props.handleTmpChange}
      fullWidth
    />
  );
}
