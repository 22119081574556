import React, { Component } from 'react';

import uniqueId from 'lodash/uniqueId';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import DateWidget from '~/components/Common/Elements/DateWidget';

import {
  addDays,
  dateNowUtc,
  datesHaveSameDayMonthYear,
  formatDateISO,
  isAfter,
  isBefore,
  subDays,
} from '~/services/TimeService';

import ReservationService from '../../../services/ReservationService';
import { daysOfWeek } from '../../../utils/daysOfWeek';
import { reportError } from '../../../utils/reportError';
import roomRateName from '../../../utils/roomRateName';

import SplitButton from './SplitButton';
import { SUBMIT_TYPES, getRoomRatesToUpdate } from './helpers/blackoutDatesHelper';
import { buttonStates } from './states/submitButton';

function newBlackoutDateBlock(startDate, endDate) {
  return {
    ranges: [
      {
        start_date: formatDateISO(startDate),
        end_date: formatDateISO(endDate),
      },
    ],
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  };
}

const addRanges = (blackoutDateBlock) => {
  blackoutDateBlock.ranges = [
    {
      start_date: blackoutDateBlock.start_date,
      end_date: blackoutDateBlock.end_date,
    },
  ];
  delete blackoutDateBlock.start_date;
  delete blackoutDateBlock.end_date;
  return blackoutDateBlock;
};

const getErrorMessage = (e) => {
  if (e.errors && e.errors.length) {
    return e.errors.map(({ message }) => message).join('\n');
  }

  return e.message || 'Something has gone wrong';
};

interface BlackoutDateFormProps {
  propertyRoomTypes: any;
  propertyId: any;
  roomType: any;
  roomRate: any;
  onBlackoutDateBlocksAdded: any;
  onSuccess?: () => void;
  onCancel: () => void;
  startDate?: any;
  endDate?: any;
}

interface BlackoutDateFormState {
  isEdit: boolean;
  selectDays: boolean;
  blackoutDateBlock: any;
  saveState: any;
  successMessages: any;
  errorMessages: any;
}

class BlackoutDateForm extends Component<BlackoutDateFormProps, BlackoutDateFormState> {
  constructor(props) {
    super(props);

    const blackoutDateBlock = props.blackoutDateBlock
      ? // deep clone
        addRanges(JSON.parse(JSON.stringify(props.blackoutDateBlock)))
      : newBlackoutDateBlock(props.startDate, props.endDate);

    const isEdit = Boolean(props.blackoutDateBlock);
    const selectDays = !(
      blackoutDateBlock.monday &&
      blackoutDateBlock.tuesday &&
      blackoutDateBlock.wednesday &&
      blackoutDateBlock.thursday &&
      blackoutDateBlock.friday &&
      blackoutDateBlock.saturday &&
      blackoutDateBlock.sunday
    );

    this.state = {
      isEdit,
      selectDays,
      blackoutDateBlock,
      saveState: buttonStates.default,
      successMessages: [],
      errorMessages: [],
    };

    this.submit = this.submit.bind(this);
  }

  getToggleDayFunc(dayKey) {
    return (e) => {
      e.preventDefault && e.preventDefault();
      this.toggleDay(dayKey);
    };
  }

  toggleDay(dayKey) {
    const newBlock = this.state.blackoutDateBlock;
    newBlock[dayKey] = !newBlock[dayKey];

    this.setState({
      blackoutDateBlock: newBlock,
    });
  }

  setSelectDays(selectDays) {
    const newBlock = selectDays
      ? this.state.blackoutDateBlock
      : Object.assign({}, this.state.blackoutDateBlock, {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        });

    this.setState({
      selectDays,
      blackoutDateBlock: newBlock,
    });
  }

  setDate(type, index, date) {
    const newBlock = this.state.blackoutDateBlock;
    const currentEndDate = newBlock.ranges[index]['end_date'];
    const currentStartDate = newBlock.ranges[index]['start_date'];
    const isSameStartAndEnd = datesHaveSameDayMonthYear(currentStartDate, currentEndDate);
    newBlock.ranges[index][type] = date;

    const shouldAutoChange =
      type === 'start_date'
        ? isSameStartAndEnd || isAfter(newBlock.ranges[index][type], currentEndDate)
        : isSameStartAndEnd || isBefore(newBlock.ranges[index][type], currentStartDate);

    if (shouldAutoChange) {
      const autoDate = type === 'start_date' ? addDays(1, dateNowUtc(date)) : subDays(1, dateNowUtc(date));
      const autoType = type === 'start_date' ? 'end_date' : 'start_date';
      newBlock.ranges[index][autoType] = autoDate;
    }

    this.setState({
      blackoutDateBlock: newBlock,
    });
  }

  addDateRange = () => {
    const newBlock = this.state.blackoutDateBlock;
    newBlock.ranges.push({
      start_date: formatDateISO(),
      end_date: formatDateISO(),
    });

    this.setState({
      blackoutDateBlock: newBlock,
    });
  };

  removeDateRange = (index) => {
    const newBlock = this.state.blackoutDateBlock;
    newBlock.ranges.splice(index, 1);
    this.setState({ blackoutDateBlock: newBlock });
  };

  async submit({ option }) {
    const { propertyRoomTypes, propertyId, roomType, roomRate, onBlackoutDateBlocksAdded, onSuccess } = this.props;

    this.setState({
      saveState: buttonStates.saving,
    });

    const roomRatesToUpdate = getRoomRatesToUpdate({
      option,
      roomType,
      roomRate,
      allRoomTypes: propertyRoomTypes,
    });

    const successes = [];
    const failures = [];
    const addedBlackoutBlocks = {};

    for (const updatingRoomType of roomRatesToUpdate) {
      for (const updatingRoomRate of updatingRoomType.room_rates) {
        const name = roomRateName(updatingRoomType, updatingRoomRate);
        try {
          const response = await ReservationService.createBlackoutDateBlock(
            this.state.blackoutDateBlock,
            propertyId,
            updatingRoomType.id,
            updatingRoomRate.id,
          );

          successes.push(`Created blackout for ${name}`);
          addedBlackoutBlocks[updatingRoomRate.id] = response.result;
        } catch (e) {
          console.warn('error', e);
          const message = getErrorMessage(e);
          const error = `Failed to create blackout for ${name} ` + message;
          failures.push(error);
          reportError(e);
        }
      }
    }

    if (successes.length > 0) {
      onBlackoutDateBlocksAdded && onBlackoutDateBlocksAdded(addedBlackoutBlocks);
      this.setState({ successMessages: successes });
      if (failures.length === 0) {
        onSuccess && onSuccess();
        this.setState({ saveState: buttonStates.saved });
      }
    }

    if (failures.length > 0) {
      this.setState({
        saveState: buttonStates.failed,
        errorMessages: failures,
      });
    }
  }

  getDateRange(index = 0) {
    return (
      <>
        <Typography>Date range</Typography>
        <Stack
          key={uniqueId(String(index))}
          spacing={2}
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DateWidget
            value={this.state.blackoutDateBlock.ranges[index].start_date}
            onChange={(date) => this.setDate('start_date', index, date)}
          />
          <DoubleArrowIcon />
          <DateWidget
            value={this.state.blackoutDateBlock.ranges[index].end_date}
            onChange={(date) => this.setDate('end_date', index, date)}
          />
          <IconButton
            sx={{ visibility: !index ? 'hidden' : 'visible' }}
            aria-label="delete"
            size="large"
            onClick={() => {
              this.removeDateRange(index);
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </>
    );
  }

  render() {
    const { successMessages, errorMessages, saveState, isEdit } = this.state;
    const saving = saveState === buttonStates.saving;

    return (
      <Box>
        {this.state.blackoutDateBlock.ranges.map((item, index) => {
          return this.getDateRange(index);
        })}
        {!isEdit && (
          <Button variant="contained" onClick={this.addDateRange} endIcon={<AddIcon />}>
            Add
          </Button>
        )}
        <Box mt={2}>
          <FormControl>
            <RadioGroup row>
              <FormControlLabel
                checked={!this.state.selectDays}
                onClick={() => this.setSelectDays(false)}
                control={<Radio />}
                label="All days in range"
              />
              <FormControlLabel
                checked={this.state.selectDays}
                onClick={() => this.setSelectDays(true)}
                control={<Radio />}
                label="Select days of week"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          {this.state.selectDays && (
            <ToggleButtonGroup size="large">
              {daysOfWeek.map((day) => {
                return (
                  <ToggleButton
                    sx={{
                      backgroundColor: this.state.blackoutDateBlock[day.key] ? 'primary.main' : 'white',
                      '&:hover': {
                        backgroundColor: this.state.blackoutDateBlock[day.key] ? 'primary.main' : 'white',
                      },
                    }}
                    key={day.key}
                    onClick={this.getToggleDayFunc(day.key)}
                    value={day.key} // Add value prop here
                  >
                    <Typography variant="h5" sx={{ color: this.state.blackoutDateBlock[day.key] ? 'white' : 'black' }}>
                      {day.shortName}
                    </Typography>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          )}
        </Box>
        <Box my={2}>
          {successMessages.length > 0 && (
            <Alert severity="success" onClose={() => this.setState({ successMessages: [] })}>
              <ul>
                {successMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </Alert>
          )}

          {errorMessages.length > 0 && (
            <Alert severity="error" onClose={() => this.setState({ errorMessages: [] })}>
              <ul>
                {errorMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </Alert>
          )}

          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <SplitButton options={SUBMIT_TYPES} onSubmit={this.submit} saving={saving} />
            <Button variant="outlined" onClick={this.props.onCancel}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    );
  }
}

export default BlackoutDateForm;
