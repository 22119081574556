import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Link as MuiLink, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { property as propertyGlobals } from '@luxuryescapes/lib-global';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Image from '~/components/Common/Image';
import PropertyMap from '~/components/Common/PropertyMap';
import DebugModal from '~/components/DebugModal/DebugModal';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import formatGeoData from '../helpers/formatGeoData';

import PropertyMetaPane from './PropertyMetaPane';
import PropertyPullOccupanciesPane from './PropertyPullOccupanciesPane';
import PropertyUploadData from './PropertyUploadData';

const getPropertyTypeName = (type) => {
  if (type === propertyGlobals.UNIQUE_STAYS_TYPE) {
    return 'Unique Stays';
  } else if (type === propertyGlobals.RENTAL_TYPE) {
    return 'Rental';
  } else {
    return 'Hotel';
  }
};

export default function PropertyPane(props) {
  return (
    <div id={props.property.id}>
      <PageSubheader title="Property overview">
        <div>
          <Button variant="text" component={Link} to={`/vendors/${props.vendorId}/edit-property/${props.property.id}`}>
            Edit
          </Button>
          <DebugModal type="property" data={props.property} />
        </div>
      </PageSubheader>

      <Grid container spacing={2}>
        <Grid xs={12} md={8} lg={9}>
          <Stack direction="column" spacing={2}>
            <div>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Hotel code
              </Typography>
              <Typography fontSize={16}>{props.property.hotel_code}</Typography>
            </div>

            <div>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Address
              </Typography>
              <Typography fontSize={16}>{props.property.address}</Typography>
            </div>

            {props.property.parentProperty && (
              <Stack direction="column" spacing={1}>
                <div>
                  <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                    Parent property
                  </Typography>

                  <MuiLink
                    component={Link}
                    to={`/vendors/${props.property.parentProperty.id_salesforce_external}/properties/${props.property.parentProperty.id}`}
                  >
                    {props.property.parentProperty.name}
                  </MuiLink>
                </div>
              </Stack>
            )}

            {props.property.bedbankProperty && (
              <Stack direction="column" spacing={1}>
                <div>
                  <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                    Bedbank property
                  </Typography>

                  <MuiLink component={Link} to={`/bedbank/properties/${props.property.bedbankProperty.id}`}>
                    {props.property.bedbankProperty.name}
                  </MuiLink>
                </div>
              </Stack>
            )}

            <Stack direction="row" spacing={1} width="50%">
              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Type
                </Typography>
                <Typography fontSize={16}>{getPropertyTypeName(props.property.type)}</Typography>
              </Box>

              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Category
                </Typography>
                <Typography fontSize={16}>{props.property.category}</Typography>
              </Box>
            </Stack>

            <Stack direction="row" spacing={1} width="50%">
              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Latitude
                </Typography>
                <Typography fontSize={16}>{props.property.latitude}</Typography>
              </Box>

              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Longitude
                </Typography>
                <Typography fontSize={16}>{props.property.longitude}</Typography>
              </Box>
            </Stack>

            <Stack direction="row" spacing={1} width="50%">
              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Location City
                </Typography>
                <Typography fontSize={16}>{props.property.location_heading}</Typography>
              </Box>

              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Location Country/State
                </Typography>
                <Typography fontSize={16}>{props.property.location_subheading}</Typography>
              </Box>
            </Stack>

            <div>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Children Policy
              </Typography>
              <Typography fontSize={16}>{props.property.children_policy}</Typography>
            </div>

            <Stack direction="row" spacing={1} width="50%">
              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Max Child Age
                </Typography>
                <Typography fontSize={16}>{props.property.max_child_age}</Typography>
              </Box>

              <Box width="50%">
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Max Infant Age
                </Typography>
                <Typography fontSize={16}>{props.property.max_infant_age}</Typography>
              </Box>
            </Stack>

            <div>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Taxes and fees
              </Typography>
              <Typography fontSize={16}>{props.property.taxes_and_fees_value}</Typography>
            </div>

            {props.property.taxes_payable_at_property && (
              <div>
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Has taxes and fees payable at property
                </Typography>
                <Typography fontSize={16}>Yes</Typography>
              </div>
            )}
            {props.property.channel_manager === PROPERTY_CHANNEL_MANAGERS.derbysoft && (
              <div>
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Send nightly reservation rates
                </Typography>
                <Typography fontSize={16}>{props.property.send_nightly_reservation_rates ? 'Yes' : 'No'}</Typography>
              </div>
            )}
            {props.property.exclude_flash_pay_at_property && (
              <div>
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Exclude Pay @ Property for LTLE
                </Typography>
                <Typography fontSize={16}>Yes</Typography>
              </div>
            )}

            <div>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Geolocation Data
              </Typography>
              <Typography fontSize={16}>{formatGeoData(props.property.geo_data, 'property')}</Typography>
            </div>
          </Stack>
        </Grid>

        <Grid xs={12} md={4} lg={3}>
          <Stack direction="column" spacing={2}>
            {props.property.channel_managed && props.property.channel_manager && (
              <div>
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Channel Manager
                </Typography>
                <Typography fontSize={16}>{props.property.channel_manager}</Typography>
              </div>
            )}

            {props.property.logo_id_cloudinary_external && (
              <div>
                <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                  Logo
                </Typography>
                <Image className="img-responsive" publicId={props.property.logo_id_cloudinary_external} />
              </div>
            )}

            <PropertyMetaPane
              vendorId={props.vendorId}
              propertyId={props.property.id}
              hotelMetaCode={props.property.hotel_meta_code}
              hotelMetaProvider={props.property.hotel_meta_provider}
            />

            <PropertyUploadData
              vendorId={props.vendorId}
              propertyId={props.property.id}
              channelManager={props.property.channel_manager}
            />

            <PropertyPullOccupanciesPane
              propertyId={props.property.id}
              channelManager={props.property.channel_manager}
            />
          </Stack>
        </Grid>
      </Grid>

      <Box mt={2}>
        <PropertyMap lat={props.property.latitude} lng={props.property.longitude} />
      </Box>
    </div>
  );
}
