import React from 'react';

import { ArrayFieldTemplateProps } from '@rjsf/utils';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Stack } from '@mui/material';

function PromotionsArrayFieldTemplate({
  items,
  handleDelete,
  canAdd,
  onAddClick,
}: ArrayFieldTemplateProps & { handleDelete: (index: number) => void }) {
  return (
    <Box>
      {items.map((item) => (
        <Stack direction="row" spacing={4} key={item.key}>
          <Box flexGrow={1}>{item.children}</Box>

          <Stack direction="column" spacing={1}>
            {item.hasRemove && (
              <Box>
                <IconButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(item.index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Stack>
        </Stack>
      ))}

      {canAdd && (
        <Box mt={2}>
          <Button variant="text" onClick={onAddClick}>
            Add New Promotion
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default PromotionsArrayFieldTemplate;
