import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Box, Button, Container } from '@mui/material';

import { emptyCart } from '~/actions/cart';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { ITEM_TYPE_GIFT_CARD, ITEM_TYPE_INSURANCE, ITEM_TYPE_OFFER, ITEM_TYPE_OFFLINE_FLIGHT } from '~/consts/order';

import AddonsSelection from '../AddonsSelection';
import BookingInformation from '../BookingInformation';
import FlightInformation from '../FlightInformation';
import GiftCardSelection from '../GiftCardSelection';
import { OfferSelection } from '../OfferSelection';
import { OfferSelection as OfferSelectionLegacy } from '../OfferSelectionLegacy';
import { PackageSelection } from '../PackageSelection';
import PackageSelectionLegacy from '../PackageSelectionLegacy';
import StepIndicator from '../StepIndicator';

import Payment from './NewOrderPayment';

/******* Names of steps in the order process **/
const OFFER_SELECTION = 'offer-selection';
const PACKAGE_SELECTION = 'package-selection';
const GIFT_CARD_SELECTION = 'gift-card-selection';
const BOOKING_INFORMATION = 'booking-information';
const ADDONS_SELECTION = 'add-ons-selection';
const FLIGHT_INFORMATION = 'traveller';
const PAYMENT = 'payment';

// a mapping from names of steps to components to render
const COMPONENTS = {
  [OFFER_SELECTION]: window.configs.MULTI_OFFER === 'true' ? OfferSelection : OfferSelectionLegacy,
  [PACKAGE_SELECTION]: window.configs.MULTI_OFFER === 'true' ? PackageSelection : PackageSelectionLegacy,
  [GIFT_CARD_SELECTION]: GiftCardSelection,
  [BOOKING_INFORMATION]: BookingInformation,
  [ADDONS_SELECTION]: AddonsSelection,
  [FLIGHT_INFORMATION]: FlightInformation,
  [PAYMENT]: Payment,
};

// maps step names to human friendly labels
const LABELS = {
  [OFFER_SELECTION]: 'Offer Selection',
  [PACKAGE_SELECTION]: 'Package Selection',
  [GIFT_CARD_SELECTION]: 'Gift Card Selection',
  [BOOKING_INFORMATION]: 'Booking Information',
  [ADDONS_SELECTION]: 'Add-ons Selection',
  [FLIGHT_INFORMATION]: 'Flight Traveller',
  [PAYMENT]: 'Payment',
} as const;

// steps is an array of steps in an ordered sequence
const STEPS = {
  [ITEM_TYPE_OFFER]: [OFFER_SELECTION, PACKAGE_SELECTION, BOOKING_INFORMATION, ADDONS_SELECTION, PAYMENT],
  [ITEM_TYPE_GIFT_CARD]: [GIFT_CARD_SELECTION, PAYMENT],
  [ITEM_TYPE_OFFLINE_FLIGHT]: [FLIGHT_INFORMATION, PAYMENT],
} as const;

type Props = {
  onEmptyCart?: Utils.ActionDispatcher<typeof emptyCart>;
  orderType: App.OrderType;
  user: App.User;
  step: string;
};

type StepComponentProps = {
  proceedStep: () => void;
  backStep: () => void;
  nextStepLabel: string;
  user: App.User;
  orderType: string;
};

export default function NewOrderPage(props: Props) {
  const { step, user, orderType, onEmptyCart } = props;

  const history = useHistory();

  const steps = useMemo<string[]>(() => STEPS[orderType], [orderType]);
  const stepNames = useMemo<string[]>(() => steps.map((step) => LABELS[step]), [steps]);
  const currentStepIdx = useMemo<number>(() => steps.indexOf(step), [steps, step]);
  const orderUrlComponent = useMemo(() => {
    if (orderType === ITEM_TYPE_OFFER) {
      return 'new-order';
    }
    if (orderType === ITEM_TYPE_GIFT_CARD) {
      return 'new-gift-card-order';
    }
    if (orderType === ITEM_TYPE_INSURANCE) {
      return 'new-insurance-order';
    }
    if (orderType === ITEM_TYPE_OFFLINE_FLIGHT) {
      return 'new-flight-order';
    }
  }, [orderType]);
  const nextStepName = useMemo(() => {
    const nextStep = steps[currentStepIdx + 1];

    return LABELS[nextStep];
  }, [currentStepIdx, steps]);

  const CurrentStepComponent = useMemo<React.ComponentType<StepComponentProps>>(
    () => COMPONENTS[step] ?? COMPONENTS[steps[0]],
    [step, steps],
  );

  const transitionStep = (nextStep?: string | null) => {
    if (!nextStep) {
      return history.push(`/users/${user.id_member}`);
    }

    history.push(`/users/${user.id_member}/${orderUrlComponent}/${nextStep}`);
  };

  const proceedStep = () => {
    const nextStep = steps[currentStepIdx + 1];
    if (!nextStep) return;

    transitionStep(nextStep);
  };

  const backStep = () => {
    const prevStep = steps[currentStepIdx - 1];
    transitionStep(prevStep);
  };

  const handleCancelOrderProcess = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onEmptyCart?.();
    history.push(`/users/${user.id_member}`);
  };

  return (
    <>
      <Helmet>
        <title>New order | {user.fullName}</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader
          title={
            <>
              New order: {user.fullName}{' '}
              <small>
                <em>{user.email}</em>
              </small>
            </>
          }
        >
          <Button variant="text" onClick={handleCancelOrderProcess}>
            Cancel
          </Button>
        </PageHeader>

        <StepIndicator currentStepIdx={currentStepIdx} steps={steps} stepNames={stepNames} />

        <Box mt={4} className="new-order-page">
          <CurrentStepComponent
            proceedStep={proceedStep}
            backStep={backStep}
            nextStepLabel={nextStepName}
            user={user}
            orderType={orderType}
          />
        </Box>
      </Container>
    </>
  );
}
