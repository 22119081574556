import React from 'react';

import { Box, BoxProps, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

interface ForceBundleViewProps {
  selectedForceBundle: App.ForceBundle;
  availability: number;
}

export const ForceBundleView = ({ selectedForceBundle, availability }: ForceBundleViewProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Force Bundle Deal Name</TableCell>
            <TableCell>{selectedForceBundle?.dealName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Force Bundle ID</TableCell>
            <TableCell>{selectedForceBundle?.forceBundleId}</TableCell>
          </TableRow>
          {selectedForceBundle?.dealDescription && (
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Force Bundle Description</TableCell>
              <TableCell>{selectedForceBundle?.dealDescription}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Origins
            </TableCell>
            <TableCell>{selectedForceBundle?.origins.join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Destinations
            </TableCell>
            <TableCell>{selectedForceBundle?.destinations.join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Region
            </TableCell>
            <TableCell>{selectedForceBundle?.region}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Fare Class
            </TableCell>
            <TableCell>{selectedForceBundle?.fareClass}</TableCell>
          </TableRow>
          {selectedForceBundle?.fareBasis && (
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Fare Basis
              </TableCell>
              <TableCell>{selectedForceBundle?.fareBasis}</TableCell>
            </TableRow>
          )}
          {selectedForceBundle?.bookingCode && (
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                Fare Booking Code
              </TableCell>
              <TableCell>{selectedForceBundle?.bookingCode}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Max Count
            </TableCell>
            <TableCell>{selectedForceBundle?.maxCountTotal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Estimated Available Count
            </TableCell>
            <TableCell>{availability}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Estimated Bookings
            </TableCell>
            <TableCell>{selectedForceBundle?.maxCountTotal - availability ?? '?'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Carriers
            </TableCell>
            <TableCell>
              {selectedForceBundle?.carriers
                .map(({ carrierCode = '??', carrierName = 'unknown' }) => `${carrierCode} (${carrierName})`)
                .join(', ')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Providers
            </TableCell>
            <TableCell>{selectedForceBundle?.providers.join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
              Running Dates
            </TableCell>
            <TableCell sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {selectedForceBundle?.bookingPeriods?.map(({ startDate, endDate }) => (
                <DateSegment key={startDate + endDate} startDate={startDate} endDate={endDate} maxWidth="300px" />
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface DateSegmentProps extends BoxProps {
  startDate?: string;
  endDate?: string;
}

const DateSegment = ({ startDate, endDate, ...other }: DateSegmentProps) => {
  const formattedStartDate = new Date(startDate).toLocaleString(undefined, {
    dateStyle: 'short',
  });
  const formattedEndDate = new Date(endDate).toLocaleString(undefined, {
    dateStyle: 'short',
  });
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flexGrow: 1,
      }}
      {...other}
    >
      <Box display="flex" alignItems="end" flexDirection="column">
        <Typography variant="subtitle2">{formattedStartDate}</Typography>
        <Box border="1px solid black" height="15px" width="15px" borderRadius="100%" />
      </Box>
      <Box sx={{ transform: 'translateY(10px)' }} flexGrow="1" height="1px" border="1px solid black" />
      <Box display="flex" alignItems="start" flexDirection="column">
        <Typography variant="subtitle2">{formattedEndDate}</Typography>
        <Box border="1px solid black" height="15px" width="15px" borderRadius="100%" />
      </Box>
    </Box>
  );
};
