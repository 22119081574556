import qs from 'qs';

import { AuditLog, AuditLogResponse } from '~/types/responses';

import { request } from './common';

const baseUrl = '/api/audit/fetch';

function getBasePath() {
  return window.configs.API_HOST;
}

export type MappedAuditLog = {
  logId: string;
  action: string;
  timestamp: string;
  userEmail: string;
  modelId: string;
  meta: any;
  diff?: { added?: any; deleted?: string[]; updated?: any };
};

export async function getLogs(page = 1, limit = 50): Promise<AuditLogResponse> {
  return request(window.configs.API_HOST + baseUrl + '?' + qs.stringify({ page, limit }), {
    method: 'GET',
  }) as Promise<AuditLogResponse>;
}

export async function getLogsByUrl(urlMatch: string, page = 1, limit = 50): Promise<AuditLogResponse> {
  return request(`${getBasePath()}${baseUrl}?${qs.stringify({ urlMatch, page, limit })}`, {
    method: 'GET',
  }) as Promise<AuditLogResponse>;
}

export async function getLogsByKeyValue(key: string, value: string, page = 1, limit = 50): Promise<AuditLogResponse> {
  return request(`${getBasePath()}${baseUrl}?${qs.stringify({ filterKey: key, filterValue: value, page, limit })}`, {
    method: 'GET',
  }) as Promise<AuditLogResponse>;
}

export async function getLogsByUserEmail(email: string, page = 1, limit = 50): Promise<AuditLogResponse> {
  return request(`${getBasePath()}${baseUrl}?${qs.stringify({ email, page, limit })}`, {
    method: 'GET',
  }) as Promise<AuditLogResponse>;
}

export async function getLogsByModelAndId(model: string, id: string, page = 1, limit = 50): Promise<AuditLogResponse> {
  return request(`${getBasePath()}${baseUrl}/${model}/${id}?${qs.stringify({ page, limit })}`, {
    method: 'GET',
  }) as Promise<AuditLogResponse>;
}

export async function getLogsByModel(model: string, page = 1, limit = 50): Promise<AuditLogResponse> {
  return request(`${getBasePath()}${baseUrl}/${model}?${qs.stringify({ page, limit })}`, {
    method: 'GET',
  }) as Promise<AuditLogResponse>;
}

export function groupLogsByModelId(
  logs: AuditLog[],
  getIdForDelete: (log: AuditLog) => string,
): { [key: string]: MappedAuditLog[] } {
  return logs
    .flatMap((log) => {
      if (log.action === 'DELETE') {
        return {
          logId: log.id,
          action: log.action,
          timestamp: log.timestamp,
          userEmail: log.user_email,
          meta: log.meta,
          modelId: getIdForDelete(log),
        };
      }

      return log.models.map((model) => ({
        logId: log.id,
        action: log.action,
        timestamp: log.timestamp,
        userEmail: log.user_email,
        meta: log.meta,
        modelId: model.id,
        diff: model.diff,
      }));
    })
    .reduce((logMap, log) => {
      if (logMap[log.modelId]) {
        logMap[log.modelId].push(log);
      } else {
        logMap[log.modelId] = [log];
      }

      return logMap;
    }, {});
}

export function groupLogsByModelType(logs: AuditLog[]): { [key: string]: AuditLog[] } {
  const map: { [key: string]: AuditLog[] } = {};

  logs.forEach((log) => {
    log.models.forEach((model) => {
      if (!map[model.type]) {
        map[model.type] = [];
      }
      map[model.type].push(log);
    });
  });

  return map;
}
