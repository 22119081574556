import React, { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import { Helmet } from 'react-helmet';

import { Box, Button, Container, Stack, Tab, Tabs } from '@mui/material';

import AmenityGroupContainer from '~/components/Amenities/AmenityGroupContainer';
import PageHeader from '~/components/Common/Elements/PageHeader';

import useToggleState from '~/hooks/useToggleState';

import ReservationService from '~/services/ReservationService';

import AmenitySearchForm from './AmenitySearchForm';
import NewAmenityGroupModal from './NewAmenityGroupModal';
import NewAmenityModal from './NewAmenityModal';

export default function AmenitiesPageContainer() {
  const [key, setKey] = useState<TabKey>('amenity');

  const [amenitySearchName, setAmenitySearchName] = useState('');
  const [amenitySearchBuiltIn, setAmenitySearchBuiltIn] = useState(true);
  const [amenityPage, setAmenityPage] = useState(0);
  const [amenities, setAmenities] = useState<App.Amenity[]>([]);
  const [amenityTotal, setAmenityTotal] = useState(0);

  const [groupPage, setGroupPage] = useState(0);
  const [groups, setGroups] = useState<App.AmenityGroup[]>([]);
  const [groupTotal, setGroupTotal] = useState(0);

  const { isToggled: isSearching, toggleOn: setIsSearching, toggleOff: setNotSearching } = useToggleState(true);

  const {
    isToggled: isNewAmenityModalVisible,
    toggleOn: showNewAmenityModal,
    toggleOff: hideNewAmenityModal,
  } = useToggleState();
  const {
    isToggled: isNewGroupModalVisible,
    toggleOn: showNewGroupModal,
    toggleOff: hideNewGroupModal,
  } = useToggleState();

  const searchAmenities = () => {
    setIsSearching();
    setAmenities([]);
    ReservationService.searchAmenities(amenitySearchName, amenitySearchBuiltIn, 10, amenityPage + 1)
      .then((data) => {
        setAmenities(data.result);
        setAmenityTotal(data.total);
      })
      .finally(() => setNotSearching());
  };

  // Effect for updating amenities
  useEffect(() => {
    debounce(() => {
      // Flush results
      searchAmenities();
    }, 500)();
  }, [amenitySearchName, amenitySearchBuiltIn, amenityPage]);

  const searchGroups = () => {
    setIsSearching();
    ReservationService.getAmenityGroups(groupPage + 1)
      .then((data) => {
        setGroups(data.result);
        setGroupTotal(data.total);
      })
      .finally(() => setNotSearching());
  };

  useEffect(() => {
    searchGroups();
  }, [groupPage]);

  const TabsMap = {
    amenity: {
      title: 'Amenities',
      component: AmenitySearchForm,
      props: {
        amenities: amenities,
        page: amenityPage,
        setPage: setAmenityPage,
        isSearching: isSearching,
        total: amenityTotal,
        searchName: amenitySearchName,
        setSearchName: setAmenitySearchName,
        searchBuiltIn: amenitySearchBuiltIn,
        setSearchBuiltIn: setAmenitySearchBuiltIn,
      },
    },
    groups: {
      title: 'Amenity Groups',
      component: AmenityGroupContainer,
      props: {
        groups: groups,
        page: groupPage,
        setPage: setGroupPage,
        isSearching: isSearching,
        total: groupTotal,
      },
    },
  } as const;

  type TabKey = keyof typeof TabsMap;
  const allTabs = Object.keys(TabsMap);
  const currentTab = TabsMap[key];
  const title = `Amenities / ${currentTab.title}`;

  const handleChange = (_: React.SyntheticEvent, newKey: TabKey) => {
    setKey(newKey);
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title}>
        <Stack direction="row" spacing={2} alignItems="right">
          <Button variant="contained" onClick={showNewAmenityModal}>
            New Amenity
          </Button>
          <Button variant="contained" onClick={showNewGroupModal}>
            New Group
          </Button>
        </Stack>
      </PageHeader>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row' }}>
        <Tabs value={key} onChange={handleChange} id="amenities">
          {allTabs.map((tabKey) => (
            <Tab key={tabKey} label={TabsMap[tabKey].title} value={tabKey} />
          ))}
        </Tabs>
      </Box>

      <Box pt={4}>
        <Box component={currentTab.component} {...currentTab.props} />
      </Box>
      <NewAmenityModal
        isModalOpen={isNewAmenityModalVisible}
        closeModal={hideNewAmenityModal}
        refreshAmenities={searchAmenities}
      />
      <NewAmenityGroupModal
        isModalOpen={isNewGroupModalVisible}
        closeModal={hideNewGroupModal}
        refreshGroups={searchGroups}
      />
    </Container>
  );
}
