import React, { useEffect, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Box, Button, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import cleanProperty from './helpers/cleanProperty';

const fetchProperty = (propertyId) => {
  return ReservationService.getProperty(propertyId);
};

const fetchPropertyTaxesAndFeesSchema = (propertyId) => {
  return ReservationService.getPropertyTaxesAndFeesSchema(propertyId);
};

const fetchVendor = (vendorId) => {
  return VendorsService.getVendorById(vendorId);
};

const formSchema = (
  taxesAndFeesSchema,
  commissionStatement,
  gstinSchema,
  taxesPayableAtProperty,
  allTaxesAndFeesAsNonCommssionable,
  includeTaxAmountIntoBeforeAmount,
  excludeFromPayAtProperty,
  channelManagerReservationRate,
  sendDailyOverrideRates,
  dependencies,
) => ({
  type: 'object',
  properties: {
    commission_statement: commissionStatement,
    gstin: gstinSchema,
    taxes_payable_at_property: taxesPayableAtProperty,
    all_taxes_and_fees_as_non_commssionable: allTaxesAndFeesAsNonCommssionable,
    ...(includeTaxAmountIntoBeforeAmount
      ? { include_tax_amount_into_before_amount: includeTaxAmountIntoBeforeAmount }
      : {}),
    exclude_flash_pay_at_property: excludeFromPayAtProperty,
    send_daily_override_rates: sendDailyOverrideRates,
    channel_manager_reservation_rate: channelManagerReservationRate,
    taxes_and_fees: taxesAndFeesSchema,
  },
  dependencies,
});

const mapItems = (item) => ({
  name: item.name,
  unit:
    item.unit === 'amount'
      ? {
          amount: item.value,
          currency: item.currency,
        }
      : {
          value: item.value,
        },
  type: item.type,
  per_person: item.per_person,
  product_type: item.product_type,
  payable_at_property: item.payable_at_property,
  additional_tax: item.additional_tax,
  exclude_from_cm_rate: item.exclude_from_cm_rate,
  exclude_flash_pay_at_property: item.exclude_flash_pay_at_property,
});

const uiSchema = {
  gstin: {
    'ui:placeholder': 'Enter GSTIN',
    classNames: 'gstin-block',
  },
  commission_statement: {
    classNames: 'gstin-block',
  },
  taxes_and_fees: {
    items: {
      classNames: 'tax-block',
    },
  },
};

export default function PropertyTaxesFeesEditPage(props) {
  const { id_property: propertyId, id_vendor: vendorId } = useParams<{ id_vendor: string; id_property: string }>();

  const [property, setProperty] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [schema, setSchema] = useState(null);

  const [items, setItems] = useState([]);
  const [saveState, setSaveState] = useState(buttonStates.default);
  const [err, setErr] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const onChange = ({ formData }: IChangeEvent) => {
    setErr(null);
    setSaveState(buttonStates.default);
    setItems(formData.taxes_and_fees);
    setProperty({
      ...property,
      commission_statement: formData.commission_statement,
      taxes_payable_at_property: formData.taxes_payable_at_property,
      all_taxes_and_fees_as_non_commssionable: formData.all_taxes_and_fees_as_non_commssionable,
      include_tax_amount_into_before_amount: formData.include_tax_amount_into_before_amount,
      send_daily_override_rates: formData.send_daily_override_rates,
      channel_manager_reservation_rate: formData.channel_manager_reservation_rate,
      exclude_flash_pay_at_property: formData.exclude_flash_pay_at_property,
      gstin: formData.gstin,
    });
  };

  const onSubmit = ({ formData }: IChangeEvent) => {
    setErr(null);

    const payload = {
      taxes_and_fees: formData.taxes_and_fees,
      commission_statement: formData.commission_statement,
      taxes_payable_at_property: formData.taxes_payable_at_property,
      all_taxes_and_fees_as_non_commssionable: formData.all_taxes_and_fees_as_non_commssionable,
      include_tax_amount_into_before_amount: formData.include_tax_amount_into_before_amount,
      gstin: formData.commission_statement ? formData.gstin : '',
      exclude_flash_pay_at_property: formData.exclude_flash_pay_at_property,
      channel_manager_reservation_rate: formData.channel_manager_reservation_rate,
      send_daily_override_rates: formData.send_daily_override_rates,
    };

    setSaveState(buttonStates.saving);

    ReservationService.updatePropertyTaxesAndFees(payload, propertyId)
      .then(() => {
        const { history } = props;
        history.push(`/vendors/${vendorId}/properties/${propertyId}`);
      })
      .catch((e) => {
        setSaveState(buttonStates.failed);
        setErr(e);
      });
  };

  useEffect(() => {
    Promise.all([fetchPropertyTaxesAndFeesSchema(propertyId), fetchProperty(propertyId), fetchVendor(vendorId)]).then(
      ([schemaData, propertyData, vendorData]) => {
        const schema = schemaData.put.body.schema;
        const property = propertyData.result;
        const vendor = vendorData.result;
        setProperty(cleanProperty(property));
        setItems(property.taxes_and_fees.map(mapItems));
        const {
          taxes_and_fees,
          commission_statement,
          gstin,
          taxes_payable_at_property,
          all_taxes_and_fees_as_non_commssionable,
          include_tax_amount_into_before_amount,
          exclude_flash_pay_at_property,
          channel_manager_reservation_rate,
          send_daily_override_rates,
        } = schema.properties;
        setSchema(
          formSchema(
            taxes_and_fees,
            commission_statement,
            gstin,
            taxes_payable_at_property,
            all_taxes_and_fees_as_non_commssionable,
            property.channel_manager === PROPERTY_CHANNEL_MANAGERS.derbysoft
              ? include_tax_amount_into_before_amount
              : undefined,
            exclude_flash_pay_at_property,
            channel_manager_reservation_rate,
            send_daily_override_rates,
            schema.dependencies,
          ),
        );

        setVendor(vendor);

        setHasLoaded(true);
      },
    );
  }, [propertyId, vendorId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor?.name || vendorId} | {property?.name || propertyId} | Edit Property Taxes and Fees
        </title>
      </Helmet>

      <PageHeader title="Edit property taxes and fees" backButton={`/vendors/${vendorId}/properties/${propertyId}`} />

      <Box>
        <p>Please note:</p>
        <ul>
          <li>Taxes/fees can be per night or per stay</li>
          <li>Each entry will be applied to the gross price</li>
          <li>Ordering does not affect the final calculation</li>
          <li>Channel manager taxes & fees will take precedence over property taxes & fees</li>
          <li>
            Taxes marked as "Channel Manager Additional Tax" will be added to the rates sent by the channel manager
          </li>
          <li>
            "Channel Manager Additional Tax" taxes that are also marked as "Send Tax-Exclusive Rates to CM" will be
            exlcude from the rates sent to the channel manager when a reservation is made
          </li>
        </ul>
      </Box>

      {!hasLoaded && <div>Loading...</div>}

      {hasLoaded && (
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={{
            exclude_flash_pay_at_property: property.exclude_flash_pay_at_property,
            taxes_and_fees: items,
            commission_statement: property.commission_statement,
            gstin: property.gstin,
            taxes_payable_at_property: property.taxes_payable_at_property,
            all_taxes_and_fees_as_non_commssionable: property.all_taxes_and_fees_as_non_commssionable,
            include_tax_amount_into_before_amount: property.include_tax_amount_into_before_amount,
            send_daily_override_rates: property.send_daily_override_rates,
            channel_manager_reservation_rate: property.channel_manager_reservation_rate,
          }}
          onChange={onChange}
          onSubmit={onSubmit}
          validator={validator}
        >
          <Button type="submit" variant="contained" disabled={saveState === buttonStates.saving}>
            {buttonMessages[saveState]}
          </Button>
        </Form>
      )}

      {!!err && <ErrorDisplay message={err.message} />}
    </Container>
  );
}
