import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { fetchBadgeDetails, removeBadge, submitPatchForm, submitPostForm } from '../../../actions/badges';

import { TAG_ICONS } from './BadgesContainer';
import BadgesForm from './BadgesForm';

interface BadgesDetailsContainerProps {
  error: { message?: string };
  success: boolean;
  isLoading: boolean;
  badgeId: number;
  badge: App.Badge;
  badges: Array<App.Badge>;
  fetchBadgeDetails: (badgeId: number, badges: Array<App.Badge>) => void;
  submitPatchForm: (badge: App.Badge) => void;
  submitPostForm: (badge: App.Badge) => void;
  removeBadge: (badge: App.Badge) => void;
}

const BadgesDetailContainer = ({
  error,
  success,
  isLoading,
  badgeId,
  badge: reducerBadge,
  badges,
  fetchBadgeDetails,
  submitPatchForm,
  submitPostForm,
  removeBadge,
}: BadgesDetailsContainerProps) => {
  const [badge, setBadge] = useState<App.Badge>();

  useEffect(() => {
    fetchBadgeDetails(badgeId, badges);
  }, [badgeId, badges, fetchBadgeDetails]);

  useEffect(() => {
    setBadge(reducerBadge);
  }, [reducerBadge]);

  const checkUniqueName = (name) => {
    const uniqueName = !badges.find((b) => b.name === name);
    return uniqueName;
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Badges | {badge?.name || ''}</title>
      </Helmet>

      <Box>
        <Button component={Link} to={'/marketing/badges/'} startIcon={<ChevronLeftIcon />} className="T-return">
          Return to badges
        </Button>
      </Box>

      <PageHeader title={`Edit badge ${badge?.name}`} />

      <BadgesForm
        {...badge}
        tag_icons={TAG_ICONS}
        onBadgeUpdate={submitPatchForm}
        onBadgeSubmit={submitPostForm}
        onBadgeRemove={removeBadge}
        checkUniqueName={checkUniqueName}
        error={error}
        success={success}
        isLoading={isLoading}
      />
    </Container>
  );
};

const mapStateToProps = (state: App.State, ownProps) => {
  const { match } = ownProps;
  const badgeId = match.params.id_badge;
  const { isLoading, isUpdating, error, success, badge, items } = state.badges;

  return {
    error,
    success,
    isLoading,
    isUpdating,
    badges: items,
    badge,
    badgeId,
  };
};

const mapDispatchToProps = {
  fetchBadgeDetails,
  submitPatchForm,
  submitPostForm,
  removeBadge,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BadgesDetailContainer));
