import React from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type ToggleProps = {
  color?: string;
  options: string[];
  background?: string;
  onClick?: (option: string) => void;
  selected: { [key: string]: boolean };
};

export const Toggle: React.FC<ToggleProps> = (props) => {
  const { options, selected, onClick } = props;

  return (
    <ToggleButtonGroup color="primary" onChange={(_, value) => onClick(value)} exclusive>
      {options.map((item: string, i) => (
        <ToggleButton key={`${i}-${item}`} value={item} selected={selected[item]}>
          {item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
