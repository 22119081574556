import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerCountryGroupsGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/country-groups']['get'];

function mapHeroPlannerCountryGroup(
  external: Utils.ArrayElement<
    HeroPlannerCountryGroupsGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerCountryGroup {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetHeroPlannerCountryGroups(brand: string) {
  if (!brand) throw 'missing brand';

  const url = makeCustomerCommunicationV1URL('/todays-escapes-schedule/hero-planner/country-groups');
  url.searchParams.set('brand', brand);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerCountryGroupsGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerCountryGroup);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerCountryGroups;
