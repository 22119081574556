import qs from 'qs';

import { definitions as CarHireApi } from '@luxuryescapes/contract-car-hire';

import { request } from './common';

interface HttpResponse<T> {
  status: number;
  message: string;
  result: T;
}

export enum CarHireStatus {
  FAILED = 'failed',
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

function basePath() {
  return window.configs.LUX_API_DOMAIN + '/api/car-hire/v1';
}

export const get = (
  id: string,
  params?: { [key: string]: string },
): Promise<HttpResponse<CarHireApi['bookingGetResponse']>> => {
  const url = `${basePath()}/bookings/${id}?${qs.stringify(params)}`;
  return request(url, {
    method: `GET`,
  });
};

export const list = (params: { [key: string]: string }): Promise<HttpResponse<CarHireApi['bookingListResponse']>> => {
  const url = `${basePath()}/bookings?${qs.stringify(params)}`;
  return request(url, {
    method: `GET`,
  });
};
