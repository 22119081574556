import React, { useEffect, useState } from 'react';

import ListAltIcon from '@mui/icons-material/ListAlt';
import { Alert, Button, Stack, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { SettingsForm } from '~/components/SupportAssistant/components/SettingsForm';
import SettingsHistoryModal from '~/components/SupportAssistant/components/SettingsHistoryModal';

import { ROLE_LESA_ADMIN } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';

import { getSettings } from '~/services/SupportService';

export interface SettingsFormData {
  GENESYS: {
    enabled: boolean;
  };
  CHRISTMAS: {
    enabled: boolean;
  };
}

export default function SettingsPage() {
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h4">Settings Page</Typography>
      <SettingsPageContent />
    </Stack>
  );
}

function SettingsPageContent() {
  const [isSettingsEnabled, setIsSettingsEnabled] = useState(false);
  const [settings, setSettings] = useState<SettingsFormData | null>(null);
  const [error, setError] = useState(null);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('loading');
  const [showSettingsHistory, setShowSettingsHistory] = useState(false);

  const { user } = useCurrentUser();

  const fetchSettings = async () => {
    setError(null);
    setFetchingState('loading');
    try {
      const { result } = await getSettings();
      setIsSettingsEnabled(result?.settingsEnabled);
      setSettings(result?.settings?.values);
      setFetchingState('success');
    } catch (error) {
      setError(error);
      setFetchingState('failed');
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  if (!user || !user.roles.includes(ROLE_LESA_ADMIN)) {
    return (
      <Stack direction="column" alignItems="center" spacing={2}>
        <ErrorDisplay message={'You are not allowed to view this page'} />
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="column" alignItems="center" spacing={2}>
        {fetchingState === 'failed' && error && <ErrorDisplay message={error.message} />}
      </Stack>

      {fetchingState === 'success' && !error && (
        <>
          {!isSettingsEnabled && (
            <Stack direction="column" alignItems="center" spacing={2}>
              <Alert severity="warning">
                Settings disabled as a feature in support service, any change you make here won't make any difference
                until until feature is enabled
              </Alert>
            </Stack>
          )}
          <SettingsForm settings={settings} onError={setError} />

          <Button startIcon={<ListAltIcon />} color="success" onClick={() => setShowSettingsHistory(true)}>
            History
          </Button>
          <SettingsHistoryModal open={showSettingsHistory} onClose={() => setShowSettingsHistory(false)} />
        </>
      )}
    </>
  );
}
