import { offer as libOffer } from '@luxuryescapes/lib-global';

export function getAccommodationPackages(offer: App.CartAccommodationOffer): App.AccommodationPackage[] {
  const optionsAndPackages = offer.packages.flatMap((pkg) => {
    return libOffer.flexibleNights.generateAllOptions(pkg).map((option) => ({ option, pkg }));
  });

  const roomRates = offer.packages.reduce((acc, pkg) => {
    for (const roomRate of pkg.room_rates) {
      acc.set(roomRate.id, roomRate);
    }
    return acc;
  }, new Map<string, App.RoomRate>());

  return optionsAndPackages.map(({ option, pkg }) => {
    return {
      ...pkg,
      fk_room_rate_id: option.roomRateId,
      fk_room_type_id: option.roomTypeId,
      name: option.name,
      number_of_nights: option.duration,
      prices: option.prices,
      room_rate: roomRates.get(option.roomRateId),
      unique_key: `P:${option.packageId}D:${option.duration}R:${option.roomRateId}`,
    };
  });
}
