import React from 'react';

import { Box } from '@mui/material';

import BookingCancelledToggle from './BookingCancelledToggle';
import RefundAmount from './RefundAmount';
import RefundVendorAgrees from './RefundVendorAgrees';

export default function CustomRefundVariant(props) {
  return (
    <Box id="CustomRefundVariant">
      <RefundVendorAgrees
        handleValueChange={props.handleValueChange}
        refund_vendor_agrees_to={props.refund.refund_vendor_agrees_to}
        refund={props.refund}
      />
      <RefundAmount
        tmpValue={props.tmpValue}
        handleTmpChange={props.handleTmpChange}
        itemRefund={props.itemRefund}
        itemRefundOriginal={props.itemRefundOriginal}
        refund={props.refund}
      />
      <BookingCancelledToggle handleFlagChange={props.handleFlagChange} mark_cancelled={props.refund.mark_cancelled} />
    </Box>
  );
}
