import React, { useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import useHeroPlannerCountries from '~/hooks/customerCommunication/useHeroPlannerCountries';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props {
  countryGroupId: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
}

function ScheduleCountryInput(props: Props) {
  const { countryGroupId, name, value, onChange } = props;
  const { countriesReq } = useHeroPlannerCountries(countryGroupId);
  const options = countriesReq?.result ?? EmptyArray;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (options.length && (!value || !options.find((i) => i.id === value))) {
      onChange(options[0].id);
    }
  }, [onChange, options, value]);

  const initialCountry = useMemo<CustomerCommunication.HeroPlannerCountry | undefined>(() => {
    if (value && !options.find((v) => v.id === value)) return { id: value, name: value };
  }, [options, value]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink>Country</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        name={name}
        required
        variant="standard"
        disabled={isRequestUnresolved(countriesReq) || isRequestRejected(countriesReq)}
        error={isRequestRejected(countriesReq)}
      >
        {!!initialCountry && (
          <MenuItem value={initialCountry.id}>
            <i>{initialCountry.name}</i>
          </MenuItem>
        )}
        {options.map((country) => (
          <MenuItem key={country.id} value={country.id}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
      {isRequestRejected(countriesReq) && <FormHelperText error>{countriesReq.error}</FormHelperText>}
      {isRequestPending(countriesReq) && <LinearProgress sx={{ position: 'absolute' }} />}
    </FormControl>
  );
}

export default ScheduleCountryInput;
