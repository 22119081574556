const daysOfWeek = [
  { key: 'monday', shortName: 'Mon', fullName: 'Monday' },
  { key: 'tuesday', shortName: 'Tue', fullName: 'Tuesday' },
  { key: 'wednesday', shortName: 'Wed', fullName: 'Wednesday' },
  { key: 'thursday', shortName: 'Thu', fullName: 'Thursday' },
  { key: 'friday', shortName: 'Fri', fullName: 'Friday' },
  { key: 'saturday', shortName: 'Sat', fullName: 'Saturday' },
  { key: 'sunday', shortName: 'Sun', fullName: 'Sunday' },
];

function daysOfWeekMessage(dateBlockWithWeekdayBooleans) {
  const days = [];
  daysOfWeek.forEach((day) => {
    dateBlockWithWeekdayBooleans[day.key] && days.push(day.shortName);
  });

  if (days.length === 7) {
    return 'All days in range';
  } else if (days.length === 0) {
    return 'No days selected';
  } else {
    return `${days.join(', ')}`;
  }
}

export { daysOfWeek, daysOfWeekMessage };
