import { addDays, dateNowUtc, formatDateFullDay, isAfter } from '~/services/TimeService';

function dateIsInAvailabilityBlock(date, block) {
  const startDate = dateNowUtc(block.range[0]);
  if (isAfter(startDate, date)) {
    return false;
  }
  const endDate = addDays(1, dateNowUtc(block.range[1]));
  return isAfter(endDate, date);
}

function dateIsInBlackoutOrSurchargeBlock(date, block) {
  const startDate = dateNowUtc(block.start_date);
  if (isAfter(startDate, date)) {
    return false;
  }

  const endDate = addDays(1, dateNowUtc(block.end_date));
  if (!isAfter(endDate, date)) {
    return false;
  }

  const dayOfWeek = formatDateFullDay(date).toLowerCase();
  return block[dayOfWeek];
}

export function dateIsBlackout(date, blackoutDates) {
  if (!date || !blackoutDates) {
    return false;
  }
  return !!blackoutDates.find((blackout) => dateIsInBlackoutOrSurchargeBlock(date, blackout));
}

export function getBlackoutRestrictionTypeForDate(date, blackoutDates) {
  if (!date || !blackoutDates) {
    return false;
  }

  const blackout = blackoutDates.find((blackout) => dateIsInBlackoutOrSurchargeBlock(date, blackout));

  if (blackout) {
    return blackout.restriction_type;
  }

  return false;
}

export function dateHasSurcharge(date, surchargeDates) {
  if (!date || !surchargeDates) {
    return null;
  }
  return surchargeDates.find((surcharge) => dateIsInBlackoutOrSurchargeBlock(date, surcharge));
}

export function getAvailabilityForDate(date, availability) {
  if (!date || !availability) {
    return null;
  }
  return availability.find((block) => dateIsInAvailabilityBlock(date, block));
}
