import React from 'react';

import { useSelector } from 'react-redux';

import { Box, Link, Typography } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import { customerPortalHost } from '~/services/common';

type Props = {
  bookingInfo: CruisesContract.BookingOrderResponse;
};

const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  let termsAndConditions = '';
  const paragraphs = tmp.getElementsByTagName('p');
  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    if (paragraph.textContent) {
      termsAndConditions += paragraph.textContent.trim() + ' ';
    }
  }
  return termsAndConditions;
};

export default function CruiseOrderFinePrint(props: Props) {
  const { bookingInfo } = props;
  const rateCodeDetails = stripHtml(bookingInfo.rateCodeDetails).split('. ');
  const tenant = useSelector((state: App.State) => state.tenant);

  return (
    <Box>
      <Link
        href={`${customerPortalHost(tenant)}/booking-policies#cruises`}
        variant="h6"
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
      >
        Terms and Conditions
      </Link>

      <Box mt={2}>
        <Typography variant="h6">Package Terms and Conditions</Typography>
        <Typography variant="body1">
          {rateCodeDetails.filter(Boolean).map((detail) => (
            <p key={detail}> {detail}. </p>
          ))}
        </Typography>
      </Box>
    </Box>
  );
}
