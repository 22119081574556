import React from 'react';

import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { withRouter } from 'react-router-dom';

import { Button } from '@mui/material';

import ReservationService from '../../../services/ReservationService';
import { reportError } from '../../../utils/reportError';
import ErrorListDisplay from '../ErrorListDisplay';

import { buttonMessages, buttonStates } from './states/submitButton';

const uiSchema = {
  rate_plans_groups: {
    'ui:options': {
      orderable: false,
    },
    items: {
      id: { 'ui:widget': 'hidden' },
      rate_plans: {
        'ui:options': {
          orderable: false,
        },
      },
    },
  },
};

class RatePlansGroupsForm extends React.Component {
  constructor(props) {
    super(props);

    this.vendorId = props.vendorId;
    this.schema = {
      ...props.schema,
      properties: {
        ...props.schema.properties,
        rate_plans_groups: {
          ...props.schema.properties.rate_plans_groups,
          items: {
            ...props.schema.properties.rate_plans_groups.items,
            title: 'Rate plan group',
          },
        },
      },
    };

    this.state = {
      ratePlansGroups: {
        rate_plans_groups: props.ratePlansGroups,
      },
      saveState: buttonStates.default,
      errors: [],
    };
  }

  onSubmit = (form) => {
    const { history } = this.props;

    form.errors = [];
    form.errorSchema = {};

    this.setState({
      saveState: buttonStates.saving,
      ratePlansGroups: form.formData,
    });

    ReservationService.updateRatePlansGroups(this.vendorId, form.formData)
      .then(() => {
        this.setState({ saveState: buttonStates.saved, errors: [] }, () => {
          history.push(`/vendors/${this.vendorId}/rate-plans-groups`);
        });
      })
      .catch((e) => {
        const apiErrors = [];
        if (e.name === 'ValidationError') {
          e.errors
            .filter((x) => x.path !== 'id_salesforce_external')
            .forEach((err) => {
              apiErrors.push(err.message);
            });
        }
        this.setState({
          saveState: buttonStates.failed,
          errors: apiErrors,
        });
        reportError(e);
      });
  };

  render() {
    const ratePlansGroups = { ...this.state.ratePlansGroups };

    return (
      <Form
        schema={this.schema}
        uiSchema={uiSchema}
        onChange={this.onChange}
        formData={ratePlansGroups}
        onSubmit={this.onSubmit}
        validator={validator}
      >
        <div className="button-container">
          <Button type="submit" variant="contained" className={this.state.saveState}>
            {buttonMessages[this.state.saveState]}
          </Button>
        </div>

        {this.state.errors.length > 0 && <ErrorListDisplay messages={this.state.errors} />}
      </Form>
    );
  }
}

export default withRouter(RatePlansGroupsForm);
