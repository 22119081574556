import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Tooltip } from '@mui/material';

import { TourScheduleState } from '~/consts/tour';

interface Props {
  status: string;
}

export default function LETourScheduleIndicatorIcon(props: Props) {
  const { status } = props;
  return (
    <>
      {status === TourScheduleState.ONLINE && (
        <Tooltip title="Online Purchase">
          <CircleIcon color="success" />
        </Tooltip>
      )}
      {status === TourScheduleState.OFFLINE && (
        <Tooltip title="Offline Purchase">
          <CircleIcon color="warning" />
        </Tooltip>
      )}
      {status === TourScheduleState.UNAVAILABLE && (
        <Tooltip title="Unavailable">
          <CircleIcon color="disabled" />
        </Tooltip>
      )}
      {status === TourScheduleState.NOT_APPLICABLE && (
        <Tooltip title="Not applicable">
          <CircleOutlinedIcon color="disabled" />
        </Tooltip>
      )}
    </>
  );
}
