import {
  OFFER_FACING_NAMES,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_CAR_HIRE,
  OFFER_TYPE_CRUISE_V2,
  OFFER_TYPE_CUSTOM_OFFER,
  OFFER_TYPE_EXPERIENCE,
  OFFER_TYPE_FLIGHT,
} from '~/consts/offerTypes';

import { humanize } from '~/utils/humanize';

const findTourOfferTypeName = (item: App.OrderTourItem) => {
  const offerTypeName = OFFER_FACING_NAMES[item.product_type];
  return offerTypeName || 'Tour';
};

function getHotelTypes(order: App.Order) {
  return order.items
    .map((item) => item.offer.type)
    .map((type) => OFFER_FACING_NAMES[type])
    .map(humanize);
}

export const buildOfferTypes = (order: App.Order) => {
  const hotelTypes = getHotelTypes(order);
  let allOfferTypes = [...hotelTypes];

  const hasBedbank = order.bedbank_items.length > 0;
  const hasExperience = order.experience_items.length > 0;
  const hasTour = order.tour_items.length > 0;
  const hasFlights = order.flight_items.length > 0;
  const hasCruise = order.cruise_items.length > 0;
  const hasCustomOffer = order.custom_offer_items.length > 0;
  const hasCarHire = order.car_hire_items.length > 0;

  if (hasBedbank) allOfferTypes.push(OFFER_FACING_NAMES[OFFER_TYPE_BED_BANK]);
  if (hasExperience) allOfferTypes.push(OFFER_FACING_NAMES[OFFER_TYPE_EXPERIENCE]);
  if (hasFlights) allOfferTypes.push(OFFER_FACING_NAMES[OFFER_TYPE_FLIGHT]);
  if (hasCustomOffer) allOfferTypes.push(OFFER_FACING_NAMES[OFFER_TYPE_CUSTOM_OFFER]);
  if (hasCarHire) allOfferTypes.push(OFFER_FACING_NAMES[OFFER_TYPE_CAR_HIRE]);
  if (hasCruise) allOfferTypes.push(OFFER_FACING_NAMES[OFFER_TYPE_CRUISE_V2]);

  if (hasTour) {
    const tourOfferTypes = order.tour_items.map((tourItem) => findTourOfferTypeName(tourItem));
    allOfferTypes = allOfferTypes.concat(tourOfferTypes);
  }

  return [...new Set(allOfferTypes)];
};
