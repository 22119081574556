import React from 'react';

import currencyFormatter from 'currency-formatter';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Grid, Stack } from '@mui/material';

const OrderInsuranceSummary = ({
  productName,
  total,
  destinationCountries,
  currencyCode,
  travellers,
  startDate,
  endDate,
}) => (
  <div className="order-summary-panel">
    <div className="offer-meta">
      <div className="offer-title">Travel Insurance</div>
    </div>

    <div className="summary-item-list" data-testid="summary-item-list">
      <div className="summary-item-item">
        <Grid container>
          <Grid item xs={12} sm={10}>
            <div className="item-detail-container">
              <div className="item-detail item-header">
                <div className="item-name">{productName}</div>
                <div className="item-reservation">
                  <Stack direction="row" className="section">
                    <LocationOnIcon />
                    {destinationCountries.join(', ')}
                  </Stack>
                  <Stack direction="row" className="section">
                    <ListAltIcon />
                    {travellers.map((traveller) => `${traveller.first_name} ${traveller.surname}`).join(', ')}
                  </Stack>
                  <Stack direction="row" className="section">
                    <CalendarMonthIcon />
                    <span>{startDate}</span>
                    <span className="separator">-</span>
                    <span>{endDate}</span>
                  </Stack>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="item-amounts">
              <div className="item-price">{currencyFormatter.format(total, { code: currencyCode })}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  </div>
);

export default OrderInsuranceSummary;
