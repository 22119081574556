import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';

const pad = (value: number) => {
  const valueAsString = value + '';
  if (valueAsString.length < 2) {
    return '0' + valueAsString;
  } else {
    return valueAsString;
  }
};

type Props = {
  hasTimedOut: boolean;
  showComponent: React.ReactElement;
};

export default function TimeElapsed(props: Props) {
  const { hasTimedOut, showComponent } = props;

  const [state, setState] = useState({
    durationInSeconds: 0,
    minutesElapsed: '00',
    secondsElapsed: '00',
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setState((prevState) => ({
        durationInSeconds: prevState.durationInSeconds + 1,
        secondsElapsed: pad((prevState.durationInSeconds + 1) % 60),
        minutesElapsed: pad(Math.floor((prevState.durationInSeconds + 1) / 60)),
      }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (hasTimedOut) {
    return showComponent;
  }

  return (
    <Typography>
      <span className="data-hj-whitelist">{state.minutesElapsed} minutes</span>{' '}
      <span className="data-hj-whitelist">{state.secondsElapsed} seconds</span>
    </Typography>
  );
}
