import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { MarkdownPreview } from 'react-marked-markdown';
import { Link, useParams } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@mui/material';

import { getRegionByCode, getRegions } from '@luxuryescapes/lib-regions';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

import { getCancellationPolicyLabel } from '~/utils/cancellationPolicy';
import { humanize } from '~/utils/humanize';

import ReservationSchedule from '../common/ReservationSchedule';

import RatePlanBonusInclusions from './RatePlanBonusInclusions';
import RatePlanOffersContainer from './RatePlanOffersContainer';

const getRegionNameFromCode = (code) => {
  const region = getRegionByCode(code, 'luxuryescapes');
  return region ? region.name : code;
};

const getCancellationPolicyDetail = (cancellationPolicyDetail) => {
  return cancellationPolicyDetail.map((p, i) => {
    return <div key={i}>{p}</div>;
  });
};

export default function RatePlanPageContainer() {
  const { id_vendor: vendorId, id_rate_plan: ratePlanId } = useParams<{ id_vendor: string; id_rate_plan: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState(null);
  const [ratePlan, setRatePlan] = useState(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([ReservationService.getRatePlan(ratePlanId), VendorsService.getVendorById(vendorId)])
      .then(([ratePlan, vendor]) => {
        setRatePlan(ratePlan.result);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, ratePlanId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load vendor or rate plan.</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor?.name || vendorId} | {ratePlan.name}
        </title>
      </Helmet>

      <PageHeader title="Rate plan" backButton={`/vendors/${vendorId}`}>
        <Button variant="text" component={Link} to={`/vendors/${vendorId}/edit-rate-plan/${ratePlanId}`}>
          Edit
        </Button>
      </PageHeader>

      <Box display="grid" gap={3} gridTemplateColumns="repeat(4, 1fr)">
        <div>
          <DefinitionTitle>Name</DefinitionTitle>
          <DefinitionText>{ratePlan.name}</DefinitionText>
        </div>

        <div>
          <DefinitionTitle>Rate type</DefinitionTitle>
          <DefinitionText>{ratePlan.rate_type}</DefinitionText>
        </div>

        {ratePlan.rate_type === 'net' && (
          <div>
            <DefinitionTitle>Markup</DefinitionTitle>
            <DefinitionText>{ratePlan.markup}%</DefinitionText>
          </div>
        )}

        {ratePlan.rate_type === 'gross' && (
          <>
            <div>
              <DefinitionTitle>Commission</DefinitionTitle>
              <DefinitionText>{ratePlan.commission}%</DefinitionText>
            </div>
            <div>
              <DefinitionTitle>Discount</DefinitionTitle>
              <DefinitionText>{ratePlan.discount}%</DefinitionText>
            </div>
          </>
        )}

        <div>
          <DefinitionTitle>Instant Purchase or Deposit</DefinitionTitle>
          <DefinitionText>{ratePlan.type_of_pay ? humanize(ratePlan.type_of_pay) : 'No'}</DefinitionText>
        </div>

        <div>
          <DefinitionTitle>Product Type</DefinitionTitle>
          <DefinitionText>{humanize(ratePlan.product_type)}</DefinitionText>
        </div>

        <div>
          <DefinitionTitle>Cancellation Policy</DefinitionTitle>
          <DefinitionText>{getCancellationPolicyLabel(ratePlan.cancellation_policy)}</DefinitionText>
        </div>

        {!!ratePlan.rate_plan_code && (
          <div>
            <DefinitionTitle>Rate Plan Code</DefinitionTitle>
            <DefinitionText>{ratePlan.rate_plan_code}</DefinitionText>
          </div>
        )}

        {!!ratePlan.inclusions && (
          <div>
            <DefinitionTitle>Rate Plan Inclusions</DefinitionTitle>
            <div>
              <MarkdownPreview value={ratePlan.inclusions} />
            </div>
          </div>
        )}

        <div>
          <DefinitionTitle>Status</DefinitionTitle>
          <DefinitionText>{ratePlan.status}</DefinitionText>
        </div>

        <div>
          <DefinitionTitle>Stay Pay</DefinitionTitle>
          <DefinitionText>{ratePlan.is_stay_pay_enabled ? 'Yes' : 'No'}</DefinitionText>
        </div>

        {ratePlan.schedules_list?.length > 0 && (
          <Box gridColumn="span 2">
            <DefinitionTitle>Schedules</DefinitionTitle>
            <div>
              <ReservationSchedule parent={ratePlan} />
            </div>
          </Box>
        )}

        {!!ratePlan.cancellation_policy_detail && (
          <Box gridColumn="1 / span 2">
            <DefinitionTitle>Cancellation policy detail</DefinitionTitle>
            <div>{getCancellationPolicyDetail(ratePlan.cancellation_policy_detail)}</div>
          </Box>
        )}

        <Box gridColumn="1 / span 2">
          <DefinitionTitle>Package Regions</DefinitionTitle>
          <div>
            <ul className="region-tags-list">
              {ratePlan.regions.length === getRegions().length ? (
                <li key="world">world</li>
              ) : (
                ratePlan.regions.map((region) => <li key={region}>{getRegionNameFromCode(region)}</li>)
              )}
            </ul>
          </div>
        </Box>

        {ratePlan.bonus_inclusions?.length > 0 && (
          <Box gridColumn="1 / span 4">
            <RatePlanBonusInclusions bonusInclusions={ratePlan.bonus_inclusions} />
          </Box>
        )}

        <Box gridColumn="1 / span 4">
          <RatePlanOffersContainer ratePlanId={ratePlan.id} vendorId={vendorId} />
        </Box>
      </Box>
    </Container>
  );
}
