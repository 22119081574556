import React from 'react';

import AddableCheckboxGroup from './AddableCheckboxGroup';

class Amenities extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, checked) {
    const { onChange, formData: currentSelection } = this.props;

    let nextSelection;
    if (checked) {
      nextSelection = [...currentSelection, value];
    } else {
      nextSelection = currentSelection.filter((option) => {
        return option !== value;
      });
    }

    onChange(nextSelection);
  }

  render() {
    const { registry, formData } = this.props;
    const { formContext } = registry;

    const options = formContext.amenityOptions;

    return (
      <div>
        <h6 className="text-uppercase text-muted">Amenities</h6>
        <AddableCheckboxGroup
          options={options}
          selection={formData || []}
          onChange={this.handleChange}
          onStartAddingAmenities={formContext.onStartAddingAmenities}
        />
      </div>
    );
  }
}

export default Amenities;
