import { datesHaveSameDayMonthYear, isAfter, isBetween } from '~/services/TimeService';

export function isBlackoutDay(dayDate) {
  const { date } = dayDate;

  if (!date) {
    return false;
  }

  return date.check_in_blackout_applies;
}

export function isCheckinDay(checkIn) {
  if (!checkIn) {
    return () => false;
  }

  return function (day) {
    return datesHaveSameDayMonthYear(day, checkIn);
  };
}

export function isCheckoutDay(lastNight) {
  if (!lastNight) {
    return () => false;
  }

  return function (day) {
    return datesHaveSameDayMonthYear(day, lastNight);
  };
}

export function isDayBlocked(yesterday) {
  return function (dayDate) {
    const { date } = dayDate;

    if (!date) {
      return true;
    }

    if (date.duration_blackout_applies) {
      return true;
    }

    if (!date.duration_available) {
      return true;
    }

    if (!isAfter(date.check_in, yesterday)) {
      return true;
    }

    return false;
  };
}

export function isDayHighlighted(checkIn, lastNight) {
  if (!checkIn || !lastNight) {
    return () => false;
  }

  return function (day) {
    if (
      //previous implementation checked for this in a much terser style
      isBetween(checkIn, lastNight, day) ||
      datesHaveSameDayMonthYear(day, checkIn) ||
      datesHaveSameDayMonthYear(lastNight, day)
    ) {
      return true;
    }
    return false;
  };
}

export function isSurchargeDay(dayDate) {
  const { date } = dayDate;

  if (!date) {
    return false;
  }

  return date.check_in_surcharge_applies;
}
