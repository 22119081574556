import { useMemo } from 'react';

import { NAVIGATION_LINKS } from '~/consts/navigation';

import featureToggleUtils from '~/utils/featureToggle';

export default function useNavigationLinks(user: App.User) {
  const navLinks = useMemo(() => {
    if (!user) return [];

    return NAVIGATION_LINKS.filter(
      (navLink) =>
        featureToggleUtils.availableToShow(navLink.featureToggle) &&
        (!navLink.requiredRoles?.size || // has no required roles
          user.roles.some((role) => navLink.requiredRoles.has(role))), // user has sufficient roles
    );
  }, [user]);

  return navLinks;
}
