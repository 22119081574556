import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import dayjs from '~/timeInit';

import { Container, Paper, Typography } from '@mui/material';

import { withTenant } from './hoc';

interface IndexPageContextProps {
  user: App.User;
}

function IndexPage(props, { user }: IndexPageContextProps) {
  const showLastLogin = useMemo(() => {
    if (user) {
      const lastLogin = user.lastLogin;
      if (lastLogin.login_date) {
        const lastLoginDate = new Date(lastLogin.login_date);
        return (
          <div>
            You last logged in {dayjs(lastLoginDate).fromNow()} on {dayjs(lastLoginDate).calendar()}{' '}
          </div>
        );
      }
    }
  }, [user]);

  return (
    <Container maxWidth="xl">
      <Paper className="user-panel">
        <Typography fontWeight="bold" variant="h3">
          Welcome {user ? ' ' + user.givenName : null}!
        </Typography>
        {showLastLogin}
      </Paper>
    </Container>
  );
}

IndexPage.contextTypes = {
  user: PropTypes.object,
};

export default withTenant(IndexPage);
