import React from 'react';

import pluralize from 'pluralize';
import { ExperiencesState } from '~/reducers/experiences';
import { theme } from '~/theme';

import {
  Alert,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { Card, FlexWrapper, GridWrapper, Spinner } from '~/components/Experiences/components';

import { ExperienceOffer } from '~/services/ExperiencesService';

import { ExperienceCardContent, ExperienceRowContent } from './Content';

type ContentProps = {
  tenant: App.Tenant;
  currentPage: number;
  sizePerPage: number;
  experiencesTotal: number;
  experiences: ExperiencesState;
  handlePage: (_, page: number) => void;
  toggleSelected: { [key: string]: boolean };
};

export const ExperiencesContent: React.FC<ContentProps> = (props) => {
  const { handlePage, experiences, sizePerPage, currentPage, toggleSelected, experiencesTotal } = props;

  const countExp = pluralize('experience', experiences?.total || 0, true);
  const experiencesTitle = `${countExp} found by your search criteria`;

  const openExperienceDetails = (experience: ExperienceOffer) => {
    window.open(`/experience/${experience.id}`, '_blank');
  };

  const pageLength = Math.ceil(experiencesTotal / sizePerPage) ?? 1;

  const notFoundExperiences: boolean = !experiences.error && !experiences.loading && experiences.items?.length === 0;

  const columns: string[] = [
    'Status',
    'Name & Description',
    'Cost Price',
    'Sale Price',
    'Margin',
    'Brands',
    'Rating',
    'Categories',
  ];

  const hasExperiences = experiences.items?.length > 0;

  return (
    <>
      <FlexWrapper>
        {experiences?.loading && <Spinner />}

        {hasExperiences && <Alert severity="success">{experiencesTitle}</Alert>}

        {experiences?.error && <Alert severity="error">ERROR ON FETCH EXPERIENCES</Alert>}

        {notFoundExperiences && <Alert severity="warning">NO EXPERIENCES FOUND</Alert>}
      </FlexWrapper>

      {hasExperiences && (
        <FlexWrapper direction="column">
          {toggleSelected?.table && (
            <FlexWrapper mt={'10px'}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column}
                          sx={{
                            padding: 1,
                            fontSize: 16,
                            color: 'white',
                            backgroundColor: theme.palette.primary.dark,
                          }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {experiences.items?.map((experience: ExperienceOffer) => (
                      <ExperienceRowContent
                        key={experience.id}
                        experience={experience}
                        onClick={() => openExperienceDetails(experience)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </FlexWrapper>
          )}

          {toggleSelected?.card && (
            <GridWrapper columns={'repeat(4, 1fr)'}>
              {experiences.items?.map((experience: ExperienceOffer) => (
                <Card key={experience.id}>
                  <ExperienceCardContent experience={experience} onClick={() => openExperienceDetails(experience)} />
                </Card>
              ))}
            </GridWrapper>
          )}
          <Stack spacing={2} mt={4}>
            <Pagination
              hidePrevButton
              hideNextButton
              count={pageLength}
              page={currentPage}
              onChange={handlePage}
              color="primary"
            />
          </Stack>
        </FlexWrapper>
      )}
    </>
  );
};
