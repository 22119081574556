import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

export default function SurchargeToggle(props) {
  return (
    <Box id="SurchargeToggle">
      <Typography>
        Should the surcharge {props.surchargeInfo.currency_code} {props.surchargeInfo.price} be refunded to customer?
      </Typography>

      <Stack>
        <Box>
          <input
            type="radio"
            key="yes"
            name="is_refunded_surcharge"
            className="T-is-refunded-surcharge-yes"
            value="1"
            onChange={props.handleFlagChange}
            checked={props.is_refunded_surcharge === true}
          />
          <label>Yes</label>
        </Box>
        <Box>
          <input
            type="radio"
            key="no"
            name="is_refunded_surcharge"
            className="T-is-refunded-surcharge-no"
            value="0"
            onChange={props.handleFlagChange}
            checked={props.is_refunded_surcharge === false}
          />
          <label>No</label>
        </Box>
      </Stack>
    </Box>
  );
}
