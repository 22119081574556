import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { Button, Card, CardContent, Container, Stack } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PropertyReviewForm from '~/components/Common/Forms/PropertyReviewForm';

import generateRandomString from '~/utils/generateRandomString';

export default function PropertyReviewsEdit({
  schema,
  vendor,
  vendorId,
  property,
  propertyId,
  reviewsData: initialReviewsData,
}) {
  const [reviewsData, setReviewsData] = useState(initialReviewsData);

  const addNewPropertyReview = () => {
    setReviewsData((prevState) => [
      ...prevState,
      {
        _id: generateRandomString(),
      },
    ]);
  };

  const removePropertyReview = (formKey) => {
    setReviewsData((prevState) => prevState.filter((review) => review.id !== formKey));
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendor | {vendor?.name || vendorId} | {property?.name || propertyId} | Edit Property Reviews
        </title>
      </Helmet>

      <PageHeader title="Edit property reviews" backButton={`/vendors/${vendorId}/properties/${propertyId}`} />

      <Stack direction="column" spacing={2}>
        {reviewsData.map((review, i) => (
          <Card variant="outlined" key={i}>
            <CardContent>
              <PropertyReviewForm
                property={property}
                propertyId={propertyId}
                formKey={review.id}
                reviewData={review}
                schema={schema}
                onRemovePropertyReview={removePropertyReview}
              />
            </CardContent>
          </Card>
        ))}

        <div>
          <Button variant="contained" onClick={addNewPropertyReview}>
            Add
          </Button>
        </div>
      </Stack>
    </Container>
  );
}
