import React, { ComponentProps, useCallback, useState } from 'react';

import requestPutHeroPlannerScheduleEmailStatus from '~/queries/customerCommunication/requestPutHeroPlannerScheduleEmailStatus';

import { Alert, AlertTitle, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { isRequestPending, isRequestRejected } from '~/utils/requestUtils';

interface Props {
  emailStatus: CustomerCommunication.HeroPlannerSchedule['emailStatus'];
  scheduleId: string;
  onStatusUpdate: () => void;
}

function ScheduleEmailStatusEditor(props: Props) {
  const { emailStatus, scheduleId, onStatusUpdate } = props;

  const [updateReq, setUpdateReq] = useState<
    Utils.RequestState<CustomerCommunication.HeroPlannerSchedule['emailStatus'], string>
  >({
    status: RequestStatus.INITIAL,
  });
  const updateStatus = useCallback(
    async (newStatus: CustomerCommunication.HeroPlannerSchedule['emailStatus']) => {
      setUpdateReq({
        status: RequestStatus.PENDING,
        params: undefined,
      });

      try {
        const result = await requestPutHeroPlannerScheduleEmailStatus(scheduleId, newStatus);
        setUpdateReq({
          status: RequestStatus.FULFILLED,
          params: undefined,
          result,
        });
        onStatusUpdate();
      } catch (error) {
        setUpdateReq({
          status: RequestStatus.REJECTED,
          params: undefined,
          error,
        });
      }
    },
    [scheduleId, onStatusUpdate],
  );

  const handleChange = useCallback<ComponentProps<typeof ToggleButtonGroup>['onChange']>(
    async (event, value) => {
      if (value) updateStatus(value);
    },
    [updateStatus],
  );

  return (
    <Stack direction="column" gap={1} position="relative" flexGrow="1">
      <ToggleButtonGroup
        value={emailStatus}
        exclusive
        disabled={isRequestPending(updateReq)}
        fullWidth
        onChange={handleChange}
      >
        <ToggleButton value="DRAFT" color="info">
          DRAFT
        </ToggleButton>
        <ToggleButton value="PENDING" color="warning">
          PENDING
        </ToggleButton>
        <ToggleButton value="APPROVED" color="success">
          APPROVED
        </ToggleButton>
      </ToggleButtonGroup>
      {isRequestRejected(updateReq) && (
        <Alert severity="error">
          <AlertTitle>Could not update status</AlertTitle>
          {updateReq.error}
        </Alert>
      )}
    </Stack>
  );
}

export default ScheduleEmailStatusEditor;
