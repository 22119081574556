import React, { useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { Skeleton, Typography } from '@mui/material';

import fetchHeroPlannerOfferDetails from '~/actions/customerCommunication/fetchHeroPlannerOfferDetails';

import { useAppSelector } from '~/hooks/store';

import { isRequestFulfilled, isRequestPending, isRequestRejected } from '~/utils/requestUtils';

interface Props {
  countryGroupId: string;
  countryId: string;
  offerId: string;
}

function ScheduleOfferDetailListItem(props: Props) {
  const { countryGroupId, countryId, offerId } = props;
  const brand = useAppSelector((state) => state.tenant.brand);
  const [key, params] = useMemo(() => {
    const params = [brand, countryGroupId, countryId, offerId] as const;
    return [getRecordKeyFor(...params), params];
  }, [brand, countryGroupId, countryId, offerId]);
  const offerDetails = useAppSelector((state) => state.customerCommunication.offerDetailsRecords[key]);
  const appDispatch = useDispatch();
  useEffect(() => {
    appDispatch(fetchHeroPlannerOfferDetails(...params));
  }, [params, appDispatch]);

  return (
    <Typography component="li" variant="inherit" color={isRequestRejected(offerDetails) ? 'error' : undefined}>
      {(!offerDetails || isRequestPending(offerDetails)) && <Skeleton width={100} />}
      {isRequestFulfilled(offerDetails) && offerDetails.result.name}
      {isRequestRejected(offerDetails) && <i>COULD NOT FETCH {offerId}</i>}
    </Typography>
  );
}

export default ScheduleOfferDetailListItem;
