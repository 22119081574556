import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, FormControl, Stack, TextField } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import VendorsService from '~/services/VendorsService';

import VendorOfferPaymentsListContainer from './VendorOfferPaymentsListContainer';

export default function VendorOfferPaymentsPane(props) {
  const [currentInput, setCurrentInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInput(event.target.value.trim());
  };

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSearchTerm(currentInput);
  };

  const handleDownload = async () => {
    try {
      await VendorsService.downloadPaymentsByOfferVendor({
        idOffer: props.idOffer,
        idVendor: props.idVendor,
        format: 'xlsx',
      });
      enqueueSnackbar('Vendor payments downloaded', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(`Cannot download vendor payments: ${err.message}`, { variant: 'error' });
    }
  };

  return (
    <Box>
      <PageSubheader title="Vendor Offer Payments">
        <Button
          startIcon={<AddIcon />}
          variant="text"
          component={Link}
          to={'/finance/offers/' + props.idOffer + '/offer-payments/' + props.idVendor}
        >
          Add offer payment
        </Button>
      </PageSubheader>

      <Stack direction="column" spacing={2} id="offer-payments">
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth variant="outlined">
            <TextField
              placeholder="Enter Booking Number or Payment Amount"
              value={currentInput}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <Button type="submit" variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Stack>
        <VendorOfferPaymentsListContainer data={props.data} searchTerm={searchTerm} />

        <Box>
          <Button type="submit" variant="outlined" onClick={handleDownload}>
            Download Vendor Offer Payments
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
