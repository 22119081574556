import * as React from 'react';

import { theme } from '~/theme';

import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export default function CustomNumberInput(props: NumberInputProps) {
  return (
    <BaseNumberInput
      slots={{
        root: InputRoot,
        input: InputElement,
        incrementButton: Hidden,
        decrementButton: Hidden,
      }}
      {...props}
    />
  );
}

export const CustomInputAdornment = styled(Box)`
  margin: 8px;
  align-items: center;
  justify-content: center;
  grid-row: 1/3;
  color: ${theme.palette.text.secondary};
`;

const InputRoot = styled(Box)`
  color: ${theme.palette.text.primary};
  background: '#fff';
  border-radius: 4px;
  border: 1px solid ${theme.palette.text.secondary};
  display: flex;
  /* overflow: hidden; */

  &:hover {
    border-color: ${theme.palette.text.primary};
  }

  &.${numberInputClasses.focused} {
    border-color: ${theme.palette.primary.dark};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

const InputElement = styled('input')`
  font-size: 1rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-row: 1/3;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`;

const Hidden = styled('div')`
  display: none;
`;
