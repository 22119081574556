import React, { useCallback } from 'react';

import { CustomOfferFormValues } from '@/typings/formValues';
import { useSnackbar } from 'notistack';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';
import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import useToggleState from '~/hooks/useToggleState';

import * as OrdersService from '~/services/OrdersService';
import { getReservationByOrderAndItem } from '~/services/OrdersService';
import { mapCountryCodeToRegion } from '~/services/UserHelper';

import {
  bedbankCancellationToString,
  combineDescription,
  combinePolicies,
  finePrintToString,
  getBedbankCancellationDate,
  lpcCancellationToString,
  setUpTravellers,
} from '~/utils/customOfferFormUtils';

import {
  FLEXIBLE_CANCELLATION_POLICY,
  NONREFUNDABLE_CANCELLATION_POLICY,
  SALES_ATTRIBUTION_MARGIN_MAX_VALUE,
} from '../constants';

import RebookingModal from './RebookingModal';

interface Props {
  sendStatus: Utils.ProcessingState;
  setSendStatus: (value: Utils.ProcessingState) => void;
  setValue: UseFormSetValue<CustomOfferFormValues>;
  watch: UseFormWatch<CustomOfferFormValues>;
  user: App.User;
  control: Control<CustomOfferFormValues>;
  isSubmitting: boolean;
  handleAddNewTraveller: (type: string) => void;
}

async function getOrderFromBookingNumber(bookingNumber: string): Promise<App.Order> {
  const response = await OrdersService.searchPurchases({ page: 0, perPage: 1, searchId: bookingNumber });
  return response.result[0] as App.Order;
}

export const PrePopulateCustomOfferForm = ({
  sendStatus,
  setSendStatus,
  setValue,
  watch,
  user,
  control,
  isSubmitting,
  handleAddNewTraveller,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  // based on booking ID, fill out fields to match given item
  const createdFromBookingNumber = watch('created_from_booking_number');
  const { isToggled: isRebookModalVisible, toggleOn: showRebookModal, toggleOff: hideReboookModal } = useToggleState();

  const populateFields = async (bookingNumber: string, isRebooking: boolean) => {
    try {
      setSendStatus('processing');
      const order = await getOrderFromBookingNumber(bookingNumber);
      if (!order) {
        throw new Error('Invalid booking ID');
      }

      let item: App.OrderItem;
      if (order.has_bedbank) {
        item = order.bedbank_items.filter((bedbank_item) => {
          return bedbank_item.booking_number === bookingNumber;
        })[0];
      } else {
        item = order.items.filter((item) => {
          return item.booking_number === bookingNumber;
        })[0];
      }
      if (!item) {
        throw new Error('Only supports LPC and LPP orders');
      }

      const offer = item.offer;
      let roomType,
        cancellationPolicy,
        finePrint,
        description,
        customerName,
        specialRequests = '';
      if (offer && offer.type === 'tactical_ao_hotel') {
        setValue('item.start_date', item.reservation.check_in);
        setValue('item.end_date', item.reservation.check_out);

        // find policies
        roomType = item['offer_package']['name'];
        cancellationPolicy = lpcCancellationToString(item['cancellation_policies'][0]);
        finePrint = offer.fine_print as string;

        // get reservations
        let reservation = await getReservationByOrderAndItem(order.id, item.id);
        reservation = reservation['result'] as Reservation.HotelReservation;

        // get special requests
        specialRequests = reservation?.guest_special_requests;

        description = combineDescription(roomType, offer.description, specialRequests);

        // find # of travellers
        setUpTravellers(handleAddNewTraveller, reservation);

        // find customer name
        customerName = `${reservation['guest_first_name']} ${reservation['guest_last_name']}`;
      } else if (item.type === 'bedbank') {
        setValue('item.start_date', item?.check_in);
        setValue('item.end_date', item?.check_out);

        // find policies
        const room = item['rooms'][0] as Order.BedbankItemRoom;
        let promotionInclusions = '';
        if (item.offer['promotions']) {
          const promotions = item.offer['promotions'] as Order.BedbankOfferPromotion[];
          if (promotions[0] && promotions[0].rate_inclusions_long) {
            promotionInclusions = promotions[0].rate_inclusions_long;
          }
        }
        roomType = room['name'];

        // get special requests
        specialRequests = room?.guest_special_requests;

        description = combineDescription(
          roomType,
          offer.description,
          specialRequests,
          room.facilities,
          promotionInclusions,
        );
        cancellationPolicy = bedbankCancellationToString(room, offer.timezone);
        finePrint = finePrintToString(offer.fine_print as FinePrint);

        // find # of travellers
        setUpTravellers(handleAddNewTraveller, room);

        // find customer name
        customerName = `${room['guest_first_name']} ${room['guest_last_name']}`;

        // fill in customer policies
        if (!room.refundable) {
          setValue('payment_metadata.cancellation_type', NONREFUNDABLE_CANCELLATION_POLICY);
        } else {
          try {
            const bedbankCancellationDate = getBedbankCancellationDate(room, offer.timezone);
            setValue('payment_metadata.cancellation_type', FLEXIBLE_CANCELLATION_POLICY);
            setValue('payment_metadata.cancellation_end_date', bedbankCancellationDate);
          } catch (err) {
            enqueueSnackbar(`Could not populate cancellation: ${err.message}`, { variant: 'error' });
          }
        }

        // if its a rebooking, set the order id that its rebooked from
        // and update the sales attribution to margin max
        if (isRebooking) {
          setValue('payment_metadata.rebooking_from_order_id', order.id_orders);
          setValue('payment_metadata.sales_attribution', SALES_ATTRIBUTION_MARGIN_MAX_VALUE);
        }
      } else {
        throw new Error('Only supports LPC and LPP items');
      }
      // update all values
      setValue('item.service_information', combinePolicies(cancellationPolicy, finePrint));
      setValue('item.description', description);
      setValue('item.name', offer.name);
      setValue('travellers.0.name', customerName);
      setValue('travellers.0.country', mapCountryCodeToRegion(user.country_code).name);
      setValue('created_from_item_id', item.id);
      setSendStatus('idle');
    } catch (err) {
      setValue('created_from_booking_number', '');
      setValue('created_from_item_id', '');
      setValue('payment_metadata.rebooking_from_order_id', '');
      setSendStatus('idle');
      enqueueSnackbar(`Could not populate: ${err.message}`, { variant: 'error' });
    }
  };

  const onLoadOrderItemClick = useCallback(async () => {
    if (createdFromBookingNumber) {
      setSendStatus('processing');
      try {
        const order = await getOrderFromBookingNumber(createdFromBookingNumber);
        if (order?.has_bedbank) {
          showRebookModal();
        } else {
          await populateFields(createdFromBookingNumber, false);
        }
      } catch (err) {
        enqueueSnackbar(`Could not find order ${createdFromBookingNumber}`, { variant: 'error' });
        setSendStatus('idle');
      }
    }
  }, [createdFromBookingNumber]);

  return (
    <Stack>
      <Typography variant="h6">Populate From Existing Booking</Typography>
      <Stack direction="row" spacing={2}>
        <Controller
          name="created_from_booking_number"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} label="Booking Number" disabled={isSubmitting} fullWidth />
          )}
        />
        <RebookingModal
          isModalOpen={isRebookModalVisible}
          closeModal={hideReboookModal}
          populateFields={populateFields}
          createdFromBookingNumber={createdFromBookingNumber}
          setValue={setValue}
          watch={watch}
        />
        <LoadingButton
          loading={sendStatus === 'processing'}
          variant="contained"
          size="small"
          onClick={onLoadOrderItemClick}
        >
          Apply
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
