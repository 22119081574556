import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { RouteProps } from 'react-router';

import { Box } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import useCurrentUser from '~/hooks/useCurrentUser';

import type { UserType } from '~/types/responses';

type Props = RouteProps;

type LegacyContext = {
  loading: boolean;
  authenticated: boolean;
  token?: string;
  user?: UserType;
};

export default function HomeRoute({ children }: Props, context: LegacyContext) {
  const { loading, authenticated, user } = context ?? {};

  const { setCurrentUserState } = useCurrentUser();

  useEffect(() => {
    setCurrentUserState({ isLoading: loading, isAuthenticated: authenticated, user: user ?? null });
  }, [loading, authenticated, user, setCurrentUserState]);

  if (loading) {
    return (
      <Box height="100vh">
        <Spinner />
      </Box>
    );
  }

  return children;
}

HomeRoute.contextTypes = {
  token: PropTypes.string,
  loading: PropTypes.bool,
  authenticated: PropTypes.bool,
  user: PropTypes.object,
};
