import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, Stack, TextField } from '@mui/material';

import type { TagType } from '~/consts/tags';

type Props = {
  type: TagType;
  onCreateTag: (type: TagType, name: string) => Promise<App.MarketingTag>;
};

export default function TagForm(props: Props) {
  const { type, onCreateTag } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (name.includes(',')) {
        enqueueSnackbar('Cannot create a tag with special character [ , ].', { variant: 'error' });
        return;
      }

      onCreateTag(type, name)
        .then(() => {
          enqueueSnackbar('Tag has been successfully created', { variant: 'success' });
          setName('');
        })
        .catch((error) => {
          if (error.message) {
            enqueueSnackbar(error.message, { variant: 'error' });
          } else {
            enqueueSnackbar("Server error. Can't create a new tag.", { variant: 'error' });
          }
        });
    },
    [type, name, onCreateTag, enqueueSnackbar],
  );

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" spacing={1} alignItems="stretch">
          <TextField
            size="small"
            type="text"
            label="Tag name"
            placeholder="Input a new tag name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{
              minLength: 3,
              maxLength: 64,
            }}
            required
          />

          <Button type="submit" variant="contained" disabled={!name}>
            Create
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
