import React from 'react';

import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Reservation } from '@luxuryescapes/contract-svc-reservation';

import ReservationService from '../../services/ReservationService';
import dateFormatter from '../../utils/dateFormatter';
import ReservationSchedule from '../Vendors/common/ReservationSchedule';

type RatePlan = Reservation.RatePlan;

interface Props {
  ratePlans: RatePlan[];
  editBtnFormatter: (value: any) => any;
  cloneBtnFormatter: (value: any) => any;
  loading?: boolean;
}

export default class RatePlanList extends React.Component<Props> {
  timer: NodeJS.Timeout;

  changeDefaultPlan = (e) => {
    const value = e.target.value;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      ReservationService.setDefaultRatePlan(value);
    }, 1000);
  };

  groupFormatter = (data) => {
    return data ? data.name : ' - ';
  };

  packagedRateIdFormatter = (packagedRateId) => {
    const { ratePlans } = this.props;
    if (!packagedRateId) {
      return ' - ';
    }

    const packagedRate = ratePlans.find((x) => x.id === packagedRateId);

    return packagedRate ? packagedRate.name : ' - ';
  };

  packagedFormatter = (isPackagedRate) => {
    return isPackagedRate ? 'T' : 'F';
  };

  nameFormatter = (name, data) => {
    return name + (data.rate_plan_code ? ` (${data.rate_plan_code})` : '');
  };

  defaultFormatter = (name, data) => {
    return (
      <input
        type="radio"
        name="default_plan"
        value={data.id}
        onChange={this.changeDefaultPlan}
        defaultChecked={data.default_plan}
      />
    );
  };

  scheduleFormatter = (name, data) => {
    const { ratePlans } = this.props;
    const ratePlan = ratePlans.find((x) => x.id === data.id);
    return ratePlan?.schedules_list?.length ? <ReservationSchedule parent={ratePlan} listView={true} /> : 'F';
  };

  render() {
    const { ratePlans, editBtnFormatter, cloneBtnFormatter } = this.props;

    const columns: GridColDef[] = [
      {
        field: 'name',
        headerName: 'Name',
        disableColumnMenu: true,
        minWidth: 125,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return this.nameFormatter(params.value, params.row);
        },
        display: 'flex',
      },
      {
        field: 'group',
        headerName: 'Group',
        disableColumnMenu: true,
        minWidth: 50,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return this.groupFormatter(params.value);
        },
        display: 'flex',
      },
      {
        field: 'default_plan',
        headerName: 'Default',
        disableColumnMenu: true,
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return this.defaultFormatter(params.value, params.row);
        },
        display: 'flex',
      },
      {
        field: 'schedules_list',
        headerName: 'Scheduled',
        disableColumnMenu: true,
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return this.scheduleFormatter(params.value, params.row);
        },
        display: 'flex',
      },
      {
        field: 'is_packaged_rate',
        headerName: 'Is Packaged',
        disableColumnMenu: true,
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return this.packagedFormatter(params.value);
        },
        display: 'flex',
      },
      {
        field: 'packaged_rate_id',
        headerName: 'Packaged',
        disableColumnMenu: true,
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return this.packagedRateIdFormatter(params.value);
        },
        display: 'flex',
      },
      {
        field: 'created_at',
        headerName: 'Created at',
        disableColumnMenu: true,
        minWidth: 125,
        flex: 1,
        valueFormatter: (value) => {
          return dateFormatter(value);
        },
        display: 'flex',
      },
      {
        field: 'clone',
        headerName: 'Clone',
        disableColumnMenu: true,
        minWidth: 70,
        sortable: false,
        flex: 0,
        renderCell: (params: GridRenderCellParams) => {
          return cloneBtnFormatter(params.row?.id);
        },
        display: 'flex',
      },
      {
        field: 'edit',
        headerName: 'Edit',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 70,
        flex: 0,
        renderCell: (params: GridRenderCellParams) => {
          return editBtnFormatter(params.row?.id);
        },
        display: 'flex',
      },
    ];

    return (
      <DataGrid
        className="T-rate-plan-table"
        loading={this.props.loading}
        rows={ratePlans || []}
        columns={columns}
        getRowId={(row) => row.id}
        getRowHeight={() => 'auto'}
        slots={{
          pagination: null,
        }}
        autoHeight
        disableRowSelectionOnClick
        sx={{ mt: 2 }}
      />
    );
  }
}
