import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import { emptyCart, setCustomer } from '../../../../actions/cart';
import { getOrder } from '../../../../services/OrdersService';
import UsersService from '../../../../services/UsersService';
import Spinner from '../../../Common/Spinner';

import Page from './Page';

type Props = {
  customer: App.User;
  doEmptyCart: () => void;
  doSetCustomer: (customer: App.User) => void;
};

function UpdateInsurance(props: Props) {
  const { id_user, id_order } = useParams<{
    id_user: string;
    id_order: string;
  }>();
  const [order, setOrder] = useState<App.Order>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    props.doEmptyCart();

    const dataRequests = [UsersService.getUser(id_user), getOrder(id_order)];

    Promise.all(dataRequests)
      .then(([customer, order]) => {
        props.doSetCustomer(customer);
        setOrder(order.result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id_user]);

  if (loading) {
    return <Spinner />;
  }

  return <Page order={order} orderId={id_order} customer={props.customer} onEmptyCart={props.doEmptyCart} />;
}

function mapStateToProps(state) {
  return {
    customer: state.cart.customer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      doEmptyCart: emptyCart,
      doSetCustomer: setCustomer,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInsurance);
