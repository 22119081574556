import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import SearchService, { UserRecentSearch } from '~/services/SearchService';
import { diffDays, formatDateWithClock } from '~/services/TimeService';

import { reportError } from '~/utils/reportError';

import GridPagination from '../Common/Elements/GridPagination';

type Props = {
  userId: string;
};

const columns: Array<GridColDef> = [
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    flex: 4,
    valueGetter: (value) => formatDateWithClock(value),
    display: 'flex',
  },
  {
    field: 'url',
    headerName: 'URL',
    flex: 1,
    renderCell: (params) => {
      const url = params.row.url;
      return (
        <a href={url} target="_blank" rel="noreferrer">
          URL
        </a>
      );
    },
    display: 'flex',
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'vertical',
    headerName: 'Vertical',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'searchType',
    headerName: 'Type',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'destinationName',
    headerName: 'Destination',
    flex: 4,
    display: 'flex',
  },
  {
    field: 'numberOfAdults',
    headerName: '# Adults',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'numberOfChildren',
    headerName: '# Children',
    flex: 2,
    display: 'flex',
    valueGetter: (value) => value || 0,
  },
  {
    field: 'rooms',
    headerName: '# Rooms',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'dateType',
    headerName: 'Date Type',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex: 2,
    display: 'flex',
    valueGetter: (value) => value || '-',
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    flex: 2,
    display: 'flex',
    valueGetter: (value) => value || '-',
  },
  {
    field: 'durationType',
    headerName: 'Duration',
    flex: 2,
    display: 'flex',
    valueGetter: (value, row: UserRecentSearch) => {
      if (row.dateType === 'flexible') {
        return row.durationType;
      }
      if (row.dateType === 'specific') {
        return diffDays(row.endDate, row.startDate, 'day');
      }
      return '-';
    },
  },
];

function UserRecentSearchTable({ userId }: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    try {
      SearchService.getUserRecentSearches(brand, userId)
        .then((response) => setResult(response.result))
        .finally(() => setLoading(false));
    } catch (error) {
      reportError(error);
    }
  }, [userId, brand]);

  return (
    <Box>
      <DataGrid
        rows={result || []}
        columns={columns}
        getRowId={() => uuid()}
        autoHeight
        loading={loading}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5]}
        paginationModel={{ pageSize: 10, page }}
        onPaginationModelChange={({ page }) => setPage(page)}
        disableColumnMenu
        disableRowSelectionOnClick
        density="compact"
        slots={{ pagination: GridPagination }}
      />
    </Box>
  );
}

export default UserRecentSearchTable;
