import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useAccommodationServiceRequest } from '~/components/Accommodation/hooks/useAccommodationServiceRequest';
import Spinner from '~/components/Common/Spinner';

import { SupplierProperty, patchPropertySupplier } from '~/services/AccommodationService';

interface Props {
  propertyId: string;
  supplier: SupplierProperty;
  onClose: () => void;
  onComplete: (supplier: SupplierProperty) => void;
}

export default function AccommodationPropertySupplierRatesEnabledModal({
  propertyId,
  supplier,
  onClose,
  onComplete,
}: Props) {
  const newSppEnabledValue = !supplier.sppEnabled;

  const apiReq = useCallback(() => {
    return patchPropertySupplier({ propertyId, supplierId: supplier.id }, { sppEnabled: newSppEnabledValue });
  }, [newSppEnabledValue, propertyId, supplier.id]);

  const onSuccess = useCallback(
    (result: SupplierProperty) => {
      onComplete(result);
    },
    [onComplete],
  );

  const { reqState, doRequest } = useAccommodationServiceRequest({ apiReq, onSuccess });

  const handleConfirm = useCallback(() => {
    doRequest();
  }, [doRequest]);

  return (
    <Dialog open>
      <DialogTitle>{newSppEnabledValue ? 'Enabling' : 'Disabling'} rates</DialogTitle>
      <DialogContent>
        {reqState.status === 'failed' && <DialogContentText color="error">{reqState.error}</DialogContentText>}
        <Box my={2}>
          <Typography>
            This action will {newSppEnabledValue ? 'enable' : 'disable'} rates for the {supplier.supplier} property (
            {supplier.supplierId}). Allowing them to be shown on the customer portal.
          </Typography>
        </Box>
        <DialogActions>
          <Button onClick={onClose} variant="text" disabled={reqState.status === 'pending'}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" disabled={reqState.status === 'pending'}>
            {reqState.status === 'pending' ? <Spinner size={15} inline /> : 'Confirm'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
