import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import RatePlanForm from '~/components/Common/Forms/RatePlanForm';
import Spinner from '~/components/Common/Spinner';

import ReservationService from '~/services/ReservationService';
import VendorsService from '~/services/VendorsService';

export default function RatePlanEditContainer() {
  const { id_vendor: vendorId, id_rate_plan: ratePlanId } = useParams<{ id_vendor: string; id_rate_plan: string }>();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('loading');
  const [vendor, setVendor] = useState(null);
  const [ratePlan, setRatePlan] = useState(null);
  const [packagedRatePlans, setPackagedRatePlans] = useState(null);
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    setLoadingState('loading');

    Promise.all([
      ReservationService.getRatePlanSchema(ratePlanId),
      ReservationService.getRatePlan(ratePlanId),
      ReservationService.getRatePlans(vendorId, true),
      VendorsService.getVendorById(vendorId),
    ])
      .then(([schema, ratePlan, packagedRatePlans, vendor]) => {
        setSchema(schema.put.body.schema);
        setRatePlan(ratePlan.result);
        const packagedRatePlansWithoutItself = packagedRatePlans.result.filter(
          (packagedPlan) => packagedPlan.id !== ratePlan.result.id,
        );
        setPackagedRatePlans(packagedRatePlansWithoutItself);
        setVendor(vendor.result);
        setLoadingState('success');
      })
      .catch(() => {
        setLoadingState('failed');
      });
  }, [vendorId, ratePlanId]);

  if (loadingState === 'loading') {
    return (
      <Container maxWidth="xl">
        <Spinner />
      </Container>
    );
  }

  if (loadingState === 'failed') {
    return (
      <Container maxWidth="xl">
        <Typography>Something went wrong. Can't load vendor or rate plan.</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>
          Vendors | {vendor?.name || vendorId} | {ratePlan.name} | Edit Rate Plan
        </title>
      </Helmet>

      <PageHeader title="Edit Rate Plan" backButton={`/vendors/${vendorId}/rate-plans/${ratePlanId}`} />

      <RatePlanForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        schema={schema}
        ratePlan={ratePlan}
        packagedRatePlans={packagedRatePlans}
        vendorId={vendorId}
        ratePlanId={ratePlanId}
      />
    </Container>
  );
}
