import React from 'react';

import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Button, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { isAllCancelled } from '~/utils/order';

const getOrderDetailPageUrl = (id_orders: string) => {
  return '/purchases/' + id_orders;
};

const orderLinkFormatter = ({ row }: GridRenderCellParams<App.Order>) => {
  const allAccommodationsCancelled = isAllCancelled(row.items);
  const allAddonsCancelled = isAllCancelled(row.addon_items);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Button variant="text" size="small" href={getOrderDetailPageUrl(row.id)} target="_blank">
        Details
      </Button>
      {row.has_addons && allAccommodationsCancelled && !allAddonsCancelled && (
        <span title="This order has all accommodation items cancelled but some addons still active.">
          <WarningOutlinedIcon color="warning" />
        </span>
      )}
    </Stack>
  );
};

export default orderLinkFormatter;
