import React from 'react';

import currencyFormatter from 'currency-formatter';
import styled from 'styled-components';

const PnrInput = styled.input`
  width: 110px;
  text-align: center;
`;
const getPnrPrice = (pnr) => {
  return pnr.travellers.reduce((price, traveller) => price + traveller.price, 0);
};

const OfflineFlightItemWrapper = ({ pnrIndex, pnr, currencyCode, onUpdatePNRId, children }) => {
  return (
    <li className="booking-item">
      <div className="booking-item-content">
        <div className="booking-item-meta">
          <div className="item-number data-hj-whitelist">{pnrIndex + 1}</div>
          <div className="data-hj-whitelist text-center">
            <h4>PNR</h4>
            <PnrInput
              className="booking-input data-hj-whitelist"
              placeholder="ABCDEF"
              value={pnr.PNR}
              onChange={(e) => onUpdatePNRId(e, pnrIndex)}
            />
          </div>
          <div className="item-price data-hj-whitelist text-center">
            <div>
              {currencyFormatter.format(getPnrPrice(pnr), {
                code: currencyCode,
              })}
            </div>
          </div>
        </div>
        <div className="booking-item-form-container">{children}</div>
      </div>
    </li>
  );
};

export default OfflineFlightItemWrapper;
