import { request } from './common';

export default class VimeoService {
  static basePath = 'https://api.vimeo.com';
  static accessToken = 'a43983cead2e71638b8373a3d28af5ed';

  static getVimeoDetails(videoId) {
    return request(`${this.basePath}/videos/${videoId}?access_token=${this.accessToken}`, {
      method: 'GET',
      credentials: 'omit',
    });
  }
}
