import React, { useCallback } from 'react';

import PropertySearchForm from '~/components/Common/Forms/PropertySearchForm';

export default function PropertiesSearchContainer() {
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }, []);

  return <PropertySearchForm handleSubmit={handleSubmit} showTable={'property'} searchType={'property'} />;
}
