import * as React from 'react';

export const Close = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg height={12} width={12} {...props}>
      <path
        fill={props?.fill}
        d="M10 1.007L8.993 0 5 3.993 1.007 0 0 1.007 3.993 5 0 8.993 1.007 10 5 6.007 8.993 10 10 8.993 6.007 5 10 1.007z"
      />
    </svg>
  );
};
