import React, { useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Box, Button, Card, CardActions, CardContent, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Image from '../Image';

interface Props {
  id: string | number;
  publicImageId: string;
  order: number;
  title?: string;
  hidden?: boolean;
  filename?: string;
  is_hero?: boolean;
  source?: string;
  onChangeTitle: (imageId: string | number, newTitle: string) => any;
  onDeleteImage: (imageId: string | number) => void;
  onHideImage: (imageId: string | number) => () => void;
  hasHiddenToggle?: boolean;
  hideDeleteButton?: boolean;
}

const imageOptions = {
  width: 400,
  height: 300,
};

const ImagePanel = (props: Props) => {
  const {
    id,
    publicImageId,
    order,
    title,
    hidden,
    filename,
    onChangeTitle,
    onDeleteImage,
    onHideImage,
    hasHiddenToggle = false,
    hideDeleteButton = false,
  } = props;

  const [localTitle, setLocalTitle] = useState(title || '');

  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const updateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalTitle(e.target.value);
    onChangeTitle(id, e.target.value);
  };

  return (
    <Grid xs={4}>
      <Card
        {...listeners}
        {...attributes}
        raised={isDragging}
        ref={setNodeRef}
        sx={{
          flexGrow: 1,
          transform: CSS.Transform.toString(transform),
          transition,
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        variant="outlined"
      >
        <CardContent>
          <Image
            className="img-responsive mx-auto"
            publicId={publicImageId}
            options={imageOptions}
            style={
              hidden
                ? {
                    filter: 'grayscale(1)',
                    opacity: 0.75,
                  }
                : {}
            }
          />
        </CardContent>
        <CardActions>
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2">{filename}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h5" pr={2}>
                {order}
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                id={`title-${id}`}
                name="title"
                type="text"
                className="image-title-input undraggable"
                value={localTitle}
                onChange={updateTitle}
                placeholder="A short description of the image"
              />
            </Stack>
            <Stack justifyContent="space-between" alignItems="center" direction="row">
              {hasHiddenToggle && (
                <Button
                  variant="text"
                  startIcon={hidden ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                  color={hidden ? 'success' : 'info'}
                  onClick={onHideImage(id)}
                >
                  <Typography variant="subtitle2">
                    &nbsp;{hidden ? 'Show' : 'Hide'}
                    &nbsp;Image
                  </Typography>
                </Button>
              )}
              {hideDeleteButton || (
                <Button variant="text" onClick={() => onDeleteImage(id)} startIcon={<DeleteIcon />}>
                  <Typography noWrap variant="subtitle2">
                    Delete image
                  </Typography>
                </Button>
              )}
            </Stack>
          </Stack>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ImagePanel;
