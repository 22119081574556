import React from 'react';

import ExpiringCreditReportForm from '../Common/Forms/ExpiringCreditReportForm';
import ExportAbandonedOrdersReportForm from '../Common/Forms/ExportAbandonedOrdersReportForm';
import ExportAddonsReportForm from '../Common/Forms/ExportAddonsReportForm';
import ExportAgentSalesReportForm from '../Common/Forms/ExportAgentSalesReportForm';
import ExportCancelledFutureBookingsReportForm from '../Common/Forms/ExportCancelledFutureBookingsReportForm';
import ExportDailyReportForm from '../Common/Forms/ExportDailyReportForm';
import ExportFutureBookingsReportForm from '../Common/Forms/ExportFutureBookingsReportForm';
import ExportGiftCardsReportForm from '../Common/Forms/ExportGiftCardsReportForm';
import ExportInsuranceBookingsReportForm from '../Common/Forms/ExportInsuranceBookingsReportForm';
import ExportLatitudeFinanceRefundsForm from '../Common/Forms/ExportLatitudeFinanceRefundsForm';
import ExportPromoCodesForm from '../Common/Forms/ExportPromoCodesForm';
import ExportPromoRefundReportForm from '../Common/Forms/ExportPromoRefundReportForm';
import ExportPurchasesForm from '../Common/Forms/ExportPurchasesForm';
import ExportPurchasesReportForm from '../Common/Forms/ExportPurchasesReportForm';
import ExportRefundReportForm from '../Common/Forms/ExportRefundReportForm';
import ExportTravellersReportForm from '../Common/Forms/ExportTravellersReportForm';
import ExportVistaraReportForm from '../Common/Forms/ExportVistaraReportForm';

import ContentWithSidebar from './ContentWithSidebar';

const DEFAULT_REPORT = 'daily-reports';

const reports = [
  {
    title: 'Daily Report',
    slug: 'daily-reports',
    component: ExportDailyReportForm,
  },
  {
    title: 'Purchases',
    slug: 'purchases',
    component: ExportPurchasesReportForm,
  },
  {
    title: 'Insurance Bookings',
    slug: 'insurance-bookings',
    component: ExportInsuranceBookingsReportForm,
  },
  { title: 'List of Orders', slug: 'orders', component: ExportPurchasesForm },
  {
    title: 'Promo Code Report',
    slug: 'promo-codes',
    component: ExportPromoCodesForm,
  },
  {
    title: 'Refunds Report',
    slug: 'refunds',
    component: ExportRefundReportForm,
  },
  {
    title: 'Promo Refunds report',
    slug: 'promo-refunds',
    component: ExportPromoRefundReportForm,
  },
  {
    title: 'Latitude Finance Refunds Report',
    slug: 'latitude-refunds',
    component: ExportLatitudeFinanceRefundsForm,
  },
  {
    title: 'Gift Cards Report',
    slug: 'gift-cards',
    component: ExportGiftCardsReportForm,
  },
  {
    title: 'Experiences Report',
    slug: 'experiences',
    component: ExportAddonsReportForm,
  },
  {
    title: 'Vistara Report',
    slug: 'vistara-repors',
    component: ExportVistaraReportForm,
  },
  {
    title: 'Travellers With Missing Details Report',
    slug: 'travellers',
    component: ExportTravellersReportForm,
  },
  {
    title: 'Agent Sales Report',
    slug: 'agent-sales',
    component: ExportAgentSalesReportForm,
  },
  {
    title: 'Abandoned Orders Report',
    slug: 'abandoned-orders',
    component: ExportAbandonedOrdersReportForm,
  },
  {
    title: 'Future Bookings Report',
    slug: 'future-bookings',
    component: ExportFutureBookingsReportForm,
  },
  {
    title: 'Cancelled Future Bookings Report',
    slug: 'cancelled-future-bookings',
    component: ExportCancelledFutureBookingsReportForm,
  },
  {
    title: 'Expiring Credits Report',
    slug: 'expiring-credits',
    component: ExpiringCreditReportForm,
  },
];

export default function BusinessReportsPage() {
  return <ContentWithSidebar items={reports} defaultItem={DEFAULT_REPORT} />;
}
