import qs from 'qs';

import { CruisesContract, definitions } from '@luxuryescapes/contract-svc-cruise';

import { json_headers, request } from '../common';

const RESOURCE = 'cabin-categories-groups';
const BASE_PATH = () => window.configs.API_HOST + '/api/cruise';

export type Brands = definitions['LE_BRAND'];
export type CabinCategoriesGroup = definitions['CABIN_CATEGORIES_GROUP_LIST'];
export type CabinCategoriesGroupListQuery = definitions['CABIN_CATEGORIES_GROUP_LIST_QUERY'];

export async function getCabinCategoriesGroupList(
  params: CabinCategoriesGroupListQuery,
): Promise<CruisesContract.Response<CabinCategoriesGroup[]>> {
  const url = `${BASE_PATH()}/v2/${RESOURCE}?${qs.stringify(params)}`;

  return await request(url, { method: 'GET' });
}

export type CabinCategoriesGroupById = definitions['CABIN_CATEGORIES_GROUP_BY_ID'];

export type CabinCategoriesGroupByIdQuery = {
  groupId: string;
  brand?: Brands;
};

export async function getCabinCategoriesGroupById(
  params: CabinCategoriesGroupByIdQuery,
): Promise<CruisesContract.Response<CabinCategoriesGroupById>> {
  const { groupId, ...queries } = params;
  const url = `${BASE_PATH()}/v2/${RESOURCE}/${groupId}?${qs.stringify(queries)}`;

  return await request(url, { method: 'GET' });
}

export type CabinCategoriesGroupResponse = definitions['CABIN_CATEGORIES_GROUP'];
export type CreateCabinCategoriesGroupParams = definitions['CREATE_CABIN_CATEGORIES_GROUP_BODY'];

export async function createCabinCategoriesGroup(
  params: CreateCabinCategoriesGroupParams,
): Promise<CruisesContract.Response<CabinCategoriesGroupResponse>> {
  const url = `${BASE_PATH()}/v2/${RESOURCE}`;

  const body: CreateCabinCategoriesGroupParams = {
    name: params.name,
    brand: params.brand,
    shipId: params.shipId,
    description: params.description,
    cabinCategoriesIds: params.cabinCategoriesIds,
    images: params.images,
  };

  return await request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}

export type UpdateCabinCategoriesGroupParams = definitions['UPDATE_CABIN_CATEGORIES_GROUP_BODY'];

export async function updateCabinCategoriesGroup(
  id: string,
  params: UpdateCabinCategoriesGroupParams,
): Promise<CruisesContract.Response<CabinCategoriesGroupResponse>> {
  const url = `${BASE_PATH()}/v2/${RESOURCE}/${id}`;

  const body: UpdateCabinCategoriesGroupParams = {
    name: params.name,
    brand: params.brand,
    description: params.description,
    cabinCategoriesIds: params.cabinCategoriesIds,
    images: params.images,
  };

  return await request(url, {
    method: 'PUT',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}

export type DeleteCabinCategoriesGroupParams = definitions['DELETE_CABIN_CATEGORIES_GROUP_BODY'];

export async function deleteCabinCategoriesGroup(
  id: string,
  params?: DeleteCabinCategoriesGroupParams,
): Promise<CruisesContract.Response<CabinCategoriesGroupResponse>> {
  const url = `${BASE_PATH()}/v2/${RESOURCE}/${id}`;

  const body: DeleteCabinCategoriesGroupParams = {
    brand: params.brand,
  };

  return await request(url, {
    method: 'DELETE',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}
