import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerBrandsGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/brands']['get'];

function mapHeroPlannerBrand(
  external: Utils.ArrayElement<
    HeroPlannerBrandsGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerBrand {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetHeroPlannerBrands() {
  const url = makeCustomerCommunicationV1URL('/todays-escapes-schedule/hero-planner/brands');

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerBrandsGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerBrand);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerBrands;
