import React, { useCallback, useState } from 'react';

import sum from 'lodash/sum';

import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';

import NumberFormatCustom from '~/components/Common/ReactNumberFormat/NumberFormatCustom';
import { RefundMetadata } from '~/components/Refund/types';
import getPricePerNightMap from '~/components/Refund/utils/getPricePerNightMap';

import { formatDateWeekDayShortMonth, generateDateRange } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

import { getCurrencySymbol } from '../../Utils/RefundUtils';

interface Props {
  refundMetadata: RefundMetadata;
  checkInDate: string;
  checkOutDate: string;
  currencyCode: string;
  formRef: any;
  handleLateChangeOfMindFieldChange: any;
}

export default function SelectNights({
  refundMetadata,
  checkInDate,
  checkOutDate,
  currencyCode,
  formRef,
  handleLateChangeOfMindFieldChange,
}: Props) {
  const checkIn = new Date(checkInDate);
  const checkOut = new Date(checkOutDate);
  const dateRange = generateDateRange(checkIn, checkOut);
  const pricesPerNight = getPricePerNightMap(refundMetadata, dateRange);
  const [checkedDates, setCheckedDates] = useState<Set<string>>(new Set());
  const [refundToCustomer, setRefundToCustomer] = useState(0);

  const handleCheckboxChange = (date) => (event) => {
    const formData = new FormData(formRef.current);
    const arrCopy = new Set(checkedDates);
    const dateString = date.toISOString();

    if (event.target.checked) {
      // Add date to checkedDates if checkbox is checked
      arrCopy.add(dateString);
    } else {
      // Remove date from checkedDates if checkbox is unchecked
      arrCopy.delete(dateString);
    }

    const totalPricesPerNight = [...arrCopy].map((date) => {
      return Number(pricesPerNight[date]);
    });
    setCheckedDates(arrCopy);
    setRefundToCustomer(sum(totalPricesPerNight));

    formData.set('totalRefundedToCustomer', sum(totalPricesPerNight).toString());
    formData.set('numberOfNights', arrCopy.size.toString());
    handleLateChangeOfMindFieldChange(formData);
  };

  const handleTotalRefundedToCustomerChange = useCallback(
    (e) => {
      const formData = new FormData(formRef.current);
      setRefundToCustomer(parseFloat(e.target.value));
      formData.set('totalRefundedToCustomer', e.target.value);
      handleLateChangeOfMindFieldChange(formData);
    },
    [formRef, handleLateChangeOfMindFieldChange],
  );

  return (
    <Box mt={3}>
      <Stack spacing={2}>
        <FormGroup>
          {dateRange.map((date, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    data-cy={`date-range-${index}`}
                    checked={checkedDates.has(date.toISOString())}
                    onChange={handleCheckboxChange(date)}
                  />
                }
                key={date.toISOString()}
                label={
                  <Grid container width="320px" justifyContent="space-between">
                    <Grid>
                      <Typography>{formatDateWeekDayShortMonth(date)}</Typography>
                    </Grid>
                    <Grid>
                      <Typography>{currencyFormatter(currencyCode, pricesPerNight[date.toISOString()], 2)}</Typography>
                    </Grid>
                  </Grid>
                }
                sx={{
                  // this overrides the tall mui checkbox
                  height: '27px',
                }}
              />
            );
          })}
        </FormGroup>
        <Grid container pl={2}>
          <Grid sm={5} alignContent="center">
            <Typography fontWeight="bold">
              {checkedDates.size} / {dateRange.length} {dateRange.length === 1 ? 'night' : 'nights'} selected
            </Typography>
            {/* Hidden input to hold the numberOfNights tab value */}
            <input type="hidden" name="numberOfNights" value={checkedDates.size} />
          </Grid>
          <Grid sm={5}>
            <Stack>
              <Typography fontWeight="bold">Total Refunded To Customer</Typography>
              <NumberFormatCustom
                value={refundToCustomer}
                onChange={handleTotalRefundedToCustomerChange}
                size="small"
                data-cy="RefundToCustomerAmount"
                prefix={getCurrencySymbol(currencyCode)}
              />
              {/* Hidden input to hold the totalRefundedToCustomer tab value */}
              <input type="hidden" name="totalRefundedToCustomer" value={refundToCustomer} />
            </Stack>
          </Grid>
        </Grid>
        <Divider />
      </Stack>
    </Box>
  );
}
