import React, { useState } from 'react';

import fileDownload from 'react-file-download';

import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getRecordsDataByUrl } from '~/services/FlightsService';
import { formatDateISOWithTime } from '~/services/TimeService';

import { ConvertToCSV } from '~/utils/arrayUtils';

import DateWidget from '../Common/Forms/DateWidget';

export const AtolReports = () => {
  const [date, setDate] = useState(new Date());

  const getAtolRecords = (url) => {
    const year = String(date.getFullYear());
    const month = ('0' + (date.getMonth() + 1)).slice(-2);

    const fileName = `atol-${url}-${year}-${month}-on-${formatDateISOWithTime()}.csv`;

    getRecordsDataByUrl(url, {
      year,
      month,
    })
      .then((response) => {
        const text = ConvertToCSV(response.result);
        fileDownload(text, fileName);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid md={12}>
        <Typography variant="h4">ATOL Reports by Given Month</Typography>
      </Grid>
      <Grid md={3}>
        <Typography>Select Month</Typography>
        <DateWidget
          value={date}
          onChange={(newDate) => {
            setDate(new Date(newDate));
          }}
        />
      </Grid>
      <Grid md={9} />
      <Grid md={6}>
        <Typography variant="h6">Get ATOL records by created month</Typography>
        <Button variant="contained" onClick={() => getAtolRecords('getAtolByCreatedMonth')}>
          Export CSV
        </Button>
      </Grid>
      <Grid md={6}>
        <Typography variant="h6">Get Refunds for ATOL records by created month</Typography>
        <Button variant="contained" onClick={() => getAtolRecords('getRefundForAtolByCreatedMonth')}>
          Export CSV
        </Button>
      </Grid>
      <Grid md={6}>
        <Typography variant="h6">Get ATOL records by departure month</Typography>
        <Button variant="contained" onClick={() => getAtolRecords('getAtolByDepartureMonth')}>
          Export CSV
        </Button>
      </Grid>
      <Grid md={6}>
        <Typography variant="h6">Get ATOL records grouped by departure month</Typography>
        <Button variant="contained" onClick={() => getAtolRecords('getAtolGroupedByDepartureMonth')}>
          Export CSV
        </Button>
      </Grid>
    </Grid>
  );
};

export default AtolReports;
