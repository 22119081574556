import { countries } from '~/globals';

import { UTM_MEDIUM } from '~/consts/utm';

export const LE_ENTITY_AUS = 'Australia';
export const LE_ENTITY_INTL = 'International';
export const LE_ENTITIES = [LE_ENTITY_AUS, LE_ENTITY_INTL];

export const DEFAULT_ITEM_TYPE = 'cruise';

export const OFFER_TYPES = [
  { value: 'cruise', label: 'Cruise' },
  { value: 'hotel', label: 'Hotel' },
  { value: 'tour', label: 'Tour' },
  { value: 'experience', label: 'Experience' },
  { value: 'other', label: 'Other' },
];

export const REASONS = [
  { value: 'vendor_not_on_le', label: "We don't have this vendor on LE" },
  { value: 'offer_not_on_le', label: "We don't have this offer on LE" },
  { value: 'tech_issues', label: 'I had tech issues booking this offer on LE' },
  { value: 'margin_max', label: 'Margin Max' },
  { value: 'other', label: 'Other' },
];

export const FLEXIBLE_CANCELLATION_POLICY = 'flexible';
export const NONREFUNDABLE_CANCELLATION_POLICY = 'nonrefundable';

export const CUSTOM_OFFER_CANCELLATION_POLICY_TYPES = [FLEXIBLE_CANCELLATION_POLICY, NONREFUNDABLE_CANCELLATION_POLICY];

export const COUNTRIES_LIST = ['Unspecified', ...countries];

export const SALES_ATTRIBUTION_MARGIN_MAX_VALUE = 'Margin_Max';

export const SALES_ATTRIBUTION_LIST = [
  { label: 'Contact Centre', value: UTM_MEDIUM },
  { label: 'Store', value: 'popup_store' },
  { label: 'Margin Max', value: SALES_ATTRIBUTION_MARGIN_MAX_VALUE },
  { label: 'Group Bookings', value: 'group_bookings' },
];
