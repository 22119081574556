import React from 'react';

import { Stack } from '@mui/material';

import FxRateForm from '../../Common/Forms/ExportFxRatesForm';

import Upload from './Upload';

export default function FxRatesPage() {
  return (
    <Stack direction="column" spacing={4}>
      <Upload />
      <FxRateForm />
    </Stack>
  );
}
