import * as libRegions from '@luxuryescapes/lib-regions';

export const getRegionsCodeByName = (list) => {
  return list.map((name) => {
    const obj = libRegions.getRegions().find((o) => o.name === name);
    if (obj !== undefined) {
      return obj.code;
    }
  });
};
