import React, { useCallback, useEffect, useState } from 'react';

import { MenuItem, Stack, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';

import cruiseLineService from '~/services/cruises/CruiseLineService';

interface Props {
  label: string;
  value: string;
  onChange: (cruiseLine: API.CruiseLine) => void;
  required?: boolean;
}

const InputCruiseLineSelect = ({ label, value, onChange, required }: Props) => {
  const [cruiseLines, setShips] = useState<API.CruiseLine[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialCruiseLine, setInitialCruiseLine] = useState<boolean>(false);

  const getShipsList = useCallback(async () => {
    if (cruiseLines.length) return;
    setLoading(true);
    const res = await cruiseLineService.listWithPagination({ take: 50 });
    setShips(res.result);
    setLoading(false);
  }, [cruiseLines]);

  const handlerOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const cruiseLine = cruiseLines.find((cruiseLine) => cruiseLine.id === e.target.value);
      onChange(cruiseLine);
    },
    [cruiseLines, onChange],
  );

  useEffect(() => {
    getShipsList();
  }, [getShipsList]);

  useEffect(() => {
    if (initialCruiseLine) return;
    const cruiseLine = cruiseLines.find((cruiseLine) => cruiseLine.id === value);
    if (cruiseLine) {
      onChange(cruiseLine);
      setInitialCruiseLine(true);
    }
  }, [cruiseLines, onChange, value, initialCruiseLine]);

  return (
    <Stack spacing={2} direction="row">
      <TextField
        select
        fullWidth
        label={label}
        value={value}
        onChange={handlerOnChange}
        required={required}
        InputLabelProps={{ shrink: true }}
      >
        {cruiseLines.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      {loading && <Spinner size={20} />}
    </Stack>
  );
};

export default InputCruiseLineSelect;
