import { sortBy } from 'lodash';

import { getRegions } from '@luxuryescapes/lib-regions';

import { REGION_SORT_ORDER, RuleConditions } from '~/consts/agentHub';

export function buildRuleParam(rules: RuleConditions) {
  return Object.entries(rules).reduce((acc, [key, conditions]) => {
    if (conditions.length <= 0) return acc;
    return { ...acc, [key]: conditions.map((condition) => condition.value) };
  }, {});
}

export const groupByValue = (acc, curr: { value: string; label: string }) => ({ ...acc, [curr.value]: curr.label });

export const getSortedRegions = () =>
  sortBy(
    getRegions(),
    (region) => {
      const regionOrder = REGION_SORT_ORDER.indexOf(region.name);
      if (regionOrder === -1) {
        // Put it at the bottom of the list if it's not in the desired order
        return 999;
      }
      return regionOrder;
    },
    'asc',
  );
