import React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

type Props = React.PropsWithChildren<{
  title: string;
  startIcon?: React.ReactNode;
}>;

export default function PageSubheader(props: Props) {
  const { title, children, startIcon } = props;

  return (
    <Box pt={1} pb={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {startIcon}

        <Typography flex={1} component="h2" color="grey.800" fontSize="1.8rem" fontWeight={500} gutterBottom>
          {title}
        </Typography>

        {children && <Box>{children}</Box>}
      </Stack>

      <Divider />
    </Box>
  );
}
