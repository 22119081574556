import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import Spinner from '~/components/Common/Spinner';

import { getAddonsByOrder } from '~/services/OrdersService';
import ReservationService from '~/services/ReservationService';
import UsersService from '~/services/UsersService';

import type { RefundsResponse } from '~/types/responses';

import AccountingExplanation from './AccountingExplanation';
import AccountingMetaTable from './AccountingMetaTable';

interface OrderDetailProps {
  order: App.Order;
  offers: Array<{ items: Array<App.OrderItem>; offer: App.AccommodationOffer }>;
  refunds: RefundsResponse;
  refreshData: () => void;
  reservationsFetched: boolean;
  reservations: App.OrderItemReservation[];
}

interface IAddons {
  [key: string]: unknown;
}

interface ICancelledReservations {
  [key: string]: unknown;
}

type Users = {
  [id: string]: App.UserSummary;
};

const getCancelledReservations = async (refunds?: RefundsResponse) => {
  let cheaperDatesReservationsData = [];
  if (refunds?.result?.length) {
    cheaperDatesReservationsData = await Promise.all(
      refunds.result.map((refundMeta) => {
        if (refundMeta.charge_component_key?.includes('surcharge|')) {
          const reservationId = refundMeta.charge_component_key.replace('surcharge|', '');
          return ReservationService.getReservationById(reservationId).catch(() => null);
        }
      }),
    );
  }

  return cheaperDatesReservationsData.reduce((acc, response) => {
    if (response?.result) {
      acc[response.result.id] = response.result;
    }
    return acc;
  }, {});
};

export default function OrderDetailAccountingData(props: OrderDetailProps) {
  const { order, offers, refunds, refreshData, reservationsFetched, reservations } = props;

  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState<Users>({});
  const [addons, setAddons] = useState<IAddons>();
  const [cancelledReservations, setCancelledReservations] = useState<ICancelledReservations>({});

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);

      const userIds = [...new Set(refunds.result.map((refund) => refund.refunded_by))];
      const users: Map<string, App.UserSummary> = await UsersService.getUsersSummaryByIds(userIds, brand);

      setUsers(Object.fromEntries(users.entries()));
    } finally {
      setLoading(false);
    }
  }, [brand, refunds]);

  const fetchData = async () => {
    const [addons, cancelledReservations] = await Promise.all([
      getAddonsByOrder(order),
      getCancelledReservations(refunds),
    ]);

    setAddons(addons);
    setCancelledReservations(cancelledReservations);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, refunds]);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = isLoading || !reservationsFetched;

  return (
    <Box mt={2}>
      <FoldableSection title="Accounting data" initiallyExpanded>
        {loading && <Spinner size={36} />}

        {!loading && (
          <>
            <AccountingMetaTable
              refunds={refunds}
              users={users}
              sale_currency_code={order.currency_code}
              refreshData={refreshData}
            />

            <AccountingExplanation
              refunds={refunds}
              sale_currency_code={order.currency_code}
              offers={offers}
              order={order}
              reservations={reservations}
              addons={addons}
              cancelledReservations={cancelledReservations}
            />
          </>
        )}
      </FoldableSection>
    </Box>
  );
}
